import React, { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Spin, Popconfirm, notification } from "antd";
import { MinimalButton, PlusCircle, ShowMore, Trash02 } from "components/utilitycomponents";
import AlternateSupplierModal from "./alternateSupplierModal";
import { EmptyTable } from "../../EmptyTable";
import {
    useSupplierCardsAlternateSuppliers,
    useSupplierCardsAlternateSupplierUpdate,
} from "controller";

type PropsType = {
    partnerId: number;
    partnerName: string;
    reloadPartners: any;
};

const AlternateSupplier: FunctionComponent<PropsType> = ({
    partnerId,
    partnerName,
    reloadPartners,
}) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState({ changed: false });
    const { loading, data } = useSupplierCardsAlternateSuppliers(partnerId, reload);
    const {
        loading: removeLoading,
        deleteAlternateSupplier,
    } = useSupplierCardsAlternateSupplierUpdate();
    const [modalVisible, setModalVisible] = useState(false);
    const [alternateSupplier, setAlternateSupplier] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setAlternateSupplier({ new: false, ...record });
            setModalVisible(true);
        },
        style: {
            cursor: "pointer",
        },
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleDelete = async () => {
        await deleteAlternateSupplier(selectedRowKeys);
        notification.success({
            message: t("success"),
            description: t("supplierProfile_DeleteAlternateSupplierSuccess"),
        });
        setSelectedRowKeys([]);
        setReload({ changed: true });
    };
    const deleteDisabled = selectedRowKeys.length < 1;
    return (
        <Spin spinning={loading}>
            <Table
                pagination={false}
                bordered
                onRow={handleOnRow}
                locale={{ emptyText: <EmptyTable /> }}
                rowSelection={rowSelection}
                rowKey="id"
                title={() => (
                    <Fragment>
                        <MinimalButton
                            icon={<PlusCircle />}
                            type="default"
                            onClick={() => {
                                setAlternateSupplier({ new: true });
                                setModalVisible(true);
                            }}>
                            {t("supplierProfile_NewAlternativeSupplier")}
                        </MinimalButton>
                        <Popconfirm
                            title={t("supplierProfile_DeleteAlternativeSupplierWarning")}
                            onConfirm={() => handleDelete()}
                            onCancel={() => setSelectedRowKeys([])}
                            okText={t("generic_Confirm")}
                            cancelText={t("generic_Cancel")}>
                            <MinimalButton
                                icon={<Trash02 style={{ color: deleteDisabled ? "grey" : "" }} />}
                                type="default"
                                loading={removeLoading}
                                disabled={deleteDisabled}>
                                {t("generic_Delete")}{" "}
                                {!deleteDisabled && <span>({selectedRowKeys.length})</span>}
                            </MinimalButton>
                        </Popconfirm>
                    </Fragment>
                )}
                columns={[
                    {
                        title: t("supplierProfile_AlternativeSupplier"),
                        dataIndex: "name",
                        width: 300,
                    },
                    {
                        title: t("supplierProfile_AlternativeSupplierComment"),
                        dataIndex: "comment",
                        render: value =>
                            value ? (
                                <ShowMore
                                    text={value}
                                    characterLimit={400}
                                    lineLimit={10}
                                    showGuidText={false}
                                />
                            ) : null,
                    },
                ]}
                dataSource={data ? data : []}
            />
            <AlternateSupplierModal
                partnerId={partnerId}
                partnerName={partnerName}
                reloadPartners={reloadPartners}
                data={alternateSupplier}
                alternateSuppliers={data}
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                onReloadTable={() => setReload({ changed: true })}
            />
        </Spin>
    );
};

export { AlternateSupplier };
