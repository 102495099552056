import React from "react";
import "./heatmap.css";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
    xValue: number;
    xLabel?: string;
    yValue: number;
    yLabel?: string;
}

const HeatMap = ({ xValue = 0, xLabel = "X", yValue = 0, yLabel = "Y" }: IProps) => {
    const { t } = useTranslation();
    const renderColumns = () => {
        let elements: any[] = [];
        for (let i = 1; i <= 5; i++) {
            elements.push(
                <div
                    className={classNames(
                        "heatmapColumn",
                        `${i >= 10 ? i : "0" + i}-${i >= 10 ? i : "0" + i}`
                    )}>
                    {renderRows(i)}
                </div>
            );
        }
        return elements;
    };

    const renderRows = (column: number) => {
        let elements: any[] = [];
        for (let i = 1; i <= 5; i++) {
            elements.push(
                <div
                    style={{ backgroundColor: getCellColor(column, i) }}
                    className={classNames(
                        "column",
                        "cell",
                        setHighlightedCell(xValue, yValue, column, i) === true
                            ? "highlightedCell"
                            : undefined
                    )}></div>
            );
        }
        return elements;
    };

    const setHighlightedCell = (
        xValue: number,
        yValue: number,
        columnNumber: number,
        cellNumber: number
    ) => {
        if (xValue === null || xValue === undefined || yValue === null || yValue === undefined) {
            return false;
        }
        if (columnNumber === 1 && cellNumber === 5 && xValue <= 20 && yValue <= 20) {
            return true;
        }
        if (
            columnNumber === 2 &&
            cellNumber === 5 &&
            xValue >= 21 &&
            xValue <= 40 &&
            yValue <= 20
        ) {
            return true;
        }
        if (
            columnNumber === 3 &&
            cellNumber === 5 &&
            xValue >= 41 &&
            xValue <= 60 &&
            yValue <= 20
        ) {
            return true;
        }
        if (
            columnNumber === 4 &&
            cellNumber === 5 &&
            xValue >= 61 &&
            xValue <= 80 &&
            yValue <= 20
        ) {
            return true;
        }
        if (
            columnNumber === 5 &&
            cellNumber === 5 &&
            xValue >= 81 &&
            xValue <= 100 &&
            yValue <= 20
        ) {
            return true;
        }

        if (
            columnNumber === 1 &&
            cellNumber === 4 &&
            xValue <= 20 &&
            yValue >= 21 &&
            yValue <= 40
        ) {
            return true;
        }
        if (
            columnNumber === 2 &&
            cellNumber === 4 &&
            xValue >= 21 &&
            xValue <= 40 &&
            yValue >= 21 &&
            yValue <= 40
        ) {
            return true;
        }
        if (
            columnNumber === 3 &&
            cellNumber === 4 &&
            xValue >= 41 &&
            xValue <= 60 &&
            yValue >= 21 &&
            yValue <= 40
        ) {
            return true;
        }
        if (
            columnNumber === 4 &&
            cellNumber === 4 &&
            xValue >= 61 &&
            xValue <= 80 &&
            yValue >= 21 &&
            yValue <= 40
        ) {
            return true;
        }
        if (
            columnNumber === 5 &&
            cellNumber === 4 &&
            xValue >= 81 &&
            xValue <= 100 &&
            yValue >= 21 &&
            yValue <= 40
        ) {
            return true;
        }

        if (
            columnNumber === 1 &&
            cellNumber === 3 &&
            xValue <= 20 &&
            yValue >= 41 &&
            yValue <= 60
        ) {
            return true;
        }
        if (
            columnNumber === 2 &&
            cellNumber === 3 &&
            xValue >= 21 &&
            xValue <= 40 &&
            yValue >= 41 &&
            yValue <= 60
        ) {
            return true;
        }
        if (
            columnNumber === 3 &&
            cellNumber === 3 &&
            xValue >= 41 &&
            xValue <= 60 &&
            yValue >= 41 &&
            yValue <= 60
        ) {
            return true;
        }

        if (
            columnNumber === 4 &&
            cellNumber === 3 &&
            xValue >= 61 &&
            xValue <= 80 &&
            yValue >= 41 &&
            yValue <= 60
        ) {
            return true;
        }
        if (
            columnNumber === 5 &&
            cellNumber === 3 &&
            xValue >= 81 &&
            xValue <= 100 &&
            yValue >= 41 &&
            yValue <= 60
        ) {
            return true;
        }

        if (
            columnNumber === 1 &&
            cellNumber === 2 &&
            xValue <= 20 &&
            yValue >= 61 &&
            yValue <= 80
        ) {
            return true;
        }
        if (
            columnNumber === 2 &&
            cellNumber === 2 &&
            xValue >= 21 &&
            xValue <= 40 &&
            yValue >= 61 &&
            yValue <= 80
        ) {
            return true;
        }

        if (
            columnNumber === 3 &&
            cellNumber === 2 &&
            xValue >= 41 &&
            xValue <= 60 &&
            yValue >= 61 &&
            yValue <= 80
        ) {
            return true;
        }
        if (
            columnNumber === 4 &&
            cellNumber === 2 &&
            xValue >= 61 &&
            xValue <= 80 &&
            yValue >= 61 &&
            yValue <= 80
        ) {
            return true;
        }
        if (
            columnNumber === 5 &&
            cellNumber === 2 &&
            xValue >= 81 &&
            xValue <= 100 &&
            yValue >= 61 &&
            yValue <= 80
        ) {
            return true;
        }

        if (
            columnNumber === 1 &&
            cellNumber === 1 &&
            xValue <= 20 &&
            yValue >= 81 &&
            yValue <= 100
        ) {
            return true;
        }
        if (
            columnNumber === 2 &&
            cellNumber === 1 &&
            xValue >= 21 &&
            xValue <= 40 &&
            yValue >= 81 &&
            yValue <= 100
        ) {
            return true;
        }
        if (
            columnNumber === 3 &&
            cellNumber === 1 &&
            xValue >= 41 &&
            xValue <= 60 &&
            yValue >= 81 &&
            yValue <= 100
        ) {
            return true;
        }
        if (
            columnNumber === 4 &&
            cellNumber === 1 &&
            xValue >= 61 &&
            xValue <= 80 &&
            yValue >= 81 &&
            yValue <= 100
        ) {
            return true;
        }
        if (
            columnNumber === 5 &&
            cellNumber === 1 &&
            xValue >= 81 &&
            xValue <= 100 &&
            yValue >= 81 &&
            yValue <= 100
        ) {
            return true;
        }
    };

    const renderAxis = (reverse: boolean = false, scale: "percentage" | "lowToHigh") => {
        let elements: any[] = [];
        if (scale === "percentage") {
            for (let i = 0; i <= 5; i++) {
                elements.push(<div>{(i === 0 || i === 5) && `${i * 2 * 10}%`}</div>);
            }
            if (reverse) {
                elements = elements.reverse();
            }
            return elements;
        } else {
            for (let i = 0; i <= 5; i++) {
                var text = "";
                if (i === 0) {
                    text = t("generic_VeryLow");
                } else if (i === 5) {
                    text = t("generic_VeryHigh");
                }
                elements.push(<div style={{ marginTop: i === 5 ? -6 : -22 }}>{text}</div>);
            }
            if (reverse) {
                elements = elements.reverse();
            }
            return elements;
        }
    };

    const getCellColor = (x: number, y: number) => {
        //this is a "reversed" version where the Y values are "swapped"
        if ((x <= 3 && y <= 1) || (x === 1 && y === 2)) {
            return "#ff7875";
        } else if (
            (x <= 2 && y === 3) ||
            ((x === 3 || x === 2) && y <= 2) ||
            (x === 4 && y === 1)
        ) {
            return "#ffc069";
        } else if (
            (x === 5 && y === 1) ||
            ((x === 5 || x === 4) && y === 2) ||
            (x === 3 && y === 3) ||
            ((x === 1 || x === 2) && y === 4) ||
            (x === 1 && y === 5)
        ) {
            return "#ffec3d";
        } else {
            return "#95de64";
        }
    };

    return (
        <div>
            {(yValue === null ||
                yValue === undefined ||
                xValue === null ||
                xValue === undefined) && (
                <div className="noValue">{t("generic_NoScoreAvailable")}</div>
            )}
            <div className="yAxisLabel">{yLabel}</div>
            <div className="heatmapContainer">
                <div className="yAxis">{renderAxis(true, "lowToHigh")}</div>
                <>{renderColumns()}</>
                <div className="xAxis">{renderAxis(false, "percentage")}</div>
            </div>
            <div className="xAxisLabel">{xLabel}</div>
        </div>
    );
};

export { HeatMap };
