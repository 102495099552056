import React, { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Button, Calendar } from "antd";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import classes from "./styles/selectDeadline.module.scss";

type PropTypes = {
    visible: boolean;
    clearData: any;
    onNext: Function;
    onPrevious: Function;
};
const SelectSupplier: FunctionComponent<PropTypes> = ({
    visible,
    clearData,
    onNext,
    onPrevious,
}) => {
    const { t } = useTranslation();
    const [endDate, setEndDate] = useState<Dayjs | undefined>(undefined);

    useEffect(() => {
        setEndDate(undefined);
    }, [clearData]);

    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return (
        <Row style={{ display: visible ? "block" : "none" }}>
            <Col span={24}>
                <Row style={{ minHeight: `${height - 350}px` }}>
                    <Col span={24}>
                        <Row gutter={32} align="middle">
                            <Col lg={7} md={12}>
                                <Calendar
                                    fullscreen={false}
                                    defaultValue={undefined}
                                    disabledDate={(current: any) => {
                                        return current && current <= dayjs().endOf("day");
                                    }}
                                    onChange={(date: Dayjs) => setEndDate(dayjs(date).endOf("day"))}
                                    value={endDate}
                                    className={classes.surveyWizardCalendar}
                                />
                                <div style={{ textAlign: "center" }}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => setEndDate(undefined)}>
                                        {t("generic_Clear")}
                                    </Button>
                                </div>
                            </Col>
                            <Col lg={6} md={12}>
                                {endDate && (
                                    <>
                                        <div style={{ textAlign: "center" }}>
                                            <b>{t("survey_Wizard_EndDate1")}</b>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <b>
                                                {endDate ? endDate.format("D MMM YYYY") : "Never"}
                                            </b>
                                        </div>
                                    </>
                                )}
                                <div style={{ textAlign: "center", marginTop: 12 }}>
                                    <b>{t("survey_Wizard_EndDate")}</b>
                                </div>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#1890ff",
                                        border: "1px solid #ccc",
                                        borderRadius: "2px",
                                    }}>
                                    <b>
                                        {endDate
                                            ? endDate.format("D MMM YYYY HH:mm") + " (UTC)"
                                            : "Never"}
                                    </b>
                                </div>
                                {endDate && (
                                    <div style={{ textAlign: "center", marginTop: 12 }}>
                                        <b>{t("survey_Wizard_EndDate2")}</b>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <Button type="primary" size="large" onClick={() => onPrevious()}>
                            {t("generic_Previous")}
                        </Button>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Button type="primary" size="large" onClick={() => onNext(endDate)}>
                            {t("generic_Next")}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SelectSupplier;
