import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any;
    loading: boolean;
};

const useDataSpecificationData = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Analytics.getDataSpecificationData({
                customError: {
                    message: "Could not retrive data for data specification page",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDataSpecificationData };
