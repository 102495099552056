import React, { Fragment, FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Button, Modal, Popconfirm, Tooltip, Typography, Badge, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { getColumnSelectProps, Grid, MessageDotsCircle } from "components/utilitycomponents";
import {
    useSurveyResults,
    useSurveyReviewCriteria,
    usePartnerList,
    useCompanyType,
    useSurveyFilterContacts,
    useSegmentationSendReminder,
} from "controller";
import { ResponseDetails } from "../responseDetails/reponseDetails";
import { ResponseDetailsByCriteria } from "../responseDetails/reponseDetailsByCriterias";
import classes from "./surveyResults.module.scss";
import { SurveySelector } from "./surveySelector";
import { SurveyStats } from "./surveyEndDate";
import { ResponseDetailsByGroup } from "../responseDetails/responseDetailsByGroup";
import { useParams } from "react-router";

interface IProps {
    surveyId?: number;
    segmentationAnalysisId?: number;
    performanceUnit?: { id: number; type: "internalLevel" | "externalLevel" };
    visible: boolean;
    onClose: Function;
    gridTitle?: ReactNode;
    type: "generic" | "segmentation" | "performance";
}

interface ISurveyCustomFields {
    endDate: string;
    isExpired: boolean;
    currentResponseCount: number;
    maxResponseCount: number;
    completionAverage: number;
}

export const SurveyResults: FunctionComponent<IProps> = ({
    surveyId = -1,
    segmentationAnalysisId = -1,
    performanceUnit = { id: -1 },
    visible,
    onClose,
    gridTitle,
    type,
}) => {
    const { t } = useTranslation();
    const { id: partnerId }: any = useParams();
    const [selectedSurveyId, setSelectedSurveyId] = useState<number>(surveyId);
    const { data: surveyCriteria, loading: loadingCriteria } = useSurveyReviewCriteria(
        selectedSurveyId,
        -1
    );
    const { isDefaultCompany } = useCompanyType();
    const [responseDetailsModal, setResponseDetailsModal] = useState<any>({
        visible: false,
        supplierId: undefined,
        contactId: undefined,
        contactName: undefined,
        supplierName: undefined,
    });
    const { SendReminder, loading: sendingSegmentationReminder } = useSegmentationSendReminder();
    const [responseDetailsByCriteriaModal, setResponseDetailsByCriteriaModal] = useState({
        visible: false,
        criteriaId: undefined,
        criteriaName: undefined,
    });
    const [responseDetailsByGroupModal, setResponseDetailsByGroupModal] = useState({
        visible: false,
        criteriaGroupId: undefined,
        criteriaGroupName: undefined,
    });
    const [stats, setSurveyEndDate] = useState<ISurveyCustomFields | undefined>(undefined);

    useEffect(() => {
        setSelectedSurveyId(surveyId);
    }, [surveyId]);

    useEffect(() => {
        if (visible === false) {
            setSelectedSurveyId(-1);
        }
    }, [visible]);

    useEffect(() => {
        let newCols = columns.slice(0);
        newCols = newCols.splice(0, 1, {
            title: t("generic_UserType_Normal"),
            dataIndex: "contactName",
            viewColumnId: "contactName",
            showSorterTooltip: false,
            fixed: true,
            key: "user",
            ellipsis: true,
            width: 150,
            sorter: true,
            filterType: "array",
            ...getColumnSelectProps(
                useSurveyFilterContacts.bind(null, selectedSurveyId),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "contactName" }
            ),
        });
        setColumns(newCols);
    }, [selectedSurveyId]);

    let initColumns = [
        {
            title: t("generic_UserType_Normal"),
            dataIndex: "contactName",
            viewColumnId: "contactName",
            showSorterTooltip: false,
            fixed: true,
            key: "user",
            ellipsis: true,
            width: 150,
            sorter: true,
            filterType: "array",
            ...getColumnSelectProps(
                useSurveyFilterContacts.bind(null, selectedSurveyId),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "contactName" }
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (!loadingCriteria) {
            let addedColumns: any[] = [];

            if (type !== "segmentation" && type !== "performance") {
                addedColumns.push({
                    title: isDefaultCompany ? t("topbar_Suppliers") : t("topbar_Customers"),
                    dataIndex: "partnerName",
                    viewColumnId: "partnerName",
                    sorter: true,
                    showSorterTooltip: false,
                    fixed: true,
                    key: "supplier",
                    ellipsis: true,
                    width: 150,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerList,
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "partnerName", includeChildren: true }
                    ),
                    render: (val: string) => (
                        <div>{val !== "survey_NoResponse" ? val : t(val)}</div>
                    ),
                });
            }

            if (type === "segmentation") {
                addedColumns.push({
                    title: t("segmentation_SuggestSegment"),
                    dataIndex: "suggestedSegmentName",
                    key: "suggestedSegment",
                    viewColumnId: "suggestedSegment",
                    width: 200,
                    render: (val: string, record: any) =>
                        record.suggestedSegmentId ? (
                            <div
                                style={{
                                    border: `1px solid ${record.suggestedSegmentColor}`,
                                    color: record.suggestedSegmentColor,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}>
                                {record.suggestedSegmentName}
                            </div>
                        ) : (
                            record.suggestedSegmentName === "survey_NoResponse" && (
                                <div>{t(record.suggestedSegmentName)}</div>
                            )
                        ),
                });
            }

            if (type === "performance") {
                addedColumns.push({
                    title: t("supplierProfile_Performance_TotalScore"),
                    dataIndex: "totalPerformanceScore",
                    key: "totalPerformanceScore",
                    viewColumnId: "totalPerformanceScore",
                    fixed: true,
                    align: "center",
                    width: 150,
                    render: (val: number) => (
                        <Tooltip title={`${val} ${t("survey_RatingAverage")}`}>
                            <div
                                style={{
                                    fontSize: 18,
                                    textAlign: "center",
                                    color: "#8c8c8c",
                                    fontWeight: "bold",
                                }}>
                                {val}
                            </div>
                        </Tooltip>
                    ),
                });
            }

            surveyCriteria.forEach((x: any, index: number) => {
                if (x.children !== null) {
                    addedColumns.push({
                        title: ColumnTitle(`${x.name} ${t("generic_Average")}`, 200),
                        showSorterTooltip: false,
                        viewColumnId: `groupAverage${x.id}`,
                        width: 200,
                        key: `GroupAvg`,
                        customColumn: true,
                        customColumnName: "GroupAvg",
                        customColumnId: x.id,
                        onHeaderCell: (column: any) => {
                            if (type === "performance") {
                                return {
                                    onClick: (e: any) =>
                                        setResponseDetailsByGroupModal({
                                            criteriaGroupId: x.id,
                                            criteriaGroupName: x.name,
                                            visible: true,
                                        }),
                                };
                            } else {
                                return { ...column };
                            }
                        },
                        render: (val: any, row: any) => {
                            let reviewData = row.reviewCriteriaData.filter(
                                (c: any) => c.groupId === x.id
                            )[0];

                            return (
                                <>
                                    {(type === "generic" || type === "segmentation") && (
                                        <div
                                            key={`gAvg${val + index}`}
                                            style={{
                                                color: reviewData
                                                    ? colorGrade(reviewData.averageRating)
                                                    : "#000",
                                                fontSize: 18,
                                                textAlign: "center",
                                            }}>
                                            {reviewData && reviewData.averageRating
                                                ? reviewData.averageRating.toFixed(2)
                                                : ""}
                                        </div>
                                    )}
                                    {type === "performance" && (
                                        <div
                                            key={`gAvg${val + index}`}
                                            style={{
                                                color: row.reviewCriteriaData.some(
                                                    (c: any) => c.groupId === x.id
                                                )
                                                    ? colorGrade(
                                                          row.reviewCriteriaData.filter(
                                                              (c: any) => c.groupId === x.id
                                                          )[0].averageRating
                                                      )
                                                    : "#000",
                                                fontSize: 18,
                                                textAlign: "center",
                                            }}>
                                            {reviewData ? reviewData.averageRating.toFixed(2) : ""}
                                        </div>
                                    )}
                                </>
                            );
                        },
                    });
                }
            });

            type !== "performance" &&
                surveyCriteria.forEach((x: any, index: number) => {
                    if (x.children !== null && x.children.length > 0) {
                        addedColumns.push({
                            title: x.name,
                            viewColumnId: `group${x.id}`,
                            showSorterTooltip: false,
                            key: `group${x.id}`,
                            width: x.children.length * 150,
                            children: x.children.map((c: any, index: number) => {
                                return {
                                    title: ColumnTitle(
                                        c.name.indexOf("ReviewCategory_").length !== -1
                                            ? t(c.name)
                                            : c.name,
                                        150,
                                        true
                                    ),
                                    onHeaderCell: () => {
                                        return {
                                            onClick: () => {
                                                setResponseDetailsByCriteriaModal({
                                                    visible: true,
                                                    criteriaId: c.id,
                                                    criteriaName:
                                                        c.name.indexOf("ReviewCategory_").length !==
                                                        -1
                                                            ? t(c.name)
                                                            : c.name,
                                                });
                                            },
                                        };
                                    },
                                    showSorterTooltip: false,
                                    dataIndex: ["reviewCriteriaData", index, "averageRating"],
                                    key: `criteria${c.id}index${index}`,
                                    render: (val: number, record: any) => (
                                        <div
                                            key={val + index}
                                            style={{
                                                color: colorGrade(
                                                    record.reviewCriteriaData.some(
                                                        (x: any) =>
                                                            x.criteriaId === c.id && x.averageRating
                                                    ) &&
                                                        record.reviewCriteriaData
                                                            .filter(
                                                                (x: any) => x.criteriaId === c.id
                                                            )[0]
                                                            .averageRating.toFixed(2)
                                                ),
                                                fontSize: 18,
                                                textAlign: "center",
                                            }}>
                                            {record.reviewCriteriaData.filter(
                                                (x: any) => x.criteriaId === c.id && x.averageRating
                                            ).length > 0
                                                ? record.reviewCriteriaData
                                                      .filter((x: any) => x.criteriaId === c.id)[0]
                                                      .averageRating.toFixed(2)
                                                : null}
                                        </div>
                                    ),
                                };
                            }),
                        });
                    } else if (x.children === null) {
                        addedColumns.push({
                            title: ColumnTitle(
                                x.name.indexOf("ReviewCategory_").length !== -1
                                    ? t(x.name)
                                    : x.name,
                                150,
                                true
                            ),
                            onHeaderCell: () => ({
                                onClick: () => {
                                    setResponseDetailsByCriteriaModal({
                                        visible: true,
                                        criteriaId: x.id,
                                        criteriaName:
                                            x.name.indexOf("ReviewCategory_").length !== -1
                                                ? t(x.name)
                                                : x.name,
                                    });
                                },
                            }),
                            showSorterTooltip: false,
                            dataIndex: ["reviewCriteriaData", index, "averageRating"],
                            key: `criteria${x.id}index${Math.random()}`,
                            width: 150,
                            viewColumnId: x.name,
                            sorter: false,
                            render: (val: number, record: any) => (
                                <div
                                    key={val + index}
                                    style={{
                                        color: colorGrade(
                                            record.reviewCriteriaData.some(
                                                (t: any) => t.criteriaId === x.id
                                            ) &&
                                                record.reviewCriteriaData
                                                    .filter((t: any) => t.criteriaId === x.id)[0]
                                                    .averageRating.toFixed(2)
                                        ),
                                        fontSize: 18,
                                        textAlign: "center",
                                    }}>
                                    {record.reviewCriteriaData.some(
                                        (t: any) => t.criteriaId === x.id
                                    )
                                        ? record.reviewCriteriaData
                                              .filter((t: any) => t.criteriaId === x.id)[0]
                                              .averageRating.toFixed(2)
                                        : null}
                                </div>
                            ),
                        });
                    }
                });

            addedColumns.push({
                title: "Completion Score",
                dataIndex: "completionScore",
                viewColumnId: "completionScore",
                align: "center",
                width: 125,
                render: (val: number) => <div className={classes.completionScore}>{`${val}%`}</div>,
            });
            addedColumns.push({
                title: "",
                dataIndex: "",
                viewColumnId: "actionCol",
                fixed: "right",
                width: 75,
                render: (val: any, record: any) => (
                    <div style={{ textAlign: "right", padding: "0 10px 0 0" }}>
                        <Badge dot={record.hasComments}>
                            <Tooltip title={t("survey_SurveyResults_ViewDetails")}>
                                <span>
                                    <MessageDotsCircle
                                        onClick={() =>
                                            setResponseDetailsModal({
                                                visible: true,
                                                supplierId: record.partnerId,
                                                contactId: record.contactId,
                                                contactName: record.contactName,
                                                supplierName: record.partnerName,
                                            })
                                        }
                                        style={{ fontSize: 20 }}
                                    />
                                </span>
                            </Tooltip>
                        </Badge>
                    </div>
                ),
            });
            setColumns(initColumns.concat(addedColumns));
        }
    }, [surveyCriteria]);

    const handleSurveyStats = (customFields: ISurveyCustomFields) => {
        setSurveyEndDate(customFields);
    };

    return (
        <Fragment>
            <Modal
                title={
                    gridTitle ? (
                        <Row>
                            <Col span={10}>{gridTitle}</Col>
                            <Col span={8}>
                                <SurveyStats stats={stats} />
                            </Col>
                            <Col span={6} style={{ backgroundColor: "#FFF" }}>
                                <SurveySelector
                                    segmentationUnitId={segmentationAnalysisId}
                                    performanceUnit={performanceUnit}
                                    onSurveySelect={(surveyId: number) =>
                                        setSelectedSurveyId(surveyId)
                                    }
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={24}>
                                <h3 style={{ position: "absolute" }}>
                                    {t("survey_SurveyResults")}
                                </h3>
                                <SurveyStats stats={stats} />
                            </Col>
                        </Row>
                    )
                }
                width={1700}
                open={visible}
                onCancel={() => onClose()}
                onOk={() => onClose()}
                destroyOnClose
                footer={[
                    segmentationAnalysisId !== -1 && selectedSurveyId !== null && (
                        <Popconfirm
                            title={t("survey_ReminderBtnInfo")}
                            okText={t("generic_Confirm")}
                            cancelText={t("generic_Cancel")}
                            onConfirm={async e => {
                                await SendReminder(segmentationAnalysisId);
                            }}>
                            <Button type="primary" loading={sendingSegmentationReminder}>
                                {t("survey_ReminderBtn")}
                            </Button>
                        </Popconfirm>
                    ),
                ]}>
                <Grid
                    onHeaderCell={() => ({
                        style: {
                            color: "#0477A9",
                            fontWeight: "bold",
                        },
                    })}
                    tableType={"SurveyResults"}
                    useData={useSurveyResults.bind(
                        null,
                        selectedSurveyId,
                        segmentationAnalysisId,
                        performanceUnit && performanceUnit.id,
                        partnerId
                    )}
                    onDataCustomFields={handleSurveyStats}
                    columns={columns}
                    hideGridSetting
                    showEmptyText
                    saveColumnSettings={false}
                    pageContainerWidth={1700}
                />
            </Modal>
            <ResponseDetails
                visible={responseDetailsModal.visible}
                surveyId={selectedSurveyId}
                segmentationAnalysisId={segmentationAnalysisId}
                performanceAnalysisId={performanceUnit.id}
                supplierId={responseDetailsModal.supplierId}
                contactId={responseDetailsModal.contactId}
                contactName={responseDetailsModal.contactName}
                supplierName={
                    responseDetailsModal.supplierName === "survey_NoResponse"
                        ? ""
                        : responseDetailsModal.supplierName
                }
                onClose={() =>
                    setResponseDetailsModal({
                        visible: false,
                        surveyId: undefined,
                        segmentationAnalysisId: undefined,
                        performanceAnalysisId: undefined,
                        supplierId: undefined,
                        contactId: undefined,
                        contactName: undefined,
                        supplierName: undefined,
                    })
                }
            />
            <ResponseDetailsByCriteria
                visible={responseDetailsByCriteriaModal.visible}
                surveyId={selectedSurveyId}
                segmentationAnalysisId={segmentationAnalysisId}
                criteriaId={responseDetailsByCriteriaModal.criteriaId}
                criteriaName={responseDetailsByCriteriaModal.criteriaName}
                onClose={() =>
                    setResponseDetailsByCriteriaModal({
                        visible: false,
                        criteriaId: undefined,
                        criteriaName: undefined,
                    })
                }
            />
            <ResponseDetailsByGroup
                visible={responseDetailsByGroupModal.visible}
                criteriaGroupId={responseDetailsByGroupModal.criteriaGroupId}
                groupName={responseDetailsByGroupModal.criteriaGroupName}
                surveyId={selectedSurveyId}
                onClose={() =>
                    setResponseDetailsByGroupModal({
                        visible: false,
                        criteriaGroupId: undefined,
                        criteriaGroupName: undefined,
                    })
                }
            />
        </Fragment>
    );
};

export default SurveyResults;

const ColumnTitle = (title: string, width: number, isQuestion: boolean = false) => {
    return (
        <Tooltip
            key={title + width}
            title={
                isQuestion ? (
                    <>
                        <div>{title}</div> Click to view details
                    </>
                ) : (
                    title
                )
            }>
            <Typography.Text
                ellipsis
                style={{ width: width, textAlign: "center", color: "#0477A9", fontWeight: "bold" }}>
                {title}
            </Typography.Text>
        </Tooltip>
    );
};

export const colorGrade = (val: any) => {
    if (val === null || val === -1) {
        return "";
    }
    if (typeof val === "boolean") {
        return "";
    }
    if (typeof val === "string") {
        val = parseFloat(val);
    }
    if (val >= 5) {
        return "#389e0d";
    }
    if (val >= 4 && val < 5) {
        return "#52c41a";
    }
    if (val >= 3 && val < 4) {
        return "#faad14";
    }
    if (val >= 2 && val < 3) {
        return "#fa8c16";
    }
    if (val < 2) {
        return "#f5222d";
    }
};
