import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { Row } from "antd";
import "./formRow.scss";

const FormRow: FunctionComponent<{ align?: any; classNames?: any[]; [key: string]: any }> = ({
    children,
    align = "middle",
    classNames = [],
    gutter = 8,
    ...rest
}) => (
    <Row
        //type="flex"
        align={align}
        gutter={gutter}
        {...rest}
        className={classnames("formRow", ...classNames)}>
        {children}
    </Row>
);

export { FormRow };
