import { IActivityChild } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IActivityChild[] | null;
};

const useActivityActionByActionId = (actionId: number | undefined, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        if (actionId !== undefined) {
            (async () => {
                setState({ ...state, loading: true });

                let data;

                data = await Provider.Action.getActionChildrenActivityById(actionId, {
                    customError: {
                        message: "Could not retrieve action data",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ ...state, loading: false, data });
            })();
        }
    }, [actionId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useActivityActionByActionId };
