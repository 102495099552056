import { post, get, remove, patch, put } from "../base";

const GovernanceTasks = {
    getGovernanceTasks: async (segmentId?: number, { customError }: any = {}) => {
        if (segmentId) {
            const url = `/v1/Governance/Tasks?segmentId=${segmentId}`;
            return await get({ url, customError });
        } else {
            const url = "/v1/Governance/Tasks";
            return await get({ url, customError });
        }
    },
    getSupplierGovernanceTasks: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Governance/SupplierTasks?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    createGovernanceTask: async (input: any, { customError }: any = {}) => {
        const url = `/v1/Governance/Task`;
        return await post({ url, payload: input, customError });
    },
    updateGovernanceTask: async (input: any, id: number, { customError }: any = {}) => {
        const url = `/v1/Governance/Task?id=${id}`;
        return await patch({ url, payload: input, customError });
    },
    setGovernanceTaskStatus: async (input: any, { customError }: any = {}) => {
        const url = `/v1/Governance/Task/Status`;
        return await put({ url, payload: input, customError });
    },
    deleteGovernanceTasks: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/Governance/Tasks`;
        return await remove({ url, payload: ids, customError });
    },
    isTitleInUse: async (title: string, segmentId: number, { customError }: any = {}) => {
        const url = `/v1/Governance/Task?title=${encodeURIComponent(title)}&segmentId=${segmentId}`;
        return await get({ url, customError });
    },
};

export { GovernanceTasks };
