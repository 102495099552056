import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Flex } from "antd";
import AcceptInvitationModal from "components/contentcomponents/suppliers/partnerOverviewModals/acceptInvitationModal";
import { PageContainer } from "components/utilitycomponents";
import { useSelector } from "react-redux";
import { AppState } from "store";
import classes from "./supplierLandingPage.module.scss";
import { NcrCount } from "./ncrCount";
import { SurveyCount } from "./surveyCount";
import { ActionCount } from "./actionCount";
import { ReviewMetric } from "./reviewMetric";
import { LandingPageTitle } from "./landingPageTitle";
import { SelfAssessmentCount } from "./selfAsssementCount";

const SupplierLandingPage = () => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const { token }: any = useParams();
    const history = useHistory();
    const [showAcceptInvitationModal, setShowAcceptInvitationModal] = useState<any>(false);
    const companyComplianceAccess = user.profile["ll-enabledCompanyModule-Compliance"] === "true";
    const companyNcrAccess = user.profile["ll-enabledCompanyModule-Ncr"] === "true";
    const companyReviewAccess = user.profile["ll-enabledCompanyModule-Review"] === "true";
    const companyActionAccess = user.profile["ll-enabledCompanyModule-Action"] === "true";

    useEffect(() => {
        if (token) {
            setShowAcceptInvitationModal(true);
        }
    }, [token]);

    return (
        <>
            <Flex justify="space-evenly">
                <PageContainer fluid className={classes.landingPageComponent}>
                    <LandingPageTitle />
                </PageContainer>
            </Flex>
            <Flex wrap="wrap" justify="space-evenly">
                {companyComplianceAccess && (
                    <PageContainer fluid className={classes.landingPageMetricComponent}>
                        <SelfAssessmentCount />
                    </PageContainer>
                )}
                {companyNcrAccess && (
                    <PageContainer fluid className={classes.landingPageMetricComponent}>
                        <NcrCount />
                    </PageContainer>
                )}
                {companyReviewAccess && (
                    <PageContainer fluid className={classes.landingPageMetricComponent}>
                        <SurveyCount />
                    </PageContainer>
                )}
                {companyActionAccess && (
                    <PageContainer fluid className={classes.landingPageMetricComponent}>
                        <ActionCount />
                    </PageContainer>
                )}
            </Flex>

            {companyReviewAccess && (
                <Flex justify="center">
                    <PageContainer fluid className={classes.landingPageComponent}>
                        <ReviewMetric />
                    </PageContainer>
                </Flex>
            )}

            <AcceptInvitationModal
                visible={showAcceptInvitationModal}
                onClose={() => {
                    setShowAcceptInvitationModal(false);
                    history.push("/");
                }}
            />
        </>
    );
};

export default SupplierLandingPage;
