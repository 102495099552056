import { get, post, remove, put, patch } from "../base";
import { categoryType } from "models/suppliers/ICategory";
import { IPartner, ITableInput } from "models";
import { IExternalPartnerCreation } from "models/suppliers/IExternalPartnerCreation";
import Config from "../../../config";

const Partners = {
    getGroups: async (
        sortByName: boolean,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/PartnerGroups?sortByName=${sortByName}`;
        let response = await get({
            url,
            retryCount: retryCount,
            customError: customError,
        });
        return response;
    },
    createGroup: async (
        payload: { name: string },
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/PartnerGroups`;
        let response = await post({
            url,
            payload: payload,
            retryCount: retryCount,
            customError: customError,
        });
        return response;
    },
    deleteGroups: async (groupIds: number[], { customError }: any = {}) => {
        const url = `/v1/PartnerGroups`;
        return await remove({
            url,
            payload: groupIds,
            customError,
        });
    },
    updateGroupName: async (
        payload: { groupId: string; newName: string },
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/PartnerGroups/${payload.groupId}`;
        await put({
            url,
            payload: { name: payload.newName },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
    },
    createCategory: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/PartnerCategories`;
        return await post({
            url,
            payload: payload,
            customError,
        });
    },
    deleteCategories: async (categoryIds: number[], { customError }: any = {}) => {
        const url = `/v1/PartnerCategories`;
        return await remove({
            url,
            payload: categoryIds,
            customError,
        });
    },
    updateCategory: async (
        payload: {
            categoryId: string;
            newName: string;
            type: categoryType;
        },
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/PartnerCategories/${payload.categoryId}`;
        await put({
            url,
            payload: {
                name: payload.newName,
                categoryType:
                    payload.type === categoryType.one ? "FirstCategory" : "SecondCategory",
            },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
    },
    getExternalPartners: async (
        withEmail?: boolean,
        withIsLinked?: boolean,
        withContacts?: boolean
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        if (withEmail !== undefined && withIsLinked !== undefined && withContacts !== undefined) {
            const url = `/v1/Partners/GetExternalPartners?withEmail=${withEmail}&withIsLinked=${withIsLinked}&withContacts=${withContacts}`;
            return await get({ url, customConfig });
        }
        if (withEmail !== undefined && withIsLinked !== undefined) {
            const url = `/v1/Partners/GetExternalPartners?withEmail=${withEmail}&withIsLinked=${withIsLinked}`;
            return await get({ url, customConfig });
        }
        if (withEmail !== undefined) {
            const url = `/v1/Partners/GetExternalPartners?withEmail=${withEmail}`;
            return await get({ url, customConfig });
        }
        if (withIsLinked !== undefined) {
            const url = `/v1/Partners/GetExternalPartners?withIsLinked=${withIsLinked}`;
            return await get({ url, customConfig });
        } else {
            const url = `/v1/Partners/GetExternalPartners`;
            return await get({ url, customConfig });
        }
    },
    getPartnerList: async ({ customError }: any = {}) => {
        const url = `/v1/Partners/GetPartnerList`;
        return await get({ url, customError });
    },
    getPartners: async () => {
        const url = `/v1/Partners`;
        return await get({ url });
    },
    createPartner: async (partner: IPartner, { customError }: any = {}) => {
        const url = `/v1/Partners`;
        return await post({ url, payload: partner, customError: customError });
    },
    createExternalPartner: async (
        partners: IExternalPartnerCreation,
        { customError }: any = {}
    ) => {
        const url = `/v1/Partners/CreateExternalPartner`;
        return await post({ url, payload: partners, customError: customError });
    },
    IsCompanyNumberInUse: async (companyNumber: string, { customError }: any = {}) => {
        const url = `/v1/Partners/IsCompanyNumberInUse?companyNumber=${encodeURIComponent(
            companyNumber
        )}`;
        return await get({ url, customError });
    },
    getAutoGenerateCompanyNumbers: async () => {
        const url = `/v1/Company/Globalsettings`;
        let response = await get({ url });
        return response.autoGenerateCompanyNumbers;
    },
    getPartnerCategories: async (categoryType: string = "all", sortByName: boolean = true) => {
        const params =
            categoryType === "all"
                ? `sortByName=${sortByName}`
                : `type=${categoryType}&sortByName=${sortByName}`;

        const url = `/v1/PartnerCategories?${params}`;
        return await get({ url });
    },
    getPartnerCategoriesByPartnerId: async (
        partnerId: number,
        type?: "FirstCategory" | "SecondCategory" | undefined,
        { customError }: any = {}
    ) => {
        let url = `/v1/PartnerCategories/GetPartnerCategoriesByPartnerId?partnerId=${partnerId}`;
        if (type !== undefined) {
            url = url + `&type=${type}`;
        }
        return await get({ url, customError });
    },
    getPartnerGroups: async (sortByName: boolean = true) => {
        const url = `/v1/PartnerGroups?sortByName=${sortByName}`;
        return await get({ url });
    },
    getPartner: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Partners/${partnerId}`;
        return await get({ url, customError });
    },
    getPartnerMasterData: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Partners/Masterdata/${partnerId}`;
        return await get({ url, customError });
    },
    updatePartnerMasterData: async (
        partnerId: number,
        partnerDetail: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Partners/Masterdata/${partnerId}`;
        return await put({ url, payload: partnerDetail, customError });
    },
    updatePartnerOwners: async (
        partnerIds: number[],
        ownerIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/Owners`;
        return await patch({ url, payload: { ids: ownerIds, partnerIds }, customError });
    },
    masterDataOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Partners/MasterOverview";
        return await post({ url, payload: tableInput, customError });
    },
    partnerOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Partners/Overview";
        return await post({ url, payload: tableInput, customError });
    },
    getCustomColumns: async ({ customError }: any = {}) => {
        const url = "/v1/PartnerCustomColumns?sortByName=false";
        return await get({ url, customError });
    },
    updateCategoriesOnPartner: async (
        partnerIds: number[],
        categoryIds: number[],
        type: "FirstCategory" | "SecondCategory",
        { customError }: any = {}
    ) => {
        const url = `/Categories`;
        const customConfig = {
            ...Config.apiConfig,
            headers: {
                ...Config.apiConfig.headers,
                types: type,
            },
        };
        return await patch({
            url,
            payload: { ids: categoryIds, partnerIds },
            customConfig,
            customError,
        });
    },
    updateGroupOnPartner: async (
        partnerIds: number[],
        groupId: number,
        { customError }: any = {}
    ) => {
        const url = `/Group?GroupId=${groupId}`;
        return await patch({ url, payload: partnerIds, customError });
    },
    deletePartners: async (partnerIds: number[], { customError }: any = {}) => {
        const url = `/v1/Partners/DeletePartners`;
        return await remove({ url, payload: partnerIds, customError });
    },
    updateMergeSet: async (
        parentId: string,
        partnersToMergeId: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/Partners/${parentId}/Children`;
        return await patch({ url, payload: partnersToMergeId, customError });
    },
    unMerge: async (parentId: string, partnersToMergeId: number[], { customError }: any = {}) => {
        const url = `/v1/Partners/UnMerge/${parentId}/Children`;
        return await patch({ url, payload: partnersToMergeId, customError });
    },
    isValidMerge: async (
        parentId: string,
        partnersToMergeId: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/Partners/IsValidMerge/${parentId}/Children`;
        return await post({ url, payload: partnersToMergeId, customError });
    },
    unlinkPartner: async (partnerId: string) => {
        const url = `/v1/Partners/${partnerId}/Unlink`;
        return await patch({ url });
    },
    getPartnerStatusLog: async (partnerId: number) => {
        const url = `/v1/Partners/PartnerStatusLog?parentId=${partnerId}`;
        return await get({ url });
    },
    getPartnerChildren: async (partnerId: string) => {
        const url = `/v1/Partners/Children/${partnerId}`;
        return await get({ url });
    },
    getPartnerChildrenById: async (partnerId: string, { customError }: any = {}) => {
        const url = `/v1/Partner/${partnerId}/Children`;
        return await get({ url, customError });
    },
    getSupplierProfile: async (partnerId: string, { customError }: any = {}) => {
        const url = `/v1/SupplierProfile?supplierId=${partnerId}`;
        return await get({ url, customError });
    },
    getPartnerComment: async (partnerId: any, { customError }: any = {}) => {
        const url = `/v1/Partner/Comment?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    updatePartnerComment: async (activityId: any, comment: string, { customError }: any = {}) => {
        const url = `/v1/Partner/Comment?partnerId=${activityId}`;
        return await patch({ url, payload: { value: comment }, customError });
    },
    isOwner: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Partners/IsOwner?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    updatePartnerDomain: async (partnerId: number, domain?: string, { customError }: any = {}) => {
        const url = `/v1/Partners/UpdatePartnerDomain?partnerId=${partnerId}&domain=${domain &&
            encodeURI(domain)}`;
        return await patch({ url, payload: {}, customError });
    },
};

export default Partners;
