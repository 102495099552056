import React, { forwardRef, useEffect } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

type PropsType = {
    value?: string;
    onChange?: (value: string, item?: any) => void;
    selectedChartType: string;
    [key: string]: any;
};
//forwardRef is used for the component to be able to be used as a FormItem child direclty
const XAxisSelect = forwardRef(
    ({ value, onChange, selectedChartType, ...rest }: PropsType, ref) => {
        const { Option, OptGroup } = Select;
        const { t } = useTranslation();

        useEffect(() => {
            if (
                selectedChartType === "DataTable" &&
                value !== "SubLevel" &&
                value !== "AllSubLevels"
            ) {
                onChange && onChange("SubLevel");
            }
        }, [selectedChartType]);

        return (
            <Select
                value={value}
                onChange={(value: string) => onChange && onChange(value)}
                showSearch
                optionFilterProp="children"
                {...rest}>
                <OptGroup label={t("analytics_XAxisSelect_Intervals")}>
                    <Option key={1} value={"Monthly"} disabled={selectedChartType === "DataTable"}>
                        {t("analytics_XAxisSelect_Monthly")}
                    </Option>
                    <Option
                        key={2}
                        value={"Quarterly"}
                        disabled={selectedChartType === "DataTable"}>
                        {t("analytics_XAxisSelect_Quarterly")}
                    </Option>
                    <Option key={3} value={"Annually"} disabled={selectedChartType === "DataTable"}>
                        {t("analytics_XAxisSelect_Annually")}
                    </Option>
                </OptGroup>
                <OptGroup label={t("analytics_XAxisSelect_Other")}>
                    <Option key={4} value={"SubLevel"}>
                        {t("analytics_XAxisSelect_SubLevels")}
                    </Option>

                    <Option key={5} value={"AllSubLevels"}>
                        {t("analytics_XAxisSelect_AllSubLevels")}
                    </Option>
                </OptGroup>
            </Select>
        );
    }
);

export { XAxisSelect };
