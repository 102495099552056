import React, { useEffect, useState } from "react";
import { Dropdown, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";
import userManager from "userManager";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { EmailNotificationsModal } from "./emailNotificationsModal";
import { useAvatar } from "controller/contact/contactOverviews/useAvatart";
import { getColorFromInitials } from "utilities/helpers/getColorFromInitials";
import { UserImage } from "components/utilitycomponents";
const AccountDropDown = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const user = useSelector((state: AppState) => state.oidc.user);
    const { data } = useAvatar(user && user.profile["ll-ContactId"]);

    const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
            case "1":
                history.push("/User");
                break;
            case "2":
                setNotificationModalOpen(true);
                break;
            case "3":
                userManager.signoutRedirect({
                    id_token_hint: user.id_token,
                });
                userManager.removeUser();
                break;
            default:
                break;
        }
    };
    const items: MenuProps["items"] = [
        {
            label: t("account_Profile"),
            key: "1",
        },
        { label: t("account_EmailNotifications"), key: "2" },
        {
            label: t("topbar_Logout"),
            key: "3",
        },
    ];
    useEffect(() => {
        getColorFromInitials(data?.initials);
    });
    return (
        <>
            <Dropdown menu={{ items, onClick }} placement="bottom">
                <a onClick={e => e.preventDefault()}>
                    <Space>
                        <div style={{ marginTop: 12 }}>
                            <UserImage
                                fontSize={15}
                                customDimension={35}
                                alt={user && user.profile["ll-FullName"]}
                                contactId={user && user.profile["ll-ContactId"]}
                            />
                        </div>
                    </Space>
                </a>
            </Dropdown>
            {notificationModalOpen && (
                <EmailNotificationsModal
                    open={notificationModalOpen}
                    onClose={() => setNotificationModalOpen(false)}
                />
            )}
        </>
    );
};

export default AccountDropDown;
