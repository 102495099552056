import React, { useState, useEffect, Fragment } from "react";
import {
    Modal,
    Row,
    Col,
    Input,
    Select,
    InputNumber,
    notification,
    Divider,
    Tooltip,
    Form,
    Button,
} from "antd";
import {
    useNcrReason,
    useNcrCreate,
    useNcrCustomLabel,
    useCompanyType,
    useCustomDropDown,
    useDefaultContacts,
} from "controller";
import currencyCodes from "utilities/misc/currencies.json";
import classes from "./styles/createForm.module.scss";
import {
    SelectData,
    SelectExternalPartner,
    AttachFile,
    SelectPartnerContacts,
    MinusCircle,
    PlusCircle,
    InfoCircle,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { getCustomLabel } from "../../getCustomLabel";
import { getCustomLabelVisiblity } from "../../getCustomLabelVisibility";
import { NcrFieldVisibility } from "models";
import { NcrActionsInput } from "../sharedComponents/ncrActionsInput";
import { getNcrFieldCustomdropdownId } from "components/contentcomponents/ncr/getNcrFieldCustomdropdown";
const Option = Select.Option;
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    defaultPartnerId?: number;
    defaultComment?: string;
    reloadExternalPartner?: any;
    onVisibleChange: Function;
    reloadTable?: Function;
}

const CreationModal = ({
    visible,
    defaultPartnerId,
    defaultComment,
    reloadExternalPartner,
    onVisibleChange,
    reloadTable = () => {},
}: IProps) => {
    const [form] = Form.useForm();
    const [reloadNcrCustomLabel, setReloadNcrCustomLabel] = useState<any>();
    const { data: ncrFields } = useNcrCustomLabel(reloadNcrCustomLabel);
    const { loading, createNcr } = useNcrCreate();
    const [selectedPartner, setSelectedPartner] = useState<any>(undefined);
    const [selectedPartnerId, setSelectedPartnerId] = useState(-1);
    const [clearFileList, setClearFileList] = useState({ clear: false });
    const { t } = useTranslation();
    const [reloadNcrs, setReloadNcrs] = useState({ reload: false });
    const { isDefaultCompany } = useCompanyType();
    const { getFieldValue } = form;
    const { data: customDropdowns } = useCustomDropDown();
    const [ncrVisibilityFieldValue, setNcrVisibilityFieldValue] = useState<any>(2);
    const [ncrValue, setNcrValue] = useState<any>(undefined);
    const [otherCostValue, setOtherCostValue] = useState<any>(undefined);
    const { data: defaultContactReceivers } = useDefaultContacts(
        selectedPartner && selectedPartner.id,
        "NcrContact"
    );

    useEffect(() => {
        if (visible === true) {
            setClearFileList({ clear: true });
            setReloadNcrs({ reload: true });
            setReloadNcrCustomLabel({ reload: true });
        }
        if (visible === false) {
            setNcrValue(undefined);
            setOtherCostValue(undefined);
            setNcrVisibilityFieldValue(2);
        }
        form.resetFields();
    }, [visible]);

    useEffect(() => {
        form.setFieldValue(
            "ncrPartnerContactReceiver",
            defaultContactReceivers.map((contact: any) => contact.id)
        );
    }, [defaultContactReceivers]);

    useEffect(() => {
        setSelectedPartnerId(selectedPartner ? selectedPartner.id : -1);
        form.setFieldsValue({ correctiveActionOwner: undefined });
        form.setFieldsValue({ preventiveActionOwner: undefined });
        form.setFieldsValue({ rootCauseActionOwner: undefined });
    }, [selectedPartner]);

    function sortCurrencyOptions() {
        return currencyCodes.sort((e1, e2) => {
            if (e1.CurrencyCode > e2.CurrencyCode) {
                return 1;
            }
            if (e1.CurrencyCode < e2.CurrencyCode) {
                return -1;
            }

            return 0;
        });
    }

    function onNcrValueCurrencyChange(value: any) {
        if (value === undefined) {
            form.setFieldsValue({ ncrValue: undefined });
        }
    }

    function onOtherCostValueCurrencyChange(value: any) {
        if (value === undefined) {
            form.setFieldsValue({ ncrOtherCost: undefined });
        }
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await createNcr({ ncr: values, visibilityVisibility: visibilityVisiblity });
        reloadTable();
        form.resetFields();
        setClearFileList({ clear: true }); //we are setting the filelistarray to force the attachfile component to rerender
        setSelectedPartner(undefined);
        notification.success({
            message: t("success"),
            description: t("ncrCreateSuccess"),
        });
        onVisibleChange(false);
    }

    function onCancel() {
        form.resetFields();
        onVisibleChange(false);
        form.resetFields();
        setSelectedPartner(undefined);
        setClearFileList({ clear: true }); //we are setting the filelistarray to force the attachfile component to rerender
    }

    let {
        purchaseOrderNumberVisiblity,
        salesOrderNumberVisiblity,
        valueVisiblity,
        otherCostsVisiblity,
        otherCostsReasonVisiblity,
        commentVisiblity,
        deliveryNoteVisiblity,
        visibilityVisiblity,
        deviationsVisiblity,
        deviationsProductNumberVisiblity,
        deviationsDescriptVisiblity,
        deviationsOrderedVisiblity,
        deviationsDeliveredVisiblity,
        deviationsFaultyVisiblity,
    } = getCustomLabelVisiblity(ncrFields);

    let {
        purchaseOrderNumberCustomDropdownId,
        salesOrderNumberCustomDropdownId,
        deliveryNoteCustomDropdownId,
    } = getNcrFieldCustomdropdownId(ncrFields);

    let {
        partnerSelectLabel,
        purchaseOrderlabel,
        salesOrderLabel,
        deliveryNoteLabel,
        ncrReasonLabel,
        deviationsLabel,
        deviationsProductNumberLabel,
        deviationsDescriptLabel,
        deviationsOrderedLabel,
        deviationsDeliveredLabel,
        deviationsFaultyLabel,
        ncrValueLabel,
        otherCostsValueLabel,
        otherCostsReasonLabel,
        commentLabel,
        visibilityLabel,
        attachmentsLabel,
        idLabel,
        createNcrLabel,
    } = getCustomLabel(ncrFields, isDefaultCompany);

    return (
        <Modal
            wrapProps={{ "data-test": "create_ncr_modal" }}
            title={createNcrLabel}
            open={visible}
            onCancel={() => onCancel()}
            onOk={onSubmit}
            width={900}
            style={{ top: 20 }}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSubmit()}>
                    {t("generic_Create")}
                </Button>,
            ]}>
            <Form
                form={form}
                onFinish={onSubmit}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                    label={idLabel}
                    className={classes.formItem}
                    name="ncrId"
                    valuePropName="value"
                    rules={[
                        {
                            whitespace: true,
                        },
                    ]}>
                    <Input
                        data-test="ncr_id_input"
                        maxLength={40}
                        placeholder={`${t("input")} ${idLabel}`}
                    />
                </Form.Item>

                <Form.Item
                    label={partnerSelectLabel}
                    className={classes.formItem}
                    valuePropName="value"
                    initialValue={defaultPartnerId}
                    name="externalPartner">
                    <SelectExternalPartner
                        data-test="external_partner_select"
                        onChange={(val: any, item: any) => {
                            setSelectedPartner(item);
                            form.setFieldsValue({ receiverusers: undefined });
                        }}
                        placeholder={`${t("select")} ${partnerSelectLabel}`}
                        allowClear
                        reload={reloadExternalPartner}
                        includeChildren
                    />
                </Form.Item>

                {purchaseOrderNumberVisiblity !== 1 && (
                    <Form.Item
                        label={purchaseOrderlabel}
                        required={purchaseOrderNumberVisiblity === 2}
                        className={classes.formItem}
                        name="purchaseOrderNumber"
                        valuePropName="value"
                        rules={[
                            {
                                required: purchaseOrderNumberVisiblity === 2,
                                message: `${purchaseOrderlabel} ${t("isRequired")} `,
                            },
                        ]}>
                        {purchaseOrderNumberCustomDropdownId === null ? (
                            <Input
                                data-test="purchase_order_number_input"
                                maxLength={50}
                                placeholder={`${t("input")} ${purchaseOrderlabel}`}
                            />
                        ) : (
                            <SelectData
                                selectOptionField="text"
                                selectIdField="text"
                                placeholder={t("generic_SelectOption")}
                                useData={() => ({
                                    data:
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === purchaseOrderNumberCustomDropdownId
                                        )[0] &&
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === purchaseOrderNumberCustomDropdownId
                                        )[0].customDropdownOptions,
                                })}
                            />
                        )}
                    </Form.Item>
                )}
                {salesOrderNumberVisiblity !== 1 && (
                    <Form.Item
                        label={salesOrderLabel}
                        required={salesOrderNumberVisiblity === 2}
                        className={classes.formItem}
                        name="salesOrderNumber"
                        valuePropName="value"
                        rules={[
                            {
                                required: salesOrderNumberVisiblity === 2,
                                message: `${salesOrderLabel} ${t("isRequired")} `,
                            },
                        ]}>
                        {salesOrderNumberCustomDropdownId === null ? (
                            <Input
                                data-test="sales_order_number_input"
                                maxLength={250}
                                placeholder={`${t("input")} ${salesOrderLabel}`}
                            />
                        ) : (
                            <SelectData
                                selectOptionField="text"
                                selectIdField="text"
                                placeholder={t("generic_SelectOption")}
                                useData={() => ({
                                    data:
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === salesOrderNumberCustomDropdownId
                                        )[0] &&
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === salesOrderNumberCustomDropdownId
                                        )[0].customDropdownOptions,
                                })}
                            />
                        )}
                    </Form.Item>
                )}
                {deliveryNoteVisiblity !== 1 && (
                    <Form.Item
                        label={deliveryNoteLabel}
                        required={deliveryNoteVisiblity === 2}
                        className={classes.formItem}
                        name="deliveryNote"
                        valuePropName="value"
                        rules={[
                            {
                                required: deliveryNoteVisiblity === 2,
                                message: `${deliveryNoteLabel} ${t("isRequired")} `,
                            },
                        ]}>
                        {deliveryNoteCustomDropdownId === null ? (
                            <Input
                                data-test="delivery_note_input"
                                maxLength={250}
                                placeholder={`${t("input")} ${deliveryNoteLabel}`}
                            />
                        ) : (
                            <SelectData
                                selectOptionField="text"
                                selectIdField="text"
                                placeholder={t("generic_SelectOption")}
                                useData={() => ({
                                    data:
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === deliveryNoteCustomDropdownId
                                        )[0] &&
                                        customDropdowns.filter(
                                            (dropdown: any) =>
                                                dropdown.id === deliveryNoteCustomDropdownId
                                        )[0].customDropdownOptions,
                                })}
                            />
                        )}
                    </Form.Item>
                )}

                <Form.Item
                    label={ncrReasonLabel}
                    required
                    className={classes.formItem}
                    name="ncrReason"
                    rules={[
                        {
                            required: true,
                            message: `${ncrReasonLabel} ${t("isRequired")} `,
                        },
                    ]}>
                    <SelectData
                        data-test="ncr_reason_select"
                        selectIdField="id"
                        useData={useNcrReason.bind(null, -1, reloadNcrs)}
                        selectOptionField="name"
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                        placeholder={`${t("select")} ${ncrReasonLabel}`}
                    />
                </Form.Item>

                {deviationsVisiblity !== 1 && (
                    <Form.List name="deviations">
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field: any, index: number) => (
                                    <Form.Item
                                        key={field.key}
                                        label={index === 0 ? deviationsLabel : " "}
                                        colon={false}
                                        required={false}
                                        labelCol={{ span: 6, style: { textAlign: "start" } }}
                                        wrapperCol={{ span: 18 }}>
                                        <Row gutter={8} style={{ marginBottom: 8 }}>
                                            {deviationsProductNumberVisiblity !== 1 && (
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[index, "productNumber"]}
                                                        key={index}
                                                        className={classes.deviationsItem}
                                                        validateTrigger="onChange"
                                                        rules={[
                                                            {
                                                                required:
                                                                    deviationsProductNumberVisiblity ===
                                                                    2,
                                                                message: `${deviationsProductNumberLabel} ${t(
                                                                    "isRequired"
                                                                )} `,
                                                            },
                                                        ]}>
                                                        <Input
                                                            data-test="deviations_product_number"
                                                            maxLength={25}
                                                            key={`deviationsProductNumber[${index}]`}
                                                            placeholder={`${t(
                                                                "input"
                                                            )} ${deviationsProductNumberLabel}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            {deviationsDescriptVisiblity !== 1 && (
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[index, "description"]}
                                                        key={index}
                                                        className={classes.deviationsItem}
                                                        rules={[
                                                            {
                                                                required:
                                                                    deviationsDescriptVisiblity ===
                                                                    2,
                                                                message: `${deviationsDescriptLabel} ${t(
                                                                    "isRequired"
                                                                )} `,
                                                            },
                                                        ]}>
                                                        <Input
                                                            data-test="deviations_description"
                                                            maxLength={150}
                                                            key={`deviationsDecription[${index}]`}
                                                            placeholder={`${t(
                                                                "input"
                                                            )} ${deviationsDescriptLabel}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row gutter={8}>
                                            {deviationsOrderedVisiblity !== 1 && (
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[index, "ordered"]}
                                                        key={index}
                                                        className={classes.formItem}
                                                        rules={[
                                                            {
                                                                required:
                                                                    deviationsOrderedVisiblity ===
                                                                    2,
                                                                message: `${deviationsOrderedLabel} ${t(
                                                                    "isRequired"
                                                                )} `,
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            data-test="deviations_ordered"
                                                            maxLength={15}
                                                            min={0}
                                                            decimalSeparator=","
                                                            style={{ width: "100%" }}
                                                            key={`deviationsOrdered[${index}]`}
                                                            placeholder={`${t(
                                                                "input"
                                                            )} ${deviationsOrderedLabel}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            {deviationsDeliveredVisiblity !== 1 && (
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[index, "delivered"]}
                                                        key={index}
                                                        className={classes.formItem}
                                                        rules={[
                                                            {
                                                                required:
                                                                    deviationsDeliveredVisiblity ===
                                                                    2,
                                                                message: `${deviationsDeliveredLabel} ${t(
                                                                    "isRequired"
                                                                )} `,
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            data-test="deviations_delivered"
                                                            maxLength={15}
                                                            min={0}
                                                            decimalSeparator=","
                                                            style={{ width: "100%" }}
                                                            key={`deviationsDelivered[${index}]`}
                                                            placeholder={`${t(
                                                                "input"
                                                            )} ${deviationsDeliveredLabel}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            {deviationsFaultyVisiblity !== 1 && (
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[index, "faulty"]}
                                                        key={index}
                                                        className={classes.formItem}
                                                        rules={[
                                                            {
                                                                required:
                                                                    deviationsFaultyVisiblity === 2,
                                                                message: `${deviationsFaultyLabel} ${t(
                                                                    "isRequired"
                                                                )} `,
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            data-test="deviations_faulty"
                                                            maxLength={15}
                                                            min={0}
                                                            decimalSeparator=","
                                                            style={{ width: "100%" }}
                                                            key={`deviationsFaulty[${index}]`}
                                                            placeholder={`${t(
                                                                "input"
                                                            )} ${deviationsFaultyLabel}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>

                                        {fields.length > 0 ? (
                                            <MinusCircle
                                                className={classes.removeRow}
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item
                                    label={" "}
                                    colon={false}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{ width: "100%" }}
                                        icon={<PlusCircle />}>
                                        Add Deviation
                                    </Button>

                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                )}

                {valueVisiblity !== 1 && (
                    <Row gutter={8}>
                        <Col span={18}>
                            <Form.Item
                                label={ncrValueLabel}
                                required={valueVisiblity === 2}
                                className={classes.formItem}
                                name="ncrValue"
                                rules={[
                                    {
                                        required: valueVisiblity === 2,
                                        message: `${ncrValueLabel} ${t("isRequired")} `,
                                    },
                                ]}>
                                <InputNumber
                                    onChange={(val: any) => {
                                        setNcrValue(val);
                                    }}
                                    data-test="ncr_value_input"
                                    maxLength={15}
                                    min={0}
                                    formatter={value => `${value}`.replace(/([A-Z])/gi, "")}
                                    decimalSeparator=","
                                    placeholder={`${t("input")} ${ncrValueLabel}`}
                                    style={{ marginLeft: 54, width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={classes.formItem}
                                name="ncrValueCurrency"
                                rules={[
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            try {
                                                const hasNcrValue =
                                                    getFieldValue("ncrValue") ||
                                                    getFieldValue("ncrValue") === 0;
                                                if (!value && hasNcrValue) {
                                                    return Promise.reject(
                                                        new Error(t("inputIsRequiredError"))
                                                    );
                                                }
                                                return Promise.resolve();
                                            } catch (error) {
                                                return Promise.reject(error);
                                            }
                                        },
                                    },
                                ]}>
                                <SelectData
                                    style={{ marginLeft: 52 }}
                                    data-test="currency_code_select"
                                    useData={() => {
                                        return {
                                            data: sortCurrencyOptions(),
                                            loading: false,
                                        };
                                    }}
                                    selectIdField="CurrencyCode"
                                    selectOptionField="CurrencyCode"
                                    disabled={ncrValue === undefined || ncrValue === null}
                                    allowClear
                                    onChange={(val: any) => onNcrValueCurrencyChange(val)}
                                    placeholder={`${t("select")} ${t("currencyPlaceholder")}`}
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {otherCostsReasonVisiblity !== 1 && (
                    <Row gutter={8}>
                        <Col span={18}>
                            <Form.Item
                                label={otherCostsValueLabel}
                                required={otherCostsVisiblity === 2}
                                className={classes.formItem}
                                name="ncrOtherCost"
                                rules={[
                                    {
                                        required: otherCostsVisiblity === 2,
                                        message: `${otherCostsValueLabel} ${t("isRequired")} `,
                                    },
                                ]}>
                                <InputNumber
                                    onChange={(val: any) => setOtherCostValue(val)}
                                    data-test="ncr_other_cost_input"
                                    maxLength={15}
                                    min={0}
                                    formatter={value => `${value}`.replace(/([A-Z])/gi, "")}
                                    decimalSeparator=","
                                    placeholder={`${t("input")} ${otherCostsValueLabel}`}
                                    style={{ marginLeft: 54, width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={classes.formItem}
                                name="ncrOtherCostCurrency"
                                rules={[
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            try {
                                                const hasNcrOtherCost =
                                                    getFieldValue("ncrOtherCost") ||
                                                    getFieldValue("ncrOtherCost") === 0;
                                                if (!value && hasNcrOtherCost) {
                                                    return Promise.reject(
                                                        new Error(t("inputIsRequiredError"))
                                                    );
                                                }
                                                return Promise.resolve();
                                            } catch (error) {
                                                return Promise.reject(error);
                                            }
                                        },
                                    },
                                ]}>
                                <SelectData
                                    style={{ marginLeft: 52 }}
                                    data-test="ncr_other_cost_currency_select"
                                    useData={() => {
                                        return {
                                            data: sortCurrencyOptions(),
                                            loading: false,
                                        };
                                    }}
                                    selectIdField="CurrencyCode"
                                    selectOptionField="CurrencyCode"
                                    disabled={
                                        otherCostValue === undefined || otherCostValue === null
                                    }
                                    onChange={(val: any) => onOtherCostValueCurrencyChange(val)}
                                    allowClear
                                    placeholder={`${t("select")} ${t("currencyPlaceholder")}`}
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {otherCostsReasonVisiblity !== 1 && (
                    <Form.Item
                        label={otherCostsReasonLabel}
                        required={otherCostsReasonVisiblity === 2}
                        className={classes.formItem}
                        name="ncrOtherCostReason"
                        rules={[
                            {
                                required: otherCostsReasonVisiblity === 2,
                                message: `${otherCostsReasonLabel} ${t("isRequired")} `,
                            },
                        ]}>
                        <Input
                            data-test="ncr_other_cost_reason_input"
                            maxLength={200}
                            placeholder={`${t("input")} ${otherCostsReasonLabel}`}
                        />
                    </Form.Item>
                )}
                {commentVisiblity !== 1 && (
                    <Form.Item
                        label={commentLabel}
                        required={commentVisiblity === 2}
                        className={classes.formItem}
                        name="comment"
                        rules={[
                            {
                                required: commentVisiblity === 2,
                                message: `${commentLabel} ${t("isRequired")} `,
                            },
                        ]}
                        initialValue={defaultComment}>
                        <TextArea data-test="comment_textarea" maxLength={4000} rows={4} />
                    </Form.Item>
                )}
                {visibilityVisiblity !== NcrFieldVisibility.Invisible &&
                    visibilityVisiblity !== NcrFieldVisibility.InvisibleInternal &&
                    visibilityVisiblity !== NcrFieldVisibility.InvisibleExternal && (
                        <Form.Item
                            label={visibilityLabel}
                            required={visibilityVisiblity === 2}
                            className={classes.formItem}
                            name="visibility"
                            initialValue={2}
                            rules={[
                                {
                                    required: visibilityVisiblity === 2,
                                    message: `${visibilityLabel} ${t("isRequired")} `,
                                },
                            ]}>
                            <Select
                                onChange={(val: any) => setNcrVisibilityFieldValue(val)}
                                data-test="visibility_select"
                                //mode="default"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }>
                                <Option key={0} value={1}>
                                    {t("internal")}
                                </Option>
                                <Option key={1} value={2}>
                                    {t("external")}
                                </Option>
                            </Select>
                        </Form.Item>
                    )}

                {visibilityVisiblity !== NcrFieldVisibility.Invisible &&
                    visibilityVisiblity !== NcrFieldVisibility.InvisibleInternal &&
                    (ncrVisibilityFieldValue === 2 || ncrVisibilityFieldValue === undefined) && (
                        <Form.Item
                            label={
                                <>
                                    {t("ncr_PartnerContactReceivers")}{" "}
                                    <Tooltip title={t("ncr_PartnerContactReceiversTip")}>
                                        <span>
                                            <InfoCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="ncrPartnerContactReceiver"
                            initialValue={defaultContactReceivers}
                            className={classes.formItem}>
                            <SelectPartnerContacts
                                partnerId={selectedPartner && selectedPartner.id}
                                disabled={selectedPartner === undefined || selectedPartner === null}
                            />
                        </Form.Item>
                    )}

                <Form.Item
                    label={attachmentsLabel}
                    data-test="create_attached_files"
                    className={classes.formItem}
                    name="attachedFiles">
                    <AttachFile maxFileSize={40} clearFileList={clearFileList} />
                </Form.Item>

                {ncrVisibilityFieldValue === 2 &&
                    visibilityVisiblity !== NcrFieldVisibility.InvisibleInternal && (
                        <Fragment>
                            <Divider />
                            <NcrActionsInput
                                form={form}
                                selectedPartner={selectedPartner}
                                selectedPartnerId={selectedPartnerId}
                                isEditMode={false}
                                parentModalVisible={visible}
                            />
                        </Fragment>
                    )}
            </Form>
        </Modal>
    );
};

export default CreationModal;
