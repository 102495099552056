import {
    Review,
    SelfAssessment,
    Compliance,
    FolderSharedByPartner,
    FolderShared,
    InvitationAccepted,
    KpiUploaded,
    KpiUploadedOnMyCompanyId,
    Message,
    NewUser,
    Project,
    Ncr,
    KickStarter,
    UnknownActivity,
    PartnerUploadedFilesToSharedFolder,
} from "../activityTypes";
import { IActivityData, ActivityType } from "models";

const getActivity = (data: IActivityData) => {
    let ActivityTypeComponent: any;
    let activityDetail: any;

    switch (data.typeName) {
        case ActivityType.KickStarter:
            ActivityTypeComponent = KickStarter;
            activityDetail = data["kickStarterDetail"];
            break;
        case ActivityType.Message:
            ActivityTypeComponent = Message;
            activityDetail = data["messageDetail"];
            break;
        case ActivityType.InvitationAccepted:
            ActivityTypeComponent = InvitationAccepted;
            activityDetail = data["invitationAcceptedDetail"];
            break;
        case ActivityType.NewUser:
            ActivityTypeComponent = NewUser;
            activityDetail = data["newUserDetail"];
            break;
        case ActivityType.FolderShared:
            ActivityTypeComponent = FolderShared;
            activityDetail = data["folderSharedDetail"];
            break;
        case ActivityType.FolderSharedByPartner:
            ActivityTypeComponent = FolderSharedByPartner;
            activityDetail = data["folderSharedByPartnerDetail"];
            break;
        case ActivityType.PartnerUploadedFilesToSharedFolder:
            ActivityTypeComponent = PartnerUploadedFilesToSharedFolder;
            activityDetail = data["partnerUploadedFilesToSharedFolderActivity"];
            break;
        case ActivityType.KpiUpload:
            ActivityTypeComponent = KpiUploaded;
            activityDetail = data["kpiUploadedDetail"];
            break;
        case ActivityType.KpiUploadOnMyCompany:
            ActivityTypeComponent = KpiUploadedOnMyCompanyId;
            activityDetail = data["kpiUploadedOnMyCompanyIdDetail"];
            break;

        case ActivityType.Review:
            ActivityTypeComponent = Review;
            activityDetail = data["reviewDetail"];
            break;
        case ActivityType.Compliance:
            if (data.selfAssessmentDetail !== null) {
                ActivityTypeComponent = SelfAssessment;
                activityDetail = data["selfAssessmentDetail"];
            } else {
                ActivityTypeComponent = Compliance;
                activityDetail = data["complianceDetail"];
            }
            break;
        case ActivityType.Project:
            ActivityTypeComponent = Project;
            activityDetail = data["projectDetail"];
            break;
        case ActivityType.Ncr:
            ActivityTypeComponent = Ncr;
            activityDetail = data["ncrDetail"];
            break;
        default:
            ActivityTypeComponent = UnknownActivity;
            activityDetail = data["activity"];
    }
    return {
        ActivityTypeComponent,
        activityDetail,
    };
};

export { getActivity };
