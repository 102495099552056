import React, { FunctionComponent, useEffect } from "react";
import { Modal, Form, notification, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useRisks } from "controller";
import { RichTextEditor, SelectData } from "components/utilitycomponents";
import { IRisk, RiskState, RiskType } from "models";

interface IProps {
    visible: boolean;
    onCancel: Function;
    onSuccess: Function;
    item: IRisk;
    partnerId: number;
}

const CreateEditModal: FunctionComponent<IProps> = ({
    visible,
    onCancel,
    onSuccess,
    item,
    partnerId,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { create, update, createLoading, updateLoading } = useRisks(partnerId, false);

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    const handleSubmit = async () => {
        const values = await form.validateFields();

        let risk: IRisk = {
            id: item ? item.id : undefined,
            title: values.title,
            type: values.type,
            state: item ? item.state : RiskState.NotMitigated,
            partnerId,
        };

        if (item) {
            const updateRes = await update({
                newItem: risk,
                item: item,
            });
            if (updateRes !== false) {
                notification.success({
                    message: t("success"),
                    description: t("generic_UpdateSuccess", {
                        name: t("riskAndIssues_Risk"),
                    }),
                });
            }
        } else {
            await create(risk);
            notification.success({
                message: t("success"),
                description: t("generic_CreateSuccess", {
                    name: t("riskAndIssues_Risk"),
                }),
            });
        }
        onSuccess();
    };

    return (
        <Modal
            title={
                item
                    ? t("generic_Edit", { title: t("riskAndIssues_Risk") })
                    : t("generic_Create", { title: t("riskAndIssues_Risk") })
            }
            open={visible}
            onCancel={() => onCancel()}
            onOk={() => form.submit()}
            footer={[
                <Button
                    loading={createLoading || updateLoading}
                    type="primary"
                    onClick={async () => form.submit()}>
                    {item ? t("generic_Save") : t("generic_Create")}
                </Button>,
            ]}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    name="title"
                    label={t("riskAndIssues_Title")}
                    rules={[
                        {
                            validateTrigger: "onBlur",
                            required: true,
                            message: t("inputIsRequiredError"),
                            whitespace: true,
                            validator: async (rule: any, value: any, callback: any) => {
                                try {
                                    if (!value || value === undefined) {
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    } else if (
                                        (value && value.trim() === "") ||
                                        value.trim() === "<p><br></p>"
                                    ) {
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    }
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                    initialValue={item ? item.title : undefined}>
                    <RichTextEditor
                        bordered
                        placeholder={t("compliance_InsertText")}
                        maxLength={255}
                    />
                </Form.Item>
                <Form.Item
                    name="type"
                    label={t("riskAndIssues_Type")}
                    valuePropName="value"
                    initialValue={item ? item.type : undefined}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <SelectData
                        useData={() => ({
                            data: [
                                { id: RiskType.Highlight, name: t("riskAndIssues_Highlight") },
                                { id: RiskType.Issue, name: t("riskAndIssues_Issue") },
                                { id: RiskType.Risk, name: t("riskAndIssues_RiskType") },
                            ],
                            loading: false,
                        })}
                        selectOptionField="name"
                        placeholder={t("select")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export { CreateEditModal };
