import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Input, notification, Form, Tooltip, Popconfirm, Flex } from "antd";
import { SelectData, SelectDataMultiple, HelpCircle } from "components/utilitycomponents";
import {
    useContactGroups,
    useContactUpdate,
    useContactsByEmail,
    useCountries,
    useDualPartnerOptions,
} from "controller";
import TextArea from "antd/lib/input/TextArea";

type PropTypes = {
    visible: boolean;
    onClose: (reload: boolean, contactId?: number, contactItem?: any) => void;
    showSupplierSelect?: boolean;
    partnerId?: any;
};

const CreateModal: FunctionComponent<PropTypes> = ({
    visible,
    onClose,
    partnerId,
    showSupplierSelect = false,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { createContact, loading } = useContactUpdate();
    const { getContactsByEmail, loading: loadingContactsByEmail } = useContactsByEmail();
    const [duplicateEmailContacts, setDuplicateEmailContacts] = useState<any[]>([]);
    const [showMore, setShowMore] = useState<boolean>(false);

    const handleSave = async () => {
        const values = await form.validateFields();

        const payload = {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            emailAddress: values.emailAddress,
            workPhone: values.workPhone ? values.workPhone.trim() : undefined,
            mobilePhone: values.mobilePhone ? values.mobilePhone.trim() : undefined,
            contactGroups: values.contactGroups
                ? values.contactGroups.map((id: number) => ({
                      id,
                  }))
                : [],
            partnerId: values.partner && values.partner === -1 ? undefined : values.partner,
            jobTitle: values.jobTitle ? values.jobTitle : "",
            department: values.department ? values.department : "",
            address: values.address ? values.address : "",
            countryId: values.countryId ? values.countryId : null,
        };
        if (showSupplierSelect === false && partnerId) {
            payload.partnerId = partnerId;
        }

        const contactId = await createContact(payload);
        notification.success({
            message: t("success"),
            description: t("generic_CreateSuccess", {
                name: t("Contact"),
            }),
        });
        form.resetFields();
        onClose(true, Number(contactId), payload);
    };

    const emailValidateHasFeedback = () => {
        return (
            duplicateEmailContacts.length > 0 ||
            loadingContactsByEmail === true ||
            form.getFieldValue("emailAddress") === ""
        );
    };

    const emailValidateStatus = () => {
        if (duplicateEmailContacts.length > 0) {
            return "warning";
        } else if (loadingContactsByEmail === true) {
            return "validating";
        } else if (form.getFieldValue("emailAddress") === "") {
            return "error";
        }
    };

    const emailFormHelp = () => {
        if (duplicateEmailContacts.length > 0)
            return (
                <Flex vertical>
                    <div>{t("contact_DuplicateContactsExistText")}:</div>
                    {duplicateEmailContacts.map((c: any) =>
                        c.fullName.length < 31 ? (
                            <div>{`${c.fullName} ${
                                c.partnerName ? "- " + c.partnerName : ""
                            }`}</div>
                        ) : (
                            <div>
                                <Tooltip title={c.fullName}>
                                    <div>{`${c.fullName.substring(0, 31)}... ${
                                        c.partnerName ? "- " + c.partnerName : ""
                                    }`}</div>
                                </Tooltip>
                            </div>
                        )
                    )}
                </Flex>
            );
        else if (form.getFieldValue("emailAddress") === "") return t("emailInputError");
    };

    return (
        <Modal
            wrapProps={{ "data-test": "contact_create_edit_modal" }}
            title={t("contact_create", "Create Contact")}
            open={visible}
            footer={[
                <Button key="cancel" onClick={() => onClose(false)}>
                    {t("generic_Cancel")}
                </Button>,
                duplicateEmailContacts.length > 0 ? (
                    <Popconfirm
                        title={t("generic_BeAdvised")}
                        description={
                            <div style={{ width: 314 }}>
                                {t("contact_DuplicateContactsExistConfirmationDescription", {
                                    count: duplicateEmailContacts.length,
                                })}
                            </div>
                        }
                        onConfirm={form.submit}
                        cancelText={t("generic_Cancel")}
                        okText={t("generic_Confirm")}>
                        <Button key="ok" type="primary" loading={loading}>
                            {t("generic_Create")}
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button key="ok" type="primary" onClick={handleSave} loading={loading}>
                        {t("generic_Create")}
                    </Button>
                ),
            ]}
            onCancel={() => onClose(false)}>
            <Form
                form={form}
                onFinish={handleSave}
                scrollToFirstError
                labelAlign="left"
                labelCol={{ lg: 6, xs: 24 }}
                wrapperCol={{ lg: 18, xs: 24 }}>
                <>
                    <Form.Item
                        label={t("contact_firstName")}
                        required
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input placeholder={t("contact_firstName")} maxLength={200} />
                    </Form.Item>

                    <Form.Item
                        label={t("contact_lastName")}
                        required
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input placeholder={t("contact_lastName")} maxLength={200} />
                    </Form.Item>

                    <Form.Item
                        label={t("contact_emailAddress")}
                        required
                        name="emailAddress"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: t("emailInputError"),
                                type: "email",
                            },
                        ]}
                        hasFeedback={emailValidateHasFeedback()}
                        validateStatus={emailValidateStatus()}
                        help={emailFormHelp()}>
                        <Input
                            placeholder={t("contact_emailAddress")}
                            maxLength={300}
                            onBlur={async () => {
                                let duplicateContacts: any[] = await getContactsByEmail(
                                    form.getFieldValue("emailAddress")
                                );
                                setDuplicateEmailContacts(duplicateContacts);
                            }}
                        />
                    </Form.Item>

                    <Form.Item label={t("contact_Group")} name="contactGroups">
                        <SelectDataMultiple
                            data-test="contact_Group"
                            useData={useContactGroups}
                            selectIdField="id"
                            selectOptionField="name"
                            allowClear
                            selectAll
                            placeholder={t("contact_Group")}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                        />
                    </Form.Item>

                    <Form.Item label={t("contact_workPhone")} name="workPhone">
                        <Input allowClear placeholder={t("contact_workPhone")} maxLength={200} />
                    </Form.Item>

                    <Form.Item label={t("contact_mobilePhone")} name="mobilePhone">
                        <Input allowClear placeholder={t("contact_mobilePhone")} maxLength={200} />
                    </Form.Item>

                    {showSupplierSelect && (
                        <Form.Item
                            label={
                                <>
                                    <label>{t("contact_companyName")} </label>
                                    <Tooltip title={t("contact_companyNameGuideCheckBox")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="partner"
                            initialValue={-1}>
                            <SelectData
                                placeholder={t("select")}
                                allowClear={false}
                                selectOptionField="name"
                                useData={useDualPartnerOptions.bind(null, [partnerId])}
                            />
                        </Form.Item>
                    )}
                </>

                {showMore && (
                    <>
                        <Form.Item label={t("contact_jobTitle")} name="jobTitle">
                            <Input allowClear placeholder={t("contact_jobTitle")} maxLength={200} />
                        </Form.Item>

                        <Form.Item label={t("contact_department")} name="department">
                            <Input
                                allowClear
                                placeholder={t("contact_department")}
                                maxLength={50}
                            />
                        </Form.Item>

                        <Form.Item label={t("contact_country")} name="countryId">
                            <SelectData
                                useData={useCountries}
                                selectIdField="id"
                                selectOptionField="name"
                                allowClear
                                placeholder={t("contact_country")}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <Form.Item label={t("contact_address")} name="address">
                            <TextArea
                                rows={4}
                                allowClear
                                placeholder={t("contact_address")}
                                maxLength={200}
                            />
                        </Form.Item>
                    </>
                )}
                <Flex justify="center">
                    <div onClick={() => setShowMore(!showMore)}>
                        {showMore ? (
                            <a>{t("showLess").toLowerCase()}</a>
                        ) : (
                            <a>{t("showMore").toLowerCase()}</a>
                        )}
                    </div>
                </Flex>
            </Form>
        </Modal>
    );
};

export { CreateModal };
