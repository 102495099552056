import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IActivityChild } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IActivityChild;
};

const NextResponsibleNcr: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NcrResponsibleChange")}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("activity_NcrResponsibleChange")}
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div data-test="changed_ncr_next_responsible" className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
        </Fragment>
    );
};

export default NextResponsibleNcr;
