import { useState } from "react";
import { Provider } from "services";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const usePartnerGroupUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const update = async (partnerIds: number[], groupId: number, groupHeadline: string) => {
        setLoading(true);
        await Provider.Partner.updateGroupOnPartner(partnerIds, groupId).catch(err => {
            setLoading(false);
            notification.error({
                message: t("systemMessage"),
                description: t("generic_ErrorMessage"),
                duration: 0,
            });
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        update,
    };
};

export { usePartnerGroupUpdate };
