import { useEffect, useState } from "react";
import Provider from "services/api";

const useFolder = (folderId: number) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (folderId) {
                setState({ ...state, loading: true });
                const data = await Provider.File.getFolder(folderId, {
                    customError: {
                        message: "Could not retrieve folder settings",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data });
            }
        })();
    }, [folderId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useFolder };
