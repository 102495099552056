import "blueimp-canvas-to-blob/js/canvas-to-blob";

const resizeImage = ({ file, height, output, callback }: any) => {
    var img = new Image(),
        reader = new FileReader();

    img.onload = function() {
        var canvas = document.createElement("canvas"),
            newWidth = img.width,
            newHeight = img.height,
            ctx = null;

        newHeight = height;
        newWidth = (height / img.height) * img.width;

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx = canvas.getContext("2d");
        if (!ctx) {
            callback(reader.result, newWidth, newHeight);
            return;
        }
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);

        if (output === "dataUrl" && canvas.toDataURL) {
            callback(canvas.toDataURL(file.type, 1), newWidth, newHeight);
        } else if (output === "blob") {
            (async () => {
                const blob = await canvasToBlob(canvas, file.name, file.type);
                callback(blob, newWidth, newHeight);
            })();
        } else {
            callback(reader.result, newWidth, newHeight);
        }
        return;
    };

    reader.onload = function(e: any) {
        img.src = e.target.result;
    };
    reader.readAsDataURL(file);
};

const canvasToBlob = async (canvas: any, imageName: any, imageType: any) => {
    return await new Promise((resolve, reject) => {
        if (canvas.toBlob) {
            canvas.toBlob(
                (blob: any) => {
                    if (!blob) {
                        reject("no blob");
                    } else {
                        try {
                            resolve(new File([blob], imageName, { type: blob.type }));
                        } catch (e) {
                            resolve(
                                Object.assign(blob, {
                                    lastModified: Date.now(),
                                    type: imageType,
                                })
                            );
                        }
                    }
                },
                imageType,
                1
            );
        } else {
            reject("no canvas.toBlob");
        }
    });
};

export { resizeImage, canvasToBlob };
