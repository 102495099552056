import { IReportSettings } from "models";
import { useState } from "react";
import { Provider } from "services";

const useReportSettingsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (data: IReportSettings, id: any) => {
        setLoading(true);
        await Provider.Company.updateReportSettings(data, id, {
            customError: {
                message: "Could not update report setting",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (id: any) => {
        setLoading(true);
        await Provider.Company.removeEmbeddedReport(id, {
            customError: {
                message: "Could not remove report setting",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        remove,
        update,
    };
};

export { useReportSettingsUpdate };
