import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, message, notification, Row, Col, Alert } from "antd";
import { ButtonedInput } from "components/utilitycomponents";
import { useDashboardGuestToken, useSendGuestReport } from "controller";
import { getDomainUrl } from "utilities";

type PropsType = {
    visible: boolean;
    onClose: Function;
    reportData: { presetId: number; companyId: number; supplierId: number };
    dashboardVisibilty: string;
};

const ShareDashboardModal: FunctionComponent<PropsType> = ({
    reportData,
    visible,
    onClose,
    dashboardVisibilty,
}) => {
    const { t } = useTranslation();
    const { getGuestToken, loading } = useDashboardGuestToken();
    const [data, setData] = useState<any>({});

    const { send, loading: sendLoading } = useSendGuestReport();

    const handleCopyUrl = () => {
        var copyText = document.getElementById(
            "analytics_send_report_modal_url"
        ) as HTMLInputElement;
        if (copyText) {
            copyText.select();
            document.execCommand("copy");
            message.success(t("copied"));
        }
    };

    const handleSendReport = async () => {
        await send(data.guestTokenGuid);
        notification.success({
            message: "Success",
            description: t("analytics_sendReport_Success"),
        });
    };

    const handleGetToken = async (ids: any) => {
        const data = await getGuestToken(ids);
        setData(data);
        notification.success({
            message: "Success",
            description: t("analytics_ShareDashboard_Success"),
        });
    };

    function footer() {
        let footer = [
            <Button loading={loading} onClick={() => handleGetToken(reportData)} key={0}>
                {t("analytics_shareButton")}
            </Button>,
        ];
        if (dashboardVisibilty === "External") {
            footer.unshift(
                <Button
                    type="primary"
                    key={1}
                    loading={sendLoading}
                    onClick={() => handleSendReport()}
                    disabled={data.isPartnerLinked === false || data.guestTokenGuid === undefined}>
                    {t("analytics_ShareDashboardViaMail", "Notify supplier")}
                </Button>
            );
        }
        return footer;
    }

    return (
        <Modal
            title={t("analytics_ShareDashboardTitle", "Send Dashboard")}
            open={visible}
            footer={footer()}
            onCancel={() => onClose()}>
            <ButtonedInput
                value={
                    data.guestTokenGuid
                        ? `${getDomainUrl()}/Guest/Analytics/${data.guestTokenGuid}`
                        : ""
                }
                buttonText={t("copyUrl")}
                inputId="analytics_send_report_modal_url"
                onClick={handleCopyUrl}
                disabled={data.guestTokenGuid === undefined}
            />
            {data.guestTokenGuid !== undefined && (
                <Row style={{ marginTop: "10px" }}>
                    <Col>
                        <Alert showIcon message={t("analytics_Sharing_Alert")}></Alert>
                    </Col>
                </Row>
            )}
            {data.guestTokenGuid !== undefined &&
                !data.isPartnerLinked &&
                dashboardVisibilty === "External" && (
                    <Row style={{ marginTop: "10px" }}>
                        <Col>
                            <Alert
                                showIcon
                                type="warning"
                                message={t("analytics_sendReportToPartnerWithNoEmail")}></Alert>
                        </Col>
                    </Row>
                )}
        </Modal>
    );
};

export default ShareDashboardModal;
