import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, notification, Tag, Form, Button } from "antd";
import { SelectDataMultiple } from "components/utilitycomponents";
import { useContactGroups, useContactUpdate } from "controller";

interface IProps {
    data: { id: number; name: string }[];
    visible: boolean;
    onClose: (reload: boolean) => void;
}

const GroupContactsModal = ({ data, visible, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, assignGroups } = useContactUpdate();

    useEffect(() => {
        form.resetFields();
    }, [data]);

    const handleAssignGroup = async () => {
        let fieldsValue = await form.validateFields();
        if (!fieldsValue.groupIds || fieldsValue.groupIds.length === 0) {
            return;
        }
        await assignGroups(
            data.map(d => d.id),
            fieldsValue.groupIds
        );
        notification.success({
            message: t("success"),
            description: t("contact_AssignGroupSuccess", { count: data.length }),
        });
        form.resetFields();
        onClose(true);
    };

    return (
        <Modal
            wrapProps={{ "data-test": "contact_assign_group_modal" }}
            title={t("contact_AssignGroup")}
            open={visible}
            onOk={() => handleAssignGroup()}
            onCancel={() => onClose(false)}
            footer={[
                <Button
                    loading={loading}
                    type="primary"
                    onClick={async () => await handleAssignGroup()}>
                    {t("contact_AddGroup")}
                </Button>,
            ]}>
            <Form
                form={form}
                labelAlign="left"
                labelCol={{ lg: 6, xs: 24 }}
                wrapperCol={{ lg: 18, xs: 24 }}>
                <div>
                    <div className="gridTagLabel">
                        {t("grid_TagSelected", `Selected Contacts`, {
                            name: t("contact_Contacts"),
                        })}
                        :
                    </div>
                    <div className="gridTagArea">
                        {data.map((item: any) => {
                            return (
                                <Tag color="blue" key={item.id}>
                                    {item.name}
                                </Tag>
                            );
                        })}
                    </div>
                </div>

                <Form.Item
                    label={t("contact_SelectGroup", "Select Group")}
                    name="group Ids"
                    initialValue={undefined}>
                    <SelectDataMultiple
                        data-test="groupIds"
                        style={{ width: "100%" }}
                        useData={useContactGroups}
                        selectIdField="id"
                        selectOptionField="name"
                        placeholder={t("contact_Group")}
                        maxTagCount={5}
                        allowClear
                        selectAll
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default GroupContactsModal;
