import { useState } from "react";
import Provider from "services/api";

const useHasDevelopmentPlanTemplateDuplication = () => {
    const [loading, setLoading] = useState(false);

    const hasDuplicateTemplateName = async (name: string) => {
        setLoading(true);
        const res = await Provider.DevelopmentPlan.hasDevelopmentPlanTemplateDuplication(name, {
            customError: {
                message: "Could not check template value",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    return {
        loading: loading,
        hasDuplicateTemplateName,
    };
};

export { useHasDevelopmentPlanTemplateDuplication };
