import React, { useState, FunctionComponent, Fragment, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
    ReviewRate,
    getFilterIcon,
    ColoredPercentTag,
} from "components/utilitycomponents";
import {
    useComplianceOverview,
    useContacts,
    useComplianceStatus,
    usePartnerList,
    useComplianceContactsFilter,
    useTemplatesForFilter,
    useCompanyType,
    useIsGateKeeperUser,
    usePartnerFields,
} from "controller";
import RegistrationModal from "./registrationModal/registrationModal";
import { formatTime } from "utilities";
import OverviewHeader from "./overviewHeader";
import { ComplianceVisibility, ReportType } from "models";
import { useTranslation } from "react-i18next";
import { MultipleTags } from "components/utilitycomponents/multipleTags/multipleTags";
import dayjs from "utilities/daysJsConfig";
import SideBarFilter from "./sideBarFilter";
import { Flex, Tag } from "antd";

interface IProps {
    [key: string]: any;
}

const Overview: FunctionComponent<IProps> = ({ ...rest }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const {
        id: registrationId,
        partnerId,
        from: fromDateString,
        to: toDateString,
        companyNumber,
    }: any = useParams();
    const { data: isGateKeeperUser } = useIsGateKeeperUser();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [registrationModal, setRegistrationModal] = useState({
        id: registrationId ? Number(registrationId) : 0,
        visible: registrationId ? true : false,
    });
    const { isDefaultCompany } = useCompanyType();
    const [additionalFilters, setAdditionalFilters] = useState<any[]>([]);
    const { data: partnerFields } = usePartnerFields();

    useEffect(() => {
        if (location.pathname.includes("filter/MyRegistrations")) {
            setAdditionalFilters([
                ...additionalFilters,
                { name: "MyRegistrations", value: "true" },
            ]);
        }
        if (location.pathname.includes("filter/MyGateKeeperRegistrations")) {
            setAdditionalFilters([
                ...additionalFilters,
                { name: "MyGateKeeperRegistrations", value: "true" },
            ]);
        }
        if (partnerId && fromDateString && toDateString) {
            setAdditionalFilters([...additionalFilters, { name: "partner", value: partnerId }]);
        }
        if (companyNumber) {
            setAdditionalFilters([
                ...additionalFilters,
                { name: "companyNumber", value: companyNumber },
            ]);
        }
    }, []);

    const [from, setFrom] = useState(
        dayjs()
            .subtract(6, "months")
            .toISOString()
    );
    const [to, setTo] = useState(
        dayjs()
            .add(1, "day")
            .toISOString()
    );

    useEffect(() => {
        setRegistrationModal({
            id: registrationId ? Number(registrationId) : 0,
            visible: registrationId ? true : false,
        });
    }, [registrationId]);

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setRegistrationModal({ id: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    let ownerColTitle = partnerFields.headlines.find((x: any) => x.name === "Owner")?.customLabel;
    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;
    let companyNumberColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyNumber")
        ?.customLabel;

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.isSelectable === false,
        }),
    };

    const columns = [
        {
            title: t("id", "Id"),
            dataIndex: "id",
            key: "Id",
            width: 100,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 1,
            ...getColumnSearchProps({ dataIndex: "id" }),
        },
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "partner",
            key: "Partner",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 2,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partner", includeChildren: true }
            ),
        },
        {
            title: companyNumberColTitle || t("companyNumber"),
            dataIndex: "companyNumber",
            key: "CompanyNumber",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 3,
            ...getColumnSearchProps({ dataIndex: "companyNumber" }),
        },
        {
            title: t("compliance_Template", "Template"),
            dataIndex: "complianceTemplate",
            key: "Template",
            width: 250,
            sorter: true,
            filterType: "array",
            viewColumnId: 4,
            ...getColumnSelectProps(
                useTemplatesForFilter.bind(null),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "complianceTemplate", includeKeyInText: true, includeChildren: true }
            ),
        },
        {
            title: ownerColTitle || t("owner"),
            dataIndex: "owners",
            key: "Owners",
            width: 200,
            filterType: "array",
            viewColumnId: 5,
            render: (owners: any[]) => (
                <MultipleTags
                    values={(owners === null ? [] : owners).map((owner: any) => ({
                        id: owner.id,
                        name: `${owner.firstName} ${owner.lastName}`,
                    }))}
                />
            ),
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "owners" }
            ),
        },
        {
            title: t("compliance_version", "Version"),
            dataIndex: "isDraft",
            key: "Version",
            width: 150,
            sorter: true,
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("draft"), value: true },
                            { text: t("submitted"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "version", radioChoice: true }
            ),
            filterType: "array",
            viewColumnId: 6,
            render: (val: any) => (val === false ? t("submitted") : t("draft")),
        },
        {
            title: t("compliance_rating", "Rating"),
            dataIndex: "rating",
            key: "Rating",
            width: 200,
            sorter: true,
            filterType: "range",
            min: 0,
            max: 5,
            viewColumnId: 7,
            ...getColumnMinMaxProps(0, 5, { dataIndex: "rating" }),
            render: (val: any) => <ReviewRate val={val} />,
        },
        {
            title: t("compliance_entity", "Entity"),
            dataIndex: "entity",
            key: "Entity",
            filterType: "string",
            width: 250,
            sorter: true,
            viewColumnId: 8,
            ...getColumnSearchProps({ dataIndex: "entity" }),
        },
        {
            title: t("compliance_status", "Status"),
            dataIndex: "status",
            key: "Status",
            width: 270,
            sorter: true,
            filterType: "array",
            viewColumnId: 9,
            ...getColumnSelectProps(
                useComplianceStatus,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "status" }
            ),
            render: (value: string) => <div>{t(value)}</div>,
        },
        {
            title: t("compliance_EndDate", "End Date"),
            dataIndex: "endDate",
            key: "EndDate",
            filterType: "date",
            width: 170,
            sorter: true,
            viewColumnId: 10,
            ...getColumnRangePickerProps({ dataIndex: "endDate" }),
            render: (endDate: any) => (
                <Flex justify="left">
                    {dayjs().isAfter(endDate) ? (
                        <Tag color="#F19306" bordered={false}>
                            {t("scorecard_State_Expired")} {formatTime(endDate)}
                        </Tag>
                    ) : (
                        endDate && formatTime(endDate, "MMM D, YYYY")
                    )}
                </Flex>
            ),
        },
        {
            title: t("compliance_AlertDate", "Alert Date"),
            dataIndex: "alertDate",
            key: "AlertDate",
            filterType: "date",
            width: 170,
            sorter: true,
            viewColumnId: 17,
            ...getColumnRangePickerProps({ dataIndex: "alertDate" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
        {
            title: t("compliance_lastUpdated", "Last Updated (UTC)"),
            dataIndex: "modified",
            key: "Modified",
            filterType: "date",
            width: 200,
            sorter: true,
            viewColumnId: 11,
            ...getColumnRangePickerProps({ dataIndex: "modified" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY (HH:mm)"),
        },
        {
            title: t("compliance_createdBy", "Created By"),
            dataIndex: "createdBy",
            key: "CreatedBy",
            width: 250,
            sorter: true,
            viewColumnId: 12,
            filterType: "array",
            render: (value: string) => value.replace(/([A-Z])/g, " $1"),
            ...getColumnSelectProps(
                useComplianceContactsFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["fullName"],
                },
                { dataIndex: "owners" }
            ),
        },
        {
            title: t("compliance_createdDate", "Created (UTC)"),
            dataIndex: "created",
            key: "Created",
            width: 200,
            sorter: true,
            filterType: "date",
            viewColumnId: 13,
            ...getColumnRangePickerProps({ dataIndex: "created" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY (HH:mm)"),
        },
        {
            title: t("compliance_Visibility", "Visibility"),
            dataIndex: "visibility",
            key: "Visibility",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("external"), value: ComplianceVisibility.External },
                            { text: t("internal"), value: ComplianceVisibility.Internal },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "visibility", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "visibility" }),
            filterType: "array",
            width: 170,
            sorter: true,
            viewColumnId: 14,
            render: (val: string) => <div>{t(val.toLocaleLowerCase())}</div>,
        },
        {
            title: t("compliance_ComplianceScore"),
            dataIndex: "averageScore",
            key: "averageScore",
            filterType: "range",
            width: 200,
            sorter: true,
            viewColumnId: 16,
            min: 0,
            max: 100,
            ...getColumnMinMaxProps(0, 100, { dataIndex: "averageScore" }),
            render: (val: any, record: any) =>
                record.averageScore != null && (
                    <>
                        <ColoredPercentTag value={record.averageScore} />
                    </>
                ),
        },
    ];

    return (
        <Fragment>
            <Grid
                additionalColumnFilters={
                    (location.pathname.includes("filter/MyRegistrations") ||
                        location.pathname.includes("filter/MyGateKeeperRegistrations") ||
                        (partnerId && fromDateString && toDateString) ||
                        companyNumber) &&
                    additionalFilters
                }
                data-test="compliance_table"
                sidebar={() => (
                    <SideBarFilter
                        isGateKeeperUser={isGateKeeperUser}
                        onClickMyGateReg={() => {
                            history.push("/Compliance/filter/MyGateKeeperRegistrations");
                            setAdditionalFilters([
                                {
                                    name: "MyGateKeeperRegistrations",
                                    value: "true",
                                },
                            ]);
                        }}
                        onClickMyReg={() => {
                            history.push("/Compliance/filter/MyRegistrations");
                            setAdditionalFilters([{ name: "MyRegistrations", value: "true" }]);
                        }}
                    />
                )}
                title={() =>
                    isDefaultCompany ? (
                        <>
                            <OverviewHeader
                                selectedRowKeys={selectedRowKeys}
                                selectedRows={selectedRows}
                                onUpdateSelectedRows={setSelectedRows}
                                onUpdateSelectedRowKeys={setSelectedRowKeys}
                                onReloadData={() => setReloadData({ reload: true })}
                            />
                        </>
                    ) : (
                        <div style={{ fontWeight: "bold" }}>{t("compliance_Compliance")}</div>
                    )
                }
                columns={columns}
                fixedColumns={["id", "partner", "companyNumber"]}
                dateRange={{ from: fromDateString, to: toDateString }}
                useData={useComplianceOverview}
                tableType="Compliance"
                onRow={handleOnRow}
                rowSelection={rowSelection}
                reloadData={reloadData}
                defaultRangeInMonth={36}
                reportType={ReportType.compliance}
                onRangeChange={(val: any) => {
                    setFrom(val.from);
                    setTo(val.to);
                }}
                tableRowKey="id"
                {...rest}
            />
            {registrationModal.visible && (
                <RegistrationModal
                    id={registrationModal.id}
                    visible={registrationModal.visible}
                    onClose={() => {
                        setRegistrationModal({ id: 0, visible: false });
                        if (location.pathname !== "/Compliance" && registrationId) {
                            history.push("/Compliance");
                        }
                    }}
                    onReloadData={() => {
                        setReloadData({ reload: true });
                        setSelectedRowKeys([]);
                    }}
                />
            )}
        </Fragment>
    );
};

export default Overview;
