import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "store";
import { Row, Col, Button } from "antd";
import Shortcut from "components/contentcomponents/home/shortcuts/shortcuts";
import classes from "./styles/home.module.scss";
import AcceptInvitationModal from "components/contentcomponents/suppliers/partnerOverviewModals/acceptInvitationModal";
import { IActionTableType } from "models/action";
import { Modal } from "antd/lib";
import { UserLandingPageContainer } from "components/contentcomponents/userLandingPage";
import { ActivityContainer } from "components/contentcomponents/home/activities/activityContainer";

interface IActionTableState {
    activeKey: any;
    type: IActionTableType;
}

const Home = () => {
    const { t } = useTranslation();
    const { token }: any = useParams();
    const history = useHistory();
    const [showAcceptInvitationModal, setShowAcceptInvitationModal] = useState<any>(false);
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const [toggleTable, setToggleTable] = useState<IActionTableState>({
        activeKey: undefined,
        type: IActionTableType.overdue,
    });

    useEffect(() => {
        if (token) {
            setShowAcceptInvitationModal(true);
        }
    }, [token]);

    return (
        <>
            <div className={classes.mainContainer}>
                <Row>
                    <Col span={24}>
                        <UserLandingPageContainer />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Shortcut />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {userType === "lightuser" ? (
                            <Fragment>
                                <div className={classes.lightUserMessageTitle}>
                                    {t("home_LightUser_Welcome", " Welcome to LeanLinking!")}
                                </div>
                                <div className={classes.lightUserMessageBody}>
                                    {t(
                                        "home_LightUser_Info",
                                        "Please select one of the shortcuts to provide your feedback."
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            <ActivityContainer />
                        )}
                    </Col>
                </Row>
            </div>
            <AcceptInvitationModal
                visible={showAcceptInvitationModal}
                onClose={() => {
                    setShowAcceptInvitationModal(false);
                    history.push("/");
                }}
            />
            <Modal
                style={{ top: 10 }}
                closeIcon={null}
                width={1000}
                open={toggleTable.activeKey !== undefined}
                footer={[
                    <Button
                        onClick={() =>
                            setToggleTable({
                                ...toggleTable,
                                activeKey: undefined,
                            })
                        }>
                        {t("generic_Close")}
                    </Button>,
                ]}
                onCancel={() =>
                    setToggleTable({
                        ...toggleTable,
                        activeKey: undefined,
                    })
                }></Modal>
        </>
    );
};

export default Home;
