import { useState } from "react";
import { Provider } from "services";

const useCategoryDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteCategories = async (categoryIds: number[]) => {
        setLoading(true);
        const response = await Provider.Partner.deleteCategories(categoryIds, {
            customError: {
                message: "Could remove selected category(s)",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return response;
    };

    return {
        loading,
        deleteCategories,
    };
};

export { useCategoryDelete };
