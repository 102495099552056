import React, { FunctionComponent, Fragment } from "react";
import classes from "./styles/shared.module.scss";
import { INewUserDetail } from "models";
import { useTranslation, Trans } from "react-i18next";

type PropsType = {
    data: INewUserDetail;
};

const NewUser: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                <Trans i18nKey="activity_WelcomeUser" values={{ fullname: data.fullName }}>
                    {t("activity_WelcomeUser")}
                </Trans>
            </div>
        </Fragment>
    );
};

export default NewUser;
