import { ITableInput } from "models";
import { post, get, remove, put } from "../base";

const AdminCompliance = {
    createTemplate: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/CreateTemplate`;
        return await post({ url, payload, customError });
    },
    getQuestionTypes: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompliance/QuestionTypeList`;
        return await get({ url, customError });
    },
    getCustomDropDowns: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/CustomDropdownList?sortByName=${sortByName}`;
        return await get({ url, customError });
    },
    getAdminTemplateGroups: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/TemplateGroupList?sortByName=${sortByName}`;
        return await get({ url, customError });
    },
    isTemplateNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/IsTemplateNameInUse?name=${encodeURIComponent(name)}`;
        return await get({ url, customError });
    },
    getTemplateGroupOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/GetTemplateGroupOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    updateTemplateGroupsOrder: async (
        id: number,
        oldOrder: number,
        newOrder: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompliance/UpdateTemplateGroupOrders?id=${id}&oldOrder=${oldOrder}&newOrder=${newOrder}`;
        return await post({ url, customError });
    },
    createTemplateGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/CreateTemplateGroup?name=${encodeURIComponent(
            payload.name
        )}`;
        return await post({ url, payload, customError });
    },
    updateTemplateGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/UpdateTemplateGroup?id=${
            payload.id
        }&name=${encodeURIComponent(payload.name)}`;
        return await post({ url, payload, customError });
    },
    deleteTemplateGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/DeleteTemplateGroups`;
        return await remove({ url, payload, customError });
    },
    isTemplateGroupNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/IsTemplateGroupInUse?name=${encodeURIComponent(name)}`;
        return await get({ url, customError });
    },
    getGateKeepersOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminCompliance/GateKeepersOverview";
        return await post({ url, payload: tableInput, customError });
    },
    getAdminComplianceStatuses: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompliance/GetAllComplianceStatuses";
        return await get({ url, customError });
    },
    getAllActiveTemplates: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompliance/GetAllActiveTemplates";
        return await get({ url, customError });
    },
    getProductTemplates: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompliance/GetActiveProductTemplates";
        return await get({ url, customError });
    },
    removeGateKeepers: async (ids: number[], { customError }: any = {}) => {
        const url = "/v1/AdminCompliance/DeleteGateKeepers";
        return await remove({ url, payload: ids, customError });
    },
    updateGateKeeper: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompliance/UpdateGateKeeper";
        return await put({ url, payload, customError });
    },
    createGateKeeper: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompliance/CreateGateKeeper";
        return await post({ url, payload, customError });
    },
    getTemplateMatrixOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/TemplatePartnerGroupMatrixOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    updateTemplateGroupMatrix: async (
        templateId: number,
        groupId: number,
        isCheck: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompliance/UpdateTemplatePartnerGroup?templateId=${templateId}&groupId=${groupId}&isCheck=${isCheck}`;
        return await post({ url, customError });
    },
    sendAdminSelfAssessment: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/SendSelfAssessments`;
        //we don't want to wait too much for this endpoint
        return await post({ url, payload, customError, customConfig: { timeout: 10000 } });
    },
    templateOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminCompliance/ComplianceTemplateOverview";
        return await post({ url, payload: tableInput, customError });
    },
    getTemplate: async (id: number, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/GetTemplateDetailById?id=${id}`;
        return await get({ url, customError });
    },
    updateTemplate: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/UpdateTemplate`;
        return await post({ url, payload, customError });
    },
    removeTemplate: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/DeleteTemplates`;
        return await remove({ url, payload: ids, customError });
    },
    copyTemplate: async (templateId: number, templateName: string, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/CopyTemplate?templateId=${templateId}&templateName=${encodeURIComponent(
            templateName
        )}`;
        return await post({ url, customError });
    },
    copyTemplateRegistrations: async (
        oldTemplateId: number,
        newTemplateId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompliance/CopyComplianceRegistrations?oldTemplateId=${oldTemplateId}&newTemplateId=${newTemplateId}`;
        return await post({ url, customError, customConfig: { timeout: 1000 } });
    },
    updateTemplateActivation: async (ids: number[], active: boolean, { customError }: any = {}) => {
        const url = `/v1/AdminCompliance/UpdateTemplateActivation?active=${active}`;
        return await put({ url, payload: ids, customError });
    },
    getTemplatesWithScoring: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompliance/GetEnabledScoreComplianceTemplates`;
        return await get({ url, customError });
    },
    UpdateSupplierProfileComplianceTemplate: async (
        templateId: number,
        complianceQuestionIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompliance/UpdateSupplierProfileComplianceDefaults`;
        return await post({
            url,
            payload: { templateId: templateId, complianceQuestionIds },
            customError,
        });
    },
    deleteSupplierProfileComplianceTemplateDefaults: async () => {
        let url = "/v1/AdminCompliance/DeleteSupplierProfileComplianceDefaults";
        return await remove({ url, payload: {}, customError: {} });
    },
};

export { AdminCompliance };
