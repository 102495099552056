import { useEffect, useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

type StateType = {
    data: number | "";
    loading: boolean;
};

const useRegistrationScore = (partnerId: number, from: string, to: string) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: "",
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Partner.getComplianceScore(partnerId, from, to, {
                customError: {
                    message: t("supplierProfile_PerformanceComplianceScoreError"),
                },
            }).catch(err => {
                setState({ loading: false, data: "" });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useRegistrationScore };
