import React, { FunctionComponent, Fragment, useEffect, useState } from "react";
import { Form, Modal, Row, Col, Input, DatePicker, Tooltip, notification, Button } from "antd";
import { SelectData, SelectTreeData } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import {
    useActionCRUD,
    useCompanyType,
    usePartnerList,
    useDevelopmentPlanByPartnerId,
} from "controller";
import {
    IActionType,
    ICreateEditAction,
    IActionPerformanceValues,
    IActionSegmentationValues,
} from "models/action";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { SelectExternalContacts } from "components/utilitycomponents/select/selectExternalContacts/selectExternalContacts";
import { CreateModal as CreateContactModal } from "../../contacts/shared";
import { PlusOutlined } from "@ant-design/icons";
import { SegmentationData } from "./segmentationData";
import { PerformanceData } from "./performanceData";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    registrationType: IActionType;
    registrationId: number;
    supplierId: number | undefined;
    visible: boolean;
    onClose: Function;
    data?: any;
}

const RegistrationCreateModal: FunctionComponent<IProps> = ({
    visible,
    onClose,
    registrationType,
    registrationId,
    supplierId,
    data,
}) => {
    const currentUserContactId = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-ContactId"]
    );
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { createAction, loading, creating } = useActionCRUD();
    const { isDefaultCompany } = useCompanyType();
    const [createModal, setCreateModal] = useState<any>({
        visible: false,
        newContacts: [],
    });
    const [reload, setReload] = useState<any>({ reload: false });
    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    const handleSave = () => {
        let performanceValuesList: IActionPerformanceValues[];
        let segmentationValues: IActionSegmentationValues | null = null;

        if (registrationType === IActionType.Performance) {
            performanceValuesList = Object.keys(data.values).map((key: string, index: number) => {
                var name =
                    key === "TotalAverage"
                        ? t("supplierProfile_Performance_TotalScore")
                        : data.columns.filter(
                              (col: any) => "CategoryGroup" + col.criteriaId.toString() === key
                          )[0].criteriaName;
                return {
                    name: name,
                    value: data.values[key],
                    order: name === "Total Score" ? 0 : index + 1,
                };
            }) as IActionPerformanceValues[];
        }

        if (registrationType === IActionType.Segmentation) {
            segmentationValues = {
                xAxisAvg: data.xAxisAvg ? data.xAxisAvg : null,
                yAxisAvg: data.yAxisAvg ? data.yAxisAvg : null,
                assignedCategorySettingId: data.alignedSegmentCurrent
                    ? data.alignedSegmentCurrent.id
                    : null,
                suggestedCategorySettingId: data.suggestedSegmentCurrent
                    ? data.suggestedSegmentCurrent.id
                    : null,
            };
            if (
                segmentationValues.xAxisAvg == null &&
                segmentationValues.yAxisAvg == null &&
                segmentationValues.assignedCategorySettingId == null &&
                segmentationValues.suggestedCategorySettingId == null
            ) {
                segmentationValues = null;
            }
        }

        form.validateFields()
            .then(async values => {
                let action: ICreateEditAction = {
                    type: registrationType,
                    registrationId: registrationId,
                    description: values.description && values.description,
                    ownerId: values.owners,
                    actionPartnerIds: values.supplier && [values.supplier],
                    dueDate: values.dueDate && values.dueDate.toISOString(),
                    title: values.title && values.title,
                    ActionPerformanceValues: performanceValuesList,
                    developmentPlanId: values.developmentPlan,
                    actionSegmentationValue: segmentationValues,
                };
                await createAction(action);
                notification.success({
                    message: t("success"),
                    description: t("action_CreatedSuccess"),
                });
                form.resetFields();
                onClose();
            })
            .catch(err => {
                return;
            });
    };

    const getRegistrationTypeName = (type: IActionType) => {
        switch (type) {
            case IActionType.Compliance:
                return t("compliance_Compliance");
            case IActionType.NCR:
                return t("ncr_NCR");
            case IActionType.Review:
                return t("review_review");
            case IActionType.Segmentation:
                return t("segmentation_segmentation");
            default:
                return "";
        }
    };

    const getDefaultTitle = (type: IActionType) => {
        switch (type) {
            case IActionType.Segmentation:
                return undefined;
            case IActionType.MeetingMinute:
                return undefined;
            case IActionType.Performance:
                return undefined;
            default:
                return `${getRegistrationTypeName(registrationType)} id ${registrationId}`;
        }
    };

    function handleOnClose() {
        form.resetFields();
        onClose();
    }

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    return (
        <Fragment>
            <Modal
                confirmLoading={loading === true || creating === true}
                onOk={handleSave}
                width={1200}
                onCancel={() => handleOnClose()}
                open={visible}
                title={t("action_CreateAction")}
                footer={[
                    <Button
                        loading={loading === true || creating === true}
                        type="primary"
                        onClick={async () => await handleSave()}>
                        {t("generic_Create")}
                    </Button>,
                ]}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        title: getDefaultTitle(registrationType),
                        supplier: supplierId !== null ? supplierId : undefined,
                    }}>
                    <Row gutter={8}>
                        <Col span={4}>
                            <Tooltip title={t("actions_IdCreationTooltip")}>
                                <Form.Item name="id" label="Id" valuePropName="value">
                                    <Input placeholder={t("actions_IdPlaceholder")} disabled />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="title"
                                label="Title"
                                valuePropName="value"
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <Input
                                    maxLength={500}
                                    placeholder={t("actions_TitlePlaceholder")}
                                    disabled={
                                        registrationType === IActionType.Segmentation ||
                                        registrationType === IActionType.MeetingMinute ||
                                        registrationType === IActionType.Performance
                                            ? false
                                            : true
                                    }
                                    autoFocus
                                />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name="description" label="Description" valuePropName="value">
                                <Input
                                    maxLength={1000}
                                    placeholder={t("actions_DescriptionPlaceholder")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name="supplier"
                                label={
                                    isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer")
                                }>
                                <SelectTreeData
                                    disabled
                                    selectOptionField="name"
                                    useData={usePartnerList.bind(null)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={9}>
                            <Form.Item
                                name="owners"
                                label="Owner"
                                initialValue={[parseInt(currentUserContactId)]}
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <SelectExternalContacts
                                    placeholder={t("actions_OwnerPlaceholder")}
                                    selectOptionField="name"
                                    supplierId={supplierId}
                                    reload={reload}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Tooltip title={t("Contacts_Add")}>
                                <Button
                                    type="primary"
                                    style={{ marginTop: "30px" }}
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        setCreateModal({ newContacts: [], visible: true })
                                    }></Button>
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="dueDate" label="Due Date">
                                <DatePicker
                                    allowClear={true}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={t("developmentPlan_AddActionToPlan")}
                                name="developmentPlan">
                                <SelectData
                                    allowClear
                                    selectIdField="id"
                                    useData={useDevelopmentPlanByPartnerId.bind(null, supplierId)}
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                    selectOptionField="name"
                                    placeholder={t("developmentPlan_SelectPlanPlaceHolder")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {data && registrationType === IActionType.Segmentation && (
                        <SegmentationData data={data} editMode={false} />
                    )}
                    {data && registrationType === IActionType.Performance && (
                        <PerformanceData data={data} />
                    )}
                </Form>
            </Modal>
            {createModal.visible && (
                <CreateContactModal
                    visible={createModal.visible}
                    onClose={(reload, contactId) => {
                        if (reload) {
                            setCreateModal({ newContacts: [contactId], visible: false });
                            setReload({ reload: true });
                            let selectedContacts = form.getFieldValue("owners");
                            form.setFieldsValue({ owners: selectedContacts.concat([contactId]) });
                        } else {
                            setCreateModal({ newContacts: [], visible: false });
                        }
                    }}
                    partnerId={supplierId}
                    showSupplierSelect
                />
            )}
        </Fragment>
    );
};

export default RegistrationCreateModal;
