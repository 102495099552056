import { useState } from "react";
import { Provider } from "services";

const useComplianceCreate = () => {
    const [loading, setLoading] = useState(false);

    const create = async (complianceCreateInput: any) => {
        setLoading(true);
        const id = await Provider.Compliance.createCompliance(complianceCreateInput, {
            customError: {
                message: "Could not create new registration",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const copyRegistration = async (id: number) => {
        setLoading(true);
        const newId = await Provider.Compliance.copyRegistration(id, {
            customError: {
                message: "Could not copy the registration",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return newId;
    };

    return {
        loading,
        create,
        copyRegistration,
    };
};

export { useComplianceCreate };
