import dayjs from "utilities/daysJsConfig";
import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveyRelatedToSegmentationUnit = (segmentationAnalysisId = -1) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (segmentationAnalysisId && segmentationAnalysisId !== -1) {
                let data = await Provider.Survey.getSurveysRelatedToSegmentationUnit(
                    segmentationAnalysisId
                ).catch((err: any) => {
                    setState({ loading: false, data: [] });
                    return Promise.reject(err);
                });
                data = data.map((survey: any) => ({
                    ...survey,
                    name: `(${dayjs(survey.created).format("DD.MM.YYYY")}) ${survey.title}`,
                }));
                setState({
                    loading: false,
                    data: data,
                });
            }
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyRelatedToSegmentationUnit };
