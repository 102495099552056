import React, { FunctionComponent } from "react";
import { Modal, Alert, Tag, notification, Button } from "antd";
import classes from "./styles/deleteModal.module.scss";
import { useNcrDelete } from "controller";
import { useTranslation } from "react-i18next";

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    selectedRowKeys: any[];
    reloadTable: Function;
    clearSelectedRows: Function;
}

const DeleteModal: FunctionComponent<IProps> = ({
    visible,
    onVisibleChange,
    selectedRowKeys,
    reloadTable,
    clearSelectedRows,
}) => {
    const { deleteNcrs, loading } = useNcrDelete();
    const { t } = useTranslation();
    function onCancel() {
        onVisibleChange();
    }

    function renderTags() {
        return selectedRowKeys.map((row: any) => {
            return (
                <Tag color="blue" key={row}>
                    {row}
                </Tag>
            );
        });
    }

    async function onSubmit() {
        await deleteNcrs(selectedRowKeys);
        reloadTable();
        onVisibleChange(false);
        clearSelectedRows();
        notification.success({
            message: t("success"),
            description: t("ncrDeletetionSuccess"),
        });
    }

    return (
        <Modal
            wrapProps={{ "data-test": "delete_modal" }}
            title={t("ncrDeleteModalTitle")}
            open={visible}
            onCancel={onCancel}
            onOk={onSubmit}
            footer={[
                <Button
                    loading={loading}
                    type="primary"
                    danger
                    onClick={async () => await onSubmit()}>
                    {t("generic_Confirm")}
                </Button>,
            ]}>
            <div>
                <div className={classes.tagAreaContainer}>
                    <div className={classes.tagLabel}>{t("selectedNcrs")}</div>
                    <div className={classes.tagArea}>{renderTags()}</div>
                </div>
                <Alert
                    message={t("attention")}
                    type={"warning"}
                    description={<div>{t("ncrDeleteWarning")}</div>}
                />
            </div>
        </Modal>
    );
};

export default DeleteModal;
