import React, { FunctionComponent, Fragment, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { GridButton, GridDeleteModal, PlusCircle } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useAutomatedSurveyDelete } from "controller";

type PropsType = {
    onCreateBtnClick: Function;
    selectedRowKeys: number[];
    selectedRows: any[];
    onUpdateSelectedRows: Function;
    onUpdateSelectedRowKeys: Function;
    onReloadData: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    onCreateBtnClick,
    selectedRows,
    selectedRowKeys,
    onUpdateSelectedRows,
    onUpdateSelectedRowKeys,
    onReloadData,
}) => {
    const { t } = useTranslation();
    const { loading, deleteAutomatedSurveys } = useAutomatedSurveyDelete();
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });
    const buttonVisibility = selectedRowKeys.length < 1;

    const handleDelete = async () => {
        await deleteAutomatedSurveys(selectedRowKeys);
        notification.success({
            message: t("success"),
            description: t(`autoSurvey_DeleteSuccess`),
        });
        setDeleteModal({
            visible: false,
        });
        onUpdateSelectedRowKeys([]);
        onUpdateSelectedRows([]);
        onReloadData();
    };

    return (
        <Fragment>
            {buttonVisibility ? (
                <GridButton
                    data-test="create_autoSurvey_button"
                    style={{ marginLeft: "6px" }}
                    onClick={() => onCreateBtnClick()}>
                    <PlusCircle />
                    {t("autoSurvey_CreateTitle", "Create Automated Survey")}
                </GridButton>
            ) : (
                <GridButton
                    danger
                    data-test="delete_button"
                    icon={<DeleteOutlined />}
                    iconColor="#FF4D4F"
                    type="default"
                    loading={loading}
                    onClick={() => setDeleteModal({ visible: true })}>
                    {t("autoSurvey_DeleteAutoSurvey")}
                </GridButton>
            )}
            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                    });
                }}
                data={selectedRows.map((r: any) => ({
                    id: r.id,
                    name: `${r.title} (${r.id})`,
                }))}
                name={t("autoSurvey_Surveys", "Registrations")}
                confirmLoading={loading}
                onConfirm={handleDelete}
            />
        </Fragment>
    );
};

export default OverviewHeader;
