import React, { FunctionComponent } from "react";
import { LoadingOutlined } from "@ant-design/icons";
type PropsType = {
    style?: any;
    [key: string]: any;
};
const LoadingIndicator: FunctionComponent<PropsType> = ({ style, ...rest }) => {
    return <LoadingOutlined {...rest} type="loading" style={{ color: "#1890ff", ...style }} />;
};

export { LoadingIndicator };
