import { useState } from "react";
import { Provider } from "services";

const usePartnerStatusDelete = () => {
    const [loading, setLoading] = useState(false);

    const remove = async (ids: number[]) => {
        setLoading(true);

        const res = await Provider.Partner.deletePartnerStatus(ids, {
            customError: {
                message: "Could not delete status category(s)",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        remove,
    };
};

export { usePartnerStatusDelete };
