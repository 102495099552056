import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tag, Spin, notification } from "antd";
import EditModal from "./editModal";
import { useSupplierCardsRisks, useSupplierCardsRiskUpdate } from "controller";
import { AttachFile, ShowMore } from "components/utilitycomponents";

type PropsType = {
    partnerId: number;
};

const RiskTable: FunctionComponent<PropsType> = ({ partnerId }) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState({ changed: false });
    const { loading, data } = useSupplierCardsRisks(partnerId, reload);
    const { updateRiskFile } = useSupplierCardsRiskUpdate();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [risk, setRisk] = useState({});

    const [attachedFileList, setAttachedFileList] = useState<any>();

    useEffect(() => {
        if (data) {
            setAttachedFileList(
                data.riskFileId ? [{ id: data.riskFileId, name: data.riskFileName }] : undefined
            );
        }
    }, [data]);

    const handleAttachFile = async (fileIds: number[]) => {
        await updateRiskFile(partnerId, fileIds.length ? fileIds[0] : 0);
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateRiskFileSuccess"),
        });
    };

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setRisk(record);
            setEditModalVisible(true);
        },
        style: {
            cursor: "pointer",
        },
    });

    const getTagColor = (value: string) => {
        if (value === "Low") return "#00b204";
        if (value === "Medium") return "#fa9308";
        if (value === "High") return "#b20000";
    };

    const getTranslationKey = (value: string) => {
        switch (value) {
            case "Low":
                return "supplierProfile_RiskLow";
            case "Medium":
                return "supplierProfile_RiskMedium";
            case "High":
                return "supplierProfile_RiskHigh";
            default:
                return value;
        }
    };

    let riskTypes: any = {};
    if (data) {
        data.risks.forEach((r: any) => {
            riskTypes[r.id] = r;
        });
    }

    const tagWidth = "60px";

    return (
        <Spin spinning={loading}>
            <Table
                rowKey="riskTypeId"
                pagination={false}
                bordered
                scroll={{ x: 700 }}
                onRow={handleOnRow}
                title={() => (
                    <b>
                        {t("supplierProfile_TotalRisk")}:{" "}
                        {data && data.totalRisk && data.totalRiskScore && (
                            <Fragment>
                                {data.totalRiskScore}{" "}
                                <Tag
                                    style={{ width: tagWidth, textAlign: "center" }}
                                    color={getTagColor(data.totalRisk)}>
                                    {t(getTranslationKey(data.totalRisk))}
                                </Tag>
                            </Fragment>
                        )}
                    </b>
                )}
                columns={[
                    {
                        title: t("supplierProfile_TypeOfRisk"),
                        dataIndex: "name",
                        width: 200,
                        render: (val: string) => <div>{t(`riskType_${val}`)}</div>,
                    },
                    {
                        title: t("supplierProfile_RiskLikelihood"),
                        dataIndex: "likelihood",
                        width: 200,
                        align: "center",
                        render: value =>
                            value ? (
                                <Tag style={{ width: tagWidth }} color={getTagColor(value)}>
                                    {t(getTranslationKey(value))}
                                </Tag>
                            ) : null,
                    },
                    {
                        title: t("supplierProfile_RiskSeverity"),
                        dataIndex: "severity",
                        align: "center",
                        width: 200,
                        render: value =>
                            value ? (
                                <Tag style={{ width: tagWidth }} color={getTagColor(value)}>
                                    {t(getTranslationKey(value))}
                                </Tag>
                            ) : null,
                    },
                    {
                        title: t("supplierProfile_RiskScore"),
                        dataIndex: "score",
                        align: "center",
                        width: 150,
                        render: (value: string, record: any) =>
                            value ? (
                                <Fragment>
                                    <span style={{ paddingRight: "6px" }}>
                                        <b>{value}</b>
                                    </span>
                                    <Tag
                                        style={{ width: tagWidth }}
                                        color={getTagColor(record["scoreRisk"])}>
                                        {t(getTranslationKey(record["scoreRisk"]))}
                                    </Tag>
                                </Fragment>
                            ) : null,
                    },
                    {
                        title: t("supplierProfile_RiskComment"),
                        dataIndex: "comment",
                        render: value =>
                            value ? (
                                <ShowMore
                                    text={value}
                                    characterLimit={200}
                                    lineLimit={10}
                                    showGuidText={false}
                                />
                            ) : null,
                    },
                ]}
                dataSource={data ? data.data : []}
                footer={() => (
                    <AttachFile
                        showMaxFileSize={false}
                        multiple={false}
                        onChange={handleAttachFile}
                        uploadSectionLayout={{ style: { display: "table" } }}
                        uploadButtonLayout={{ style: { display: "table-cell" } }}
                        filesSectionLayout={{
                            style: {
                                display: "table-cell",
                                width: "100%",
                                padding: "0 10px",
                            },
                        }}
                        attachedFileList={attachedFileList}>
                        {t("supplierProfile_AddAFile")}
                    </AttachFile>
                )}
            />

            {data && data.risks && (
                <EditModal
                    partnerId={partnerId}
                    data={risk}
                    riskTypes={data.risks}
                    visible={editModalVisible}
                    onClose={() => {
                        setEditModalVisible(false);
                    }}
                    onReloadTable={() => setReload({ changed: true })}
                />
            )}
        </Spin>
    );
};

export default RiskTable;
