import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const usePartnerOwners = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.User.getPartnerOwners(
                { sortByName: true, users: ["Administrator", "User"] },
                {
                    customError: {
                        message: "Could not retrieve partner owners",
                    },
                }
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ ...state, loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerOwners };
