import { Button, Result, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSupplierPortalSurveyRequestCount } from "controller";
import classes from "./supplierLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { Star01 } from "components/utilitycomponents";

const SurveyCount = () => {
    const { data, loading } = useSupplierPortalSurveyRequestCount();
    const { t } = useTranslation("supplierPortal");

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Result
                    className={classes.resultPadding}
                    icon={<Star01 style={{ fontSize: 72 }} />}
                    title={
                        <span>
                            {t("generic_YouHave")} <span className={classes.count}>{data}</span>{" "}
                            {t("survey_LandingPageComponentText")}
                        </span>
                    }
                    extra={
                        <Link to="/SurveyRequests">
                            <Button type="primary">{t("generic_See")}</Button>
                        </Link>
                    }
                />
            )}
        </>
    );
};

export { SurveyCount };
