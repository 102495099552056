enum ShortcutType {
    "customShortcut" = 1,
    "shortcuts_goToSupplier" = 2,
    "survey_CreateNewSegmentation" = 3,
    "performance_CreateNewPerformance" = 4,
    "uploadFiles" = 5,
    "addSuppliers" = 6,
    "admin_User_InviteUsers" = 7,
    "createReview" = 8,
    "goToTemplates" = 9,
    "requestInfo" = 10,
    "createNcr" = 11,
    "action_CreateAction" = 12,
    "developmentPlan_AddPlan" = 13,
    "requestProductInfo" = 14,
    "createMeetingMinute" = 15,
}

export { ShortcutType };
