import React, { useState, useEffect } from "react";
import { Modal, Select, Input, notification, Spin, Form, Button } from "antd";
import classes from "./styles/editForm.module.scss";
import {
    SelectExternalPartner,
    AttachFile,
    Star,
    SelectTreeData,
} from "components/utilitycomponents";
import {
    useCompanyType,
    useReviewCategory,
    useReviewRegistration,
    useReviewUpdate,
} from "controller";
import { useTranslation } from "react-i18next";
import { getCategoryStarText } from "../../../criteriaStarTexts";
const Option = Select.Option;
const TextArea = Input.TextArea;

interface IProps {
    id: number;
    visible: boolean;
    onVisibleChange: Function;
    reloadTable?: Function;
    reloadRegistrationModal?: Function;
}

const EditModal = ({
    id,
    visible,
    onVisibleChange,
    reloadTable,
    reloadRegistrationModal,
}: IProps) => {
    const [form] = Form.useForm();
    const [selectedPartner, setSelectedPartner] = useState<any>(undefined);
    const { data } = useReviewRegistration(id, visible);
    const { updateReview, loading } = useReviewUpdate();
    const [fileList, setFileList] = useState<any[]>();
    const { t } = useTranslation();
    const [selectedRating, setSelectedRating] = useState<any>(undefined);
    const [selectedCriteria, setSelectedCriteria] = useState<any>(undefined);
    const { data: categories } = useReviewCategory(
        data && data.categoryId !== null ? data.categoryId : -1
    );
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        if (visible === true) {
            if (data) {
                let currentCriteria = undefined;
                categories.forEach((x: any) => {
                    if (x.children && x.children.some((c: any) => c.id === data.categoryId)) {
                        currentCriteria = x.children.filter(
                            (c: any) => c.id === data.categoryId
                        )[0];
                    } else if (x.id === data.categoryId) {
                        currentCriteria = x;
                    }
                });
                setSelectedCriteria(currentCriteria);
                setSelectedRating(data.rating);
                setFileList(
                    data.attachments !== undefined &&
                        data.attachments.map((file: any) => ({
                            id: file.fileId,
                            name: file.fileName,
                        }))
                );
            }
        }
        form.resetFields();
    }, [data, visible]);

    function getFileIds(val: any) {
        let fileIds: number[] = [];
        if (val) {
            if (val.length === 0) {
                fileIds = [];
            } else if (val[0].id) {
                fileIds = [];
                for (let fileItem of val) {
                    fileIds.push(fileItem.id);
                }
            } else {
                fileIds = val;
            }
        }
        return fileIds;
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await updateReview(id, {
            reviewedPartnerId: values.externalPartner,
            reviewedPartnerName: selectedPartner && selectedPartner.name,
            categoryId: values.reviewCriteria,
            notes: values.notes,
            rating: values.reviewRating,
            visibility: values.visibility,
            attachmentIds: getFileIds(values.attachedFiles),
        });
        notification.success({
            message: t("Success"),
            description: t("review_UpdateSuccess"),
        });
        reloadRegistrationModal && reloadRegistrationModal();
        setSelectedPartner(undefined);
        reloadTable && reloadTable();
        onVisibleChange();
        form.resetFields();
    }

    function onCancel() {
        setSelectedPartner(undefined);
        form.resetFields();
        onVisibleChange();
    }

    return data ? (
        <Modal
            wrapProps={{ "data-test": "review_edit_modal" }}
            title={"Edit Review"}
            open={visible}
            onCancel={onCancel}
            onOk={onSubmit}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSubmit()}>
                    {t("generic_Save")}
                </Button>,
            ]}
            width={900}
            style={{ top: 20 }}
            destroyOnClose>
            <Form
                form={form}
                onFinish={onSubmit}
                labelAlign="left"
                wrapperCol={{ span: 18 }}
                labelCol={{ span: 6 }}>
                <Form.Item
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }
                    className={classes.formItem}
                    name="externalPartner"
                    valuePropName="value"
                    initialValue={
                        visible && data && data.reviewedPartnerId ? data.reviewedPartnerId : null
                    }>
                    <SelectExternalPartner
                        disabled={true}
                        showCreationButton={false}
                        allowClear
                        onChange={(val: any, item: any) => {
                            setSelectedPartner(item);
                        }}
                        placeholder={`${t("select")} ${
                            isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer")
                        }`}
                        includeChildren
                    />
                </Form.Item>

                <Form.Item
                    label={t("review_Criteria")}
                    className={classes.formItem}
                    name="reviewCriteria"
                    initialValue={data && data.categoryId}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}
                    extra={selectedCriteria !== undefined ? selectedCriteria.description : ""}>
                    <SelectTreeData
                        disabled={data && data.isVoiceOfSupplier}
                        placeholder={`${t("select")} ${t("review_Criteria")}`}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                        selectOptionField="name"
                        useData={() => {
                            return { data: categories, loading: false };
                        }}
                        onChange={(val: any, valItem: any) =>
                            setSelectedCriteria(valItem && valItem)
                        }
                        selectOnlyChildren
                    />
                </Form.Item>

                <Form.Item
                    label={t("review_Rating")}
                    className={classes.formItem}
                    name="reviewRating"
                    initialValue={data && data.rating}
                    valuePropName="value"
                    required={true}
                    rules={[
                        {
                            required: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}
                    extra={getCategoryStarText(selectedRating, selectedCriteria)}>
                    <Star
                        data-test="review_rating"
                        onHover={(val: number) => setSelectedRating(val)}
                    />
                </Form.Item>

                <Form.Item
                    label={t("review_Notes")}
                    data-test="edit_review_comment_textarea"
                    className={classes.formItem}
                    name="notes"
                    initialValue={data && data.notes}>
                    <TextArea maxLength={1000} />
                </Form.Item>

                <Form.Item
                    label={t("review_Visibility")}
                    className={classes.formItem}
                    name="visibility"
                    initialValue={data && data.visibility}>
                    <Select
                        disabled={data && data.isVoiceOfSupplier}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }>
                        <Option key={0} value={"Internal"}>
                            {t("internal")}
                        </Option>
                        <Option key={1} value={"External"}>
                            {t("external")}
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("review_Attachments")}
                    data-test="edit_attached_files"
                    className={classes.formItem}
                    name="attachedFiles"
                    initialValue={fileList}>
                    <AttachFile maxFileSize={40} attachedFileList={fileList} />
                </Form.Item>
            </Form>
        </Modal>
    ) : (
        <Spin spinning={loading} />
    );
};

export default EditModal;
