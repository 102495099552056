import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    DatePicker,
    Input,
    Divider,
    Button,
    Spin,
    Tooltip,
    Popconfirm,
    Card,
} from "antd";
import { useTranslation } from "react-i18next";
import {
    AttachFile,
    Edit02,
    MinusCircle,
    PureDiv,
    RichTextEditor,
    SelectData,
    SelectPartnerContacts,
    SelectTreeDataMultiple,
} from "components/utilitycomponents";
import {
    useActionCRUD,
    useMeetingMinuteTypes,
    useMeetingMinute,
    useMeetingMinutesCRUD,
    useInternalAndExternalContacts,
    useContactsWithGroups,
} from "controller";
import { Modules } from "models";
import classes from "./meetingCalendar.module.scss";
import { IActionType, ICreateEditAction } from "models/action";
import { CreateModal as CreateContactModal } from "../../../../../contacts/shared/createModal";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { TagRender } from "../shared/tagRender";
import dayjs from "utilities/daysJsConfig";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { Moment } from "moment";
import moment from "moment";
const MomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

interface IProps {
    visible: boolean;
    onClose: Function;
    meetingId: any;
    onActionInteraction: Function;
    partnerId: number;
    [key: string]: any;
}

const EditModal: FunctionComponent<IProps> = ({
    visible,
    onClose,
    meetingId,
    onActionInteraction,
    partnerId,
    ...rest
}) => {
    const [form] = Form.useForm();
    const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });
    const { t } = useTranslation();
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);

    const {
        deleteActions,
        updateAction,
        createAction,
        reOpenActions,
        completeActions,
        creating: creatingAction,
    } = useActionCRUD();
    const { updateMeetingMinute, getMeetingMinuteActions, loading } = useMeetingMinutesCRUD();
    const [reload, setReload] = useState({ reload: false });
    const { data } = useMeetingMinute(reload, meetingId);
    const [fileList, setFileList] = useState<any[]>();
    const { data: contacts, loading: gettingContacts } = useInternalAndExternalContacts(
        partnerId,
        reload
    );
    const [actionLoading, setActionLoading] = useState<any>(undefined);
    const [actions, setActions] = useState<any[]>([]);
    const [toggleEditDescription, setToggleEditDescription] = useState(false);
    const [toggleEditOutcome, setToggleEditOutcome] = useState(false);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [outcome, setOutcome] = useState<string | undefined>(undefined);
    const { data: companyContacts, loading: loadingCompanyContacts } = useContactsWithGroups(
        ["Administrator", "User", "LightUser"],
        Modules.Partner,
        reloadContacts
    );
    const [contactModal, setContactModal] = useState<any>({
        visible: false,
    });

    useEffect(() => {
        if (data) {
            if (data.files) {
                setFileList(
                    data.files.map((file: any) => ({
                        id: file.id,
                        name: file.name,
                    }))
                );
            }
            setActions(data.actions);
            setDescription(data.description);
            setOutcome(data.outcome);
        }
        form.resetFields();
    }, [visible, data]);

    useEffect(() => {
        if (visible) {
            setReloadContacts({ reload: true });
        }
    }, [visible]);

    const handleOnOk = () => {
        form.validateFields()
            .then(async values => {
                let input = {
                    id: meetingId,
                    title: values.title,
                    partnerId: partnerId,
                    description: values.description,
                    date: values.datetime,
                    typeId: values.type,
                    outcome: values.outcome,
                    internalRepresentatives: values.internalReps,
                    externalRepresentatives: values.externalReps,
                    fileIds: values.files,
                };
                await updateMeetingMinute(input);
                onClose(true);
            })
            .catch(err => {
                return;
            });
    };

    const handleActionUpdate = async (
        type: "title" | "description" | "dueDate" | "owner",
        action: any,
        payLoad: any,
        reload: boolean = false
    ) => {
        await updateAction(action.id, {
            type: IActionType.MeetingMinute,
            registrationId: data.id,
            title: type === "title" ? payLoad : form.getFieldValue(`title${action.id}`),
            description:
                type === "description" ? payLoad : form.getFieldValue(`description${action.id}`),
            ownerId:
                type === "owner"
                    ? payLoad
                        ? [payLoad]
                        : undefined
                    : form.getFieldValue(`owner${action.id}`) !== undefined
                    ? [form.getFieldValue(`owner${action.id}`)]
                    : [],
            actionPartnerIds: [data.partnerId],
            dueDate: type === "dueDate" ? payLoad : form.getFieldValue(`dueDate${action.id}`),
        });

        if (reload) {
            onActionInteraction();
        }
    };

    const handleActionCreate = async () => {
        let action: ICreateEditAction = {
            type: IActionType.MeetingMinute,
            registrationId: data.id,
            title: "",
            description: undefined,
            ownerId: undefined,
            actionPartnerIds: [data.partnerId],
            dueDate: undefined,
        };
        await createAction(action);
        let newActions = await getMeetingMinuteActions(meetingId);
        setActions(newActions);
        await onActionInteraction();
    };

    const handleActionDelete = async (action: any) => {
        await deleteActions([action.id]);
        let newActions = await getMeetingMinuteActions(meetingId);
        setActions(newActions);
        await onActionInteraction();
    };

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    return (
        <div>
            <Modal
                width={900}
                title={t("governance_EditMeeting")}
                open={visible}
                onOk={() => handleOnOk()}
                onCancel={() => onClose()}
                footer={[
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={async () => await handleOnOk()}>
                        {t("generic_Save")}
                    </Button>,
                ]}
                {...rest}>
                <div
                    style={{ background: "#fff", position: "relative" }}
                    id="meetingCalendarEdit"></div>
                <Spin spinning={loading}>
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={t("generic_Type")}
                                    name="type"
                                    required
                                    rules={[{ required: true }]}
                                    initialValue={data && data.typeId}>
                                    <SelectData
                                        useData={useMeetingMinuteTypes}
                                        selectOptionField="name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={t("generic_Date")}
                                    name="datetime"
                                    required
                                    rules={[{ required: true }]}
                                    initialValue={data && moment(data.date)}>
                                    <MomentDatePicker
                                        style={{ width: "100%" }}
                                        showTime={{ format: "HH:mm" }}
                                        minuteStep={15 as any}
                                        format="MMM D, YYYY (HH:mm)"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={t("generic_Title")}
                                    name="title"
                                    required
                                    rules={[{ required: true }]}
                                    initialValue={data && data.title}>
                                    <Input
                                        placeholder={t("generic_Placeholder-Text")}
                                        maxLength={255}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <Row>
                                            <Col span={22}>{t("generic_Description")}</Col>
                                            <Col span={2}>
                                                <Tooltip title={`${t("generic_EditClickTooltip")}`}>
                                                    <span>
                                                        <Edit02
                                                            onClick={() =>
                                                                setToggleEditDescription(
                                                                    !toggleEditDescription
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    }
                                    name="description"
                                    initialValue={
                                        data && data.description ? data.description : undefined
                                    }>
                                    {toggleEditDescription ? (
                                        <RichTextEditor
                                            placeholder={t("generic_Placeholder-Text")}
                                            maxLength={10000}
                                            bordered
                                            showSaveButton={true}
                                            onSaveButtonClick={() =>
                                                setToggleEditDescription(!toggleEditDescription)
                                            }
                                            onChange={(val: string) => setDescription(val)}
                                        />
                                    ) : (
                                        <Card bodyStyle={{ padding: 4, minHeight: 30 }}>
                                            <PureDiv textContent={description ? description : ""} />
                                        </Card>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <Row>
                                            <Col span={22}>{t("governance_Outcome")}</Col>
                                            <Col span={2}>
                                                <Tooltip title={`${t("generic_EditClickTooltip")}`}>
                                                    <span>
                                                        <Edit02
                                                            onClick={() =>
                                                                setToggleEditOutcome(
                                                                    !toggleEditOutcome
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    }
                                    name="outcome"
                                    initialValue={data && data.outcome ? data.outcome : undefined}>
                                    {toggleEditOutcome ? (
                                        <RichTextEditor
                                            placeholder={t("Outcome")}
                                            maxLength={10000}
                                            bordered
                                            showSaveButton={true}
                                            onSaveButtonClick={() =>
                                                setToggleEditOutcome(!toggleEditOutcome)
                                            }
                                            onChange={(val: any) => setOutcome(val)}
                                        />
                                    ) : (
                                        <Card bodyStyle={{ padding: 4, minHeight: 30 }}>
                                            <PureDiv textContent={outcome ? outcome : ""} />
                                        </Card>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation="left" orientationMargin={0}>
                            {t("governance_Attendants")}
                        </Divider>
                        <Row gutter={8}>
                            <Col span={12}>
                                <span className={classes.representativeLabel}>
                                    {t("meetingForum_CompanyMember", {
                                        name: companyName,
                                        interpolation: { escapeValue: false },
                                    })}
                                </span>
                                <div className={classes.box}>
                                    <Form.Item
                                        wrapperCol={{ span: 22 }}
                                        colon={false}
                                        name="internalReps"
                                        initialValue={
                                            data && data.internalRepresentatives
                                                ? data.internalRepresentatives
                                                : []
                                        }>
                                        <SelectTreeDataMultiple
                                            style={{ width: "100%" }}
                                            bordered={false}
                                            allowClear={true}
                                            useData={() => ({
                                                data: companyContacts,
                                                loading: loadingCompanyContacts,
                                            })}
                                            selectAll
                                            selectOptionField="name"
                                            placeholder={t("select")}
                                            getPopupContainer={(node: any) =>
                                                document.getElementById("meetingCalendarEdit")
                                            }
                                            selectOnlyChildren
                                            tagRender={(props: any) => TagRender(props, true)}
                                            dropdownRender={(menu: any) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: "8px 0" }} />
                                                    <Button
                                                        block
                                                        size="small"
                                                        onClick={() =>
                                                            setContactModal({
                                                                visible: true,
                                                                partnerId: undefined,
                                                                type: "companyContacts",
                                                            })
                                                        }>
                                                        + {t("Contacts_Add")}
                                                    </Button>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <span className={classes.representativeLabel}>
                                    {t("meetingForum_SupplierMember")}
                                </span>
                                <div className={classes.box}>
                                    <Form.Item
                                        wrapperCol={{ span: 22 }}
                                        name="externalReps"
                                        initialValue={
                                            data && data.externalRepresentatives
                                                ? data.externalRepresentatives
                                                : []
                                        }>
                                        <SelectPartnerContacts
                                            partnerId={partnerId}
                                            bordered={false}
                                            includeChildSupplierContacts={true}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Divider
                            children={t("actions_Actions")}
                            orientationMargin={0}
                            orientation="left"
                        />
                        <Row>
                            <Col span={4}>{t("generic_Title")}</Col>
                            <Col span={6}>{t("generic_Description")}</Col>
                            <Col span={3}>{t("generic_Status")}</Col>
                            <Col span={4}>{t("generic_Deadline")}</Col>
                            <Col span={6}>{t("action_AssignedTo")}</Col>
                            <Col span={1}></Col>
                            <Divider style={{ marginTop: 4, marginBottom: 12 }} />
                        </Row>
                        {actions.map((action: any) => (
                            <Row>
                                <Col span={4}>
                                    <Form.Item
                                        name={`title${action.id}`}
                                        initialValue={
                                            action.title === "" ? undefined : action.title
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: t("inputIsRequiredError"),
                                            },
                                        ]}>
                                        <Input
                                            autoFocus
                                            placeholder={t("generic_Placeholder-Text")}
                                            className={classes.inputClass}
                                            maxLength={500}
                                            variant="borderless"
                                            onBlur={async (e: any) => {
                                                if (
                                                    e.target.value.trim() !== "" &&
                                                    e.target.value.trim() !== action.title.trim()
                                                )
                                                    await handleActionUpdate(
                                                        "title",
                                                        action,
                                                        e.target.value
                                                    );
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Tooltip title={action.description} mouseEnterDelay={0.5}>
                                        <Form.Item
                                            name={`description${action.id}`}
                                            initialValue={action.description}>
                                            <Input
                                                placeholder={t("generic_Placeholder-Text")}
                                                className={classes.inputClass}
                                                maxLength={1000}
                                                variant="borderless"
                                                onBlur={async (e: any) =>
                                                    await handleActionUpdate(
                                                        "description",
                                                        action,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={3}>
                                    <Form.Item name="status">
                                        <span key={`status${action.id}`}>
                                            {action.completed === true ? (
                                                <Button
                                                    data-test="re_open_action"
                                                    size="small"
                                                    loading={actionLoading === action.id}
                                                    onClick={async (e: any) => {
                                                        setActionLoading(action.id);
                                                        await reOpenActions([action.id]);
                                                        setReload({ reload: true });
                                                        setActionLoading(undefined);
                                                        onActionInteraction();
                                                    }}
                                                    type="primary"
                                                    className={classes.actionButton}>
                                                    {t("actions_ReOpen")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    data-test="complete_action"
                                                    size="small"
                                                    loading={actionLoading === action.id}
                                                    onClick={async (e: any) => {
                                                        setActionLoading(action.id);
                                                        await completeActions([action.id]);
                                                        setReload({ reload: true });
                                                        setActionLoading(undefined);
                                                        onActionInteraction();
                                                    }}
                                                    type="primary"
                                                    className={classes.actionButton}>
                                                    {t("actions_Complete")}
                                                </Button>
                                            )}
                                        </span>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name={`dueDate${action.id}`}
                                        initialValue={
                                            action.dueDate ? dayjs(action.dueDate) : undefined
                                        }>
                                        <DatePicker
                                            format={"MMM D, YYYY "}
                                            className={classes.inputClass}
                                            variant="borderless"
                                            onChange={async (date: any, dateString: string) =>
                                                await handleActionUpdate(
                                                    "dueDate",
                                                    action,
                                                    dateString.trim() === ""
                                                        ? undefined
                                                        : dateString,
                                                    true
                                                )
                                            }
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name={`owner${action.id}`}
                                        initialValue={action.ownerId[0]}>
                                        <SelectData
                                            onChange={async (_: any, item: any) =>
                                                await handleActionUpdate(
                                                    "owner",
                                                    action,
                                                    item ? item.id : undefined,
                                                    true
                                                )
                                            }
                                            useData={() => ({
                                                data: contacts,
                                                loading: gettingContacts,
                                            })}
                                            selectOptionField="name"
                                            groupNames={[
                                                t("action_Internal-Contacts"),
                                                t("action_External-Contacts"),
                                            ]}
                                            groupFilter={(item: any, groupName: string) => {
                                                if (groupName === t("action_Internal-Contacts"))
                                                    return item.externalUser === false;
                                                if (groupName === t("action_External-Contacts"))
                                                    return item.externalUser === true;
                                            }}
                                            placeholder={t("select")}
                                            bordered={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <div
                                        style={{ textAlign: "center", paddingTop: 4 }}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}>
                                        <Popconfirm
                                            title={t("developmentPlan_ActionRemoveConfirm")}
                                            cancelText={t("generic_Cancel")}
                                            okText={t("generic_Confirm")}
                                            onConfirm={async () => handleActionDelete(action)}>
                                            <span>
                                                <MinusCircle />
                                            </span>
                                        </Popconfirm>
                                    </div>
                                </Col>
                            </Row>
                        ))}

                        <Button
                            className={classes.addActionButton}
                            loading={creatingAction}
                            onClick={async () => await handleActionCreate()}>
                            {t("developmentPlan_AddAction")}
                        </Button>

                        <Divider style={{ marginTop: 8 }}></Divider>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={t("attachFile")}
                                    name="files"
                                    initialValue={
                                        data && data.files && data.files.map((x: any) => x.id)
                                    }>
                                    <AttachFile
                                        makeTextNearButton={true}
                                        attachedFileList={fileList}
                                        maxFileSize={40}
                                        uploadSectionLayout={{
                                            style: { display: "flex", flexDirection: "column" },
                                        }}
                                        uploadButtonLayout={{
                                            style: {
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginBottom: 10,
                                            },
                                        }}>
                                        {t("generic_SelectFile")}
                                    </AttachFile>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {contactModal.visible && (
                    <CreateContactModal
                        visible={contactModal.visible}
                        onClose={(reload, contactId) => {
                            if (reload === false) {
                                setContactModal({
                                    visible: false,
                                    partnerId: undefined,
                                    type: undefined,
                                });
                            } else {
                                setReloadContacts({ reload: true });

                                if (contactModal.type === "companyContacts") {
                                    form.setFieldsValue({
                                        internalReps: [
                                            ...form.getFieldValue("internalReps"),
                                            contactId,
                                        ],
                                    });
                                }
                                if (contactModal.type === "supplierContacts") {
                                    form.setFieldsValue({
                                        externalReps: [
                                            ...form.getFieldValue("externalReps"),
                                            contactId,
                                        ],
                                    });
                                }
                                setContactModal({
                                    visible: false,
                                    partnerId: undefined,
                                    type: undefined,
                                });
                            }
                        }}
                        partnerId={contactModal.partnerId}
                        showSupplierSelect={false}
                    />
                )}
            </Modal>
        </div>
    );
};

export { EditModal };
