import React, { useState, FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    getColumnSearchProps,
    getColumnRangePickerProps,
} from "components/utilitycomponents";
import { useContactGroupOverview } from "controller";
import OverviewHeader from "./overviewHeader";
import CreateEditModal from "./createEditModal";
import { formatTime } from "utilities";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [createEditModal, setCreateEditModal] = useState({
        data: {},
        visible: false,
    });
    const handleOnRow = (record: any) => ({
        onClick: () => {
            setCreateEditModal({ data: record, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    const rowSelection = {
        selectedRows,
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const columns = [
        {
            title: t("id", "Id"),
            dataIndex: "id",
            key: "Id",
            width: 100,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 1,
            ...getColumnSearchProps({ dataIndex: "id" }),
        },
        {
            title: t("contact_GroupName"),
            dataIndex: "name",
            key: "Name",
            width: 150,
            sorter: true,
            filterType: "string",
            viewColumnId: 3,
            ...getColumnSearchProps({ dataIndex: "name" }),
        },
        {
            title: t("contact_GroupCreated"),
            dataIndex: "created",
            key: "Created",
            width: 170,
            sorter: true,
            viewColumnId: 4,
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "created" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
    ];

    return (
        <Fragment>
            <Grid
                data-test="contact_group_table"
                title={() => (
                    <OverviewHeader
                        onCreateClicked={() => setCreateEditModal({ data: {}, visible: true })}
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        onClearSelectedRows={setSelectedRowKeys}
                        onReloadData={() => setReloadData({ reload: true })}
                    />
                )}
                columns={columns}
                fixedColumns={["id"]}
                useData={useContactGroupOverview}
                tableType="ContactGroup"
                onRow={handleOnRow}
                rowSelection={rowSelection}
                reloadData={reloadData}
            />
            {createEditModal.visible && (
                <CreateEditModal
                    data={createEditModal.data}
                    visible={createEditModal.visible}
                    onClose={(reload: boolean) => {
                        setCreateEditModal({ data: {}, visible: false });
                        if (reload) {
                            setReloadData({ reload: true });
                            setSelectedRowKeys([]);
                        }
                    }}
                />
            )}
        </Fragment>
    );
};

export default Overview;
