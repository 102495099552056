import React, { forwardRef, Fragment, useState } from "react";
import { MailOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import CreateExternalPartnerModal from "./createExternalPartnerModal";
import { useExternalPartners, useCompanySettings } from "controller";
import { SelectData } from "../selectData";
import { SelectTreeData } from "../selectTreeData/selectTreeData";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";

type PropsType = {
    onChange?: (value?: any, item?: any) => void;
    onInit?: (item?: any) => void;
    value?: any;
    includeChildren?: boolean;
    reload?: boolean;
    showCreationButton?: boolean;
    creationButtonType?: "default" | "large";
    lightUserSupplierCreationPermissions?: boolean;
    hideSupplierSelection?: boolean;
    withEmail?: boolean;
    withIsLinked?: boolean;
    withContacts?: boolean;
    [key: string]: any;
};

const SelectExternalPartner = forwardRef(
    (
        {
            onChange,
            value,
            onInit,
            reload,
            includeChildren = false,
            showCreationButton = true,
            creationButtonType = "default",
            lightUserSupplierCreationPermissions = false,
            smallSupplierSelection = false,
            withEmail,
            withIsLinked,
            withContacts,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();
        const user = useSelector((state: AppState) => state.oidc.user);
        const userType: string = user.profile["ll-Usertype"];
        const [externalPartnerModalVisible, setExternalPartnerModalVisible] = useState(false);
        const [newPartner, setNewPartner] = useState<any>(0);
        const { data: companySettings } = useCompanySettings();
        //light users are able to create suppliers if the template value supplied from the URL is not undefined
        const canCreateSupplier =
            companySettings.canUsersCreateSupplier ||
            userType === "administrator" ||
            lightUserSupplierCreationPermissions === true;

        const handleCreateExternalPartner = (newPartnerId: number, item: any) => {
            setNewPartner(newPartnerId);
            onChange && onChange(newPartnerId, item);
        };

        return (
            <Fragment>
                <Row>
                    <Col
                        lg={showCreationButton && canCreateSupplier ? 22 : 24}
                        xs={showCreationButton && canCreateSupplier ? 20 : 24}>
                        {includeChildren ? (
                            <SelectTreeData
                                useData={useExternalPartners.bind(
                                    null,
                                    newPartner,
                                    reload,
                                    withEmail,
                                    withIsLinked,
                                    withContacts
                                )}
                                data-test="external_partner_select"
                                ref={ref as any}
                                value={value}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                selectOptionField={(item: any) => ({
                                    text: `${item.name} ${item.number}`,
                                    node: (
                                        <>
                                            {item.hasEmail && <MailOutlined />} {item.name}{" "}
                                            {item.number !== "" && item.number}
                                        </>
                                    ),
                                })}
                                onChange={onChange}
                                autoClearSearchValue={false}
                                disabled={lightUserSupplierCreationPermissions}
                                {...rest}
                            />
                        ) : (
                            <SelectData
                                useData={useExternalPartners.bind(
                                    null,
                                    newPartner,
                                    reload,
                                    withEmail,
                                    withIsLinked,
                                    withContacts
                                )}
                                data-test="external_partner_select"
                                ref={ref as any}
                                value={value}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                selectOptionField={(item: any) => ({
                                    text: `${item.name} ${item.number}`,
                                    node: (
                                        <>
                                            {item.hasEmail && <MailOutlined />} {item.name}{" "}
                                            {item.number !== "" && item.number}
                                        </>
                                    ),
                                })}
                                onChange={onChange}
                                autoClearSearchValue={false}
                                disabled={lightUserSupplierCreationPermissions}
                                {...rest}
                            />
                        )}
                    </Col>

                    {showCreationButton && canCreateSupplier && creationButtonType === "default" && (
                        <Col lg={2} xs={4}>
                            <Button
                                data-test="create_external_partner_button"
                                style={{ margin: "0 10px" }}
                                onClick={() =>
                                    setExternalPartnerModalVisible(!externalPartnerModalVisible)
                                }>
                                <PlusOutlined />
                            </Button>
                        </Col>
                    )}
                    {showCreationButton && canCreateSupplier && creationButtonType === "large" && (
                        <Col lg={22} xs={22}>
                            <Button
                                style={{ marginTop: 8 }}
                                block
                                type="primary"
                                data-test="create_external_partner_button"
                                onClick={() =>
                                    setExternalPartnerModalVisible(!externalPartnerModalVisible)
                                }>
                                {t("generic_AddNewItem", { item: t("generic_Company") })}
                            </Button>
                        </Col>
                    )}
                </Row>

                <CreateExternalPartnerModal
                    onCreateExternalPartner={handleCreateExternalPartner}
                    visible={externalPartnerModalVisible}
                    onCancel={() => setExternalPartnerModalVisible(!externalPartnerModalVisible)}
                    footer={"default"}
                />
            </Fragment>
        );
    }
);

export { SelectExternalPartner };
