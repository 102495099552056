import { put, get } from "../base";

const AdminPerformanceSettings = {
    GetPerformanceReviewCategoryGroup: async ({ customError }: any = {}) => {
        const url = "/v1/Review/GetPerformanceReviewCategoryGroup";
        return await get({ url, customError });
    },
    ChangeCriteriaOrderForPerformance: async (payload: any[], { customError }: any = {}) => {
        const url = "/v1/Review/ChangeCriteriaOrderForPerformance";
        await put({ url, payload, customError });
    },
};

export { AdminPerformanceSettings };
