import { ILink, ILinkType } from "models/links";
import { get, put, remove } from "../base";

const Links = {
    getLinks: async (type: ILinkType, partnerId?: number, { customError }: any = {}) => {
        const url = `/v1/Link?partnerId=${partnerId}&linkType=${type}`;
        return await get({ url, customError });
    },
    upsertLink: async (link: ILink, { customError }: any = {}) => {
        const url = `/v1/Link`;
        return await put({ url, payload: link, customError });
    },
    deleteLinks: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/Link`;
        return await remove({ url, payload: ids, customError });
    },
};

export { Links };
