import { Reducer, Action } from "redux";

export interface ReloadState {
    reloadState: boolean;
}

const initialState: ReloadState = {
    reloadState: false,
};

const SET_RELOAD = "SET_RELOAD";

interface SetReloadAction extends Action<typeof SET_RELOAD> {
    payload: boolean;
}

export const setReloadState = (reload: boolean): SetReloadAction => ({
    type: SET_RELOAD,
    payload: reload,
});

type ReloadActions = SetReloadAction;

const reloadReducer: Reducer<ReloadState, ReloadActions> = (state = initialState, action) => {
    switch (action.type) {
        case SET_RELOAD:
            return { ...state, reloadState: action.payload };
        default:
            return state;
    }
};

export default reloadReducer;
