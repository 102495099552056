import React, { FunctionComponent, useState } from "react";
import { Row, Col, Card, Spin, Result } from "antd";
import { useReviewRegistration } from "controller";
import { ChildActivities, UserImage, PageContainer } from "components/utilitycomponents";
import ReviewHeader from "./reviewHeader";
import { ReviewDetails } from "./reviewDetail";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type PropsType = {
    id: number;
    onVisibleChange: Function;
    reloadTable?: Function;
};

const RegistrationModal: FunctionComponent<PropsType> = ({ onVisibleChange }) => {
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [reload] = useState({ reload: false });
    const { loading, data } = useReviewRegistration(guestToken, reload);

    if (loading && data === null) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    if (!loading && (data === null || data === ""))
        return (
            <PageContainer settingContainer>
                <Result status="info" title={t("review_InvalidReviewToken")} />
            </PageContainer>
        );

    return (
        <PageContainer>
            <Card>
                <Row gutter={8}>
                    <Col span={2}>
                        <UserImage
                            alt={data.creator}
                            style={{ width: "100%" }}
                            fileId={
                                "creatorProfilePictureId" in data &&
                                data.creatorProfilePictureId !== null
                                    ? data.creatorProfilePictureId
                                    : undefined
                            }
                            customDimension={65}
                        />
                    </Col>
                    <Col span={21}>
                        <ReviewHeader data={data} />
                        <>
                            <ReviewDetails data={data} />
                        </>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col span={2}></Col>
                    <Col span={21}>
                        <ChildActivities
                            data={data.childrenActivity}
                            parentActivityId={data.activityId}
                            onRemoveActivity={() => {}}
                            onAddActivity={() => {}}
                        />
                    </Col>
                </Row>
            </Card>
        </PageContainer>
    );
};

export default RegistrationModal;
