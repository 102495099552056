import { post, get, remove } from "../base";

const PartnerRisk = {
    getRisks: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Risks/GetRisks?partnerId=${partnerId}`;
        return await get({ url, customError: customError });
    },
    getAlternatePartners: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Risks/GetAlternativePartners?partnerId=${partnerId}`;
        return await get({ url, customError: customError });
    },
    updateRisk: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/Risks/UpdateRisk`;
        return await post({ url, payload, customError: customError });
    },
    updateRiskFile: async (partnerId: number, fileId: number, { customError }: any = {}) => {
        const url = `/v1/Risks/UpdateRiskFileId?partnerId=${partnerId}&fileId=${fileId}`;
        return await post({ url, customError: customError });
    },
    createAlternateSupplier: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/Risks/CreateAlternativeSupplier`;
        return await post({ url, payload, customError: customError });
    },
    updateAlternateSupplier: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/Risks/UpdateAlternativeSupplier`;
        return await post({ url, payload, customError: customError });
    },
    deleteAlternateSupplier: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/Risks/DeleteAlternativeSupplier`;
        return await remove({ url, payload: ids, customError: customError });
    },
};

export default PartnerRisk;
