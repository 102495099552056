import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IMeetingMinuteDetail } from "models";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";
import { getDomainUrl } from "utilities";
import { PartnerLink } from "../partnerLink";
import { useSelector } from "react-redux";
import { AppState } from "store";

type PropsType = {
    data: IMeetingMinuteDetail;
};

const MeetingMinute: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];

    let from = <PartnerLink partnerId={data.fromPartnerId} partnerName={data.creatorCompanyName} />;
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {data.isOnPartner === true ? (
                    t("notification_CreatorSharedMeetingMinute", {
                        creator: data.creator,
                        interpolation: { escapeValue: false },
                    })
                ) : (
                    <>
                        {data.creator} {t("from")} {from}
                        {t("notification_ExternalCreatorSharedMeetingMinute")}
                    </>
                )}
            </div>
            <>
                {data.isOnPartner === true && userType !== "lightuser" ? (
                    <Link
                        className={classes.notificationLink}
                        to={`/partners/supplierprofile/4/${data.partnerId}/${data.meetingMinuteId}`}>
                        <RightCircleFilled />
                        {t("notification_ViewMeetingMinute")}
                    </Link>
                ) : (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${getDomainUrl()}/Guest/MeetingMinutes/${data.guestToken}`}>
                        {t("notification_ViewMeetingMinute")}
                    </a>
                )}
            </>
        </span>
    );
};

export { MeetingMinute };
