import React, { Component, FunctionComponent } from "react";
import Provider from "services/api";
import {
    getAllUserOrderedColumns,
    getShownUserOrderedColumns,
    getHiddenColumns,
    addOtherHiddenColumns,
    aggregateDateRanges,
    aggragateNumberRanges,
} from "./helpers";
import { ITableType, ITableSize } from "models";
import { IColumnFilter } from "components/utilitycomponents/grid/models";
import { getTableSize } from "utilities";
import dayjs from "utilities/daysJsConfig";

type PropsType = {
    tableType: string;
    columns: any[];
    additionalColumnFilters?: any[];
    minColumnWidth?: number;
    customPageSize?: number;
    pageContainerWidth?: number;
    saveColumnWidth?: boolean;
    saveColumnOrder?: boolean;
    saveColumnSettings?: boolean;
    onCustomFiltersChange?: Function;
};

type InjectedPropsType = {
    tableSize: string;
    apiFilters: any[];
    shownUserOrderedColumns: any[];
    hiddenColumns: any[];
    viewSettingsLoading: boolean;
    viewSettingUpdateLoading: boolean;
    onTableSettingSave: Function;
    onAddTableSettingToAllUsers: Function;
    onReorderColumns: Function;
    onTableFiltersChange: Function;
    onColumnsResize: Function;
    onTableSizeChange: Function;
    onColumnResizeEnd: Function;
};

function withViewSetting<T>(WrappedComponent: FunctionComponent<T & InjectedPropsType>) {
    return class extends Component<Omit<T & PropsType, keyof InjectedPropsType>> {
        state = {
            viewSettingLoading: true,
            viewColumnSettings: [],
            shownUserOrderedColumns: [],
            allUserOrderedColumns: [],
            columnsCopy: [],
            hiddenColumns: [],
            tableSize: "small",
            pageSize: null,
            isFilterRemembered: false,
            columnFilters: [],
            customFilters: [],
            apiFilters: [],
            viewPort: {
                update: false,
            },
            viewSettingUpdateLoading: false,
        };

        async componentDidMount() {
            let viewSettings: any = {};

            const defaultPageSize = this.props.customPageSize
                ? this.props.customPageSize
                : getTableSize().pageSize;

            if (this.props.saveColumnSettings !== false) {
                viewSettings = await Provider.User.getViewSetting(this.props.tableType);
            } else {
                viewSettings = {
                    isFilterRemembered: false,
                    uiViewSetting: {
                        viewColumnSettings: null,
                        viewSize: "small",
                        columnFilters: [],
                        itemsPerPage: defaultPageSize,
                    },
                };
            }
            const {
                isFilterRemembered,
                uiViewSetting: { viewColumnSettings, viewSize, columnFilters, itemsPerPage },
            } = viewSettings;

            this.props.columns.map(
                column => (column.viewColumnId = column.viewColumnId.toString())
            );
            const columnsCopy = JSON.parse(JSON.stringify(this.props.columns));

            this.setState({
                viewSettingLoading: false,
                viewColumnSettings: viewColumnSettings === null ? [] : viewColumnSettings,
                columnsCopy: columnsCopy,
                shownUserOrderedColumns: getShownUserOrderedColumns(
                    viewColumnSettings,
                    this.props.columns,
                    this.props.minColumnWidth,
                    this.props.pageContainerWidth
                ),
                allUserOrderedColumns: getAllUserOrderedColumns(viewColumnSettings, columnsCopy),
                hiddenColumns: getHiddenColumns(viewColumnSettings, this.props.columns),
                tableSize: viewSize,
                isFilterRemembered,
                pageSize: isFilterRemembered && itemsPerPage ? itemsPerPage : defaultPageSize,
                columnFilters: this.props.additionalColumnFilters
                    ? this.props.additionalColumnFilters
                    : isFilterRemembered
                    ? columnFilters.filter((filter: any) => filter.type === null)
                    : [],
                customFilters: columnFilters.filter(
                    (filter: any) => filter.type === "customFilter"
                ),
                apiFilters: this.props.additionalColumnFilters
                    ? this.convertColumnToApiFilters(this.props.additionalColumnFilters)
                    : isFilterRemembered
                    ? this.convertColumnToApiFilters(columnFilters)
                    : [],
            });

            //this will reconfigure column widths on viewport updates
            window.addEventListener("resize", this.updateViewPort);
        }

        public componentDidUpdate(prevProps: PropsType, prevState: any) {
            if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
                this.props.columns.map(
                    column => (column.viewColumnId = column.viewColumnId.toString())
                );
                const columnsCopy = JSON.parse(JSON.stringify(this.props.columns));
                this.setState({
                    shownUserOrderedColumns: getShownUserOrderedColumns(
                        this.state.viewColumnSettings,
                        this.props.columns,
                        this.props.minColumnWidth,
                        this.props.pageContainerWidth
                    ),
                    allUserOrderedColumns: getAllUserOrderedColumns(
                        this.state.viewColumnSettings,
                        columnsCopy
                    ),
                    hiddenColumns: getHiddenColumns(
                        this.state.viewColumnSettings,
                        this.props.columns
                    ),
                    columnsCopy,
                });
            }
            if (prevState.viewPort !== this.state.viewPort) {
                this.setState({
                    shownUserOrderedColumns: getShownUserOrderedColumns(
                        this.state.viewColumnSettings,
                        this.props.columns,
                        this.props.minColumnWidth,
                        this.props.pageContainerWidth
                    ),
                });
            }

            if (
                JSON.stringify(prevProps.additionalColumnFilters) !==
                JSON.stringify(this.props.additionalColumnFilters)
            ) {
                let newColumnFilters: any[] = this.state.columnFilters;
                //remove previous additional column filters
                if (prevProps.additionalColumnFilters) {
                    prevProps.additionalColumnFilters.forEach(
                        prevFilter =>
                            (newColumnFilters = newColumnFilters.filter(
                                (f: any) => f.name !== prevFilter.name
                            ))
                    );
                }
                //add new additional column filters
                if (this.props.additionalColumnFilters) {
                    newColumnFilters = [
                        ...newColumnFilters,
                        ...(this.props.additionalColumnFilters as any[]),
                    ];
                }

                this.setState({
                    columnFilters: newColumnFilters,
                    apiFilters: this.convertColumnToApiFilters(newColumnFilters),
                });
            }
        }

        public UNSAFE_componentWillMount() {
            window.removeEventListener("resize", this.updateViewPort);
        }

        public updateViewPort = () => {
            this.setState({ viewPort: { update: true } });
        };

        handleTableSizeChange = (newTableSize: string) => {
            Provider.User.updateTableSize(
                (ITableType as any)[this.props.tableType],
                (ITableSize as any)[newTableSize]
            );
            this.setState({
                tableSize: newTableSize,
            });
            setTimeout(() => {
                this.setState({
                    shownUserOrderedColumns: [...this.state.shownUserOrderedColumns],
                });
            }, 1500);
        };

        handlePageSizeChange = (newPageSize: number) => {
            if (
                this.state.isFilterRemembered &&
                this.props.saveColumnSettings !== false &&
                this.state.pageSize !== newPageSize
            ) {
                Provider.User.updatePageSize(
                    (ITableType as any)[this.props.tableType],
                    newPageSize
                );
            }
            this.setState({
                pageSize: newPageSize,
            });
        };

        handleReorderColumns = async (newUserOrderedColumns: any[]) => {
            let newViewColumnSettings = newUserOrderedColumns.map((col: any) => ({
                isHidden: false,
                viewColumnId: col.viewColumnId,
                width: this.state.viewColumnSettings.some(
                    (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                )
                    ? this.state.viewColumnSettings.filter(
                          (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                      )[0]["width"]
                    : col.width || null,
            }));

            this.setState({
                viewSettingUpdateLoading: true,
            });

            newViewColumnSettings = [
                ...newViewColumnSettings,
                ...addOtherHiddenColumns(newUserOrderedColumns, this.state.allUserOrderedColumns),
            ];
            if (this.props.saveColumnSettings !== false) {
                await Provider.User.updateListView(
                    (ITableType as any)[this.props.tableType],
                    newViewColumnSettings.map((c: any, index: number) => ({
                        ...c,
                        position: index,
                    }))
                );
            }

            this.setState({
                viewSettingUpdateLoading: false,
                viewColumnSettings: newViewColumnSettings,
                shownUserOrderedColumns: getShownUserOrderedColumns(
                    newViewColumnSettings,
                    this.props.columns,
                    this.props.minColumnWidth,
                    this.props.pageContainerWidth
                ),
                allUserOrderedColumns: getAllUserOrderedColumns(
                    newViewColumnSettings,
                    this.state.columnsCopy
                ),
            });
        };

        handleTableSettingSave = async (newHiddenColumns: any[], isReset: boolean = false) => {
            let newViewColumnSettings: any[] = [];
            if (isReset) {
                newViewColumnSettings = this.state.columnsCopy.map((col: any) => ({
                    isHidden: false,
                    width: col.width,
                    viewColumnId: col.viewColumnId,
                }));
            } else {
                newViewColumnSettings = this.state.allUserOrderedColumns.map((col: any) => ({
                    viewColumnId: col.viewColumnId,
                    isHidden: newHiddenColumns.includes(col.dataIndex),
                    width: this.state.viewColumnSettings.some(
                        (colSetting: any) =>
                            colSetting.viewColumnId === col.viewColumnId && col.width
                    )
                        ? this.state.viewColumnSettings.filter(
                              (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                          )[0]["width"]
                        : col.width || null,
                }));
            }

            await Provider.User.updateListView(
                (ITableType as any)[this.props.tableType],
                newViewColumnSettings.map((c: any, index: number) => ({ ...c, position: index }))
            );

            //remove hidden columns from new Column Filters
            let newColumnFilters = this.state.columnFilters;
            if (newHiddenColumns.length) {
                newColumnFilters = this.state.columnFilters.filter(
                    (filter: any) => !newHiddenColumns.includes(filter.name)
                );
                if (this.state.isFilterRemembered) {
                    Provider.User.setColumnFilters(
                        newColumnFilters,
                        (ITableType as any)[this.props.tableType]
                    );
                }
            }
            //end block
            this.setState({
                viewColumnSettings: newViewColumnSettings,
                shownUserOrderedColumns: getShownUserOrderedColumns(
                    newViewColumnSettings,
                    this.props.columns,
                    this.props.minColumnWidth,
                    this.props.pageContainerWidth
                ),
                allUserOrderedColumns: getAllUserOrderedColumns(
                    newViewColumnSettings,
                    this.state.columnsCopy
                ),
                hiddenColumns: getHiddenColumns(newViewColumnSettings, this.props.columns),
                columnFilters: newColumnFilters,
                apiFilters: this.convertColumnToApiFilters(newColumnFilters),
            });
        };
        handleAddTableSettingToAllUsers = async (
            newHiddenColumns: any[],
            isReset: boolean = false
        ) => {
            let newViewColumnSettings: any[] = [];
            newViewColumnSettings = this.state.allUserOrderedColumns.map((col: any) => ({
                viewColumnId: col.viewColumnId,
                isHidden: newHiddenColumns.includes(col.dataIndex),
                width: this.state.viewColumnSettings.some(
                    (colSetting: any) => colSetting.viewColumnId === col.viewColumnId && col.width
                )
                    ? this.state.viewColumnSettings.filter(
                          (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                      )[0]["width"]
                    : col.width || null,
            }));
            await Provider.User.SetViewColumnSettingsForAllUsers(
                (ITableType as any)[this.props.tableType],
                newViewColumnSettings.map((c: any, index: number) => ({ ...c, position: index }))
            );
            //remove hidden columns from new Column Filters
            let newColumnFilters = this.state.columnFilters;
            if (newHiddenColumns.length) {
                newColumnFilters = this.state.columnFilters.filter(
                    (filter: any) => !newHiddenColumns.includes(filter.name)
                );
                if (this.state.isFilterRemembered) {
                    Provider.User.setColumnFilters(
                        newColumnFilters,
                        (ITableType as any)[this.props.tableType]
                    );
                }
            }
            //end block
            this.setState({
                viewColumnSettings: newViewColumnSettings,
                shownUserOrderedColumns: getShownUserOrderedColumns(
                    newViewColumnSettings,
                    this.props.columns,
                    this.props.minColumnWidth,
                    this.props.pageContainerWidth
                ),
                allUserOrderedColumns: getAllUserOrderedColumns(
                    newViewColumnSettings,
                    this.state.columnsCopy
                ),
                hiddenColumns: getHiddenColumns(newViewColumnSettings, this.props.columns),
                columnFilters: newColumnFilters,
                apiFilters: this.convertColumnToApiFilters(newColumnFilters),
            });
        };

        handleTableFiltersChange = async (newTableFilters: any) => {
            const newColumnFilters = this.convertTableToColumnFilters(newTableFilters);
            if (JSON.stringify(newColumnFilters) === JSON.stringify(this.state.columnFilters)) {
                return;
            }

            this.setState({
                columnFilters: newColumnFilters,
                apiFilters: this.convertColumnToApiFilters([
                    ...newColumnFilters,
                    ...this.state.customFilters,
                ]),
            });

            if (this.state.isFilterRemembered) {
                await Provider.User.setColumnFilters(
                    [
                        ...newColumnFilters,
                        ...this.state.customFilters.map((f: any) => ({
                            ...f,
                            type: "customFilter",
                        })),
                    ],
                    (ITableType as any)[this.props.tableType]
                );
            }
        };

        handleClearFilters = async (staticFilters: any) => {
            const newColumFilters = this.convertTableToColumnFilters(staticFilters);
            this.setState({
                columnFilters: newColumFilters,
                customFilters: [],
                apiFilters: this.convertColumnToApiFilters(newColumFilters),
            });

            if (this.state.isFilterRemembered) {
                await Provider.User.setColumnFilters([], (ITableType as any)[this.props.tableType]);
            }
        };

        handleColumnsResize = (newShownUserOrderedColumns: any[]) => {
            this.setState({
                shownUserOrderedColumns: newShownUserOrderedColumns,
            });
        };

        handleColumnResizeEnd = async (column: any, width: any) => {
            let newViewColumnSettings: any[] = [];
            if (
                this.state.viewColumnSettings !== null &&
                this.state.viewColumnSettings.length > 0
            ) {
                newViewColumnSettings = this.state.shownUserOrderedColumns.map((col: any) => {
                    const viewSetting: any = {
                        isHidden: false,
                        viewColumnId: col.viewColumnId,
                    };
                    if (col.viewColumnId === column.viewColumnId) {
                        viewSetting.width = column.width;
                    } else {
                        viewSetting.width = this.state.viewColumnSettings.some(
                            (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                        )
                            ? this.state.viewColumnSettings.filter(
                                  (colSetting: any) => colSetting.viewColumnId === col.viewColumnId
                              )[0]["width"]
                            : col.width || null;
                    }
                    return viewSetting;
                });
                newViewColumnSettings = [
                    ...newViewColumnSettings,
                    ...addOtherHiddenColumns(
                        this.state.shownUserOrderedColumns,
                        this.state.allUserOrderedColumns
                    ),
                ];
            } else {
                newViewColumnSettings = this.state.allUserOrderedColumns.map((col: any) => ({
                    viewColumnId: col.viewColumnId,
                    isHidden: false,
                    width: col.viewColumnId === column.viewColumnId ? width : col.width,
                }));
            }
            this.setState({
                viewSettingUpdateLoading: true,
            });

            if (this.props.saveColumnSettings !== false) {
                await Provider.User.updateListView(
                    (ITableType as any)[this.props.tableType],
                    newViewColumnSettings.map((c: any, index: number) => ({
                        ...c,
                        position: index,
                    }))
                );
            }

            this.setState({
                viewSettingUpdateLoading: false,
                viewColumnSettings: newViewColumnSettings,
                shownUserOrderedColumns: getShownUserOrderedColumns(
                    newViewColumnSettings,
                    this.props.columns,
                    this.props.minColumnWidth,
                    this.props.pageContainerWidth
                ),
                allUserOrderedColumns: getAllUserOrderedColumns(
                    newViewColumnSettings,
                    this.state.columnsCopy
                ),
            });
        };

        handleCustomFiltersChange = async (newCustomFilters: IColumnFilter[]) => {
            this.setState({
                customFilters: newCustomFilters,
                apiFilters: this.convertColumnToApiFilters([
                    ...this.state.columnFilters,
                    ...newCustomFilters,
                ]),
            });

            if (this.state.isFilterRemembered) {
                await Provider.User.setColumnFilters(
                    [
                        ...this.state.columnFilters,
                        ...newCustomFilters.map(f => ({
                            ...f,
                            type: "customFilter",
                        })),
                    ],
                    (ITableType as any)[this.props.tableType]
                );
            }
        };

        convertColumnToApiFilters = (columnFilters: any) => {
            let indexedColumnsByDataIndex: any = {};
            this.props.columns.forEach(col => {
                indexedColumnsByDataIndex[col.dataIndex] = col;
            });

            const apiFilters: any = [];
            columnFilters.forEach((filter: any) => {
                if (indexedColumnsByDataIndex[filter.name]) {
                    apiFilters.push({
                        type: indexedColumnsByDataIndex[filter.name].customColumn
                            ? indexedColumnsByDataIndex[filter.name].customColumnName
                                ? indexedColumnsByDataIndex[filter.name].customColumnName
                                : "CustomColumn"
                            : indexedColumnsByDataIndex[filter.name].key,
                        ...(indexedColumnsByDataIndex[filter.name].customColumn && {
                            customColumnId: indexedColumnsByDataIndex[filter.name].customColumnId
                                ? indexedColumnsByDataIndex[filter.name].customColumnId
                                : parseInt(indexedColumnsByDataIndex[filter.name].key),
                        }),
                        value: filter.value,
                    });
                } else {
                    apiFilters.push({
                        type: filter.name,
                        value: filter.value,
                        static: filter.static,
                    });
                }
            });
            return apiFilters;
        };

        convertTableToColumnFilters = (tableFilters: any) => {
            let indexedColumnsByKey: any = {};
            this.props.columns.forEach(col => {
                indexedColumnsByKey[col.key] = col;
            });
            let columnFilters = [];
            for (const key in tableFilters) {
                if (Object.keys(indexedColumnsByKey).includes(key)) {
                    let value = "";
                    switch (indexedColumnsByKey[key].filterType) {
                        case "date":
                            value = aggregateDateRanges(tableFilters[key]);
                            break;
                        case "range":
                            value = aggragateNumberRanges(
                                tableFilters[key],
                                indexedColumnsByKey[key].min,
                                indexedColumnsByKey[key].max
                            );
                            break;
                        case "array":
                            if (Array.isArray(tableFilters[key])) {
                                value = tableFilters[key].join();
                            } else {
                                value = tableFilters[key];
                            }
                            break;
                        default:
                            value = tableFilters[key][0].toString();
                    }
                    columnFilters.push({
                        name: indexedColumnsByKey[key].dataIndex,
                        value,
                    });
                } else {
                    columnFilters.push({
                        name: key,
                        value: tableFilters[key],
                        static: true,
                    });
                }
            }

            return columnFilters;
        };

        addFilteredValueToShownColumns = (shownColumns: any) => {
            return shownColumns.map((column: any) => {
                let indexedColumnFilters: any = {};
                this.state.columnFilters.forEach((filter: any) => {
                    indexedColumnFilters[filter.name] = filter.value;
                });
                switch (column.filterType) {
                    case "string":
                    case "boolean":
                        column["filteredValue"] = indexedColumnFilters[column.dataIndex]
                            ? [indexedColumnFilters[column.dataIndex]]
                            : null;
                        break;
                    case "date":
                        const dates = indexedColumnFilters[column.dataIndex]
                            ? indexedColumnFilters[column.dataIndex].split(",")
                            : [];
                        column["filteredValue"] = dates.length
                            ? [dayjs(dates[0]), dayjs(dates[1])]
                            : [];
                        break;
                    case "array":
                    case "range":
                        column["filteredValue"] = indexedColumnFilters[column.dataIndex]
                            ? indexedColumnFilters[column.dataIndex].split(",")
                            : [];
                        break;
                }
                return column;
            });
        };

        render() {
            const shownUserOrderedColumns = this.addFilteredValueToShownColumns(
                this.state.shownUserOrderedColumns
            );

            return (
                <WrappedComponent
                    {...(this.props as T)}
                    viewSettingsLoading={this.state.viewSettingLoading}
                    onTableSettingSave={this.handleTableSettingSave}
                    onAddTableSettingToAllUsers={this.handleAddTableSettingToAllUsers}
                    onReorderColumns={this.handleReorderColumns}
                    onTableSizeChange={this.handleTableSizeChange}
                    onPageSizeChange={this.handlePageSizeChange}
                    onColumnsResize={this.handleColumnsResize}
                    onTableFiltersChange={this.handleTableFiltersChange}
                    onColumnResizeEnd={this.handleColumnResizeEnd}
                    onCustomFiltersChange={this.handleCustomFiltersChange}
                    onClearFilters={this.handleClearFilters}
                    shownUserOrderedColumns={shownUserOrderedColumns}
                    hiddenColumns={this.state.hiddenColumns}
                    tableSize={this.state.tableSize}
                    pageSize={this.state.pageSize}
                    customFilters={this.state.customFilters}
                    apiFilters={this.state.apiFilters}
                    viewSettingUpdateLoading={this.state.viewSettingUpdateLoading}
                    {...(this.props.saveColumnSettings === false && { hideGridSetting: true })}
                />
            );
        }
    };
}
export { withViewSetting };
