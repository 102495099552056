import React, { FunctionComponent, Fragment } from "react";
import NCROverview from "components/contentcomponents/ncr/overview/overview";
import { FiltersProvider } from "./ncrProvider";

export const NCR: FunctionComponent = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <NCROverview />
            </FiltersProvider>
        </Fragment>
    );
};
export default NCR;
