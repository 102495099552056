import { useEffect, useState } from "react";
import { Provider } from "services";

const usePerformanceChart = (partnerId: any, year: any, input: any[]) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: {},
    });
    useEffect(() => {
        setState({
            ...state,
            loading: true,
        });
        (async () => {
            const data = await Provider.Performance.getPerformanceChartData(
                partnerId,
                year,
                input,
                {
                    customError: {
                        message: "Could not performance data",
                    },
                }
            ).catch(err => {
                setState({
                    ...state,
                    loading: false,
                });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data,
            });
        })();
    }, [partnerId, input, year]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePerformanceChart };
