import { get, post } from "../base";
import { ITableInput, ComplianceVisibility } from "models";

const Compliance = {
    complianceHeadLinesList: async (templateId: number) => {
        const url = `/v1/Compliance/ComplianceHeadLines?templateId=${templateId}`;
        return await get({ url });
    },
    complianceOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Compliance/Overview";
        return await post({ url, payload: tableInput, customError });
    },
    getComplianceForRegistrationDialog: async (id: number, { customError }: any = {}) => {
        const url = `/v1/Compliance/ComplianceRegistration?id=${id}`;
        return await get({ url, customError });
    },
    getComplianceForEditPage: async (id: number, { customError }: any = {}) => {
        const url = `/v1/Compliance/Edit?id=${id}`;
        return await get({ url, customError });
    },
    createCompliance: async (complianceCreateInput: any, { customError }: any = {}) => {
        const url = "/v1/Compliance/Create";
        return await post({ url, payload: complianceCreateInput, customError });
    },
    updateStatus: async (id: number, statusId: number, { customError }: any = {}) => {
        const url = `/v1/Compliance/ChangeComplianceStatus?id=${id}&status=${statusId}`;
        return await post({ url, customError });
    },
    bulkUpdateStatus: async (ids: number[], statusId: number, { customError }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/Compliance/ChangeMultipleComplianceStatus?status=${statusId}`;
        return await post({ url, payload: ids, customError, customConfig });
    },
    //used in conjunction with BulkUpdateStatus to trigger email notification flow after status change has been completed
    SendEmailOfChangingMultipleComplianceStatus: async (
        payload: any,
        { customError }: any = {}
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/Compliance/SendEmailOfChangingMultipleComplianceStatus`;
        post({ url, payload, customError, customConfig });
    },
    updateRating: async (
        id: number,
        rating: number,
        activityId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Compliance/ChangeComplianceRating?id=${id}&rating=${rating}&activityId=${activityId}`;
        return await post({ url, customError });
    },
    updateRegistration: async (registrationUpdateInput: any, { customError }: any = {}) => {
        const url = "/v1/Compliance/Edit";
        return await post({ url, payload: registrationUpdateInput, customError });
    },
    updateVisibility: async (
        ids: number[],
        visibility: ComplianceVisibility,
        { customError }: any = {}
    ) => {
        const url = `/v1/Compliance/ChangeRegistrationsVisibility?visibility=${visibility}`;
        return await post({ url, payload: ids, customError });
    },
    removeRegistration: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/Compliance/DeleteRegistrations`;
        return await post({ url, payload: ids, customError });
    },
    copyRegistration: async (id: number, { customError }: any = {}) => {
        const url = `/v1/Compliance/CopyCompliance?id=${id}`;
        return await post({ url, customError });
    },
    canChangeToStatus: async (id: number, statusId: number, { customError }: any = {}) => {
        const url = `/v1/Compliance/canChangeToStatus?id=${id}&status=${statusId}`;
        return await post({ url, customError });
    },
    getContactsForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Compliance/ContactsFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },

    getTemplatesForOverviewFilter: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Compliance/TemplateFilter`;
        return await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
    },

    getComplianceByToken: async (token: string, { customError }: any = {}) => {
        const url = `/v1/Compliance/GetComplianceByToken?token=${token}`;
        return await post({ url, customError });
    },
    useSupplierProfileComplianceScore: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/SupplierProfile/GetMaturityChartData?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
};

export default Compliance;
