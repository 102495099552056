export enum FileInternalVisibilityEnum {
    Personal,
    Internal,
    Selected,
}

export enum FileExternalVisibilityEnum {
    None,
    Public,
    Selected,
}
