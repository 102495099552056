import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Alert, Tag, notification, Button } from "antd";
import { useFolderUpdate } from "controller";

interface IProps {
    visible: boolean;
    onClose: (reload?: boolean) => void;
    data: any;
}

const DeleteModal: FunctionComponent<IProps> = ({ visible, onClose, data }) => {
    const { t } = useTranslation();
    const { loading, deleteFoldersAndFiles } = useFolderUpdate();

    function renderTags(selectedEntities: any[]) {
        return selectedEntities.map((row: any) => {
            return (
                <Tag color="blue" key={row.id}>
                    {row.name}
                </Tag>
            );
        });
    }

    async function onSubmit() {
        await deleteFoldersAndFiles({
            folderIds: data.folders.map((f: any) => f.id),
            fileIds: data.files.map((f: any) => f.id),
        });
        notification.success({
            message: t("success"),
            description: t(`file_DeleteSuccess`),
        });
        onClose(true);
    }

    return (
        <Modal
            wrapProps={{ "data-test": "delete_files_modal" }}
            title={t(`file_DeleteTitle`)}
            open={visible}
            footer={[
                <Button
                    loading={loading}
                    type="primary"
                    danger
                    onClick={async () => await onSubmit()}>
                    {t("generic_Confirm")}
                </Button>,
            ]}
            onCancel={() => onClose()}
            onOk={onSubmit}>
            <Fragment>
                <Fragment>
                    {data.folders.length !== 0 && (
                        <Fragment>
                            <div className="gridTagLabel">{t("file_SelectedFolders") + " :"}</div>
                            <div className="gridTagArea">{renderTags(data.folders)}</div>
                        </Fragment>
                    )}
                    {data.files.length !== 0 && (
                        <Fragment>
                            <div className="gridTagLabel">{t("file_SelectedFiles") + " :"}</div>
                            <div className="gridTagArea">{renderTags(data.files)}</div>
                        </Fragment>
                    )}
                </Fragment>
                <Alert
                    message={t("attention")}
                    type={"warning"}
                    description={
                        <div>
                            {data.folders.length === 0
                                ? t(`file_DeleteFileWarning`)
                                : data.files.length === 0
                                ? t(`file_DeleteFolderWarning`)
                                : t(`file_DeleteWarning`)}
                        </div>
                    }
                />
            </Fragment>
        </Modal>
    );
};

export default DeleteModal;
