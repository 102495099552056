import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { ResponsiveBar } from "@nivo/bar";
import { Card, Select, Row, Col } from "antd";
import { BarChart07, LoadingIndicator } from "components/utilitycomponents";
import { useKpi } from "controller";
import CardTitle from "./cardTitle";
import { IKpiGraph } from "models";
import classes from "./styles/kpiScoreBarChart.module.scss";
import dayjs from "utilities/daysJsConfig";
const Option = Select.Option;

type PropsType = {
    partner: any;
    range: {
        from: string;
        to: string;
    };
    reload: any;
    partnerId: number;
};

const KpiScoreBarCharts: FunctionComponent<PropsType> = ({ partner, range, reload, partnerId }) => {
    const { t } = useTranslation();

    const [selectedPartner, setSelectedPartner] = useState(partner.partnerId);
    const { loading, data } = useKpi(selectedPartner, range.from, range.to, reload);

    useEffect(() => {
        setSelectedPartner(partner.partnerId);
    }, [partner]);

    useEffect(() => {
        setSelectedPartner(partnerId);
    }, [partnerId]);

    if (loading) return <LoadingIndicator />;
    if (!data || data.length === 0) return null;

    const chartsWithData = data.filter(d => d.data.length !== 0);
    if (chartsWithData.length === 0 && partner.children.length === 0) return null;

    return (
        <Row style={{ marginTop: "10px", pageBreakBefore: "always" }}>
            <Col span={24}>
                <Card
                    title={
                        <Row>
                            <Col span={12}>
                                <CardTitle
                                    icon={<BarChart07 />}
                                    title={t("supplierProfile_KpiScore")}
                                />
                            </Col>
                            {partner.children.length !== 0 && (
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <Select
                                        style={{ minWidth: "150px" }}
                                        onChange={(partnerId: number) =>
                                            setSelectedPartner(partnerId)
                                        }
                                        value={selectedPartner}>
                                        <Option key={partner.partnerId} value={partner.partnerId}>
                                            {partner.partnerName}
                                        </Option>
                                        {partner.children.map((c: any) => (
                                            <Option key={c.partnerId} value={c.partnerId}>
                                                {c.partnerName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}
                        </Row>
                    }>
                    <Row>
                        {data.map((graphData, index) => {
                            if (graphData.data.length !== 0) {
                                return (
                                    <Col
                                        xs={24}
                                        md={graphData.data.length / 2 >= 12 ? 24 : 12}
                                        lg={graphData.data.length / 2 >= 12 ? 24 : 12}
                                        xl={graphData.data.length / 2 >= 12 ? 24 : 12}
                                        xxl={
                                            graphData.data.length / 2 >= 14
                                                ? 24
                                                : graphData.data.length / 2 >= 7
                                                ? 12
                                                : 8
                                        }
                                        key={index}>
                                        <div style={{ pageBreakInside: "avoid" }}>
                                            <div className={classes.kpiChartTitle}>
                                                <b>{graphData.title}</b>
                                            </div>
                                            {renderChart(graphData)}
                                        </div>
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

const renderChart = (graphData: IKpiGraph) => {
    let maxData = 0;
    const data = graphData.data.map((item: any) => {
        if (maxData < item.value) {
            maxData = item.value;
        }
        return {
            type:
                dayjs()
                    .month(item.month - 1)
                    .format("MMM") +
                " " +
                item.year,
            value: item.value,
        };
    });

    return (
        <div className="kpiChartContainer">
            <div
                data-test="kpi_score_bar_chart"
                className={classnames(classes.kpiScoreBarChart, "kpiChart")}>
                <ResponsiveBar
                    colors={["#488db5"]}
                    margin={{
                        top: 50,
                        right: 5,
                        bottom: 60,

                        left: 100,
                    }}
                    padding={data.length <= 9 ? 1 - data.length / 10 : 0.2}
                    groupMode="grouped"
                    layout="vertical"
                    indexBy="type"
                    keys={["value"]}
                    minValue={maxData ? 0 : 1}
                    data={data}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        format: v => (graphData.isPercentage ? `${v}%` : `${v}`),
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 30,
                        renderTick: (tick: any) => {
                            const addedY = tick.tickIndex % 2 === 0 ? 15 : 0;
                            return (
                                <g
                                    key={tick.tickIndex}
                                    transform={`translate(${tick.x},${tick.y + 21 + addedY})`}>
                                    <line
                                        stroke="rgb(187,187,187)"
                                        strokeWidth={1.5}
                                        y1={-21 - addedY}
                                        y2={-12}
                                    />
                                    <text
                                        data-test="kpi_score_tick_value"
                                        textAnchor="middle"
                                        style={{
                                            fontSize: 10,
                                        }}>
                                        <title>{tick.value}</title>
                                        {tick.value.length > 30
                                            ? tick.value.substring(0, 30).trim() + "..."
                                            : tick.value}
                                    </text>
                                </g>
                            );
                        },
                    }}
                    enableLabel={true}
                    tooltip={({ value, indexValue, theme }: any) => {
                        return (
                            <div className={classes.tooltip}>
                                <div>
                                    <b>{indexValue}</b>
                                </div>
                                <div>
                                    <b>{graphData.isPercentage ? value + "%" : value}</b>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
            <div className={classnames(classes.kpiChartPrint, "kpiChartPrint")}></div>
        </div>
    );
};

export default KpiScoreBarCharts;
