import countryCodes from "utilities/misc/countryCodes.json";

const getCountries = () => {
    return countryCodes.sort((e1, e2) => {
        if (e1.TwoLetterIsoCode > e2.TwoLetterIsoCode) {
            return 1;
        }
        if (e1.TwoLetterIsoCode < e2.TwoLetterIsoCode) {
            return -1;
        }

        return 0;
    });
};

export { getCountries };
