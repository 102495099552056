import { useState } from "react";
import Provider from "services/api";

const useProductDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteProduct = async (id: number | undefined) => {
        if (id === undefined) return;
        setLoading(true);
        await Provider.Product.deleteProduct(id, {
            customError: {
                message: "Could not delete product",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        deleteProduct,
    };
};

export { useProductDelete };
