import React, { useState, useEffect } from "react";
import { Result } from "antd";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { useTranslation } from "react-i18next";

interface IProps {
    reportObject: any;
    supplierNumber: string;
}

const RiskReport = ({ reportObject, supplierNumber }: IProps) => {
    const { t } = useTranslation();
    const [queryParameterValue, setQueryParameterValue] = useState<string | undefined>(undefined);
    const { height } = useWindowDimensions();

    useEffect(() => {
        if (reportObject.queryParameterName && supplierNumber) {
            setQueryParameterValue(encodeURI(reportObject.queryParameterName + supplierNumber));
        }
    }, [reportObject, supplierNumber]);

    return (
        <>
            <>
                {reportObject && reportObject.embedLink ? (
                    <>
                        <iframe
                            width="100%"
                            height={300}
                            style={{
                                borderWidth: 0,
                                padding: 0,
                                height: height!,
                            }}
                            src={
                                reportObject.embedLink +
                                `${queryParameterValue !== undefined ? queryParameterValue : ""}`
                            }
                            title={"Spend Analytics"}></iframe>
                    </>
                ) : (
                    <Result
                        status="info"
                        title={t("generic_ReportNotConfigured")}
                        subTitle={t("generic_ReportNotConfiguredSubTitle")}
                    />
                )}
            </>
        </>
    );
};

export { RiskReport };
