import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useReportCreate = () => {
    const [loading, SetLoading] = useState(false);

    const addReport = async (presetId: number, creationData: any) => {
        SetLoading(true);

        let reportData = {
            title: creationData.reportTitle,
            description: creationData.reportDescription,
            filter: {
                chartType: creationData.chartType,
                samePeriodPreviousYear: creationData.previousPeriod,
                includeBenchmark: creationData.benchmark,
                from: creationData.dateRange[0].toISOString(),
                to: creationData.dateRange[1].toISOString(),
                dataRelation: {
                    dataId: creationData.kpi,
                    subLevels: (creationData.subLevels
                        ? creationData.subLevels
                        : creationData.singleSubLevel
                        ? [creationData.singleSubLevel]
                        : []
                    ).map((subLevel: any) => ({ id: subLevel })),
                },

                xAxisProduct: creationData.xAxisProduct,
                enabledStandardColumns:
                    creationData.enabledStandardColumns &&
                    creationData.enabledStandardColumns.toString(),
                enabledCustomColumns: creationData.enabledCustomColumns
                    ? creationData.enabledCustomColumns.map((x: any) => ({
                          customColumnId: x,
                      }))
                    : [],
            },
        };

        let response = await serviceProvider.Analytics.addReport(presetId, reportData).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
        return response;
    };

    return {
        loading,
        addReport,
    };
};

export { useReportCreate };
