import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any[];
    loading: boolean;
};

const useContactGroups = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.User.getContactGroups(undefined, {
                retryCount: 3,
                customerError: "Could not retrieve contact groups",
            }).catch(err => {
                setState({ ...data, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
            return data;
        })();
    }, []);
    return { loading: state.loading, data: state.data };
};

export default useContactGroups;
