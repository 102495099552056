import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Col, Input, notification, Form } from "antd";
import { FormRow, FormLabel } from "components/utilitycomponents";
import { useFolderUpdate } from "controller";

interface IProps {
    visible: boolean;
    parentId?: number;
    onClose: (reload?: boolean) => void;
}

const CreateFolderModal: FunctionComponent<IProps> = ({ visible, onClose, parentId }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { isFolderNameInUse, createFolder, loading } = useFolderUpdate();

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        await createFolder(fieldsValue.name, parentId);
        notification.success({
            message: t("success"),
            description: t("file_CreateFolderSuccess"),
        });

        form.resetFields();
        onClose(true);
    };

    return (
        <Modal
            wrapProps={{ "data-test": "folder_create_modal" }}
            title={t("file_CreateFolder")}
            open={visible}
            footer={[
                <Button key="ok" type="primary" onClick={handleSave} loading={loading}>
                    {t("generic_Save")}
                </Button>,
            ]}
            onCancel={() => {
                form.resetFields();
                onClose();
            }}>
            <Form form={form}>
                <FormRow>
                    <Col lg={8} xs={24}>
                        <FormLabel required>{t("file_FolderName")}</FormLabel>
                    </Col>

                    <Col lg={16} xs={24}>
                        <Form.Item
                            name="name"
                            validateTrigger="onChange"
                            rules={[
                                {
                                    validator: async (rule: any, value: any, callback: any) => {
                                        try {
                                            if (!value || value.trim() === "") {
                                                return Promise.reject(
                                                    new Error(t("inputIsRequiredError"))
                                                );
                                            }
                                            if (
                                                value.toLowerCase() === "_upload" ||
                                                value.toLowerCase() === "_pictures"
                                            ) {
                                                return Promise.reject(
                                                    new Error(t("file_FolderRestrictedName"))
                                                );
                                            }
                                            if (await isFolderNameInUse(value, parentId ?? null))
                                                return Promise.reject(
                                                    new Error(t("file_FolderNameInUse"))
                                                );
                                        } catch (error) {
                                            return Promise.reject(error);
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input placeholder={t("file_FolderName")} maxLength={255} />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default CreateFolderModal;
