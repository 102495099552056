import {
    Input,
    Typography,
    Divider,
    Table,
    InputNumber,
    Modal,
    Upload,
    DatePicker,
    Col,
    Row,
    Checkbox,
    Spin,
    Tooltip,
    Button,
    Select,
    Rate,
    Tag,
    Drawer,
    notification,
    Popconfirm,
    message,
    Card,
    List,
    Popover,
    Alert,
    Collapse,
    Badge,
    Radio,
    Descriptions,
    Switch,
    Progress,
    Menu,
    Tabs,
    Layout,
    Steps,
    Empty,
    Tree,
    Dropdown,
    Timeline,
    Slider,
    Result,
} from "antd";

const Option = Select.Option;
const TextArea = Input.TextArea;
const Text = Typography.Text;
const RangePicker = DatePicker.RangePicker;
const MonthPicker = DatePicker.MonthPicker;
const InputGroup = Input.Group;
const Panel = Collapse.Panel;
const MenuItem = Menu.Item;
const TabPane = Tabs.TabPane;

export {
    Input,
    Select,
    Button,
    Tooltip,
    Spin,
    Checkbox,
    Row,
    Col,
    DatePicker,
    Option,
    Upload,
    Modal,
    InputNumber,
    TextArea,
    Table,
    RangePicker,
    MonthPicker,
    Text,
    Typography,
    Divider,
    Rate,
    Tag,
    Drawer,
    notification,
    InputGroup,
    Popconfirm,
    message,
    List,
    Popover,
    Card,
    Alert,
    Panel,
    Collapse,
    Badge,
    Radio,
    Descriptions,
    Switch,
    Progress,
    Menu,
    MenuItem,
    Tabs,
    TabPane,
    Layout,
    Steps,
    Empty,
    Tree,
    Dropdown,
    Timeline,
    Slider,
    Result,
};
