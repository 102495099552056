import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Tag, Row, Col, Divider, notification, Form, Button } from "antd";
import IMasterDataPartner from "models/suppliers/IMasterDataPartner";
import ICustomHeadline from "models/suppliers/ICustomHeadline";
import { useCompanyType, usePartnerCategories, usePartnerCategoriesUpdate } from "controller";
import { SelectDataMultiple } from "components/utilitycomponents";
import { categoryType } from "models";
interface ModalProps {
    visible: boolean;
    selectedRows: IMasterDataPartner[];
    setModalVisibility(value: boolean): void;
    setCreateCategory2ModalVisible(value: boolean): void;
    addCategories(categories: string[]): void;
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    updateTable(): void;
    category2Headline: ICustomHeadline;
    createdCategories: { key: string | number; label: string }[];
    resetCreatedCategories(): void;
    onSelectedRowsChange: Function;
}

export const ContentModal = ({
    visible,
    selectedRows,
    setModalVisibility,
    setCreateCategory2ModalVisible,
    updateSelectedRows,
    updateTable,
    category2Headline,
    createdCategories,
    resetCreatedCategories,
    onSelectedRowsChange,
}: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const { update, loading } = usePartnerCategoriesUpdate();
    const { isDefaultCompany } = useCompanyType();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    useEffect(() => {
        if (createdCategories.length > 0) {
            let categoryIds: any[] = createdCategories
                .map(c => c.key)
                .concat(form.getFieldValue("selectCategory"));
            let categories = [
                ...selectedCategories,
                ...createdCategories.map(c => ({ id: c.key, name: c.label })),
            ];
            form.setFieldsValue({
                selectCategory: categoryIds,
            });
            setSelectedCategories(categories);
        }
    }, [createdCategories]);

    useEffect(() => {
        form.setFieldsValue({
            selectCategory:
                selectedRows.length === 1
                    ? selectedRows[0].category2!.value.map((x: any) => x.id)
                    : [],
        });
        setSelectedCategories(
            selectedRows.length === 1
                ? selectedRows[0].category2!.value.map((x: any) => ({
                      id: x.id,
                      name: x.name,
                  }))
                : []
        );
    }, [visible, selectedRows]);

    function onCancel() {
        form.resetFields();
        resetCreatedCategories();
        setModalVisibility(false);
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await update(
            selectedRows.map(r => Number(r.id.value)),
            values.selectCategory,
            "SecondCategory",
            categoryHeadline
        );
        notification.success({
            message: t("success"),
            description: t("supplier_CategoryUpdatePartnerSuccess", {
                categoryHeadline,
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier").toLocaleLowerCase()
                    : t("company_Type_Customer").toLocaleLowerCase(),
            }),
        });
        setModalVisibility(false);
        updateTable();
        onSelectedRowsChange(
            selectedRows.map(r => ({
                ...r,
                category2: {
                    ...r.category2,
                    value: selectedCategories.map((cat: any) => ({
                        id: cat.id,
                        name: cat.name,
                    })),
                },
            }))
        );
        resetCreatedCategories();
        form.resetFields();
    }

    function onTagClose(tag: any, key: any) {
        updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return selectedRows.map((row: IMasterDataPartner) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onNewCatClick() {
        setCreateCategory2ModalVisible(true);
    }

    const categoryHeadline =
        category2Headline && category2Headline.customLabel
            ? category2Headline.customLabel
            : t("category2");

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Modal
                        wrapProps={{ "data-test": "category2_modal" }}
                        title={t("supplier_CategoryTitle", {
                            categoryHeadline: categoryHeadline,
                            companyType: isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer"),
                        })}
                        open={visible}
                        onOk={onSubmit}
                        onCancel={onCancel}
                        confirmLoading={loading}
                        width={"75vmin"}
                        footer={[
                            <Button
                                loading={loading}
                                type="primary"
                                onClick={async () => await onSubmit()}>
                                {t("generic_Save")}
                            </Button>,
                        ]}>
                        <Form form={form} {...formItemLayout} colon={false}>
                            <Form.Item
                                label={t("supplier_SelectedSuppliers", {
                                    companyType: isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer"),
                                })}>
                                {selectedRows.length === 1 && (
                                    <div className="tagArea">{renderTags(false)}</div>
                                )}
                                {selectedRows.length > 1 && (
                                    <div className="tagArea">{renderTags(true)}</div>
                                )}
                            </Form.Item>
                            <Form.Item
                                name="selectCategory"
                                valuePropName="value"
                                label={t("categorySelectLabel", {
                                    categoryHeadline: categoryHeadline,
                                })}>
                                <SelectDataMultiple
                                    data-test="category2_select"
                                    style={{ width: "100%" }}
                                    placeholder={`${t(
                                        "categorySelectPlaceholder"
                                    )} ${categoryHeadline}`}
                                    dropdownRender={(menu: any) => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    padding: "8px",
                                                    cursor: "pointer",
                                                }}
                                                onMouseDown={() => onNewCatClick()}>
                                                <PlusOutlined /> {t("add")} {categoryHeadline}
                                            </div>
                                        </div>
                                    )}
                                    selectOptionField="name"
                                    useData={usePartnerCategories.bind(
                                        null,
                                        categoryType.two,
                                        "",
                                        createdCategories.length > 0 ? { reload: true } : null
                                    )}
                                    onChange={(values: number[], elements: any[]) => {
                                        setSelectedCategories(elements);
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
};

const CategoryTowModal = ContentModal;
export default CategoryTowModal;
