import { useState } from "react";
import { Provider } from "services";

const useStatusEventDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteStatusEvents = async (ids: number[]) => {
        setLoading(true);

        const res = await Provider.Partner.deleteStatusEvents(ids, {
            customError: {
                message: "Could not delete status event(s)",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        deleteStatusEvents,
    };
};

export { useStatusEventDelete };
