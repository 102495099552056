import { useState } from "react";
import { Provider } from "services";

const useCustomDropdownUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (customDropdownInput: any) => {
        setLoading(true);
        const statusCode = await Provider.Company.updateCustomDropdown(customDropdownInput, {
            customError: {
                message: "Could not update new custom dropdown",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return statusCode;
    };

    return {
        loading,
        update,
    };
};

export { useCustomDropdownUpdate };
