export enum Modules {
    NoAccess = 0,
    Review = 1,
    ActivityFeed = 2,
    Files = 4,
    Kpi = 8,
    Invite = 16,
    NoModule = 32,
    Admin = 64,
    Compliance = 128,
    Partner = 256,
    Contact = 512,
    Ncr = 1024,
    Project = 2048,
    AllModules = ~0,
}

export default Modules;
