import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import {
    Modal,
    Col,
    Select,
    Checkbox,
    Input,
    notification,
    Tooltip,
    DatePicker,
    Form,
    Row,
    Skeleton,
    Spin,
    Button,
} from "antd";
import { useTranslation } from "react-i18next";
import {
    SelectDataMultiple,
    SelectExternalPartnerMultiple,
    RichTextEditor,
    ButtonedInput,
    FormRow,
    FormLabel,
    SelectTreeDataMultiple,
    GridButton,
    PlusCircle,
    HelpCircle,
} from "components/utilitycomponents";
import classes from "./styles/createForm.module.scss";
import { copyText, getDomainUrl } from "utilities";
import {
    useReviewCategory,
    useCompanyContactsWithAccess,
    useSurveyCreate,
    useContactGroups,
    useSurveyTemplate,
} from "controller";
import { ICreateSurvey, Modules, SurveyExtraFieldType } from "models";
import { DynamicTextFields } from "./dynamicTextFields";
import SelectSurveyTemplateModal from "./selectSurveyTemplateModal";
import { parseValuesToSurveyObject } from "./parseValuesToSurveyObject";
import SupplierContacts from "./supplierContacts";
import dayjs from "utilities/daysJsConfig";
const Option = Select.Option;

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    reloadTable: Function;
}

const CreationModal: FunctionComponent<IProps> = ({ visible, onVisibleChange, reloadTable }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVoiceOfSupplier, setIsVoiceOfSupplier] = useState(false);
    const { loading: createLoading, createSurvey } = useSurveyCreate();
    const [reloadTemplateData, setReloadTemplateData] = useState<any>(null);
    const [selectedPartners, setSelectedPartners] = useState<any[]>([]);
    const [customizeReceivers, setCustomizeReceivers] = useState(false);
    const [surveyTemplateModal, setSurveyTemplateModal] = useState<any>({
        templateId: undefined,
        visible: false,
    });
    const { loading: templateLoading, data: templateData } = useSurveyTemplate(
        surveyTemplateModal.templateId,
        reloadTemplateData
    );

    useEffect(() => {
        if (visible) {
            setIsVoiceOfSupplier(false);
            setCustomizeReceivers(false);
            form.resetFields();
        }
    }, [visible]);

    useEffect(() => {
        if (templateData) {
            setIsVoiceOfSupplier(templateData.isVoiceOfSupplier);
            setCustomizeReceivers(false);
            form.resetFields();
        }
    }, [templateData]);

    const onCancel = () => {
        setSurveyTemplateModal({ visible: false, templateId: undefined });
        onVisibleChange(false);
        setSelectedPartners([]);
    };

    const onSubmit = async () => {
        const values = await form.validateFields();
        let survey: ICreateSurvey = parseValuesToSurveyObject({
            ...values,
            surveyTemplateId: surveyTemplateModal.templateId,
        });
        let token = await createSurvey(survey, { retryCount: 3, customError: "" });
        notification.success({
            style: {
                width: 600,
            },
            duration: 0,
            message: "Success",
            description: (
                <div>
                    {t("survey_CreateSuccess", "Survey created successfully")}
                    <ButtonedInput
                        value={`${getDomainUrl()}/Reviews/Survey/${token}`}
                        buttonText={t("copyUrl")}
                        inputId={`survey_url`}
                        onClick={() => copyText("survey_url")}
                    />
                </div>
            ),
        });
        reloadTable();
        onCancel();
    };

    return (
        <>
            <Modal
                wrapProps={{ "data-test": "create_survey_modal" }}
                title={
                    <Row>
                        <Col span={12}>{t("survey_CreateSurvey")}</Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                            <GridButton
                                data-test="create_survey_from_template_button"
                                icon={<PlusCircle />}
                                size="small"
                                onClick={() =>
                                    setSurveyTemplateModal({
                                        ...surveyTemplateModal,
                                        visible: true,
                                    })
                                }>
                                {t("survey_CreateSurveyFromTemplate")}
                            </GridButton>
                        </Col>
                    </Row>
                }
                open={visible}
                onCancel={() => onCancel()}
                footer={[
                    <Button
                        loading={createLoading}
                        type="primary"
                        onClick={async () => form.submit()}>
                        {t("generic_Create")}
                    </Button>,
                ]}
                onOk={() => form.submit()}
                width={900}
                style={{ top: 20 }}>
                <Spin spinning={templateLoading}>
                    <Form
                        form={form}
                        onFinish={onSubmit}
                        scrollToFirstError
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                        labelWrap>
                        <Form.Item
                            label={t("survey_Title")}
                            required
                            name="title"
                            valuePropName="value"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: t("survey_TitleRequired"),
                                },
                            ]}
                            initialValue={templateData ? templateData.title : undefined}>
                            <Input
                                maxLength={200}
                                placeholder={t("survey_TitlePlaceholder", "Insert a title")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("suvey_IntroductionText")}
                            name="introduction"
                            initialValue={templateData ? templateData.introduction : ""}>
                            <RichTextEditor
                                placeholder={t("survey_IntroductionPlaceholder")}
                                maxLength={1000}
                                bordered
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("survey_SurveyEndDate")}
                            name="endDate"
                            initialValue={undefined}>
                            <DatePicker
                                format={"MMM D, YYYY"}
                                placeholder={t("generic_SelectDate")}
                                disabledDate={(current: any) => {
                                    return current && current < dayjs().endOf("day");
                                }}
                                getPopupContainer={node =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <FormRow>
                            <Col span={6} className={classes.labelPadding}>
                                <Tooltip title={t("survey_InsertExtraSurveyFieldTooltip")}>
                                    <span>
                                        <HelpCircle />
                                    </span>
                                </Tooltip>{" "}
                                <FormLabel>{t("survey_InsertExtraSurveyField")}</FormLabel>
                            </Col>
                            <Col span={18}>
                                {templateLoading ? (
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                ) : (
                                    <DynamicTextFields
                                        type={"SurveyFields"}
                                        data={
                                            templateData
                                                ? templateData.extraFieldHeadlines.filter(
                                                      (x: any) =>
                                                          x.surveyExtraFieldTypeId ===
                                                          SurveyExtraFieldType.Survey
                                                  )
                                                : undefined
                                        }
                                    />
                                )}
                            </Col>
                        </FormRow>

                        <Form.Item
                            label={t("survey_SelectedPartners")}
                            required
                            name="partners"
                            valuePropName="value"
                            rules={[
                                {
                                    required: true,
                                    message: t("surveySelectedPartnersRequired"),
                                },
                            ]}
                            initialValue={undefined}>
                            <SelectExternalPartnerMultiple
                                selectAll
                                placeholder={t("survey_SelectedPartnerPlaceholder")}
                                includeChildren
                                onChange={(_: any, items: any) => {
                                    setSelectedPartners(items);
                                }}
                            />
                        </Form.Item>

                        <FormRow>
                            <Col span={6} className={classes.labelPadding}>
                                <Tooltip title={t("survey_InsertExtraSupplierFieldTooltip")}>
                                    <span>
                                        <HelpCircle />
                                    </span>
                                </Tooltip>{" "}
                                <FormLabel>
                                    <div style={{ display: "inline-flex", maxWidth: "165px" }}>
                                        {t("survey_InsertExtraSupplierField")}
                                    </div>
                                </FormLabel>
                            </Col>
                            <Col span={18}>
                                {templateLoading ? (
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                ) : (
                                    <DynamicTextFields
                                        type={"SupplierFields"}
                                        data={
                                            templateData
                                                ? templateData.extraFieldHeadlines.filter(
                                                      (x: any) =>
                                                          x.surveyExtraFieldTypeId ===
                                                          SurveyExtraFieldType.Supplier
                                                  )
                                                : undefined
                                        }
                                    />
                                )}
                            </Col>
                        </FormRow>

                        <Form.Item
                            label={t("survey_ReviewCriteria")}
                            required
                            name="selectedReviewCriteriaIds"
                            rules={[
                                {
                                    required: true,
                                    message: t("survey_criteriaRequired"),
                                    type: "array",
                                },
                            ]}
                            initialValue={
                                templateData ? templateData.selectedReviewCriteriaIds : undefined
                            }>
                            <SelectTreeDataMultiple
                                data-test="criteria_select"
                                allowClear={true}
                                useData={useReviewCategory}
                                selectAll
                                selectOptionField="name"
                                placeholder={t("survey_criteriaPlaceholder")}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                selectOnlyChildren
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("survey_Visibility")}
                            name="visibility"
                            initialValue={templateData ? templateData.visibilityId : 1}>
                            <Select
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }>
                                <Option key={0} value={1}>
                                    {t("internal")}
                                </Option>
                                <Option key={1} value={2}>
                                    {t("external")}
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("survey_ContactGroup")}
                            name="contactGroups"
                            valuePropName="value"
                            initialValue={undefined}>
                            <SelectDataMultiple
                                allowClear={true}
                                placeholder={t("survey_ContractGroupPlaceholder")}
                                useData={useContactGroups}
                                selectAll
                                selectOptionField="name"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("survey_Users")}
                            name="users"
                            valuePropName="value"
                            initialValue={undefined}>
                            <SelectDataMultiple
                                allowClear={true}
                                placeholder={t("survey_UsersPlaceholder")}
                                useData={useCompanyContactsWithAccess.bind(
                                    null,
                                    ["Administrator", "User", "lightuser"],
                                    Modules.Review
                                )}
                                selectAll
                                selectIdField="id"
                                selectOptionField="name"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("survey_VoiceOfSupplier")}
                            name="isVoiceOfSupplier"
                            valuePropName="checked"
                            initialValue={templateData ? templateData.isVoiceOfSupplier : false}>
                            <Checkbox
                                onChange={(e: any) => {
                                    setIsVoiceOfSupplier(e.target.checked);
                                    setCustomizeReceivers(false);
                                }}
                            />
                        </Form.Item>

                        {isVoiceOfSupplier && (
                            <>
                                <SupplierContacts selectedPartners={selectedPartners} />

                                <Form.Item
                                    label={t("survey_VoiceOfSupplierAllowGuests")}
                                    name="allowGuestVOS"
                                    valuePropName="checked"
                                    initialValue={
                                        templateData
                                            ? templateData.allowGuestVOS === null
                                                ? true
                                                : templateData.allowGuestVOS
                                            : true
                                    }>
                                    <Checkbox disabled={customizeReceivers} />
                                </Form.Item>
                                <Form.Item
                                    label={t("survey_VoiceOfSupplierIntroductionText")}
                                    name="introductionVOS"
                                    initialValue={
                                        templateData ? templateData.introductionVOS : false
                                    }>
                                    <RichTextEditor
                                        placeholder={t("survey_VoiceOfSupplierIntroductionText")}
                                        maxLength={1000}
                                        bordered
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label={t("survey_AllowGuests")}
                            name="allowGuest"
                            valuePropName="checked"
                            initialValue={templateData ? templateData.allowGuest : true}>
                            <Checkbox />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <SelectSurveyTemplateModal
                visible={surveyTemplateModal.visible}
                onClose={() => setSurveyTemplateModal({ ...surveyTemplateModal, visible: false })}
                onSelect={(templateId: number) => {
                    if (surveyTemplateModal.templateId === templateId) {
                        setReloadTemplateData({ reload: true });
                    }
                    setSurveyTemplateModal({ templateId, visible: false });
                }}
            />
        </>
    );
};

export default CreationModal;
