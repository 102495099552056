import { Modules } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

type StateType = {
    data: any[];
    loading: boolean;
};

const useContactsWithGroups = (
    userTypes: string[],
    accessTo: Modules,
    reload: any,
    usersOnly: boolean = false
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data: any = await Provider.User.getContactGroupsWithAllContacts(
                userTypes,
                accessTo,
                {
                    customerError: "Could not retrieve contact list",
                }
            ).catch(err => {
                setState({ ...data, loading: false });
                return Promise.reject(err);
            });
            if (usersOnly === true) {
                data.forEach((x: any) => {
                    if (x.children !== null) {
                        x.children = x.children.filter((c: any) => c.userId !== null);
                        x.userId = uuidv4();
                    }
                    if (x.children !== null && x.children.length === 0) {
                        x.children = null;
                        x.userId = null;
                    }
                });
                data = data.filter((x: any) => x.userId !== null);
            }

            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useContactsWithGroups };
