import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any;
    loading: boolean;
};

const useMatrixSADetail = (partnerId: number, templateId: number) => {
    const [state, setState] = useState<StateType>({
        data: {},
        loading: false,
    });
    useEffect(() => {
        (async () => {
            if (partnerId !== -1 && templateId !== -1) {
                setState({
                    ...state,
                    loading: true,
                });
                const data = await Provider.Compliance.getMatrixSADetail(partnerId, templateId, {
                    customError: {
                        message: "Could not retrieve self assessment detail",
                    },
                }).catch(err => {
                    setState({
                        ...state,
                        loading: false,
                    });
                    return Promise.reject(err);
                });
                setState({
                    data,
                    loading: false,
                });
            }
        })();
    }, [partnerId, templateId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMatrixSADetail };
