import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IActivityChild } from "models";
import { DownloadFile } from "components/utilitycomponents";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IActivityChild;
    isDeleted: boolean;
};

const FilesUploaded: FunctionComponent<PropsType> = ({ data, isDeleted }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_FileUploaded")}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("activity_FileUploaded")}
            </Fragment>
        );
    };

    const renderAttachedFiles = (data: IActivityChild) => {
        if (data.isDeleted) {
            return <div>{t("activity_FileDeleted")}</div>;
        }
        const filesName = data.filesName.split(",");
        const filesId = data.filesId.split(",");
        return filesId.map((id, index) => (
            <div data-test="download_file_button" key={index}>
                <DownloadFile file={{ id: parseInt(id), name: filesName[index] }} />
            </div>
        ));
    };

    return (
        <Fragment>
            <div data-test="file_uploaded_activity">
                <div className={classes.activityTitle}>
                    {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
                </div>
                {data.filesId !== null && data.filesName !== null && renderAttachedFiles(data)}
            </div>
        </Fragment>
    );
};

export default FilesUploaded;
