import React, { FunctionComponent, useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { UseSharedDashboardsTokenInfo, useRemoveSharedDashboardToken } from "controller";
import { DeleteIcon } from "components/utilitycomponents";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    visible: boolean;
    companyId: number;
    partnerId: number;
    onClose: Function;
}

const SharedDashboardsModal: FunctionComponent<IProps> = ({
    visible,
    companyId,
    partnerId,
    onClose,
}) => {
    const { t } = useTranslation();
    const { loading: removeLoading, removeGuestToken } = useRemoveSharedDashboardToken();
    const [reloadList, setReloadList] = useState({ reload: false });

    const { data, loading } = UseSharedDashboardsTokenInfo(companyId, partnerId, reloadList);
    const columns = [
        { title: t("analytics_col_dashboardName"), dataIndex: "name", key: "name" },
        {
            title: t("analytics_col_shared"),
            dataIndex: "created",
            key: "created",
            render: (val: any, record: any) => dayjs(val).format("MMM D, YYYY (HH:mm)"),
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            width: 50,
            render: (_: any, record: any) => (
                <Tooltip title={t("anylytics_StopSharing")}>
                    <DeleteIcon
                        style={{
                            fontSize: "18px",
                        }}
                        onClick={async () => {
                            await removeGuestToken([record.tokenId]);
                            setReloadList({ reload: true });
                        }}
                    />
                </Tooltip>
            ),
        },
    ];

    return (
        <Modal
            open={visible}
            title={t("analytics_SharedDashboards")}
            onCancel={() => onClose()}
            footer={null}>
            <Table
                scroll={{ y: 400 }}
                loading={loading || removeLoading}
                dataSource={data}
                columns={columns}
                rowKey={"tokenId"}
                pagination={false}
            />
        </Modal>
    );
};

export { SharedDashboardsModal };
