import { Button, FormInstance, Modal, Result } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import SecondStep from "./steps/secondStep";
import classes from "../styles/onBoarding.module.scss";
import Icon from "../onBoardComponents/leanLinkingLogo.svg";
import { useTranslation } from "react-i18next";
import { useAddRelationsUserDetails } from "controller/admin/user/useAddRelationsUserDetails";
import { IRelationsUserDetail } from "models/user";
import { useCompletedRelationsOnboarding } from "controller/company/useCompletedRelationsOnboarding";
import { useSelector } from "react-redux";
import { AppState } from "store";
const OnboardingExistingUsers = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const formRef = useRef<FormInstance>(form);
    const { addUserDetails } = useAddRelationsUserDetails();
    const { showCompletedOnboarding } = useCompletedRelationsOnboarding();
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [visibleModal, setVisibleModal] = useState<boolean>(true);
    const [completeForm, setCompleteForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const user = useSelector((state: AppState) => state.oidc.user);

    const storageKey = `completedOnboarding_${user?.profile["ll-UserId"]}`;
    useEffect(() => {
        const completedOnboarding = localStorage.getItem(storageKey) === "true";

        if (showCompletedOnboarding && !completedOnboarding) {
            setVisibleModal(true);
        } else {
            setVisibleModal(false);
        }
    }, [showCompletedOnboarding, storageKey]);
    const submitHandler = async () => {
        setLoading(true);
        const preservedValues = form.getFieldsValue(true);
        const validatedValues = await form.validateFields();
        let values = { ...preservedValues, ...validatedValues };
        let userDetails: IRelationsUserDetail = {
            departmentId: values.department,
            jobLevelId: values.jobLevel,
            useCaseIds: values.useCases,
        };
        await addUserDetails(userDetails);
        setCompleteForm(true);
        localStorage.setItem(storageKey, "true");
        setLoading(false);
    };
    return visibleModal ? (
        <Modal
            title=""
            open={visibleModal}
            width={822}
            className={classes.modal}
            footer={null}
            closeIcon={null}>
            {!completeForm ? (
                <>
                    <div className={classes.modalHeader}>
                        <img src={Icon} className={classes.icon} alt="" />
                        <span className={classes.invitationText}>
                            {t("onboarding_existing_user_info")}
                        </span>
                    </div>
                    <div className={classes.container}>
                        <Form layout="vertical" form={form}>
                            <div style={{ width: 400 }}>
                                <SecondStep
                                    formRef={formRef}
                                    maxWidth={400}
                                    setDisable={setDisableButton}
                                />
                            </div>
                        </Form>
                        <Button
                            loading={loading}
                            disabled={disableButton}
                            className={classes.submitButton}
                            onClick={async () => await submitHandler()}>
                            {t("generic_Submit")}
                        </Button>
                    </div>
                </>
            ) : (
                <Result
                    status={"success"}
                    title={t("onboarding_Success_Title")}
                    subTitle={t("onboarding_Success_Subtitle")}
                    extra={[
                        <Button
                            className={classes.nextButton}
                            onClick={() => setVisibleModal(false)}>
                            {t("generic_Done")}
                        </Button>,
                    ]}
                />
            )}
        </Modal>
    ) : null;
};

export default OnboardingExistingUsers;
