import React, { useEffect, useState, FunctionComponent } from "react";
import { Tabs, Row, Col } from "antd";
import classes from "./governance.module.scss";
import { CalendarOutlined, NotificationOutlined, ProfileOutlined } from "@ant-design/icons";
import { GovernanceTasks } from "./governanceTasks/governanceTasks";
import { useTranslation } from "react-i18next";
import { MeetingCalendar } from "./meetingCalendar/meetingCalendar";
import { MeetingForums } from "./meetingForums/meetingForums";
import { useParams } from "react-router-dom";
import { Relationships } from "./relationships/Relationships";
const { TabPane } = Tabs;

interface IProps {
    partnerSelectChange?: any;
    clickedTab: any;
    partnerId: number;
}

const Governance: FunctionComponent<IProps> = ({ partnerSelectChange, clickedTab, partnerId }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("governancePlaybook");
    const { presetId: meetingMinuteId }: any = useParams();

    useEffect(() => {
        if (meetingMinuteId) {
            setActiveTab("meetingMinutes");
        }
    }, [meetingMinuteId]);

    useEffect(() => {
        setActiveTab(clickedTab);
    }, [clickedTab]);

    useEffect(() => {
        if (
            partnerSelectChange &&
            partnerSelectChange.activeTab &&
            partnerSelectChange.activeTab === "relationships"
        ) {
            setActiveTab("relationships");
        }
    }, [partnerSelectChange]);

    return (
        <>
            <Tabs
                tabPosition="left"
                size="large"
                tabBarGutter={24}
                activeKey={activeTab}
                onTabClick={(key: string) => setActiveTab(key)}
                renderTabBar={() => <></>}>
                <TabPane
                    key="governancePlaybook"
                    tab={
                        <div className={classes.tab}>
                            <Row className={classes.tabContent}>
                                <Col span={8}>
                                    <ProfileOutlined className={classes.tabIcon} />
                                </Col>
                                <Col span={16} className={classes.tabText}>
                                    {t("governance_GovernancePlaybook")}
                                </Col>
                            </Row>
                        </div>
                    }>
                    <GovernanceTasks partnerId={partnerId} />
                </TabPane>

                <TabPane
                    key="meetingForums"
                    tab={
                        <div className={classes.tab}>
                            <Row className={classes.tabContent}>
                                <Col span={8}>
                                    <NotificationOutlined className={classes.tabIcon} />
                                </Col>
                                <Col span={16} className={classes.tabText}>
                                    {t("governance_MeetingForums")}
                                </Col>
                            </Row>
                        </div>
                    }>
                    <MeetingForums partnerId={partnerId} />
                </TabPane>
                <TabPane
                    key="meetingMinutes"
                    tab={
                        <div className={classes.tab}>
                            <Row className={classes.tabContent}>
                                <Col span={8}>
                                    <CalendarOutlined className={classes.tabIcon} />
                                </Col>
                                <Col span={16} className={classes.tabText}>
                                    {t("governance_MeetingMinutes")}
                                </Col>
                            </Row>
                        </div>
                    }>
                    <MeetingCalendar partnerId={partnerId} meetingMinuteId={meetingMinuteId} />
                </TabPane>

                <TabPane
                    key="relationships"
                    tab={
                        <div className={classes.tab}>
                            <Row className={classes.tabContent}>
                                <Col span={8}>
                                    <CalendarOutlined className={classes.tabIcon} />
                                </Col>
                                <Col span={16} className={classes.tabText}>
                                    {t("governance_Relationships")}
                                </Col>
                            </Row>
                        </div>
                    }>
                    <div className={classes.tabComponentContainer}>
                        <Relationships partnerId={partnerId} />
                    </div>
                </TabPane>
            </Tabs>
        </>
    );
};

export { Governance };
