import React, { FunctionComponent, Fragment, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Row, Button } from "antd";
import ActivityChild from "./childActivity";
import { IActivityChild } from "models";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: IActivityChild[];
    parentActivityId: number;
    onRemoveActivity: (parentActivityId: number, activityId: number) => void;
};

const ChildActivitiesForHome: FunctionComponent<PropsType> = ({
    data,
    parentActivityId,
    onRemoveActivity,
}) => {
    const { t } = useTranslation();
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    if (!data) return null;
    return (
        <Fragment>
            {data.map(
                (child: IActivityChild, index: number) =>
                    (index < 2 || (index >= 2 && showMoreClicked)) && (
                        <ActivityChild
                            key={index}
                            data={child}
                            parentActivityId={parentActivityId}
                            onRemoveActivity={onRemoveActivity}
                        />
                    )
            )}
            {data.length > 2 && (
                <Row style={{ textAlign: "center" }}>
                    <Button type="link" onClick={() => setShowMoreClicked(!showMoreClicked)}>
                        {showMoreClicked ? (
                            <Fragment>
                                {t("showLess")} <UpOutlined />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {t("showMore")} <DownOutlined />
                            </Fragment>
                        )}
                    </Button>
                </Row>
            )}
        </Fragment>
    );
};

export { ChildActivitiesForHome };
