import { CheckCircle, GridLight, Link01 } from "components/utilitycomponents";
import React, { useState } from "react";
import { useGovernanceTasksForSupplier, useGovernanceTaskCRUD } from "controller";
import { Result, Row, Tooltip } from "antd";
import classes from "./governanceTasks.module.scss";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    partnerId: number;
}

const GovernanceTasks = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, segment, loading } = useGovernanceTasksForSupplier(partnerId, reload);
    const { setStatus } = useGovernanceTaskCRUD();

    let columns: any[] = [
        {
            title: <div className={classes.colTitle}>{t("governance_Task")}</div>,
            dataIndex: "title",
            key: "title",
            width: 300,
            render: (val: string, row: any) => (
                <>
                    {row.url ? (
                        <Tooltip placement="bottom" title={row.url} mouseEnterDelay={0.5}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.taskTitle}
                                style={{ cursor: "pointer" }}
                                href={row.url}>
                                {val}
                            </a>
                            <Link01 className={classes.linkIcon} />
                        </Tooltip>
                    ) : (
                        <span className={classes.taskTitle}>{val}</span>
                    )}
                </>
            ),
        },
        {
            title: <div className={classes.colTitle}>{t("governance_Condition")}</div>,
            dataIndex: "mandatory",
            key: "mandatory",
            width: 200,
            render: (val: boolean) => (
                <div>{val ? t("generic_Mandatory") : t("generic_Optional")}</div>
            ),
        },
        {
            title: <div className={classes.colTitle}>{t("governance_Status")}</div>,
            dataIndex: "done",
            key: "done",
            width: 100,
            render: (val: boolean, row: any) => (
                <div>
                    {val ? (
                        <Tooltip title={t("governance_SetInComplete")} placement="right">
                            <span>
                                <CheckCircle
                                    style={{ color: "#73d13d" }}
                                    onClick={() => handleOnDoneClick(false, row.id)}
                                    className={classes.doneIcon}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t("governance_SetCompleted")} placement="right">
                            <span>
                                <CheckCircle
                                    style={{ color: "#b2bccd" }}
                                    onClick={() => handleOnDoneClick(true, row.id)}
                                    className={classes.doneIcon}
                                />
                            </span>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: <div className={classes.colTitle}>{t("governance_LastModified")}</div>,
            dataIndex: "modified",
            key: "modified",
            width: 200,
            render: (val: string) => <div>{val && dayjs(val).format("MMM D, YYYY (HH:mm)")}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_ModifiedBy")}</div>,
            dataIndex: "modifiedBy",
            key: "modifiedBy",
            width: 200,
            ellipsis: true,
        },
    ];

    async function handleOnDoneClick(done: boolean, taskId: number) {
        let input = {
            governanceTaskId: taskId,
            partnerId: partnerId,
            done: done,
        };
        await setStatus(input);
        setReload({ reload: true });
    }

    return (
        <div>
            {data && segment && segment.id !== 0 && (
                <GridLight
                    className={classes.customTableStyle}
                    title={() =>
                        segment && (
                            <div>
                                <Row className={classes.typeLabel}>
                                    {t("governance_SupplierSegment", {
                                        segmentName: segment.segmentName,
                                        interpolation: { escapeValue: false },
                                    })}
                                </Row>
                                <Row className={classes.tableTitle}>
                                    {t("governance_SuggestedTasks")}
                                </Row>
                            </div>
                        )
                    }
                    tableSize="big"
                    pagination="none"
                    bordered={false}
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    renderTableOnDataChange
                    emptyText={t("governanceTask_NoData")}
                />
            )}

            {!loading && segment.id === 0 && (
                <Result
                    style={{ marginLeft: -10 }}
                    title={t("governance_NoParentLevelSegmentAligned")}
                />
            )}
        </div>
    );
};

export { GovernanceTasks };
