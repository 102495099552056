import { IOwlerNewsFeed } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IOwlerNewsFeed[];
};

const useOwlerNewsFeed = (domain: string | undefined, reload?: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (domain === undefined || domain === null) {
                setState({ loading: false, data: [] });
                return;
            }
            setState({ ...state, loading: true });
            let data: IOwlerNewsFeed[] = await Provider.Owler.GetOwlerNewsFeed(domain).catch(
                err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                }
            );

            setState({ loading: false, data });
        })();
    }, [domain, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useOwlerNewsFeed;
