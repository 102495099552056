import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const usePartnerChildren = (partnerId: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (partnerId) {
                setState({ ...state, loading: true });
                const data = await Provider.Partner.getPartnerChildrenById(partnerId).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ ...state, loading: false, data });
            } else {
                setState({ ...state, loading: false, data: [] });
            }
        })();
    }, [partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerChildren };
