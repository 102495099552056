import React, { FunctionComponent, Fragment } from "react";
import ContactsGroupOverview from "components/contentcomponents/contacts/groupOverview/overview";

export const ContactsGroupOverviewPage: FunctionComponent = () => {
    return (
        <Fragment>
            <ContactsGroupOverview />
        </Fragment>
    );
};
export default ContactsGroupOverviewPage;
