import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
    segment: any;
};

const useGovernanceTasksForSupplier = (partnerId: number, reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
        segment: undefined,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Governance.getSupplierGovernanceTasks(partnerId, {
                customError: {
                    message: "Could not retrieve governance tasks",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({
                loading: false,
                data: data.governanceTasks,
                segment: data.alignedSegment,
            });
        })();
    }, [partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
        segment: state.segment,
    };
};

export { useGovernanceTasksForSupplier };
