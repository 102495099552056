import { API_ERROR, ApiStateType } from "./types";

const initialState: ApiStateType = {
    apiError: {
        title: null,
        message: "",
    },
};
export function apiReducer(state = initialState, action: any) {
    switch (action.type) {
        case API_ERROR:
            return {
                apiError: {
                    title: action.payload.title,
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
}
