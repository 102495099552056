import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import {
    CheckSquare,
    ColoredPercentTag,
    DownloadFile,
    Minus,
    MinusSquare,
    ReviewRate,
    ShowMore,
} from "components/utilitycomponents";
import classes from "./styles/complianceHeadlines.module.scss";
import classnames from "classnames";
import { IComplianceQuestion, IComplianceTemplate } from "models";

type PropsType = {
    data: IComplianceTemplate;
    canSeeScores: boolean;
};

const ComplianceTemplateDetail: FunctionComponent<PropsType> = ({ data, canSeeScores }) => {
    const { t } = useTranslation();
    return (
        <>
            {data.headLines.map((headline: any, index: number) => (
                <Fragment key={index}>
                    <Row gutter={16} className={classes.head}>
                        <Col
                            data-test={`headline_${index + 1}`}
                            span={12}
                            className={classes.title}>
                            {index + 1}. {headline.headline}{" "}
                        </Col>
                        <Col span={canSeeScores ? 10 : 12} style={{ textAlign: "right" }}>
                            {canSeeScores ? (
                                <Row className={classes.score}>
                                    <b>{t("compliance_SectionScore")}:</b>
                                </Row>
                            ) : (
                                <Row className={classes.title}>{t("compliance_Result")}</Row>
                            )}
                        </Col>
                        {canSeeScores && (
                            <Col span={2} style={{ textAlign: "center" }}>
                                {headline.averageScore !== null && (
                                    <ColoredPercentTag
                                        data-test="average_score_tag"
                                        value={headline.averageScore}
                                    />
                                )}
                            </Col>
                        )}
                    </Row>
                    {headline.complianceQuestions.map(
                        (question: IComplianceQuestion, subIndex: number) => (
                            <Row
                                data-test={`question_area_${index + 1}_${subIndex + 1}`}
                                gutter={16}
                                key={subIndex}
                                className={
                                    subIndex !== headline.complianceQuestions.length - 1
                                        ? classnames([classes.question])
                                        : classnames([classes.question, classes.last])
                                }>
                                <Col data-test="question" span={12}>
                                    {index + 1}.{subIndex + 1}. {question.question}
                                </Col>
                                <Col
                                    data-test="question_answer"
                                    span={canSeeScores ? 10 : 12}
                                    style={{
                                        textAlign: "left",
                                    }}>
                                    {renderTypedComponent(
                                        question.questionType,
                                        question.complianceAnswers.length !== 0
                                            ? question.questionType ===
                                              "MultipleChoiceCustomDropdown"
                                                ? question.complianceAnswers[0]
                                                      .multipleChoiceQuestionValue
                                                : question.complianceAnswers[0].value
                                            : "",
                                        question.questionType === "AttachDocuments" &&
                                            question.complianceAnswers.length !== 0
                                            ? question.complianceAnswers[0].files
                                            : undefined
                                    )}
                                    {question.complianceAnswers.length !== 0 &&
                                        question.complianceAnswers[0].comment !== null && (
                                            <Row>
                                                <Col
                                                    span={24}
                                                    style={{
                                                        textAlign: "left",
                                                        marginTop: "5px",
                                                    }}>
                                                    <div className={classes.commentTitle}>
                                                        {t("comment")}:
                                                    </div>
                                                    <ShowMore
                                                        text={question.complianceAnswers[0].comment}
                                                        className={classes.comment}
                                                        characterLimit={200}
                                                        lineLimit={2}
                                                        showGuidText={false}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                </Col>
                                {canSeeScores && (
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        {question.complianceAnswers.length !== 0 &&
                                            question.complianceAnswers[0].averageScore !== null && (
                                                <ColoredPercentTag
                                                    data-test="compliance_answer_average_score_tag"
                                                    value={
                                                        question.complianceAnswers[0].averageScore
                                                    }
                                                />
                                            )}
                                    </Col>
                                )}
                            </Row>
                        )
                    )}
                </Fragment>
            ))}
        </>
    );
};

const renderTypedComponent = (type: string, answer: any, additionalValues?: any) => {
    switch (type) {
        case "Check":
            return answer === "true" ? (
                <CheckSquare style={{ color: "#01b400", fontSize: "25px" }} />
            ) : (
                <MinusSquare style={{ color: "#b20000", fontSize: "25px" }} />
            );
        case "Stars":
            return <ReviewRate val={answer ? parseInt(answer) : 0} />;
        case "Date":
            return answer ? (
                <span className={classes.answer}>{answer}</span>
            ) : (
                <Minus style={{ color: "#b20000" }} />
            );
        case "AttachDocuments":
            return (
                <>
                    {additionalValues && additionalValues.length !== 0 ? (
                        additionalValues.map((file: any) => <DownloadFile file={file} />)
                    ) : (
                        <Minus style={{ color: "#b20000" }} />
                    )}
                </>
            );
        case "MultipleChoiceCustomDropdown":
            return (
                <>
                    {answer &&
                        answer.split("|").map((ans: string) => (
                            <Row style={{ marginBottom: 12 }}>
                                <Col span={24}>
                                    {" "}
                                    <ShowMore
                                        className={classes.answer}
                                        text={ans}
                                        characterLimit={200}
                                        lineLimit={2}
                                        showGuidText={false}
                                    />
                                </Col>
                            </Row>
                        ))}
                </>
            );
        default:
            return answer.toString() ? (
                <ShowMore
                    className={classes.answer}
                    text={answer.toString()}
                    characterLimit={200}
                    lineLimit={2}
                    showGuidText={false}
                />
            ) : (
                <Minus style={{ color: "#b20000" }} />
            );
    }
};

export default ComplianceTemplateDetail;
