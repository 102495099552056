import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "antd";
import { InfoCircle, Plus, SelectFlatTreeData, TreeTransfer } from "components/utilitycomponents";
import { useExternalPartnerContacts } from "controller";
import classes from "./selectRespondent.module.scss";
import { CreateModal } from "../../../../contacts/shared/createModal";

type PropTypes = {
    supplierId: number;
    supplierName: string;
    onSelectedContactsChange: Function;
    defaultValues?: any[];
    visible: boolean;
};

const SelectSupplierRespondents: FunctionComponent<PropTypes> = ({
    supplierId,
    supplierName,
    onSelectedContactsChange,
    defaultValues,
    visible,
}) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const { data, loading } = useExternalPartnerContacts(supplierId, reload);
    const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
    const [createModal, setCreateModal] = useState<any>({
        visible: false,
        newContacts: [],
    });
    const [addedContacts, setAddedContacts] = useState<number[]>([]);
    useEffect(() => {
        onSelectedContactsChange({
            receiverContacts: selectedContacts,
            receiverEmails: [],
        });
    }, [selectedContacts, defaultValues]);

    useEffect(() => {
        setAddedContacts(createModal.newContacts);
    }, [data]);

    return (
        <div style={{ display: visible ? "block" : "none" }}>
            <TreeTransfer
                data={data}
                selectOptionField="name"
                selectOnlyChildren
                onTransferChange={(ids: number[]) => setSelectedContacts(ids)}
                injectedTargetKeys={defaultValues ? defaultValues : addedContacts}>
                {({
                    direction,
                    selectedSourceKeys,
                    selectedTargetKeys,
                    targetKeys,
                    targetData,
                    removeSourceCheckedKeys,
                    removeTargetCheckedKeys,
                    removeTargetSelectAll,
                    removeSourceSelectAll,
                    setSelectedSourceKeys,
                    setSelectedTargetKeys,
                    removeTargetSearch,
                }: any) => {
                    if (direction === "left") {
                        return (
                            <SelectFlatTreeData
                                title={supplierName + " " + t("Contact", { count: 2 })}
                                placeholder="Search Name or Email"
                                selectAll
                                selectOnlyChildren
                                useData={() => ({
                                    loading,
                                    data,
                                })}
                                selectOptionField={(item: any) => {
                                    return {
                                        node: (
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "350px",
                                                    display: "block",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                                title={
                                                    item.emailAddress
                                                        ? item.emailAddress
                                                        : item.name
                                                }>
                                                {item.name +
                                                    (item.emailAddress
                                                        ? ` (${item.emailAddress})`
                                                        : "")}
                                            </span>
                                        ),
                                        text:
                                            item.name +
                                            (item.emailAddress ? item.emailAddress : ""),
                                        disabled: targetKeys.includes(item.id),
                                    };
                                }}
                                height={350}
                                onCheck={(checked: any, info: any) => {
                                    info.node.id = Array.isArray(info.node.id)
                                        ? info.node.id
                                        : [info.node.id];
                                    if (info.checked === true || info.selected === true) {
                                        setSelectedSourceKeys([
                                            ...selectedSourceKeys,
                                            ...info.node.id,
                                        ]);
                                    } else {
                                        setSelectedSourceKeys(
                                            selectedSourceKeys.filter(
                                                (s: any) => !info.node.id.includes(s)
                                            )
                                        );
                                    }
                                }}
                                onMultipleCheck={(ids: any[]) => {
                                    setSelectedSourceKeys(ids);
                                }}
                                blockNode
                                checkable
                                checkStrictly
                                removeCheckedKeys={removeSourceCheckedKeys}
                                removeSelectAll={removeSourceSelectAll}
                                removeSearch={removeTargetSearch}
                            />
                        );
                    }
                    if (direction === "right") {
                        return (
                            <SelectFlatTreeData
                                selectAll
                                selectOnlyChildren
                                title={t("survey_Wizard_SelectedRespondents")}
                                useData={() => ({
                                    loading: false,
                                    data: targetData,
                                })}
                                selectOptionField={(item: any) => {
                                    return {
                                        node: (
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "350px",
                                                    display: "block",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                                title={
                                                    item.emailAddress
                                                        ? item.emailAddress
                                                        : item.name
                                                }>
                                                {item.name +
                                                    (item.emailAddress
                                                        ? ` (${item.emailAddress})`
                                                        : "")}
                                            </span>
                                        ),
                                        text:
                                            item.name +
                                            (item.emailAddress ? item.emailAddress : ""),
                                    };
                                }}
                                height={350}
                                onCheck={(checked: any, info: any) => {
                                    if (info.checked === true || info.selected === true) {
                                        setSelectedTargetKeys([
                                            ...selectedTargetKeys,
                                            info.node.id,
                                        ]);
                                    } else {
                                        setSelectedTargetKeys(
                                            selectedTargetKeys.filter(
                                                (s: any) => s !== info.node.id
                                            )
                                        );
                                    }
                                }}
                                onMultipleCheck={(ids: any[]) => {
                                    setSelectedTargetKeys(ids);
                                }}
                                blockNode
                                checkable
                                checkStrictly
                                removeCheckedKeys={removeTargetCheckedKeys}
                                removeSelectAll={removeTargetSelectAll}
                                removeSearch={removeTargetSearch}
                            />
                        );
                    }
                }}
            </TreeTransfer>
            <Button
                type="primary"
                style={{ marginTop: "10px" }}
                icon={<Plus />}
                onClick={() => setCreateModal({ newContacts: [], visible: true })}>
                {t("Contacts_Add")}
            </Button>
            <Tooltip title={t("survey_wizard_AddContactTooltip")}>
                <span>
                    <InfoCircle className={classes.tooltipIcom} />
                </span>
            </Tooltip>
            {createModal.visible && (
                <CreateModal
                    visible={createModal.visible}
                    onClose={(reload, contactId) => {
                        if (reload) {
                            setCreateModal({ newContacts: [contactId], visible: false });
                            setReload({ reload: true });
                        } else {
                            setCreateModal({ newContacts: [], visible: false });
                        }
                    }}
                    partnerId={supplierId}
                />
            )}
        </div>
    );
};

export default SelectSupplierRespondents;
