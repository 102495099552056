import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { User } from "oidc-client";
import { AppState } from "../../../../store";
import {
    Row,
    Col,
    Modal,
    Input,
    Select,
    Divider,
    notification,
    InputNumber,
    Form,
    Button,
    Tooltip,
    Flex,
    Skeleton,
} from "antd";
import countryCodes from "../../../../utilities/misc/countryCodes.json";
import currencyCodes from "../../../../utilities/misc/currencies.json";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import { categoryType } from "../../../../models/suppliers/ICategory";
import ICustomColumn from "../../../../models/suppliers/customcolumns/ICustomColumn.js";
import IHeadlineSetting from "../../../../models/suppliers/IHeadlineSetting.js";
import { useTranslation } from "react-i18next";
import {
    useCompanyType,
    useContacts,
    useCustomDropDown,
    useIsCompanyNumberInUse,
    useNcrFeature,
    usePartnerCategories,
    usePartnerGroups,
    usePartnerMasterData,
    usePartnerStatuses,
    usePartnerUpdate,
} from "controller";
import {
    Plus,
    SelectData,
    SelectDataMultiple,
    SelectPartnerContacts,
    UserPlus01,
    UserRight01,
    UserX01,
} from "components/utilitycomponents";
import classes from "components/contentcomponents/suppliers/supplierProfile/masterData/styles/masterData.module.scss";
import SwitchOwnerModal from "../supplierProfile/masterData/switchOwnerModal";
const { TextArea } = Input;
const { Option } = Select;

interface ModalProps {
    visible: boolean;
    selectedRow: IMasterDataPartner;
    setModalEditPartnerVisibility(value: boolean): void;
    setCreateGroupModalVisible(value: boolean): void;
    setCreateCategoryOneModalVisible(value: boolean): void;
    setCreateCategoryTwoModalVisible(value: boolean): void;
    updateTable(): void;
    customColumns: ICustomColumn[];
    customHeadlines: IHeadlineSetting;
    user: User;
    createdCategories1: { key: string | number; label: string }[];
    createdCategories2: { key: string | number; label: string }[];
    createdGroup: { key: string | number; label: string }[];
    resetCreatedCategories(): void;
    resetCreatedGroup(): void;
}

export const ContentModal = (props: ModalProps) => {
    const [reload, setReload] = useState<any>({ reload: false });
    const [canEditOwner, setCanEditOwner] = useState(false);
    const [canAddSelfToOwners, setCanAddSelfToOwners] = useState(false);
    const [canRemoveSelfFromOwners, setCanRemoveSelfFromOwners] = useState(false);
    const [canSwitchOwner, setCanSwitchOwner] = useState(false);
    const { loading: loadingPartnerData, data: masterdata, companySettings } = usePartnerMasterData(
        props.selectedRow ? props.selectedRow.id.value : undefined,
        reload
    );
    const { data: customDropdowns } = useCustomDropDown();
    useEffect(() => {
        if (masterdata && companySettings !== null) {
            let isOwnerOnRecord = masterdata?.ownerIds.some(
                (id: number) => id.toString() === currentUserContactId
            );
            if (userType === "administrator") {
                setCanEditOwner(true);
                setCanAddSelfToOwners(!isOwnerOnRecord);
                setCanRemoveSelfFromOwners(isOwnerOnRecord);
                setCanSwitchOwner(isOwnerOnRecord);
            }
            if (userType === "user") {
                setCanAddSelfToOwners(
                    !isOwnerOnRecord && companySettings.allowUsersMakeThemSelvesAsOwner
                );

                setCanRemoveSelfFromOwners(
                    isOwnerOnRecord && companySettings.allowUsersMakeThemSelvesAsOwner
                );

                setCanSwitchOwner(isOwnerOnRecord && companySettings.allowNormalUsersToSwitchOwner);
            }
        }
    }, [masterdata]);

    const [modalOpen, toggleModalOpen] = useState(props.visible);
    const [form] = Form.useForm();
    const currentUserContactId = useSelector(
        (state: any) => state.oidc.user.profile["ll-ContactId"]
    );

    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            lg: { span: 22 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            lg: { span: 22 },
        },
    };
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined);
    const [selectedStatusName, setSelectedStatusName] = useState<any>(undefined);
    const userType = props.user.profile["ll-Usertype"];
    const { updatePartnerDetail, loading, updatePartnerOwners } = usePartnerUpdate();
    const { checkForExistingNumber } = useIsCompanyNumberInUse();
    const [categoryAdded, setCategoryAdded] = useState<any>({
        added: false,
    });
    const [groupAdded, setGroupAdded] = useState<any>({
        added: false,
    });
    const { isDefaultCompany } = useCompanyType();
    const { hasNcrFeature } = useNcrFeature();
    const [showModals, setShowModals] = useState<any>({
        showSwitchOwnerModal: false,
    });

    useEffect(() => {
        if (masterdata) {
            setSelectedCurrency(masterdata.currency);
        }
        form.resetFields();
    }, [masterdata]);

    useEffect(() => {
        toggleModalOpen(props.visible);
    }, [props.visible]);

    useEffect(() => {
        if (props.createdCategories1.length > 0) {
            let categories: any[] = props.createdCategories1.concat(
                form.getFieldValue("cat1")
                    ? form.getFieldValue("cat1").map((x: any) => ({ key: x, label: x }))
                    : []
            );

            form.setFieldsValue({
                cat1: categories.map((x: any) => x.key),
            });
        } else {
            form.setFieldsValue({
                cat1: props.selectedRow
                    ? props.selectedRow.category1!.value.map((x: any) => x.key)
                    : [],
            });
        }
        setCategoryAdded({
            added: true,
        });
        form.resetFields();
    }, [props.createdCategories1]);

    useEffect(() => {
        if (props.createdCategories2.length > 0) {
            let categories: any[] = props.createdCategories2.concat(
                form.getFieldValue("cat2")
                    ? form.getFieldValue("cat2").map((x: any) => ({ key: x, label: x }))
                    : []
            );
            form.setFieldsValue({
                cat2: categories.map((x: any) => x.key),
            });
        } else {
            form.setFieldsValue({
                cat2: props.selectedRow
                    ? props.selectedRow.category2!.value.map((x: any) => x.key)
                    : [],
            });
        }
        setCategoryAdded({
            added: true,
        });
        form.resetFields();
    }, [props.createdCategories2]);

    useEffect(() => {
        if (props.createdGroup.length > 0) {
            form.setFieldsValue({
                group: props.createdGroup[0].key,
            });
            setGroupAdded({
                added: true,
            });
        }
        form.resetFields();
    }, [props.createdGroup]);

    async function onSubmit() {
        let values = await form.validateFields();

        const statusUpdated = values.status !== masterdata?.statusId;
        let customColumns: any = [];
        if (values.customColumn) {
            let existingCCs = Object.keys(props.selectedRow.customColumnsValuesWithIndex);
            Object.keys(values.customColumn)
                .filter((key: any) => existingCCs.filter((ccKey: any) => ccKey === key).length > 0)
                .forEach((key: any) => {
                    customColumns.push({
                        customColumnId: parseInt(key),
                        partnerId: props.selectedRow.id.value,
                        value: values.customColumn[key],
                    });
                });
        }
        let updatedPartnerDetails: any = {
            companyName: values.companyName.trim(),
            companyNumber: values.companyNumber.trim(),
            domain: values.domain ? values.domain.trim() : undefined,
            pocEmail: values.email,
            pocCountry: values.pocCountry,
            pocFirstName: values.pocFirstName,
            pocLastName: values.pocLastName,
            pocPhone: values.pocPhone,
            currency: selectedCurrency,
            yearlyValueOfCooperation: parseFloat(values.yearlyVal),
            statusId: values.status,
            statusDescription:
                statusUpdated && values.status !== undefined
                    ? t("supplier_statusUpdated", {
                          userFullName: props.user.profile["ll-Fullname"],
                          statusName: selectedStatusName,
                      })
                    : statusUpdated && values.status === undefined
                    ? t("supplier_statusRemoved", {
                          userFullName: props.user.profile["ll-Fullname"],
                      })
                    : null,
            groupId: values.group,
            ownerIds: values.owner,
            category1Ids: values.cat1 ? values.cat1 : [],
            category2Ids: values.cat2 ? values.cat2 : [],
            customColumnValues: customColumns,
            complianceContacts: [...new Set(values.complianceContacts)],
            ncrContacts: hasNcrFeature ? [...new Set(values.ncrContacts)] : [],
        };

        await updatePartnerDetail(props.selectedRow.id.value, updatedPartnerDetails);
        notification.success({
            message: t("success"),
            description: t("supplier_UpdateSupplierSuccess", {
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier").toLocaleLowerCase()
                    : t("company_Type_Customer").toLocaleLowerCase(),
            }),
        });

        setSelectedCurrency("");
        props.resetCreatedCategories();
        props.resetCreatedGroup();
        form.resetFields();
        props.setModalEditPartnerVisibility(false);
        await props.updateTable();
    }

    function onCancel() {
        setSelectedCurrency("");
        form.resetFields();
        props.resetCreatedCategories();
        props.resetCreatedGroup();
        props.setModalEditPartnerVisibility(false);
    }

    function renderCountryOptions() {
        let countryArray = countryCodes.sort((e1, e2) => {
            if (e1.TwoLetterIsoCode > e2.TwoLetterIsoCode) {
                return 1;
            }
            if (e1.TwoLetterIsoCode < e2.TwoLetterIsoCode) {
                return -1;
            }

            return 0;
        });
        return countryArray.map((element: { TwoLetterIsoCode: string; EnglishName: string }) => {
            return (
                <Option key={element.TwoLetterIsoCode} value={element.TwoLetterIsoCode}>
                    {element.TwoLetterIsoCode}
                </Option>
            );
        });
    }

    function renderCurrencyOptions() {
        let currencyArray = currencyCodes.sort((e1, e2) => {
            if (e1.CurrencyCode > e2.CurrencyCode) {
                return 1;
            }
            if (e1.CurrencyCode < e2.CurrencyCode) {
                return -1;
            }

            return 0;
        });

        return currencyArray.map((element: { CurrencyCode: string; EnglishName: string }) => {
            return (
                <Option key={element.CurrencyCode} value={element.CurrencyCode}>
                    {element.CurrencyCode}
                </Option>
            );
        });
    }

    function onSelectCurrencyChange(currencyCode: string) {
        if (currencyCode === undefined) {
            form.setFieldsValue({ yearlyVal: undefined });
        }
        setSelectedCurrency(currencyCode);
    }

    function onNewCatClick(categoryType: 1 | 2) {
        if (categoryType === 1) {
            props.setCreateCategoryOneModalVisible(true);
        } else {
            props.setCreateCategoryTwoModalVisible(true);
        }
    }

    function onNewGroupClick() {
        props.setCreateGroupModalVisible(true);
    }

    //#region Headlines
    const companyHeadline =
        props.customHeadlines.companyHeadline &&
        props.customHeadlines.companyHeadline!.customLabel !== null
            ? props.customHeadlines.companyHeadline!.customLabel
            : t("companyName", "Company Name");
    const companyNumberHeadline =
        props.customHeadlines.companyNumberHeadline &&
        props.customHeadlines.companyNumberHeadline!.customLabel !== null
            ? props.customHeadlines.companyNumberHeadline!.customLabel
            : t("companyNumber", "Company Number");
    const yearlyValHeadline =
        props.customHeadlines.valueHeadline &&
        props.customHeadlines.valueHeadline!.customLabel !== null
            ? props.customHeadlines.valueHeadline!.customLabel
            : t("supplier_Value", "Yearly Value of Cooperation");
    const statusHeadline =
        props.customHeadlines.statusHeadline &&
        props.customHeadlines.statusHeadline!.customLabel !== null
            ? props.customHeadlines.statusHeadline!.customLabel
            : t("supplierStatus", "Status");
    const groupHeadline =
        props.customHeadlines.groupHeadline &&
        props.customHeadlines.groupHeadline!.customLabel !== null
            ? props.customHeadlines.groupHeadline!.customLabel
            : t("group", "Group");
    const ownerHeadline =
        props.customHeadlines.ownerHeadline &&
        props.customHeadlines.ownerHeadline!.customLabel !== null
            ? props.customHeadlines.ownerHeadline!.customLabel
            : t("owners", "Owners");
    const cat1Headline =
        props.customHeadlines.category1Headline &&
        props.customHeadlines.category1Headline!.customLabel !== null
            ? props.customHeadlines.category1Headline!.customLabel
            : t("category1", "Category 1");
    const cat2Headline =
        props.customHeadlines.category2Headline &&
        props.customHeadlines.category2Headline!.customLabel !== null
            ? props.customHeadlines.category2Headline!.customLabel
            : t("category2", "Category 2");
    const domainHeadline =
        props.customHeadlines.Domain && props.customHeadlines.Domain!.customLabel !== null
            ? props.customHeadlines.Domain!.customLabel
            : t("partner_Website");

    //#endregion

    const setSelfAsOwner = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const oldOwners = form.getFieldValue("owner") ? form.getFieldValue("owner") : [];
        const newOwners = [...oldOwners, parseInt(currentUserContactId)];
        await updatePartnerOwners(props.selectedRow.id.value, newOwners);
        form.setFieldsValue({ owner: newOwners });
        notification.success({
            message: t("success"),
            description: t("updateOwnerSuccess", {
                ownerHeadline: ownerHeadline,
            }),
        });
        props.updateTable();
        setReload({ reload: true });
    };

    const removeSelfAsOwner = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const owners = form.getFieldValue("owner");
        owners.splice(owners.indexOf(parseInt(currentUserContactId)), 1);
        await updatePartnerOwners(props.selectedRow.id.value, owners);
        form.setFieldsValue({ owner: owners });
        notification.success({
            message: t("success"),
            description: t("updateOwnerSuccess", {
                ownerHeadline: ownerHeadline,
            }),
        });
        props.updateTable();
        setReload({ reload: true });
    };

    const colSpan =
        props.customColumns.length > 0
            ? { lg: 8, sm: 8, md: 24, xs: 24 }
            : { lg: 12, sm: 12, md: 24, xs: 24 };

    return (
        <>
            <Modal
                wrapProps={{ "data-test": "edit_supplier_modal" }}
                title={isDefaultCompany ? t("editSupplierTitle") : t("editCustomerTitle")}
                open={modalOpen}
                onOk={onSubmit}
                onCancel={onCancel}
                footer={[
                    masterdata?.canEdit ? (
                        <Button
                            type="primary"
                            onClick={async () => await onSubmit()}
                            loading={loading}>
                            {t("generic_Save")}
                        </Button>
                    ) : null,
                ]}
                width={1400}
                style={{ top: 20 }}>
                <Form
                    form={form}
                    {...formItemLayout}
                    colon={false}
                    layout="vertical"
                    labelAlign="left">
                    {!loadingPartnerData && masterdata && props.selectedRow ? (
                        <Row gutter={8}>
                            <Col {...colSpan}>
                                <Form.Item
                                    label={companyHeadline}
                                    name="companyName"
                                    valuePropName="value"
                                    initialValue={masterdata?.companyName}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={companyHeadline}
                                        className="input"
                                        maxLength={200}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={companyNumberHeadline}
                                    name="companyNumber"
                                    valuePropName="value"
                                    initialValue={masterdata?.companyNumber}
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                        {
                                            validator: async (
                                                rule: any,
                                                value: any,
                                                callback: any
                                            ) => {
                                                if (!value || value.trim() === "")
                                                    return Promise.reject(
                                                        new Error(t("inputIsRequiredError"))
                                                    );
                                                else if (
                                                    !masterdata?.companyNumber ||
                                                    value.toLowerCase().trim() !==
                                                        masterdata.companyNumber
                                                            .toLowerCase()
                                                            .trim()
                                                ) {
                                                    if (
                                                        await checkForExistingNumber(value.trim())
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(t("companyNumberInUse"))
                                                        );
                                                    }
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        },
                                    ]}>
                                    <Input
                                        placeholder={companyNumberHeadline}
                                        className="input"
                                        maxLength={20}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="domain"
                                    label={domainHeadline}
                                    initialValue={masterdata?.domain}
                                    rules={[
                                        {
                                            whitespace: true,
                                            type: "url",
                                            required: props.customHeadlines?.Domain?.isMandatory,
                                        },
                                    ]}>
                                    <Input
                                        maxLength={253}
                                        className="input"
                                        placeholder={t("partner_WebsitePlaceholder")}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        props.customHeadlines?.POCFirstName?.customLabel
                                            ? props.customHeadlines?.POCFirstName?.customLabel
                                            : t("pocFirstName")
                                    }
                                    name={"pocFirstName"}
                                    initialValue={masterdata?.pocFirstName}
                                    rules={[
                                        {
                                            whitespace: true,
                                            required:
                                                props.customHeadlines?.POCFirstName?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            props.customHeadlines?.POCFirstName?.customLabel
                                                ? props.customHeadlines?.POCFirstName?.customLabel
                                                : t("pocFirstName")
                                        }
                                        className="input"
                                        maxLength={200}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        props.customHeadlines?.POCLastName?.customLabel
                                            ? props.customHeadlines?.POCLastName?.customLabel
                                            : t("pocLastName")
                                    }
                                    name={"pocLastName"}
                                    initialValue={masterdata?.pocLastName}
                                    rules={[
                                        {
                                            whitespace: true,
                                            required:
                                                props.customHeadlines?.POCLastName?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            props.customHeadlines?.POCLastName?.customLabel
                                                ? props.customHeadlines?.POCLastName?.customLabel
                                                : t("pocLastName")
                                        }
                                        className="input"
                                        maxLength={200}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        props.customHeadlines?.EmailAddress?.customLabel
                                            ? props.customHeadlines?.EmailAddress?.customLabel
                                            : t("pocEmailAddress")
                                    }
                                    name={"email"}
                                    validateTrigger="onBlur"
                                    initialValue={masterdata?.pocEmail}
                                    rules={[
                                        {
                                            required:
                                                props.customHeadlines?.EmailAddress?.isMandatory,
                                            type: "email",
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            props.customHeadlines?.EmailAddress?.customLabel
                                                ? props.customHeadlines?.EmailAddress?.customLabel
                                                : t("pocEmailAddress")
                                        }
                                        className="input"
                                        maxLength={300}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        props.customHeadlines?.POCPhone?.customLabel
                                            ? props.customHeadlines?.POCPhone?.customLabel
                                            : t("pocPhone")
                                    }
                                    name={"pocPhone"}
                                    valuePropName="value"
                                    initialValue={masterdata?.pocPhone}
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: props.customHeadlines?.POCPhone?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            props.customHeadlines?.POCPhone?.customLabel
                                                ? props.customHeadlines?.POCPhone?.customLabel
                                                : t("pocPhone")
                                        }
                                        className="input"
                                        maxLength={50}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        props.customHeadlines?.POCCountry?.customLabel
                                            ? props.customHeadlines?.POCCountry?.customLabel
                                            : t("pocCountry")
                                    }
                                    name={"pocCountry"}
                                    valuePropName="value"
                                    initialValue={masterdata?.pocCountry}
                                    rules={[
                                        {
                                            whitespace: true,
                                            required:
                                                props.customHeadlines?.POCCountry?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Select
                                        allowClear
                                        placeholder={
                                            props.customHeadlines?.POCCountry?.customLabel
                                                ? props.customHeadlines?.POCCountry?.customLabel
                                                : t("pocCountry")
                                        }
                                        showSearch
                                        disabled={!masterdata?.canEdit}>
                                        {renderCountryOptions()}
                                    </Select>
                                </Form.Item>
                                <Row>
                                    <Col span={19}>
                                        <Form.Item
                                            label={yearlyValHeadline}
                                            name={"yearlyVal"}
                                            initialValue={masterdata?.yearlyValueOfCooperation}
                                            rules={[
                                                {
                                                    required: props.customHeadlines?.valueHeadline!
                                                        ?.isMandatory,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <InputNumber
                                                placeholder={yearlyValHeadline}
                                                disabled={
                                                    selectedCurrency === undefined ||
                                                    !masterdata?.canEdit
                                                }
                                                style={{ width: "90%" }}
                                                maxLength={15}
                                                decimalSeparator={","}
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Select
                                            id="currency"
                                            placeholder={t("currencyPlaceholder")}
                                            style={{
                                                width: 100,
                                                // position: "absolute",
                                                bottom: -30,
                                                left: -48,
                                            }}
                                            showSearch
                                            allowClear
                                            value={selectedCurrency}
                                            onChange={(value: string, option: any) =>
                                                onSelectCurrencyChange(
                                                    option ? option.key : undefined
                                                )
                                            }
                                            disabled={!masterdata?.canEdit}>
                                            {renderCurrencyOptions()}
                                        </Select>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label={statusHeadline}
                                    name={"status"}
                                    valuePropName="value"
                                    initialValue={masterdata?.statusId}
                                    rules={[
                                        {
                                            required: props.customHeadlines?.statusHeadline!
                                                ?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectData
                                        data-test="select_status_select"
                                        placeholder={statusHeadline}
                                        allowClear
                                        useData={usePartnerStatuses}
                                        selectOptionField={(item: any) => ({
                                            text: item.name,
                                            node: (
                                                <span style={{ color: item.color }}>
                                                    {item.name}
                                                </span>
                                            ),
                                        })}
                                        onChange={(v: number, item: any) =>
                                            setSelectedStatusName(item ? item.name : undefined)
                                        }
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...colSpan}>
                                <Form.Item
                                    label={groupHeadline}
                                    name={"group"}
                                    valuePropName="value"
                                    initialValue={masterdata?.groupId}
                                    rules={[
                                        {
                                            required: props.customHeadlines?.groupHeadline!
                                                ?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectData
                                        data-test="select_groups_select"
                                        useData={usePartnerGroups.bind(null, groupAdded)}
                                        selectOptionField="name"
                                        allowClear
                                        placeholder={groupHeadline}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() => onNewGroupClick()}>
                                                    <Plus /> {t("add")} {groupHeadline}
                                                </div>
                                            </div>
                                        )}
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <>
                                            <div style={{ marginRight: 5 }}>{ownerHeadline}</div>
                                            <Flex justify="flex-start">
                                                {canAddSelfToOwners && (
                                                    <Tooltip
                                                        title={t("setSelfAsOwnerTooltip", {
                                                            ownerHeadline: ownerHeadline,
                                                        })}>
                                                        <span>
                                                            <UserPlus01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="setSelfAsOwner_icon"
                                                                onClick={(e: any) =>
                                                                    setSelfAsOwner(e)
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {canRemoveSelfFromOwners && (
                                                    <Tooltip
                                                        title={t("removeAsOwnerTooltip", {
                                                            ownerHeadline: ownerHeadline,
                                                        })}>
                                                        <span>
                                                            <UserX01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="removeSelfAsOwner_btn"
                                                                onClick={(e: any) =>
                                                                    removeSelfAsOwner(e)
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {canSwitchOwner && (
                                                    <Tooltip
                                                        title={`${t(
                                                            "switchOwnerTooltip"
                                                        )} ${ownerHeadline}`}>
                                                        <span>
                                                            <UserRight01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="switchOwner_btn"
                                                                onClick={(e: any) =>
                                                                    setShowModals({
                                                                        showSwitchOwnerModal: true,
                                                                    })
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </Flex>
                                        </>
                                    }
                                    name={"owner"}
                                    valuePropName="value"
                                    initialValue={masterdata?.ownerIds}
                                    rules={[
                                        {
                                            required: props.customHeadlines?.ownerHeadline!
                                                ?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        data-test="select_owners_select"
                                        placeholder={ownerHeadline}
                                        useData={useContacts}
                                        disabled={!masterdata?.canEdit || !canEditOwner}
                                        allowClear
                                        selectIdField="id"
                                        selectOptionField="name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={cat1Headline}
                                    name={"cat1"}
                                    valuePropName="value"
                                    initialValue={masterdata?.category1Ids}
                                    rules={[
                                        {
                                            required: props.customHeadlines?.category1Headline!
                                                ?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        data-test="category1_select"
                                        useData={usePartnerCategories.bind(
                                            null,
                                            categoryType.one,
                                            categoryAdded
                                        )}
                                        selectOptionField="name"
                                        placeholder={cat1Headline}
                                        allowClear
                                        maxTagCount={5}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() => onNewCatClick(1)}>
                                                    <Plus /> {t("add")} {cat1Headline}
                                                </div>
                                            </div>
                                        )}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={cat2Headline}
                                    name={"cat2"}
                                    valuePropName="value"
                                    initialValue={masterdata?.category2Ids}
                                    rules={[
                                        {
                                            required: props.customHeadlines?.category2Headline!
                                                ?.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        data-test="category2_select"
                                        useData={usePartnerCategories.bind(
                                            null,
                                            categoryType.two,
                                            categoryAdded
                                        )}
                                        selectOptionField="name"
                                        placeholder={cat2Headline}
                                        allowClear
                                        maxTagCount={5}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() => onNewCatClick(2)}>
                                                    <Plus /> {t("add")} {cat2Headline}
                                                </div>
                                            </div>
                                        )}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        disabled={!masterdata?.canEdit}
                                    />
                                </Form.Item>

                                {hasNcrFeature && (
                                    <Form.Item
                                        name="ncrContacts"
                                        label={t("partner_NcrContacts")}
                                        initialValue={masterdata?.ncrContacts}>
                                        <SelectPartnerContacts
                                            disabled={!masterdata?.canEdit}
                                            reloadOnDropdownVisibiltyChange={true}
                                            partnerId={props.selectedRow.id.value}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    name="complianceContacts"
                                    label={t("partner_ComplianceContacts")}
                                    initialValue={masterdata?.complianceContacts}>
                                    <SelectPartnerContacts
                                        disabled={!masterdata?.canEdit}
                                        reloadOnDropdownVisibiltyChange={true}
                                        partnerId={props.selectedRow.id.value}
                                    />
                                </Form.Item>
                            </Col>

                            {props.customColumns.length > 0 && (
                                <Col {...colSpan}>
                                    {masterdata &&
                                        masterdata.customColumns.map((column: any) => {
                                            const customValue = masterdata.customColumnValues.filter(
                                                v => v.customColumnId === column.id
                                            );

                                            return (
                                                <Form.Item
                                                    className={classes.formItem}
                                                    key={column.id}
                                                    label={column.name}
                                                    labelCol={formItemLayout.labelCol}
                                                    wrapperCol={formItemLayout.wrapperCol}
                                                    name={["customColumn", column.id]}
                                                    initialValue={
                                                        customValue.length
                                                            ? customValue[0].value
                                                            : undefined
                                                    }
                                                    rules={[
                                                        {
                                                            max: 400,
                                                        },
                                                    ]}>
                                                    {column.customDropdownId === null ? (
                                                        <TextArea
                                                            data-test={column.name}
                                                            placeholder={column.name}
                                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                                            maxLength={400}
                                                            disabled={!masterdata?.canEdit}
                                                        />
                                                    ) : (
                                                        <SelectData
                                                            allowClear
                                                            style={{ marginLeft: 0, width: "100%" }}
                                                            placeholder={t("generic_SelectOption")}
                                                            selectOptionField="text"
                                                            selectIdField="text"
                                                            defaultValue={
                                                                customValue.length
                                                                    ? customValue[0].value
                                                                    : undefined
                                                            }
                                                            useData={() => ({
                                                                data:
                                                                    customDropdowns.filter(
                                                                        (dropdown: any) =>
                                                                            dropdown.id ===
                                                                            column.customDropdownId
                                                                    ) &&
                                                                    customDropdowns.filter(
                                                                        (dropdown: any) =>
                                                                            dropdown.id ===
                                                                            column.customDropdownId
                                                                    )[0].customDropdownOptions,
                                                                loading: false,
                                                            })}
                                                        />
                                                    )}
                                                </Form.Item>
                                            );
                                        })}
                                </Col>
                            )}
                        </Row>
                    ) : (
                        <Skeleton loading paragraph={{ rows: 16 }} />
                    )}
                </Form>
            </Modal>
            {showModals.showSwitchOwnerModal && (
                <SwitchOwnerModal
                    partnerId={props.selectedRow.id.value}
                    onClose={(shouldReload: boolean = false, newOwnerIds: number[] | undefined) => {
                        setShowModals({ ...showModals, showSwitchOwnerModal: false });

                        if (shouldReload && newOwnerIds) {
                            form.setFieldsValue({ owner: newOwnerIds });
                            props.updateTable();
                            setReload({ reload: true });
                        }
                    }}
                    visible={showModals.showSwitchOwnerModal}
                    ownerHeadline={ownerHeadline}
                    ownerIds={masterdata?.ownerIds ?? []}
                />
            )}
        </>
    );
};
function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        dispatch,
    };
}
const EditPartnerModal = ContentModal;
export default connect(mapStateToProps, mapDispatchToProps)(EditPartnerModal);
