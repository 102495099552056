import React from "react";
import { Tag, Tooltip } from "antd";

const TagRender = (props: any, singleLineOptions: boolean) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color="blue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginBottom: 4,
                marginRight: singleLineOptions ? 120 : 4,
                wordBreak: "break-word",
                whiteSpace: "normal",
            }}>
            {label && label.length > 50 ? (
                <Tooltip title={label}>{label.substr(0, 50)} ...</Tooltip>
            ) : (
                label
            )}
        </Tag>
    );
};

export { TagRender };
