import { IKpiDefinition } from "models";
import { get, put, remove, post } from "../base";

const AdminKpi = {
    getKpiDefinitions: async ({ customError }: any = {}) => {
        const url = `/v1/AdminKpi/KpiDefinitions`;
        return await get({ url, customError });
    },
    getKpiDefinitionHasData: async (
        kpiId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminKpi/KpiDefinition/HasData?kpiId=${kpiId}&from=${from}&to=${to}`;
        return await get({ url, customError });
    },
    saveKpiDefinitions: async (definitions: IKpiDefinition[], { customError }: any = {}) => {
        const url = `/v1/AdminKpi/KpiDefinitions`;
        await put({ url, payload: definitions, customError });
    },
    deleteUploadDataOnKpi: async (kpiId: number, { customError }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/AdminKpi/KpiDefinition/DeleteUploadedData?kpiId=${kpiId}`;
        await remove({ url, payload: {}, customError, customConfig });
    },
    uploadKpi: async (
        fileId: number,
        sendEmails: boolean,
        dashboardIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminKpi/UploadKpi`;
        return await post({ url, payload: { fileId, sendEmails, dashboardIds }, customError });
    },
    getUploadKpiInitData: async ({ customError }: any = {}) => {
        const url = `/v1/AdminKpi/GetKpiUploadInitData`;
        return await get({ url, customError });
    },
    getDataSpecificationExample: async ({ customError, onDownloadProgress }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = "/v1/AdminKpi/DataExtractTemplate";
        return await get({
            url,
            responseType: "blob",
            customError,
            customConfig,
            onDownloadProgress,
        });
    },
    getDataSpecificationData: async ({ customError }: any = {}) => {
        const url = "/v1/AdminKpi/DataSpecification";
        return await get({ url, customError });
    },
    getAdminKpiCustomColumns: async ({ customError }: any = {}) => {
        const url = `/v1/AdminKpi/KpiCustomColumns`;
        return await get({ url, customError });
    },
    updateKpiCustomColumns: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminKpi/KpiCustomColumns`;
        return await put({ url, payload, customError });
    },
};

export { AdminKpi };
