import React, { FunctionComponent, Fragment } from "react";
import { Descriptions, Table } from "antd";
import "./styles/ncrDetail.css";
import classes from "./styles/ncrDetail.module.scss";

type Propstype = {
    data: any;
    purchaseOrderNumberLabel: string;
    salesOrderNumberLabel: string;
    deliveryNoteLabel: string;
    ncrValueLabel: string;
    otherCostsLabel: string;
    OtherCostsReasonLabel: string;
    commentLabel: string;
    purchaseOrderNumberVisibility: number;
    salesOrderNumberVisibility: number;
    deliveryNoteVisibility: number;
    ncrValueVisibility: number;
    otherCostsVisibility: number;
    otherCostsReasonVisibilty: number;
    commentVisibility: number;
    deviationsVisibility: number;
    deviationsLabel: string;
    deviationsProductNumberLabel: string;
    deviationsDescriptLabel: string;
    deviationsOrderedLabel: string;
    deviationsDeliveredLabel: string;
    deviationsFaultyLabel: string;
    deviationsProductNumberVisiblity: number;
    deviationsDescriptVisiblity: number;
    deviationsOrderedVisiblity: number;
    deviationsDeliveredVisiblity: number;
    deviationsFaultyVisiblity: number;
};

const NCRDetails: FunctionComponent<Propstype> = ({
    data,
    purchaseOrderNumberLabel,
    salesOrderNumberLabel,
    deliveryNoteLabel,
    ncrValueLabel,
    otherCostsLabel,
    OtherCostsReasonLabel,
    commentLabel,
    purchaseOrderNumberVisibility,
    salesOrderNumberVisibility,
    deliveryNoteVisibility,
    ncrValueVisibility,
    otherCostsVisibility,
    otherCostsReasonVisibilty,
    commentVisibility,
    deviationsVisibility,
    deviationsLabel,
    deviationsProductNumberLabel,
    deviationsDescriptLabel,
    deviationsOrderedLabel,
    deviationsDeliveredLabel,
    deviationsFaultyLabel,
    deviationsProductNumberVisiblity,
    deviationsDescriptVisiblity,
    deviationsOrderedVisiblity,
    deviationsDeliveredVisiblity,
    deviationsFaultyVisiblity,
}) => {
    function getDeviationColumnns() {
        let columns: any[] = [];
        if (deviationsProductNumberVisiblity !== 1) {
            columns.push({
                title: deviationsProductNumberLabel,
                dataIndex: "productNumber",
                key: "productNumber",
                ellipsis: true,
            });
        }
        if (deviationsDescriptVisiblity !== 1) {
            columns.push({
                title: deviationsDescriptLabel,
                dataIndex: "description",
                key: "description",
                ellipsis: true,
            });
        }
        if (deviationsOrderedVisiblity !== 1) {
            columns.push({
                title: deviationsOrderedLabel,
                dataIndex: "ordered",
                key: "ordered",
                ellipsis: true,
            });
        }
        if (deviationsDeliveredVisiblity !== 1) {
            columns.push({
                title: deviationsDeliveredLabel,
                dataIndex: "delivered",
                key: "delivered",
                ellipsis: true,
            });
        }
        if (deviationsFaultyVisiblity !== 1) {
            columns.push({
                title: deviationsFaultyLabel,
                dataIndex: "faulty",
                key: "faulty",
                ellipsis: true,
            });
        }
        return columns;
    }

    return (
        <Fragment>
            <Descriptions
                column={{ xs: 1, sm: 1, md: 2, xl: 2, lg: 2, xxl: 2 }}
                bordered
                size="small">
                {purchaseOrderNumberVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} label={purchaseOrderNumberLabel}>
                        <div className={classes.detailComment}>{data.purchaseOrderNumber}</div>
                    </Descriptions.Item>
                )}
                {ncrValueVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} label={ncrValueLabel}>
                        <div className={classes.detailComment}>{`${
                            data.valueCurrency !== null ? data.valueCurrency : ""
                        } ${
                            data.value !== null
                                ? data.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : ""
                        }`}</div>
                    </Descriptions.Item>
                )}
                {salesOrderNumberVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} label={salesOrderNumberLabel}>
                        <div className={classes.detailComment}>{data.salesOrderNumber}</div>
                    </Descriptions.Item>
                )}
                {otherCostsVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} label={otherCostsLabel}>
                        <div className={classes.detailComment}>
                            {`${data.otherCostsCurrency !== null ? data.otherCostsCurrency : ""} ${
                                data.otherCosts !== null
                                    ? data.otherCosts.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : ""
                            }`}
                        </div>
                    </Descriptions.Item>
                )}
                {deliveryNoteVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} label={deliveryNoteLabel}>
                        <div className={classes.detailComment}>{data.deliveryNote}</div>
                    </Descriptions.Item>
                )}
                {otherCostsReasonVisibilty !== 1 && (
                    <Descriptions.Item className={classes.content} label={OtherCostsReasonLabel}>
                        <div className={classes.detailComment}>{data.otherCostsReason}</div>
                    </Descriptions.Item>
                )}
                {commentVisibility !== 1 && (
                    <Descriptions.Item className={classes.content} span={2} label={commentLabel}>
                        <div data-test="ncr_description_comment" className={classes.detailComment}>
                            {data.comment}
                        </div>
                    </Descriptions.Item>
                )}
            </Descriptions>
            {deviationsVisibility !== 1 && data.deviations.length > 0 && (
                <Table
                    data-test="ncr_deviation_table"
                    className={classes.deviationTable}
                    title={() => <div>{deviationsLabel}</div>}
                    bordered
                    size="small"
                    pagination={false}
                    dataSource={data.deviations}
                    columns={getDeviationColumnns()}
                    rowKey="id"
                />
            )}
        </Fragment>
    );
};

export default NCRDetails;
