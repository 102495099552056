import React, { useState, useEffect, FunctionComponent, useLayoutEffect } from "react";
import {
    Table,
    Row,
    Col,
    Button,
    Card,
    Popconfirm,
    Spin,
    Result,
    notification,
    Form,
    Affix,
} from "antd";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";
import classes from "./styles/submissionMatrix.module.scss";
import CriteriaCard from "./criteriaCard";
import SubmissionCard from "./submissionCard";
import { SurveyTextFields } from "./surveyTextFields";
import { useTranslation } from "react-i18next";
import { useSurveySubmissionAggregate, useSubmitSurveyAnswers } from "controller";
import { useParams } from "react-router-dom";
import { BreakPoint, IMatrixSupplier, ISurveyHeadline } from "models";
import { getViewPort, MultipleTags, PureDiv } from "components/utilitycomponents";
import { groupBy } from "utilities/helpers/groupBy";
import dayjs from "utilities/daysJsConfig";

const SubmissionMatrix = () => {
    const [form] = Form.useForm();
    const { guestToken, cid }: any = useParams();
    const { data, loading, isAnswered } = useSurveySubmissionAggregate(guestToken, cid);
    const { t } = useTranslation();
    const [xScroll, setXscroll] = useState(0);
    const [columns, setColumns] = useState<any[]>([]);
    const { submitAnswers, loading: submittingAnswers } = useSubmitSurveyAnswers();
    const [submitted, setSubmitted] = useState(false);
    const [hasSurveyFields, setHasSurveyFields] = useState(false);
    const [numberOfSubmission, setNumberOfSubmissions] = useState<number>(0);
    const [viewPort, setViewPort] = useState(getViewPort());
    const [criteriaGroupSets, setCriteriaGroupSets] = useState<any[]>([]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setViewPort(getViewPort());
        };

        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    });

    useEffect(() => {
        if (data) {
            createColumns();
            parseTableData();
            setHasSurveyFields(data.surveyHeadlines.length > 0);
        }
    }, [data]);

    useEffect(() => {
        let newXscroll = 0;
        for (let col of columns) {
            if (col.width) {
                newXscroll = newXscroll + col.width;
            }
        }
        setXscroll(newXscroll + 100);
    }, [columns]);

    const getSuppliers = () => {
        let suppliers = [];
        if (data) {
            if (data.supplierDescriptors && data.supplierDescriptors.length > 0) {
                suppliers = data.supplierDescriptors;
            } else {
                suppliers = data.suppliers;
            }
        }
        return suppliers;
    };

    const getSupplierType = () => {
        let type = "Supplier";
        if (data && data.supplierDescriptors && data.supplierDescriptors.length > 0) {
            type = "SupplierDescriptor";
        }
        return type;
    };

    const createColumns = () => {
        let columns: any[] = [];
        if (data) {
            getSuppliers().forEach((supplier: IMatrixSupplier, i: number) => {
                columns.push(createColumnForEachSupplier(supplier, getSupplierType()));
            });
        }
        setColumns(columns);
    };

    const createColumnForEachSupplier = (supplier: IMatrixSupplier, type: string) => {
        const supplierId = supplier.supplierId;
        const analysisUnitId = type === "SupplierDescriptor" ? supplier.id : null;
        return {
            title: (
                <div className={classes.colummTitles}>
                    {type === "SupplierDescriptor" ? (
                        <SupplierDescriptorTitle {...supplier} />
                    ) : (
                        supplier.supplierName
                    )}
                </div>
            ),
            key: `${analysisUnitId ? analysisUnitId : supplierId}`,
            render: (_: any, record: any) =>
                record.isCriteria ? (
                    <div>
                        <CriteriaCard
                            ratingTexts={record.ratingTexts}
                            fieldKey={`${record.criteriaId}-${supplierId}-${analysisUnitId}`}
                        />
                    </div>
                ) : (
                    <div>
                        <SubmissionCard
                            fieldKey={`${record.headlineId}-${supplierId}-${analysisUnitId}`}
                            headline={record}
                            type={record.isCustomDropdown === true ? "dropdown" : "textField"}
                            required={false}
                        />
                    </div>
                ),
            width: 300,
        };
    };

    function parseTableData() {
        if (data !== undefined) {
            let groupedData = groupBy(data.criteria, "reviewGroupName");
            let groupedSets: any[] = [];

            //Review criteria questions
            for (let key of Object.keys(groupedData)) {
                groupedSets.push({
                    [key]: groupedData[key].map(criteria => ({
                        id: uuidv4(),
                        headlineId: criteria.criteriaId,
                        headline: criteria.title,
                        translateHeadline: criteria.translate,
                        ratingTexts: criteria.ratingTexts,
                        reviewGroupName: criteria.reviewGroupName,
                        title: criteria.title,
                        description: criteria.description,
                        isCriteria: true,
                        criteriaId: criteria.criteriaId,
                        isCustomDropdown: false,
                        customDropdownOptions: [],
                    })),
                });
            }

            //Extra supplier fields
            for (let i = 0; i < data!.supplierHeadlines.length; i++) {
                let headline: ISurveyHeadline = data!.supplierHeadlines[i];
                groupedSets.push({
                    [headline.title]: [
                        {
                            id: uuidv4(),
                            headlineId: headline.id,
                            headline: headline.title,
                            translateHeadline: "",
                            ratingTexts: [],
                            title: headline.title,
                            description: headline.description,
                            isCriteria: false,
                            criteriaId: null,
                            isCustomDropdown: headline.customDropdownId ? true : false,
                            customDropdownOptions: headline.customDropdown
                                ? headline.customDropdown.customDropdownOptions
                                : [],
                        },
                    ],
                });
            }

            setCriteriaGroupSets(groupedSets);
        }
    }

    const renderTableForSmallScreens = () => {
        // eslint-disable-next-line array-callback-return
        return getSuppliers().map((supplier: any) => {
            criteriaGroupSets.map((set: any) => {
                let data = set[Object.keys(set)[0]];
                let title = Object.keys(set)[0];
                return (
                    <Table
                        rowClassName={(record, index) =>
                            index % 2 === 0 ? classes.tableRowLight : classes.tableRowDark
                        }
                        pagination={false}
                        className={classes.matrixGrid}
                        columns={[
                            {
                                title: (
                                    <div className={classes.colummTitles}>
                                        {title !== "null" && (
                                            <span className={classes.groupTitleText}>{title}</span>
                                        )}
                                    </div>
                                ),
                                dataIndex: "headline",
                                key: "headline",
                                width: 150,
                                render: (val: string, record: any) => (
                                    <div>
                                        {record.translateHeadline ? t(val) : val}
                                        <div>
                                            <small>{record.description}</small>
                                        </div>
                                    </div>
                                ),
                            },
                            createColumnForEachSupplier(supplier, getSupplierType()),
                        ]}
                        dataSource={data}
                        bordered
                        rowKey="id"
                    />
                );
            });
        });
    };

    async function onSubmit() {
        const values = await form.validateFields();
        let submittedReviewsNumber = await submitAnswers(values, guestToken, cid);
        if (submittedReviewsNumber === 0) {
            notification.info({
                message: t("generic_BeAdvised"),
                description: t("survey_SubmitNoReviewsMessage"),
            });
        } else {
            setNumberOfSubmissions(submittedReviewsNumber);
            setSubmitted(true);
        }
    }
    if (loading || submittingAnswers) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={classes.surveyContainer}>
            <Form form={form} onFinish={onSubmit} scrollToFirstError>
                {data && !submitted && (
                    <div style={{ margin: viewPort > BreakPoint.sm ? "36px" : "10px" }}>
                        {data.endDate ? (
                            <Row>
                                <Col span={24}>
                                    <Card
                                        title={`${t("survey_SurveyEndDate")}: ${dayjs(
                                            data.endDate
                                        ).format("MMM D, YYYY")}`}
                                        headStyle={{ backgroundColor: "#fafafa" }}
                                        bodyStyle={{ display: "none" }}></Card>
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col span={24} className={data.endDate ? classes.topMargin : ""}>
                                <Card
                                    title={
                                        <div>
                                            <Row>
                                                <Col span={10}>{t("survey_Introduction")}</Col>
                                                <Col span={14} style={{ textAlign: "right" }}>
                                                    {t("survey_surveyId", { surveyId: data.id })}
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    headStyle={{ backgroundColor: "#fafafa" }}>
                                    <PureDiv textContent={data.introduction} />
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {hasSurveyFields && (
                                <Col span={24}>
                                    <SurveyTextFields
                                        form={form}
                                        surveyHeadlines={data.surveyHeadlines}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col span={24}>
                                {viewPort > BreakPoint.sm ? (
                                    <>
                                        <div
                                            id="tableGroupsContainer"
                                            className={classes.tableGroupsContainer}
                                            // style={{
                                            //     maxHeight: height! - 400,
                                            //     overflow: "auto",
                                            // }}
                                        >
                                            {criteriaGroupSets.map((set: any) => {
                                                let data = set[Object.keys(set)[0]];
                                                let title = Object.keys(set)[0];

                                                return (
                                                    <Table
                                                        rowClassName={(record, index) =>
                                                            index % 2 === 0
                                                                ? classes.tableRowLight
                                                                : classes.tableRowDark
                                                        }
                                                        pagination={false}
                                                        className={classes.matrixGrid}
                                                        sticky={true}
                                                        columns={[
                                                            {
                                                                title: (
                                                                    <div
                                                                        className={
                                                                            classes.colummTitles
                                                                        }>
                                                                        {title !== "null" && (
                                                                            <span
                                                                                className={
                                                                                    classes.groupTitleText
                                                                                }>
                                                                                {title}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                ),
                                                                dataIndex: "headline",
                                                                key: "headline",
                                                                width: 190,
                                                                fixed: true,
                                                                render: (
                                                                    val: string,
                                                                    record: any
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: "#fff",
                                                                        }}
                                                                        className={
                                                                            classes.questionCell
                                                                        }>
                                                                        {record.translateHeadline
                                                                            ? t(val)
                                                                            : val}
                                                                        <div>
                                                                            <small>
                                                                                {record.description}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            },
                                                            ...columns,
                                                        ]}
                                                        dataSource={data}
                                                        bordered
                                                        scroll={{ x: xScroll, y: 550 }}
                                                        rowKey="id"
                                                    />
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    renderTableForSmallScreens()
                                )}
                            </Col>
                        </Row>
                        <Affix offsetBottom={45} className={classes.saveButtons}>
                            <Popconfirm
                                placement="topLeft"
                                title={t("survey_SubmitConfirmMessage")}
                                okText={t("generic_Confirm")}
                                cancelText={t("generic_Cancel")}
                                onConfirm={() => form.submit()}>
                                <Button style={{ marginRight: 8 }} type="primary">
                                    {t("survey_SubmitButtonText")}
                                </Button>
                            </Popconfirm>
                        </Affix>
                    </div>
                )}
                {submitted && (
                    <Result
                        status="success"
                        title={t("survey_SubmitSuccessfullTitle", {
                            numberOfSubmissions: numberOfSubmission,
                        })}
                        subTitle={t("survey_SubmitSuccessfullCloseText")}
                    />
                )}
                {!loading && (data === null || data === undefined) && !isAnswered && (
                    <Result status="info" title={t("survey_SurveyNotValid")} />
                )}
                {!loading && (data === null || data === undefined) && isAnswered && (
                    <Result status="success" title={t("survey_SurveyAnswered")} />
                )}
            </Form>
        </div>
    );
};

const SupplierDescriptorTitle: FunctionComponent<any> = supplier => {
    let record: any = { commodityCategories: [], regions: [], divisions: [] };
    if (supplier.commodityCategories === undefined) {
        record.commodityCategories = [];
    } else {
        record.commodityCategories = supplier.commodityCategories.map((r: any) => r.description);
    }
    if (supplier.regions === undefined) {
        record.regions = [];
    } else {
        record.regions = supplier.regions.map((r: any) => r.name);
    }
    if (supplier.divisions === undefined) {
        record.divisions = [];
    } else {
        record.divisions = supplier.divisions.map((r: any) => r.name);
    }

    return (
        <>
            <Row
                style={{
                    color: "#808080",
                    fontSize: "15px",
                }}>
                <Col span={24}>{supplier.supplierName}</Col>
            </Row>

            <Row>
                <Col span={24}>
                    <MultipleTags
                        style={{
                            position: "relative",
                            bottom: 2,
                        }}
                        tagNumber={
                            record.commodityCategories.length +
                            record.divisions.length +
                            record.regions.length
                        }
                        ellipsisThreshold={10}
                        className={classes.surveyTitleDescriptorContainer}
                        values={record.commodityCategories
                            .concat(record.divisions, record.regions)
                            .map((item: string, index: number) => ({
                                name: item,
                                id: index,
                            }))}
                        isSpecialStyle={true}
                        numberPerLine={3}
                        color="#108ee9"
                        tagTooltips={true}
                    />
                </Col>
            </Row>
        </>
    );
};

export default SubmissionMatrix;
