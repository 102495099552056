import { useState } from "react";
import { Provider } from "services";

const useComplianceStatusBulkUpdateSendEmail = () => {
    const [loading, setLoading] = useState(false);

    const sendBulkStatusChangeEmails = async (complianceEmailInfoObject: any) => {
        setLoading(true);

        const res = await Provider.Compliance.SendEmailOfChangingMultipleComplianceStatus(
            complianceEmailInfoObject,
            {
                customError: {
                    message: "Error in sending email notifications regarding status change",
                },
            }
        ).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };

    return {
        loading,
        sendBulkStatusChangeEmails,
    };
};

export { useComplianceStatusBulkUpdateSendEmail };
