export function getHeaderSize(parentElementId: any) {
    let parentElementHeight: any = document.getElementById(parentElementId)!.style.height;
    let ElementHeight = parentElementHeight.replace("px", "");

    let titleHeight: any = document.getElementById(`reportTitle${parentElementId}`)!.offsetHeight;
    let titleExcess = titleHeight;

    let descriptionHeight: any = document.getElementById(`reportDescription${parentElementId}`)!
        .offsetHeight;
    let descriptionExcess = descriptionHeight;

    return { ElementHeight, titleExcess, descriptionExcess };
}
