import { useState } from "react";
import { Provider } from "services";

const useRiskReportEdit = () => {
    const [loading, setLoading] = useState(false);

    const upsert = async (payload: any) => {
        setLoading(true);

        const res = await Provider.Company.addOrEditRiskReport(payload, {
            customError: { message: "Failed to set risk report settings" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        upsert,
    };
};

export { useRiskReportEdit };
