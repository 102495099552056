import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useSurveyTemplatesByType = (type: number) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any = await Provider.Survey.getSurveyTemplatesByType(type, {
                customError: {
                    message: "Could not get survey templates",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyTemplatesByType };
