import { useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

const useReviewDelete = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const deleteReviewsByIds = async (reviewIds: number[]) => {
        setLoading(true);
        await Provider.Review.deleteReviewsByIds(reviewIds, {
            customError: {
                message: t("review_DeleteError"),
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        deleteReviewsByIds,
    };
};

export { useReviewDelete };
