import { useEffect, useState } from "react";
import { Provider } from "services";

const useUserRegistrationType = (invitationToken = "") => {
    const [state, setState] = useState<any>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (invitationToken) {
                setState({ ...state, loading: true });
                let data = await Provider.User.getInvitationRegistrationType(invitationToken, {
                    customError: {
                        message: "Could not retrieve user registration type",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [invitationToken]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useUserRegistrationType };
