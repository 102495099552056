import { useEffect, useState } from "react";
import Provider from "services/api";

const UseSharedDashboardsTokenInfo = (companyId: number, partnerId: number, reload: any) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (companyId !== undefined && partnerId !== undefined) {
                const data = await Provider.Analytics.getSharedDashboardGuestTokenInfo(
                    partnerId,
                    companyId,
                    { customError: { message: "Could not retrieve list of presets" } }
                ).catch(err => {
                    setState({ loading: false, data: {} });
                    return Promise.reject(err);
                });

                setState({
                    loading: false,
                    data: data,
                });
            }
        })();
    }, [companyId, partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { UseSharedDashboardsTokenInfo };
