import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Tag, notification, Form, Button } from "antd";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import { IOwner } from "../../../../models/suppliers/IOwner";
import ICustomHeadline from "../../../../models/suppliers/ICustomHeadline";
import { useCompanyType, useContacts, usePartnerUpdate } from "controller";
import { SelectData } from "components/utilitycomponents";

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    updateTable(): void;
    clearSelectedRows(): void;
    ownerHeadlinne: ICustomHeadline;
    contactId: string;
}

export const ContentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };
    const { t } = useTranslation();
    const { updatePartnerOwners, loading: confirmLoading } = usePartnerUpdate();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: any) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onCancel() {
        props.clearSelectedRows();
        form.resetFields();
        props.setModalVisibility(false);
    }

    async function onSubmit() {
        let values = await form.validateFields();
        let existingOwnerIds: string[] = props.selectedRows[0]
            .owners!.value.filter(x => x.id.toString() !== props.contactId)
            .map((x: IOwner) => x.id.toString());
        let newOwnerIds = [...existingOwnerIds, values.selectSwitchOwner];
        newOwnerIds = [...new Set(newOwnerIds.map(x => Number(x)))];
        await updatePartnerOwners(props.selectedRows.map(x => x.id.value)[0], newOwnerIds);

        notification.success({
            message: t("success"),
            description: t("supplier_UpdateOwnerSuccess", {
                ownerHeadline,
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier").toLocaleLowerCase()
                    : t("company_Type_Customer").toLocaleLowerCase(),
            }),
        });
        form.resetFields();
        props.clearSelectedRows();
        props.setModalVisibility(false);
        await props.updateTable();
    }

    const ownerHeadline =
        props.ownerHeadlinne && props.ownerHeadlinne.customLabel !== null
            ? props.ownerHeadlinne.customLabel
            : "Owners";

    return (
        <div>
            <Modal
                wrapProps={{ "data-test": "switchOwner_modal" }}
                title={t("supplier_SwitchOwnerTitle", {
                    ownerHeadline: ownerHeadline,
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier").toLowerCase()
                        : t("company_Type_Customer").toLowerCase(),
                })}
                open={props.visible}
                onOk={onSubmit}
                onCancel={onCancel}
                width={700}
                footer={[
                    <Button
                        loading={confirmLoading}
                        type="primary"
                        onClick={async () => await onSubmit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Form form={form} {...formItemLayout} layout={"horizontal"} colon={false}>
                    <Form.Item
                        label={
                            t("supplier_SelectedSuppliers", {
                                companyType: isDefaultCompany
                                    ? t("company_Type_Supplier")
                                    : t("company_Type_Customer"),
                            }) + " : "
                        }>
                        {props.selectedRows.length === 1 && (
                            <div className="tagArea">{renderTags(false)}</div>
                        )}
                        {props.selectedRows.length > 1 && (
                            <div className="tagArea">{renderTags(true)}</div>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={t("Selected") + ` ${ownerHeadline}`}
                        name={"selectSwitchOwner"}
                        valuePropName="value"
                        initialValue={
                            props.selectedRows.length === 1 &&
                            props.selectedRows[0].owners!.value.some(
                                x => x.id.toString() === props.contactId
                            )
                                ? Number(props.contactId)
                                : undefined
                        }>
                        <SelectData
                            data-test="selectSwitchOwner"
                            id="selectSwitchOwner"
                            style={{ width: "100%" }}
                            placeholder={`Please select a ${ownerHeadline}`}
                            allowClear={false}
                            useData={useContacts}
                            selectIdField="id"
                            selectOptionField="name"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
const ModalSwitchOwner = ContentModal;
export default ModalSwitchOwner;
