import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
// @ts-ignore
import { ResponsiveBar } from "@nivo/bar";
import { useReviewGraph } from "controller";
import { LoadingIndicator } from "components/utilitycomponents";
import classes from "./styles/surveyScoreBarChart.module.scss";
import groupPartner from "./styles/images/groupPartner.gif";
import selectPartner from "./styles/images/selectPartner.gif";
import voicePartner from "./styles/images/voicePartner.gif";
import voiceAllPartner from "./styles/images/voiceAllPartner.gif";
import allPartner from "./styles/images/allPartner.gif";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

export const SurveyScoreBarChart: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();

    const { loading, data } = useReviewGraph(partnerId, range.from, range.to);
    if (loading) return <LoadingIndicator />;
    if (!data) return null;

    let graphData: any = [
        {
            type: t("supplierProfile_PerformanceSurveyedReviews"),
            "0": data.surveyedAverageRating,
            "1": data.surveyedTotalAverageRating,
            "2": data.surveyedPartnersInGroupAverageRating,
            "3": data.surveyedVoiceOfSelectedPartnersAverageRating,
            "4": data.surveyedVoiceOfAllPartnersAverageRating,
        },
        {
            type: t("supplierProfile_PerformanceNonSurveyedReviews"),
            "0": data.nonSurveyedAverageRating,
            "1": data.nonSurveyedTotalAverageRating,
            "2": data.nonSurveyedPartnersInGroupAverageRating,
            "3": 0,
            "4": 0,
        },
    ];

    const colors = ["#0c5a87", "#488db5", "#6baed6", "#9ecae1", "#c6dbef"];
    return (
        <Fragment>
            <div
                data-test="survey_score_bar_chart"
                className={classnames(classes.surveyBarChart, "surveyChart")}>
                <ResponsiveBar
                    data={graphData}
                    keys={["0", "1", "2", "3", "4"]}
                    margin={{ top: 5, right: 30, bottom: 60, left: 30 }}
                    groupMode="grouped"
                    layout="vertical"
                    indexBy="type"
                    colors={(bar: any) => colors[bar.id]}
                    maxValue={5}
                    minValue={0}
                    innerPadding={3}
                    padding={0.5}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    enableLabel={false}
                    tooltip={({ id, value, indexValue }: any) => {
                        let text = "";
                        switch (id) {
                            case "0":
                                if (indexValue === "Surveyed Reviews") {
                                    text = t(`supplierProfile_ReviewChartSelectedPartnerCount`, {
                                        reviewCount: data.surveyedReviewCount,
                                        value,
                                    });
                                } else {
                                    text = t(`supplierProfile_ReviewChartSelectedPartnerCount`, {
                                        reviewCount: data.nonSurveyedReviewCount,
                                        value,
                                    });
                                }
                                break;
                            case "1":
                                if (indexValue === "Surveyed Reviews") {
                                    text = t(`supplierProfile_ReviewChartAllPartnerCount`, {
                                        totalReviewCount: data.surveyedTotalReviewCount,
                                        value,
                                    });
                                } else {
                                    text = t(`supplierProfile_ReviewChartAllPartnerCount`, {
                                        totalReviewCount: data.nonSurveyedTotalReviewCount,
                                        value,
                                    });
                                }
                                break;
                            case "2":
                                if (indexValue === "Surveyed Reviews") {
                                    text = t("supplierProfile_ReviewChartGroupPartnerCount", {
                                        groupName: data.groupName,
                                        partnersInGroupReviewCount:
                                            data.surveyedPartnersInGroupReviewCount,
                                        value,
                                        interpolation: { escapeValue: false },
                                    });
                                } else {
                                    text = t("supplierProfile_ReviewChartGroupPartnerCount", {
                                        groupName: data.groupName,
                                        partnersInGroupReviewCount:
                                            data.nonSurveyedPartnersInGroupReviewCount,
                                        value,
                                        interpolation: { escapeValue: false },
                                    });
                                }
                                break;
                            case "3":
                                text = t("supplierProfile_ReviewChartVoicePartnerCount", {
                                    voiceOfSelectedPartnersReviewCount:
                                        data.surveyedVoiceOfSelectedPartnersReviewCount,
                                    value,
                                });

                                break;
                            case "4":
                                text = t("supplierProfile_ReviewChartVoiceAllPartnerCount", {
                                    voiceOfAllPartnersAverageRating:
                                        data.surveyedVoiceOfAllPartnersReviewCount,
                                    value,
                                });
                                break;
                        }
                        return (
                            <div className={classes.tooltip}>
                                <div style={{ backgroundColor: "#fff" }}>
                                    <b>{indexValue}</b>
                                </div>
                                <div>
                                    <b>{text}</b>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
            <div className={classnames(classes.surveyChartPrint, "surveyChartPrint")}></div>
            <Legend>
                <img src={selectPartner} alt="img" />
                {t(`supplierProfile_ReviewChartSelectedPartner`, {
                    reviewCount: data.surveyedReviewCount + data.nonSurveyedReviewCount,
                })}
            </Legend>
            <Legend>
                <img src={allPartner} alt="img" />
                {t("supplierProfile_ReviewChartAllPartner", {
                    totalReviewCount:
                        data.surveyedTotalReviewCount + data.nonSurveyedTotalReviewCount,
                })}
            </Legend>
            <Legend>
                <img src={groupPartner} alt="img" />
                {t("supplierProfile_ReviewChartGroupPartner", {
                    groupName: data.groupName,
                    partnersInGroupReviewCount:
                        data.surveyedPartnersInGroupReviewCount +
                        data.nonSurveyedPartnersInGroupReviewCount,
                    interpolation: { escapeValue: false },
                })}
            </Legend>
            <Legend>
                <img src={voicePartner} alt="img" />
                {t("supplierProfile_ReviewChartVoicePartner", {
                    voiceOfSelectedPartnersReviewCount:
                        data.surveyedVoiceOfSelectedPartnersReviewCount,
                })}
            </Legend>
            <Legend>
                <img src={voiceAllPartner} alt="img" />
                {t("supplierProfile_ReviewChartVoiceAllPartner", {
                    voiceOfAllPartnersAverageRating: data.surveyedVoiceOfAllPartnersReviewCount,
                })}
            </Legend>
        </Fragment>
    );
};

const Legend = ({ img, children }: any) => {
    return <div className={classes.legend}>{children}</div>;
};
export default SurveyScoreBarChart;
