import React, { FunctionComponent, useState } from "react";
import classes from "./styles/shared.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Spin, Col } from "antd";
import CreateCompany from "./createCompany";
import CreateProfile from "./createProfile";
import { useUserRegistrationType } from "controller";

const PartnerRegistration: FunctionComponent = () => {
    const { t } = useTranslation();
    const { token }: any = useParams();
    const history = useHistory();
    const [step, setStep] = useState(0);
    const { loading, data: invitation } = useUserRegistrationType(token);
    const [company, setCompany] = useState<any>(null);

    if (loading) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" tip={t("Loading...")} />
            </div>
        );
    }

    if (invitation && invitation.invitationType === "CompanyExists") {
        history.push(`/AcceptInvitation/${token}`);
    }

    return (
        <div>
            <Row style={{ display: step === 0 ? "block" : "none" }} className={classes.overflow}>
                <Col span={24}>
                    <CreateCompany
                        onNext={(data: any) => {
                            setCompany(data);
                            setStep(1);
                        }}
                        invitation={invitation}
                    />
                </Col>
            </Row>
            <Row style={{ display: step === 1 ? "block" : "none" }} className={classes.overflow}>
                <Col span={24}>
                    <CreateProfile
                        companyName={
                            company && company.companyName ? company.companyName : undefined
                        }
                        email={
                            invitation && invitation.emailAddress
                                ? invitation.emailAddress
                                : undefined
                        }
                        onBack={() => {
                            setStep(0);
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default PartnerRegistration;
