import { useState } from "react";
import Provider from "services/api";

const useMeetingMinutesCRUD = () => {
    const [loading, setLoading] = useState(false);

    const create = async (input: any) => {
        setLoading(true);
        const id = await Provider.Governance.addMeetingMinute(input, {
            customError: {
                message: "Could not create new meeting minute",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const update = async (input: any) => {
        setLoading(true);
        await Provider.Governance.updateMeetingMinute(input, {
            customError: {
                message: "Could not update the meeting minute",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const get = async (id: any) => {
        setLoading(true);
        const data = await Provider.Governance.getMeetingMinuteById(id, {
            customError: {
                message: "Could not get meeting minute",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    const getActions = async (id: any) => {
        setLoading(true);
        const data: any = await Provider.Governance.getMeetingMinuteById(id, {
            customError: {
                message: "Could not get meeting minute",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data.actions;
    };

    const remove = async (id: any) => {
        setLoading(true);
        await Provider.Governance.deleteMeetingMinute(id, {
            customError: {
                message: "Could not delete meeting minute",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        createMeetingMinute: create,
        updateMeetingMinute: update,
        getMeetingMinuteActions: getActions,
        getMeetingMinute: get,
        removeMeetingMinute: remove,
    };
};

export { useMeetingMinutesCRUD };
