import { ILink } from "models/links";
import { useState } from "react";
import Provider from "services/api";

const useLinksCrud = () => {
    const [loading, setLoading] = useState(false);

    const create = async (input: ILink) => {
        setLoading(true);
        const id = await Provider.Links.upsertLink(input, {
            customError: {
                message: "Could not create new link",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const upsert = async (input: ILink) => {
        setLoading(true);
        await Provider.Links.upsertLink(input, {
            customError: {
                message: "Could not insert link",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (ids: number[]) => {
        setLoading(true);
        const id = await Provider.Links.deleteLinks(ids, {
            customError: {
                message: "Could not delete links",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        create,
        upsert,
        remove,
    };
};

export { useLinksCrud };
