import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Provider } from "services";
const cancelToken = axios.CancelToken;

type InputFileType = {
    name: string;
    id: number;
};

type FileType = {
    progress: number;
    name: string;
    id: number;
    source: any;
};

type InputType = {
    clearFileList?: any;
    attachedFileList?: any[];
    onChange: any;
    notification: any;
    maxFileSize?: any;
    accept?: string;
    folderId?: number | null;
};

const useAttach = ({
    clearFileList,
    attachedFileList,
    onChange,
    notification,
    maxFileSize = 40,
    accept,
    folderId = null,
}: InputType) => {
    const { t } = useTranslation();
    const [newFiles, setNewFiles] = useState<FileType[]>([]);
    const [allFiles, setAllFiles] = useState<FileType[]>([]);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    useEffect(() => {
        if (clearFileList !== undefined && clearFileList.clear === true) {
            setNewFiles([]);
            setAllFiles([]);
        }
    }, [clearFileList]);

    useEffect(() => {
        if (attachedFileList !== undefined) {
            setAllFiles(
                attachedFileList.map((file: InputFileType) => ({
                    id: file.id,
                    name: file.name,
                    progress: 100,
                    source: null,
                }))
            );
        }
    }, [attachedFileList]);

    const handleDeleteFile = (fileId: number) => {
        setAllFiles(allFiles.filter(f => f.id !== fileId));
        onChange && onChange(allFiles.filter(f => f.id !== fileId).map(f => f.id));
    };

    const handleAllFilesUploaded = (
        uploadedFiles: FileType[],
        uploaded: number,
        numberOfFiles: number
    ) => {
        if (uploaded === numberOfFiles) {
            setNewFiles([]);
            setAllFiles([...allFiles, ...uploadedFiles]);
            setUploadInProgress(false);
            uploadedFiles.length &&
                onChange &&
                onChange(
                    [...allFiles.map(f => f.id), ...uploadedFiles.map(f => f.id)],
                    [...allFiles.map(f => f.name), ...uploadedFiles.map(f => f.name)]
                );
        }
    };

    const handleUpload = (
        files: any,
        fileNames: any = undefined,
        folderName?: string,
        title?: string,
        partnerId?: number
    ) => {
        setUploadInProgress(true);
        let uploaded = 0;
        let uploadedFiles: FileType[] = [];

        files = Array.from(files); //make sure that files is an array.
        files.forEach(async (fileToUpload: any) => {
            if (fileNames && !fileNames[fileToUpload.name]) {
                uploaded++;
                return;
            }

            if (fileToUpload.size / (1024 * 1024) > maxFileSize) {
                notification.error({
                    message: t("systemMessage"),
                    description: `${t("uploadFileError")} ${
                        fileToUpload.name
                    } (${t("maximumAllowedSize", { maxFileSize })})`,
                });
                uploaded++;
                handleAllFilesUploaded(uploadedFiles, uploaded, Array.from(files).length);
                return;
            }
            if (accept && !accept.includes(fileToUpload.type)) {
                notification.error({
                    message: t("systemMessage"),
                    description: `${t("uploadFileError")} ${fileToUpload.name} (${t(
                        "uploadFileExtensionError"
                    )})`,
                });
                uploaded++;
                handleAllFilesUploaded(uploadedFiles, uploaded, Array.from(files).length);
                return;
            }

            const fileIsDuplicate: boolean =
                allFiles.filter(f => f.name === fileToUpload.name).length !== 0 ||
                uploadedFiles.filter(f => f.name === fileToUpload.name).length !== 0;
            if (fileIsDuplicate) {
                notification.error({
                    message: t("file_DuplicateFile"),
                    description: `${fileToUpload.name} ${t("file_IsAlreadyAttached")}`,
                });
                uploaded++;
                handleAllFilesUploaded(uploadedFiles, uploaded, Array.from(files).length);
                return;
            }

            let formData = new FormData();
            if (fileNames && fileNames[fileToUpload.name]) {
                formData.append("files", fileToUpload, fileNames[fileToUpload.name]);
            } else {
                formData.append("files", fileToUpload);
            }
            const source = cancelToken.source();
            uploadedFiles.push({
                name: fileToUpload.name,
                progress: 0,
                id: 0,
                source,
            });
            try {
                const onUploadProgress = (progressEvent: any) => {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );

                    uploadedFiles = uploadedFiles.map((file: any) =>
                        file.name === fileToUpload.name
                            ? { ...file, progress: percentCompleted }
                            : file
                    );

                    setNewFiles(uploadedFiles);
                };

                const res =
                    folderId === null
                        ? await Provider.File.uploadFile(
                              formData,
                              source.token,
                              onUploadProgress,
                              folderName,
                              fileToUpload.title,
                              fileToUpload.partnerId
                          )
                        : await Provider.File.uploadFileToFolder(
                              folderId,
                              formData,
                              source.token,
                              onUploadProgress
                          );

                uploaded++;
                uploadedFiles = uploadedFiles.map((file: any) =>
                    file.name === fileToUpload.name ? { ...file, id: res[0] } : file
                );
                setNewFiles(uploadedFiles);

                handleAllFilesUploaded(uploadedFiles, uploaded, Array.from(files).length);
            } catch (err) {
                console.log(err);
                if (!axios.isCancel(err)) {
                    notification.error({
                        message: t("systemMessage"),
                        description: `${t("uploadFileError")} ${fileToUpload.name}`,
                    });
                }
                uploaded++;
                uploadedFiles = uploadedFiles.filter(
                    (file: any) => file.name !== fileToUpload.name
                );
                setNewFiles(uploadedFiles.filter((file: any) => file.name !== fileToUpload.name));
                handleAllFilesUploaded(uploadedFiles, uploaded, Array.from(files).length);
            }
        });
    };

    return {
        handleDeleteFile,
        handleUpload,
        uploadInProgress,
        allFiles,
        newFiles,
    };
};

export { useAttach };
