import React, { useEffect } from "react";
import { Modal, Form, Input, Divider, notification, Button } from "antd";
import { SelectData, SelectTreeData, SelectTreeDataMultiple } from "components/utilitycomponents";
import {
    usePartnerList,
    useProductIdInUse,
    useCommodityCategories,
    useProductUpsert,
    useProductStatus,
} from "controller";
import { useTranslation } from "react-i18next";

type Props = {
    visible: boolean;
    onClose: () => void;
};

const CreateModal = ({ visible, onClose }: Props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { isProductIdInUse } = useProductIdInUse();
    const { upsert, loading } = useProductUpsert();

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    const onSubmit = async () => {
        let values = await form.validateFields();
        await upsert({
            productId: values.productId,
            name: values.name,
            description: values.description,
            commodityCategoryId: values.commodityCategory,
            partnerIds: values.suppliers,
            productStatusId: values.productStatus,
        });
        notification.success({ message: t("success"), type: "success" });
        onClose();
        form.resetFields();
    };

    return (
        <Modal
            destroyOnClose
            title={
                <Divider orientation="left" orientationMargin={0}>
                    {t("product_AddProduct")}
                </Divider>
            }
            onOk={onSubmit}
            open={visible}
            onCancel={() => onClose()}
            width={800}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSubmit()}>
                    {t("generic_Create")}
                </Button>,
            ]}>
            <Form
                colon={false}
                form={form}
                variant="filled"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                    required
                    name="productId"
                    label={t("product_ProductId")}
                    validateTrigger="onBlur"
                    rules={[
                        {
                            validator: async (rule: any, value: any, callback: any) => {
                                try {
                                    if (!value || !value.trim()) {
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    }

                                    const productIdExists = await isProductIdInUse(value);
                                    if (productIdExists) {
                                        return Promise.reject(
                                            new Error(t("product_ProductIdExistError"))
                                        );
                                    }
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}>
                    <Input placeholder={t("product_ProductIdPlaceholder")} maxLength={500} />
                </Form.Item>
                <Form.Item
                    required
                    name="name"
                    label={t("generic_Name")}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <Input placeholder={t("product_ProductNamePlaceholder")} maxLength={500} />
                </Form.Item>
                <Form.Item name="description" label={t("generic_Description")}>
                    <Input.TextArea
                        placeholder={t("product_ProductDescriptionPlaceholder")}
                        maxLength={5000}
                    />
                </Form.Item>
                <Form.Item
                    required
                    name="commodityCategory"
                    label={t("product_Category")}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <SelectTreeData
                        placeholder={t("product_ProductCategoryPlaceholder")}
                        selectIdField="internalId"
                        selectOptionField="description"
                        useData={useCommodityCategories.bind(null, {
                            level: 1,
                            singleLevel: false,
                        })}
                    />
                </Form.Item>
                <Form.Item
                    required
                    name="suppliers"
                    label={t("Supplier")}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <SelectTreeDataMultiple
                        useData={usePartnerList}
                        selectOptionField={(item: any) => ({
                            text: `${item.name} ${item.number}`,
                            node: (
                                <span>
                                    {item.name} {item.number}
                                </span>
                            ),
                        })}
                        placeholder={t("product_SupplierPlaceholder")}
                    />
                </Form.Item>
                <Form.Item
                    required={false}
                    name="productStatus"
                    label={t("product_ProductStatus")}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}
                    initialValue={2}>
                    <SelectData
                        useData={useProductStatus}
                        selectIdField="id"
                        selectOptionField="name"
                        placeholder={t("product_ProductPlaceholder")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export { CreateModal };
