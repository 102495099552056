import { get } from "../base";

const UIViewSetting = {
    getViewSetting: async (viewName: string) => {
        const url = `/v1/UIViewSetting?view=${viewName}`;
        return await get({ url });
    },
};

export default UIViewSetting;
