import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Divider, notification } from "antd";
import EditButton from "../../editButton";
import EditModal from "./editModal";
import { ICompanyCard } from "models";
import { AttachFile, AttachImage } from "components/utilitycomponents";
import { useCompanyType, useSupplierCardsPartnerUpdate } from "controller";
import classes from "./style/IdCard.module.scss";

type PropsType = {
    partnerId: number;
    partnerName: string;
    data: ICompanyCard;
};

const IdCard: FunctionComponent<PropsType> = ({ partnerId, partnerName, data }) => {
    const { t } = useTranslation();
    const { updatePartnerInfo } = useSupplierCardsPartnerUpdate();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [supplierData, setSupplierData] = useState<ICompanyCard>(data);
    const [attachedFileList, setAttachedFileList] = useState<any>();
    const [attachedImageList, setAttachedImageList] = useState<any>();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        setSupplierData(data);
        setAttachedFileList(data.fileId ? [{ id: data.fileId, name: data.fileName }] : undefined);
        setAttachedImageList(
            data.profilePictureId ? [{ id: data.profilePictureId, name: "" }] : undefined
        );
    }, [data]);

    const handleAttachFile = async (fileIds: number[]) => {
        const title = t("supplierProfile_CompanyProfile", {
            companyType: isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer"),
        });
        await updatePartnerInfo({ partnerId, supplierFileId: fileIds.length ? fileIds[0] : 0 });
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateSupplierProfileSuccess", { title }),
        });
    };

    const handleAttachImage = async (fileIds: number[]) => {
        await updatePartnerInfo({ partnerId, profilePictureId: fileIds.length ? fileIds[0] : 0 });
        setSupplierData({ ...supplierData, profilePictureId: fileIds.length ? fileIds[0] : 0 });
    };

    const handleSave = ({ ...rest }: any) => {
        setSupplierData({ ...supplierData, ...rest });
    };

    return (
        <Fragment>
            <Card bodyStyle={{ paddingTop: "16px" }}>
                <Fragment>
                    <Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <EditButton onClick={() => setEditModalVisible(true)}>
                                <span className={classes.editButtonText}>{t("edit")}</span>
                            </EditButton>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col lg={12}>
                            <AttachImage
                                attachedFileList={attachedImageList}
                                onChange={handleAttachImage}
                                multiple={false}
                            />
                        </Col>

                        <Col lg={12} className={classes.marginTopInSmallScreen}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            data-test="partner_name"
                                            style={{ verticalAlign: "top" }}>
                                            <b>
                                                {isDefaultCompany
                                                    ? t("company_Type_Supplier")
                                                    : t("company_Type_Customer")}
                                                :{" "}
                                            </b>
                                        </td>
                                        <td>
                                            <div className={classes.breakLongText}>
                                                {partnerName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                            <b>{t("supplierProfile_IdCardCeo")}: </b>
                                        </td>
                                        <td>
                                            <div
                                                data-test="partner_ceo"
                                                className={classes.breakLongText}>
                                                {supplierData.ceo}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                            <b>{t("supplierProfile_IdCardHq")}: </b>
                                        </td>
                                        <td>
                                            <div
                                                data-test="partner_hq"
                                                className={classes.breakLongText}>
                                                {supplierData.hq}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <div data-test="main_business" className={classes.breakLongText}>
                                <b>{t("supplierProfile_MainBusiness")}: </b>
                                {supplierData.mainBusiness}
                            </div>
                            <div data-test="number_of_employees" className={classes.breakLongText}>
                                <b>{t("supplierProfile_NumberOfEmployees")}: </b>
                                {supplierData.numberOfEmployees}
                            </div>
                            <div data-test="geography_coverage" className={classes.breakLongText}>
                                <b>{t("supplierProfile_GeographyCoverage")}: </b>
                                {supplierData.geographyCoverage}
                            </div>
                            <div
                                data-test="shareholder_structure"
                                className={classes.breakLongText}>
                                <b>{t("supplierProfile_ShareholderStructure")}: </b>
                                {supplierData.shareholderStructure}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div data-test="year_established" className={classes.breakLongText}>
                                <b>{t("supplierProfile_YearEstablished")}: </b>
                                {supplierData.yearStablished}
                            </div>
                            <div data-test="yearly_revenue" className={classes.breakLongText}>
                                <b>{t("supplierProfile_YearlyRevenue")}: </b>
                                {supplierData.lastYearRevenue}
                            </div>
                            <div data-test="yearly_net_income" className={classes.breakLongText}>
                                <b>{t("supplierProfile_YearlyNetIncome")}: </b>
                                {supplierData.lastYearNetIncome}
                            </div>
                            <div data-test="financial_rating" className={classes.breakLongText}>
                                <b>{t("supplierProfile_FinancialRating")}: </b>
                                {supplierData.financialRating}
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            </Card>
            <div
                style={{
                    borderWidth: "0 1px 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#e8e8e8",
                    padding: "10px",
                }}>
                <AttachFile
                    showMaxFileSize={false}
                    multiple={false}
                    onChange={handleAttachFile}
                    uploadSectionLayout={{ style: { display: "table" } }}
                    uploadButtonLayout={{ style: { display: "table-cell" } }}
                    filesSectionLayout={{
                        style: { display: "table-cell", width: "100%", padding: "0 10px" },
                    }}
                    attachedFileList={attachedFileList}>
                    {t("supplierProfile_AddAFile")}
                </AttachFile>
            </div>
            <EditModal
                partnerId={partnerId}
                visible={editModalVisible}
                data={supplierData}
                onSave={handleSave}
                onClose={() => setEditModalVisible(false)}
            />
        </Fragment>
    );
};

export default IdCard;
