class EventSingleton {
    private static instance: EventSingleton | null = null;
    private events: { name: string; callback: Function }[] = [];

    private constructor() {}

    public static getInstance(): EventSingleton {
        if (!EventSingleton.instance) {
            EventSingleton.instance = new EventSingleton();
        }
        return EventSingleton.instance;
    }

    public addEvent(name: string | string[], callback: Function, force?: boolean): void {
        if (typeof name === "string") {
            const existingEventIndex = this.events.findIndex(event => event && event.name === name);
            if (existingEventIndex !== -1 && force === false) {
                this.events[existingEventIndex].callback = callback;
            } else {
                this.events.push({ name, callback });
            }
        } else {
            name.forEach(name => this.addEvent(name, callback, force));
        }
    }

    public removeEvent(name: string) {
        this.events.forEach((event, index) => {
            if (event.name.startsWith(name)) {
                delete this.events[index];
            }
        });
    }

    public emitEvent(name: string, ...params: any[]): void {
        const findEvents = this.events.filter(item => item.name === name);
        findEvents.forEach((element, index) => {
            if (typeof element?.callback === "function") element.callback(...params);
        });
    }
}

const useEmit = () => {
    const eventSingleton = EventSingleton.getInstance();

    const newEmit = (name: string | string[], func: any, force: boolean = false): void => {
        eventSingleton.addEvent(name, func, force);
    };

    const emit = (name: string, ...params: any[]): void => {
        eventSingleton.emitEvent(name, ...params);
    };

    const removeEvents = (name: string) => {
        eventSingleton.removeEvent(name);
    };

    return { emit, newEmit, removeEvents };
};

export default useEmit;
