import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any | null;
};

const useSurveyCopyInformation = (surveyId: number | undefined) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (surveyId !== undefined) {
                setState({ ...state, loading: true });
                const data = await Provider.Survey.getSurveyInfoForCopy(surveyId, {
                    customError: {
                        message: "Could not retrieve survey information",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [surveyId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyCopyInformation };
