export enum IActionType {
    "Generic" = "Generic",
    "NCR" = "NCR",
    "Review" = "Review",
    "Compliance" = "Compliance",
    "Survey" = "Survey",
    "SelfAssessment" = "SelfAssessment",
    "GateKeeper" = "GateKeeper",
    "Segmentation" = "Segmentation",
    "MeetingMinute" = "MeetingMinute",
    "Performance" = "Performance",
}
