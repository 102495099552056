import React, { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Button, Spin, Skeleton, Divider, Form, Collapse, Tooltip, Input } from "antd";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { SurveyExtraFieldType, SurveyType } from "models";
import EditIntroduction from "./editIntroduction";
import SurveyQuestions from "./surveyQuestion";
import SurveySettingsModal from "./surveySettingsModal";
import ExtraFields from "./extraFields/extraFields";
import getExtraFieldValue from "./extraFields/getExtraFieldValues";
import classes from "./styles/adjustSurvey.module.scss";
import { HelpCircle } from "components/utilitycomponents";

type PropTypes = {
    visible: boolean;
    template: any;
    templateLoading: boolean;
    loading: boolean;
    surveyType: SurveyType;
    onPrevious: Function;
    onSubmit: Function;
    defaultSurvey: any | undefined;
    selectedVOSValue: boolean;
};

const AdjustSurvey: FunctionComponent<PropTypes> = ({
    visible,
    template,
    loading,
    templateLoading,
    surveyType,
    onPrevious,
    onSubmit,
    defaultSurvey,
    selectedVOSValue,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [introduction, setIntroduction] = useState<any>(undefined);
    const [selectedReviewCriteriaIds, setSelectedReviewCriteriaIds] = useState<any>([]);
    const [selectedOrderedReviewCriteria, setSelectedOrderedReviewCriteria] = useState<any>([]);
    const [surveySettings, setSurveySettings] = useState<any>({});
    const [surveySettingsModal, setSurveySettingsModal] = useState<any>({ visible: false });
    const [surveyFieldKeys, setSurveyFieldKeys] = useState<any>();
    const [supplierFieldKeys, setSupplierFieldKeys] = useState<any>();
    const [surveyFieldsCollapseOpen, setSurveyFieldsCollapseOpen] = useState<any>([]);
    const [supplierFieldsCollapseOpen, setSupplierFieldsCollapseOpen] = useState<any>([]);
    const [surveyTitle, setSurveyTitle] = useState<any>(undefined);

    useEffect(() => {
        if (template) {
            if (defaultSurvey) {
                setIntroduction(defaultSurvey.introduction);
                setSurveyTitle(defaultSurvey.title);
            } else {
                setIntroduction(template.introduction);
                setSurveyTitle(template.title);
            }
            setSelectedReviewCriteriaIds(template.selectedReviewCriteriaIds);
            setSelectedOrderedReviewCriteria(
                template.selectedReviewCriteriaIds.map((id: number, index: number) => ({
                    reviewCriteriaId: id,
                    reviewCategoryOrder: index,
                }))
            );
            if (surveyType !== 3) {
                setSurveySettings({
                    visibility: template.visibilityId,
                    introductionVOS: template.introductionVOS,
                    isVoiceOfSupplier: template.isVoiceOfSupplier,
                    allowGuestVOS: template.allowGuestVOS ? template.allowGuestVOS : false,
                    allowGuest: template.allowGuest ? template.allowGuest : false,
                });
            } else if (selectedVOSValue !== undefined) {
                setSurveySettings({
                    visibility: template && template.visibilityId,
                    introductionVOS: template && template.introductionVOS,
                    isVoiceOfSupplier: selectedVOSValue,
                    allowGuestVOS: selectedVOSValue,
                    allowGuest: template && template.allowGuest ? template.allowGuest : false,
                });
            }

            if (defaultSurvey) {
                setSurveyFieldsCollapseOpen(
                    defaultSurvey.textFieldHeadlines.some((x: any) => x.headlineType === "Survey")
                        ? [1]
                        : []
                );
                setSupplierFieldsCollapseOpen(
                    defaultSurvey.textFieldHeadlines.some((x: any) => x.headlineType === "Supplier")
                        ? [1]
                        : []
                );
            } else {
                setSurveyFieldsCollapseOpen(
                    template.extraFieldHeadlines.some(
                        (x: any) => x.surveyExtraFieldTypeId === SurveyExtraFieldType.Survey
                    )
                        ? [1]
                        : []
                );
                setSupplierFieldsCollapseOpen(
                    template.extraFieldHeadlines.some(
                        (x: any) => x.surveyExtraFieldTypeId === SurveyExtraFieldType.Supplier
                    )
                        ? [1]
                        : []
                );
            }

            form.resetFields();
        }
    }, [template, selectedVOSValue]);

    const handleSubmit = async () => {
        const values = await form.validateFields();
        const { customDropdownHeadlines, textFieldHeadlines } = getExtraFieldValue(
            values,
            surveyFieldKeys,
            supplierFieldKeys
        );
        onSubmit({
            title: surveyTitle,
            customDropdownHeadlines: customDropdownHeadlines,
            textFieldHeadlines: textFieldHeadlines,
            selectedReviewCriterias: selectedOrderedReviewCriteria,
            introduction,
            visibility: surveySettings.visibility,
            introductionVOS: surveySettings.introductionVOS,
            isVoiceOfSupplier: surveySettings.isVoiceOfSupplier,
            allowGuestOnVOS: surveySettings.allowGuestVOS,
            allowGuest: surveySettings.allowGuest,
        });
    };

    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return (
        <>
            <Row style={{ display: visible ? "block" : "none" }}>
                <Col span={24}>
                    <Spin spinning={templateLoading}>
                        <Form form={form} onFinish={handleSubmit}>
                            <Row style={{ minHeight: `${height - 350}px` }}>
                                <Col span={24}>
                                    <Row>
                                        <Col lg={{ span: 9 }} md={24}>
                                            <div
                                                className={classnames(
                                                    classes.section,
                                                    classes.first
                                                )}>
                                                {t("Survey Title")}
                                            </div>
                                            <Divider className={classes.sectionDivider} />
                                            <Input
                                                maxLength={1000}
                                                value={surveyTitle}
                                                onChange={(e: any) =>
                                                    setSurveyTitle(e.target.value)
                                                }
                                            />
                                            <div className={classnames(classes.section)}>
                                                {t("survey_Wizard_Introduction")}
                                            </div>
                                            <Divider className={classes.sectionDivider} />
                                            <EditIntroduction
                                                text={introduction}
                                                onChange={(val: string) => setIntroduction(val)}
                                            />
                                            {(surveyType === SurveyType.Performance ||
                                                surveyType === SurveyType.generic) &&
                                                selectedVOSValue === true && (
                                                    <>
                                                        <div className={classes.section}>
                                                            {t("survey_Wizard_OtherSurveySettings")}
                                                        </div>
                                                        <Divider
                                                            className={classes.sectionDivider}
                                                        />
                                                        <Button
                                                            type="primary"
                                                            onClick={() =>
                                                                setSurveySettingsModal({
                                                                    visible: true,
                                                                })
                                                            }>
                                                            {t(
                                                                "survey_Wizard_EditOtherSurveySettings"
                                                            )}
                                                        </Button>
                                                    </>
                                                )}
                                        </Col>
                                        <Col lg={{ span: 14, offset: 1 }} md={24}>
                                            <Collapse
                                                ghost
                                                className={classes.extraFields}
                                                activeKey={surveyFieldsCollapseOpen}
                                                onChange={(key: any) =>
                                                    setSurveyFieldsCollapseOpen(key)
                                                }>
                                                <Collapse.Panel
                                                    header={
                                                        <>
                                                            <div
                                                                className={
                                                                    classes.extraFieldHeader
                                                                }>
                                                                {t("survey_ExtraSurveyField", {
                                                                    count: 2,
                                                                })}
                                                            </div>
                                                            <Tooltip
                                                                title={t(
                                                                    "survey_InsertExtraSurveyFieldTooltip"
                                                                )}>
                                                                <div className={classes.infoIcon}>
                                                                    <HelpCircle />
                                                                </div>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    key={1}>
                                                    {templateLoading ? (
                                                        <Skeleton active paragraph={{ rows: 0 }} />
                                                    ) : (
                                                        <ExtraFields
                                                            type={"SurveyFields"}
                                                            data={
                                                                defaultSurvey
                                                                    ? defaultSurvey.textFieldHeadlines.filter(
                                                                          (x: any) =>
                                                                              x.headlineType ===
                                                                              "Survey"
                                                                      )
                                                                    : template
                                                                    ? template.extraFieldHeadlines.filter(
                                                                          (x: any) =>
                                                                              x.surveyExtraFieldTypeId ===
                                                                              SurveyExtraFieldType.Survey
                                                                      )
                                                                    : undefined
                                                            }
                                                            onFieldKeysChange={(keys: any) =>
                                                                setSurveyFieldKeys(keys)
                                                            }
                                                        />
                                                    )}
                                                </Collapse.Panel>
                                            </Collapse>
                                            <Collapse
                                                ghost
                                                className={classes.extraFields}
                                                activeKey={supplierFieldsCollapseOpen}
                                                onChange={(key: any) =>
                                                    setSupplierFieldsCollapseOpen(key)
                                                }>
                                                <Collapse.Panel
                                                    header={
                                                        <>
                                                            <div
                                                                className={
                                                                    classes.extraFieldHeader
                                                                }>
                                                                {t("survey_ExtraSupplierField", {
                                                                    count: 2,
                                                                })}
                                                            </div>
                                                            <Tooltip
                                                                title={t(
                                                                    "survey_InsertExtraSupplierFieldTooltip"
                                                                )}>
                                                                <div className={classes.infoIcon}>
                                                                    <HelpCircle />
                                                                </div>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    key={1}>
                                                    {templateLoading ? (
                                                        <Skeleton active paragraph={{ rows: 0 }} />
                                                    ) : (
                                                        <ExtraFields
                                                            type={"SupplierFields"}
                                                            data={
                                                                defaultSurvey
                                                                    ? defaultSurvey.textFieldHeadlines.filter(
                                                                          (x: any) =>
                                                                              x.headlineType ===
                                                                              "Supplier"
                                                                      )
                                                                    : template
                                                                    ? template.extraFieldHeadlines.filter(
                                                                          (x: any) =>
                                                                              x.surveyExtraFieldTypeId ===
                                                                              SurveyExtraFieldType.Supplier
                                                                      )
                                                                    : undefined
                                                            }
                                                            onFieldKeysChange={(keys: any) =>
                                                                setSupplierFieldKeys(keys)
                                                            }
                                                        />
                                                    )}
                                                </Collapse.Panel>
                                            </Collapse>
                                            <SurveyQuestions
                                                selectedReviewCriteriaIds={
                                                    selectedReviewCriteriaIds
                                                }
                                                onChange={(criteria: number[]) =>
                                                    setSelectedOrderedReviewCriteria(criteria)
                                                }
                                                surveyType={surveyType}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                    <Row style={{ marginTop: "10px" }}>
                        <Col span={12}>
                            <Button type="primary" size="large" onClick={() => onPrevious()}>
                                {t("generic_Previous")}
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => form.submit()}
                                loading={loading}>
                                {t("survey_Wizard_Create")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {surveySettingsModal.visible && (
                <SurveySettingsModal
                    visible={surveySettingsModal.visible}
                    onClose={() => setSurveySettingsModal({ visible: false })}
                    data={surveySettings}
                    onEdit={(values: any) => {
                        setSurveySettings(values);
                        setSurveySettingsModal({ visible: false });
                    }}
                />
            )}
        </>
    );
};

export default AdjustSurvey;
