import { useEffect, useState } from "react";
import { Provider } from "services";

const useTemplate = (id: number | undefined) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: null,
        sections: [],
    });

    useEffect(() => {
        (async () => {
            if (id) {
                setState({ ...state, loading: true });
                const data = await Provider.Compliance.getTemplate(id, {
                    customError: {
                        message: "Could not retrieve template data",
                    },
                }).catch((err: any) => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data,
                    sections: data.headLines.map((s: any) => ({
                        id: s.id.toString(),
                        questions: s.questions.map((q: any) => q.id.toString()),
                    })),
                });
            } else {
                setState({ data: null, sections: [], loading: false });
            }
        })();
    }, [id]);

    const handleChangeSections = (sections: any[]) => {
        state["section"] = sections;
        setState(state);
    };

    return {
        loading: state.loading,
        data: state.data,
        sections: state.sections,
        onSectionsListChange: handleChangeSections,
    };
};

export { useTemplate };
