import { useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

const useAccountSettingsUpdate = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const updateAccountSettings = async (values: any) => {
        setLoading(true);

        await Provider.User.updateAccountSettings(values, {
            customError: {
                message: t("account_UpdateError"),
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const isUsernameInUse = async (userName: string) => {
        return await Provider.User.isUsernameInUse(userName, {
            customError: {
                message: t("account_CouldNotValidateUsername"),
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    return {
        loading,
        updateAccountSettings,
        isUsernameInUse,
    };
};

export { useAccountSettingsUpdate };
