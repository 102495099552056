import { useState } from "react";
import { Provider } from "services";
import INcr from "models/ncr/INcr";
import { INcrDeviation } from "models/ncr/INcrDeviation";
import { NcrFieldVisibility } from "models";

const useNcrUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateApprovalStatus = async (ncrId: number, approvalStatus: string) => {
        setLoading(true);
        let approvalStatusId = 0;
        switch (approvalStatus) {
            case "Undecided":
                approvalStatusId = 0;
                break;
            case "Approved":
                approvalStatusId = 1;
                break;
            case "Not approved":
                approvalStatusId = 2;
                break;
            default:
                break;
        }
        const success = await Provider.Ncr.updateApprovalStatus(ncrId, approvalStatusId, {
            customError: {
                message: "Failed to update approval status",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    const updateHolder = async (ncrId: number, holderId: number) => {
        setLoading(true);
        const success = await Provider.Ncr.updateHolder(ncrId, holderId, {
            customError: {
                message: "Failed to update NCR holder",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    const removeHolder = async (ncrId: number) => {
        setLoading(true);
        const success = await Provider.Ncr.removeHolder(ncrId, {
            customError: {
                message: "Failed to remove NCR holder",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    const updateNcr = async ({
        ncrId,
        ncrValues,
        ncrActions,
        visibilityVisibility,
        previousVisibility,
    }: {
        ncrId: number;
        ncrValues: any;
        ncrActions: any[];
        visibilityVisibility: NcrFieldVisibility;
        previousVisibility: "Internal" | "External";
    }) => {
        setLoading(true);
        const ncrToUpdate = parseNcrToUpdate({
            ncrValues,
            existingActions: ncrActions,
            visibilityVisibility,
            previousVisibility,
        });
        await Provider.Ncr.updateNcr(ncrId, ncrToUpdate, {
            customError: {
                message: "Failed to update NCR",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const updateSearchTags = async (ncrId: number, searchTags: string[]) => {
        setLoading(true);
        const success = await Provider.Ncr.updateSeachTags(ncrId, searchTags, {
            customError: {
                message: "Failed to update search tags",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    const updateNextResponsible = async (ncrId: number, nextResponsible: string) => {
        setLoading(true);
        const success = await Provider.Ncr.updateNextResponsible(ncrId, nextResponsible, {
            customError: {
                message: "Failed to update next responsible",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    return {
        loading,
        updateApprovalStatus,
        updateHolder,
        removeHolder,
        updateNcr,
        updateSearchTags,
        updateNextResponsible,
    };

    function parseNcrToUpdate({
        ncrValues,
        existingActions = [],
        visibilityVisibility,
        previousVisibility,
    }: {
        ncrValues: any;
        existingActions: any[];
        visibilityVisibility: NcrFieldVisibility;
        previousVisibility: "Internal" | "External";
    }) {
        let ncr: INcr = {
            partnerId: ncrValues.externalPartner,
            ncrReasonId: ncrValues.ncrReason,
            visibility: ncrValues.visibility,
            customizedReceivers: ncrValues.customizedReceivers,
        };

        if (
            visibilityVisibility !== NcrFieldVisibility.Visible &&
            visibilityVisibility !== NcrFieldVisibility.VisibleAndMandatory
        ) {
            ncr["visibility"] = previousVisibility;
        }
        for (let [key, value] of Object.entries(ncrValues)) {
            let val: any = value;
            if (key === "salesOrderNumber" && val !== undefined) {
                ncr.salesOrderNumber = val;
            }
            if (key === "purchaseOrderNumber" && val !== undefined) {
                ncr.purchaseOrderNumber = val;
            }
            if (key === "deliveryNote" && val !== undefined) {
                ncr.deliveryNote = val;
            }
            if (key === "ncrValue" && val !== undefined) {
                ncr.ncrValue = val;
            }
            if (key === "ncrValueCurrency" && val !== undefined) {
                ncr.ncrValueCurrency = val;
            }
            if (key === "ncrOtherCost" && val !== undefined) {
                ncr.otherCostsValue = val;
            }
            if (key === "ncrOtherCostCurrency" && val !== undefined) {
                ncr.otherCostsValueCurrency = val;
            }
            if (key === "ncrOtherCostReason" && val !== undefined) {
                ncr.otherCostsReason = val;
            }
            if (key === "comment" && val !== undefined) {
                ncr.comment = val;
            }
            if (key === "attachedFiles" && val !== undefined) {
                if (val.length === 0) {
                    ncr.AttachmentIds = [];
                } else if (val[0].id) {
                    ncr.AttachmentIds = [];
                    for (let fileItem of val) {
                        ncr.AttachmentIds.push(fileItem.id);
                    }
                } else {
                    ncr.AttachmentIds = val;
                }
            }
            if (key === "ncrId" && val !== undefined && val !== "") {
                ncr.alternateId = val;
            }
            if (key === "ncrPartnerContactReceiver" && val !== undefined) {
                ncr.ncrPartnerContactReceiverIds = [...new Set(val)];
            }
        }

        let ncrActions: any[] = [];
        const correctiveAction = existingActions.filter(
            (x: any) => x.actionType === "Corrective"
        )[0];
        const preventiveAction = existingActions.filter(
            (x: any) => x.actionType === "Preventive"
        )[0];
        const rootCauseAction = existingActions.filter((x: any) => x.actionType === "RootCause")[0];
        if (ncrValues.correctiveActionCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.correctiveActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.correctiveActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                id: correctiveAction ? correctiveAction.id : undefined,
                actionType: "Corrective",
                owners: [
                    {
                        ownerId: ncrValues.correctiveActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };

            if (ncrValues.correctiveDeadline) {
                actionItem.deadline = ncrValues.correctiveDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        if (ncrValues.preventiveActionCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.preventiveActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.preventiveActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                id: preventiveAction ? preventiveAction.id : undefined,
                actionType: "Preventive",
                owners: [
                    {
                        ownerId: ncrValues.preventiveActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };
            if (ncrValues.preventiveDeadline) {
                actionItem.deadline = ncrValues.preventiveDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        if (ncrValues.rootCauseCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.rootCauseActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.rootCauseActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                id: rootCauseAction ? rootCauseAction.id : undefined,
                actionType: "RootCause",
                owners: [
                    {
                        ownerId: ncrValues.rootCauseActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };
            if (ncrValues.rootCauseDeadline) {
                actionItem.deadline = ncrValues.rootCauseDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        ncr.ncrActions = ncrActions;

        let deviations: INcrDeviation[] = [];
        if (ncrValues.deviations !== undefined && ncrValues.deviations.length > 0) {
            for (let i = 0; i < ncrValues.deviations.length; i++) {
                deviations.push({
                    productNumber:
                        ncrValues.deviations[i].productNumber &&
                        ncrValues.deviations[i].productNumber,
                    description:
                        ncrValues.deviations[i].description && ncrValues.deviations[i].description,
                    ordered: ncrValues.deviations[i].ordered && ncrValues.deviations[i].ordered,
                    delivered:
                        ncrValues.deviations[i].delivered && ncrValues.deviations[i].delivered,
                    faulty: ncrValues.deviations[i].faulty && ncrValues.deviations[i].faulty,
                });
            }
        }
        if (deviations.length > 0) {
            ncr.deviations = deviations;
        }
        return ncr;
    }
};

export { useNcrUpdate };
