import { SavedFilterType } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any;
    loading: boolean;
};

const useSavedFilters = (filterType: SavedFilterType, reloadSavedFilters: any = null) => {
    const [state, setState] = useState<StateType>({
        data: undefined,
        loading: false,
    });

    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data: any = await Provider.Segmentation.getSegmentationFilters(filterType).catch(
                e => {
                    setState({ ...state, loading: false });
                    return Promise.reject();
                }
            );
            setState({
                data: data,
                loading: false,
            });
        })();
    }, [filterType, reloadSavedFilters]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSavedFilters };
