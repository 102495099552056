import React, { useEffect } from "react";
import { setActiveKeyState, setSelectedKeyState } from "store/mainMenu/mainMenuSlice";
import { Divider, Menu } from "antd";
import {
    useAvailableSharedDashboards,
    useCompanyType,
    useCompanyBranding,
    useEnabledPowerBiReportsByArea,
    useProductFeature,
    useEmbeddedReportSettings,
    useNcrFeature,
    useFilesFeature,
} from "controller";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AppState } from "store";
import Config from "../../../config";
import {
    CheckCircleFilled,
    ContactsFilled,
    DashboardFilled,
    FileTextFilled,
    FlagFilled,
    GlobalOutlined,
    HomeFilled,
    LineChartOutlined,
    PushpinFilled,
    SettingFilled,
    ShoppingFilled,
    StarFilled,
} from "@ant-design/icons";
import classes from "./mainMenu.module.scss";
import { AdminMenu } from "./adminMenu/adminMenu";
import { generate } from "@ant-design/colors";
import { AreaType } from "models";

const { SubMenu } = Menu;

type MenuItemObject = {
    id: string;
    title: string;
    url: string;
    category: string;
    hasDivider: boolean;
};

interface IProps {
    onBrandingLoaded?: Function;
}

const MainMenu = ({ onBrandingLoaded }: IProps) => {
    const dispatch = useDispatch();
    const activeKey = useSelector((state: AppState) => state.mainMenu.activeKey);
    const selectedKey = useSelector((state: AppState) => state.mainMenu.selectedKey);
    const reload = useSelector((state: AppState) => state.reload.reloadState);
    const { data: reports } = useEmbeddedReportSettings(reload);

    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const { hasProductFeature } = useProductFeature();
    const { hasNcrFeature } = useNcrFeature();
    const { hasFilesFeature } = useFilesFeature();
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;
    const hasAdminAccess = user.profile["ll-Admin"] === "true" ? true : false;
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const hasSupplierAccess = user.profile["ll-Partner"] === "true" ? true : false;
    const hasNcrAccess = user.profile["ll-Ncr"] === "true" ? true : false;
    const hasFilesAccess = user.profile["ll-Files"] === "true" ? true : false;
    const hasContactAccess = user.profile["ll-Contact"] === "true" ? true : false;
    const hasKpiAccess = user.profile["ll-Kpi"] === "true" ? true : false;
    const hasReportingAccess = user.profile["ll-Reporting"] === "true";
    const hasProductAccess = user.profile["ll-Product"] === "true";
    const companyActionAccess =
        (user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyComplianceAccess =
        (user.profile["ll-enabledCompanyModule-Compliance"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyContactAccess =
        (user.profile["ll-enabledCompanyModule-Contact"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyPartnersAccess =
        (user.profile["ll-enabledCompanyModule-Supplier"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyFilesAccess =
        (user.profile["ll-enabledCompanyModule-File"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyNcrAccess =
        (user.profile["ll-enabledCompanyModule-Ncr"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReviewAccess =
        (user.profile["ll-enabledCompanyModule-Review"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReportingAccess =
        (user.profile["ll-enabledCompanyModule-Reporting"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const { data: sharedDashboards } = useAvailableSharedDashboards();
    const isGuest: boolean = user.profile["ll-Usertype"] === "Guest";
    const history = useHistory();
    const { data: branding, loading: loadingBranding } = useCompanyBranding(
        userType === Config.guestUser
    );
    const { data: ncrPbiReports } = useEnabledPowerBiReportsByArea(
        AreaType.Ncr,
        userType === Config.guestUser
    );

    useEffect(() => {}, []);

    useEffect(() => {
        if (!isGuest) {
            if (!loadingBranding && branding && branding.menuColor) {
                let accentColor = generate(branding.menuColor)[2];
                let element = document.getElementById("mainMenu");
                let homeElement = document.getElementById("homeIcon");
                element!.style.setProperty("--menuColor", branding.menuColor);
                element!.style.setProperty("--menuAccentColor", accentColor);
                element!.style.setProperty("--menuTextColor", branding.menuTextColor);
                homeElement!.style.setProperty("--textColor", branding.menuTextColor);
                onBrandingLoaded && onBrandingLoaded(branding);
            } else if (!loadingBranding) {
                let accentColor = generate("#1890ff")[2];
                let element = document.getElementById("mainMenu");
                let homeElement = document.getElementById("homeIcon");
                element!.style.setProperty("--menuColor", "#1890ff");
                element!.style.setProperty("--menuAccentColor", accentColor);
                element!.style.setProperty("--menuTextColor", "#ffffff");
                homeElement!.style.setProperty("--textColor", "#ffffff");
                onBrandingLoaded &&
                    onBrandingLoaded({
                        menuColor: "#1890ff",
                        menuTextColor: "#ffffff",
                    });
            }
        }
    }, [branding]);

    useEffect(() => {
        if (userType === Config.guestUser) {
            let element = document.getElementById("guestMenu");
            if (element) {
                element!.style.setProperty("background-color", "#1890ff");
                element!.style.setProperty("height", "100%");
            }
            let triggerElement = document.getElementsByClassName("ant-layout-sider-trigger")[0];
            if (triggerElement) {
                triggerElement!.setAttribute(
                    "style",
                    "background-color: #1890ff; color: #1890ff; width: 48px;"
                );
            }
        }
    }, [userType]);

    const supplierMenuItems: MenuItemObject[] = [
        {
            id: "supplier:0",
            title: t("mainMenu_PartnerOverview", {
                partnerType: isDefaultCompany ? t("topbar_Suppliers") : t("topbar_Customers"),
            }),
            url: "/Partners",
            category: "",
            hasDivider: false,
        },
        {
            id: "supplier:1",
            title: t("topbar_SupplierMasterdata", "Master Data Overview"),
            url: "/Partners/Masterdata",
            category: "",
            hasDivider: false,
        },
    ];
    const contactsMenuItems: MenuItemObject[] = [
        {
            id: "contacts:0",
            title: t("mainMenu_ContactsOverview"),
            url: "/Contacts",
            category: "",
            hasDivider: false,
        },
        {
            id: "contacts:1",
            title: t("topbar_ContactGroupOverview", "Group Overview"),
            url: "/Contacts/ContactGroup",
            category: "",
            hasDivider: false,
        },
    ];

    const reviewsMenuItems: MenuItemObject[] =
        userType !== "lightuser"
            ? [
                  {
                      id: "reviews:1",
                      title: t("mainMenu_ReviewOverview"),
                      url: "/Reviews",
                      category: "Reviews",
                      hasDivider: false,
                  },
                  {
                      id: "reviews:2",
                      title: t("topbar_SurveyOverview"),
                      url: "/Reviews/SurveyOverview",
                      category: "Survey",
                      hasDivider: false,
                  },
                  {
                      id: "reviews:3",
                      title: t("topbar_SurveyRequests"),
                      url: "/SurveyRequests",
                      category: "Survey",
                      hasDivider: false,
                  },
                  {
                      id: "reviews:4",
                      title: t("topbar_NonResponseOverview"),
                      url: "/Reviews/NonResponseOverview",
                      category: "Survey",
                      hasDivider: false,
                  },
                  {
                      id: "reviews:5",
                      title: t("topbar_AutoSurveyOverview"),
                      url: "/Reviews/AutomatedSurveyOverview",
                      category: "Automated Survey",
                      hasDivider: false,
                  },
              ]
            : [
                  {
                      id: "reviews:1",
                      title: t("topbar_ReviewOverview"),
                      url: "/Reviews",
                      category: "Reviews",
                      hasDivider: false,
                  },
              ];
    const ncrMenuItems: MenuItemObject[] | any[] = [
        {
            id: "ncr:0",
            title: t("mainMenu_NCROverview"),
            url: "/Ncrs",
            category: "",
            hasDivider: false,
        },
        ncrPbiReports.length > 0
            ? {
                  id: "ncr:1",
                  title: "Portfolio",
                  url: "/Ncrs/Portfolio",
                  category: "",
                  hasDivider: false,
              }
            : null,
    ];
    const complianceMenuItems: MenuItemObject[] = [
        {
            id: "compliance:0",
            title: t("mainMenu_ComplianceOverview"),
            url: "/Compliance",
            category: "",
            hasDivider: false,
        },
        ...(userType === "administrator" || userType === "user"
            ? [
                  {
                      id: "compliance:1",
                      title: t("topbar_ComplianceMatrix", "Matrix Overview"),
                      url: "/Compliance/MatrixOverview",
                      category: "",
                      hasDivider: false,
                  },
                  {
                      id: "compliance:2",
                      title: t("topbar_ComplianceRequests", "Requests"),
                      url: "/Compliance/Requests",
                      category: "",
                      hasDivider: false,
                  },
              ]
            : []),
    ];

    const actionsMenuItems: MenuItemObject[] = [
        {
            id: "actions:0",
            title: t("mainMenu_ActionsOverview"),
            url: "/Actions",
            category: "",
            hasDivider: false,
        },
        {
            id: "actions:1",
            title: t("developmentPlans_Overview"),
            url: "/DevelopmentPlans",
            category: "",
            hasDivider: false,
        },
    ];

    const sharedAnalyticsMenuItems: MenuItemObject[] = [
        {
            id: "sharedAnalytics:0",
            title: "Shared Analytics",
            url: "/partnerAnalytics",
            category: "",
            hasDivider: false,
        },
    ];

    function renderMenuItems(MenuItemsArray: MenuItemObject[]) {
        return MenuItemsArray.map((menuItem, index) => {
            if (menuItem === null) return null;
            switch (menuItem.category) {
                case "Reviews":
                    if (menuItem.hasDivider) {
                        return [<Menu.ItemGroup title={menuItem.category} key={index} />];
                    }
                    return [
                        <Menu.Item key={menuItem.id}>
                            <Link to={menuItem.url} />
                            {menuItem.title}
                        </Menu.Item>,
                    ];

                case "Survey":
                    if (menuItem.hasDivider) {
                        return [<Menu.ItemGroup title={menuItem.category} key={index} />];
                    }
                    return [
                        <Menu.Item key={menuItem.id}>
                            <Link to={menuItem.url} />
                            {menuItem.title}
                        </Menu.Item>,
                    ];

                case "Automated Survey":
                    if (menuItem.hasDivider) {
                        return [<Menu.ItemGroup title={menuItem.category} key={index} />];
                    }
                    return [
                        <Menu.Item key={menuItem.id}>
                            <Link to={menuItem.url} />
                            {menuItem.title}
                        </Menu.Item>,
                    ];

                default:
                    return [
                        <Menu.Item key={menuItem.id}>
                            <Link to={menuItem.url} />
                            {menuItem.title}
                        </Menu.Item>,
                    ];
            }
        });
    }

    const handleOnTitleClick = (location: string, key?: string) => {
        if (key !== undefined) {
            dispatch(setSelectedKeyState([key]));
        } else {
            dispatch(setSelectedKeyState([]));
            dispatch(setActiveKeyState(""));
        }
        history.push(location);
    };

    const handleOnSelectChange = (key: string) => {
        if (key !== undefined) {
            if (key.indexOf("supplier overview") !== -1) {
                dispatch(setSelectedKeyState(["1"]));
            } else if (key.indexOf("contacts") !== -1) {
                dispatch(setSelectedKeyState(["2"]));
            } else if (key.indexOf("files") !== -1) {
                dispatch(setSelectedKeyState(["3"]));
            } else if (key.indexOf("reviews") !== -1) {
                dispatch(setSelectedKeyState(["4"]));
            } else if (key.indexOf("ncr") !== -1) {
                dispatch(setSelectedKeyState(["5"]));
            } else if (key.indexOf("compliance") !== -1) {
                dispatch(setSelectedKeyState(["6"]));
            } else if (key.indexOf("actions") !== -1) {
                dispatch(setSelectedKeyState(["7"]));
            } else if (key.indexOf("sharedAnalytics") !== -1) {
                dispatch(setSelectedKeyState(["8"]));
                dispatch(setActiveKeyState(key));
            } else if (key.indexOf("reporting") !== -1) {
                dispatch(setSelectedKeyState(["9"]));
                dispatch(setActiveKeyState(key));
            } else if (key.indexOf("adminSettings") !== -1 || key.startsWith("admin")) {
                dispatch(setSelectedKeyState(["10"]));
                dispatch(setActiveKeyState(key));
            } else {
                dispatch(setSelectedKeyState([key]));
            }
            dispatch(setActiveKeyState(key));
        }
    };

    return (
        <>
            {userType !== Config.guestUser ? (
                <Menu
                    id="mainMenu"
                    mode="vertical"
                    className={classes.menu}
                    selectedKeys={selectedKey}
                    activeKey={activeKey}
                    onSelect={({ item, key, keypath, selectedKeys, domEvent }: any) => {
                        handleOnSelectChange(key);
                    }}>
                    {userType !== Config.guestUser && (
                        <SubMenu
                            onTitleClick={(event: any) => handleOnTitleClick("/")}
                            className={classes.homeSubMenu}
                            key={"0"}
                            title={
                                <Link to="/">
                                    <HomeFilled id="homeIcon" className={classes.homeMenuIcon} />
                                    {t("topbar_Home")}
                                </Link>
                            }></SubMenu>
                    )}
                    {(userType === "user" || userType === "administrator") &&
                        hasSupplierAccess &&
                        companyPartnersAccess && (
                            <SubMenu
                                popupClassName={classes.subMenuPopover}
                                key={"1"}
                                title={
                                    <Link to="#">
                                        <GlobalOutlined className={classes.menuIcon} />

                                        {isDefaultCompany
                                            ? t("topbar_Suppliers")
                                            : t("topbar_Customers")}
                                    </Link>
                                }>
                                {renderMenuItems(supplierMenuItems)}
                            </SubMenu>
                        )}
                    {(userType === "administrator" || userType === "user") &&
                        hasContactAccess &&
                        companyContactAccess && (
                            <SubMenu
                                popupClassName={classes.subMenuPopover}
                                key={"2"}
                                title={
                                    <Link to="#">
                                        <ContactsFilled className={classes.menuIcon} />
                                        {t("topbar_Contacts")}
                                    </Link>
                                }>
                                {renderMenuItems(contactsMenuItems)}
                            </SubMenu>
                        )}
                    {hasFilesFeature &&
                        (userType === "user" || userType === "administrator") &&
                        hasFilesAccess &&
                        companyFilesAccess && (
                            <SubMenu
                                className={classes.noRowSubMenu}
                                key={"3"}
                                onTitleClick={(event: any) => handleOnTitleClick("/Files")}
                                title={
                                    <Link to="/Files">
                                        <FileTextFilled className={classes.menuIcon} />
                                        {t("topbar_Files")}
                                    </Link>
                                }></SubMenu>
                        )}
                    {hasReviewAccess && companyReviewAccess && (
                        <SubMenu
                            popupClassName={classes.subMenuPopover}
                            key={"4"}
                            title={
                                <Link to="#">
                                    <StarFilled className={classes.menuIcon} />
                                    {t("topbar_Reviews")}
                                </Link>
                            }>
                            {renderMenuItems(reviewsMenuItems)}
                        </SubMenu>
                    )}
                    {hasNcrFeature && hasNcrAccess && companyNcrAccess && (
                        <SubMenu
                            popupClassName={classes.subMenuPopover}
                            key={"5"}
                            title={
                                <Link to="#">
                                    <FlagFilled className={classes.menuIcon} />
                                    {t("topbar_Ncr")}
                                </Link>
                            }>
                            {renderMenuItems(ncrMenuItems)}
                        </SubMenu>
                    )}
                    {hasComplianceAccess && companyComplianceAccess && (
                        <SubMenu
                            popupClassName={classes.subMenuPopover}
                            key={"6"}
                            title={
                                <Link to="#">
                                    <CheckCircleFilled className={classes.menuIcon} />
                                    {t("topbar_Compliance")}
                                </Link>
                            }>
                            {renderMenuItems(complianceMenuItems)}
                        </SubMenu>
                    )}
                    {userType !== "lightuser" && hasProductFeature && hasProductAccess && (
                        <SubMenu
                            onTitleClick={(event: any) =>
                                handleOnTitleClick("/Products", event.key)
                            }
                            className={classes.noRowSubMenu}
                            key={"14"}
                            title={
                                <Link to="#">
                                    <ShoppingFilled
                                        className={classes.menuIcon}
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                    {t("product_Products")}
                                </Link>
                            }></SubMenu>
                    )}
                    {(userType === "user" ||
                        userType === "administrator" ||
                        userType === "lightuser") &&
                        companyActionAccess && (
                            <SubMenu
                                popupClassName={classes.subMenuPopover}
                                key={"7"}
                                title={
                                    <Link to="#">
                                        <PushpinFilled
                                            className={classes.menuIcon}
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                        {t("actions_Actions")}
                                    </Link>
                                }>
                                {renderMenuItems(actionsMenuItems)}
                            </SubMenu>
                        )}
                    {(userType === "user" || userType === "administrator") &&
                        hasKpiAccess &&
                        sharedDashboards.length > 0 && (
                            <SubMenu
                                popupClassName={classes.subMenuPopover}
                                key={"8"}
                                title={
                                    <Link to="#">
                                        <LineChartOutlined className={classes.menuIcon} />
                                        {t("analytics_Shared_MenuTitle")}
                                    </Link>
                                }>
                                {renderMenuItems(sharedAnalyticsMenuItems)}
                            </SubMenu>
                        )}
                    {(userType === "user" || userType === "administrator") &&
                        hasReportingAccess &&
                        companyReportingAccess &&
                        reports.length > 0 && (
                            <SubMenu
                                onTitleClick={(event: any) => handleOnTitleClick("/Reporting")}
                                className={classes.noRowSubMenu}
                                key={"9"}
                                title={
                                    <Link to="/Reporting">
                                        <DashboardFilled className={classes.menuIcon} />
                                        {t("topbar_Reporting")}
                                    </Link>
                                }></SubMenu>
                        )}
                    <Divider />
                    {userType === "administrator" && hasAdminAccess && (
                        <SubMenu
                            popupClassName={classes.subMenuPopover}
                            onTitleMouseEnter={() => {
                                setTimeout(() => {
                                    let elements = document.getElementsByClassName(
                                        "ant-menu-inline-collapsed"
                                    );
                                    if (elements) {
                                        if (elements[1]) {
                                            elements[1].classList.remove(
                                                "ant-menu-inline-collapsed"
                                            );
                                        }
                                    }
                                    let elements1 = document.getElementsByClassName(
                                        "ant-menu-inline-collapsed"
                                    );
                                    if (elements1) {
                                        if (elements1[1]) {
                                            elements1[1].classList.remove(
                                                "ant-menu-inline-collapsed"
                                            );
                                        }
                                    }
                                }, 150);
                            }}
                            key={"10"}
                            title={
                                <Link to="#">
                                    <SettingFilled className={classes.menuIcon} />
                                    {t("admin_Settings")}
                                </Link>
                            }>
                            {!isGuest && (
                                <AdminMenu
                                    selectedKeys={selectedKey}
                                    activeKeys={activeKey}
                                    onClick={(event: any) => handleOnSelectChange(event.key)}
                                />
                            )}
                        </SubMenu>
                    )}
                </Menu>
            ) : (
                <div id="guestMenu"></div>
            )}
        </>
    );
};

export { MainMenu };
