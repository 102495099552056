import { useState } from "react";
import { Provider } from "services";

const useGroupCreate = () => {
    const [loading, setLoading] = useState(false);

    const createGroup = async ({ name, customHeadline }: any) => {
        setLoading(true);
        const id = await Provider.Partner.createGroup(
            { name },
            {
                customError: { message: `Could not create ${customHeadline}` },
            }
        );
        setLoading(false);
        return id;
    };

    return {
        loading,
        createGroup,
    };
};

export { useGroupCreate };
