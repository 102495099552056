import React, { createRef, forwardRef } from "react";
import { TreeSelect, Spin, Empty } from "antd";
import classnames from "classnames";
import "./selectTreeData.scss";
const { TreeNode } = TreeSelect;

type PropsType = {
    value?: any;
    selectIdField?: string;
    selectOptionField: string | Function;
    emptyOption?: any;
    selectOnlyChildren: boolean;
    onChange?: (value?: any, item?: any) => void;
    useData: Function;
    [key: string]: any;
};
//forwardRef is used for the component to be able to be used as a FormItem child direclty
const SelectTreeData = forwardRef(
    (
        {
            value,
            selectIdField = "id",
            selectOptionField,
            emptyOption = null,
            selectOnlyChildren = false,
            onChange,
            useData,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { data, loading } = useData();

        const refsArray = data.map((x: any) => ({ id: x[selectIdField], ref: createRef() }));

        const expandParent = (elementId: string) => {
            const elementRef = refsArray.some((x: any) => x.id === elementId)
                ? refsArray.filter((x: any) => x.id === elementId)[0].ref
                : null;
            if (elementRef) {
                const p: HTMLElement | null = elementRef.current.closest(".parentTreeNode");
                if (p) {
                    const switcherElement: NodeListOf<HTMLElement> = p.querySelectorAll(
                        ".ant-select-tree-switcher"
                    );
                    if (switcherElement) {
                        switcherElement[0].click();
                    }
                }
            }
        };

        const createTreeNode = (item: any) => {
            return (
                <TreeNode
                    data-test={
                        typeof selectOptionField === "function"
                            ? selectOptionField(item).text.replace(/\s/g, "")
                            : item[selectOptionField].replace(/\s/g, "")
                    }
                    key={item[selectIdField]}
                    className={!item.children ? "nonParentTreeNode" : "parentTreeNode"}
                    style={{
                        marginLeft: item.children ? 0 : -18,
                    }}
                    value={item[selectIdField]}
                    {...(selectOnlyChildren &&
                        item.children && {
                            title: () => (
                                <div
                                    ref={
                                        refsArray.some((x: any) => x.id === item[selectIdField])
                                            ? refsArray.filter(
                                                  (x: any) => x.id === item[selectIdField]
                                              )[0].ref
                                            : null
                                    }
                                    onClick={() => expandParent(item[selectIdField])}>
                                    {typeof selectOptionField === "function"
                                        ? selectOptionField(item).node
                                        : item[selectOptionField]}
                                </div>
                            ),
                        })}
                    {...((!selectOnlyChildren || !item.children) && {
                        title:
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).node
                                : item[selectOptionField],
                    })}
                    treeNodeFilterProp={
                        typeof selectOptionField === "function"
                            ? selectOptionField(item).text
                            : item[selectOptionField]
                    }
                    selectable={
                        selectOnlyChildren && item.children
                            ? false
                            : typeof selectOptionField === "function" &&
                              selectOptionField(item).selectable !== undefined
                            ? selectOptionField(item).selectable
                            : true
                    }>
                    {item.children && item.children.map((child: any) => createTreeNode(child))}
                </TreeNode>
            );
        };

        const findSelectedItem = (val: any) => {
            for (let i = 0; i < data.length; i++) {
                let selectedItem = findSelected(data[i], val);
                if (selectedItem) {
                    return selectedItem;
                }
            }
            return undefined;
        };

        const findSelected = (item: any, val: any) => {
            if (item[selectIdField] === val) {
                return item;
            } else if (item.children) {
                for (let j = 0; j < item.children.length; j++) {
                    const child: any = findSelected(item.children[j], val);
                    if (child) {
                        return child;
                    }
                }
            } else {
                return undefined;
            }
        };

        const searchNodes = (inputValue: any, treeNode: any) => {
            if (!treeNode) return false;
            if (
                treeNode["treeNodeFilterProp"] &&
                treeNode["treeNodeFilterProp"]
                    .toLocaleLowerCase()
                    .includes(inputValue.toLocaleLowerCase())
            ) {
                return true;
            }
            if (treeNode.children) {
                for (let j = 0; j < treeNode.children.length; j++) {
                    if (
                        treeNode.children[j]["treeNodeFilterProp"]
                            .toLocaleLowerCase()
                            .includes(inputValue.toLocaleLowerCase())
                    ) {
                        return true;
                    }
                }
            }

            return false;
        };

        const generateExpandedKeys = () => {
            let keys: any[] = [];
            if (!value) return [];
            for (let i = 0; i < data.length; i++) {
                keys = [...keys, ...expandedKeys(data[i])];
            }
            return keys;
        };

        const expandedKeys = (item: any) => {
            let keys: any[] = [];
            if (!item.children) return [];

            if (item.children.some((x: any) => x[selectIdField] === value)) {
                keys = [...keys, item[selectIdField]];
            }
            for (let i = 0; i < item.children.length; i++) {
                keys = [...keys, ...expandedKeys(item.children[i])];
            }
            return keys;
        };

        return (
            <TreeSelect
                value={findSelectedItem(value) ? value : undefined}
                onChange={(value?: any) => {
                    onChange && onChange(value, findSelectedItem(value));
                }}
                treeDefaultExpandedKeys={generateExpandedKeys()}
                showSearch
                filterTreeNode={searchNodes}
                notFoundContent={
                    loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                popupClassName={classnames("dropdownWrapper", rest.dropdownClassName)}
                loading={loading}
                {...rest}>
                {data && data.map((item: any) => createTreeNode(item))}
            </TreeSelect>
        );
    }
);

export { SelectTreeData };
