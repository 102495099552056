import currencyCodes from "utilities/misc/currencies.json";
const getCurrencies = () => {
    return currencyCodes.sort((e1, e2) => {
        if (e1.CurrencyCode > e2.CurrencyCode) {
            return 1;
        }
        if (e1.CurrencyCode < e2.CurrencyCode) {
            return -1;
        }

        return 0;
    });
};
export { getCurrencies };
