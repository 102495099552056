import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Radio, Form, message } from "antd";
import { INotificationSetting, ReceiveEmailNotificationsTypes } from "models";
import { FormRow, FormLabel } from "components/utilitycomponents";
import { AppState } from "store";
import { useNotificationSettingUpdate } from "controller";

interface IProps {
    moduleSetting: INotificationSetting;
}

const NotificationSetting = ({ moduleSetting }: IProps) => {
    const { t } = useTranslation();
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const { updateNotificationSetting } = useNotificationSettingUpdate();

    return (
        <Fragment>
            <FormRow>
                <Col span={23} offset={1}>
                    <FormLabel>{t("account_SendEmailOnEvent")}</FormLabel>
                </Col>
                <Col span={23} offset={1}>
                    <Form.Item
                        name={`receiveEmailNotification[${moduleSetting.moduleId}]`}
                        initialValue={
                            moduleSetting ? moduleSetting.receiveEmailNotification : undefined
                        }>
                        <Radio.Group
                            onChange={(e: any) => {
                                updateNotificationSetting(moduleSetting.moduleId, e.target.value);
                                message.success(t("account_NotificationSettingUpdated"));
                            }}
                            value={0}>
                            <Radio value={ReceiveEmailNotificationsTypes.None}>
                                {t("account_NotificationNone")}
                            </Radio>
                            <Radio value={ReceiveEmailNotificationsTypes.All}>
                                {moduleSetting.moduleId === 6
                                    ? t("account_notification_ActionsAssignedTo")
                                    : t("account_NotificationAll")}
                            </Radio>
                            {(userType !== "lightuser" ||
                                (userType === "lightuser" && moduleSetting.moduleId === 6)) && (
                                <Radio value={ReceiveEmailNotificationsTypes.OnlyOnOwnedPartners}>
                                    {moduleSetting.moduleId === 6
                                        ? t("account_notification_ActionsAssignedToOrCreator")
                                        : t("account_NotificationRelatedToPartners")}
                                </Radio>
                            )}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </FormRow>
        </Fragment>
    );
};

export default NotificationSetting;
