import React, { FunctionComponent } from "react";
import classnames from "classnames";
import classes from "./formLabel.module.scss";

const FormLabel: FunctionComponent<{ required?: boolean; [key: string]: any }> = ({
    children,
    required,
    ...rest
}) => {
    const className = rest.className;
    delete rest.className;
    return (
        <label className={classnames(required ? classes.labelRequired : "", className)} {...rest}>
            {children}
        </label>
    );
};

export { FormLabel };
