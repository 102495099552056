import React, { useState, useEffect } from "react";
import { Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import { usePartnerReviewAverage } from "controller";
import { ReviewRate } from "components/utilitycomponents";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    visible: boolean;
    onClose: Function;
    partnerId: number;
}

const RapidReviewModal = ({ visible, onClose, partnerId }: IProps) => {
    const { t } = useTranslation();

    const [trigger, setTrigger] = useState<any>({ trigger: false });
    const { data, loading } = usePartnerReviewAverage(
        trigger,
        partnerId,
        dayjs()
            .subtract(1, "year")
            .toISOString(),
        dayjs().toISOString(),
        null,
        true
    );

    useEffect(() => {
        if (visible === true) {
            setTrigger({ trigger: true });
        }
    }, [visible]);

    let columns = [
        {
            title: t("createdBy"),
            dataIndex: "createdByContact",
            key: "createdByContact",
            render: (val: any, record: any) => (
                <div>{val && val.firstName + " " + val.lastName}</div>
            ),
        },
        {
            title: t("rating"),
            dataIndex: "rating",
            key: "rating",
            render: (val: any) => <ReviewRate val={val} />,
        },
        { title: t("comment"), dataIndex: "comment", key: "comment", ellipsis: true },
        {
            title: t("Created"),
            dataIndex: "created",
            key: "created",
            render: (val: any) => <div>{val && dayjs(val).format("DD MMM YYYY")}</div>,
        },
    ];

    return (
        <Modal
            title={"Reviews - Past 12 Months"}
            width={800}
            open={visible}
            onCancel={() => onClose()}
            footer={null}>
            <Table loading={loading} columns={columns} dataSource={data && data.reviews} />
        </Modal>
    );
};

export { RapidReviewModal };
