import React, { FunctionComponent, useState, Fragment } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useCompanyType, useReviewRegistration } from "controller";
import {
    LoadingIndicator,
    UserImage,
    FollowActivity,
    ChildActivities,
    ArrowsRight,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import ReviewHeader from "./reviewHeader";
import EditModal from "../edit/editModal";
import { IActivityChild } from "models";
import { ReviewDetails } from "./reviewDetail";
import { default as CreateActionModal } from "components/contentcomponents/action/registration/registrationCreateModal";
import { IActionType } from "models/action";

type PropsType = {
    visible: boolean;
    id: number;
    onVisibleChange: Function;
    reloadTable?: Function;
    onAddActivity?: (parentActivityId: number, activity: IActivityChild) => void; //coming from homepage
    onRemoveActivity?: (parentActivityId: number, activityId: number) => void; //coming from homepage
    onChangeFollowActivity?: (activityId: number, isFollowed: boolean) => void; //coming from homepage
};

const RegistrationModal: FunctionComponent<PropsType> = ({
    visible,
    id,
    onVisibleChange,
    reloadTable,
    onAddActivity,
    onRemoveActivity,
    onChangeFollowActivity,
}) => {
    const [reload, setReload] = useState({ reload: false });
    const { loading, data, addActivity, removeActivity } = useReviewRegistration(id, reload);
    const [editModal, setEditModal] = useState({
        id: -1,
        visible: false,
    });
    const [actionModal, setActionModal] = useState<any>({
        visible: false,
        registrationId: undefined,
        supplierId: undefined,
    });
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();

    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        addActivity(parentActivityId, activity);
        onAddActivity && onAddActivity(parentActivityId, activity);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        removeActivity(parentActivityId, activityId);
        onRemoveActivity && onRemoveActivity(parentActivityId, activityId);
    };

    if (!loading && (data === null || data === "")) return null;
    return (
        <Fragment>
            <Modal
                wrapProps={{ "data-test": "review_registration_modal" }}
                title={
                    <Row>
                        <Col
                            span={16}
                            style={{
                                lineHeight: "32px",
                            }}>
                            {data
                                ? `${t("review_review")} ${data.id} ${
                                      data.reviewedPartnerName
                                          ? "on " + data.reviewedPartnerName
                                          : ""
                                  } `
                                : null}
                        </Col>
                        <Col span={7} style={{ textAlign: "right" }}>
                            {isDefaultCompany && (
                                <Button
                                    style={{ marginRight: 4 }}
                                    size="small"
                                    onClick={() =>
                                        setActionModal({
                                            visible: true,
                                            registrationId: data.id,
                                            supplierId:
                                                data.reviewedPartnerId !== null &&
                                                data.reviewedPartnerId !== 0
                                                    ? data.reviewedPartnerId
                                                    : undefined,
                                        })
                                    }>
                                    <ArrowsRight />
                                    {t("action_CreateAction")}
                                </Button>
                            )}
                            {data && (
                                <FollowActivity
                                    isFollowed={data.isFollow}
                                    activityId={data.activityId}
                                    onChangeFollowActivity={onChangeFollowActivity}
                                />
                            )}
                        </Col>
                    </Row>
                }
                open={visible}
                footer={null}
                onCancel={() => onVisibleChange()}
                width={900}
                style={{ top: 20 }}>
                {loading && (
                    <Row style={{ textAlign: "center" }}>
                        <LoadingIndicator style={{ fontSize: "18px" }} />
                    </Row>
                )}
                {data === null && null}
                {data !== null && data !== "" && (
                    <Row gutter={8}>
                        <Col span={2}>
                            <UserImage
                                contactId={data.createdByContactId}
                                alt={data.creator}
                                style={{ width: "100%" }}
                                fileId={
                                    "creatorProfilePictureId" in data &&
                                    data.creatorProfilePictureId !== null
                                        ? data.creatorProfilePictureId
                                        : undefined
                                }
                                customDimension={65}
                            />
                        </Col>
                        <Col span={21}>
                            <ReviewHeader data={data} />
                            <div>
                                <ReviewDetails data={data} />
                            </div>
                            {!data.externallyCreated && (
                                <Row style={{ marginTop: "12px" }}>
                                    <Button
                                        data-test="edit_review_button"
                                        type="primary"
                                        onClick={() =>
                                            setEditModal({ ...editModal, visible: true })
                                        }>
                                        {t("review_EditReview")}
                                    </Button>
                                </Row>
                            )}
                            <div style={{ marginTop: "10px" }}>
                                <ChildActivities
                                    data={data.childrenActivity}
                                    parentActivityId={data.activityId}
                                    onRemoveActivity={handleRemoveActivity}
                                    onAddActivity={handleAddActivity}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </Modal>
            <EditModal
                id={id}
                visible={editModal.visible}
                onVisibleChange={() => setEditModal({ ...editModal, visible: false })}
                reloadTable={() => reloadTable && reloadTable()}
                reloadRegistrationModal={() => setReload({ reload: true })}
            />
            <CreateActionModal
                visible={actionModal.visible}
                registrationId={actionModal.registrationId}
                supplierId={actionModal.supplierId}
                registrationType={IActionType.Review}
                onClose={() =>
                    setActionModal({
                        visible: false,
                        supplierId: undefined,
                        registrationId: undefined,
                    })
                }
            />
        </Fragment>
    );
};

export default RegistrationModal;
