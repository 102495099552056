import { useState } from "react";
import { Provider } from "services";

const useSendGuestReport = () => {
    const [loading, setLoading] = useState(false);

    const send = async (guestToken: string) => {
        setLoading(true);

        await Provider.Analytics.sendGuestReport(guestToken, {
            customError: { message: "Could not send report" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);
    };

    return {
        loading,
        send,
    };
};

export { useSendGuestReport };
