import React, { useState, useEffect, Fragment } from "react";
import { Checkbox, Divider, Tooltip, Form, Modal, Button } from "antd";
import { HelpCircle, SelectTreeData, SelectTreeDataMultiple } from "components/utilitycomponents";
import { SelectData, SelectDataMultiple } from "components/utilitycomponents";
import { XAxisSelect } from "../dashboardUtils/xAxisSelect/xAxisSelect";
import { PeriodPicker } from "../dashboardUtils/periodPicker/periodPicker";
import { useReportUpdateFilter, useKpisAvailable, useKpisSubLevels } from "controller";
import classes from "./styles/filterModal.module.scss";
import { useTranslation } from "react-i18next";
import { translateSubLevelHeadline } from "../dashboardUtils/subLevelTranslationHelper";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    visible: boolean;
    closeModal: Function;
    reportObject: any;
    reload: Function;
    benchmarkId: any;
    dashboardVisibility: any;
    partnerId: number;
}

const FilterModal = ({
    visible,
    closeModal,
    reportObject,
    reload,
    benchmarkId,
    dashboardVisibility,
    partnerId,
}: IProps) => {
    const [form] = Form.useForm();
    const { updateFilters, loading } = useReportUpdateFilter();
    const [kpiId, setKpiId] = useState<any>(undefined);
    const [isReviewKpi, setIsReviewKpi] = useState(false);
    const [monthRange, setMonthRange] = useState([dayjs(), dayjs()]);
    const { t } = useTranslation();
    const { data: kpiData, loading: kpisLoading } = useKpisAvailable(
        partnerId,
        dashboardVisibility
    );
    const [kpiCustomColumns, setKpiCustomColumns] = useState([]);
    const [kpiSubLevelHeadline, setKpiSubLevelHeadline] = useState("Sub Level");
    const [selectedXAxis, setSelectedXAxis] = useState<any>(undefined);
    const [chartType, setChartType] = useState<any>(undefined);

    useEffect(() => {
        form.resetFields();
    }, [reportObject]);

    useEffect(() => {
        if (visible) {
            setKpiId(reportObject.filter.dataRelation.dataId);
            setMonthRange([dayjs(reportObject.filter.from), dayjs(reportObject.filter.to)]);

            let kpi = kpiData.filter(
                (x: any) => x.id === reportObject.filter.dataRelation.dataId
            )[0];
            setIsReviewKpi(kpi && kpi.title.toLocaleLowerCase().includes("review"));
            if (kpi && kpi.customColumns) {
                getAvailableCustomColumns();
            }
            setKpiSubLevelHeadline(
                translateSubLevelHeadline(
                    reportObject.filter.dataRelation.subLevel.subLevelHeadline
                )
            );
            setSelectedXAxis(reportObject.filter.xAxisProduct);
            setChartType(reportObject.filter.chartType);
        }
    }, [visible, reportObject]);

    function getAvailableCustomColumns() {
        let selectedKpiId = reportObject.filter.dataRelation.dataId;
        let availableCustomColumns = kpiData
            .filter((x: any) => x.id === selectedKpiId)[0]
            .customColumns.map((x: any) => ({ label: x.name, value: x.id }));
        setKpiCustomColumns(availableCustomColumns);
    }

    async function submit() {
        let values = await form.validateFields();
        if (isReviewKpi) {
            values.subLevels = values.reviewSubLevels ? values.reviewSubLevels : undefined;
        }
        values.dateRange = monthRange;
        await updateFilters(reportObject.id, values);
        reload(reportObject.id);
    }

    function onCancel() {
        closeModal();
        form.resetFields();
    }

    function onKpiChange(val: any, item: any) {
        setKpiId(parseInt(val));
        setKpiCustomColumns(
            item.customColumns.map((x: any) => ({
                value: x.id,
                label: x.name,
            }))
        );
        setKpiSubLevelHeadline(item.subLevel.subLevelHeadline);
        let kpi = kpiData.filter((x: any) => x.id === val)[0];
        setIsReviewKpi(kpi && kpi.title.toLocaleLowerCase().includes("review"));
        form.setFieldsValue({ level: undefined, subLevels: undefined, reviewSubLevels: undefined });
    }

    const checkboxOptions = [
        { label: t("analytics_CompanyName"), value: "CompanyName" },
        { label: t("analytics_Month"), value: "Month" },
        { label: t("analytics_Year"), value: "Year" },
        { label: kpiSubLevelHeadline, value: "SubLevel" },
        { label: t("analytics_KpiValue"), value: "KpiValue" },
        { label: t("analytics_Target"), value: "TargetValue" },
        { label: t("analytics_AllPartnersValue"), value: "AllExternalPartnersValue" },
        { label: t("analytics_Visibility"), value: "Visibility" },
    ];

    return (
        <div>
            {reportObject && (
                <Modal
                    destroyOnClose
                    title={`${t("analytics_FiltrationTitle")} ${
                        reportObject.title ? reportObject.title : t("analyics_FiltrationTitle1")
                    }`}
                    closable={true}
                    onOk={submit}
                    width={1000}
                    open={visible}
                    mask={false}
                    onCancel={(e: any) => {
                        onCancel();
                    }}
                    footer={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={async () => await submit()}>
                            {t("generic_Save")}
                        </Button>,
                    ]}>
                    <Form
                        form={form}
                        onFinish={submit}
                        labelAlign="left"
                        wrapperCol={{ span: 18 }}
                        labelCol={{ span: 6 }}>
                        <>
                            <Form.Item
                                label={t("analytics_FiltrationChartType")}
                                name="chartType"
                                initialValue={reportObject.filter.chartType}>
                                <SelectData
                                    className={classes.select}
                                    placeholder={t("analytics_FiltrationChartTypePlaceholder")}
                                    selectOptionField="name"
                                    onChange={(val: any) => setChartType(val)}
                                    useData={() => {
                                        return {
                                            data: [
                                                { id: "Bar", name: t("analytics_BarChart") },
                                                { id: "Pie", name: t("analytics_PieChart") },
                                                {
                                                    id: "DataTable",
                                                    name: t("analytics_DataTable"),
                                                },
                                                { id: "Line", name: t("analytics_LineChart") },
                                            ],
                                            loading: false,
                                        };
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t("analytics_FiltrationKPI")}
                                name="kpi"
                                initialValue={reportObject.filter.dataRelation.dataId}>
                                <SelectData
                                    className={classes.select}
                                    placeholder={t("analytics_FiltrationKPIPlaceholder")}
                                    selectOptionField="title"
                                    useData={() => {
                                        return { data: kpiData, loading: kpisLoading };
                                    }}
                                    onChange={(val: any, item: any) => {
                                        onKpiChange(val, item);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="period" label={t("analytics_FiltrationPeriod")}>
                                <PeriodPicker
                                    initialValue={monthRange}
                                    onChange={(values: any[]) => setMonthRange(values)}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t("analytics_FiltrationXAxisProduct")}
                                name="xAxisProduct"
                                initialValue={reportObject.filter.xAxisProduct}>
                                <XAxisSelect
                                    className={classes.select}
                                    placeholder={t("analytics_FiltrationXAxisProductPlaceholder")}
                                    onChange={(val: any) => setSelectedXAxis(val)}
                                    selectedChartType={selectedXAxis}
                                />
                            </Form.Item>

                            {(selectedXAxis === undefined &&
                                reportObject.filter.xAxisProduct === "SubLevel") ||
                            selectedXAxis === "SubLevel" ||
                            (selectedXAxis === "DataTable" && selectedXAxis !== "AllSubLevels") ? (
                                <Fragment>
                                    {isReviewKpi ? (
                                        <Form.Item
                                            label={t("analytics_XAxisSelect_SubLevels")}
                                            required
                                            name="reviewSubLevels"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}
                                            initialValue={reportObject.filter.dataRelation.subLevels.map(
                                                (x: any) => x.id
                                            )}>
                                            <SelectTreeDataMultiple
                                                selectAll
                                                className={classes.subLevelSelect}
                                                placeholder={t(
                                                    "analytics_FiltrationSubLevelsPlaceholder"
                                                )}
                                                getPopupContainer={(node: any) => document.body}
                                                selectIdField="id"
                                                selectOptionField="subLevelHeadline"
                                                useData={useKpisSubLevels.bind(
                                                    null,
                                                    kpiId,
                                                    partnerId
                                                )}
                                                selectOnlyChildren
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label={t("analytics_XAxisSelect_SubLevels")}
                                            required
                                            name="subLevels"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}
                                            initialValue={reportObject.filter.dataRelation.subLevels.map(
                                                (x: any) => x.id
                                            )}>
                                            <SelectDataMultiple
                                                selectAll
                                                className={classes.subLevelSelect}
                                                placeholder={t(
                                                    "analytics_FiltrationSubLevelsPlaceholder"
                                                )}
                                                getPopupContainer={(node: any) => document.body}
                                                selectIdField="id"
                                                selectOptionField="subLevelHeadline"
                                                useData={useKpisSubLevels.bind(
                                                    null,
                                                    kpiId,
                                                    partnerId
                                                )}
                                            />
                                        </Form.Item>
                                    )}
                                </Fragment>
                            ) : (
                                (selectedXAxis === undefined &&
                                    reportObject.filter.xAxisProduct !== "SubLevel" &&
                                    reportObject.filter.xAxisProduct !== "AllSubLevels") ||
                                (selectedXAxis !== undefined &&
                                    selectedXAxis !== "SubLevel" &&
                                    selectedXAxis !== "AllSubLevels" && (
                                        <Fragment>
                                            <Form.Item
                                                label={t("analytics_FiltrationLevel")}
                                                required
                                                name="level"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}
                                                initialValue={
                                                    reportObject.filter.dataRelation.subLevels.map(
                                                        (x: any) => x.id
                                                    )[0]
                                                }>
                                                {isReviewKpi ? (
                                                    <SelectTreeData
                                                        className={classes.select}
                                                        placeholder={t(
                                                            "analytics_FiltrationLevelPlaceholder"
                                                        )}
                                                        selectIdField="id"
                                                        selectOptionField="subLevelHeadline"
                                                        useData={useKpisSubLevels.bind(
                                                            null,
                                                            kpiId,
                                                            partnerId
                                                        )}
                                                        selectOnlyChildren
                                                    />
                                                ) : (
                                                    <SelectData
                                                        className={classes.select}
                                                        placeholder={t(
                                                            "analytics_FiltrationLevelPlaceholder"
                                                        )}
                                                        selectIdField="id"
                                                        selectOptionField="subLevelHeadline"
                                                        useData={useKpisSubLevels.bind(
                                                            null,
                                                            kpiId,
                                                            partnerId
                                                        )}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Fragment>
                                    ))
                            )}

                            <Form.Item
                                label={
                                    <>
                                        {t("analytics_FiltrationBenchmark")}
                                        {benchmarkId === undefined && (
                                            <Tooltip
                                                title={t("analytics_FiltrationBenchmarkTooltip")}>
                                                <span>
                                                    <HelpCircle />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </>
                                }
                                name="benchmark"
                                valuePropName="checked"
                                initialValue={reportObject.filter.includeBenchmark}>
                                <Checkbox disabled={benchmarkId === undefined}>
                                    {t("analytics_FiltrationBenchmarkLabel")}
                                </Checkbox>
                            </Form.Item>

                            <Form.Item
                                label={t("analytics_FiltrationSamePeriod")}
                                name="previousPeriod"
                                valuePropName="checked"
                                initialValue={reportObject.filter.samePeriodPreviousYear}>
                                <Checkbox>{t("analytics_FiltrationSamePeriodLabel")}</Checkbox>
                            </Form.Item>

                            {(chartType === "DataTable" ||
                                (reportObject.filter.chartType === "DataTable" &&
                                    chartType === undefined)) && (
                                <Fragment>
                                    <Divider orientation="left">
                                        {t("analytics_FiltrationDataTableSettings")}
                                    </Divider>

                                    <Form.Item
                                        label={t("analytics_FiltrationEnabledColumns")}
                                        name="enabledStandardColumns"
                                        initialValue={reportObject.filter.enabledStandardColumns}>
                                        <Checkbox.Group options={checkboxOptions} />
                                    </Form.Item>

                                    <Form.Item
                                        label={t("analytics_FiltrationEnabledCustomColumns")}
                                        name="enabledCustomColumns"
                                        initialValue={
                                            reportObject.filter.enabledCustomColumns
                                                ? reportObject.filter.enabledCustomColumns
                                                      .filter((x: any) => x.active === true)
                                                      .map((x: any) => x.customColumnId)
                                                : []
                                        }>
                                        {kpiCustomColumns.length > 0 ? (
                                            <Checkbox.Group options={kpiCustomColumns} />
                                        ) : (
                                            <div>
                                                {t(
                                                    "analytics_NoCustomColumnsPlaceholder",
                                                    "There are no custom columns defined for this KPI"
                                                )}
                                            </div>
                                        )}
                                    </Form.Item>
                                </Fragment>
                            )}
                        </>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default FilterModal;
