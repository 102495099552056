import React from "react";
import { Row, Col } from "antd";
import classes from "./segmentationInfo.module.scss";
import { useTranslation } from "react-i18next";

const LabelSection = () => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={classes.infoLabel} span={24} style={{ paddingTop: 26 }}>
                    {t("unitOfAnalysis_AlignedSegment")}
                </Col>
            </Row>
            <Row>
                <Col className={classes.infoLabel} span={24} style={{ paddingTop: 22 }}>
                    {t("segmentation_Restrictions")}
                </Col>
            </Row>

            <Row>
                <Col className={classes.infoLabel} span={24} style={{ paddingTop: 16 }}>
                    {t("segmentation_Comment")}
                </Col>
            </Row>
        </>
    );
};

export { LabelSection };
