import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input, Divider, Form } from "antd";
import { TypedQuestion } from "../../sharedComponents";
import { IComplianceHeadLine } from "models";
import classes from "./styles/createForm.module.scss";
const TextArea = Input.TextArea;

interface IProps {
    templateHeadLines: IComplianceHeadLine[];
    onAttachDocumentLoading?: any;
    form: any;
}

const HeadLines = ({ form, templateHeadLines, onAttachDocumentLoading }: IProps) => {
    const { t } = useTranslation();
    const [fileQuestionsLoading, setFileQuestionsLoading] = useState<any[]>([]);

    useEffect(() => {
        onAttachDocumentLoading && onAttachDocumentLoading(fileQuestionsLoading);
    }, [fileQuestionsLoading]);

    return (
        <Fragment>
            {templateHeadLines.map((headLine, index) => (
                <Fragment key={headLine.id}>
                    <Divider
                        data-test={`divider_${index + 1}`}
                        orientation="left"
                        orientationMargin={0}
                        style={{ fontWeight: "bold" }}>
                        {index + 1}. {headLine.headline}
                    </Divider>
                    {headLine.complianceQuestions &&
                        headLine.complianceQuestions.map((question, questionIndex) => {
                            return (
                                <Row
                                    data-test={`question_area_${index + 1}_${questionIndex + 1}`}
                                    key={question.id}
                                    gutter={16}
                                    style={{ marginBottom: "22px" }}>
                                    <Col lg={16} md={16} sm={24} xs={24}>
                                        <Form.Item
                                            labelCol={{ lg: 16, md: 17, sm: 24, xs: 24 }}
                                            wrapperCol={{ lg: 8, md: 9, sm: 24, xs: 24 }}
                                            label={
                                                <>
                                                    <div
                                                        data-test={`question_${index +
                                                            1}_${questionIndex + 1}`}
                                                        className={classes.question}>
                                                        {index + 1}.{questionIndex + 1}.{" "}
                                                        {question.isMandatory &&
                                                            question.isMandatory && (
                                                                <span className={classes.required}>
                                                                    *
                                                                </span>
                                                            )}{" "}
                                                        {question.question}
                                                    </div>
                                                    <div
                                                        data-test={`question_guideline_${index +
                                                            1}_${questionIndex + 1}`}>
                                                        {question.guideline}
                                                    </div>
                                                </>
                                            }
                                            data-test={`question[${question.id}]`}
                                            className={classes.formItem}
                                            name={`question[${question.id}]`}
                                            valuePropName={"value"}
                                            rules={[
                                                {
                                                    required: question.isMandatory
                                                        ? question.isMandatory
                                                        : false,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <TypedQuestion
                                                question={question}
                                                onUploadingFile={(uploading: boolean) => {
                                                    let index = fileQuestionsLoading.findIndex(
                                                        (x: any) =>
                                                            Object.keys(x)[0] ===
                                                            question.id.toString()
                                                    );
                                                    if (index === -1) {
                                                        setFileQuestionsLoading([
                                                            ...fileQuestionsLoading,
                                                            { [`${question.id}`]: uploading },
                                                        ]);
                                                    } else {
                                                        let newVals = fileQuestionsLoading
                                                            .slice(0)
                                                            .filter(
                                                                (x: any) =>
                                                                    Object.keys(x)[0] !==
                                                                    question.id.toString()
                                                            );
                                                        newVals.splice(index, 0, {
                                                            [`${question.id}`]: uploading,
                                                        });
                                                        setFileQuestionsLoading(newVals);
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            data-test={`comment[${question.id}]`}
                                            name={`comment[${question.id}]`}>
                                            <TextArea
                                                data-test="comment_textarea"
                                                maxLength={2000}
                                                rows={4}
                                                placeholder={t("compliance_AddComment")}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default HeadLines;
