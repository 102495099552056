import React, { useState, useEffect } from "react";
import { Modal, Form, Tooltip, Tag, Button, Divider, Input } from "antd";
import { useTranslation } from "react-i18next";
import { CreateModal as CreateContactModal } from "../../../../../contacts/shared/createModal";
import { Mail, SelectTreeData } from "components/utilitycomponents";
import {
    useContactsWithGroups,
    useExternalPartnerContacts,
    useRelationshipCompanyContactCRUD,
    useRelationshipSupplierContactCRUD,
} from "controller";
import { Modules } from "models";
import classes from "./relationships.module.scss";

interface IProps {
    visible: boolean;
    onClose: Function;
    onSubmit: Function;
    type: "company" | "partner";
    categoryId: number | undefined;
    contactsLength: number | undefined;
    contact: any | undefined;
    partnerId: number;
}

const CreateEditMemberModal = ({
    visible,
    onClose,
    type,
    categoryId,
    contactsLength,
    onSubmit,
    contact,
    partnerId,
}: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });
    const [contactModal, setContactModal] = useState<any>({
        visible: false,
    });
    const {
        createContact: createCompanyContact,
        updateContact: updateCompanyContact,
        loading: creatingCompanyContact,
    } = useRelationshipCompanyContactCRUD();
    const {
        createContact: createSupplierContact,
        updateContact: updateSupplierContact,
        loading: creatingSupplierContact,
    } = useRelationshipSupplierContactCRUD();
    const { data: companyContacts, loading: loadingCompanyContacts } = useContactsWithGroups(
        ["Administrator", "User", "LightUser"],
        Modules.Partner,
        reloadContacts
    );
    const { data: partnerContacts, loading: loadingPartnerContacts } = useExternalPartnerContacts(
        partnerId,
        reloadContacts,
        true
    );
    const [expandedKeys, setExpandedKeys] = useState([]);

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    const handleOnSubmnit = async () => {
        let values = await form.validateFields();
        let contactObject = {
            id: contact !== undefined ? contact.relationshipCategoryContactId : undefined,
            categoryId: categoryId,
            contactId: values.contact,
            role: values.role,
            order: contact === undefined ? contactsLength! + 1 : contact.order,
        };
        if (contact === undefined) {
            if (type === "company") {
                await createCompanyContact(contactObject);
            } else {
                await createSupplierContact(contactObject);
            }
        } else {
            if (type === "company") {
                await updateCompanyContact(contactObject);
            } else {
                await updateSupplierContact(contactObject);
            }
        }
        onSubmit();
    };

    const tagRender = (props: any, singleLineOptions: boolean) => {
        const { label, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color="blue"
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginBottom: 4, marginRight: singleLineOptions ? 120 : 4 }}>
                {label.length > 50 ? (
                    <Tooltip title={label}>{label.substr(0, 50)} ...</Tooltip>
                ) : (
                    label
                )}
            </Tag>
        );
    };

    async function handleEmailClick(email: string) {
        window.location.href = `mailto:${email}`;
    }

    return (
        <Modal
            destroyOnClose
            open={visible}
            onCancel={() => onClose()}
            title={
                contact === undefined
                    ? t("relationsMatrix_AddMember")
                    : t("relationsMatrix_EditMember")
            }
            cancelText={t("generic_Cancel")}
            onOk={() => handleOnSubmnit()}
            width={600}
            footer={[
                <Button
                    loading={creatingCompanyContact === true || creatingSupplierContact === true}
                    type="primary"
                    onClick={async () => await handleOnSubmnit()}>
                    {contact === undefined ? t("generic_Add") : t("generic_Save")}
                </Button>,
            ]}>
            <Form
                onFinish={handleOnSubmnit}
                form={form}
                labelCol={{ span: 5, style: { textAlign: "left" } }}>
                <Form.Item
                    name="contact"
                    label={t("Contact")}
                    initialValue={contact !== undefined ? contact.contactId : undefined}
                    rules={[{ required: true }]}>
                    <SelectTreeData
                        allowClear={true}
                        onChange={(val: any, item: any) =>
                            form.setFieldsValue({
                                email: item ? item.emailAddress : undefined,
                                workPhone: item ? item.workPhone : undefined,
                                mobilePhone: item ? item.mobilePhone : undefined,
                            })
                        }
                        useData={() => ({
                            data: type === "company" ? companyContacts : partnerContacts,
                            loading:
                                type === "company"
                                    ? loadingCompanyContacts
                                    : loadingPartnerContacts,
                        })}
                        selectAll
                        selectOptionField="name"
                        placeholder={t("select")}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                        tagRender={(props: any) => tagRender(props, false)}
                        treeExpandedKeys={expandedKeys}
                        onTreeExpand={(keys: any) => {
                            if (keys && keys.length === 1) {
                                setExpandedKeys(keys);
                            } else if (keys && keys.length > 1) {
                                setExpandedKeys(keys.slice(1, 2));
                            } else {
                                setExpandedKeys(keys);
                            }
                        }}
                        selectOnlyChildren
                        dropdownRender={(menu: any) => (
                            <>
                                {menu}
                                <Divider style={{ margin: "8px 0" }} />
                                <Button
                                    block
                                    size="small"
                                    onClick={() =>
                                        setContactModal({
                                            visible: true,
                                            partnerId: type === "company" ? undefined : partnerId,
                                            type: type,
                                        })
                                    }>
                                    + {t("Contacts_Add")}
                                </Button>
                            </>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    name="role"
                    label={t("relationsMatrix_Role")}
                    initialValue={contact !== undefined ? contact.role : undefined}>
                    <Input maxLength={256} placeholder={t("generic_Placeholder-Text")} />
                </Form.Item>
                <Form.Item
                    label={t("generic_Email")}
                    name="email"
                    initialValue={contact !== undefined ? contact.email : undefined}>
                    <Input
                        className={classes.input}
                        variant="borderless"
                        disabled
                        suffix={
                            <Tooltip title={t("admin_Invite_ComposeEmailTitle")}>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEmailClick(form.getFieldValue("email"))}>
                                    <Mail />
                                </div>
                            </Tooltip>
                        }
                    />
                </Form.Item>
                <Form.Item
                    label={t("account_WorkPhone")}
                    name="workPhone"
                    initialValue={contact !== undefined ? contact.workPhone : undefined}>
                    <Input
                        className={classes.input}
                        variant="borderless"
                        placeholder="-"
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    label={t("contact_mobilePhone")}
                    name="mobilePhone"
                    initialValue={contact !== undefined ? contact.mobilePhone : undefined}>
                    <Input
                        className={classes.input}
                        variant="borderless"
                        placeholder="-"
                        disabled
                    />
                </Form.Item>
            </Form>
            <CreateContactModal
                visible={contactModal.visible}
                onClose={(reload, contactId, contact) => {
                    if (contactId) {
                        setReloadContacts({ reload: true });

                        form.setFieldsValue({
                            contact: contactId,
                            email: contact.emailAddress,
                            workPhone: contact.workPhone,
                            mobilePhone: contact.mobilePhone,
                        });
                    }

                    setContactModal({ visible: false, partnerId: undefined });
                }}
                partnerId={contactModal.partnerId}
                showSupplierSelect={false}
            />
        </Modal>
    );
};

export { CreateEditMemberModal as AddMemberModal };
