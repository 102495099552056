import { get, post, remove } from "../base";

const NewsLog = {
    getNewsLog: async (
        supplierId: any,
        searchTitle?: any,
        from?: any,
        to?: any,
        flaggedItems: boolean = false,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/GetNewsLogs?supplierId=${supplierId}&flaggedItems=${flaggedItems.toString()}`;
        if (searchTitle) {
            url = url + `&title=${searchTitle}`;
        }
        if (to) {
            url = url + `&to=${to}`;
        }
        if (from) {
            url = url + `&from=${from}`;
        }

        let response = await get({
            url,
            retryCount: retryCount,
            customError: customError,
        });
        return response;
    },
    addNewsLogs: async (
        payload: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/CreateNewsLog";
        await post({ url, payload, retryCount: retryCount, customError: customError });
    },
    remoceNewsLog: async (
        id: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/DeleteNewsLog?newsLogId=${id}`;
        await remove({ url, retryCount: retryCount, customError: customError });
    },
    flagNews: async (
        payload: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/CreateFlaggedNewsItem`;
        await post({ url, payload, retryCount, customError });
    },
    unFlagNews: async (
        id: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/DeleteFlaggedNewsItem?flaggedNewsItemId=${id}`;
        await remove({ url, retryCount, customError });
    },
};

//
export default NewsLog;
