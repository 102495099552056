import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useNcrActionAnswerUpdate = () => {
    const [loading, setLoading] = useState(false);

    const UpdateAnswer = async (actionId: number, answerText: string) => {
        setLoading(true);

        const ncrActionid = await serviceProvider.Ncr.updateNcrActionAnswer(
            actionId,
            answerText
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return ncrActionid;
    };

    return {
        loading,
        UpdateAnswer,
    };
};

export { useNcrActionAnswerUpdate };
