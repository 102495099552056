import React, { useEffect } from "react";
import { Modal, Input, Form, Button } from "antd";
import { useReportUpdate } from "controller";
import { useTranslation } from "react-i18next";
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    closeModal: Function;
    reportObject: any;
    onSubmitFinished: Function;
}

const EditReportContainerModal = ({
    visible,
    closeModal,
    reportObject,
    onSubmitFinished,
}: IProps) => {
    const [form] = Form.useForm();
    const { loading, updateReport } = useReportUpdate();
    const { t } = useTranslation();

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    async function onSubmit() {
        let values = await form.validateFields();
        await updateReport(reportObject.id, {
            title: values.reportTitle,
            description: values.reportDescription,
        });
        form.resetFields();
        closeModal();
        onSubmitFinished(reportObject.id);
    }

    return (
        <div>
            <Modal
                title={`${t("analytics_TitleSettingsTitle1")} ${
                    reportObject && reportObject.title
                        ? reportObject.title
                        : t("analyics_FiltrationTitle1")
                }`}
                closable={true}
                onOk={onSubmit}
                width={900}
                confirmLoading={loading}
                open={visible}
                mask={false}
                onCancel={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal();
                }}
                footer={[
                    <Button loading={loading} type="primary" onClick={async () => await onSubmit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}>
                    <Form.Item
                        label={t("analytics_TitleSettingsReportLabel")}
                        name="reportTitle"
                        valuePropName="value"
                        initialValue={reportObject && reportObject.title}>
                        <Input
                            maxLength={100}
                            placeholder={t("analytics_TitleSettingsReportLabelPlaceholder")}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_TitleSettingsDescrtiptionLabel")}
                        name="reportDescription"
                        valuePropName="value"
                        initialValue={reportObject && reportObject.description}>
                        <TextArea
                            maxLength={256}
                            placeholder={t("analytics_TitleSettingsDescrtiptionLabelPlaceHolder")}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default EditReportContainerModal;
