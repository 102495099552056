import { ITableInput } from "models";
import { post, get } from "../base";

const controller = `/v1/scorecard`;
const Scorecard = {
    getScorecardOverview: async (
        tableInput: ITableInput,
        partnerId: number,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `${controller}/tableoverview?partnerId=${[partnerId]}`;
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },

    getAwardStatus: async (
        partnerId: number,

        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `${controller}/GetAwardStatus?partnerId=${partnerId}`;
        let response = await get({
            url,

            customError: customError ? customError : null,
        });
        return response;
    },
    getAreaStatus: async (
        partnerId: number,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occurred" },
        }
    ) => {
        let url = `${controller}/GetAreaStatus?partnerId=${partnerId}`;
        let response = await get({
            url,
            customError: customError ? customError : null,
        });
        if (!Array.isArray(response)) {
            return [];
        }
        return response;
    },
    getMetric: async (
        metricId: number,
        partnerId: number,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occurred" },
        }
    ) => {
        let url = `${controller}/metricId?metricId=${metricId}&partnerId=${partnerId}`;
        return await get({ url, customError: customError ? customError : null });
    },
};

export { Scorecard };
