import { uniqueId } from "lodash";
import { ITableInput } from "models";
import { useEffect, useState } from "react";
import Provider from "services/api";

type StateType = {
    loading: boolean;
    data: any[];
    totalRows: number;
    customFields: any;
};

const useScorecardSettingsOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
        totalRows: 0,
        customFields: undefined,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
            customFields: state.customFields,
        });
        (async () => {
            await getData();
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range]);

    const updateData = (newData: any) => {
        setState({ ...state, data: newData });
    };

    const updateRowOrder = async (oldOrder: number, newOrder: number, id: number) => {
        await Provider.Scorecard.orderScorecardArea({ scoreAreaId: id, order: newOrder }).catch(
            (err: any) => {
                return Promise.reject(err);
            }
        );
        setState({
            ...state,
            loading: true,
        });
        await getData();
    };

    const getData = async () => {
        (async () => {
            if (!externalLoading) {
                const response = await Provider.Scorecard.getScorecardSettingsOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters,
                        sorts,
                        to: range.to,
                        from: range.from,
                    },
                    {
                        customError: {
                            message: "Could not retrieve scorecard settings",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                const data = response as { data: ITableInput[]; length: number };
                let scorecardSettings = data.data.map((x: any) => ({ ...x, key: uniqueId() }));
                setState({
                    loading: false,
                    data: scorecardSettings,
                    totalRows: scorecardSettings.length,
                    customFields: { areaCount: scorecardSettings.length },
                });
            }
        })();
    };

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        customFields: state.customFields,
        updateData,
        updateRowOrder,
    };
};

export { useScorecardSettingsOverview };
