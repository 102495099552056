import { IProduct } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IProduct | undefined;
};

const useProduct = (id: number | undefined, partnerId: number | undefined, reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            if (id === undefined || id === 0) {
                setState({ loading: false, data: undefined });
                return;
            }
            setState({ ...state, loading: true });
            let data = await Provider.Product.getProductById(id, partnerId, {
                customError: {
                    message: "Could not retrieve product",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [id, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useProduct;
