import { useState } from "react";
import { Provider } from "services";

const useUserSettingUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateUserSettings = async (userId: number, payload: any) => {
        setLoading(true);
        const id = await Provider.User.updateUserSettings(userId, payload, {
            customError: {
                message: "Could not update user settings",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        updateUserSettings,
    };
};

export { useUserSettingUpdate };
