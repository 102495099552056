import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Radio, Spin, Button, Input } from "antd";
import "./styles/filterDropDown.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: any;
    clearFilters: any;
    useData: Function;
    dataFields: { value: string[]; text: string[] };
    dataIndex: string;
};

const RadioDropDown: FunctionComponent<PropsType> = ({
    useData,
    dataFields,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex,
}) => {
    const { t } = useTranslation();
    const { loading, data } = useData();
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setFilteredData(data);
    }, [loading]);

    const handleSearch = (e: any) => {
        const value = e.target.value;
        setSearch(value);
        setFilteredData(
            data.filter(
                (d: any) =>
                    dataFields.text
                        .reduce((acc, column) => `${acc}${d[column]} `, "")
                        .toLocaleLowerCase()
                        .indexOf(value.toLocaleLowerCase()) !== -1
            )
        );
    };

    return (
        <Fragment>
            <div className="filterDropDownContainer">
                <Spin spinning={loading}>
                    {data.length > 20 && (
                        <div className="filterSearchInput">
                            <Input
                                data-test={`search_${dataIndex}_input`}
                                placeholder={t("search") + "..."}
                                value={search}
                                onChange={handleSearch}
                                allowClear={true}
                            />
                        </div>
                    )}
                    <Radio.Group className="checkBoxGroup" value={selectedKeys[0]}>
                        {filteredData.map((row: any, index: any) => {
                            return (
                                <Radio
                                    data-test={`filter_dropdown_value_${dataFields.text
                                        .reduce((acc, column) => `${acc}${row[column]} `, "")
                                        .split(" ")
                                        .join("")
                                        .trim()
                                        .toLowerCase()}`}
                                    className="filterCheckBox"
                                    key={index}
                                    value={dataFields.value
                                        .reduce((acc, column) => `${acc}${row[column]} `, "")
                                        .trim()}
                                    onChange={e =>
                                        setSelectedKeys(
                                            e.target.checked
                                                ? [e.target.value]
                                                : selectedKeys.filter(
                                                      (x: any) => x !== e.target.value
                                                  )
                                        )
                                    }>
                                    {dataFields.text.reduce(
                                        (acc, column) => `${acc}${row[column]} `,
                                        ""
                                    )}
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                </Spin>
            </div>
            <div className="filterDropDownAction">
                <Button
                    data-test={`search_${dataIndex}_button`}
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("grid_Filter")}
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    {t("grid_Reset")}
                </Button>
            </div>
        </Fragment>
    );
};

export default RadioDropDown;
