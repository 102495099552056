import { useEffect, useState } from "react";
import Provider from "services/api";

const useFolderExternalPartners = () => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Partner.getExternalPartners(undefined, true, undefined).catch(
                err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                }
            );
            data.map((item: any) => {
                if (item.children) {
                    item.children = item.children.filter((child: any) => child.isLinked === true);
                }
                return item;
            });

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useFolderExternalPartners };
