import React, { FunctionComponent, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import GridLayout from "react-grid-layout";
import { Row, Col, Button, Spin, Empty } from "antd";
import classes from "./styles/dashboard.module.scss";
import { FileDownload03, getViewPort, PageContainer } from "components/utilitycomponents";
import { useGuestDashBoard } from "controller";
import { ChartContainer } from "./reports/reportContainer/reportContainer";
import "./styles/dashboard.css";
import { Margin, usePDF } from "react-to-pdf";

const GuestDashBoard: FunctionComponent = () => {
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [viewPort, setViewPort] = useState(getViewPort());
    const { data, loading } = useGuestDashBoard(guestToken);
    const contentSectionElement = document.getElementById("contentSection");
    const centerDivHeight = contentSectionElement ? contentSectionElement!.offsetHeight - 250 : 0;
    const { toPDF, targetRef } = usePDF({
        filename: `partnerAnalytics.pdf`,
        page: { margin: Margin.MEDIUM },
    });
    const [exporting, setExporting] = useState(false);

    useLayoutEffect(() => {
        const updateSize = () => {
            setViewPort(getViewPort());
        };
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    });

    return (
        <div ref={targetRef}>
            <PageContainer
                fluid
                title={
                    data && data.data
                        ? t("analytics_DashboardSharedWith", { name: data.data.partnerName })
                        : " "
                }>
                <Row className={classes.actionRow}>
                    <Col span={12}></Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                            icon={
                                <Spin size="small" spinning={exporting}>
                                    <FileDownload03 />
                                </Spin>
                            }
                            className={classes.button}
                            disabled={loading}
                            onClick={async () => {
                                setExporting(true);
                                await toPDF();
                                setExporting(false);
                            }}>
                            {t("exportBtnText")}
                        </Button>
                    </Col>
                </Row>
                <div>
                    <Spin spinning={loading}>
                        {data &&
                        data.data &&
                        data.data.preset &&
                        data.data.preset.boxes.length > 0 ? (
                            <GridLayout
                                className={classes.gridLayout}
                                onLayoutChange={() => {}}
                                layout={data && data.layout}
                                cols={24}
                                rowHeight={30}
                                width={viewPort - 109}>
                                {data &&
                                    data.data.preset.boxes.map((box: any, index: number) => {
                                        return (
                                            <div
                                                className={classes.gridItem}
                                                id={box.i}
                                                key={box.i}>
                                                <ChartContainer
                                                    partnerId={data.data.partnerId}
                                                    benchmarkId={data.data.benchmarkId}
                                                    parentId={box.i}
                                                    onEditChartModalClick={() => {}}
                                                    onFilterModalClick={() => {}}
                                                    reportId={box.report.id}
                                                    reportObject={box.report}
                                                    reloadReport={{
                                                        reload: false,
                                                        id: box.report.id,
                                                    }}
                                                    reloadDashboard={() => {}}
                                                    editable={false}
                                                    columnNames={{
                                                        subLevelName:
                                                            data.data && data.data.subLevelName,
                                                        targetName:
                                                            data.data && data.data.targetName,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                            </GridLayout>
                        ) : (
                            <div
                                style={{
                                    height: centerDivHeight,
                                }}>
                                <Empty
                                    className={classes.centerAddContainer}
                                    description={
                                        loading
                                            ? ""
                                            : t(
                                                  "analytics_NoGuestDashboard",
                                                  "No Dashboard To Display"
                                              )
                                    }
                                />
                            </div>
                        )}
                    </Spin>
                </div>
            </PageContainer>
        </div>
    );
};

export default GuestDashBoard;
