import React, { forwardRef, useState, useEffect } from "react";
import { Tree, Spin, Popover, Button } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type PropsType = {
    value?: string;
    onChange?: (value: string | string[], item?: any) => void;
    useData: Function;
    selectedKey?: any;
    [key: string]: any;
};

const DashboardTreeSelect = forwardRef(
    ({ value, onChange, useData, selectedKey, ...rest }: PropsType, ref) => {
        const { presetId: paramDashboardId }: any = useParams();
        const { TreeNode } = Tree;
        const { data, loading } = useData();
        const personalDashboards: any[] = data.filter((x: any) => x.visibility === 0);
        const internalDashboards: any[] = data.filter((x: any) => x.visibility === 1);
        const externalDashboards: any[] = data.filter(
            (x: any) => x.visibility === 2 && x.editable === true
        );
        const [expandedKeys, setExpandedKeys] = useState<string[]>(getDefaultExpandedKeys());
        const [selectedKeys, setSelectedKeys] = useState(
            paramDashboardId ? [paramDashboardId] : undefined
        );

        const { t } = useTranslation();

        useEffect(() => {
            setExpandedKeys(getDefaultExpandedKeys());
            setSelectedKeys([paramDashboardId]);
        }, [paramDashboardId, loading]);

        function getDefaultExpandedKeys() {
            if (
                personalDashboards.filter((x: any) => x.id.toString() === paramDashboardId).length >
                0
            ) {
                return ["0-1"];
            } else if (
                internalDashboards.filter((x: any) => x.id.toString() === paramDashboardId).length >
                0
            ) {
                return ["0-2"];
            } else if (
                externalDashboards.filter((x: any) => x.id.toString() === paramDashboardId).length >
                0
            ) {
                return ["0-3"];
            } else {
                return [];
            }
        }

        function onExpand(expandedKeys: any) {
            setExpandedKeys(expandedKeys);
        }

        function onSelect(selectedKeys: any, { node }: any) {
            if (node.props.isLeaf) {
                setSelectedKeys(selectedKeys);
                onChange &&
                    onChange(
                        selectedKeys,
                        data.filter((x: any) => x.id === parseInt(selectedKeys))[0]
                    );
            } else {
                setExpandedKeys(
                    node.props.expanded
                        ? expandedKeys!.filter((k: any) => k !== node.props.eventKey)
                        : expandedKeys!.concat(node.props.eventKey)
                );
            }
        }

        function sortDashboard(a: any, b: any) {
            if (a && b) {
                if (a.name > b.name) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                return 1;
            }
        }

        function renderTreeNodes() {
            let treeNodes: any[] = [
                [
                    <TreeNode
                        title={`${t("analytics_Personal")} (${personalDashboards.length})`}
                        key="0-1"
                        isLeaf={false}>
                        {personalDashboards
                            .sort((a: any, b: any) => sortDashboard(a, b))
                            .map((x: any, index) => (
                                <TreeNode
                                    selectable={
                                        paramDashboardId &&
                                        x.id.toString() !== paramDashboardId.toString()
                                    }
                                    title={x.name}
                                    isLeaf
                                    key={`${x.id}`}
                                />
                            ))}
                    </TreeNode>,
                ],
                [
                    <TreeNode
                        title={`${t("analytics_Internal")} (${internalDashboards.length})`}
                        key="0-2"
                        isLeaf={false}>
                        {internalDashboards
                            .sort((a: any, b: any) => sortDashboard(a, b))
                            .map((x: any, index) => (
                                <TreeNode
                                    selectable={
                                        paramDashboardId &&
                                        x.id.toString() !== paramDashboardId.toString()
                                    }
                                    title={x.name}
                                    isLeaf
                                    key={`${x.id}`}
                                />
                            ))}
                    </TreeNode>,
                ],
                [
                    <TreeNode
                        title={`${t("analytics_External")} (${externalDashboards.length})`}
                        key="0-3"
                        isLeaf={false}>
                        {externalDashboards
                            .sort((a: any, b: any) => sortDashboard(a, b))
                            .map((x: any, index) => (
                                <TreeNode
                                    selectable={
                                        paramDashboardId &&
                                        x.id.toString() !== paramDashboardId.toString()
                                    }
                                    title={x.name}
                                    isLeaf
                                    key={`${x.id}`}
                                />
                            ))}
                    </TreeNode>,
                ],
            ];
            return treeNodes;
        }

        const content = (
            <Spin spinning={loading}>
                <Tree
                    expandedKeys={expandedKeys}
                    selectedKeys={selectedKeys}
                    {...rest}
                    onSelect={(selectedKeys: any, info: any) => {
                        onSelect(selectedKeys, info);
                    }}
                    onExpand={(expandedKeys: any) => onExpand(expandedKeys)}>
                    {renderTreeNodes()}
                </Tree>
            </Spin>
        );

        return (
            <Popover
                placement="bottom"
                title={t("analyics_Dashboards")}
                trigger="hover"
                content={content}>
                <Button>{t("analyics_Dashboards")}</Button>
            </Popover>
        );
    }
);

export { DashboardTreeSelect };
