import React, { forwardRef } from "react";
import { TreeSelect, Spin, Empty } from "antd";
import classnames from "classnames";
import "./selectTreeData.scss";
import { useTranslation } from "react-i18next";
const { TreeNode } = TreeSelect;

type PropsType = {
    value?: any[];
    selectIdField?: string;
    selectOptionField: string | Function;
    selectAll?: boolean;
    selectOnlyChildren?: boolean;
    onChange?: (value: any[], item?: any) => void;
    useData: Function;
    multiple: boolean;
    [key: string]: any;
};
//forwardRef is used for the component to be able to be used as a FormItem child direclty
const SelectTreeDataMultiple = forwardRef(
    (
        {
            value,
            selectIdField = "id",
            selectOptionField,
            selectOnlyChildren = false,
            onChange,
            useData,
            selectAll = false,
            multiple = true,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();
        const { data, loading } = useData();

        const allValues: any[] = [];
        for (let i = 0; i < data.length; i++) {
            if (!selectOnlyChildren || data[i].children === null) {
                if (typeof selectOptionField !== "function") {
                    allValues.push(data[i][selectIdField]);
                } else {
                    let item = selectOptionField(data[i]);
                    if (item.selectable === undefined || item.selectable === true) {
                        allValues.push(data[i][selectIdField]);
                    }
                }
            }
            if (data[i].children) {
                for (let j = 0; j < data[i].children.length; j++) {
                    if (typeof selectOptionField !== "function") {
                        allValues.push(data[i].children[j][selectIdField]);
                    } else {
                        let item = selectOptionField(data[i].children[j]);
                        if (item.selectable === undefined || item.selectable === true) {
                            allValues.push(data[i].children[j][selectIdField]);
                        }
                    }
                }
            }
        }

        const handleChange = (values: any[], elements: any[]) => {
            if (!onChange) return;

            if (values && values.length && values.includes("-1")) {
                if (values.length === allValues.length + 1) {
                    onChange([], []);
                } else {
                    onChange([...allValues], data);
                }
            } else {
                onChange(values, elements);
            }
        };

        const SELECT_DESELECT_ALL = `--${t("selectAllColumns")}--`;
        return (
            <TreeSelect
                value={value && value.some(v => allValues.includes(v)) ? value : []}
                onChange={(values: string[]) => {
                    let selectedItems: any = [];
                    let newValues: string[] = [];
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        if (selectOnlyChildren) {
                            let childrenRemoved = false;
                            if (values.includes(item[selectIdField])) {
                                if (!item.children) {
                                    selectedItems = [...selectedItems, item];
                                    newValues = [...newValues, item[selectIdField]];
                                } else {
                                    if (!values.includes(item.children[0][selectIdField])) {
                                        for (let j = 0; j < item.children.length; j++) {
                                            const childItem = item.children[j];
                                            if (!childItem.disabled) {
                                                selectedItems = [...selectedItems, childItem];
                                                newValues = [
                                                    ...newValues,
                                                    childItem[selectIdField],
                                                ];
                                            }
                                        }
                                    } else {
                                        childrenRemoved = true;
                                    }
                                }
                            }
                            if (item.children && !childrenRemoved) {
                                for (let j = 0; j < item.children.length; j++) {
                                    const childItem = item.children[j];
                                    if (values.includes(childItem[selectIdField])) {
                                        selectedItems = [...selectedItems, childItem];
                                        newValues = [...newValues, childItem[selectIdField]];
                                    }
                                }
                            }
                        } else {
                            if (values.includes(item[selectIdField])) {
                                selectedItems = [...selectedItems, item];
                                newValues = [...newValues, item[selectIdField]];
                            }
                            if (item.children) {
                                for (let j = 0; j < item.children.length; j++) {
                                    const childItem = item.children[j];
                                    if (values.includes(childItem[selectIdField])) {
                                        selectedItems = [...selectedItems, childItem];
                                        newValues = [...newValues, childItem[selectIdField]];
                                    }
                                }
                            }
                        }
                    }
                    if (values.includes("-1")) {
                        newValues = [...newValues, "-1"];
                    }

                    handleChange(newValues, selectedItems);
                }}
                treeDefaultExpandedKeys={data
                    .filter(
                        (item: any) =>
                            value &&
                            item.children &&
                            item.children.some((x: any) => value.includes(x[selectIdField]))
                    )
                    .map((item: any) => item[selectIdField].toString())}
                showSearch
                filterTreeNode={(inputValue: any, treeNode: any) => {
                    if (!treeNode) return false;
                    if (
                        treeNode["treeNodeFilterProp"] &&
                        treeNode["treeNodeFilterProp"]
                            .toLocaleLowerCase()
                            .includes(inputValue.toLocaleLowerCase())
                    ) {
                        return true;
                    }
                    if (
                        treeNode.children &&
                        treeNode.children.some((t: any) =>
                            t["treeNodeFilterProp"]
                                .toLocaleLowerCase()
                                .includes(inputValue.toLocaleLowerCase())
                        )
                    ) {
                        return true;
                    }
                    return false;
                }}
                notFoundContent={
                    loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                popupClassName={classnames("dropdownWrapper", rest.dropdownClassName)}
                loading={loading}
                multiple={multiple}
                {...rest}>
                {selectAll && data.length > 0 && (
                    <TreeNode
                        value={-1}
                        key={-1}
                        data-test="selectAll"
                        title={SELECT_DESELECT_ALL}
                    />
                )}
                {data &&
                    data.map((item: any) => (
                        <TreeNode
                            key={
                                selectOnlyChildren && item.children
                                    ? `group-${item[selectIdField]}`
                                    : item[selectIdField]
                            }
                            data-test={
                                typeof selectOptionField === "function"
                                    ? selectOptionField(item).text.replace(/\s/g, "")
                                    : item[selectOptionField].replace(/\s/g, "")
                            }
                            className={data.children ? "" : "nonParentTreeNode"}
                            value={item[selectIdField]}
                            style={{
                                marginLeft: item.children ? 0 : -18,
                            }}
                            title={
                                typeof selectOptionField === "function"
                                    ? selectOptionField(item).node
                                    : item[selectOptionField]
                            }
                            treeNodeFilterProp={
                                typeof selectOptionField === "function"
                                    ? selectOptionField(item).text
                                    : item[selectOptionField]
                            }
                            selectable={
                                typeof selectOptionField === "function" &&
                                selectOptionField(item).selectable !== undefined
                                    ? selectOptionField(item).selectable
                                    : true
                            }
                            disabled={
                                typeof selectOptionField === "function" &&
                                selectOptionField(item).disabled !== undefined
                                    ? selectOptionField(item).disabled
                                    : false
                            }>
                            {item.children &&
                                item.children.map((child: any) => (
                                    <TreeNode
                                        key={
                                            selectOnlyChildren
                                                ? `group-${item[selectIdField]}-child-${child[selectIdField]}`
                                                : child[selectIdField]
                                        }
                                        data-test={
                                            typeof selectOptionField === "function"
                                                ? selectOptionField(child).text.replace(/\s/g, "")
                                                : child[selectOptionField].replace(/\s/g, "")
                                        }
                                        value={child[selectIdField]}
                                        title={
                                            typeof selectOptionField === "function"
                                                ? selectOptionField(child).node
                                                : child[selectOptionField]
                                        }
                                        treeNodeFilterProp={
                                            typeof selectOptionField === "function"
                                                ? selectOptionField(child).text
                                                : child[selectOptionField]
                                        }
                                        selectable={
                                            typeof selectOptionField === "function" &&
                                            selectOptionField(child).selectable !== undefined
                                                ? selectOptionField(child).selectable
                                                : true
                                        }
                                        disabled={
                                            typeof selectOptionField === "function" &&
                                            selectOptionField(child).disabled !== undefined
                                                ? selectOptionField(child).disabled
                                                : false
                                        }>
                                        {child.children &&
                                            child.children.map((child: any) => (
                                                <TreeNode
                                                    key={
                                                        selectOnlyChildren
                                                            ? `group-${item[selectIdField]}-child-${child[selectIdField]}`
                                                            : child[selectIdField]
                                                    }
                                                    data-test={
                                                        typeof selectOptionField === "function"
                                                            ? selectOptionField(child).text.replace(
                                                                  /\s/g,
                                                                  ""
                                                              )
                                                            : child[selectOptionField].replace(
                                                                  /\s/g,
                                                                  ""
                                                              )
                                                    }
                                                    value={child[selectIdField]}
                                                    title={
                                                        typeof selectOptionField === "function"
                                                            ? selectOptionField(child).node
                                                            : child[selectOptionField]
                                                    }
                                                    treeNodeFilterProp={
                                                        typeof selectOptionField === "function"
                                                            ? selectOptionField(child).text
                                                            : child[selectOptionField]
                                                    }
                                                    selectable={
                                                        typeof selectOptionField === "function" &&
                                                        selectOptionField(child).selectable !==
                                                            undefined
                                                            ? selectOptionField(child).selectable
                                                            : true
                                                    }
                                                    disabled={
                                                        typeof selectOptionField === "function" &&
                                                        selectOptionField(child).disabled !==
                                                            undefined
                                                            ? selectOptionField(child).disabled
                                                            : false
                                                    }></TreeNode>
                                            ))}
                                    </TreeNode>
                                ))}
                        </TreeNode>
                    ))}
            </TreeSelect>
        );
    }
);

export { SelectTreeDataMultiple };
