import useOwlerCompanyInfo from "controller/owler/useOwlerCompanyInfo";
import React, { useEffect, useState } from "react";
import classes from "./owlerStyle.module.scss";
import {
    Card,
    Flex,
    Tooltip,
    Modal,
    Row,
    Col,
    Image,
    Skeleton,
    Tag,
    Collapse,
    Typography,
} from "antd";
import { OwlerDomainInput } from "./owlerDomainInput";
import {
    FacebookOutlined,
    IdcardOutlined,
    LinkedinOutlined,
    TwitterOutlined,
    YoutubeOutlined,
} from "@ant-design/icons";
import { Expand01, MultipleTags, User01 } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { OwlerCardFooter } from "./owlerCardFooter";
const { Panel } = Collapse;

interface IProps {
    domain: string | undefined;
    loadingDomain: boolean;
    onDomainSet?: Function;
    reloadDatasource?: any;
}

const OwlerCompanyInfoContainer = ({
    domain,
    loadingDomain,
    onDomainSet,
    reloadDatasource,
}: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const [controlledDomain, setControlledDomain] = useState<string | undefined>(domain);
    const { loading, data } = useOwlerCompanyInfo(controlledDomain, reload);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setControlledDomain(domain);
    }, [domain]);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadDatasource]);

    const onDomainClick = (domain?: string) => {
        if (domain) {
            if (domain.includes("https://")) {
                window.open(domain, "_blank");
            } else {
                window.open("https://" + domain, "_blank");
            }
        }
    };

    const baseInfo = (logoPosition: "right" | "none") => {
        if (data === undefined || data === null) return null;
        let ellipsisThreshold = 40;
        if (width && width < 1712) {
            ellipsisThreshold = 20;
        }
        let hideLogo = logoPosition === "right" && width! >= 1190 && width! <= 1325;
        return (
            <>
                <Flex justify="space-between">
                    <div>
                        <div className={classes.companyInfoSubTitle}>{t("owler_Industry")}</div>

                        <div className={classes.companyInfoText}>
                            {data.sectors && (
                                <MultipleTags
                                    tooltipValueField="parentIndustry"
                                    ellipsisThreshold={ellipsisThreshold}
                                    bordered={false}
                                    tagNumber={3}
                                    values={data.sectors.map(sector => ({
                                        id: sector.name,
                                        name: sector.name,
                                        parentIndustry: sector.parentIndustry,
                                    }))}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        {logoPosition === "right" && data.logoUrl && !hideLogo && (
                            <Image width={"5em"} src={data.logoUrl} />
                        )}
                    </div>
                </Flex>
                <div className={classes.companyInfoSubTitle}>{t("owler_HQ")}</div>
                <div className={classes.companyInfoText}>
                    <div>{data.hqAddress && data.hqAddress.street1}</div>
                    <div>
                        {data.hqAddress && data.hqAddress.city}{" "}
                        {data.hqAddress && data.hqAddress.country}
                    </div>
                </div>
                <div className={classes.companyInfoSubTitle}>{t("owler_Employees")}</div>
                <div className={classes.companyInfoText}>
                    {data.employeeCount && data.employeeCount.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </div>
                <div className={classes.companyInfoSubTitle}>{t("owler_Revenue")}</div>
                {data.revenue && (
                    <div className={classes.companyInfoText}>
                        <span style={{ fontWeight: "bold" }}>$</span>
                        {data.revenue
                            ? `${(Number.parseFloat(data.revenue) / 1000000)
                                  .toPrecision(6)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} MM`
                            : "N/A"}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <Card
                className={classes.owlerCard}
                bodyStyle={{ overflow: "hidden", height: 254 }}
                size="small"
                bordered={false}
                title={
                    <Flex justify="space-between">
                        <div className={classes.cardTitle}>
                            {t("owler_FirmographicInfo").toLocaleUpperCase()}
                        </div>
                        <Tooltip title={t("owler_FirmographicInfoAdditionalInfo")}>
                            <span>
                                <Expand01
                                    id="owlerCompanyInfoExpandIcon"
                                    onClick={() => domain && data && setShowModal(true)}
                                    className={classes.expandIcon}
                                />
                            </span>
                        </Tooltip>
                    </Flex>
                }>
                <div>
                    {!loading && domain && data && (
                        <div>
                            {baseInfo("right")}
                            <OwlerCardFooter modifiedValue={data && data.infoModified} />
                        </div>
                    )}
                    <Skeleton
                        active={loadingDomain === true || loading === true}
                        loading={loadingDomain === true || loading === true}
                    />
                    {!loadingDomain &&
                        !loading &&
                        (domain === undefined ||
                            domain === null ||
                            (domain !== undefined && domain !== null && !data)) && (
                            <Flex justify="center" className={classes.domainInputContainer}>
                                <Flex vertical justify="center">
                                    <OwlerDomainInput
                                        domain={domain}
                                        data={data}
                                        onDomainChange={(setDomain: string | undefined) => {
                                            if (setDomain) {
                                                setReload({ reload: true });
                                                onDomainSet && onDomainSet(setDomain);
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        )}
                </div>
            </Card>
            <Modal
                destroyOnClose
                width={800}
                open={showModal}
                title={t("owler_FirmographicInfoDetails")}
                footer={
                    data && (
                        <Row>
                            <Col span={24}>
                                <Flex justify="space-between">
                                    {data.twitterLink && (
                                        <Tag
                                            className={classes.socialMediaIcon}
                                            icon={<TwitterOutlined />}
                                            color="#55acee"
                                            onClick={() => window.open(data.twitterLink, "_blank")}>
                                            {t("account_Twitter")}
                                        </Tag>
                                    )}
                                    {data.youtubeLink && (
                                        <Tag
                                            className={classes.socialMediaIcon}
                                            icon={<YoutubeOutlined />}
                                            color="#cd201f"
                                            onClick={() => window.open(data.youtubeLink, "_blank")}>
                                            {t("account_Youtube")}
                                        </Tag>
                                    )}
                                    {data.facebookLink && (
                                        <Tag
                                            className={classes.socialMediaIcon}
                                            icon={<FacebookOutlined />}
                                            color="#3b5999"
                                            onClick={() =>
                                                window.open(data.facebookLink, "_blank")
                                            }>
                                            {t("account_Facebook")}
                                        </Tag>
                                    )}
                                    {data.linkedinLink && (
                                        <Tag
                                            className={classes.socialMediaIcon}
                                            icon={<LinkedinOutlined />}
                                            color="#55acee"
                                            onClick={() =>
                                                window.open(data.linkedinLink, "_blank")
                                            }>
                                            {t("account_LinkedIn")}
                                        </Tag>
                                    )}
                                    {data.permId && (
                                        <Tag
                                            className={classes.socialMediaIcon}
                                            icon={<IdcardOutlined />}
                                            color="#bfbfbf"
                                            onClick={() => window.open(data.permId, "_blank")}>
                                            {t("account_PermId")}
                                        </Tag>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                    )
                }
                onCancel={() => setShowModal(false)}>
                {domain && data && (
                    <>
                        <Row gutter={8}>
                            <Col span={12}>
                                {baseInfo("none")}
                                <div className={classes.companyInfoSubTitle}>
                                    {t("owler_Stock")}
                                </div>
                                {data.stock && (
                                    <div className={classes.companyInfoText}>
                                        <Tooltip title={t("owler_Exchange")}>
                                            {data.stock.exchange}
                                        </Tooltip>{" "}
                                        |{" "}
                                        <Tooltip title={t("owler_Tick")}>
                                            {data.stock.ticker}
                                        </Tooltip>
                                    </div>
                                )}
                            </Col>
                            <Col span={12}>
                                <div className={classes.companyInfoSubTitle}>{t("owler_Name")}</div>
                                <div className={classes.companyInfoText}>{data.name}</div>
                                <div className={classes.companyInfoSubTitle}>
                                    {t("owler_Status")}
                                </div>
                                <div className={classes.companyInfoText}>{data.status}</div>
                                <div className={classes.companyInfoSubTitle}>
                                    {t("owler_Description")}
                                </div>
                                <div className={classes.companyInfoText}>
                                    <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}>
                                        {data.description}
                                    </Typography.Paragraph>
                                </div>
                                <div className={classes.companyInfoSubTitle}>{t("owler_Ceo")}</div>
                                {data.ceo && (
                                    <div className={classes.companyInfoText}>
                                        {`${data.ceo.firstName} ${data.ceo.lastName}`}{" "}
                                        <Tooltip
                                            title={
                                                <Image
                                                    width={75}
                                                    src={data.ceo && data.ceo.imageUrl}
                                                />
                                            }>
                                            <span>
                                                <User01 />
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}

                                <div className={classes.companyInfoSubTitle}>
                                    {t("owler_Founded")}
                                </div>
                                <div className={classes.companyInfoText}>{data.foundedDate}</div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 8 }}>
                            <Col span={24}>
                                <Collapse bordered={false} size="small">
                                    <Panel
                                        key="1"
                                        header={
                                            <div className={classes.companyInfoSubTitle}>
                                                {t("owler_Acquisitions")}
                                            </div>
                                        }>
                                        <div style={{ maxHeight: 300, overflowY: "auto" }}>
                                            <div className={classes.companyInfoText}>
                                                {data.acquisition &&
                                                    data.acquisition.map((acquisition, index) => (
                                                        <div key={index}>
                                                            <div
                                                                className={
                                                                    acquisition.website
                                                                        ? classes.linkStyle
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    onDomainClick(
                                                                        acquisition.website
                                                                    )
                                                                }>
                                                                {acquisition.name}
                                                            </div>

                                                            <div>
                                                                {t("owler_Amount")} -{" "}
                                                                <span
                                                                    style={{ fontWeight: "bold" }}>
                                                                    $
                                                                </span>
                                                                {acquisition.amount &&
                                                                acquisition.amount.trim() !== ""
                                                                    ? `${(
                                                                          Number.parseFloat(
                                                                              acquisition.amount
                                                                          ) / 1000000
                                                                      )
                                                                          .toPrecision(5)
                                                                          .toString()
                                                                          .replace(
                                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                                              "."
                                                                          )} MM`
                                                                    : "N/A"}
                                                            </div>

                                                            <div>
                                                                {acquisition.date &&
                                                                    acquisition.date.split(" ")[0]}
                                                            </div>
                                                            <div>{acquisition.status}</div>
                                                            <div>
                                                                {t("owler_Undisclosed")} -{" "}
                                                                {acquisition.undisclosed}
                                                            </div>
                                                            {index !==
                                                                data.acquisition.length - 1 && (
                                                                <hr />
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal>
        </>
    );
};

export { OwlerCompanyInfoContainer };
