import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IInvitationAcceptedDetail } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IInvitationAcceptedDetail;
};

const InvitationAccepted: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {t("congratulation")} {data.invitedPartner} {t("activity_InvitationAccept")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.invitedPartner} />
            </div>
        </span>
    );
};

export { InvitationAccepted };
