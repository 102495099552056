import { useState } from "react";
import { Provider } from "services";

const useComplianceUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (complianceUpdateInput: any) => {
        setLoading(true);
        const id = await Provider.Compliance.updateRegistration(complianceUpdateInput, {
            customError: {
                message: "Could not update the registration",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        update,
    };
};

export { useComplianceUpdate };
