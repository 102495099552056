import IColumnSetting from "models/listview/IColumnSetting";

const getHiddenColumns = (viewColumnSettings: IColumnSetting[], columns: any[]) => {
    let hiddenColumns: any[] = [];
    if (viewColumnSettings && viewColumnSettings.length > 0) {
        for (let view of viewColumnSettings) {
            if (view.isHidden === true) {
                for (let i = 0; i < columns.length; i++) {
                    if (view.viewColumnId === columns[i]["viewColumnId"]) {
                        hiddenColumns.push(columns[i]["dataIndex"]);
                        break;
                    }
                }
            }
        }
    }
    return hiddenColumns;
};
export { getHiddenColumns };
