import { useEffect, useState } from "react";
import Provider from "services/api";
type State = {
    loading: boolean;
    data: any;
};
const useMetricIdTable = (partnerId: number, metricId: number, reload: any) => {
    const [state, setState] = useState<State>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Scorecard.getMetric(partnerId, metricId).catch((err: any) => {
                setState({ ...state, loading: false });
                return [];
            });

            setState({ loading: false, data });
        })();
    }, [metricId, partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useMetricIdTable;
