export const departments = [
    {
        value: 1,
        label: "Procurement",
    },
    {
        value: 2,
        label: "Quality",
    },
    {
        value: 3,
        label: "Sales",
    },
    {
        value: 4,
        label: "Marketing",
    },
    {
        value: 5,
        label: "HR",
    },
    {
        value: 6,
        label: "Legal",
    },
    {
        value: 7,
        label: "Finance/Accounting",
    },
    {
        value: 8,
        label: "Product",
    },
    {
        value: 9,
        label: "Logistics / Operations",
    },
    {
        value: 10,
        label: "Production / Manufacturing",
    },
    {
        value: 11,
        label: "Innovation / R&D",
    },
    {
        value: 12,
        label: "IT / Tech",
    },
    {
        value: 13,
        label: "Executive",
    },
    {
        value: 14,
        label: "Other",
    },
];
