import { IGetAreaStatus } from "models/scorecard";
import { useEffect, useState } from "react";
import Provider from "services/api";

type State = {
    loading: boolean;
    data: IGetAreaStatus[];
};

const useAreaStatus = (partnerId: number) => {
    const [state, setState] = useState<State>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Scorecard.getAreaStatus(partnerId).catch((err: any) => {
                setState({ ...state, loading: false });
                return [];
            });
            if (!Array.isArray(data)) {
                data = [];
            }
            setState({ loading: false, data });
        })();
    }, [partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useAreaStatus;
