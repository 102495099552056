import { useState } from "react";
import { Provider } from "services";

const useSupplierCardsAlternateSupplierUpdate = () => {
    const [loading, setLoading] = useState(false);

    const createAlternateSupplier = async (values: any) => {
        setLoading(true);
        await Provider.Partner.createAlternateSupplier(values, {
            customError: {
                message: "Could not create alternate supplier",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updateAlternateSupplier = async (values: any) => {
        setLoading(true);
        await Provider.Partner.updateAlternateSupplier(values, {
            customError: {
                message: "Could not update alternate supplier",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const deleteAlternateSupplier = async (ids: number[]) => {
        setLoading(true);
        await Provider.Partner.deleteAlternateSupplier(ids, {
            customError: {
                message: "Could not delete alternate supplier",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        createAlternateSupplier,
        updateAlternateSupplier,
        deleteAlternateSupplier,
    };
};

export { useSupplierCardsAlternateSupplierUpdate };
