import React, { useState, useEffect, FunctionComponent } from "react";
import {
    Form,
    Modal,
    Divider,
    Input,
    Button,
    Popconfirm,
    notification,
    Row,
    Col,
    Tooltip,
    DatePicker,
} from "antd";
import {
    AttachFile,
    InfoCircle,
    SelectData,
    SelectTreeData,
    XCircle,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import {
    usePartnerList,
    useDevelopmentPlanCRUD,
    useDevelopmentPlanTemplates,
    useDevelopmentPlanTemplateById,
    useCompanyType,
    useInternalAndExternalContacts,
} from "controller";
import classes from "./developmentPlans.module.scss";
import { IDevelopmentPlanActionModel, IDevelopmentPlanModel } from "models";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    visible: boolean;
    onClose: Function;
    manuallySetPartnerId?: any;
    modalPartnerId?: number;
}

const CreateModal: FunctionComponent<IProps> = ({
    visible,
    onClose,
    manuallySetPartnerId,
    modalPartnerId,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [actions, setActions] = useState<any[]>([]);
    const [selectedPartnerId, setSelectedPartnerId] = useState<any>(
        modalPartnerId
            ? modalPartnerId
            : manuallySetPartnerId !== undefined
            ? manuallySetPartnerId
            : undefined
    );
    const { createDevelopmentPlan, loading: creatingPlan } = useDevelopmentPlanCRUD();
    const { getTemplateById } = useDevelopmentPlanTemplateById();
    const { isDefaultCompany } = useCompanyType();
    const { data: contacts, loading: gettingContacts } = useInternalAndExternalContacts(
        selectedPartnerId,
        undefined
    );
    const [reloadTemplates, setReloadTemplates] = useState<any>({ reload: false });
    const { data: templates } = useDevelopmentPlanTemplates(reloadTemplates);
    const [mode, setMode] = useState<"template" | "normal" | "select">("select");

    useEffect(() => {
        if (visible) {
            setReloadTemplates({ reload: true });
            form.resetFields();
        }
        if (!visible) {
            setMode("select");
        }
        setActions([]);
    }, [visible]);

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    const handleCreate = async () => {
        const values = await form.validateFields();
        let planActions: IDevelopmentPlanActionModel[] = actions.map((action: any) => ({
            title: action.title,
            description: action.description,
            dueDate: action.dueDate && dayjs(action.dueDate).toDate(),
            ownerId: action.ownerId,
        }));
        let creationInput: IDevelopmentPlanModel = {
            title: values.title,
            description: values.description,
            supplierId: values.supplier,
            developmentPlanTemplateId: values.developmentPlanTemplateId,
            actions: planActions,
            fileIds: values.files,
        };

        await createDevelopmentPlan(creationInput);
        notification.success({
            message: t("success"),
            description: t("generic_CreateSuccess"),
        });
        form.resetFields();
        onClose(true);
    };

    const handleTemplateSelect = async (templateId: number) => {
        let template: any = await getTemplateById(templateId);
        form.setFieldsValue({ developmentPlanTemplateId: template.id });
        form.setFieldsValue({ title: template.title });
        form.setFieldsValue({ description: template.description });
        setActions(
            template.actions.map((templateAction: any) => ({
                title: templateAction.title,
                description: templateAction.description,
                dueDate: undefined,
                ownerId: undefined,
                tableKey: uuidv4(),
            }))
        );
        setMode("normal");
    };

    const handleActionAdd = (action: any) => {
        let index = actions.findIndex((x: any) => x.tableKey === action.tableKey);
        if (index === -1) {
            setActions([...actions, action]);
        } else {
            let newActions = actions.slice(0).filter((x: any) => x.tableKey !== action.tableKey);
            newActions.splice(index, 0, action);
            setActions(newActions);
        }
    };

    const handleActionUpdate = async (
        type: "title" | "description" | "dueDate" | "owner",
        tableKey: any,
        payLoad: any
    ) => {
        let newArr = [...actions];
        let index = newArr.findIndex((ac: any) => ac.tableKey === tableKey);

        switch (type) {
            case "title":
                newArr[index].title = payLoad;
                break;
            case "description":
                newArr[index].description = payLoad;
                break;
            case "dueDate":
                newArr[index].dueDate = payLoad;
                break;
            case "owner":
                newArr[index].ownerId = payLoad;
                break;
            default:
                break;
        }

        setActions(newArr);
    };

    const templateModeFooter = [
        <Button onClick={() => setMode("select")}>{t("generic_Back")}</Button>,
    ];

    const normalModeFooter = [
        <Button
            onClick={() => {
                setActions([]);
                form.resetFields();
                setMode("select");
            }}>
            {t("generic_Back")}
        </Button>,
        <Button type="primary" loading={creatingPlan} onClick={() => form.submit()}>
            {t("generic_Create")}
        </Button>,
    ];

    const selectModeFooter = null;

    const disableSupplierSelect = actions.filter((x: any) => x.externalOwner === true).length > 0;

    return (
        <>
            <Modal
                width={1000}
                open={visible}
                title={t("developmentPlan_Add")}
                onCancel={() => onClose()}
                cancelText={t("generic_Cancel")}
                confirmLoading={creatingPlan}
                footer={
                    mode === "template"
                        ? templateModeFooter
                        : mode === "normal"
                        ? normalModeFooter
                        : selectModeFooter
                }>
                <Form form={form} layout="vertical" onFinish={handleCreate} scrollToFirstError>
                    {mode === "select" && (
                        <Row gutter={32}>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    onClick={() => setMode("template")}
                                    block
                                    style={{ height: 80, fontSize: 22 }}>
                                    {t("developmentPlan_CreateFromTemplate")}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    onClick={() => setMode("normal")}
                                    block
                                    style={{ height: 80, fontSize: 22 }}>
                                    {t("developmentPlan_AddNewPlan")}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {mode === "template" && (
                        <>
                            <Row>
                                <Col push={7} span={12}>
                                    <h2 className={classes.templateHeader}>
                                        {t("developmentPlan_TemplateSelection")}
                                    </h2>
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col push={7} span={12} className={classes.templateContainer}>
                                    {templates &&
                                        templates.map((template: any) => (
                                            <div
                                                className={classes.templateItem}
                                                onClick={() => {
                                                    handleTemplateSelect(template.id);
                                                }}>
                                                <span>
                                                    {template.title.length > 50 ? (
                                                        <Tooltip
                                                            placement="right"
                                                            mouseEnterDelay={0.5}
                                                            title={
                                                                template.title
                                                            }>{`${template.title.substring(
                                                            0,
                                                            50
                                                        )}...`}</Tooltip>
                                                    ) : (
                                                        template.title
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                </Col>
                            </Row>
                        </>
                    )}
                    {mode === "normal" && (
                        <>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Form.Item
                                    style={{ display: "none" }}
                                    name="developmentPlanTemplateId">
                                    <Input maxLength={500} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name="title"
                                    label={t("generic_Title")}
                                    className={classes.titleInput}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("inputIsRequiredError"),
                                            whitespace: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={t("generic_Placeholder-Text")}
                                        maxLength={500}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name="supplier"
                                    label={
                                        <>
                                            {isDefaultCompany
                                                ? t("company_Type_Supplier")
                                                : t("company_Type_Customer")}
                                            {disableSupplierSelect && (
                                                <Tooltip
                                                    title={t(
                                                        "developmentPlan_SupplierSelectDisabled"
                                                    )}>
                                                    <span
                                                        style={{
                                                            marginLeft: 4,
                                                        }}>
                                                        <InfoCircle />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </>
                                    }
                                    className={classes.supplierInput}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}
                                    initialValue={
                                        modalPartnerId
                                            ? modalPartnerId
                                            : manuallySetPartnerId !== undefined
                                            ? manuallySetPartnerId
                                            : undefined
                                    }>
                                    <SelectTreeData
                                        placeholder={`${t("select")} ${
                                            isDefaultCompany
                                                ? t("company_Type_Supplier").toLocaleLowerCase()
                                                : t("company_Type_Customer").toLocaleLowerCase()
                                        }`}
                                        disabled={
                                            modalPartnerId !== undefined || disableSupplierSelect
                                        }
                                        selectOptionField="name"
                                        onChange={(val: any) => {
                                            setSelectedPartnerId(val);
                                        }}
                                        useData={usePartnerList}
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item name="description" label={t("generic_Description")}>
                                <Input.TextArea
                                    placeholder={t("generic_Placeholder-Text")}
                                    maxLength={1000}></Input.TextArea>
                            </Form.Item>

                            <div className={classes.title}>Actions</div>
                            <Row>
                                <Col span={6}>{t("generic_Title")}</Col>
                                <Col span={6}>{t("generic_Description")}</Col>
                                <Col span={4}>{t("generic_Deadline")}</Col>
                                <Col span={6}>{t("action_AssignedTo")}</Col>
                                <Col span={2}></Col>
                                <Divider style={{ marginTop: 4, marginBottom: 12 }} />
                            </Row>
                            {actions.map((action: any) => (
                                <Row>
                                    <Col span={6}>
                                        <Tooltip title={action.title} mouseEnterDelay={0.5}>
                                            <Form.Item
                                                name={`title${action.tableKey}`}
                                                initialValue={action.title}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <Input
                                                    autoFocus
                                                    placeholder={t("generic_Placeholder-Text")}
                                                    className={classes.inputClass}
                                                    maxLength={500}
                                                    bordered={false}
                                                    onBlur={(e: any) => {
                                                        handleActionUpdate(
                                                            "title",
                                                            action.tableKey,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col span={6}>
                                        <Tooltip title={action.description} mouseEnterDelay={0.5}>
                                            <Form.Item
                                                name={`description${action.tableKey}`}
                                                initialValue={action.description}>
                                                <Input
                                                    className={classes.inputClass}
                                                    placeholder={t("generic_Placeholder-Text")}
                                                    maxLength={1000}
                                                    bordered={false}
                                                    onBlur={(e: any) =>
                                                        handleActionUpdate(
                                                            "description",
                                                            action.tableKey,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name={`dueDate${action.tableKey}`}
                                            initialValue={
                                                action.dueDate ? dayjs(action.dueDate) : undefined
                                            }>
                                            <DatePicker
                                                format={"MMM D, YYYY "}
                                                className={classes.inputClass}
                                                disabledDate={disabledDate}
                                                bordered={false}
                                                onChange={(date: any, dateString: string) =>
                                                    handleActionUpdate(
                                                        "dueDate",
                                                        action.tableKey,
                                                        dateString.trim() === ""
                                                            ? undefined
                                                            : dateString
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name={`owner${action.tableKey}`}
                                            initialValue={action.ownerId}>
                                            <SelectData
                                                onChange={(_: any, item: any) =>
                                                    handleActionUpdate(
                                                        "owner",
                                                        action.tableKey,
                                                        item ? item.id : undefined
                                                    )
                                                }
                                                useData={() => ({
                                                    data: contacts,
                                                    loading: gettingContacts,
                                                })}
                                                selectOptionField="name"
                                                groupNames={[
                                                    t("action_Internal-Contacts"),
                                                    t("action_External-Contacts"),
                                                ]}
                                                groupFilter={(item: any, groupName: string) => {
                                                    if (groupName === t("action_Internal-Contacts"))
                                                        return item.externalUser === false;
                                                    if (groupName === t("action_External-Contacts"))
                                                        return item.externalUser === true;
                                                }}
                                                placeholder={t("select")}
                                                bordered={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                paddingTop: 4,
                                                cursor: "pointer",
                                            }}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}>
                                            <Popconfirm
                                                title={t("generic_RemoveConfirm", {
                                                    title: t("generic_Action").toLowerCase(),
                                                })}
                                                cancelText={t("generic_Cancel")}
                                                okText={t("generic_Confirm")}
                                                onConfirm={() =>
                                                    setActions(
                                                        actions.filter(
                                                            (ac: any) =>
                                                                ac.tableKey !== action.tableKey
                                                        )
                                                    )
                                                }>
                                                <span>
                                                    <XCircle />
                                                </span>
                                            </Popconfirm>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                            <Button
                                onClick={() =>
                                    handleActionAdd({
                                        tableKey: uuidv4(),
                                        title: undefined,
                                        description: undefined,
                                        dueDate: undefined,
                                        ownerId: undefined,
                                    })
                                }>
                                {t("developmentPlan_AddAction")}
                            </Button>
                            <Form.Item
                                className={classes.topMargin}
                                label={t("generic_Files")}
                                name="files"
                                initialValue={[]}>
                                <AttachFile
                                    makeTextNearButton={true}
                                    maxFileSize={40}
                                    uploadSectionLayout={{
                                        style: { display: "flex", flexDirection: "column" },
                                    }}
                                    uploadButtonLayout={{
                                        style: {
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom: 10,
                                        },
                                    }}>
                                    {t("generic_SelectFile")}
                                </AttachFile>
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export { CreateModal };
