import { useEffect, useState } from "react";
import { Provider } from "services";
import { INcrGraph } from "models/suppliers/summaryCards/INcrGraph";

type StateType = {
    data: INcrGraph | null;
    loading: boolean;
};

const useNcrGraph = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Partner.getNcrGraph(partnerId, from, to, {
                customError: {
                    message: "Could not retrive data for ncr graph",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNcrGraph };
