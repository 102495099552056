import React from "react";
import { Divider, Skeleton } from "antd";
import classes from "../userLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { useComplianceCount } from "controller";
import { ICompanyBranding } from "models";
import { useHistory } from "react-router-dom";
import { ArrowRight, ClipboardCheck } from "components/utilitycomponents";

type IProps = {
    branding: ICompanyBranding;
};

const ComplianceCount = ({ branding }: IProps) => {
    const { t } = useTranslation();
    const { data, loading } = useComplianceCount();
    const phrase = t("gatekeeper_LandingPageTooltipTitle");
    const capitalizedTitle = phrase.charAt(0).toUpperCase() + phrase.slice(1).toLowerCase();
    const history = useHistory();
    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <div className={classes.actionCardStyle}>
                    <div>
                        <ClipboardCheck className={classes.icon} />
                    </div>
                    <span className={classes.counter}>{data}</span>
                    <div className={classes.cardTitle}>{capitalizedTitle}</div>
                    <Divider style={{ margin: 0 }} />

                    <div
                        className={classes.cardButton}
                        onClick={() => {
                            history.push("/Compliance/filter/MyRegistrations");
                        }}>
                        <span style={{ width: 177 }}>
                            {t("landing_Page_Compliance_Overview_Expiring")}
                            <ArrowRight style={{ fontSize: 12, marginLeft: 5, color: "#1677ff" }} />
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export { ComplianceCount };
