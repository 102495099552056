import { post, get, patch, remove } from "../base";

const AdminShortcutSettings = {
    createSection: async (section: any, { customError }: any = {}) => {
        const url = "/v1/Shortcuts/CreateShortcutSection";
        await post({ url, payload: section, customError });
    },
    getSections: async ({ customError }: any = {}) => {
        const url = "/v1/Shortcuts/GetShortcutSections";
        return await get({ url, customError });
    },
    editSection: async (section: any, { customError }: any = {}) => {
        const url = "/v1/Shortcuts/EditShortcutSection";
        return await patch({ url, payload: section, customError });
    },
    editSectionOrders: async (sections: any[], { customError }: any = {}) => {
        const url = "/v1/Shortcuts/UpdateShortcutSectionsOrder";
        return await patch({ url, payload: sections, customError });
    },
    deleteSection: async (id: any, { customError }: any = {}) => {
        const url = `/v1/Shortcuts/DeleteShortcutSection?id=${id}`;
        return await remove({ url, customError });
    },
    createShortcut: async (shortcut: any, { customError }: any = {}) => {
        const url = "/v1/Shortcuts/CreateShortcut";
        await post({ url, payload: shortcut, customError });
    },
    editShortcut: async (shortcut: any, { customError }: any = {}) => {
        const url = "/v1/Shortcuts/UpdateShortcut";
        return await patch({ url, payload: shortcut, customError });
    },
    editShortcutOrders: async (shortcuts: any[], { customError }: any = {}) => {
        const url = "/v1/Shortcuts/UpdateShortcutsOrder";
        return await patch({ url, payload: shortcuts, customError });
    },
    deleteShortcut: async (id: any, { customError }: any = {}) => {
        const url = `/v1/Shortcuts/DeleteShortcut?id=${id}`;
        return await remove({ url, customError });
    },
    getShortcutTypes: async ({ customError }: any = {}) => {
        const url = "/v1/Shortcuts/GetShortcutTypes";
        return await get({ url, customError });
    },
};

export { AdminShortcutSettings };
