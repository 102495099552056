import { useEffect, useState } from "react";
import Provider from "services/api";
import { IComplianceTemplate } from "models";

type StateType = {
    data: IComplianceTemplate | null;
    loading: boolean;
};

const useTemplateForCompliance = (id: number | null | undefined) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (id !== null && id !== undefined) {
                setState({ ...state, loading: true });
                const data = await Provider.Compliance.getTemplateForCompliance(id);
                setState({ loading: false, data });
            }
        })();
    }, [id]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useTemplateForCompliance };
