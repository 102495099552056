import { useState } from "react";
import { Provider } from "services";

const useUpdatePartnerAccessSettings = () => {
    const [loading, setLoading] = useState(false);

    const updatePartnerAccessSettings = async (payload: any) => {
        setLoading(true);
        await Provider.User.UpdatePartnerInformationAccess(payload, {
            customError: {
                message: "Could not set partner access settings",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updatePartnerAccessSettings,
    };
};

export { useUpdatePartnerAccessSettings };
