import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    // connect with React
    .use(initReactI18next);
// for all options read: https://www.i18next.com/overview/configuration-options

//init is run in main component

export default i18n;
