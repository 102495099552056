import React from "react";

function HandShakeIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 96 96">
            <path
                d="M47.388 75.852c-2.268 0-4.428-.868-5.696-1.704-2.156-1.416-17.844-14.532-19.628-16.024l-5.44-4.58a2.002 2.002 0 01-.236-2.82 2.009 2.009 0 012.82-.244l5.428 4.572C33.2 62.22 42.564 69.928 43.892 70.804c.96.632 2.668 1.18 4.02 1.024 1.148-.14 1.5-.716 1.676-1.204a1.985 1.985 0 011.408-1.252 1.996 1.996 0 011.828.464c1.18 1.08 3.024 1.176 4.244.7.608-.24 1.364-.724 1.444-1.656.076-.76.572-1.416 1.28-1.688s1.516-.112 2.08.412c.572.524 1.924.508 3.028-.04.612-.304 1.62-1.008 1.492-2.248a1.996 1.996 0 013.12-1.856c.588.412 1.344.088 1.804-.308.748-.636 1.276-1.86.54-3.4C69.58 55.016 57.424 43.48 49.94 38.248l-.064.02c-2.124.772-2.356.84-3.932.996-1.984 2.88-5.932 7.8-11.828 5.852-1.868-.616-3.144-2.02-3.596-3.96-1.012-4.344 2.232-10.376 4.968-13.064 4.5-4.412 12.748-8.228 21.176-4.448 7.804 3.492 12.664 4.932 12.712 4.948 1.064.312 1.672 1.42 1.36 2.48s-1.416 1.672-2.48 1.356c-.208-.06-5.14-1.52-13.224-5.136-6.596-2.948-13.144.124-16.748 3.656-2.208 2.172-4.432 6.892-3.868 9.296.136.588.404.892.952 1.072 2.988.988 5.196-1.208 7.784-5.072a1.999 1.999 0 011.46-.876l.736-.072c1.332-.128 1.332-.128 3.16-.792l1.032-.376a2.02 2.02 0 011.792.22c7.236 4.824 21.212 17.608 24.12 23.668 1.416 2.948.812 6.156-1.544 8.168-1.164.996-2.54 1.508-3.916 1.452-.564 1.452-1.716 2.704-3.312 3.5-1.656.82-3.484 1.032-5.076.664-.696 1.084-1.76 1.944-3.112 2.468-2.084.804-4.452.696-6.424-.204-.916.956-2.176 1.56-3.688 1.736-.328.036-.66.052-.992.052z"
                fill="#303030"></path>
            <path
                d="M40.952 28.484c-.072 0-.144-.004-.22-.012-4.1-.444-13.888-.736-17.028-.64-.932.068-2.024-.836-2.056-1.94a2 2 0 011.936-2.06c3.308-.096 13.252.196 17.58.668a1.996 1.996 0 011.772 2.2 1.99 1.99 0 01-1.984 1.784zM74.204 60.072a1.999 1.999 0 01-1.208-3.592l3.916-2.968c.88-.664 2.136-.5 2.8.384s.5 2.14-.384 2.804l-3.916 2.972c-.36.264-.788.4-1.208.4zM68.368 67.092a2.02 2.02 0 01-1.24-.428c-2.096-1.656-13.08-11.916-13.544-12.352-.808-.752-.848-2.016-.092-2.828s2.024-.844 2.828-.092c3.14 2.936 11.656 10.848 13.288 12.136a2.003 2.003 0 01.332 2.812 2.002 2.002 0 01-1.572.752zM60.5 71.06c-.436 0-.88-.144-1.252-.432-2.712-2.172-10.348-9.048-11.5-10.296a1.993 1.993 0 01.12-2.82 1.996 1.996 0 012.828.112c.928 1.008 8.404 7.752 11.052 9.88a2.001 2.001 0 01.316 2.812c-.392.488-.98.744-1.564.744zM51.46 73.312c-.452 0-.912-.156-1.288-.476-2.604-2.196-6.188-5.772-8.584-8.336-.752-.804-.708-2.076.1-2.828s2.076-.704 2.828.096c2.312 2.48 5.752 5.916 8.236 8.012a2.001 2.001 0 01-1.292 3.532z"
                fill="#303030"></path>
            <path
                d="M7.184 60.476c-2.496 0-4.328-.156-5.604-.46a1.999 1.999 0 01-1.532-1.96c.136-14.536 3.9-35.392 4.06-36.268a1.997 1.997 0 012.272-1.62c.52.084 12.848 2.004 17.924 3.776a1.995 1.995 0 011.336 2.06c-.908 10.404-8.016 31.8-8.32 32.704a1.99 1.99 0 01-1.708 1.352c-.184.02-4.416.416-8.428.416zm-3.1-4.124c2.168.196 7.492.008 9.852-.164 1.456-4.5 6.348-20 7.544-28.94-3.988-1.12-10.532-2.272-13.772-2.816-.896 5.3-3.3 20.412-3.624 31.92zM87.492 59.42c-4.304 0-7.18-.328-8.796-.992a1.99 1.99 0 01-1.164-1.32c-1.532-5.544-7.18-21.424-9.616-25.344a1.987 1.987 0 01-.216-1.64 2.007 2.007 0 011.092-1.236c.288-.132 7.12-3.16 14.436-3.16 1.548 0 3.028.144 4.396.412.5.096.94.384 1.24.796 4.828 6.736 6.86 27.72 7.076 30.084a1.995 1.995 0 01-.464 1.476 2.007 2.007 0 01-1.388.704c-.004.004-3.128.22-6.596.22zm-6.412-4.444c1 .216 2.9.448 6.416.448 1.592 0 3.108-.052 4.256-.096-.756-6.928-2.736-20.316-5.712-25.4a19.556 19.556 0 00-2.8-.196c-4.252 0-8.416 1.204-10.788 2.036 2.812 5.816 7.016 17.816 8.628 23.208z"
                fill="#303030"></path>
        </svg>
    );
}

export default HandShakeIcon;
