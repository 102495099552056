import { useState } from "react";
import { Provider } from "services";

const useFileUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [checkFileNameLoading, setCheckFileNameLoading] = useState(false);

    const updateFile = async (fileData: any, title?: string, partnerId?: number) => {
        setLoading(true);
        const res = await Provider.File.updateFile(fileData, {
            customError: {
                message: "Could not update file settings",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    const updateFileTitle = async (fileId: number, title: string) => {
        setLoading(true);
        await Provider.File.updateFileTitle(fileId, title).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const isFileNameInUse = async (folderId: number, name: string) => {
        setCheckFileNameLoading(true);
        const res = await Provider.File.isFileNameInUse(folderId, name, {
            customError: {
                message: "Could not check file name",
            },
        }).catch((err: any) => {
            setCheckFileNameLoading(false);
            return Promise.reject(err);
        });
        setCheckFileNameLoading(false);
        return res;
    };

    return {
        loading: loading,
        updateFile,
        updateFileTitle,
        isFileNameInUse,
        checkFileNameLoading,
    };
};

export { useFileUpdate };
