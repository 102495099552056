import React, { Fragment, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { notification, Progress, Row, Col, Popconfirm } from "antd";
import {
    UploadButton,
    LoadingIndicator,
    DownloadFile,
    useAttach,
    Trash02,
} from "components/utilitycomponents";

type InputFileType = {
    name: string;
    id: number;
};

type PropsType = {
    makeTextNearButton?: boolean;
    onChange?: Function;
    externalLoading?: boolean;
    attachedFileList?: InputFileType[];
    maxFileSize?: number;
    clearFileList?: any;
    showMaxFileSize?: boolean;
    icon?: any;
    multiple?: boolean;
    accept?: string;
    uploadSectionLayout?: any;
    uploadButtonLayout?: any;
    filesSectionLayout?: any;
    children?: any;
    onUploadingFile?: any;
};

const AttachFile = forwardRef(
    (
        {
            onChange,
            externalLoading = false,
            attachedFileList = undefined,
            maxFileSize = 40,
            clearFileList,
            showMaxFileSize = true,
            icon,
            multiple = true,
            accept,
            uploadSectionLayout,
            uploadButtonLayout,
            filesSectionLayout,
            children = "",
            onUploadingFile,
            makeTextNearButton = false,
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();
        const { handleDeleteFile, handleUpload, uploadInProgress, allFiles, newFiles } = useAttach({
            clearFileList,
            attachedFileList,
            onChange,
            notification,
            maxFileSize,
            accept,
        });

        useEffect(() => {
            onUploadingFile && onUploadingFile(uploadInProgress);
        }, [uploadInProgress]);

        return (
            <div {...(uploadSectionLayout ? { ...uploadSectionLayout } : {})}>
                <div {...(uploadButtonLayout ? { ...uploadButtonLayout } : {})}>
                    <UploadButton
                        onSelect={handleUpload}
                        loading={uploadInProgress}
                        icon={icon}
                        multiple={multiple}
                        accept={accept}
                        disabled={!multiple && allFiles.length > 0}>
                        {children === "" ? t("attachFile") : children}
                    </UploadButton>
                    {showMaxFileSize && makeTextNearButton === true && (
                        <div style={{ marginLeft: 10 }}>
                            {t("maximumAllowedSize", { maxFileSize: maxFileSize })}
                        </div>
                    )}
                </div>
                <div {...(filesSectionLayout ? { ...filesSectionLayout } : {})}>
                    {showMaxFileSize && makeTextNearButton === false && (
                        <div>{t("maximumAllowedSize", { maxFileSize: maxFileSize })}</div>
                    )}
                    {newFiles.map((file: any, index: number) => (
                        <Fragment key={index}>
                            <div style={{ lineHeight: "20px" }}>{file.name}</div>
                            <Row style={{ lineHeight: "20px" }}>
                                <Col span={1} style={{ textAlign: "center" }}>
                                    <LoadingIndicator />
                                </Col>
                                <Col span={21}>
                                    <Progress
                                        percent={file.progress}
                                        size="small"
                                        status={file.progress < 100 ? "active" : "normal"}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: "center" }}>
                                    {file.progress < 100 && (
                                        <Trash02 onClick={() => file.source.cancel()} />
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    ))}
                    {allFiles.map((file: any, index: number) => (
                        <div style={{ lineHeight: "30px", display: "flex" }} key={index}>
                            <Popconfirm
                                title={t("file_RemovalConfirm")}
                                onConfirm={() => handleDeleteFile(file.id)}
                                okText={t("generic_Yes")}
                                cancelText={t("generic_No")}>
                                <span>
                                    <Trash02
                                        onClick={() => {}}
                                        data-test="remove_file_button"
                                        title={t("file_RemoveFile")}
                                        style={{ marginRight: "5px" }}
                                    />
                                </span>
                            </Popconfirm>
                            <DownloadFile file={file} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export { AttachFile };
