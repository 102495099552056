import { useState, useEffect } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

type StateType = {
    loading: boolean;
    data: any[];
};

const useReviewCategoryForFilter = (from: string, to: string) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Review.getReviewCategoriesForFilter(from, to, {
                customError: {
                    message: t("review_GetCategoriesError"),
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            data = data.map((d: any) => ({ ...d, categoryName: t(d.categoryName) }));
            setState({ loading: false, data: data });
        })();
    }, [from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewCategoryForFilter };
