import { useEffect, useState } from "react";
import { Provider } from "services";
import { IReportSettings } from "models";
import Config from "../../../config";

type StateType = {
    loading: boolean;
    data: IReportSettings[];
};

const useEmbeddedReportSettings = (reload: any = { reload: false }, userType: string = "user") => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (userType !== Config.guestUser) {
                setState({ ...state, loading: true });
                let data: any = await Provider.Company.getEmbeddedReportSettings({
                    customError: {
                        message: "Could not retrieve report settings",
                    },
                }).catch((err: any) => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data,
                });
            }
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useEmbeddedReportSettings };
