import { useState } from "react";
import Provider from "services/api";

const useRelationshipDimensionCRUD = () => {
    const [loading, setLoading] = useState(false);

    const createDimension = async (dimension: any) => {
        setLoading(true);
        const id = await Provider.Relationships.createRelationshipDimension(dimension, {
            customError: {
                message: "Could not create dimension",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const updateDimension = async (dimensionId: number, dimension: any) => {
        setLoading(true);
        await Provider.Relationships.updateRelationshipDimension(dimensionId, dimension, {
            customError: {
                message: "Could not update dimension",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const deleteDimension = async (id: number) => {
        setLoading(true);
        await Provider.Relationships.deleteRelationshipDimension(id, {
            customError: {
                message: "Could not delete dimension",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const reorder = async (orders: any[]) => {
        setLoading(true);
        await Provider.Relationships.updateRelationDimensionOrders(orders, {
            customError: {
                message: "Could not update orders",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        createDimension,
        updateDimension,
        deleteDimension,
        reorder,
    };
};

export { useRelationshipDimensionCRUD };
