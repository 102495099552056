import { IGetAwardStatus } from "models/scorecard";
import { useEffect, useState } from "react";
import Provider from "services/api";

type State = {
    loading: boolean;
    data: IGetAwardStatus | null;
};
const useAwardStatus = (partnerId: number) => {
    const [state, setState] = useState<State>({
        loading: true,
        data: null,
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Scorecard.getAwardStatus(partnerId).catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId]);
    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAwardStatus };
