import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import i18n from "i18n";
import dayjs from "utilities/daysJsConfig";
import getFilterIcon from "./getFilterIcon";
const RangePicker = DatePicker.RangePicker;

const getColumnRangePickerProps = (
    params: any = { dataIndex: Math.floor(Math.random() * 100) }
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div data-test={`search_${params.dataIndex}_input`} className="filterDropDownContainer">
            <RangePicker
                onChange={(dates: any) => {
                    setSelectedKeys([dates[0].utc().startOf("day"), dates[1].utc().endOf("day")]);
                }}
                value={[
                    selectedKeys[0]
                        ? selectedKeys[0]
                        : dayjs()
                              .subtract(6, "months")
                              .startOf("day"),
                    selectedKeys[1] ? selectedKeys[1] : dayjs().endOf("day"),
                ]}
                presets={[
                    {
                        label: i18n.t("dateRange_lastMonth"),
                        value: [
                            dayjs()
                                .subtract(1, "months")
                                .startOf("day"),
                            dayjs().endOf("day"),
                        ],
                    },
                    {
                        label: i18n.t("dateRange_last3Months"),
                        value: [
                            dayjs()
                                .subtract(2, "months")
                                .startOf("day"),
                            dayjs().endOf("day"),
                        ],
                    },
                    {
                        label: i18n.t("dateRange_last6Months"),
                        value: [
                            dayjs()
                                .subtract(5, "months")
                                .startOf("day"),
                            dayjs().endOf("day"),
                        ],
                    },
                    {
                        label: i18n.t("dateRange_last9Months"),
                        value: [
                            dayjs()
                                .subtract(9, "months")
                                .startOf("day"),
                            dayjs().endOf("day"),
                        ],
                    },
                    {
                        label: i18n.t("dateRange_lastYear"),
                        value: [
                            dayjs()
                                .subtract(11, "months")
                                .startOf("day"),
                            dayjs().endOf("day"),
                        ],
                    },
                ]}
                format={"MMM D, YYYY"}
                autoFocus
                style={{ marginBottom: 8 }}
                allowClear={false}
            />
            <div style={{ textAlign: "left" }}>
                <Button
                    data-test={`search_${params.dataIndex}_button`}
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, setSelectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {i18n.t("grid_Filter")}
                </Button>

                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    {i18n.t("grid_Reset")}
                </Button>
            </div>
        </div>
    ),
    ...getFilterIcon(params),
});

const handleSearch = (selectedKeys: any, setSelectedKeys: any, confirm: any) => {
    if (!selectedKeys.length) {
        setSelectedKeys([
            dayjs()
                .subtract(6, "months")
                .startOf("day"),
            dayjs().endOf("day"),
        ]);
    }
    confirm();
};

export default getColumnRangePickerProps;
