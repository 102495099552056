const getAllUserOrderedColumns = (viewColumnSettings: any[], columns: any[]) => {
    let allUserOrderedColumns: any[] = [];
    if (viewColumnSettings && viewColumnSettings.length > 0) {
        for (let view of viewColumnSettings) {
            for (let i = 0; i < columns.length; i++) {
                if (view.viewColumnId === columns[i]["viewColumnId"]) {
                    allUserOrderedColumns.push({ ...columns[i] });
                    break;
                }
            }
        }
        /*there might be a situation in which a column has been added in code but the list of
            columns are not updated yet*/
        for (let i = 0; i < columns.length; i++) {
            let columnIsInViewSettings = false;
            for (let view of viewColumnSettings) {
                if (view.viewColumnId === columns[i]["viewColumnId"]) {
                    columnIsInViewSettings = true;
                    break;
                }
            }
            if (!columnIsInViewSettings) {
                allUserOrderedColumns.push({ ...columns[i] });
            }
        }
    } else allUserOrderedColumns = [...columns];
    return allUserOrderedColumns;
};

export { getAllUserOrderedColumns };
