import React, { FunctionComponent, useEffect } from "react";
import { Modal, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useRisks } from "controller";
import { SelectData } from "components/utilitycomponents";
import { IRisk, RiskState } from "models";

interface IProps {
    visible: boolean;
    onClose: Function;
    item: IRisk;
    partnerId: number;
}

const ChangeState: FunctionComponent<IProps> = ({ visible, onClose, item, partnerId }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { updateState, updateStateLoading } = useRisks(partnerId, false);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({ state: item.state });
        }
    }, [visible]);

    const handleSubmit = async () => {
        const values = await form.validateFields();

        await updateState({ newItem: { id: item.id, state: values.state }, item });
        onClose(true);
    };

    return (
        <Modal
            title={t("riskAndIssues_ChangeState")}
            open={visible}
            onCancel={() => onClose()}
            onOk={() => form.submit()}
            footer={[
                <Button
                    loading={updateStateLoading}
                    type="primary"
                    onClick={async () => form.submit()}>
                    {t("generic_Save")}
                </Button>,
            ]}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    name="state"
                    label={t("riskAndIssues_State")}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <SelectData
                        useData={() => ({
                            data: [
                                { id: RiskState.Mitigated, name: t("riskAndIssues_Mitigated") },
                                {
                                    id: RiskState.MitigationOnGoing,
                                    name: t("riskAndIssues_MitigationOnGoing"),
                                },
                                {
                                    id: RiskState.NotMitigated,
                                    name: t("riskAndIssues_NotMitigated"),
                                },
                            ],
                            loading: false,
                        })}
                        selectOptionField="name"
                        placeholder={t("select")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export { ChangeState };
