import i18n from "i18n";

export const translateSubLevelHeadline = (headline: string) => {
    let translateHeadline = false;

    switch (headline) {
        case "NCR_Reason":
        case "Review_Category":
            translateHeadline = true;
            break;
        default:
            translateHeadline = false;
            break;
    }

    return translateHeadline ? i18n.t(headline) : headline;
};
