import React, { FunctionComponent, useEffect } from "react";
import { Col, Form, Input, Modal } from "antd";
import { useSegmentationFilters } from "controller";
import { useTranslation } from "react-i18next";
import { SavedFilterType, ISavedReportFilter } from "models";
import { FormRow } from "components/utilitycomponents";

type PropsType = {
    filters: ISavedReportFilter[];
    filterType: SavedFilterType;
    visible: boolean;
    onClose: Function;
};

const SaveFilterModal: FunctionComponent<PropsType> = ({
    filters,
    filterType,
    visible,
    onClose,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [visible]);
    const {
        filterInUse,
        filterInUseLoading,
        saveFilters,
        saveFiltersLoading,
    } = useSegmentationFilters(filterType);

    const getFiltersToSave = () => {
        let filtersToSave = [];
        for (let filter of filters) {
            if (Array.isArray(filter.value) && filter.value.length > 0) {
                filtersToSave.push({ type: filter.type, value: filter.value.join(",") });
            } else if (filter.value) {
                filtersToSave.push(filter);
            }
        }
        return filtersToSave;
    };

    const handleSaveFilter = async () => {
        if (!filterInUseLoading) {
            let values = await form.validateFields();
            let filtersToSave = getFiltersToSave();
            if (filtersToSave.length > 0) {
                const filterId = await saveFilters(values.name, filtersToSave);
                onClose(true, filterId);
            }
        }
    };

    return (
        <Modal
            title={t("segmentation_SaveFilter")}
            open={visible}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okButtonProps={{
                loading: saveFiltersLoading || filterInUseLoading,
            }}
            okText={t("segmentation_SaveFilter")}>
            <Form form={form} onFinish={handleSaveFilter}>
                <FormRow>
                    <Col span={6}>{t("segmentation_FilterName")}</Col>
                    <Col span={18}>
                        <Form.Item
                            validateTrigger={[]}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t("inputIsRequiredError"),
                                },
                                {
                                    validator: async (rule: any, value: any, callback: any) => {
                                        if (value && value.trim()) {
                                            const result: any = await filterInUse(
                                                value,
                                                getFiltersToSave()
                                            );
                                            if (result.isDuplicateName === true) {
                                                throw new Error(
                                                    t("generic_DuplicateError", {
                                                        title: t("segmentation_FilterName"),
                                                    })
                                                );
                                            }
                                            if (result.isDuplicateData !== null) {
                                                throw new Error(
                                                    t("segmentation_DuplicateFilter", {
                                                        name: result.isDuplicateData,
                                                    })
                                                );
                                            }
                                        }
                                        callback();
                                    },
                                },
                            ]}>
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default SaveFilterModal;
