import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./styles/pageTitle.module.scss";
import { useTranslation } from "react-i18next";
export const PageTitle: FC = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const getPageTitle = (pathname: string): string => {
        switch (pathname) {
            case "/Partners":
                return t("partner_Supplier_Overview_Page_Title");
            case `/Partners/Masterdata`:
                return t("partner_MasterData_Overview_Page_Title");
            case "/Contacts":
                return t("contacts_Contacts_Overview_Page_Title");
            case "/Contacts/ContactGroup":
                return t("contacts_Group_Overview_Page_Title");
            case "/Files":
                return t("files_Files_Overview_Page_Title");
            case "/Reviews":
                return t("reviews_Review_Overview_Page_Title");
            case "/Reviews/SurveyOverview":
                return t("reviews_Surveys_Overview_Page_Title");
            case "/SurveyRequests":
                return t("reviews_Survey_Requests_Page_Title");
            case "/Reviews/NonResponseOverview":
                return t("reviews_Non_Response_Overview_Page_Title");
            case "/Reviews/AutomatedSurveyOverview":
                return t("reviews_Automated_Surveys_Overview_Page_Title");
            case "/Ncrs":
                return t("ncr_NCR_Overview_Page_Title");
            case "/Ncrs/Portfolio":
                return t("ncr_Portfolio_Page_Title");
            case "/Compliance":
                return t("compliance_Compliance_Overview_Page_Title");
            case "/Compliance/MatrixOverview":
                return t("compliance_Matrix_Overview_Page_Title");
            case "/Compliance/Requests":
                return t("compliance_Requests_Page_Title");
            case "/Products":
                return t("product_Products");
            case "/Actions":
                return t("actions_Actions_Page_Title");
            case "/DevelopmentPlans":
                return t("actions_Development_Plans_Page_Title");
            case "/partnerAnalytics":
                return t("partner_Analytics_Shared_Analytics_Page_Title");
            case "/Reporting":
                return t("reporting_Reporting_Page_Title");
            case "/User":
                return t("account_Profile");
            case "/Admin/CompanyInformation":
                return t("admin_General_CompanyInfo");
            case "/Admin/CompanySettings":
                return t("admin_General_CompanySettings");
            case "/Admin/CustomDropdowns":
                return t("admin_General_CustomDropdown");
            case "/Admin/SingleSignOn":
                return t("admin_General_Sso");
            case "/Admin/ReportSettings":
                return t("admin_General_ReportSettings");
            case "/Admin/ShortcutSettings":
                return t("admin_General_ShortcutSettings");
            case "/Admin/SupplierAccessSettings":
                return t("admin_SupplierAccessSettings_SupplierAccessSettings");
            case "/Admin/UserManagement":
                return t("admin_Users_UserManagement");
            case "/Admin/UserAccessSettings":
                return t("admin_UserAccessSetting_UserAccessSettings");
            case "/Admin/UploadContacts":
                return t("admin_Contacts_UploadContacts");
            case "/Admin/UploadPartnerFile":
                return t("admin_SupplierPartnerFileLabel");
            case "/Admin/Invite":
                return t("admin_Invite_PageTitle");
            case "/Admin/InvitationStatus":
                return t("admin_Supplier_InvitationStatus");
            case "/Admin/StandingSettings":
                return t("admin_Supplier_StatusCategories");
            case "/Admin/StatusEventSettings":
                return t("admin_Supplier_StatusEventSettings");
            case "/Admin/SupplierTags":
                return t("admin_Supplier_ManageTags");
            case "/Admin/FieldSettings":
                return t("admin_General_FieldSettings");
            case "/Admin/supplierProfileSettings":
                return t("admin_SupplierProfile_Settings");
            case "/Admin/ScorecardSettings":
                return t("admin_supplierProfile_scorecard_PageTitle");
            case "/Admin/GovernanceTasks":
                return t("admin_SupplierProfile_GovernanceTaskSettings");
            case "/Admin/MeetingForums":
                return t("governance_MeetingForums");
            case "/Admin/SegmentationChartSettings":
                return t("admin_SupplierProfile_SegmentationChartSettings");
            case "/Admin/SegmentationScoreSettings":
                return t("admin_SupplierProfile_SegmentationScoreSettings");
            case "/Admin/PerformanceSettings":
                return t("admin_SupplierProfile_PerformanceSettings");
            case "/Admin/RiskSettings":
                return t("admin_SupplierProfile_RiskSettings");
            case "/Admin/SummarySettings":
                return t("admin_SupplierProfile_SummarySettings");
            case "/Admin/SustainabilitySettings":
                return t("admin_SupplierProfile_SustainabilitySettings");
            case "/Admin/KpiDefinition":
                return t("admin_KPI_SpecificationDefintionTableTitle");
            case "/Admin/KPICustomColumns":
                return t("admin_KpiCustomColumn_PageTitle");
            case "/Admin/KpiUpload":
                return t("admin_KpiUpload_KpiUpload");
            case "/Admin/ReviewsCriteriaGroup":
                return t("admin_ReviewCriteria_Group");
            case "/Admin/ReviewsCriteria":
                return t("admin_Review_Criteria");
            case "/Admin/ReviewDefaults":
                return t("admin_DefaultReview_ReviewDefaults");
            case "/Admin/SurveyTemplateGroup":
                return t("admin_SurveyTemplate_Group");
            case "/Admin/SurveyTemplateOverview":
                return t("admin_SurveyTemplate_Overview");
            case "/Admin/NCRFields":
                return t("admin_NcrFields_PageTitle");
            case "/Admin/NcrReasons":
                return t("admin_Ncr_NcrReason");
            case "/Admin/TemplateGroup":
                return t("admin_Compliance_TemplateGroup");
            case "/Admin/TemplateGroupMatrix":
                return t("admin_Compliance_TemplateGroupMatrix");
            case "/Admin/TemplateOverview":
                return t("admin_Compliance_TemplateOverview");
            case "/Admin/RequestInformation":
                return t("admin_RequestInformation_PageTitle");
            case "/Admin/Gatekeepers":
                return t("admin_Compliance_Gatekeepers");
            case "/Admin/developmentPlanTemplates":
                return t("admin_developmentPlan_TemplateOverview_PageTitle");

            default:
                return "";
        }
    };

    useEffect(() => {
        const currentTitle = getPageTitle(location.pathname);
        document.title = currentTitle;
    }, [location]);

    return (
        <div data-test="page_title" id="pageTitle" className={classes.title}>
            {getPageTitle(location.pathname)}
        </div>
    );
};
