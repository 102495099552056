import React, { useState, useEffect } from "react";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import {
    Modal,
    Tag,
    Input,
    Alert,
    Tooltip,
    Collapse,
    notification,
    Spin,
    Form,
    Button,
} from "antd";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import IPartnerToCreate from "../../../../models/suppliers/IPartnerToCreate";
import { useTranslation } from "react-i18next";
import { LinkStatusType } from "models";
import {
    useCompanyNumberInUse,
    useCompanyType,
    useGlobalSettings,
    usePartnerCreate,
    useUpdateMergeSet,
} from "controller";

const Panel = Collapse.Panel;

interface ModalProps {
    visible: boolean;
    setMergeModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    selectedRowKeys: string[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    clearSelectedRows(): void;
    updateTable(): void;
}

export const ContentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };
    const [reload, setReload] = useState<any>(null);
    const {
        data: { autoGenerateCompanyNumbers },
        loading,
    } = useGlobalSettings(reload);
    const [isMoreThanOneLinkedInvitedPartners, setIsMoreThanOneLinkedInvitedPartners] = useState(
        false
    );
    const { create } = usePartnerCreate();
    const { updateMergeSet, loading: updateMergeSetLoading } = useUpdateMergeSet();
    const { isCompanyNumberInUse, loading: companyNumberInUseLoading } = useCompanyNumberInUse();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        if (props.visible === true) {
            setReload({ reload: true });
        }
        form.resetFields();
    }, [props.visible]);

    useEffect(() => {
        let linkedInvitedPartners = 0;
        props.selectedRows.forEach(x => {
            if (
                x.linked &&
                (x.linked.value.name === LinkStatusType.Linked ||
                    x.linked.value.name === LinkStatusType.Invited)
            ) {
                linkedInvitedPartners++;
            }
        });
        setIsMoreThanOneLinkedInvitedPartners(linkedInvitedPartners > 1);
    }, [props.selectedRows]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: any) => {
            const color =
                row.linked &&
                isMoreThanOneLinkedInvitedPartners &&
                (row.linked.value.name === LinkStatusType.Linked ||
                    row.linked.value.name === LinkStatusType.Invited)
                    ? "red"
                    : "blue";
            return (
                <Tag
                    color={color}
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onCancel() {
        form.resetFields();
        props.setMergeModalVisibility(false);
    }

    async function onSubmit() {
        if (companyNumberInUseLoading) return;
        let values = await form.validateFields();
        let parentPartner: IPartnerToCreate = {
            companyName: values.companyName,
            companyNumber: values.companyNumber,
        };
        const parentId = await create(parentPartner);
        await updateMergeSet(
            parentId,
            props.selectedRowKeys.map(x => parseInt(x))
        );
        notification.success({
            message: t("success"),
            description: t("supplier_MergeSuccessMessage", {
                parent: values.companyName,
                children: props.selectedRows.map(x => x.company.value).join(", "),
            }),
        });
        form.resetFields();
        props.clearSelectedRows();
        await props.updateTable();
        props.setMergeModalVisibility(false);
    }

    return (
        <div>
            <Modal
                wrapProps={{ "data-test": "merge_supplier_modal" }}
                title={t("supplier_MergeSupplierTitle", {
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier")
                        : t("company_Type_Customer"),
                })}
                open={props.visible}
                onOk={() => onSubmit()}
                width={700}
                onCancel={() => onCancel()}
                footer={[
                    <Button
                        disabled={isMoreThanOneLinkedInvitedPartners}
                        loading={updateMergeSetLoading}
                        type="primary"
                        onClick={async () => await onSubmit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Spin spinning={loading}>
                    <Form form={form} {...formItemLayout} layout={"horizontal"} colon={false}>
                        {isMoreThanOneLinkedInvitedPartners && (
                            <Alert
                                style={{ marginBottom: "24px" }}
                                type={"warning"}
                                message={t("supplier_MergeMoreThanOneLinkedPartnerWarning", {
                                    companyType: isDefaultCompany
                                        ? t("company_Type_Supplier").toLowerCase()
                                        : t("company_Type_Customer").toLowerCase(),
                                })}
                            />
                        )}
                        <Form.Item
                            label={
                                t("supplier_SelectedSuppliers", {
                                    companyType: isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer"),
                                }) + " : "
                            }>
                            {props.selectedRows.length === 2 && (
                                <div className="tagArea" style={{ maxWidth: "90%" }}>
                                    {renderTags(false)}
                                </div>
                            )}
                            {props.selectedRows.length > 2 && (
                                <div className="tagArea">{renderTags(true)}</div>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <div>{t("companyName") + " : "}</div>
                                    <div>
                                        <Tooltip
                                            title={t("mergeCompanyNameTooltip")}
                                            className="tooltip">
                                            <QuestionCircleTwoTone twoToneColor="#1890ff" />
                                        </Tooltip>
                                    </div>
                                </>
                            }
                            name={"companyName"}
                            rules={[{ required: true, message: t("companyNameRequired") }]}>
                            <Input placeholder={t("companyName")} className="input" />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    <div>{t("companyNumber") + " : "}</div>
                                    <div>
                                        <Tooltip
                                            title={t("mergeCompanyNumberTooltip")}
                                            className="tooltip">
                                            <QuestionCircleTwoTone twoToneColor="#1890ff" />
                                        </Tooltip>
                                    </div>
                                </>
                            }
                            name={"companyNumber"}
                            rules={[
                                {
                                    required: autoGenerateCompanyNumbers === true ? false : true,
                                    max: 200,
                                    validator: async (rule, value, callback) => {
                                        let companyNumberInputValues: string[] = [];

                                        companyNumberInputValues.push(
                                            form.getFieldValue(`companyNumber`)
                                        );

                                        try {
                                            if (value) {
                                                let hasDuplicateCompanyNumbers =
                                                    companyNumberInputValues.filter(
                                                        x => x === value.toString()
                                                    ).length > 1;
                                                const isNumberInUser: boolean = await isCompanyNumberInUse(
                                                    value
                                                );

                                                if (
                                                    isNumberInUser === true ||
                                                    hasDuplicateCompanyNumbers
                                                ) {
                                                    return Promise.reject(
                                                        new Error(t("companyNumberInUse"))
                                                    );
                                                }
                                            } else if (
                                                !value &&
                                                autoGenerateCompanyNumbers === false
                                            ) {
                                                return Promise.reject(
                                                    new Error(t("companyNumberRequired"))
                                                );
                                            }
                                        } catch (error) {
                                            return Promise.reject(error);
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input
                                placeholder={t("companyNumber")}
                                className="input"
                                maxLength={20}
                                disabled={autoGenerateCompanyNumbers}
                            />
                        </Form.Item>

                        <Form.Item label=" ">
                            <Collapse
                                className="tooltip"
                                style={{ marginLeft: 0, maxWidth: "90%" }}>
                                <Panel header={t("mergeWarningTitle")} key="1">
                                    <Alert
                                        type={"warning"}
                                        message={t("supplier_MergeWarningText", {
                                            companyType: isDefaultCompany
                                                ? t("company_Type_Supplier").toLowerCase()
                                                : t("company_Type_Customer").toLowerCase(),
                                        })}
                                    />
                                </Panel>
                            </Collapse>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};
const ModalMerge = ContentModal;

export default ModalMerge;
