import { useEffect, useState } from "react";
import { Provider } from "services";
import { IActivityChild } from "models";

type StateType = {
    loading: boolean;
    data: any | null;
};

const useReviewRegistration = (reviewId: any, reloadData: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (reviewId !== -1 && reloadData !== false) {
                setState({ ...state, loading: true });
                const data = await Provider.Review.getReviewById(reviewId, {
                    customError: {
                        message: "Could not retrieve Review registration",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [reviewId, reloadData]);

    const addActivity = (parentActivityId: number, newActivity: IActivityChild) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: [newActivity, ...state.data.childrenActivity],
                },
            });
        }
    };

    const removeActivity = (parentActivityId: number, activityId: number) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: state.data.childrenActivity.map(
                        (activity: IActivityChild) => {
                            if (activity.id !== activityId) {
                                return activity;
                            }
                            activity.isDeleted = true;
                            activity.canDelete = false;
                            return activity;
                        }
                    ),
                },
            });
        }
    };

    return {
        loading: state.loading,
        data: state.data,
        addActivity,
        removeActivity,
    };
};

export { useReviewRegistration };
