import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Col, Form, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { FormLabel, FormRow, RichTextEditor, SelectData } from "components/utilitycomponents";

type PropsType = {
    visible: boolean;
    onClose: Function;
    data: any;
    onEdit: Function;
};

const SurveySettingsModal: FunctionComponent<PropsType> = ({ visible, data, onEdit, onClose }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVoiceOfSupplier, setIsVoiceOfSupplier] = useState(data.isVoiceOfSupplier);

    const handleSubmit = async () => {
        const values = await form.validateFields();
        onEdit(values);
    };

    useEffect(() => {
        visible && form.resetFields();
    }, [visible]);

    useEffect(() => {
        form.resetFields();
    }, [data]);
    return (
        <Modal
            wrapProps={{ "data-test": "select_survey_question_modal" }}
            title={t("survey_Wizard_EditOtherSurveySettings")}
            open={visible}
            onOk={() => {
                form.submit();
            }}
            okText={t("generic_Edit")}
            onCancel={() => {
                onClose();
            }}
            destroyOnClose
            width={800}>
            <Form form={form} onFinish={handleSubmit}>
                <FormRow>
                    <Col span={6}>
                        <FormLabel>{t("survey_Visibility")}</FormLabel>
                    </Col>
                    <Col span={18}>
                        <Form.Item name="visibility" initialValue={data ? data.visibility : 1}>
                            <SelectData
                                selectOptionField="name"
                                useData={() => ({
                                    loading: false,
                                    data: [
                                        { id: 1, name: t("internal") },
                                        { id: 2, name: t("external") },
                                    ],
                                })}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                style={{ width: "200px" }}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col span={6}>
                        <FormLabel>{t("survey_VoiceOfSupplier")}</FormLabel>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="isVoiceOfSupplier"
                            valuePropName="checked"
                            initialValue={data ? data.isVoiceOfSupplier : false}>
                            <Checkbox
                                onChange={(e: any) => setIsVoiceOfSupplier(e.target.checked)}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                {isVoiceOfSupplier && (
                    <>
                        <FormRow>
                            <Col span={6}>
                                <FormLabel>{t("survey_Wizard_IntroductionVOS")}</FormLabel>
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    name="introductionVOS"
                                    initialValue={data ? data.introductionVOS : ""}>
                                    <RichTextEditor
                                        placeholder={t("survey_VoiceOfSupplierIntroductionText")}
                                        maxLength={1000}
                                        bordered
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={6}>
                                <FormLabel>{t("survey_VoiceOfSupplierAllowGuests")}</FormLabel>
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    name="allowGuestVOS"
                                    valuePropName="checked"
                                    initialValue={data ? data.allowGuestVOS : false}>
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </>
                )}
                <FormRow>
                    <Col span={6}>
                        <FormLabel>{t("survey_AllowGuests")}</FormLabel>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="allowGuest"
                            valuePropName="checked"
                            initialValue={data ? data.allowGuest : false}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default SurveySettingsModal;
