import { useEffect, useState } from "react";
import { Provider } from "services";
import dayjs from "utilities/daysJsConfig";

const usePerformanceFiles = (partnerId: any, reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            ...state,
            loading: true,
        });
        (async () => {
            const result = await Provider.Performance.getPerformanceFilesAndLinks(partnerId, {
                customError: {
                    message: "Could not retrieve data",
                },
            }).catch(err => {
                setState({
                    ...state,
                    loading: false,
                });
                return Promise.reject(err);
            });
            let files = result.files.map((file: any) => ({ ...file, type: "file" }));
            let links = result.links.map((link: any) => ({ ...link, type: "link" }));
            let data = [...files, ...links];
            data = data.sort((a: any, b: any) => sortCreatedDate(a, b));
            setState({
                loading: false,
                data,
            });
        })();
    }, [reload, partnerId]);

    function sortCreatedDate(a: any, b: any) {
        return dayjs(a.created).isBefore(b.created) ? 1 : -1;
    }

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePerformanceFiles };
