import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    Modal,
    Button,
    Col,
    Row,
    Input,
    notification,
    Tooltip,
    Checkbox,
    DatePicker,
    Spin,
    Form,
} from "antd";
import {
    FormRow,
    FormLabel,
    SelectData,
    CountDownChars,
    SelectTreeDataMultiple,
    SelectDataMultiple,
} from "components/utilitycomponents";
import {
    useFolder,
    useFolderUpdate,
    useFolderExternalPartners,
    useFolderExternalVisibilities,
    useFolderInternalVisibilities,
    useInternalContactsWithUser,
} from "controller";
import { FileInternalVisibilityEnum, FileExternalVisibilityEnum, Modules } from "models";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    data: any;
    onClose: (reload: boolean) => void;
}

const EditFolderModal = ({ data: { name, id, parentId }, visible, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { data, loading } = useFolder(id);
    const { updateFolder, isFolderNameInUse, loading: updateLoading } = useFolderUpdate();
    const [alertDateEnabled, setAlertDateEnabled] = useState(false);
    const [endDateEnabled, setEndDateEnabled] = useState(false);
    const [internalVisibility, setInternalVisibility] = useState(0);
    const [externalVisibility, setExternalVisibility] = useState(0);

    useEffect(() => {
        if (data) {
            setAlertDateEnabled(data.alertDate !== null);
            setEndDateEnabled(data.endDate !== null);
            setInternalVisibility(data.internalVisibility);
            setExternalVisibility(data.externalVisibility);
        }
        form.resetFields();
    }, [data]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const payload = {
            id,
            name: fieldsValue.name,
            endDate: fieldsValue.endDate
                ? fieldsValue.endDate
                : data.canEditReminders
                ? null
                : data.endDate,
            alertDate: fieldsValue.alertDate
                ? fieldsValue.alertDate
                : data.canEditReminders
                ? null
                : data.alertDate,
            alertMessage: fieldsValue.alertMessage
                ? fieldsValue.alertMessage
                : data.canEditReminders
                ? null
                : data.alertMessage,
            internalVisibility: fieldsValue.internalVisibility,
            externalVisibility:
                fieldsValue.externalVisibility !== undefined
                    ? fieldsValue.externalVisibility
                    : null,
            allowUploadFromPartners: fieldsValue.allowUploadFromPartners
                ? fieldsValue.allowUploadFromPartners
                : null,
            selectedPartners:
                fieldsValue.selectedPartners && fieldsValue.selectedPartners.length
                    ? fieldsValue.selectedPartners.map((s: number) => ({
                          selectedPartnerId: s,
                      }))
                    : null,
            selectedInternalContacts:
                fieldsValue.selectedInternalContacts && fieldsValue.selectedInternalContacts.length
                    ? fieldsValue.selectedInternalContacts.map((s: number) => ({
                          selectedContactId: s,
                      }))
                    : null,
            parentId: parentId,
        };
        await updateFolder(payload);
        notification.success({
            message: t("success"),
            description: t("file_FolderUpdateSuccess"),
        });

        form.resetFields();
        onClose(true);
    };

    return (
        <Modal
            wrapProps={{ "data-test": "folder_edit_modal" }}
            title={`${t("file_FolderSettings")}: ${name}`}
            open={visible}
            footer={[
                <Button key="ok" type="primary" onClick={handleSave} loading={updateLoading}>
                    {t("generic_Save")}
                </Button>,
            ]}
            onCancel={() => {
                form.resetFields();
                onClose(false);
            }}
            style={{ top: 20 }}
            width={1000}>
            <Spin spinning={loading}>
                <Form form={form}>
                    <Row gutter={30}>
                        <Col span={12}>
                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <FormLabel required>{t("file_RenameFolder")}</FormLabel>
                                </Col>

                                <Col lg={14} xs={24}>
                                    <Form.Item
                                        name="name"
                                        initialValue={data ? data.name : undefined}
                                        validateTrigger="onBlur"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("inputIsRequiredError"),
                                            },
                                            {
                                                validator: async (
                                                    rule: any,
                                                    value: any,
                                                    callback: any
                                                ) => {
                                                    try {
                                                        if (
                                                            value.toLowerCase() === "_upload" ||
                                                            value.toLowerCase() === "_pictures"
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    t("file_FolderRestrictedName")
                                                                )
                                                            );
                                                        }
                                                        if (
                                                            value !== data.name &&
                                                            (await isFolderNameInUse(
                                                                value,
                                                                parentId ?? null
                                                            ))
                                                        )
                                                            return Promise.reject(
                                                                new Error(t("file_FolderNameInUse"))
                                                            );
                                                    } catch (error) {
                                                        return Promise.reject(error);
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}>
                                        <Input maxLength={256} />
                                    </Form.Item>
                                </Col>
                            </FormRow>
                            {data && data.canEditReminders && parentId === null && (
                                <Fragment>
                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <FormLabel>{t("file_EndDate")}</FormLabel>{" "}
                                            <Tooltip title={t("file_FolderEndDateGuide")}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            <Form.Item
                                                name="endDate"
                                                initialValue={
                                                    data && data.endDate
                                                        ? dayjs(data.endDate)
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        required: endDateEnabled,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <DatePicker
                                                    format={"MMM D, YYYY"}
                                                    style={{ width: "100%" }}
                                                    placeholder={t("file_EndDate")}
                                                    allowClear
                                                    onChange={value => {
                                                        setEndDateEnabled(value !== null);
                                                    }}
                                                    disabledDate={(current: any) =>
                                                        current < dayjs().startOf("day")
                                                    }
                                                    getPopupContainer={node =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </FormRow>

                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <FormLabel>{t("file_AlertDate")}</FormLabel>{" "}
                                            <Tooltip title={t("file_FolderAlertDateGuide")}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            <Form.Item
                                                name="alertDate"
                                                initialValue={
                                                    data && data.alertDate
                                                        ? dayjs(data.alertDate)
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        required: alertDateEnabled,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <DatePicker
                                                    format={"MMM D, YYYY"}
                                                    style={{ width: "100%" }}
                                                    placeholder={t("file_AlertDate")}
                                                    allowClear
                                                    onChange={value => {
                                                        setAlertDateEnabled(value !== null);
                                                    }}
                                                    disabledDate={(current: any) =>
                                                        current < dayjs().startOf("day")
                                                    }
                                                    getPopupContainer={node =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </FormRow>
                                    {alertDateEnabled && (
                                        <FormRow>
                                            <Col lg={10} xs={24}>
                                                <label>{t("file_AlertMessage")}</label>{" "}
                                                <Tooltip title={t("file_AlertMessageGuide")}>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </Col>

                                            <Col lg={14} xs={24}>
                                                <Form.Item
                                                    name="alertMessage"
                                                    initialValue={
                                                        data ? data.alertMessage : undefined
                                                    }>
                                                    <CountDownChars maxChars={500}>
                                                        <TextArea
                                                            rows={4}
                                                            allowClear
                                                            placeholder={t("file_AlertMessage")}
                                                            maxLength={500}
                                                        />
                                                    </CountDownChars>
                                                </Form.Item>
                                            </Col>
                                        </FormRow>
                                    )}
                                </Fragment>
                            )}
                        </Col>
                        <Col span={12}>
                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <label>{t("file_InternalVisibility")}</label>{" "}
                                    <Tooltip title={t("file_InternalVisibilityGuide")}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </Col>

                                <Col lg={14} xs={24}>
                                    <Form.Item
                                        name="internalVisibility"
                                        initialValue={data ? data.internalVisibility : undefined}>
                                        <SelectData
                                            style={{ width: "100%" }}
                                            placeholder={t("file_InternalVisibility")}
                                            selectOptionField="name"
                                            useData={useFolderInternalVisibilities.bind(
                                                null,
                                                data ? data.id : undefined
                                            )}
                                            onChange={(value: number) =>
                                                setInternalVisibility(value)
                                            }
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </FormRow>
                            {internalVisibility === FileInternalVisibilityEnum.Selected && (
                                <FormRow>
                                    <Col lg={10} xs={24}>
                                        <label>{t("file_SelectedUsers")}</label>{" "}
                                        <Tooltip title={t("file_SelectedUsersTooltip")}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </Col>
                                    <Col lg={14} xs={24}>
                                        <Form.Item
                                            name="selectedInternalContacts"
                                            initialValue={
                                                data
                                                    ? data.selectedInternalContacts.map(
                                                          (user: any) => user.selectedContactId
                                                      )
                                                    : undefined
                                            }>
                                            <SelectDataMultiple
                                                style={{ width: "100%" }}
                                                placeholder={t("file_SelectedUsers")}
                                                selectIdField="contactId"
                                                selectOptionField="name"
                                                useData={useInternalContactsWithUser.bind(
                                                    null,
                                                    Modules.Files
                                                )}
                                                getPopupContainer={(node: any) =>
                                                    node
                                                        ? (node.parentNode as HTMLElement)
                                                        : document.body
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </FormRow>
                            )}

                            {(internalVisibility === FileInternalVisibilityEnum.Internal ||
                                internalVisibility === FileInternalVisibilityEnum.Selected) && (
                                <Fragment>
                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <label>{t("file_ExternalVisibility")}</label>{" "}
                                            <Tooltip title={t("file_ExternalVisibilityGuide")}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            <Form.Item
                                                name="externalVisibility"
                                                initialValue={
                                                    data ? data.externalVisibility : undefined
                                                }>
                                                <SelectData
                                                    style={{ width: "100%" }}
                                                    placeholder={t("file_ExternalVisibility")}
                                                    selectOptionField="name"
                                                    useData={useFolderExternalVisibilities.bind(
                                                        null,
                                                        data ? data.id : undefined
                                                    )}
                                                    onChange={(value?: string | number) =>
                                                        setExternalVisibility(Number(value))
                                                    }
                                                    getPopupContainer={(node: any) =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </FormRow>
                                    {externalVisibility !== FileExternalVisibilityEnum.None && (
                                        <FormRow>
                                            <Col lg={10} xs={24}>
                                                <label>{t("file_AllowUploadFromPartners")}</label>{" "}
                                                <Tooltip
                                                    title={t("file_AllowUploadFromPartnersGuide")}>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </Col>

                                            <Col lg={14} xs={24}>
                                                <Form.Item
                                                    name="allowUploadFromPartners"
                                                    valuePropName="checked"
                                                    initialValue={
                                                        data
                                                            ? data.allowUploadFromPartners
                                                            : undefined
                                                    }>
                                                    <Checkbox onChange={() => {}}>
                                                        {t("file_Enabled")}
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </FormRow>
                                    )}
                                    {externalVisibility === FileExternalVisibilityEnum.Selected && (
                                        <FormRow>
                                            <Col xs={24}>
                                                <label>{t("file_SelectedPartners")}</label>{" "}
                                                <Tooltip title={t("file_SelectedPartnersGuide")}>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </Col>

                                            <Col xs={24}>
                                                <Form.Item
                                                    name="selectedPartners"
                                                    initialValue={
                                                        data
                                                            ? data.selectedPartners.map(
                                                                  (s: any) => s.selectedPartnerId
                                                              )
                                                            : undefined
                                                    }
                                                    rules={[
                                                        {
                                                            required:
                                                                externalVisibility ===
                                                                FileExternalVisibilityEnum.Selected,
                                                            message: t("inputIsRequiredError"),
                                                        },
                                                    ]}>
                                                    <SelectTreeDataMultiple
                                                        style={{ width: "100%" }}
                                                        placeholder={t("file_SelectedPartners")}
                                                        useData={useFolderExternalPartners}
                                                        allowClear
                                                        selectAll
                                                        getPopupContainer={(node: any) =>
                                                            node
                                                                ? (node.parentNode as HTMLElement)
                                                                : document.body
                                                        }
                                                        selectOptionField={(item: any) => {
                                                            return {
                                                                text: item.name,
                                                                node: (
                                                                    <>
                                                                        {item.representsCompanyId !==
                                                                        null ? (
                                                                            <>{item.name}</>
                                                                        ) : (
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgba(0, 0, 0, 0.25)",
                                                                                }}>
                                                                                {item.name}
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                ),
                                                                selectable:
                                                                    item.representsCompanyId !==
                                                                    null,
                                                            };
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </FormRow>
                                    )}
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

export default EditFolderModal;
