import { useSelector } from "react-redux";
import { AppState } from "store";

const useFilesFeature = () => {
    const user = useSelector((state: AppState) => state.oidc.user);

    const hasFilesFeature =
        user && user.profile && user.profile["ll-enabledFeature-Files"] === "true";

    return {
        hasFilesFeature,
    };
};

export { useFilesFeature };
