import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Form, Input, notification, Divider, Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import {
    useContactsWithGroups,
    useForumFrequency,
    useForumType,
    useMeetingForumsForSupplier,
} from "controller";
import {
    SelectData,
    SelectPartnerContacts,
    SelectTreeDataMultiple,
} from "components/utilitycomponents";
import { IMeetingForum, Modules } from "models";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { CreateModal as CreateContactModal } from "../../../../../contacts/shared/createModal";
import classes from "./meetingForums.module.scss";
import { TagRender } from "../shared/tagRender";

interface IProps {
    visible: boolean;
    onCancel: Function;
    onSuccess: Function;
    item: IMeetingForum;
    partnerId: number;
}

const CreateEditModal: FunctionComponent<IProps> = ({
    visible,
    onCancel,
    onSuccess,
    item,
    partnerId,
}) => {
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { create, update, createLoading, updateLoading } = useMeetingForumsForSupplier(
        partnerId,
        false
    );
    const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });
    const { data: companyContacts, loading: loadingCompanyContacts } = useContactsWithGroups(
        ["Administrator", "User", "LightUser"],
        Modules.Partner,
        reloadContacts
    );
    const [contactModal, setContactModal] = useState<any>({
        visible: false,
    });

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    const handleSubmit = async () => {
        const values = await form.validateFields();

        let meeting: IMeetingForum = {
            templateId: item ? item.templateId : 0,
            forumType: item && item.isDefault ? item.forumType : values.forumType,
            governance: item && item.isDefault ? item.governance : values.governance,
            frequency: item && item.isDefault ? item.frequency : values.frequency,
            partnerId: partnerId,
            meetingOwners: [
                ...values.meetingOwners.map((id: number) => ({
                    contactId: id,
                    ownerName: null,
                })),
            ],
            companyContacts: [
                ...values.companyContacts.map((id: number) => ({
                    contactId: id,
                    memberName: null,
                })),
            ],
            supplierContacts: [
                ...values.supplierContacts.map((id: number) => ({
                    contactId: id,
                    memberName: null,
                })),
            ],
        };

        if (item) {
            await update(meeting);
            notification.success({
                message: t("success"),
                description: t("generic_UpdateSuccess", {
                    name: t("meetingForum_MeetingForum"),
                }),
            });
        } else {
            await create(meeting);
            notification.success({
                message: t("success"),
                description: t("generic_CreateSuccess", {
                    name: t("meetingForum_MeetingForum"),
                }),
            });
        }
        onSuccess();
    };

    return (
        <>
            <Modal
                title={
                    <div>
                        {item
                            ? t("generic_Edit", { title: t("meetingForum_MeetingForum") })
                            : t("generic_Create", { title: t("meetingForum_MeetingForum") })}
                    </div>
                }
                open={visible}
                onCancel={() => onCancel()}
                onOk={() => form.submit()}
                width={850}
                destroyOnClose
                footer={[
                    <Button
                        loading={createLoading || updateLoading}
                        type="primary"
                        onClick={async () => form.submit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <div
                    style={{ background: "#fff", position: "relative" }}
                    id="meetingForumCreateEdit"></div>
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={handleSubmit}
                    labelAlign="left"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}>
                    {(!item || !item.isDefault) && (
                        <>
                            <h4>{t("generic_Details").toLocaleUpperCase()}</h4>
                            <Form.Item
                                colon={false}
                                name="forumType"
                                label={t("meetingForum_ForumType")}
                                valuePropName="value"
                                initialValue={item ? item.forumType : undefined}
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <SelectData
                                    useData={useForumType}
                                    selectOptionField="name"
                                    placeholder={t("select")}
                                />
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                name="governance"
                                label={t("meetingForum_Governance")}
                                initialValue={item ? item.governance : undefined}
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <Input
                                    maxLength={250}
                                    placeholder={t("meetingForum_NamePlaceholder")}
                                />
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                name="frequency"
                                label={t("meetingForum_Frequency")}
                                initialValue={item ? item.frequency : undefined}
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <SelectData
                                    useData={useForumFrequency}
                                    selectOptionField="name"
                                    placeholder={t("select")}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        colon={false}
                        name="meetingOwners"
                        label={t("meetingForum_Owner")}
                        initialValue={
                            item && item.meetingOwners
                                ? item.meetingOwners
                                      .filter((c: any) => c.contactId !== null)
                                      .map((c: any) => c.contactId)
                                : []
                        }>
                        <SelectTreeDataMultiple
                            allowClear={true}
                            useData={() => ({
                                data: companyContacts,
                                loading: loadingCompanyContacts,
                            })}
                            selectAll
                            selectOptionField="name"
                            placeholder={t("select")}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            tagRender={(props: any) => TagRender(props, false)}
                            selectOnlyChildren
                            dropdownRender={(menu: any) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Button
                                        block
                                        size="small"
                                        onClick={() =>
                                            setContactModal({
                                                visible: true,
                                                partnerId: undefined,
                                                type: "meetingOwners",
                                            })
                                        }>
                                        + {t("Contacts_Add")}
                                    </Button>
                                </>
                            )}
                        />
                    </Form.Item>
                    <Divider />
                    <h4>{t("generic_Representatives").toLocaleUpperCase()}</h4>
                    <Row gutter={8}>
                        <Col span={12}>
                            <span className={classes.representativeLabel}>
                                {t("meetingForum_CompanyMember", {
                                    name: companyName,
                                    interpolation: { escapeValue: false },
                                })}
                            </span>
                            <div className={classes.box}>
                                <Form.Item
                                    wrapperCol={{ span: 22 }}
                                    colon={false}
                                    name="companyContacts"
                                    initialValue={
                                        item && item.companyContacts
                                            ? item.companyContacts
                                                  .filter((c: any) => c.contactId !== null)
                                                  .map((c: any) => c.contactId)
                                            : []
                                    }>
                                    <SelectTreeDataMultiple
                                        style={{ width: "100%", zIndex: 10 }}
                                        bordered={false}
                                        allowClear={true}
                                        useData={() => ({
                                            data: companyContacts,
                                            loading: loadingCompanyContacts,
                                        })}
                                        selectAll
                                        selectOptionField="name"
                                        placeholder={t("select")}
                                        getPopupContainer={(node: any) =>
                                            document.getElementById("meetingForumCreateEdit")
                                        }
                                        selectOnlyChildren
                                        tagRender={(props: any) => TagRender(props, true)}
                                        dropdownRender={(menu: any) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: "8px 0" }} />
                                                <Button
                                                    block
                                                    size="small"
                                                    onClick={() =>
                                                        setContactModal({
                                                            visible: true,
                                                            partnerId: undefined,
                                                            type: "companyContacts",
                                                        })
                                                    }>
                                                    + {t("Contacts_Add")}
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <span className={classes.representativeLabel}>
                                {t("meetingForum_SupplierMember")}
                            </span>
                            <div className={classes.box}>
                                <Form.Item
                                    wrapperCol={{ span: 22 }}
                                    name="supplierContacts"
                                    initialValue={
                                        item && item.supplierContacts
                                            ? item.supplierContacts
                                                  .filter((c: any) => c.contactId !== null)
                                                  .map((c: any) => c.contactId)
                                            : []
                                    }>
                                    <SelectPartnerContacts partnerId={partnerId} bordered={false} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {contactModal.visible && (
                <CreateContactModal
                    visible={contactModal.visible}
                    onClose={(reload, contactId) => {
                        if (reload === false) {
                            setContactModal({
                                visible: false,
                                partnerId: undefined,
                                type: undefined,
                            });
                        } else {
                            setReloadContacts({ reload: true });
                            if (contactModal.type === "meetingOwners") {
                                form.setFieldsValue({
                                    meetingOwners: [
                                        ...form.getFieldValue("meetingOwners"),
                                        contactId,
                                    ],
                                });
                            }
                            if (contactModal.type === "companyContacts") {
                                form.setFieldsValue({
                                    companyContacts: [
                                        ...form.getFieldValue("companyContacts"),
                                        contactId,
                                    ],
                                });
                            }
                            if (contactModal.type === "supplierContacts") {
                                form.setFieldsValue({
                                    supplierContacts: [
                                        ...form.getFieldValue("supplierContacts"),
                                        contactId,
                                    ],
                                });
                            }
                            setContactModal({
                                visible: false,
                                partnerId: undefined,
                                type: undefined,
                            });
                        }
                    }}
                    partnerId={contactModal.partnerId}
                    showSupplierSelect={false}
                />
            )}
        </>
    );
};

export { CreateEditModal };
