import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: number;
};

const useActionsOverdueCount = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: 0,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });

            let data;

            data = await Provider.Action.getOverDueActionsCount({
                customError: {
                    message: "Could not get amount of overdue actions",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ ...state, loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useActionsOverdueCount };
