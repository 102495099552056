import {
    ExcelExportProps,
    SET_EXCELEXPORT,
    CLEAR_EXCELEXPORT,
    SetExcelExport,
    ClearExcelExport,
} from "./types";

export function setExcelExport({
    reportType,
    loading,
    downloadProgress,
    ...rest
}: ExcelExportProps): SetExcelExport {
    return {
        type: SET_EXCELEXPORT,
        payload: {
            reportType,
            loading,
            downloadProgress,
            ...rest,
        },
    };
}

export function clearExcelExport(): ClearExcelExport {
    return {
        type: CLEAR_EXCELEXPORT,
        payload: {
            reportType: undefined,
            loading: false,
            downloadProgress: 0,
        },
    };
}
