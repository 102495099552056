import React, { FunctionComponent } from "react";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FormRow, FormLabel, SelectPartnerContacts } from "components/utilitycomponents";

interface IProps {
    selectedPartners: any[];
}

const SupplierContacts: FunctionComponent<IProps> = ({ selectedPartners }) => {
    const { t } = useTranslation();
    return selectedPartners.length > 0 ? (
        <Row>
            <Col span={24}>
                {selectedPartners.map(partner => (
                    <>
                        <Row>
                            <Col span={24}>{partner.name}</Col>
                        </Row>
                        <FormRow>
                            <Col span={24}>
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                    label={t("receiverContacts")}
                                    required
                                    rules={[{ required: true, message: t("inputIsRequiredError") }]}
                                    name={["receiverContacts", partner.id.toString()]}>
                                    <SelectPartnerContacts partnerId={partner.id} />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </>
                ))}
            </Col>
        </Row>
    ) : (
        <FormRow>
            <Col span={6}>
                <FormLabel>{t("receiverContacts")}</FormLabel>
            </Col>
            <Col span={18}>{t("noReceiverContacts")}</Col>
        </FormRow>
    );
};

export default SupplierContacts;
