import { ICreateAutoSurvey, SurveyPartnerSpecification } from "models";
import { ICustomDropdownHeadline } from "models/survey/ICustomDropdownHeadline";
import { ITextFieldHeadline } from "models/survey/ITextFieldHeadline";

export function parseValuesToAutomatedSurveyObject(values: any) {
    let customDropdownHeadlines: ICustomDropdownHeadline[] = [];
    let textFieldHeadlines: ITextFieldHeadline[] = [];
    let position = 0;
    for (let i = 0; i < values.textFieldTitleSurveyFields.length; i++) {
        if (
            values.textFieldTitleSurveyFields[i] &&
            values.textFieldTitleSurveyFields[i].trim() !== ""
        ) {
            if (values.dropdownSurveyFields && values.dropdownSurveyFields[i]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSurveyFields[i],
                    title: values.textFieldTitleSurveyFields[i],
                    headlineType: "Survey",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSurveyFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSurveyFields[i] !== undefined
                            ? values.textFieldDescriptionSurveyFields[i]
                            : "",
                    title: values.textFieldTitleSurveyFields[i],
                    headlineType: "Survey",
                    position,
                });
                position++;
            }
        }
    }
    position = 0;
    for (let i = 0; i < values.textFieldTitleSupplierFields.length; i++) {
        if (
            values.textFieldTitleSupplierFields[i] &&
            values.textFieldTitleSupplierFields[i].trim() !== ""
        ) {
            if (values.dropdownSupplierFields && values.dropdownSupplierFields[i]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSupplierFields[i],
                    title: values.textFieldTitleSupplierFields[i],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSupplierFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSupplierFields[i] !== undefined
                            ? values.textFieldDescriptionSupplierFields[i]
                            : "",
                    title: values.textFieldTitleSupplierFields[i],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            }
        }
    }
    let surveyPartnerSpecifications = [];
    for (let partnerId of values.partners) {
        let surveyPartnerSpecification: SurveyPartnerSpecification = {
            partnerId: 0,
            contactIds: [],
        };
        surveyPartnerSpecification.partnerId = partnerId;
        if (values.receiverContacts || values.receiverEmails) {
            surveyPartnerSpecification.contactIds = values.receiverContacts[partnerId]
                ? values.receiverContacts[partnerId]
                : [];
        }
        surveyPartnerSpecifications.push(surveyPartnerSpecification);
    }

    let survey: ICreateAutoSurvey = {
        title: values.title,
        introduction: values.introduction,
        endDateDays: values.endDateDays ? Number(values.endDateDays) : undefined,
        selectedPartnerIds: values.partners && values.partners,
        selectedReviewCriteriaIds: values.criteria,
        visibility: values.visibility,
        selectedContactGroupIds: values.contactGroups ? values.contactGroups : [],
        selectedContactIds: values.users ? values.users : [],
        isVoiceOfSupplier: values.isVoiceOfSupplier && values.isVoiceOfSupplier,
        allowGuestOnVOS: values.voiceOfSupplierAllowGuests && values.voiceOfSupplierAllowGuests,
        introductionVOS: values.introductionVOS,
        allowGuest: values.allowGuests && values.allowGuests,
        frequency: values.frequency,
        startDate: values.startDate.toISOString(),
        customDropdownHeadlines: customDropdownHeadlines,
        textFieldHeadlines: textFieldHeadlines,
        surveyTemplateId: values.surveyTemplateId,
        surveyPartnerSpecifications,
    };

    return survey;
}
