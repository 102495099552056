import React, { FunctionComponent, Fragment, useState } from "react";
import { Row, Col, Button, Switch, Select, Tooltip, notification } from "antd";
import classes from "./styles/ncrInteractables.module.scss";
import { SelectData } from "components/utilitycomponents";
import { useNcrUpdate, useNcrOpen, useNcrClose, useCompanyContactsWithAccess } from "controller";
import CloseNcr from "./ncrClose";
import { useTranslation } from "react-i18next";
import { Modules } from "models";
const Option = Select.Option;

type Propstype = {
    data: any;
    approvalStatusLabel: string;
    ncrHolderLabel: string;
    nextResponsibleLabel: string;
    reloadTable: Function;
    openEditModal: Function;
};

const NCRInteractables: FunctionComponent<Propstype> = ({
    data,
    approvalStatusLabel,
    ncrHolderLabel,
    nextResponsibleLabel,
    reloadTable,
    openEditModal,
}) => {
    const colLayout = { span: 10 };
    const [holderContact, setholderContact] = useState(data.holderId);
    const {
        updateApprovalStatus,
        updateHolder,
        removeHolder,
        updateNextResponsible,
        updateSearchTags,
    } = useNcrUpdate();
    const { loading: openNcrLoading, openNcr } = useNcrOpen();
    const { closeNcr, loading: closeNcrLoading } = useNcrClose();
    const [showCloseNcrUi, setShowCloseNcrUi] = useState(false);
    const [ncrStatus, setNcrStatus] = useState(data.ncrStatus);
    const { t } = useTranslation();
    async function onApprovalStatusChange(approvalStatus: string) {
        await updateApprovalStatus(data.id, approvalStatus);
        reloadTable();
        notification.success({
            message: "Success",
            description: `Approval status was updated successfully`,
        });
    }

    async function onHolderChange(holderId: number | undefined) {
        setholderContact(holderId);
        if (holderId === undefined) {
            await removeHolder(data.id);
        } else {
            await updateHolder(data.id, holderId);
        }
        notification.success({
            message: t("success"),
            description: `NCR holder was updated successfully`,
        });
        reloadTable();
    }

    async function onSearchTagsChange(val: any) {
        await updateSearchTags(data.id, val);
        reloadTable();
        notification.success({
            message: "Success",
            description: `Search tags were updated successfully`,
        });
    }
    async function onCloseNcrClick() {
        await closeNcr(data.id);
        setNcrStatus("Closed");
        setShowCloseNcrUi(true);
        reloadTable();
        notification.success({
            message: "Success",
            description: `Your registration was closed successfully`,
        });
    }

    async function onOpenNcrClick() {
        await openNcr(data.id);
        setNcrStatus("Open");
        reloadTable();
        notification.success({
            message: "Success",
            description: `Registration was re-opened successfully`,
        });
    }

    async function onNextResponsibleChange(checked: boolean) {
        let nextResponsible: string = checked === false ? "Us" : "Them";
        await updateNextResponsible(data.id, nextResponsible);
        reloadTable();
        notification.success({
            message: t("success"),
            description: t("ncrNextResponsinbleSuccess"),
        });
    }

    return (
        <Fragment>
            {data.externallyCreated === false && (
                <Fragment>
                    <Row>
                        <Col span={14}></Col>
                        <Col span={4}>
                            <div>{t("searchTags")}:</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col {...colLayout}>
                            <div style={{ textAlign: "right" }}></div>
                            {ncrStatus === "Open" && (
                                <Button
                                    data-test="close_ncr_button"
                                    loading={closeNcrLoading}
                                    className={classes.greenActionButton}
                                    onClick={() => onCloseNcrClick()}>
                                    {t("closeNcr")}
                                </Button>
                            )}
                            {ncrStatus === "Closed" && (
                                <Button
                                    data-test="reopen_ncr_button"
                                    loading={openNcrLoading}
                                    danger
                                    className={classes.actionButton}
                                    onClick={() => onOpenNcrClick()}>
                                    {t("reopenNcr")}
                                </Button>
                            )}
                            <Button
                                data-test="edit_ncr_button"
                                type="primary"
                                className={classes.actionButton}
                                onClick={() => openEditModal()}>
                                {t("editNcr")}
                            </Button>
                            <Tooltip title={nextResponsibleLabel}>
                                <Select
                                    style={{ width: 80 }}
                                    defaultValue={data.nextResponsible === "Them"}
                                    options={[
                                        { value: true, label: t("ncrNextResponsible_Them") },
                                        { value: false, label: t("ncrNextResponsible_Us") },
                                    ]}
                                    onChange={(value: boolean) => onNextResponsibleChange(value)}
                                />
                            </Tooltip>
                        </Col>
                        <Col span={4}></Col>
                        <Col {...colLayout}>
                            <Select
                                data-test="search_tags_select"
                                onChange={(val: any) => onSearchTagsChange(val)}
                                style={{ width: "100%" }}
                                mode="tags"
                                defaultValue={
                                    data.searchTags !== null ? data.searchTags : undefined
                                }
                                placeholder={`${t("input")} ${t("searchTags")}`}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }></Select>
                        </Col>
                    </Row>
                    {showCloseNcrUi && (
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={24}>
                                <CloseNcr
                                    data={data}
                                    onClose={() => {
                                        setShowCloseNcrUi(false);
                                        reloadTable();
                                    }}
                                    reloadTable={() => reloadTable()}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row style={{ marginTop: "16px" }}>
                        <Col {...colLayout}>
                            <div>{approvalStatusLabel}:</div>
                            <Select
                                data-test="approval_status_select"
                                onChange={(value: string) => onApprovalStatusChange(value)}
                                className={classes.approvalSelect}
                                //mode="default"
                                defaultValue={data.approvalStatus}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }>
                                <Option value={"Undecided"}>{t("ncrStatusUndecided")}</Option>
                                <Option value={"Approved"}>{t("ncrStatusApproved")}</Option>
                                <Option value={"Not approved"}>{t("ncrStatusNotApproved")}</Option>
                            </Select>
                        </Col>
                        <Col span={4}></Col>
                        <Col {...colLayout}>
                            <div>{ncrHolderLabel}:</div>
                            <SelectData
                                data-test="ncr_holder_select"
                                className={classes.select}
                                useData={useCompanyContactsWithAccess.bind(
                                    null,
                                    ["Administrator", "User"],
                                    Modules.Ncr,
                                    true
                                )}
                                selectOptionField="name"
                                selectIdField="id"
                                placeholder={`${t("select")} ${ncrHolderLabel}`}
                                value={holderContact ? holderContact : undefined}
                                onChange={(value: number | undefined) => onHolderChange(value)}
                                allowClear
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
            {data.externallyCreated && data.ncrStatus === "Open" && (
                <Fragment>
                    <Tooltip title={t("nextResponsbile")}>
                        <Switch
                            data-test="next_responsible"
                            className={classes.toggle}
                            checkedChildren={t("ncrNextResponsible_Them")}
                            unCheckedChildren={t("ncrNextResponsible_Us")}
                            defaultChecked={!(data.nextResponsible === "Them")}
                            onChange={(value: boolean) => onNextResponsibleChange(!value)}
                        />
                    </Tooltip>
                </Fragment>
            )}
        </Fragment>
    );
};

export default NCRInteractables;
