import { useState } from "react";
import Provider from "services/api";

const useContactGroupUpdate = () => {
    const [loading, setLoading] = useState(false);

    const create = async (contactGroupCreateInput: any) => {
        setLoading(true);
        const id = await Provider.Contact.createGroup(contactGroupCreateInput, {
            customError: {
                message: "Could not create new group",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const update = async (contactGroupUpdateInput: any) => {
        setLoading(true);
        const id = await Provider.Contact.updateGroup(contactGroupUpdateInput, {
            customError: {
                message: "Could not update the group",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const remove = async (ids: any[]) => {
        setLoading(true);
        const id = await Provider.Contact.deleteGroup(ids, {
            customError: {
                message: "Could not delete the group",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        createGroup: create,
        updateGroup: update,
        deleteGroup: remove,
    };
};

export { useContactGroupUpdate };
