import React, { Fragment, useState, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Input, Form } from "antd";
import { Plus, Star, X } from "components/utilitycomponents";
import classes from "./styles/submissionMatrix.module.scss";
const TextArea = Input.TextArea;

interface IProps {
    ratingTexts: any[];
    fieldKey: any;
}

const CriteriaCard: FunctionComponent<IProps> = ({ ratingTexts, fieldKey }) => {
    const { t } = useTranslation();
    const [toggleTextArea, setToggleTextArea] = useState(false);

    return (
        <Fragment>
            <Row>
                <Col span={24}>
                    <Form.Item name={[`reviewRatings`, fieldKey]}>
                        <Star tooltips={ratingTexts.map(r => (r.translate ? t(r.text) : r.text))} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row>
                        {toggleTextArea === false && (
                            <Col span={8}>
                                <Button
                                    onClick={() => setToggleTextArea(true)}
                                    size="small"
                                    icon={<Plus />}>
                                    {t("generic_Notes")}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            {toggleTextArea && (
                <Row style={{ marginTop: "6px" }}>
                    <Col span={22}>
                        <Form.Item name={[`reviewComments`, fieldKey]}>
                            <TextArea maxLength={250} autoSize={{ minRows: 3, maxRows: 10 }} />
                        </Form.Item>
                    </Col>
                    <Col
                        span={2}
                        style={{ textAlign: "center", alignSelf: "center", color: "#ff4d4f" }}>
                        <X className={classes.closeIcon} onClick={() => setToggleTextArea(false)} />
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default CriteriaCard;
