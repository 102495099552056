import React, { useState } from "react";
import { Col, Row } from "antd";
import { Gauge } from "components/utilitycomponents";
import classes from "../../summary.module.scss";
import { useSupplierProfileComplianceScore, useReputationScore, useCarbonScore } from "controller";
import RegistrationModal from "components/contentcomponents/compliance/overview/registrationModal/registrationModal";
import { useTranslation } from "react-i18next";

interface IProps {
    showReputationScore?: boolean;
    showMaturityScore?: boolean;
    showCarbonSccore?: boolean;
    partnerId: number;
}

const GuageMetrics = ({
    showCarbonSccore = true,
    showMaturityScore = true,
    showReputationScore = true,
    partnerId,
}: IProps) => {
    const { t } = useTranslation();
    const [registrationModal, setRegistrationModal] = useState({
        id: 0,
        visible: false,
    });
    const {
        data: complianceScoreData,
        loading: loadingComplianceScore,
    } = useSupplierProfileComplianceScore(partnerId);
    const { data: reputationScoreData, loading: loadingReputationScore } = useReputationScore(
        partnerId
    );
    const { data: carbonScoreData, loading: loadingCarbonScore } = useCarbonScore(partnerId);

    const reputationScoreScaleValues = [
        { percentage: 50, color: "#00b204", description: t("risk_LowRisk") },
        {
            percentage: 80,
            color: "#ff7a45",
            description: t("risk_MediumRisk"),
        },
        { percentage: 100, color: "#b20000", description: t("risk_HighRisk") },
    ];

    const carbonScoreScaleValues = [
        {
            percentage: 20,
            color: "#00E004",
            description: t("generic_VeryLow"),
        },
        {
            percentage: 40,
            color: "#00b204",
            description: t("generic_Low"),
        },
        {
            percentage: 60,
            color: "#ffd965",
            description: t("generic_Medium"),
        },
        {
            percentage: 80,
            color: "#ff7a45",
            description: t("generic_High"),
        },
        {
            percentage: 100,
            color: "#b20000",
            description: t("generic_VeryHigh"),
        },
    ];

    const susScaleValues = [
        {
            percentage: 20,
            color: "#b20000",
            description: t("generic_VeryLow"),
        },
        {
            percentage: 40,
            color: "#ff7a45",
            description: t("generic_Low"),
        },
        {
            percentage: 60,
            color: "#ffd965",
            description: t("generic_Medium"),
        },
        {
            percentage: 80,
            color: "#00b204",
            description: t("generic_High"),
        },
        {
            percentage: 100,
            color: "#00E004",
            description: t("generic_VeryHigh"),
        },
    ];

    const gaugeSpan =
        showReputationScore === true && showMaturityScore === true && showCarbonSccore === true
            ? 8
            : showReputationScore === false &&
              showMaturityScore === true &&
              showCarbonSccore === true
            ? 12
            : 24;

    return (
        <>
            <Row className={classes.marginBottom} gutter={16} style={{ paddingRight: 1 }}>
                {showReputationScore && (
                    <Col span={gaugeSpan}>
                        <Gauge
                            title={t("supplierProfile_summary_ReputationScore").toUpperCase()}
                            type="dashboard"
                            modifiedFieldName="created"
                            useData={() => ({
                                data: reputationScoreData,
                                loading: loadingReputationScore,
                            })}
                            format={"number"}
                            maxNumber={10}
                            strokeWidth={10}
                            tooltipPosition="right"
                            strokeLinecap="round"
                            scaleValues={reputationScoreScaleValues}
                        />
                    </Col>
                )}
                {showMaturityScore && (
                    <Col span={gaugeSpan}>
                        <Gauge
                            title={t("supplierProfile_summary_MaturityScore").toUpperCase()}
                            type="dashboard"
                            modifiedFieldName="lastUpdated"
                            useData={() => ({
                                data: complianceScoreData,
                                loading: loadingComplianceScore,
                            })}
                            format={"percentage"}
                            maxNumber={5}
                            strokeWidth={10}
                            tooltipPosition="right"
                            onClick={() => {
                                if (complianceScoreData && complianceScoreData.hasAccess === true) {
                                    setRegistrationModal({
                                        id: complianceScoreData.complianceId,
                                        visible: true,
                                    });
                                }
                            }}
                            strokeLinecap="round"
                            scaleValues={susScaleValues}
                        />
                    </Col>
                )}
                {showCarbonSccore && (
                    <Col span={gaugeSpan}>
                        <Gauge
                            hideNumber={true}
                            title={t("supplierProfile_summary_CarbonContribution").toUpperCase()}
                            type="dashboard"
                            modifiedFieldName="created"
                            useData={() => ({
                                data: carbonScoreData,
                                loading: loadingCarbonScore,
                            })}
                            format={"percentage"}
                            maxNumber={5}
                            strokeWidth={10}
                            tooltipPosition="right"
                            strokeLinecap="round"
                            scaleValues={carbonScoreScaleValues}
                        />
                    </Col>
                )}
            </Row>
            {registrationModal.visible && (
                <RegistrationModal
                    id={registrationModal.id}
                    visible={registrationModal.visible}
                    onClose={() => {
                        setRegistrationModal({ id: 0, visible: false });
                    }}
                />
            )}
        </>
    );
};

export { GuageMetrics };
