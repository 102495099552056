import { useState } from "react";
import Provider from "services/api";

const useRelationshipCategoryCRUD = () => {
    const [loading, setLoading] = useState(false);

    const createCategory = async (category: any) => {
        setLoading(true);
        const id = await Provider.Relationships.createRelationshipCategory(category, {
            customError: {
                message: "Could not create category",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const updateCategory = async (category: any) => {
        setLoading(true);
        await Provider.Relationships.updateRelationshipCategory(category, {
            customError: {
                message: "Could not update category",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const deleteCategory = async (id: number) => {
        setLoading(true);
        await Provider.Relationships.deleteRelationshipCategory(id, {
            customError: {
                message: "Could not delete category",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const reorder = async (categories: any[]) => {
        setLoading(true);
        await Provider.Relationships.updateRelationCategoriesOrders(categories, {
            customError: {
                message: "Could not update category",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        createCategory,
        updateCategory,
        deleteCategory,
        reorder,
    };
};

export { useRelationshipCategoryCRUD };
