import { IScorecardMetric } from "models";
import { useState } from "react";
import Provider from "services/api";

const useScorecardMetricCRUD = () => {
    const [loading, setLoading] = useState(false);

    const createScorecardMetric = async (payload: IScorecardMetric) => {
        setLoading(true);
        const id = await Provider.Scorecard.createMetric(payload).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const deleteScorecardMetric = async (id: number) => {
        setLoading(true);
        await Provider.Scorecard.deleteMetric(id).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        createScorecardMetric,
        deleteScorecardMetric,
    };
};

export { useScorecardMetricCRUD };
