import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Row,
    Col,
    Input,
    Select,
    Divider,
    Button,
    Spin,
    notification,
    Tooltip,
    InputNumber,
    Form,
    Flex,
} from "antd";
import {
    SelectData,
    SelectDataMultiple,
    PageContainer,
    SelectPartnerContacts,
    Plus,
    UserPlus01,
    UserX01,
    UserRight01,
} from "components/utilitycomponents";
import {
    usePartnerStatuses,
    usePartnerMasterData,
    usePartnerOwners,
    usePartnerCategories,
    usePartnerGroups,
    usePartnerUpdate,
    useIsCompanyNumberInUse,
    useNcrFeature,
    useCustomDropDown,
} from "controller";
import { categoryType } from "models";
import { getHeadLines } from "./getHeadLines";
import { getCountries } from "./getCountries";
import { getCurrencies } from "./getCurrencies";
import CreateGroupModal from "components/contentcomponents/suppliers/masterdataOverviewModals/createGroupModal";
import CreateCategoryModal from "components/contentcomponents/suppliers/masterdataOverviewModals/createCategoryModal";
import SwitchOwnerModal from "./switchOwnerModal";
import classes from "./styles/masterData.module.scss";
const Option = Select.Option;
const TextArea = Input.TextArea;

interface IProps {
    partnerId: number;
    partnerName: string;
    onReloadPartnerData: Function;
}

const MasterData = ({ partnerId, partnerName, onReloadPartnerData }: IProps) => {
    const [form] = Form.useForm();
    const { hasNcrFeature } = useNcrFeature();
    const { t } = useTranslation();
    const userType = useSelector((state: any) => state.oidc.user.profile["ll-Usertype"]);
    const currentUserContactId = useSelector(
        (state: any) => state.oidc.user.profile["ll-ContactId"]
    );
    const userFullName = useSelector((state: any) => state.oidc.user.profile["ll-Fullname"]);
    const [reload, setReload] = useState({ changed: false });
    const { loading, data, companySettings } = usePartnerMasterData(partnerId, reload);
    const { loading: statusLoading, data: statuses } = usePartnerStatuses();
    const { loading: updateLoading, updatePartnerDetail, updatePartnerOwners } = usePartnerUpdate();
    const { checkForExistingNumber } = useIsCompanyNumberInUse();
    const [showModals, setShowModals] = useState<any>({
        showCreateGroupModal: false,
        showCreateCategoryModal: false,
        categoryType: null,
        showSwitchOwnerModal: false,
    });
    const [categoryAdded, setCategoryAdded] = useState<any>({
        added: false,
    });
    const [groupAdded, setGroupAdded] = useState<any>({
        added: false,
    });
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined);
    const { data: customDropdowns } = useCustomDropDown();
    useEffect(() => {
        form.resetFields();
    }, [partnerId]);

    useEffect(() => {
        if (data !== null) {
            setSelectedCurrency(data.currency ? data.currency : undefined);
        }
        form.resetFields();
    }, [data]);

    const {
        CompanyNameHeadline,
        CompanyNumberHeadline,
        OwnerHeadline,
        GroupHeadline,
        Category1Headline,
        Category2Headline,
        ValueHeadline,
        POCFirstNameHeadline,
        POCLastNameHeadline,
        POCPhoneHeadline,
        POCCountryHeadline,
        EmailAddressHeadline,
        StatusHeadline,
        DomainHeadline,
    } = getHeadLines(
        data && data.partnerHeadlineSetting ? data.partnerHeadlineSetting.typeValue : undefined,
        t
    );

    const onSelectCurrencyChange = (currencyCode: string) => {
        if (currencyCode === undefined) {
            form.setFieldsValue({ yearlyVal: undefined });
        }
        setSelectedCurrency(currencyCode);
    };

    const handleAddCategory = (categoryType: categoryType) => {
        setShowModals({
            ...showModals,
            showCreateCategoryModal: true,
            categoryType,
        });
    };

    const handleAddGroup = () => {
        setShowModals({
            ...showModals,
            showCreateGroupModal: true,
        });
    };

    const setSelfAsOwner = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const oldOwners = form.getFieldValue("owner") ? form.getFieldValue("owner") : [];
        const newOwners = [...oldOwners, parseInt(currentUserContactId)];
        await updatePartnerOwners(partnerId, newOwners);
        form.setFieldsValue({ owner: newOwners });
        notification.success({
            message: t("success"),
            description: t("updateOwnerSuccess", {
                ownerHeadline: OwnerHeadline.customLabel ?? t("owner"),
            }),
        });
        setReload({ changed: true });
    };

    const removeSelfAsOwner = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const owners = form.getFieldValue("owner");
        owners.splice(owners.indexOf(parseInt(currentUserContactId)), 1);
        await updatePartnerOwners(partnerId, owners);
        form.setFieldsValue({ owner: owners });
        notification.success({
            message: t("success"),
            description: t("updateOwnerSuccess", {
                ownerHeadline: OwnerHeadline.customLabel ?? t("owner"),
            }),
        });
        setReload({ changed: true });
    };

    const handleSubmit = async () => {
        let fieldsValue = await form.validateFields();
        const payload: any = {
            companyName: fieldsValue.companyName.trim(),
            companyNumber: fieldsValue.companyNumber.trim(),
            domain: fieldsValue.domain ? fieldsValue.domain.trim() : undefined,
            pocFirstName: fieldsValue.pocFirstName,
            pocLastName: fieldsValue.pocLastName,
            pocEmail: fieldsValue.email,
            pocPhone: fieldsValue.pocPhone,
            pocCountry: fieldsValue.pocCountry,
            yearlyValueOfCooperation: parseFloat(fieldsValue.yearlyVal),
            currency: selectedCurrency,
            statusId: fieldsValue.status,
            groupId: fieldsValue.group,
            ownerIds: fieldsValue.owner,
            category1Ids: fieldsValue.cat1,
            category2Ids: fieldsValue.cat2,
            customColumnValues: data!.customColumns.map((column: any) => ({
                customColumnId: column.id,
                value: fieldsValue[`customColumn-${column.id}`],
            })),
            complianceContacts: [...new Set(fieldsValue.complianceContacts)],
            ncrContacts: hasNcrFeature ? [...new Set(fieldsValue.ncrContacts)] : [],
        };
        if (fieldsValue.status !== data!.statusId) {
            payload.statusDescription =
                fieldsValue.status !== undefined
                    ? t("supplier_statusUpdated", {
                          userFullName,
                          statusName: statuses.filter((x: any) => x.id === fieldsValue.status)[0]
                              .name,
                      })
                    : t("supplier_statusRemoved", { userFullName });
        }
        await updatePartnerDetail(partnerId, payload);
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateMasterDataSuccess"),
        });
        form.setFieldsValue({ companyName: fieldsValue.companyName.trim() });
        form.setFieldsValue({ companyNumber: fieldsValue.companyNumber.trim() });
        setReload({ changed: true });
        if (fieldsValue.companyName !== partnerName) {
            onReloadPartnerData();
        }
    };
    if (!loading && (data === null || companySettings === null)) return null;

    //TODO authorization should come from backend
    /* authorization */
    let canEdit = data?.canEdit;
    let canEditOwner = false;
    let canAddSelfToOwners = false;
    let canRemoveSelfFromOwners = false;
    let canSwitchOwner = false;
    if (data !== null && companySettings !== null) {
        let isOwnerOnRecord = data.ownerIds.some((x: any) => x.toString() === currentUserContactId);
        if (userType === "administrator") {
            canEditOwner = true;
            canAddSelfToOwners = !isOwnerOnRecord;
            canRemoveSelfFromOwners = isOwnerOnRecord;
            canSwitchOwner = isOwnerOnRecord;
        }
        if (userType === "user") {
            canAddSelfToOwners =
                !isOwnerOnRecord && companySettings.allowUsersMakeThemSelvesAsOwner;

            canRemoveSelfFromOwners =
                isOwnerOnRecord && companySettings.allowUsersMakeThemSelvesAsOwner;

            canSwitchOwner = isOwnerOnRecord && companySettings.allowNormalUsersToSwitchOwner;
        }
    }
    /* end authorization */

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            lg: { span: 22 },
            xl: { span: 22 },
        },
        wrapperCol: {
            xs: { span: 24 },
            lg: { span: 22 },
            xl: { span: 22 },
        },
    };
    const middleFormItemLayout = {
        labelCol: {
            xs: { span: 24 },
            lg: { span: 22 },
            xl: { span: 22 },
        },
        wrapperCol: {
            xs: { span: 24 },
            lg: { span: 22 },
            xl: { span: 22 },
        },
    };

    return (
        <Spin spinning={loading || updateLoading || statusLoading}>
            <PageContainer fluid gridContainer style={{ padding: 8, borderRadius: 5 }}>
                {data && data.partnerHeadlineSetting && (
                    <Form form={form} layout="vertical" labelWrap labelAlign="left">
                        <Row>
                            <Col xxl={8} lg={8} xs={24}>
                                <Form.Item
                                    className={classes.formItem}
                                    label={CompanyNameHeadline.customLabel ?? t("companyName")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="companyName"
                                    initialValue={data ? data.companyName : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            CompanyNameHeadline.customLabel ?? t("companyName")
                                        }
                                        maxLength={200}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={CompanyNumberHeadline.customLabel ?? t("companyNumber")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="companyNumber"
                                    initialValue={data ? data.companyNumber : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            validator: async (
                                                rule: any,
                                                value: any,
                                                callback: any
                                            ) => {
                                                if (!value || value.trim() === "")
                                                    return Promise.reject(
                                                        new Error(t("inputIsRequiredError"))
                                                    );
                                                else if (
                                                    data &&
                                                    value.toLowerCase().trim() !==
                                                        data.companyNumber.toLowerCase().trim()
                                                ) {
                                                    if (
                                                        await checkForExistingNumber(value.trim())
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(t("companyNumberInUse"))
                                                        );
                                                    }
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            CompanyNumberHeadline.customLabel ?? t("companyNumber")
                                        }
                                        maxLength={20}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={classes.formItem}
                                    name="domain"
                                    label={DomainHeadline.customLabel ?? t("partner_Website")}
                                    rules={[
                                        {
                                            required: DomainHeadline.isMandatory,
                                            whitespace: true,
                                            type: "url",
                                        },
                                    ]}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    initialValue={data.domain ? data.domain : undefined}>
                                    <Input
                                        disabled={!canEdit}
                                        maxLength={253}
                                        placeholder={t("partner_WebsitePlaceholder")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={classes.formItem}
                                    label={POCFirstNameHeadline.customLabel ?? t("pocFirstName")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="pocFirstName"
                                    initialValue={
                                        data && data.pocFirstName ? data.pocFirstName : undefined
                                    }
                                    rules={[
                                        {
                                            required: POCFirstNameHeadline.isMandatory,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            POCFirstNameHeadline.customLabel ?? t("pocFirstName")
                                        }
                                        maxLength={200}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={POCLastNameHeadline.customLabel ?? t("pocLastName")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="pocLastName"
                                    initialValue={
                                        data && data.pocLastName ? data.pocLastName : undefined
                                    }
                                    rules={[
                                        {
                                            required: POCLastNameHeadline.isMandatory,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={
                                            POCLastNameHeadline.customLabel ?? t("pocLastName")
                                        }
                                        maxLength={200}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={EmailAddressHeadline.customLabel ?? t("pocEmailAddress")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="email"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: EmailAddressHeadline.isMandatory,
                                            type: "email",
                                            message: t("pocEmailAddressValidation"),
                                        },
                                    ]}
                                    initialValue={
                                        data && data.pocEmail ? data.pocEmail : undefined
                                    }>
                                    <Input
                                        placeholder={
                                            EmailAddressHeadline.customLabel ?? t("pocEmailAddress")
                                        }
                                        maxLength={300}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={POCPhoneHeadline.customLabel ?? t("pocPhone")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="pocPhone"
                                    initialValue={data && data.pocPhone ? data.pocPhone : undefined}
                                    rules={[
                                        {
                                            required: POCPhoneHeadline.isMandatory,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={POCPhoneHeadline.customLabel ?? t("pocPhone")}
                                        maxLength={50}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={POCCountryHeadline.customLabel ?? t("pocCountry")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="pocCountry"
                                    initialValue={
                                        data && data.pocCountry ? data.pocCountry : undefined
                                    }
                                    rules={[
                                        {
                                            required: POCCountryHeadline.isMandatory,
                                            whitespace: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <Select
                                        placeholder={
                                            POCCountryHeadline.customLabel ?? t("pocCountry")
                                        }
                                        showSearch
                                        allowClear
                                        disabled={!canEdit}>
                                        {getCountries().map(
                                            (element: {
                                                TwoLetterIsoCode: string;
                                                EnglishName: string;
                                            }) => {
                                                return (
                                                    <Option
                                                        key={element.TwoLetterIsoCode}
                                                        value={element.TwoLetterIsoCode}>
                                                        {element.TwoLetterIsoCode}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                <Row gutter={8}>
                                    <Col span={15}>
                                        <Form.Item
                                            className={classes.formItem}
                                            label={ValueHeadline.customLabel ?? t("supplier_Value")}
                                            name="yearlyVal"
                                            initialValue={
                                                data && data.yearlyValueOfCooperation
                                                    ? data.yearlyValueOfCooperation.toString()
                                                    : undefined
                                            }
                                            rules={[
                                                {
                                                    required: ValueHeadline.isMandatory,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <InputNumber
                                                style={{ width: "99%" }}
                                                placeholder={
                                                    ValueHeadline.customLabel ?? t("yearlyValue")
                                                }
                                                disabled={
                                                    selectedCurrency === undefined || !canEdit
                                                }
                                                maxLength={15}
                                                decimalSeparator={","}
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Select
                                            id="currency"
                                            placeholder={t("currencyPlaceholder")}
                                            showSearch
                                            allowClear
                                            value={selectedCurrency}
                                            onChange={(value: string, option: any) =>
                                                onSelectCurrencyChange(
                                                    option ? option.key : undefined
                                                )
                                            }
                                            disabled={!canEdit}
                                            style={{
                                                width: 100,
                                                // position: "absolute",
                                                bottom: -30,
                                                left: -5,
                                            }}>
                                            {getCurrencies().map(
                                                (element: {
                                                    CurrencyCode: string;
                                                    EnglishName: string;
                                                }) => {
                                                    return (
                                                        <Option
                                                            key={element.CurrencyCode}
                                                            value={element.CurrencyCode}>
                                                            {element.CurrencyCode}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Col>
                                </Row>

                                <Form.Item
                                    className={classes.formItem}
                                    label={StatusHeadline.customLabel ?? t("generic_Status")}
                                    labelCol={formItemLayout.labelCol}
                                    wrapperCol={formItemLayout.wrapperCol}
                                    name="status"
                                    initialValue={data && data.statusId ? data.statusId : undefined}
                                    rules={[
                                        {
                                            required: StatusHeadline.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectData
                                        placeholder={
                                            StatusHeadline.customLabel ?? t("generic_Status")
                                        }
                                        useData={usePartnerStatuses}
                                        selectOptionField="name"
                                        disabled={!canEdit}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} lg={8} xs={24}>
                                <Form.Item
                                    className={classes.formItem}
                                    label={GroupHeadline.customLabel ?? t("generic_Group")}
                                    labelCol={middleFormItemLayout.labelCol}
                                    wrapperCol={middleFormItemLayout.wrapperCol}
                                    name="group"
                                    initialValue={data && data.groupId ? data.groupId : undefined}
                                    rules={[
                                        {
                                            required: GroupHeadline.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectData
                                        useData={usePartnerGroups.bind(null, groupAdded)}
                                        selectOptionField="name"
                                        allowClear
                                        placeholder={
                                            GroupHeadline.customLabel ?? t("generic_Group")
                                        }
                                        disabled={!canEdit}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() => handleAddGroup()}>
                                                    <Plus /> {t("add")}{" "}
                                                    {GroupHeadline.customLabel ??
                                                        t("generic_Group")}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={
                                        <>
                                            <div style={{ marginRight: 5 }}>
                                                {OwnerHeadline.customLabel ?? t("owner")}
                                            </div>
                                            <Flex justify="flex-start">
                                                {canAddSelfToOwners && (
                                                    <Tooltip
                                                        title={t("setSelfAsOwnerTooltip", {
                                                            ownerHeadline:
                                                                OwnerHeadline.customLabel ??
                                                                t("owner"),
                                                        })}>
                                                        <span>
                                                            <UserPlus01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="setSelfAsOwner_icon"
                                                                onClick={(e: any) =>
                                                                    setSelfAsOwner(e)
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {canRemoveSelfFromOwners && (
                                                    <Tooltip
                                                        title={t("removeAsOwnerTooltip", {
                                                            ownerHeadline:
                                                                OwnerHeadline.customLabel ??
                                                                t("owner"),
                                                        })}>
                                                        <span>
                                                            <UserX01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="removeSelfAsOwner_btn"
                                                                onClick={(e: any) =>
                                                                    removeSelfAsOwner(e)
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {canSwitchOwner && (
                                                    <Tooltip
                                                        title={`${t(
                                                            "switchOwnerTooltip"
                                                        )} ${OwnerHeadline.customLabel ??
                                                            t("owner")}`}>
                                                        <span>
                                                            <UserRight01
                                                                style={{
                                                                    textAlign: "left",
                                                                    width: "20px",
                                                                }}
                                                                data-test="switchOwner_btn"
                                                                onClick={(e: any) =>
                                                                    setShowModals({
                                                                        ...showModals,
                                                                        showSwitchOwnerModal: true,
                                                                    })
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </Flex>
                                        </>
                                    }
                                    labelCol={middleFormItemLayout.labelCol}
                                    wrapperCol={middleFormItemLayout.wrapperCol}
                                    name="owner"
                                    initialValue={
                                        data && data.ownerIds.length ? data.ownerIds : undefined
                                    }
                                    rules={[
                                        {
                                            required: OwnerHeadline.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        style={{ width: "100%" }}
                                        allowClear
                                        maxTagCount={5}
                                        useData={usePartnerOwners}
                                        selectIdField="id"
                                        selectOptionField="fullName"
                                        placeholder={OwnerHeadline.customLabel ?? t("owner")}
                                        disabled={!canEdit || !canEditOwner}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={Category1Headline.customLabel ?? t("category1")}
                                    labelCol={middleFormItemLayout.labelCol}
                                    wrapperCol={middleFormItemLayout.wrapperCol}
                                    name="cat1"
                                    initialValue={
                                        data && data.category1Ids.length ? data.category1Ids : []
                                    }
                                    rules={[
                                        {
                                            required: Category1Headline.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        useData={usePartnerCategories.bind(
                                            null,
                                            categoryType.one,
                                            categoryAdded
                                        )}
                                        selectOptionField="name"
                                        placeholder={
                                            Category1Headline.customLabel ?? t("category1")
                                        }
                                        allowClear
                                        maxTagCount={5}
                                        disabled={!canEdit}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() =>
                                                        handleAddCategory(categoryType.one)
                                                    }>
                                                    <Plus /> {t("add")}{" "}
                                                    {Category1Headline.customLabel ??
                                                        t("category1")}
                                                </div>
                                            </div>
                                        )}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={classes.formItem}
                                    label={Category2Headline.customLabel ?? t("category2")}
                                    labelCol={middleFormItemLayout.labelCol}
                                    wrapperCol={middleFormItemLayout.wrapperCol}
                                    name="cat2"
                                    initialValue={
                                        data && data.category2Ids.length ? data.category2Ids : []
                                    }
                                    rules={[
                                        {
                                            required: Category2Headline.isMandatory,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        useData={usePartnerCategories.bind(
                                            null,
                                            categoryType.two,
                                            categoryAdded
                                        )}
                                        selectOptionField="name"
                                        placeholder={
                                            Category2Headline.customLabel ?? t("category2")
                                        }
                                        allowClear
                                        maxTagCount={5}
                                        disabled={!canEdit}
                                        dropdownRender={(menu: any) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: "4px 0" }} />
                                                <div
                                                    className={classes.dropDownOperation}
                                                    onMouseDown={() =>
                                                        handleAddCategory(categoryType.two)
                                                    }>
                                                    <Plus /> {t("add")}{" "}
                                                    {Category2Headline.customLabel ??
                                                        t("category2")}
                                                </div>
                                            </div>
                                        )}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>

                                {hasNcrFeature && (
                                    <Form.Item
                                        name="ncrContacts"
                                        label={t("partner_NcrContacts")}
                                        labelCol={middleFormItemLayout.labelCol}
                                        wrapperCol={middleFormItemLayout.wrapperCol}
                                        initialValue={data && data.ncrContacts}>
                                        <SelectPartnerContacts
                                            reloadOnDropdownVisibiltyChange={true}
                                            partnerId={partnerId}
                                            disabled={!canEdit}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    name="complianceContacts"
                                    label={t("partner_ComplianceContacts")}
                                    labelCol={middleFormItemLayout.labelCol}
                                    wrapperCol={middleFormItemLayout.wrapperCol}
                                    initialValue={data && data.complianceContacts}>
                                    <SelectPartnerContacts
                                        reloadOnDropdownVisibiltyChange={true}
                                        partnerId={partnerId}
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} lg={8} xs={24}>
                                {data &&
                                    data.customColumns.map((column: any) => {
                                        const customValue = data.customColumnValues.filter(
                                            v => v.customColumnId === column.id
                                        );
                                        return (
                                            <Form.Item
                                                className={classes.formItem}
                                                key={column.id}
                                                label={column.name}
                                                labelCol={formItemLayout.labelCol}
                                                wrapperCol={formItemLayout.wrapperCol}
                                                name={`customColumn-${column.id}`}
                                                initialValue={
                                                    customValue.length
                                                        ? customValue[0].value
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        max: 400,
                                                    },
                                                ]}>
                                                {column.customDropdownId === null ? (
                                                    <TextArea
                                                        data-test={column.name}
                                                        placeholder={column.name}
                                                        autoSize={{ minRows: 1, maxRows: 6 }}
                                                        maxLength={400}
                                                        disabled={!canEdit}
                                                    />
                                                ) : (
                                                    <SelectData
                                                        allowClear
                                                        style={{ marginLeft: 0, width: "100%" }}
                                                        placeholder={t("generic_SelectOption")}
                                                        selectOptionField="text"
                                                        selectIdField="text"
                                                        defaultValue={
                                                            customValue.length
                                                                ? customValue[0].value
                                                                : undefined
                                                        }
                                                        useData={() => ({
                                                            data:
                                                                customDropdowns.filter(
                                                                    (dropdown: any) =>
                                                                        dropdown.id ===
                                                                        column.customDropdownId
                                                                ) &&
                                                                customDropdowns.filter(
                                                                    (dropdown: any) =>
                                                                        dropdown.id ===
                                                                        column.customDropdownId
                                                                )[0].customDropdownOptions,
                                                            loading: false,
                                                        })}
                                                    />
                                                )}
                                            </Form.Item>
                                        );
                                    })}
                            </Col>
                        </Row>
                        {data && canEdit && (
                            <Row>
                                <Col span={24} style={{ textAlign: "right" }}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{ padding: "0 25px" }}
                                        onClick={() => handleSubmit()}
                                        loading={updateLoading}>
                                        {t("generic_Save")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                )}
                <CreateGroupModal
                    visible={showModals.showCreateGroupModal}
                    setModalVisibility={(value: boolean) =>
                        setShowModals({ ...showModals, showCreateGroupModal: value })
                    }
                    setCreatedGroup={(createdGroups: { key: string | number; label: string }[]) => {
                        setGroupAdded({
                            added: true,
                        });
                        form.setFieldsValue({
                            group: createdGroups[0].key,
                        });
                    }}
                    groupHeadline={(GroupHeadline && GroupHeadline.customLabel) ?? t("group")}
                />
                {showModals.categoryType && (
                    <CreateCategoryModal
                        visible={showModals.showCreateCategoryModal}
                        setModalVisibility={(value: boolean) =>
                            setShowModals({ ...showModals, showCreateCategoryModal: value })
                        }
                        setCreatedCategory={(createdCategories: any) => {
                            setCategoryAdded({
                                added: true,
                            });
                            form.setFieldsValue({
                                [showModals.categoryType === categoryType.one ? "cat1" : "cat2"]: [
                                    ...form.getFieldValue(
                                        showModals.categoryType === categoryType.one
                                            ? "cat1"
                                            : "cat2"
                                    ),
                                    ...createdCategories.map((c: any) => c.key),
                                ],
                            });
                        }}
                        categoryHeadline={
                            showModals.categoryType === categoryType.one
                                ? Category1Headline.customLabel ?? t("category1")
                                : showModals.categoryType === categoryType.two
                                ? Category2Headline.customLabel ?? t("category2")
                                : ""
                        }
                        catType={showModals.categoryType}
                    />
                )}
                {showModals.showSwitchOwnerModal &&
                    canSwitchOwner &&
                    data &&
                    data.ownerIds &&
                    data.ownerIds.includes(parseInt(currentUserContactId)) && (
                        <SwitchOwnerModal
                            partnerId={partnerId}
                            onClose={(
                                shouldReload: boolean = false,
                                newOwnerIds: number[] | undefined
                            ) => {
                                setShowModals({ ...showModals, showSwitchOwnerModal: false });

                                if (shouldReload && newOwnerIds) {
                                    form.setFieldsValue({ owner: newOwnerIds });
                                    setReload({ changed: true });
                                }
                            }}
                            visible={showModals.showSwitchOwnerModal}
                            ownerHeadline={OwnerHeadline.customLabel ?? t("owner")}
                            ownerIds={data ? data.ownerIds : []}
                        />
                    )}
            </PageContainer>
        </Spin>
    );
};

export default MasterData;
