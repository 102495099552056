import React, { FunctionComponent } from "react";
import { AnalyticsGrid } from "./analyticsGrid/analyticsGrid";
import { useDatatable } from "controller";

interface IProps {
    parentId: any;
    reportObject: any;
    columnNames: { targetName: string; subLevelName: string };
}

export const DataTable: FunctionComponent<IProps> = ({ parentId, reportObject, columnNames }) => {
    return (
        <div>
            <AnalyticsGrid
                columnNames={columnNames}
                parentId={parentId}
                useData={useDatatable}
                reportObject={reportObject}
            />
        </div>
    );
};
