import React, { SyntheticEvent, useState } from "react";
import { Affix, Button, Tooltip } from "antd";
import { useMeetingForumsForSupplier } from "controller";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { GridDeleteModal, GridLight, MultipleTags, Trash02 } from "components/utilitycomponents";
import { CreateEditModal } from "./createEditModal";
import { IMeetingForum } from "models";
import classes from "./meetingForums.module.scss";

interface IProps {
    partnerId: number;
}

const MeetingForums = ({ partnerId }: IProps) => {
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading, remove, deleteLoading } = useMeetingForumsForSupplier(partnerId, reload);
    const [createEditModal, setCreateEditModal] = useState<any>({
        visible: false,
        row: undefined,
    });

    const [deleteModal, setDeleteModal] = useState<any>({
        visible: false,
        records: [],
    });

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setCreateEditModal({ row: record, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    const handleDelete = async () => {
        await remove(
            partnerId,
            deleteModal.records.map((r: IMeetingForum) => r.templateId)
        );
        setDeleteModal({
            visible: false,
            records: [],
        });
        setReload({ reload: true });
    };

    let columns: any[] = [
        {
            title: <div className={classes.colTitle}>{t("meetingForum_ForumType")}</div>,
            dataIndex: "forumName",
            key: "forumName",
            viewColumnId: "forumName",
            width: 130,
            render: (value: string) => <div className={classes.forumType}>{value}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("meetingForum_Governance")}</div>,
            dataIndex: "governance",
            key: "governance",
            viewColumnId: "governance",
            width: 150,
            render: (value: string) => <div className={classes.governance}>{value}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("meetingForum_Owner")}</div>,
            dataIndex: "meetingOwners",
            key: "meetingOwners",
            viewColumnId: "meetingOwners",
            width: 150,
            render: (value: any[]) => (
                <div>
                    {value && value.length > 0 && (
                        <MultipleTags
                            tagNumber={3}
                            values={value.map((m: any) => ({
                                id: m.contactId,
                                name: m.ownerName,
                            }))}
                        />
                    )}
                </div>
            ),
        },
        {
            title: <div className={classes.colTitle}>{t("meetingForum_Frequency")}</div>,
            dataIndex: "frequencyName",
            key: "frequencyName",
            viewColumnId: "frequencyName",
            width: 150,
        },
        {
            title: (
                <div className={classes.colTitle}>
                    {t("meetingForum_CompanyMember", {
                        name: companyName,
                        interpolation: { escapeValue: false },
                    })}
                </div>
            ),
            dataIndex: "companyContacts",
            key: "companyContacts",
            viewColumnId: "companyContacts",
            width: 150,
            render: (value: any[]) => (
                <div>
                    {value && value.length > 0 && (
                        <MultipleTags
                            tagNumber={3}
                            values={value.map((m: any) => ({
                                id: m.contactId,
                                name: m.memberName,
                            }))}
                        />
                    )}
                </div>
            ),
        },
        {
            title: <div className={classes.colTitle}>{t("meetingForum_SupplierMember")}</div>,
            dataIndex: "supplierContacts",
            key: "supplierContacts",
            viewColumnId: "supplierContacts",
            width: 150,
            render: (value: any[]) => (
                <div>
                    {value && value.length > 0 && (
                        <MultipleTags
                            tagNumber={3}
                            values={value.map((m: any) => ({
                                id: m.contactId,
                                name: m.memberName,
                            }))}
                        />
                    )}
                </div>
            ),
        },
        {
            title: <div className={classes.colTitle}></div>,
            dataIndex: "delete",
            key: "delete",
            viewColumnId: "delete",
            operationColumn: true,
            width: 50,
            render: (_: any, record: IMeetingForum) =>
                !record.isDefault ? (
                    <Tooltip title={t("meetingForum_Delete")} placement="right">
                        <span>
                            <Trash02
                                onClick={(e: SyntheticEvent) => {
                                    e.stopPropagation();
                                    setDeleteModal({ visible: true, records: [record] });
                                }}
                            />
                        </span>
                    </Tooltip>
                ) : null,
        },
    ];

    return (
        <>
            {data && (
                <GridLight
                    title={() => (
                        <div className={classes.tableTitle}>{t("governance_MeetingForums")}</div>
                    )}
                    className={classes.customTableStyle}
                    tableSize="big"
                    pagination="none"
                    bordered={false}
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    renderTableOnDataChange
                    emptyText={t("meetingForum_NoData")}
                    onRow={handleOnRow}
                    tableExcessHeight={160}
                    scroll={{ y: 800 }}
                />
            )}
            <Affix style={{ position: "fixed", bottom: 10, right: 30 }} className={classes.affix}>
                <Button
                    className={classes.addBtn}
                    shape="round"
                    onClick={() => setCreateEditModal({ visible: true, row: undefined })}>
                    {t("meetingForum_AddForum")}
                </Button>
            </Affix>
            <CreateEditModal
                partnerId={partnerId}
                visible={createEditModal.visible}
                item={createEditModal.row}
                onCancel={() => setCreateEditModal({ visible: false, row: undefined })}
                onSuccess={() => {
                    setCreateEditModal({ visible: false, row: undefined });
                    setReload({ reload: true });
                }}
            />
            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                        records: [],
                    });
                }}
                data={deleteModal.records.map((r: IMeetingForum) => ({
                    id: r.templateId,
                    name: `${r.forumName} (${r.frequencyName})`,
                }))}
                name={t("meetingForum_MeetingForum", {
                    count: deleteModal.records.length > 1 ? 2 : 1,
                })}
                confirmLoading={deleteLoading}
                onConfirm={handleDelete}
            />
        </>
    );
};

export { MeetingForums };
