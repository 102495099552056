import { useState } from "react";
import { Provider } from "services";

const useAutomatedSurveyDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteAutomatedSurveys = async (autoSurveyIds: number[]) => {
        setLoading(true);
        await Provider.AutomatedSurvey.deleteAutomatedSurveys(autoSurveyIds).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
    };

    return {
        loading,
        deleteAutomatedSurveys,
    };
};

export { useAutomatedSurveyDelete };
