import { get } from "../base";

const BusinessPartner = {
    getBusinessPartnerCategories: async (
        topLevel: boolean = false,
        oneLevelOnly: boolean = false,
        { customError }: any = {}
    ) => {
        const url = `/v1/BusinessPartner?topLevel=${topLevel}&oneLevelOnly=${oneLevelOnly}`;
        return await get({ url, customError });
    },
};

export default BusinessPartner;
