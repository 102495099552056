import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./metrics.module.scss";
import { Divider, Flex } from "antd";
import ReviewCategoriesBarChart from "components/contentcomponents/suppliers/supplierProfile/performanceOverview/reviewCategoryBarChart";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";

interface IProps {
    partnerId: number;
}

const ReviewCategoryMetric = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [from] = useState<any>(
        dayjs()
            .subtract(12, "month")
            .startOf("month")
    );
    const [to] = useState<any>(dayjs().endOf("month"));
    const { width } = useWindowDimensions();

    return (
        <div className={classes.reviewCategoryMetricsContainer}>
            <div className={classes.reviewCategoryTitle}>
                <div style={{ marginLeft: 14 }}>
                    {t("supplierprofile_summary_StakeholderFeedbackAvg").toUpperCase()}
                </div>

                <Divider className={classes.divider} />
            </div>

            <div className={classes.reviewCategoryChart}>
                <ReviewCategoriesBarChart
                    partnerId={partnerId}
                    range={{ from, to }}
                    renderType="supplierSummary"
                />
            </div>
            {width! > 1800 && (
                <div className={classes.reviewCategoryLegend}>
                    <Legend>
                        <div
                            className={classes.legendElement}
                            style={{ backgroundColor: "#0c5a87" }}
                        />
                        <div className={classes.legendText}>
                            {t(`supplierProfile_ReviewChartSelectedPartner`)}
                        </div>
                    </Legend>
                    <Legend>
                        <div
                            className={classes.legendElement}
                            style={{ backgroundColor: "#488db5" }}
                        />
                        <div className={classes.legendText}>
                            {t("supplierProfile_ReviewChartAllPartner")}
                        </div>
                    </Legend>
                    <Legend>
                        <div
                            className={classes.legendElement}
                            style={{ backgroundColor: "#6baed6" }}
                        />
                        <div className={classes.legendText}>
                            {t("supplierProfile_ReviewChartGroupPartner")}
                        </div>
                    </Legend>
                    <Legend>
                        <div
                            className={classes.legendElement}
                            style={{ backgroundColor: "#9ecae1" }}
                        />
                        <div className={classes.legendText}>
                            {t("supplierProfile_ReviewChartVoicePartner")}
                        </div>
                    </Legend>
                    <Legend>
                        <div
                            className={classes.legendElement}
                            style={{ backgroundColor: "#c6dbef" }}
                        />
                        <div className={classes.legendText}>
                            {t("supplierProfile_ReviewChartVoiceAllPartner")}
                        </div>
                    </Legend>
                </div>
            )}
        </div>
    );
};
const Legend = ({ img, children }: any) => {
    return <Flex className={classes.legend}>{children}</Flex>;
};
export { ReviewCategoryMetric };
