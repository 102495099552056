import React, { FunctionComponent } from "react";
import classes from "./styles/shared.module.scss";
import { IUnknownDetail } from "models";
import { useTranslation, Trans } from "react-i18next";

type PropsType = {
    data: IUnknownDetail;
};

const UnknownActivity: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.activityTitle}>
            <Trans i18nKey="activity_Unknown" values={{ id: data.id }}>
                {t("activity_Unknown")}
            </Trans>
        </div>
    );
};

export default UnknownActivity;
export { UnknownActivity };
