import { get } from "../base";

const Notification = {
    notificationOverview: async (
        pageNumber: number,
        pageSize: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Notification/Overview?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        return await get({ url, customError: customError });
    },
    getUnreadNotificationCount: async ({ customError }: any = {}) => {
        const url = `/v1/Notification/GetUnreadNotificationsCount`;
        return await get({ url, customError: customError });
    },
    setShownOnAllNotifications: async ({ customError }: any = {}) => {
        const url = `/v1/Notification/SetShownOnAllNotifications`;
        return await get({ url, customError: customError });
    },
};

export default Notification;
