import { useEffect, useState } from "react";
import { Provider } from "services";

const useAdminNcrFields = (reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Ncr.getAdminNcrFields({
                customError: {
                    message: "Could not retrieve list of ncr fields",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data: data.typeValue });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAdminNcrFields };
