import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Config from "../../../../config";
import { Spin } from "antd";
import PartnerRegistration from "components/contentcomponents/suppliers/partnerRegistration/partnerRegistration";
import onBoardingForm from "components/contentcomponents/user/userRegistration/onBoardComponents/onBoardingForm";

const components: any = {
    "Registration/User": onBoardingForm,
    Registration: PartnerRegistration,
};

const UnAuthenticatedRoutes = () => {
    return (
        <Suspense
            fallback={
                <div className="callbackSpinner">
                    <Spin size="large" />
                </div>
            }>
            <Switch>
                {Config.unAuthenticatedRoutes.map((r: any, index: number) => (
                    <Route key={index} path={`/${r}/:token/returnUrl/:returnUrl+`}>
                        {React.createElement(components[r])}
                    </Route>
                ))}
                {Config.unAuthenticatedRoutes.map((r: any, index: number) => (
                    <Route key={index} exact path={`/${r}/:token`}>
                        {React.createElement(components[r])}
                    </Route>
                ))}
            </Switch>
        </Suspense>
    );
};

export default UnAuthenticatedRoutes;
