import { useEffect, useState } from "react";
import { Provider } from "services";
import { IComplianceStatus } from "models";
import { useTranslation } from "react-i18next";

type StateType = {
    loading: boolean;
    data: IComplianceStatus[];
};

const useComplianceStatus = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Compliance.complianceStatusList();

            data = data.map((s: IComplianceStatus) => {
                // s.name = s.name.replace(/([A-Z])/g, " $1");
                s.name = t(s.name.replace(" ", ""));
                return s;
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useComplianceStatus };
