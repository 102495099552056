import { useState } from "react";
import { Provider } from "services";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const usePartnerCategoriesUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const update = async (
        partnerIds: number[],
        categoryIds: number[],
        type: "FirstCategory" | "SecondCategory",
        categoryHeadline: string
    ) => {
        setLoading(true);

        await Provider.Partner.updateCategoriesOnPartner(partnerIds, categoryIds, type).catch(
            err => {
                setLoading(false);
                notification.error({
                    message: t("systemMessage"),
                    description: t("generic_ErrorMessage"),
                    duration: 0,
                });
                return Promise.reject(err);
            }
        );
        setLoading(false);
    };
    return {
        loading,
        update,
    };
};

export { usePartnerCategoriesUpdate };
