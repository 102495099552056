export const getColorFromInitials = (initials: string | undefined): string => {
    const colors = [
        "#0045F5",
        "#002FA7",
        "#880673",
        "#F750DB",
        "#7A3700",
        "#139FCE",
        "#0A526A",
        "#03634A",
        "#5F0483",
        "#BB21F8",
    ];
    const defaultColor = ["#808080"];
    if (!initials) return defaultColor[0];
    const index = (initials.charCodeAt(0) + (initials.charCodeAt(1) || 0)) % colors.length;
    return colors[index];
};
