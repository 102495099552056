import React from "react";
import summaryClasses from "../../summary.module.scss";
import { Col, Divider, Row, Table, Tooltip } from "antd";
import classes from "../sustainability.module.scss";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "components/utilitycomponents";

interface IProps {
    data: any;
    loading: boolean;
}

const SustainabilityTable = ({ data, loading }: IProps) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: (
                <div className={classes.tableHeader}>
                    {t("supplierProfile_SustainabilityColumn_Question")}
                </div>
            ),
            key: "kpi",
            dataIndex: "question",
            width: "70%",
            ellipsis: true,
        },
        {
            title: (
                <div className={classes.tableHeader}>
                    {t("compliance_Score")}{" "}
                    <Tooltip
                        placement="bottomLeft"
                        title={t("supplierProfile_SustainabilityTableHeaderTooltip")}>
                        <span>
                            <InfoCircle />
                        </span>
                    </Tooltip>
                </div>
            ),
            key: "score",
            dataIndex: "currentYear",
            render: (val: any) => <div>{Math.round(val)}</div>,
        },
    ];

    return (
        <div style={{ height: 500 }} className={summaryClasses.container}>
            <Row>
                <Col span={24}>
                    <div
                        className={summaryClasses.tableTitle}
                        style={{ marginTop: 4, marginLeft: 8 }}>
                        {t("supplierProfile_SustainabilityHeadlines").toUpperCase()}
                    </div>
                    <Divider style={{ marginTop: 8 }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        loading={loading}
                        style={{ padding: 8 }}
                        className={summaryClasses.customTableStyle}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export { SustainabilityTable };
