import React, { Fragment, FunctionComponent } from "react";
import { PieChart } from "../pie/pieChart";
import { BarChart } from "../bar/barChart";
import { LineChart } from "../line/lineChart";
import { ReportHeader } from "./reportHeader";
import { DataTable } from "../dataTable/dataTable";
import { useReport } from "controller";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
    onEditChartModalClick: Function;
    onFilterModalClick: Function;
    reportId: number;
    reloadReport: any;
    reportObject: any;
    reloadDashboard: Function;
    parentId: any;
    benchmarkId: any;
    partnerId: number;
    editable: boolean;
    columnNames: { targetName: string; subLevelName: string };
}

export const ChartContainer: FunctionComponent<IProps> = ({
    onEditChartModalClick,
    onFilterModalClick,
    reportId,
    reloadReport,
    reloadDashboard,
    parentId,
    benchmarkId,
    partnerId,
    editable,
    columnNames,
    ...rest
}) => {
    const { data, loading } = useReport(partnerId, reportId, reloadReport);
    const { t } = useTranslation();

    function renderChart(chartType: string) {
        switch (chartType) {
            case "Bar":
                return <BarChart parentId={parentId && parentId} reportObject={data} />;
            case "Pie":
                return (
                    <PieChart
                        parentId={parentId && parentId}
                        reportObject={data}
                        benchmarkId={benchmarkId}
                    />
                );
            case "Line":
                return <LineChart parentId={parentId && parentId} reportObject={data} />;
            case "DataTable":
                return (
                    <DataTable
                        columnNames={columnNames}
                        parentId={parentId && parentId}
                        reportObject={data}
                    />
                );
            default:
                return <Fragment>{t("analytics_ReportSwitchError")}</Fragment>;
        }
    }

    return (
        <div id={`reportElement${parentId}`}>
            <ReportHeader
                editable={editable}
                reportObject={data}
                onEditChartModalClick={(reportObject: any) => onEditChartModalClick(reportObject)}
                onFilterModalClick={(reportObject: any) => onFilterModalClick(reportObject)}
                reloadDashboard={() => reloadDashboard()}
                parentId={parentId}
            />

            {data && data.filter ? (
                renderChart(data.filter.chartType)
            ) : (
                <Spin spinning={loading}></Spin>
            )}
        </div>
    );
};
