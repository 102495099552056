import enUS from "antd/lib/locale/en_US";
import enGB from "antd/lib/locale/en_GB";
import daDK from "antd/lib/locale/da_DK";
import frFR from "antd/lib/locale/fr_FR";
import deDE from "antd/lib/locale/de_DE";
import esES from "antd/lib/locale/es_ES";

export function parseLanguageCode(languageCode: string) {
    switch (languageCode) {
        case "en-GB":
            return 1;
        case "da-DK":
            return 2;
        case "de-DE":
            return 3;
        case "fr-FR":
            return 4;
        case "es-ES":
            return 5;
        case "en-US":
            return 0;

        default:
            return 1;
    }
}

export function setLanguage(localeClaim: string) {
    switch (localeClaim) {
        case "en-US":
            return "en-GB";
        default:
            return localeClaim;
    }
}

export function getAntLocale(localeClaim: string) {
    switch (localeClaim) {
        case "en-GB":
            return enGB;
        case "da-DA":
            return daDK;
        case "de-DE":
            return deDE;
        case "fr-FR":
            return frFR;
        case "es-ES":
            return esES;
        case "en-US":
            return enUS;
        default:
            return enGB;
    }
}

export function momentLocale(localeClaim: string) {
    switch (localeClaim) {
        case "en-GB":
            return "en-gb";
        case "da-DA":
            return "da";
        case "de-DE":
            return "de";
        case "fr-FR":
            return "fr";
        case "es-ES":
            return "es";
        case "en-US":
            return "en-gb";
        default:
            return "en-gb";
    }
}
