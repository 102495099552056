import { ITableInput } from "models";
import { get, post, put } from "../base";

const AdminUser = {
    getUserAccessGroups: async ({ customError }: any = {}) => {
        const url = `/v1/AdminUser/GetUserAccessSettings?sortByName=true`;
        return await get({ url, customError });
    },
    getUserAccessSettingsOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminUser/GetUserAccessSettingOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    getUserAccessSettingModules: async ({ customError }: any = {}) => {
        const url = `/v1/AdminUser/GetUserAccessSettingModules`;
        return await get({ url, customError });
    },
    createUserAccessSetting: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminUser/AddUserAccessSetting`;
        return await post({ url, payload, customError });
    },
    updateUserAccessSetting: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminUser/UpdateUserAccessSetting`;
        return await post({ url, payload, customError });
    },
    deleteUserAccessSetting: async (ids: any[], { customError }: any = {}) => {
        const url = `/v1/AdminUser/RemoveUserAccessSettings`;
        return await post({ url, payload: ids, customError });
    },
    isUserAccessSettingNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminUser/IsUserAccessSettingNameInUse?name=${encodeURIComponent(name)}`;
        return await get({ url, customError });
    },
    getUserManagementOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminUser/GetUserManagementOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    getUserManagementSelectOptions: async ({ customError }: any = {}) => {
        const url = `/v1/AdminUser/GetUserManangementSettings`;
        return await get({ url, customError });
    },
    updateUserAccessAllowed: async (
        accessAllowed: boolean,
        userIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminUser/UpdateUserAccessAllowed?isAccessAllowed=${accessAllowed}`;
        return await put({ url, payload: userIds, customError });
    },
    updateUserSettings: async (userId: number, settings: any, { customError }: any = {}) => {
        const url = `/v1/AdminUser/updateUser/${userId}`;
        return await post({ url, payload: settings, customError });
    },
    sendUserInvitation: async (invitations: any[], { customError }: any = {}) => {
        const url = `/v1/AdminUser/SendUserInvitations`;
        return await post({ url, payload: invitations, customError });
    },
    getGateKeeperUsers: async ({ customError }: any = {}) => {
        const url = "/v1/AdminUser/GetUsersForGateKeepers";
        return await get({ url, customError });
    },
    getUserActivityOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminUser/GetUserActivities";
        return await post({ url, payload: tableInput, customError });
    },
    getUserActivityUsers: async ({ customError }: any = {}) => {
        const url = "/v1/AdminUser/GetUserActivitySettings";
        return await post({ url, customError });
    },
    getUserActivityTypes: async ({ customError }: any = {}) => {
        const url = "/v1/AdminUser/GetUserActivitySettings";
        return await post({ url, customError });
    },
    getUserTypes: async ({ customError }: any = {}) => {
        const url = "/v1/AdminUser/GetUserTypes";
        return await post({ url, customError });
    },
    getPartnerAccessOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/Partners/PartnerOverviewForSupplierInformationAccess`;
        return await post({ url, payload: tableInput, customError });
    },
    UpdatePartnerInformationAccess: async (accessSettings: any[], { customError }: any = {}) => {
        const url = `/v1/AdminUser/UpdateSupplierInformationAccess`;
        return await post({ url, payload: accessSettings, customError });
    },
};

export { AdminUser };
