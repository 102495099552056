import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportType } from "models";
import { Button, Popconfirm } from "antd";
import { setExcelExport, AppState } from "store";
import { useTranslation } from "react-i18next";
import { FileDownload03 } from "../icons";

type PropsType = {
    reportType: ReportType;
    filters: any[];
    sorts: any[];
    range: any[];
};

const GridExportButton: FunctionComponent<PropsType> = ({ reportType, filters, sorts, range }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading } = useSelector((state: AppState) => state.excelExport.props);
    return (
        <Popconfirm
            title={t("confirm_Export_Title")}
            description={t("confirm_Export_Text")}
            placement="left"
            onConfirm={() =>
                dispatch(
                    setExcelExport({
                        reportType,
                        loading: true,
                        downloadProgress: 0,
                        filters,
                        sorts,
                        ...range,
                    })
                )
            }
            okText={t("generic_Export")}
            cancelText={t("generic_Cancel")}>
            <Button data-test="export_button" loading={loading} icon={<FileDownload03 />}>
                {t("generic_Export")}
            </Button>
        </Popconfirm>
    );
};

export default GridExportButton;
