import { useEffect, useState } from "react";
import Provider from "services/api";

const useAvailableLanguages = () => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Locize.getAvailableLanguages({
                customError: {
                    message: "Could not retrieve list of available languages",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            let languageOptions: any[] = [];
            for (let [key, value] of Object.entries(data)) {
                if (value) {
                    let options: any = value;

                    let name = options.name;
                    if (name === "English") {
                        name = "English (UK)";
                    }
                    if (name === "Spanish; Castilian") {
                        name = "Spanish";
                    }
                    languageOptions.push({ name: name, languageCode: key });
                }
            }
            languageOptions.push({ name: "English (US)", languageCode: "en-US" });
            languageOptions.sort(function(a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            setState({ loading: false, data: languageOptions });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAvailableLanguages };
