import React, { FunctionComponent } from "react";
import { Modal } from "antd";
import { useUserResponseDetails } from "controller";
import { GridLight, ReviewRate } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";

interface IProps {
    visible: boolean;
    onClose: Function;
    surveyId: number;
    segmentationAnalysisId: number;
    performanceAnalysisId: number;
    supplierId?: number;
    contactId?: number;
    contactName?: string;
    supplierName?: string;
}

const ResponseDetails: FunctionComponent<IProps> = ({
    visible,
    onClose,
    surveyId = -1,
    segmentationAnalysisId = -1,
    performanceAnalysisId = -1,
    supplierId,
    contactId,
    contactName,
    supplierName,
}) => {
    const { t } = useTranslation();
    const { data, loading } = useUserResponseDetails(
        surveyId,
        segmentationAnalysisId,
        performanceAnalysisId,
        contactId,
        supplierId
    );

    const columns = [
        {
            title: t("generic_Question"),
            dataIndex: "criteriaName",
            key: "criteria",
            width: 250,
            ellipsis: true,
            render: (val: string, record: any) => (
                <span>
                    <span
                        style={{
                            whiteSpace: "break-spaces",
                            display: "block",
                            textAlign: "justify",
                            lineHeight: "normal",
                        }}>
                        {val.indexOf("ReviewCategory_") !== -1 ? t(val) : val}
                    </span>
                    <sub
                        style={{
                            whiteSpace: "break-spaces",
                            display: "block",
                            textAlign: "justify",
                            lineHeight: "normal",
                        }}>
                        {record.criteriaDescription}
                    </sub>
                </span>
            ),
        },
        {
            title: t("rating"),
            dataIndex: "rating",
            key: "rating",
            width: 100,
            render: (val: number) => (
                <div>
                    <ReviewRate allowHalf val={val} />
                </div>
            ),
        },
        {
            title: t("generic_Notes"),
            dataIndex: "notes",
            key: "notes",
            width: 350,
        },
    ];

    return (
        <Modal
            title={<div style={{ height: 30 }}> </div>}
            width={1700}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            zIndex={2000}>
            <GridLight
                title={() =>
                    t("survey_ResponseDetailsTitle", {
                        contactName: contactName,
                        Conjunction: supplierName === "" ? "" : t("on"),
                        supplierName: supplierName,
                    })
                }
                dataSource={data}
                columns={columns}
                pagination="none"
                loading={loading}
            />
        </Modal>
    );
};

export { ResponseDetails };
