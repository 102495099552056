import { ITableInput, categoryType } from "models";
import { IStatusEvent } from "models/admin";
import { post, get, remove, put } from "../base";

const AdminPartner = {
    setStatusAsDefault: async (id: number, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/SetPartnerStatusAsDefault?id=${id}`;
        return await post({
            url,
            customError,
        });
    },
    getAdminPartnerStatuses: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/GetPartnerStatuses?sortByName=${sortByName}`;
        return await get({ url, customError: customError });
    },
    createPartnerStatuses: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/CreatePartnerStatus`;
        return await post({
            url,
            payload,
            customError: customError,
        });
    },
    deletePartnerStatus: async (statusIds: number[], { customError }: any = {}) => {
        const url = `/v1/AdminPartner/DeletePartnerStatuses`;
        return await remove({ url, payload: statusIds, customError: customError });
    },
    updatePartnerStatus: async (statusId: number, payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/UpdatePartnerStatus/${statusId}`;
        return await put({ url, payload, customError: customError });
    },
    getStatusEventOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminPartner/StatusEventSettingOverview";
        return await post({ url, payload: tableInput, customError });
    },
    createStatusEvent: async (statusEvent: IStatusEvent, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/CreateStatusEventSetting`;
        return await post({ url, payload: statusEvent, customError });
    },
    updateStatusEvent: async (statusEvent: IStatusEvent, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/UpdateStatusEventSetting`;
        return await put({ url, payload: statusEvent, customError });
    },
    deleteStatusEvents: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminPartner/DeleteStatusEventSettings`;
        return await remove({ url, payload: ids, customError });
    },
    isStatusEventSettingInUse: async (statusEvent: IStatusEvent, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/IsStatusEventSettingInUse`;
        return await post({ url, payload: statusEvent, customError });
    },
    getAdminPartnerGroups: async ({ customError }: any = {}) => {
        const url = `/v1/AdminPartner/GetPartnerGroups`;
        return await get({ url, customError: customError });
    },
    getAdminPartnerCategories: async (type?: categoryType, { customError }: any = {}) => {
        const url = type
            ? `/v1/AdminPartner/GetPartnerCategories?type=${type}&sortByName=true`
            : `/v1/AdminPartner/GetPartnerCategories?sortByName=true`;
        return await get({ url, customError: customError });
    },
    getInvitationStatusOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminPartner/GetInvitationStatusOverview";
        return await post({ url, payload: tableInput, customError });
    },
    deleteInvitationStatus: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminPartner/DeleteInvitationStatus`;
        return await remove({ url, payload: ids, customError });
    },
    getPartnerFileTemplate: async ({ customError, onDownloadProgress }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/AdminPartner/GetPartnerDataExtractTemplate`;
        return await get({
            url,
            responseType: "blob",
            customError,
            customConfig,
            onDownloadProgress,
        });
    },
    getAdminExternalPartners: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/GetPartners?sortByName=${sortByName}`;
        return await get({ url, customError: customError });
    },
    uploadPartnerFile: async (
        fileId: number,
        overwriteIfBlank: boolean,
        baseCurrency?: string,
        { customError }: any = {}
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        let url = `/v1/AdminPartner/UploadPartnerFile?fileId=${fileId}&overwriteIfBlank=${overwriteIfBlank}`;
        if (baseCurrency !== undefined) {
            url = url + `&baseCurrency=${baseCurrency}`;
        }
        return await post({ url, customError, customConfig });
    },
    getMailTemplate: async ({ customError }: any = {}) => {
        const url = `/v1/AdminPartner/GetPartnerMailTemplate`;
        return await get({ url, customError });
    },
    getInvitePartnersOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminPartner/GetPartnerInviteListOverview";
        return await post({ url, payload: tableInput, customError });
    },
    updatePartnerMailTemplate: async (mail: any, { customError }: any = {}) => {
        const url = `/v1/AdminPartner/CreateOrUpdatePartnerMailTemplate`;
        return await post({ url, payload: mail, customError });
    },
    sendInvitationToPartners: async (partnerData: any[], { customError }: any = {}) => {
        const url = `/v1/AdminPartner/SendPartnerEmailInvitations`;
        return await post({ url, payload: partnerData, customError });
    },
    getPartnerStatusOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminPartner/PartnerStatusOverview";
        return await post({ url, payload: tableInput, customError });
    },
};

export { AdminPartner };
