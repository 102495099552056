import { post, get, put, remove } from "../base";
import { ITableInput, IUpdateReview, ICreateReview } from "models";

const Review = {
    createReview: async (
        review: ICreateReview,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review`;
        const success = await post({ url, payload: review, retryCount, customError });
        return success;
    },
    getOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/review/Overview`;
        let response = await post({
            url,
            payload: tableInput,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getReviewCategories: async (
        id = -1,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/categories/${id}`;
        let response = await post({
            url,
            payload: {},
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    updateReviewsVisibility: async (
        reviewIds: number[],
        visibility: 1 | 2,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/visibility/${visibility}`;
        await put({
            url,
            payload: reviewIds,
            retryCount,
            customError,
        });
    },
    deleteReviewsByIds: async (
        reviewIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review`;
        await remove({
            url,
            payload: reviewIds,
            retryCount,
            customError,
        });
    },
    getReviewById: async (
        reviewId: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/${reviewId}`;
        const data = await get({
            url,
            retryCount,
            customError,
        });
        return data;
    },
    updateReview: async (
        reviewId: number,
        reviewValues: IUpdateReview,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/${reviewId}`;
        await put({ url, payload: reviewValues, retryCount, customError });
    },
    getDefaultCreationValues: async () => {
        const url = `/v1/Review/defaultValues`;
        const data = await get({
            url,
            retryCount: 3,
            customError: {
                title: "Error",
                message: "Failed to retrieve custom values for review creation",
            },
        });
        return data;
    },
    getReviewCategoriesForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/categoriesForFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getContactsForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/ContactsFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getPartnerReviewAverage: async (
        partnerId: any,
        from: any,
        to: any,
        reviewCriteriaId: any,
        includeReviews: boolean = false,
        { retryCount, customError }: any = {}
    ) => {
        const url = `/v1/Review/GetReviewCriteriaAverage?partnerId=${partnerId}&from=${from}&to=${to}&includeReviews=${includeReviews}${
            reviewCriteriaId ? "&reviewCriteriaId=" + reviewCriteriaId : ""
        }`;
        const data = await get({
            url,
            retryCount: 1,
            customError,
        });
        return data;
    },
};

export default Review;
