import { Button, Col, Divider, Form, Modal, Result, Row, Tag, notification } from "antd";
import useMetricIdTable from "controller/admin/scorecard/useMetricIdTable";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "../scorecard.module.scss";
import { RichTextEditor, SelectPartnerContacts } from "components/utilitycomponents";
import CreateModal from "components/contentcomponents/compliance/overview/create/createModal";
import { useSendSelfAssessment } from "controller";
import _ from "lodash";
import ReadOnlyRegistrationModal from "components/contentcomponents/compliance/overview/registrationModal/readOnlyRegistrationModal";
interface IProps {
    metricId: number;
    partnerId: number;
    open: boolean;
    onCancel: () => void;
}
const ActionModal = ({ metricId, partnerId, open, onCancel }: IProps) => {
    const [form] = Form.useForm();
    const [reload, setReload] = useState<{ reload: boolean }>({ reload: false });
    const { t } = useTranslation();
    const { data } = useMetricIdTable(metricId, partnerId, reload);
    const [requestForm, setRequestForm] = useState<boolean>(false);
    const { sendSelfAssessments, loading: requestLoading } = useSendSelfAssessment();
    const selectedContacts = Form.useWatch("selectedContacts", form);
    const [result, setResult] = useState<boolean>(false);
    const [complianceModal, toggleComplianceModal] = useState<{
        visible: boolean;
        id: number | undefined;
    }>({
        visible: false,
        id: undefined,
    });
    const [viewComplianceModal, toggleViewComplianceModal] = useState<boolean>(false);

    const onSubmit = async () => {
        let values = await form.validateFields();

        await sendSelfAssessments({
            templateIds: [data.templateId],
            partnerId: partnerId,
            multipleAssesmentsEnabled: true,
            hasAdditionalText: true,
            additionalText: values.customMsg,
            compliancePartnerContactReceiverIds: selectedContacts,
            productIds: undefined,
        });
        notification.success({
            message: "Success",
            description: t("compliance_sendSA_Success"),
        });
        setResult(true);
    };

    const closeModal = () => {
        form.resetFields();
        setResult(false);
        setRequestForm(false);
        onCancel();
    };

    return (
        <>
            <Modal
                open={open}
                onCancel={closeModal}
                width={679}
                title={
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 15,
                        }}>
                        {" "}
                        {t(data.templateName)}{" "}
                    </div>
                }
                footer={[
                    !result ? (
                        <>
                            {!requestForm ? (
                                <Button
                                    onClick={() => {
                                        toggleComplianceModal({
                                            visible: true,
                                            id: data.complianceId,
                                        });
                                    }}>
                                    {t("Fill in manually")}
                                </Button>
                            ) : (
                                <Button
                                    key="backBtn"
                                    onClick={() => {
                                        setRequestForm(false);
                                    }}>
                                    {t("Back")}
                                </Button>
                            )}

                            {!requestForm ? (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setRequestForm(true);
                                    }}>
                                    {" "}
                                    {t("product_RequestDocs")}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    key="requestBtn"
                                    loading={requestLoading}
                                    disabled={
                                        selectedContacts === undefined ||
                                        selectedContacts.length === 0
                                    }
                                    onClick={onSubmit}>
                                    {t("compliance_CreateAndSendSA")}
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button type="primary" key="doneBtn" onClick={() => closeModal()}>
                            {t("Done")}
                        </Button>
                    ),
                ]}>
                {!result ? (
                    <>
                        <Divider
                            orientation="left"
                            orientationMargin={0}
                            children={t("product_Documentation")}
                        />
                        <Row
                            style={{
                                marginBottom: 8,
                            }}>
                            <Col span={8}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}>
                                    {t("compliance_Template")}
                                </span>
                            </Col>
                            <Col span={4}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}>
                                    {t("product_EndDate")}
                                </span>
                            </Col>
                            <Col span={6}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}>
                                    {t("product_ProductStatus")}
                                </span>
                            </Col>
                            <Col span={6}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}>
                                    {t("generic_Requested")}
                                </span>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginBottom: 81,
                            }}>
                            <Col span={8}>
                                {data.complianceId !== null ? (
                                    <Button
                                        type="link"
                                        title={data.templateName}
                                        onClick={() => toggleViewComplianceModal(true)}>
                                        {_.truncate(t(data.templateName), {
                                            length: 20,
                                        })}
                                    </Button>
                                ) : (
                                    <span title={data.templateName}>
                                        {_.truncate(t(data.templateName), {
                                            length: 20,
                                        })}
                                    </span>
                                )}
                            </Col>
                            <Col span={4}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}>
                                    {data.endDate ? (
                                        <Tag bordered={false} color={"#F19306"}>
                                            {dayjs(data.endDate).format("MMM D, YYYY")}
                                        </Tag>
                                    ) : (
                                        <Tag bordered={false}>{t("product_NoEndDateSet")}</Tag>
                                    )}
                                </span>
                            </Col>
                            <Col span={6}>
                                {data.status ? (
                                    <Tag bordered={false} title={data.status}>
                                        {_.truncate(t(data.status), {
                                            length: 20,
                                        })}
                                    </Tag>
                                ) : (
                                    <Tag bordered={false}>
                                        {t("product_NoComplianceRegisted").toLocaleLowerCase()}
                                    </Tag>
                                )}
                            </Col>
                            <Col span={6}>
                                {data.requested && (
                                    <Tag bordered={false}>
                                        {dayjs(data.requested).format("MMM D, YYYY")}
                                    </Tag>
                                )}
                            </Col>
                        </Row>

                        {requestForm ? (
                            <Form layout={"vertical"} form={form}>
                                <Form.Item
                                    className={classes.formItemNoColor}
                                    label={t("compliance_RequestReceivers")}
                                    name="selectedContacts"
                                    style={{
                                        marginTop: 32,
                                    }}>
                                    <SelectPartnerContacts
                                        partnerId={partnerId}
                                        placeholder={t("product_RequestInfo_SelectReceivers")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="customMsg"
                                    label={t("compliance_CustomMsg")}
                                    initialValue={""}>
                                    <RichTextEditor
                                        placeholder={t("compliance_InsertText")}
                                        maxLength={1000}
                                        bordered={true}
                                    />
                                </Form.Item>
                            </Form>
                        ) : null}
                    </>
                ) : (
                    <Result status="success" title={t("compliance_sendSA_Success")} />
                )}
            </Modal>
            <CreateModal
                templateId={data.templateId}
                partnerId={partnerId}
                productId={undefined}
                open={complianceModal.visible}
                onClose={() => {
                    toggleComplianceModal({
                        visible: false,
                        id: undefined,
                    });
                    if (reload) {
                        setReload({ reload: true });
                    }
                }}
            />
            {viewComplianceModal && (
                <ReadOnlyRegistrationModal
                    id={data.complianceId}
                    visible={viewComplianceModal}
                    onClose={() => {
                        toggleViewComplianceModal(false);
                    }}
                />
            )}
        </>
    );
};

export default ActionModal;
