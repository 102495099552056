import { ISegmentCategory as SegmentCategory } from "models";
import { put, get } from "../base";

const AdminSegmentation = {
    getSegmentCategories: async ({ customError }: any = {}) => {
        const url = `/api/SegmentationCategorySetting/GetAllSegmentationCategorySettings`;
        return await get({ url, customError });
    },
    updateSegmentCategories: async (
        segmentCategories: SegmentCategory[],
        { customError }: any = {}
    ) => {
        const url = `/api/SegmentationCategorySetting/UpdateSegmentationCategorySettings`;
        await put({ url, payload: segmentCategories, customError });
    },
};

export { AdminSegmentation };
