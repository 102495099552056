import React from "react";
import { Col, Row, Tooltip } from "antd";
import classes from "./owlerStyle.module.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IProps {
    modifiedValue: string;
}

const OwlerCardFooter = ({ modifiedValue }: IProps) => {
    const { t } = useTranslation();

    return (
        <Row className={classes.footerContainer}>
            <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={15}>
                <div className={classes.footerText}>
                    {t("lastUpdated")}:{" "}
                    {modifiedValue ? moment(modifiedValue).format("MMM D, YYYY") : "N/A"}
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={9}>
                <Tooltip title={t("owler_OwlerExplainer")}>
                    <div className={classes.footerText}>{t("owler_Owler")}</div>
                </Tooltip>
            </Col>
        </Row>
    );
};

export { OwlerCardFooter };
