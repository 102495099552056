import React, { FunctionComponent, ReactNode } from "react";
import classnames from "classnames";
import classes from "./styles/pageContainer.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

type PropsType = {
    title?: string | ReactNode;
    backUrl?: string;
    fluid?: boolean;
    settingContainer?: boolean;
    gridContainer?: boolean;
    [key: string]: any;
};
const PageContainer: FunctionComponent<PropsType> = ({
    title = "",
    backUrl = "",
    fluid = false,
    gridContainer = false,
    settingContainer = false,
    children,
    ...rest
}) => {
    let className = "";
    if (rest && rest.className) {
        className = rest.className;
        delete rest.className;
    }
    return (
        <div
            data-test="page_title"
            className={classnames(
                classes.pageContainer,
                fluid ? (gridContainer ? classes.fluidGrid : classes.fluid) : classes.fixed,
                settingContainer ? classes.settingContainer : "",
                className
            )}
            {...rest}>
            {(title || backUrl) && (
                <div>
                    {title && <div className={classes.title}>{title}</div>}
                    {backUrl && (
                        <Link to={backUrl}>
                            <ArrowLeftOutlined className={classes.icon} />
                        </Link>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};

export { PageContainer };
