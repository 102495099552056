import React, { FunctionComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISelfAssessmentDetail } from "models";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: ISelfAssessmentDetail;
    isHomePage: boolean;
    onDisplayRegistrationModal: Function;
};

const SelfAssessment: FunctionComponent<PropsType> = ({
    data,
    isHomePage = false,
    onDisplayRegistrationModal,
}) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: ISelfAssessmentDetail) => {
        let from = null;
        if (data.creator === "Guest") {
            from =
                data.partnerId === data.fromOrOnPartnerId ? (
                    <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />
                ) : (
                    `${t("yourCompany")} (${data.myCompanyName})`
                );
        } else {
            from = (
                <Link to={`/Partners/SupplierProfile/0/${data.fromOrOnPartnerId}`}>
                    {data.fromOrOnPartner}
                </Link>
            );
        }
        return (
            <Fragment>
                {data.creator} {t("from")} {from}{" "}
                {t("activity_NewSelfAssessmentOnPartner", {
                    creator: data.creator,
                    from: from,
                    id: data.id,
                    interpolation: { escapeValue: false },
                })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: ISelfAssessmentDetail) => {
        return (
            <Fragment>
                {t("activity_NewSelfAssessmentByPartner", { creator: data.creator, id: data.id })}
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            {isHomePage && (
                <Button
                    icon={<RightOutlined />}
                    type="link"
                    onClick={() =>
                        onDisplayRegistrationModal({
                            id: data.id,
                            visible: true,
                        })
                    }
                    className={classes.link}>
                    {t("activity_SelfAssessmentView")}
                </Button>
            )}
        </Fragment>
    );
};

export default SelfAssessment;
