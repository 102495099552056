import React, { FunctionComponent, Fragment } from "react";
import { DownloadFile, getActivity } from "components/utilitycomponents";
import { Col, Row } from "antd";
import { IActivityData } from "models";
import classes from "./styles/ncrHeader.module.scss";
import { useTranslation } from "react-i18next";
import { elapsedTime } from "utilities";

type PropsType = {
    data: any;
};

const NCRHeader: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const activityData: IActivityData = {
        isFollow: data.isFollow,
        canDelete: false,
        typeName: "Ncr",
        activity: {
            id: data.activityId,
            created: data.created,
        },
        children: data.childrenActivity,
        ncrDetail: {
            comment: data.comment,
            creator: data.createdBy,
            deliveryNote: data.deliveryNote,
            id: data.alternateId ? data.alternateId : data.id,
            isOnPartner: data.isOnPartner,
            ncrReason: data.ncrReason,
            partnerId: data.partnerId,
            purchaseOrderNumber: data.purchaseOrderNumber,
            salesOrderNumber: data.salesOrderNumber,
            visibility: data.visibility,
            fromOrOnPartner: data.partnerName,
            creatorProfilePictureId: data.creatorProfilePictureId,
        },
    };
    const { ActivityTypeComponent, activityDetail } = getActivity(activityData);
    return (
        <Fragment>
            <Row>
                <ActivityTypeComponent data={activityDetail} />
            </Row>
            <Row>
                <Col span={3} className={classes.title}>
                    {t("ncrReceivers")}:
                </Col>
                <Col span={20}>
                    {data.receiverContacts && data.receiverContacts.length > 0
                        ? data.receiverContacts.map((contact: any, index: 0) => {
                              return (
                                  <>
                                      <div
                                          style={{ display: "inline-block" }}
                                          title={contact.emailAddress}>
                                          {contact.fullName}
                                      </div>
                                      {index !== data.receiverContacts.length - 1 && (
                                          <span>, </span>
                                      )}
                                  </>
                              );
                          })
                        : "-"}
                </Col>
            </Row>
            {data.attachments.length !== 0 && (
                <Row>
                    <div data-test="attachments">
                        <span className={classes.title}>{t("compliance_Attachments")}: </span>
                        {data.attachments.map((file: any, index: number) => (
                            <div style={{ marginTop: 7 }} key={index}>
                                <DownloadFile file={{ id: file.fileId, name: file.fileName }} />
                            </div>
                        ))}
                    </div>
                </Row>
            )}
            <Row>
                <span style={{ marginTop: 5 }}>
                    {t("createdDate")}: {elapsedTime(activityData.activity.created)}
                </span>
            </Row>
        </Fragment>
    );
};

export default NCRHeader;
