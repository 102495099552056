import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PartnerLink } from "../partnerLink";
import { RightCircleFilled } from "@ant-design/icons";
import { IVoiceOfSupplierSurveyNotificationDetail } from "models";
import { getDomainUrl } from "utilities";
import classes from "./styles/shared.module.scss";

type PropsType = {
    data: any;
};

const VoiceOfSupplierSurvey: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IVoiceOfSupplierSurveyNotificationDetail) => {
        let from = (
            <PartnerLink partnerId={data.fromOrOnPartnerId} partnerName={data.fromOrOnPartner} />
        );

        return (
            <Fragment>
                {data.creator} {t("from")} {from}{" "}
                {t("notification_NewSurveyRequest", "has created a survey for you to fill in")}
            </Fragment>
        );
    };
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>{renderDoneOnPartner(data)} </div>
            <a
                className={classes.notificationLink}
                href={`${getDomainUrl()}/Surveys/Respond/${data.token}`}>
                <RightCircleFilled />
                View Survey
            </a>
        </span>
    );
};

export { VoiceOfSupplierSurvey };
