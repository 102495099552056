import React, { FunctionComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { INcrDetail } from "models";
import { PartnerLink } from "../partnerLink";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";

type PropsType = {
    data: INcrDetail;
};

const Ncr: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: INcrDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewNcr", { id: data.id })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: INcrDetail) => {
        return (
            <Fragment>
                {t("activity_NewNcrByPartner", { creator: data.creator, id: data.id })}{" "}
                {data.fromOrOnPartner ? (
                    <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />
                ) : (
                    <Fragment>
                        {data.fromOrOnPartner ? (
                            <span>{data.fromOrOnPartner}</span>
                        ) : (
                            t("noPartner")
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    };
    const renderReason = (data: INcrDetail) => {
        return (
            data.ncrReason && (
                <Fragment>
                    {" "}
                    {t("activity_NcrWithReason")} <u>{data.ncrReason}</u>
                </Fragment>
            )
        );
    };

    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
                {renderReason(data)}
            </div>
            <Link className={classes.notificationLink} to={`/Ncrs/${data.id}`}>
                <RightCircleFilled />
                View NCR
            </Link>
        </span>
    );
};

export { Ncr };
