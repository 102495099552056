import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useDashboardToggleLock = () => {
    const [loading, SetLoading] = useState(false);

    const ToggleLock = async (presetId: number, toggleLocked: boolean) => {
        SetLoading(true);
        await serviceProvider.Analytics.updateDashboard(presetId, { isLocked: toggleLocked }).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
    };

    return {
        loading,
        ToggleLock,
    };
};

export { useDashboardToggleLock };
