import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Provider } from "services";
import { AppState } from "store";

const useSupplierCustomerEmails = (reload: any = null) => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const isGuest: boolean = userType === "Guest";
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (!isGuest) {
                setState({ ...state, loading: true });

                let data = await Provider.SupplierPortal.getSupplierCustomerEmails({
                    customError: {
                        message: "Could not retrieve customer emails",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSupplierCustomerEmails };
