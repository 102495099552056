import { useState } from "react";
import { Provider } from "services";
import { ICreateAutoSurvey } from "models";

const useAutomatedSurveyCreate = () => {
    const [loading, setLoading] = useState(false);

    const createAutomatedSurvey = async (
        payload: ICreateAutoSurvey,
        { retryCount, customError }: any
    ) => {
        setLoading(true);
        const token = await Provider.AutomatedSurvey.createAutomatedSurvey(payload, {
            retryCount: 3,
            customError: { message: "Could not create automated survey" },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return token;
    };

    return { loading, createAutomatedSurvey };
};

export { useAutomatedSurveyCreate };
