import { useEffect, useState } from "react";
import { Provider } from "services";
import { IExternalPartner } from "models";
import { AppState } from "store";
import { useSelector } from "react-redux";

type StateType = {
    data: IExternalPartner[];
    loading: boolean;
};

const useDualPartnerOptions = (selectablePartnerIds: number[]) => {
    const currentUserCompanyName: string = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-CompanyName"]
    );
    const [state, setState] = useState<StateType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            let data: any[] = await Provider.Partner.getExternalPartners(
                undefined,
                undefined,
                undefined
            );
            data = data.filter(partner => selectablePartnerIds.includes(partner.id));
            data.push({ name: currentUserCompanyName, id: -1 });

            setState({
                data,
                loading: false,
            });
        })();
    }, [selectablePartnerIds]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDualPartnerOptions };
