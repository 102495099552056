import { ICompanyCustomColumn, ICompanyHeadline, IReportSettings } from "models";
import { IGeneralCompanySettings } from "models/admin/companySettings/ICompanySettings";
import { ICompanyInformation } from "models/admin/company/ICompanyInformation";
import { put, post, remove, get } from "../base";
import { ITableInput } from "models";

const AdminCompany = {
    getCompanySettings: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetCompanySettings`;
        return await get({ url, customError });
    },
    getCompanyFaqLink: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetCompanyFaqLink`;
        return await get({ url, customError });
    },
    updateCompanySettings: async (settings: IGeneralCompanySettings, { customError }: any = {}) => {
        const url = `/v1/AdminCompany/UpdateCompanySettings`;
        await put({ url, payload: settings, customError });
    },
    getCompanyHeadlines: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompany/GetCompanySettingsPartnerHeadline";
        return await get({ url, customError });
    },
    updateCompanyHeadlines: async (headlines: ICompanyHeadline[], { customError }: any = {}) => {
        const url = "/v1/AdminCompany/SubmitPartnerHeadlines";
        await put({ url, payload: headlines, customError });
    },
    getCompanyCustomColumns: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompany/GetExternalPartnerCustomColumns";
        return await get({ url, customError });
    },
    updateCompanyCustomColumns: async (
        customColumns: ICompanyCustomColumn[],
        { customError }: any = {}
    ) => {
        const url = "/v1/AdminCompany/SubmitExternalPartnerCustomColumns";
        await put({ url, payload: customColumns, customError });
    },

    customDropdownOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/AdminCompany/GetCustomDropdowns";
        return await post({ url, payload: tableInput, customError });
    },
    createCustomDropdown: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompany/CreateCustomDropdown";
        return await post({ url, payload: payload, customError });
    },
    updateCustomDropdown: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompany/UpdateCustomDropdown";
        return await put({ url, payload: payload, customError });
    },
    deleteCustomDropdowns: async (payload: any[], { customError }: any = {}) => {
        const url = "/v1/AdminCompany/RemoveCustomDropdowns";
        await remove({ url, payload, customError });
    },
    isHeadlineInUse: async (
        headlineText: string,
        headlineId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompany/IsCustomDropdownHeadlineDuplicate?headline=${headlineText}&id=${headlineId}`;
        return await post({ url, payload: headlineText, customError });
    },
    isCustomDropdownsInUse: async (customDropdownIds: number[], { customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetCustomDropdownsInUseStatus`;
        return await post({ url, payload: customDropdownIds, customError });
    },
    getCompanyInformation: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetCompanyInformation`;
        return await get({ url, customError });
    },
    updateCompanyInformation: async (
        companyId: number,
        newCompanyInformation: ICompanyInformation,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompany?companyId=${companyId}`;
        return await put({ url, payload: newCompanyInformation, customError });
    },
    UpdateCompanyLogo: async (fileId: number | null, { customError }: any = {}) => {
        const url = fileId
            ? `/v1/AdminCompany/UpdateCompanyLogo?logoPictureId=${fileId}`
            : `/v1/AdminCompany/UpdateCompanyLogo`;
        await put({
            url,
            customError,
        });
    },
    isCustomDropDownOptionInUse: async (id: number, { customError }: any = {}) => {
        const url = `/v1/AdminCompany/IsCustomDropdownOptionInUse?customDropdownOptionId=${id}`;
        return await post({
            url,
            customError,
        });
    },
    getReportSettings: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetEmbeddedReport`;
        return await get({ url, customError });
    },
    getEmbeddedReportSettings: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetEmbeddedReports`;
        return await get({ url, customError });
    },
    updateReportSettings: async (payload: IReportSettings, id: any, { customError }: any = {}) => {
        if (id) {
            const url = `/v1/AdminCompany/CreateOrUpdateEmbeddedReport?id=${id}`;
            return await post({ url, payload: payload, customError });
        } else {
            const url = `/v1/AdminCompany/CreateOrUpdateEmbeddedReport`;
            return await post({ url, payload: payload, customError });
        }
    },
    removeEmbeddedReport: async (id: number, { customError }: any = {}) => {
        let url = `/v1/AdminCompany/removeEmbeddedReport?id=${id}`;
        await remove({ url, customError });
    },
    getSummaryReport: async ({ customError }: any = {}) => {
        const url = "/v1/AdminCompany/GetSummaryReport";
        return await get({ url });
    },
    addOrEditSummaryReport: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompany/AddOrEditSummaryReport";
        await put({ url, payload });
    },
    getRiskReport: async (riskReportId?: number, { customError }: any = {}) => {
        if (riskReportId) {
            const url = `/v1/AdminCompany/GetRiskReport?riskReportId=${riskReportId}`;
            return await get({ url });
        }
        const url = "/v1/AdminCompany/GetRiskReport";
        return await get({ url });
    },
    addOrEditRiskReport: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/AdminCompany/AddOrEditRiskReport";
        await put({ url, payload });
    },
    deleteRiskReport: async (riskReportId: number, { customError }: any = {}) => {
        const url = `/v1/AdminCompany/RemoveRiskReport?riskReportId=${riskReportId}`;
        await remove({ url, customError });
    },
};

export { AdminCompany };
