import i18n from "i18n";

function findNcrFieldCustomLabel(internalName: string, ncrFields: any[]) {
    let customLabel: string = "";
    if (ncrFields) {
        let ncrField = ncrFields.filter(
            (x: any) => x.internalName === internalName || x.Name === internalName
        )[0];

        if (ncrField !== undefined) {
            customLabel = ncrField.customLabel || ncrField.CustomLabel;
            if (customLabel === undefined || customLabel === "") {
                return "";
            }
        }
    }

    return customLabel;
}

const getCustomLabel = (ncrFields: any[], isDefaultCompany: boolean = true) => {
    let holderLabel = findNcrFieldCustomLabel("NcrHolder", ncrFields);
    holderLabel = holderLabel !== "" ? holderLabel : i18n.t("NcrField_NCRHolder");
    let purchaseOrderNumberLabel = findNcrFieldCustomLabel("PurchaseOrderNumber", ncrFields);
    purchaseOrderNumberLabel =
        purchaseOrderNumberLabel !== ""
            ? purchaseOrderNumberLabel
            : i18n.t("NcrField_PurchaseNumber");
    let valueLabel = findNcrFieldCustomLabel("ValueOfNCR", ncrFields);
    valueLabel = valueLabel !== "" ? valueLabel : i18n.t("NcrField_ValueOfNCR");
    let otherCostsLabel = findNcrFieldCustomLabel("OtherCostsToNCR", ncrFields);
    otherCostsLabel = otherCostsLabel !== "" ? otherCostsLabel : i18n.t("NcrField_OtherCosts");
    let commentLabel = findNcrFieldCustomLabel("Comment", ncrFields);
    commentLabel = commentLabel !== "" ? commentLabel : i18n.t("NcrField_Comment");
    let salesOrderNumberLabel = findNcrFieldCustomLabel("SalesOrderNumber", ncrFields);
    salesOrderNumberLabel =
        salesOrderNumberLabel !== "" ? salesOrderNumberLabel : i18n.t("NcrField_SalesNumber");
    let deliveryNoteLabel = findNcrFieldCustomLabel("DeliveryNote", ncrFields);
    deliveryNoteLabel =
        deliveryNoteLabel !== "" ? deliveryNoteLabel : i18n.t("NcrField_DeliveryNote");
    let approvalStatusLabel = findNcrFieldCustomLabel("ApprovalStatus", ncrFields);
    approvalStatusLabel =
        approvalStatusLabel !== "" ? approvalStatusLabel : i18n.t("NcrField_ApprovalStatus");
    let otherCostsReasonLabel = findNcrFieldCustomLabel("ReasonForOtherCosts", ncrFields);
    otherCostsReasonLabel =
        otherCostsReasonLabel !== "" ? otherCostsReasonLabel : i18n.t("NcrField_CostsReason");

    let partnerSelectLabel = findNcrFieldCustomLabel("Partner", ncrFields);
    partnerSelectLabel =
        partnerSelectLabel !== ""
            ? partnerSelectLabel
            : isDefaultCompany
            ? i18n.t("company_Type_Supplier")
            : i18n.t("company_Type_Customer");
    let purchaseOrderlabel = findNcrFieldCustomLabel("PurchaseOrderNumber", ncrFields);
    purchaseOrderlabel =
        purchaseOrderlabel !== "" ? purchaseOrderlabel : i18n.t("NcrField_PurchaseNumber");
    let ncrReasonLabel = findNcrFieldCustomLabel("NcrReason", ncrFields);
    ncrReasonLabel = ncrReasonLabel !== "" ? ncrReasonLabel : i18n.t("NcrField_NCRReason");
    let ncrValueLabel = findNcrFieldCustomLabel("ValueOfNCR", ncrFields);
    ncrValueLabel = ncrValueLabel !== "" ? ncrValueLabel : i18n.t("NcrField_ValueOfNCR");
    let otherCostsValueLabel = findNcrFieldCustomLabel("OtherCostsToNCR", ncrFields);
    otherCostsValueLabel =
        otherCostsValueLabel !== "" ? otherCostsValueLabel : i18n.t("NcrField_OtherCosts");
    let visibilityLabel = findNcrFieldCustomLabel("Visibility", ncrFields);
    visibilityLabel = visibilityLabel !== "" ? visibilityLabel : i18n.t("NcrField_Visibility");
    let salesOrderLabel = findNcrFieldCustomLabel("SalesOrderNumber", ncrFields);
    salesOrderLabel = salesOrderLabel !== "" ? salesOrderLabel : i18n.t("NcrField_SalesNumber");
    let attachmentsLabel = findNcrFieldCustomLabel("Attachments", ncrFields);
    attachmentsLabel = attachmentsLabel !== "" ? attachmentsLabel : i18n.t("NcrField_Attachments");
    let deviationsLabel = findNcrFieldCustomLabel("Deviations", ncrFields);
    deviationsLabel = deviationsLabel !== "" ? deviationsLabel : i18n.t("NcrField_Deviations");
    let deviationsProductNumberLabel = findNcrFieldCustomLabel("ProductNumber", ncrFields);
    deviationsProductNumberLabel =
        deviationsProductNumberLabel !== ""
            ? deviationsProductNumberLabel
            : i18n.t("NcrField_ProductNumber");
    let deviationsDescriptLabel = findNcrFieldCustomLabel("Description", ncrFields);
    deviationsDescriptLabel =
        deviationsDescriptLabel !== "" ? deviationsDescriptLabel : i18n.t("NcrField_Description");
    let deviationsOrderedLabel = findNcrFieldCustomLabel("Ordered", ncrFields);
    deviationsOrderedLabel =
        deviationsOrderedLabel !== "" ? deviationsOrderedLabel : i18n.t("NcrField_Ordered");
    let deviationsDeliveredLabel = findNcrFieldCustomLabel("Delivered", ncrFields);
    deviationsDeliveredLabel =
        deviationsDeliveredLabel !== "" ? deviationsDeliveredLabel : i18n.t("NcrField_Delivered");
    let deviationsFaultyLabel = findNcrFieldCustomLabel("Faulty", ncrFields);
    deviationsFaultyLabel =
        deviationsFaultyLabel !== "" ? deviationsFaultyLabel : i18n.t("NcrField_Faulty");
    let idLabel = findNcrFieldCustomLabel("NcrId", ncrFields);
    idLabel = idLabel !== "" ? idLabel : i18n.t("NcrField_NCRId");
    let nextResponsibleLabel = findNcrFieldCustomLabel("NextResponsible", ncrFields);
    nextResponsibleLabel =
        nextResponsibleLabel !== "" ? nextResponsibleLabel : i18n.t("NcrField_NextResponsible");

    let partnerColTitle = findNcrFieldCustomLabel("Partner", ncrFields);
    partnerColTitle = partnerColTitle !== "" ? partnerColTitle : i18n.t("supplier");
    let idColTile = findNcrFieldCustomLabel("NcrId", ncrFields);
    idColTile = idColTile !== "" ? idColTile : i18n.t("NcrField_NCRId");
    let ncrStatusColTitle = findNcrFieldCustomLabel("Status", ncrFields);
    ncrStatusColTitle = ncrStatusColTitle !== "" ? ncrStatusColTitle : i18n.t("NcrField_Status");
    let partnerLinkedColTitle = findNcrFieldCustomLabel("PartnerLinkedStatus", ncrFields);
    partnerLinkedColTitle =
        partnerLinkedColTitle !== "" ? partnerLinkedColTitle : i18n.t("NcrField_Linked");
    let modifedColTitle = findNcrFieldCustomLabel("LastUpdatedUTC", ncrFields);
    modifedColTitle = modifedColTitle !== "" ? modifedColTitle : i18n.t("NcrField_LastUpdated");
    let ncrHolderTitle = findNcrFieldCustomLabel("NcrHolder", ncrFields);
    ncrHolderTitle = ncrHolderTitle !== "" ? ncrHolderTitle : i18n.t("NcrField_NCRHolder");
    let purchaseOrderColTitle = findNcrFieldCustomLabel("PurchaseOrderNumber", ncrFields);
    purchaseOrderColTitle =
        purchaseOrderColTitle !== "" ? purchaseOrderColTitle : i18n.t("NcrField_PurchaseNumber");
    let createdByColTitle = findNcrFieldCustomLabel("CreatedBy", ncrFields);
    createdByColTitle = createdByColTitle !== "" ? createdByColTitle : i18n.t("NcrField_CreatedBy");
    let createdColTitle = findNcrFieldCustomLabel("CreatedUTC", ncrFields);
    createdColTitle = createdColTitle !== "" ? createdColTitle : i18n.t("NcrField_CreatedTime");
    let ncrReasonColTitle = findNcrFieldCustomLabel("NcrReason", ncrFields);
    ncrReasonColTitle = ncrReasonColTitle !== "" ? ncrReasonColTitle : i18n.t("NcrField_NCRReason");
    let nextColTitle = findNcrFieldCustomLabel("NextResponsible", ncrFields);
    nextColTitle = nextColTitle !== "" ? nextColTitle : i18n.t("NcrField_NextResponsible");
    let valueColTitle = findNcrFieldCustomLabel("ValueOfNCR", ncrFields);
    valueColTitle = valueColTitle !== "" ? valueColTitle : i18n.t("NcrField_ValueOfNCR");
    let otherCostColTitle = findNcrFieldCustomLabel("OtherCostsToNCR", ncrFields);
    otherCostColTitle =
        otherCostColTitle !== "" ? otherCostColTitle : i18n.t("NcrField_OtherCosts");
    let visibilityColTitle = findNcrFieldCustomLabel("Visibility", ncrFields);
    visibilityColTitle =
        visibilityColTitle !== "" ? visibilityColTitle : i18n.t("NcrField_Visibility");
    let commentColTitle = findNcrFieldCustomLabel("Comment", ncrFields);
    commentColTitle = commentColTitle !== "" ? commentColTitle : i18n.t("NcrField_Comment");
    let searchTagsColTitle = findNcrFieldCustomLabel("SearchTags", ncrFields);
    searchTagsColTitle = searchTagsColTitle !== "" ? searchTagsColTitle : i18n.t("searchTags");
    let salesOrderColTilte = findNcrFieldCustomLabel("SalesOrderNumber", ncrFields);
    salesOrderColTilte =
        salesOrderColTilte !== "" ? salesOrderColTilte : i18n.t("NcrField_SalesNumber");
    let deliveryNoteColTitle = findNcrFieldCustomLabel("DeliveryNote", ncrFields);
    deliveryNoteColTitle =
        deliveryNoteColTitle !== "" ? deliveryNoteColTitle : i18n.t("NcrField_DeliveryNote");
    let attachmentsColTitle = findNcrFieldCustomLabel("Attachments", ncrFields);
    attachmentsColTitle =
        attachmentsColTitle !== "" ? attachmentsColTitle : i18n.t("NcrField_Attachments");
    let createNcrLabel = findNcrFieldCustomLabel("CreateNonconformanceReport", ncrFields);
    createNcrLabel = createNcrLabel !== "" ? createNcrLabel : i18n.t("NcrField_CreateNcr");
    let attachmentsButtonText = findNcrFieldCustomLabel("AttachFilesToNcr", ncrFields);
    attachmentsButtonText =
        attachmentsButtonText !== "" ? attachmentsButtonText : i18n.t("attachFile");
    return {
        holderLabel,
        purchaseOrderNumberLabel,
        valueLabel,
        otherCostsLabel,
        commentLabel,
        salesOrderNumberLabel,
        deliveryNoteLabel,
        approvalStatusLabel,
        otherCostsReasonLabel,
        partnerSelectLabel,
        purchaseOrderlabel,
        ncrReasonLabel,
        ncrValueLabel,
        otherCostsValueLabel,
        visibilityLabel,
        salesOrderLabel,
        attachmentsLabel,
        deviationsLabel,
        deviationsProductNumberLabel,
        deviationsDescriptLabel,
        deviationsOrderedLabel,
        deviationsDeliveredLabel,
        deviationsFaultyLabel,
        idLabel,
        partnerColTitle,
        idColTile,
        ncrStatusColTitle,
        partnerLinkedColTitle,
        modifedColTitle,
        ncrHolderTitle,
        purchaseOrderColTitle,
        createdByColTitle,
        createdColTitle,
        ncrReasonColTitle,
        nextColTitle,
        valueColTitle,
        otherCostColTitle,
        visibilityColTitle,
        commentColTitle,
        searchTagsColTitle,
        salesOrderColTilte,
        deliveryNoteColTitle,
        attachmentsColTitle,
        createNcrLabel,
        attachmentsButtonText,
        nextResponsibleLabel,
    };
};

export { getCustomLabel };
