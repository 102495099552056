import { useState } from "react";
import { Provider } from "services";

const useFileRemove = () => {
    const [loading, setLoading] = useState(false);

    const removeFile = async (fileIds: number[]) => {
        setLoading(true);
        await Provider.File.removeFile(fileIds, {
            customError: {
                message: "Could not remove file",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        removeFile,
    };
};

export { useFileRemove };
