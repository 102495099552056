import React, { FunctionComponent } from "react";
import { MultipleTags } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { Col, Row, Tooltip } from "antd";
import classes from "./surveyResults.module.scss";

interface TitleProps {
    resultsModal: any;
}

const SegmentationSurveyResultsTitle: FunctionComponent<TitleProps> = ({ resultsModal }) => {
    const { t } = useTranslation();

    const renderValue = (value: any) => {
        if (value) {
            return value;
        } else {
            return t("generic_NotApplicable");
        }
    };

    const colorGrade = (val: number) => {
        if (val < 2) {
            return "#ff4d4f";
        } else if (val >= 2 && val < 3) {
            return "#ff9c6e";
        } else if (val >= 3 && val < 4) {
            return "#ffc069";
        } else if (val >= 4 && val < 5) {
            return "#bae637";
        } else if (val <= 5) {
            return "#52c41a";
        }
    };

    return (
        <Row className={classes.titleBackground}>
            <Col span={24}>
                <Row style={{ marginBottom: 8 }}>
                    {resultsModal.supplierEntity && (
                        <Col span={24}>
                            <span className={classes.surveyTitle}>
                                {t("segmentation_SurveyResults_Title", {
                                    supplierName: resultsModal.supplierEntity.name,
                                    supplierId: resultsModal.supplierEntity.id,
                                    interpolation: { escapeValue: false },
                                })}
                            </span>
                            {resultsModal.descriptors && (
                                <MultipleTags
                                    tagNumber={1}
                                    className={classes.surveyTitleDescriptorContainer}
                                    values={resultsModal.descriptors}
                                    color="#108ee9"
                                    tagTooltips={false}
                                />
                            )}
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col span={7} className={classes.borderDivider}>
                        <Row className={classes.headerLabel}>Averages </Row>
                        <Row>
                            <Col span={12}>
                                <span className={classes.labelValue}>Alignment </span>
                                <span
                                    style={{
                                        color: colorGrade(resultsModal.tableRecord.xAxisAvg),
                                    }}>
                                    {resultsModal.tableRecord.xAxisAvg
                                        ? resultsModal.tableRecord.xAxisAvg.toPrecision(2)
                                        : 0}
                                </span>
                            </Col>
                            <Col span={12}>
                                <span className={classes.labelValue}>Impact </span>
                                <span
                                    style={{
                                        color: colorGrade(resultsModal.tableRecord.yAxisAvg),
                                    }}>
                                    {resultsModal.tableRecord.yAxisAvg
                                        ? resultsModal.tableRecord.yAxisAvg.toPrecision(2)
                                        : 0}
                                </span>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6} className={classes.borderDivider}>
                        <div className={classes.headerLabel}>
                            {t("segmentation_SuggestSegment")}
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                border: `1px solid ${resultsModal.tableRecord
                                    .suggestedSegmentCurrent &&
                                    resultsModal.tableRecord.suggestedSegmentCurrent.segmentColor}`,
                                color:
                                    resultsModal.tableRecord.suggestedSegmentCurrent &&
                                    resultsModal.tableRecord.suggestedSegmentCurrent.segmentColor,
                                fontWeight: "bold",
                                textAlign: "center",
                                padding: 4,
                            }}>
                            {renderValue(
                                resultsModal.tableRecord.suggestedSegmentCurrent &&
                                    resultsModal.tableRecord.suggestedSegmentCurrent.segmentName
                            )}
                        </div>
                    </Col>
                    <Col span={10}>
                        <Row className={classes.headerLabel}>
                            <Col span={24}>{t("unitOfAnalysis_AlignedSegment")}</Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Tooltip title={t("segmentation_current")}>
                                    <span
                                        style={{
                                            border: `1px solid ${resultsModal.tableRecord.alignedSegmentCurrent.segmentColor}`,
                                            color:
                                                resultsModal.tableRecord.alignedSegmentCurrent
                                                    .segmentColor,
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            padding: 4,
                                        }}>
                                        {renderValue(
                                            resultsModal.tableRecord.alignedSegmentCurrent
                                                .segmentName
                                        )}
                                    </span>
                                </Tooltip>
                                <span style={{ fontSize: 20, marginLeft: 4, marginRight: 4 }}>
                                    /
                                </span>
                                <Tooltip title={t("segmentation_future")}>
                                    <span
                                        style={{
                                            border: `1px solid ${resultsModal.tableRecord.alignedSegmentFuture.segmentColor}`,
                                            color:
                                                resultsModal.tableRecord.alignedSegmentFuture
                                                    .segmentColor,
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            padding: 4,
                                        }}>
                                        {renderValue(
                                            resultsModal.tableRecord.alignedSegmentFuture
                                                .segmentName
                                        )}
                                    </span>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export { SegmentationSurveyResultsTitle };
