import React from "react";
import FilterDropDown from "./filterDropDown";
import getFilterIcon from "./getFilterIcon";

const getColumnSelectProps = (
    useData: Function,
    dataFields = { value: ["id"], text: ["name"] },
    params: any = {
        dataIndex: Math.floor(Math.random() * 100),
        radioChoice: false,
        includeKeyInText: false,
        includeChildren: false,
    }
) => {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <FilterDropDown
                useData={useData}
                dataFields={dataFields}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={params.dataIndex}
                radioChoice={params.radioChoice}
                includeKeyInText={params.includeKeyInText}
                includeChildren={params.includeChildren}
            />
        ),
        ...getFilterIcon(params),
    };
};

export default getColumnSelectProps;
