import { useState } from "react";
import { default as serviceProvider } from "../../services/api";
import { ICreateReview } from "models";

const useReviewCreate = () => {
    const [loading, SetLoading] = useState(false);

    const createReview = async (review: ICreateReview) => {
        SetLoading(true);
        const success = await serviceProvider.Review.createReview(review);
        SetLoading(false);
        return success;
    };

    return {
        loading,
        createReview,
    };
};

export { useReviewCreate };
