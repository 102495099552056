import React, { useEffect, useState } from "react";
import classes from "./owlerStyle.module.scss";
import { Card, Flex, Tooltip, Modal, Image, Skeleton, List, Badge, Button } from "antd";
import { OwlerDomainInput } from "./owlerDomainInput";
import { useTranslation } from "react-i18next";
import useOwlerNewsFeed from "controller/owler/useOwlerNewsFeed";
import { IOwlerNewsFeed } from "models";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { OwlerCardFooter } from "./owlerCardFooter";
import { isArray } from "lodash";
import dayjs from "utilities/daysJsConfig";
import { Expand01 } from "components/utilitycomponents";
interface IProps {
    domain: string | undefined;
    loadingDomain: boolean;
    onDomainSet?: Function;
    reloadDatasource?: any;
}

const OwlerNewsFeedContainer = ({
    domain,
    loadingDomain,
    onDomainSet,
    reloadDatasource,
}: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const [controlledDomain, setControlledDomain] = useState<string | undefined>(domain);
    const { loading, data: feed } = useOwlerNewsFeed(controlledDomain, reload);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<IOwlerNewsFeed[]>(feed);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setControlledDomain(domain);
    }, [domain]);

    useEffect(() => {
        setData(feed);
    }, [feed]);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadDatasource]);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            setData(feed);
        } else {
            setData(feed.filter(f => selectedCategories.includes(f.category)));
        }
    }, [selectedCategories]);

    const getCategoryColor = (category: string) => {
        switch (category) {
            case "NEWS":
                return "#1677ff";
            case "PRESS":
                return "#91caff";
            case "FUNDING":
                return "#87e8de";
            case "ACQUISITION":
                return "#91caff";
            case "PEOPLE":
                return "#13c2c2";
            case "BLOG":
                return "#36cfc9";
            case "VIDEOS":
                return "#5cdbd3";
            default:
                return undefined;
        }
    };

    const onCategoryClick = (category: string) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(c => c !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const renderFeed = (itemRenderCount: number, layout: "dynamic" | "default") => {
        return (
            <List
                pagination={
                    data.length > 10
                        ? {
                              pageSize: 10,
                              position: "bottom",
                              hideOnSinglePage: true,
                              align: "center",
                          }
                        : false
                }
                size="small"
                itemLayout="horizontal"
                dataSource={data.slice(0, itemRenderCount)}
                renderItem={(item, index) => {
                    if (item) {
                        let startIndex =
                            item.title.indexOf(":") !== -1 ? item.title.indexOf(":") + 1 : 0;
                        let endIndex = 90;
                        if (layout === "dynamic") {
                            if (width! < 1600) {
                                endIndex = 35;
                            }
                            if (width! >= 1600 && width! <= 1880) {
                                endIndex = 55;
                            }
                        }
                        let title = `${item.title.substring(startIndex, endIndex).trim()}`;
                        if (item.title.length > endIndex) {
                            title += "...";
                        }
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <Badge.Ribbon
                                            text={item.category}
                                            style={{ top: -18, fontSize: 10 }}
                                            color={getCategoryColor(item.category)}>
                                            <Flex justify="flex-start">
                                                <Flex vertical justify="center">
                                                    <Tooltip title={item.publisherName}>
                                                        <Image
                                                            width={40}
                                                            src={item.publisherLogo}
                                                        />
                                                    </Tooltip>
                                                </Flex>

                                                <a
                                                    style={{ marginLeft: 16, marginRight: 0 }}
                                                    href={item.sourceUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {title}
                                                </a>
                                            </Flex>
                                        </Badge.Ribbon>
                                    }
                                    description={
                                        <div className={classes.listDescription}>
                                            {t("owler_ArticleDate")}:{" "}
                                            {dayjs(item.feedDate).format("MMM D, YYYY")}
                                        </div>
                                    }
                                />
                            </List.Item>
                        );
                    }
                }}
            />
        );
    };

    return (
        <>
            <Card
                className={classes.owlerCard}
                bodyStyle={{ overflow: "hidden", height: 254 }}
                size="small"
                bordered={false}
                title={
                    <Flex justify="space-between">
                        <div className={classes.cardTitle}>
                            {t("owler_NewsFeed").toLocaleUpperCase()}
                        </div>
                        <Tooltip title={t("owler_FirmographicInfoAdditionalInfo")}>
                            <span>
                                <Expand01
                                    id="owlerNewsFeedExpandIcon"
                                    onClick={() => domain && data && setShowModal(true)}
                                    className={classes.expandIcon}
                                />
                            </span>
                        </Tooltip>
                    </Flex>
                }>
                <div>
                    {!loading && domain && data[0] && (
                        <div>
                            {renderFeed(width! < 1332 && width! > 1210 ? 2 : 3, "dynamic")}
                            <OwlerCardFooter modifiedValue={data[0] && data[0].feedModified!} />
                        </div>
                    )}
                    <Skeleton
                        active={loadingDomain === true || loading === true}
                        loading={loadingDomain === true || loading === true}
                    />
                    {!loadingDomain &&
                        (domain === undefined ||
                            domain === null ||
                            (domain !== undefined && domain !== null && !data)) && (
                            <Flex justify="center" className={classes.domainInputContainer}>
                                <Flex vertical justify="center">
                                    <OwlerDomainInput
                                        domain={domain}
                                        data={data}
                                        onDomainChange={(setDomain: string | undefined) => {
                                            if (setDomain) {
                                                setReload({ reload: true });
                                                onDomainSet && onDomainSet(setDomain);
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        )}
                </div>
            </Card>
            <Modal
                destroyOnClose
                width={800}
                open={showModal}
                title={t("owler_NewsFeed")}
                footer={null}
                onCancel={() => {
                    setShowModal(false);
                    setSelectedCategories([]);
                }}>
                {domain && data && isArray(data) && (
                    <>
                        <Flex justify="right" style={{ marginBottom: 32, marginTop: 32 }}>
                            <div style={{ marginTop: 2, marginRight: 18 }}>Filters</div>
                            <Button
                                size="small"
                                shape="round"
                                onClick={() => onCategoryClick("NEWS")}
                                className={
                                    selectedCategories.includes("NEWS")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                NEWS
                            </Button>
                            <Button
                                size="small"
                                shape="round"
                                onClick={() => onCategoryClick("PRESS")}
                                className={
                                    selectedCategories.includes("PRESS")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                PRESS
                            </Button>
                            <Button
                                size="small"
                                shape="round"
                                color={getCategoryColor("ACQUISITION")}
                                onClick={() => onCategoryClick("ACQUISITION")}
                                className={
                                    selectedCategories.includes("ACQUISITION")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                ACQUISITION
                            </Button>
                            <Button
                                size="small"
                                shape="round"
                                color={getCategoryColor("PEOPLE")}
                                onClick={() => onCategoryClick("PEOPLE")}
                                className={
                                    selectedCategories.includes("PEOPLE")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                PEOPLE
                            </Button>
                            <Button
                                size="small"
                                shape="round"
                                color={getCategoryColor("BLOG")}
                                onClick={() => onCategoryClick("BLOG")}
                                className={
                                    selectedCategories.includes("BLOG")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                BLOG
                            </Button>
                            <Button
                                size="small"
                                shape="round"
                                color={getCategoryColor("VIDEOS")}
                                onClick={() => onCategoryClick("VIDEOS")}
                                className={
                                    selectedCategories.includes("VIDEOS")
                                        ? classes.selectedCategory
                                        : classes.feedCategory
                                }>
                                VIDEOS
                            </Button>
                        </Flex>

                        {renderFeed(data.length, "default")}
                    </>
                )}
            </Modal>
        </>
    );
};

export { OwlerNewsFeedContainer };
