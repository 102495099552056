import { useState } from "react";
import Provider from "services/api";

const useSegmentationSendReminder = () => {
    const [loading, setLoading] = useState(false);

    const SendReminder = async (segmentationAnalysisUnitId: any) => {
        if (segmentationAnalysisUnitId !== -1) {
            setLoading(true);
            await Provider.Segmentation.sendSegmentationSurveyReminder(segmentationAnalysisUnitId, {
                customError: {
                    message: "Error while sending reminder",
                },
            }).catch(err => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
        }
    };

    return {
        loading,
        SendReminder: SendReminder,
    };
};

export { useSegmentationSendReminder };
