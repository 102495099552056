import { createStore, combineReducers } from "redux";
import { User } from "oidc-client";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import { composeWithDevTools } from "redux-devtools-extension";
import userManager from "userManager";
import { ncrModalReducer, NCRModalState } from "./ncrModal";
import { ExcelExportState, excelExportReducer } from "./excelExport";
import { apiReducer, ApiStateType } from "./api";
import reloadReducer, { ReloadState } from "./reporting/reloadSlice";
import mainMenuReducer, { KeysState } from "./mainMenu/mainMenuSlice";

interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

export interface AppState {
    excelExport: ExcelExportState;
    ncrModal: NCRModalState;
    oidc: OidcState;
    api: ApiStateType;
    reload: ReloadState;
    mainMenu: KeysState;
}

const rootReducer = () =>
    combineReducers({
        excelExport: excelExportReducer,
        ncrModal: ncrModalReducer,
        oidc: oidcReducer,
        api: apiReducer,
        reload: reloadReducer,
        mainMenu: mainMenuReducer,
    });

function configureStore() {
    const store = createStore(rootReducer(), composeWithDevTools());

    loadUser(store, userManager);

    return store;
}

const store = configureStore();

export { store };
