import { post, get, remove, patch } from "../base";

const AdminGovernance = {
    addColumn: async (
        column: any,
        tableType:
            | "parentCompanyTable"
            | "parentSupplierTable"
            | "dimensionCompanyTable"
            | "dimensionSupplierTable",
        { customError }: any = {}
    ) => {
        const url = `/v1/RelationsMatrix/Column?tableType=${tableType}`;
        await post({ url, payload: column, customError });
    },
    getAdminPage: async ({ customError }: any = {}) => {
        const url = `/v1/RelationsMatrix/AdminPage`;
        let data = await get({ url });
        return data;
    },
    deleteColumn: async (columnId: any, { customError }: any = {}) => {
        const url = `/v1/RelationsMatrix/RemoveColumn?columnId=${columnId}`;
        await remove({ url });
    },
    updateColumn: async (columndId: any, name: string, { customError }: any = {}) => {
        const url = `/v1/RelationsMatrix?columnId=${columndId}&name=${name}`;
        await patch({ url });
    },
    addDimension: async (dimension: any, { customError }: any = {}) => {
        const url = `/v1/RelationsMatrix/AddDimension`;
        await post({ url, payload: dimension, customError });
    },
    removeDimension: async (rowId: any, { customError }: any = {}) => {
        const url = `/v1/RelationsMatrix/RemoveDimension?rowId=${rowId}`;
        await remove({ url });
    },
};

export { AdminGovernance };
