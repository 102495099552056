import { Modal } from "antd";
import React from "react";
import SemiCircularGauge from "../semiCircularChart";
import classes from "../scorecard.module.scss";
import AreaStatusComponent from "../areaStatusComponent";
import { useTranslation } from "react-i18next";
import { IGetAwardStatus } from "models/scorecard";
interface IProps {
    open: boolean;
    awardData: IGetAwardStatus | null;
    awardLevelsData?: any;
    areaData: any;
    onClose: () => void;
    supplierName?: string;
}
const ScorecardModal = ({
    open,
    awardData,
    areaData,
    onClose,
    supplierName,
    awardLevelsData,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            width={`100%`}
            title={
                <div className={classes.tableTitle}>
                    {supplierName} - {t("scorecard_overview_modal")}
                </div>
            }
            open={open}
            onCancel={() => onClose()}
            footer={null}
            className={classes.modal}>
            <div className={classes.awardModalSection}>
                <SemiCircularGauge
                    openModal={open}
                    value={awardData && awardData?.score}
                    level={awardData?.level}
                    width={542}
                    height={381}
                    widget={false}
                    strokeWidth={15}
                    offset={-12}
                    circleRadius={97}
                    awardLevelData={awardLevelsData}
                />
            </div>
            <div className={classes.modalDevider}>
                <div style={{ width: 100 }} className={classes.tableTitle}>
                    {t("scorecard_area_statesTitle")}
                </div>
                <div className={classes.deviderSection} />
            </div>
            <div className={classes.areaModalSection}>
                <AreaStatusComponent data={areaData} modalFormat={true} />
            </div>
        </Modal>
    );
};

export default ScorecardModal;
