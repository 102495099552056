import { useEffect, useState } from "react";
import { ISegmentationAlignmentInfo } from "models";
import { Provider } from "services";

type UsePartnersType = {
    data: ISegmentationAlignmentInfo | undefined;
    loading: boolean;
};

const useSegmentParentInfo = (parentSupplierId: any, reload: any = null) => {
    const [state, setState] = useState<UsePartnersType>({
        data: undefined,
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data: any = await Provider.Survey.GetParentAlignmentInfo(parentSupplierId);
            setState({
                data: data,
                loading: false,
            });
        })();
    }, [reload, parentSupplierId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSegmentParentInfo };
