import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import SurveyScoreBarChart from "./surveyScoreBarChart";
import ReviewCategoryBarChart from "./reviewCategoryBarChart";
import RatingDistributionPieChart from "./ratingDistributionPieChart";
import SurveyReviewTable from "./surveyReviewTable";
import NcrReportsBarChart from "./ncrReportsBarChart";
import KpiScoreBarCharts from "./kpiScoreBarCharts";
import FillRateProgressBar from "./fillRateProgressBar";
import ScoreProgressBar from "./scoreProgressBar";
import ComplianceElements from "./complianceElements/complianceElements";
import { Card, Button, Row, Col, Tooltip, Spin } from "antd";
import {
    ClipboardCheck,
    FileDownload03,
    GridRangePicker,
    InfoCircle,
    Star01,
} from "components/utilitycomponents";
import classes from "./styles/performanceOverview.module.scss";
import CardTitle from "./cardTitle";
import { formatTime } from "utilities";
import { RadarDiagram } from "./radarDiagram";
import dayjs from "utilities/daysJsConfig";
import { Margin, usePDF } from "react-to-pdf";
import { useNcrFeature } from "controller";

type PropsType = {
    partner: any;
    reloadKpi: any;
};

export const PerformanceOverview: FunctionComponent<PropsType> = ({ partner, reloadKpi }) => {
    const { t } = useTranslation();
    const [range, setRange] = useState<any>({
        to: dayjs()
            .endOf("day")
            .toISOString(),
        from: dayjs()
            .subtract(24, "months")
            .startOf("day")
            .toISOString(),
    });
    const [exporting, setExporting] = useState(false);
    const { hasNcrFeature } = useNcrFeature();

    const { toPDF, targetRef } = usePDF({
        filename: `performanceOvefview_${partner.partnerId}.pdf`,
        page: { margin: Margin.MEDIUM },
    });

    return (
        <div ref={targetRef}>
            <div className="performanceOverviewContainer">
                <div className={classes.dateBar}>
                    <GridRangePicker
                        onRangeChange={setRange}
                        {...range}
                        className={classes.rangePicker}
                    />
                    <Button
                        icon={
                            <Spin size="small" spinning={exporting}>
                                <FileDownload03 />
                            </Spin>
                        }
                        className={classes.exportButton}
                        onClick={async () => {
                            setExporting(true);
                            await toPDF();
                            setExporting(false);
                        }}>
                        {t("exportBtnText")}
                    </Button>
                </div>
                <div className={classes.dateBarPrint}>
                    {t("supplierProfile_PerfomancePrintInfo")} {partner.partnerName} - {t("from")}{" "}
                    {formatTime(range.from, "MMM D, YYYY")} {t("to")}{" "}
                    {formatTime(range.to, "MMM D, YYYY")}
                </div>

                <Card
                    data-test="supplier_profile_performance_review_score"
                    title={
                        <CardTitle icon={<Star01 />} title={t("supplierProfile_ReviewScore")} />
                    }>
                    <Row>
                        <Col xs={24} md={12} xxl={8}>
                            <SurveyScoreBarChart range={range} partnerId={partner.partnerId} />
                        </Col>
                        <Col xs={24} md={12} xxl={8}>
                            <RatingDistributionPieChart
                                range={range}
                                partnerId={partner.partnerId}
                            />
                        </Col>
                        <Col xs={24} md={12} xxl={8}>
                            <SurveyReviewTable range={range} partnerId={partner.partnerId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={24} xxl={24}>
                            <ReviewCategoryBarChart range={range} partnerId={partner.partnerId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={24} xxl={24}>
                            <RadarDiagram range={range} partnerId={partner.partnerId} />
                        </Col>
                    </Row>
                </Card>

                <KpiScoreBarCharts
                    range={range}
                    partner={partner}
                    reload={reloadKpi}
                    partnerId={partner.partnerId}
                />

                <div style={{ pageBreakBefore: "always" }}>
                    <Row gutter={16} style={{ pageBreakInside: "avoid" }}>
                        <Col lg={8} xs={24} style={{ marginTop: "10px" }}>
                            <Card
                                title={
                                    <CardTitle
                                        icon={<ClipboardCheck />}
                                        title={t("supplierProfile_SelfAssessmentFillRate")}
                                    />
                                }>
                                <FillRateProgressBar
                                    type="SA"
                                    range={range}
                                    partnerId={partner.partnerId}
                                />
                            </Card>
                        </Col>
                        <Col lg={8} xs={24} style={{ marginTop: "10px" }}>
                            <Card
                                title={
                                    <CardTitle
                                        icon={<ClipboardCheck />}
                                        title={t("supplierProfile_ComplianceFillRate")}
                                    />
                                }>
                                <FillRateProgressBar
                                    type="Compliance"
                                    range={range}
                                    partnerId={partner.partnerId}
                                />
                            </Card>
                        </Col>
                        <Col lg={8} xs={24} style={{ marginTop: "10px" }}>
                            <Card
                                title={
                                    <CardTitle
                                        icon={<ClipboardCheck />}
                                        title={t("supplierProfile_ComplianceScore")}
                                    />
                                }>
                                <ScoreProgressBar
                                    type="Compliance"
                                    range={range}
                                    partnerId={partner.partnerId}
                                />
                            </Card>
                        </Col>
                    </Row>

                    {hasNcrFeature && (
                        <div style={{ marginTop: "10px", pageBreakInside: "avoid" }}>
                            <NcrReportsBarChart range={range} partnerId={partner.partnerId} />
                        </div>
                    )}

                    <div style={{ pageBreakInside: "avoid", marginTop: "10px" }}>
                        <Card
                            title={
                                <div>
                                    <CardTitle
                                        icon={<ClipboardCheck />}
                                        title={t("supplierProfile_ComplianceElements")}
                                    />
                                    <Tooltip title={t("supplierProfile_ComplianceElementsInfo")}>
                                        <span>
                                            <InfoCircle />
                                        </span>
                                    </Tooltip>
                                </div>
                            }>
                            <ComplianceElements range={range} partnerId={partner.partnerId} />
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PerformanceOverview;
