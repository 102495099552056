import React, { FunctionComponent, useState, Fragment } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classnames from "classnames";
import { newlineToBr } from "utilities";
import { useTranslation } from "react-i18next";
import classes from "./showMore.module.scss";
import { PureDiv } from "components/utilitycomponents";

type PropsType = {
    text: string;
    lineLimit?: number;
    characterLimit?: number;
    UpIcon?: any;
    DownIcon?: any;
    showGuidText?: boolean;
    showMoreText?: string;
    showLessText?: string;
    convertNlToBr?: boolean;
    showGuidAlign?: "left" | "right" | "center";
    className?: string;
};

const ShowMore: FunctionComponent<PropsType> = ({
    text,
    lineLimit = 20,
    characterLimit = 600,
    UpIcon,
    DownIcon,
    showGuidText = true,
    showMoreText,
    showLessText,
    convertNlToBr = true,
    showGuidAlign = "center",
    className = "",
}) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState(false);

    let trimmedText = text.split(/(?:\r\n|\r|\n)/g, lineLimit).join("\n");
    if (trimmedText.length > characterLimit) {
        trimmedText = trimmedText.substr(0, characterLimit);
    }
    const textIsTrimmed = trimmedText.length < text.length;

    let displayedText = "";
    if (text && convertNlToBr) {
        displayedText = newlineToBr(showMore ? text : trimmedText);
    } else if (text) {
        displayedText = showMore ? text : trimmedText;
    }

    return (
        <div>
            <PureDiv
                className={classnames(classes.breakLongText, className)}
                textContent={displayedText}
            />
            {textIsTrimmed && (
                <div style={{ textAlign: showGuidAlign }}>
                    <Button
                        type="link"
                        onClick={e => {
                            e.stopPropagation();
                            setShowMore(!showMore);
                        }}
                        style={{ height: "22px", paddingRight: 0, paddingLeft: 0 }}>
                        {showMore ? (
                            <Fragment>
                                {!showGuidText ? null : showMoreText ? showMoreText : t("showLess")}{" "}
                                {UpIcon ? <UpIcon /> : <UpOutlined />}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {!showGuidText ? null : showLessText ? showLessText : t("showMore")}{" "}
                                {DownIcon ? <DownIcon /> : <DownOutlined />}
                            </Fragment>
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export { ShowMore };
