import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Alert, Tag, Button } from "antd";

interface IProps {
    visible: boolean;
    onClose: (reload?: boolean) => void;
    data: { id: number; name: string | number }[];
    modalTitle?: string;
    name: string;
    alertDescription?: string;
    confirmLoading: boolean;
    onConfirm: Function;
}

const GridDeleteModal: FunctionComponent<IProps> = ({
    visible,
    modalTitle,
    name,
    data,
    alertDescription,
    confirmLoading,
    onConfirm,
    onClose,
}) => {
    const { t } = useTranslation();
    const [confirmedData, setConfirmedData] = useState<any>(data);

    useEffect(() => {
        setConfirmedData(data.map(item => item.id));
    }, [data]);

    function renderTags(data: any) {
        return data.map((item: any) => {
            return (
                <Tag color="blue" key={item.id}>
                    {item.name}
                </Tag>
            );
        });
    }

    return (
        <Modal
            wrapProps={{ "data-test": "delete_modal" }}
            title={
                modalTitle
                    ? modalTitle
                    : t("grid_DeleteSelected", `Delete Selected ${name}`, { name: name })
            }
            open={visible}
            onCancel={() => onClose()}
            onOk={() => onConfirm(confirmedData)}
            footer={[
                <Button
                    loading={confirmLoading}
                    type="primary"
                    danger
                    onClick={async () => await onConfirm(confirmedData)}>
                    {t("generic_Confirm")}
                </Button>,
            ]}>
            <Fragment>
                <div className="gridTagLabel">
                    {t("grid_TagSelected", `Selected ${name}`, { name: name })}:
                </div>
                <div className="gridTagArea">{renderTags(data)}</div>
                <Alert
                    message={t("attention")}
                    type={"warning"}
                    description={
                        <div>
                            {alertDescription
                                ? alertDescription
                                : t(
                                      "grid_DeleteWarning",
                                      `Are you sure you want to delete the selected ${name}? This action is irreversible.`,
                                      { name: name.toLocaleLowerCase() }
                                  )}
                        </div>
                    }
                />
            </Fragment>
        </Modal>
    );
};

export { GridDeleteModal };
