import { get } from "../base";

const Kpi = {
    getAvailableKpis: async (
        supplierId: number,
        visibility: string,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/Kpi/AvailableKpis?supplierId=${supplierId}&visibility=${visibility}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
        return response;
    },
    getAvailableSubLevels: async (
        kpiId: number,
        supplierId: number,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/Kpi/AvailableSubLevels?kpiId=${kpiId}&supplierId=${supplierId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
        return response;
    },
};

export default Kpi;
