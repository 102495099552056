import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { Row } from "antd";
import classes from "./styles/form.module.scss";

const FormRow: FunctionComponent<{ align?: any; classNames?: any[]; [key: string]: any }> = ({
    children,
    align = "middle",
    classNames = [],
    ...rest
}) => (
    <Row align={align} {...rest} className={classnames(classes.formRow, ...classNames)}>
        {children}
    </Row>
);

export { FormRow };
