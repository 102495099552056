import React, { forwardRef, useMemo } from "react";
import { Select, Spin, Empty } from "antd";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
const { Option, OptGroup } = Select;

type PropsType = {
    value?: any;
    selectIdField?: string;
    selectOptionField:
        | string
        | ((item: any) => { text: string; disabled?: boolean; node?: React.ReactNode });
    emptyOption?: any;
    onChange?: (value?: any, item?: any) => void;
    useData: Function;
    groupFilter?: Function;
    groupNames?: any[];
    [key: string]: any;
    defaultValue?: string;
};

const SelectData = forwardRef(
    (
        {
            defaultValue = "",
            value,
            selectIdField = "id",
            selectOptionField,
            emptyOption = null,
            onChange,
            useData,
            groupFilter,
            groupNames,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();
        const { data: originalData, loading } = useData();

        const data = useMemo(() => {
            if (defaultValue && defaultValue !== "" && originalData) {
                const defaultValueExists = originalData.some(
                    (item: any) => item[selectIdField] === defaultValue
                );
                if (!defaultValueExists) {
                    const defaultItem: any = { [selectIdField]: defaultValue };
                    if (typeof selectOptionField === "string") {
                        defaultItem[selectOptionField] = defaultValue;
                    } else if (typeof selectOptionField === "function") {
                        const result = selectOptionField({ [selectIdField]: defaultValue });
                        defaultItem.text = result.text;
                    }
                    return [defaultItem, ...originalData];
                }
            }
            return originalData;
        }, [originalData, defaultValue, selectIdField, selectOptionField]);

        const getOptionText = (item: any) => {
            if (typeof selectOptionField === "string") {
                return item[selectOptionField];
            } else if (typeof selectOptionField === "function") {
                return selectOptionField(item).text;
            }
            return "";
        };

        const renderOptionsStructure = () => {
            if (groupNames && groupNames.length > 0) {
                return groupNames.map(name => {
                    let selectData = data
                        ? data.filter((c: any) => groupFilter && groupFilter(c, name))
                        : [];
                    return selectData.length > 0 ? (
                        <OptGroup key={name} label={name}>
                            {renderOptions(selectData)}
                        </OptGroup>
                    ) : (
                        renderOptions(selectData)
                    );
                });
            } else {
                return renderOptions(data);
            }
        };

        const renderOptions = (data: any) => {
            return (
                data &&
                data.map((item: any, index: number) => (
                    <Option
                        data-test={getOptionText(item).replace(/\s/g, "")}
                        disabled={
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).disabled === true
                                : false
                        }
                        key={index}
                        value={item[selectIdField]}
                        {...(item.color ? { style: { color: item.color } } : {})}
                        data-text={getOptionText(item)}>
                        {typeof selectOptionField === "function"
                            ? selectOptionField(item).node || getOptionText(item)
                            : getOptionText(item)}
                    </Option>
                ))
            );
        };

        return (
            <Select
                defaultValue={defaultValue !== "" ? defaultValue : null}
                value={
                    value !== undefined && data.some((x: any) => x[selectIdField] === value)
                        ? (value as any)
                        : undefined
                }
                onChange={(value: string) => {
                    onChange &&
                        onChange(
                            value,
                            value &&
                                data.filter(
                                    (x: any) => x[selectIdField].toString() === value.toString()
                                )[0]
                        );
                }}
                showSearch
                filterOption={(inputValue: any, option: any) => {
                    if (!option) return false;
                    return (
                        option["data-text"] &&
                        option["data-text"]
                            .toLocaleLowerCase()
                            .includes(inputValue.toLocaleLowerCase())
                    );
                }}
                notFoundContent={
                    loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                popupClassName={classnames("dropdownWrapper", rest.dropdownClassName)}
                getPopupContainer={(node: any) =>
                    node ? (node.parentNode as HTMLElement) : document.body
                }
                {...rest}>
                {emptyOption && (
                    <Option
                        key={-1}
                        value={emptyOption[selectIdField] ? emptyOption[selectIdField] : undefined}
                        style={{ color: emptyOption.color ? emptyOption.color : "#BFBFBF" }}>
                        {typeof selectOptionField !== "function" && emptyOption[selectOptionField]
                            ? emptyOption[selectOptionField]
                            : t("select") + "..."}
                    </Option>
                )}
                {renderOptionsStructure()}
            </Select>
        );
    }
);

export { SelectData };
