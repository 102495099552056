import { IOwlerCompetitor } from "models/owler/IOwlerCompetitor";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IOwlerCompetitor[];
};

const useOwlerCompetitorList = (domain: string | undefined, reload?: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (domain === undefined || domain === null) {
                setState({ loading: false, data: [] });
                return;
            }
            setState({ ...state, loading: true });
            let data: IOwlerCompetitor[] = await Provider.Owler.GetOwlerCompetitorList(
                domain
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, [domain, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useOwlerCompetitorList;
