import { IGeneralCompanySettings } from "models/admin/companySettings/ICompanySettings";
import { useState } from "react";
import Provider from "services/api";

const useCompanySettingsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateSettings = async (settings: IGeneralCompanySettings) => {
        setLoading(true);
        const id = await Provider.Company.updateCompanySettings(settings, {
            customError: {
                message: "Could not update settings",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        updateSettings,
    };
};

export { useCompanySettingsUpdate };
