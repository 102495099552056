import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

export const useComplianceContactsFilter = (from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Compliance.getContactsForFilter(from, to);

            let firstGuestIndex: number | undefined = undefined;
            let guestContacts: any[] = data.filter((x: any, index: number) => {
                if (
                    firstGuestIndex === undefined &&
                    x.fullName.toLocaleLowerCase() === "guest guest"
                ) {
                    firstGuestIndex = index;
                    return true;
                } else if (x.fullName.toLocaleLowerCase() === "guest guest") {
                    return true;
                } else {
                    return false;
                }
            });
            if (guestContacts.length) {
                let combinedGuestContacts: any = {
                    id: guestContacts
                        .map(x => x.id)
                        .toString()
                        .trim()
                        //built in antd filter dropdown selection uses comma as delimiter. That is why use a semicolon here to keep the value from being split.
                        .replace(",", ";"),
                    fullName: "Guest Guest",
                };
                data.splice(firstGuestIndex, guestContacts.length, combinedGuestContacts);
            }
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};
