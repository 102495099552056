import { get } from "../base";

const Owler = {
    GetOwlerCompanyData: async (domain: string, { customError }: any = {}) => {
        let parsedDomain = domain.replace("https://", "");
        const url = `/v1/OwlerApi/GetOwlerCompanyData?website=${encodeURIComponent(parsedDomain)}`;
        return await get({ url, customError });
    },
    GetOwlerNewsFeed: async (domain: string, { customError }: any = {}) => {
        let parsedDomain = domain.replace("https://", "");
        const url = `/v1/OwlerApi/GetOwlerFeedData?website=${encodeURIComponent(parsedDomain)}`;
        return await get({ url, customError });
    },
    GetOwlerCompetitorList: async (domain: string, { customError }: any = {}) => {
        let parsedDomain = domain.replace("https://", "");
        const url = `/v1/OwlerApi/GetOwlerCompetitorData?website=${parsedDomain}`;
        return await get({ url, customError });
    },
};

export default Owler;
