import React, { FunctionComponent, useRef } from "react";
import { Button } from "antd";
import classes from "./styles/attachArea.module.scss";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";

type PropTypes = {
    onSelect: Function;
    loading: boolean;
    icon?: any;
    multiple?: boolean;
    disabled?: boolean;
    accept?: string;
    [key: string]: any;
};

const AttachArea: FunctionComponent<PropTypes> = ({
    onSelect,
    loading = false,
    icon = <UploadOutlined />,
    multiple = true,
    disabled = false,
    accept = null,
    children = "",
    ...rest
}) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const onChange = (ev: any) => {
        onSelect(Array.from(ev.target.files));
    };

    const preventDefault = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: any) => {
        preventDefault(e);
        let dt = e.dataTransfer;
        let files = dt.files;
        onSelect(files);
    };

    let className = "";
    if (rest.className) {
        className = rest.className;
        delete rest.className;
    } else {
        className = classes.attachArea;
    }

    return (
        <div style={{ position: "relative" }}>
            <input
                data-test="upload_input"
                style={{
                    opacity: "0",
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                }}
                type="file"
                name="files"
                {...(multiple ? { multiple: true } : {})}
                {...(accept ? { accept: accept } : {})}
                onChange={onChange}
                ref={inputEl}
                key={Date.now()}
            />
            <Button
                {...rest}
                className={className}
                loading={loading}
                icon={icon && icon}
                disabled={loading || disabled}
                onDrop={handleDrop}
                onDragEnter={e => preventDefault(e)}
                onDragOver={e => preventDefault(e)}
                onDragLeave={e => preventDefault(e)}
                onClick={() => inputEl.current !== null && inputEl.current.click()}>
                {children === "" ? t("generic_Upload") : children}
            </Button>
        </div>
    );
};

export { AttachArea };
