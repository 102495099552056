import { useEffect, useState } from "react";
import { Provider } from "services";
import { IReviewGraph } from "models";

type StateType = {
    data: IReviewGraph | null;
    loading: boolean;
};

const useReviewGraph = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Partner.getReviewGraph(partnerId, from, to, {
                customError: {
                    message: "Could not retrive data for review chart",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [from, to, partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewGraph };
