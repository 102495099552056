import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ExcelExportProps } from "store/excelExport/types";
import { AppState } from "store";
import { DownloadOutlined, FileExcelOutlined, SyncOutlined } from "@ant-design/icons";
import { Progress, Tooltip } from "antd";
import classes from "./downloadProgress.module.scss";

interface Props {
    excelExportProps?: ExcelExportProps;
    className?: string;
}

const DownloadProgress = (props: Props) => {
    const { t } = useTranslation();
    if (!props.excelExportProps) return null;
    return (
        <Fragment>
            {props.excelExportProps.loading === true &&
                props.excelExportProps.downloadProgress === 0 && (
                    <div className={props.className}>
                        <Tooltip
                            title={t(
                                "ExcelExport_RequestProcess",
                                "We are currently processing your excel export request"
                            )}>
                            <SyncOutlined className={classes.syncIcon} spin></SyncOutlined>
                            <FileExcelOutlined className={classes.fileIcon} />
                        </Tooltip>
                    </div>
                )}
            {props.excelExportProps.loading === true &&
                props.excelExportProps.downloadProgress > 0 && (
                    <div className={props.className}>
                        <Progress
                            className={classes.progressIcon}
                            type="circle"
                            status="active"
                            percent={props.excelExportProps.downloadProgress}
                            width={26}
                        />
                        <DownloadOutlined className={classes.fileIcon} />
                    </div>
                )}
        </Fragment>
    );
};

function mapStateToProps(state: AppState) {
    return {
        excelExportProps: state.excelExport.props,
    };
}

export default connect(mapStateToProps, null)(DownloadProgress);
