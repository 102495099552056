import React, { Fragment, FunctionComponent } from "react";
import { Form } from "antd";
import { IAction, ISystemActionType } from "models/action";
import { SelectDataMultiple } from "components/utilitycomponents";
import { useContacts } from "controller";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { SelectExternalContacts } from "components/utilitycomponents/select/selectExternalContacts/selectExternalContacts";

interface IProps {
    data: IAction | null;
    editMode: boolean;
    supplierId?: any;
    reload?: any;
    onChange?: Function;
    initialOwner?: any;
}

const ActionOwnerSelect: FunctionComponent<IProps> = ({
    data,
    editMode,
    supplierId,
    reload = { reload: false },
    onChange,
    initialOwner,
}) => {
    const { t } = useTranslation();
    const currentUserContactId = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-ContactId"]
    );

    return (
        <Fragment>
            {(data && data.systemGeneratedType === ISystemActionType.GateKeeper && editMode) ||
            (data &&
                data.systemGeneratedType === ISystemActionType.ComplianceEndDate &&
                editMode) ? (
                <Form.Item name="owners" label={t("actions_Owner")} initialValue={data.ownerId}>
                    <SelectDataMultiple
                        data-test="action_owner_select"
                        allowClear
                        placeholder={t("actions_OwnerPlaceholder")}
                        disabled
                        selectOptionField="name"
                        useData={useContacts.bind(null, ["Administrator", "User", "LightUser"])}
                    />
                </Form.Item>
            ) : (
                <Form.Item
                    name="owner"
                    label={t("actions_Owner")}
                    initialValue={initialOwner ? initialOwner : [parseInt(currentUserContactId)]}
                    rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                    <SelectExternalContacts
                        data-test="action_owner_select"
                        placeholder={t("actions_OwnerPlaceholder")}
                        disabled={
                            data &&
                            editMode &&
                            (data.systemGenerated ||
                                data.completed ||
                                data.editable === false ||
                                data.createdExternally)
                                ? true
                                : false
                        }
                        selectOptionField="name"
                        supplierId={supplierId}
                        reload={reload}
                        onChange={(_: any, item: any) => onChange && onChange(item)}
                    />
                </Form.Item>
            )}
        </Fragment>
    );
};

export { ActionOwnerSelect };
