import React from "react";
import { useEnabledPowerBiReportsByArea } from "controller";
import { AreaType } from "models";
import { Tabs, Spin } from "antd";
import { PbiEmbedReport } from "components/utilitycomponents";
import classes from "./portfolio.module.scss";

const Portfolio = () => {
    const {
        data: enabledPowerBiReports,
        loading: loadingEnabledPowerBiReports,
    } = useEnabledPowerBiReportsByArea(AreaType.Ncr);

    return (
        <div className={classes.portfolioContainer}>
            <Spin spinning={loadingEnabledPowerBiReports}>
                <Tabs
                    type="card"
                    defaultActiveKey={"0"}
                    key="reportContainer"
                    items={
                        enabledPowerBiReports.length > 0 &&
                        enabledPowerBiReports.map((report: any, index: number) => {
                            return {
                                label: report.name,
                                key: index,
                                children: (
                                    <PbiEmbedReport
                                        workspaceId={report.workspaceId}
                                        reportId={report.reportId}
                                        reportClassName={classes.pbiReport}
                                    />
                                ),
                            };
                        })
                    }></Tabs>
            </Spin>
        </div>
    );
};

export { Portfolio };
