import { useState } from "react";
import Provider from "services/api";

const useUpdateVisibility = () => {
    const [loading, setLoading] = useState(false);

    const changeVisibility = async (visibility: number, contactIds: number[]) => {
        setLoading(true);
        const id = await Provider.Contact.updateContactsVisibility(visibility, contactIds, {
            customError: {
                message: "Could not change visibility",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        changeVisibility,
    };
};

export { useUpdateVisibility };
