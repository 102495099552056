import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/shared.module.scss";
import { IMessageDetail } from "models";
import { ShowMore } from "components/utilitycomponents/showMore/showMore";

type PropsType = {
    data: IMessageDetail;
};

const Message: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {t("notification_AddMessage", { creator: data.creator })}
                <ShowMore
                    showGuidAlign="left"
                    text={data.message}
                    characterLimit={50}
                    lineLimit={1}
                />
            </div>
        </span>
    );
};

export { Message };
