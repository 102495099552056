import { useState } from "react";
import { Provider } from "services";

const useComplianceTemplateForScoreChartUpdate = () => {
    const [loading, setLoading] = useState(false);
    const setTemplate = async (templateId: number, complianceQuestionIds: number[]) => {
        setLoading(true);
        if (templateId) {
            let data = await Provider.Compliance.UpdateSupplierProfileComplianceTemplate(
                templateId,
                complianceQuestionIds,
                {
                    customError: {
                        message: "Could not set template",
                    },
                }
            ).catch((err: any) => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
            return data;
        } else {
            let data = await Provider.Compliance.deleteSupplierProfileComplianceTemplateDefaults().catch(
                (err: any) => {
                    setLoading(false);
                    return Promise.reject(err);
                }
            );
            setLoading(false);
            return data;
        }
    };

    return {
        loading,
        setTemplate,
    };
};

export { useComplianceTemplateForScoreChartUpdate };
