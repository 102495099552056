import React from "react";
import { Row, Col } from "antd";
import classes from "./supplierInformation.module.scss";
import { usePartnerFields } from "controller";

interface IProps {
    data: any;
}

const RelationshipOwners = ({ data }: IProps) => {
    const { data: partnerFields } = usePartnerFields();
    const OWNER_HEADLINE =
        partnerFields && partnerFields.headlines.find((x: any) => x.name === "Owner");

    return (
        <Row style={{ overflowX: "auto" }}>
            <Col span={24}>
                <Row>
                    <Col span={24} className={classes.headline}>
                        {OWNER_HEADLINE && OWNER_HEADLINE.customLabel
                            ? OWNER_HEADLINE.customLabel.toLocaleUpperCase()
                            : OWNER_HEADLINE?.standardLabel.toLocaleUpperCase()}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {data.owners.length > 0
                            ? data.owners.map((owner: any) => {
                                  return (
                                      <div className={classes.contactContainer}>
                                          <div className={classes.companyContact}>
                                              {owner.fullName}
                                          </div>
                                      </div>
                                  );
                              })
                            : "-"}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export { RelationshipOwners };
