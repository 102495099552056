import { useEffect, useState } from "react";
import Provider from "services/api";

type StateType = {
    data: any;
    loading: boolean;
};

const useSelfAssessment = (token: string, reload?: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.SelfAssessment.getTemplateForSelfAssessment(token).catch(
                err => {
                    setState({ data: null, loading: false });
                    return Promise.reject(err);
                }
            );
            setState({ loading: false, data });
        })();
    }, [token, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSelfAssessment };
