import { useEffect, useState } from "react";
import { Provider } from "services";

type stateType = {
    data: any;
    loading: boolean;
};

const useCarbonScore = (partnerId: number, reload: any = null) => {
    const [state, setState] = useState<stateType>({
        data: undefined,
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data = await Provider.Partner.getCarbonScore(partnerId);
            setState({
                data: data,
                loading: false,
            });
        })();
    }, [reload, partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCarbonScore };
