import { useEffect, useState } from "react";
import { IPartner } from "models";
import { Provider } from "services";

export type UsePartnersType = {
    data: IPartner[];
    loading: boolean;
};

const usePartners = (reload: any = null) => {
    const [partners, setPartners] = useState<UsePartnersType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setPartners({
                ...partners,
                loading: true,
            });
            const data = await Provider.Partner.getPartners();
            setPartners({
                data,
                loading: false,
            });
        })();
    }, [reload]);

    return {
        loading: partners.loading,
        data: partners.data,
    };
};

export default usePartners;
