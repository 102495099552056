import React, { useState, useEffect } from "react";
import { Button, Col, message, notification, Progress, Row, Tooltip } from "antd";
import { CreateModal } from "./createModal";
import { EditModal } from "./editModal";
import {
    AlertTriangle,
    getColumnRangePickerProps,
    getColumnSearchProps,
    getColumnSelectProps,
    Grid,
    GridButton,
    GridDeleteModal,
    Link01,
} from "components/utilitycomponents";
import {
    useDevelopmentPlanCRUD,
    useDevelopmentPlanOverview,
    useDevelopmentPlanTemplates,
    usePartnerList,
    useDevelopmentPlanContactsFilter,
    usePartnerFields,
} from "controller";
import { useTranslation } from "react-i18next";
import classes from "./developmentPlans.module.scss";
import { formatTime, getDomainUrl } from "utilities";
import { useLocation, useParams } from "react-router-dom";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";

interface IProps {
    editButtons?: boolean;
    partnerId?: number;
}

const Overview = ({ editButtons = true, partnerId }: IProps) => {
    const { planId }: any = useParams();
    const location = useLocation();
    const [toggleCreateModal, setToggleCreateModal] = useState(false);
    const [toggleEditModal, setToggleEditModal] = useState({ dataId: undefined, visible: false });
    const [reload, setReload] = useState({ reload: false });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });
    const { deleteDevelopmentPlan, loading: deleting } = useDevelopmentPlanCRUD();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const { data: partnerFields } = usePartnerFields();
    const [actionsFilter, setActionsFilter] = useState<any>([]);
    const [toggleShareButton, setToggleShareButton] = useState<any>(undefined);
    const [customDevPlanFields, setCustomDevPlanFields] = useState<any>({});

    useEffect(() => {
        if (planId !== undefined) {
            setToggleEditModal({ dataId: planId, visible: true });
        }
    }, [planId]);
    useEffect(() => {
        if (location.pathname.includes("MyPlans")) {
            setActionsFilter([{ name: "MyPlans", value: "MyPlans" }]);
        }
    }, []);

    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;

    const getStrokeColor = (
        completedActions: number,
        overDueActionsCount: number,
        actionsCount: number
    ) => {
        let percentage = (completedActions / actionsCount) * 100;
        if (percentage === 100) {
            return {
                "0%": "#00b204",
                "100%": "#00b204",
            };
        } else if (percentage < 100 && overDueActionsCount === 0) {
            return {
                "0%": "#0477a9",
                "100%": "#0477a9",
            };
        } else if (percentage < 100 && overDueActionsCount !== 0) {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        } else {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        }
    };
    const handleOnRow = (record: any) => ({
        onClick: async () => {
            setToggleEditModal({
                dataId: record.id,
                visible: true,
            });
        },
        onMouseEnter: () => {
            setToggleShareButton(record.id);
        },
        onMouseLeave: () => {
            setToggleShareButton(undefined);
        },
        style: {
            cursor: "pointer",
        },
    });
    const handleDelete = async (ids: number[]) => {
        await deleteDevelopmentPlan(ids);
        setDeleteModal({
            visible: false,
        });
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setReload({ reload: true });
        notification.success({
            message: t("success"),
            description: t(`developmentPlan_DeleteSuccess`),
        });
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };
    const handleCopyUrl = async (planId: number) => {
        await navigator.clipboard.writeText(`${getDomainUrl()}/DevelopmentPlans/${planId}`);
        message.success(t("generic_CopiedToClipboard"));
    };

    const columns = [
        {
            title: companyNameColTitle || t("company_Type_Supplier"),
            viewColumnId: "supplier",
            dataIndex: "supplierName",
            key: "Supplier",
            filterType: "array",
            width: 200,
            sorter: true,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "Supplier" }
            ),
        },
        {
            title: "Template",
            viewColumnId: "template",
            dataIndex: "templateName",
            key: "Template",
            ellipsis: true,
            filterType: "array",
            width: 200,
            sorter: true,
            ...getColumnSelectProps(
                useDevelopmentPlanTemplates.bind(null),
                {
                    value: ["id"],
                    text: ["title"],
                },
                { dataIndex: "Template" }
            ),
        },
        {
            title: "Title",
            viewColumnId: "title",
            dataIndex: "title",
            key: "title",
            width: 200,
            sorter: true,
            ellipsis: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "title" }),
        },
        {
            title: "Progress",
            viewColumnId: "progress",
            dataIndex: "actionsCount",
            key: "Action",
            width: 200,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("overdue_actions"), value: "OverdueAction" },
                            { text: t("no_overdue_actions"), value: "NoOverdueAction" },
                            { text: t("action_AllCompletedActions"), value: "CompletedAction" },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "Action", radioChoice: true }
            ),
            render: (_: any, record: any) => (
                <div className={classes.progress}>
                    <div className={classes.overDueAction}>
                        <Tooltip
                            title={t("developmentPlan_OverDueActionTooltip", {
                                count: record.overDueActionsCount,
                            })}>
                            <span>
                                <AlertTriangle
                                    style={{
                                        display: record.overDueActionsCount !== 0 ? "" : "none",
                                    }}
                                    className={classes.overDueActionIcon}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <Progress
                        success={{ percent: 0 }}
                        size="small"
                        percent={(record.completedActions / record.actionsCount) * 100}
                        format={val => (
                            <span>{`${record.completedActions} / ${record.actionsCount}`}</span>
                        )}
                        strokeWidth={20}
                        strokeColor={getStrokeColor(
                            record.completedActions,
                            record.overDueActionsCount,
                            record.actionsCount
                        )}
                    />
                </div>
            ),
        },
        {
            title: "Next Deadline",
            viewColumnId: "nextDeadline",
            dataIndex: "dueDate",
            filterType: "date",
            sorter: true,
            width: 200,
            key: "Deadline",

            ...getColumnRangePickerProps({ dataIndex: "Deadline" }),
            render: (val: any) => val && formatTime(val),
        },
        {
            title: "Next Action",
            viewColumnId: "nextAction",
            dataIndex: "assignedTo",
            key: "Owner",
            filterType: "array",
            operationColumn: true,
            sorter: true,
            width: 200,
            ...getColumnSelectProps(
                useDevelopmentPlanContactsFilter,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "owner" }
            ),
            render: (val: string, record: any) => (
                <Row>
                    <Col span={toggleShareButton !== record.id ? 24 : 16}>{val}</Col>
                    {toggleShareButton === record.id && (
                        <Col span={4}>
                            <Tooltip title={t("generic_CopyLink")}>
                                <span>
                                    <Link01
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            handleCopyUrl(record.id);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            ),
        },
    ];
    const buttonVisibility = selectedRowKeys.length < 1;
    return (
        <>
            <Grid
                className={editButtons && editButtons === true ? "" : classes.customTableStyle}
                hideGridSetting={editButtons && editButtons === true ? false : true}
                containerMargin={editButtons}
                additionalColumnFilters={actionsFilter}
                tableType="DevelopmentPlan"
                title={() =>
                    editButtons && editButtons === true ? (
                        <>
                            {buttonVisibility ? (
                                <div>
                                    <Button onClick={() => setToggleCreateModal(true)}>
                                        {t("developmentPlan_AddPlan")}
                                    </Button>

                                    <GridButton
                                        style={{ marginLeft: "6px" }}
                                        onClick={() =>
                                            setActionsFilter([
                                                { name: "MyPlans", value: "MyPlans" },
                                            ])
                                        }>
                                        {t("developmentPlan_MyPlans")}
                                    </GridButton>
                                    <GridButton
                                        onClick={() =>
                                            setActionsFilter([
                                                { name: "MySuppliers", value: "MySuppliers" },
                                            ])
                                        }>
                                        {t("developmentPlan_MySuppliers")}
                                    </GridButton>
                                    <span className={classes.devPlanCounts}>
                                        <span>
                                            <strong>{customDevPlanFields.totalActive}</strong>
                                            {t("developmentPlans_Active")}
                                        </span>
                                        <span>|</span>
                                        <span>
                                            <strong>{customDevPlanFields.totalCompleted}</strong>
                                            {t("developmentPlans_Completed")}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <Button
                                    danger
                                    onClick={() => setDeleteModal({ visible: true })}
                                    style={{ marginRight: "6px" }}>
                                    {t("generic_Delete")}
                                </Button>
                            )}
                        </>
                    ) : (
                        <div>{t("developmentPlans_Overview")}</div>
                    )
                }
                columns={columns}
                useData={useDevelopmentPlanOverview.bind(null, partnerId)}
                rowSelection={rowSelection}
                reloadData={reload}
                onRow={handleOnRow}
                showEmptyText={true}
                scroll={editButtons === false ? { y: 200, x: width! } : { x: width! - 250 }}
                onDataCustomFields={(customFields: any) =>
                    customFields !== null && setCustomDevPlanFields(customFields)
                }
            />
            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                    });
                }}
                data={
                    selectedRows &&
                    selectedRows.map((r: any) => ({
                        id: r.id,
                        name: `${r.title}`,
                    }))
                }
                name={"Templates"}
                confirmLoading={deleting}
                onConfirm={handleDelete}
            />
            <CreateModal
                visible={toggleCreateModal}
                onClose={(reload: boolean) => {
                    setToggleCreateModal(false);
                    if (reload) {
                        setReload({ reload: true });
                    }
                }}
                modalPartnerId={partnerId}
            />
            <EditModal
                dataId={toggleEditModal.dataId}
                visible={toggleEditModal.visible}
                onClose={(reload: boolean) => {
                    setToggleEditModal({ dataId: undefined, visible: false });
                    if (reload) {
                        setReload({ reload: true });
                    }
                }}
                onActionUpdate={() => setReload({ reload: true })}
            />

            <div className={classes.legendBox}>
                <div>
                    <span className={classes.circle} style={{ backgroundColor: "#00b204" }}></span>
                    {t("developmentPlan_ActionsCompleted")}
                </div>

                <div>
                    <span className={classes.circle} style={{ backgroundColor: "#0477a9" }}></span>
                    {t("no_overdue_actions")}
                </div>
                <div>
                    <span className={classes.circle} style={{ backgroundColor: "#b20000" }}></span>
                    {t("overdue_actions")}
                </div>
            </div>
        </>
    );
};

export { Overview };
