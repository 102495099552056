import { useEffect, useState } from "react";
import Provider from "services/api";

const useReport = (
    supplierId: number,
    reportId: number,
    reloadReport: { reload: boolean; id: number }
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: {},
    });
    useEffect(() => {
        (async () => {
            if (reportId && (reloadReport.id === reportId || reloadReport.id === -1)) {
                if (reportId !== -1) {
                    setState({
                        loading: true,
                        data: state.data,
                    });
                    const data = await Provider.Analytics.getReport(supplierId, reportId, {
                        retryCount: 3,
                        customError: { message: "Could not retrieve report" },
                    }).catch(err => {
                        setState({ loading: false, data: {} });
                        return Promise.reject(err);
                    });
                    setState({
                        loading: false,
                        data: data,
                    });
                }
            }
        })();
    }, [reportId, reloadReport]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReport };
