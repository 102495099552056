import { useState } from "react";
import Provider from "services/api";

const usePerformanceFileCrud = () => {
    const [loading, setLoading] = useState(false);

    const upsert = async (input: any) => {
        setLoading(true);
        await Provider.Performance.upsertPerformanceLinks(input, {
            customError: {
                message: "Could not create new link",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (id: any) => {
        setLoading(true);
        await Provider.Performance.deletePerformanceLink(id, {
            customError: {
                message: "Could not delete link",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        upsert,
        remove,
    };
};

export { usePerformanceFileCrud };
