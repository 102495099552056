import { ICompanyHeadline } from "models";
import { useState } from "react";
import Provider from "services/api";

const useCompanyHeadlinesUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateHeadlines = async (headlines: ICompanyHeadline[]) => {
        setLoading(true);
        const id = await Provider.Company.updateCompanyHeadlines(headlines, {
            customError: {
                message: "Could not update headlines",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        updateHeadlines,
    };
};

export { useCompanyHeadlinesUpdate };
