import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Eye, GridButton, PlusCircle, Trash02 } from "components/utilitycomponents";

type PropsType = {
    onCreateBtnClick: Function;
    onDeleteBtnClick: Function;
    onChangeVisibilityClick: Function;
    selectedRowKeys: any[];
    externalReviewsSelected: boolean;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    onCreateBtnClick,
    onDeleteBtnClick,
    onChangeVisibilityClick,
    selectedRowKeys,
    externalReviewsSelected,
}) => {
    const { t } = useTranslation();
    const buttonVisibility = selectedRowKeys.length > 0 || externalReviewsSelected;
    return (
        <Fragment>
            {!buttonVisibility && (
                <GridButton data-test="create_review_button" onClick={() => onCreateBtnClick()}>
                    <PlusCircle />
                    {t("review_CreateReview", "Create Review")}
                </GridButton>
            )}
            {buttonVisibility && (
                <>
                    <GridButton
                        data-test="change_visibility_button"
                        onClick={() => onChangeVisibilityClick()}>
                        <Eye />
                        {t("review_ChangeVisibility", "Change Visibility")}
                    </GridButton>
                    <GridButton
                        danger
                        data-test="delete_review_button"
                        onClick={() => onDeleteBtnClick()}>
                        <Trash02 style={{ color: selectedRowKeys.length !== 0 ? "#FF4D4F" : "" }} />
                        {t("review_Delete", "Delete Review")}
                    </GridButton>
                </>
            )}
        </Fragment>
    );
};

export default OverviewHeader;
