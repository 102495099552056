import React, { FunctionComponent } from "react";
import { Modal, Alert, Tag, notification, Button } from "antd";
import classes from "./styles/deleteModal.module.scss";
import { useTranslation } from "react-i18next";
import { useSurveysDelete } from "controller";

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    selectedRowKeys: any[];
    reloadTable: Function;
    clearSelectedRows: Function;
}

const DeleteModal: FunctionComponent<IProps> = ({
    visible,
    onVisibleChange,
    selectedRowKeys,
    reloadTable,
    clearSelectedRows,
}) => {
    const { t } = useTranslation();
    const { loading, deleteSurveys } = useSurveysDelete();

    function onCancel() {
        onVisibleChange();
    }

    function renderTags() {
        return selectedRowKeys.map((row: any) => {
            return (
                <Tag color="blue" key={row}>
                    {row}
                </Tag>
            );
        });
    }

    async function onSubmit() {
        await deleteSurveys(selectedRowKeys, {
            retryCount: 3,
            customError: t("survey_DeleteError"),
        });
        reloadTable();
        onVisibleChange(false);
        clearSelectedRows();
        notification.success({
            message: t("success"),
            description: t("survey_DeleteSuccess"),
        });
    }

    return (
        <Modal
            wrapProps={{ "data-test": "delete_survey_modal" }}
            title={t("survey_DeleteTitle")}
            open={visible}
            confirmLoading={loading}
            footer={[
                <Button
                    loading={loading}
                    type="primary"
                    danger
                    onClick={async () => await onSubmit()}>
                    {t("generic_Confirm")}
                </Button>,
            ]}
            onCancel={onCancel}
            onOk={onSubmit}>
            <div>
                <div className={classes.tagAreaContainer}>
                    <div className={classes.tagLabel}>{t("survey_SelectedSurveys")}</div>
                    <div className={classes.tagArea}>{renderTags()}</div>
                </div>
                <Alert
                    message={t("attention")}
                    type={"warning"}
                    description={<div>{t("survey_DeleteWarning")}</div>}
                />
            </div>
        </Modal>
    );
};

export default DeleteModal;
