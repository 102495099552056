import { IScorecardArea } from "models";
import { useState } from "react";
import Provider from "services/api";

const useScorecardAreaCRUD = () => {
    const [loading, setLoading] = useState(false);

    const createScorecardArea = async (scorecardArea: IScorecardArea) => {
        setLoading(true);
        const id = await Provider.Scorecard.createScorecardArea(scorecardArea).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const updateScorecardArea = async (scorecardArea: IScorecardArea) => {
        setLoading(true);
        await Provider.Scorecard.updateScorecardArea(scorecardArea).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const deleteScorecardArea = async (id: number) => {
        setLoading(true);
        await Provider.Scorecard.deleteScorecardArea(id).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        createScorecardArea,
        updateScorecardArea,
        deleteScorecardArea,
    };
};

export { useScorecardAreaCRUD };
