import { createUserManager } from "redux-oidc";
import { UserManagerSettings } from "oidc-client";
import { getAuthorityUrl, getDomainUrl } from "./utilities";

const frontEndUrl = getDomainUrl();
const authority = getAuthorityUrl();

const redirectUri = `${frontEndUrl}/callback`;
const silentRedirectUri = `${frontEndUrl}/silentRenew.html`;

const signingKeys: any =
    process.env.NODE_ENV === "production"
        ? [
              {
                  kty: "RSA",
                  use: "sig",
                  kid: "1FD8F6EE4A8B2DC86115F062F09DF550383EBB57",
                  x5t: "H9j27kqLLchhFfBi8J31UDg-u1c",
                  e: "AQAB",
                  n:
                      "yhdsCF70DUoB7WhfJZEdZq0CAKBEGmeEO1roh9i-7lF_7O8tpRHDMnU_1KHCkEyhLac0Pac25h-sfLZPnQu4ScFXmCy6pdrw-4Yo-x75AesLwiTYsQCw8sFT9KlrEtbNwX0DBC1yCOcpP1hq0xI714qGrjN35zAq1t_FrwPMB_gNCRHT0-ux1BDMfJDyw7mv5X7RnH6IPDhV2fclQxTa8siyC-bnX-VWN1hGYBatWL1KyFcH_w4HRfpzBatMUBrB-gdHCpppv2wx6TtzJEc41TzO_1L74fkwb4c5J9Ik_dvKFCK3Q_1mesZZjI0CNc6CWWwFjr1ua0fKv1PDiBLNlkaAdefXqeYQqZ9iqDEkouTVoNaYXCaJCnMXt6ikVsqKvt3z7R-ub_0x2zA931XZg-USL9XtUBhh1HyVl_VoXLYNqUHOkhiVm-e0rGJ4EBL8g58F-JpRmo1mrTgRELQmfolsje6skaqpWiFHIBG2gGgm2BYos_MsYEOp5WCRNjOxq7udPyOaonDpdQ1OWhVB7dza9azhkyhqfrEPxL8V_gzDMZIa0qMp3unAK3TybwFswRWFU-YPXJAKSmQsoyUVBO_-qfQVriPRDOoTXM5H_5XL2RX-vxkTHk9UYLz3o8Lp7MJuxCaNZTUzUmhQZwnbe0614oTsW8v2vYRERq8kCP0",
                  x5c: [
                      "MIIFJzCCAw+gAwIBAgIUGem+uAhSU0WL53ECMUIvBc1xUTwwDQYJKoZIhvcNAQELBQAwIzEhMB8GA1UEAwwYaWRlbnRpdHkubGVhbmxpbmtpbmcuY29tMB4XDTE5MTExNTEzMjMzNFoXDTI5MTExMjEzMjMzNFowIzEhMB8GA1UEAwwYaWRlbnRpdHkubGVhbmxpbmtpbmcuY29tMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAyhdsCF70DUoB7WhfJZEdZq0CAKBEGmeEO1roh9i+7lF/7O8tpRHDMnU/1KHCkEyhLac0Pac25h+sfLZPnQu4ScFXmCy6pdrw+4Yo+x75AesLwiTYsQCw8sFT9KlrEtbNwX0DBC1yCOcpP1hq0xI714qGrjN35zAq1t/FrwPMB/gNCRHT0+ux1BDMfJDyw7mv5X7RnH6IPDhV2fclQxTa8siyC+bnX+VWN1hGYBatWL1KyFcH/w4HRfpzBatMUBrB+gdHCpppv2wx6TtzJEc41TzO/1L74fkwb4c5J9Ik/dvKFCK3Q/1mesZZjI0CNc6CWWwFjr1ua0fKv1PDiBLNlkaAdefXqeYQqZ9iqDEkouTVoNaYXCaJCnMXt6ikVsqKvt3z7R+ub/0x2zA931XZg+USL9XtUBhh1HyVl/VoXLYNqUHOkhiVm+e0rGJ4EBL8g58F+JpRmo1mrTgRELQmfolsje6skaqpWiFHIBG2gGgm2BYos/MsYEOp5WCRNjOxq7udPyOaonDpdQ1OWhVB7dza9azhkyhqfrEPxL8V/gzDMZIa0qMp3unAK3TybwFswRWFU+YPXJAKSmQsoyUVBO/+qfQVriPRDOoTXM5H/5XL2RX+vxkTHk9UYLz3o8Lp7MJuxCaNZTUzUmhQZwnbe0614oTsW8v2vYRERq8kCP0CAwEAAaNTMFEwHQYDVR0OBBYEFM6jqUOFwUhNWDF6Y1UXjoW3ny0ZMB8GA1UdIwQYMBaAFM6jqUOFwUhNWDF6Y1UXjoW3ny0ZMA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggIBAIaI973GDa1Hlaskez4ynbEsPdan2oXtgFpnWPbSIaSEK0B67pkB15m/s3gTaCJA7b/Rqf+NIF1q7GSsXYxljTt6MMQAOweemQBVzj/p82ikD2odKAgYmfAKqA3tW3HnlPMMs/uKciIszlPuWuBtnMFDsSRUyNTSZ7OLTbOT/eg6YbBC5myMAwnDzgxHj9oIxOo6cPAiIJOLNf+cbMMJYl8rNxpqPlVb1qckma1y2mKtyejcEoJSkzHU4BCa2NtXVFlPjpPLCWtMLZVp6vnmqytmDSGyTvCvmFMvG6HohWTPf65hjK/Cgf6l9bBdyPUG0ptvfOJOc++Enot0XIx8YiM5Wf8sy/NN6zJOI7OLQJ60WykGNxbi/0uDecJhQtRFTzgXWjI2zlSUouPQDhM2+v9GauRilPWPwCr9gSQ6WGUULrMiP/ERjEDqBucyMcwR0r1u1GLhLZeCek878ZOIAs9XtMAH5P00l/d469qE6fVlDESW/S24isIN5gZMR2Hz4Sx/9ODlZfwhBN1AsxCMytibc/ygDCu60335ARLyMKCSf6/QXdransc5aCsF4iS7+9ioMqIPbqwaO8cP3S0reVfrFimsJAnckOmKSdwMj+QdnJveGEfoSIoz1szfbXbRPMDNuwm71HZyTwJHj3uuyoTg9JgC47tCQODtGGphoFBo",
                  ],
                  alg: "RS256",
              },
          ]
        : "";

const userManagerConfig: UserManagerSettings = {
    client_id: "reactApp",
    redirect_uri: redirectUri,
    response_type: "code",
    scope: "openid profile apiAuth",
    authority: authority,
    silent_redirect_uri: silentRedirectUri,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    signingKeys: signingKeys,
    post_logout_redirect_uri: frontEndUrl,
    includeIdTokenInSilentRenew: false,
    client_secret: "ReactLeanLinking",
};

const userManager = createUserManager(userManagerConfig);
userManager.startSilentRenew();

userManager.events.addAccessTokenExpiring(() => {
    console.log("token expiring. Trying to renew");
});

userManager.events.addUserSignedOut(() => {
    userManager
        .signoutRedirect()
        .then(() => {
            userManager.removeUser();
            console.log("User has logged out. Redirecting ...");
        })
        .catch(err => {
            console.log(err);
        });
});

userManager.events.addSilentRenewError(function(error) {
    console.error("error while renewing the access token", error);
});

export default userManager;
