import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IReviewDetail } from "models";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IReviewDetail;
};

const Review: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IReviewDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewReviewOnPartner", { id: data.id })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IReviewDetail) => {
        return (
            <Fragment>
                {t("activity_NewReviewByPartner", { creator: data.creator, id: data.id })}{" "}
                {data.reviewedPartnerName ? (
                    <PartnerLink
                        partnerId={data.partnerId}
                        partnerName={data.reviewedPartnerName}
                    />
                ) : (
                    <span>{t("noPartner")}</span>
                )}
            </Fragment>
        );
    };
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            <Link className={classes.notificationLink} to={`/Reviews/reviewId/${data.id}`}>
                <RightCircleFilled />
                {t("activity_ReviewView")}
            </Link>
        </span>
    );
};

export { Review };
