import { get, post, remove, put } from "../base";

const CompanyAuthenticationSettings = {
    getSSOSettings: async () => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting`;
        return await get({ url });
    },
    addSSODomain: async (domainName: string) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/DomainName`;
        await post({ url, payload: `"${domainName}"` });
    },
    deleteSSODomain: async (domainId: number) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/DomainName/${domainId}`;
        await remove({ url });
    },
    updateSSODomain: async (domainId: number, newName: string) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/DomainName/${domainId}`;
        await put({ url, payload: `"${newName}"` });
    },
    setIsInternalLoginAllowed: async (isAllowed: boolean) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/InternalLoginAllowed/${isAllowed}`;
        await put({ url, payload: {} });
    },
    setSsoEnabled: async (isEnabled: boolean) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/SSOEnalbed/${isEnabled}`;
        await put({ url, payload: {} });
    },
    checkExistingDomain: async (domainName: string) => {
        const url = `/v1/CompanyAuthenticationSettings/AuthenticationSetting/DomainNameExists/${domainName}`;
        return await post({ url, payload: {} });
    },
};

export default CompanyAuthenticationSettings;
