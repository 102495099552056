import React, { FunctionComponent, Fragment } from "react";
import ContactsOverview from "components/contentcomponents/contacts/overview/overview";

export const ContactsOverviewPage: FunctionComponent = () => {
    return (
        <Fragment>
            <ContactsOverview />
        </Fragment>
    );
};
export default ContactsOverviewPage;
