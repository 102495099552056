import { get } from "../base";

const Company = {
    getGlobalSettings: async ({ customError }: any = {}) => {
        const url = `/v1/Company/GlobalSettings`;
        return await get({ url, customError });
    },

    getCompanyById: async (
        companyId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Company/${companyId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
};

export default Company;
