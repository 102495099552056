import { IRelationsUserDetail } from "models/user";
import { get, post, put } from "../base";

const UserAccount = {
    registerUser: async (userData: any, invitationToken: string, { customError }: any = {}) => {
        const url = `/v1/UserAccount/RegisterUser?token=${invitationToken}`;
        return await post({ url, payload: userData, customError });
    },
    linkPartnerByToken: async (token: string, { customError }: any = {}) => {
        const url = `/v1/UserAccount/LinkExistingUserPartner?token=${token}`;
        return await post({ url, customError });
    },
    isUsernameDuplicate: async (
        userName: string,
        invitationToken: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/UserAccount/IsUsernameDuplicate?username=${encodeURIComponent(
            userName
        )}&token=${invitationToken}`;
        return await post({ url, payload: {}, customError });
    },
    getInvitationRegistrationType: async (invitationToken: string, { customError }: any = {}) => {
        const url = `/v1/UserAccount/GetInvitationTypeForRegister?token=${invitationToken}`;
        return await post({ url, payload: {}, customError });
    },
    checkPassword: async (username: string, password: string, { customError }: any = {}) => {
        const url = `/v1/UserAccount/IsPasswordWeak`;
        return await post({ url, payload: { username, password }, customError });
    },
    addRelationsUserDetail: async (payload: IRelationsUserDetail, { customError }: any) => {
        const url = `/v1/UserAccount/AddRelationsUserDetail`;
        return await post({ url, payload: payload, customError });
    },
    getNotificationSettings: async ({ customError }: any = {}) => {
        let url = "/v1/UserAccount/NotificationsSettings";
        return await get({ url, customError });
    },
    putNotificationSetting: async (
        moduleId: number,
        receiveEmail: number,
        { customError }: any = {}
    ) => {
        let url = `/v1/UserAccount/NotificationsSetting?moduleId=${moduleId}&receiveEmail=${receiveEmail}`;
        return await put({ url, payload: {}, customError });
    },
};

export default UserAccount;
