import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Col, Input, notification, Form } from "antd";
import { FormRow } from "components/utilitycomponents";
import { ISummaryCardsMeeting } from "models";
import { useSupplierCardsPartnerUpdate } from "controller";
const TextArea = Input.TextArea;
interface IProps {
    partnerId: number;
    data: ISummaryCardsMeeting;
    visible: boolean;
    onSave: Function;
    onClose: () => void;
}

const EditModal = ({ partnerId, data, visible, onSave, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, updateMeeting } = useSupplierCardsPartnerUpdate();

    useEffect(() => {
        form.resetFields();
    }, [data, partnerId]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        for (const property in fieldsValue) {
            fieldsValue[property] = fieldsValue[property] === null ? "" : fieldsValue[property];
        }
        await updateMeeting({
            partnerId,
            meetingTypeId: data.meetingTypeId,
            ...fieldsValue,
        });
        notification.success({
            message: "Success",
            description: t("supplierProfile_UpdateMeetingSuccess", {
                title: data.meetingType,
            }),
        });
        onSave({
            meetingTypeId: data.meetingTypeId,
            ...fieldsValue,
        });
        onClose();
    };

    return (
        <Modal
            wrapProps={{ "data-test": "supplier_profile_meeting_edit_modal" }}
            style={{ top: 20 }}
            title={data.meetingType}
            onCancel={onClose}
            footer={[
                <Button
                    data-test="meeting_modal_save_button"
                    key="ok"
                    type="primary"
                    onClick={handleSave}
                    loading={loading}>
                    {t("Save")}
                </Button>,
            ]}
            open={visible}>
            <Form form={form}>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_MeetingFrequency")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="frequency" initialValue={data.frequency}>
                            <Input data-test="meeting_frequency_input" placeholder="Frequency" />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_MeetingAttendees")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="attendees" initialValue={data.attendees}>
                            <TextArea
                                data-test="meeting_attendees_textarea"
                                placeholder="Attendees"
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default EditModal;
