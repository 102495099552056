import { IProductUpsert, ITableInput } from "models";
import { get, patch, post, put, remove } from "../base";

const Product = {
    getProductsByPartnerId: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Product/${partnerId}`;
        return await get({ url, customError });
    },
    getProductOverview: async (
        tableInput: ITableInput,
        partnerId: number | undefined,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/Product/Overview`;
        if (partnerId !== undefined) {
            url = `/v1/Product/Overview?supplierId=${partnerId}`;
        }
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },
    getProductById: async (id: number, partnerId?: number, { customError }: any = {}) => {
        let url = `/v1/Product/id?id=${id}`;
        if (partnerId) {
            url += `&partnerId=${partnerId}`;
        }
        return await get({ url, customError });
    },
    getProductStatus: async ({ customError }: any = {}) => {
        const url = `/v1/Product/ProductStatus`;
        return await get({ url, customError });
    },
    upsertProduct: async (input: IProductUpsert, { customError }: any = {}) => {
        const url = "/v1/Product";
        return await put({ url, payload: input, customError });
    },
    deleteProduct: async (id: number, { customError }: any = {}) => {
        const url = `/v1/Product/${id}`;
        return await remove({ url, customError });
    },
    isProductInUse: async (productId: string, { customError }: any = {}) => {
        const url = `/v1/Product/IsProductIdInUse?productId=${encodeURIComponent(productId)}`;
        return await get({ url, customError });
    },
    hasProductDocumentation: async (internalProductId: number, { customError }: any = {}) => {
        const url = `/v1/Product/HasDocumentation?productId=${internalProductId}`;
        return await get({ url, customError });
    },
    moveDocumentation: async (
        currentProductId: number,
        newProductId: number,
        newProductStatusId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Product/MoveDocuments?currentProductId=${currentProductId}&newProductId=${newProductId}&newProductStatusId=${newProductStatusId}`;
        return await patch({ url, customError });
    },
    getProducts: async ({ customError }: any = {}) => {
        const url = "/v1/Product/GetProductsList";
        return await get({ url, customError });
    },
};

export { Product };
