import React, { FunctionComponent, Fragment } from "react";
import { IActivityChild } from "models";
import { useTranslation } from "react-i18next";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IActivityChild;
};

const ProjectApproved: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IActivityChild) => {
        return <Fragment>{data.creator_FullName}</Fragment>;
    };
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
                {data.approvedValue ? (
                    <Fragment>
                        {" "}
                        {t("activity_ProjectApproved", { approvedValue: data.approvedValue })}{" "}
                        {data.approvedCurrency}
                    </Fragment>
                ) : null}
            </div>
        </Fragment>
    );
};

export default ProjectApproved;
