import { useEffect, useState } from "react";
import Provider from "services/api";

type UseTemplatesType = {
    data: any[];
    loading: boolean;
};

const useSegmentationAlignmentChangelog = (alignmentId: any) => {
    const [state, setState] = useState<UseTemplatesType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (alignmentId) {
                setState({ ...state, loading: true });
                const data = await Provider.Segmentation.getAlignmentChangelog(alignmentId, {
                    customError: {
                        message: "Could not retrieve changelog",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [alignmentId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSegmentationAlignmentChangelog };
