import React from "react";
import { Button } from "antd";
import { Trans } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";

const getOperationColumn = ({
    editingKey,
    setEditingKey,
    onSaveRow,
    onEditRow,
    updateRow,
    updateRowLoading,
    editText,
    saveText,
}: any) => {
    return (_: any, record: any) => {
        const editable = record.id === editingKey;
        return editable ? (
            <>
                <Button
                    loading={updateRowLoading}
                    type="primary"
                    onClick={async (e: any) => {
                        e.stopPropagation();
                        if (onSaveRow) {
                            const newRecord = await onSaveRow(record);
                            updateRow && updateRow(newRecord);
                        }
                        setEditingKey(undefined);
                    }}>
                    {saveText ? saveText : <Trans i18nKey="generic_Save" />}
                </Button>
                <Button
                    style={{ margin: "0 5px" }}
                    onClick={e => {
                        e.stopPropagation();
                        setEditingKey(undefined);
                    }}>
                    <Trans i18nKey="generic_Cancel" />
                </Button>
            </>
        ) : (
            <Button
                type="link"
                disabled={editingKey !== undefined}
                onClick={(e: any) => {
                    e.stopPropagation();
                    onEditRow && onEditRow(record);
                    setEditingKey(record.id);
                }}>
                <EditOutlined />
            </Button>
        );
    };
};
export { getOperationColumn };
