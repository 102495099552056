import { useState } from "react";
import { Provider } from "services";

const useDevelopmentPlanTemplateById = () => {
    const [loading, setLoading] = useState(false);

    const getTemplateById = async (templateId: number) => {
        if (templateId !== -1) {
            setLoading(true);
            const template = await Provider.DevelopmentPlan.getDevelopmentPlanTemplateById(
                templateId,
                {
                    customError: {
                        message: "Could not retrieve template",
                    },
                }
            ).catch(err => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
            return template;
        }
    };

    return {
        loading: loading,
        getTemplateById,
    };
};

export { useDevelopmentPlanTemplateById };
