import { useState } from "react";
import { Provider } from "services";

const useCustomDropdownsInUse = () => {
    const [loading, setLoading] = useState(false);

    const areCustomDropdownsInUse = async (dropdownIds: number[]) => {
        setLoading(true);
        const result = await Provider.Company.isCustomDropdownsInUse(dropdownIds, {
            customError: {
                message: "Could not check if custom dropdowns are in use",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading,
        areCustomDropdownsInUse,
    };
};

export { useCustomDropdownsInUse };
