import React, { FunctionComponent, useState, useEffect } from "react";
import defaultImage from "./defaultImage.png";
import { useFileDownload } from "controller";
import classes from "./styles/userImage.module.scss";
import { Avatar, Spin } from "antd";
import { useAvatar } from "controller/contact/contactOverviews/useAvatart";
import { getColorFromInitials } from "utilities/helpers/getColorFromInitials";
import useEmit from "utilities/helpers/useEmit";

type PropsType = {
    fileId?: any | undefined;
    alt: string;
    customDimension?: number;
    [key: string]: any;
    contactId?: any;
    fontSize?: number;
};

const UserImage: FunctionComponent<PropsType> = ({
    alt,
    fileId,
    customDimension,
    contactId,
    fontSize = 22,
    ...rest
}) => {
    const { profileImageLoading, downloadProfileImage } = useFileDownload();
    const [downloadUrl, setDownloadUrl] = useState<any>();
    const { data, loading, refresh } = useAvatar(contactId);
    const [backgroundColor, setBackgroundColor] = useState<string>("");
    const { newEmit } = useEmit();
    useEffect(() => {
        const color = getColorFromInitials(data?.initials);
        setBackgroundColor(color);
    }, [data?.initials]);

    const setProfileImageHandler = async () => {
        if (fileId && fileId !== "-1") {
            const data = await downloadProfileImage(fileId).catch(err => {
                setDownloadUrl(defaultImage);

                return Promise.reject(err);
            });

            setDownloadUrl(window.URL.createObjectURL(new Blob([data])));
        } else if (!fileId && data?.hasFile && data?.profilePictureData !== null) {
            setDownloadUrl(`data:image/png;base64,${data?.profilePictureData}`);
        } else {
            setDownloadUrl(defaultImage);
        }
    };
    useEffect(() => {
        setProfileImageHandler();
    }, [fileId, contactId, data]);

    useEffect(() => {
        newEmit("refresh_Avatar", () => {
            refresh();
            setProfileImageHandler();
        });
    }, [newEmit]);

    return (
        <Spin spinning={profileImageLoading || loading} size="small">
            <div
                className={classes.imageCropper}
                style={
                    customDimension
                        ? { width: `${customDimension}px`, height: `${customDimension}px` }
                        : {}
                }>
                {data?.hasFile ? (
                    <img className={classes.profilePic} {...rest} src={downloadUrl} alt={alt} />
                ) : (
                    <Avatar
                        style={{
                            backgroundColor: `${backgroundColor}`,
                            verticalAlign: "middle",
                            width: `${customDimension}px`,
                            height: `${customDimension}px`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: fontSize,
                        }}>
                        {contactId !== null ? data?.initials : "Guest"}
                    </Avatar>
                )}
            </div>
        </Spin>
    );
};

export { UserImage };
