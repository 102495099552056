import React, { FunctionComponent } from "react";
import { MinimalButton } from "components/utilitycomponents";
import { EditOutlined } from "@ant-design/icons";
type PropsType = {
    onClick: Function;
};
const EditButton: FunctionComponent<PropsType> = ({ onClick, children }) => {
    return (
        <MinimalButton
            data-test={`edit_button`}
            icon={<EditOutlined />}
            iconColor="#0477a9"
            type="default"
            onClick={onClick}
            style={{ color: "#0477a9", padding: 0 }}>
            {children}
        </MinimalButton>
    );
};

export default EditButton;
