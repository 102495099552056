import { useState } from "react";
import { Provider } from "services";

const useSupplierAccessSettingUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (settingId: number) => {
        setLoading(true);
        await Provider.Company.updateSupplierAccessSetting(settingId, {
            customError: {
                message: "Could not update setting",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        update,
    };
};

export { useSupplierAccessSettingUpdate };
