import React, { FunctionComponent } from "react";
import { InputNumber, Input, Form } from "antd";

type PropsType = {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    rules: any[];
    record: any;
    children: React.ReactNode;
    [key: string]: any;
};

const EditableCell: FunctionComponent<PropsType> = ({
    editing,
    dataIndex,
    title,
    inputType,
    rules,
    record,
    children,
    ...rest
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input maxLength={500} />;
    const style = rest.style ? rest.style : {};
    return (
        <td
            {...rest}
            style={dataIndex === "grid_operation" ? { cursor: "auto", ...style } : style}
            onClick={e => (editing || dataIndex === "grid_operation") && e.stopPropagation()}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export { EditableCell };
