import { useState } from "react";
import { Provider } from "services";

const useUserAccessSettingUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (payload: any) => {
        setLoading(true);
        const id = await Provider.User.updateUserAccessSetting(payload, {
            customError: {
                message: "Could not create update access group",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        update,
    };
};

export { useUserAccessSettingUpdate };
