import React from "react";
import RadioDropDown from "./radioDropDown";
import getFilterIcon from "./getFilterIcon";

const getColumnRadioProps = (
    useData: Function,
    dataFields = { value: ["id"], text: ["name"] },
    params: any = { dataIndex: Math.floor(Math.random() * 100) }
) => {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <RadioDropDown
                useData={useData}
                dataFields={dataFields}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={params.dataIndex}
            />
        ),
        ...getFilterIcon(params),
    };
};

export { getColumnRadioProps };
