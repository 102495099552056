import React from "react";
import classes from "./scorecard.module.scss";
import { scoreColor } from "./scorecardGradient";

interface IProps {
    data: any[];
    modalFormat?: boolean;
}

const AreaStatusComponent = ({ data = [], modalFormat = false }: IProps) => {
    return (
        <div
            className={`${classes.areaStatusBoxContainer} ${
                modalFormat ? classes.areaStatusModalContainer : ""
            }`}>
            {data.map((box: any) => (
                <div
                    key={box.id}
                    style={{
                        background: box.score !== null ? scoreColor(box.score) : "#808080",
                    }}
                    className={
                        !modalFormat ? classes.areaStatusBox : classes.interactiveAreaStatusBox
                    }>
                    {box.title}
                </div>
            ))}
        </div>
    );
};

export default AreaStatusComponent;
