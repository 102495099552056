import React, { useEffect, useState } from "react";
import CreateForm from "./createForm/createForm";
import { useTemplateForCompliance } from "controller";
import { LoadingIndicator } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Modal, Row } from "antd";

interface IProps {
    templateId?: number;
    partnerId?: number;
    productId?: number;
    open: boolean;
    onClose: Function;
}

const CreateModal = ({ templateId, partnerId, productId, open, onClose }: IProps) => {
    const { template: queryParamTemplateId }: any = useParams();
    const [activeTemplate, setActiveTemplate] = useState<number | null | undefined>(
        queryParamTemplateId !== undefined ? Number(queryParamTemplateId) : undefined
    );
    const { loading, data } = useTemplateForCompliance(activeTemplate);
    const { t } = useTranslation();

    useEffect(() => {
        setActiveTemplate(templateId);
    }, [templateId, partnerId]);

    return (
        <Modal
            title={t("createRegistration")}
            open={open}
            onCancel={() => onClose()}
            width={1200}
            footer={null}>
            <Row>
                <Col span={24}>
                    {activeTemplate && loading && <LoadingIndicator />}
                    {activeTemplate && data !== null && (
                        <CreateForm
                            data={data}
                            lightUserSupplierCreation={true}
                            defaultSelectedPartner={partnerId}
                            onSubmitFinished={(reload: boolean) => onClose(reload)}
                            parentComponentType="modal"
                            defaultSelectedProducts={productId ? [productId] : undefined}
                            enableSupplierCreation={false}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default CreateModal;
