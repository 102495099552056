import React, { FunctionComponent, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ISummaryCardsMeeting } from "models";
import EditModal from "./editModal";
import EditButton from "../../editButton";

type PropsType = {
    partnerId: number;
    data: ISummaryCardsMeeting[];
};

const Meeting: FunctionComponent<PropsType> = ({ partnerId, data }) => {
    const { t } = useTranslation();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [meetings, setMeetings] = useState(data);
    const [meeting, setMeeting] = useState<ISummaryCardsMeeting | null>();

    const handleSave = ({ meetingTypeId, ...rest }: any) => {
        setMeetings(
            meetings.map((m: ISummaryCardsMeeting) => {
                if (m.meetingTypeId === meetingTypeId) {
                    return {
                        ...m,
                        ...rest,
                    };
                }
                return m;
            })
        );
    };

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setMeeting(record);
            setEditModalVisible(true);
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <Fragment>
            <Table
                data-test="supplier_profile_meeting_types_table"
                pagination={false}
                bordered
                scroll={{ x: 640 }}
                onRow={handleOnRow}
                rowKey="meetingTypeId"
                columns={[
                    {
                        title: t("supplierProfile_MeetingType"),
                        dataIndex: "meetingType",
                        fixed: true,
                        width: 220,
                        render: (text: string) => (
                            <div style={{ display: "table", width: "100%" }}>
                                <div style={{ display: "table-cell" }}>{text}</div>
                                <div style={{ display: "table-cell", textAlign: "right" }}>
                                    <EditButton onClick={() => setEditModalVisible(true)} />
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: t("supplierProfile_MeetingFrequency"),
                        dataIndex: "frequency",
                    },
                    {
                        title: t("supplierProfile_MeetingAttendees"),
                        dataIndex: "attendees",
                    },
                ]}
                dataSource={meetings.map((m: ISummaryCardsMeeting) => ({
                    meetingTypeId: m.meetingTypeId,
                    meetingType: m.meetingType,
                    frequency: m.frequency,
                    attendees: m.attendees,
                }))}
            />
            {meeting && (
                <EditModal
                    visible={editModalVisible}
                    partnerId={partnerId}
                    data={meeting}
                    onSave={handleSave}
                    onClose={() => {
                        setEditModalVisible(false);
                        setMeeting(null);
                    }}
                />
            )}
        </Fragment>
    );
};

export default Meeting;
