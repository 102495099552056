import React, { FunctionComponent, useEffect, useRef } from "react";
import { useRegistrationScore } from "controller";
import { LoadingIndicator, getPercentTagColor } from "components/utilitycomponents";
import { drawProgressBar } from "./drawProgressBar";
import { useTranslation } from "react-i18next";

type PropsType = {
    type: string;
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

const ScoreProgressBar: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();
    const canvasRef = useRef(null);
    const { loading, data } = useRegistrationScore(partnerId, range.from, range.to);

    useEffect(() => {
        if (data !== "") {
            const canvas = canvasRef.current;
            drawProgressBar(canvas, getPercentTagColor(data), data);
        }
    }, [data]);

    if (loading) return <LoadingIndicator />;
    if (data === "") return <span>{t("supplierProfile_NoScore", "No Score")}</span>;
    return <canvas style={{ width: "100%" }} height="35px" ref={canvasRef}></canvas>;
};

export default ScoreProgressBar;
