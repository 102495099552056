import React from "react";
import { useHistory } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";
import { User } from "oidc-client";
import userManager from "userManager";
import { Spin } from "antd";
import { getEnvironmentInfo } from "utilities";
import { Environment } from "models/Environment";

const Callback = () => {
    const history = useHistory();
    const pendoInitialize = (user: User) => {
        // @ts-ignore
        if (getEnvironmentInfo().type !== Environment.Local && !window.Cypress) {
            //@ts-ignore
            window.pendo.initialize({
                visitor: {
                    // Required if user is logged in
                    id: `${getEnvironmentInfo().type === Environment.Staging ? "staging-" : ""}${
                        user.profile["ll-UserName"]
                    }`,
                    // Recommended if using Pendo Feedback, or NPS Email
                    email: user.profile["ll-UserEmail"],
                    // Recommended if using Pendo Feedback
                    full_name: user.profile["ll-Fullname"],
                    // Optional
                    role: user.profile["ll-Usertype"],
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                    user_id: user.profile["ll-UserId"],
                    user_locale: user.profile["ll-Locale"],
                    user_jobTitle: user.profile["ll-JobTitle"],
                    user_department: user.profile["ll-Department"],
                },
                account: {
                    // Required if using Pendo Feedback
                    id: `${getEnvironmentInfo().type === Environment.Staging ? "staging-" : ""}${
                        user.profile["ll-CompanyName"]
                    }`,
                    // Optional
                    name: user.profile["ll-CompanyName"],
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                    company_id: user.profile["ll-CompanyId"],
                    company_type: user.profile["ll-companyType"],
                },
            });
        }
    };
    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={(user: User) => {
                pendoInitialize(user);
                var redirectPath = user.state.path as string;
                history.push(redirectPath);
            }}
            errorCallback={(error: any) => {
                console.log(error);
                history.push("/");
            }}>
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        </CallbackComponent>
    );
};

export default Callback;
