import { post, get, remove, put } from "../base";
import { ISegmentationAlignmentInfo, ITableInput, SurveyType } from "models";
import { ICreateSurvey } from "models";

const Survey = {
    getOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/Overview`;
        let response = await post({
            url,
            payload: tableInput,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyReviewCountGraphs: async (
        surveyId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetSurveyReviewCategoriesGraph?surveyId=${surveyId}`;
        let response = await get({
            url,
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        });
        return response;
    },
    createSurvey: async (survey: ICreateSurvey, { customError }: any = {}) => {
        const url = `/v1/Survey/Create`;
        let response = await post({
            url,
            payload: survey,
            customError,
        });
        return response;
    },
    deleteSurveys: async (
        surveyIds: string[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/DeleteSurveys`;
        await remove({
            url,
            payload: surveyIds,
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        });
    },
    getNonResponseOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetNonResponse`;
        let response = await post({
            url,
            payload: tableInput,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyTitles: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetAllSurveysTitle`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyTitlesForReviewOverview: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Review/GetAllSurveysTitleForReviewOverview`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyTitlesForNonResponseOverview: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetAllNonResponseSurveysTitle`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveySubmissionAggregate: async (
        surveyToken: string,
        contactId: string | undefined,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/Survey/SurveyAggregate?surveyToken=${surveyToken}`;
        if (contactId !== undefined) {
            url = url + `&contactId=${contactId}`;
        }
        return await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
    },
    submitSurveyAnswers: async (
        answers: any,
        surveyToken: string,
        contactid?: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = ``;
        if (contactid) {
            url = `/v1/Survey/SurveyAggregate?surveyToken=${surveyToken}&contactId=${contactid}`;
        } else {
            url = `/v1/Survey/SurveyAggregate?surveyToken=${surveyToken}`;
        }
        let response = await post({
            url,
            payload: answers,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyInfoForCopy: async (
        surveyId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetSurveyDetailForCopyById/${surveyId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getSurveyTemplates: async ({ customError }: any = {}) => {
        const url = `/v1/Survey/GetAllSurveyTemplates`;
        return await get({ url, customError });
    },
    getSurveyTemplatesByType: async (type: number, { customError }: any = {}) => {
        const url = `/v1/Survey/GetSurveyTemplatesByType/${type}`;
        return await get({ url, customError });
    },
    getSurveyResultsBySurveyId: async (
        surveyId: number,
        tableInput: ITableInput,
        partnerId?: any,
        { customError }: any = {}
    ) => {
        let url = `/v1/Survey/GetSurveyReviewsBySurveyId?surveyId=${surveyId}`;
        if (partnerId !== undefined) {
            url = url + `&partnerId=${partnerId}`;
        }
        return await post({
            url,
            payload: tableInput,
            customError,
        });
    },
    getSurveyResultsBySegmentationAnalysisId: async (
        segmentationAnalysisId: number,
        tableInput: ITableInput,
        partnerId: any,
        { customError }: any = {}
    ) => {
        let url = `/v1/Survey/GetSurveyReviewsBySegmentationAnalysisId?segmentationAnalysisId=${segmentationAnalysisId}`;
        if (partnerId !== undefined) {
            url = url + `&partnerId=${partnerId}`;
        }
        return await post({
            url,
            payload: tableInput,
            customError,
        });
    },
    getReviewCriteriaBySurveyId: async (
        surveyId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Survey/GetReviewCriteriasBySurveyId?surveyId=${surveyId}`;
        let response = await get({
            url,
            retryCount: 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    getReviewCriteriaBySegmentationAnalysisId: async (
        segmentationAnalysisId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetReviewCriteriasBySegmentationAnalysisId?segmentationAnalysisId=${segmentationAnalysisId}`;
        let response = await get({
            url,
            customError,
        });
        return response;
    },
    getUserReviewDetailsBySurveyId: async (
        surveyId: number,
        contactId: number,
        partnerId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetReviewDetailByContactAndSurveyId/${surveyId}/${contactId}/${partnerId}`;
        return await post({
            url,
            customError,
        });
    },
    getUserReviewDetailsBySegmentationAnalysisId: async (
        segmentationAnalysisId: number,
        contactId: number,
        partnerId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetReviewDetailByContactAndSegmentationAnalysisId/${segmentationAnalysisId}/${contactId}/${partnerId}`;
        return await post({
            url,
            customError,
        });
    },
    getSegmentationUnitOfAnalysisOverview: async (
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetSegmentationAnalysisUnitOverview`;
        let response = await post({
            url,
            payload: tableInput,
            customError,
        });
        return response;
    },
    AlignSegments: async (segmentInfo: ISegmentationAlignmentInfo, { customError }: any = {}) => {
        const url = `/v1/Survey/AlignSegmentation`;
        await put({ url, payload: segmentInfo, customError });
    },
    GetParentAlignmentInfo: async (parentSupplierId: number, { customError }: any = {}) => {
        const url = `/v1/Survey/AlignmentInfo/${parentSupplierId}`;
        return await get({ url });
    },
    getDivisions: async ({ customError }: any = {}) => {
        const url = `/v1/Survey/GetAllDivisions`;
        return await get({ url, customError });
    },
    getRegions: async ({ customError }: any = {}) => {
        const url = `/v1/Survey/GetAllRegions`;
        return await get({ url, customError });
    },
    getRegionsByPartner: async (
        partnerIds: number[],
        type: SurveyType,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/SegmentationFilter/GetRegionFromPartner?surveyTypes=${type}`;
        return await post({ url, payload: partnerIds, customError });
    },
    getDivisionsByPartner: async (
        partnerIds: number[],
        type: SurveyType,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/SegmentationFilter/GetDivisionFromPartner?surveyTypes=${type}`;
        return await post({ url, payload: partnerIds, customError });
    },
    resendSurvey: async (surveyId: number, { customError }: any = {}) => {
        const url = `/v1/Survey/SendRemindersBySurveyId?surveyId=${surveyId}`;
        return await post({ url, payload: {}, customError });
    },
    getSurveyCriteriaResponse: async (
        surveyId: number,
        criteriaId: number,
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetSurveyAnswersByReviewCriteriaId?surveyId=${surveyId}&reviewCriteriaId=${criteriaId}`;
        return await post({
            url,
            payload: tableInput,
            customError,
        });
    },
    getSegmentationCriteriaResponse: async (
        segmentationAnalysisId: number,
        criteriaId: number,
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/GetSurveyAnswersBySegmentationAnalysisId?segmentationAnalysisId=${segmentationAnalysisId}&reviewCriteriaId=${criteriaId}`;
        return await post({
            url,
            payload: tableInput,
            customError,
        });
    },
    getSurveysRelatedToSegmentationUnit: async (segmentationAnalysisId: number) => {
        const url = `/v1/Survey/GetSurveysBySegmentationAnalysisId?segmentationAnalysisId=${segmentationAnalysisId}`;
        return await get({ url });
    },
};

export default Survey;
