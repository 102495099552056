import React, { forwardRef } from "react";
import { Select } from "antd";
import { IComplianceCustomDropDown } from "models";
import { useTranslation } from "react-i18next";
import classes from "./styles/customDrop.module.scss";
const Option = Select.Option;

type PropsType = {
    onChange?: (value: string) => void;
    value?: string;
    options: IComplianceCustomDropDown[];
};

const CustomDropDown = forwardRef(({ onChange, value = "", options }: PropsType, ref) => {
    const { t } = useTranslation();
    return (
        <Select
            value={value}
            onChange={(value: string) => onChange && onChange(value)}
            getPopupContainer={node => (node ? (node.parentNode as HTMLElement) : document.body)}
            style={{ minWidth: "100%", maxWidth: "100%" }}>
            <Option value="" key="-1">
                {t("compliance_Blank")}
            </Option>
            {options.map((option, index) => (
                <Option key={index} value={option.text} className={classes.selectOption}>
                    {option.text}
                </Option>
            ))}
        </Select>
    );
});

export { CustomDropDown };
