import React, { FunctionComponent } from "react";
import { Row, Col } from "antd";
import { AlternateSupplier } from "./alternateSupplier/alternateSupplier";
import RiskTable from "./riskTable/riskTable";
import RiskGuid from "./riskGuid";
import { PageContainer } from "components/utilitycomponents";

type PropsType = {
    partnerId: number;
    partnerName: string;
    reloadPartners: any;
};

const Risk: FunctionComponent<PropsType> = ({ partnerId, partnerName, reloadPartners }) => {
    return (
        <PageContainer fluid style={{ marginLeft: 10, marginRight: 10, minHeight: 500 }}>
            <Row gutter={16}>
                <Col lg={14} xs={24}>
                    <RiskTable partnerId={partnerId} />
                </Col>

                <Col lg={10} xs={24}>
                    <RiskGuid />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} style={{ margin: "10px 0" }}>
                    <AlternateSupplier
                        partnerId={partnerId}
                        partnerName={partnerName}
                        reloadPartners={reloadPartners}
                    />
                </Col>
            </Row>
        </PageContainer>
    );
};

export default Risk;
