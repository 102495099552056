import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "components/alwaysOnComponents/errors/404";
import { AccountProfile } from "components/contentcomponents/user/accountProfile/profile";
import { AppState } from "store";
import ComplianceOverview from "components/contentcomponents/compliance/overview/overview";
import SelfAssessment from "components/contentcomponents/compliance/selfAssessment/selfAssessment";
import NCR from "components/mainContainers/ncr/ncr";
import Dashboard from "components/mainContainers/analytics/analyticsArea";
import ContactsOverviewPage from "components/mainContainers/contacts/contactsOverviewPage";
import ContactsGroupOverviewPage from "components/mainContainers/contacts/contactsGroupOverviewPage";
import ActionsOverviewPage from "components/mainContainers/action/actionOverviewPage";
import { Spin } from "antd";
import GuestRoutes from "./guestRoutes";
import developmentPlanOverviewPage from "components/mainContainers/developmentPlan/developmentPlanOverviewPage";
import SupplierLandingPage from "components/mainContainers/supplierLandingPage/supplierLandingPage";
import { SurveyRequests } from "components/contentcomponents/supplierPortal/surveyRequests";
import { SelfAssessmentRequests } from "components/contentcomponents/supplierPortal/selfAssessmentRequests";
import FilesOverviewPage from "components/mainContainers/files/filesOverviewPage";
import ReviewOverview from "components/mainContainers/reviews/reviews";
import { useFilesFeature } from "controller";

const AdminRoutes = lazy(() => import("./adminRoutes"));

const SupplierRoutes = () => {
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const { hasFilesFeature } = useFilesFeature();
    const userType: string = user.profile["ll-Usertype"];
    const hasAdminAccess = user.profile["ll-Admin"] === "true" ? true : false;
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const hasNcrAccess = user.profile["ll-Ncr"] === "true" ? true : false;
    const hasContactAccess = user.profile["ll-Contact"] === "true" ? true : false;
    const hasKpiAccess = user.profile["ll-Kpi"] === "true" ? true : false;
    const companyActionAccess = user.profile["ll-enabledCompanyModule-Action"] === "true";
    const companyComplianceAccess = user.profile["ll-enabledCompanyModule-Compliance"] === "true";
    const companyContactAccess = user.profile["ll-enabledCompanyModule-Contact"] === "true";
    const companyNcrAccess = user.profile["ll-enabledCompanyModule-Ncr"] === "true";
    const companyReviewAccess = user.profile["ll-enabledCompanyModule-Review"] === "true";
    const companyFileAccess = user.profile["ll-enabledCompanyModule-File"] === "true";

    if (location.hash.startsWith("#/")) {
        history.replace(location.hash.replace("#", "")); // or history.replace
    }
    return (
        <Suspense
            fallback={
                <div className="callbackSpinner">
                    <Spin size="large" />
                </div>
            }>
            <Switch>
                <Route exact path="/AcceptInvitation/:token" component={SupplierLandingPage} />
                <Route
                    exact
                    path="/Registration/:token"
                    render={props => (
                        <Redirect to={`/AcceptInvitation/${props.match.params.token}`} />
                    )}
                />
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") && (
                    <Route exact path="/" component={SupplierLandingPage} />
                )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") && (
                    <Route path="/Home" component={SupplierLandingPage} />
                )}

                {(userType === "user" || userType === "administrator") && hasKpiAccess && (
                    <Route
                        path="/partnerAnalytics/:id(\d+)?/:presetId(\d+)?/:companyId(\d+)?"
                        render={() => <Dashboard sharedDashboardView={true} />}
                    />
                )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance" component={ComplianceOverview} />
                    )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance/:id(\d+)?" component={ComplianceOverview} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/partnerId/:partnerId/from/:from/to/:to"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/companyNumber/:companyNumber"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/SelfAssessmentRequests"
                            component={SelfAssessmentRequests}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/SelfAssessment/:guestToken"
                            component={SelfAssessment}
                        />
                    )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrAccess &&
                    companyNcrAccess && (
                        <Route
                            path="/Ncrs/partnerId/:partnerId/from/:from/to/:to"
                            component={NCR}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route exact path="/Ncrs" component={NCR} />}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route exact path="/Ncrs/:id(\d+)?" component={NCR} />}
                {(userType === "user" || userType === "administrator") &&
                    hasContactAccess &&
                    companyContactAccess && (
                        <Route
                            exact
                            path="/Contacts/ContactGroup"
                            component={ContactsGroupOverviewPage}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasContactAccess &&
                    companyContactAccess && (
                        <Route exact path="/Contacts" component={ContactsOverviewPage} />
                    )}

                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route exact path="/Actions" component={ActionsOverviewPage} />
                    )}
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route path="/Actions/:actionId" component={ActionsOverviewPage} />
                    )}

                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route
                            path="/DevelopmentPlans/:planId(\d+)?"
                            component={developmentPlanOverviewPage}
                        />
                    )}
                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    companyFileAccess && (
                        <Route exact path="/Files" component={FilesOverviewPage} />
                    )}

                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    companyFileAccess && (
                        <Route
                            exact
                            path="/Files/folderId/:folderId(\d+)?"
                            component={FilesOverviewPage}
                        />
                    )}
                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    companyFileAccess && (
                        <Route
                            exact
                            path="/Files/fileId/:fileId(\d+)?"
                            component={FilesOverviewPage}
                        />
                    )}
                <Route path="/analytics/Dashboard/:presetId(\d+)" component={Dashboard} />
                <Route exact path="/User" component={AccountProfile} />
                {companyReviewAccess && <Route path="/SurveyRequests" component={SurveyRequests} />}
                {companyReviewAccess && <Route exact path="/Reviews" component={ReviewOverview} />}

                {companyReviewAccess && (
                    <Route exact path="/Reviews/reviewId/:id" component={ReviewOverview} />
                )}
                {companyReviewAccess && (
                    <Route
                        exact
                        path="/Reviews/partnerId/:partnerId/from/:from/to/:to"
                        component={ReviewOverview}
                    />
                )}

                {userType === "administrator" && hasAdminAccess && (
                    <Route path="/Admin" component={AdminRoutes} />
                )}

                <GuestRoutes />

                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default SupplierRoutes;
