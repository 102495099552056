import { Modal, Form, Button, Divider } from "antd";
import { SelectDataMultiple } from "components/utilitycomponents";
import {
    useCompanyContactsWithAccess,
    useExternalContacts,
    useMeetingMinute,
    useShareMeetingMinute,
} from "controller";
import { Modules } from "models";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./meetingCalendar.module.scss";
import { CreateModal as CreateContactModal } from "../../../../../contacts/shared/createModal";

interface IProps {
    visible: boolean;
    onClose: Function;
    meetingId: number;
    supplierId: any;
}

const ShareModal: FunctionComponent<IProps> = ({ visible, onClose, meetingId, supplierId }) => {
    const [form] = Form.useForm();
    const [reload] = useState({ reload: false });
    const { data } = useMeetingMinute(reload, meetingId);
    const { t } = useTranslation();
    const { shareMeetingMinute, loading: sharing } = useShareMeetingMinute();
    const [contactModal, setContactModal] = useState<any>({
        visible: false,
    });
    const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });

    useEffect(() => {
        if (visible) form.resetFields();
    }, [visible, data]);

    const handleOnOk = () => {
        form.validateFields()
            .then(async values => {
                let input = {
                    contactIds: values.internalReps.concat(values.externalReps),
                };
                await shareMeetingMinute(meetingId, input.contactIds);
                onClose();
            })
            .catch(err => {
                return;
            });
    };

    return (
        <>
            <Modal
                width={800}
                title={"Share Meeting Details"}
                open={visible}
                confirmLoading={sharing}
                okText={"Share"}
                onCancel={() => onClose()}
                onOk={async () => await handleOnOk()}>
                <Form form={form} layout="vertical">
                    <div className={classes.shareInfo}>Adjust recipients</div>
                    <Form.Item
                        label={
                            <div className={classes.formLabel}>
                                {t("governance_OurRepresentatives")}
                            </div>
                        }
                        name="internalReps"
                        initialValue={data && data.internalRepresentatives}>
                        <SelectDataMultiple
                            allowClear={true}
                            placeholder={t("survey_UsersPlaceholder")}
                            useData={useCompanyContactsWithAccess.bind(
                                null,
                                ["Administrator", "User", "lightuser"],
                                Modules.Review,
                                false,
                                reloadContacts
                            )}
                            selectAll
                            selectIdField="id"
                            selectOptionField="name"
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            dropdownRender={(menu: any) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Button
                                        block
                                        size="small"
                                        onClick={() =>
                                            setContactModal({
                                                visible: true,
                                                partnerId: undefined,
                                                type: "companyContacts",
                                            })
                                        }>
                                        + {t("Contacts_Add")}
                                    </Button>
                                </>
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className={classes.formLabel}>
                                {t("governance_TheirRepresentatives")}
                            </div>
                        }
                        name="externalReps"
                        initialValue={data && data.externalRepresentatives}>
                        <SelectDataMultiple
                            allowClear={true}
                            placeholder={t("survey_UsersPlaceholder")}
                            useData={useExternalContacts.bind(null, supplierId, reloadContacts)}
                            selectAll
                            selectIdField="id"
                            selectOptionField="name"
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            dropdownRender={(menu: any) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />
                                    <Button
                                        block
                                        size="small"
                                        onClick={() =>
                                            setContactModal({
                                                visible: true,
                                                partnerId: supplierId,
                                                type: "supplierContacts",
                                            })
                                        }>
                                        + {t("Contacts_Add")}
                                    </Button>
                                </>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {contactModal.visible && (
                <CreateContactModal
                    visible={contactModal.visible}
                    onClose={(reload, contactId) => {
                        if (reload === false) {
                            setContactModal({
                                visible: false,
                                partnerId: undefined,
                                type: undefined,
                            });
                        } else {
                            setReloadContacts({ reload: true });

                            if (contactModal.type === "companyContacts") {
                                form.setFieldsValue({
                                    internalReps: [
                                        ...form.getFieldValue("internalReps"),
                                        contactId,
                                    ],
                                });
                            }
                            if (contactModal.type === "supplierContacts") {
                                form.setFieldsValue({
                                    externalReps: [
                                        ...form.getFieldValue("externalReps"),
                                        contactId,
                                    ],
                                });
                            }
                            setContactModal({
                                visible: false,
                                partnerId: undefined,
                                type: undefined,
                            });
                        }
                    }}
                    partnerId={contactModal.partnerId}
                    showSupplierSelect={false}
                />
            )}
        </>
    );
};

export { ShareModal };
