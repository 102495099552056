import React, { FunctionComponent, useState } from "react";
import classes from "./performance.module.scss";
import Overview from "./unitOfAnalysis/overview";
import { PerformanceChart } from "./performanceChart/performanceChart";
import { PerformanceFiles } from "./performanceFiles/performanceFiles";
import { Row, Col, Affix, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface PropTypes {
    partnerId: number;
}
const Performance: FunctionComponent<PropTypes> = ({ partnerId }) => {
    const { t } = useTranslation();
    const [reload] = useState({ reload: false });
    const [performanceChartInput, setPerformanceChartInput] = useState<any[]>([]);
    const [selectedYear, setSelectedYear] = useState<any>(undefined);
    const user = useSelector((state: AppState) => state.oidc.user);
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;

    return (
        <>
            <Row gutter={18}>
                <Col span={16}>
                    <PerformanceChart
                        input={performanceChartInput}
                        selectedYear={selectedYear}
                        partnerId={partnerId}
                    />
                </Col>
                <Col span={8}>
                    <PerformanceFiles partnerId={partnerId} />
                </Col>
            </Row>
            <Row className={classes.bottomContainer}>
                <Col span={24}>
                    <Overview
                        partnerId={partnerId}
                        onIconClick={(input: any[]) => setPerformanceChartInput(input)}
                        reloadTable={reload}
                        onYearFilterChange={(year: any) => setSelectedYear(year)}
                    />
                </Col>
            </Row>
            {hasReviewAccess === true && (
                <Affix
                    style={{ position: "fixed", bottom: 10, right: 30 }}
                    className={classes.affix}>
                    <Button shape="round" className={classes.createAssessmentBtn}>
                        <Link to={`/Reviews/SurveyOverview/Create/Type/3/${partnerId}`}>
                            <div>{t("survey_CreateAssessment")}</div>
                        </Link>
                    </Button>
                </Affix>
            )}
        </>
    );
};

export { Performance };
