import React, { FunctionComponent, useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Row, Col } from "antd";
import classes from "./styles/pieChart.module.scss";
import { getHeaderSize } from "../reportUtilities/reportUtilities";
import { useTranslation } from "react-i18next";
import { ChartToolTipContainer } from "components/utilitycomponents";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    reportObject: any;
    parentId: any;
    benchmarkId: any;
}

export const PieChart: FunctionComponent<IProps> = ({ reportObject, parentId, benchmarkId }) => {
    const { ElementHeight, titleExcess, descriptionExcess } = getHeaderSize(parentId);
    const { data } = reportObject;
    const { t } = useTranslation();
    const [pieData, setPieData] = useState(data.chartData);

    useEffect(() => {
        if (data && data.chartData) {
            let nonEmptyBaseData =
                data.chartData.baseData &&
                data.chartData.baseData.filter((x: any) => x.value !== null);
            let nonEmptyPreviousPeriodData =
                data.chartData.previousPeriodData &&
                data.chartData.previousPeriodData.filter((x: any) => x.value !== null);
            let nonEmptyBenchmarkData =
                data.chartData.benchmarkData &&
                data.chartData.benchmarkData.filter((x: any) => x.value !== null);
            setPieData({
                ...pieData,
                baseData: nonEmptyBaseData,
                previousPeriodData: nonEmptyPreviousPeriodData,
                benchmarkData: nonEmptyBenchmarkData,
            });
        }
    }, [data]);

    let mainColSpan =
        reportObject.filter.samePeriodPreviousYear &&
        reportObject.filter.includeBenchmark &&
        benchmarkId !== undefined
            ? 8
            : reportObject.filter.samePeriodPreviousYear
            ? 12
            : reportObject.filter.includeBenchmark && benchmarkId !== undefined
            ? 12
            : 24;

    return (
        <Row>
            <Col
                id="mainPieChart"
                span={mainColSpan}
                className={classes.pieChartElement}
                style={{ height: ElementHeight - titleExcess - descriptionExcess }}>
                <ResponsivePie
                    data={pieData.baseData}
                    margin={{ top: 20, right: 100, bottom: 50, left: 100 }}
                    innerRadius={0}
                    colors={{ scheme: "spectral" }}
                    borderWidth={1}
                    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                    arcLabel={function(e) {
                        let val =
                            e.id.toString().length > 30
                                ? e.id
                                      .toString()
                                      .substring(0, 30)
                                      .trim() + "..."
                                : e.id;
                        return val.toString();
                    }}
                    arcLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={5}
                    arcLinkLabelsStraightLength={5}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLinkLabelsSkipAngle={10}
                    arcLabelsSkipAngle={10}
                    enableArcLabels={false}
                    animate={true}
                    tooltip={({ datum }: any) => (
                        <ChartToolTipContainer>
                            <strong>
                                {datum.id}:
                                {datum.value &&
                                    datum.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}
                            </strong>
                        </ChartToolTipContainer>
                    )}
                />
                <div className={classes.footer}>
                    <h4>
                        {t("analytics_FiltrationPeriod")}:{" "}
                        {dayjs(reportObject.filter.from).format("MM/YYYY") +
                            " - " +
                            dayjs(reportObject.filter.to).format("MM/YYYY")}
                    </h4>
                </div>
            </Col>
            {reportObject.filter.samePeriodPreviousYear === true && (
                <Col
                    span={
                        reportObject.filter.includeBenchmark && benchmarkId !== undefined ? 8 : 12
                    }
                    className={classes.pieChartElement}
                    style={{ height: ElementHeight - titleExcess - descriptionExcess }}>
                    <ResponsivePie
                        data={pieData.previousPeriodData}
                        margin={{ top: 20, right: 100, bottom: 50, left: 100 }}
                        innerRadius={0}
                        // colors={["#bae637", "#ff9c6e", "#ff4d4f", "#ffec3d"]}
                        colors={{ scheme: "spectral" }}
                        borderWidth={1}
                        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                        arcLabel={function(e) {
                            let val =
                                e.id.toString().length > 30
                                    ? e.id
                                          .toString()
                                          .substring(0, 30)
                                          .trim() + "..."
                                    : e.id;
                            return val.toString();
                        }}
                        arcLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={5}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLinkLabelsSkipAngle={10}
                        arcLabelsSkipAngle={10}
                        enableArcLabels={false}
                        animate={true}
                        tooltip={({ datum }: any) => (
                            <ChartToolTipContainer>
                                <strong>
                                    {datum.id}:
                                    {datum.value &&
                                        datum.value
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}
                                </strong>
                            </ChartToolTipContainer>
                        )}
                    />
                    <div className={classes.footer}>
                        <h4>{t("analytics_PieChartSamePeriodLabel")}</h4>
                    </div>
                </Col>
            )}
            {reportObject.filter.includeBenchmark === true && benchmarkId !== undefined && (
                <Col
                    span={reportObject.filter.samePeriodPreviousYear ? 8 : 12}
                    className={classes.pieChartElement}
                    style={{ height: ElementHeight - titleExcess - descriptionExcess }}>
                    <ResponsivePie
                        data={pieData.benchmarkData}
                        margin={{ top: 20, right: 100, bottom: 50, left: 100 }}
                        innerRadius={0}
                        colors={{ scheme: "spectral" }}
                        borderWidth={1}
                        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                        arcLabel={function(e) {
                            let val =
                                e.id.toString().length > 30
                                    ? e.id
                                          .toString()
                                          .substring(0, 30)
                                          .trim() + "..."
                                    : e.id;
                            return val.toString();
                        }}
                        arcLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={5}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLinkLabelsSkipAngle={10}
                        arcLabelsSkipAngle={10}
                        enableArcLabels={false}
                        animate={true}
                        tooltip={({ datum }: any) => (
                            <ChartToolTipContainer>
                                <strong>
                                    {datum.id}:
                                    {datum.value &&
                                        datum.value
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}
                                </strong>
                            </ChartToolTipContainer>
                        )}
                    />
                    <div className={classes.footer}>
                        <h4>{t("analytics_PieChartBenchmarkLabel")}</h4>
                    </div>
                </Col>
            )}
        </Row>
    );
};
