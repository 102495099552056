import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AreaChartOutlined } from "@ant-design/icons";
import { ReportSettingsType } from "models";
import { PageContainer } from "components/utilitycomponents";
import classes from "./embeddedReports.module.scss";

interface IProps {
    data: any;
}

const EmbeddedReports = ({ data }: IProps) => {
    const { t } = useTranslation();
    const topBarHeight = 180;
    const tabHeight = 46;
    useEffect(() => {
        window.addEventListener(
            "resize",
            function(e) {
                const iFrames: any = document.getElementsByClassName("resourceIframe");
                Array.from(iFrames).forEach((iframe: any) => {
                    if (iframe) {
                        iframe.height = window.innerHeight - topBarHeight - tabHeight + "px";
                    }
                });
            },
            false
        );
    }, []);

    const renderFrame = () => {
        if (data && data.headline === null) {
            return (
                <div className={classes.noReport}>
                    <div className={classes.noReportIcon}>
                        <AreaChartOutlined />
                    </div>
                    <div>
                        <p>{t("reporting_ReportNote")}</p>
                        <p>{t("reporting_ContactAdmin")}</p>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        margin: "8px 0",
                        color: "#676767",
                    }}>
                    {data ? data.headline : ""}
                </div>
                {data && (
                    <iframe
                        className="resourceIframe"
                        width="100%"
                        height={window.innerHeight - topBarHeight - tabHeight}
                        style={{ borderWidth: 0, margin: "auto" }}
                        src={
                            data.type === ReportSettingsType.URL && data.url
                                ? data.url
                                : "data:text/html," +
                                  encodeURIComponent(data.embeddedCode ? data.embeddedCode : "")
                        }
                        title={data ? data.headline : ""}></iframe>
                )}
            </>
        );
    };

    return <PageContainer fluid>{renderFrame()}</PageContainer>;
};

export default EmbeddedReports;
