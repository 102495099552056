import { useSelector } from "react-redux";
import { AppState } from "store";

const useScorecardFeature = () => {
    const user = useSelector((state: AppState) => state.oidc.user);

    const hasScorecardFeature =
        user && user.profile && user.profile["ll-enabledFeature-Scorecard"] === "true";

    return {
        hasScorecardFeature,
    };
};

export { useScorecardFeature };
