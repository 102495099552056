import { useEffect, useState } from "react";
import Provider from "services/api";

type StateType = {
    data: any;
    loading: boolean;
};

const useComplianceByToken = (token: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Compliance.getComplianceByToken(token, {
                customError: {
                    message: "Could not retrieve registration data",
                },
            }).catch(err => {
                setState({ data: null, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [token]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useComplianceByToken };
