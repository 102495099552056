import React, { useState, FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Tag, notification, Tooltip, Popconfirm } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Grid, getColumnSearchProps, getColumnSelectProps } from "components/utilitycomponents";
import {
    useContactGroups,
    useContactOverview,
    useContactUpdate,
    useContactUserTypes,
} from "controller";
import OverviewHeader from "./overviewHeader";
import CreateEditModal from "./createEditModal";
import GroupContactsModal from "./groupContactsModal";
import { ReportType } from "../../../../models";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();

    const { removeGroupFromContact } = useContactUpdate();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [createEditModal, setCreateEditModal] = useState({
        data: {},
        visible: false,
    });
    const [groupContactsModal, setGroupContactsModal] = useState({
        visible: false,
    });
    const [groupToDelete, setGroupToDelete] = useState({
        groupId: undefined,
        contactId: undefined,
    });

    const handleOnRow = (record: any) => ({
        onClick: () => {
            if (record.isEditable === true) {
                setCreateEditModal({ data: record, visible: true });
            }
        },
        style: {
            cursor: record.isEditable === true ? "pointer" : "auto",
        },
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    const columns = [
        {
            title: t("contact_userType"),
            dataIndex: "contactUserType",
            key: "ContactUserType",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 1,
            ...getColumnSelectProps(
                useContactUserTypes,
                {
                    value: ["id"],
                    text: ["typeName"],
                },
                { dataIndex: "contactUserType" }
            ),
            render: (value: any) => t(value),
        },
        {
            title: t("contact_firstName"),
            dataIndex: "firstName",
            key: "FirstName",
            ellipsis: true,
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 2,
            ...getColumnSearchProps({ dataIndex: "firstName" }),
        },
        {
            title: t("contact_lastName"),
            dataIndex: "lastName",
            key: "LastName",
            ellipsis: true,
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 3,
            ...getColumnSearchProps({ dataIndex: "lastName" }),
        },
        {
            title: t("contact_Group"),
            dataIndex: "contactGroups",
            key: "Group",
            width: 250,
            filterType: "array",
            viewColumnId: 4,
            render: (groups: any[], record: any) => (
                <div>
                    {groups.map((group: any, index: number) => (
                        <div key={index}>
                            <Tag
                                style={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}
                                color="blue"
                                key={index}>
                                {group.name.length > 35 ? (
                                    <Tooltip title={group.name}>
                                        {group.name.substring(0, 35)} ...
                                    </Tooltip>
                                ) : (
                                    group.name
                                )}

                                <Tooltip
                                    placement="top"
                                    title={t("contact_DeleteGroupFromContact")}>
                                    <Popconfirm
                                        title={t("contact_DeleteGroupFromContactWarning", {
                                            count: selectedRowKeys.length,
                                        })}
                                        onConfirm={async (e: any) => {
                                            e.stopPropagation();
                                            setGroupToDelete({
                                                groupId: group.id,
                                                contactId: record.id,
                                            });
                                            await removeGroupFromContact([record.id], [group.id]);
                                            notification.success({
                                                message: t("success"),
                                                description: t(
                                                    "contact_DeleteGroupFromContactSuccess",
                                                    {
                                                        group: group.name,
                                                    }
                                                ),
                                            });
                                            setReloadData({ reload: true });
                                            setGroupToDelete({
                                                groupId: undefined,
                                                contactId: undefined,
                                            });
                                        }}
                                        onCancel={(e: any) => {
                                            e.stopPropagation();
                                        }}
                                        okText={t("generic_Confirm")}
                                        cancelText={t("generic_Cancel")}>
                                        {group.id === groupToDelete.groupId &&
                                        record.id === groupToDelete.contactId ? (
                                            <LoadingOutlined
                                                style={{
                                                    marginLeft: "3px",
                                                    fontSize: "10px",
                                                    color: "rgba(0, 0, 0, 0.45)",
                                                }}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        ) : (
                                            <CloseOutlined
                                                style={{
                                                    marginLeft: "3px",
                                                    fontSize: "10px",
                                                    color: "rgba(0, 0, 0, 0.45)",
                                                }}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        )}
                                    </Popconfirm>
                                </Tooltip>
                            </Tag>
                        </div>
                    ))}
                </div>
            ),
            ...getColumnSelectProps(
                useContactGroups,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "contactGroups" }
            ),
        },
        {
            title: t("contact_companyName"),
            dataIndex: "companyName",
            key: "CompanyName",
            width: 250,
            sorter: true,
            filterType: "string",
            viewColumnId: 5,
            ...getColumnSearchProps({ dataIndex: "companyName" }),
        },
        {
            title: t("contact_companyNumber"),
            dataIndex: "companyNumber",
            key: "CompanyNumber",
            width: 200,
            sorter: true,
            filterType: "string",
            viewColumnId: 6,
            ...getColumnSearchProps({ dataIndex: "companyNumber" }),
        },
        {
            title: t("contact_emailAddress"),
            dataIndex: "emailAddress",
            key: "EmailAddress",
            width: 250,
            sorter: true,
            filterType: "string",
            viewColumnId: 7,
            ...getColumnSearchProps({ dataIndex: "emailAddress" }),
        },
        {
            title: t("contact_workPhone"),
            dataIndex: "workPhone",
            key: "WorkPhone",
            width: 200,
            sorter: true,
            filterType: "string",
            viewColumnId: 8,
            ...getColumnSearchProps({ dataIndex: "workPhone" }),
        },
        {
            title: t("contact_mobilePhone"),
            dataIndex: "mobilePhone",
            key: "MobilePhone",
            width: 200,
            sorter: true,
            filterType: "string",
            viewColumnId: 9,
            ...getColumnSearchProps({ dataIndex: "mobilePhone" }),
        },
        {
            title: t("contact_jobTitle"),
            dataIndex: "jobTitle",
            key: "JobTitle",
            filterType: "string",
            width: 250,
            sorter: true,
            viewColumnId: 10,
            ...getColumnSearchProps({ dataIndex: "jobTitle" }),
        },
        {
            title: t("contact_department"),
            dataIndex: "department",
            key: "Department",
            width: 250,
            sorter: true,
            filterType: "string",
            viewColumnId: 11,
            ...getColumnSearchProps({ dataIndex: "department" }),
        },
        {
            title: t("contact_country"),
            dataIndex: "countryName",
            key: "Country",
            filterType: "string",
            width: 150,
            sorter: true,
            viewColumnId: 12,
            ...getColumnSearchProps({ dataIndex: "countryName" }),
        },
        {
            title: t("contact_address"),
            dataIndex: "address",
            key: "Address",
            filterType: "string",
            width: 250,
            sorter: true,
            viewColumnId: 13,
            ...getColumnSearchProps({ dataIndex: "address" }),
        },
        {
            title: t("contact_Visibility"),
            dataIndex: "visibility",
            key: "visibility",
            filterType: "array",
            width: 250,
            sorter: true,
            viewColumnId: 14,
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("external"), value: 2 },
                            { text: t("internal"), value: 1 },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "visibility", radioChoice: true }
            ),
            render: (val: string) => <div>{t(val.toLowerCase())}</div>,
        },
    ];

    return (
        <Fragment>
            <Grid
                data-test="contact_table"
                title={() => (
                    <OverviewHeader
                        onCreateClicked={() => setCreateEditModal({ data: {}, visible: true })}
                        onGroupClicked={() => {
                            setGroupContactsModal({
                                visible: true,
                            });
                        }}
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        onClearSelectedRows={setSelectedRowKeys}
                        onReloadData={() => setReloadData({ reload: true })}
                    />
                )}
                columns={columns}
                reportType={ReportType.contact}
                fixedColumns={["contactUserType", "firstName", "lastName"]}
                useData={useContactOverview}
                tableType="Contact"
                onRow={handleOnRow}
                rowSelection={rowSelection}
                reloadData={reloadData}
            />
            {createEditModal.visible && (
                <CreateEditModal
                    data={createEditModal.data}
                    visible={createEditModal.visible}
                    onClose={(reload: boolean) => {
                        setCreateEditModal({ data: {}, visible: false });
                        if (reload) {
                            setReloadData({ reload: true });
                        }
                        setSelectedRowKeys([]);
                    }}
                />
            )}
            {groupContactsModal.visible && (
                <GroupContactsModal
                    data={selectedRows.map((r: any) => ({
                        id: r.id,
                        name: `${r.firstName} ${r.lastName}`,
                    }))}
                    visible={groupContactsModal.visible}
                    onClose={(reload: boolean) => {
                        setGroupContactsModal({ visible: false });
                        if (reload) {
                            setReloadData({ reload: true });
                        }
                        setSelectedRowKeys([]);
                    }}
                />
            )}
        </Fragment>
    );
};

export default Overview;
