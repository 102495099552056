import { Card, Flex } from "antd";
import React from "react";
import SemiCircularGauge from "../semiCircularChart";
import { useAwardsSettings, useAwardStatus } from "controller";
import { useTranslation } from "react-i18next";
import classes from "./scorecardStatusWidget.module.scss";

interface IProps {
    partnerId: number;
}

const ScorecardStatusWidget = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const { data } = useAwardStatus(partnerId);
    const { data: awardSettings } = useAwardsSettings();

    return (
        <Card
            title={<div className={classes.cardTitle}>{t("scorecard_SupplierScore")}</div>}
            size="small">
            <Flex justify="center">
                <SemiCircularGauge
                    value={data && data.score}
                    width={342}
                    height={181}
                    widget={true}
                    level={data?.level}
                    awardLevelData={awardSettings}
                />
            </Flex>
        </Card>
    );
};

export { ScorecardStatusWidget };
