import React, { FunctionComponent, useEffect } from "react";
import { Modal, Col, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { FormRow, SelectTreeData } from "components/utilitycomponents";
import { useSurveyTemplates } from "controller";

type PropsType = {
    visible: boolean;
    onClose: Function;
    onSelect: Function;
};

const SelectSurveyTemplateModal: FunctionComponent<PropsType> = ({
    visible,
    onClose,
    onSelect,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        const values = await form.validateFields();
        onSelect(values.templateId);
    };

    useEffect(() => {
        visible && form.resetFields();
    }, [visible]);

    return (
        <Modal
            wrapProps={{ "data-test": "select_survey_template_modal" }}
            title={t("survey_SelectSurveyTemplate")}
            open={visible}
            onOk={() => {
                form.submit();
            }}
            footer={[
                <Button type="primary" onClick={async () => form.submit()}>
                    {t("generic_Ok")}
                </Button>,
            ]}
            onCancel={() => {
                onClose();
            }}
            destroyOnClose>
            <Form form={form} onFinish={handleSubmit}>
                <FormRow>
                    <Col span={24}>
                        <Form.Item
                            rules={[{ required: true, message: t("inputIsRequiredError") }]}
                            name="templateId">
                            <SelectTreeData
                                useData={useSurveyTemplates}
                                selectIdField="id"
                                selectOptionField="name"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                placeholder={t("select")}
                                selectOnlyChildren
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default SelectSurveyTemplateModal;
