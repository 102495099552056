import { IStatusEvent } from "models/admin";
import { useState } from "react";
import { Provider } from "services";

const useStatusEventCreate = () => {
    const [loading, setLoading] = useState(false);

    const createStatusEvent = async (statusEvent: IStatusEvent) => {
        setLoading(true);

        const res = await Provider.Partner.createStatusEvent(statusEvent, {
            customError: {
                message: "Could not create status event",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        createStatusEvent,
    };
};

export { useStatusEventCreate };
