import { useState } from "react";
import { default as serviceProvider } from "../../services/api";

const useIsCompanyNumberInUse = () => {
    const [loading, setLoading] = useState(false);

    const checkForExistingNumber = async (companyNumber: string) => {
        if (companyNumber !== "") {
            setLoading(true);
            const inUse = await serviceProvider.Partner.IsCompanyNumberInUse(companyNumber, {
                customError: {
                    message: "Could not validate company name",
                },
            }).catch(err => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
            return inUse;
        }
    };

    return {
        loading,
        checkForExistingNumber,
    };
};

export default useIsCompanyNumberInUse;
