import React, { FunctionComponent, Fragment } from "react";
import classes from "./styles/shared.module.scss";
import { IKickStarterDetail } from "models";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: IKickStarterDetail;
};

const KickStarter: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className={classes.activityTitle}>{t("activity_Welcome")}</div>
            <div>{t("activity_Kickstarter")}</div>
        </Fragment>
    );
};

export default KickStarter;
