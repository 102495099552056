import React, { FunctionComponent, Fragment } from "react";
import classes from "./styles/shared.module.scss";
import { IMessageDetail } from "models";
import { newlineToBr } from "utilities";
import { PureDiv } from "components/utilitycomponents";

type PropsType = {
    data: IMessageDetail;
};

const Message: FunctionComponent<PropsType> = ({ data }) => {
    return (
        <Fragment>
            <div className={classes.activityTitle}>{data.creator}</div>
            <PureDiv data-test="activity_body" textContent={newlineToBr(data.message)} />
        </Fragment>
    );
};

export default Message;
