import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./supplierInformation.module.scss";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { useSupplierDomainUpdate } from "controller";

interface IProps {
    propsDomain: string | undefined;
    onDomainChange?: Function;
    partnerId: number;
}

const SupplierDomain = ({ propsDomain, onDomainChange, partnerId }: IProps) => {
    const { t } = useTranslation();
    const [supplierDomain, setSupplierDomain] = useState<string | undefined>(propsDomain);
    const [editDomain, toggleEditDomain] = useState<boolean>(
        supplierDomain === undefined || supplierDomain === null
    );
    const { updateDomain, loading } = useSupplierDomainUpdate();

    useEffect(() => {
        setSupplierDomain(propsDomain);
        toggleEditDomain(propsDomain === undefined || propsDomain === null);
    }, [propsDomain]);

    const onDomainClick = (domain?: string) => {
        if (domain) {
            if (domain.includes("https://")) {
                window.open(domain, "_blank");
            } else {
                window.open("https://" + domain, "_blank");
            }
        }
    };

    return (
        <Row className={classes.domainRow}>
            <Col span={20}>
                {editDomain ? (
                    <Input
                        className={classes.inputMinimalBorder}
                        onChange={(e: any) => setSupplierDomain(e.target.value)}
                        variant="borderless"
                        value={supplierDomain}
                        placeholder={t("partner_WebsitePlaceholder")}
                        disabled={!editDomain}
                    />
                ) : (
                    <>
                        <a
                            title={supplierDomain}
                            onClick={() => onDomainClick(supplierDomain)}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.domainText}>
                            {supplierDomain!.replace("https://", "")}
                        </a>
                        <div className={classes.domainDivider}></div>
                    </>
                )}
            </Col>
            <Col span={4}>
                {!editDomain ? (
                    <Button
                        className={classes.domainIcon}
                        icon={<EditOutlined />}
                        size="small"
                        onClick={() => toggleEditDomain(!editDomain)}></Button>
                ) : (
                    <Button
                        className={!loading ? classes.domainIcon : classes.loadingButton}
                        type="primary"
                        icon={<CheckOutlined />}
                        size="small"
                        loading={loading}
                        onClick={async () => {
                            await updateDomain(partnerId, supplierDomain);
                            onDomainChange &&
                                onDomainChange(
                                    supplierDomain
                                        ? supplierDomain.replace("https://", "")
                                        : undefined
                                );
                            toggleEditDomain(!editDomain);
                        }}></Button>
                )}
            </Col>
        </Row>
    );
};

export { SupplierDomain };
