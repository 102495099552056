import React, { FunctionComponent, Fragment, useState } from "react";
import { Button, Modal } from "antd";
import { useRecallSelfAssessment } from "controller";
import { GridButton, LinkBroken02 } from "components/utilitycomponents";
import { useTranslation, Trans } from "react-i18next";

type PropsType = {
    selectedTokens: string[];
    onReloadData: Function;
    onClearSelectedRows: Function;
};

const RequestsHeader: FunctionComponent<PropsType> = ({
    selectedTokens,
    onReloadData,
    onClearSelectedRows,
}) => {
    const [recallModal, setRecallModal] = useState({
        visible: false,
    });
    const { loading: recallLoading, recall } = useRecallSelfAssessment();
    const { t } = useTranslation();

    const handleRecallClicked = async () => {
        await recall(selectedTokens);
        setRecallModal({ visible: false });
        onReloadData();
        onClearSelectedRows([]);
    };
    const buttonVisibility = selectedTokens.length > 0;
    return (
        <Fragment>
            {buttonVisibility && (
                <GridButton
                    data-test="recall_button"
                    icon={<LinkBroken02 />}
                    type="default"
                    onClick={() => setRecallModal({ visible: true })}>
                    {t("compliance_Recall")}
                </GridButton>
            )}
            <Modal
                data-test="recall_confirm_modal"
                title={t("generic_PleaseConfirm")}
                open={recallModal.visible}
                onOk={handleRecallClicked}
                onCancel={() => {
                    setRecallModal({ visible: false });
                    onClearSelectedRows([]);
                }}
                footer={[
                    <Button
                        loading={recallLoading}
                        type="primary"
                        onClick={async () => await handleRecallClicked()}>
                        {t("generic_Confirm")}
                    </Button>,
                ]}>
                <p>
                    <Trans
                        i18nKey="compliance_RecallWarning"
                        values={{ count: selectedTokens.length }}>
                        {t("compliance_RecallWarning")}
                    </Trans>
                </p>
            </Modal>
        </Fragment>
    );
};

export default RequestsHeader;
