import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Collapse, Checkbox, DatePicker, Alert, Form } from "antd";
import { SelectData } from "components/utilitycomponents";
import { useCompanyType, useNcrExternalActionOwners } from "controller";
import classes from "./styles/ncrActionsInput.module.scss";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    form: any;
    record?: any;
    selectedPartner: any;
    selectedPartnerId: any;
    isEditMode: boolean;
    parentModalVisible: boolean;
}

const NcrActionsInput: FunctionComponent<IProps> = ({
    form,
    record,
    selectedPartner,
    selectedPartnerId,
    isEditMode,
    parentModalVisible,
}) => {
    const { Panel } = Collapse;
    const { t } = useTranslation();
    const [partner, setPartner] = useState(selectedPartner);
    const [partnerId, setPartnerId] = useState(selectedPartnerId);
    const { data, loading: ncrOwnersLoading } = useNcrExternalActionOwners(partnerId, partner);
    const [ncrOwners, setNcrOwners] = useState(data);
    const [activeKey, setActiveKey] = useState<string | string[]>([]);
    const initialCorrectiveAction =
        record && record.ncrActions.filter((x: any) => x.actionType === "Corrective")[0];
    const [correctiveChecked, setCorrectiveChecked] = useState(
        initialCorrectiveAction ? true : false
    );
    const initialPreventiveAction =
        record && record.ncrActions.filter((x: any) => x.actionType === "Preventive")[0];
    const [preventiveChecked, setPreventiveChecked] = useState(
        initialPreventiveAction ? true : false
    );
    const initialRootCauseAction =
        record && record.ncrActions.filter((x: any) => x.actionType === "RootCause")[0];
    const [rootCauseChecked, setRootCauseChecked] = useState(initialRootCauseAction ? true : false);
    const { isDefaultCompany } = useCompanyType();

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    useEffect(() => {
        if (!isEditMode) {
            setActiveKey([]);
        } else {
            setActiveKey("1");
        }
    }, [parentModalVisible]);

    useEffect(() => {
        setPartner(selectedPartner);
        setPartnerId(selectedPartnerId);
    }, [selectedPartner, selectedPartnerId]);

    useEffect(() => {
        if (isEditMode) {
            if (data.length > ncrOwners.length) {
                setNcrOwners(data);
            }
        } else {
            setNcrOwners(data);
        }
    }, [data]);
    return (
        <>
            <Collapse
                data-test="ncr_actions_header"
                activeKey={activeKey}
                onChange={(key: string | string[]) => setActiveKey(key)}>
                <Panel
                    data-test="ncr_actions_panel"
                    className={classes.actionsHeader}
                    header={t("ncrActions_RequestHeaderTitle", {
                        companyType: isDefaultCompany
                            ? t("company_Type_Supplier")
                            : t("company_Type_Customer"),
                    })}
                    key="1">
                    <Row gutter={8}>
                        <Col span={8}></Col>
                        <Col span={10}>{t("ncrActions_Owner")}</Col>
                        <Col span={6}>{t("ncrActions_Deadline")}</Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item
                                className={classes.formItem}
                                name="rootCauseCheckBox"
                                valuePropName="checked"
                                initialValue={rootCauseChecked}>
                                <Checkbox
                                    data-test="root_cause_action_checkbox"
                                    onChange={(e: any) => {
                                        setRootCauseChecked(e.target.checked);
                                    }}>
                                    {record
                                        ? t("ncrActions_RootCauseAction")
                                        : t("ncrActions_AddRootCauseAction")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                className={classes.formItem}
                                name="rootCauseActionOwner"
                                initialValue={
                                    initialRootCauseAction && initialRootCauseAction.owners[0]
                                        ? initialRootCauseAction.owners[0].dependentOwnerId
                                        : undefined
                                }
                                rules={[
                                    {
                                        required: rootCauseChecked,
                                        message: t("inputIsRequiredError"),
                                    },
                                ]}>
                                <SelectData
                                    data-test="root_cause_action_owner_select"
                                    selectIdField="id"
                                    selectOptionField="name"
                                    placeholder={t("ncrActions_OwnerPlaceholder")}
                                    disabled={!rootCauseChecked}
                                    useData={() => {
                                        return {
                                            data: ncrOwners,
                                            loading: ncrOwnersLoading,
                                        };
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={classes.formItem}
                                name="rootCauseDeadline"
                                initialValue={
                                    isEditMode
                                        ? initialRootCauseAction && initialRootCauseAction.deadline
                                            ? dayjs(initialRootCauseAction.deadline)
                                            : undefined
                                        : dayjs().add(5, "days")
                                }>
                                <DatePicker
                                    format={"MMM D, YYYY"}
                                    data-test="root_cause_deadline_date_picker"
                                    disabled={!rootCauseChecked}
                                    disabledDate={disabledDate}
                                    placeholder={t("ncrActions_DeadlinePlaceholder")}
                                    allowClear
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {initialRootCauseAction && rootCauseChecked === false && record && (
                        <Alert message={t("ncrActions_DeletionAlert")} type="warning"></Alert>
                    )}
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item
                                className={classes.formItem}
                                name="correctiveActionCheckBox"
                                valuePropName="checked"
                                initialValue={correctiveChecked}>
                                <Checkbox
                                    data-test="corrective_action_checkbox"
                                    onChange={(e: any) => setCorrectiveChecked(e.target.checked)}>
                                    {record
                                        ? t("ncrActions_CorrectiveAction")
                                        : t("ncrActions_AddCorrectiveAction")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                className={classes.formItem}
                                name="correctiveActionOwner"
                                initialValue={
                                    initialCorrectiveAction && initialCorrectiveAction.owners[0]
                                        ? initialCorrectiveAction.owners[0].dependentOwnerId
                                        : undefined
                                }
                                rules={[
                                    {
                                        required: correctiveChecked,
                                        message: t("inputIsRequiredError"),
                                    },
                                ]}>
                                <SelectData
                                    data-test="corrective_action_owner_select"
                                    selectIdField="id"
                                    selectOptionField="name"
                                    placeholder={t("ncrActions_OwnerPlaceholder")}
                                    disabled={!correctiveChecked}
                                    useData={() => {
                                        return {
                                            data: ncrOwners,
                                            loading: ncrOwnersLoading,
                                        };
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={classes.formItem}
                                name="correctiveDeadline"
                                initialValue={
                                    isEditMode
                                        ? initialCorrectiveAction &&
                                          initialCorrectiveAction.deadline
                                            ? dayjs(initialCorrectiveAction.deadline)
                                            : undefined
                                        : dayjs().add(5, "day")
                                }>
                                <DatePicker
                                    format={"MMM D, YYYY"}
                                    data-test="corrective_deadline_date_picker"
                                    disabled={!correctiveChecked}
                                    disabledDate={disabledDate}
                                    placeholder={t("ncrActions_DeadlinePlaceholder")}
                                    allowClear
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {initialCorrectiveAction && !correctiveChecked && record && (
                        <Alert message={t("ncrActions_DeletionAlert")} type="warning"></Alert>
                    )}
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item
                                className={classes.formItem}
                                name="preventiveActionCheckBox"
                                valuePropName="checked"
                                initialValue={preventiveChecked}>
                                <Checkbox
                                    data-test="preventive_action_checkbox"
                                    onChange={(e: any) => setPreventiveChecked(e.target.checked)}>
                                    {record
                                        ? t("ncrActions_PreventiveAction")
                                        : t("ncrActions_AddPreventiveAction")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                className={classes.formItem}
                                name="preventiveActionOwner"
                                initialValue={
                                    initialPreventiveAction && initialPreventiveAction.owners[0]
                                        ? initialPreventiveAction.owners[0].dependentOwnerId
                                        : undefined
                                }
                                rules={[
                                    {
                                        required: preventiveChecked,
                                        message: t("inputIsRequiredError"),
                                    },
                                ]}>
                                <SelectData
                                    data-test="preventive_action_owner_select"
                                    selectIdField="id"
                                    selectOptionField="name"
                                    placeholder={t("ncrActions_OwnerPlaceholder")}
                                    disabled={!preventiveChecked}
                                    useData={() => {
                                        return {
                                            data: ncrOwners,
                                            loading: ncrOwnersLoading,
                                        };
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={classes.formItem}
                                name="preventiveDeadline"
                                initialValue={
                                    isEditMode
                                        ? initialPreventiveAction &&
                                          initialPreventiveAction.deadline
                                            ? dayjs(initialPreventiveAction.deadline)
                                            : undefined
                                        : dayjs().add(5, "days")
                                }>
                                <DatePicker
                                    format={"MMM D, YYYY"}
                                    data-test="preventive_deadline_date_picker"
                                    disabled={!preventiveChecked}
                                    disabledDate={disabledDate}
                                    placeholder={t("ncrActions_DeadlinePlaceholder")}
                                    allowClear
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {initialPreventiveAction && !preventiveChecked && record && (
                        <Alert message={t("ncrActions_DeletionAlert")} type="warning"></Alert>
                    )}
                </Panel>
            </Collapse>
        </>
    );
};

export { NcrActionsInput };
