import React, { FunctionComponent, useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { format } from "d3-format";
import { Tooltip } from "antd";
import classes from "./styles/barChart.module.scss";
import "./styles/barChart.css";
import { getHeaderSize } from "../reportUtilities/reportUtilities";
import { useTranslation } from "react-i18next";
import { BoxLegendSvg } from "@nivo/legends";

interface IProps {
    reportObject: any;
    parentId: any;
}

export const BarChart: FunctionComponent<IProps> = ({ reportObject, parentId }) => {
    const { ElementHeight, titleExcess, descriptionExcess } = getHeaderSize(parentId);
    const { data } = reportObject;
    const { t } = useTranslation();
    const [maxValue, setMaxValue] = useState<any>("auto");

    useEffect(() => {
        if (data && data.chartData) {
            let maxVal: any = "auto";
            let targetVal: any = 0;
            for (let dataPoint of data.chartData) {
                if (dataPoint[data.keys[0]] < dataPoint.target && dataPoint.target > targetVal) {
                    targetVal = dataPoint.target;
                    maxVal = dataPoint.target;
                } else if (
                    dataPoint[data.keys[0]] > dataPoint.target &&
                    dataPoint[data.keys[0]] > targetVal
                ) {
                    maxVal = "auto";
                }
            }
            setMaxValue(maxVal);
        }
    }, [data]);

    const customBottomTick = (tick: any) => {
        return (
            <g transform={`translate(${tick.x},${tick.tickIndex % 2 ? tick.y + 12 : tick.y + 22})`}>
                <line
                    stroke="rgb(190, 190, 190)"
                    strokeWidth={1.5}
                    y1={tick.tickIndex % 2 ? -12 : -22}
                    y2={-12}
                />
                <text
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fill: "#333",
                        fontSize: 10,
                    }}>
                    <title>{t(tick.value)}</title>
                    {tick.value.length > 30
                        ? t(tick.value)
                              .substring(0, 30)
                              .trim() + "..."
                        : t(tick.value)}
                </text>
            </g>
        );
    };

    const colors = ["#3288bd", "#5e4fa2", "#66c2a5"];

    const markerLayer = (props: any) => {
        let higherIsBetter = data.higherIsBetter;
        return props.bars.map((bar: any, index: number) => {
            if (bar.data.id === "Same Period Last Year" && bar.data.data.targetPreviousPeriod) {
                return (
                    <Tooltip
                        key={index}
                        title={
                            <span>
                                Target Previous Period - {bar.data.data.period}:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {bar.data.data.targetPreviousPeriod}
                                </span>
                            </span>
                        }>
                        <rect
                            key={index}
                            x={
                                props.bars.filter(
                                    (b: any) =>
                                        b.key === `Same Period Last Year.${bar.data.data.period}`
                                )[0].x
                            }
                            y={props.yScale(bar.data.data.targetPreviousPeriod)}
                            width={bar.width}
                            height={"3"}
                            stroke={
                                bar.data.data.targetPreviousPeriod <= bar.data.value
                                    ? higherIsBetter
                                        ? "#5BFA55"
                                        : "#FF655C"
                                    : "#FF655C"
                            }
                            fill={
                                bar.data.data.targetPreviousPeriod <= bar.data.value
                                    ? higherIsBetter
                                        ? "#5BFA55"
                                        : "#FF655C"
                                    : "#FF655C"
                            }></rect>
                    </Tooltip>
                );
            } else if (bar.data.id === data.keys[0]) {
                return (
                    bar.data.data.target && (
                        <Tooltip
                            key={index}
                            title={
                                <span>
                                    Target - {bar.data.data.period}:{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                        {bar.data.data.target}
                                    </span>
                                </span>
                            }
                            overlayClassName={classes.tooltip}>
                            <rect
                                key={index}
                                x={bar.x}
                                y={props.yScale(bar.data.data.target)}
                                width={bar.width}
                                height={"3"}
                                stroke={
                                    bar.data.data.target <= bar.data.value
                                        ? higherIsBetter
                                            ? "#5BFA55"
                                            : "#FF655C"
                                        : "#FF655C"
                                }
                                fill={
                                    bar.data.data.target <= bar.data.value
                                        ? higherIsBetter
                                            ? "#5BFA55"
                                            : "#FF655C"
                                        : "#FF655C"
                                }></rect>
                        </Tooltip>
                    )
                );
            } else return null;
        });
    };

    return (
        <div>
            <div
                className={classes.barChartElement}
                style={{
                    height: ElementHeight - titleExcess - descriptionExcess - 15,
                }}>
                <ResponsiveBar
                    layers={
                        ["grid", "axes", "bars", markerLayer, BarLegend, "annotations"] as any[]
                    }
                    maxValue={maxValue}
                    borderRadius={2}
                    data={data.chartData}
                    groupMode="grouped"
                    keys={data.keys}
                    indexBy="period"
                    margin={{ top: 50, right: 130, bottom: 50, left: 80 }}
                    innerPadding={3}
                    padding={0.2}
                    colors={colors}
                    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        renderTick: customBottomTick,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: `${t("analytics_Value")} ${data.isPercentage ? " %" : ""}`,
                        legendPosition: "middle",
                        legendOffset: -62,
                        format: (v: any) => (!data.isPercentage && v > 1 ? format("~s")(v) : v),
                    }}
                    label={d =>
                        !data.isPercentage
                            ? `${d.value &&
                                  d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}`
                            : `${d.value && d.value}`
                    }
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                    legends={[
                        {
                            dataFrom: "keys",
                            data:
                                data &&
                                data.keys
                                    .map((key: string, index: number) => {
                                        if (key !== null) {
                                            return {
                                                color: colors[index],
                                                id: key,
                                                label:
                                                    key && key.length > 20
                                                        ? t(key).slice(0, 19) + "..."
                                                        : t(key),
                                            };
                                        } else {
                                            return null;
                                        }
                                    })
                                    .filter((x: any) => x),
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: "top-to-bottom",
                            itemWidth: 80,
                            itemHeight: 40,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    animate={true}
                    defs={[
                        {
                            id: "gradientA",
                            type: "linearGradient",
                            colors: [
                                { offset: 0, color: "inherit" },
                                { offset: 100, color: "#54BBDE" },
                            ],
                        },
                        {
                            id: "gradientB",
                            type: "linearGradient",
                            colors: [
                                { offset: 0, color: "inherit" },
                                { offset: 100, color: "#4395B0" },
                            ],
                        },
                        {
                            id: "gradientC",
                            type: "linearGradient",
                            colors: [
                                { offset: 0, color: "inherit" },
                                { offset: 100, color: "#397F96" },
                            ],
                        },
                    ]}
                    fill={[
                        { match: { id: data.keys[0] }, id: "gradientA" },
                        { match: { id: data.keys[2] }, id: "gradientB" },
                        { match: { id: "Same Period Last Year" }, id: "gradientC" },
                    ]}
                />
            </div>
        </div>
    );
};

const BarLegend = ({ height, legends, width }: any) => (
    <>
        {legends &&
            legends.slice(0, 1).map(
                (legend: any, index: number) =>
                    legend.data !== undefined && (
                        <BoxLegendSvg
                            key={JSON.stringify(
                                legend.data.map(({ id }: any) => {
                                    return id;
                                })
                            )}
                            {...legend}
                            containerHeight={height}
                            containerWidth={width}
                        />
                    )
            )}
    </>
);
