import { NCRModalState, SHOW_NCRMODAL, HIDE_NCRMODAL, NCRModalActionTypes } from "./types";

const initialState: NCRModalState = {
    props: { visible: false, partnerId: "", currentRow: undefined, fromDate: "", toDate: "" },
};

export function ncrModalReducer(state = initialState, action: NCRModalActionTypes): NCRModalState {
    switch (action.type) {
        case SHOW_NCRMODAL:
            return {
                props: {
                    visible: true,
                    partnerId: action.payload.partnerId,
                    currentRow: action.payload.currentRow,
                    fromDate: action.payload.fromDate,
                    toDate: action.payload.toDate,
                },
            };
        case HIDE_NCRMODAL:
            return {
                props: {
                    visible: false,
                    partnerId: "",
                    currentRow: undefined,
                    fromDate: "",
                    toDate: "",
                },
            };
        default:
            return state;
    }
}
