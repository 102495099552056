import React, { FunctionComponent } from "react";
import { Table } from "antd";
import { useReviewRank } from "controller";
import classes from "./styles/surveyReviewTable.module.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

export const SurveyReviewTable: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();

    const { data } = useReviewRank(partnerId, range.from, range.to);
    if (!data) return null;

    const tableColumns = [
        {
            title: "",
            dataIndex: "Name",
            width: 130,
        },
        {
            title: t("supplierProfile_NumberOfReviews"),
            className: "Number of Reviews",
            dataIndex: "NumberOfReviews",
            width: 100,
        },
        {
            title: t("supplierProfile_AverageReviewScore"),
            dataIndex: "AverageReviewScore",
            width: 120,
            render: (v: number) => (v === 0 ? "N/A" : v),
        },
    ];

    return (
        <div
            data-test="survey_review_table_container"
            className={classes.surveyReviewTableContainer}>
            <strong>
                {t("supplierProfile_ReviewRankSentence", {
                    reviewRank: data.reviewRank,
                    numberOfPartners: data.numberOfPartners,
                })}
            </strong>
            <Table
                data-test="survey_review_table"
                className={classes.surveyReviewTable}
                columns={tableColumns}
                dataSource={[
                    {
                        key: "1",
                        Name: t("supplierProfile_SurveyedReviews"),
                        NumberOfReviews: data.surveyReviewCount,
                        AverageReviewScore: data.surveyReviewAvg,
                    },
                    {
                        key: "2",
                        Name: t("supplierProfile_NonSurveyedReviews"),
                        NumberOfReviews: data.nonSurveyReviewCount,
                        AverageReviewScore: data.nonSurveyReviewAvg,
                    },
                    {
                        key: "3",
                        Name: t("supplierProfile_TotalReviews"),
                        NumberOfReviews: data.totalNumberOfReviews,
                        AverageReviewScore: data.averageReviewScore,
                    },
                ]}
                size="middle"
                pagination={false}
                bordered
            />
        </div>
    );
};

export default SurveyReviewTable;
