import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useDevelopmentPlanByPartnerId = (suplierId: Number = 0) => {
    const { id: partnerId }: any = useParams();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.DevelopmentPlan.getDevelopmentPlanByPartnerId(
                partnerId ? partnerId : suplierId,
                {
                    customError: {
                        message: "Could not retrieve development plan",
                    },
                }
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ ...state, loading: false, data });
        })();
    }, [suplierId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDevelopmentPlanByPartnerId };
