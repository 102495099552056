import React, { forwardRef } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
const Option = Select.Option;

type PropsType = {
    onChange?: (value: string | string[]) => void;
    value?: string;
};

const ThreeStatusDropDown = forwardRef(({ onChange, value = "" }: PropsType, ref) => {
    const { t } = useTranslation();
    return (
        <Select
            value={value}
            onChange={(value: string) => onChange && onChange(value)}
            getPopupContainer={node => (node ? (node.parentNode as HTMLElement) : document.body)}
            style={{ minWidth: "150px" }}>
            <Option value="">{t("compliance_Blank")}</Option>
            <Option value="Yes">{t("generic_Yes")}</Option>
            <Option value="No">{t("generic_No")}</Option>
        </Select>
    );
});

export { ThreeStatusDropDown };
