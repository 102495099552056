import React, { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Button, Table, Card, Modal, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { useReviewCategory, useSurveyTemplatesByType } from "controller";
import { SurveyType } from "models";
import classes from "./styles/selectTemplate.module.scss";
import classnames from "classnames";
import { PureDiv } from "components/utilitycomponents";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";

type PropTypes = {
    visible: boolean;
    surveyType: SurveyType;
    clearData: any;
    onNext: Function;
    surveyTemplate: any;
    onTemplateSelect: Function;
};
const SelectTemplate: FunctionComponent<PropTypes> = ({
    visible,
    surveyType,
    clearData,
    onNext,
    surveyTemplate,
    onTemplateSelect,
}) => {
    const { t } = useTranslation();
    const { repeatSurveyId }: any = useParams();
    const [template, setTemplate] = useState<any>(undefined);
    const { data, loading } = useSurveyTemplatesByType(surveyType);
    const [datasource, setDatasource] = useState<any[]>([]);
    const [selectedReviewCriteriaIds, setSelectedReviewCriteriaIds] = useState<any[]>([]);
    const { data: reviewCriteria } = useReviewCategory();
    const { height } = useWindowDimensions();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [internalRespondentsChecked, setInternalRespondentsChecked] = useState<boolean>(true);
    const [externalRespondentsChecked, setExternalRespondentsChecked] = useState<boolean>(false);
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);

    useEffect(() => {
        setTemplate(undefined);
    }, [clearData]);

    const columns = [
        {
            title: <div className={classes.title}>{t("compliance_Template").toUpperCase()}</div>,
            key: "template",
            dataIndex: "name",
            render: (val: string) => <div className={classes.templateCell}>{val}</div>,
        },
        {
            title: <div className={classes.title}>{t("generic_Group").toUpperCase()}</div>,
            key: "group",
            dataIndex: "group",
            render: (val: string) => <div className={classes.groupCell}>{val}</div>,
        },
    ];

    useEffect(() => {
        if (data) {
            defineDatasource();
        }
    }, [data]);

    useEffect(() => {
        if (surveyTemplate !== null) {
            setSelectedReviewCriteriaIds(surveyTemplate.selectedReviewCriteriaIds);
            setTemplate(surveyTemplate.id);
        }
    }, [surveyTemplate]);

    const defineDatasource = () => {
        if (!loading && data) {
            let tempData: any[] = [];
            data.forEach((item: any) =>
                item.children.forEach((child: any) =>
                    tempData.push({ name: child.name, id: child.id, group: item.name })
                )
            );
            setDatasource(tempData);
        }
    };

    const createFlattenedReviewCriteria = (selectedIds: number[]) => {
        let filteredReviewCriteria: any = [];
        reviewCriteria.forEach((criteria: any) => {
            const hasSelectedChild =
                criteria.children &&
                selectedIds.some((id: number) =>
                    criteria.children.map((c: any) => c.id).includes(id)
                );
            const isSelectedItem = !criteria.children && selectedIds.includes(criteria.id);
            if (hasSelectedChild) {
                filteredReviewCriteria = [
                    ...filteredReviewCriteria,
                    {
                        id: criteria.id,
                        name: criteria.name,
                        children: criteria.children.filter((childCriteria: any) =>
                            selectedIds.includes(childCriteria.id)
                        ),
                    },
                ];
            }
            if (isSelectedItem) {
                filteredReviewCriteria = [
                    ...filteredReviewCriteria,
                    {
                        id: criteria.id,
                        name: criteria.name,
                        children: null,
                    },
                ];
            }
        });
        return flatten(filteredReviewCriteria);
    };

    const flatten = (list: any[]) => {
        let flatList: any = [];

        list.forEach(item => {
            if (item.children) {
                flatList = [
                    ...flatList,
                    { id: item.id, name: item.name, isGroup: true, parent: null },
                ];
                item.children.forEach((child: any) => {
                    flatList = [
                        ...flatList,
                        {
                            id: child.id,
                            name: child.name,
                            isGroup: false,
                            parent: item.id,
                        },
                    ];
                });
            } else {
                flatList = [
                    ...flatList,
                    { id: item.id, name: item.name, isGroup: false, parent: null },
                ];
            }
        });
        return flatList;
    };

    let questionCount = 1;

    return (
        <>
            <Row style={{ display: visible ? "block" : "none" }}>
                <Col span={24}>
                    <Row style={{ minHeight: `${height! - 350}px` }} gutter={32}>
                        <Col lg={16} md={12} sm={24}>
                            <Card
                                className={classes.tableContainer}
                                style={{ maxHeight: height! - 400 }}>
                                <Table
                                    className={classes.customTableStyle}
                                    loading={loading}
                                    columns={columns}
                                    bordered={false}
                                    dataSource={datasource}
                                    size="large"
                                    pagination={false}
                                    rowClassName={(record, index) =>
                                        template === record.id
                                            ? classes.highlighted
                                            : repeatSurveyId && repeatSurveyId !== record.id
                                            ? classes.disabledRow
                                            : classes.tableRow
                                    }
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                setTemplate(record.id);
                                                onTemplateSelect(record.id);
                                            },
                                        };
                                    }}
                                    scroll={{ y: height! - 500 }}
                                />
                            </Card>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                            <Card
                                className={classes.container}
                                style={{ maxHeight: height! - 400 }}>
                                <div className={classes.title} style={{ marginBottom: 12 }}>
                                    {t("generic_Sample").toUpperCase()}
                                </div>
                                {surveyTemplate && (
                                    <>
                                        <div className={classes.subTitle}>
                                            {t("admin_Template_Introduction")}
                                        </div>
                                        <PureDiv textContent={surveyTemplate.introduction} />
                                        <div className={classes.subTitle}>
                                            {t("generic_Questions")}
                                        </div>
                                        {createFlattenedReviewCriteria(
                                            selectedReviewCriteriaIds
                                        ).map((criteria: any) => {
                                            if (criteria.isGroup) {
                                                return (
                                                    <div
                                                        key={criteria.id}
                                                        className={classes.groupName}>
                                                        {criteria.name}
                                                    </div>
                                                );
                                            }
                                            if (criteria.parent !== null) {
                                                return (
                                                    <Row
                                                        key={criteria.name}
                                                        className={classnames(
                                                            "criteria",
                                                            classes.criteria
                                                        )}>
                                                        <Col lg={21}>
                                                            {`${questionCount++}. `}
                                                            {criteria.name}
                                                        </Col>
                                                    </Row>
                                                );
                                            } else {
                                                return (
                                                    <Row
                                                        className={classnames(
                                                            "criteria",
                                                            classes.criteria
                                                        )}
                                                        key={criteria.name}>
                                                        <Col lg={22}>
                                                            {`${questionCount++}. `}
                                                            {criteria.name}
                                                        </Col>
                                                    </Row>
                                                );
                                            }
                                        })}
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}></Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() =>
                                    surveyType === SurveyType.Performance ||
                                    surveyType === SurveyType.generic
                                        ? setToggleModal(true)
                                        : onNext(template)
                                }
                                disabled={!template}>
                                {t("generic_Next")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                title={t("survey_wizard_SelectRespondentTypes")}
                open={toggleModal}
                centered
                footer={[
                    <Button
                        size="large"
                        type="primary"
                        disabled={
                            internalRespondentsChecked === false &&
                            externalRespondentsChecked === false
                        }
                        onClick={() => {
                            setToggleModal(false);
                            onNext(
                                template,
                                internalRespondentsChecked,
                                externalRespondentsChecked
                            );
                        }}>
                        {t("generic_Next")}
                    </Button>,
                ]}
                mask={true}
                closable={false}>
                <Row gutter={16}>
                    <Col span={12} style={{ textAlign: "center" }}>
                        <Checkbox
                            value={internalRespondentsChecked}
                            checked={internalRespondentsChecked}
                            onChange={(e: any) => setInternalRespondentsChecked(e.target.checked)}
                            className={classes.typeCheckbox}>
                            {t("survey_wizard_RespondentInternal", {
                                companyName: companyName,
                                interpolation: { escapeValue: false },
                            })}
                        </Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox
                            value={externalRespondentsChecked}
                            checked={externalRespondentsChecked}
                            onChange={(e: any) => setExternalRespondentsChecked(e.target.checked)}
                            className={classes.typeCheckbox}>
                            {t("survey_wizard_RespondentExternal")}
                        </Checkbox>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default SelectTemplate;
