import React, { useState, useEffect, forwardRef } from "react";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";
const RangePicker = DatePicker.RangePicker;

type PropsType = {
    onChange?: (values: any[]) => void;
    initialValue: any[];
    [key: string]: any;
};

const PeriodPicker = forwardRef(({ onChange, initialValue, ...rest }: PropsType, ref) => {
    const [value, setValue] = useState<any>(initialValue);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    function handleChange(value: any) {
        setValue(value);
        onChange && onChange([value[0].utc(), value[1].utc()]);
    }
    return (
        <RangePicker
            data-test="date_period_picker"
            open={isOpen}
            onOpenChange={status => {
                if (status) {
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }
            }}
            format="MM-YYYY"
            value={value}
            onChange={handleChange}
            picker="month"
            allowClear={false}
            presets={[
                {
                    label: t("dateRange_last3Months"),
                    value: [
                        dayjs()
                            .subtract(3, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last6Months"),
                    value: [
                        dayjs()
                            .subtract(5, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_lastYear"),
                    value: [
                        dayjs()
                            .subtract(11, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last2Years"),
                    value: [
                        dayjs()
                            .subtract(23, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last3Years"),
                    value: [
                        dayjs()
                            .subtract(35, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last4Years"),
                    value: [
                        dayjs()
                            .subtract(47, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
            ]}
        />
    );
});

export { PeriodPicker };
