import React, { FunctionComponent, useState, useEffect } from "react";
import { Col, Row, Input, Form, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
//@ts-ignore
import ReactDragListView from "react-drag-listview";
import { useCustomDropDown } from "controller";
import {
    GridButton,
    ChevronSelectorVertical,
    PlusCircle,
    Trash02,
} from "components/utilitycomponents";
import ExtraFieldRowDescription from "./extraFieldDescription";
import classes from "./styles/extraFields.module.scss";
import classNames from "classnames";
const TextArea = Input.TextArea;

interface IProps {
    type: "SupplierFields" | "SurveyFields";
    data?: any[];
    onFieldKeysChange?: Function;
}

const ExtraFields: FunctionComponent<IProps> = ({ type, data = undefined, onFieldKeysChange }) => {
    const { t } = useTranslation();
    const [currentKey, setCurrentKey] = useState(-1);
    const [keys, setKeys] = useState<any>([]);
    const { data: customDropdowns, loading } = useCustomDropDown();

    useEffect(() => {
        if (data && data.length !== 0) {
            setCurrentKey(data.length - 1);
            const newKeys = [...Array(data.length).keys()];
            setKeys(newKeys);
            onFieldKeysChange && onFieldKeysChange(newKeys);
        } else {
            setCurrentKey(-1);
            setKeys([]);
            onFieldKeysChange && onFieldKeysChange([]);
        }
    }, []);

    function addRow() {
        if (keys.length < 10) {
            const newKeys = [...keys, currentKey + 1];
            setKeys(newKeys);
            onFieldKeysChange && onFieldKeysChange(newKeys);
            setCurrentKey(currentKey + 1);
        }
    }

    function removeRow(k: any) {
        const newKeys = keys.filter((key: any) => key !== k);
        setKeys(newKeys);
        onFieldKeysChange && onFieldKeysChange(newKeys);
    }

    const sectionDragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            const data = [...keys];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setKeys(data);
            onFieldKeysChange && onFieldKeysChange(data);
        },
        nodeSelector: ".extraFieldRow",
        handleSelector: ".drag",
    };

    return (
        <>
            <ReactDragListView {...sectionDragProps}>
                {keys.map((key: any, index: number) => {
                    return (
                        <Row
                            gutter={8}
                            key={key}
                            className={classNames(
                                "extraFieldRow",
                                classes.extraFieldRow,
                                index === 0 ? classes.first : ""
                            )}
                            align="middle">
                            {keys.length > 1 && (
                                <Col span={1}>
                                    <ChevronSelectorVertical
                                        className={classNames("drag", classes.dragIcon)}
                                    />
                                </Col>
                            )}
                            <Col span={7}>
                                <Form.Item
                                    name={[`textFieldTitle${type}`, key]}
                                    initialValue={
                                        data && data[key] && data[key].title
                                            ? data[key].title
                                            : undefined
                                    }>
                                    <TextArea
                                        autoSize
                                        maxLength={100}
                                        placeholder={t("survey_TextFieldTitlePlaceholder")}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={keys.length > 1 ? 15 : 16}>
                                <ExtraFieldRowDescription
                                    type={type}
                                    fieldKey={key}
                                    customDropdowns={{
                                        data: customDropdowns,
                                        loading: loading,
                                    }}
                                    data={data && data[key]}
                                />
                            </Col>
                            <Col span={1} style={{ textAlign: "right" }}>
                                <Popconfirm
                                    title={t("generic_RemoveConfirm", {
                                        title:
                                            type === "SurveyFields"
                                                ? t("survey_ExtraSurveyField").toLowerCase()
                                                : t("survey_ExtraSupplierField").toLowerCase(),
                                    })}
                                    onConfirm={() => removeRow(key)}
                                    okText={t("generic_Yes")}
                                    cancelText={t("generic_No")}
                                    getPopupContainer={() =>
                                        document.getElementById("contentSection") as HTMLElement
                                    }>
                                    <span className={classes.deleteIcon}>
                                        <Trash02 />
                                    </span>
                                </Popconfirm>
                            </Col>
                        </Row>
                    );
                })}
            </ReactDragListView>
            {keys.length < 10 && (
                <GridButton
                    data-test={
                        type === "SurveyFields"
                            ? "add_survey_field_button"
                            : "add_supplier_field_button"
                    }
                    className={classes.addButton}
                    icon={<PlusCircle />}
                    onClick={addRow}>
                    {type === "SurveyFields"
                        ? `${t("generic_Add")} ${t("survey_ExtraSurveyField")}`
                        : `${t("generic_Add")} ${t("survey_ExtraSupplierField")}`}
                </GridButton>
            )}
        </>
    );
};

export default ExtraFields;
