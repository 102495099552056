import { get, post, remove, patch } from "../base";

const File = {
    getFile: async (fileId: number, { customError, onDownloadProgress }: any = {}) => {
        const url = `/v1/File/GetFile?fileId=${fileId}`;
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        return await get({
            url,
            responseType: "blob",
            customError,
            customConfig,
            onDownloadProgress,
        });
    },
    uploadFile: async (
        data: any,
        cancelToken: any,
        onUploadProgress: Function,
        folderName?: string,
        title?: string,
        partnerId?: number,
        { customError }: any = {}
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        let url = `/v1/File/UploadFile`;
        if (title || partnerId) {
            url = url + `?`;
        }
        if (title) {
            url = url + `&Title=${title}`;
        }
        if (partnerId) {
            url = url + `&PartnerId=${partnerId}`;
        }
        if (folderName) {
            if (title || partnerId) {
                url = url + `&folderName=${folderName}`;
            } else {
                url = url + `?folderName=${folderName}`;
            }
        }
        return await post({
            url,
            payload: data,
            cancelToken,
            onUploadProgress,
            customError,
            customConfig,
        });
    },
    getProfileImage: async (fileId: number, { customError }: any = {}) => {
        const url = `/v1/File/GetFile?fileId=${fileId}`;
        const customConfig = {
            headers: {
                Accept: "image/*",
                "Content-Type": "application/json-patch+json",
                "Cache-Control": "public",
                Pragma: "cache",
            },
        };
        return await get({ url, responseType: "blob", customError, customConfig });
    },
    getFileDetail: async (id: any, { customError }: any = {}) => {
        const url = `/v1/File/GetFileDetailById?id=${id}`;
        return await get({ url, customError });
    },
    updateFile: async (fileData: any, { customError }: any = {}) => {
        const url = `/v1/File/Edit`;

        return await post({ url, payload: fileData, customError });
    },
    uploadFileToFolder: async (
        folderId: number,
        data: any,
        cancelToken: any,
        onUploadProgress: Function,
        { customError }: any = {}
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/File/UploadFileByFolderId?folderId=${folderId}`;
        return await post({
            url,
            payload: data,
            cancelToken,
            onUploadProgress,
            customError,
            customConfig,
        });
    },
    isFileNameInUse: async (folderId: number, name: string, { customError }: any = {}) => {
        const url = `/v1/File/isFileNameInUse?folderId=${folderId}&fileName=${name}`;
        return await get({ url, customError });
    },
    removeFile: async (fileIds: number[], { customError }: any = {}) => {
        const url = `/v1/File/DeleteFiles`;
        await remove({ url, payload: fileIds });
    },
    updateFileTitle: async (fileId: number, title: string, { customError }: any = {}) => {
        const url = `/v1/File/UpdateFileTitle?fileId=${fileId}&title=${title}`;
        await patch({ url, payload: {}, customError });
    },
};

export default File;
