import React, { useState, useEffect } from "react";
import { Modal, Tag, Input, Alert, Collapse, notification, Form, Button } from "antd";
import IMasterDataPartner from "models/suppliers/IMasterDataPartner";
import { useTranslation } from "react-i18next";
import { useCompanyType, usePartnerDelete } from "controller";

const Panel = Collapse.Panel;

interface ModalProps {
    visible: boolean;
    setDeleteModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    clearSelectedRows(): void;
    updateTable(): void;
}

export const ContentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [confirmationText, setConfirmationText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const { remove, loading } = usePartnerDelete();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        if (confirmationText.toLowerCase() === "delete") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [confirmationText]);

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: any) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onCancel() {
        setConfirmationText("");
        form.resetFields();
        props.setDeleteModalVisibility(false);
    }

    async function onSubmit() {
        await form.validateFields();
        let partnerIds: number[] = [];
        props.selectedRows.forEach(row => {
            partnerIds.push(row.id.value);
        });
        await remove(partnerIds);
        notification.success({
            message: t("success"),
            description: t("supplier_DeleteSupplierSuccess", {
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier")
                    : t("company_Type_Customer"),
            }),
        });
        props.setDeleteModalVisibility(false);
        setConfirmationText("");
        form.resetFields();

        props.clearSelectedRows();
        props.updateTable();
    }

    return (
        <div>
            <Modal
                wrapProps={{ "data-test": "delete_supplier_modal" }}
                title={t("supplier_DeleteSupplierTitle", {
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier")
                        : t("company_Type_Customer"),
                })}
                open={props.visible}
                onOk={() => onSubmit()}
                onCancel={() => onCancel()}
                footer={[
                    <Button
                        disabled={disabled}
                        loading={loading}
                        type="primary"
                        danger
                        onClick={async () => await onSubmit()}>
                        {t("generic_Confirm")}
                    </Button>,
                ]}>
                <Form
                    layout="vertical"
                    labelAlign="left"
                    labelWrap
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <div className="gridTagLabel">
                        {t("supplier_SelectedSuppliers", {
                            companyType: isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer"),
                        })}
                        :
                    </div>
                    <div className="gridTagArea">
                        {props.selectedRows.length === 1 && (
                            <div className="tagArea">{renderTags(false)}</div>
                        )}
                        {props.selectedRows.length > 1 && (
                            <div className="tagArea">{renderTags(true)}</div>
                        )}
                    </div>
                    <Form.Item
                        name="delete"
                        label={t("supplier_DeleteSupplierLabel", {
                            companyType: isDefaultCompany
                                ? t("company_Type_Supplier").toLowerCase()
                                : t("company_Type_Customer").toLowerCase(),
                        })}>
                        <Input
                            id="deleteInput"
                            placeholder={t("supplier_DeleteSupplierPlaceholder", {
                                companyType: isDefaultCompany
                                    ? t("company_Type_Supplier").toLowerCase()
                                    : t("company_Type_Customer").toLowerCase(),
                            })}
                            onChange={(e: any) => setConfirmationText(e.target.value)}
                            value={confirmationText}
                            autoFocus
                        />
                    </Form.Item>
                    <Form.Item>
                        <Collapse className="collapse">
                            <Panel header={t("deleteSupplierWarningTitle")} key="1">
                                <Alert
                                    type={"error"}
                                    message={t("supplier_DeleteSupplierWarningText", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier").toLowerCase()
                                            : t("company_Type_Customer").toLowerCase(),
                                    })}
                                />
                            </Panel>
                        </Collapse>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
const ModalDelete = ContentModal;

export default ModalDelete;
