import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useNcrActionOwners = (initialContactId: string, ncr: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (initialContactId !== undefined) {
                let company: any = undefined;
                let partner: any = undefined;
                if (initialContactId.indexOf("companyId:") > -1) {
                    company = await Provider.Company.getCompanyById(
                        parseInt(initialContactId.replace("companyId:", ""))
                    );
                } else if (initialContactId.indexOf("partnerId:") > -1) {
                    partner = await Provider.Partner.getPartner(
                        parseInt(initialContactId.replace("partnerId:", ""))
                    );
                }
                let data = await Provider.Ncr.getNcrOwnerContacts(initialContactId);
                data = data.map((contact: IContact) => ({
                    ...contact,
                    id: contact.id.toString(),
                    name: `${contact.firstName} ${contact.lastName}`,
                }));

                if (company !== undefined) {
                    data.push({
                        id: `companyId:${company.id}`,
                        name: company.companyName,
                    });
                }
                if (partner !== undefined) {
                    data.push({
                        id: `partnerId:${company.id}`,
                        name: company.companyName,
                    });
                }

                setState({ loading: false, data });
            }
        })();
    }, [initialContactId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNcrActionOwners };
