import { useState } from "react";
import Provider from "services/api";

const useAdminExport = () => {
    const [loading, setLoading] = useState(false);

    const update = async (id: number, hasAccess: boolean) => {
        setLoading(true);
        await Provider.Company.updateExportPrivilegeOption(id, hasAccess, {
            customError: {
                message: "Could not update the export option",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        update: update,
    };
};

export { useAdminExport };
