import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message } from "antd";
import { ButtonedInput } from "components/utilitycomponents";
import { getDomainUrl } from "utilities";
import classes from "./styles/surveyUrlModal.module.scss";

type PropsType = {
    visible: boolean;
    onClose: Function;
    urlData?: {
        isVoiceOfSupplier: boolean;
        externalPartner: string | undefined;
        url: string;
    }[];
    clickedRow: any;
};

const UrlLinkModal: FunctionComponent<PropsType> = ({ urlData, visible, onClose, clickedRow }) => {
    const { t } = useTranslation();

    const handleCopyUrl = (elementId: string) => {
        var copyText = document.getElementById(elementId) as HTMLInputElement;
        if (copyText) {
            copyText.select();
            document.execCommand("copy");
            message.success(t("copied"));
        }
    };

    const renderVoiceOfSupplierLinks = () => {
        return (
            urlData &&
            urlData.map((data: any, index) => {
                if (data.isVoiceOfSupplier) {
                    return (
                        <div className={classes.buttunInput}>
                            <h4>{data.externalPartner}</h4>
                            <ButtonedInput
                                value={`${getDomainUrl()}/Reviews/Survey/${data.url}`}
                                buttonText={t("copyUrl")}
                                inputId={`survey_modal_url_${index}`}
                                onClick={() => handleCopyUrl(`survey_modal_url_${index}`)}
                            />
                        </div>
                    );
                } else {
                    return [];
                }
            })
        );
    };

    return (
        <Fragment>
            {visible && (
                <Modal
                    title={t("survey_UrlLinkModalTitle")}
                    open={visible}
                    onCancel={() => onClose()}
                    footer={null}>
                    <Fragment>
                        <h2>
                            {!clickedRow.isVoiceOfSupplier
                                ? t("survey_Survey", "Survey")
                                : t("survey_VoiceOfSupplier")}
                        </h2>
                        {urlData && urlData.length === 1 && (
                            <h4>{urlData && urlData[0].externalPartner}</h4>
                        )}
                        <div className={classes.buttunInput}>
                            <ButtonedInput
                                value={`${getDomainUrl()}/Reviews/Survey/${urlData &&
                                    urlData[0].url}`}
                                buttonText={t("copyUrl")}
                                inputId="survey_modal_url"
                                onClick={() => handleCopyUrl("survey_modal_url")}
                            />
                        </div>

                        {!clickedRow.isVoiceOfSupplier &&
                            urlData &&
                            urlData.some(x => x.isVoiceOfSupplier === true) && (
                                <h2>{t("survey_VoiceOfSupplier")}</h2>
                            )}
                        {!clickedRow.isVoiceOfSupplier && renderVoiceOfSupplierLinks()}
                    </Fragment>
                </Modal>
            )}
        </Fragment>
    );
};

export default UrlLinkModal;
