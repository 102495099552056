const getPageKeyByPageName = (pageName: string) => {
    switch (pageName) {
        case "SummaryPage":
            return "1";
        case "SegmentationManagement":
            return "2";
        case "PerformanceManagement":
            return "3";
        case "Governance":
            return "4";
        case "Risk":
            return "6";
        case "StrategyAndGovernance":
            return "7";
        case "PerformanceOverview":
            return "8";
        case "RiskOverview":
            return "9";
        case "MasterData":
            return "10";
        case "Analytics":
            return "11";
        case "Compliance":
            return "12";
        case "Actions":
            return "13";
        case "Products":
            return "14";
        case "Scorecard":
            return "15";
        default:
            return undefined;
    }
};

export { getPageKeyByPageName };
