import { get, put } from "../base";
import { IColumnSetting } from "models";

const ListView = {
    getListView: async (viewId: number) => {
        const url = `/v1/ListView/${viewId}`;
        return await get({ url });
    },
    updateListView: async (viewId: number, viewColumnSetting: IColumnSetting[]) => {
        viewColumnSetting = viewColumnSetting.map((v: any) => ({
            ...v,
            viewColumnId: v.viewColumnId.toString(),
        }));
        const url = `/v1/ListView/${viewId}`;
        return await put({ url, payload: viewColumnSetting });
    },
    SetViewColumnSettingsForAllUsers: async (
        viewId: number,
        viewColumnSetting: IColumnSetting[]
    ) => {
        viewColumnSetting = viewColumnSetting.map((v: any) => ({
            ...v,
            viewColumnId: v.viewColumnId.toString(),
        }));
        const url = `/v1/ListView/SetViewColumnSettingsForAllUsers/${viewId}`;
        return await put({ url, payload: viewColumnSetting });
    },
};

export default ListView;
