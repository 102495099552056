import React, { useState, useEffect } from "react";
import classes from "./performanceChart.module.scss";
import { ResponsiveBar } from "@nivo/bar";
import { usePerformanceChart } from "controller";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    input: any[];
    selectedYear: any;
    partnerId: number;
}

const PerformanceChart = ({ input = [], selectedYear = dayjs().year(), partnerId }: IProps) => {
    const { t } = useTranslation();
    const [year, setYear] = useState<any>(undefined);

    const { data: performanceChart, loading } = usePerformanceChart(partnerId, year, input);

    useEffect(() => {
        setYear(selectedYear);
    }, [selectedYear]);

    const translateId = (id: string) => {
        switch (id) {
            case "parentLevelAvg":
                return t("unitOfAnalysis_ParentLevel");
            case "performanceAvgOne":
                return t("unitOfAnalysis_EntityLevel");
            case "performanceAvgTwo":
                return t("unitOfAnalysis_EntityLevel2");

            default:
                return id;
        }
    };

    return (
        <Spin spinning={loading}>
            <div className={classes.chartContainer}>
                <div className={classes.chartTitle}>
                    {t("supplierProfile_Enterprise_PerfomanceChartTitle")}
                </div>
                <div className={classes.legendContainer}>
                    <span className={classes.legendItem}>
                        <div
                            className={classes.square}
                            style={{ backgroundColor: "#4D4D4D" }}></div>
                        {t("unitOfAnalysis_ParentLevel")}
                    </span>
                    <span className={classes.legendItem}>
                        <span
                            className={classes.square}
                            style={{ backgroundColor: "#1378A7" }}></span>
                        {t("unitOfAnalysis_EntityLevel")}
                    </span>
                    <span className={classes.legendItem}>
                        <span
                            className={classes.square}
                            style={{ backgroundColor: "#FFA829" }}></span>
                        {t("unitOfAnalysis_EntityLevel2")}
                    </span>
                </div>

                <ResponsiveBar
                    data={performanceChart && performanceChart.data ? performanceChart.data : []}
                    keys={["parentLevelAvg", "performanceAvgOne", "performanceAvgTwo"]}
                    groupMode="grouped"
                    indexBy="criteriaGroup"
                    margin={{ top: 70, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    minValue={0}
                    maxValue={5}
                    indexScale={{ type: "band", round: false }}
                    colors={["#4D4D4D", "#1378A7", "#FFA829"]}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 7,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: "color",
                        modifiers: [["brighter", 3]],
                    }}
                    valueFormat={(data: any) => data && data.toPrecision(2)}
                    tooltip={(data: any) => {
                        return (
                            <div className={classes.tooltip}>
                                {translateId(data.id)} : {data.value && data.value.toPrecision(2)}
                            </div>
                        );
                    }}
                />
            </div>
        </Spin>
    );
};

export { PerformanceChart };
