import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import classnames from "classnames";
import { useRatingDistribution } from "controller";
import classes from "./styles/ratingDistributionPieChart.module.scss";
import { ChartToolTipContainer, LoadingIndicator } from "components/utilitycomponents";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

export const RatingDistributionPieChart: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();
    const { loading, data } = useRatingDistribution(partnerId, range.from, range.to);

    if (loading) return <LoadingIndicator />;
    if (!data || data.length === 0) return null;

    let totalCount = 0;
    data.forEach(r => {
        totalCount += r.count;
    });
    const graphData = data.map(r => {
        const starText = r.rating > 1 ? t("StarPlural") : t("Star");
        return {
            id: `${r.rating.toString()} ${starText}`,
            value: r.count,
        };
    });

    return (
        <Fragment>
            <div data-test="rating_distribution_pie_chart_title">
                <b>{t("supplierProfile_RatingDistribution")}</b>
            </div>
            <div
                data-test="rating_distribution_pie_chart"
                className={classnames(classes.ratingDistributionChart, "ratingDistributionChart")}>
                <ResponsivePie
                    data={graphData as any[]}
                    margin={{ top: 5, right: 30, bottom: 50, left: 30 }}
                    colors={["#b20000", "#bf4b00", "#f99406", "#b1bf0a", "#00b204"]}
                    arcLabel={d => getPercent(d.value, totalCount) + "%"}
                    enableArcLinkLabels={false}
                    arcLabelsSkipAngle={30}
                    arcLabelsTextColor="#fff"
                    innerRadius={0}
                    padAngle={0}
                    cornerRadius={0}
                    legends={[
                        {
                            anchor: "left",
                            direction: "column",
                            itemWidth: 55,
                            itemHeight: 20,
                            translateX: -30,
                            itemTextColor: "#000",
                            symbolSize: 15,
                            symbolShape: "circle",
                        },
                    ]}
                    tooltip={({ datum }: any) => {
                        return (
                            <ChartToolTipContainer>
                                <div>
                                    <b>{datum.id}</b>
                                </div>
                                <div>
                                    <b>
                                        {datum.value} ({getPercent(datum.value, totalCount)} %)
                                    </b>
                                </div>
                            </ChartToolTipContainer>
                        );
                    }}
                />
            </div>
            <div
                className={classnames(
                    classes.ratingDistributionChartPrint,
                    "ratingDistributionChartPrint"
                )}></div>
        </Fragment>
    );
};

const getPercent = (value: number, total: number) => ((value * 100) / total).toFixed(1);

export default RatingDistributionPieChart;
