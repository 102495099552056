import { ICompanyInformation } from "models/admin/company/ICompanyInformation";
import ICompanyRelation from "models/admin/company/ICompanyRelation";
import { useState } from "react";
import { Provider } from "services";

const useCompanyInformationUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateCompanyInformation = async (companyId: string, data: any) => {
        setLoading(true);
        let newCompanyInformation: ICompanyInformation = { owners: [], subsidiaries: [] };

        newCompanyInformation.companyName = data.companyName;
        newCompanyInformation.address = data.address;
        newCompanyInformation.zipCode = data.zipCode;
        newCompanyInformation.city = data.city;
        newCompanyInformation.state = data.state;
        newCompanyInformation.countryId = data.countryId;
        newCompanyInformation.vatCode = data.vatCode;
        newCompanyInformation.dunsNumber = parseInt(data.dunsNumber);
        newCompanyInformation.phoneNumber = data.phoneNumber;
        newCompanyInformation.webSite = data.webSite;
        newCompanyInformation.employeeNumber = data.employeeNumber;
        newCompanyInformation.yearlyTurnover = data.yearlyTurnover;
        newCompanyInformation.ceo = data.ceo;
        newCompanyInformation.supportEmail = data.supportEmail;
        newCompanyInformation.menuColor = data.menuColor;
        newCompanyInformation.menuTextColor = data.menuTextColor;
        newCompanyInformation.faqLink = data.faqLink;

        let owners: ICompanyRelation[] = [];
        if (data.ownerCompanyName) {
            for (let i = 0; i < data.ownerCompanyName.length; i++) {
                if (data.ownerCompanyName[i]) {
                    owners.push({
                        id: data.ownerId[i],
                        companyId: parseInt(companyId),
                        companyName: data.ownerCompanyName[i] && data.ownerCompanyName[i],
                        countryId: data.ownerCountry[i] && data.ownerCountry[i],
                        dunsNumber: data.ownerDunsNo[i] && parseInt(data.ownerDunsNo[i]),
                        vatCode: data.ownerVatCode[i] && data.ownerVatCode[i],
                        relationType: 1,
                    });
                }
            }
        }
        newCompanyInformation.owners = owners;

        let subsidiaries: ICompanyRelation[] = [];
        if (data.subsidiaryCompanyName) {
            for (let i = 0; i < data.subsidiaryCompanyName.length; i++) {
                if (data.subsidiaryCompanyName[i]) {
                    subsidiaries.push({
                        id: data.subsidiaryId[i],
                        companyId: parseInt(companyId),
                        companyName: data.subsidiaryCompanyName[i] && data.subsidiaryCompanyName[i],
                        countryId: data.subsidiaryCountry[i] && data.subsidiaryCountry[i],
                        dunsNumber: data.subsidiaryDunsNo[i] && parseInt(data.subsidiaryDunsNo[i]),
                        vatCode: data.subsidiaryVatCode[i] && data.subsidiaryVatCode[i],
                        relationType: 2,
                    });
                }
            }
        }
        newCompanyInformation.subsidiaries = subsidiaries;
        await Provider.Company.updateCompanyInformation(
            parseInt(companyId),
            newCompanyInformation,
            {
                customError: {
                    message: "Could not update company information",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updateCompanyInformation,
    };
};

export { useCompanyInformationUpdate };
