import { useEffect, useState } from "react";
import { Provider } from "services";
import { GraphType, IReviewCategoryChart } from "models";

type StateType = {
    data: IReviewCategoryChart[] | null;
    loading: boolean;
};

const useReviewCategoryChart = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            const data = await Provider.Partner.getReviewCategory(
                partnerId,
                from,
                to,
                GraphType.BarChart,
                {
                    customError: {
                        message: "Could not retrieve data for review category",
                    },
                }
            ).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewCategoryChart };
