import Config from "../../config";

export function hasUserTypeAccess(userType: string, moduleName: string, hasNcrFeature: boolean) {
    if (moduleName === "NCR" && !hasNcrFeature) {
        return false;
    }
    if (userType === Config.adminUser || userType === Config.normalUser) {
        return true;
    } else if (
        userType === Config.lightUser &&
        Config.lightUserModuleAccess.filter(
            (m: string) => m.toLowerCase() === moduleName.toLowerCase()
        ).length > 0
    ) {
        return true;
    }
    return false;
}
