import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useInternalAndExternalContacts } from "controller";
import { SelectDataMultiple } from "../selectDataMultiple";
import { SelectData } from "../selectData";

type PropsType = {
    value?: any;
    supplierId?: any;
    onChange?: (value?: any, item?: any) => void;
    reload?: any;
    mode: "single" | "multiple";
    [key: string]: any;
};

const SelectExternalContacts = forwardRef(
    (
        {
            value,
            supplierId = undefined,
            onChange,
            reload = { reload: false },
            mode = "multiple",
            ...rest
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();

        return (
            <>
                {mode === "multiple" ? (
                    <SelectDataMultiple
                        value={value}
                        onChange={onChange}
                        useData={useInternalAndExternalContacts.bind(null, supplierId, reload)}
                        selectOptionField="name"
                        groupNames={[t("action_Internal-Contacts"), t("action_External-Contacts")]}
                        groupFilter={(item: any, groupName: string) => {
                            if (groupName === t("action_Internal-Contacts"))
                                return item.externalUser === false;
                            if (groupName === t("action_External-Contacts"))
                                return item.externalUser === true;
                        }}
                        placeholder={t("select")}
                        {...rest}
                    />
                ) : (
                    <SelectData
                        value={value}
                        onChange={onChange}
                        useData={useInternalAndExternalContacts.bind(null, supplierId, reload)}
                        selectOptionField="name"
                        groupNames={[t("action_Internal-Contacts"), t("action_External-Contacts")]}
                        groupFilter={(item: any, groupName: string) => {
                            if (groupName === t("action_Internal-Contacts"))
                                return item.externalUser === false;
                            if (groupName === t("action_External-Contacts"))
                                return item.externalUser === true;
                        }}
                        placeholder={t("select")}
                        {...rest}
                    />
                )}
            </>
        );
    }
);

export { SelectExternalContacts };
