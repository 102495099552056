import React, { useState } from "react";
import { Badge, Popover } from "antd";
import classes from "./actionDropdown.module.scss";
import { useActionsOverdueCount } from "controller";
import OverdueActionsTable from "./overdueActionsTable";

import { AlertTriangle } from "components/utilitycomponents";

const ActionsDropdown = () => {
    const [reloadCount, setReloadCount] = useState({ reload: false });
    const { data: count } = useActionsOverdueCount(reloadCount);

    return (
        <Popover
            overlayClassName={classes.actionDropDown}
            placement="bottomRight"
            content={
                <div>
                    <OverdueActionsTable
                        closeDropdown={() => {}}
                        reloadCount={() => setReloadCount({ reload: true })}
                    />
                </div>
            }
            trigger="click">
            <div>
                <Badge count={count}>
                    <AlertTriangle style={{ fontSize: 24 }} className="topbarIcon" />
                </Badge>
            </div>
        </Popover>
    );
};
export { ActionsDropdown };
