import { useState } from "react";
import { Provider } from "services";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const useGateKeeperCreate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const create = async (payload: any) => {
        setLoading(true);
        let data = await Provider.Compliance.createGateKeeper(payload, {
            customError: {
                message: "Could not create gatekeeper",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        if (data === false) {
            notification.error({
                message: t("systemMessage"),
                description: t("admin_GateKeeper_DuplicateError"),
            });
            setLoading(false);
            return Promise.reject();
        }
        setLoading(false);
        return data;
    };

    return {
        loading,
        create,
    };
};

export { useGateKeeperCreate };
