function getCategoryStarText(rating: number, criteriaItem: any) {
    if (criteriaItem) {
        switch (rating) {
            case 1:
                return criteriaItem.firstStarText
                    ? criteriaItem.firstStarText
                    : "1 star: Very low - unacceptable experience. Immediate change is needed.";
            case 2:
                return criteriaItem.secondStarText
                    ? criteriaItem.secondStarText
                    : "2 stars: Low - a bad experience.";
            case 3:
                return criteriaItem.thirdStarText
                    ? criteriaItem.thirdStarText
                    : "3 stars: Medium - an acceptable experience.";
            case 4:
                return criteriaItem.fourthStarText
                    ? criteriaItem.fourthStarText
                    : "4 stars: Very good - a positive experience.";
            case 5:
                return criteriaItem.fifthStarText
                    ? criteriaItem.fifthStarText
                    : "5 stars: Excellent - an outstanding experience. An example to be followed.";

            default:
                break;
        }
    } else {
        return "";
    }
}

export { getCategoryStarText };
