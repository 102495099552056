import { useEffect, useState } from "react";

const useDatatable = (pageSize: number, pageNumber: number, sorts: any, reloadData: any) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        columns: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
            columns: state.columns,
        });

        (async () => {
            const columns = [
                { title: "Company Name", dataIndex: "companyName", key: "companyName", width: 100 },
                { title: "Month", dataIndex: "month", key: "month", width: 100 },
                {
                    title: "Sub Level",
                    dataIndex: "subLevel",
                    key: "subLevel",
                    width: 100,
                },
                // { title: "Promise Date", dataIndex: "promiseDate", key: "promiseDate", width: 100 },
                // { title: "Receipt Date", dataIndex: "receiptDate", key: "receiptDate", width: 100 },
                { title: "KPI Value", dataIndex: "kpiValue", key: "kpiValue", width: 100 },
                { title: "Target Value", dataIndex: "targetValue", key: "targetValue", width: 100 },
                {
                    title: "All External Partners Value",
                    dataIndex: "allExternalPartnersValue",
                    key: "allExternalPartnersValue",
                    width: 100,
                },
                {
                    title: "Visibility",
                    dataIndex: "visibility",
                    key: "visibility",
                    width: 100,
                    render: (value: any) => (value === 0 ? "Internal" : "External"),
                },
            ];

            const data: any[] = [];
            for (let i = 0; i < 200; i++) {
                data.push({
                    id: i,
                    companyName: `Supplier ${i}`,
                    month: `Month ${i}`,
                    purchaseOrder: `EAS1000${i}`,
                    promiseDate: `30-11-200${i}`,
                    receiptDate: `12-03-200${i}`,
                    kpiValue: 1 * i,
                    targetValue: i + 100,
                    externalPartnerValue: 10000 + i,
                    visibility: "internal",
                });
            }

            setState({
                loading: false,
                data: data,
                totalRows: data.length,
                columns: columns,
            });
        })();
    }, [pageSize, pageNumber, sorts, reloadData]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        columns: state.columns,
    };
};

export { useDatatable };
