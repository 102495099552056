import { useEffect, useState } from "react";
import { IContact, Modules } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

export const useCompanyContactsWithAccess = (
    userTypes: string[] = ["Administrator", "User"],
    accessTo: Modules,
    justUser: boolean = false,
    reload: any
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data = await Provider.User.getCompanyContactsWithModuleAccess(
                userTypes,
                accessTo,
                justUser
            );
            data = data.map((contact: IContact) => ({
                ...contact,
                name: `${contact.firstName} ${contact.lastName}`,
            }));
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useCompanyContactsWithAccess;
