import { useEffect, useState } from "react";
import Provider from "services/api";

const useTemplateGroupsOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            await getData();
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range]);

    const updateData = (newData: any) => {
        setState({ ...state, data: newData });
    };

    const updateRowOrder = async (oldOrder: number, newOrder: number, id: number) => {
        await Provider.Compliance.updateTemplateGroupsOrder(id, oldOrder, newOrder, {
            customError: {
                message: "Could not update the order of template groups",
            },
        }).catch((err: any) => {
            return Promise.reject(err);
        });
        setState({
            ...state,
            loading: true,
        });
        await getData();
    };

    const getData = async () => {
        if (!externalLoading) {
            const data = await Provider.Compliance.getTemplateGroupOverview(
                {
                    pageSize,
                    pageNumber,
                    filters,
                    sorts,
                    to: range.to,
                    from: range.from,
                },
                {
                    customError: {
                        message: "Could not retrieve list of template groups",
                    },
                }
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data: data.data,
                totalRows: data.totalNumberOfRecords,
            });
        }
    };

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        updateData,
        updateRowOrder,
    };
};

export { useTemplateGroupsOverview };
