import { IContact } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useInternalAndExternalContacts = (supplierId: any, reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Contact.GetInternalAndExternalContacts(supplierId, {
                retryCount: 3,
                customerError: "Could not retrieve contacts",
            }).catch(err => {
                setState({ ...data, loading: false });
                return Promise.reject(err);
            });
            data = data.map((contact: any) => ({
                ...contact,
                name: contact.fullName,
            }));
            setState({ loading: false, data });
            return data;
        })();
    }, [supplierId, reload]);
    return { loading: state.loading, data: state.data };
};

export { useInternalAndExternalContacts };
