import React, { FunctionComponent, useState } from "react";
import { Spin, Tabs } from "antd";
import { PageContainer, PbiEmbedReport } from "components/utilitycomponents";
import EmbeddedReports from "./tabs/embeddedReport";
import classes from "./reporting.module.scss";
import { useCompanySettings, useEmbeddedReportSettings } from "controller";
import { useParams, useHistory } from "react-router-dom";
import { Environment, IReportSettings } from "models";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getEnvironmentInfo } from "utilities";
import { RocheSegmentationReport } from "./tabs/segmentation/rocheSegmentationReport";

const Reporting: FunctionComponent = () => {
    const { loading: loadingCompanySettings } = useCompanySettings();
    const { tab }: any = useParams();
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(tab);
    const [reload] = useState<any>({ reload: false });
    const { data: embeddedReports } = useEmbeddedReportSettings(reload);
    const user = useSelector((state: AppState) => state.oidc.user);
    const currentCompanyId = user.profile["ll-CompanyId"];
    const showInDevelopmentFeatures: boolean =
        user.profile["ll-ShowInDevelopmentFeatures"] === "True";

    if (loadingCompanySettings) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    const handleTabChange = (e: any) => {
        history.push(`/Reporting/${e}`);
        setActiveTab(e);
    };

    return (
        <>
            <PageContainer fluid style={{ marginLeft: 10, marginRight: 10 }}>
                <Tabs
                    className={classes.tabs}
                    defaultActiveKey={activeTab}
                    onChange={(tabKey: any) => handleTabChange(tabKey)}>
                    {embeddedReports.map((report: IReportSettings) => (
                        <Tabs.TabPane tab={report.headline} key={`${report.headline}${report.id}`}>
                            {!report.isPbiReport ? (
                                <EmbeddedReports data={report} />
                            ) : (
                                <PbiEmbedReport
                                    workspaceId={report.powerBiWorkspaceGuid}
                                    reportId={report.powerBiReportGuid}
                                />
                            )}
                        </Tabs.TabPane>
                    ))}

                    {((currentCompanyId === "4439" && showInDevelopmentFeatures) ||
                        getEnvironmentInfo().type === Environment.Local ||
                        getEnvironmentInfo().type === Environment.Staging) && (
                        <Tabs.TabPane tab="Segmentation Responses" key="segmentationResponses">
                            <RocheSegmentationReport />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </PageContainer>
        </>
    );
};

export default Reporting;
