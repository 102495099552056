export const API_ERROR = "API_ERROR";

export interface ApiErrorType {
    title: string | null;
    message: string;
}

export interface ApiStateType {
    apiError: ApiErrorType;
}
