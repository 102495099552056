import React from "react";
import { Row, Col } from "antd";
import { DevelopmentPlanSummary } from "./developmentPlanSummary/developmentPlanSummary";
import { useEnabledSupplierProfilePages, useOwlerFeature } from "controller";
import classes from "../summary.module.scss";
import classNames from "classnames";
import { ISupplierProfilePage } from "models";
import { PartnerCommentFeed } from "./partnerComment/partnerComment";
import { Categories } from "./category/categories";
import { GuageMetrics } from "./metrics/gaugeMetrics";
import { PerformanceMetrics } from "./metrics/performanceMetrics";
import { RapidReview } from "./rapidReview/rapidReview";
import { MeetingSummary } from "./meetingSummary/meetingSummary";
import { checkForActive } from "../../shared/checkActivePage";
import { ReviewCategoryMetric } from "./metrics/reviewCategoryMetric";
import { OwlerContainer } from "./owler/owlerContainer";
import { ScorecardStatusWidget } from "../../../scorecard/widget/scorecardStatusWidget";

interface IProps {
    supplierData: any;
    partnerId: number;
}

const SupplierSummary = ({ supplierData, partnerId }: IProps) => {
    const { data: pages } = useEnabledSupplierProfilePages();
    const { hasOwlerFeature } = useOwlerFeature();
    const governanceEnabled =
        pages.findIndex((page: ISupplierProfilePage) => page.pageName === "Governance") !== -1;
    const performanceManagementEnabled =
        pages.findIndex(
            (page: ISupplierProfilePage) => page.pageName === "PerformanceManagement"
        ) !== -1;
    const scorecardEnabled =
        pages.findIndex((page: ISupplierProfilePage) => page.pageName === "ScorecardWidget") !== -1;

    return (
        <div>
            {/* {scorecardEnabled && <div>Scorecard</div>} */}
            {hasOwlerFeature && (
                <OwlerContainer
                    domain={supplierData && supplierData.domain}
                    loadingDomain={supplierData === undefined || supplierData === null}
                    style={{ marginBottom: 12 }}
                />
            )}
            {checkForActive("SupplierSummaryMetrics", pages) && (
                <GuageMetrics partnerId={partnerId} />
            )}
            <Row gutter={16} style={{ marginBottom: 12 }}>
                {performanceManagementEnabled ? (
                    <Col span={16}>
                        <PerformanceMetrics partnerId={partnerId} />
                    </Col>
                ) : (
                    <Col span={16}>
                        <ReviewCategoryMetric partnerId={partnerId} />
                    </Col>
                )}
                <Col span={8}>
                    <Row>
                        <Col span={24} style={{ marginBottom: 12 }}>
                            <RapidReview partnerId={partnerId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingRight: 1 }}>
                            <Categories partnerId={partnerId} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {governanceEnabled && checkForActive("MeetingMinutes", pages) && !scorecardEnabled && (
                <Row gutter={16} className={classes.upperSummary}>
                    <Col span={24}>
                        <MeetingSummary partnerId={partnerId} />
                    </Col>
                </Row>
            )}
            {governanceEnabled && checkForActive("MeetingMinutes", pages) && scorecardEnabled && (
                <Row gutter={16} className={classes.upperSummary}>
                    <Col span={16}>
                        <MeetingSummary partnerId={partnerId} />
                    </Col>
                    <Col span={8}>
                        <ScorecardStatusWidget partnerId={partnerId} />
                    </Col>
                </Row>
            )}
            {!governanceEnabled && scorecardEnabled && (
                <Row gutter={16} className={classes.upperSummary}>
                    <Col span={16}></Col>
                    <Col span={8}>
                        <ScorecardStatusWidget partnerId={partnerId} />
                    </Col>
                </Row>
            )}
            <Row className={classNames(classes.verticalSeparation, classes.lowerSummary)}>
                <Col span={24}>
                    <DevelopmentPlanSummary partnerId={partnerId} />
                </Col>
            </Row>
            <Row className={classNames(classes.verticalSeparation)} style={{ marginBottom: 10 }}>
                <Col span={24}>
                    <PartnerCommentFeed partnerId={partnerId} />
                </Col>
            </Row>
        </div>
    );
};
export { SupplierSummary };
