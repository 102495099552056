import { useState } from "react";
import { Provider } from "services";

const useCompanySupportEmail = () => {
    const [loading, setLoading] = useState(false);

    const getSupportEmail = async () => {
        setLoading(true);
        const supportEmail = await Provider.Company.getCompanySupportEmail().catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return supportEmail;
    };

    return {
        loading,
        getSupportEmail,
    };
};

export { useCompanySupportEmail };
