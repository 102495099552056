export enum ReportType {
    "partner",
    "compliance",
    "ncr",
    "review",
    "survey",
    "nonResponseSurvey",
    "automatedSurvey",
    "complianceMatrix",
    "contact",
}
