import React, { useState } from "react";
import classes from "./styles/activities.module.scss";
import { Button, Collapse } from "antd";
import Activities from "./activities";
import { RightOutlined, SyncOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;

const ActivityContainer = () => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useState(false);
    const [reloadActivities, setReloadActivities] = useState({ reload: false });

    return (
        <>
            <Collapse
                destroyInactivePanel={true}
                onChange={(val: any) => toggleOpen(!open)}
                activeKey={open ? "1" : undefined}
                className={classes.customcollapse}
                expandIcon={({ isActive }) => (
                    <span>
                        <RightOutlined
                            style={{ fontSize: 20, marginTop: 48 }}
                            rotate={isActive ? 90 : 0}
                        />
                    </span>
                )}>
                <Panel
                    className={classes.custompanel}
                    key="1"
                    header={<h2>{t("home_LatestUpdates", "Latest Updates")}</h2>}>
                    <Button
                        shape="circle"
                        icon={<SyncOutlined />}
                        className={classes.refreshButton}
                        onClick={() => {
                            setReloadActivities({ reload: true });
                        }}></Button>

                    {open && <Activities reloadActivities={reloadActivities} />}
                </Panel>
            </Collapse>
        </>
    );
};

export { ActivityContainer };
