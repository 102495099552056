import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import classes from "../../styles/onBoarding.module.scss";
import { FormInstance } from "antd/es/form";
import { useParams } from "react-router-dom";
import { useIsUserNameInUse, useUserRegistrationType } from "controller";
import { getEmailValidationPattern, getUserNameValidationPattern } from "utilities";
interface IProps {
    formRef: React.RefObject<FormInstance>;
    setDisable: React.Dispatch<React.SetStateAction<boolean>>;
    step?: number;
}
const FirstStep = ({ formRef, setDisable, step }: IProps) => {
    const { t } = useTranslation();
    const { token }: any = useParams();
    const { isUserNameInUse } = useIsUserNameInUse();
    const { data: invitation } = useUserRegistrationType(token);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [username, setUsername] = useState("");
    useEffect(() => {
        if (invitation) {
            formRef.current?.setFieldsValue({
                email: invitation.emailAddress,
                username: invitation.emailAddress,
            });
        }
    }, [invitation, formRef]);

    const checkFields = () => {
        const firstNameValue = formRef.current?.getFieldValue("firstName");
        const lastNameValue = formRef.current?.getFieldValue("lastName");
        const emailAddressValue = formRef.current?.getFieldValue("email");
        const userNameValue = formRef.current?.getFieldValue("username");
        if (firstNameValue && lastNameValue && emailAddressValue && userNameValue) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };
    useEffect(() => {
        checkFields();
    }, [firstName, lastName, username, emailAddress, step, formRef]);

    return (
        <div>
            <Form.Item
                className={classes.formItem}
                label={t("admin_UserRegistration_EmailAddress")}
                name="email"
                rules={[
                    {
                        required: true,
                        validator: (rule: any, value: any, callback: any) => {
                            if (!value || value.trim() === "") {
                                return Promise.reject(new Error(t("inputIsRequiredError")));
                            } else if (!new RegExp(getEmailValidationPattern().regex).test(value)) {
                                return Promise.reject(
                                    new Error(t(getEmailValidationPattern().messageKey))
                                );
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
                initialValue={
                    invitation && invitation.emailAddress ? invitation.emailAddress : undefined
                }>
                <Input
                    disabled={true}
                    onChange={(e: any) => {
                        setEmailAddress(e.target.value);
                    }}
                    maxLength={256}
                    placeholder={t("admin_UserRegistration_EmailAddressPlaceholder")}
                />
            </Form.Item>
            <Form.Item
                className={classes.formItem}
                name="username"
                label={t("admin_UserRegistration_Username")}
                rules={[
                    {
                        required: true,
                        validator: async (rule: any, value: any, callback: any) => {
                            if (!value || value.trim() === "") {
                                return Promise.reject(new Error(t("inputIsRequiredError")));
                            } else if (
                                !new RegExp(getUserNameValidationPattern().regex).test(value)
                            ) {
                                return Promise.reject(
                                    new Error(t(getUserNameValidationPattern().messageKey))
                                );
                            } else if (await isUserNameInUse(value, token)) {
                                return Promise.reject(
                                    new Error(t("admin_UserRegistration_UsernameDuplicateMsg"))
                                );
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
                initialValue={
                    invitation && invitation.emailAddress ? invitation.emailAddress : undefined
                }>
                <Input
                    onChange={(e: any) => {
                        setUsername(e.target.value);
                    }}
                    maxLength={50}
                    placeholder={t("admin_UserRegistration_UsernamePlaceholder")}
                />
            </Form.Item>
            <Form.Item
                className={classes.formItem}
                label={t("admin_UserRegistration_FistName")}
                name="firstName"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t("inputIsRequiredError"),
                    },
                ]}>
                <Input
                    maxLength={200}
                    placeholder={t("admin_UserRegistration_FirstNamePlaceholder")}
                    onChange={(e: any) => {
                        setFirstName(e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                className={classes.formItem}
                label={t("admin_UserRegistration_LastName")}
                name="lastName"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t("inputIsRequiredError"),
                    },
                ]}>
                <Input
                    maxLength={200}
                    placeholder={t("admin_UserRegistration_LastNamePlaceholder")}
                    onChange={(e: any) => {
                        setLastName(e.target.value);
                    }}
                />
            </Form.Item>
        </div>
    );
};

export default FirstStep;
