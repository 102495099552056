import React, { useLayoutEffect } from "react";
import { Modal, Select, Switch, notification, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./styles/editForm.module.scss";
import { useAutomatedSurveyUpdate } from "controller";
const Option = Select.Option;

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    reloadTable: Function;
    autoSurveyId: number;
    frequency: string;
    activation: boolean;
}

const EditModal = ({
    visible,
    onVisibleChange,
    reloadTable,
    autoSurveyId,
    frequency,
    activation,
}: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { updateAutomatedSurvey, loading } = useAutomatedSurveyUpdate();

    useLayoutEffect(() => {
        form.resetFields();
    }, [visible]);

    function onCancel() {
        form.resetFields();
        onVisibleChange(false);
        form.resetFields();
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await updateAutomatedSurvey(
            autoSurveyId,
            parseFrequencyTextToInt(values.frequency),
            values.activation
        );
        reloadTable();
        onCancel();
        notification.success({
            message: t("Success"),
            description: t("autoSurvey_UpdateSuccess"),
        });
    }

    function parseFrequencyTextToInt(text: string) {
        let frequencyInt = -1;
        switch (text) {
            case "autoSurvey_Daily":
                frequencyInt = 0;
                break;
            case "autoSurvey_Weekly":
                frequencyInt = 1;
                break;
            case "autoSurvey_Monthly":
                frequencyInt = 2;
                break;
            case "autoSurvey_Quarterly":
                frequencyInt = 3;
                break;
            case "autoSurvey_Annually":
                frequencyInt = 4;
                break;
            case "autoSurvey_BiAnnually":
                frequencyInt = 5;
                break;
            default:
                frequencyInt = 1;
                break;
        }
        return frequencyInt;
    }

    return (
        <Modal
            title={t("autoSurvey_EditSurvey")}
            open={visible}
            onCancel={() => onCancel()}
            onOk={() => onSubmit()}
            confirmLoading={loading}
            width={900}
            style={{ top: 20 }}
            destroyOnClose={true}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSubmit()}>
                    {t("generic_Save")}
                </Button>,
            ]}>
            <Form
                form={form}
                onFinish={onSubmit}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                    label={t("autoSurvey_Frequency")}
                    className={classes.formItem}
                    name="frequency"
                    valuePropName="value"
                    initialValue={frequency}>
                    <Select style={{ width: "100%" }}>
                        <Option key={0} value={"autoSurvey_Daily"}>
                            {t("autoSurvey_Daily")}
                        </Option>
                        <Option key={1} value={"autoSurvey_Weekly"}>
                            {t("autoSurvey_Weekly")}
                        </Option>
                        <Option key={2} value={"autoSurvey_Monthly"}>
                            {t("autoSurvey_Monthly")}
                        </Option>
                        <Option key={3} value={"autoSurvey_Quarterly"}>
                            {t("autoSurvey_Quarterly")}
                        </Option>
                        <Option key={4} value={"autoSurvey_BiAnnually"}>
                            {t("autoSurvey_BiAnnually")}
                        </Option>
                        <Option key={5} value={"autoSurvey_Annually"}>
                            {t("autoSurvey_Annually")}
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("autoSurvey_Activation")}
                    className={classes.formItem}
                    name="activation"
                    valuePropName="checked"
                    initialValue={activation ? activation : false}>
                    <Switch
                        checkedChildren={t("autoSurvey_Active")}
                        unCheckedChildren={t("autoSurvey_Inactive")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
