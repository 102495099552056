import { useEffect, useState } from "react";
import { Provider } from "services";

const useMatrixTemplateGroups = (reload = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Compliance.getMatrixTemplateGroups();
            let newData: any = [];
            data.forEach((group: any) => {
                if (group.groupId == null) {
                    return group.templateDtos.forEach((t: any) => {
                        newData = [
                            ...newData,
                            {
                                groupId: null,
                                groupName: null,
                                templateDtos: [t],
                            },
                        ];
                    });
                } else {
                    newData = [...newData, group];
                }
            });
            setState({ loading: false, data: newData });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMatrixTemplateGroups };
