import {
    ExcelExportState,
    SET_EXCELEXPORT,
    CLEAR_EXCELEXPORT,
    ExcelExportActionTypes,
} from "./types";

const initialState: ExcelExportState = {
    props: { reportType: undefined, loading: false, downloadProgress: 0 },
};

export function excelExportReducer(
    state = initialState,
    action: ExcelExportActionTypes
): ExcelExportState {
    switch (action.type) {
        case SET_EXCELEXPORT:
            return {
                props: action.payload,
            };
        case CLEAR_EXCELEXPORT:
            return {
                props: {
                    reportType: undefined,
                    loading: false,
                    downloadProgress: 0,
                },
            };
        default:
            return state;
    }
}
