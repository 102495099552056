import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Tag, notification, Form, Button } from "antd";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import ICustomHeadline from "../../../../models/suppliers/ICustomHeadline";
import { useTranslation } from "react-i18next";
import { useCompanyType, useContacts, usePartnerUpdate } from "controller";
import { SelectDataMultiple } from "components/utilitycomponents";

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    updateTable(): void;
    ownerHeadlinne: ICustomHeadline;
    onSelectedRowsChange: Function;
}

export const ContentModal: FunctionComponent<ModalProps> = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };
    const [selectedOwners, setSelectedOwners] = useState([]);
    const { updateOwnersOnPartners, loading: confirmLoading } = usePartnerUpdate();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: any) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onCancel() {
        form.resetFields();
        setSelectedOwners([]);
        props.setModalVisibility(false);
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await updateOwnersOnPartners(
            props.selectedRows.map(x => x.id.value),
            values.selectOwners
        );
        notification.success({
            message: t("success"),
            description: t("supplier_OwnerUpdatePartnerSuccess", {
                ownerHeadline,
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier").toLocaleLowerCase()
                    : t("company_Type_Customer").toLocaleLowerCase(),
            }),
        });
        form.resetFields();

        props.setModalVisibility(false);
        await props.updateTable();
        props.onSelectedRowsChange(
            props.selectedRows.map(r => ({
                ...r,
                owners: {
                    ...r.owners,
                    value: selectedOwners.map((o: any) => ({
                        id: o.id,
                        name: o.name,
                    })),
                },
            }))
        );
    }

    const ownerHeadline =
        props.ownerHeadlinne && props.ownerHeadlinne.customLabel !== null
            ? props.ownerHeadlinne.customLabel
            : t("owners");

    return (
        <div>
            <Modal
                wrapProps={{ "data-test": "owner_modal" }}
                title={t("supplier_OwnerTitle", {
                    ownerHeadline: ownerHeadline,
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier")
                        : t("company_Type_Customer"),
                })}
                open={props.visible}
                onOk={onSubmit}
                onCancel={onCancel}
                width={700}
                footer={[
                    <Button
                        loading={confirmLoading}
                        type="primary"
                        onClick={async () => await onSubmit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Form form={form} {...formItemLayout} layout={"horizontal"} colon={false}>
                    <Form.Item
                        label={
                            t("supplier_SelectedSuppliers", {
                                companyType: isDefaultCompany
                                    ? t("company_Type_Supplier")
                                    : t("company_Type_Customer"),
                            }) + " : "
                        }>
                        {props.selectedRows.length === 1 && (
                            <div className="tagArea">{renderTags(false)}</div>
                        )}
                        {props.selectedRows.length > 1 && (
                            <div className="tagArea">{renderTags(true)}</div>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={`${t("ownerSelectLabel")} ${ownerHeadline} : `}
                        name={"selectOwners"}
                        initialValue={
                            props.selectedRows.length === 1
                                ? props.selectedRows[0].owners!.value.map(x => x.id)
                                : []
                        }>
                        <SelectDataMultiple
                            data-test="select_owners_select"
                            style={{ width: "100%" }}
                            placeholder={`${t("ownerSelectPlaceholder")} ${ownerHeadline}`}
                            allowClear={true}
                            useData={useContacts}
                            selectIdField="id"
                            selectOptionField="name"
                            onChange={(value: any, items: any) => setSelectedOwners(items)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
const ModalOwner = ContentModal;
export default ModalOwner;
