import React, { FunctionComponent, Fragment } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from "./styles/shared.module.scss";
import { IComplianceDetail } from "models";
import { useTranslation } from "react-i18next";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IComplianceDetail;
    isHomePage: boolean;
    onDisplayRegistrationModal: Function;
};

const Compliance: FunctionComponent<PropsType> = ({
    data,
    isHomePage = false,
    onDisplayRegistrationModal,
}) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IComplianceDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewComplianceRegistrationDoneOnPartner", {
                    id: data.id,
                    myCompany: data.myCompanyName,
                })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IComplianceDetail) => {
        return (
            <Fragment>
                {t("activity_NewComplianceRegistrationByPartner", {
                    creator: data.creator,
                    id: data.id,
                    interpolation: { escapeValue: false },
                })}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            {isHomePage && (
                <Button
                    icon={<RightOutlined />}
                    type="link"
                    onClick={() =>
                        onDisplayRegistrationModal({
                            id: data.id,
                            visible: true,
                        })
                    }
                    className={classes.link}>
                    {t("activity_ViewRegistration")}
                </Button>
            )}
        </Fragment>
    );
};

export default Compliance;
