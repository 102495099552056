import React, { useEffect, useState } from "react";
import { Modal, Skeleton, Form, Input, Divider, Button, notification } from "antd";
import useProduct from "controller/product/useProduct";
import { SelectData, SelectDataMultiple, SelectTreeData } from "components/utilitycomponents";
import {
    useCommodityCategories,
    usePartnerList,
    useProductUpsert,
    useProductStatus,
} from "controller";
import dayjs from "dayjs";
import classes from "./overview.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
    id: number | undefined;
    visible: boolean;
    onClose: Function;
};

const ProductDetails = ({ id, visible, onClose }: Props) => {
    const { t } = useTranslation();
    const [reload] = useState<{ reload: boolean }>({ reload: false });
    const { data, loading } = useProduct(id, undefined, reload);
    const [form] = Form.useForm();
    const { upsert, loading: upserting } = useProductUpsert();

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    const onSubmit = async () => {
        let values = await form.validateFields();
        await upsert({
            productId: values.productId,
            name: values.name,
            description: values.description,
            commodityCategoryId: values.commodityCategory,
            partnerIds: values.suppliers,
            productStatusId: values.productStatus,
        });
        notification.success({ message: t("success"), type: "success" });
        onClose(true);
        form.resetFields();
    };

    const disabled = data && data.createdBy ? false : true;
    const disabledClass = disabled ? classes.disabledFormItem : "";

    return (
        <Modal
            destroyOnClose
            confirmLoading={upserting}
            title={
                <Divider orientation="left" orientationMargin={0}>
                    {t("generic_Details")}
                </Divider>
            }
            open={visible}
            onCancel={() => onClose()}
            width={800}
            footer={[
                !disabled ? (
                    <Button onClick={onSubmit} type="primary" loading={upserting}>
                        {t("generic_Update")}
                    </Button>
                ) : (
                    undefined
                ),
            ]}>
            <Skeleton loading={loading} active>
                <Form
                    colon={false}
                    form={form}
                    variant="filled"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}>
                    <Form.Item
                        name="productId"
                        label={t("product_ProductId")}
                        initialValue={data && data.productId}>
                        <Input
                            disabled={disabled}
                            className={disabledClass}
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_ProductIdPlaceholder")
                                    : t("product_NotEditable")
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={t("generic_Name")}
                        initialValue={data && data.name}>
                        <Input
                            disabled={disabled}
                            className={disabledClass}
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_ProductNamePlaceholder")
                                    : t("product_NotEditable")
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={t("generic_Description")}
                        initialValue={data && data.description}>
                        <Input.TextArea
                            disabled={disabled}
                            className={disabledClass}
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_ProductDescriptionPlaceholder")
                                    : t("product_NotEditable")
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="commodityCategory"
                        label={t("product_Category")}
                        initialValue={data && data.commodityCategoryId}>
                        <SelectTreeData
                            disabled={disabled}
                            className={disabledClass}
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_ProductCategoryPlaceholder")
                                    : t("product_NotEditable")
                            }
                            selectIdField="internalId"
                            selectOptionField="description"
                            showArrow={!disabled}
                            useData={useCommodityCategories.bind(null, {
                                level: 1,
                                singleLevel: false,
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name="suppliers"
                        label={t("Supplier")}
                        initialValue={
                            data && data.partners
                                ? data.partners.map((partner: any) => partner.id)
                                : undefined
                        }>
                        <SelectDataMultiple
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_SupplierPlaceholder")
                                    : t("product_NotEditable")
                            }
                            useData={usePartnerList}
                            selectOptionField={(item: any) => ({
                                text: `${item.name} ${item.number}`,
                                node: (
                                    <span>
                                        {item.name} {item.number}
                                    </span>
                                ),
                            })}
                            disabled={disabled}
                            className={disabledClass}
                            showArrow={!disabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="productStatus"
                        label={t("product_ProductStatus")}
                        initialValue={data && data.productStatusId}>
                        <SelectData
                            useData={useProductStatus}
                            selectIdField="id"
                            selectOptionField="name"
                            disabled={disabled}
                            className={disabledClass}
                            placeholder={
                                data && data.isUserCreated
                                    ? t("product_ProductPlaceholder")
                                    : t("product_NotEditable")
                            }
                            showArrow={!disabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="created"
                        label={t("Created")}
                        initialValue={data && dayjs(data.created).format("MMM D, YYYY")}>
                        <Input disabled={true} className={classes.disabledFormItem} />
                    </Form.Item>
                    <Form.Item
                        name="modified"
                        label={t("generic_Updated")}
                        initialValue={data && dayjs(data.modified).format("MMM D, YYYY")}>
                        <Input disabled={true} className={classes.disabledFormItem} />
                    </Form.Item>
                </Form>
            </Skeleton>
        </Modal>
    );
};

export { ProductDetails };
