import { post, get, put, remove } from "../base";

const MeetingMinutes = {
    getMeetingMinutes: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/GetAllMeetingMinutes?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    addMeetingMinute: async (input: any, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/CreateMeetingMinute`;
        return await post({ url, payload: input, customError });
    },
    getMeetingMinuteTypes: async ({ customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/GetAllMeetingMinuteTypes`;
        return await get({ url, customError });
    },
    getMeetingMinuteById: async (id: number, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/GetMeetingMinuteById?id=${id}`;
        return await get({ url, customError });
    },
    updateMeetingMinute: async (input: any, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/UpdateMeetingMinute`;
        return await put({ url, payload: input, customError });
    },
    shareMeetingMinute: async (
        meetingMinutId: number,
        contactIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/MeetingMinutes/Share/${meetingMinutId}`;
        return await post({ url, payload: contactIds, customError });
    },
    getMeetingMinuteByToken: async (token: string, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutesGuest/Get/${token}`;
        return await get({ url, customError });
    },
    deleteMeetingMinute: async (id: number, { customError }: any = {}) => {
        const url = `/v1/MeetingMinutes/${id}`;
        return await remove({ url, customError });
    },
};

export default MeetingMinutes;
