import { useState, useEffect } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

type StateType = {
    loading: boolean;
    data: any;
};

const useSurveyReviewCountGraphs = (surveyId: number) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: false,
        data: {},
    });

    useEffect(() => {
        (async () => {
            if (surveyId !== -1) {
                setState({ ...state, loading: true });
                let data = await Provider.Survey.getSurveyReviewCountGraphs(surveyId, {
                    customError: {
                        message: t("survey_GetSurveyReviewCountGraphsError"),
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data: data });
            }
        })();
    }, [surveyId]);

    return { data: state.data, loading: state.loading };
};

export { useSurveyReviewCountGraphs };
