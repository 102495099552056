import { useEffect, useState } from "react";
import { Provider } from "services";
import { INcrReason } from "models";
import { useTranslation } from "react-i18next";

type StateType = {
    loading: boolean;
    data: INcrReason[];
};

const useNcrReason = (ncrReasonId = -1, reload: any) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (ncrReasonId) {
                setState({ ...state, loading: true });
                let data = await Provider.Ncr.getNcrReasons(ncrReasonId, {
                    customError: {
                        message: "Could not retrieve NCR reasons",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                let translatedData = data.map((d: any) => ({ ...d, name: t(d.name) }));
                translatedData = translatedData.sort((a: any, b: any) => {
                    let nameA = a.name.toUpperCase();
                    let nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setState({ loading: false, data: translatedData });
            }
        })();
    }, [ncrReasonId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useNcrReason;
