import { Modules } from "models";
import { get, post, put } from "../base";

const Contacts = {
    getContacts: async (
        currentUser: string[] = ["Administrator", "User"],
        sortByName: boolean = true
    ) => {
        const url = `/v1/Contacts?sortByName=${sortByName}`;
        return await post({ url, payload: currentUser });
    },
    getCompanyContactsWithModuleAccess: async (
        userTypes: string[] = ["Administrator", "User"],
        accessTo: Modules,
        justUser: boolean = false,
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/companyContactsWithModuleAccess/${accessTo}?justUser=${justUser}`;
        return await post({ url, payload: userTypes, customError });
    },
    getComplianceNotifyUsers: async (users: number[], { customError }: any = {}) => {
        const url = `/v1/Contacts/GetNotifyUsers`;
        return await post({ url, payload: users, customError });
    },
    getExternalPartnerUsers: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Contacts/externalPartnerUsers/${partnerId}`;
        return await get({ url });
    },
    getNonRestrictedExternalPartnerUsers: async (
        partnerId: number,
        restrictedModule: Modules,
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/nonRestrictedExternalPartnerUsers/${partnerId}/${restrictedModule}`;
        return await get({ url });
    },
    getContactGroups: async (sortByName: boolean = true, { retryCount, customError }: any) => {
        const url = `/v1/Contacts/GetContactGroups?sortByName=${sortByName}`;
        return await get({ url, retryCount, customError });
    },
    getPartnerOwners: async ({ sortByName, users }: any, { customError }: any = {}) => {
        const url = `/v1/Contacts?sortByName=${sortByName}`;
        return await post({ url, payload: users, customError });
    },
    updateProfilePicture: async (fileId: number | null, { customError }: any = {}) => {
        const url = fileId
            ? `/v1/Contacts/UpdateUserAccountPicture?profilePictureId=${fileId}`
            : `/v1/Contacts/UpdateUserAccountPicture`;
        await put({
            url,
            customError,
        });
    },
    getContactGroupsWithAllContacts: async (
        userTypes: string[] = ["Administrator", "User"],
        accessTo: Modules,
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/ContactGroupsWithAllContacts/${accessTo}`;
        return await post({ url, payload: userTypes, customError });
    },
    getCompanyUsers: async (currentUserOnIndexZero: boolean = false, { customError }: any = {}) => {
        const url = `/v1/Contacts/GetCurrentCompanyUserContacts/${currentUserOnIndexZero}`;
        return await get({ url, customError });
    },
};

export default Contacts;
