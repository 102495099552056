import { useState } from "react";
import { Provider } from "services";

const useReviewCriteriaActivationUpdate = () => {
    const [loading, setLoading] = useState(false);
    const update = async (ids: number[], active: boolean) => {
        setLoading(true);
        let data = await Provider.Review.updateReviewCriteriaActivation(ids, active, {
            customError: {
                message: "Could not update template activation",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    return {
        loading,
        update,
    };
};

export { useReviewCriteriaActivationUpdate };
