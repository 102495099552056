export const useCaseTree = [
    {
        title: "Procurement",
        value: 1,
        children: [
            { title: "Risk Management", value: 2 },
            {
                title: "Supply Chain Management & Operations",
                value: 3,
            },
            { title: "Supplier Management", value: 4 },
            { title: "Procurement", value: 5 },
            { title: "Contracts & Negotiations", value: 6 },
            { title: "Category Management", value: 7 },
        ],
    },
    {
        title: "Product",
        value: 8,
        children: [
            { title: "Product Management", value: 9 },
            { title: "R&D, Innovation", value: 10 },
        ],
    },
    {
        title: "Quality",
        value: 11,
        children: [
            { title: "Quality Management", value: 12 },
            { title: "Sustainability/ESG", value: 13 },
        ],
    },
    {
        title: "Sales & Marketing",
        value: 14,
        children: [
            { title: "Sales", value: 15 },
            { title: "Marketing", value: 16 },
            { title: "Account Management", value: 17 },
        ],
    },
    {
        title: "Logistics",
        value: 18,
        children: [
            { title: "Logistics Management", value: 19 },
            { title: "Warehousing", value: 20 },
        ],
    },
    {
        title: "Other",
        value: 21,
        children: [
            { title: "IT/Systems Management", value: 22 },
            {
                title: "Senior Leadership/Executive Management",
                value: 23,
            },
            {
                title: "Customer Service/Support/Success",
                value: 24,
            },
            { title: "Human Resources", value: 25 },
            { title: "Finance & Accounting", value: 26 },
            { title: "Legal", value: 27 },
            { title: "Production & Manufacturing", value: 28 },
        ],
    },
];
