import React, {
    FunctionComponent,
    useState,
    ReactNode,
    useLayoutEffect,
    SyntheticEvent,
    Fragment,
    createElement,
    ComponentType,
    useEffect,
} from "react";
//@ts-ignore
import ReactDragListView from "react-drag-listview";
import { Resizable } from "react-resizable";
import classnames from "classnames";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    FilterOutlined,
    FilterTwoTone,
} from "@ant-design/icons";
import { Button, Table, Drawer, Result, Tag } from "antd";
import { ReportType } from "models";
import { withViewSetting } from "controller";
import { getTableSize } from "./gridUtilities";
import GridSetting from "./gridSetting";
import GridRangePicker from "./gridRangePicker";
import GridExportButton from "./gridExportButton";
import { EditableCell } from "./editableCell";
import classes from "./styles/grid.module.scss";
import { useTranslation, Trans } from "react-i18next";
import "./styles/grid.scss";
import { getOperationColumn } from "./getOperationColumn";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { IColumnFilter } from "./models";
import dayjs from "utilities/daysJsConfig";

type PropTypes = {
    title?: () => ReactNode;
    sidebar?: ComponentType<any>;
    topBar?: ComponentType<any>;
    columns: any[];
    fixedColumns?: string[];
    reloadData?: Object | null;
    apiFilters: any[];
    customFilters: IColumnFilter[];
    tableSize: any;
    pageSize: number;
    pageSizeOptions?: string[];
    customPageSize?: number;
    tableExcessHeight?: number;
    reportType?: ReportType;
    rangePicker?: boolean;
    defaultRange?: boolean;
    dateRange?: any;
    defaultRangeInMonth?: number;
    classNames?: string[];
    externalLoading?: boolean;
    viewSettingsLoading: boolean;
    viewSettingUpdateLoading: boolean;
    hideColumnSetting?: boolean;
    hideGridSetting?: boolean;
    shownUserOrderedColumns: any[];
    hiddenColumns: any[];
    minColumnWidth?: number;
    updateRowLoading?: boolean;
    draggableRows?: boolean;
    editableRows?: boolean;
    useData: Function;
    onRow?: any;
    onHeaderCell?: any;
    onTableSettingSave: Function;
    onReorderColumns: Function;
    onTableFiltersChange: Function;
    onColumnsResize: Function;
    onColumnResizeEnd: Function;
    onTableSizeChange: Function;
    onPageSizeChange: Function;
    onDataCustomFields?: Function;
    onCustomFiltersChange?: Function;
    onClearFilters?: Function;
    onCustomFiltersUpdate?: Function;
    onRangeChange?: Function;
    onSaveRow?: Function;
    onEditRow?: Function;
    customEmptyText?: boolean;
    emptyText?: ReactNode;
    showTitle?: boolean;
    containerMargin?: boolean;
    mutateRowSelectionOnFilterChange?: boolean;
    [key: string]: any;
};
const { pageSize: defaultPageSize, excess } = getTableSize();

const Grid: FunctionComponent<PropTypes> = ({
    title,
    sidebar,
    topBar,
    columns,
    tableSize,
    pageSize,
    fixedColumns,
    reloadData = null,
    apiFilters,
    customFilters,
    pageSizeOptions = ["20", "50", "100"],
    customPageSize,
    tableExcessHeight = 0,
    reportType,
    rangePicker = false,
    defaultRange = false,
    dateRange = null,
    defaultRangeInMonth = 6,
    shownUserOrderedColumns,
    hiddenColumns,
    draggableRows,
    editableRows = false,
    useData,
    onRow,
    onHeaderCell,
    classNames = [""],
    externalLoading = false,
    viewSettingsLoading = false,
    viewSettingUpdateLoading = false,
    hideColumnSetting = false,
    hideGridSetting = false,
    minColumnWidth = 200,
    updateRowLoading = false,
    onTableSettingSave,
    onAddTableSettingToAllUsers,
    onReorderColumns,
    onTableFiltersChange,
    onColumnsResize,
    onColumnResizeEnd,
    onTableSizeChange,
    onPageSizeChange,
    onDataCustomFields = null,
    onCustomFiltersChange = null,
    onClearFilters = null,
    onCustomFiltersUpdate = null,
    onRangeChange = null,
    onSaveRow,
    onEditRow,
    showEmptyText = null,
    customEmptyText = null,
    showTitle = true,
    containerMargin = true,
    mutateRowSelectionOnFilterChange = true,
    ...rest
}) => {
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState(undefined);
    const [pageNumber, setPageNumber] = useState(1);
    const [sorters, setSorters] = useState<any>([]);
    const [range, setRange] = useState<any>(
        defaultRange
            ? {
                  to:
                      dateRange && dateRange.to
                          ? dayjs(dateRange.to)
                          : dayjs()
                                .endOf("day")
                                .toISOString(),
                  from:
                      dateRange && dateRange.from
                          ? dayjs(dateRange.from)
                          : dayjs()
                                .subtract(defaultRangeInMonth, "months")
                                .startOf("day")
                                .toISOString(),
              }
            : {
                  from: dateRange ? dateRange.from : undefined,
                  to: dateRange ? dateRange.to : undefined,
              }
    );

    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
    const {
        loading,
        data,
        totalRows,
        customFields,
        recordsWithChildrenFound = null,
        updateData = null,
        updateRowOrder = null,
        updateRow = null,
    } = useData(
        pageSize,
        pageNumber,
        apiFilters,
        sorters,
        externalLoading || viewSettingsLoading,
        reloadData,
        range
    );
    const hasComplianceExportPrivile: boolean = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-hasComplianceExportPrivilege"] === "true"
    );
    const isAdmin: boolean = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-Usertype"] === "administrator"
    );

    if (onDataCustomFields !== null) {
        if (customFields !== undefined && customFields !== null) {
            onDataCustomFields(customFields);
        }
    }

    const resizableColumns = shownUserOrderedColumns.map((col, index) => {
        let column = {
            ...col,
            onHeaderCell: (column: any) => ({
                width: column.width,
                onResize: handleResize(index),
                onResizeEnd: onColumnResizeEnd.bind(null, col),
                minColumnWidth: minColumnWidth,
                className: column.pronounced === true ? "pronouncedColumn" : "",
                style:
                    column.fixed === true
                        ? { backgroundColor: "#f4f4f3", ...(onHeaderCell && onHeaderCell().style) }
                        : { backgroundColor: "#fafafa", ...(onHeaderCell && onHeaderCell().style) },
                onClick:
                    col.onHeaderCell && col.onHeaderCell().onClick
                        ? () => col.onHeaderCell().onClick(column)
                        : undefined,
            }),
            ...(editableRows && {
                onCell: col.onCell
                    ? col.onCell
                    : (record: any) => ({
                          record,
                          dataIndex: col.dataIndex,
                          rules: col.rules,
                          editing: col.editable && record.id === editingKey,
                      }),
            }),
            ...(editableRows &&
                col.dataIndex === "grid_operation" && {
                    render: (_: any, record: any) =>
                        !col.condition || (col.condition && col.condition(record))
                            ? getOperationColumn({
                                  editingKey,
                                  setEditingKey,
                                  onSaveRow,
                                  onEditRow,
                                  updateRow,
                                  updateRowLoading,
                              })(_, record)
                            : null,
                }),
        };
        const originalRender = column.render;

        column.render = (value: any, record: any, index: number) => {
            if (col.operationColumn === true) {
                return originalRender(value, record, index);
            }
            // Check for null, undefined, or empty string
            const isEmpty =
                value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && value.length === 0);

            // Use the original render if it exists, otherwise use the value or placeholder
            const content = isEmpty ? <Tag>-</Tag> : value;

            return isEmpty
                ? content
                : originalRender
                ? originalRender(content, record, index)
                : content;
        };

        return column;
    });
    useEffect(() => {
        if (recordsWithChildrenFound !== null) {
            setExpandedRowKeys(recordsWithChildrenFound);
        }
    }, [JSON.stringify(recordsWithChildrenFound)]);

    useLayoutEffect(() => {
        const header = document.querySelector(".grid .ant-table-header") as HTMLElement;
        const stopScroll = (e: any) => {
            header.style.overflowY = "hidden";
        };
        const startScroll = (e: any) => {
            header.style.overflowY = "scroll";
        };
        if (header) {
            header.addEventListener("dragstart", stopScroll, false);
            header.addEventListener("dragend", startScroll, false);
            header.addEventListener("wheel", (e: any) => e.preventDefault(), true);
        }
    }, []);

    useEffect(() => {
        if (
            mutateRowSelectionOnFilterChange &&
            rest.rowSelection &&
            rest.rowSelection.onChange &&
            ((rest.rowSelection.selectedRowKeys && rest.rowSelection.selectedRowKeys.length > 0) ||
                (rest.rowSelection.selectedRows && rest.rowSelection.selectedRows.length > 0))
        ) {
            const tableRowKey = rest.rowKey ? rest.rowKey : "id";
            const recordIds = data.map((record: any) =>
                typeof tableRowKey === "function" ? tableRowKey(record) : record[tableRowKey]
            );
            let newSelectedRowKeys: number[] = [],
                newSelectedRows: any[] = [];
            if (rest.rowSelection.selectedRowKeys) {
                newSelectedRowKeys = rest.rowSelection.selectedRowKeys.filter((key: number) =>
                    recordIds.includes(key)
                );
            }
            if (rest.rowSelection.selectedRows) {
                newSelectedRows = rest.rowSelection.selectedRows.filter((record: any) =>
                    recordIds.includes(
                        typeof tableRowKey === "function"
                            ? tableRowKey(record)
                            : record[tableRowKey]
                    )
                );
            }
            rest.rowSelection.onChange(newSelectedRowKeys, newSelectedRows);
        }
    }, [totalRows]);

    const handleClearFilters = () => {
        let staticFilters: any = {};
        apiFilters.forEach(object => {
            if (object.static) {
                staticFilters[object.type] = object.value;
            }
        });
        //if static is set we want to persist the filtration regardless of clearFilters logic
        onClearFilters && onClearFilters(staticFilters ? staticFilters : []);
        onCustomFiltersUpdate && onCustomFiltersUpdate([]);
    };

    const handleResetColumns = () => {
        onTableSizeChange("small");
        onTableSettingSave(columns, [], true);
    };

    const handleSaveTableSettings = (newHiddenColumns: string[], newTableSize: any = "small") => {
        onTableSizeChange(newTableSize);
        onTableSettingSave(newHiddenColumns);
    };
    const handleAddTableSettingToAllUsers = (
        newHiddenColumns: string[],
        newTableSize: any = "small"
    ) => {
        onTableSizeChange(newTableSize);
        onAddTableSettingToAllUsers(newHiddenColumns);
    };

    const dragProps = {
        async onDragEnd(fromIndex: number, toIndex: number) {
            const cls = [...shownUserOrderedColumns];
            if (rest.rowSelection !== undefined) {
                if (fromIndex === 0) return;
                fromIndex--;
                toIndex--;
            }

            if (!cls[toIndex] || !cls[fromIndex]) return;

            /* if we are in < 700 viewport, we don't have the fixed attributes, 
            so we should prevent the fixed columns being dragged to unfixed positions */
            if (fixedColumns && fixedColumns.length) {
                const fromFixedColumn = fixedColumns.includes(cls[fromIndex].dataIndex);
                const toFixedColumns = fixedColumns.includes(cls[toIndex].dataIndex);
                if ((fromFixedColumn || toFixedColumns) && !(fromFixedColumn && toFixedColumns)) {
                    return;
                }
            }

            const item = cls.splice(fromIndex, 1)[0];
            cls.splice(toIndex, 0, item);
            onReorderColumns(cls, []);
        },
        enableScroll: false,
        nodeSelector: "th",
        ignoreSelector: ".ignoreDrag",
    };

    const rowDragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const rows = [...data];
            const oldOrder = rows[fromIndex - 1].order;
            const newOrder = rows[toIndex - 1].order;
            const item = rows.splice(fromIndex - 1, 1)[0];
            rows.splice(toIndex - 1, 0, item);
            updateData && updateData(rows);
            updateRowOrder && updateRowOrder(oldOrder, newOrder, item.id);
        },
        enableScroll: true,
        nodeSelector: "tr.draggableGridRow",
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const newPageNumber = pagination.current;
        if (newPageNumber !== pageNumber) {
            if (rest.rowSelection && rest.rowSelection.onChange) {
                rest.rowSelection.onChange([], []);
            }
        }
        setPageNumber(pagination.current);

        for (const key in filters) {
            if (filters[key] === null || filters[key].length === 0) {
                delete filters[key];
            }
        }

        if (sorter["column"]) {
            setSorters([
                {
                    ascending: sorter.order === "ascend" ? true : false,
                    index: 0,
                    type: sorter.column.customColumn
                        ? sorter.column.customColumnName
                            ? sorter.column.customColumnName
                            : "CustomColumn"
                        : sorter.column.key,
                    ...(sorter.column.customColumn && {
                        customColumnId: sorter.column.customColumnId
                            ? sorter.column.customColumnId
                            : Number(sorter.column.key),
                    }),
                },
            ]);
        } else if (sorter.column === undefined) {
            setSorters([
                {
                    ascending: false,
                    index: 0,
                    type: "UnSorted",
                },
            ]);
        }

        apiFilters.forEach(object => {
            if (object.static) {
                filters[object.type] = object.value;
            }
        });

        onTableFiltersChange(filters);
        onPageSizeChange(pagination.pageSize);
    };

    const components = {
        header: {
            cell: ResizableTitle,
        },
        ...(editableRows && {
            body: {
                cell: EditableCell,
            },
        }),
    };

    const handleResize = (index: any) => (e: any, { size }: any) => {
        let nextColumns = [...shownUserOrderedColumns];
        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };

        if (nextColumns[index]["children"]) {
            const childrenCount = nextColumns[index]["children"].length;
            const children = nextColumns[index].children.map((child: any) => ({
                ...child,
                width: size.width / childrenCount,
            }));
            nextColumns[index] = {
                ...nextColumns[index],
                children,
            };
        }
        onColumnsResize(nextColumns);
    };

    const showExportButton = () => {
        if (reportType !== undefined) {
            if (isAdmin) {
                return true;
            }
            if (
                reportType === ReportType.compliance ||
                reportType === ReportType.complianceMatrix
            ) {
                return !hasComplianceExportPrivile;
            }
            return true;
        }
        return false;
    };

    const paginationItemRender = (page: any, type: string) => {
        if (type === "prev") {
            return (
                <span className={classes.previousButton}>
                    <ArrowLeftOutlined className={classes.icon} />
                    {t("grid_Previous")}
                </span>
            );
        }

        if (type === "next") {
            return (
                <span className={classes.nextButton}>
                    {t("grid_Next")} <ArrowRightOutlined className={classes.icon} />
                </span>
            );
        }

        if (type === "page") {
            const isActive = pageNumber === page;

            return (
                <span className={`${isActive ? classes.activePage : classes.nonActivePage}`}>
                    {page}
                </span>
            );
        }
        if (type === "jump-prev" || type === "jump-next") {
            return <span className={classes.jumpButton}>...</span>;
        }

        return null;
    };

    return (
        <Fragment>
            <div style={containerMargin === true ? { margin: "25px 23px", height: "90vh" } : {}}>
                <ReactDragListView.DragColumn {...dragProps}>
                    <ReactDragListView {...rowDragProps}>
                        <Table
                            bordered={true}
                            loading={{
                                spinning:
                                    loading ||
                                    externalLoading ||
                                    viewSettingsLoading ||
                                    viewSettingUpdateLoading,
                                tip: t("generic_Loading"),
                            }}
                            rowClassName={(record, index) =>
                                classnames(draggableRows ? "draggableGridRow" : "")
                            }
                            className={classnames(
                                "grid",
                                ...classNames,
                                totalRows === 0 ? "no-rows" : ""
                            )}
                            rowKey="id"
                            size={rest.size ? rest.size : tableSize}
                            columns={resizableColumns}
                            dataSource={data}
                            scroll={{
                                y: `calc(100vh - ${excess + tableExcessHeight + 32}px`,
                                x: shownUserOrderedColumns.reduce((total: number, column: any) => {
                                    if (column.width && column.children) {
                                        return (
                                            total +
                                            Math.max(
                                                column.children.length * minColumnWidth,
                                                column.width
                                            )
                                        );
                                    }
                                    return total + (column.width || 0);
                                }, 0),
                            }}
                            onChange={handleTableChange}
                            expandedRowKeys={expandedRowKeys}
                            onExpandedRowsChange={expandedRows => {
                                setExpandedRowKeys(expandedRows);
                            }}
                            components={components}
                            locale={{
                                emptyText:
                                    customEmptyText !== null && showEmptyText ? (
                                        customEmptyText
                                    ) : (
                                        <Result
                                            title={t("grid_NoData")}
                                            style={{
                                                display: showEmptyText === true ? "block" : "none",
                                            }}
                                            status="info"></Result>
                                    ),
                            }}
                            pagination={{
                                current: pageNumber,
                                position: ["bottomRight"],
                                pageSizeOptions: [
                                    ...pageSizeOptions,
                                    defaultPageSize.toString(),
                                ].sort((a: string, b: string) => parseInt(a) - parseInt(b)),
                                pageSize: pageSize,
                                showSizeChanger: true,
                                total: totalRows,
                                itemRender: paginationItemRender,
                                locale: {
                                    items_per_page: "/ " + t("tablePage"),
                                    prev_page: t("grid_PrevPage"),
                                    next_page: t("grid_NextPage"),
                                },
                                showTotal: (total: number, range: any) => (
                                    <Trans
                                        i18nKey="tableTotalItems"
                                        values={{
                                            range0: range[0],
                                            range1: range[1],
                                            total: total,
                                        }}>
                                        {t("tableTotalItems")}
                                    </Trans>
                                ),
                            }}
                            title={
                                showTitle
                                    ? () => {
                                          return (
                                              <div
                                                  style={{
                                                      display: "table",
                                                      width: "100%",
                                                  }}>
                                                  <div className={classes.userTitle}>
                                                      {title && title()}
                                                      {rest.rowSelection &&
                                                          rest.rowSelection.selectedRowKeys &&
                                                          rest.rowSelection.selectedRowKeys
                                                              .length >= 1 && (
                                                              <span>
                                                                  {
                                                                      rest.rowSelection
                                                                          .selectedRowKeys.length
                                                                  }
                                                                  {t("grid_Selected")}
                                                              </span>
                                                          )}
                                                      {topBar &&
                                                          createElement(topBar, {
                                                              onCustomFiltersChange: (
                                                                  newCustomFilters: IColumnFilter[]
                                                              ) => {
                                                                  onCustomFiltersChange &&
                                                                      onCustomFiltersChange(
                                                                          newCustomFilters
                                                                      );
                                                                  onCustomFiltersUpdate &&
                                                                      onCustomFiltersUpdate(
                                                                          newCustomFilters
                                                                      );
                                                              },
                                                              customFilters,
                                                              ...customFields,
                                                          })}
                                                  </div>

                                                  <div className={classes.gridTitle}>
                                                      {((apiFilters.some(x => !x.static) &&
                                                          apiFilters.length !== 0) ||
                                                          Object.keys(customFilters).length !==
                                                              0) && (
                                                          <Button
                                                              data-test="clear_filters_button"
                                                              className="clearFiltersBtn"
                                                              onClick={() => handleClearFilters()}>
                                                              <FilterTwoTone />
                                                              {t("clearActiveFilters")}
                                                          </Button>
                                                      )}
                                                      {showExportButton() === true && (
                                                          <GridExportButton
                                                              reportType={reportType!}
                                                              filters={apiFilters}
                                                              sorts={sorters}
                                                              range={range}
                                                          />
                                                      )}
                                                      {rangePicker && (
                                                          <GridRangePicker
                                                              defaultRange={defaultRange}
                                                              {...range}
                                                              onRangeChange={(val: any) => {
                                                                  setRange(val);
                                                                  setPageNumber(1);
                                                                  onRangeChange &&
                                                                      onRangeChange(val);
                                                              }}
                                                          />
                                                      )}
                                                      {sidebar && (
                                                          <Button
                                                              data-test="other_filters_button"
                                                              className="setting"
                                                              onClick={() =>
                                                                  setFilterDrawerVisible(
                                                                      !filterDrawerVisible
                                                                  )
                                                              }>
                                                              <FilterOutlined
                                                                  style={{
                                                                      ...(customFilters.length >
                                                                          0 && {
                                                                          color: "#1890ff",
                                                                      }),
                                                                  }}
                                                              />
                                                              <span
                                                                  style={{
                                                                      ...(customFilters.length >
                                                                          0 && {
                                                                          color: "#1890ff",
                                                                      }),
                                                                  }}>
                                                                  {t("grid_Filters")}
                                                              </span>
                                                          </Button>
                                                      )}
                                                      {!hideGridSetting && (
                                                          <GridSetting
                                                              hideColumnSetting={hideColumnSetting}
                                                              columns={columns}
                                                              excludedColumns={[
                                                                  ...(fixedColumns
                                                                      ? fixedColumns
                                                                      : []),
                                                                  ...(editableRows
                                                                      ? ["grid_operation"]
                                                                      : []),
                                                              ]}
                                                              hiddenColumns={hiddenColumns}
                                                              onSave={(
                                                                  newHiddenColumns: any,
                                                                  newTableSize: any
                                                              ) =>
                                                                  handleSaveTableSettings(
                                                                      newHiddenColumns,
                                                                      newTableSize
                                                                  )
                                                              }
                                                              onSetSettingsForAllUser={(
                                                                  newHiddenColumns: any,
                                                                  newTableSize: any
                                                              ) =>
                                                                  handleAddTableSettingToAllUsers(
                                                                      newHiddenColumns,
                                                                      newTableSize
                                                                  )
                                                              }
                                                              onResetColumns={handleResetColumns}
                                                              tableSize={tableSize}
                                                          />
                                                      )}
                                                  </div>
                                              </div>
                                          );
                                      }
                                    : undefined
                            }
                            {...(onRow
                                ? {
                                      onRow: (record: any, index: number | undefined) =>
                                          editableRows && editingKey !== undefined
                                              ? onRow.call(
                                                    null,
                                                    { ...record, editingKey: true },
                                                    index
                                                )
                                              : onRow.call(null, record, index),
                                  }
                                : {})}
                            {...rest}
                        />
                    </ReactDragListView>
                </ReactDragListView.DragColumn>
            </div>
            {sidebar && (
                <Drawer
                    title={
                        <div style={{ textAlign: "left", color: "#000" }}>{t("grid_Filters")}</div>
                    }
                    placement="right"
                    onClose={() => setFilterDrawerVisible(false)}
                    open={filterDrawerVisible}
                    getContainer={false}
                    className="drawer"
                    width={340}
                    style={{ position: "absolute" }}>
                    {createElement(sidebar, {
                        onCustomFiltersChange: (newCustomFilters: IColumnFilter[]) => {
                            onCustomFiltersChange && onCustomFiltersChange(newCustomFilters);
                            onCustomFiltersUpdate && onCustomFiltersUpdate(newCustomFilters);
                        },
                        customFilters,
                    })}
                </Drawer>
            )}
        </Fragment>
    );
};

export default withViewSetting(Grid);

const ResizableTitle = (props: any) => {
    const { onResize, onResizeEnd, width, onClick, minColumnWidth, ...restProps } = props;
    const [allowHeaderClick, setAllowHeaderClick] = useState(true);

    if (!width) {
        return <th {...restProps} onClick={onClick} />;
    }

    const onResizeStart = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setAllowHeaderClick(false);
    };

    const onResizeStop = (e: any, data: any) => {
        onResizeEnd(data.size.width);
    };

    return (
        <Resizable
            width={width}
            minConstraints={[
                restProps.colSpan ? restProps.colSpan * minColumnWidth : minColumnWidth,
                20,
            ]}
            height={0}
            onResize={onResize}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            draggableOpts={{ enableUserSelectHack: false }}>
            <th
                {...restProps}
                onMouseDown={() => setAllowHeaderClick(true)}
                onClick={(e: SyntheticEvent) =>
                    allowHeaderClick && onClick !== undefined && onClick(e)
                }
            />
        </Resizable>
    );
};
