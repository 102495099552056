import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Empty, Select, Spin } from "antd";
import classnames from "classnames";
const { Option, OptGroup } = Select;

type PropsType = {
    value?: any[];
    selectIdField?: string;
    selectOptionField: string | Function;
    selectAll?: boolean;
    onChange?: Function;
    useData: Function;
    groupFilter?: Function;
    groupNames?: any[];
    [key: string]: any;
};
//forwardRef is used for the component to be able to be used as a FormItem child directly
const SelectDataMultiple = forwardRef(
    (
        {
            value,
            selectIdField = "id",
            selectOptionField,
            onChange,
            useData,
            selectAll = false,
            groupFilter,
            groupNames,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();

        const { data, loading } = useData();

        const allValues: any[] = [];
        for (let i = 0; i < data.length; i++) {
            if (
                typeof selectOptionField === "function" &&
                selectOptionField(data[i]).disabled === true
            ) {
                continue;
            }
            allValues.push(data[i][selectIdField]);
        }

        const handleChange = (values: any[], elements: any[]) => {
            if (!onChange) return;
            if (values && values.length && values.includes(-1)) {
                if (values.length === allValues.length + 1) {
                    onChange([], []);
                } else {
                    onChange([...allValues], data);
                }
            } else {
                onChange(values, elements);
            }
        };

        const renderOptionsStructure = () => {
            if (groupNames && groupNames.length > 0) {
                return groupNames.map(name => {
                    let selectData = data
                        ? data.filter((c: any) => groupFilter && groupFilter(c, name))
                        : [];
                    return selectData.length > 0 ? (
                        <OptGroup key={name} label={name}>
                            {renderOptions(selectData)}
                        </OptGroup>
                    ) : (
                        renderOptions(selectData)
                    );
                });
            } else {
                return renderOptions(data);
            }
        };

        const renderOptions = (data: any) => {
            return (
                data &&
                data.map((item: any, index: number) => (
                    <Option
                        data-test={
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).text.replace(/\s/g, "")
                                : item[selectOptionField].replace(/\s/g, "")
                        }
                        disabled={
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).disabled === true
                                : false
                        }
                        key={item[selectIdField]}
                        value={item[selectIdField]}
                        {...(item.color ? { style: { color: item.color } } : {})}
                        data-text={
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).text
                                : item[selectOptionField]
                        }
                        optionFilterProp={
                            typeof selectOptionField === "function"
                                ? selectOptionField(item).text
                                : item[selectOptionField]
                        }>
                        {typeof selectOptionField === "function"
                            ? selectOptionField(item).node
                            : item[selectOptionField]}
                    </Option>
                ))
            );
        };

        const SELECT_DESELECT_ALL = `--${t("selectAllColumns")}--`;
        const ids = data.map((item: any) => item[selectIdField]);
        return (
            <Select
                ref={ref as any}
                mode="multiple"
                value={value && value.some(v => ids.includes(v)) ? value : []}
                onChange={(values: number[]) =>
                    handleChange(
                        values,
                        values ? data.filter((x: any) => values.includes(x[selectIdField])) : []
                    )
                }
                filterOption={(inputValue: any, option: any) => {
                    if (!option) return false;
                    return (
                        inputValue !== SELECT_DESELECT_ALL.toLocaleLowerCase() &&
                        option["optionFilterProp"] &&
                        option["optionFilterProp"]
                            .toLocaleLowerCase()
                            .includes(inputValue.toLocaleLowerCase())
                    );
                }}
                notFoundContent={
                    loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                dropdownClassName={classnames("dropdownWrapper", rest.dropdownClassName)}
                getPopupContainer={(node: any) =>
                    node ? (node.parentNode as HTMLElement) : document.body
                }
                {...rest}>
                {selectAll && data.length > 0 && (
                    <Option value={-1} key={0} data-test="selectAll">
                        {SELECT_DESELECT_ALL}
                    </Option>
                )}
                {renderOptionsStructure()}
            </Select>
        );
    }
);

export { SelectDataMultiple };
