import { useEffect, useState } from "react";
import Provider from "services/api";

const useAutomatedSurveyTitles = () => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            const data = await Provider.AutomatedSurvey.getAutomatedSurveyTitles().catch(
                (err: any) => {
                    setState({ loading: false, data: [] });
                    return Promise.reject(err);
                }
            );
            let surveyTiles: any[] = data.map((x: any) => ({
                id: x.id,
                title: `${x.title} (id:${x.id})`,
            }));
            setState({
                loading: false,
                data: surveyTiles,
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAutomatedSurveyTitles };
