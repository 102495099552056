import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AttachArea,
    DeleteIcon,
    File02,
    LoadingIndicator,
    PlusCircle,
    Trash02,
    useAttach,
} from "components/utilitycomponents";
import { Row, Col, Progress, notification, Modal, Button, Checkbox, Input, Form, Tabs } from "antd";
import { useFolderUpdate, usePerformanceFileCrud } from "controller";
import classes from "./performanceFiles.module.scss";

type PropTypes = {
    visible: boolean;
    onClose: (reload?: boolean) => void;
    partnerId: number;
};

const AddModal = ({ visible, onClose, partnerId }: PropTypes) => {
    const [fileForm] = Form.useForm();
    const [linkForm] = Form.useForm();
    const { t } = useTranslation();
    const {
        // checkFileNames,
        loading: checkFileNameLoading,
    } = useFolderUpdate();
    const [clearFileList, setClearFileList] = useState<any>({ clear: false });
    const [duplicateFileModal, setDuplicateFileModal] = useState<any>();
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [itemType, setItemType] = useState<string>("files");
    const { upsert: addLink, loading } = usePerformanceFileCrud();

    useEffect(() => {
        setItemType("files");
        if (!visible) {
            setSelectedFiles([]);
        } else {
            fileForm.resetFields();
            linkForm.resetFields();
        }
    }, [visible]);

    const { handleUpload, uploadInProgress, newFiles } = useAttach({
        onChange: (fileIds: number[]) => {
            if (fileIds && fileIds.length > 0) {
                notification.success({
                    message: t("success"),
                    description: t("file_UploadFilesSuccess", { count: fileIds.length }),
                });
                onClose(true);
            } else {
                onClose();
            }
            setClearFileList({ clear: true });
        },
        notification,
        maxFileSize: 40,
        // folderId: folder.value,//TO DO
        clearFileList,
    });

    useEffect(() => {
        setDuplicateFileModal({
            fileNames: [],
            visible: false,
            onClose: () => {},
        });
    }, [visible]);

    const handleSelectFile = async (files: any) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleFileUpload = async () => {
        let values = await fileForm.validateFields();

        selectedFiles.map(
            (file: any, index: number) => (file.title = values["file" + index].title)
        );
        selectedFiles.map((file: any, index: number) => (file.partnerId = partnerId));

        await handleUpload(selectedFiles, undefined, "PerformanceManagement");
    };

    const removeFile = (fileName: string) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };

    const handleLinkUpload = async () => {
        let values = await linkForm.validateFields();

        let link = {
            title: values.title,
            partnerId: partnerId,
            url: values.url,
        };

        await addLink(link);
        onClose(true);
    };

    return (
        <>
            <Modal
                bodyStyle={{ paddingTop: 0 }}
                open={visible}
                footer={[
                    <Button
                        type="primary"
                        disabled={itemType === "files" && selectedFiles.length === 0}
                        onClick={async () => {
                            if (itemType === "files") {
                                fileForm.submit();
                            } else {
                                linkForm.submit();
                            }
                        }}
                        loading={loading}>
                        {t("generic_Add")}
                    </Button>,
                ]}
                onCancel={() => onClose()}
                destroyOnClose>
                <Tabs
                    animated={false}
                    defaultActiveKey="files"
                    onChange={(activeKey: string) => setItemType(activeKey)}>
                    <Tabs.TabPane tab="Files" key="files">
                        <div>
                            <AttachArea
                                icon={<PlusCircle />}
                                onSelect={handleSelectFile}
                                loading={uploadInProgress || checkFileNameLoading}
                                style={{ width: "100%", height: "100px" }}
                            />
                        </div>
                        <div>
                            <div style={{ marginTop: "5px" }}>
                                {t("maximumAllowedSize", { maxFileSize: 40 })}
                            </div>

                            {newFiles.map((file: any, index: number) => (
                                <Fragment key={index}>
                                    <Row style={{ lineHeight: "20px" }}>
                                        <Col span={24}>{file.name}</Col>
                                    </Row>
                                    <Row style={{ lineHeight: "20px" }}>
                                        <Col span={1} style={{ textAlign: "center" }}>
                                            <LoadingIndicator />
                                        </Col>
                                        <Col span={21}>
                                            <Progress
                                                percent={file.progress}
                                                size="small"
                                                status={file.progress < 100 ? "active" : "normal"}
                                            />
                                        </Col>
                                        <Col span={2} style={{ textAlign: "center" }}>
                                            {file.progress < 100 && (
                                                <DeleteIcon
                                                    fontSize={"70px"}
                                                    onClick={() => file.source.cancel()}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Fragment>
                            ))}
                        </div>
                        <div style={{ marginTop: 12 }}>
                            <Form form={fileForm} onFinish={handleFileUpload}>
                                {selectedFiles.map((file: any, index: number) => (
                                    <Row>
                                        <Col span={2}>
                                            <File02 />
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                name={[`file${index}`, "title"]}
                                                style={{ marginBottom: 0, marginTop: 8 }}>
                                                <Input
                                                    placeholder={t("file_TitlePlaceholder")}
                                                    maxLength={200}
                                                />
                                            </Form.Item>
                                            <div>{file.name}</div>
                                            <Form.Item
                                                name={[`file${index}`, "file"]}
                                                hidden
                                                initialValue={file}></Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Trash02
                                                className={classes.removeIcon}
                                                onClick={() => removeFile(file.name)}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Form>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Link" key="link" style={{ minHeight: 139 }}>
                        <Form
                            form={linkForm}
                            onFinish={handleLinkUpload}
                            labelAlign="left"
                            labelCol={{ span: 3 }}>
                            <Form.Item
                                name="url"
                                label={t("generic_Url")}
                                rules={[{ type: "url", whitespace: true, required: true }]}>
                                <Input maxLength={4000} placeholder={t("generic_UrlPlaceholder")} />
                            </Form.Item>
                            <Form.Item name="title" label={t("generic_Title")}>
                                <Input
                                    maxLength={200}
                                    placeholder={t("generic_TitlePlaceholder")}
                                    max={200}
                                />
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
            <DuplicateFileModal {...duplicateFileModal} />
        </>
    );
};

const DuplicateFileModal = ({ fileDescriptions, visible, onClose }: any) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<any>({});
    const [index, setIndex] = useState(0);
    const [resolveAll, setResolveAll] = useState(false);

    useEffect(() => {
        if (visible === true) {
            setFiles({});
            setIndex(0);
            setResolveAll(false);
        }
    }, [visible]);

    const uploadAndReplace = () => {
        let newFiles = {
            ...files,
            [fileDescriptions[index].name]: fileDescriptions[index].name,
        };
        setFiles(newFiles);
        resolveAll ? resolveAllFiles("REPLACE") : resolveNextFile(newFiles);
    };
    const doNotUpload = () => {
        resolveAll ? resolveAllFiles("NONE") : resolveNextFile(files);
    };
    const uploadAndKeep = () => {
        let newFiles = {
            ...files,
            [fileDescriptions[index].name]: fileDescriptions[index].rename,
        };
        setFiles(newFiles);
        resolveAll ? resolveAllFiles("KEEP_BOTH") : resolveNextFile(newFiles);
    };

    const resolveNextFile = (newFiles: any) => {
        if (index + 1 === fileDescriptions.length) {
            onClose(newFiles);
        } else {
            setIndex(index + 1);
        }
    };

    const resolveAllFiles = (type: string = "NONE") => {
        let newFiles: any = {};
        switch (type) {
            case "REPLACE":
                fileDescriptions.forEach((f: any) => {
                    newFiles = {
                        ...newFiles,
                        [f.name]: f.name,
                    };
                });
                break;
            case "KEEP_BOTH":
                fileDescriptions.forEach((f: any) => {
                    newFiles = {
                        ...newFiles,
                        [f.name]: f.rename,
                    };
                });
                break;
        }
        onClose(newFiles);
    };

    return fileDescriptions && fileDescriptions[index] ? (
        <Modal
            wrapProps={{ "data-test": "folder_upload_modal" }}
            title={fileDescriptions[index].name}
            open={visible}
            footer={[
                <Row key={0}>
                    <Col span={16} style={{ textAlign: "left" }}>
                        {index === 0 && fileDescriptions.length > 1 && (
                            <Fragment>
                                <Checkbox
                                    onChange={(e: any) => {
                                        if (e.target.checked) {
                                            setResolveAll(true);
                                        }
                                    }}
                                />{" "}
                                {t("file_DuplicateBatchConflictResolve", {
                                    count: fileDescriptions.length - 1,
                                })}
                            </Fragment>
                        )}
                    </Col>

                    <Col span={8} style={{ textAlign: "right" }}>
                        <Button key="skip" onClick={doNotUpload} type="primary">
                            {t("generic_Skip")}
                        </Button>
                    </Col>
                </Row>,
            ]}
            onCancel={() => resolveAllFiles()}>
            <Fragment>
                <Row style={{ marginBottom: "15px", marginTop: "5px", fontWeight: "bold" }}>
                    {t("file_DuplicateMessage")}
                </Row>

                <Row>
                    <Button type="primary" onClick={uploadAndReplace}>
                        {t("file_UploadAndReplace")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_UploadAndReplaceMessage")}
                </Row>
                <Row>
                    <Button type="primary" onClick={doNotUpload}>
                        {t("file_DoNotUpload")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_DoNotUploadMessage")}
                </Row>
                <Row>
                    <Button type="primary" onClick={uploadAndKeep}>
                        {t("file_UploadAndKeep")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_UploadAndKeepMessage", {
                        newName: fileDescriptions[index].rename,
                    })}
                </Row>
            </Fragment>
        </Modal>
    ) : null;
};
export default AddModal;
