import { Flex, Result, Skeleton, Statistic, Tooltip } from "antd";
import React from "react";
import {
    useSupplierReviewAverage,
    useSupplierReviewAverageByCriteriaGroup,
    useSupplierCustomerNames,
} from "controller";
import classes from "./supplierLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaceFrown, FaceHappy, FaceNeutral } from "components/utilitycomponents";

const ReviewMetric = () => {
    const { data: overallAverage, loading } = useSupplierReviewAverage();
    const { data: criteriaGroupAverages } = useSupplierReviewAverageByCriteriaGroup();
    const { data: customerNames } = useSupplierCustomerNames();
    const { t } = useTranslation("supplierPortal");

    const getIcon = () => {
        if (criteriaGroupAverages?.length === 0) {
            return null;
        } else if (overallAverage <= 5 && overallAverage >= 4) {
            return <FaceHappy style={{ fontSize: 72, color: "#73d13d" }} />;
        } else if (overallAverage >= 2 && overallAverage < 4) {
            return <FaceNeutral style={{ fontSize: 72, color: "#ff7a45" }} />;
        } else {
            return <FaceFrown style={{ fontSize: 72, color: "#ff4d4f" }} />;
        }
    };

    const renderGroupAverages = () => {
        return criteriaGroupAverages.map((criteriaGroupAverage: any, index: number) => (
            <Statistic
                key={index}
                title={
                    criteriaGroupAverage.reviewCategoryGroupName === "_unGrouped"
                        ? t("review_UnGrouped")
                        : criteriaGroupAverage.reviewCategoryGroupName
                }
                value={criteriaGroupAverage.average.toPrecision(2)}
            />
        ));
    };

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Result
                    className={classes.resultContainer}
                    icon={
                        <Flex justify="center" style={{ height: 76 }}>
                            <Tooltip
                                title={
                                    <>
                                        <Flex justify="flex-start">
                                            <div style={{ fontSize: 18, marginRight: 8 }}>
                                                4 - 5.0
                                            </div>
                                            <FaceHappy style={{ fontSize: 18, color: "#73d13d" }} />
                                        </Flex>
                                        <Flex justify="flex-start">
                                            <div style={{ fontSize: 18, marginRight: 8 }}>
                                                2 - 3.9
                                            </div>
                                            <FaceNeutral
                                                style={{ fontSize: 18, color: "#ff7a45" }}
                                            />
                                        </Flex>
                                        <Flex justify="flex-start">
                                            <div style={{ fontSize: 18, marginRight: 8 }}>
                                                0 - 1.9
                                            </div>
                                            <FaceFrown style={{ fontSize: 18, color: "#ff4d4f" }} />
                                        </Flex>
                                    </>
                                }
                                placement="right">
                                <div>{getIcon()} </div>
                            </Tooltip>
                        </Flex>
                    }
                    title={
                        criteriaGroupAverages?.length !== 0 ? (
                            <span>
                                <span>
                                    Your overall <Link to="/Reviews">review</Link> score is{" "}
                                </span>
                                <span className={classes.count}>
                                    <Tooltip title={t("review_LandingPageComponentText4")}>
                                        {overallAverage.toPrecision(2)}
                                    </Tooltip>
                                </span>{" "}
                                {t("review_LandingPageComponentText2", {
                                    count: criteriaGroupAverages?.length,
                                })}{" "}
                                {customerNames.length === 1 ? (
                                    <span className={classes.customerName}>{customerNames[0]}</span>
                                ) : (
                                    t("generic_YourCustomer")
                                )}
                                .
                            </span>
                        ) : (
                            <span>
                                {customerNames.length === 1 ? (
                                    <span className={classes.customerName}>{customerNames[0]}</span>
                                ) : (
                                    t("generic_YourCustomer")
                                )}{" "}
                                {t("review_LandingPageComponentText3")}
                            </span>
                        )
                    }
                    extra={<Flex justify="space-evenly">{renderGroupAverages()}</Flex>}
                />
            )}
        </>
    );
};

export { ReviewMetric };
