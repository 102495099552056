import { post, remove, get, patch } from "../base";

const Activity = {
    activityList: async (pageNumber: number, pageSize: number, { customError }: any = {}) => {
        const url = `/v1/Activity/Overview?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        return await post({ url, customError });
    },
    getActivitiesTotalCount: async ({ customError }: any = {}) => {
        const url = `/v1/Activity/GetActivitiesTotalCount`;
        return await get({ url, customError });
    },
    createMessage: async (message: any) => {
        const url = `/v1/Activity/Message`;
        return await post({ url, payload: message });
    },
    createChildMessage: async (
        message: string,
        parentActivityId: number | null,
        contactActionActivity: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/Activity/Comment`;
        return await post({
            url,
            payload: { message, parentActivityId, isContactAction: contactActionActivity },
            customError,
        });
    },
    updateFollowing: async (activityId: number, isFollow: boolean) => {
        const url = `/v1/Activity/SetFollowing?activityId=${activityId}&isFollow=${isFollow}`;
        return await post({ url });
    },
    remove: async (activityId: number) => {
        const url = `/v1/Activity/${activityId}`;
        return await remove({ url });
    },
    createFileUploadActivity: async (
        fileIds: number[],
        parentActivityId: number,
        contactActionActivity: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/Activity/CreateFileUploadActivity`;
        return await post({
            url,
            payload: { fileIds, parentActivityId, IsContactAction: contactActionActivity },
            customError,
        });
    },
    updateComment: async (activityId: number, comment: any, { customError }: any = {}) => {
        const url = `/v1/Activity/Comment?activityId=${activityId}`;
        await patch({ url, payload: { value: comment }, customError });
    },
};

export default Activity;
