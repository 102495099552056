import React, { useState } from "react";
import classes from "./rapidReview.module.scss";
import { Button, Card, Flex, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { usePartnerReviewAverage, useReviewCriteriaDefault } from "controller";
import { colorGrade } from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import { ReviewRate } from "components/utilitycomponents";
import { RapidReviewModal } from "./rapidReviewModal";
import CreationModal from "components/contentcomponents/reviews/review/modals/create/creationModal";
import dayjs from "utilities/daysJsConfig";
import _ from "lodash";

interface IProps {
    partnerId: number;
}

const RapidReview = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [trigger, setTrigger] = useState<any>({ trigger: true });
    const { data, loading } = usePartnerReviewAverage(
        trigger,
        partnerId,
        dayjs()
            .subtract(1, "year")
            .toISOString(),
        dayjs().toISOString(),
        null,
        false
    );
    const { data: defaultCriteria } = useReviewCriteriaDefault();
    const [modal, setModal] = useState<boolean>(false);
    const [creationModal, setCreationModal] = useState<boolean>(false);

    return (
        <>
            <Card
                size="small"
                title={
                    <Flex justify="space-between">
                        <div className={classes.cardTitle}>
                            {t("supplierProfile_summary_RapidReview").toUpperCase()}{" "}
                        </div>
                        <div>
                            {data && data.average !== null && (
                                <Button
                                    style={{ marginTop: -5 }}
                                    type="link"
                                    onClick={() => setCreationModal(true)}>
                                    {t("generic_Add")}
                                </Button>
                            )}
                        </div>
                    </Flex>
                }>
                <Spin spinning={loading}>
                    {data && data.average !== null ? (
                        <>
                            {defaultCriteria && defaultCriteria.name && (
                                <Flex justify="center" align="center">
                                    <div style={{ height: 32 }}>
                                        {defaultCriteria.name.indexOf("ReviewCategory_") !== -1
                                            ? t(defaultCriteria.name)
                                            : _.truncate(defaultCriteria.name, {
                                                  length: 45,
                                                  separator: "...",
                                              })}
                                    </div>
                                </Flex>
                            )}
                            <Flex justify="center" align="center">
                                <div style={{ display: "inline-block" }}>
                                    <div
                                        style={{
                                            color: colorGrade(data && data.average),
                                            fontSize: 26,
                                            marginRight: 8,
                                            display: "inline-block",
                                        }}>
                                        {data && data.average ? data.average.toPrecision(2) : "-"}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        <ReviewRate
                                            style={{ fontSize: 28 }}
                                            allowHalf={true}
                                            val={data && data.average}
                                        />
                                    </div>
                                </div>
                            </Flex>
                            <Flex justify="center">
                                <Button type="link" onClick={() => setModal(true)}>
                                    {t("supplierProfile_summary_RapidReviewButtonText")}
                                </Button>
                            </Flex>
                        </>
                    ) : (
                        <Flex justify="center" align="center">
                            <Button
                                style={{ marginTop: 6 }}
                                type="primary"
                                onClick={() => setCreationModal(true)}>
                                {t("review_CreateReview3")}
                            </Button>
                        </Flex>
                    )}
                </Spin>
            </Card>

            <CreationModal
                visible={creationModal}
                onVisibleChange={() => setCreationModal(false)}
                reloadTable={() => setTrigger({ trigger: true })}
                partnerId={partnerId}
                disableCriteriaSelection={true}
            />
            <RapidReviewModal
                partnerId={partnerId}
                visible={modal}
                onClose={() => setModal(false)}
            />
        </>
    );
};

export { RapidReview };
