import { useState } from "react";
import { Provider } from "services";

const useContactsByEmail = () => {
    const [loading, setLoading] = useState(false);

    const getContactsByEmail: any = async (email: string, exclusionId?: number) => {
        if (email === undefined || email === null || email === "") return [];
        setLoading(true);
        const result = await Provider.Contact.getContactsByEmail(email, exclusionId, {
            customError: {
                message: "Could not get contacts by email address",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading,
        getContactsByEmail,
    };
};

export { useContactsByEmail };
