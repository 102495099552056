import { useState } from "react";
import { Provider } from "services";

const useSubmitSurveyAnswers = () => {
    const [loading, setLoading] = useState(false);

    const submitAnswers = async (values: any, surveyToken: string, contactId?: string) => {
        setLoading(true);

        let parsedReviews: any[] = [];
        if (values.reviewRatings) {
            for (const [key, value] of Object.entries(values.reviewRatings)) {
                let subStrings = key.split("-");
                let categoryId = parseInt(subStrings[0]);
                let supplierId = parseInt(subStrings[1]);
                let analysisUnitId = parseInt(subStrings[2]);
                let notes =
                    values.reviewComments &&
                    Object.entries(values.reviewComments).filter(
                        ([xKey, xValue]) => xKey === key
                    )[0] &&
                    Object.entries(values.reviewComments).filter(
                        ([xKey, xValue]) => xKey === key
                    )[0][1];
                if (notes || value) {
                    parsedReviews.push({
                        reviewCategoryId: categoryId,
                        partnerId: supplierId,
                        analysisUnitId: analysisUnitId,
                        rating: value,
                        notes: notes,
                    });
                }
            }
        }

        if (parsedReviews.length === 0) {
            setLoading(false);
            return 0;
        }

        let parsedSupplierTextFieldAnswers: any[] = [];
        if (values.supplierTextFieldAnswers) {
            for (const [key, value] of Object.entries(values.supplierTextFieldAnswers)) {
                if (value) {
                    let subStrings = key.split("-");
                    let headlineId = parseInt(subStrings[0]);
                    let supplierId = parseInt(subStrings[1]);
                    parsedSupplierTextFieldAnswers.push({
                        answer: value,
                        headlineId: headlineId,
                        supplierId: supplierId,
                    });
                }
            }
        }
        let parsedSupplierCustomDropdownAnswers: any[] = [];
        if (values.supplierCustomDropdownAnswers) {
            for (const [key, value] of Object.entries(values.supplierCustomDropdownAnswers)) {
                if (value) {
                    let subStrings = key.split("-");
                    let headlineId = parseInt(subStrings[0]);
                    let supplierId = parseInt(subStrings[1]);
                    parsedSupplierCustomDropdownAnswers.push({
                        answer: value,
                        headlineId: headlineId,
                        supplierId: supplierId,
                    });
                }
            }
        }
        let parsedSurveyCustomDropdownAnswers: any[] = [];
        if (values.surveyCustomDropdownAnswers) {
            for (const [key, value] of Object.entries(values.surveyCustomDropdownAnswers)) {
                if (value) {
                    let headlineId = parseInt(key);
                    parsedSurveyCustomDropdownAnswers.push({
                        answer: value,
                        headlineId: headlineId,
                    });
                }
            }
        }
        let parsedSurveyTextFieldAnswers: any[] = [];
        if (values.surveyTextFieldAnswers) {
            for (const [key, value] of Object.entries(values.surveyTextFieldAnswers)) {
                if (value) {
                    let headlineId = parseInt(key);
                    parsedSurveyTextFieldAnswers.push({
                        answer: value,
                        headlineId: headlineId,
                    });
                }
            }
        }

        let payload = {
            reviews: parsedReviews,
            supplierTextFieldAnswers: parsedSupplierTextFieldAnswers,
            supplierCustomDropdownAnswers: parsedSupplierCustomDropdownAnswers,
            surveyCustomDropdownAnswers: parsedSurveyCustomDropdownAnswers,
            surveyTextFieldAnswers: parsedSurveyTextFieldAnswers,
        };

        await Provider.Survey.submitSurveyAnswers(payload, surveyToken, contactId, {
            retryCount: 3,
            customError: { message: "Could not submit survey answers" },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return payload.reviews.length;
    };

    return { loading, submitAnswers };
};

export { useSubmitSurveyAnswers };
