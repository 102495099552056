import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: [];
    loading: boolean;
};

const usePartnerCategoriesByPartnerId = (
    partnerId: number,
    reload: any = null,
    type?: "FirstCategory" | "SecondCategory" | undefined
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Partner.getPartnerCategoriesByPartnerId(partnerId, type);
            setState({ loading: false, data });
        })();
    }, [reload, partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerCategoriesByPartnerId };
