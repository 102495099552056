import React, { FunctionComponent, useEffect, useState } from "react";
import { Divider, Spin, Row, Col, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InfoSection } from "./infoSection";
import { useSegmentParentInfo, useSegmentationReviewGroupNames } from "controller";
import { colorGrade } from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import AlignModal from "../unitOfAnalysis/alignModal/alignModal";
import classes from "./segmentationInfo.module.scss";
import { LabelSection } from "./labelSection";
import { InfoCircle } from "components/utilitycomponents";

interface IProps {
    partnerId: number;
    reload: any;
    accumulatedValues: any;
    onAlign: Function;
    onInfoLoaded: Function;
}

const SegmentationInfo: FunctionComponent<IProps> = ({
    partnerId,
    reload,
    accumulatedValues,
    onAlign,
    onInfoLoaded,
}) => {
    const { t } = useTranslation();
    const { loading, data } = useSegmentParentInfo(partnerId, reload);
    const [alignModal, setAlignModal] = useState<any>({ visible: false });
    const [parentData, setParentData] = useState<any>(undefined);
    const { data: xyNames, loading: loadingNames } = useSegmentationReviewGroupNames();

    useEffect(() => {
        if (data) {
            let parentData = {
                alignedSegmentCurrent: {
                    id: data.currentAlignedSegmentId,
                    restrictions: data.currentSegmentRestrictions,
                    comment: data.currentSegmentComment,
                },
                suggestedSegmentCurrent: {
                    segmentColor: data.suggestedSegmentColor,
                    segmentName: data.suggestedSegment,
                },
                alignedSegmentFuture: {
                    id: data.futureAlignedSegmentId,
                },
                id: data.unitOfAnalysisId,
            };
            setParentData(parentData);
        }
    }, [data, partnerId]);

    useEffect(() => {
        if (!loading) {
            onInfoLoaded(data);
        }
    }, [loading]);

    return (
        <>
            <Spin size="large" spinning={loading}>
                <div className={classes.infoContainer}>
                    <div className={classes.infoHeader}>
                        <div>
                            {t("segmentation_ParentLevelInfo")}
                            <Button
                                style={{ marginLeft: 16, width: 195, display: "inline-block" }}
                                type="primary"
                                onClick={() => setAlignModal({ visible: true })}>
                                {t("segmentation_alignParent")}
                            </Button>
                        </div>
                    </div>

                    <div className={classes.labels}>
                        <LabelSection />
                    </div>
                    <div className={classes.middle}>
                        <Divider type="vertical" className={classes.verticalDivider} />
                    </div>
                    <div className={classes.left}>
                        <InfoSection type="current" data={data!} />
                    </div>

                    <div className={classes.right}>
                        <InfoSection type="future" data={data!} />
                    </div>
                </div>
                <div className={classes.horiDivider}>
                    <Divider dashed orientation="left" orientationMargin={0}>
                        <span className={classes.partHeader}>
                            {t("segmentation_ConsolidatedValues")}
                        </span>{" "}
                        <Tooltip placement="bottom" title={t("segmentation_AccumulatedValuesInfo")}>
                            <span>
                                <InfoCircle />
                            </span>
                        </Tooltip>
                    </Divider>
                </div>
                <Row>
                    <Col xxl={6} xl={8} lg={9} md={12} sm={12} className={classes.colMargin}>
                        <span className={classes.infoLabel}>
                            {!loadingNames && xyNames && xyNames.activeXName
                                ? `${xyNames.activeXName} ${t("generic_Average2")}`
                                : t("unitOfAnalysis_AlignmentAverage")}
                        </span>
                        <span
                            className={classes.averageBlock}
                            style={{
                                backgroundColor: accumulatedValues
                                    ? colorGrade(accumulatedValues.xAverage)
                                    : "#ff4d4f",
                            }}>
                            {accumulatedValues
                                ? accumulatedValues.xAverage &&
                                  accumulatedValues.xAverage.toPrecision(2)
                                : 0}
                        </span>
                    </Col>
                    <Col xxl={6} xl={8} lg={9} md={12} sm={12} className={classes.colMargin}>
                        <span className={classes.infoLabel}>
                            {!loadingNames && xyNames && xyNames.activeYName
                                ? `${xyNames.activeYName} ${t("generic_Average2")}`
                                : t("unitOfAnalysis_ImpactAverage")}
                        </span>
                        <span
                            className={classes.averageBlock}
                            style={{
                                backgroundColor: accumulatedValues
                                    ? colorGrade(accumulatedValues.yAverage)
                                    : "#ff4d4f",
                            }}>
                            {accumulatedValues
                                ? accumulatedValues.yAverage &&
                                  accumulatedValues.yAverage.toPrecision(2)
                                : 0}
                        </span>
                    </Col>
                    <Col xxl={8} xl={8} lg={6} md={6} sm={12}>
                        <span className={classes.infoLabel}>
                            {t("segmentation_SuggestSegment")}
                        </span>

                        <span
                            style={{
                                border: `1px solid ${
                                    accumulatedValues
                                        ? accumulatedValues.suggestedSegment &&
                                          accumulatedValues.suggestedSegment.color
                                        : "transparent"
                                }`,
                                color: accumulatedValues
                                    ? accumulatedValues.suggestedSegment &&
                                      accumulatedValues.suggestedSegment.color
                                    : "black",
                                fontWeight: "bold",
                                textAlign: "center",
                                padding: 4,
                                marginLeft: 14,
                            }}>
                            {accumulatedValues
                                ? accumulatedValues.suggestedSegment &&
                                  accumulatedValues.suggestedSegment.segment
                                : "-"}
                        </span>
                    </Col>
                </Row>
            </Spin>
            <AlignModal
                visible={alignModal.visible}
                onClose={() => setAlignModal({ visible: false })}
                record={parentData}
                onSuccess={() => onAlign()}
                onParentAlign={() => onAlign()}
                partnerId={partnerId}
            />
        </>
    );
};

export { SegmentationInfo };
