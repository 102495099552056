import { useState } from "react";
import { Provider } from "services";

const useProfilePictureUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateProfilePicture = async (fileId: number | null) => {
        setLoading(true);

        await Provider.User.updateProfilePicture(fileId, {
            customError: {
                message: "Could not update profile picture",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updateProfilePicture,
    };
};

export { useProfilePictureUpdate };
