import React, { FunctionComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";
import { IActivityChild } from "models";

type PropsType = {
    data: IActivityChild;
};

const ClosedNcr: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("from")} {data.fromOrOnPartner} {t("activity_CloseNcr")}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IActivityChild) => {
        return (
            <Fragment>
                {data.creator_FullName} {t("activity_CloseNcr")}
            </Fragment>
        );
    };

    return (
        <span className={classes.notificationContainer}>
            <div data-test="closed_ncr" className={classes.notificationTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            <Link to={`/Ncrs/${data.entityId}`} className={classes.notificationLink}>
                <RightCircleFilled />
                View NCR
            </Link>
        </span>
    );
};

export { ClosedNcr };
