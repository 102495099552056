import React, { useEffect, useState } from "react";
import { GridLight, Share07 } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import classes from "./../../summary.module.scss";
import { Button, Progress, Radio, Tooltip } from "antd";
import { EditModal } from "../../../governance/meetingCalendar/editModal";
import { ShareModal } from "../../../governance/meetingCalendar/shareModal";
import { useMeetingMinutes } from "controller";
import { CreateModal } from "components/contentcomponents/suppliers/supplierProfile/enterprisePages/governance/meetingCalendar/createModal";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    onMeetingCreated: Function;
    reloadTable: any;
    onSwitchClick: Function;
    partnerId: number;
}

const PastMeetings = ({ onMeetingCreated, reloadTable, onSwitchClick, partnerId }: IProps) => {
    const { t } = useTranslation();
    const [shareModal, setShareModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const [editModal, setEditModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading } = useMeetingMinutes(reload, partnerId);
    const [createModal, setCreateModal] = useState<boolean>(false);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadTable]);

    const getStrokeColor = (val: number, maxNumber: number) => {
        let percentage = (val / maxNumber) * 100;
        if (percentage === 100) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        } else if (percentage < 100 && percentage >= 50) {
            return {
                "0%": "#fa8c16",
                "100%": "#fa8c16",
            };
        } else {
            return {
                "0%": "#f5222d",
                "100%": "#f5222d",
            };
        }
    };

    const columns: any[] = [
        {
            title: <div className={classes.colTitle}>{t("governance_meetingDate")}</div>,
            dataIndex: "date",
            key: "date",
            width: 100,
            render: (val: string) => <div>{val && dayjs(val).format("MMM D, YYYY")}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingType")}</div>,
            dataIndex: "type",
            key: "type",
            ellipsis: true,
            width: 100,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingTitle")}</div>,
            dataIndex: "title",
            ellipsis: true,
            key: "title",
            width: 150,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingActions")}</div>,
            dataIndex: "completeActionCount",
            key: "actions",
            width: 150,
            render: (val: number, row: any) => (
                <div style={{ width: 125 }}>
                    {row.actionCount && row.actionCount > 0 ? (
                        <Progress
                            success={{ percent: 0 }}
                            size="small"
                            percent={(val / row.actionCount) * 100}
                            format={val => (
                                <span>{`${row.completeActionCount} / ${row.actionCount}`}</span>
                            )}
                            strokeWidth={17}
                            strokeColor={getStrokeColor(val, row.actionCount)}
                        />
                    ) : null}
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "shareCol",
            key: "shareCol",
            width: 25,
            operationColumn: true,
            render: (val: any, row: any) => (
                <div>
                    {dayjs(row.date).isBefore(dayjs()) && (
                        <Tooltip mouseEnterDelay={0.5} title="Share Meeting Minute">
                            <span>
                                <Share07
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShareModal({ visible: true, id: row.id });
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ];

    const handleOnRow = (row: any) => ({
        onClick: () => {
            setEditModal({ visible: true, id: row.id });
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <>
            <GridLight
                title={() => (
                    <div>
                        <div className={classes.tableTitle}>
                            {t("governance_Meetings").toLocaleUpperCase()}
                        </div>
                        <div className={classes.tableTitleSwitch}>
                            <Radio.Group
                                options={[
                                    { label: "Upcoming", value: "upcoming" },
                                    { label: "Past", value: "past" },
                                ]}
                                onChange={() => onSwitchClick()}
                                value={"past"}
                                optionType="button"
                                size="small"
                            />
                        </div>
                        {data.pastMeetings.length !== 0 && (
                            <div className={classes.tableTitleAddBtn}>
                                <Button
                                    style={{ marginRight: -10 }}
                                    type="link"
                                    onClick={() => setCreateModal(true)}>
                                    <span>{t("generic_Add")}</span>
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                className={classes.customTableStyle}
                dataSource={data.pastMeetings.slice(0, 5)}
                onRow={handleOnRow}
                columns={columns}
                tableSize="small"
                pagination="none"
                loading={loading}
                bordered={false}
                renderTableOnDataChange
                showHeader={false}
                emptyText={
                    <Button type="primary" onClick={() => setCreateModal(true)}>
                        Add Meeting
                    </Button>
                }
            />
            <CreateModal
                visible={createModal}
                onClose={(reload: boolean) => {
                    if (reload) {
                        setReload({ reload: true });
                        onMeetingCreated();
                    }
                    setCreateModal(false);
                }}
                partnerId={partnerId}
                centered
            />
            <EditModal
                partnerId={partnerId}
                visible={editModal.visible}
                meetingId={editModal.id}
                onClose={() => setEditModal({ visible: false, id: undefined })}
                onActionInteraction={() => setReload({ reload: true })}
                centered
            />
            <ShareModal
                visible={shareModal.visible}
                onClose={() => setShareModal({ visible: false, id: undefined })}
                meetingId={shareModal.id}
                supplierId={partnerId}
            />
        </>
    );
};

export { PastMeetings };
