import React, { FunctionComponent, useEffect, useState } from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { SelectDataMultiple, FormRow, FormLabel, SelectData } from "components/utilitycomponents";
import classes from "./selectSupplier.module.scss";

type PropTypes = {
    selectedParent: any;
    clearData: any;
    onChange: Function;
};
const SelectSupplierChildren: FunctionComponent<PropTypes> = ({
    selectedParent,
    onChange,
    clearData,
}) => {
    const { t } = useTranslation();

    const [selectedChild, setSelectedChild] = useState<any>({});

    useEffect(() => {
        setSelectedChild({});
    }, [clearData]);

    useEffect(() => {
        setSelectedChild({});
        onChange([]);
    }, [selectedParent]);

    const createChildren: any = (parent: any, level = 1) =>
        parent && parent.children ? (
            parent.children.some((child: any) => child.children !== null) ? (
                <>
                    <FormRow>
                        <Col span={24}>
                            <FormLabel>Supplier(Child Level {level})</FormLabel>
                            <SelectData
                                value={
                                    selectedChild[level] && selectedChild[level].id
                                        ? selectedChild[level].id
                                        : undefined
                                }
                                useData={() => ({
                                    data: parent.children,
                                    loading: false,
                                })}
                                selectOptionField={(item: any) => ({
                                    text: `${item.name} ${item.number}`,
                                    node: (
                                        <span>
                                            {item.name} {item.number}
                                        </span>
                                    ),
                                })}
                                className={classes.selectBox}
                                style={{ width: "100%" }}
                                placeholder={t("select")}
                                onChange={(_: any, item: any) => {
                                    let selected = { ...selectedChild };
                                    for (let key in selected) {
                                        if (Number(key) > level) selected[key] = undefined;
                                    }
                                    selected = { ...selected, [level]: item };
                                    setSelectedChild(selected);
                                    if (item) {
                                        onChange([item]);
                                    } else {
                                        onChange([]);
                                    }
                                }}
                                allowClear
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Col>
                    </FormRow>
                    {createChildren(
                        selectedChild[level] ? selectedChild[level] : undefined,
                        level + 1
                    )}
                </>
            ) : (
                <FormRow>
                    <Col span={24}>
                        <FormLabel>Supplier(Child Level {level})</FormLabel>
                        <SelectDataMultiple
                            value={
                                selectedChild[level] && selectedChild[level].length > 0
                                    ? selectedChild[level].map((c: any) => c.id)
                                    : undefined
                            }
                            useData={() => ({
                                data: parent.children,
                                loading: false,
                            })}
                            selectOptionField={(item: any) => ({
                                text: `${item.name} ${item.number}`,
                                node: (
                                    <span>
                                        {item.name} {item.number}
                                    </span>
                                ),
                            })}
                            className={classes.selectBox}
                            style={{ width: "100%" }}
                            placeholder={t("select")}
                            onChange={(_: any, items: any) => {
                                setSelectedChild({ ...selectedChild, [level]: items });
                                if (items.length === 0 && level > 1) {
                                    onChange([selectedChild[level - 1]]);
                                } else {
                                    onChange(items);
                                }
                            }}
                            allowClear
                            selectAll
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                        />
                    </Col>
                </FormRow>
            )
        ) : null;
    return createChildren(selectedParent, 1);
};

export default SelectSupplierChildren;
