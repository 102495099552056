import Config from "../../config";
import { Environment } from "models";

export function getTableSize() {
    let windowInnerHeight: number = window.innerHeight;
    let headerOuterHeight: number = document.getElementById("headerSection")
        ? document.getElementById("headerSection")!.clientHeight
        : 0;
    let masterDataOverviewOuterheight: number = document.getElementById(
        "masterDataOverviewActionBar"
    )
        ? document.getElementById("masterDataOverviewActionBar")!.clientHeight
        : 0;
    let paginationHeight = 25;
    let defaultRowHeight: number = 25;
    let tableHeight: number = 0;
    let pageSize: number = 0;
    let excess: number = 0;
    if (masterDataOverviewOuterheight === 0) {
        tableHeight = windowInnerHeight - headerOuterHeight - paginationHeight - 200;
        pageSize = Math.round(tableHeight / defaultRowHeight);
        excess = headerOuterHeight + paginationHeight + 230;
    } else {
        tableHeight =
            windowInnerHeight -
            headerOuterHeight -
            masterDataOverviewOuterheight -
            paginationHeight -
            115;
        pageSize = Math.round(tableHeight / defaultRowHeight);
        excess = headerOuterHeight + masterDataOverviewOuterheight + paginationHeight + 215;
    }

    return { pageSize: pageSize, excess: excess.toString() };
}

export function getActivitiesMaxHeight() {
    let screenHeight = window.innerHeight;
    if (screenHeight >= 800 && screenHeight <= 899) {
        return "20vh";
    } else if (screenHeight >= 900 && screenHeight <= 929) {
        return "30vh";
    } else if (screenHeight >= 930 && screenHeight <= 960) {
        return "40vh";
    } else if (screenHeight >= 960 && screenHeight <= 1249) {
        return "40vh";
    } else if (screenHeight >= 1250 && screenHeight <= 1349) {
        return "55vh";
    } else if (screenHeight >= 1350) {
        return "65vh";
    }
}

export function getBackendDomainUrl() {
    switch (getEnvironmentInfo().type) {
        case Environment.Local:
            return "https://localhost:44392";
        case Environment.Staging:
            return "https://staging-backend.leanlinking.com";
        case Environment.Production:
            return "https://backend.leanlinking.com";
    }
}

export function getDomainUrl() {
    const environmentInfo = getEnvironmentInfo();
    switch (environmentInfo.type) {
        case Environment.Local:
            return `https://localhost:3000`;
        case Environment.Staging:
            console.log(
                `https://${environmentInfo.isExperimental ? "exp-" : ""}staging-app.leanlinking.com`
            );
            return `https://${
                environmentInfo.isExperimental ? "exp-" : ""
            }staging-app.leanlinking.com`;
        case Environment.Production:
            return `https://${environmentInfo.isExperimental ? "exp-" : ""}app.leanlinking.com`;
    }
}

export function getAuthorityUrl() {
    switch (getEnvironmentInfo().type) {
        case Environment.Local:
            return "https://localhost:44366";
        case Environment.Staging:
            return "https://staging-identity.leanlinking.com";
        case Environment.Production:
            return "https://identity.leanlinking.com";
    }
}

export function getRequestExportUrl() {
    switch (getEnvironmentInfo().type) {
        case Environment.Local:
            return "https://localhost:7071/api/ExcelExport";
        case Environment.Staging:
            return "https://reportingstagingv2.azurewebsites.net/api/ExcelExport?code=yN7KsquuBC5R6Ldb9N951Pim8oZ4Kh6kwWf2rVBcO0_iAzFuzycq6w==";
        case Environment.Production:
            return "https://reportingproductionv2.azurewebsites.net/api/ExcelExport?code=7G2ZlC1lgUH3C3wKIPmMKkzyNrNMGl_hRqbe6o1lvbsjAzFuV9zWCg==";
    }
}

type EnvironmentInfo = {
    type: Environment;
    isExperimental: boolean;
};

export function getEnvironmentInfo() {
    let environmentType: EnvironmentInfo = {
        type: Environment.Production,
        isExperimental: window.location.href.indexOf("exp") !== -1,
    };

    if (window.location.href.indexOf("localhost") !== -1) {
        environmentType.type = Environment.Local;
    } else if (window.location.href.toLowerCase().indexOf("staging") !== -1) {
        environmentType.type = Environment.Staging;
    }

    return environmentType;
}

export function isGuestAuthorizedPath(pathName: string) {
    let isAuthorized = false;
    Config.guestRoutes.forEach((route: string) => {
        if (pathName.match(new RegExp("/" + route + "/[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*/?"))) {
            isAuthorized = true;
        }
    });
    return isAuthorized;
}

export function getGuestTokenFromPathName(pathName: string) {
    if (pathName.indexOf("/id") !== -1) {
        pathName = pathName.replace(pathName.slice(pathName.indexOf("/id")), "");
    }
    const matches = pathName.match(new RegExp("/.*/([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)/?"));
    return matches ? matches[1] : undefined;
}

export function getUserNameValidationPattern() {
    return {
        regex: /^[0-9a-zA-Z][0-9a-zA-Z-\\..@\-_+]{1,48}[0-9a-zA-Z]$/i,
        messageKey: "admin_UserRegistration_UserValidationMsg",
    };
}

export function getPasswordValidationPattern() {
    return {
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z-zÀ-ȕ\d@$!%*#?&<>/\\%´()_\-+,.'~^=|{}";:[\]`]{12,40}$/,
        messageKey: "admin_UserRegistration_PasswordValidationMsg",
    };
}

export function getEmailValidationPattern() {
    return {
        regex: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        messageKey: "admin_UserRegistration_EmailAddressValidationMsg",
    };
}
