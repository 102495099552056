import { useState } from "react";
import { Provider } from "services";
import { ISegmentationAlignmentInfo } from "models";

const useSegmentsAlign = () => {
    const [loading, setLoading] = useState(false);

    const AlignSegments = async (segmentInfo: ISegmentationAlignmentInfo) => {
        setLoading(true);
        await Provider.Survey.AlignSegments(segmentInfo, {
            customError: {
                message: "Could not align segments",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        AlignSegments,
    };
};

export { useSegmentsAlign };
