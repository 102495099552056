import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Input, notification, Form } from "antd";
import { FormRow } from "components/utilitycomponents";
import { ICompanyCard } from "models";
import { useCompanyType, useSupplierCardsPartnerUpdate } from "controller";
import { useTranslation } from "react-i18next";

interface IProps {
    partnerId: number;
    visible: boolean;
    data: ICompanyCard;
    onClose: () => void;
    onSave: Function;
}

const EditModal = ({ visible, partnerId, data, onClose, onSave }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, updatePartnerInfo } = useSupplierCardsPartnerUpdate();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        form.resetFields();
    }, [partnerId, visible]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        await updatePartnerInfo({ partnerId: partnerId, ...fieldsValue });

        const title = t("supplierProfile_CompanyProfile", {
            companyType: isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer"),
        });
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateSupplierProfileSuccess", { title }),
        });

        onSave(fieldsValue);
        onClose();
    };

    return (
        <Modal
            wrapProps={{ "data-test": "supplier_profile_modal" }}
            width={"100vmin"}
            style={{ top: 20 }}
            title={t("supplierProfile_SupplierProfile")}
            onCancel={onClose}
            footer={[
                <Button
                    data-test="supplier_profile_modal_save_button"
                    key="ok"
                    type="primary"
                    onClick={handleSave}
                    loading={loading}>
                    {t("generic_Save")}
                </Button>,
            ]}
            open={visible}>
            <Form form={form}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_IdCardCeo")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="ceo"
                                    initialValue={data.ceo}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_IdCardCeo"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_ceo_input"
                                        placeholder={t("supplierProfile_IdCardCeo")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_IdCardHq")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="hq"
                                    initialValue={data.hq}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_IdCardHq"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_hq_input"
                                        placeholder={t("supplierProfile_IdCardHq")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_MainBusiness")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="mainBusiness"
                                    initialValue={data.mainBusiness}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_IdCardHq"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_main_business_input"
                                        placeholder={t("supplierProfile_MainBusiness")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_NumberOfEmployees")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="numberOfEmployees"
                                    initialValue={data.numberOfEmployees}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_NumberOfEmployees"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_number_of_employees_input"
                                        placeholder={t("supplierProfile_NumberOfEmployees")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_GeographyCoverage")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="geographyCoverage"
                                    initialValue={data.geographyCoverage}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_GeographyCoverage"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_geography_coverage_input"
                                        placeholder={t("supplierProfile_GeographyCoverage")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_ShareholderStructure")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="shareholderStructure"
                                    initialValue={data.shareholderStructure}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_ShareholderStructure"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_shareholder_structure_input"
                                        placeholder={t("supplierProfile_ShareholderStructure")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </Col>
                    <Col lg={12} xs={24}>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_YearEstablished")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="yearStablished"
                                    initialValue={data.yearStablished}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_YearEstablished"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_year_established_input"
                                        placeholder={t("supplierProfile_YearEstablished")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_YearlyRevenue")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="lastYearRevenue"
                                    initialValue={data.lastYearRevenue}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_YearlyRevenue"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_yearly_revenue_input"
                                        placeholder={t("supplierProfile_YearlyRevenue")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_YearlyNetIncome")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="lastYearNetIncome"
                                    initialValue={data.lastYearNetIncome}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_YearlyNetIncome"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_yearly_net_income_input"
                                        placeholder={t("supplierProfile_YearlyNetIncome")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col lg={12} xs={24}>
                                <label>{t("supplierProfile_FinancialRating")}</label>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item
                                    name="financialRating"
                                    initialValue={data.financialRating}
                                    rules={[
                                        {
                                            max: 100,
                                            message: t("stringLengthError", {
                                                name: t("supplierProfile_FinancialRating"),
                                                length: 100,
                                            }),
                                        },
                                    ]}>
                                    <Input
                                        data-test="supplier_profile_financial_rating_input"
                                        placeholder={t("supplierProfile_FinancialRating")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EditModal;
