import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type UseLinkedStatusType = {
    data: { name: string; id: number }[];
    loading: boolean;
};

const useLinkedStatus = () => {
    const { t } = useTranslation();
    const [linkedStatusState, setLinkedStatusState] = useState<UseLinkedStatusType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setLinkedStatusState({ ...linkedStatusState, loading: true });
            const data = [
                { name: t("Invited"), id: 3 },
                { name: t("Linked"), id: 2 },
                { name: t("NoEmail"), id: 4 },
                { name: t("NotLinked"), id: 1 },
            ];
            setLinkedStatusState({ loading: false, data });
        })();
    }, []);

    return {
        loading: linkedStatusState.loading,
        data: linkedStatusState.data,
    };
};

export default useLinkedStatus;
