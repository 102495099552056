export enum ISystemActionType {
    "NcrHolder" = "NcrHolder",
    "NcrOpenOnSupplier" = "NcrOpenOnSupplier",
    "NcrCorrectiveAction" = "NcrCorrectiveAction",
    "NcrPreventiveAction" = "NcrPreventiveAction",
    "NcrRootCauseAction" = "NcrRootCauseAction",
    "SelfAssessmentRequest" = "SelfAssessmentRequest",
    "SurveyRequest" = "SurveyRequest",
    "GateKeeper" = "GateKeeper",
    "ComplianceEndDate" = "ComplianceEndDate",
    "SegmentationSettingCurrentAssignedSegment" = "SegmentationSettingCurrentAssignedSegment",
}
