import { useEffect, useState } from "react";
import { Provider } from "services";
import { GraphType } from "models";

type StateType = {
    data: any;
    loading: boolean;
};

const useReviewCategoriesRadarChart = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Partner.getReviewCategory(
                partnerId,
                from,
                to,
                GraphType.Radar,
                {
                    customError: {
                        message: "Could not retrive data for review categories graph",
                    },
                }
            ).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewCategoriesRadarChart };
