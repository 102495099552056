import { useState } from "react";
import Provider from "services/api";

const useNewsLogDelete = () => {
    const [loading, setLoading] = useState(false);

    const remove = async (id: any) => {
        setLoading(true);
        await Provider.Partner.remoceNewsLog(id, {
            customError: {
                message: "Could not delete item",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        deleteItem: remove,
    };
};

export { useNewsLogDelete };
