import { get } from "../base";
const ComplianceTemplate = {
    getTemplateForCompliance: async (templateId: number, { customError }: any = {}) => {
        const url = `/v1/ComplianceTemplate?id=${templateId}`;
        return await get({ url, customError });
    },
    getTemplateGroups: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/ComplianceTemplate/GroupList?sortByName=${sortByName}`;
        return await get({ url, customError });
    },
    getAuthorizedTemplates: async (sortByName: boolean = true, { customError }: any = {}) => {
        const url = `/v1/ComplianceTemplate/AuthorizedTemplateList?sortByName=${sortByName}`;
        return await get({ url, customError });
    },
    getAllActiveTemplates: async ({ customError }: any = {}) => {
        const url = `/v1/ComplianceTemplate/GetAllActiveTemplates`;
        return await get({ url, customError });
    },
    getTemplateForSelfAssessment: async (token: string, { customError }: any = {}) => {
        const url = `/v1/SelfAssessment/GetSelfAssessmentTemplate?token=${token}`;
        return await get({ url, customError });
    },
};

export default ComplianceTemplate;
