import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
// @ts-ignore
import { ResponsiveBar } from "@nivo/bar";
import { useReviewCategoryChart } from "controller";
import { Divider } from "antd";
import classes from "./styles/reviewCategoryBarChart.module.scss";
import { LoadingIndicator } from "components/utilitycomponents";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
    renderType?: "performanceOverview" | "supplierSummary";
};

export const ReviewCategoriesBarChart: FunctionComponent<PropsType> = ({
    partnerId,
    range,
    renderType = "performanceOverview",
}) => {
    const { t } = useTranslation();
    const { loading, data } = useReviewCategoryChart(partnerId, range.from, range.to);

    if (loading) return <LoadingIndicator />;
    if (!data || data.length === 0) return null;

    const keys = [
        "averageRating",
        "totalAverageRating",
        "partnersInGroupAverageRating",
        "voiceOfSelectedPartnersAverageRating",
        "voiceOfAllPartnersAverageRating",
    ];
    const colors: any = {
        averageRating: "#0c5a87",
        totalAverageRating: "#488db5",
        partnersInGroupAverageRating: "#6baed6",
        voiceOfSelectedPartnersAverageRating: "#9ecae1",
        voiceOfAllPartnersAverageRating: "#c6dbef",
    };
    const renderChart = () => {
        return (
            <ResponsiveBar
                colors={bar => colors[bar.id]}
                margin={{
                    top: renderType === "performanceOverview" ? 30 : 10,
                    right: 30,
                    bottom: renderType === "performanceOverview" ? 100 : 110,
                    left: 30,
                }}
                innerPadding={0}
                padding={data.length <= 8 ? 1 - data.length / 10 : 0.2}
                groupMode="grouped"
                layout="vertical"
                indexBy="text"
                maxValue={5}
                keys={keys}
                data={data as any[]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    renderTick: (tick: any) => {
                        const addedY = data.length > 5 && tick.tickIndex % 2 === 0 ? 15 : 0;

                        return (
                            <g
                                key={tick.tickIndex}
                                transform={`translate(${tick.x},${tick.y + 25 + addedY})`}>
                                <line
                                    stroke="rgb(187,187,187)"
                                    strokeWidth={1.5}
                                    y1={-25 - addedY}
                                    y2={-12}
                                />
                                <text
                                    data-test="review_criteria_bar_chart_value"
                                    textAnchor="start"
                                    style={{
                                        fontSize: 12,
                                        transform:
                                            renderType === "performanceOverview"
                                                ? "rotate(20deg)"
                                                : "rotate(50deg)",
                                    }}>
                                    <title>{t(tick.value)}</title>
                                    {renderType === "performanceOverview" && (
                                        <>
                                            {tick.value.length > 30
                                                ? t(tick.value)
                                                      .substring(0, 30)
                                                      .trim() + "..."
                                                : t(tick.value)}
                                        </>
                                    )}
                                    {renderType === "supplierSummary" && (
                                        <>
                                            {t(tick.value).length > 8
                                                ? t(tick.value)
                                                      .substring(0, 8)
                                                      .trim() + "..."
                                                : t(tick.value)}
                                        </>
                                    )}
                                </text>
                            </g>
                        );
                    },
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                enableLabel={false}
                tooltip={({ data, id, value }: any) => {
                    let text = "";
                    switch (id) {
                        case "averageRating":
                            text = t(`supplierProfile_ReviewCategorySelectedPartnerCount`, {
                                reviewCount: data.reviewCount,
                                value,
                            });

                            break;
                        case "totalAverageRating":
                            text = t(`supplierProfile_ReviewCategoryAllPartnerCount`, {
                                totalReviewCount: data.totalReviewCount,
                                value,
                            });

                            break;
                        case "partnersInGroupAverageRating":
                            text = t("supplierProfile_ReviewCategoryGroupPartnerCount", {
                                groupName: data.groupName,
                                partnersInGroupReviewCount: data.partnersInGroupReviewCount,
                                value,
                            });

                            break;
                        case "voiceOfSelectedPartnersAverageRating":
                            text = t("supplierProfile_ReviewCategoryVoicePartnerCount", {
                                voiceOfSelectedPartnersReviewCount:
                                    data.voiceOfSelectedPartnersReviewCount,
                                value,
                            });
                            break;
                        case "voiceOfAllPartnersAverageRating":
                            text = t("supplierProfile_ReviewCategoryVoiceAllPartnerCount", {
                                voiceOfAllPartnersAverageRating: data.voiceOfAllPartnersReviewCount,
                                value,
                            });
                            break;
                    }
                    return (
                        <div className={classes.tooltip}>
                            <div>
                                <b>
                                    {data.text.indexOf("ReviewCategory_") !== -1
                                        ? t(data.text)
                                        : data.text}
                                </b>
                            </div>
                            <div>
                                <b>{text}</b>
                            </div>
                        </div>
                    );
                }}
            />
        );
    };

    return (
        <>
            {renderType === "performanceOverview" ? (
                <div style={{ pageBreakInside: "avoid" }}>
                    <Divider />
                    <div data-test="supplier_profile_review_categories_title">
                        <strong>{t("supplierProfile_ReviewCategories")}</strong>
                    </div>
                    <div
                        data-test="review_bar_chart"
                        className={classnames(classes.reviewBarChart)}>
                        {renderChart()}
                    </div>
                    {/**print section */}
                    <div style={{ width: "595px", position: "absolute", left: -9999 }}>
                        <div
                            data-test="print_review_bar_chart"
                            className={classnames(classes.reviewBarChart, "reviewChart")}>
                            {renderChart()}
                        </div>
                    </div>
                    <div
                        data-test="review_chart_print"
                        className={classnames(classes.reviewChartPrint, "reviewChartPrint")}></div>
                    {/**end print section */}
                </div>
            ) : (
                renderChart()
            )}
        </>
    );
};
export default ReviewCategoriesBarChart;
