import { theme, ThemeConfig } from "antd";

export const antdThemeConfig: ThemeConfig = {
    algorithm: theme.defaultAlgorithm,
    components: {
        Button: {
            colorPrimary: "rgb(0,47,167)",
            colorPrimaryHover: "rgb(0,35,122)",
            colorPrimaryActive: "rgb(0,23,82)",
            colorError: "rgb(255,77,79)",
            colorErrorHover: "rgb(255,10,14)",
            colorErrorActive: "rgb(199,0,3)",
        },
        Checkbox: {
            colorPrimary: "rgb(0,47,167)",
            colorPrimaryHover: "rgb(51,109,255)",
            colorPrimaryBorder: "rgb(0,47,167)",
        },
        Table: {
            colorPrimary: "#002FA7",
            headerColor: "rgb(71,84,103)",
            headerBorderRadius: 0,
            borderRadius: 0,
            borderColor: "#F0F0F0",
        },
        Tooltip: {
            colorBgSpotlight: "rgb(16,24,40)",
        },
        Input: {
            activeBorderColor: "rgb(51,109,255)",
            borderRadius: 4,
            colorError: "rgb(253,162,155)",
            colorErrorText: "rgb(240,68,56)",
            colorPrimaryHover: "rgb(51,109,255)",
            colorPrimaryActive: "rgb(51,109,255)",
            colorTextPlaceholder: "rgb(102,112,133)",
            colorBgContainerDisabled: "rgb(249,250,251)",
            colorTextDisabled: "rgb(102,112,133) !important",
        },
        Select: {
            colorTextPlaceholder: "rgb(102,112,133)",
            colorTextDisabled: "rgb(102,112,133)",
            colorBgContainerDisabled: "rgb(249,250,251)",
        },
        DatePicker: {
            colorTextPlaceholder: "rgb(102,112,133)",
            colorBgContainerDisabled: "rgb(249,250,251)",
            colorTextDisabled: "rgb(102,112,133)",
        },
        Pagination: {
            borderRadius: 0,
            colorPrimary: "transparent",
            colorPrimaryHover: "transparent",
        },
        Form: {
            labelColor: "rgb(52,64,84)",
        },
        Tabs: {
            colorPrimary: "#002FA7",
            colorPrimaryHover: "#002FA7",
        },
    },
};
