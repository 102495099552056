import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveBar } from "@nivo/bar";
import classnames from "classnames";
import { useNcrGraph } from "controller";
import { Card } from "antd";
import { AlertOctagon, LoadingIndicator } from "components/utilitycomponents";
import classes from "./styles/ncrReportsBarChart.module.scss";
import CardTitle from "./cardTitle";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

export const NcrReportsBarChart: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();

    const { loading, data } = useNcrGraph(partnerId, range.from, range.to);

    if (loading) return <LoadingIndicator />;
    if (!data || data.stats.length === 0) return null;

    const renderChart = () => {
        return (
            <ResponsiveBar
                colors={["#0c5a87"]}
                margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
                padding={data.stats.length < 10 ? 1 - data.stats.length / 10 : 0}
                groupMode="grouped"
                layout="vertical"
                indexBy="text"
                keys={["count"]}
                data={data.stats}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    renderTick: (tick: any) => {
                        const addedY = data.stats.length > 5 && tick.tickIndex % 2 === 0 ? 15 : 0;

                        return (
                            <g
                                key={tick.tickIndex}
                                transform={`translate(${tick.x},${tick.y + 21 + addedY})`}>
                                <line
                                    stroke="rgb(187,187,187)"
                                    strokeWidth={1.5}
                                    y1={-21 - addedY}
                                    y2={-12}
                                />
                                <text
                                    textAnchor="middle"
                                    style={{
                                        fontSize: 12,
                                    }}>
                                    <title>{t(tick.value)}</title>
                                    {tick.value.length > 30
                                        ? t(tick.value)
                                              .substring(0, 30)
                                              .trim() + "..."
                                        : t(tick.value)}
                                </text>
                            </g>
                        );
                    },
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                enableLabel={false}
                tooltip={({ value, indexValue }: any) => {
                    return (
                        <div className={classes.tooltip}>
                            <div>
                                <b>{t(indexValue)}</b>
                            </div>
                            <div>
                                <b>{value}</b>
                            </div>
                        </div>
                    );
                }}
            />
        );
    };
    return (
        <Card
            title={
                <CardTitle
                    icon={<AlertOctagon />}
                    title={t("supplierProfile_NonconformanceReports")}
                />
            }>
            <div className={classes.titleBar}>
                <strong>
                    {t("supplierProfile_NCROpenDays")}: {data.ncrDaysOpen}
                </strong>
            </div>
            <div className={classnames(classes.ncrBarChart)}>{renderChart()}</div>
            {/**print section */}
            <div style={{ width: "595px", position: "absolute", left: -9999 }}>
                <div className={classnames(classes.ncrBarChart, "ncrChart")}>{renderChart()}</div>
            </div>
            <div className={classnames(classes.ncrChartPrint, "ncrChartPrint")}></div>
            {/**end print section */}
        </Card>
    );
};

export default NcrReportsBarChart;
