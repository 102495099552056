import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useSurveyFilterContacts = (surveyId: number) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        if (surveyId !== -1) {
            (async () => {
                let data = await Provider.Contact.GetSurveyFilterContacts(surveyId);
                data = data.map((contact: IContact) => ({
                    ...contact,
                    name: `${contact.firstName} ${contact.lastName}`,
                }));
                setState({ loading: false, data });
            })();
        }
    }, [surveyId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyFilterContacts };
