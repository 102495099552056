import { useEffect, useState } from "react";
import { Provider } from "services";
import { IAccountSetting } from "models";

type StateType = {
    loading: boolean;
    data: IAccountSetting | undefined;
};

const useAccountSettings = () => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.User.getAccountSettings({
                retryCount: 3,
                customError: { message: "Could not retrieve account settings" },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAccountSettings };
