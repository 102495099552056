import { useState } from "react";
import { Provider } from "services";

const useActivityFollowingUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (activityId: number, isFollow: boolean) => {
        setLoading(true);
        const id = await Provider.Activity.updateFollowing(activityId, isFollow);
        setLoading(false);
        return id;
    };

    return {
        loading,
        update,
    };
};

export { useActivityFollowingUpdate };
