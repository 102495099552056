import { useEffect, useState } from "react";
import { Provider } from "services";

const useEmbedInfo = (workspaceId: string, reportId: string, reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: undefined,
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            if (workspaceId && reportId) {
                let data = await Provider.PowerBi.getEmbedInfo(workspaceId, reportId, {
                    customError: {
                        message: "Could not retrieve embed info",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [reload, workspaceId, reportId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useEmbedInfo };
