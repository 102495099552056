import React, { FunctionComponent, useEffect, useState } from "react";
import { Descriptions, Row, Col, Input, Spin, Form, Button, Result, notification } from "antd";
import { useTranslation } from "react-i18next";
import { ChildActivities, PageContainer, SelectDataMultiple } from "components/utilitycomponents";
import {
    useActionByToken,
    useActionCRUD,
    useCompanyType,
    useInternalAndExternalContacts,
} from "controller";
import classes from "./styles/action.module.scss";
import { useParams } from "react-router-dom";
import { IActivityChild } from "models";
import { ICreateEditAction, ISystemActionType } from "models/action";
import dayjs from "utilities/daysJsConfig";
interface IProps {}

const GuestActionPage: FunctionComponent<IProps> = () => {
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [reload, setReloadData] = useState({ reload: false });
    const { data, loading, addActivity, removeActivity } = useActionByToken(guestToken, reload);
    const [form] = Form.useForm();
    const [actionSupplier, setActionSupplier] = useState<any>(-1);
    const { isDefaultCompany } = useCompanyType();
    const {
        updateAction,
        loading: loadingAction,
        completionLoading,
        completeActions,
        reOpenActions,
    } = useActionCRUD();
    useEffect(() => {
        form.resetFields();
        if (data && data.actionPartnerIds) {
            if (data.actionPartnerIds[0]) {
                setActionSupplier(data.actionPartnerIds[0]);
            } else {
                setActionSupplier(-1);
            }
        }
    }, [data]);

    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        addActivity(parentActivityId, activity);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        removeActivity(parentActivityId, activityId);
    };

    const handleOnCompleteAction = async () => {
        await completeActions([data!.id]);
        notification.success({
            message: t("success"),
            description: t("action_CompleteSuccess"),
        });
        setReloadData({ reload: true });
    };

    const handleOnReOpenAction = async () => {
        await reOpenActions([data!.id]);
        notification.success({
            message: t("success"),
            description: t("action_ReOpenSuccess"),
        });
        setReloadData({ reload: true });
    };

    const handleSave = () => {
        form.validateFields()
            .then(async values => {
                let action: ICreateEditAction = {
                    title: values.title,
                    description: values.description && values.description,
                    ownerId: values.owner,
                    actionPartnerIds: data && data.actionPartnerIds,
                    dueDate: data && data.dueDate,
                };

                await updateAction(data!.id, action);
                notification.success({
                    message: t("success"),
                    description: t("action_updateSuccess"),
                });
            })
            .catch(err => {
                return;
            });
    };

    const parseDescriptionText = (text: string) => {
        switch (data?.systemGeneratedType) {
            case ISystemActionType.NcrCorrectiveAction:
            case ISystemActionType.NcrPreventiveAction:
            case ISystemActionType.NcrRootCauseAction:
            case ISystemActionType.NcrHolder:
            case ISystemActionType.NcrOpenOnSupplier:
                return t(text);
            default:
                return text;
        }
    };

    return !data && !loading ? (
        <div>
            <Result
                status="info"
                title={t("actions_Not_Available")}
                subTitle={t("actions_Not_AvailableMoreInfo")}
            />
        </div>
    ) : (
        data && (
            <PageContainer
                title={<div>{data && t("actions_guestPageTitle", { id: data.id })}</div>}
                style={{ minHeight: 500 }}>
                <Spin spinning={loading}>
                    <Form form={form}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Descriptions layout="vertical" column={2} colon={false}>
                                    <Descriptions.Item
                                        label={
                                            <div className={classes.formLabel}>
                                                {t("actions_Title")}
                                            </div>
                                        }>
                                        <Form.Item
                                            style={{ width: "100%" }}
                                            name="title"
                                            required
                                            initialValue={data && data.title}>
                                            <Input.TextArea
                                                disabled={data && data.completed}
                                                autoSize={{ minRows: 1, maxRows: 10 }}
                                                maxLength={500}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <div className={classes.formLabel}>
                                                {t("actions_Description")}
                                            </div>
                                        }>
                                        <Form.Item
                                            style={{ width: "100%" }}
                                            name="description"
                                            required
                                            initialValue={
                                                data &&
                                                data.description &&
                                                parseDescriptionText(data.description)
                                            }>
                                            <Input.TextArea
                                                disabled={data && data.completed}
                                                autoSize={{ minRows: 1, maxRows: 10 }}
                                                maxLength={1000}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" column={3} colon={false}>
                                    <Descriptions.Item
                                        style={{ maxWidth: 100 }}
                                        label={
                                            <div className={classes.formLabel}>
                                                {isDefaultCompany
                                                    ? t("company_Type_Supplier")
                                                    : t("company_Type_Customer")}
                                            </div>
                                        }>
                                        {data && data.actionPartnerNames
                                            ? data.actionPartnerNames[0]
                                            : t("generic_NotApplicable")}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <div className={classes.formLabel}>
                                                {t("actions_DueDate")}
                                            </div>
                                        }>
                                        {data && data.dueDate
                                            ? dayjs(data.dueDate).format("DD-MM-YYYY")
                                            : t("generic_NotApplicable")}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            <div className={classes.formLabel}>
                                                {t("actions_Owner")}
                                            </div>
                                        }>
                                        <Form.Item
                                            name="owner"
                                            style={{ width: "100%" }}
                                            initialValue={data && data.ownerId}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <SelectDataMultiple
                                                data-test="action_owner_select"
                                                allowClear
                                                showSearch
                                                disabled={data && data.completed}
                                                placeholder={t("actions_OwnerPlaceholder")}
                                                selectOptionField="name"
                                                groupNames={[
                                                    data && data.originatingPartnerName
                                                        ? `${data.originatingPartnerName} ` +
                                                          t("admin_UserAccessSetting_Contacts")
                                                        : t("admin_UserAccessSetting_Contacts"),
                                                    data &&
                                                        data.suppliers &&
                                                        `${data.suppliers[0]} ` +
                                                            t("admin_UserAccessSetting_Contacts"),
                                                ]}
                                                groupFilter={(item: any, groupName: string) => {
                                                    if (
                                                        (data &&
                                                            data.originatingPartnerName &&
                                                            groupName ===
                                                                `${data.originatingPartnerName} ` +
                                                                    t(
                                                                        "admin_UserAccessSetting_Contacts"
                                                                    )) ||
                                                        groupName ===
                                                            t("admin_UserAccessSetting_Contacts")
                                                    )
                                                        return item.externalUser === false;
                                                    if (
                                                        data &&
                                                        data.suppliers &&
                                                        groupName ===
                                                            `${data.suppliers[0]} ` +
                                                                t(
                                                                    "admin_UserAccessSetting_Contacts"
                                                                )
                                                    )
                                                        return item.externalUser === true;
                                                }}
                                                useData={useInternalAndExternalContacts.bind(
                                                    null,
                                                    actionSupplier
                                                )}
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col lg={19} sm={16} md={16} xs={10}></Col>
                            <Col lg={3} sm={5} md={5} xs={7}>
                                {data && !loadingAction && data.completed ? (
                                    <Button
                                        data-test="reopen_action_button"
                                        loading={completionLoading}
                                        type="primary"
                                        onClick={() => handleOnReOpenAction()}>
                                        {t("action_ReOpenAction")}
                                    </Button>
                                ) : (
                                    !loadingAction && (
                                        <Button
                                            data-test="complete_action_button"
                                            loading={completionLoading}
                                            type="primary"
                                            onClick={() => handleOnCompleteAction()}>
                                            {t("action_CompleteAction")}
                                        </Button>
                                    )
                                )}
                            </Col>
                            <Col lg={2} sm={3} md={3} xs={7}>
                                <Button
                                    loading={loadingAction}
                                    type="primary"
                                    onClick={() => handleSave()}>
                                    {t("generic_Save")}
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                {data && (
                                    <ChildActivities
                                        guestCommentary={true}
                                        data={data.childrenActivity}
                                        parentActivityId={data.activityId}
                                        onRemoveActivity={handleRemoveActivity}
                                        onAddActivity={handleAddActivity}
                                        isContactActivty={true}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </PageContainer>
        )
    );
};

export { GuestActionPage };
