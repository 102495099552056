import React, { useState, FunctionComponent } from "react";
import CreateForm from "./createForm/createForm";
import { useAuthorizedTemplates, useTemplateForCompliance } from "controller";
import { LoadingIndicator, PageContainer, SelectTreeData } from "components/utilitycomponents";
import { FormRow, ColLeft, ColRight } from "../sharedComponents";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Create: FunctionComponent = () => {
    const { template: queryParamTemplateId }: any = useParams();
    const [activeTemplate, setActiveTemplate] = useState<number | null | undefined>(
        queryParamTemplateId !== undefined ? Number(queryParamTemplateId) : undefined
    );
    const { loading, data } = useTemplateForCompliance(activeTemplate);
    const { t } = useTranslation();

    return (
        <PageContainer title={t("createRegistration")} settingContainer>
            <FormRow>
                <ColLeft>
                    <label>{t("compliance_SelectTemplate")}</label>
                </ColLeft>
                <ColRight>
                    <SelectTreeData
                        data-test="template_select"
                        value={activeTemplate ? activeTemplate : undefined}
                        disabled={queryParamTemplateId !== undefined}
                        style={{ width: "100%" }}
                        onChange={(value?: number) => value && setActiveTemplate(value)}
                        placeholder={t("compliance_NotSelected")}
                        selectOptionField="name"
                        useData={useAuthorizedTemplates}
                        getPopupContainer={() =>
                            document.getElementById("contentSection") || document.body
                        }
                        selectOnlyChildren
                    />
                </ColRight>
            </FormRow>
            {activeTemplate && loading && <LoadingIndicator />}
            {activeTemplate && data !== null && (
                <CreateForm
                    data={data}
                    lightUserSupplierCreation={queryParamTemplateId !== undefined}
                />
            )}
        </PageContainer>
    );
};

export default Create;
