import { useState } from "react";
import { Provider } from "services";

const useReviewCriteriaGroupCategory = () => {
    const [loading, setLoading] = useState(false);

    const update = async (criteriaGroupIds: number[], categoryId: number) => {
        setLoading(true);
        let data = await Provider.Review.updateCriteriaGroupCategoryRelation(
            criteriaGroupIds,
            categoryId,
            {
                customError: {
                    message: "Could not update criteria group template",
                },
            }
        ).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const remove = async (criteriaGroupIds: number[]) => {
        setLoading(true);
        let data = await Provider.Review.removeCriteriaGroupCategoryRelation(criteriaGroupIds, {
            customError: {
                message: "Could not delete criteria group template",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    return {
        loading,
        update,
        remove,
    };
};

export { useReviewCriteriaGroupCategory };
