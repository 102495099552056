import Config from "../../../config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Provider } from "services";
import { AppState } from "store";

const useAvailableSharedDashboards = () => {
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (userType !== Config.guestUser) {
                const data = await Provider.Analytics.getAvailableSharedPresets({
                    retryCount: 3,
                    customError: { message: "Could not retrieve list of presets" },
                }).catch(err => {
                    setState({ loading: false, data: {} });
                    return Promise.reject(err);
                });

                setState({
                    loading: false,
                    data: data,
                });
            }
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAvailableSharedDashboards };
