import React, { FunctionComponent, SyntheticEvent } from "react";
import { Row, Col, Input, Button } from "antd";

type PropTypes = {
    onClick: (event: SyntheticEvent) => void;
    value: string;
    buttonText: string;
    inputId?: string;
    disabled?: boolean;
};

const ButtonedInput: FunctionComponent<PropTypes> = ({
    onClick,
    value,
    buttonText,
    inputId = `buttonedInput${Math.random()}`,
    disabled = false,
}) => {
    return (
        <Row>
            <Col span={19}>
                <Input
                    id={inputId}
                    value={value}
                    style={{ borderRadius: "4px 0 0 4px" }}
                    disabled={disabled && disabled}
                />
            </Col>
            <Col span={5}>
                <Button
                    style={{ borderRadius: "0 4px 4px 0" }}
                    type="primary"
                    onClick={onClick}
                    disabled={disabled}>
                    {buttonText}
                </Button>
            </Col>
        </Row>
    );
};

export { ButtonedInput };
