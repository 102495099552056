import React, { useState, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Steps, Row, Col, notification } from "antd";
import { useParams } from "react-router-dom";
import { ButtonedInput, PageContainer } from "components/utilitycomponents";
import SelectTemplate from "./selectTemplate";
import SelectSupplier from "./selectSupplier/selectSupplier";
import SelectRespondents from "./selectRespondents/selectRespondents";
import SelectDeadline from "./selectDeadline";
import AdjustSurvey from "./adjustSurvey/adjustSurvey";
import { useSurveyCreate, useSurveyTemplate, useSurveyCopyInformation } from "controller";
import { copyText, getDomainUrl } from "utilities";
import {
    ICreateSurvey,
    ICustomDropdownHeadline,
    ITextFieldHeadline,
    SurveyPartnerSpecification,
    SurveyType,
} from "models";

const SurveyWizard: FunctionComponent = () => {
    const { t } = useTranslation();
    const { type: surveyType = SurveyType.Segmentation, repeatSurveyId }: any = useParams();
    const { loading: createLoading, createSurvey } = useSurveyCreate();
    const [current, setCurrent] = useState(0);
    const [survey, setSurvey] = useState<Survey>({});
    const [selectedSupplierIds, setSelectedSupplierIds] = useState<number[]>([]);
    const [clearData, setClearData] = useState<any>(null);
    const [reloadTemplate, setReloadTemplate] = useState<any>(null);
    const [surveyCopy, setSurveyCopy] = useState<any>(undefined);
    const { data: existingSurveyDetails } = useSurveyCopyInformation(repeatSurveyId);
    const { data: template, loading: templateLoading } = useSurveyTemplate(
        surveyCopy ? surveyCopy.surveyTemplateId : survey.templateId,
        reloadTemplate
    );
    const [internalRespondentsChecked, setInternalRespondentsChecked] = useState<
        boolean | undefined
    >(undefined);
    const [externalRespondentsChecked, setExternalRespondentsChecked] = useState<
        boolean | undefined
    >(undefined);

    useEffect(() => {
        setSurveyCopy(existingSurveyDetails);
    }, [existingSurveyDetails]);

    useEffect(() => {
        setReloadTemplate({ reload: true });
    }, [survey.templateId]);

    const handleSubmit = async (restSurveyData: AdjustedSurvey) => {
        let surveyPartnerSpecifications = [];

        for (let partnerId of selectedSupplierIds) {
            let surveyPartnerSpecification: SurveyPartnerSpecification = {
                partnerId: 0,
                contactIds: [],
            };
            surveyPartnerSpecification.partnerId = partnerId;
            if (survey.supplierContacts && survey.supplierContacts[partnerId]) {
                surveyPartnerSpecification.contactIds =
                    survey.supplierContacts[partnerId].receiverContacts;
            }
            surveyPartnerSpecifications.push(surveyPartnerSpecification);
        }

        let surveyToCreate: ICreateSurvey = {
            title: restSurveyData.title,
            introduction: restSurveyData.introduction,
            endDate: survey.endDate ? survey.endDate : undefined,
            selectedPartnerIds: selectedSupplierIds,
            selectedDescriptors:
                survey.supplierSet &&
                survey.supplierSet.map((set: any) => ({
                    partnerId: set.supplier.id,
                    divisionIds: set.division && set.division.id ? [set.division.id] : [],
                    regionIds: set.regions ? set.regions.map((r: any) => r.id) : [],
                    commodityCategoryIds: [
                        ...(set.jcc1 && set.jcc1.internalId ? [set.jcc1.internalId] : []),
                        ...(set.jcc2 && set.jcc2.internalId ? [set.jcc2.internalId] : []),
                        ...(set.jcc3 && set.jcc3.internalId ? [set.jcc3.internalId] : []),
                    ],
                })),
            selectedReviewCriterias: restSurveyData.selectedReviewCriterias,
            visibility: restSurveyData.visibility,
            selectedContactGroupIds: [],
            selectedContactIds: survey.selectedContactIds ? survey.selectedContactIds : [],
            isVoiceOfSupplier: restSurveyData.isVoiceOfSupplier && restSurveyData.isVoiceOfSupplier,
            allowGuestOnVOS: restSurveyData.allowGuestOnVOS && restSurveyData.allowGuestOnVOS,
            introductionVOS: restSurveyData.introductionVOS,
            allowGuest: restSurveyData.allowGuest && restSurveyData.allowGuest,
            customDropdownHeadlines: restSurveyData.customDropdownHeadlines,
            textFieldHeadlines: restSurveyData.textFieldHeadlines,
            surveyTemplateId: survey.templateId,
            surveyPartnerSpecifications,
        };

        let token = await createSurvey(surveyToCreate);
        notification.success({
            style: {
                width: 600,
            },
            duration: 0,
            message: t("success"),
            description: (
                <div>
                    {t("generic_CreateSuccess", { name: "Survey" })}
                    <ButtonedInput
                        value={`${getDomainUrl()}/Reviews/Survey/${token}`}
                        buttonText={t("copyUrl")}
                        inputId={`survey_url`}
                        onClick={() => {
                            copyText("survey_url");
                        }}
                    />
                </div>
            ),
        });
        setCurrent(0);
        setSurvey({});
        setClearData({ clear: true });
    };

    const handleFromTemplateSelectNext = (
        templateId: number,
        internalRespondentsChecked?: boolean,
        externalRespondentsChecked?: boolean
    ) => {
        if (surveyType !== Number(SurveyType.Segmentation).toString()) {
            setInternalRespondentsChecked(internalRespondentsChecked);
            setExternalRespondentsChecked(externalRespondentsChecked);
            setSurvey({ ...survey, templateId: templateId });
            setCurrent(1);
        } else {
            setSurvey({ ...survey, templateId: templateId });
            setCurrent(1);
        }
    };

    const getIsVOSValue = () => {
        if (surveyType !== Number(SurveyType.Segmentation).toString()) {
            return externalRespondentsChecked !== undefined && externalRespondentsChecked;
        } else {
            return template && template.isVoiceOfSupplier;
        }
    };
    const getAllowGuestVOSValue = () => {
        return template && template.allowGuestVOS;
    };

    const getShowInternalRespondentsValue = () => {
        if (surveyType === Number(SurveyType.Performance).toString()) {
            return internalRespondentsChecked !== undefined && internalRespondentsChecked;
        } else {
            return true;
        }
    };

    return (
        <PageContainer
            style={{ marginBottom: 60, padding: 8, borderRadius: 5 }}
            title={t("survey_Wizard_CreatSurvey")}>
            <Steps current={current} style={{ margin: "10px 0 30px 0" }}>
                <Steps.Step title={t("survey_Wizard_SelectTemplate")} key={0} />
                <Steps.Step title={t("survey_Wizard_SelectSuppliers")} key={1} />
                <Steps.Step title={t("survey_Wizard_SelectRespondents")} key={2} />
                <Steps.Step title={t("survey_Wizard_SetDeadLines")} key={3} />
                <Steps.Step
                    title={t("survey_Wizard_AdjustSurvey")}
                    key={4}
                    disabled={!survey.selectedPartnerIds || survey.selectedPartnerIds.length === 0}
                />
            </Steps>

            <Row>
                <Col span={24}>
                    <SelectTemplate
                        onNext={(
                            templateId: number,
                            internalRespondentsChecked?: boolean,
                            externalRespondentsChecked?: boolean
                        ) => {
                            handleFromTemplateSelectNext(
                                templateId,
                                internalRespondentsChecked,
                                externalRespondentsChecked
                            );
                        }}
                        onTemplateSelect={(templateId: number) => {
                            setSurvey({ ...survey, templateId: templateId });
                        }}
                        visible={current === 0}
                        clearData={clearData}
                        surveyType={Number(surveyType)}
                        surveyTemplate={template}
                    />
                    <SelectSupplier
                        onNext={(supplierSet: SupplierSet[]) => {
                            setSurvey({
                                ...survey,
                                supplierSet,
                            });
                            setSelectedSupplierIds([
                                ...new Set(supplierSet.map((set: any) => set.supplier.id)),
                            ] as number[]);
                            setCurrent(2);
                        }}
                        onPrevious={() => {
                            setCurrent(0);
                        }}
                        visible={current === 1}
                        clearData={clearData}
                        selectedEntities={surveyCopy && surveyCopy.selectedDescriptorDetails}
                    />
                    <SelectRespondents
                        onNext={(contactIds: number[], supplierContacts: SupplierContacts) => {
                            setSurvey({
                                ...survey,
                                selectedContactIds: contactIds,
                                supplierContacts,
                            });
                            setCurrent(3);
                        }}
                        onPrevious={() => {
                            setCurrent(1);
                        }}
                        visible={current === 2}
                        clearData={clearData}
                        isVos={getIsVOSValue()}
                        hasAllowGuestOnVOS={getAllowGuestVOSValue()}
                        supplierSet={survey.supplierSet ? survey.supplierSet : []}
                        defaultSurvey={surveyCopy}
                        showInternalRespondents={getShowInternalRespondentsValue()}
                    />
                    <SelectDeadline
                        onNext={(endDate: string) => {
                            if (survey.templateId && selectedSupplierIds.length !== 0) {
                                setSurvey({ ...survey, endDate });
                                setCurrent(4);
                            }
                        }}
                        onPrevious={() => {
                            setCurrent(2);
                        }}
                        visible={current === 3}
                        clearData={clearData}
                    />
                    <AdjustSurvey
                        onPrevious={() => {
                            setCurrent(3);
                        }}
                        onSubmit={(restSurveyData: AdjustedSurvey) => {
                            handleSubmit(restSurveyData);
                        }}
                        template={template}
                        visible={current === 4}
                        loading={createLoading}
                        templateLoading={templateLoading}
                        surveyType={Number(surveyType)}
                        defaultSurvey={surveyCopy}
                        selectedVOSValue={getIsVOSValue()}
                    />
                </Col>
            </Row>
        </PageContainer>
    );
};

type Survey = {
    templateId?: number;
    selectedPartnerIds?: number[];
    endDate?: string;
    supplierSet?: SupplierSet[];
    selectedContactIds?: number[];
    supplierContacts?: SupplierContacts;
};

type AdjustedSurvey = {
    title: string;
    introduction: string;
    selectedReviewCriterias: { reviewCriteriaId: number; reviewCriteriaOrder: number }[];
    visibility: string;
    isVoiceOfSupplier: boolean;
    allowGuestOnVOS: boolean;
    introductionVOS: string;
    allowGuest: boolean;
    customDropdownHeadlines: ICustomDropdownHeadline[];
    textFieldHeadlines: ITextFieldHeadline[];
};

type SupplierSet = {
    supplier: { id: number; name: string };
    division: { id: number; name: string };
    regions: { id: number; name: string }[];
    jcc1: { internalId: number };
    jcc2: { internalId: number };
    jcc3: { internalId: number };
};

export type SupplierContacts = {
    [key: number]: SupplierContact;
};

export type SupplierContact = {
    receiverContacts: number[];
    receiverEmails: string[];
};

export default SurveyWizard;
