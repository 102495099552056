import { useState } from "react";
import { default as serviceProvider } from "../../services/api";

const useNcrClose = () => {
    const [loading, setLoading] = useState(false);

    const closeNcr = async (ncrId: number) => {
        setLoading(true);
        const success = await serviceProvider.Ncr.closeNcr(ncrId, {
            customError: {
                message: "Could not close the registration",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    return {
        loading,
        closeNcr,
    };
};

export default useNcrClose;
