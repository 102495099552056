import { useState } from "react";
import { Provider } from "services";

const useCustomDropHeadlineInUse = () => {
    const [loading, setLoading] = useState(false);

    const isHeadlineInUse = async (headlineText: string, headlineId: number) => {
        setLoading(true);
        const result = await Provider.Company.isHeadlineInUse(headlineText, headlineId, {
            customError: {
                message: "Could not check if custom dropdown headline exists",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading,
        isHeadlineInUse,
    };
};

export { useCustomDropHeadlineInUse };
