import { useState } from "react";
import { Provider } from "services";

const useSendInvitationToPartners = () => {
    const [loading, setLoading] = useState(false);

    const send = async (partnerData: any) => {
        setLoading(true);

        const res = await Provider.Partner.sendInvitationToPartners(partnerData, {
            customError: {
                message: "Could not send invitation request",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        send,
    };
};

export { useSendInvitationToPartners };
