import { useEffect, useState } from "react";
import { Provider } from "services";
import { ICompanySettings, IPartnerMasterData } from "models";

type StateType = {
    loading: boolean;
    data: IPartnerMasterData | null;
    companySettings: ICompanySettings | null;
};

const usePartnerMasterData = (partnerId: number | undefined, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
        companySettings: null,
    });

    useEffect(() => {
        (async () => {
            if (partnerId !== undefined && partnerId !== null) {
                setState({ ...state, loading: true });
                const data = await Provider.Partner.getPartnerMasterData(partnerId!, {
                    customError: {
                        message: "Could not retrieve partner data",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                const companySettings = await Provider.Company.getGlobalSettings({
                    customError: {
                        message: "Could not retrieve global settings on the company",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ ...state, loading: false, data, companySettings });
            }
        })();
    }, [partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
        companySettings: state.companySettings,
    };
};

export { usePartnerMasterData };
