import { useEffect, useState } from "react";
import { Provider } from "services";

const useAdminKpiCustomColumns = (reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        customColumns: [],
        kpiDefinitions: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Analytics.getAdminKpiCustomColumns({
                customError: {
                    message: "Could not retrieve list of kpi custom columns",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                customColumns: data.customColumns,
                kpiDefinitions: data.kpiDefinitions,
            });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        customColumns: state.customColumns,
        kpiDefinitions: state.kpiDefinitions,
    };
};

export { useAdminKpiCustomColumns };
