import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useReviewDefaultCreationValues = () => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Review.getDefaultCreationValues().catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data: data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewDefaultCreationValues };
