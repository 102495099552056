import { IMeetingForum } from "models";
import { post, get, put, remove } from "../base";

const MeetingForums = {
    getMeetingForums: async ({ customError }: any = {}) => {
        const url = `/v1/AdminGovernance/GetDefaultMeetingForums`;
        return await get({ url, customError });
    },
    createMeetingForum: async (meeting: IMeetingForum, { customError }: any = {}) => {
        const url = `/v1/AdminGovernance/CreateDefaultForum`;
        return await post({ url, payload: meeting, customError });
    },
    updateMeetingForum: async (meeting: IMeetingForum, { customError }: any = {}) => {
        const url = `/v1/AdminGovernance/UpdateDefaultForum/${meeting.templateId}`;
        return await put({ url, payload: meeting, customError });
    },
    deleteMeetingForum: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminGovernance/DeleteDefaultMeetingForum`;
        return await remove({ url, payload: ids, customError });
    },
    getForumTypes: async ({ customError }: any = {}) => {
        const url = `/v1/AdminGovernance/GetMeetingForumTypeList`;
        return await get({ url, customError });
    },
    getForumFrequency: async ({ customError }: any = {}) => {
        const url = `/v1/AdminGovernance/GetFrequencyTypeList`;
        return await get({ url, customError });
    },
    getMeetingForumsForSupplier: async (supplierId: number, { customError }: any = {}) => {
        const url = `/api/MeetingForum/GetMeetingDetails/${supplierId}`;
        return await get({ url, customError });
    },
    updateSupplierMeetingForum: async (meeting: IMeetingForum, { customError }: any = {}) => {
        const url = `/api/MeetingForum/UpdateMeetingForum`;
        return await put({ url, payload: meeting, customError });
    },
    deleteSupplierMeetingForum: async (
        supplierId: number,
        templateIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/api/MeetingForum/DeleteMeetingForum/${supplierId}`;
        return await remove({ url, payload: templateIds, customError });
    },
};

export { MeetingForums };
