import { useState } from "react";
import { Provider } from "services";

const useLinkPartnerByToken = () => {
    const [loading, setLoading] = useState(false);

    const linkPartner = async (token: string) => {
        setLoading(true);
        const id = await Provider.User.linkPartnerByToken(token, {
            customError: {
                message: "Could not link the partner",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        linkPartner,
    };
};

export { useLinkPartnerByToken };
