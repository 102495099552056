import { useState } from "react";
import { default as serviceProvider } from "../../services/api";
import INcr from "models/ncr/INcr";
import { INcrDeviation } from "models/ncr/INcrDeviation";
import { NcrFieldVisibility } from "models";

const useNcrCreate = () => {
    const [loading, setLoading] = useState(false);

    const createNcr = async ({
        ncr,
        visibilityVisibility,
    }: {
        ncr: any;
        visibilityVisibility: NcrFieldVisibility;
    }) => {
        setLoading(true);
        const ncrCreation = parseNcr({ ncrValues: ncr, visibilityVisibility });
        const ncrId = await serviceProvider.Ncr.createNcr(ncrCreation, {
            customError: {
                message: "Could not create the registration",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return ncrId;
    };

    return {
        loading,
        createNcr,
    };

    function parseNcr({ ncrValues, visibilityVisibility }: any) {
        let ncr: INcr = {
            partnerId: ncrValues.externalPartner,
            ncrReasonId: ncrValues.ncrReason,
            visibility: ncrValues.visibility,
            customizedReceivers: ncrValues.customizedReceivers,
        };
        if (
            visibilityVisibility === NcrFieldVisibility.Invisible ||
            visibilityVisibility === NcrFieldVisibility.InvisibleInternal
        ) {
            ncr["visibility"] = "Internal";
        }
        if (visibilityVisibility === NcrFieldVisibility.InvisibleExternal) {
            ncr["visibility"] = "External";
        }

        for (let [key, value] of Object.entries(ncrValues)) {
            let val: any = value;
            if (key === "salesOrderNumber" && val !== undefined) {
                ncr.salesOrderNumber = val;
            }
            if (key === "purchaseOrderNumber" && val !== undefined) {
                ncr.purchaseOrderNumber = val;
            }
            if (key === "deliveryNote" && val !== undefined) {
                ncr.deliveryNote = val;
            }
            if (key === "ncrValue" && val !== undefined) {
                ncr.ncrValue = val;
            }
            if (key === "ncrValueCurrency" && val !== undefined) {
                ncr.ncrValueCurrency = val;
            }
            if (key === "ncrOtherCost" && val !== undefined) {
                ncr.otherCostsValue = val;
            }
            if (key === "ncrOtherCostCurrency" && val !== undefined) {
                ncr.otherCostsValueCurrency = val;
            }
            if (key === "ncrOtherCostReason" && val !== undefined) {
                ncr.otherCostsReason = val;
            }
            if (key === "comment" && val !== undefined) {
                ncr.comment = val;
            }
            if (key === "attachedFiles" && val !== undefined) {
                ncr.AttachmentIds = val;
            }
            if (key === "ncrId" && val !== undefined && val !== "") {
                ncr.alternateId = val;
            }
            if (key === "ncrPartnerContactReceiver" && val !== undefined) {
                ncr.ncrPartnerContactReceiverIds = [...new Set(val)];
            }
        }

        let ncrActions: any[] = [];
        if (ncrValues.correctiveActionCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.correctiveActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.correctiveActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                actionType: "Corrective",
                owners: [
                    {
                        ownerId: ncrValues.correctiveActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };
            if (ncrValues.correctiveDeadline) {
                actionItem.deadline = ncrValues.correctiveDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        if (ncrValues.preventiveActionCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.preventiveActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.preventiveActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                actionType: "Preventive",
                owners: [
                    {
                        ownerId: ncrValues.preventiveActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };
            if (ncrValues.preventiveDeadline) {
                actionItem.deadline = ncrValues.preventiveDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        if (ncrValues.rootCauseCheckBox === true) {
            let ownerType = "Contact";
            if (ncrValues.rootCauseActionOwner.toString().indexOf("companyId:") > -1)
                ownerType = "Company";
            if (ncrValues.rootCauseActionOwner.toString().indexOf("partnerId:") > -1)
                ownerType = "Partner";
            let actionItem: any = {
                actionType: "RootCause",
                owners: [
                    {
                        ownerId: ncrValues.rootCauseActionOwner
                            .toString()
                            .replace("companyId:", "")
                            .replace("partnerId:", ""),
                        ownerType: ownerType,
                    },
                ],
            };
            if (ncrValues.rootCauseDeadline) {
                actionItem.deadline = ncrValues.rootCauseDeadline.toISOString();
            }
            ncrActions.push(actionItem);
        }
        ncr.ncrActions = ncrActions;

        let deviations: INcrDeviation[] = [];

        if (ncrValues.deviations !== undefined && ncrValues.deviations.length > 0) {
            for (let i = 0; i < ncrValues.deviations.length; i++) {
                deviations.push({
                    productNumber:
                        ncrValues.deviations[i].productNumber &&
                        ncrValues.deviations[i].productNumber,
                    description:
                        ncrValues.deviations[i].description && ncrValues.deviations[i].description,
                    ordered: ncrValues.deviations[i].ordered && ncrValues.deviations[i].ordered,
                    delivered:
                        ncrValues.deviations[i].delivered && ncrValues.deviations[i].delivered,
                    faulty: ncrValues.deviations[i].faulty && ncrValues.deviations[i].faulty,
                });
            }
        }
        if (deviations.length > 0) {
            ncr.deviations = deviations;
        }

        return ncr;
    }
};

export default useNcrCreate;
