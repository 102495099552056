import { useState } from "react";
import { Provider } from "services";

const useSegmentationReviewCriteriaSettingsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (criteriaSettings: any) => {
        setLoading(true);
        const id = await Provider.Segmentation.updateSegmentationReviewCriteriaSettings(
            criteriaSettings,
            {
                customError: { message: `Could not update criteria score settings` },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        update,
    };
};

export { useSegmentationReviewCriteriaSettingsUpdate };
