import { useEffect, useState } from "react";
import { Provider } from "services";

const useTemplateGroupMatrixOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });
        (async () => {
            if (!externalLoading) {
                let data = await Provider.Compliance.getTemplateMatrixOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters,
                        sorts,
                    },
                    {
                        customError: {
                            message: "Could not retrieve list of template matrix",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                let rows: any = [],
                    prevTemplate: any = null,
                    templateGroupStart: number = 0,
                    templateGroupCount: number = 0;

                data.data.forEach((template: any, index: number) => {
                    let row: any = {};
                    row["id"] = template.id;
                    row["name"] = template.name;
                    row["templateGroup"] = {
                        id: template.templateGroup.id,
                        name: template.templateGroup.name,
                    };

                    if (
                        !prevTemplate ||
                        prevTemplate.templateGroup.id !== template.templateGroup.id
                    ) {
                        if (template.templateGroup.numberOfTemplates > 0) {
                            row["templateGroup"]["rowSpan"] =
                                template.templateGroup.numberOfTemplates;
                        } else {
                            row["templateGroup"]["rowSpan"] = null;
                            row["templateGroup"]["name"] = "-";
                        }
                    } else if (
                        prevTemplate &&
                        prevTemplate.templateGroup.id === template.templateGroup.id
                    ) {
                        if (template.templateGroup.numberOfTemplates > 0) {
                            row["templateGroup"]["rowSpan"] = 0;
                        } else {
                            row["templateGroup"]["rowSpan"] = null;
                            row["templateGroup"]["name"] = "-";
                        }
                    }

                    template.groups.forEach((group: any) => {
                        row[group.name] = group.checked;
                    });
                    rows = [...rows, row];
                    prevTemplate = template;
                });
                if (templateGroupCount !== 0) {
                    rows[templateGroupStart].templateGroup.rowSpan = templateGroupCount;
                }

                setState({
                    loading: false,
                    data: rows,
                    totalRows: data.totalNumberOfRecords,
                });
            }
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
    };
};

export { useTemplateGroupMatrixOverview };
