import { useEffect, useState } from "react";
import Provider from "services/api";

const useSetShownOnAllNotifications = (reload: any = null) => {
    const [state, setState] = useState<any>({
        setloading: true,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, setloading: true });
            await Provider.Notification.setShownOnAllNotifications().catch((err: any) => {
                setState({ ...state, setloading: false });
                return Promise.reject(err);
            });
            setState({
                setloading: false,
            });
        })();
    }, [reload]);

    return {
        setloading: state.loading,
    };
};

export { useSetShownOnAllNotifications };
