import { get } from "../base";

const SupplierPortal = {
    getSupplierCustomerNames: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSupplierCustomerNames`;
        return await get({ url, customError });
    },
    getSupplierCustomerEmails: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSupplierCustomerEmails`;
        return await get({ url, customError });
    },
    getSupplierSurveyRequests: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSurveyRequestForSupplier`;
        return await get({ url, customError });
    },
    getSupplierSurveyRequestCount: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSurveyRequestForSupplierCount`;
        return await get({ url, customError });
    },
    getOpenNcrForSupplierCount: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetOpenNcrForSupplierCount`;
        return await get({ url, customError });
    },
    getActionsForSupplierCount: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetActionsForSupplierCount`;
        return await get({ url, customError });
    },
    getComplianceForSupplierCount: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetComplianceForSupplierCount`;
        return await get({ url, customError });
    },
    getSelfAssessmentRequestsForSupplier: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSelfAssessmentRequestsForSupplier`;
        return await get({ url, customError });
    },
    getSelfAssessmentRequestsForSupplierCount: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSelfAssessmentRequestsForSupplierCount`;
        return await get({ url, customError });
    },
    getReviewAverage: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSupplierReviewAverage`;
        return await get({ url, customError });
    },
    GetSupplierReviewAverageByCriteriaGroup: async ({ customError }: any = {}) => {
        const url = `/v1/SupplierPortal/GetSupplierReviewAverageByCriteriaGroup`;
        return await get({ url, customError });
    },
};

export { SupplierPortal };
