import React, { useState } from "react";
import classes from "./performanceFiles.module.scss";
import {
    Button,
    Col,
    Form,
    Input,
    List,
    notification,
    Popconfirm,
    Popover,
    Row,
    Typography,
} from "antd";
import { DownloadFile, Edit02, File02, Link01, Trash02 } from "components/utilitycomponents";
import {
    useFileRemove,
    useFileUpdate,
    usePerformanceFileCrud,
    usePerformanceFiles,
} from "controller";
import { useTranslation } from "react-i18next";
import AddModal from "./addModal";
import dayjs from "utilities/daysJsConfig";
const { Text } = Typography;

interface IProps {
    partnerId: number;
}

const PerformanceFiles = ({ partnerId }: IProps) => {
    const [toggleAddModal, setToggleAddModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading } = usePerformanceFiles(partnerId, reload);

    const getIcon = (item: any) => {
        if (item.type === "file") {
            return <File02 className={classes.listIcon} />;
        }
        return <Link01 className={classes.listIcon} />;
    };

    return (
        <div className={classes.filesContainer}>
            <Row className={classes.containerTitleRow}>
                <Col span={18} className={classes.containerTitle}>
                    {t("performance_FileAreaTitle")}
                </Col>
                <Col span={6} className={classes.containerTitleButton}>
                    <Button
                        size="small"
                        type="primary"
                        block
                        onClick={() => setToggleAddModal(true)}>
                        {t("generic_Add")} +
                    </Button>
                </Col>
            </Row>
            <div className={classes.fileList}>
                <List
                    loading={loading}
                    pagination={
                        data.length > 5
                            ? {
                                  defaultCurrent: 1,
                                  pageSize: 4,
                                  total: data.length,
                                  size: "small",
                              }
                            : false
                    }
                    size="small"
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item: any, index: number) => (
                        <List.Item
                            actions={[
                                <EditTitle
                                    item={item}
                                    onTitleUpdate={() => setReload({ reload: true })}
                                />,
                                <RemoveFile
                                    item={item}
                                    onRemoveFile={() => setReload({ reload: true })}
                                />,
                            ]}>
                            <List.Item.Meta
                                avatar={
                                    <div className={classes.listIconContainer}>{getIcon(item)}</div>
                                }
                                title={
                                    item.type === "file" ? (
                                        <DownloadFile
                                            hideIcon
                                            file={{
                                                id: item.id,
                                                name: item.name,
                                            }}
                                            title={item.title && item.title.substring(0, 30)}
                                            ellipsis={true}
                                        />
                                    ) : (
                                        <div>
                                            <a
                                                className={classes.url}
                                                href={
                                                    item.url.indexOf("https") === -1
                                                        ? `https://${item.url}`
                                                        : item.url
                                                }
                                                target="_blank"
                                                rel="noreferrer">
                                                {item.title
                                                    ? item.title.substring(0, 30)
                                                    : item.url.substring(0, 30)}
                                            </a>
                                        </div>
                                    )
                                }
                                description={
                                    <Row>
                                        <Col span={8}>
                                            <Text
                                                className={classes.smallText}
                                                ellipsis={{
                                                    tooltip:
                                                        item.type === "file" ? item.name : item.url,
                                                }}>
                                                {item.type === "file" ? item.name : item.url}
                                            </Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text
                                                className={classes.smallText}
                                                ellipsis={{
                                                    tooltip: item.createdBy,
                                                }}>{`${t("createdBy")} ${item.createdBy}`}</Text>
                                        </Col>
                                        <Col span={4} className={classes.smallText2}>
                                            {dayjs(item.created).format("MMM D, YYYY")}
                                        </Col>
                                    </Row>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
            <AddModal
                partnerId={partnerId}
                visible={toggleAddModal}
                onClose={() => {
                    setToggleAddModal(false);
                    setReload({ reload: true });
                }}
            />
        </div>
    );
};

interface IEditTitleProps {
    item: any;
    onTitleUpdate: Function;
}

interface IRemoveFileProps {
    item: any;
    onRemoveFile: Function;
}

const RemoveFile = ({ item, onRemoveFile }: IRemoveFileProps) => {
    const { t } = useTranslation();
    const { removeFile } = useFileRemove();
    const { remove: removeLink } = usePerformanceFileCrud();

    return (
        <Popconfirm
            placement="left"
            title={t("file_RemovalConfirm")}
            cancelText={t("generic_Cancel")}
            okText={t("generic_Confirm")}
            onConfirm={async () => {
                if (item.type === "link") {
                    await removeLink(item.id);
                    onRemoveFile();
                }
                if (item.type === "file") {
                    await removeFile([item.id]);
                    onRemoveFile();
                }
            }}>
            <span>
                <Trash02 className={classes.actionIcon} />
            </span>
        </Popconfirm>
    );
};

const EditTitle = ({ item, onTitleUpdate }: IEditTitleProps) => {
    const { updateFileTitle, loading: updating } = useFileUpdate();
    const { upsert: updateLink } = usePerformanceFileCrud();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(false);

    const handleSave = async () => {
        let values = await form.validateFields();

        if (item.type === "link") {
            await updateLink({ ...item, title: values.fileTitle, url: values.url });
        }
        if (item.type === "file") {
            await updateFileTitle(item.id, values.fileTitle);
        }

        notification.success({
            message: t("success"),
            description: t("generic_UpdateSuccess", {}),
        });
        onTitleUpdate();
        setToggle(false);
    };

    const content = () => {
        return (
            <>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleSave}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 12 }}>
                    {item.type === "link" && (
                        <Form.Item
                            name="url"
                            initialValue={item.url}
                            label={t("generic_Url")}
                            rules={[{ required: true, whitespace: true, type: "url" }]}>
                            <Input
                                style={{ width: 300 }}
                                maxLength={4000}
                                placeholder={t("generic_UrlPlaceholder")}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        name="fileTitle"
                        initialValue={item.title}
                        label={t("generic_Title")}>
                        <Input
                            style={{ width: 300 }}
                            maxLength={100}
                            placeholder={t("file_TitlePlaceholder")}
                        />
                    </Form.Item>
                    <Row>
                        <Col span={18} style={{ textAlign: "right" }}>
                            <Button onClick={() => setToggle(false)}>{t("generic_Cancel")}</Button>
                        </Col>
                        <Col span={6} style={{ textAlign: "right" }}>
                            <Button
                                style={{ textAlign: "end" }}
                                type="primary"
                                onClick={() => form.submit()}
                                loading={updating}>
                                {t("generic_Save")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    };

    return (
        <Popover
            trigger="click"
            placement="left"
            content={() => content()}
            open={toggle}
            onOpenChange={(visible: boolean) => {
                if (visible) {
                    form.resetFields();
                }
            }}>
            <span>
                <Edit02 onClick={() => setToggle(true)} />
            </span>
        </Popover>
    );
};

export { PerformanceFiles };
