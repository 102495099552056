import React, { FunctionComponent } from "react";
import { Form, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useReviewCreate } from "controller";
import CreateForm from "./createForm";
import { PageContainer } from "components/utilitycomponents";

const Create: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const { createReview, loading } = useReviewCreate();

    return (
        <PageContainer title={t("review_CreateReview")}>
            <CreateForm
                onSubmit={() => history.push("/Reviews")}
                createReview={createReview}
                form={form}
            />
            <Row>
                <Col span={18} style={{ textAlign: "right" }}>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                        {t("Save")}
                    </Button>
                </Col>
            </Row>
        </PageContainer>
    );
};

export default Create;
