import React, { SyntheticEvent, useState } from "react";
import { useRisks } from "controller";
import { useTranslation } from "react-i18next";
import {
    AlertCircle,
    CheckCircle,
    Clock,
    Edit02,
    Grid,
    GridDeleteModal,
    PageContainer,
    PureDiv,
    Trash02,
} from "components/utilitycomponents";
import { CreateEditModal } from "./createEditModal";
import { IRisk, RiskState, RiskType } from "models";
import classes from "./risks.module.scss";
import { Affix, Button, Col, Row, Tooltip } from "antd";
import { ChangeState } from "./changeState";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    partnerId: number;
}

const Risks = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { remove, deleteLoading } = useRisks(partnerId, false);
    const [createEditModal, setCreateEditModal] = useState<any>({
        visible: false,
        row: undefined,
    });
    const [deleteModal, setDeleteModal] = useState<any>({
        visible: false,
        records: [],
    });
    const [changeState, setChangeState] = useState<any>({
        visible: false,
        row: undefined,
    });

    const handleDelete = async () => {
        await remove(deleteModal.records.map((r: IRisk) => r.id));
        setDeleteModal({
            visible: false,
            records: [],
        });
        setReload({ reload: true });
    };

    const getRiskState = (riskState: RiskState) => {
        switch (riskState) {
            case RiskState.Mitigated:
                return {
                    text: t("riskAndIssues_Mitigated"),
                    color: "#94af41",
                    icon: <CheckCircle />,
                };

            case RiskState.MitigationOnGoing:
                return {
                    text: t("riskAndIssues_MitigationOnGoing"),
                    color: "#dba92e",
                    icon: <Clock />,
                };

            case RiskState.NotMitigated:
                return {
                    text: t("riskAndIssues_NotMitigated"),
                    color: "#d87625",
                    icon: <AlertCircle />,
                };
        }
    };

    const getRiskType = (riskType: RiskType) => {
        switch (riskType) {
            case RiskType.Highlight:
                return t("riskAndIssues_Highlight");
            case RiskType.Issue:
                return t("riskAndIssues_Issue");
            case RiskType.Risk:
                return t("riskAndIssues_RiskType");
        }
    };

    const columns = [
        {
            title: t("riskAndIssues_Title"),
            dataIndex: "title",
            key: "Title",
            viewColumnId: "title",
            width: 500,
            render: (value: string) => (
                <PureDiv
                    className={classes.title}
                    textContent={value}
                    onClick={(e: any) => e.stopPropagation()}></PureDiv>
            ),
        },
        {
            title: t("riskAndIssues_Type"),
            dataIndex: "type",
            key: "Type",
            viewColumnId: "type",
            align: "center",
            render: (value: RiskType) => <span className={classes.type}>{getRiskType(value)}</span>,
        },
        {
            title: t("riskAndIssues_State"),
            dataIndex: "state",
            key: "State",
            viewColumnId: "state",
            align: "center",
            render: (value: RiskState, record: IRisk[]) => (
                <Tooltip title={t("riskAndIssues_ChangeState")} placement="right">
                    <span>
                        {React.cloneElement(getRiskState(value).icon, {
                            style: { fontSize: "22px", color: getRiskState(value).color },
                            onClick: (e: any) => {
                                e.stopPropagation();
                                setChangeState({ visible: true, row: record });
                            },
                        })}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: t("governance_LastModified"),
            dataIndex: "modified",
            key: "modified",
            viewColumnId: "modified",
            render: (val: string) => <div>{val && dayjs(val).format("MMM D, YYYY (HH:mm)")}</div>,
        },
        {
            title: t("governance_ModifiedBy"),
            dataIndex: "modifiedBy",
            key: "modifiedBy",
            viewColumnId: "modifiedBy",
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "delete",
            key: "delete",
            viewColumnId: "delete",
            width: 50,
            align: "right",
            operationColumn: true,
            render: (_: any, record: IRisk) => (
                <Row>
                    <Col span={16}>
                        <Tooltip title={t("generic_Edit")} placement="left">
                            <span>
                                <Edit02
                                    style={{ fontSize: "16px", margin: "0 10px" }}
                                    onClick={() => {
                                        setCreateEditModal({ row: record, visible: true });
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </Col>
                    <Col span={2}>
                        <Tooltip title={t("riskAndIssues_Delete")} placement="right">
                            <span>
                                <Trash02
                                    style={{ fontSize: "16px", margin: "0 4px" }}
                                    onClick={(e: SyntheticEvent) => {
                                        e.stopPropagation();
                                        setDeleteModal({ visible: true, records: [record] });
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <PageContainer fluid gridContainer style={{ padding: 8, marginBottom: 8, borderRadius: 5 }}>
            <Grid
                columns={columns}
                useData={useRisks.bind(null, partnerId, true)}
                tableExcessHeight={160}
                saveColumnSettings={false}
                saveColumnOrder={false}
                saveColumnWidth={false}
                hideGridSetting
                hideColumnSetting
                showTitle={false}
                className={classes.risksGrid}
                size="large"
                reloadData={reload}
                showEmptyText
            />

            <Affix style={{ position: "fixed", bottom: 10, right: 30 }} className={classes.affix}>
                <Button
                    className={classes.addBtn}
                    shape="round"
                    onClick={() => setCreateEditModal({ visible: true, row: undefined })}>
                    {t("riskAndIssues_AddRisk")}
                </Button>
            </Affix>
            <CreateEditModal
                partnerId={partnerId}
                visible={createEditModal.visible}
                item={createEditModal.row}
                onCancel={() => setCreateEditModal({ visible: false, row: undefined })}
                onSuccess={() => {
                    setCreateEditModal({ visible: false, row: undefined });
                    setReload({ reload: true });
                }}
            />
            <ChangeState
                partnerId={partnerId}
                visible={changeState.visible}
                item={changeState.row}
                onClose={(reload: boolean = false) => {
                    setChangeState({ visible: false, row: undefined });
                    if (reload) {
                        setReload({ reload: true });
                    }
                }}
            />
            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                        records: [],
                    });
                }}
                data={deleteModal.records.map((r: IRisk) => ({
                    id: r.id,
                    name: r.title.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 25), // remove html tags
                }))}
                name={t("riskAndIssues_Risk", {
                    count: deleteModal.records.length > 1 ? 2 : 1,
                })}
                confirmLoading={deleteLoading}
                onConfirm={handleDelete}
            />
        </PageContainer>
    );
};

export { Risks };
