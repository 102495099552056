import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Input, Divider, Form } from "antd";
import { useTranslation } from "react-i18next";
import { TypedQuestion } from "components/contentcomponents/compliance/overview/sharedComponents";
import classes from "components/contentcomponents/compliance/overview/edit/styles/edit.module.scss";
import { IComplianceHeadLine, IComplianceQuestion } from "models";
import dayjs from "utilities/daysJsConfig";
const { TextArea } = Input;
interface IProps {
    headlines: IComplianceHeadLine[];
    answers: any[];
    onAttachDocumentLoading?: any;
    form?: any;
    loadFiles?: any;
}

const TemplateHeadLines = ({ headlines, answers, onAttachDocumentLoading, loadFiles }: IProps) => {
    const { t } = useTranslation();
    const [fileQuestionsLoading, setFileQuestionsLoading] = useState<any[]>([]);

    useEffect(() => {}, [answers]);

    useEffect(() => {
        onAttachDocumentLoading && onAttachDocumentLoading(fileQuestionsLoading);
    }, [fileQuestionsLoading]);

    return (
        <Fragment>
            {headlines &&
                headlines.map((headLine: IComplianceHeadLine, index: number) => (
                    <Fragment key={headLine.id}>
                        <Divider
                            orientationMargin={0}
                            orientation="left"
                            style={{ fontWeight: "bold" }}>
                            {index + 1}. {headLine.headline}
                        </Divider>
                        {headLine.complianceQuestions &&
                            headLine.complianceQuestions.map(
                                (question: IComplianceQuestion, questionIndex: number) => {
                                    const options: any = {
                                        rules: [
                                            {
                                                required:
                                                    question.isMandatory &&
                                                    question.questionType !== "Check"
                                                        ? question.isMandatory
                                                        : false,
                                                message: t("inputIsRequiredError"),
                                            },
                                        ],
                                    };
                                    let answerId = 0,
                                        answerValue = undefined,
                                        answerComment = undefined,
                                        answerValueMultiple: any = undefined;
                                    if (
                                        question.complianceAnswers &&
                                        question.complianceAnswers.length !== 0
                                    ) {
                                        answerId = question.complianceAnswers[0].id;
                                    }

                                    answers &&
                                        answers.forEach((answer: any) => {
                                            if (
                                                question.questionType !== "AttachDocuments" &&
                                                Number(answer.complianceQuestionId) ===
                                                    Number(question.id)
                                            ) {
                                                answerId = answer.id;
                                                answerValue = answer.value;
                                                answerComment = answer.comment;
                                                answerValueMultiple =
                                                    answer.multipleChoiceQuestionValue;
                                                return;
                                            }
                                        });

                                    if (answerValue) {
                                        if (question.questionType === "Date") {
                                            options["initialValue"] = dayjs(answerValue);
                                        } else if (question.questionType === "Check") {
                                            options["valuePropName"] = "value";
                                            options["initialValue"] = answerValue === "true";
                                        } else options["initialValue"] = answerValue;
                                    } else if (question.questionType === "AttachDocuments") {
                                        let index = answers.findIndex(
                                            (x: any) => x.complianceQuestionId === question.id
                                        );

                                        if (index !== -1) {
                                            answerComment = answers[index].comment;
                                            question.complianceAnswers = [
                                                {
                                                    comment: answers[index].comment,
                                                    complianceId: answers[index].complianceId,
                                                    complianceQuestionId:
                                                        answers[index].complianceQuestionId,
                                                    id: answers[index].id,
                                                    value: answers[index].value,
                                                    files: answers[index].files.map(
                                                        (file: any) => ({
                                                            id: file.id,
                                                            name: file.name,
                                                        })
                                                    ),
                                                },
                                            ];
                                            options["initialValue"] = answers[index].files.map(
                                                (file: any) => file.id
                                            );
                                        }
                                    } else if (
                                        question.questionType === "MultipleChoiceCustomDropdown"
                                    ) {
                                        if (
                                            answerValueMultiple !== undefined &&
                                            answerValueMultiple !== null
                                        )
                                            options["initialValue"] = answerValueMultiple.split(
                                                "|"
                                            );
                                        else {
                                            options["initialValue"] = [];
                                        }
                                    } else {
                                        options["initialValue"] = answerValue;
                                    }

                                    return (
                                        <Row
                                            gutter={16}
                                            style={{ marginBottom: "22px" }}
                                            key={question.id}>
                                            <Col lg={16} md={16} sm={24} xs={24}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <div className={classes.question}>
                                                                {index + 1}.{questionIndex + 1}.{" "}
                                                                {question.isMandatory &&
                                                                    question.isMandatory && (
                                                                        <span
                                                                            className={
                                                                                classes.required
                                                                            }>
                                                                            *
                                                                        </span>
                                                                    )}{" "}
                                                                {question.question}
                                                            </div>
                                                            <div>{question.guideline}</div>
                                                        </>
                                                    }
                                                    labelCol={{ lg: 16, md: 17, sm: 24, xs: 24 }}
                                                    wrapperCol={{ lg: 8, md: 9, sm: 24, xs: 24 }}
                                                    name={`question[${question.id}]answer[${answerId}]`}
                                                    initialValue={options.initialValue}
                                                    valuePropName={options.valuePropName}
                                                    rules={options.rules}>
                                                    <TypedQuestion
                                                        question={question}
                                                        onUploadingFile={(uploading: boolean) => {
                                                            let index = fileQuestionsLoading.findIndex(
                                                                (x: any) =>
                                                                    Object.keys(x)[0] ===
                                                                    question.id.toString()
                                                            );
                                                            if (index === -1) {
                                                                setFileQuestionsLoading([
                                                                    ...fileQuestionsLoading,
                                                                    {
                                                                        [`${question.id}`]: uploading,
                                                                    },
                                                                ]);
                                                            }
                                                        }}
                                                        loadFiles={loadFiles}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={8} md={8} sm={24} xs={24}>
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}
                                                    name={`comment[${question.id}]`}
                                                    initialValue={answerComment}>
                                                    <TextArea
                                                        maxLength={2000}
                                                        rows={4}
                                                        placeholder={t("compliance_AddComment")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    );
                                }
                            )}
                    </Fragment>
                ))}
        </Fragment>
    );
};

export default TemplateHeadLines;
