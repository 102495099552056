import React, { useState } from "react";
import { useNewsLog, useNewsLogDelete } from "controller";
import { useTranslation } from "react-i18next";
import classes from "./newsLog.module.scss";
import { Button, Col, Input, Row, DatePicker, Table, Tooltip, Popconfirm, Switch } from "antd";
import dayjs from "utilities/daysJsConfig";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { DownloadFile, Plus, PureDiv, Trash02 } from "components/utilitycomponents";
import { CreateModal } from "./createModal";
const { Search } = Input;
const { RangePicker } = DatePicker;

interface IProps {
    partnerId: number;
}

const NewsLog = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [from, setFrom] = useState<any>(undefined);
    const [to, setTo] = useState<any>(undefined);
    const [searchTitle, setSearchTitle] = useState<string | undefined>(undefined);
    const [reload, setReload] = useState<any>({ reload: false });
    const [showFlaggedItems, setShowFlaggedItem] = useState<boolean>(true);
    const { data, loading } = useNewsLog(
        partnerId,
        reload,
        searchTitle,
        from,
        to,
        showFlaggedItems
    );
    const { height } = useWindowDimensions();
    const [toggleCreateModal, setToggleCreateModal] = useState(false);
    const { deleteItem } = useNewsLogDelete();

    const columns = [
        {
            title: (
                <div className={classes.columnHeader}>
                    {t("supplierProfile_NewsLogDateTime").toUpperCase()}
                </div>
            ),
            key: "dateTime",
            dataIndex: "createdDate",
            width: 200,
            render: (val: string) => <div>{dayjs(val).format("MMM D, YYYY (HH:mm a)")}</div>,
        },
        {
            title: (
                <div className={classes.columnHeader}>
                    {t("supplierProfile_NewsLogEvent").toUpperCase()}
                </div>
            ),
            key: "event",
            dataIndex: "title",
            render: (val: string, row: any) => (
                <>
                    {row.url && row.url.trim() !== "" ? (
                        <>
                            <Row>
                                <PureDiv
                                    style={{ fontWeight: "bold", maxWidth: "97%" }}
                                    textContent={val}
                                />
                                <PureDiv textContent={row.summary} />
                                <Tooltip
                                    placement="bottom"
                                    overlayInnerStyle={{ color: "#fff" }}
                                    title={row.url}
                                    mouseEnterDelay={0.5}
                                    color="blue">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ cursor: "pointer", marginLeft: 5 }}
                                        href={row.url}>
                                        {t("generic_ViewSource")}
                                    </a>
                                </Tooltip>
                            </Row>
                            <Row>
                                {row.files.length !== 0 &&
                                    row.files.map((file: any) => (
                                        <div style={{ marginRight: 4 }}>
                                            <DownloadFile file={file} />
                                        </div>
                                    ))}
                            </Row>
                        </>
                    ) : (
                        <>
                            <div>
                                <PureDiv textContent={val} />
                            </div>
                            <div>
                                <PureDiv textContent={row.summary} />
                            </div>
                            {row.files.length !== 0 &&
                                row.files.map((file: any) => (
                                    <div style={{ marginRight: 4 }}>
                                        <DownloadFile file={file} />
                                    </div>
                                ))}
                        </>
                    )}
                    {row.isCraftData === false && (
                        <div className={classes.delete}>
                            <Popconfirm
                                placement="left"
                                title={t("generic_RemoveConfirm", {
                                    title: t("generic_Item").toLocaleLowerCase(),
                                })}
                                onConfirm={async () => {
                                    await deleteItem(row.id);
                                    setReload({ reload: true });
                                }}>
                                <span style={{ cursor: "pointer" }}>
                                    <Trash02 />
                                </span>
                            </Popconfirm>
                        </div>
                    )}
                </>
            ),
        },
    ];

    return (
        <div className={classes.containter}>
            <Table
                className={classes.customTableStyle}
                title={() => (
                    <div style={{ marginBottom: -10 }}>
                        <Row>
                            <Col span={20}>
                                <div className={classes.tableTitle}>
                                    {t("supplierProfile_NewsLogHeadline").toUpperCase()}
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={3}>
                                <Switch
                                    onChange={(e: any) => setShowFlaggedItem(!showFlaggedItems)}
                                    style={{ width: 125 }}
                                    defaultChecked={showFlaggedItems}
                                    checkedChildren={<div>{t("generic_Curated")}</div>}
                                    unCheckedChildren={<div>{t("generic_All")}</div>}
                                />
                            </Col>
                            <Col span={5}>
                                <RangePicker
                                    onChange={(values: any) => {
                                        if (values && values[0]) {
                                            setFrom(values[0].startOf("month").toISOString());
                                        } else {
                                            setFrom(undefined);
                                        }
                                        if (values && values[1]) {
                                            setTo(values[1].endOf("month").toISOString());
                                        } else {
                                            setTo(undefined);
                                        }
                                    }}
                                    size="small"
                                    picker="month"
                                    presets={[
                                        {
                                            label: t("dateRange_last3Months"),
                                            value: [
                                                dayjs().subtract(2, "months"),
                                                dayjs().endOf("day"),
                                            ],
                                        },
                                        {
                                            label: t("dateRange_last6Months"),
                                            value: [
                                                dayjs().subtract(5, "months"),
                                                dayjs().endOf("day"),
                                            ],
                                        },
                                        {
                                            label: t("dateRange_lastYear"),
                                            value: [
                                                dayjs().subtract(11, "months"),
                                                dayjs().endOf("day"),
                                            ],
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={11}>
                                <Search
                                    allowClear
                                    loading={loading}
                                    size="small"
                                    placeholder={t("supplierProfile_NewsLogSearchPlaceholder")}
                                    onSearch={(searchValue: string) => setSearchTitle(searchValue)}
                                    style={{ width: 300 }}
                                />
                            </Col>
                            <Col span={5} style={{ textAlign: "end" }}>
                                <div
                                    className={classes.addButton}
                                    onClick={() => {
                                        setToggleCreateModal(true);
                                    }}>
                                    <Button size="small" icon={<Plus />} style={{ marginRight: 8 }}>
                                        {t("generic_AddItem")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
                dataSource={data}
                columns={columns}
                bordered={false}
                size="large"
                scroll={{ y: height! - 480 }}
                loading={loading}
            />
            <CreateModal
                visible={toggleCreateModal}
                onClose={() => {
                    setToggleCreateModal(false);
                }}
                onCreate={() => {
                    setReload({ reload: true });
                }}
            />
        </div>
    );
};

export { NewsLog };
