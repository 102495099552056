export enum ITableType {
    "Partner" = 1,
    "MasterData" = 2,
    "Compliance" = 3,
    "ComplianceMatrix" = 4,
    "ComplianceRequest" = 5,
    "Ncr" = 6,
    "Reviews" = 7,
    "Surveys" = 8,
    "NonResponse" = 9,
    "AutomatedSurveys" = 10,
    "Contact" = 11,
    "ContactGroup" = 12,
    "File" = 13,
    "Template" = 14,
    "UserAccessGroup" = 15,
    "GateKeeper" = 16,
    "StatusEventSettings" = 17,
    "TemplateGroup" = 18,
    "UserManagement" = 19,
    "CustomDropdown" = 20,
    "TemplateMatrixGroup" = 21,
    "UserActivityLog" = 22,
    "InvitationStatus" = 23,
    "InvitePartnerList" = 24,
    "Action" = 25,
    "PartnerStatus" = 26,
    "ReviewCriteriaGroup" = 27,
    "ReviewCriteria" = 28,
    "DistributionList" = 29,
    "DistributionAssignmentList" = 30,
    "SurveyTemplateGroup" = 31,
    "SurveyTemplate" = 32,
    "SurveyResults" = 33,
    "BusinessPartner" = 34,
    "DevelopmentPlan" = 35,
    "ProductOverview" = 36,
    "ScorecardSettings" = 37,
    "ScorecardOverview" = 38,
}
