import { ISystemActionType } from "models/action";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    systemGeneratedType?: ISystemActionType;
    description: string;
}

const ActionDescriptionRenderer: FunctionComponent<IProps> = ({
    description,
    systemGeneratedType,
}) => {
    const { t } = useTranslation();
    const renderDescription = (description: string, systemType?: ISystemActionType) => {
        switch (systemType) {
            case undefined:
                return description;
            case ISystemActionType.NcrHolder:
                return `${t("action_NcrOpen")} - ${t(description)}`;
            case ISystemActionType.NcrOpenOnSupplier:
                return `${t(description)} ${t("action_NcrOpenOnYourCompany")}`;
            case ISystemActionType.NcrCorrectiveAction:
            case ISystemActionType.NcrPreventiveAction:
            case ISystemActionType.NcrRootCauseAction:
                return `${t(description)}`;
            case ISystemActionType.SelfAssessmentRequest:
                return `${t("action_SelfAssessmentRequest", {
                    templateName: description,
                    interpolation: { escapeValue: false },
                })}`;
            case ISystemActionType.SurveyRequest:
                return t(description);
            case ISystemActionType.GateKeeper:
                return `${t("action_gatekeeperDescription", {
                    templateName: description,
                    interpolation: { escapeValue: false },
                })}`;
            case ISystemActionType.ComplianceEndDate:
                return `${t("action_ComplianceEndDateDescription", {
                    templateName: description,
                    interpolation: { escapeValue: false },
                })}`;
            case ISystemActionType.SegmentationSettingCurrentAssignedSegment:
                return `${t("action_SettingAssignedSegment(Current)")}`;
            default:
                return description;
        }
    };

    return <div>{renderDescription(description, systemGeneratedType)}</div>;
};

export { ActionDescriptionRenderer };
