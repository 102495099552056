import { useState } from "react";
import { Provider } from "services";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useCompanyType } from "controller";

const usePartnerDelete = () => {
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();
    const [loading, setLoading] = useState(false);

    const remove = async (partnerIds: number[]) => {
        setLoading(true);

        const res = await Provider.Partner.deletePartners(partnerIds, { retryCount: 3 }).catch(
            err => {
                notification.error({
                    message: t("systemMessage"),
                    description: t("supplier_DeleteSupplierError", {
                        companyType: isDefaultCompany
                            ? t("company_Type_Supplier").toLocaleLowerCase()
                            : t("company_Type_Customer").toLocaleLowerCase(),
                    }),
                    duration: 0,
                });
                setLoading(false);
                return Promise.reject(err);
            }
        );

        setLoading(false);
        return res;
    };
    return {
        loading,
        remove,
    };
};

export { usePartnerDelete };
