import { Modal, Tabs, Form, Divider } from "antd";
import { useNcrFeature, useNotificationSettings } from "controller";
import { INotificationSetting } from "models";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { hasUserTypeAccess } from "utilities";
import NotificationSetting from "../user/notificationSetting";

interface IProps {
    open: boolean;
    onClose: () => void;
}

const EmailNotificationsModal = ({ open, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { data, loading } = useNotificationSettings();
    const [settings, setSettings] = useState<INotificationSetting[]>([]);
    const { hasNcrFeature } = useNcrFeature();
    const user = useSelector((state: AppState) => state.oidc.user);

    useEffect(() => {
        form.resetFields();
        let permissedSettings = data.filter((n: INotificationSetting) =>
            hasUserTypeAccess(
                user.profile["ll-Usertype"],
                n.moduleName ? n.moduleName : "",
                hasNcrFeature
            )
        );
        setSettings(permissedSettings);
    }, [data]);

    if (data === undefined || loading) {
        return null;
    }

    return (
        <Modal
            width={900}
            title="Email Notification Settings"
            open={open}
            onCancel={() => {
                onClose();
            }}
            footer={null}>
            <Form form={form}>
                <Tabs
                    tabPosition="left"
                    items={settings.map((n: INotificationSetting) => ({
                        key: n.moduleId.toString(),
                        label: n.moduleName,
                        children: (
                            <>
                                <Divider orientation="left">{n.moduleName}</Divider>
                                <NotificationSetting moduleSetting={n} />
                            </>
                        ),
                    }))}></Tabs>
            </Form>
        </Modal>
    );
};
export { EmailNotificationsModal };
