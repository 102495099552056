import { useState } from "react";
import { Provider } from "services";

const useActivityRemove = () => {
    const [loading, setLoading] = useState(false);

    const remove = async (activityId: number) => {
        setLoading(true);
        const id = await Provider.Activity.remove(activityId);
        setLoading(false);
        return id;
    };

    return {
        loading,
        remove,
    };
};

export { useActivityRemove };
