import React, { FunctionComponent, useEffect, useState } from "react";
import classNames from "classnames";
import {
    DeleteIcon,
    SelectData,
    SelectDataMultiple,
    SelectTreeDataMultiple,
} from "components/utilitycomponents";
import {
    useCommodityCategoriesByPartner,
    useAnalysisUnitRegions,
    useAnalysisUnitDivisions,
    usePartnerList,
    useSegmentationFilters,
    usePartnerChildren,
    useCompanyUsers,
    useSavedFilters,
} from "controller";
import { useTranslation } from "react-i18next";
import classes from "./topFilter.module.scss";
import { Button, DatePicker, Input, Spin } from "antd";
import dayjs from "utilities/daysJsConfig";
import { FilterTwoTone } from "@ant-design/icons";
import { SavedFilterType, ISavedReportFilter, SurveyType } from "models";
import SaveFilterModal from "./saveFilterModal";
import { RangePicker } from "components/ui";

type PropsType = {
    onFiltersChange?: Function;
    filterType: SavedFilterType; //type of saved filters
    updateSavedFilters: any;
    onSavedFiltersChange: Function;
    type: SurveyType;
    onDateRangeChange?: Function;
    partnerId: number;
};

const currentYear = dayjs(
    dayjs()
        .year()
        .toString()
).format("YYYY");

const TopFilter: FunctionComponent<PropsType> = ({
    onFiltersChange,
    filterType,
    updateSavedFilters,
    onSavedFiltersChange,
    type,
    onDateRangeChange,
    partnerId,
}) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>([]);
    const [supplier, setSupplier] = useState<any>([]);
    const [saveFilterModal, setSaveFilterModal] = useState({ visible: false });
    const [selectedSavedFilter, setSelectedSavedFilter] = useState<any>(undefined);
    const [reloadSavedFilters, setReloadSavedFilters] = useState<any>();
    const { getFilter, filterLoading, deleteFilter, deleteFilterLoading } = useSegmentationFilters(
        filterType
    );

    useEffect(() => {
        if (filterType === SavedFilterType.SegmentationPerformance) {
            setSupplier([partnerId]);
            onFiltersChange &&
                onFiltersChange([...filters, { type: "Supplier", value: partnerId.toString() }]);
            setFilters([...filters, { type: "Supplier", value: partnerId.toString() }]);
        }
    }, [partnerId]);

    const handleSelectSavedFilter = async (id: string) => {
        setSelectedSavedFilter(id);
        const savedFilters = await getFilter(id);

        let newFilters = savedFilters.map((f: any) => {
            switch (f.type) {
                case "Year":
                case "SupplierNumber":
                    return { type: f.type, value: f.value };
                case "From":
                case "To":
                    return { type: f.type, value: dayjs(f.value).toISOString() };
                default:
                    return {
                        type: f.type,
                        value: f.value.split(",").map((f: string) => Number(f)),
                    };
            }
        });

        if (filterType === SavedFilterType.SegmentationPerformance) {
            newFilters = [...newFilters, { type: "Supplier", value: [partnerId.toString()] }];
        } else {
            setSupplier(
                newFilters.find((f: ISavedReportFilter) => f.type === "Supplier")
                    ? newFilters.find((f: ISavedReportFilter) => f.type === "Supplier").value
                    : []
            );
        }

        setFilters(newFilters);
        onFiltersChange && onFiltersChange(newFilters);
    };

    const handleFilterChange = async (type: string, value: any) => {
        if (type === "Supplier") {
            setSupplier(value);
            setFilters(value.length > 0 ? [{ type, value: value.toString() }] : []);
        } else if (type === "dateRange" && SurveyType.Segmentation) {
            onDateRangeChange && onDateRangeChange(value);
            if (filters.findIndex((x: any) => x.type === "To") === -1) {
                setFilters([
                    ...filters,
                    { type: "From", value: value[0].toISOString() },
                    { type: "To", value: value[1].toISOString() },
                ]);
            } else {
                let newFilters = filters.filter((x: any) => x.type !== "From" && x.type !== "To");
                newFilters = [
                    { type: "From", value: value[0].toISOString() },
                    { type: "To", value: value[1].toISOString() },
                ];
                setFilters(newFilters);
            }
        } else {
            let newFilters = [];
            if (!value || value.length === 0) {
                newFilters = filters.filter((f: any) => f.type !== type);
            } else {
                newFilters = filters.some((f: any) => f.type === type)
                    ? filters.map((f: any) => (f.type === type && value ? { type, value } : f))
                    : [...filters, { type, value }];
            }
            if (filterType === SavedFilterType.SegmentationPerformance) {
                let hasPageSupplierFilter =
                    filters.filter((f: any) => f.type === "Supplier").length === 1;

                if (!hasPageSupplierFilter) {
                    setFilters([...newFilters, { type: "Supplier", value: partnerId.toString() }]);
                } else {
                    setFilters(newFilters);
                }
            } else {
                setFilters(newFilters);
            }
        }
    };

    const handleFilter = () => {
        if (filterType === SavedFilterType.SegmentationPerformance) {
            onFiltersChange &&
                onFiltersChange([...filters, { type: "Supplier", value: partnerId.toString() }]);
        } else {
            onFiltersChange && onFiltersChange(filters);
        }
    };

    const handleClearFilters = () => {
        setFilters([]);
        if (filterType === SavedFilterType.SegmentationPerformance) {
            setSupplier([partnerId]);
            onFiltersChange &&
                onFiltersChange([{ type: "Supplier", value: partnerId.toString() }], true);
            onDateRangeChange &&
                onDateRangeChange([dayjs().startOf("year"), dayjs().endOf("year")]);
        } else {
            setSupplier([]);
            onFiltersChange && onFiltersChange([], true);
        }
        setSelectedSavedFilter(undefined);
    };

    const handleDeleteSavedFilter = async (e: any, itemId: string) => {
        e.stopPropagation();
        await deleteFilter(itemId);
        setReloadSavedFilters({ reload: true });
        onSavedFiltersChange();
    };

    return (
        <Spin spinning={filterLoading}>
            <div className={classes.container}>
                <div className={classes.filterBar}>
                    <div>
                        {filterType === SavedFilterType.FilteredSegmentation ? (
                            <SelectTreeDataMultiple
                                value={supplier}
                                onChange={(value: any) => {
                                    handleFilterChange("Supplier", value);
                                }}
                                selectOptionField="name"
                                useData={usePartnerList}
                                selectAll
                                maxTagCount={1}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                className={classNames(classes.filter, classes.supplier)}
                                placeholder={t("select") + " " + t("supplier")}
                                includeChildren
                                maxTagTextLength={6}
                            />
                        ) : (
                            <SelectDataMultiple
                                value={Array.isArray(supplier) ? supplier : [supplier]}
                                onChange={(value: any, item: any) => {
                                    handleFilterChange("Supplier", value);
                                }}
                                selectOptionField="name"
                                useData={usePartnerChildren.bind(null, partnerId)}
                                selectAll
                                maxTagCount={1}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                className={classNames(classes.filter, classes.Supplier)}
                                dropdownMatchSelectWidth={false}
                                maxTagTextLength={6}
                            />
                        )}
                    </div>
                    <div>
                        {filterType === SavedFilterType.FilteredSegmentation && (
                            <Input
                                value={
                                    filters.find(
                                        (f: ISavedReportFilter) => f.type === "SupplierNumber"
                                    )?.value
                                }
                                style={{ width: 150 }}
                                maxLength={100}
                                bordered={false}
                                placeholder={t("generic_FilterOn", {
                                    value: t("Number").toLocaleLowerCase(),
                                })}
                                onChange={(e: any) => {
                                    handleFilterChange("SupplierNumber", e.target.value);
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <SelectDataMultiple
                            value={
                                filters.find(
                                    (f: ISavedReportFilter) => f.type === "CommodityCategory1"
                                )?.value
                            }
                            useData={useCommodityCategoriesByPartner.bind(
                                null,
                                supplier,
                                "1",
                                type
                            )}
                            selectOptionField="value"
                            placeholder={t("select") + " " + t("survey_Wizard_JCC", { number: 1 })}
                            onChange={(value: any) => {
                                handleFilterChange("CommodityCategory1", value);
                            }}
                            selectAll
                            maxTagCount={1}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            className={classNames(classes.filter, classes.jcc)}
                            dropdownMatchSelectWidth={false}
                            maxTagTextLength={4}
                        />
                    </div>
                    <div>
                        <SelectDataMultiple
                            value={
                                filters.find(
                                    (f: ISavedReportFilter) => f.type === "CommodityCategory2"
                                )?.value
                            }
                            useData={useCommodityCategoriesByPartner.bind(
                                null,
                                supplier,
                                "2",
                                type
                            )}
                            selectOptionField="value"
                            placeholder={t("select") + " " + t("survey_Wizard_JCC", { number: 2 })}
                            onChange={(value: any) => {
                                handleFilterChange("CommodityCategory2", value);
                            }}
                            selectAll
                            maxTagCount={1}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            className={classNames(classes.filter, classes.jcc)}
                            dropdownMatchSelectWidth={false}
                            maxTagTextLength={4}
                        />
                    </div>
                    <div>
                        <SelectDataMultiple
                            value={
                                filters.find(
                                    (f: ISavedReportFilter) => f.type === "CommodityCategory3"
                                )?.value
                            }
                            useData={useCommodityCategoriesByPartner.bind(
                                null,
                                supplier,
                                "3",
                                type
                            )}
                            selectOptionField="value"
                            placeholder={t("select") + " " + t("survey_Wizard_JCC", { number: 3 })}
                            onChange={(value: any) =>
                                handleFilterChange("CommodityCategory3", value)
                            }
                            selectAll
                            maxTagCount={1}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            className={classNames(classes.filter, classes.jcc)}
                            dropdownMatchSelectWidth={false}
                            maxTagTextLength={4}
                        />
                    </div>
                    <div>
                        <SelectDataMultiple
                            value={
                                filters.find((f: ISavedReportFilter) => f.type === "Division")
                                    ?.value
                            }
                            useData={useAnalysisUnitDivisions.bind(null, supplier, type)}
                            selectOptionField="value"
                            placeholder={t("select") + " " + t("survey_Wizard_Division")}
                            onChange={(value: any) => handleFilterChange("Division", value)}
                            selectAll
                            maxTagCount={1}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            className={classNames(classes.filter, classes.division)}
                            dropdownMatchSelectWidth={false}
                            maxTagTextLength={4}
                        />
                    </div>
                    <div>
                        <SelectDataMultiple
                            value={
                                filters.find((f: ISavedReportFilter) => f.type === "Regions")?.value
                            }
                            useData={useAnalysisUnitRegions.bind(null, supplier, type)}
                            selectOptionField="value"
                            placeholder={t("select") + " " + t("survey_Wizard_Regions")}
                            onChange={(value: any) => handleFilterChange("Regions", value)}
                            selectAll
                            maxTagCount={1}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            className={classNames(classes.filter, classes.region)}
                            dropdownMatchSelectWidth={false}
                            maxTagTextLength={4}
                        />
                    </div>
                    {filterType === SavedFilterType.SegmentationPerformance &&
                        type === SurveyType.Performance && (
                            <div>
                                <SelectDataMultiple
                                    value={
                                        filters.find(
                                            (f: ISavedReportFilter) => f.type === "Creator"
                                        )?.value
                                    }
                                    useData={useCompanyUsers.bind(null, true)}
                                    selectIdField="contactId"
                                    selectOptionField="name"
                                    placeholder={t("select") + " " + t("generic_Creator")}
                                    onChange={(value: any) => handleFilterChange("Creator", value)}
                                    selectAll
                                    maxTagCount={1}
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                    className={classNames(classes.filter, classes.region)}
                                    dropdownMatchSelectWidth={false}
                                    maxTagTextLength={4}
                                />
                            </div>
                        )}
                    {type === SurveyType.Segmentation && (
                        <div>
                            <RangePicker
                                size="small"
                                variant="borderless"
                                allowClear={false}
                                onChange={(value: any) => handleFilterChange("dateRange", value)}
                                picker="month"
                                value={[
                                    filters.some((f: ISavedReportFilter) => f.type === "From")
                                        ? dayjs(
                                              filters.find(
                                                  (f: ISavedReportFilter) => f.type === "From"
                                              ).value
                                          )
                                        : dayjs(currentYear).startOf("year"),
                                    filters.some((f: ISavedReportFilter) => f.type === "To")
                                        ? dayjs(
                                              filters.find(
                                                  (f: ISavedReportFilter) => f.type === "To"
                                              ).value
                                          )
                                        : dayjs(currentYear).endOf("year"),
                                ]}
                            />
                        </div>
                    )}

                    {type === SurveyType.Performance && (
                        <div>
                            <DatePicker
                                allowClear={false}
                                onChange={(value: any) =>
                                    handleFilterChange("Year", dayjs(value).format("YYYY"))
                                }
                                picker="year"
                                value={
                                    filters.some((f: ISavedReportFilter) => f.type === "Year")
                                        ? dayjs(
                                              filters.find(
                                                  (f: ISavedReportFilter) => f.type === "Year"
                                              ).value
                                          )
                                        : dayjs(currentYear)
                                }
                            />
                        </div>
                    )}

                    <>
                        <Button onClick={handleFilter} type="primary" className={classes.show}>
                            {t("segmentation_ShowFilter")}
                        </Button>

                        <Button
                            type="primary"
                            className={classes.show}
                            onClick={() => setSaveFilterModal({ visible: true })}
                            disabled={filters.length === 0}>
                            {t("segmentation_SaveFilter")}
                        </Button>

                        {filters.length > 0 && (
                            <Button
                                data-test="clear_filters_button"
                                className={classes.show}
                                onClick={handleClearFilters}>
                                <FilterTwoTone />
                                {t("clearActiveFilters")}
                            </Button>
                        )}

                        <SelectData
                            value={selectedSavedFilter}
                            useData={useSavedFilters.bind(null, filterType, reloadSavedFilters)}
                            selectOptionField={(item: any) => ({
                                text: item.filterName,
                                node:
                                    selectedSavedFilter !== item.id ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                color: "#1b7caa",
                                            }}>
                                            <div>{item.filterName}</div>
                                            <div>
                                                <Spin
                                                    size="small"
                                                    spinning={deleteFilterLoading === item.id}>
                                                    <DeleteIcon
                                                        onClick={(e: any) =>
                                                            handleDeleteSavedFilter(e, item.id)
                                                        }
                                                    />
                                                </Spin>
                                            </div>
                                        </div>
                                    ) : (
                                        item.filterName
                                    ),
                            })}
                            className={classes.filterSelect}
                            placeholder={t("segmentation_SelectSavedFilter")}
                            onChange={(value: any) => handleSelectSavedFilter(value)}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            dropdownMatchSelectWidth={false}
                            allowClear
                        />
                    </>
                </div>
                <SaveFilterModal
                    visible={saveFilterModal.visible}
                    filters={filters}
                    onClose={(success: boolean = false, filterId: string) => {
                        if (success) {
                            setReloadSavedFilters({ reload: true });
                            setSelectedSavedFilter(filterId);
                            onSavedFiltersChange();
                            setReloadSavedFilters({ reload: true });
                        }
                        setSaveFilterModal({ visible: false });
                    }}
                    filterType={filterType}
                />
            </div>
        </Spin>
    );
};

export { TopFilter };
