import { PageContainer } from "components/utilitycomponents";
import React, { useRef, useState, useEffect } from "react";
import classes from "../styles/onBoarding.module.scss";
import Icon from "./leanLinkingLogo.svg";
import { useTranslation } from "react-i18next";
import { Steps, Form, Button, FormInstance, Result } from "antd";
import FirstStep from "./steps/firstStep";
import SecondStep from "./steps/secondStep";
import ThirdStep from "./steps/thirdStep";
import { useUserRegistration, useUserRegistrationType } from "controller";
import { INewUser } from "models/user";
import { useParams } from "react-router-dom";
import { getDomainUrl } from "utilities";
const OnBoardingForm = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [step, setStep] = useState(0);
    const { registerUser } = useUserRegistration();
    const { Step } = Steps;
    const [controllerUsername, setControllerUsername] = useState<string | undefined>(undefined);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const firstFormRef = useRef<FormInstance>(form);
    const secondFormRef = useRef<FormInstance>(form);
    const thirdFormRef = useRef<FormInstance>(form);
    const { token }: any = useParams();
    const { data: invitation } = useUserRegistrationType(token);
    const [userRegistered, setUserRegistered] = useState(false);
    const [loadOnboarding, setLoadOnboarding] = useState(false);
    useEffect(() => {
        const checkInvitation = () => {
            setLoadOnboarding(true);

            if (invitation && invitation.emailAddress === null) {
                setUserRegistered(true);
                window.location.href = "/";
            } else {
                setUserRegistered(false);
            }

            setLoadOnboarding(false);
        };

        checkInvitation();
    }, [token, invitation]);

    const handleNext = () => {
        if (step === 0) {
            firstFormRef.current
                ?.validateFields()
                .then(() => {
                    setControllerUsername(firstFormRef.current?.getFieldValue("username"));

                    setStep(step + 1);
                })
                .catch(error => {});
        } else if (step === 1) {
            secondFormRef.current
                ?.validateFields()
                .then(() => {
                    setStep(step + 1);
                })
                .catch(error => {});
        } else {
            setStep(step + 1);
        }
    };

    const handleOnFinish = async () => {
        const preservedValues = form.getFieldsValue(true);
        const validatedValues = await form.validateFields();
        let values = { ...preservedValues, ...validatedValues };

        let newUser: INewUser = {
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.email,
            userName: values.username,
            password: values.password,
            invitationType: 2,
            relationsUserDetail: {
                departmentId: values.department,
                jobLevelId: values.jobLevel,
                useCaseIds: values.useCases,
            },
        };
        await registerUser(newUser, token);
        setUserRegistered(true);
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    return (
        !loadOnboarding && (
            <div className={classes.mainLayout}>
                {!userRegistered ? (
                    <PageContainer className={classes.pageContainer}>
                        <div className={classes.header}>
                            <img src={Icon} className={classes.icon} alt="" />
                            <span className={classes.invitationText}>
                                {t("onboarding_Invite_Title")}
                            </span>
                            <span className={classes.instructionText}>
                                {t("onboarding_Info_Text")}
                            </span>
                        </div>
                        <div style={{ marginTop: 23, marginBottom: 7 }}>
                            <Steps
                                progressDot
                                current={step}
                                style={{ width: 650, margin: "auto" }}>
                                <Step
                                    title={t("onboarding_step_1")}
                                    style={{ fontWeight: step === 0 ? 500 : "normal" }}
                                />
                                <Step
                                    title={t("onboarding_step_2")}
                                    style={{ fontWeight: step === 1 ? 500 : "normal" }}
                                />
                                <Step
                                    title={t("onboarding_step_3")}
                                    style={{ fontWeight: step === 2 ? 500 : "normal" }}
                                />
                            </Steps>
                        </div>
                        <div className={classes.formContainer}>
                            <Form form={form} layout="vertical" autoComplete="off" preserve>
                                <div>
                                    {step === 0 && (
                                        <FirstStep
                                            formRef={firstFormRef}
                                            setDisable={setDisableButton}
                                            step={step}
                                        />
                                    )}
                                </div>
                                <div>
                                    {step === 1 && (
                                        <SecondStep
                                            formRef={secondFormRef}
                                            setDisable={setDisableButton}
                                            step={step}
                                        />
                                    )}
                                </div>
                                {step === 2 && (
                                    <ThirdStep
                                        formRef={thirdFormRef}
                                        username={controllerUsername}
                                    />
                                )}
                            </Form>
                            <div className={classes.buttonsContainer}>
                                {step > 0 && (
                                    <Button
                                        className={classes.backButton}
                                        onClick={() => {
                                            setStep(step - 1);
                                        }}>
                                        {t("generic_Back")}
                                    </Button>
                                )}
                                {step < 2 ? (
                                    <Button
                                        className={classes.nextButton}
                                        onClick={handleNext}
                                        disabled={disableButton}>
                                        {t("generic_Next")}
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.nextButton}
                                        onClick={async () => await handleOnFinish()}>
                                        {t("generic_Complete")}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </PageContainer>
                ) : (
                    <PageContainer>
                        <Result
                            status={"success"}
                            title={t("onboarding_Success_Title")}
                            subTitle={t("onboarding_Success_Subtitle")}
                            extra={[
                                <Button
                                    className={classes.nextButton}
                                    onClick={() => (window.location.href = getDomainUrl())}>
                                    {t("onboarding_Success_Button_Text")}
                                </Button>,
                            ]}
                        />
                    </PageContainer>
                )}
            </div>
        )
    );
};

export default OnBoardingForm;
