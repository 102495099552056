import { useState, useEffect } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";
type StateType = {
    loading: boolean;
    data: any[];
};
const useNcrReasonsForFilter = (from: string, to: string) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Ncr.getNcrReasonsForFilter(from, to, {
                customError: {
                    message: t("ncr_GetReasonsError"),
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            let translatedData = data.map((d: any) => ({ ...d, name: t(d.name) }));
            translatedData = translatedData.sort((a: any, b: any) => {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            setState({ loading: false, data: translatedData });
        })();
    }, [from, to]);
    return {
        loading: state.loading,
        data: state.data,
    };
};
export { useNcrReasonsForFilter };
