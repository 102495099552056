import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "i18n";
import { store } from "store";
import userManager from "userManager";
import Main from "./main";
import CallBackComponent from "./callBack";
import { Spin } from "antd";

import "./styles/App.css";
import "./styles/antdOverrides.css";

const App: React.FC = () => {
    return (
        <Suspense
            fallback={
                <div className="callbackSpinner">
                    <Spin size="large" />
                </div>
            }>
            <Provider store={store}>
                <OidcProvider userManager={userManager} store={store}>
                    <Router>
                        <Switch>
                            <Route path="/callback(.*)" component={CallBackComponent} />
                            <Main />
                        </Switch>
                    </Router>
                </OidcProvider>
            </Provider>
        </Suspense>
    );
};

export default App;
