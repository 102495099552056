import { useEffect, useState } from "react";
import Provider from "services/api";

const useReviewOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (!externalLoading) {
                const data = await Provider.Review.getOverview(
                    {
                        pageNumber,
                        pageSize,
                        filters,
                        sorts,
                        from: range.from,
                        to: range.to,
                    },
                    {
                        retryCount: 3,
                        customError: { message: "Could not retrieve Reviews overview" },
                    }
                ).catch(err => {
                    setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: data.reviewData,
                    totalRows: data.numberOfTotalReviews,
                });
            }
        })();
    }, [pageSize, pageNumber, filters, sorts, externalLoading, reloadData, range]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
    };
};

export { useReviewOverview };
