import { get, post, put } from "../base";
import { ITableInput, IRequestInfoObject } from "models";

const Matrix = {
    matrixOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Matrix/GetMatrixData";
        return await post({ url, payload: tableInput, customError });
    },
    getMatrixSADetail: async (partnerId: number, templateId: number, { customError }: any = {}) => {
        const url = `/v1/Matrix/GetSelfAssessmentDetail?templateId=${templateId}&partnerId=${partnerId}`;
        return await post({ url, payload: { partnerId, templateId }, customError });
    },
    updateSAType: async (token: string, flag: boolean, { customError }: any = {}) => {
        const url = `/v1/Matrix/UpdateSAMultiple/${token}/${flag}`;
        return await put({ url, customError });
    },
    sendSelfAssessments: async (
        requestInfoData: IRequestInfoObject,
        { customError, retryCount }: any = {}
    ) => {
        let url = `/v1/Matrix/SendSelfAssessments`;

        return await post({ url, payload: requestInfoData, customError, retryCount: 3 });
    },
    getMatrixTemplateGroups: async ({ customError }: any = {}) => {
        const url = `/v1/Matrix/GetMatrixTemplateGroup`;
        return await get({ url, customError });
    },
};

export default Matrix;
