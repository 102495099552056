import { useState } from "react";
import { Provider } from "services";

const useResendSurvey = () => {
    const [loading, setLoading] = useState(false);

    const ResendSurvey = async (surveyId: number) => {
        setLoading(true);
        await Provider.Survey.resendSurvey(surveyId).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
    };

    return {
        loading,
        ResendSurvey,
    };
};

export { useResendSurvey };
