import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Skeleton } from "antd";
import { useEnabledSupplierProfilePages, useSupplierProfile } from "controller";
import { useParams } from "react-router-dom";
import { Relationships } from "./relationships";
import { SupplierAddress } from "./supplierAddress";
import classes from "./supplierInformation.module.scss";
import { SupplierSegmentation } from "./supplierSegmentation";
import { RelationshipOwners } from "./relationshipOwners";
import { SupplierLinks } from "./supplierLinks";
import { checkForActive } from "../../shared/checkActivePage";

interface IProps {
    onAssignRelationClick: Function;
    onDataLoaded: Function;
    partnerId: number;
    [key: string]: any;
}

const SupplierInformation: FunctionComponent<IProps> = ({
    onAssignRelationClick,
    onDataLoaded,
    partnerId,
    ...rest
}) => {
    const [reload, setReload] = useState<any>({ reload: false });
    const { tab }: any = useParams();
    const { data, loading } = useSupplierProfile(partnerId, reload);
    const { data: pages } = useEnabledSupplierProfilePages();

    useEffect(() => {
        if (tab === "0") setReload({ reload: true });
    }, [tab]);

    useEffect(() => {
        if (!loading && data) {
            onDataLoaded(data);
        }
    }, [data]);

    return (
        <div className={classes.infoContainer} {...rest}>
            <Row className={classes.infoBox}>
                <Col span={24}>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <SupplierAddress
                            partnerId={partnerId}
                            data={data}
                            loading={loading}
                            onDomainSet={() => setReload({ reload: true })}
                        />
                    )}
                </Col>
            </Row>
            <Row style={{ minHeight: 80 }} className={classes.infoBox}>
                {loading ? (
                    <Skeleton paragraph={false} active />
                ) : (
                    <SupplierSegmentation data={data} loading={loading} partnerId={partnerId} />
                )}
            </Row>

            <Row style={{ minHeight: 30 }} className={classes.infoBox}>
                {loading ? (
                    <Skeleton paragraph={false} active />
                ) : (
                    <Col span={24}>
                        <RelationshipOwners data={data} />
                    </Col>
                )}
            </Row>
            {checkForActive("Relationships", pages) && (
                <Row style={{ minHeight: 150 }} className={classes.infoBox}>
                    {loading ? (
                        <Skeleton paragraph={false} active />
                    ) : (
                        <Col span={24}>
                            <Relationships
                                partnerId={partnerId}
                                data={data}
                                loading={loading}
                                onAssignRelationClick={() => onAssignRelationClick()}
                            />
                        </Col>
                    )}
                </Row>
            )}

            <Row className={classes.infoBox}>
                <Col span={24}>
                    <SupplierLinks partnerId={partnerId} />
                </Col>
            </Row>
            {/* <Row style={{ minHeight: 120 }} className={classes.infoBox}>
                {loading ? (
                    <Skeleton paragraph={false} active />
                ) : (
                    <Col span={24}>
                        <Actions actions={data.actions} partnerId={partnerId} />
                    </Col>
                )}
            </Row> */}
        </div>
    );
};

export { SupplierInformation };
