import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
interface IProps {
    onClickMyReg: () => void;
    onClickMyGateReg: () => void;
    isGateKeeperUser: any;
}
const SideBarFilter = ({ onClickMyReg, onClickMyGateReg, isGateKeeperUser }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div style={{ marginBottom: 8 }}>{t("filter_By_Label")}</div>
            <Button onClick={onClickMyReg}>{t("complince_Sidebar_My_Reg_Btn")}</Button>
            {isGateKeeperUser && (
                <Button style={{ marginTop: 12 }} onClick={onClickMyGateReg}>
                    {t("complince_Sidebar_My_GateKeeper_Reg_Btn")}
                </Button>
            )}
        </>
    );
};

export default SideBarFilter;
