import { useEffect, useState } from "react";
import { Provider } from "services";
import { ISummaryCardsPartner } from "models";

type StateType = {
    data: ISummaryCardsPartner | null;
    loading: boolean;
};

const useSupplierCardsPartner = (partnerId: number, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            const data = await Provider.Partner.getSupplierCardsPartnerInfo(partnerId, {
                customError: {
                    message: "Could not retrieve data for the partner",
                },
            }).catch((err: any) => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSupplierCardsPartner };
