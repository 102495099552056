import { IMetricType } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Provider from "services/api";

type State = {
    loading: boolean;
    data: IMetricType[];
};

const useMetricTypes = () => {
    const [state, setState] = useState<State>({
        loading: true,
        data: [],
    });
    const { t } = useTranslation();

    const renderType = (value: string) => {
        switch (value) {
            case "Compliance":
                return t("admin_supplierProfile_scorecard_metric_type_compliance");
            case "ReviewCategoryGroup":
                return t("admin_supplierProfile_scorecard_metric_type_reviewCriteriaGroup");
            case "ReviewCategory":
                return t("admin_supplierProfile_scorecard_metric_type_reviewCriteria");
            default:
                return "";
        }
    };

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Scorecard.getScorecardMetricTypes().catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            let metricTypes = data.map((x: any) => {
                return { ...x, name: renderType(x.name) };
            });
            setState({ loading: false, data: metricTypes });
        })();
    }, []);
    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMetricTypes };
