import React, { useState, Fragment, useEffect } from "react";
import { Tag } from "antd";
import { Grid, getColumnSelectProps, getPercentTagColor } from "components/utilitycomponents";
import {
    useMatrixOverview,
    useMatrixTemplateGroups,
    usePartnerList,
    useCompanyType,
    useCustomFilters,
    usePartnerFields,
} from "controller";
import SendSAModal from "./sendSAModal";
import SideFilter from "./sideFilter";
import "./styles/matrixOverview.scss";
import { useTranslation } from "react-i18next";
import { formatTime } from "utilities";
import { ITableType, ReportType } from "models";
import { IColumnFilter } from "components/utilitycomponents/grid/models";

const MatrixOverview = () => {
    const { t } = useTranslation();
    const { data: initialCustomFilters, loading: initialCustomFiltersLoading } = useCustomFilters(
        ITableType["ComplianceMatrix"]
    );
    const { data: partnerFields } = usePartnerFields();
    const [customFilters, setCustomFilters] = useState<IColumnFilter[]>([]);
    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;

    useEffect(() => {
        setCustomFilters(initialCustomFilters);
    }, [initialCustomFilters]);

    const [sendSAModal, setSendSAModal] = useState<any>({
        visible: false,
        SAData: null,
    });

    const { loading: templateGroupsLoading, data: templateGroups } = useMatrixTemplateGroups();

    const [reloadData, setReloadData] = useState({ reload: false });

    const { isDefaultCompany } = useCompanyType();

    const renderFillRateTag = (score: number) => {
        return (
            <Tag style={{ minWidth: "85px" }} color={getPercentTagColor(score)}>
                {t("compliance_Fill")}: {score}%
            </Tag>
        );
    };

    const renderScoreRateTag = (score: number) => {
        return (
            <Tag style={{ minWidth: "85px" }} color={getPercentTagColor(score)}>
                {t("compliance_Score")}: {score}%
            </Tag>
        );
    };

    const renderCell = (data: any) => {
        return (
            data && (
                <Fragment>
                    <div
                        data-test={`cell_${data.id}`}
                        style={{ textAlign: "center", margin: "5px 0" }}>
                        <>
                            {data.averageScore !== null && (
                                <div style={{ marginBottom: "3px" }}>
                                    {renderScoreRateTag(data.averageScore)}
                                </div>
                            )}
                            {data.score !== null && (
                                <div style={{ position: "relative", left: 0 }}>
                                    {renderFillRateTag(data.score)}
                                </div>
                            )}
                            {data.sentDate && formatTime(data.sentDate, "MMM D, YYYY")}
                            {data.averageScore == null && data.score == null && !data.sentDate && (
                                <div style={{ textAlign: "left", margin: "5px 0", opacity: 0 }}>
                                    <Tag style={{ minWidth: "40px", cursor: "pointer" }}> </Tag>
                                </div>
                            )}
                        </>
                    </div>
                </Fragment>
            )
        );
    };
    const onCellHandle = (record: any, dataIndex: string) => {
        return {
            onClick: () => {
                setSendSAModal({
                    visible: true,
                    SAData: {
                        partnerId: record.partnerId,
                        templateId: record[dataIndex].id,
                        isObligated: record[dataIndex].isObligated,
                    },
                });
            },
            style: { cursor: "pointer", position: "relative" },
        };
    };

    let columns: any = [
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "partnerName",
            key: "Partner",
            filterType: "array",
            sorter: true,
            width: 250,
            viewColumnId: -1,
            fixed: true,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partnerName", includeChildren: true }
            ),
        },
    ];

    if (!templateGroupsLoading && !initialCustomFiltersLoading) {
        const templateGroup = customFilters
            .filter(f => f.type)
            .some(f => f.name === "TemplateGroup")
            ? customFilters
                  .find(f => f.name === "TemplateGroup")
                  ?.value.split(",")
                  .map(t => Number(t))
            : [];
        const template = customFilters.some(f => f.name === "Template")
            ? customFilters
                  .find(f => f.name === "Template")
                  ?.value.split(",")
                  .map(t => Number(t))
            : [];
        const dynamicColumns: any = [];
        templateGroups.forEach((group: any, index: number) => {
            if (
                templateGroup &&
                templateGroup.length > 0 &&
                !templateGroup.includes(group.groupId)
            ) {
                return;
            }
            if (
                group.templateDtos.filter((templateObj: any) => {
                    if (!template || template.length === 0) return true;
                    return template.includes(templateObj.id);
                }).length === 0
            ) {
                return;
            }
            dynamicColumns.push({
                title: group.groupName ? `${group.groupName} (${group.groupId})` : "",
                width: group.templateDtos.length * 175,
                viewColumnId: group.groupId ? group.groupId : `t${group.templateDtos[0].id}`,
                align: "center",
                children: group.templateDtos
                    .filter((template: any) => {
                        if (!customFilters.find(f => f.name === "Template")) return true;
                        return customFilters
                            .find(f => f.name === "Template")
                            ?.value.includes(template.id);
                    })
                    .map((template: any) => {
                        return {
                            title: `${template.name}(${template.id})`,
                            dataIndex: `t${template.id}`,
                            key: `${template.id}`,
                            width: 175,
                            className: "ignoreDrag",
                            onHeaderCell: () => ({
                                style: { textAlign: "center" },
                            }),
                            render: renderCell,
                            onCell: (record: any) => onCellHandle(record, `t${template.id}`),
                        };
                    }),
            });
        });

        columns = [...columns, ...dynamicColumns];
    }

    return (
        <Fragment>
            <Grid
                data-test="matrix_table"
                bordered
                columns={columns}
                fixedColumns={["partnerName"]}
                useData={useMatrixOverview}
                tableType="ComplianceMatrix"
                rowKey="partnerId"
                classNames={["matrix-grid"]}
                align="center"
                externalLoading={templateGroupsLoading}
                tableExcessHeight={50}
                defaultRangeInMonth={36}
                hideColumnSetting
                sidebar={SideFilter}
                reloadData={reloadData}
                minColumnWidth={120}
                reportType={ReportType.complianceMatrix}
                onCustomFiltersUpdate={setCustomFilters}
                pageSizeOptions={["20", "50"]}
            />

            {sendSAModal.SAData && (
                <SendSAModal
                    {...sendSAModal}
                    onClose={() => setSendSAModal({ SAData: null, visible: false })}
                    onCloseAndReload={() => {
                        setSendSAModal({ SAData: null, visible: false });
                        setReloadData({ reload: true });
                    }}
                />
            )}
        </Fragment>
    );
};

export default MatrixOverview;
