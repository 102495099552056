import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, notification, Spin, Result, Form } from "antd";
import { useSelfAssessment, useSelfAssessmentCreate } from "controller";
import {
    AttachFile,
    DownloadFile,
    PureDiv,
    PageContainer,
    SelectDataMultiple,
} from "components/utilitycomponents";
import { newlineToBr } from "utilities";
import { Entity, FormRow } from "../overview/sharedComponents";
import TemplateHeadLines from "./headLines";
import classes from "./styles/selfAssessment.module.scss";
import dayjs from "dayjs";
import useProductsByPartner from "controller/product/useProductsByPartner";

enum SubmitType {
    Draft,
    Submit,
    SubmitNew,
}

const SelfAssessmentForm = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [reload, setReload] = useState<any>({ reload: false });
    const [isEdit, setIsEdit] = useState<any>(false);
    const [selfAssessmentData, setSelfAssessmentData] = useState<any>(null);
    const [submitType, setSubmitType] = useState<any>(undefined);
    const [attachedFileList, setAttachedFileList] = useState<any>([]);
    const { data: sa, loading } = useSelfAssessment(guestToken, reload);
    const { createUpdate, loading: createLoading } = useSelfAssessmentCreate();
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
    const [loadFiles, setLoadFiles] = useState<any>({ load: true });
    const { data: products, loading: loadingProducts } = useProductsByPartner(
        sa && sa.partnerId,
        true
    );
    const [showResultPage, setShowResultPage] = useState<boolean>(false);
    const [registrationId, setRegistrationId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (sa !== null && !loading) {
            setIsEdit(!sa.isNew);
            setSelfAssessmentData(sa.data);
            if (sa.isNew) {
                setAttachedFileList([]);
            } else {
                let files =
                    sa.data && sa.data.files
                        ? sa.data.files.map((file: any) => ({
                              id: file.id,
                              name: file.name,
                          }))
                        : [];
                form.setFieldsValue({ attachedFiles: files.map((file: any) => file.id) });
                setAttachedFileList(files);
            }
        }
    }, [sa]);

    useEffect(() => {
        if (selfAssessmentData) {
            form.setFieldsValue({ entity: selfAssessmentData.entity });
        }
    }, [selfAssessmentData]);

    useEffect(() => {
        if (showValidationMessage === true) {
            setTimeout(() => {
                setShowValidationMessage(false);
            }, 7500);
        }
    }, [showValidationMessage]);

    const handleSubmit = async (submitType: SubmitType, createNew: boolean = false) => {
        setLoadFiles({ load: false });
        await form
            .validateFields()
            .then(async (fieldsValue: any) => {
                const valueKeys: any[] = Object.keys(fieldsValue);
                const questionKeys = valueKeys.filter((key: string) => key.startsWith("question["));
                const questionsAndAnswersIds = questionKeys.map((questionKey: string) => {
                    let splitString = questionKey.split("[");
                    return {
                        questionId: splitString[1].replace("]answer", ""),
                        answerId: splitString[2].replace("]", ""),
                    };
                });

                const selfAssessment: any = {
                    token: guestToken,
                    isDraft: submitType === SubmitType.Draft,
                    isNew: createNew === true ? true : !isEdit,
                    complianceId: isEdit ? selfAssessmentData.id : 0,
                    entity: fieldsValue["entity"] ? fieldsValue["entity"] : "",
                    endDate: fieldsValue["endDate"]
                        ? dayjs(fieldsValue["endDate"])
                              .endOf("day")
                              .toISOString()
                        : null,
                    alertDate: fieldsValue["alertDate"]
                        ? dayjs(fieldsValue["alertDate"])
                              .endOf("day")
                              .toISOString()
                        : null,
                    productIds: fieldsValue["products"] ? fieldsValue["products"] : [],
                    complianceAnswers:
                        questionsAndAnswersIds.length > 0
                            ? questionsAndAnswersIds.map((idItem: any) => {
                                  let questionId = idItem.questionId;
                                  let answerId = 0;
                                  if (selfAssessmentData) {
                                      selfAssessmentData.complianceAnswers.forEach(
                                          (answer: any) => {
                                              if (
                                                  Number(answer.complianceQuestionId) ===
                                                  Number(idItem.questionId)
                                              ) {
                                                  answerId = answer.id;
                                                  return;
                                              }
                                          }
                                      );
                                  }

                                  let value = "";
                                  let multiChoiceValue;
                                  let fileIds;
                                  let fieldValue: any = form.getFieldValue(
                                      `question[${questionId}]answer[${answerId}]`
                                  );

                                  if (fieldValue) {
                                      let isArray = Array.isArray(fieldValue);
                                      if (isArray) {
                                          if (
                                              fieldValue.filter((x: any) => typeof x === "number")
                                                  .length > 0
                                          ) {
                                              fileIds = fieldValue;
                                              value = fieldValue.length > 0 ? "true" : "";
                                          } else {
                                              multiChoiceValue = fieldValue.join("|");
                                          }
                                      } else {
                                          value = dayjs.isDayjs(fieldValue)
                                              ? dayjs(fieldValue)
                                                    .endOf("day")
                                                    .toISOString()
                                              : fieldValue.target && fieldValue.target.checked
                                              ? fieldValue.target.checked.toString()
                                              : fieldValue.toString();
                                      }
                                  }
                                  return {
                                      id: Number(answerId),
                                      value,
                                      MultipleChoiceQuestionValue: multiChoiceValue,
                                      fileIds: fileIds,
                                      comment: fieldsValue[`comment[${questionId}]`]
                                          ? fieldsValue[`comment[${questionId}]`]
                                          : null,
                                      complianceQuestionId: Number(questionId),
                                      complianceId: isEdit ? selfAssessmentData.id : 0,
                                  };
                              })
                            : [],
                    fileIds: fieldsValue["attachedFiles"] ? fieldsValue["attachedFiles"] : [],
                    files: fieldsValue["attachedFiles"] ? fieldsValue["attachedFiles"] : [],
                };
                const result: any = await createUpdate(selfAssessment);
                setRegistrationId(result.id);

                if (submitType === SubmitType.Draft) {
                    notification.success({
                        message: "Success",
                        description: isEdit
                            ? t("compliance_SelfAssessmentDraftEditSuccess", { id: result.id })
                            : t("compliance_SelfAssessmentDraftCreateSuccess", {
                                  id: result.id,
                              }),
                        duration: 0,
                    });
                } else {
                    if (sa.multipleAssessmentEnabled && submitType === SubmitType.Submit) {
                        notification.success({
                            message: "Success",
                            duration: 2,
                        });
                    } else {
                        notification.success({
                            message: "Success",
                            description: t("compliance_SelfAssessmentSendSuccess", {
                                id: result.id,
                            }),
                            duration: 0,
                        });
                    }
                }
                if (submitType === SubmitType.SubmitNew) {
                    setSelfAssessmentData(null);
                    setIsEdit(false);
                    setAttachedFileList([]);
                    form.resetFields();
                } else {
                    if (sa.multipleAssessmentEnabled) {
                        setShowResultPage(true);
                    } else {
                        setIsEdit(true);
                        setReload({ reload: true });
                        setLoadFiles({ load: true });
                    }
                }
                const el = document.querySelectorAll("#contentSection") as any;
                if (el && el[0]) {
                    el[0].scrollTo({ top: 0, behavior: "smooth" });
                    el[0].focus();
                }
            })
            .catch(err => {
                setShowValidationMessage(true);
                return;
            });
    };

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }
    if (loading) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    if (!loading && sa === null) {
        return (
            <PageContainer settingContainer>
                <Result status="info" title={t("compliance_InvalidSelfAssessmentToken")} />
            </PageContainer>
        );
    }

    return (
        <>
            {!loading && !showResultPage && (
                <PageContainer
                    title={<PureDiv textContent={sa.templateName} />}
                    style={{ padding: 8, borderRadius: 5, marginBottom: 8 }}>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        labelWrap
                        labelAlign="left"
                        labelCol={{ lg: 5, md: 12, sm: 12, xs: 24 }}
                        wrapperCol={{ lg: 8, md: 12, sm: 12, xs: 24 }}>
                        <FormRow>
                            <Col lg={5} md={12} sm={12} xs={24}>
                                <label>{t("compliance_Introduction")}</label>
                            </Col>
                            <Col lg={8} md={12} sm={12} xs={24}>
                                <label>
                                    <PureDiv
                                        textContent={newlineToBr(
                                            sa.introduction ? sa.introduction : ""
                                        )}
                                    />
                                </label>
                                {sa.files &&
                                    sa.files.map((file: any, index: number) => (
                                        <div key={index}>
                                            <DownloadFile file={file} />
                                        </div>
                                    ))}
                            </Col>
                        </FormRow>
                        {sa.includeEndDate && (
                            <Form.Item
                                label={t("compliance_EndDate")}
                                name="endDate"
                                initialValue={
                                    sa.data && sa.data.endDate && !sa.data.isNew
                                        ? dayjs(sa.data.endDate)
                                        : undefined
                                }>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    getPopupContainer={node =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        )}
                        {sa.includeAlertDate && (
                            <Form.Item
                                label={t("compliance_AlertDate")}
                                name="alertDate"
                                initialValue={
                                    sa.data && sa.data.alertDate && !sa.data.isNew
                                        ? dayjs(sa.data.alertDate)
                                        : undefined
                                }>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    getPopupContainer={node =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            label={sa.entity}
                            name="entity"
                            initialValue={
                                isEdit && selfAssessmentData ? selfAssessmentData.entity : undefined
                            }
                            rules={[
                                {
                                    required: sa.isEntityRequired === true,
                                    whitespace: sa.isEntityRequired === true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}>
                            <Entity entityType={sa.entityType} options={sa.customDropdownOptions} />
                        </Form.Item>

                        {sa.isProductTemplate && (
                            <Form.Item
                                label={t("product_Products")}
                                name="products"
                                initialValue={sa && sa.productIds}
                                rules={[
                                    {
                                        required: true,
                                        message: t("inputIsRequiredError"),
                                    },
                                ]}>
                                <SelectDataMultiple
                                    selectOptionField="name"
                                    selectIdField="id"
                                    useData={() => ({
                                        data: products,
                                        loading: loadingProducts,
                                    })}
                                />
                            </Form.Item>
                        )}
                        {sa.canUserAccessAttachments && (
                            <Form.Item
                                label={t("compliance_Attachments")}
                                name="attachedFiles"
                                initialValue={
                                    isEdit && selfAssessmentData && selfAssessmentData.files
                                        ? selfAssessmentData.files.map((file: any) => file.id)
                                        : []
                                }>
                                <AttachFile
                                    maxFileSize={40}
                                    attachedFileList={attachedFileList}
                                    onUploadingFile={(uplading: boolean) => {}}
                                />
                            </Form.Item>
                        )}
                        <TemplateHeadLines
                            headlines={sa.headLines}
                            answers={sa.data ? sa.data.complianceAnswers ?? [] : []}
                            onAttachDocumentLoading={(fileQuestionsLoading: any[]) => {}}
                            loadFiles={loadFiles}
                        />
                    </Form>
                    {(!isEdit || (selfAssessmentData && selfAssessmentData.isDraft)) && (
                        <FormRow gutter={16}>
                            <Col lg={{ span: 8, offset: 16 }} style={{ textAlign: "right" }}>
                                <Button
                                    onClick={() => {
                                        setSubmitType(SubmitType.Draft);
                                        handleSubmit(SubmitType.Draft);
                                    }}
                                    loading={submitType === SubmitType.Draft && createLoading}
                                    disabled={
                                        createLoading
                                        // ||
                                        // fileUploadInProgress ||
                                        // fileQuestionsLoading.filter(
                                        //     (x: any) => Object.values(x)[0] === true
                                        // ).length > 0
                                    }>
                                    {t("compliance_SaveAsDraft")}
                                </Button>
                            </Col>
                        </FormRow>
                    )}
                    <FormRow gutter={16}>
                        <Col
                            lg={{ span: 16, offset: 8 }}
                            md={{ span: 16, offset: 8 }}
                            sm={24}
                            xs={24}
                            style={{ textAlign: "right" }}>
                            {showValidationMessage && (
                                <span className={classes.validationErrorText}>
                                    {t("compliance_ValidationErrorMsg")}
                                </span>
                            )}
                            <Button
                                type="primary"
                                onClick={() => {
                                    setSubmitType(SubmitType.Submit);
                                    handleSubmit(SubmitType.Submit);
                                }}
                                loading={submitType === SubmitType.Submit && createLoading}
                                disabled={
                                    createLoading
                                    // ||
                                    // fileUploadInProgress ||
                                    // fileQuestionsLoading.filter(
                                    //     (x: any) => Object.values(x)[0] === true
                                    // ).length > 0
                                }>
                                {t("compliance_SubmitAnswer")}
                            </Button>
                            {sa.multipleAssessmentEnabled && (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setIsEdit(false);
                                        setSubmitType(SubmitType.SubmitNew);
                                        handleSubmit(SubmitType.SubmitNew, true);
                                    }}
                                    loading={submitType === SubmitType.SubmitNew && createLoading}
                                    disabled={
                                        createLoading
                                        //  ||
                                        // fileUploadInProgress ||
                                        // fileQuestionsLoading.filter(
                                        //     (x: any) => Object.values(x)[0] === true
                                        // ).length > 0
                                    }
                                    style={{ marginLeft: "10px" }}>
                                    {t("compliance_SubmitAnswerAndCreateNew")}
                                </Button>
                            )}
                        </Col>
                    </FormRow>
                </PageContainer>
            )}
            {showResultPage && (
                <PageContainer style={{ padding: 8, borderRadius: 5, marginBottom: 8 }}>
                    <Result
                        status="success"
                        title={t("compliance_SelfAssessmentSendSuccess", {
                            id: registrationId,
                        })}
                    />
                </PageContainer>
            )}
        </>
    );
};

export default SelfAssessmentForm;
