import React from "react";
import { Flex, Tooltip } from "antd";
import { HelpCircle, PageContainer } from "components/utilitycomponents";
import { SurveyRequestsCount } from "./surveys/SurveyRequestsCount";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { NcrCount } from "./ncrs/NcrCount";
import classes from "./userLandingPage.module.scss";
import { ComplianceCount } from "./compliance/complianceCount";
import { GateKeeperComplianceCount } from "./compliance/gateKeeperComplinaceCount";
import { DevelopmentPlanCount } from "./developmentPlans/developmentPlanCount";
import { useCompanyBranding, useIsGateKeeperUser, useNcrFeature } from "controller";
import { useTranslation } from "react-i18next";

const UserLandingPageContainer = () => {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;
    const hasNcrAccess = user.profile["ll-Ncr"] === "true" ? true : false;
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const { hasNcrFeature } = useNcrFeature();
    const { data: isGateKeeper } = useIsGateKeeperUser();
    const { data: branding } = useCompanyBranding(false);

    return (
        <>
            <Flex justify="space-between" wrap="wrap">
                {hasComplianceAccess && isGateKeeper && (
                    <PageContainer
                        className={
                            isGateKeeper
                                ? classes.landingPageMetricComponent5
                                : classes.landingPageMetricComponent4
                        }>
                        <GateKeeperComplianceCount branding={branding!} />
                    </PageContainer>
                )}
                {hasComplianceAccess && (
                    <PageContainer
                        className={
                            isGateKeeper
                                ? classes.landingPageMetricComponent5
                                : classes.landingPageMetricComponent4
                        }>
                        <ComplianceCount branding={branding!} />
                    </PageContainer>
                )}
                {hasReviewAccess && (
                    <PageContainer
                        className={
                            isGateKeeper
                                ? classes.landingPageMetricComponent5
                                : classes.landingPageMetricComponent4
                        }>
                        <SurveyRequestsCount branding={branding!} />
                    </PageContainer>
                )}
                {hasNcrFeature && hasNcrAccess && (
                    <PageContainer
                        className={
                            isGateKeeper
                                ? classes.landingPageMetricComponent5
                                : classes.landingPageMetricComponent4
                        }>
                        <NcrCount branding={branding!} />
                    </PageContainer>
                )}
                <PageContainer
                    className={
                        isGateKeeper
                            ? classes.landingPageMetricComponent5
                            : classes.landingPageMetricComponent4
                    }>
                    <DevelopmentPlanCount branding={branding!} />
                </PageContainer>
            </Flex>
            <Tooltip
                placement="left"
                overlayClassName={classes.tooltipPopup}
                title={
                    <div>
                        <Flex vertical={true}>
                            {isGateKeeper && (
                                <div>
                                    <div style={{ fontWeight: "bold" }}>
                                        {t("compliance_LandingPageTooltipTitle")}:
                                    </div>
                                    <div>{t("compliance_LandingPageTooltip")}</div>
                                </div>
                            )}
                            <div style={{ marginTop: 6 }}>
                                <div style={{ fontWeight: "bold" }}>
                                    {t("gatekeeper_LandingPageTooltipTitle")}:
                                </div>
                                <div>{t("gatekeeper_LandingPageTooltip")}</div>
                            </div>
                            <div style={{ marginTop: 6 }}>
                                <div style={{ fontWeight: "bold" }}>
                                    {t("survey_LandingPageTooltipTitle")}:
                                </div>
                                <div>{t("survey_LandingPageTooltip")}</div>
                            </div>
                            <div style={{ marginTop: 6 }}>
                                <div style={{ fontWeight: "bold" }}>
                                    {t("ncr_LandingPageTooltipTitle")}:
                                </div>
                                <div>{t("ncr_LandingPageTooltip")}</div>
                            </div>
                            <div style={{ marginTop: 6 }}>
                                <div style={{ fontWeight: "bold" }}>
                                    {t("developmentPlan_LandingPageTooltipTitle")}:
                                </div>
                                <div>{t("developmentPlan_LandingPageTooltip")}</div>
                            </div>
                        </Flex>
                    </div>
                }>
                <span className={classes.tooltipIcon}>
                    <HelpCircle />
                </span>
            </Tooltip>
        </>
    );
};

export { UserLandingPageContainer };
