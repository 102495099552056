import React, { forwardRef } from "react";
import { Select } from "antd";
import { IComplianceStatus } from "models";
import { useTranslation } from "react-i18next";
const Option = Select.Option;

type PropsType = {
    onChange?: (value: number) => void;
    value?: number;
    options: any;
};

const SelectStatus = forwardRef(({ onChange, value, options }: PropsType, ref) => {
    const { t } = useTranslation();
    return (
        <Select
            data-test="registration_status_select"
            ref={ref as any}
            value={value}
            placeholder={t("notSelected")}
            onChange={onChange}
            style={{ minWidth: 270, fontSize: "13px", textAlign: "left" }}
            getPopupContainer={node => (node ? (node.parentNode as HTMLElement) : document.body)}>
            {options.map((status: IComplianceStatus, index: number) => (
                <Option
                    style={{
                        ...(options[index + 1] &&
                            status.name.toLocaleLowerCase().indexOf("block") === -1 &&
                            options[index + 1].name.toLocaleLowerCase().indexOf("block") !== -1 && {
                                borderBottom: "1px solid #e8e8e8",
                            }),
                        fontSize: "13px",
                    }}
                    key={status.id}
                    value={status.id}>
                    {t(status.name)}
                </Option>
            ))}
        </Select>
    );
});

export { SelectStatus };
