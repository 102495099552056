import { IProductUpsert } from "models";
import { useState } from "react";
import Provider from "services/api";

const useProductUpsert = () => {
    const [loading, setLoading] = useState(false);

    const upsert = async (payload: IProductUpsert) => {
        setLoading(true);
        await Provider.Product.upsertProduct(payload, {
            customError: {
                message: "Could not upsert product",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        upsert,
    };
};

export { useProductUpsert };
