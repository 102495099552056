import { Col, Row, Spin } from "antd";
import { colorGrade } from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/regAction.module.scss";
import { ISegmentCategory } from "models";
import { useSegmentCategories } from "controller";

interface IProps {
    data: any | undefined;
    editMode: boolean;
}

const SegmentationData = ({ data, editMode }: IProps) => {
    const { t } = useTranslation();
    const { data: categories, loading } = useSegmentCategories();

    const assignedSegmentSetting =
        data && editMode
            ? categories.filter((x: ISegmentCategory) => x.id === data.assignedCategorySettingId)[0]
            : undefined;
    const suggestedSegmentSettings =
        data && editMode
            ? categories.filter(
                  (x: ISegmentCategory) => x.id === data.suggestedCategorySettingId
              )[0]
            : undefined;

    return (
        <Spin spinning={loading}>
            <div className={classes.dataInfoContainer}>
                <Row>
                    <Col span={4} className={classes.dataLabel}>
                        {t("unitOfAnalysis_AlignmentAverage")}
                    </Col>
                    <Col span={4} className={classes.dataLabel}>
                        {t("unitOfAnalysis_ImpactAverage")}
                    </Col>
                    <Col span={6} className={classes.dataLabel}>
                        {t("segmentation_SuggestSegment")}
                    </Col>
                    <Col span={6} className={classes.dataLabel}>
                        {t("segmentation_AssignedSegment_Current")}
                    </Col>
                </Row>
                <Row>
                    <Col
                        span={4}
                        style={{
                            textAlign: "center",
                            color: colorGrade(data && data.xAxisAvg ? data.xAxisAvg : -1),
                        }}>
                        {data && data.xAxisAvg && data.xAxisAvg.toPrecision(2)}
                    </Col>
                    <Col
                        span={4}
                        style={{
                            textAlign: "center",
                            color: colorGrade(data && data.yAxisAvg ? data.yAxisAvg : -1),
                        }}>
                        {data && data.yAxisAvg && data.yAxisAvg.toPrecision(2)}
                    </Col>
                    <Col span={6}>
                        {editMode ? (
                            <div>
                                <div
                                    style={{
                                        border: `1px solid ${
                                            suggestedSegmentSettings
                                                ? suggestedSegmentSettings.color
                                                : "#808080"
                                        }`,
                                        color: suggestedSegmentSettings
                                            ? suggestedSegmentSettings.color
                                            : "#808080",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        marginLeft: 48,
                                        marginRight: 48,
                                    }}>
                                    {suggestedSegmentSettings
                                        ? suggestedSegmentSettings.segment
                                        : "-"}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div
                                    style={{
                                        border: `1px solid ${
                                            data.suggestedSegmentCurrent
                                                ? data.suggestedSegmentCurrent.segmentColor
                                                : "#808080"
                                        }`,
                                        color: data.suggestedSegmentCurrent
                                            ? data.suggestedSegmentCurrent.segmentColor
                                            : "#808080",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        marginLeft: 48,
                                        marginRight: 48,
                                    }}>
                                    {data.suggestedSegmentCurrent
                                        ? data.suggestedSegmentCurrent.segmentName
                                        : "-"}
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col span={6}>
                        {editMode ? (
                            <div
                                style={{
                                    border: `1px solid ${
                                        assignedSegmentSetting
                                            ? assignedSegmentSetting.color
                                            : "#808080"
                                    }`,
                                    color: assignedSegmentSetting
                                        ? assignedSegmentSetting.color
                                        : "#808080",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginLeft: 48,
                                    marginRight: 48,
                                }}>
                                {assignedSegmentSetting ? assignedSegmentSetting.segment : "-"}
                            </div>
                        ) : (
                            <div
                                style={{
                                    border: `1px solid ${
                                        data.alignedSegmentCurrent.segmentColor
                                            ? data.alignedSegmentCurrent.segmentColor
                                            : "#808080"
                                    }`,
                                    color: data.alignedSegmentCurrent.segmentColor
                                        ? data.alignedSegmentCurrent.segmentColor
                                        : "#808080",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginLeft: 48,
                                    marginRight: 48,
                                }}>
                                {data.alignedSegmentCurrent.segmentName
                                    ? data.alignedSegmentCurrent.segmentName
                                    : "-"}
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export { SegmentationData };
