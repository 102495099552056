import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col } from "antd";
import { useCompliance } from "controller";
import ComplianceTemplateDetail from "./complianceTemplateDetail";
import ComplianceDetail from "./complianceDetail";
import { LoadingIndicator, UserImage } from "components/utilitycomponents";
import { ComplianceDataTypeEnum } from "models";
type PropsType = {
    visible: boolean;
    id: number | undefined;
    onClose: Function;
};

const ReadOnlyRegistrationModal = ({ id, visible, onClose }: PropsType) => {
    const { t } = useTranslation();
    const { loading, data } = useCompliance(ComplianceDataTypeEnum.ForRegistrationDialog, id);

    if (!loading && data === null) return null;

    return (
        <Fragment>
            <Modal
                wrapProps={{ "data-test": "registration_modal" }}
                title={
                    data !== null ? (
                        <Row>
                            <Col
                                span={12}
                                style={{
                                    lineHeight: "32px",
                                }}>{`${t("compliance_RegistrationOn")} ${data.partnerName}`}</Col>
                        </Row>
                    ) : (
                        ""
                    )
                }
                open={visible}
                footer={null}
                onCancel={() => onClose()}
                width={1000}
                style={{ top: 20 }}>
                {loading && data === null && (
                    <div style={{ textAlign: "center" }}>
                        <LoadingIndicator style={{ fontSize: "18px" }} />
                    </div>
                )}
                <div>
                    {data !== null && (
                        <Row gutter={8}>
                            <Col span={2}>
                                <UserImage
                                    data-test="creator_profile_picture"
                                    alt={data.creator}
                                    style={{ width: "100%" }}
                                    fileId={data["creatorProfilePictureId"]}
                                    customDimension={65}
                                />
                            </Col>
                            <Col span={21}>
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <ComplianceDetail
                                                readOnly={true}
                                                data={data}
                                                approvedForLoading={loading}
                                                onRatingChanged={() => {}}
                                            />
                                        </Col>
                                    </Row>

                                    <div style={{ marginTop: "10px" }}>
                                        <ComplianceTemplateDetail
                                            data={data.templateDetail}
                                            canSeeScores={data.canSeeScores}
                                        />
                                    </div>
                                </>
                            </Col>
                        </Row>
                    )}
                </div>
            </Modal>
        </Fragment>
    );
};

export default ReadOnlyRegistrationModal;
