import React, { Fragment, useState, useEffect } from "react";
import { Modal, Checkbox, Divider, Input, Tooltip, Form, Button } from "antd";
import {
    SelectData,
    SelectDataMultiple,
    SelectTreeDataMultiple,
    SelectTreeData,
    HelpCircle,
} from "components/utilitycomponents";
import { XAxisSelect } from "../../dashboardUtils/xAxisSelect/xAxisSelect";
import { PeriodPicker } from "../../dashboardUtils/periodPicker/periodPicker";
import classes from "./styles/creationModal.module.scss";
import { useReportCreate, useKpisAvailable, useKpisSubLevels } from "controller";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    closeModal: Function;
    reload: Function;
    benchmarkId: any;
    dashboardVisibility: any;
    partnerId: number;
    presetId: number;
}

const CreationModal = ({
    visible,
    closeModal,
    reload,
    benchmarkId,
    dashboardVisibility,
    partnerId,
    presetId,
}: IProps) => {
    const [form] = Form.useForm();
    const { loading, addReport } = useReportCreate();
    const [monthRange, setMonthRange] = useState([dayjs().subtract(1, "month"), dayjs()]);
    const [kpiId, setKpiId] = useState<any>(undefined);
    const [isReviewKpi, setIsReviewKpi] = useState(false);
    const { t } = useTranslation();
    const { data: kpiData, loading: kpisLoading } = useKpisAvailable(
        partnerId,
        dashboardVisibility
    );
    const [kpiCustomColumns, setKpiCustomColumns] = useState([]);
    const [allSubLevelsSelected, setAllSubLevelsSelected] = useState(false);
    const [kpiSubLevelHeadline, setKpiSubLevelHeadline] = useState("Sub Level");
    const [chartType, setChartType] = useState<string>("Bar");
    const [xAxisProduct, setXAxisProduct] = useState<any>(undefined);

    useEffect(() => {
        if (!visible) {
            setKpiCustomColumns([]);
        }
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    async function submit() {
        const values = await form.validateFields();
        values.dateRange = monthRange;
        if (isReviewKpi) {
            values.subLevels = values.reviewSubLevels ? values.reviewSubLevels : undefined;
        }
        await addReport(presetId, values);
        form.resetFields();
        reload();
        closeModal();
        setAllSubLevelsSelected(false);
    }

    function onCancel() {
        form.resetFields();
        setAllSubLevelsSelected(false);
        closeModal();
    }

    function onKpiChange(val: any, item: any) {
        setKpiId(parseInt(val));
        setKpiCustomColumns(
            item.customColumns.map((x: any) => ({
                value: x.id,
                label: x.name,
            }))
        );
        setKpiSubLevelHeadline(item.subLevel.subLevelHeadline);
        let kpi = kpiData.filter((x: any) => x.id === val)[0];
        setIsReviewKpi(kpi && kpi.title.toLocaleLowerCase().includes("review"));
        form.setFieldsValue({
            subLevels: undefined,
        });
    }

    const checkboxOptions = [
        { label: t("analytics_CompanyName"), value: "CompanyName" },
        { label: t("analytics_Month"), value: "Month" },
        { label: t("analytics_Year"), value: "Year" },
        { label: kpiSubLevelHeadline, value: "SubLevel" },
        { label: t("analytics_KpiValue"), value: "KpiValue" },
        { label: t("analytics_Target"), value: "TargetValue" },
        { label: t("analytics_AllPartnersValue"), value: "AllExternalPartnersValue" },
        { label: t("analytics_Visibility"), value: "Visibility" },
    ];

    return (
        <div>
            <Modal
                title={t("analytics_AddReport")}
                onOk={submit}
                closable={true}
                width={900}
                open={visible}
                mask={false}
                onCancel={(e: any) => {
                    onCancel();
                }}
                destroyOnClose
                footer={[
                    <Button loading={loading} type="primary" onClick={async () => await submit()}>
                        {t("generic_Add")}
                    </Button>,
                ]}>
                <Form
                    form={form}
                    onFinish={submit}
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}>
                    <Divider orientation="left">{t("analytics_TitleSettingsTitle")}</Divider>

                    <Form.Item
                        label={t("analytics_TitleSettingsReportLabel")}
                        name="reportTitle"
                        valuePropName="value">
                        <Input
                            maxLength={100}
                            placeholder={t("analytics_TitleSettingsReportLabelPlaceholder")}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_TitleSettingsDescrtiptionLabel")}
                        name="reportDescription"
                        valuePropName="value">
                        <TextArea
                            maxLength={256}
                            placeholder={t("analytics_TitleSettingsDescrtiptionLabelPlaceHolder")}
                        />
                    </Form.Item>

                    <Divider orientation="left">
                        {t("analytics_AddReportFiltrationSettingsTitle")}
                    </Divider>

                    <Form.Item
                        label={t("analytics_FiltrationChartType")}
                        name="chartType"
                        initialValue={"Bar"}>
                        <SelectData
                            className={classes.select}
                            placeholder={t("analytics_FiltrationChartTypePlaceholder")}
                            selectOptionField="name"
                            onChange={(val: any) => setChartType(val)}
                            useData={() => {
                                return {
                                    data: [
                                        { id: "Bar", name: t("analytics_BarChart") },
                                        { id: "Pie", name: t("analytics_PieChart") },
                                        {
                                            id: "DataTable",
                                            name: t("analytics_DataTable"),
                                        },
                                        { id: "Line", name: t("analytics_LineChart") },
                                    ],
                                    loading: false,
                                };
                            }}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_FiltrationKPI")}
                        name="kpi"
                        rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                        <SelectData
                            className={classes.select}
                            placeholder={t("analytics_FiltrationKPIPlaceholder")}
                            selectOptionField="title"
                            useData={() => {
                                return { data: kpiData, loading: kpisLoading };
                            }}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            onChange={(val: any, item: any) => onKpiChange(val, item)}
                        />
                    </Form.Item>

                    <Form.Item label={t("analytics_FiltrationPeriod")} name="period">
                        <PeriodPicker
                            initialValue={monthRange}
                            onChange={(values: any[]) => setMonthRange(values)}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_FiltrationXAxisProduct")}
                        name="xAxisProduct"
                        initialValue={chartType === "DataTable" ? "SubLevel" : "Monthly"}>
                        <XAxisSelect
                            className={classes.select}
                            placeholder={t("analytics_FiltrationXAxisProductPlaceholder")}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                            selectedChartType={chartType}
                            onChange={(value: string) => {
                                setAllSubLevelsSelected(value === "AllSubLevels" ? true : false);
                                setXAxisProduct(value);
                            }}
                        />
                    </Form.Item>

                    {xAxisProduct === "SubLevel" ||
                    (chartType === "DataTable" && !allSubLevelsSelected) ? (
                        <Fragment>
                            {isReviewKpi ? (
                                <Form.Item
                                    label={t("analytics_XAxisSelect_SubLevels")}
                                    required={!allSubLevelsSelected}
                                    name="reviewSubLevels"
                                    rules={[
                                        {
                                            required: !allSubLevelsSelected,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectTreeDataMultiple
                                        selectAll
                                        className={classes.subLevelSelect}
                                        placeholder={t("analytics_FiltrationSubLevelsPlaceholder")}
                                        selectIdField="id"
                                        selectOptionField="subLevelHeadline"
                                        useData={useKpisSubLevels.bind(null, kpiId, partnerId)}
                                        getPopupContainer={(node: any) => document.body}
                                        selectOnlyChildren
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label={t("analytics_XAxisSelect_SubLevels")}
                                    required
                                    name="subLevels"
                                    rules={[
                                        {
                                            required: !allSubLevelsSelected,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectDataMultiple
                                        selectAll
                                        className={classes.subLevelSelect}
                                        placeholder={t("analytics_FiltrationSubLevelsPlaceholder")}
                                        selectIdField="id"
                                        selectOptionField="subLevelHeadline"
                                        useData={useKpisSubLevels.bind(null, kpiId, partnerId)}
                                        getPopupContainer={(node: any) => document.body}
                                    />
                                </Form.Item>
                            )}
                        </Fragment>
                    ) : (
                        !allSubLevelsSelected && (
                            <Fragment>
                                <Form.Item
                                    label={t("analytics_FiltrationLevel")}
                                    required={!allSubLevelsSelected}
                                    name="singleSubLevel"
                                    rules={[
                                        {
                                            required: !allSubLevelsSelected,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    {isReviewKpi ? (
                                        <SelectTreeData
                                            className={classes.select}
                                            placeholder={t("analytics_FiltrationLevelPlaceholder")}
                                            selectIdField="id"
                                            selectOptionField="subLevelHeadline"
                                            useData={useKpisSubLevels.bind(null, kpiId, partnerId)}
                                            selectOnlyChildren
                                        />
                                    ) : (
                                        <SelectData
                                            className={classes.select}
                                            placeholder={t("analytics_FiltrationLevelPlaceholder")}
                                            selectIdField="id"
                                            selectOptionField="subLevelHeadline"
                                            useData={useKpisSubLevels.bind(null, kpiId, partnerId)}
                                        />
                                    )}
                                </Form.Item>
                            </Fragment>
                        )
                    )}

                    <Form.Item
                        label={
                            <>
                                {t("analytics_FiltrationBenchmark")}{" "}
                                {benchmarkId === undefined && (
                                    <Tooltip title={t("analytics_FiltrationBenchmarkTooltip")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                )}
                            </>
                        }
                        name="benchmark"
                        valuePropName="checked">
                        <Checkbox disabled={benchmarkId === undefined}>
                            {t("analytics_FiltrationBenchmarkLabel")}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_FiltrationSamePeriod")}
                        name="previousPeriod"
                        valuePropName="checked">
                        <Checkbox>{t("analytics_FiltrationSamePeriodLabel")}</Checkbox>
                    </Form.Item>

                    {chartType === "DataTable" && kpiId !== undefined && (
                        <Fragment>
                            <Divider orientation="left">
                                {t("analytics_FiltrationDataTableSettings")}
                            </Divider>

                            <Form.Item
                                label={t("analytics_FiltrationEnabledColumns")}
                                name="enabledStandardColumns"
                                initialValue={checkboxOptions.map(x => x.value)}>
                                <Checkbox.Group options={checkboxOptions} />
                            </Form.Item>

                            <Form.Item
                                label={t("analytics_FiltrationEnabledCustomColumns")}
                                name="enabledCustomColumns"
                                initialValue={[]}>
                                {kpiCustomColumns.length > 0 ? (
                                    <Checkbox.Group options={kpiCustomColumns} />
                                ) : (
                                    <div>There are no custom columns defined for this KPI</div>
                                )}
                            </Form.Item>
                        </Fragment>
                    )}
                </Form>
            </Modal>
        </div>
    );
};

export default CreationModal;
