import { IRequestInfoObject } from "models";
import { useState } from "react";
import { Provider } from "services";

const useSendSelfAssessment = () => {
    const [loading, setLoading] = useState(false);

    const sendSelfAssessments = async (requestInfoData: IRequestInfoObject) => {
        setLoading(true);
        if (requestInfoData.hasAdditionalText) {
            requestInfoData.additionalText =
                "<b>Message from sender:</b> " +
                requestInfoData.additionalText +
                " <em>End of message</em>";
        }
        const id = await Provider.Compliance.sendSelfAssessments(requestInfoData, {
            customError: {
                message: "Could not send self assessment(s)",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });

        setLoading(false);
        return id;
    };

    return {
        loading,
        sendSelfAssessments,
    };
};

export { useSendSelfAssessment };
