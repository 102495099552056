import { ISavedReportFilter } from "models";
import { useEffect, useState } from "react";
import Provider from "services/api";

const useSegmentUnitOfAnalysisOverview = (
    additionalFilters: ISavedReportFilter[],
    range: any,
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        customFields: [],
    });

    useEffect(() => {
        (async () => {
            setState({
                loading: true,
                data: state.data,
                totalRows: state.totalRows,
                customFields: state.totalRows,
            });

            if (!externalLoading) {
                let apiFilters = additionalFilters
                    .filter((f: ISavedReportFilter) => f.value !== undefined)
                    .map((f: ISavedReportFilter) => ({
                        type: f.type,
                        value: Array.isArray(f.value) ? f.value.join(",") : f.value,
                    }));

                let newFilters = [...filters, ...apiFilters];
                newFilters = newFilters.filter((x: any) => x.type !== "To" && x.type !== "From"); //removing these from filters as we use the from / to param instead
                newFilters.push({
                    type: "Created",
                    value: `${range[0].startOf("month").toISOString()},${range[1]
                        .endOf("month")
                        .toISOString()}`,
                });

                const data: any = await Provider.Survey.getSegmentationUnitOfAnalysisOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters: newFilters,
                        sorts,
                        from: range[0].startOf("month"),
                        to: range[1].endOf("month"),
                    },
                    {
                        customError: {
                            message: "Could not retrieve list of segmentation unit of analysis",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({
                    loading: false,
                    data: data.data,
                    totalRows: data.totalNumberOfRecords,
                    customFields: {
                        parentUnit: data.data[0],
                        xyNames: {
                            xName: data && data.data[0] && data.data[0].xName,
                            yName: data && data.data[0] && data.data[0].yName,
                        },
                        accumulatedValues: data.customFields,
                    },
                });
            }
        })();
    }, [
        pageNumber,
        pageSize,
        filters,
        sorts,
        reloadData,
        externalLoading,
        range,
        additionalFilters,
    ]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        customFields: state.customFields,
    };
};

export { useSegmentUnitOfAnalysisOverview };
