import { ITableInput } from "models";
import { get, post, put, remove } from "../base";

const AdminReview = {
    getReviewDefaults: async ({ customError }: any = {}) => {
        const url = `/v1/AdminReview/GetReviewSetting`;
        return await get({ url, customError });
    },
    updateReviewDefaults: async (reviewDefaults: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/SetReviewSetting`;
        return await post({ url, payload: reviewDefaults, customError });
    },
    getAdminReviewCriteria: async ({ customError }: any = {}) => {
        const url = `/v1/AdminReview/GetReviewCategories`;
        return await get({ url, customError });
    },
    getReviewGroupsOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminReview/GetReviewCategoryGroupOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    createReviewCriteriaGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/CreateReviewCategoryGroup?name=${encodeURIComponent(
            payload.name
        )}`;
        return await post({ url, payload, customError });
    },
    updateReviewCriteriaGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/UpdateReviewCategoryGroup?id=${
            payload.id
        }&name=${encodeURIComponent(payload.name)}`;
        return await post({ url, payload, customError });
    },
    deleteReviewCriteriaGroup: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/DeleteReviewCategoryGroups`;
        return await remove({ url, payload, customError });
    },
    isReviewCriteriaGroupNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/IsReviewCategoryGroupInUse?name=${encodeURIComponent(name)}`;
        return await get({ url, customError });
    },
    getReviewCriteriaGroups: async ({ customError }: any = {}) => {
        const url = `/v1/AdminReview/GetAllReviewCategoryGroups`;
        return await get({ url, customError });
    },
    getReviewCriteriaOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminReview/GetReviewCategoriesCriteriaOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    createReviewCriteria: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/CreateReviewCategoryCriteria`;
        return await post({ url, payload, customError });
    },
    updateReviewCriteria: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/UpdateReviewCategoryCriteria`;
        return await post({ url, payload, customError });
    },
    isReviewCriteriaNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/IsReviewCategoryCriteriaNameInUse?name=${encodeURIComponent(
            name
        )}`;
        return await get({ url, customError });
    },
    updateReviewCriteriaActivation: async (
        ids: number[],
        activation: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminReview/UpdateReviewCategoryCriteriaActivation?activation=${activation}`;
        return await put({ url, payload: ids, customError });
    },
    updateReviewCriteriaSelectedGroup: async (
        ids: number[],
        groupId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminReview/UpdateReviewCategoryCriteriaGroup?${
            groupId !== null ? `groupId=${groupId}` : ""
        }`;
        return await put({ url, payload: ids, customError });
    },
    deleteReviewCriteria: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminReview/DeleteReviewCategoryCriteria`;
        return await remove({ url, payload: ids, customError });
    },
    getSurveyTemplateGroupOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminReview/GetSurveyTemplateGroupOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    createSurveyTemplateGroup: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/CreateSurveyTemplateGroup?name=${encodeURIComponent(name)}`;
        return await post({ url, customError });
    },
    updateSurveyTemplateGroup: async (id: number, name: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/UpdateSurveyTemplateGroup?id=${id}&name=${encodeURIComponent(
            name
        )}`;
        return await put({ url, customError });
    },
    deleteSurveyTemplateGroups: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminReview/DeleteSurveyTemplateGroups`;
        return await remove({ url, payload: ids, customError });
    },
    isSurveyTemplateGroupNameInUse: async (name: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/IsSurveyTemplateGroupInUse?name=${encodeURIComponent(name)}`;
        return await get({ url, customError });
    },
    getSurveyTypes: async ({ customError }: any = {}) => {
        const url = `/v1/AdminReview/GetAllSurveyTypes`;
        return await get({ url, customError });
    },
    getSurveyTemplateGroups: async ({ customError }: any = {}) => {
        const url = `/v1/AdminReview/GetAllSurveyTemplateGroups`;
        return await get({ url, customError });
    },
    createSurveyTemplate: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/CreateSurveyTemplate`;
        return await post({ url, payload, customError });
    },
    getSurveyTemplateOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/AdminReview/GetSurveyTemplateOverview`;
        return await post({ url, payload: tableInput, customError });
    },
    deleteSurveyTemplates: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminReview/DeleteSurveyTemplates`;
        return await remove({ url, payload: ids, customError });
    },
    updateSurveyTemplate: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminReview/UpdateSurveyTemplate`;
        return await put({ url, payload, customError });
    },
    getSurveyTemplate: async (id: number, { customError }: any = {}) => {
        const url = `/v1/AdminReview/GetSurveyTemplateById?id=${id}`;
        return await get({ url, customError });
    },
    isSurveyTemplateTitleInUse: async (title: string, { customError }: any = {}) => {
        const url = `/v1/AdminReview/IsSurveyTemplateInUse?name=${encodeURIComponent(title)}`;
        return await get({ url, customError });
    },
    updateCriteriaGroupCategoryRelation: async (
        criteriaGroupIds: number[],
        categoryId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminReview/PutCriteriaGroupCategoryRelation/${categoryId}`;
        await put({ url, payload: criteriaGroupIds, customError });
    },
    removeCriteriaGroupCategoryRelation: async (
        criteriaGroupIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminReview/DeleteCriteriaGroupCategoryRelation`;
        await put({ url, payload: criteriaGroupIds, customError });
    },
    UpdateSurveyTemplateGroupType: async (
        surveyTemplateGroupIds: number[],
        typeId?: string,
        { customError }: any = {}
    ) => {
        let url = ``;
        if (typeId) {
            url = `/v1/AdminReview/UpdateSurveyTemplateGroupType?typeId=${typeId}`;
        } else {
            url = `/v1/AdminReview/UpdateSurveyTemplateGroupType`;
        }
        await put({ url: url, payload: surveyTemplateGroupIds, customError });
    },
    GetDefaultReviewCriteria: async () => {
        let url = `/v1/AdminReview/GetDefaultReviewCriteria`;
        return await get({ url });
    },
};

export { AdminReview };
