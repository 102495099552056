import { post } from "../base";
import { ITableInput } from "models";

const Request = {
    requestOverview: async (tableInput: ITableInput, { customError }: any) => {
        const url = "/v1/Request/GetSelfAssessmentForRecall";
        return await post({ url, payload: tableInput, customError });
    },
    recallSelfAssessment: async (tokens: string[]) => {
        const url = "/v1/Request/RecallSelfAssessment";
        return await post({ url, payload: tokens });
    },
};

export default Request;
