import { Grid, getColumnSearchProps, getColumnSelectProps } from "components/utilitycomponents";
import { useProductOverview, useProductStatus } from "controller";
import React, { useState } from "react";
import { ProductDocumentationDetails } from "./ProductDocumentationDetails";
import { Badge, Tag, Tooltip } from "antd";
import { IProduct } from "models";
import { useTranslation } from "react-i18next";

interface IProps {
    partnerId: number;
}

const SupplierProfileProductOverview = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [productModal, toggleProductModal] = useState<any>({ visible: false, id: undefined });
    const [reloadTable, setReloadTable] = useState<any>({ reload: false });

    const columns = [
        {
            title: t("product_ProductId"),
            dataIndex: "productId",
            viewColumnId: "productId",
            key: "productId",
            ellipsis: true,
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "productId" }),
        },
        {
            title: t("generic_Name"),
            dataIndex: "name",
            viewColumnId: "name",
            key: "productName",
            ellipsis: true,
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "productName" }),
        },
        {
            title: t("product_Category"),
            dataIndex: "commodityCategory",
            viewColumnId: "commodityCategory",
            key: "commodityCategory",
            ellipsis: true,
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "commodityCategory" }),
        },
        {
            title: t("product_ProductStatus"),
            dataIndex: "productStatus",
            viewColumnId: "productStatus",
            key: "productStatus",
            width: 150,
            sorter: true,
            render: (text: any) => {
                switch (text) {
                    case "Active":
                        return (
                            <Tag bordered={false} color="#2db7f5">
                                {text}
                            </Tag>
                        );
                    case "Inactive":
                        return <Tag bordered={false}>{text}</Tag>;
                    case "Pending":
                        return (
                            <Tag bordered={false} color="#f50">
                                {text}
                            </Tag>
                        );

                    default:
                        return <></>;
                }
            },
            filterType: "array",
            ...getColumnSelectProps(
                useProductStatus,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "productStatus" }
            ),
        },
        {
            title: t("product_Documents"),
            dataIndex: "approvedDocumentsCount",
            key: "approvedDocumentsCount",
            viewColumnId: "approvedDocumentsCount",
            render: (val: number, record: IProduct) => (
                <>
                    {record.activeDocumentsCount! > 0 && (
                        <Tooltip title={t("product_ApprovedDocumentsCount")}>
                            <Badge count={record.activeDocumentsCount} color="#2db7f5" />
                        </Tooltip>
                    )}
                    {record.expiringDocumentsCount! > 0 && (
                        <Tooltip title={t("product_ExpiringDocumentsCount")}>
                            <Badge count={record.expiringDocumentsCount} color="#f50" />
                        </Tooltip>
                    )}
                    {record.activeDocumentsCount === 0 && record.expiringDocumentsCount === 0 && (
                        <Badge count={0} showZero color="grey" />
                    )}
                </>
            ),
        },
    ];

    const handleOnRow = (record: any) => ({
        onClick: async () => {
            toggleProductModal({ id: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <div>
            <Grid
                useData={useProductOverview.bind(null, partnerId)}
                columns={columns}
                tableType="ProductOverview"
                onRow={handleOnRow}
                reloadData={reloadTable}
            />
            <ProductDocumentationDetails
                partnerId={partnerId}
                id={productModal.id}
                visible={productModal.visible}
                onClose={() => toggleProductModal(false)}
                reloadTable={() => setReloadTable({ reload: true })}
            />
        </div>
    );
};

export { SupplierProfileProductOverview };
