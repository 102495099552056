import Config from "../../../config";
import { IGeneralCompanySettings } from "models/admin/companySettings/ICompanySettings";
import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: IGeneralCompanySettings;
};

const useCompanySettings = (userType: string = "user") => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: {
            showOwnUploadedPartnerName: false,
            allowUsersOnCreateSupplier: false,
            allowUsersMakeThemSelvesAsOwner: false,
            allowOwnersToEditPartnerData: false,
            autoGenerateCompanyNumbers: false,
            allowComplianceRequesterToReceiveEmailWhenStatusChanged: false,
            allowNormalUsersToSwitchOwner: false,
            allowComplianceToHaveStatusForRegions: false,
            allowComplianceToHaveStatusForSites: false,
            canUsersCreateSupplier: false,
            allowGatekeeperToEditMasterdata: false,
        },
    });

    useEffect(() => {
        (async () => {
            if (userType !== Config.guestUser) {
                setState({ ...state, loading: true });
                let data = await Provider.Company.getCompanySettings().catch((err: any) => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCompanySettings };
