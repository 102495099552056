import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useDefaultDashboard = () => {
    const [loading, SetLoading] = useState(false);

    const setDefaultDashboard = async (presetId: number) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.setDefaultDashboard(presetId).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
        return response;
    };

    const setDefaultCompanyDashboard = async (presetId: number) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.setDefaultCompanyDashboard(presetId).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
        return response;
    };

    return {
        loading,
        setDefaultDashboard,
        setDefaultCompanyDashboard,
    };
};

export { useDefaultDashboard };
