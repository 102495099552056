import React, { forwardRef } from "react";
import { Select, Input } from "antd";
import { IComplianceCustomDropDown } from "models";
import { useTranslation } from "react-i18next";
const Option = Select.Option;

type PropsType = {
    onChange?: (value: string) => void;
    value?: string;
    entityType: string;
    options: IComplianceCustomDropDown[] | null;
};

const Entity = forwardRef(({ onChange, value, entityType, options }: PropsType, ref) => {
    const { t } = useTranslation();
    if (entityType === "Text") {
        return (
            <Input
                maxLength={200}
                data-test="entity_text"
                ref={ref as any}
                onChange={(e: any) => onChange && onChange(e.target.value)}
                value={value}
            />
        );
    }
    return (
        <Select
            data-test="entity_select"
            value={value}
            onChange={(value: string) => onChange && onChange(value)}
            placeholder={t("select")}
            ref={ref as any}
            getPopupContainer={(node: any) =>
                node ? (node.parentNode as HTMLElement) : document.body
            }
            style={{ minWidth: "150px", maxWidth: "100%" }}>
            {options &&
                options.map((option, index) => (
                    <Option key={index} value={option.text}>
                        {option.text}
                    </Option>
                ))}
        </Select>
    );
});

export { Entity };
