import React, { useState, useEffect, FunctionComponent } from "react";
import {
    AlertCircle,
    BarChart07,
    Eye,
    File02,
    Grid,
    MultipleTags,
    PlusCircle,
    Send01,
    TopFilter,
    Trash02,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { Button, Tooltip, Progress, Row, Col, Popconfirm } from "antd";
import {
    useSegmentUnitOfAnalysisOverview,
    useSegmentationReviewGroupNames,
    useSegmentationDelete,
} from "controller";
import AlignModal from "./alignModal/alignModal";
import { ChangeLogModal } from "../changelog/changelogModal";
import SurveyResults, {
    colorGrade,
} from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import { SegmentationSurveyResultsTitle } from "components/contentcomponents/reviews/survey/modals/surveyResults/segmentationSurveyResultsTitle";
import classes from "./segmentationOverview.module.scss";
import { formatTime } from "utilities";
import RegistrationCreateModal from "components/contentcomponents/action/registration/registrationCreateModal";
import { IActionType } from "models/action";
import { Link } from "react-router-dom";
import { SavedFilterType, SurveyType } from "models";
import { useSelector } from "react-redux";
import { AppState } from "store";
import dayjs from "utilities/daysJsConfig";
interface IProps {
    partnerId: any;
    onParentAlign: Function;
    onShowInChart: Function;
    onAccumulatedValues: Function;
    reloadTable: any;
}

const Overview: FunctionComponent<IProps> = ({
    partnerId,
    onParentAlign,
    onShowInChart,
    onAccumulatedValues,
    reloadTable,
}) => {
    const { t } = useTranslation();
    const [alignModal, setAlignModal] = useState({ visible: false, data: undefined });
    const [changeLogModal, setChangeLogModal] = useState({ visible: false, data: undefined });
    const [reload, setReload] = useState({ reload: false });
    const [resultsModal, setResultsModal] = useState<any>({
        surveyId: undefined,
        segmentationAnalysisId: undefined,
        visible: false,
        supplierEntity: undefined,
        descriptors: [],
        tableRecord: undefined,
    });
    const { deleteItem } = useSegmentationDelete();
    const [actionModal, setActionModal] = useState<any>({
        visible: false,
        tableRecord: undefined,
    });
    const [chartData, setChartData] = useState<any[]>([]);
    const [parentUnit, setParentUnit] = useState<any>(undefined);
    const [topFilters, setTopFilters] = useState<any>([]);
    const [dateRange, setDateRange] = useState<any[]>([
        dayjs().startOf("year"),
        dayjs().endOf("year"),
    ]);
    const { data: axisNames } = useSegmentationReviewGroupNames();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const getStrokeColor = (val: number, maxNumber: number) => {
        if (val > maxNumber) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        }

        let percentage = (val / maxNumber) * 100;
        if (percentage === 100) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        } else if (percentage < 100 && percentage >= 75) {
            return {
                "0%": "#ABC41D",
                "100%": "#ABC41D",
            };
        } else if (percentage < 75 && percentage >= 33) {
            return {
                "0%": "#fa8c16",
                "100%": "#fa8c16",
            };
        } else {
            return {
                "0%": "#f5222d",
                "100%": "#f5222d",
            };
        }
    };

    useEffect(() => {
        handleShowInChart(parentUnit, 0);
    }, [parentUnit, partnerId]);

    useEffect(() => {
        onShowInChart(chartData);
    }, [chartData, partnerId]);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadTable]);

    const handleShowInChart = (unit: any, index: number) => {
        let tempArray: any[] = [];
        if (unit && unit.xAxisAvg && unit.yAxisAvg) {
            let pointObject = {
                unitId: unit.id,
                name: (
                    <>
                        <div>{unit.supplierName}</div> {buildDescriptors(unit)}
                    </>
                ),
                x: unit.xWeightedAvg,
                y: unit.yWeightedAvg,
                parent: index === 0 ? true : false,
            };

            tempArray.push(pointObject);
        }
        setChartData(tempArray);
    };

    const handleOnIconClick = (unit: any, index: number) => {
        let tempArray = chartData.slice(0);
        if (unit && unit.xAxisAvg && unit.yAxisAvg) {
            let pointObject = {
                unitId: unit.id,
                name: (
                    <>
                        <div>{unit.supplierName}</div> {buildDescriptors(unit)}
                    </>
                ),
                x: unit.xWeightedAvg,
                y: unit.yWeightedAvg,
                parent: index === 0 ? true : false,
            };

            if (tempArray.filter(x => x.unitId === pointObject.unitId).length >= 1) {
                tempArray = tempArray.filter(x => x.unitId !== pointObject.unitId);
            } else {
                tempArray.push(pointObject);
            }
        }
        setChartData(tempArray);
    };

    const buildDescriptors = (record: any) => {
        return (
            <>
                {record.commodityCategories && (
                    <div style={{ wordBreak: "break-word" }}>
                        {record.commodityCategories.map((c: string) => c).join("/ ")}
                    </div>
                )}
                {record.divisions && <div>{record.divisions.map((d: string) => d).join("/")}</div>}
                {record.regions && <div>{record.regions.map((r: string) => r).join("/")}</div>}
            </>
        );
    };

    let columns = [
        {
            title: t("generic_Status"),
            dataIndex: "deadline",
            key: "deadline",
            width: 130,
            fixed: true,
            viewColumnId: "deadline",
            render: (deadline: string, record: any) => (
                <div
                    style={{
                        paddingLeft: "30px",
                    }}>
                    <div className={classes.highlightBox}>
                        <Tooltip title={t("unitOfAnalysis_NoSegmentAssigned")}>
                            <span>
                                <AlertCircle
                                    style={{
                                        display: record.highlight === true ? "" : "none",
                                    }}
                                    className={classes.highlightIcon}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    {((deadline && dayjs().isBefore(deadline, "day")) ||
                        dayjs().isSame(deadline, "day")) && (
                        <div className={classes.activeDeadline}>
                            {dayjs(deadline)
                                .format("MMM D, YYYY")
                                .toString()}
                        </div>
                    )}
                    {deadline && dayjs().isAfter(deadline, "day") && (
                        <div className={classes.resolvedDeadline}>
                            {t("unitOfAnalysis_Completed")}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: t("unitOfAnalysis_SupplierEntity"),
            dataIndex: "supplierName",
            key: "SupplierName",
            width: 230,
            fixed: true,
            viewColumnId: "supplierName",
            render: (name: string, record: any) => (
                <div className={classes.entityCell}>
                    <div>
                        {name}{" "}
                        {record.supplierId === parseInt(partnerId) && (
                            <div style={{ display: "inline-block", marginRight: 4 }}>
                                <Tooltip title={t("unitOfAnalysis_ParentLevel")}>
                                    <span className={classes.parentIcon}>P</span>
                                </Tooltip>
                            </div>
                        )}
                        <MultipleTags
                            tagNumber={1}
                            className={classes.surveyTitleDescriptorContainer}
                            values={record.commodityCategories
                                .concat(record.divisions, record.regions)
                                .map((item: string, index: number) => ({
                                    name: item,
                                    id: index,
                                }))}
                            color="#108ee9"
                            tagTooltips={true}
                        />
                    </div>
                </div>
            ),
        },
        {
            title:
                axisNames && axisNames.activeXName
                    ? axisNames.activeXName
                    : t("unitOfAnalysis_AlignmentAverage"),
            dataIndex: "xAxisAvg",
            key: "XAxisAvg",
            width: 150,
            pronounced: true,
            viewColumnId: "alignmentAverage",
            render: (avg: number, row: any) => (
                <Tooltip
                    title={
                        avg &&
                        t("segmentation_ValueBasedOnSurveyCount", {
                            value: avg.toPrecision(2),
                            surveyCount: row.surveyCount,
                        })
                    }>
                    <span style={{ color: colorGrade(avg) }}>{avg ? avg.toPrecision(2) : 0}</span>
                </Tooltip>
            ),
        },
        {
            title:
                axisNames && axisNames.activeXName
                    ? axisNames.activeYName
                    : t("unitOfAnalysis_ImpactAverage"),
            dataIndex: "yAxisAvg",
            key: "YAxisAvg ",
            width: 130,
            pronounced: true,
            viewColumnId: "impactAverage",
            render: (avg: number, row: any) => (
                <Tooltip
                    title={
                        avg &&
                        t("segmentation_ValueBasedOnSurveyCount", {
                            value: avg.toPrecision(2),
                            surveyCount: row.surveyCount,
                        })
                    }>
                    <span style={{ color: colorGrade(avg) }}>{avg ? avg.toPrecision(2) : 0}</span>
                </Tooltip>
            ),
        },
        {
            title: t("unitOfAnalysis_SuggestedSegment_Current"),
            dataIndex: "suggestedSegmentCurrent",
            key: "suggestedSegmentCurrent",
            width: 175,
            pronounced: true,
            viewColumnId: "suggestedSegment",
            render: (suggestedSegmentCurrent: any) => {
                if (suggestedSegmentCurrent && suggestedSegmentCurrent.segmentName) {
                    return (
                        <div
                            style={{
                                border: `1px solid ${suggestedSegmentCurrent.segmentColor}`,
                                color: suggestedSegmentCurrent.segmentColor,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}>
                            {suggestedSegmentCurrent.segmentName}
                        </div>
                    );
                }
            },
        },
        {
            title: t("unitOfAnalysis_AlignedSegment"),
            width: 350,
            viewColumnId: "alignedSegment",
            children: [
                {
                    title: t("unitOfAnalysis_Current"),
                    dataIndex: "alignedSegmentCurrent",
                    onHeaderCell: () => ({
                        style: {
                            color: "#808080",
                            fontWeight: "bold",
                            textAlign: "center",
                        },
                    }),
                    render: (_: any, record: any) => {
                        if (
                            record.alignedSegmentCurrent &&
                            record.alignedSegmentCurrent.segmentName
                        ) {
                            return (
                                <div
                                    style={{
                                        border: `1px solid ${record.alignedSegmentCurrent.segmentColor}`,
                                        color: record.alignedSegmentCurrent.segmentColor,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}>
                                    {record.alignedSegmentCurrent.segmentName}
                                </div>
                            );
                        }
                    },
                },
                {
                    title: t("unitOfAnalysis_Future"),
                    dataIndex: "alignedSegmentFuture",
                    onHeaderCell: () => ({
                        style: {
                            color: "#808080",
                            fontWeight: "bold",
                            textAlign: "center",
                        },
                    }),
                    render: (_: any, record: any) => {
                        if (
                            record.alignedSegmentFuture &&
                            record.alignedSegmentFuture.segmentName
                        ) {
                            return (
                                <div
                                    style={{
                                        border: `1px solid ${record.alignedSegmentFuture.segmentColor}`,
                                        color: record.alignedSegmentFuture.segmentColor,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}>
                                    {record.alignedSegmentFuture.segmentName}
                                </div>
                            );
                        }
                    },
                },
            ],
        },
        {
            title: t("unitOfAnalysis_LastAligned"),
            width: 200,
            dataIndex: "modified",
            viewColumnId: "lastAligned",
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
        {
            title: t("unitOfAnalysis_AlignSegmentation"),
            width: 150,
            viewColumnId: "alignSegmentation",
            render: (val: any, record: any) => (
                <Button
                    type="primary"
                    size="middle"
                    onClick={() => setAlignModal({ visible: true, data: record })}>
                    {t("unitOfAnalysis_Align")}
                </Button>
            ),
        },
        {
            title: t("unitOfAnalysis_ViewResponseStatistics"),
            width: 150,
            viewColumnId: "viewResult",
            render: (_: any, record: any) => (
                <Row
                    onClick={() =>
                        setResultsModal({
                            segmentationAnalysisId: record.id,
                            surveyId: record.latestSurveyId,
                            visible: true,
                            supplierEntity: { name: record.supplierName, id: record.supplierId },
                            descriptors: record.commodityCategories
                                .concat(record.divisions, record.regions)
                                .map((item: string, index: number) => ({
                                    name: item,
                                    id: index,
                                })),
                            tableRecord: record,
                        })
                    }
                    style={{ cursor: "pointer" }}>
                    <Col span={6}>
                        <Eye
                            style={{
                                fontSize: "20px",
                                marginTop: 2,
                            }}
                        />
                    </Col>
                    <Tooltip title={t("unitOfAnalysis_BasedRecentSurvey")}>
                        <Col span={16}>
                            <Progress
                                success={{ percent: 0 }}
                                size="small"
                                width={100}
                                percent={
                                    (record.currentResponseCount / record.maxResponseCount) * 100
                                }
                                format={val => (
                                    <span>{`${record.currentResponseCount} / ${record.maxResponseCount}`}</span>
                                )}
                                strokeWidth={20}
                                strokeColor={getStrokeColor(
                                    record.currentResponseCount,
                                    record.maxResponseCount
                                )}
                            />
                        </Col>
                    </Tooltip>
                </Row>
            ),
        },
        {
            title: t("generic_Actions"),
            className: classes.centerTitle,
            width: 245,
            viewColumnId: "actionButtons",
            render: (_: any, record: any, index: number) => (
                <div>
                    <Tooltip title={t("unitOfAnalysis_Log")}>
                        <span>
                            <File02
                                onClick={() => setChangeLogModal({ visible: true, data: record })}
                                className={classes.changeLogIcon}
                                style={{ marginLeft: 8, marginRight: 8 }}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip title={t("unitOfAnalysis_ShowInChart")}>
                        <span>
                            <BarChart07
                                onClick={() => handleOnIconClick(record, index)}
                                className={
                                    chartData.filter(x => x.unitId === record.id).length > 0
                                        ? classes.showInChartActive
                                        : classes.showInChartInactive
                                }
                                style={{ marginLeft: 8, marginRight: 8 }}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip title={t("unitOfAnalysis_CreateAction")}>
                        <span>
                            <PlusCircle
                                style={{
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                                onClick={() =>
                                    setActionModal({ visible: true, tableRecord: record })
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip title={"Repeat Survey"}>
                        {record.latestSurveyId ? (
                            <Link
                                to={`/Reviews/SurveyOverview/Create/type/2/${partnerId}/${record.latestSurveyId}`}>
                                <Send01
                                    style={{
                                        marginLeft: 8,
                                        marginRight: 8,
                                        color: "#0477A9",
                                    }}
                                />
                            </Link>
                        ) : (
                            <span>
                                <Send01
                                    style={{
                                        marginLeft: 8,
                                        marginRight: 8,
                                        color: "color: #808080;",
                                    }}
                                />
                            </span>
                        )}
                    </Tooltip>
                    {userType === "administrator" && (
                        <Tooltip title={t("segmentation_DeleteUnitOfAnalysis")} placement="left">
                            <Popconfirm
                                placement="left"
                                title={t("segmentation_RemoveConfirm")}
                                okText={t("generic_Yes")}
                                cancelText={t("generic_No")}
                                onConfirm={async () => {
                                    await deleteItem(record.id);
                                    setReload({ reload: true });
                                }}>
                                <span>
                                    <Trash02
                                        className={classes.removeSegment}
                                        style={{ marginLeft: 8, marginRight: 8 }}
                                    />
                                </span>
                            </Popconfirm>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div style={{ marginBottom: 4 }}>
            <Grid
                data-test="unit_of_analysis_table"
                columns={columns}
                useData={useSegmentUnitOfAnalysisOverview.bind(null, topFilters, dateRange)}
                saveColumnSettings={false}
                fixedColumns={["id", "supplierName"]}
                reloadData={reload}
                onHeaderCell={() => ({
                    style: {
                        color: "#808080",
                        fontWeight: "bold",
                    },
                })}
                title={() => (
                    <>
                        <TopFilter
                            partnerId={partnerId}
                            onFiltersChange={(val: any) => {
                                setTopFilters(val);
                            }}
                            filterType={SavedFilterType.SegmentationPerformance}
                            onSavedFiltersChange={(val: any) => {
                                setReload({ reload: true });
                            }}
                            updateSavedFilters={(val: any) => setTopFilters(val)}
                            type={SurveyType.Segmentation}
                            onDateRangeChange={(value: any) => {
                                setDateRange(value);
                            }}
                        />
                    </>
                )}
                onDataCustomFields={(customFields: any) => {
                    customFields !== null && setParentUnit(customFields.parentUnit);
                    customFields !== null && onAccumulatedValues(customFields.accumulatedValues);
                }}
                customPageSize={15}
            />
            {alignModal.visible && (
                <AlignModal
                    visible={alignModal.visible}
                    onClose={() => setAlignModal({ visible: false, data: undefined })}
                    record={alignModal.data}
                    onSuccess={() => setReload({ reload: true })}
                    onParentAlign={() => onParentAlign()}
                    partnerId={partnerId}
                />
            )}
            <ChangeLogModal
                visible={changeLogModal.visible}
                onClose={() => setChangeLogModal({ visible: false, data: undefined })}
                record={changeLogModal.data}
            />
            <SurveyResults
                gridTitle={<SegmentationSurveyResultsTitle resultsModal={resultsModal} />}
                segmentationAnalysisId={resultsModal.segmentationAnalysisId}
                surveyId={resultsModal.surveyId}
                visible={resultsModal.visible}
                type={"segmentation"}
                onClose={() =>
                    setResultsModal({
                        surveyId: undefined,
                        segmentationAnalysisId: undefined,
                        supplierEntity: undefined,
                        descriptors: [],
                        tableRecord: undefined,
                        visible: false,
                    })
                }
            />
            {actionModal.tableRecord && (
                <RegistrationCreateModal
                    registrationType={IActionType.Segmentation}
                    visible={actionModal.visible}
                    onClose={() => setActionModal({ visible: false, tableRecord: undefined })}
                    registrationId={actionModal.tableRecord.id}
                    supplierId={actionModal.tableRecord.supplierId}
                    data={actionModal.tableRecord}
                />
            )}
        </div>
    );
};

export default Overview;
