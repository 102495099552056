import { useEffect, useState } from "react";
import Provider from "services/api";

const useFolderFileCreators = () => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.File.getFolderOrFileCreatorList({
                customError: {
                    message: "Could not retrieve createdBy list",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useFolderFileCreators };
