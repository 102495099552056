import React from "react";
import { Row, Col } from "antd";
import { SustainabilityRadarChart } from "./sustainabilityRadarChart";
import { SustainabilityTable } from "./sustainabilityTable";
import { useSustainabilityScores } from "controller";

interface IProps {
    partnerId: number;
}

const DrilldownMetrics = ({ partnerId }: IProps) => {
    const { data, loading } = useSustainabilityScores(partnerId);

    return (
        <Row gutter={16}>
            <Col span={14}>
                <SustainabilityRadarChart data={data} loading={loading} />
            </Col>
            <Col span={10}>
                <SustainabilityTable data={data} loading={loading} />
            </Col>
        </Row>
    );
};

export { DrilldownMetrics };
