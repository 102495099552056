import React from "react";
import { Divider, Progress, Row, Skeleton, Spin, Tooltip } from "antd";
import classes from "./gauge.module.scss";
import { Link } from "react-router-dom";
import { ConditionalWrapper } from "../conditionalWrapper/ConditionalWrapper";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";

interface IScaleColor {
    percentage: number;
    color: string;
    description: string;
}

interface IProps {
    hideNumber?: boolean;
    title: string;
    useData: Function;
    type: "line" | "circle" | "dashboard";
    gapDegree?: number;
    gapPosition?: "top" | "bottom" | "left" | "right";
    strokeWidth?: number;
    width?: number;
    format?: "percentage" | "number";
    maxNumber?: number;
    scaleValues?: IScaleColor[];
    linkTo?: string;
    strokeLinecap?: "round" | "butt" | "square";
    size?: "default" | "small";
    scoreFieldName?: string;
    modifiedFieldName?: string;
    emptyText?: string;
    tooltipPosition?:
        | "top"
        | "left"
        | "right"
        | "bottom"
        | "topLeft"
        | "topRight"
        | "bottomLeft"
        | "bottomRight"
        | "leftTop"
        | "leftBottom"
        | "rightTop"
        | "rightBottom";
    innerFontColor?: string;
    descriptionFontColor?: string;
    descriptionPosition?: "bottom" | "right";
    descriptionStyle?: any;
    onClick?: Function;
    [key: string]: any;
}

const Gauge = ({
    hideNumber = false,
    title,
    useData,
    type = "line",
    gapPosition = "bottom",
    gapDegree = 75,
    strokeWidth = 6,
    width = 132,
    format = "percentage",
    maxNumber = 5,
    linkTo,
    strokeLinecap = "round",
    size = "default",
    scoreFieldName = "score",
    modifiedFieldName = "modified",
    emptyText = "No score available",
    scaleValues = [
        { percentage: 35, color: "#b20000", description: "Low" },
        {
            percentage: 70,
            color: "#ff7a45",
            description: "Medium",
        },
        { percentage: 100, color: "#00b204", description: "High" },
    ],
    tooltipPosition = "top",
    innerFontColor,
    descriptionFontColor,
    descriptionPosition = "bottom",
    descriptionStyle,
    onClick,
    ...rest
}: IProps) => {
    const { data, loading } = useData();
    const { t } = useTranslation();
    const getStrokeColor = () => {
        if (data) {
            for (let i = 0; i < scaleValues.length; i++) {
                if (data[scoreFieldName] <= scaleValues[i].percentage) {
                    return scaleValues[i].color;
                }
            }
        }
    };

    const getDescription = () => {
        if (data && data[scoreFieldName]) {
            for (let i = 0; i < scaleValues.length; i++) {
                if (data[scoreFieldName] <= scaleValues[i].percentage) {
                    return scaleValues[i].description;
                }
            }
        }
    };

    return (
        <div
            className={
                descriptionPosition === "bottom"
                    ? classes.gaugeContainerBottom
                    : classes.gaugeContainerRight
            }>
            <div
                className={
                    descriptionPosition === "bottom" ? classes.headerBottom : classes.headerRight
                }>
                <Row>
                    <span className={classes.titleText}>{title}</span>
                </Row>
                <Row className={classes.updateTextContainer}>
                    {!loading && (
                        <span className={classes.updateText}>{`${t("generic_gauge_LastUpdate")} ${
                            data && data[modifiedFieldName]
                                ? dayjs(data[modifiedFieldName]).format("MMM D, YYYY")
                                : "-"
                        }`}</span>
                    )}
                </Row>
                <Row>
                    <Divider className={classes.divider} />
                </Row>
            </div>
            <Row
                className={
                    descriptionPosition === "bottom" ? classes.gaugeBottom : classes.gaugeRight
                }>
                <>
                    <Spin spinning={loading} size="large">
                        {!loading && data && data[scoreFieldName] !== null && (
                            <div
                                className={classes.gaugeBody}
                                style={{
                                    cursor:
                                        onClick !== undefined || linkTo !== undefined
                                            ? "pointer"
                                            : "default",
                                }}
                                onClick={() => onClick && onClick()}>
                                <ConditionalWrapper
                                    condition={linkTo}
                                    wrapper={(children: any) => (
                                        <Link to={linkTo!}>{children}</Link>
                                    )}>
                                    <Tooltip
                                        title={getDescription()}
                                        placement={tooltipPosition}
                                        color="blue"
                                        overlayInnerStyle={{ color: "#fff" }}>
                                        <Progress
                                            size={size}
                                            percent={
                                                data[scoreFieldName]
                                                    ? data[scoreFieldName]
                                                    : undefined
                                            }
                                            strokeColor={getStrokeColor()}
                                            type={type}
                                            gapPosition={gapPosition}
                                            gapDegree={gapDegree}
                                            strokeWidth={strokeWidth}
                                            width={width}
                                            strokeLinecap={strokeLinecap}
                                            format={(percent: any, successPercent: any) => {
                                                return (
                                                    !hideNumber && (
                                                        <>
                                                            {format === "percentage" ? (
                                                                <div
                                                                    style={{
                                                                        color: innerFontColor
                                                                            ? innerFontColor
                                                                            : getStrokeColor(),
                                                                    }}>
                                                                    {percent !== 100
                                                                        ? percent.toPrecision(2)
                                                                        : percent}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        color: innerFontColor
                                                                            ? innerFontColor
                                                                            : getStrokeColor(),
                                                                    }}>
                                                                    {(
                                                                        (percent / 100) *
                                                                        maxNumber
                                                                    ).toPrecision(2)}{" "}
                                                                    / {maxNumber}
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                );
                                            }}
                                            {...rest}
                                        />
                                    </Tooltip>
                                </ConditionalWrapper>
                            </div>
                        )}
                        {loading && (data === null || data === undefined) && <Skeleton />}
                        {!loading &&
                            (data === null ||
                                data === undefined ||
                                (data !== null && data[scoreFieldName] === null)) && (
                                <div className={classes.emptyContainer}>{emptyText}</div>
                            )}
                    </Spin>
                </>
            </Row>
            <div
                className={
                    descriptionPosition === "bottom"
                        ? classes.scoretextBottom
                        : classes.scoretextRight
                }
                style={{
                    color: descriptionFontColor ? descriptionFontColor : getStrokeColor(),
                    ...descriptionStyle,
                }}>
                {getDescription()}
            </div>
        </div>
    );
};

export { Gauge };
