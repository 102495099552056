import { ICompanyCustomColumn } from "models";
import { useState } from "react";
import Provider from "services/api";

const useCustomColumnsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateCustomColumns = async (customColumns: ICompanyCustomColumn[]) => {
        setLoading(true);
        const id = await Provider.Company.updateCompanyCustomColumns(customColumns, {
            customError: {
                message: "Could not update custom columns",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        updateCustomColumns,
    };
};

export { useCustomColumnsUpdate };
