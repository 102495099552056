import { useEffect, useState } from "react";
import { Provider } from "services";
import { IKpiGraph } from "models";

type StateType = {
    data: IKpiGraph[] | null;
    loading: boolean;
};

const useKpi = (partnerId: number, from: string, to: string, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            const data = await Provider.Partner.getKpiGraph(partnerId, from, to, {
                customError: {
                    message: "Could not retrieve data for kpi charts",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, from, to, reload]);
    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useKpi };
