import { post, get, put, remove } from "../base";
import { ICreateNcrReview, ITableInput } from "models";
import INcr from "models/ncr/INcr";

const Ncr = {
    getOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/Overview`;
        let response = await post({
            url,
            payload: tableInput,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getRegistration: async (id: string, retryCount = 3, { customError }: any = {}) => {
        const url = `/v1/Ncr/${id}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError,
        });
        return response;
    },
    getNcrFields: async ({ customError }: any = {}) => {
        const url = `/v1/Ncr/NcrFields`;
        let response = await get({
            url,
            customError,
        });
        return response;
    },
    updateApprovalStatus: async (
        id: number,
        approvalStatusId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Ncr/${id}/approvalStatusId/${approvalStatusId}`;
        const response = await put({ url, payload: {}, customError });
        return response;
    },
    updateHolder: async (id: number, holderId: number, { customError }: any = {}) => {
        const url = `/v1/Ncr/${id}/ncrHolder/${holderId}`;
        const response = await put({ url, payload: {}, customError });
        return response;
    },
    removeHolder: async (id: number, { customError }: any = {}) => {
        const url = `/v1/Ncr/${id}/removeNcrHolder`;
        const response = await put({ url, payload: {}, customError });
        return response;
    },
    updateNextResponsible: async (
        id: number,
        nextResponsible: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/Ncr/${id}/NextResponsible/${nextResponsible}`;
        const response = await put({ url, payload: {}, customError });
        return response;
    },
    getNcrReasons: async (id = -1, { customError }: any = {}) => {
        const url = `/api/NcrReason/${id}`;
        const response = await post({ url, payload: {}, customError });
        return response;
    },
    createNcr: async (
        ncr: INcr,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr`;
        const ncrId = await post({
            url,
            payload: ncr,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return ncrId;
    },
    updateNcr: async (
        ncrId: number,
        ncr: INcr,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/${ncrId}`;
        const sucess = await put({
            url,
            payload: ncr,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return sucess;
    },
    deleteNcrs: async (
        ncrIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr`;
        const success = await remove({
            url,
            payload: ncrIds,
            retryCount: 3,
            customError: customError ? customError : null,
        });
        return success;
    },
    updateSeachTags: async (id: number, searchTags: string[], { customError }: any = {}) => {
        const url = `/v1/Ncr/${id}/searchTags`;
        const response = await put({ url, payload: searchTags, customError });
        return response;
    },
    closeNcr: async (
        ncrId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/${ncrId}/CloseNcr`;
        const success = await put({ url, payload: {}, retryCount, customError });
        return success;
    },
    openNcr: async (
        ncrId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/${ncrId}/OpenNcr`;
        const success = await put({ url, payload: {}, retryCount, customError });
        return success;
    },
    getNcrReasonsForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/api/NcrReason/ReasonsForFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getContactsForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/ContactsFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getNcrOwnerContacts: async (
        initialContactId: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/OwnerContacts/${initialContactId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getExternalNcrOwnerContacts: async (
        supplierId: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/ExternalOwnerContacts/${supplierId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    updateNcrActionAnswer: async (
        actionId: number,
        answerText: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/answer/${actionId}`;
        let response = await put({
            url,
            payload: { text: answerText.toString() },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    updateNcrActionOwner: async (
        actionId: number,
        ownerId: string,
        ownerType: number | string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/owner/${actionId}/${ownerId}/${ownerType}`;
        let response = await put({
            url,
            payload: {},
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    changeNcrVisibility: async (
        ncrIds: number[],
        visibility: number,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Ncr/ChangeNCRsVisibility?visibility=${visibility}`;
        await post({ url, payload: ncrIds, customError, retryCount });
    },
    createReview: async (review: ICreateNcrReview, { customError }: any = {}) => {
        const url = `/v1/Ncr/CreateReviewOnNcrClosed`;
        const success = await post({ url, payload: review, customError });
        return success;
    },
};

export default Ncr;
