import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, InputNumber } from "antd";
import getFilterIcon from "./getFilterIcon";
import i18n from "i18n";

const isSet = (input: any) => {
    return input !== undefined && input !== null && input.toString().trim() !== "";
};

const getColumnMinMaxProps = (
    min: any = undefined,
    max: any = undefined,
    params: any = { dataIndex: Math.floor(Math.random() * 100), allowDecimals: true }
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
        let searchInput1: any, searchInput2: any;
        return (
            <div className="filterDropDownContainer">
                <div style={{ display: "block", marginBottom: "8px" }}>
                    <InputNumber
                        data-test={`search_range_${params.dataIndex}_min_input`}
                        style={{ marginRight: "8px" }}
                        value={isSet(selectedKeys[0]) ? selectedKeys[0] : undefined}
                        min={min}
                        max={max}
                        precision={
                            params.allowDecimals === undefined || params.allowDecimals ? 2 : 0
                        }
                        placeholder="Min"
                        size="small"
                        ref={node => {
                            searchInput1 = node;
                        }}
                        onChange={(value: any) =>
                            setSelectedKeys([isSet(value) ? value : undefined, selectedKeys[1]])
                        }></InputNumber>
                    <InputNumber
                        data-test={`search_range_${params.dataIndex}_max_input`}
                        value={isSet(selectedKeys[1]) ? selectedKeys[1] : undefined}
                        min={min}
                        max={max}
                        precision={
                            params.allowDecimals === undefined || params.allowDecimals ? 2 : 0
                        }
                        ref={node => {
                            searchInput2 = node;
                        }}
                        placeholder="Max"
                        size="small"
                        onChange={(value: any) =>
                            setSelectedKeys([selectedKeys[0], isSet(value) ? value : undefined])
                        }></InputNumber>
                </div>
                <Button
                    data-test={`search_range_${params.dataIndex}_button`}
                    type="primary"
                    onClick={() => {
                        if (min === undefined && selectedKeys[0] === undefined) {
                            searchInput1.focus();
                        } else if (max === undefined && selectedKeys[1] === undefined) {
                            searchInput2.focus();
                        } else {
                            confirm();
                        }
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {i18n.t("grid_Filter")}
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    {i18n.t("grid_Reset")}
                </Button>
            </div>
        );
    },
    ...getFilterIcon(params),
});

export default getColumnMinMaxProps;
