import React, { useState, useEffect } from "react";
import { OwlerCompanyInfoContainer } from "./owlerCompanyInfoContainer";
import { Col, Row } from "antd";
import { OwlerNewsFeedContainer } from "./owlerNewsFeedContainer";
import { OwlerCompetitorContainer } from "./owlerCompetitorContainer";

interface IProps {
    domain: string | undefined;
    loadingDomain: boolean;
    [key: string]: any;
}

const OwlerContainer = ({ domain, loadingDomain, reloadOwlerDatasources, ...rest }: IProps) => {
    const [reloadDatasources, setReloadDatasources] = useState<any>({ reload: false });
    const [controlledDomain, setControlledDomain] = useState<string | undefined>(domain);

    useEffect(() => {
        setControlledDomain(domain);
    }, [domain]);

    return (
        <Row gutter={16} {...rest}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                <OwlerCompanyInfoContainer
                    domain={controlledDomain}
                    loadingDomain={loadingDomain}
                    onDomainSet={(domain: string | undefined) => {
                        setReloadDatasources({ reload: true });
                        setControlledDomain(domain);
                    }}
                    reloadDatasource={reloadDatasources}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                <OwlerNewsFeedContainer
                    domain={controlledDomain}
                    loadingDomain={loadingDomain}
                    onDomainSet={(domain: string | undefined) => {
                        setReloadDatasources({ reload: true });
                        setControlledDomain(domain);
                    }}
                    reloadDatasource={reloadDatasources}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                <OwlerCompetitorContainer
                    domain={controlledDomain}
                    loadingDomain={loadingDomain}
                    onDomainSet={(domain: string | undefined) => {
                        setReloadDatasources({ reload: true });
                        setControlledDomain(domain);
                    }}
                    reloadDatasource={reloadDatasources}
                />
            </Col>
        </Row>
    );
};

export { OwlerContainer };
