import { useEffect, useState } from "react";
import Provider from "services/api";

const useCommodityCategoriesById = (commodityId: string | undefined) => {
    const [state, setState] = useState<any>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            if (commodityId) {
                let data: any = await Provider.CommodityCategory.getCommodityCategoryById(
                    commodityId,
                    true,
                    {
                        customError: {
                            message: "Could not retrieve commodity categories",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data: data.children });
            } else {
                setState({ loading: false, data: [] });
            }
        })();
    }, [commodityId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCommodityCategoriesById };
