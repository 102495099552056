import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IInvitationAcceptedDetail } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IInvitationAcceptedDetail;
};

const InvitationAccepted: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.activityTitle}>
            {t("congratulation")} <PartnerLink data={data} /> {t("activity_InvitationAccept")}{" "}
            <PartnerLink partnerId={data.partnerId} partnerName={data.invitedPartner} />
        </div>
    );
};

export default InvitationAccepted;
