import React, { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Button, Divider, Tooltip, Form } from "antd";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";
import {
    SelectData,
    SelectDataMultiple,
    FormRow,
    FormLabel,
    X,
} from "components/utilitycomponents";
import {
    useCommodityCategories,
    useCommodityCategoriesById,
    usePartnerList,
    usePartnerDivisions,
    usePartnerRegions,
} from "controller";
import classes from "./selectSupplier.module.scss";
import SelectSupplierChildren from "./selectSupplierChildren";
import { useParams } from "react-router-dom";

type PropTypes = {
    visible: boolean;
    clearData: any;
    onNext: Function;
    onPrevious: Function;
    selectedEntities: any[] | undefined;
};
const SelectSupplier: FunctionComponent<PropTypes> = ({
    visible,
    clearData,
    onNext,
    onPrevious,
    selectedEntities = [],
}) => {
    const { t } = useTranslation();
    const [selectedSupplierSet, setSelectedSupplierSet] = useState<any[]>([]);
    const [addClicked, setAddClicked] = useState<boolean>(false);
    const [selectedParent, setSelectedParent] = useState<any>({});
    const [selectedChildren, setSelectedChildren] = useState<any>([]);
    const [selectedJcc1, setSelectedJcc1] = useState<any>({});
    const [selectedJcc2, setSelectedJcc2] = useState<any>({});
    const [selectedJcc3, setSelectedJcc3] = useState<any>({});
    const [selectedDivision, setSelectedDivision] = useState<any>({});
    const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
    const { data: suppliers, loading: suppliersLoading } = usePartnerList();
    const { partnerId: urlPartnerId }: any = useParams();

    useEffect(() => {
        if (selectedEntities && selectedEntities.length > 0) {
            let supps = selectedEntities.map((entity: any) => ({
                ...entity,
                supplier: { name: entity.partnerName, id: entity.partnerId },
                jcc1: entity.jcC1[0],
                jcc2: entity.jcC2[0],
                jcc3: entity.jcC3[0],
                regions: entity.regions,
                division: entity.divisions[0],
            }));
            setSelectedSupplierSet(supps);
        }
    }, [selectedEntities]);

    useEffect(() => {
        setSelectedParent({});
        setSelectedSupplierSet([]);
        setSelectedChildren([]);
        setSelectedJcc1({});
        setSelectedJcc2({});
        setSelectedJcc3({});
        setSelectedDivision({});
        setSelectedRegions([]);
        setAddClicked(false);
    }, [clearData]);

    useEffect(() => {
        if (!selectedJcc1) {
            setSelectedJcc2({});
        }
    }, [selectedJcc1]);
    useEffect(() => {
        if (!selectedJcc2) {
            setSelectedJcc3({});
        }
    }, [selectedJcc2]);

    useEffect(() => {
        if (urlPartnerId && suppliers) {
            let defaultParent = suppliers.filter(
                (supp: any) => supp.id === parseInt(urlPartnerId)
            )[0];
            setSelectedParent(defaultParent);
        }
    }, [urlPartnerId, suppliers]);

    const handleAddToList = () => {
        setAddClicked(true);
        if (
            (selectedParent && selectedParent.id) ||
            (selectedChildren && selectedChildren.length > 0)
        ) {
            let newSupplierSets: any = [];
            if (selectedChildren.length > 0) {
                newSupplierSets = selectedChildren.map((c: any) => ({
                    key: uuidv4(),
                    supplier: c,
                    jcc1: selectedJcc1,
                    jcc2: selectedJcc2,
                    jcc3: selectedJcc3,
                    division: selectedDivision,
                    regions: selectedRegions,
                }));
            } else if (selectedParent.id) {
                newSupplierSets = [
                    {
                        key: uuidv4(),
                        supplier: selectedParent,
                        jcc1: selectedJcc1,
                        jcc2: selectedJcc2,
                        jcc3: selectedJcc3,
                        division: selectedDivision,
                        regions: selectedRegions,
                    },
                ];
            }
            newSupplierSets = [...selectedSupplierSet, ...newSupplierSets];
            const uniqueSupplierSets = newSupplierSets.filter((value: any, index: number) => {
                const _value = JSON.stringify({ ...value, key: undefined });
                return (
                    newSupplierSets.findIndex((obj: any) => {
                        const _obj = JSON.stringify({ ...obj, key: undefined });
                        return _obj === _value;
                    }) === index
                );
            });
            setSelectedSupplierSet(uniqueSupplierSets);
        }
    };

    const handleRemoveFromList = (key: string) => {
        setSelectedSupplierSet(selectedSupplierSet.filter(set => set.key !== key));
    };

    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return (
        <Row style={{ display: visible ? "block" : "none" }}>
            <Col span={24}>
                <Row style={{ minHeight: `${height - 350}px` }}>
                    <Col span={6}>
                        <div className={classes.title}>{t("survey_wizard_selectSuppliers")}</div>
                        <span className={classes.hint}>
                            {t("survey_wizard_selectSuppliersHint")}
                        </span>
                        <Row>
                            <Col span={24}>
                                <FormLabel>{t("survey_wizard_supplierParentLevel")}</FormLabel>
                                <Form.Item
                                    initialValue={urlPartnerId ? parseInt(urlPartnerId) : undefined}
                                    validateStatus={
                                        addClicked && (!selectedParent || !selectedParent.id)
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        addClicked && (!selectedParent || !selectedParent.id)
                                            ? t("inputIsRequiredError")
                                            : ""
                                    }>
                                    <SelectData
                                        defaultValue={
                                            urlPartnerId ? parseInt(urlPartnerId) : undefined
                                        }
                                        className={classes.selectBox}
                                        value={selectedParent ? selectedParent.id : undefined}
                                        useData={() => ({
                                            data: suppliers,
                                            loading: suppliersLoading,
                                        })}
                                        selectOptionField={(item: any) => ({
                                            text: `${item.name} ${item.number}`,
                                            node: (
                                                <span>
                                                    {item.name} {item.number}
                                                </span>
                                            ),
                                        })}
                                        style={{ width: "100%" }}
                                        placeholder={t("select")}
                                        onChange={(_: any, item: any) => {
                                            setSelectedParent(item);
                                            setAddClicked(false);
                                        }}
                                        allowClear
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <SelectSupplierChildren
                            selectedParent={selectedParent}
                            onChange={(items: any[]) => setSelectedChildren(items)}
                            clearData={clearData}
                        />
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                        <Divider
                            type="vertical"
                            dashed
                            style={{ height: "100%" }}
                            className={classes.verticalSeparator}
                        />
                    </Col>
                    <Col span={6}>
                        <div className={classes.title} style={{ marginBottom: 22 }}>
                            {t("survey_wizard_selectDescriptors")}
                        </div>
                        <Row>
                            <Col span={24}>
                                <FormRow>
                                    <Col span={24}>
                                        <FormLabel>
                                            {t("survey_Wizard_JCC", { number: 1 })}
                                        </FormLabel>
                                        <SelectData
                                            className={classes.selectBox}
                                            value={
                                                selectedJcc1 ? selectedJcc1.internalId : undefined
                                            }
                                            useData={useCommodityCategories.bind(null, {
                                                level: 1,
                                                singleLevel: false,
                                            })}
                                            dropdownStyle={{ width: 300 }}
                                            selectIdField="internalId"
                                            selectOptionField="description"
                                            style={{ width: "100%" }}
                                            placeholder={t("select")}
                                            onChange={(_: any, item: any) => {
                                                setSelectedJcc1(item);
                                                setSelectedJcc2({});
                                            }}
                                            allowClear
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col span={24}>
                                        <FormLabel>
                                            {t("survey_Wizard_JCC", { number: 2 })}
                                        </FormLabel>
                                        <SelectData
                                            className={classes.selectBox}
                                            value={
                                                selectedJcc2 ? selectedJcc2.internalId : undefined
                                            }
                                            useData={useCommodityCategoriesById.bind(
                                                null,
                                                selectedJcc1 ? selectedJcc1.id : undefined
                                            )}
                                            selectIdField="internalId"
                                            selectOptionField="description"
                                            style={{ width: "100%" }}
                                            placeholder={t("select")}
                                            onChange={(_: any, item: any) => {
                                                setSelectedJcc2(item);
                                                setSelectedJcc3({});
                                            }}
                                            allowClear
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col span={24}>
                                        <FormLabel>
                                            {t("survey_Wizard_JCC", { number: 3 })}
                                        </FormLabel>
                                        <SelectData
                                            className={classes.selectBox}
                                            value={
                                                selectedJcc3 ? selectedJcc3.internalId : undefined
                                            }
                                            useData={useCommodityCategoriesById.bind(
                                                null,
                                                selectedJcc2 ? selectedJcc2.id : undefined
                                            )}
                                            selectIdField="internalId"
                                            selectOptionField="description"
                                            style={{ width: "100%" }}
                                            placeholder={t("select")}
                                            onChange={(_: any, item: any) => setSelectedJcc3(item)}
                                            allowClear
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col span={24}>
                                        <FormLabel>{t("survey_Wizard_Division")}</FormLabel>
                                        <SelectData
                                            className={classes.selectBox}
                                            value={
                                                selectedDivision ? selectedDivision.id : undefined
                                            }
                                            useData={usePartnerDivisions}
                                            selectOptionField="name"
                                            style={{ width: "100%" }}
                                            placeholder={t("select")}
                                            onChange={(_: any, item: any) =>
                                                setSelectedDivision(item)
                                            }
                                            allowClear
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col span={24}>
                                        <FormLabel>{t("survey_Wizard_Regions")}</FormLabel>
                                        <SelectDataMultiple
                                            value={
                                                selectedRegions
                                                    ? selectedRegions.map(d => d.id)
                                                    : undefined
                                            }
                                            useData={usePartnerRegions}
                                            selectOptionField="name"
                                            style={{ width: "100%" }}
                                            placeholder={t("select")}
                                            onChange={(_: any, items: any) =>
                                                setSelectedRegions(items)
                                            }
                                            allowClear
                                            selectAll
                                            getPopupContainer={(node: any) =>
                                                node
                                                    ? (node.parentNode as HTMLElement)
                                                    : document.body
                                            }
                                        />
                                    </Col>
                                </FormRow>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                        <Divider
                            type="vertical"
                            dashed
                            style={{ height: "100%" }}
                            className={classes.verticalSeparator}
                        />
                    </Col>

                    <Col span={2} style={{ alignSelf: "center" }}>
                        {selectedSupplierSet.length > 0 &&
                        selectedParent !== undefined &&
                        selectedSupplierSet.filter(
                            (set: any) => set.supplier.id === selectedParent.id
                        ).length > 0 ? (
                            <Tooltip
                                title={
                                    "A survey may only contain one instance of a vendor/supplier at any one time"
                                }>
                                <Button type="primary" onClick={handleAddToList} disabled>
                                    Add To List
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button type="primary" onClick={handleAddToList}>
                                Add To List
                            </Button>
                        )}
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                        <Divider
                            type="vertical"
                            dashed
                            style={{ height: "100%" }}
                            className={classes.verticalSeparator}
                        />
                    </Col>
                    <Col span={7}>
                        <div
                            className={classes.supplierSetContainer}
                            style={{ height: height - 380 }}>
                            {selectedSupplierSet.map(set => (
                                <Row className={classes.supplierSet} key={set.key}>
                                    <Col span={22}>
                                        <>
                                            <div className={classes.bold}>{set.supplier.name}</div>
                                            {set.jcc1 && set.jcc1.description && (
                                                <span className={classes.subItem}>
                                                    <span className={classes.bold}>JCC1: </span>
                                                    {set.jcc1.description}
                                                </span>
                                            )}
                                            {set.jcc2 && set.jcc2.description && (
                                                <span className={classes.subItem}>
                                                    <span className={classes.bold}>JCC2: </span>
                                                    {set.jcc2.description}
                                                </span>
                                            )}
                                            {set.jcc3 && set.jcc3.description && (
                                                <span className={classes.subItem}>
                                                    <span className={classes.bold}>JCC3: </span>
                                                    {set.jcc3.description}
                                                </span>
                                            )}
                                            {set.division && set.division.name && (
                                                <span className={classes.subItem}>
                                                    <span className={classes.bold}>Division: </span>
                                                    {set.division.name}
                                                </span>
                                            )}
                                            {set.regions && set.regions.length > 0 && (
                                                <span className={classes.subItem}>
                                                    <span className={classes.bold}>Regions: </span>
                                                    {set.regions.map((d: any) => d.name).join(",")}
                                                </span>
                                            )}
                                        </>
                                    </Col>
                                    <Col
                                        span={2}
                                        style={{ textAlign: "center", alignSelf: "center" }}>
                                        <Tooltip title="Remove From List">
                                            <span>
                                                <X
                                                    className={classes.removeIcon}
                                                    onClick={() => handleRemoveFromList(set.key)}
                                                />
                                            </span>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <Button type="primary" size="large" onClick={() => onPrevious()}>
                            {t("generic_Previous")}
                        </Button>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => onNext(selectedSupplierSet)}
                            disabled={selectedSupplierSet.length === 0}>
                            {t("generic_Next")}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SelectSupplier;
