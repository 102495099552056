import { useState } from "react";
import { Provider } from "services";

const useReviewCriteriaUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (criteria: any) => {
        setLoading(true);

        const res = await Provider.Review.updateReviewCriteria(criteria, {
            customError: { message: "Could not update review criteria" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        update,
    };
};

export { useReviewCriteriaUpdate };
