import React, { FunctionComponent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridButton } from "components/utilitycomponents";
import DeleteModal from "./deleteModal";
import UploadFilesModal from "./uploadFilesModal";
import CreateFolderModal from "./createFolderModal";
import { DeleteOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";

type PropsType = {
    selectedRowKeys: number[];
    selectedRows: any[];
    onClearSelectedRows: Function;
    onReloadData: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    selectedRowKeys,
    selectedRows,
    onClearSelectedRows,
    onReloadData,
}) => {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState<any>({
        visible: false,
        data: {
            files: [],
            folders: [],
        },
    });
    const [uploadFilesModal, setUploadFilesModal] = useState<any>({
        visible: false,
    });
    const [createFolderModal, setCreateFolderModal] = useState<any>({
        visible: false,
    });

    const handleDelete = async () => {
        let files: any[] = [],
            folders: any[] = [];
        selectedRows.forEach((r: any) => {
            if ("extension" in r) {
                files = [...files, { id: r.id, name: r.name }];
            } else {
                folders = [...folders, { id: r.id, name: r.name }];
            }
        });
        setDeleteModal({ visible: true, data: { files, folders } });
    };

    const deleteButtonVisibility =
        selectedRowKeys.length > 0 || selectedRows.some(s => s.canEdit === true);
    const buttonVisibility = selectedRowKeys.length < 1;
    return (
        <Fragment>
            {buttonVisibility && (
                <>
                    <GridButton
                        data-test="create_folder_button"
                        icon={<PlusCircleOutlined />}
                        iconColor="#52c41a"
                        type="default"
                        onClick={() => setCreateFolderModal({ visible: true })}>
                        {t("file_CreateFolder")}
                    </GridButton>
                    <GridButton
                        data-test="upload_file_button"
                        icon={<UploadOutlined />}
                        iconColor="#0477A9"
                        type="default"
                        onClick={() => setUploadFilesModal({ visible: true })}>
                        {t("file_UploadFiles")}
                    </GridButton>
                </>
            )}

            {deleteButtonVisibility && (
                <GridButton
                    danger
                    data-test="delete_file_button"
                    icon={<DeleteOutlined />}
                    iconColor="#FF4D4F"
                    type="default"
                    onClick={() => handleDelete()}>
                    {t("file_DeleteFile")}
                </GridButton>
            )}

            <CreateFolderModal
                visible={createFolderModal.visible}
                onClose={(reload: boolean = false) => {
                    setCreateFolderModal({ visible: false });
                    if (reload) {
                        onReloadData({ reload: true });
                        onClearSelectedRows([]);
                    }
                }}
            />

            <UploadFilesModal
                visible={uploadFilesModal.visible}
                onClose={(reload: boolean = false) => {
                    setUploadFilesModal({ visible: false });
                    if (reload) {
                        onReloadData({ reload: true });
                        onClearSelectedRows([]);
                    }
                }}
            />

            <DeleteModal
                data={deleteModal.data}
                visible={deleteModal.visible}
                onClose={(reload: boolean = false) => {
                    setDeleteModal({ data: { files: [], folders: [] }, visible: false });
                    if (reload) {
                        onReloadData({ reload: true });
                        onClearSelectedRows([]);
                    }
                }}
            />
        </Fragment>
    );
};

export default OverviewHeader;
