import { useState } from "react";
import Provider from "services/api";

const useContactGroupInUse = () => {
    const [loading, setLoading] = useState(false);

    const isContactGroupNameInUse = async (groupName: string) => {
        setLoading(true);
        const res = await Provider.Contact.isGroupNameDuplicated(groupName, {
            customError: {
                message: "Could not check group name",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    return {
        loading: loading,
        isContactGroupNameInUse,
    };
};

export { useContactGroupInUse };
