import React from "react";
import { useCarbonScore, useSupplierProfileComplianceScore } from "controller";
import { Row, Col, Spin, Divider } from "antd";
import classes from "../summary.module.scss";
import { HeatMap } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";

interface IProps {
    partnerId: number;
}

const HeatMapMetric = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const {
        data: complianceScoreData,
        loading: loadingComplianceScore,
    } = useSupplierProfileComplianceScore(partnerId);
    const { data: carbonScoreData, loading: loadingCarbonScore } = useCarbonScore(partnerId);

    return (
        <div className={classes.container}>
            <Row>
                <Col span={24}>
                    <div className={classes.tableTitle} style={{ marginTop: 4, marginLeft: 8 }}>
                        {t("supplierProfile_summary_MaturityScoreVsCarbonScore").toUpperCase()}
                    </div>
                    <Divider style={{ marginTop: 8 }} />
                </Col>
            </Row>
            <Row style={{ height: 484, paddingTop: 12, paddingBottom: 12 }}>
                <Col push={4} span={18}>
                    <Spin spinning={loadingComplianceScore === true || loadingCarbonScore === true}>
                        <HeatMap
                            xValue={complianceScoreData && complianceScoreData.score}
                            xLabel={t("supplierProfile_summary_MaturityScore")}
                            yValue={carbonScoreData && carbonScoreData.score}
                            yLabel={t("supplierProfile_summary_CarbonContribution")}
                        />
                    </Spin>
                </Col>
            </Row>
        </div>
    );
};
export { HeatMapMetric };
