import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useExternalContacts = (supplierId: string, reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (supplierId !== undefined) {
                let data = await Provider.Contact.GetExtneralContacts(supplierId);
                data = data.map((contact: IContact) => ({
                    ...contact,
                    name: `${contact.firstName} ${contact.lastName}`,
                }));
                setState({ loading: false, data });
            }
        })();
    }, [supplierId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useExternalContacts };
