import React, { useState, Fragment, useEffect } from "react";
import { Modal, Row, Col, Button, Divider, Tooltip, Typography, Popconfirm, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import _ from "lodash";
import { Eye, EyeOff, HelpCircle, Settings01 } from "../icons";
const Text = Typography.Text;

type PropTypes = {
    columns: any[];
    hiddenColumns: string[];
    excludedColumns?: string[];
    hideColumnSetting: boolean;
    tableSize: any;
    onSave: Function;
    onSetSettingsForAllUser: Function;
    onResetColumns: Function;
};

const GridSetting: React.FC<PropTypes> = ({
    columns,
    hiddenColumns,
    excludedColumns = [],
    tableSize,
    hideColumnSetting = false,
    onSave,
    onSetSettingsForAllUser,
    onResetColumns,
}) => {
    const [visible, setVisible] = useState(false);
    const [newHiddenColumns, setNewHiddenColumns] = useState<string[] | any[]>(hiddenColumns);
    const [newTableSize, setNewTableSize] = useState<any>(tableSize);
    const [showHideError, setShowHideError] = useState(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    useEffect(() => {
        setNewHiddenColumns(hiddenColumns);
        setNewTableSize(tableSize);
    }, [hiddenColumns, tableSize]);
    const disableButtonHandler = () => {
        if (newHiddenColumns.length > 0) {
            setDisableButton(false);
        } else if (newHiddenColumns.length === 0) {
            setDisableButton(true);
        }
    };
    const setErrorMessageHandler = () => {
        if (newHiddenColumns.length < columns.length - excludedColumns.length) {
            setShowHideError(false);
        } else {
            setShowHideError(true);
        }
    };
    useEffect(() => {
        setErrorMessageHandler();
        disableButtonHandler();
    }, [hiddenColumns, tableSize, newHiddenColumns]);

    const handleIconClick = (enable: boolean, dataIndex: any) => {
        if (enable === false) {
            if (newHiddenColumns.length < columns.length - excludedColumns.length - 1) {
                setNewHiddenColumns([...newHiddenColumns, dataIndex]);
                setShowHideError(false);
                setShowHideError(false);
            } else {
                setShowHideError(true);
            }
        } else {
            setNewHiddenColumns(newHiddenColumns.filter(title => title !== dataIndex));
        }
    };
    const handleSave = async () => {
        onSave(newHiddenColumns, newTableSize);
        setShowHideError(false);
        setVisible(false);
    };
    const handleAddTableSettingToAllUsers = async () => {
        onSetSettingsForAllUser(newHiddenColumns, newTableSize);
        setShowHideError(false);
        setVisible(false);
    };
    const handleResetColumns = () => {
        setLoadingTable(true);
        onResetColumns();
        setShowHideError(false);
        setTimeout(() => {
            setLoadingTable(false);
        }, 3000);
    };

    const handleCancel = () => {
        setNewHiddenColumns(hiddenColumns);
        setNewTableSize(tableSize);
        setShowHideError(false);
        setVisible(false);
    };

    const onCheckAllClick = () => {
        if (newHiddenColumns.length > 1) {
            setNewHiddenColumns([]);
        } else {
            setNewHiddenColumns(
                columns
                    .filter(x => !excludedColumns.includes(x.dataIndex))
                    .sort((a: any, b: any) => (a.title < b.title ? 1 : -1))
                    .map(x => x.dataIndex)
                    .splice(
                        0,
                        columns.filter(c => c.operationColumn !== true).length -
                            excludedColumns.length -
                            1
                    )
            );
        }
        setShowHideError(false);
    };

    return (
        <Fragment>
            <Button icon={<Settings01 />} className="setting" onClick={() => setVisible(true)} />
            <Modal
                title={t("tableSettingsTitle")}
                width={"80vmin"}
                onCancel={handleCancel}
                footer={[
                    <Button key="ok" type="primary" onClick={handleSave}>
                        {t("generic_Save")}
                    </Button>,
                ]}
                open={visible}>
                <Fragment>
                    <Spin spinning={loadingTable}>
                        {!hideColumnSetting && (
                            <Fragment>
                                <Row>
                                    <Text strong={true}>
                                        {t("SelectColumnsTitle")}
                                        <Tooltip title={t("selectAllColumnsTooltip")}>
                                            <span style={{ marginLeft: "6px" }}>
                                                <HelpCircle />
                                            </span>
                                        </Tooltip>
                                    </Text>
                                </Row>
                                <Row style={{ marginTop: "8px", marginBottom: "8px" }}>
                                    <Button type="text" onClick={() => onCheckAllClick()}>
                                        {t("selectAllColumns")}
                                    </Button>
                                </Row>
                                <Row>
                                    {columns
                                        .slice(0)
                                        .sort((a: any, b: any) => (a.title > b.title ? 1 : -1))
                                        .map((x: any, index: number) => {
                                            if (
                                                !excludedColumns.includes(x.dataIndex) &&
                                                x.operationColumn !== true
                                            ) {
                                                return (
                                                    <Col span={8} key={index}>
                                                        {!newHiddenColumns.includes(x.dataIndex) ? (
                                                            <Button
                                                                type="text"
                                                                key={x.title}
                                                                onClick={(e: any) =>
                                                                    handleIconClick(
                                                                        false,
                                                                        x.dataIndex
                                                                    )
                                                                }
                                                                icon={<Eye />}>
                                                                <span title={x.title}>
                                                                    {_.truncate(x.title, {
                                                                        length: 24,
                                                                        separator: "...",
                                                                    })}
                                                                </span>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                type="text"
                                                                key={x.title}
                                                                onClick={(e: any) =>
                                                                    handleIconClick(
                                                                        true,
                                                                        x.dataIndex
                                                                    )
                                                                }
                                                                icon={<EyeOff />}>
                                                                <span title={x.title}>
                                                                    {_.truncate(x.title, {
                                                                        length: 24,
                                                                        separator: "...",
                                                                    })}
                                                                </span>
                                                            </Button>
                                                        )}
                                                    </Col>
                                                );
                                            }
                                            return null;
                                        })}
                                </Row>
                                <Row>
                                    {showHideError && (
                                        <Text
                                            style={{ display: "block", marginTop: "5px" }}
                                            type="danger">
                                            {t("selectAllColumnsErrorText")}
                                        </Text>
                                    )}
                                </Row>

                                <Divider type={"horizontal"} />
                            </Fragment>
                        )}
                    </Spin>

                    <Row>
                        {userType === "administrator" && (
                            <Popconfirm
                                placement="top"
                                title={t("setForAllUsersTableSettingsConfirm")}
                                okText={t("generic_Yes")}
                                cancelText={t("generic_No")}
                                onConfirm={handleAddTableSettingToAllUsers}>
                                <Button key="setForAllUsers" style={{ marginRight: 5 }}>
                                    {t("setSettingsForAllUsersBtnText")}
                                </Button>
                            </Popconfirm>
                        )}
                        <Button key="reset" onClick={handleResetColumns} disabled={disableButton}>
                            {t("resetColumnsBtnText")}
                        </Button>
                        <Tooltip title={t("resetColumnsToolTip")}>
                            <span style={{ margin: "0 6px" }}>
                                <HelpCircle />
                            </span>
                        </Tooltip>
                    </Row>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default GridSetting;
