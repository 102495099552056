import React from "react";
import { GridLight } from "components/utilitycomponents";
import { useSupplierPortalSelfAssessmentRequests } from "controller";
import { getDomainUrl } from "utilities";
import dayjs from "utilities/daysJsConfig";
import classes from "./supplierPortal.module.scss";
import { Flex, Tag } from "antd";

const SelfAssessmentRequests = () => {
    const { data, loading } = useSupplierPortalSelfAssessmentRequests();

    const columns = [
        {
            title: "Title",
            dataIndex: "templateTitle",
            key: "title",
        },
        {
            title: "Requested",
            dataIndex: "requestDate",
            key: "deadline",
            render: (val: string) => <div>{dayjs(val).format("MMM D, YYYY (HH:mm)")}</div>,
        },
        {
            title: "Url",
            dataIndex: "url",
            key: "url",
            render: (val: string, record: any) => (
                <Flex>
                    <a href={`${getDomainUrl()}${val}`}>Submit Self Assessment</a>
                    {record && record.isDraft === true && (
                        <Tag style={{ marginLeft: 8 }} color="#ffa940">
                            Draft Submitted
                        </Tag>
                    )}
                </Flex>
            ),
        },
    ];

    return (
        <div className={classes.tableContainer}>
            <GridLight
                title={() => <div className={classes.tableTitle}>Self Assessment Requests</div>}
                rowKey={"url"}
                columns={columns}
                dataSource={data}
                pagination="none"
                scroll={{ y: 500 }}
                loading={loading}
            />
        </div>
    );
};

export { SelfAssessmentRequests };
