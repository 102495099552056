import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: any[];
    loading: boolean;
};

const useDefaultContacts = (
    partnerId: number | undefined,
    type: "NcrContact" | "ComplianceContact",
    reload: any = null
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (partnerId) {
                let data: any = await Provider.Contact.getPartnerContactsByModuleType(
                    partnerId,
                    type
                );
                data = data.map((contact: IContact) => ({
                    ...contact,
                    name: `${contact.firstName} ${contact.lastName}`,
                }));

                setState({ loading: false, data });
            }
        })();
    }, [type, partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDefaultContacts };
