import { useState } from "react";
import Provider from "services/api";

const useGovernanceTaskCRUD = () => {
    const [loading, setLoading] = useState(false);

    const create = async (input: any) => {
        setLoading(true);
        const id = await Provider.Governance.createGovernanceTask(input, {
            customError: {
                message: "Could not create new task",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const update = async (id: number, input: any) => {
        setLoading(true);
        await Provider.Governance.updateGovernanceTask(input, id, {
            customError: {
                message: "Could not update the task",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const setStatus = async (input: any) => {
        setLoading(true);
        await Provider.Governance.setGovernanceTaskStatus(input, {
            customError: {
                message: "Could not set the task status",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (ids: number[]) => {
        setLoading(true);
        const id = await Provider.Governance.deleteGovernanceTasks(ids, {
            customError: {
                message: "Could not delete tasks",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        createTask: create,
        updateTask: update,
        deleteTasks: remove,
        setStatus: setStatus,
    };
};

export { useGovernanceTaskCRUD };
