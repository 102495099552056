import React, { Children, cloneElement, forwardRef, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type PropsType = {
    value?: string;
    onChange?: (value: string) => void;
    maxChars: number;
    children?: any;
    [key: string]: any;
};

//forwardRef is used for the component to be able to be used as a FormItem child directly
const CountDownChars = forwardRef(
    ({ value, onChange, maxChars, children, ...rest }: PropsType, ref) => {
        const { t } = useTranslation();
        const [newMessage, setNewMessage] = useState<any>(value ? value : "");

        useEffect(() => {
            setNewMessage(value ? value : "");
        }, [value]);

        const handleInputChange = (e: any) => {
            setNewMessage(e.target.value.substring(0, maxChars));
            onChange && onChange(e.target.value);
        };

        return (
            <Fragment>
                {cloneElement(Children.only(children), { ref, value, onChange: handleInputChange })}
                <div
                    {...(rest.style
                        ? { style: rest.style }
                        : { style: { paddingTop: "5px", lineHeight: "5px" } })}
                    {...(rest.className ? { className: rest.className } : { className: "" })}>
                    {maxChars - newMessage.length} {t("activity_CommentRemainingChars")}
                </div>
            </Fragment>
        );
    }
);

export { CountDownChars };
