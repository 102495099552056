import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useReportUpdate = () => {
    const [loading, SetLoading] = useState(false);

    const updateReport = async (reportId: number, updateData: any) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.updateReport(reportId, updateData).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
        return response;
    };

    return {
        loading,
        updateReport,
    };
};

export { useReportUpdate };
