import { useState } from "react";
import { Provider } from "services";

const usePartnerFileUpload = () => {
    const [loading, setLoading] = useState(false);

    const uploadPartnerFile = async (
        fileId: number,
        overwriteIfBlank: boolean,
        baseCurrency?: string
    ) => {
        setLoading(true);
        const id = await Provider.Partner.uploadPartnerFile(
            fileId,
            overwriteIfBlank,
            baseCurrency,
            {
                customError: {
                    message: "Could not upload partner file",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        uploadPartnerFile,
    };
};

export { usePartnerFileUpload };
