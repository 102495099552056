import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useExternalPartnerContacts = (
    supplierId: number,
    reload: any,
    includeChildSupplierContacts: boolean = false
) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (supplierId !== undefined && supplierId !== null) {
                setState({ loading: true, data: [] });
                let data = await Provider.Contact.getExternalPartnerContacts(
                    supplierId,
                    includeChildSupplierContacts
                );

                setState({ loading: false, data });
            }
        })();
    }, [supplierId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useExternalPartnerContacts };
