import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    GlobalOutlined,
    MonitorOutlined,
    ProfileOutlined,
    RadarChartOutlined,
} from "@ant-design/icons";
import { Row, Col, Card, Divider } from "antd";
import Meeting from "./meeting/meeting";
import Role from "./role/role";
import IdCard from "./idCard/IdCard";
import EditableCard from "./editableCard";
import { ISummaryCardsPartner } from "models";
import { useCompanyType } from "controller";

type PropsType = {
    data: ISummaryCardsPartner;
};

const StrategyAndGovernance: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();
    return (
        <Fragment>
            <Row gutter={16}>
                <Col lg={12} xs={24}>
                    <IdCard
                        partnerId={data.partnerId}
                        partnerName={data.partnerName}
                        data={data.companyCard}
                    />
                    <div style={{ marginTop: "10px" }}>
                        <EditableCard
                            icon={<ProfileOutlined />}
                            title={t("supplierProfile_CompanyBusinessProfile", {
                                companyType: isDefaultCompany
                                    ? t("company_Type_Supplier")
                                    : t("company_Type_Customer"),
                            })}
                            data={data.supplierBusinessProfile}
                            partnerId={data.partnerId}
                            textParameterName="supplierBusinessProfile"
                            fileParameterName="supplierBusinessProfileFileId"
                        />
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    <div>
                        <EditableCard
                            icon={<MonitorOutlined />}
                            title={t("supplierProfile_MainCompetitorAndMarketInsight")}
                            data={data.mainCompetitorsAndMarketInsights}
                            partnerId={data.partnerId}
                            textParameterName="mainCompetitorsAndMarketInsights"
                            fileParameterName="mainBusinessFileId"
                        />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <EditableCard
                            icon={<RadarChartOutlined />}
                            title={t("supplierProfile_BusinessRelationshipOverview")}
                            data={data.businessRelationshipOverview}
                            partnerId={data.partnerId}
                            textParameterName="businessRelationshipOverview"
                            fileParameterName="businessRelationshipFileId"
                        />
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Card
                    headStyle={{ background: "#fafafa" }}
                    title={
                        <Fragment>
                            <GlobalOutlined style={{ marginRight: "5px", color: "#0477a9" }} />
                            {t("supplierProfile_StrategyTab")}
                        </Fragment>
                    }>
                    <Row gutter={16}>
                        <Col lg={10} xs={24}>
                            <div>
                                <EditableCard
                                    title={t("supplierProfile_CompanyCriticality", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    })}
                                    data={data.supplierCriticality}
                                    partnerId={data.partnerId}
                                    textParameterName="supplierCriticality"
                                    fileParameterName="supplierCriticalityFileId"
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <EditableCard
                                    title={t("supplierProfile_StrategicPotential")}
                                    data={data.strategicPotential}
                                    partnerId={data.partnerId}
                                    textParameterName="strategicPotential"
                                    fileParameterName="strategicPotentialFileId"
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <EditableCard
                                    title={t("supplierProfile_CompanyStrategyTowardsUs", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    })}
                                    data={data.suppliersStrategyTowardUs}
                                    partnerId={data.partnerId}
                                    textParameterName="suppliersStrategyTowardUs"
                                    fileParameterName="suppliersStrategyTowardUsFileId"
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <EditableCard
                                    title={t("supplierProfile_CompanyStrategy", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    })}
                                    data={data.supplierStrategy}
                                    partnerId={data.partnerId}
                                    textParameterName="supplierStrategy"
                                    fileParameterName="supplierStrategyFileId"
                                />
                            </div>
                        </Col>
                        <Col lg={14} xs={24}>
                            <Meeting partnerId={data.partnerId} data={data.governances} />
                            <div style={{ marginTop: "10px" }}>
                                <Role partnerId={data.partnerId} data={data.contacts} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </Fragment>
    );
};

export default StrategyAndGovernance;
