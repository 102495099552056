import React, { FunctionComponent, useState, useEffect } from "react";
import { Table } from "antd";
import { getTableSize } from "./analyticsGridUtilities";
import classes from "./styles/analyticsGrid.module.scss";
import { useTranslation } from "react-i18next";
import { translateSubLevelHeadline } from "../../../dashboardUtils/subLevelTranslationHelper";

type Props = {
    parentId: any;
    reloadData?: Object | null;
    useData: Function;
    reportObject: any;
    columnNames: { targetName: string; subLevelName: string };
    [key: string]: any;
};

export const AnalyticsGrid: FunctionComponent<Props> = ({
    parentId,
    reloadData = null,
    useData,
    reportObject,
    columnNames,
    ...rest
}) => {
    const {
        pageSize: defaultPageSize,
        yScrollHeight,
        titleExcess,
        descriptionExcess,
    } = getTableSize(parentId);
    const [pageNumber, setPageNumber] = useState(1);
    const { t } = useTranslation();
    const { data: datasource, filter } = reportObject;

    const columns = [
        {
            title: t("analytics_CompanyName"),
            dataIndex: "companyName",
            key: "CompanyName",
            width: 100,
            sorter: (a: any, b: any) => {
                let nameA = a.companyName;
                let nameB = b.companyName;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            },
        },
        {
            title: t("analytics_Month"),
            dataIndex: "month",
            key: "Month",
            width: 100,
            sorter: (a: any, b: any) => {
                let monthA = a.month;
                let monthB = b.month;
                if (monthA < monthB) {
                    return -1;
                }
                if (monthA > monthB) {
                    return 1;
                }
            },
        },
        {
            title: t("analytics_Year"),
            dataIndex: "year",
            key: "Year",
            width: 100,
            sorter: (a: any, b: any) => {
                let valueA = a.year;
                let valueB = b.year;
                return valueA - valueB;
            },
        },
        {
            title: translateSubLevelHeadline(
                reportObject.filter.dataRelation.subLevel.subLevelHeadline
            ),
            dataIndex: "subLevel",
            key: "SubLevel",
            width: 100,
            sorter: (a: any, b: any) => {
                let nameA = a.subLevel;
                let nameB = b.subLevel;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            },
            render: (value: any) => (
                <div>{renderSubLevelColumn(reportObject.filter.areaType, value)}</div>
            ),
        },
        {
            title: t("analytics_KpiValue"),
            dataIndex: "kpiValue",
            key: "KpiValue",
            width: 100,
            sorter: (a: any, b: any) => {
                let valueA = a.kpiValue;
                let valueB = b.kpiValue;
                return valueA - valueB;
            },
            render: (value: any) => (
                <div>{value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}</div>
            ),
        },
        {
            title: columnNames.targetName,
            dataIndex: "targetValue",
            key: "TargetValue",
            width: 100,
            sorter: (a: any, b: any) => {
                let valueA = a.targetValue;
                let valueB = b.targetValue;
                return valueA - valueB;
            },
            render: (value: any) => (
                <div>{value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}</div>
            ),
        },
        {
            title: t("analytics_AllPartnersValue"),
            dataIndex: "allExternalPartnersValue",
            key: "AllExternalPartnersValue",
            width: 100,
            sorter: (a: any, b: any) => {
                let valueA = a.allExternalPartnersValue;
                let valueB = b.allExternalPartnersValue;
                return valueA - valueB;
            },
        },
        {
            title: t("analytics_Visibility"),
            dataIndex: "visibility",
            key: "Visibility",
            width: 100,
            render: (value: any) => (value === 0 ? t("internal") : t("external")),
            sorter: (a: any, b: any) => {
                let nameA = a.visibility;
                let nameB = b.visibility;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            },
        },
    ];

    const [visibleColumns, setVisibleColumns] = useState<any[]>([]);

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPageNumber(pagination.current);
    };

    const renderSubLevelColumn = (areaType: string, value: string) => {
        switch (areaType) {
            case "NCR":
            case "Review":
                return t(value);
            default:
                return value;
        }
    };

    useEffect(() => {
        let visibleColumnNames = filter.enabledStandardColumns;
        let enabledCustomColumns = filter.enabledCustomColumns.filter(
            (x: any) => x.active === true
        );
        let visibleCustomColumns = enabledCustomColumns.map((x: any) => ({
            title: x.name,
            dataIndex: x.name,
            key: `customColumn${x.customColumnId}`,
            width: 100,
        }));
        setVisibleColumns(
            columns.filter(x => visibleColumnNames.includes(x.key)).concat(visibleCustomColumns)
        );
    }, [filter.enabledStandardColumns, filter.enabledCustomColumns]);

    return datasource ? (
        <Table
            className={classes.dataTable}
            columns={visibleColumns}
            dataSource={datasource.records}
            pagination={{
                size: "small",
                pageSize: defaultPageSize,
                current: pageNumber,
                total: datasource.records.length,
                showTotal: (total: number, range: any) => (
                    <span>
                        {t("tableTotalItems", {
                            range0: range[0],
                            range1: range[1],
                            total: total,
                        })}
                    </span>
                ),
                locale: { prev_page: t("grid_PrevPage"), next_page: t("grid_NextPage") },
            }}
            rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            size="small"
            scroll={{ x: true, y: yScrollHeight - titleExcess - descriptionExcess }}
            rowKey="id"
            onChange={handleTableChange}
            {...rest}
        />
    ) : (
        <Table className={classes.dataTable} dataSource={[]} columns={[]} />
    );
};
