import React, { FunctionComponent } from "react";
import { Modal, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useReviewCreate } from "controller";
import CreateForm from "./createForm";

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    reloadTable?: Function;
    partnerId?: any;
    disableCriteriaSelection?: boolean;
}

const CreationModal: FunctionComponent<IProps> = ({
    visible,
    onVisibleChange,
    reloadTable,
    partnerId,
    disableCriteriaSelection = false,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { createReview, loading } = useReviewCreate();

    return (
        <Modal
            wrapProps={{ "data-test": "create_review_modal" }}
            title={t("review_CreateReview")}
            open={visible}
            onCancel={() => {
                onVisibleChange(false);
                reloadTable && reloadTable({ reload: true });
            }}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => form.submit()}>
                    {t("generic_Create")}
                </Button>,
            ]}
            onOk={() => form.submit()}
            width={900}
            style={{ top: 20 }}
            destroyOnClose>
            <CreateForm
                onSubmit={() => {
                    onVisibleChange(false);
                    reloadTable && reloadTable({ reload: true });
                }}
                createReview={createReview}
                form={form}
                partnerId={partnerId}
                disableCriteriaSelection={disableCriteriaSelection}
            />
        </Modal>
    );
};

export default CreationModal;
