import React, { useState } from "react";
import { SegmentationChart } from "./2x2Chart/segmentationChart";
import { SegmentationInfo } from "./segmentInfo/segmentationInfo";
import UnitOfAnalysisOverview from "./unitOfAnalysis/overview";
import classes from "./segmentation.module.scss";
import classnames from "classnames";
import { Affix, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface PropTypes {
    partnerId: number;
}

const Segmentation = ({ partnerId }: PropTypes) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState({ reload: false });
    const [chartData, setChartData] = useState<any[]>([]);
    const [accumulatedValues, setAccumulatedValues] = useState<any>({
        xAverage: undefined,
        yAverage: undefined,
        suggestedSegment: undefined,
    });
    const user = useSelector((state: AppState) => state.oidc.user);
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;
    const [globalSegment, setGlobalSegment] = useState<any>(undefined);

    return (
        <>
            <div className={classes.topContainer}>
                <div id="2x2Container" className={classnames(classes.topLeft)}>
                    <SegmentationChart chartData={chartData} globalSegment={globalSegment} />
                </div>
                <div className={classnames(classes.topRight)}>
                    <SegmentationInfo
                        reload={reload}
                        partnerId={partnerId}
                        accumulatedValues={accumulatedValues}
                        onAlign={() => setReload({ reload: true })}
                        onInfoLoaded={(data: any) => {
                            setGlobalSegment(data);
                        }}
                    />
                </div>
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.bottomMiddle}>
                    <UnitOfAnalysisOverview
                        partnerId={partnerId}
                        onParentAlign={() => setReload({ reload: true })}
                        onShowInChart={(val: any[]) => setChartData(val)}
                        onAccumulatedValues={(accumulatedValues: any) =>
                            setAccumulatedValues(accumulatedValues)
                        }
                        reloadTable={reload}
                    />
                </div>
            </div>
            {hasReviewAccess === true && (
                <Affix
                    style={{ position: "fixed", bottom: 10, right: 30 }}
                    className={classes.affix}>
                    <Button shape="round" className={classes.createAssessmentBtn}>
                        <Link to={`/Reviews/SurveyOverview/Create/Type/2/${partnerId}`}>
                            <div>{t("survey_CreateAssessment")}</div>
                        </Link>
                    </Button>
                </Affix>
            )}
        </>
    );
};

export { Segmentation };
