import React, { FunctionComponent } from "react";
import { Row, Col, Collapse } from "antd";
import classes from "./supplierInformation.module.scss";
import { useTranslation } from "react-i18next";
import { CaretRightOutlined } from "@ant-design/icons";
import { SupplierDomain } from "./supplierDomain";
const { Panel } = Collapse;

interface IProps {
    data: any;
    loading: boolean;
    onDomainSet: Function;
    partnerId: number;
}

const SupplierAddress: FunctionComponent<IProps> = ({ data, loading, onDomainSet, partnerId }) => {
    const { t } = useTranslation();

    const textRenderer = (text: any) => {
        if (text !== undefined && text !== null) {
            return text;
        } else {
            return "";
        }
    };

    return (
        <div>
            <Row>
                <div className={classes.headline}>{t("supplierCards").toLocaleUpperCase()}</div>
            </Row>

            <SupplierDomain
                partnerId={partnerId}
                propsDomain={data && data.domain}
                onDomainChange={() => onDomainSet()}
            />
            <Collapse
                bordered={false}
                ghost={true}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                        className={classes.collapseIcon}
                        rotate={isActive ? 90 : 0}
                    />
                )}>
                <Panel
                    key={"1"}
                    header={
                        <div className={classes.headline}>
                            {t("supplierProfile_Summary_GeneralInfo").toLocaleUpperCase()}
                        </div>
                    }
                    className={classes.panelHeader}>
                    <Row>
                        <Col>
                            <Row>
                                <div className={classes.headline}>
                                    {t("governance_SupplierNumber")}
                                </div>
                            </Row>
                            <Row>
                                <div className={classes.smallFatText}>
                                    {!loading && textRenderer(data.supplierNumber)}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <div className={classes.headline}>{t("contact_address")}</div>
                            </Row>
                            <Row>
                                <div className={classes.text}>
                                    {!loading &&
                                        textRenderer(data.street) +
                                            " " +
                                            textRenderer(data.streetNumber)}
                                </div>
                            </Row>
                            <Row>
                                <div className={classes.text}>
                                    {!loading &&
                                        textRenderer(data.city) +
                                            " " +
                                            textRenderer(data.zipCode) +
                                            " " +
                                            textRenderer(data.region)}
                                </div>
                            </Row>
                            <Row>
                                <div className={classes.text}>
                                    {!loading && textRenderer(data.country)}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
};

export { SupplierAddress };
