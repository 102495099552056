import { useState } from "react";
import { Provider } from "services";

const useCategoryUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async ({ categoryId, newName, type, customHeadline }: any) => {
        setLoading(true);
        await Provider.Partner.updateCategory(
            {
                categoryId: categoryId,
                newName: newName,
                type: type,
            },
            {
                customError: { message: `Could not update ${customHeadline}` },
            }
        );
        setLoading(false);
    };

    return {
        loading,
        update,
    };
};

export { useCategoryUpdate };
