import { post, get, put } from "../base";
import { ITableInput, Modules } from "models";

const Contact = {
    contactOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Contacts/Overview";
        return await post({ url, payload: tableInput, customError });
    },
    createContact: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/Contacts/CreateContact";
        return await post({ url, payload, customError });
    },
    updateContact: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/Contacts/UpdateContact";
        return await post({ url, payload, customError });
    },
    deleteContact: async (ids: number[], { customError }: any = {}) => {
        const url = "/v1/Contacts/DeleteContacts";
        return await post({ url, payload: ids, customError });
    },
    assignContactsToGroups: async (
        contactIds: number[],
        groupIds: number[],
        { customError }: any = {}
    ) => {
        const url = "/v1/Contacts/AssignContactsToGroups";
        return await post({ url, payload: { contactGroupIds: groupIds, contactIds }, customError });
    },
    deleteContactsFromGroups: async (
        contactIds: number[],
        groupIds: number[],
        { customError }: any = {}
    ) => {
        const url = "/v1/Contacts/DeleteContactsFromContactGroups";
        return await post({ url, payload: { contactGroupIds: groupIds, contactIds }, customError });
    },
    getCountries: async ({ customError }: any = {}) => {
        const url = "/v1/Contacts/GetCountries";
        return await get({ url, customError });
    },
    getContactUserTypes: async ({ customError }: any = {}) => {
        const url = "/v1/Contacts/GetContactUserTypes";
        return await get({ url, customError });
    },
    contactGroupOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Contacts/ContactGroupOverview";
        return await post({ url, payload: tableInput, customError });
    },
    createGroup: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/Contacts/CreateContactGroup";
        return await post({ url, payload, customError });
    },
    updateGroup: async (payload: any, { customError }: any = {}) => {
        const url = "/v1/Contacts/UpdateContactGroup";
        return await post({ url, payload, customError });
    },
    deleteGroup: async (ids: number[], { customError }: any = {}) => {
        const url = "/v1/Contacts/DeleteContactGroup";
        return await post({ url, payload: ids, customError });
    },
    isGroupNameDuplicated: async (groupName: string, { customError }: any = {}) => {
        const url = `/v1/Contacts/IsGroupNameDuplicated?newGroupName=${groupName}`;
        return await post({ url, customError });
    },
    updateContactsVisibility: async (
        visibility: number,
        contactIds: number[],
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/UpdateContactsVisibility?visibility=${visibility}`;
        return await put({ url, payload: contactIds, customError });
    },
    GetSurveyFilterContacts: async (surveyId: number, { customError }: any = {}) => {
        const url = `/v1/Contacts/GetSurveyContactFilterOptions/${surveyId}`;
        return await get({ url, customError });
    },
    isContactEmailInUse: async (email: string, { customError }: any = {}) => {
        const url = `/v1/Contacts/IsContactEmailInUse?email=${encodeURIComponent(email)}`;
        return await get({ url, customError });
    },
    getContactsByEmail: async (email: string, exclusionId?: number, { customError }: any = {}) => {
        let url = `/v1/Contacts/GetContactsViaEmail?email=${encodeURIComponent(email)}`;
        if (exclusionId !== undefined) {
            url += `&exclusionId=${exclusionId}`;
        }
        return await get({ url, customError });
    },
    GetInternalAndExternalContacts: async (supplierId: string, { customError }: any = {}) => {
        let url = "";
        if (supplierId) {
            url = `/v1/Contacts/GetInternalAndExternalContacts/${supplierId}`;
        } else {
            url = `/v1/Contacts/GetInternalAndExternalContacts/-1`;
        }
        return await get({ url, customError });
    },
    GetExtneralContacts: async (supplierId: string, { customError }: any = {}) => {
        const url = `/v1/Contacts/GetExternalContacts?supplierId=${supplierId}`;
        return await get({ url, customError });
    },
    getExternalPartnerContacts: async (
        supplierId: number,
        includeChildSupplierContacts: boolean = false,
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/GetExternalPartnerContacts/${supplierId}?includeChildSupplierContacts=${includeChildSupplierContacts}`;
        return await get({ url, customError });
    },
    getInternalContactsWithUser: async (accessTo: Modules, { customError }: any = {}) => {
        const url = `/v1/Contacts/GetInternalContactsWithUser/${accessTo}`;
        return await get({ url, customError });
    },
    getPartnerContactsByModuleType: async (
        partnerId: number,
        type: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Contacts/GetPartnerContactsByModuleType?partnerId=${partnerId}&type=${type}`;
        return await get({ url, customError });
    },
    getAvatarData: async (contactId: number | null, { customError }: any = {}) => {
        const url = `/v1/Contacts/${contactId}/avatar`;
        return await get({ url, customError });
    },
};

export default Contact;
