import React, { useState, useEffect } from "react";
import { Modal, Tag, Alert, Collapse, notification, Spin, Form, Button } from "antd";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import { useTranslation } from "react-i18next";
import { LinkStatusType } from "models/suppliers/LinkStatusType";
import { useCompanyType, useUpdateMergeSet } from "controller";
import { useIsValidMerge } from "controller/supplier/useIsValidMerge";
const Panel = Collapse.Panel;

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    selectedRowKeys: string[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    clearSelectedRows(): void;
    updateTable(): void;
    mergeSetParent: IMasterDataPartner;
}

export const ContentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };

    const [parent, setParent] = useState<IMasterDataPartner>();
    const [childrenIds, setChildrenIds] = useState<number[]>();
    const [validMerge, setValidMerge] = useState<boolean>(false);
    const { updateMergeSet, loading: updateMergeSetLoading } = useUpdateMergeSet();
    const { isValidMerge, loading: isValidMergeLoading } = useIsValidMerge();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        (async () => {
            if (props.visible) {
                setParent(props.mergeSetParent);
                let existingChildrenIds: number[] = props.mergeSetParent!.children!.map(
                    x => x.id.value
                );
                let newChildrenIds: number[] = props.selectedRows
                    .filter(x => x.hasChildren === false)
                    .map((row: IMasterDataPartner) => {
                        return row.id.value;
                    });
                setChildrenIds(existingChildrenIds.concat(newChildrenIds));
                const res = await isValidMerge(
                    props.mergeSetParent.id.value,
                    existingChildrenIds.concat(newChildrenIds)
                );
                setValidMerge(res === false ? false : true);
            }
        })();
    }, [props.visible, props.selectedRows]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: IMasterDataPartner) => {
            const color =
                row.linked &&
                !validMerge &&
                !isValidMergeLoading &&
                (row.linked.value.name === LinkStatusType.Linked ||
                    row.linked.value.name === LinkStatusType.Invited)
                    ? "red"
                    : "blue";
            if (row.hasChildren === false) {
                return (
                    <Tag
                        color={color}
                        key={row.id.value}
                        closable={closable}
                        onClose={() => onTagClose(row.company.value, row.id)}>
                        {row.company.value}
                    </Tag>
                );
            } else {
                return false;
            }
        });
    }

    function onCancel() {
        form.resetFields();
        props.setModalVisibility(false);
    }

    async function onSubmit() {
        await form.validateFields();
        await updateMergeSet(parent!.id.value.toString(), childrenIds!);
        notification.success({
            message: t("success"),
            description: t("supplier_MergeSuccessMessage", {
                parent: parent?.company.value,
                children: props.selectedRows
                    .filter(x => x.hasChildren === false)
                    .map((row: IMasterDataPartner) => {
                        return row.company.value;
                    })
                    .join(", "),
            }),
        });
        form.resetFields();
        props.clearSelectedRows();
        props.updateTable();
        props.setModalVisibility(false);
    }
    return (
        <div>
            <Modal
                title={`${t("supplier_MergeIntoExistingTitle", {
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier")
                        : t("company_Type_Customer"),
                })} ${parent ? parent!.company.value : "Merge Set"}`}
                open={props.visible}
                onOk={() => onSubmit()}
                onCancel={() => onCancel()}
                footer={[
                    <Button
                        disabled={!validMerge}
                        loading={updateMergeSetLoading}
                        type="primary"
                        onClick={async () => await onSubmit()}>
                        {t("generic_Confirm")}
                    </Button>,
                ]}>
                <Spin spinning={isValidMergeLoading}>
                    <Form form={form} {...formItemLayout} layout={"horizontal"} colon={false}>
                        {!validMerge && !isValidMergeLoading && (
                            <Alert
                                style={{ marginBottom: "24px" }}
                                type={"warning"}
                                message={t(
                                    "supplier_MergeIntoMergeSetMoreThanOneLinkedPartnerError"
                                )}
                            />
                        )}

                        <Form.Item
                            label={
                                t("supplier_SelectedSuppliers", {
                                    companyType: isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer"),
                                }) + " : "
                            }>
                            {props.selectedRows.length === 2 && (
                                <div className="AddToMergeSetTagArea">{renderTags(false)}</div>
                            )}
                            {props.selectedRows.length > 2 && (
                                <div className="AddToMergeSetTagArea">{renderTags(true)}</div>
                            )}
                        </Form.Item>

                        {validMerge && (
                            <Alert
                                style={{ marginBottom: "16px" }}
                                message={t("mergeConfirmationText")}
                                type="warning"
                            />
                        )}

                        <Collapse style={{ width: "100%" }}>
                            <Panel header={t("mergeWarningTitle")} key="1">
                                <Alert
                                    type={"warning"}
                                    message={t("supplier_MergeWarningText", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier").toLowerCase()
                                            : t("company_Type_Customer").toLowerCase(),
                                    })}
                                />
                            </Panel>
                        </Collapse>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};
const ModalAddToMergeSet = ContentModal;

export default ModalAddToMergeSet;
