import React, { useState, FunctionComponent, useEffect, Fragment } from "react";
import {
    Modal,
    Row,
    Col,
    Input,
    Select,
    InputNumber,
    notification,
    Spin,
    Divider,
    Form,
    Button,
    Tooltip,
} from "antd";
import {
    useNcrReason,
    useNcrUpdate,
    useNcrRegistration,
    useCompanyType,
    useCustomDropDown,
} from "controller";
import currencyCodes from "utilities/misc/currencies.json";
import classes from "./styles/editForm.module.scss";
import {
    SelectData,
    SelectExternalPartner,
    AttachFile,
    SelectPartnerContacts,
    MinusCircle,
    PlusCircle,
    InfoCircle,
} from "components/utilitycomponents";
import { getCustomLabel } from "../../getCustomLabel";
import { useTranslation, Trans } from "react-i18next";
import { getCustomLabelVisiblity } from "../../getCustomLabelVisibility";
import { NcrActionsInput } from "../sharedComponents/ncrActionsInput";
import { NcrFieldVisibility } from "models";
import { getNcrFieldCustomdropdownId } from "components/contentcomponents/ncr/getNcrFieldCustomdropdown";
const Option = Select.Option;
const TextArea = Input.TextArea;

interface IProps {
    id: any;
    visible: boolean;
    onVisibleChange: Function;
    reloadTable?: Function;
    reloadRegistrationModal?: Function;
}

const EditModal: FunctionComponent<IProps> = ({
    id,
    visible,
    onVisibleChange,
    reloadTable,
    reloadRegistrationModal,
}: IProps) => {
    const [form] = Form.useForm();
    const { data: record, loading: loadingRecord } = useNcrRegistration(id, visible);
    const { updateNcr, loading } = useNcrUpdate();
    const [visibility, setVisibility] = useState(1);
    const [valueCurrDisabled, setValueCurrDisabled] = useState(false);
    const [otherCostsCurrDisabled, setOtherCostsCurrDisabled] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState<any>(
        record && {
            name: record.partnerName,
            representsCompanyId: record.representsCompanyId,
            id: record.partnerId,
            hasEmail: record.ncrPartnerHasEmail,
        }
    );
    const [fileList, setFileList] = useState<any[]>();
    const [ncrFields, setNcrFields] = useState<any[]>([]);
    const { t } = useTranslation();
    const [ncrReasonId, setNcrReasonId] = useState<any>(-1);
    const { isDefaultCompany } = useCompanyType();
    const { data: customDropdowns } = useCustomDropDown();

    useEffect(() => {
        if (visible === true) {
            if (record !== null) {
                if (record.customFields) {
                    setNcrFields(record.customFields.ncrFields);
                }
                setVisibility(record.visibility === "External" ? 2 : 1);
                setValueCurrDisabled(record.value === undefined || record.value === null);
                setOtherCostsCurrDisabled(
                    record.otherCosts === undefined || record.otherCosts === null
                );
                setSelectedPartner({
                    name: record.partnerName,
                    representsCompanyId: record.representsCompanyId,
                    id: record.partnerId,
                    hasEmail: record.ncrPartnerHasEmail,
                });

                setNcrReasonId(record.ncrReasonId);
                setFileList(
                    record.attachments !== undefined &&
                        record.attachments.map((file: any) => ({
                            id: file.fileId,
                            name: file.fileName,
                        }))
                );
            }
        }
        form.resetFields();
    }, [record, visible]);

    useEffect(() => {
        if (selectedPartner) {
            if (selectedPartner.name !== record.partnerName) {
                form.setFieldsValue({ correctiveActionOwner: undefined });
                form.setFieldsValue({ preventiveActionOwner: undefined });
                form.setFieldsValue({ rootCauseActionOwner: undefined });
            }
        }
    }, [selectedPartner]);

    let {
        partnerSelectLabel,
        purchaseOrderlabel,
        ncrReasonLabel,
        ncrValueLabel,
        otherCostsValueLabel,
        visibilityLabel,
        commentLabel,
        salesOrderLabel,
        deliveryNoteLabel,
        attachmentsLabel,
        otherCostsReasonLabel,
        deviationsLabel,
        deviationsProductNumberLabel,
        deviationsDescriptLabel,
        deviationsOrderedLabel,
        deviationsDeliveredLabel,
        deviationsFaultyLabel,
    } = getCustomLabel(ncrFields, isDefaultCompany);

    let {
        purchaseOrderNumberVisiblity,
        salesOrderNumberVisiblity,
        valueVisiblity,
        otherCostsVisiblity,
        otherCostsReasonVisiblity,
        commentVisiblity,
        deliveryNoteVisiblity,
        visibilityVisiblity,
        deviationsVisiblity,
        deviationsProductNumberVisiblity,
        deviationsDescriptVisiblity,
        deviationsOrderedVisiblity,
        deviationsDeliveredVisiblity,
        deviationsFaultyVisiblity,
    } = getCustomLabelVisiblity(ncrFields);

    let {
        purchaseOrderNumberCustomDropdownId,
        salesOrderNumberCustomDropdownId,
        deliveryNoteCustomDropdownId,
    } = getNcrFieldCustomdropdownId(ncrFields);

    function sortCurrencyOptions() {
        return currencyCodes.sort((e1, e2) => {
            if (e1.CurrencyCode > e2.CurrencyCode) {
                return 1;
            }
            if (e1.CurrencyCode < e2.CurrencyCode) {
                return -1;
            }

            return 0;
        });
    }

    function onNcrValueCurrencyChange(value: any) {
        if (value === undefined) {
            form.setFieldsValue({ ncrValue: undefined });
            setValueCurrDisabled(true);
        }
    }

    function onOtherCostValueCurrencyChange(value: any) {
        if (value === undefined) {
            form.setFieldsValue({ ncrOtherCost: undefined });
            setOtherCostsCurrDisabled(true);
        }
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await updateNcr({
            ncrId: record.id,
            ncrValues: values,
            ncrActions: record.ncrActions,
            visibilityVisibility: visibilityVisiblity,
            previousVisibility: record.visibility,
        });
        if (reloadTable) reloadTable();
        if (reloadRegistrationModal) reloadRegistrationModal();
        onVisibleChange(false);
        form.resetFields();
        notification.success({
            message: t("success"),
            description: t("ncrUpdateSuccess"),
        });
    }

    function onCancel() {
        onVisibleChange(false);
        form.resetFields();
    }

    return (
        <Fragment>
            {record !== null && (
                <Modal
                    wrapProps={{ "data-test": "ncr_edit_modal" }}
                    title={
                        record !== null ? (
                            <Trans
                                i18nKey="ncrModalTitle"
                                values={{
                                    id: record.alternateId
                                        ? `${record.alternateId} (${record.id})`
                                        : record.id,
                                    ncrReason: record.ncrReason,
                                }}>
                                {t("ncrModalTitle")}
                            </Trans>
                        ) : null
                    }
                    open={visible}
                    onCancel={() => onCancel()}
                    onOk={onSubmit}
                    okText={t("generic_Save")}
                    width={900}
                    style={{ top: 20 }}
                    footer={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={async () => await onSubmit()}>
                            {t("generic_Save")}
                        </Button>,
                    ]}>
                    <Spin spinning={loadingRecord}>
                        {!loadingRecord && (
                            <Form
                                form={form}
                                onFinish={onSubmit}
                                initialValues={{
                                    deviations:
                                        record &&
                                        record.deviations &&
                                        record.deviations.map((x: any) => null),
                                }}
                                labelAlign="left"
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}>
                                <Form.Item
                                    name="ncrId"
                                    hidden={true}
                                    valuePropName="value"
                                    initialValue={
                                        record && record.alternateId
                                            ? record.alternateId
                                            : undefined
                                    }>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={partnerSelectLabel}
                                    className={classes.formItem}
                                    name="externalPartner"
                                    valuePropName="value"
                                    initialValue={
                                        record && record.partnerId ? record.partnerId : undefined
                                    }>
                                    <SelectExternalPartner
                                        disabled={true}
                                        showCreationButton={false}
                                        placeholder={`${t("select")} ${partnerSelectLabel}`}
                                        includeChildren
                                    />
                                </Form.Item>

                                {purchaseOrderNumberVisiblity !== 1 && (
                                    <>
                                        {purchaseOrderNumberCustomDropdownId === null ||
                                        purchaseOrderNumberCustomDropdownId === 0 ? (
                                            <Form.Item
                                                label={purchaseOrderlabel}
                                                required={purchaseOrderNumberVisiblity === 2}
                                                className={classes.formItem}
                                                initialValue={record && record.purchaseOrderNumber}
                                                rules={[
                                                    {
                                                        required:
                                                            purchaseOrderNumberVisiblity === 2,
                                                        message: `${purchaseOrderlabel} ${t(
                                                            "isRequired"
                                                        )} `,
                                                    },
                                                ]}
                                                name="purchaseOrderNumber">
                                                <Input
                                                    maxLength={50}
                                                    placeholder={`${t(
                                                        "input"
                                                    )} ${purchaseOrderlabel}`}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                label={purchaseOrderlabel}
                                                required={purchaseOrderNumberVisiblity === 2}
                                                className={classes.formItem}
                                                initialValue={record && record.purchaseOrderNumber}
                                                name="purchaseOrderNumber">
                                                <SelectData
                                                    allowClear={purchaseOrderNumberVisiblity !== 2}
                                                    selectOptionField="text"
                                                    selectIdField="text"
                                                    placeholder={t("generic_SelectOption")}
                                                    useData={() => ({
                                                        data:
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    purchaseOrderNumberCustomDropdownId
                                                            )[0] !== undefined &&
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    purchaseOrderNumberCustomDropdownId
                                                            )[0].customDropdownOptions,
                                                    })}
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )}
                                {salesOrderNumberVisiblity !== 1 && (
                                    <>
                                        {salesOrderNumberCustomDropdownId === null ||
                                        salesOrderNumberCustomDropdownId === 0 ? (
                                            <Form.Item
                                                label={salesOrderLabel}
                                                required={salesOrderNumberVisiblity === 2}
                                                className={classes.formItem}
                                                initialValue={record && record.salesOrderNumber}
                                                rules={[
                                                    {
                                                        required: salesOrderNumberVisiblity === 2,
                                                        message: `${salesOrderLabel} ${t(
                                                            "isRequired"
                                                        )} `,
                                                    },
                                                ]}
                                                name="salesOrderNumber">
                                                <Input
                                                    maxLength={250}
                                                    placeholder={`${t("input")} ${salesOrderLabel}`}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                label={salesOrderLabel}
                                                required={salesOrderNumberVisiblity === 2}
                                                className={classes.formItem}
                                                initialValue={record && record.salesOrderNumber}
                                                name="salesOrderNumber">
                                                <SelectData
                                                    allowClear={salesOrderNumberVisiblity !== 2}
                                                    selectOptionField="text"
                                                    selectIdField="text"
                                                    placeholder={t("generic_SelectOption")}
                                                    useData={() => ({
                                                        data:
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    salesOrderNumberCustomDropdownId
                                                            )[0] !== undefined &&
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    salesOrderNumberCustomDropdownId
                                                            )[0].customDropdownOptions,
                                                    })}
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )}
                                {deliveryNoteVisiblity !== 1 && (
                                    <>
                                        {deliveryNoteCustomDropdownId === null ||
                                        deliveryNoteCustomDropdownId === 0 ? (
                                            <Form.Item
                                                label={deliveryNoteLabel}
                                                required={deliveryNoteVisiblity === 2}
                                                className={classes.formItem}
                                                name="deliveryNote"
                                                initialValue={record && record.deliveryNote}
                                                rules={[
                                                    {
                                                        required: deliveryNoteVisiblity === 2,
                                                        message: `${deliveryNoteLabel} ${t(
                                                            "isRequired"
                                                        )} `,
                                                    },
                                                ]}>
                                                <Input
                                                    maxLength={250}
                                                    placeholder={`${t(
                                                        "input"
                                                    )} ${deliveryNoteLabel}`}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                label={deliveryNoteLabel}
                                                required={deliveryNoteVisiblity === 2}
                                                className={classes.formItem}
                                                name="deliveryNote"
                                                initialValue={record && record.deliveryNote}>
                                                <SelectData
                                                    allowClear={deliveryNoteVisiblity !== 2}
                                                    selectOptionField="text"
                                                    selectIdField="text"
                                                    placeholder={t("generic_SelectOption")}
                                                    useData={() => ({
                                                        data:
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    deliveryNoteCustomDropdownId
                                                            )[0] !== undefined &&
                                                            customDropdowns.filter(
                                                                (dropdown: any) =>
                                                                    dropdown.id ===
                                                                    deliveryNoteCustomDropdownId
                                                            )[0].customDropdownOptions,
                                                    })}
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )}

                                <Form.Item
                                    label={ncrReasonLabel}
                                    required={true}
                                    className={classes.formItem}
                                    name="ncrReason"
                                    initialValue={record && record.ncrReasonId}>
                                    <SelectData
                                        selectIdField="id"
                                        useData={useNcrReason.bind(null, ncrReasonId)}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        selectOptionField="name"
                                        placeholder={`${t("select")} ${ncrReasonLabel}`}
                                    />
                                </Form.Item>

                                {deviationsVisiblity !== 1 && (
                                    <Form.List name="deviations">
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field: any, index: number) => (
                                                    <Form.Item
                                                        name="deviations"
                                                        key={field.key}
                                                        label={index === 0 ? deviationsLabel : " "}
                                                        colon={false}
                                                        required={false}
                                                        labelCol={{
                                                            span: 6,
                                                            style: { textAlign: "start" },
                                                        }}
                                                        wrapperCol={{ span: 18 }}>
                                                        <Row gutter={8} style={{ marginBottom: 8 }}>
                                                            {deviationsProductNumberVisiblity !==
                                                                1 && (
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[
                                                                            index,
                                                                            "productNumber",
                                                                        ]}
                                                                        key={index}
                                                                        className={
                                                                            classes.deviationsItem
                                                                        }
                                                                        validateTrigger="onChange"
                                                                        initialValue={
                                                                            record &&
                                                                            record.deviations !==
                                                                                undefined &&
                                                                            record.deviations[
                                                                                index
                                                                            ] !== undefined
                                                                                ? record.deviations[
                                                                                      index
                                                                                  ].productNumber
                                                                                : undefined
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    deviationsProductNumberVisiblity ===
                                                                                    2,
                                                                                message: `${deviationsProductNumberLabel} ${t(
                                                                                    "isRequired"
                                                                                )} `,
                                                                            },
                                                                        ]}>
                                                                        <Input
                                                                            data-test="deviations_product_number"
                                                                            maxLength={25}
                                                                            key={`deviationsProductNumber[${index}]`}
                                                                            placeholder={`${t(
                                                                                "input"
                                                                            )} ${deviationsProductNumberLabel}`}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            )}
                                                            {deviationsDescriptVisiblity !== 1 && (
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[
                                                                            index,
                                                                            "description",
                                                                        ]}
                                                                        key={index}
                                                                        className={
                                                                            classes.deviationsItem
                                                                        }
                                                                        initialValue={
                                                                            record &&
                                                                            record.deviations !==
                                                                                undefined &&
                                                                            record.deviations[
                                                                                index
                                                                            ] !== undefined
                                                                                ? record.deviations[
                                                                                      index
                                                                                  ].description
                                                                                : undefined
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    deviationsDescriptVisiblity ===
                                                                                    2,
                                                                                message: `${deviationsDescriptLabel} ${t(
                                                                                    "isRequired"
                                                                                )} `,
                                                                            },
                                                                        ]}>
                                                                        <Input
                                                                            data-test="deviations_description"
                                                                            maxLength={150}
                                                                            key={`deviationsDecription[${index}]`}
                                                                            placeholder={`${t(
                                                                                "input"
                                                                            )} ${deviationsDescriptLabel}`}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                        <Row gutter={8}>
                                                            {deviationsOrderedVisiblity !== 1 && (
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[index, "ordered"]}
                                                                        key={index}
                                                                        className={classes.formItem}
                                                                        initialValue={
                                                                            record &&
                                                                            record.deviations !==
                                                                                undefined &&
                                                                            record.deviations[
                                                                                index
                                                                            ] !== undefined
                                                                                ? record.deviations[
                                                                                      index
                                                                                  ].ordered
                                                                                : undefined
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    deviationsOrderedVisiblity ===
                                                                                    2,
                                                                                message: `${deviationsOrderedLabel} ${t(
                                                                                    "isRequired"
                                                                                )} `,
                                                                            },
                                                                        ]}>
                                                                        <InputNumber
                                                                            data-test="deviations_ordered"
                                                                            maxLength={15}
                                                                            min={0}
                                                                            decimalSeparator=","
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            key={`deviationsOrdered[${index}]`}
                                                                            placeholder={`${t(
                                                                                "input"
                                                                            )} ${deviationsOrderedLabel}`}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            )}
                                                            {deviationsDeliveredVisiblity !== 1 && (
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[index, "delivered"]}
                                                                        key={index}
                                                                        className={classes.formItem}
                                                                        initialValue={
                                                                            record &&
                                                                            record.deviations !==
                                                                                undefined &&
                                                                            record.deviations[
                                                                                index
                                                                            ] !== undefined
                                                                                ? record.deviations[
                                                                                      index
                                                                                  ].delivered
                                                                                : undefined
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    deviationsDeliveredVisiblity ===
                                                                                    2,
                                                                                message: `${deviationsDeliveredLabel} ${t(
                                                                                    "isRequired"
                                                                                )} `,
                                                                            },
                                                                        ]}>
                                                                        <InputNumber
                                                                            data-test="deviations_delivered"
                                                                            maxLength={15}
                                                                            min={0}
                                                                            decimalSeparator=","
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            key={`deviationsDelivered[${index}]`}
                                                                            placeholder={`${t(
                                                                                "input"
                                                                            )} ${deviationsDeliveredLabel}`}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            )}
                                                            {deviationsFaultyVisiblity !== 1 && (
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[index, "faulty"]}
                                                                        key={index}
                                                                        className={classes.formItem}
                                                                        initialValue={
                                                                            record &&
                                                                            record.deviations !==
                                                                                undefined &&
                                                                            record.deviations[
                                                                                index
                                                                            ] !== undefined
                                                                                ? record.deviations[
                                                                                      index
                                                                                  ].faulty
                                                                                : undefined
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    deviationsFaultyVisiblity ===
                                                                                    2,
                                                                                message: `${deviationsFaultyLabel} ${t(
                                                                                    "isRequired"
                                                                                )} `,
                                                                            },
                                                                        ]}>
                                                                        <InputNumber
                                                                            data-test="deviations_faulty"
                                                                            maxLength={15}
                                                                            min={0}
                                                                            decimalSeparator=","
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            key={`deviationsFaulty[${index}]`}
                                                                            placeholder={`${t(
                                                                                "input"
                                                                            )} ${deviationsFaultyLabel}`}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            )}
                                                        </Row>

                                                        {fields.length > 0 ? (
                                                            <MinusCircle
                                                                className={classes.removeRow}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </Form.Item>
                                                ))}
                                                <Form.Item
                                                    label={" "}
                                                    colon={false}
                                                    labelCol={{ span: 6 }}
                                                    wrapperCol={{ span: 18 }}>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: "100%" }}
                                                        icon={<PlusCircle />}>
                                                        Add Deviation
                                                    </Button>

                                                    <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                )}

                                {valueVisiblity !== 1 && (
                                    <Row gutter={8}>
                                        <Col span={18}>
                                            <Form.Item
                                                label={ncrValueLabel}
                                                required={valueVisiblity === 2}
                                                className={classes.formItem}
                                                name="ncrValue"
                                                initialValue={record && record.value}
                                                rules={[
                                                    {
                                                        required: valueVisiblity === 2,
                                                        message: `${ncrValueLabel} ${t(
                                                            "isRequired"
                                                        )} `,
                                                    },
                                                ]}>
                                                <InputNumber
                                                    maxLength={15}
                                                    min={0}
                                                    formatter={value =>
                                                        `${value}`.replace(/([A-Z])/gi, "")
                                                    }
                                                    onChange={(val: any) => {
                                                        setValueCurrDisabled(
                                                            val === undefined ||
                                                                val === null ||
                                                                (val !== null && val === "")
                                                        );
                                                    }}
                                                    decimalSeparator=","
                                                    placeholder={`${t("input")} ${ncrValueLabel}`}
                                                    style={{ marginLeft: 54, width: "100%" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className={classes.formItem}
                                                name="ncrValueCurrency"
                                                initialValue={
                                                    record && record.valueCurrency !== null
                                                        ? record.valueCurrency
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        validator: (
                                                            rule: any,
                                                            value: any,
                                                            callback: any
                                                        ) => {
                                                            try {
                                                                const hasNcrValue =
                                                                    form.getFieldValue(
                                                                        "ncrValue"
                                                                    ) ||
                                                                    form.getFieldValue(
                                                                        "ncrValue"
                                                                    ) === 0;
                                                                if (!value && hasNcrValue) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            t(
                                                                                "inputIsRequiredError"
                                                                            )
                                                                        )
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            } catch (error) {
                                                                return Promise.reject(error);
                                                            }
                                                        },
                                                    },
                                                ]}>
                                                <SelectData
                                                    style={{ marginLeft: 52 }}
                                                    useData={() => {
                                                        return {
                                                            data: sortCurrencyOptions(),
                                                            loading: false,
                                                        };
                                                    }}
                                                    selectIdField="CurrencyCode"
                                                    selectOptionField="CurrencyCode"
                                                    allowClear
                                                    disabled={valueCurrDisabled}
                                                    onChange={(val: any) =>
                                                        onNcrValueCurrencyChange(val)
                                                    }
                                                    placeholder={`${t("select")} ${t(
                                                        "currencyPlaceholder"
                                                    )}`}
                                                    getPopupContainer={(node: any) =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                {otherCostsReasonVisiblity !== 1 && (
                                    <Row gutter={8}>
                                        <Col span={18}>
                                            <Form.Item
                                                label={otherCostsValueLabel}
                                                required={otherCostsVisiblity === 2}
                                                className={classes.formItem}
                                                name="ncrOtherCost"
                                                initialValue={record && record.otherCosts}
                                                rules={[
                                                    {
                                                        required: otherCostsVisiblity === 2,
                                                        message: `${otherCostsValueLabel} ${t(
                                                            "isRequired"
                                                        )} `,
                                                    },
                                                ]}>
                                                <InputNumber
                                                    maxLength={15}
                                                    min={0}
                                                    formatter={value =>
                                                        `${value}`.replace(/([A-Z])/gi, "")
                                                    }
                                                    onChange={(val: any) =>
                                                        setOtherCostsCurrDisabled(
                                                            val === undefined ||
                                                                val === null ||
                                                                (val !== null && val === "")
                                                        )
                                                    }
                                                    decimalSeparator=","
                                                    placeholder={`${t(
                                                        "input"
                                                    )} ${otherCostsValueLabel}`}
                                                    style={{ marginLeft: 54, width: "100%" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className={classes.formItem}
                                                name="ncrOtherCostCurrency"
                                                initialValue={
                                                    record && record.otherCostsCurrency !== null
                                                        ? record.otherCostsCurrency
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        validator: (
                                                            rule: any,
                                                            value: any,
                                                            callback: any
                                                        ) => {
                                                            try {
                                                                const hasNcrOtherCost =
                                                                    form.getFieldValue(
                                                                        "ncrOtherCost"
                                                                    ) ||
                                                                    form.getFieldValue(
                                                                        "ncrOtherCost"
                                                                    ) === 0;
                                                                if (!value && hasNcrOtherCost) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            t(
                                                                                "inputIsRequiredError"
                                                                            )
                                                                        )
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            } catch (error) {
                                                                return Promise.reject(error);
                                                            }
                                                        },
                                                    },
                                                ]}>
                                                <SelectData
                                                    style={{ marginLeft: 52 }}
                                                    useData={() => {
                                                        return {
                                                            data: sortCurrencyOptions(),
                                                            loading: false,
                                                        };
                                                    }}
                                                    selectIdField="CurrencyCode"
                                                    selectOptionField="CurrencyCode"
                                                    disabled={otherCostsCurrDisabled}
                                                    onChange={(val: any) =>
                                                        onOtherCostValueCurrencyChange(val)
                                                    }
                                                    allowClear
                                                    placeholder={`${t("select")} ${t(
                                                        "currencyPlaceholder"
                                                    )}`}
                                                    getPopupContainer={(node: any) =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                {otherCostsReasonVisiblity !== 1 && (
                                    <Form.Item
                                        label={otherCostsReasonLabel}
                                        required={otherCostsReasonVisiblity === 2}
                                        className={classes.formItem}
                                        name="ncrOtherCostReason"
                                        initialValue={record && record.otherCostsReason}
                                        rules={[
                                            {
                                                required: otherCostsReasonVisiblity === 2,
                                                message: `${otherCostsReasonLabel} ${t(
                                                    "isRequired"
                                                )} `,
                                            },
                                        ]}>
                                        <Input
                                            maxLength={200}
                                            placeholder={`${t("input")} ${otherCostsReasonLabel}`}
                                        />
                                    </Form.Item>
                                )}
                                {commentVisiblity !== 1 && (
                                    <Form.Item
                                        label={commentLabel}
                                        required={commentVisiblity === 2}
                                        data-test="ncr_view_comment"
                                        className={classes.formItem}
                                        name="comment"
                                        initialValue={record && record.comment}
                                        rules={[
                                            {
                                                required: commentVisiblity === 2,
                                                message: `${commentLabel} ${t("isRequired")} `,
                                            },
                                        ]}>
                                        <TextArea maxLength={4000} />
                                    </Form.Item>
                                )}

                                {visibilityVisiblity !== NcrFieldVisibility.Invisible &&
                                    visibilityVisiblity !== NcrFieldVisibility.InvisibleInternal &&
                                    visibilityVisiblity !==
                                        NcrFieldVisibility.InvisibleExternal && (
                                        <Form.Item
                                            label={visibilityLabel}
                                            required={visibilityVisiblity === 2}
                                            className={classes.formItem}
                                            name="visibility"
                                            valuePropName="value"
                                            initialValue={
                                                record && record.visibility === "Internal" ? 1 : 2
                                            }
                                            rules={[
                                                {
                                                    required: visibilityVisiblity === 2,
                                                    message: `${visibilityLabel} ${t(
                                                        "isRequired"
                                                    )} `,
                                                },
                                            ]}>
                                            <Select
                                                onChange={(val: any) => setVisibility(val)}
                                                getPopupContainer={(node: any) =>
                                                    node
                                                        ? (node.parentNode as HTMLElement)
                                                        : document.body
                                                }>
                                                <Option key={0} value={1}>
                                                    {t("internal")}
                                                </Option>
                                                <Option key={1} value={2}>
                                                    {t("external")}
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    )}

                                {visibilityVisiblity !== NcrFieldVisibility.Invisible &&
                                    NcrFieldVisibility.InvisibleExternal &&
                                    visibility === 2 && (
                                        <Form.Item
                                            label={
                                                <>
                                                    {t("ncr_PartnerContactReceivers")}{" "}
                                                    <Tooltip
                                                        title={t("ncr_PartnerContactReceiversTip")}>
                                                        <span>
                                                            <InfoCircle />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            }
                                            name="ncrPartnerContactReceiver"
                                            initialValue={
                                                record && record.ncrPartnerContactReceiverIds
                                            }
                                            className={classes.formItem}>
                                            <SelectPartnerContacts
                                                partnerId={selectedPartner && selectedPartner.id}
                                                disabled={
                                                    selectedPartner === undefined ||
                                                    selectedPartner === null
                                                }
                                            />
                                        </Form.Item>
                                    )}

                                <Form.Item
                                    label={attachmentsLabel}
                                    data-test="edit_attached_files"
                                    className={classes.formItem}
                                    name="attachedFiles"
                                    initialValue={fileList}>
                                    <AttachFile maxFileSize={40} attachedFileList={fileList} />
                                </Form.Item>

                                {((visibilityVisiblity === 0 && visibility === 2) ||
                                    (visibilityVisiblity === 0 && visibility === undefined) ||
                                    visibilityVisiblity ===
                                        NcrFieldVisibility.InvisibleExternal) && (
                                    <Fragment>
                                        <Divider />
                                        <NcrActionsInput
                                            record={record}
                                            selectedPartner={selectedPartner}
                                            selectedPartnerId={record.partnerId}
                                            form={form}
                                            isEditMode={true}
                                            parentModalVisible={visible}
                                        />
                                    </Fragment>
                                )}
                            </Form>
                        )}
                    </Spin>
                </Modal>
            )}
        </Fragment>
    );
};

export default EditModal;
