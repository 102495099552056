import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import classes from "./styles/shared.module.scss";
import { IMessageChildDetail } from "models";
import { newlineToBr } from "utilities";
import { useTranslation } from "react-i18next";
import { PureDiv } from "components/utilitycomponents";
import { useActivityUpdateComment } from "controller";
import { Button, Input, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppState } from "store";

type PropsType = {
    data: IMessageChildDetail;
    isDeleted: boolean;
};

const MessageChild: FunctionComponent<PropsType> = ({ data, isDeleted }) => {
    const { t } = useTranslation();
    const { update: updateComment } = useActivityUpdateComment();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [message, setMessage] = useState<string>(data.message);
    const currentUserId: string = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-UserId"]
    );

    useEffect(() => {
        setMessage(data.message);
    }, [data]);

    const handleOnCancel = () => {
        setMessage(data.message);
        setEditMode(false);
    };

    const handleOnSave = async () => {
        await updateComment(data.id, message);
        setEditMode(false);
    };

    return (
        <Fragment>
            <div className={classes.activityTitle}>{data.creator_FullName}</div>
            {!editMode ? (
                <div key={data.id}>
                    <PureDiv
                        data-test="message_child"
                        textContent={
                            !isDeleted ? newlineToBr(message) : t("activity_CommentDeleted")
                        }
                    />
                    {data &&
                        data.createdByUserId &&
                        data.createdByUserId.toString() === currentUserId &&
                        !isDeleted && (
                            <Tooltip title={t("generic_Edit")}>
                                <Button
                                    icon={<EditOutlined />}
                                    type="link"
                                    size="small"
                                    onClick={() => setEditMode(true)}
                                    style={{ position: "absolute", top: -1, right: 0 }}></Button>
                            </Tooltip>
                        )}
                </div>
            ) : (
                <div key={data.id}>
                    <Input.TextArea
                        autoFocus
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        maxLength={1000}
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}></Input.TextArea>
                    <Button
                        size="small"
                        type="primary"
                        style={{ float: "right", marginTop: 4 }}
                        onClick={async () => await handleOnSave()}>
                        {t("generic_Save")}
                    </Button>
                    <Button
                        size="small"
                        type="primary"
                        style={{ float: "right", marginRight: 4, marginTop: 4 }}
                        onClick={() => handleOnCancel()}>
                        {t("generic_Cancel")}
                    </Button>
                </div>
            )}
        </Fragment>
    );
};

export default MessageChild;
