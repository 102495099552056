import { useState } from "react";
import { Provider } from "services";

const useKpiDefinitionDataDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteDefinitionData = async (kpiId: number) => {
        setLoading(true);

        const res = await Provider.Kpi.deleteUploadDataOnKpi(kpiId, {
            customError: { message: "Could not delete data on kpi" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        deleteDefinitionData,
    };
};

export { useKpiDefinitionDataDelete };
