import React, { FunctionComponent } from "react";
import { Col, Row, Skeleton, Form, Tooltip } from "antd";
import classes from "./supplierInformation.module.scss";
import { useTranslation } from "react-i18next";
import { useSegmentCategories, useManualSegmentHistoryCRUD } from "controller";
import { SelectData } from "components/utilitycomponents";
import { InfoCircleTwoTone } from "@ant-design/icons";
// import dayjs from "utilities/daysJsConfig";
import moment from "moment";

interface IProps {
    data: any;
    loading: boolean;
    partnerId: number;
}

const SupplierSegmentation: FunctionComponent<IProps> = ({ data, loading, partnerId }) => {
    const { t } = useTranslation();
    const {
        create: addHistory,
        remove: deleteHistory,
        update: updateHistory,
    } = useManualSegmentHistoryCRUD();
    const [segmentHistoryForm] = Form.useForm();
    const { data: segmentCategories, loading: loadingSegmentCategories } = useSegmentCategories(
        "black"
    );
    const [history, setHistory] = React.useState<any[]>(
        data.segmentations.manualSegmentationHistory
    );

    const renderSegment = (segmentData: any[]) => {
        return segmentData.map((segmentData: any, index: number) => {
            let year = segmentData.year;
            if (index === 0 || (index !== 0 && segmentData.segment !== null)) {
                // we do not allow setting a historic value for the current year or for years where a value from the changeLog exists
                return (
                    <Row gutter={4} style={{ height: 26 }}>
                        <Col span={8} style={{ paddingTop: 5 }}>
                            {year}
                        </Col>
                        <Col
                            span={16}
                            style={{ fontWeight: index === 0 ? "bold" : "normal", paddingTop: 5 }}>
                            {segmentData.segment ? segmentData.segment : "-"}
                        </Col>
                    </Row>
                );
            }

            let manualSegmentHistory = history.map((x: any) => ({
                ...x,
                segmentationYear: moment(x.segmentationDate).year(),
            }));
            let currentYearSegmentHistoryItem = manualSegmentHistory.find(
                (item: any) => item.segmentationYear.toString() === year
            );

            return (
                <Row gutter={4} style={{ height: 26, width: 200 }}>
                    <Col span={8} style={{ paddingTop: 5 }}>
                        {year}
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            style={{ padding: 0, margin: 0, width: "100%", marginLeft: -8 }}
                            name={year}
                            initialValue={
                                currentYearSegmentHistoryItem
                                    ? currentYearSegmentHistoryItem.segmentationCategorySettingId
                                    : -1
                            }>
                            <SelectData
                                onChange={(value: any, item: any) =>
                                    handleOnSegmentCategoryChange(
                                        value,
                                        year,
                                        currentYearSegmentHistoryItem
                                    )
                                }
                                showArrow={false}
                                style={{ width: "100%" }}
                                dropdownMatchSelectWidth={false}
                                selectOptionField="segment"
                                size={"small"}
                                bordered={false}
                                useData={() => {
                                    return {
                                        data: [{ id: -1, segment: "-" }, ...segmentCategories],
                                        loading: loadingSegmentCategories,
                                    };
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            );
        });
    };

    const handleOnSegmentCategoryChange = async (
        categoryId: number,
        year: any,
        historyItem: any
    ) => {
        if (categoryId === -1 && historyItem !== undefined) {
            await deleteHistory(historyItem.id);
            setHistory(history.filter((x: any) => x.id !== historyItem.id));
            return;
        }

        if (categoryId !== -1 && historyItem === undefined) {
            let historyItem: any = {
                segmentationCategorySettingId: categoryId,
                segmentationDate: moment(year)
                    .utc()
                    .toISOString(),
                partnerId,
            };
            console.log(year);
            let historyId = await addHistory({
                segmentationCategorySettingId: categoryId,
                segmentationDate: moment(year)
                    .utc()
                    .toISOString(),
                partnerId,
            });
            historyItem.id = historyId;
            setHistory([...history, historyItem]);
            return;
        }
        if (categoryId !== -1 && historyItem !== undefined) {
            await updateHistory(historyItem.id, categoryId);
            return;
        }
    };

    return (
        <div>
            <Row>
                <div className={classes.headline}>
                    {t("governance_SupplierSegmentation").toLocaleUpperCase()}
                    <Tooltip title={t("segmentation_ManuelHistoryInfo")}>
                        <InfoCircleTwoTone style={{ marginLeft: 4 }} />
                    </Tooltip>
                </div>
            </Row>
            <Row>
                <>
                    {loading === true || loadingSegmentCategories === true ? (
                        <Skeleton.Input size="small" active />
                    ) : (
                        <Col span={24}>
                            <Form form={segmentHistoryForm}>
                                {renderSegment(data.segmentations.segmentationInfo)}
                            </Form>
                        </Col>
                    )}
                </>
            </Row>
        </div>
    );
};

export { SupplierSegmentation };
