import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/action.module.scss";
import { Button } from "antd";
interface IProps {
    onMyActionsClicked: Function;
    onMyCreatedActionsClicked: Function;
}
const SideBarFilter = ({ onMyActionsClicked, onMyCreatedActionsClicked }: IProps) => {
    const { t } = useTranslation();
    return (
        <div className={classes.sideBarContainer}>
            <div style={{ marginBottom: 8 }}>{t("filter_By_Label")}</div>
            <Button
                data-test="my_actions_button"
                className={classes.buttonStyle}
                onClick={() => onMyActionsClicked()}>
                {t("action_MyActions")}
            </Button>
            <Button
                className={classes.buttonStyle}
                data-test="my_created_actions_button"
                onClick={() => onMyCreatedActionsClicked()}>
                {t("action_CreatedByMe")}
            </Button>
        </div>
    );
};

export default SideBarFilter;
