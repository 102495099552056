import React, { FunctionComponent } from "react";
import { Tag } from "antd";

type PropsType = {
    value: number | undefined;
    getColor?: Function;
    style?: any;
    [key: string]: any;
};

export const getPercentTagColor = (score: number) => {
    if (score === undefined) return "#bfbfbf";
    if (score < 25) return "#b20000";
    if (score >= 25 && score < 50) return "#fa9308";
    if (score >= 50 && score < 75) return "#b1bf0a";
    else return "#00b204";
};

const ColoredPercentTag: FunctionComponent<PropsType> = ({
    value,
    getColor = getPercentTagColor,
    style = null,
    ...rest
}) => {
    return (
        <Tag
            style={{ ...style, width: "40px", padding: "0 3px", textAlign: "center" }}
            color={getColor(value)}
            {...rest}>
            {`${value !== undefined ? value : "- "}`}%
        </Tag>
    );
};

export { ColoredPercentTag };
