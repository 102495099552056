import React, { FunctionComponent } from "react";
import { Row, Col, Typography, Divider } from "antd";
import classes from "./segmentationInfo.module.scss";
import { useTranslation } from "react-i18next";
import { ISegmentationAlignmentInfo } from "models";
const { Paragraph } = Typography;

interface IProps {
    data: ISegmentationAlignmentInfo;
    type: "current" | "future";
}

const InfoSection: FunctionComponent<IProps> = ({ data, type }) => {
    const { t } = useTranslation();
    const isCurrent = type === "current";

    const renderRestrictionText = (val: string) => {
        switch (val) {
            case "none":
                return t("generic_None");
            case "Limited":
                return t("Limited");
            case "Prohibited":
                return t("Prohibited");
            default:
                return "";
        }
    };

    if (data === undefined) {
        return null;
    }

    return (
        <>
            <Row className={classes.topRow}>
                <Divider
                    orientation="left"
                    orientationMargin={10}
                    className={classes.horizontalDivider}>
                    <span className={classes.infoTitleText}>
                        {isCurrent ? t("segmentation_current") : t("segmentation_future")}
                    </span>
                </Divider>
            </Row>
            <Row className={classes.rowMargin}>
                <Col span={12}>
                    {((isCurrent && data.currentSegment) || (!isCurrent && data.futureSegment)) && (
                        <div
                            className={classes.segmentTag}
                            style={{
                                color: isCurrent
                                    ? data.currentSegmentColor
                                    : data.futureSegmentColor,
                            }}>
                            {type === "current" ? data.currentSegment : data.futureSegment}
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Paragraph className={classes.infoText}>
                        {renderRestrictionText(
                            isCurrent
                                ? data.currentSegmentRestrictions!
                                : data.futureSegmentRestrictions!
                        )}
                    </Paragraph>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Paragraph
                        className={classes.infoText}
                        ellipsis={{
                            rows: 2,
                            tooltip: true,
                        }}>
                        {isCurrent ? data.currentSegmentComment : data.futureSegmentComment}
                    </Paragraph>
                </Col>
            </Row>
        </>
    );
};

export { InfoSection };
