import IPartnerTableData from "models/suppliers/IPartnerTableData";
import ICustomColumn from "models/suppliers/customcolumns/ICustomColumn";
import IHeadlineSetting from "models/suppliers/IHeadlineSetting";
import { IPartnerFieldVisibility } from "models";

export class PartnerModelMapper {
    public mapSupplier(data: any[]) {
        let mappedResult: IPartnerTableData[] = [];
        data.forEach(element => {
            let partner: IPartnerTableData = {
                company: { value: element.partner.name, displayName: "Company Name" },
                companynumber: {
                    value: element.partner.number,
                    displayName: "Company Number",
                },
                id: { value: element.partner.id, displayName: "ID" },
                group: {
                    value: element.partnerGroup
                        ? { name: element.partnerGroup.name, id: element.partnerGroup.id }
                        : { id: undefined, name: undefined },
                    displayName: "Group",
                },
                owners: {
                    value: element.owners.map(({ id, userId, firstName, lastName }: any) => ({
                        id: id,
                        userId: userId,
                        firstName: firstName,
                        lastName: lastName,
                    })),
                    displayName: "Owner",
                },
                yearlyValueOfCooperation: {
                    value: element.partner.yearlyValueOfCooperation,
                    displayName: "Value",
                },
                currency: element.partner.currency,
                category1: {
                    value: element.firstCategory.map(({ id, name }: any) => ({
                        id: id,
                        name: name,
                    })),
                    displayName: "Category 1",
                },
                category2: {
                    value: element.secondCategory.map(({ id, name }: any) => ({
                        id: id,
                        name: name,
                    })),
                    displayName: "Category 2",
                },
                linked: {
                    value: {
                        name:
                            element.partnerDetails && element.partnerDetails.linkStatus
                                ? element.partnerDetails.linkStatus
                                : "NoEmail",
                    },
                    displayName: "Linked",
                },
                kpis: {
                    value: element.kpiScoreStatistic
                        ? {
                              score: element.kpiScoreStatistic.score,
                              month: element.kpiScoreStatistic.month
                                  ? element.kpiScoreStatistic.month
                                  : undefined,
                              applicable: element.kpiScoreStatistic.applicable,
                              hasScore: element.kpiScoreStatistic.hasScore,
                          }
                        : { score: undefined, month: undefined },
                    displayName: "KPIs",
                },
                ncrs: {
                    value: element.ncrStatistic
                        ? {
                              totalCount: element.ncrStatistic.totalCount
                                  ? element.ncrStatistic.totalCount
                                  : undefined,
                              openDaysAverage: element.ncrStatistic.openDaysAverage,
                          }
                        : {
                              totalCount: undefined,
                              openDaysAverage: undefined,
                          },
                    displayName: "NCRs",
                },
                review: {
                    value: element.reviewStatistic
                        ? {
                              totalCount: element.reviewStatistic.totalCount,
                              reviewsAverage: element.reviewStatistic.reviewsAverage,
                          }
                        : { totalCount: undefined, reviewsAverage: undefined },
                    displayName: "Reviews",
                },
                compliance: {
                    value: element.complianceStatistic
                        ? {
                              totalCount:
                                  "totalCount" in element.complianceStatistic
                                      ? element.complianceStatistic.totalCount
                                      : null,
                              answersAverage:
                                  "answersAverage" in element.complianceStatistic
                                      ? element.complianceStatistic.answersAverage
                                      : null,
                          }
                        : { totalCount: null, answersAverage: null },
                    displayName: "Compliances",
                },
                complianceScore: {
                    value: element.complianceScoreAverage,
                    displayName: "Compliance Score Average",
                },
                ncrReasonStatistic:
                    element.ncrStatistic && element.ncrStatistic.ncrReasonStatistic
                        ? element.ncrStatistic.ncrReasonStatistic.map((x: any) => ({
                              reasonText: x.reasonText,
                              totalCount: x.totalCountReason,
                          }))
                        : [],
                status: {
                    value: element.partnerStatus
                        ? {
                              name: element.partnerStatus.name,
                              color: element.partnerStatus.color,
                              key: element.partnerStatus.id,
                          }
                        : { name: "", key: "", color: "" },
                    displayName: "Status",
                },
                actionsCreated: element.actionsCreated,
                actionsAssigned: element.actionsAssigned,
                segment: element.segment,
                scorecardAwardStatus: element.awardStatus,
                scorecardScore: element.score,
            };
            mappedResult.push(partner);
        });

        return mappedResult;
    }

    public mapCustomColumns(data: any[]) {
        let mappedResult: ICustomColumn[] = [];
        data.forEach(element => {
            if (element.isActive) {
                let customColumn: ICustomColumn = {
                    id: element.id,
                    name: element.name,
                };
                mappedResult.push(customColumn);
            }
        });
        return mappedResult;
    }

    public mapCustomHeadlines(data: any[]) {
        let mappedResult: IHeadlineSetting = {};
        data.forEach(element => {
            switch (element.name) {
                case "CompanyName":
                    mappedResult.companyHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "CompanyNumber":
                    mappedResult.companyNumberHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "KPIs":
                    mappedResult.kpisHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "NCRs":
                    mappedResult.ncrsHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "NCRDaysOpen":
                    mappedResult.ncrsOpenHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Audits":
                    mappedResult.compliancesHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Reviews":
                    mappedResult.reviewsHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Status":
                    mappedResult.statusHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Owner":
                    mappedResult.ownerHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Group":
                    mappedResult.groupHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Category1":
                    mappedResult.category1Headline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Category2":
                    mappedResult.category2Headline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Value":
                    mappedResult.valueHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "Linked":
                    mappedResult.linkedHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                case "AverageScore":
                    mappedResult.complianceScoreHeadline = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
                    break;
                default:
                    mappedResult[element.name] = {
                        name: element.name,
                        standardLabel: element.standardLabel,
                        customLabel: element.customLabel,
                        isMandatory:
                            element.visibility === IPartnerFieldVisibility.VisibleAndMandatory,
                    };
            }
        });
        return mappedResult;
    }
}
