import { SurveyType } from "models";
import { get, post } from "../base";

const CommodityCategory = {
    getCommodityCategories: async (
        level: number = 1,
        oneLevelOnly: boolean = false,
        { customError }: any = {}
    ) => {
        const url = `/v1/CommodityCategories?topLevel=${level}&oneLevelOnly=${oneLevelOnly}`;
        return await get({ url, customError });
    },
    getCommodityCategoryById: async (
        commodityId: string,
        includeChildren: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/CommodityCategory?commodityId=${commodityId}&includeChildren=${includeChildren}`;
        return await get({ url, customError });
    },
    getCommodityCategoriesByPartner: async (
        partnerIds: number[],
        level: string,
        type: SurveyType,
        { customError }: any = {}
    ) => {
        const url = `/v1/Survey/SegmentationFilter/GetCommodityFromPartner?level=${level}&surveyType=${type}`;
        return await post({ url, payload: partnerIds, customError });
    },
};

export default CommodityCategory;
