import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "services";
import { getPasswordValidationPattern } from "utilities";

const useCheckPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const checkPassword = async (username: string, password: string) => {
        let result: any = null;
        if (!password || password.trim() === "") {
            result = t("inputIsRequiredError");
        } else if (!new RegExp(getPasswordValidationPattern().regex).test(password)) {
            result = t(getPasswordValidationPattern().messageKey);
        } else if (
            username &&
            password
                .trim()
                .toLowerCase()
                .includes(username.trim().toLowerCase())
        ) {
            result = t("admin_UserRegistration_PasswordContainsUserName");
        } else {
            setLoading(true);
            const response: any = await Provider.User.checkPassword(username, password, {
                customError: {
                    message: "Could not check password",
                },
            }).catch((err: any) => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);

            if (response.pwendCount !== 0) return t("admin_UserRegistration_CommonPassword");
            else if (response.passwordScore < 4) {
                result = `${t("admin_UserRegistration_WeakPassword")} ${response.suggestions.join(
                    ","
                )}`;
            }
        }
        return result;
    };
    return {
        loading,
        checkPassword,
    };
};

export { useCheckPassword };
