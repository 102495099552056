import { useEffect, useState } from "react";
import Provider from "services/api";

const useFolderOverview = (
    folderId: number | undefined,
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        recordsWithChildrenFound: null,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
            recordsWithChildrenFound: state.recordsWithChildrenFound,
        });

        (async () => {
            if (!externalLoading) {
                const data = await Provider.File.folderOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters: folderId ? [...filters, { type: "Id", value: folderId }] : filters,
                        sorts,
                        to: range.to,
                        from: range.from,
                    },
                    {
                        customError: {
                            message: "Could not retrieve list of folders",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                data.folderData = data.folderData.map((folder: any) => ({
                    ...folder,
                    children: folder.subFolders
                        ? folder.children.concat(folder.subFolders)
                        : folder.children,
                }));
                setState({
                    loading: false,
                    data: data.folderData,
                    totalRows: data.numberOfTotalFolders,
                    recordsWithChildrenFound: data.foldersWithFilesFound,
                });
            }
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range, folderId]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        recordsWithChildrenFound: state.recordsWithChildrenFound,
    };
};

export { useFolderOverview };
