import { Button, Result, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSupplierPortalSelfAssessmentRequestsCount } from "controller";
import classes from "./supplierLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { ClipboardCheck } from "components/utilitycomponents";

const SelfAssessmentCount = () => {
    const { data, loading } = useSupplierPortalSelfAssessmentRequestsCount();
    const { t } = useTranslation("supplierPortal");

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Result
                    className={classes.resultPadding}
                    icon={<ClipboardCheck style={{ fontSize: 72 }} />}
                    title={
                        <span>
                            {t("generic_YouHave")} <span className={classes.count}>{data}</span>{" "}
                            {t("selfAssessment_LandingPageComponentText")}
                        </span>
                    }
                    extra={
                        <Link to="/SelfassessmentRequests">
                            <Button type="primary">{t("generic_See")}</Button>
                        </Link>
                    }
                />
            )}
        </>
    );
};

export { SelfAssessmentCount };
