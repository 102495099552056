const addOtherHiddenColumns = (userOrderedColumns: any[], allUserOrderedColumns: any[]) => {
    let otherHiddenColumns: any[] = [];
    if (userOrderedColumns.length < allUserOrderedColumns.length) {
        allUserOrderedColumns.forEach((col: any) => {
            const notExistInUserOrderColumns = !userOrderedColumns.filter(
                ({ dataIndex }: any) => dataIndex === col.dataIndex
            ).length;
            if (notExistInUserOrderColumns) {
                otherHiddenColumns.push({
                    viewColumnId: col.viewColumnId,
                    width: col.width,
                    isHidden: true,
                });
            }
        });
    }
    return otherHiddenColumns;
};

export { addOtherHiddenColumns };
