import React from "react";
import { Route, Switch } from "react-router-dom";
import Config from "../../../../config";
import NotFound from "components/alwaysOnComponents/errors/404";
import SelfAssessment from "components/contentcomponents/compliance/selfAssessment/selfAssessment";
import GuestRegistrationModal from "components/contentcomponents/ncr/overview/modals/registration/guestRegistrationModal";
import GuestDashBoard from "components/contentcomponents/analytics/dashboard/guestDashBoard";
import SubmissionMatrix from "components/contentcomponents/reviews/survey/submission/submissionMatrix";
import { default as ReviewGuestModal } from "components/contentcomponents/reviews/review/modals/registration/guestRegistrationModal";
import ComplianceGuestRegistration from "components/contentcomponents/compliance/overview/registrationModal/complianceGuestRegistration";
import { GuestMeetingMinutePage } from "components/contentcomponents/suppliers/supplierProfile/enterprisePages/governance/meetingCalendar/meetingMinuteGuestPage";
import { GuestActionPage } from "components/contentcomponents/action/overview/guestActionPage";

const components: any = {
    SelfAssessment: SelfAssessment,
    "Guest/Compliance": ComplianceGuestRegistration,
    "Reviews/Survey": SubmissionMatrix,
    "Guest/Ncr": GuestRegistrationModal,
    "Guest/Analytics": GuestDashBoard,
    "Guest/Review": ReviewGuestModal,
    "Guest/MeetingMinutes": GuestMeetingMinutePage,
    "Guest/Actions": GuestActionPage,
};

const GuestRoutes = () => {
    return (
        <Switch>
            {Config.guestRoutes.map((r: any, index: number) => {
                return (
                    <Route key={index} path={`/${r}/:guestToken/id/:cid?`}>
                        {React.createElement(components[r])}
                    </Route>
                );
            })}
            {Config.guestRoutes.map((r: any, index: number) => {
                return (
                    <Route key={index} exact path={`/${r}/:guestToken`}>
                        {React.createElement(components[r])}
                    </Route>
                );
            })}
            <Route component={NotFound} />
        </Switch>
    );
};

export default GuestRoutes;
