import React, { FunctionComponent, Fragment } from "react";
import PartnerOverview from "../contentcomponents/suppliers/partnerOverview";

export const Suppliers: FunctionComponent = () => {
    return (
        <Fragment>
            <PartnerOverview />
        </Fragment>
    );
};
export default Suppliers;
