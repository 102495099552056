import { get, post } from "../base";
import { ITableType } from "models/tables/ITableType";

const ColumnFilters = {
    getColumnFilters: async (table: ITableType) => {
        const url = `/v1/ColumnFilters?Table=${table}`;
        return await get({ url });
    },
    setColumnFilters: async (filters: any[], table: ITableType) => {
        const url = `/v1/ColumnFilters?Table=${table}`;
        return await post({ url, payload: filters });
    },
};

export default ColumnFilters;
