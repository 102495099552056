import { useState, useEffect } from "react";
import Provider from "services/api";

const useProductHasDocumentation = (internalProductId: number | undefined) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: true,
    });

    useEffect(() => {
        (async () => {
            if (internalProductId === undefined) return;
            setState({ ...state, loading: true });
            let hasDocumentation = await Provider.Product.hasProductDocumentation(
                internalProductId,
                {
                    customError: {
                        message: "Error checking if product has documentation",
                    },
                }
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data: hasDocumentation });
        })();
    }, [internalProductId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useProductHasDocumentation };
