import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ActivityType, IActivityChild } from "models";
import { useTranslation } from "react-i18next";
import { ShowMore } from "components/utilitycomponents";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";

type PropsType = {
    data: IActivityChild;
};

const MessageChild: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    let type = "",
        link = "",
        linkText = "";
    switch (data.parentTypeName) {
        case ActivityType.Compliance:
            type = "a compliance";
            link = `/Compliance/${data.entityId}`;
            linkText = "View Compliance";
            break;
        case ActivityType.Ncr:
            type = "a nonconformance report";
            link = `/Ncrs/${data.entityId}`;
            linkText = "View NCR";
            break;
        case ActivityType.Review:
            type = "a review";
            link = `/Reviews/reviewId/${data.entityId}`;
            linkText = "View Review";
            break;
        case ActivityType.Project:
            type = "a project";
            link = `/Project/${data.entityId}`;
            linkText = "View Project";
            break;
        case ActivityType.Message:
            type = "a message";
            break;
        default:
            type = "an activity";
            break;
    }
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {t("notification_AddComment", { creator: data.creator_FullName, type: type })}
            </div>
            {link ? (
                <Link className={classes.notificationLink} to={link}>
                    <RightCircleFilled />
                    {linkText}
                </Link>
            ) : (
                <ShowMore
                    showGuidAlign="left"
                    text={data.message}
                    characterLimit={50}
                    lineLimit={1}
                />
            )}
        </span>
    );
};

export { MessageChild };
