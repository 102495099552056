import { useState } from "react";
import { Provider } from "services";
import { ComplianceVisibility } from "models";

const useComplianceVisibilityUpdate = () => {
    const [loading, setLoading] = useState(false);
    const update = async (ids: number[], visibility: ComplianceVisibility) => {
        setLoading(true);
        const id = await Provider.Compliance.updateVisibility(ids, visibility, {
            customError: {
                message: "Could not update the visibility of the registration(s)",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };
    return {
        loading,
        update,
    };
};

export { useComplianceVisibilityUpdate };
