import { useEffect, useState } from "react";
import Provider from "services/api";

const useFile = (fileId: number) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (fileId) {
                setState({ ...state, loading: true });
                const data = await Provider.File.getFileDetail(fileId, {
                    customError: {
                        message: "Could not retrieve file settings",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data });
            }
        })();
    }, [fileId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useFile };
