import { patch, post, put, remove } from "../base";
import { IRisk, ITableInput, RiskState } from "models";

const Risks = {
    getRisks: async (partnerId: number, tableInput: ITableInput, { customError }: any = {}) => {
        const url = `/v1/RiskAndIssue/GetRisksAndIssues`;
        return await post({ url, payload: { ...tableInput, partnerId }, customError });
    },
    createRisk: async (risk: IRisk, { customError }: any = {}) => {
        const url = `/v1/RiskAndIssue/AddRiskAndIssue`;
        return await post({ url, payload: risk, customError });
    },
    updateRisk: async (risk: IRisk, { customError }: any = {}) => {
        const url = `/v1/RiskAndIssue/UpdateRiskAndIssue/${risk.id}`;
        return await put({ url, payload: risk, customError });
    },
    deleteRisk: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/RiskAndIssue/DeleteRisksAndIssues`;
        return await remove({ url, payload: ids, customError });
    },
    updateRiskState: async (id: number, state: RiskState, { customError }: any = {}) => {
        const url = `/v1/RiskAndIssue/UpdateRiskAndIssueState/${id}?state=${state}`;
        return await patch({ url, payload: state, customError });
    },
};

export { Risks };
