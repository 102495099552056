import React, { FunctionComponent, useState, useEffect } from "react";
import { Modal, Timeline, Spin, Row, Col, Alert } from "antd";
import "./modal-statusLog.css";
import { useTranslation } from "react-i18next";
import { usePartnerStatusLogs } from "controller";
import dayjs from "utilities/daysJsConfig";
interface ModalProps {
    visible: boolean;
    selectedRow: any;
    setModalVisible(value: boolean): void;
}

export const ModalStatusLog: FunctionComponent<ModalProps> = (props: ModalProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const [companyName, setCompanyName] = useState("");
    const { data: logs, loading } = usePartnerStatusLogs(
        props.selectedRow ? props.selectedRow.id.value : undefined,
        reload
    );

    useEffect(() => {
        if (props.visible) {
            setReload({ reload: true });
            setCompanyName(props.selectedRow.company.value);
        }
    }, [props.visible]);

    function closeModal() {
        props.setModalVisible(false);
    }

    function renderTimelineItems() {
        return logs.map((log: any, index: number) => {
            return (
                <Timeline.Item key={log.id} color={index % 2 === 0 ? "#05B4FF" : "#07A3FF"}>
                    <Row>
                        <Col span={14}>{`${log.description}`}</Col>
                        <Col className="dateTimeRow" span={10}>{`${dayjs(log.createdDate).format(
                            "MMM D, YYYY (HH:mm:ss A)"
                        )}`}</Col>
                    </Row>
                </Timeline.Item>
            );
        });
    }

    return (
        <Modal
            open={props.visible}
            onCancel={() => closeModal()}
            title={`${t("statusLogTitle")} ${companyName}`}
            width={700}
            footer={null}>
            <Spin spinning={loading}>
                <div>
                    {logs.length > 0 ? (
                        <div className="timelineContainer">
                            <Timeline>{renderTimelineItems()}</Timeline>
                        </div>
                    ) : (
                        <Alert type="info" message={t("statusLogEmtpty")} showIcon />
                    )}
                </div>
            </Spin>
        </Modal>
    );
};

export default ModalStatusLog;
