import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any;
    loading: boolean;
};

const useSummaryReport = (reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data = await Provider.Company.getSummaryReport();
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSummaryReport };
