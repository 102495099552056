import { useEffect, useState } from "react";
import Provider from "services/api";

const useAvailableDashboards = (reloadPresets: { reload: boolean }, sort: boolean = false) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            const data: any = await Provider.Analytics.getAvailablePresets({
                retryCount: 3,
                customError: { message: "Could not retrieve list of presets" },
            }).catch(err => {
                setState({ loading: false, data: {} });
                return Promise.reject(err);
            });

            setState({
                loading: false,
                data: sort ? data.sort((a: any, b: any) => sortDashboard(a, b)) : data,
            });
        })();
    }, [reloadPresets]);

    return {
        loading: state.loading,
        data: state.data,
    };

    function sortDashboard(a: any, b: any) {
        if (a && b) {
            if (a.name > b.name) {
                return 1;
            } else {
                return -1;
            }
        } else {
            return 1;
        }
    }
};

export { useAvailableDashboards };
