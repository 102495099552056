import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "store";

const PartnerLink = ({ partnerId, partnerName }: any) => {
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const hasPartnerAccess =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Partner"]) === "true"
            ? true
            : false;
    return hasPartnerAccess && userType !== "lightuser" ? (
        <Link to={`/Partners/SupplierProfile/0/${partnerId}`}>{partnerName}</Link>
    ) : (
        <span>{partnerName}</span>
    );
};

export { PartnerLink };
