import { get, put, post, remove } from "../base";

const Dashboard = {
    getPreset: async (
        presetId: number,
        supplierId: number,
        companyId: number,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Preset?presetId=${presetId}&supplierId=${supplierId}&companyId=${companyId}`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
        return response;
    },
    getAvailablePresets: async ({ retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/Presets`;
        let response = await get({ url, retryCount: retryCount ? retryCount : 3, customError });

        return response;
    },
    getAvailableSharedPresets: async ({ retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/SharedPresets`;
        let response = await get({ url, retryCount: retryCount ? retryCount : 3 });

        return response;
    },
    updateLayout: async (
        presetId: number,
        layout: any[],
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Layout?presetId=${presetId}`;
        await put({ url, payload: layout, retryCount: retryCount ? retryCount : 3, customError });
    },
    addDashboard: async (
        supplierId: number,
        creationData: any,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Preset?supplierId=${supplierId}`;
        const response = await post({
            url,
            payload: creationData,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
        return response;
    },
    updateDashboard: async (
        presetId: number,
        metaData: any,
        supplierId?: number,
        benchmarkId?: number,
        { retryCount = 3, customError }: any = {}
    ) => {
        if (benchmarkId) {
            const url = `/v1/DashboardAnalytics/Preset?presetId=${presetId}&supplierId=${supplierId}&benchmarkId=${benchmarkId}`;
            await put({
                url,
                payload: metaData,
                retryCount: retryCount ? retryCount : 3,
                customError,
            });
        } else if (supplierId) {
            const url = `/v1/DashboardAnalytics/Preset?presetId=${presetId}&supplierId=${supplierId}`;
            await put({
                url,
                payload: metaData,
                retryCount: retryCount ? retryCount : 3,
                customError,
            });
        } else {
            const url = `/v1/DashboardAnalytics/Preset?presetId=${presetId}`;
            await put({
                url,
                payload: metaData,
                retryCount: retryCount ? retryCount : 3,
                customError,
            });
        }
    },
    //add a box to the grid layout
    addReport: async (
        presetId: number,
        creationData: any,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Report?presetId=${presetId}`;
        await post({
            url,
            payload: creationData,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
    },
    updateReport: async (
        reportId: number,
        updateData: any,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Report?reportId=${reportId}`;
        await put({
            url,
            payload: updateData,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
    },
    getReport: async (
        supplierId: number,
        reportId: number,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Report?supplierId=${supplierId}&reportId=${reportId}`;
        const response = await get({ url, retryCount: retryCount ? retryCount : 3, customError });
        return response;
    },
    updateReportFilter: async (
        reportId: number,
        filterData: any,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Filter?reportId=${reportId}`;
        await put({
            url,
            payload: filterData,
            retryCount: retryCount ? retryCount : 3,
            customError,
        });
    },
    removeReport: async (reportId: number, { retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/Report?reportId=${reportId}`;
        await remove({ url, retryCount: retryCount ? retryCount : 3, customError });
    },
    removeDashboard: async (presetId: number, { retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/Preset?presetId=${presetId}`;
        await remove({ url, retryCount: retryCount ? retryCount : 3, customError });
    },
    getDefaultDashboardId: async ({ retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/Preset/Default`;
        const response = await get({ url, retryCount: retryCount ? retryCount : 3, customError });
        return response;
    },
    setDefaultDashboard: async (presetId: number, { retryCount = 3, customError }: any = {}) => {
        const url = `/v1/DashboardAnalytics/Preset/UserDefault?presetId=${presetId}`;
        await post({ url, payload: {}, retryCount: retryCount ? retryCount : 3, customError });
    },
    setDefaultCompanyDashboard: async (
        presetId: number,
        { retryCount = 3, customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalytics/Preset/CompanyDefault?presetId=${presetId}`;
        await post({ url, payload: {}, retryCount: retryCount ? retryCount : 3, customError });
    },
    getAnalyticsGuestDashboard: async (guestToken: string, { customError }: any = {}) => {
        const url = `/v1/DashboardAnalyticsGuest/GuestPreset?guestTokenGuid=${guestToken}`;
        return await get({ url, customError });
    },
    getAnalyticsGuestToken: async (
        presetId: number,
        supplierId: number,
        companyId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalyticsGuest/GuestSharePreset?presetId=${presetId}&supplierId=${supplierId}&companyId=${companyId}`;
        return await post({ url, customError });
    },
    sendGuestReport: async (guestToken: string, { customError }: any = {}) => {
        const url = `/v1/DashboardAnalyticsGuest/GuestSharePreset/SendMail?guestTokenGuid=${guestToken}`;
        return await post({ url, customError });
    },
    getSharedDashboardGuestTokenInfo: async (
        partnerId: number,
        companyId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/DashboardAnalyticsGuest/GuestSharedPresets?companyId=${companyId}&partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    removeSharedDashboardGuestToken: async (tokenIds: number[], { customError }: any = {}) => {
        const url = `/v1/DashboardAnalyticsGuest/GuestSharedPresets`;
        return await remove({ url, payload: tokenIds, customError });
    },
};

export default Dashboard;
