import React, { useState, useEffect, FunctionComponent } from "react";
import { Select, Input, notification, Form, Spin } from "antd";
import { SelectTreeData } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { SelectExternalPartner, AttachFile, Star } from "components/utilitycomponents";
import classes from "./styles/createForm.module.scss";
import { getCategoryStarText } from "../../../criteriaStarTexts";
import { useCompanyType, useReviewCategory, useReviewDefaultCreationValues } from "controller";
const Option = Select.Option;
const TextArea = Input.TextArea;

type PropTypes = {
    onSubmit: Function;
    form: any;
    createReview: Function;
    partnerId?: any;
    disableCriteriaSelection?: boolean;
};

const CreateForm: FunctionComponent<PropTypes> = ({
    onSubmit,
    form,
    createReview,
    partnerId,
    disableCriteriaSelection = false,
}) => {
    const { t } = useTranslation();
    const [selectedPartner, setSelectedPartner] = useState<any>(undefined);
    const [selectedRating, setSelectedRating] = useState<any>(0);
    const [selectedCriteria, setSelectedCriteria] = useState<any>(undefined);
    const { data: defaultValues, loading: defaultValuesLoading } = useReviewDefaultCreationValues();
    const { data: categories, loading: categoriesLoading } = useReviewCategory();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        form.resetFields();
    }, []);

    useEffect(() => {
        if (partnerId) {
            setSelectedPartner({ id: partnerId });
        }
    }, [partnerId]);

    useEffect(() => {
        form.setFieldsValue({
            reviewCriteria:
                defaultValues.defaultCriteria !== -1 ? defaultValues.defaultCriteria : undefined,
            visibility: defaultValues.defaultReviewVisibility,
        });

        if (defaultValues.defaultCriteria && defaultValues.defaultCriteria !== -1) {
            let defaultCriteria = undefined;
            for (let index = 0; index < categories.length; index++) {
                const category = categories[index];
                if (category.children) {
                    defaultCriteria = category.children.find(
                        (c: any) => c.id === defaultValues.defaultCriteria
                    );
                    if (defaultCriteria) break;
                } else if (category.id === defaultValues.defaultCriteria) {
                    defaultCriteria = category;
                    break;
                }
            }
            setSelectedCriteria(defaultCriteria);
        }
    }, [defaultValues, categories]);

    const handleSubmit = async () => {
        const values = await form.validateFields();
        await createReview({
            partnerId: selectedPartner && selectedPartner.id,
            reviewedPartnerName: selectedPartner && selectedPartner.name,
            notes: values.notes,
            rating: values.reviewRating,
            reviewCategoryId: values.reviewCriteria,
            visibility: values.visibility,
            attachmentIds: values.attachmentIds,
        });
        notification.success({
            message: "Success",
            description: `Your review was created successfully`,
        });
        onSubmit();
    };

    return (
        <Spin spinning={defaultValuesLoading || categoriesLoading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                labelAlign="left">
                <Form.Item
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }
                    className={classes.formItem}
                    name="externalPartner"
                    required
                    initialValue={partnerId}
                    rules={[
                        {
                            required: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}>
                    <SelectExternalPartner
                        allowClear
                        placeholder={`${t("select")} ${
                            isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer")
                        }`}
                        onChange={(val: any, item: any) => {
                            setSelectedPartner(item);
                        }}
                        includeChildren
                    />
                </Form.Item>

                <Form.Item
                    label={t("review_Criteria")}
                    required
                    className={classes.formItem}
                    name="reviewCriteria"
                    initialValue={
                        defaultValues.defaultCriteria !== -1
                            ? defaultValues.defaultCriteria
                            : undefined
                    }
                    rules={[
                        {
                            required: true,
                            message: t("inputIsRequiredErrorWithName", {
                                name: t("review_Criteria"),
                            }),
                        },
                    ]}
                    extra={selectedCriteria !== undefined ? selectedCriteria.description : ""}>
                    <SelectTreeData
                        selectIdField="id"
                        useData={() => {
                            return { data: categories, loading: false };
                        }}
                        selectOptionField="name"
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                        placeholder={`${t("select")} ${t("review_Criteria")}`}
                        onChange={(val: any, valItem: any) =>
                            setSelectedCriteria(valItem && valItem)
                        }
                        selectOnlyChildren
                        disabled={disableCriteriaSelection}
                    />
                </Form.Item>

                <Form.Item
                    label={t("review_Rating")}
                    required
                    className={classes.formItem}
                    name="reviewRating"
                    rules={[
                        {
                            required: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}
                    extra={getCategoryStarText(selectedRating, selectedCriteria)}>
                    <Star onHover={(val: number) => setSelectedRating(val)} />
                </Form.Item>

                <Form.Item
                    label={t("review_Notes")}
                    data-test="create_review_comment_textarea"
                    className={classes.formItem}
                    name="notes">
                    <TextArea maxLength={1000} />
                </Form.Item>

                <Form.Item
                    label={t("review_Visibility")}
                    className={classes.formItem}
                    name="visibility"
                    initialValue={defaultValues.defaultReviewVisibility}>
                    <Select
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }>
                        <Option key={0} value={1}>
                            {t("internal")}
                        </Option>
                        <Option key={1} value={2}>
                            {t("external")}
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("review_Attachments")}
                    data-test="create_attached_files"
                    className={classes.formItem}
                    name="attachmentIds">
                    <AttachFile maxFileSize={40} />
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateForm;
