import { useEffect, useState } from "react";
import Provider from "services/api";

const usePerformanceAnswersByGroup = (
    criteriaGroupId: number | undefined,
    surveyId: number,
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        customFields: [],
    });

    useEffect(() => {
        (async () => {
            setState({
                loading: true,
                data: state.data,
                totalRows: state.totalRows,
                customFields: state.totalRows,
            });

            if (!externalLoading) {
                if (criteriaGroupId !== undefined) {
                    const result: any = await Provider.Performance.getPerformanceSurveyAnswersByGroup(
                        criteriaGroupId,
                        surveyId,
                        {
                            pageSize,
                            pageNumber,
                            filters,
                            sorts,
                        },
                        {
                            customError: {
                                message: "Could not retrieve list of performance units",
                            },
                        }
                    ).catch(err => {
                        setState({ ...state, loading: false });
                        return Promise.reject(err);
                    });

                    setState({
                        loading: false,
                        data: result.data,
                        totalRows: result.totalNumberOfRecords,
                    });
                }
            }
        })();
    }, [
        pageNumber,
        pageSize,
        filters,
        sorts,
        reloadData,
        externalLoading,
        range,
        criteriaGroupId,
        surveyId,
    ]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        customFields: state.customFields,
    };
};

export { usePerformanceAnswersByGroup };
