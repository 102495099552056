import { post, get, put } from "../base";

const AdminNcr = {
    getAdminNcrReasons: async ({ customError }: any = {}) => {
        const url = `/v1/AdminNcr/GetNcrReasons`;
        return await get({ url, customError });
    },
    setAdminReasons: async (reasons: any, { customError }: any = {}) => {
        const url = `/v1/AdminNcr/SetNcrReasonStatus`;
        return await post({ url, payload: reasons, customError });
    },
    getAdminNcrFields: async ({ customError }: any = {}) => {
        const url = `/v1/AdminNcr/GetNcrFields`;
        return await get({ url, customError });
    },
    updateNcrFields: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/AdminNcr/SubmitNcrFields`;
        return await put({ url, payload, customError });
    },
};

export { AdminNcr };
