import { put, get } from "../base";

const AdminExport = {
    getAllExportPrivilegeOptions: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetExportPrivilegeOptions`;
        return await get({ url, customError });
    },
    updateExportPrivilegeOption: async (
        id: number,
        hasAccess: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminCompany/UpdateExportPrivilege`;
        await put({ url, payload: { id: id, hasPrivilege: hasAccess }, customError });
    },
};

export { AdminExport };
