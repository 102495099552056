import { useState } from "react";
import { Provider } from "services";

const useAutomatedSurveyUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateAutomatedSurvey = async (
        autoSurveyId: number,
        frequency: number,
        isActive: boolean
    ) => {
        setLoading(true);
        await Provider.AutomatedSurvey.updateAutomatedSurvey(
            autoSurveyId,
            frequency,
            isActive
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return { loading, updateAutomatedSurvey };
};

export { useAutomatedSurveyUpdate };
