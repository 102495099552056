import { useEffect, useState } from "react";
import { Provider } from "services";
import { IExternalPartner } from "models";

type StateType = {
    data: IExternalPartner[];
    loading: boolean;
};

const usePartnerList = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data = await Provider.Partner.getPartnerList();
            setState({
                data,
                loading: false,
            });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerList };
