import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Col,
    Form,
    Modal,
    Row,
    Tooltip,
    notification,
    Steps,
    Button,
    Divider,
    Flex,
} from "antd";
import {
    SelectExternalPartner,
    SelectTreeDataMultiple,
    SelectPartnerContacts,
    RichTextEditor,
    InfoCircle,
} from "components/utilitycomponents";
import { useSendSelfAssessment, useDefaultContacts, useProductTemplates } from "controller";
import { useTranslation } from "react-i18next";
import classes from "./requestProductInfo.module.scss";
import useProductsByPartner from "controller/product/useProductsByPartner";

interface PropsType {
    visible: boolean;
    onClose: Function;
}

const RequestProductInfoModal = ({ visible, onClose }: PropsType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const { sendSelfAssessments, loading: sendSALoading } = useSendSelfAssessment();
    const [addCustomMsg, setAddCustomMsg] = useState<boolean>(false);
    const [selectedPartner, setSelectedPartner] = useState<any | undefined>(undefined);
    const { data: defaultContactReceivers } = useDefaultContacts(
        selectedPartner && selectedPartner.id,
        "ComplianceContact"
    );
    const [currentStep, setCurrentStep] = useState<number>(0);
    const selectedContacts = Form.useWatch("compliancePartnerContactReceiver", form);
    const { data: products, loading: loadingProducts } = useProductsByPartner(
        selectedPartner && selectedPartner.id,
        false,
        true
    );
    const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [selectedReceivers, setSelectedReceivers] = useState<any[]>([]);

    useEffect(() => {
        form.setFieldValue(
            "compliancePartnerContactReceiver",
            defaultContactReceivers.map((contact: any) => contact.id)
        );
        setSelectedReceivers(defaultContactReceivers.map((contact: any) => contact.id));
    }, [defaultContactReceivers]);

    useEffect(() => {
        if (visible) {
            setReload({ reload: true });
        }
        form.resetFields();
    }, [visible]);

    const onCancel = () => {
        form.resetFields();
        setAddCustomMsg(false);
        setSelectedPartner(undefined);
        setSelectedTemplates([]);
        setSelectedProducts([]);
        setSelectedReceivers([]);
        setCurrentStep(0);
        onClose();
    };

    const onSubmit = async () => {
        let values = await form.validateFields();
        await sendSelfAssessments({
            templateIds: values.templates,
            partnerId: values.partner,
            multipleAssesmentsEnabled: false,
            hasAdditionalText: values.addCustomMsg,
            additionalText: values.customMsg,
            compliancePartnerContactReceiverIds: values.compliancePartnerContactReceiver
                ? [...new Set(values.compliancePartnerContactReceiver)]
                : [],
            productIds: values.products,
        });
        notification.success({
            message: "Success",
            description: t("compliance_sendSA_Success"),
        });
        onCancel();
    };

    const setBtnDisabled = () => {
        let disabled = false;
        if (currentStep === 0) {
            disabled = selectedPartner === undefined;
        } else if (currentStep === 1) {
            disabled = selectedTemplates.length === 0;
        } else if (currentStep === 2) {
            disabled = selectedProducts.length === 0;
        } else if (currentStep === 3) {
            disabled = selectedReceivers.length === 0;
        }
        return disabled;
    };

    return (
        <Modal
            title={
                <Divider
                    orientation="left"
                    orientationMargin={0}
                    children={t("product_RequestProductInfo")}
                />
            }
            closeIcon={null}
            open={visible}
            onCancel={() => onCancel()}
            style={{ top: 20 }}
            okText={t("compliance_CreateAndSendSA")}
            onOk={onSubmit}
            okButtonProps={{
                disabled: selectedContacts === undefined || selectedContacts.length === 0,
            }}
            confirmLoading={sendSALoading}
            cancelText={t("generic_Cancel")}
            width={800}
            footer={[
                currentStep !== 0 ? (
                    <Button
                        onClick={() => {
                            currentStep === 0 ? onCancel() : setCurrentStep(currentStep - 1);
                        }}>
                        {currentStep === 0 ? t("generic_Close") : t("generic_Back")}
                    </Button>
                ) : null,
                <Button
                    loading={sendSALoading}
                    type={currentStep === 3 ? "primary" : "default"}
                    disabled={setBtnDisabled()}
                    onClick={() => {
                        currentStep === 3 ? onSubmit() : setCurrentStep(currentStep + 1);
                    }}>
                    {currentStep === 3 ? t("compliance_CreateAndSendSA") : t("generic_Next")}
                </Button>,
            ]}>
            <Form form={form} onFinish={onSubmit} layout="vertical">
                <Row gutter={16}>
                    <Col span={8}>
                        <Flex justify="space-evenly">
                            <Steps
                                onChange={(current: number) => setCurrentStep(current)}
                                direction="vertical"
                                size="small"
                                current={currentStep}
                                items={[
                                    {
                                        title: (
                                            <div className={classes.stepTitle}>
                                                {t("select")} {t("supplier")}
                                            </div>
                                        ),
                                        description:
                                            selectedPartner !== undefined &&
                                            `${selectedPartner && selectedPartner.name}`,
                                    },
                                    {
                                        title: (
                                            <div className={classes.stepTitle}>
                                                {t("admin_RequestInformation_SelectTemplate")}
                                            </div>
                                        ),
                                        description: (
                                            <>
                                                {selectedTemplates.map((template: any) => (
                                                    <Row>{template.name}</Row>
                                                ))}
                                            </>
                                        ),
                                        disabled: selectedPartner === undefined,
                                    },
                                    {
                                        title: (
                                            <div className={classes.stepTitle}>
                                                {t("select")} {t("product_Products")}
                                            </div>
                                        ),
                                        description: (
                                            <>
                                                {selectedProducts.map((item: any) => (
                                                    <Row>{item.name}</Row>
                                                ))}
                                            </>
                                        ),
                                        disabled:
                                            selectedTemplates === undefined ||
                                            selectedTemplates.length === 0,
                                    },
                                    {
                                        title: (
                                            <div className={classes.stepTitle}>
                                                {t("product_RequestInfo_SelectReceivers")}
                                            </div>
                                        ),
                                        description: (
                                            <>
                                                {selectedReceivers.map((item: any) => (
                                                    <Row>{item.name}</Row>
                                                ))}
                                            </>
                                        ),
                                        disabled:
                                            selectedProducts === undefined ||
                                            selectedProducts.length === 0,
                                    },
                                ]}
                            />
                            <Divider type="vertical" plain style={{ height: "inherit" }} />
                        </Flex>
                    </Col>
                    <Col span={16}>
                        <Row className={currentStep !== 0 ? classes.hidden : ""}>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <div className={classes.formItemLabel}>
                                            {t("product_RequestInfo_SelectPartnerTip")}
                                        </div>
                                    }
                                    required={false}
                                    className={classes.formItem}
                                    name="partner"
                                    valuePropName="value"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("compliance_PartnerRequired"),
                                        },
                                    ]}>
                                    <SelectExternalPartner
                                        placeholder={t("compliance_SelectPartnerPlaceholder")}
                                        onChange={(value: number | undefined, partner: any) => {
                                            setSelectedPartner(partner);
                                            setSelectedProducts([]);
                                            setSelectedReceivers([]);
                                        }}
                                        showCreationButton={false}
                                        withEmail={false}
                                        withIsLinked={true}
                                        withContacts={false}
                                        includeChildren={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={currentStep !== 1 ? classes.hidden : ""}>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <div className={classes.formItemLabel}>
                                            {t("product_RequestInfo_SelectTemplateTip")}
                                        </div>
                                    }
                                    required={false}
                                    className={classes.formItem}
                                    name="templates"
                                    valuePropName="value"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("compliance_TemplateRequired"),
                                        },
                                    ]}>
                                    <SelectTreeDataMultiple
                                        placeholder={t("compliance_SelectTemplatePlaceholder")}
                                        className={classes.select}
                                        selectOptionField="name"
                                        useData={useProductTemplates.bind(null, reload)}
                                        onChange={(_: any, templates: any[]) =>
                                            setSelectedTemplates(templates)
                                        }
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        selectOnlyChildren
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={currentStep !== 2 ? classes.hidden : ""}>
                            <Col span={24}>
                                <Form.Item
                                    preserve
                                    label={
                                        <div className={classes.formItemLabel}>
                                            {t("product_RequestInfo_SelectProductsTip")}
                                        </div>
                                    }
                                    required={false}
                                    className={classes.formItem}
                                    name="products"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}>
                                    <SelectTreeDataMultiple
                                        disabled={selectedPartner === undefined}
                                        placeholder={t("select")}
                                        selectOnlyChildren
                                        selectOptionField="name"
                                        selectIdField="id"
                                        onChange={(value: number | undefined, elements: any[]) =>
                                            setSelectedProducts(elements)
                                        }
                                        useData={() => ({
                                            data: products,
                                            loading: loadingProducts,
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className={currentStep !== 3 ? classes.hidden : ""}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        preserve
                                        required={false}
                                        name="compliancePartnerContactReceiver"
                                        label={
                                            <div>
                                                <span className={classes.formItemLabel}>
                                                    {t("product_RequestInfo_SelectReceiverTip")}{" "}
                                                </span>
                                                <Tooltip
                                                    title={t("compliance_RequestReceiversTip")}>
                                                    <span>
                                                        <InfoCircle />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        initialValue={defaultContactReceivers}
                                        className={classes.formItem}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("inputIsRequiredError"),
                                            },
                                        ]}>
                                        <SelectPartnerContacts
                                            onChange={(
                                                values: any[] | undefined,
                                                elements: any[]
                                            ) => setSelectedReceivers(elements)}
                                            partnerId={selectedPartner && selectedPartner.id}
                                            disabled={
                                                selectedPartner === undefined ||
                                                selectedPartner === null
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        className={classes.formItem}
                                        name="addCustomMsg"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Checkbox
                                            onChange={(e: any) =>
                                                setAddCustomMsg(e.target.checked)
                                            }>
                                            {t("compliance_AddCustomMsg")}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {addCustomMsg === true && (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name="customMsg" initialValue={""}>
                                            <RichTextEditor
                                                placeholder={t("compliance_InsertText")}
                                                maxLength={1000}
                                                bordered={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export { RequestProductInfoModal };
