import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Divider, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import { IComplianceHeadLine } from "models";
import { TypedQuestion } from "../sharedComponents";
import classes from "./styles/edit.module.scss";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;

interface IProps {
    data: IComplianceHeadLine[];
    onAttachDocumentLoading?: any;
    form: any;
}

const TemplateHeadLines = ({ form, data, onAttachDocumentLoading }: IProps) => {
    const { t } = useTranslation();
    const [fileQuestionsLoading, setFileQuestionsLoading] = useState<any[]>([]);

    useEffect(() => {
        onAttachDocumentLoading && onAttachDocumentLoading(fileQuestionsLoading);
    }, [fileQuestionsLoading]);

    return (
        <Fragment>
            {data.map((headLine, index) => (
                <Fragment key={headLine.id}>
                    <Divider
                        orientation="left"
                        orientationMargin={0}
                        style={{ fontWeight: "bold" }}>
                        {index + 1}. {headLine.headline}
                    </Divider>
                    {headLine.complianceQuestions &&
                        headLine.complianceQuestions.map((question, questionIndex) => {
                            const options: any = {
                                rules: [
                                    {
                                        required:
                                            question.isMandatory &&
                                            question.questionType !== "Check"
                                                ? question.isMandatory
                                                : false,
                                        message: t("inputIsRequiredError"),
                                    },
                                ],
                            };
                            let answerId = 0,
                                answerValue = undefined,
                                answerComment = undefined,
                                answerValueMultiple = undefined;
                            if (question.complianceAnswers.length !== 0) {
                                answerId = question.complianceAnswers[0].id;
                                answerValue = question.complianceAnswers[0].value;
                                answerComment = question.complianceAnswers[0].comment;
                                answerValueMultiple =
                                    question.complianceAnswers[0].multipleChoiceQuestionValue;
                            }
                            if (answerValue) {
                                if (question.questionType === "Date") {
                                    options["initialValue"] = dayjs(answerValue);
                                } else if (question.questionType === "Check") {
                                    options["valuePropName"] = "value";
                                    options["initialValue"] = answerValue === "true";
                                } else if (question.questionType === "AttachDocuments") {
                                    if (
                                        question.complianceAnswers[0] &&
                                        question.complianceAnswers[0].files
                                    ) {
                                        options[
                                            "initialValue"
                                        ] = question.complianceAnswers[0].files.map(
                                            (file: any) => ({
                                                id: file.id,
                                                name: file.name,
                                            })
                                        );
                                    }
                                } else options["initialValue"] = answerValue;
                            }
                            if (answerValueMultiple) {
                                options["initialValue"] = answerValueMultiple.split("|");
                            }

                            return (
                                <Row gutter={16} style={{ marginBottom: "22px" }} key={question.id}>
                                    <Col lg={16} md={16} sm={24} xs={24}>
                                        <Form.Item
                                            labelCol={{ lg: 16, md: 17, sm: 24, xs: 24 }}
                                            wrapperCol={{ lg: 8, md: 9, sm: 24, xs: 24 }}
                                            label={
                                                <>
                                                    <div className={classes.question}>
                                                        {index + 1}.{questionIndex + 1}.
                                                        {question.isMandatory &&
                                                            question.isMandatory && (
                                                                <span className={classes.required}>
                                                                    *
                                                                </span>
                                                            )}{" "}
                                                        {question.question}
                                                    </div>
                                                    <div>{question.guideline}</div>
                                                </>
                                            }
                                            name={`question[${question.id}]answer[${answerId}]`}
                                            initialValue={options.initialValue}
                                            valuePropName={options.valuePropName}
                                            rules={options.rules}>
                                            <TypedQuestion
                                                question={question}
                                                onUploadingFile={(uploading: boolean) => {
                                                    let index = fileQuestionsLoading.findIndex(
                                                        (x: any) =>
                                                            Object.keys(x)[0] ===
                                                            question.id.toString()
                                                    );
                                                    if (index === -1) {
                                                        setFileQuestionsLoading([
                                                            ...fileQuestionsLoading,
                                                            { [`${question.id}`]: uploading },
                                                        ]);
                                                    } else {
                                                        let newVals = fileQuestionsLoading
                                                            .slice(0)
                                                            .filter(
                                                                (x: any) =>
                                                                    Object.keys(x)[0] !==
                                                                    question.id.toString()
                                                            );
                                                        newVals.splice(index, 0, {
                                                            [`${question.id}`]: uploading,
                                                        });
                                                        setFileQuestionsLoading(newVals);
                                                    }
                                                }}
                                                loadFiles={{ load: true }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            name={`comment[${question.id}]`}
                                            initialValue={answerComment}>
                                            <TextArea
                                                maxLength={2000}
                                                rows={4}
                                                placeholder={t("compliance_AddComment")}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                </Fragment>
            ))}
        </Fragment>
    );
};

export default TemplateHeadLines;
