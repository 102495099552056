import React, { FunctionComponent, useCallback, useState } from "react";
import classnames from "classnames";
import { Popconfirm, Popover, Progress, Typography } from "antd";
import { useFileDownload } from "controller";
import { showDownloadDialog } from "utilities";
import classes from "./styles/downloadFile.module.scss";
import { useTranslation } from "react-i18next";
import { FileDownload03 } from "components/utilitycomponents/icons";
const { Text } = Typography;

type PropTypes = {
    file: { id: number; name: string };
    hideIcon?: boolean;
    kpiDataSpecificationSample?: boolean;
    contactUploadTemplateSample?: boolean;
    partnerFileTemplate?: boolean;
    title?: string | undefined;
    ellipsis?: boolean;
    [key: string]: any;
};

const DownloadFile: FunctionComponent<PropTypes> = ({
    file,
    hideIcon = false,
    children = null,
    kpiDataSpecificationSample = false,
    contactUploadTemplateSample = false,
    partnerFileTemplate = false,
    title = undefined,
    ellipsis = false,
    ...rest
}) => {
    const {
        downloadFileLoading,
        downloadFileProgress,
        downloadFile,
        downloadKPIDataSpecificationSampleFile,
        downloadContactUploadSampleFile,
        downloadPartnerFileTemplate,
    } = useFileDownload();
    let className = "";
    if (rest.className) {
        className = rest.className;
        delete rest.className;
    }
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const downloadHandler = async (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
        if (!downloadFileLoading) {
            e?.stopPropagation();
            e?.preventDefault();
            let data;
            if (kpiDataSpecificationSample) {
                data = await downloadKPIDataSpecificationSampleFile();
            } else if (contactUploadTemplateSample) {
                data = await downloadContactUploadSampleFile();
            } else if (partnerFileTemplate) {
                data = await downloadPartnerFileTemplate();
            } else {
                data = await downloadFile(file.id);
            }
            showDownloadDialog(data, file.name);
        }
        setOpen(false);
    };

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };

    const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setOpen(false);
    };
    const handleOpenChange = useCallback((newOpen: boolean) => {
        setOpen(newOpen);
    }, []);
    return (
        <div className={classes.ButtonContainer}>
            <Popconfirm
                title={t("generic_Download_FIle")}
                description={t("confirm_Download_File")}
                placement="left"
                open={open}
                onConfirm={downloadHandler}
                onCancel={handleCancel}
                okText={t("generic_Download")}
                cancelText={t("generic_Cancel")}
                onOpenChange={handleOpenChange}>
                <span>
                    <FileDownload03
                        title={t("generic_Download_FIle")}
                        data-test="download_link_button"
                        className={classnames(className)}
                        {...rest}
                        onClick={handleClick}
                    />
                </span>
            </Popconfirm>
            <Popover
                content={
                    <div style={{ width: "100px" }}>
                        <Progress percent={downloadFileProgress} size="small" />
                    </div>
                }
                open={downloadFileLoading}>
                {children ? (
                    children
                ) : ellipsis ? (
                    <Text
                        style={{ width: 400, cursor: "default" }}
                        className={classes.downloadLink}
                        ellipsis={{ tooltip: title || file.name }}>
                        {title || file.name}
                    </Text>
                ) : (
                    <span className={classes.fileName}>{title || file.name}</span>
                )}
            </Popover>
        </div>
    );
};

export { DownloadFile };
