import { useEffect, useState } from "react";
import Provider from "services/api";

const useReviewGroupsOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (!externalLoading) {
                const data = await Provider.Review.getReviewGroupsOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters,
                        sorts,
                        to: range.to,
                        from: range.from,
                    },
                    {
                        customError: {
                            message: "Could not retrieve list of review criteria groups",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: data.data,
                    totalRows: data.totalNumberOfRecords,
                    customFields: data.customFields,
                });
            }
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range]);
    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
    };
};

export { useReviewGroupsOverview };
