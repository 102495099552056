import React, { useState, FunctionComponent, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
    DownloadFile,
    ReviewRate,
    getFilterIcon,
    FileCheck02,
} from "components/utilitycomponents";
import {
    useReviewOverview,
    useSurveyTitles,
    useReviewCategoryForFilter,
    usePartnerList,
    useReviewContactsFilter,
    useCompanyType,
    usePartnerFields,
} from "controller";
import { useTranslation } from "react-i18next";
import classes from "./styles/overview.module.scss";
import { Tag, Tooltip } from "antd";
import OverviewHeader from "./overviewHeader";
import CreationModal from "./modals/create/creationModal";
import VisibilityModal from "./modals/visibility/visibilityModal";
import DeleteModal from "./modals/delete/deleteModal";
import RegistrationModal from "./modals/registration/registrationModal";
import { ReportType } from "models";
import dayjs from "utilities/daysJsConfig";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        id: regsitrationId,
        surveyId,
        partnerId,
        from: fromDateString,
        to: toDateString,
    }: any = useParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [creationModalVisible, setCreationModalVisible] = useState(false);
    const [visibilityModalVisible, setVisibilityModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [registrationModal, setRegistrationModal] = useState({
        id: regsitrationId ? parseInt(regsitrationId) : -1,
        visible: regsitrationId ? true : false,
    });
    const [reloadData, setReloadData] = useState<any>(null);
    const [from, setFrom] = useState(
        dayjs()
            .subtract(6, "months")
            .toISOString()
    );
    const [to, setTo] = useState(
        dayjs()
            .add(1, "day")
            .toISOString()
    );
    const { data: partnerFields } = usePartnerFields();
    const [externalReviewsSelected, setExternalReviewsSelected] = useState(false);
    const { isDefaultCompany } = useCompanyType();

    function onSelectChange(selectedRowKeys: any[], selectedRows: any[]) {
        setSelectedRowKeys(selectedRowKeys);
        setExternalReviewsSelected(
            selectedRows.filter(x => x.externallyCreated === true).length > 0
        );
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (row: any) => ({
            disabled: row.externallyCreated === true,
        }),
    };

    const handleOnRow = (record: any, rowIndex: number) => ({
        onClick: () => {
            setRegistrationModal({ id: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;

    let columns = [
        {
            title: t("review_Id", "Id"),
            dataIndex: "id",
            key: "id",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 0,
            ...getColumnSearchProps({ dataIndex: "id" }),
        },
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "reviewedPartnerName",
            key: "reviewedPartnerName",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 1,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "reviewedPartnerName", includeChildren: true }
            ),
        },
        {
            title: t("review_Rating", "Rating"),
            dataIndex: "rating",
            key: "rating",
            width: 250,
            fixed: false,
            sorter: true,
            filterType: "range",
            viewColumnId: 2,
            min: 0,
            max: 5,
            ...getColumnMinMaxProps(0, 5, { dataIndex: "rating", allowDecimals: false }),
            render: (val: any) => <ReviewRate val={val} />,
        },
        {
            title: t("review_Criteria", "Criteria"),
            dataIndex: "criteria",
            key: "criteria",
            width: 250,
            fixed: false,
            sorter: true,
            filterType: "array",
            viewColumnId: 3,
            ...getColumnSelectProps(
                useReviewCategoryForFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["categoryName"],
                },
                { dataIndex: "criteria" }
            ),
            render: (val: string) => <div>{t(val)}</div>,
        },
        {
            title: t("review_Notes", "Notes"),
            dataIndex: "notes",
            key: "notes",
            width: 250,
            fixed: false,
            sorter: true,
            ellipsis: true,
            filterType: "string",
            viewColumnId: 4,
            ...getColumnSearchProps({ dataIndex: "Notes" }),
            render: (value: any, record: any) =>
                record.surveyId ? (
                    <div className={classes.commentContainer}>
                        <div className={classes.commentTextContainer}>{value}</div>

                        {record.surveyId && (
                            <div className={classes.commentIconContainer}>
                                <Tooltip title={t("review_CommentTooltip")}>
                                    <span>
                                        <FileCheck02 />
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>{value}</div>
                ),
        },
        {
            title: t("review_CreatedBy", "Created By"),
            dataIndex: "createdBy",
            key: "createdBy",
            width: 250,
            fixed: false,
            sorter: true,
            filterType: "array",
            viewColumnId: 5,
            render: (createdBy: any) => (
                <div>{createdBy !== undefined && <Tag>{`${createdBy}`}</Tag>}</div>
            ),
            ...getColumnSelectProps(
                useReviewContactsFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "createdBy" }
            ),
        },
        {
            title: t("review_Created", "Created (UTC)"),
            dataIndex: "created",
            key: "created",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 6,
            filterType: "date",
            ...getColumnRangePickerProps(),
            render: (value: any) => <span>{dayjs(value).format("MMM D, YYYY (HH:mm)")}</span>,
        },
        {
            title: t("review_Attachments", "Attachments"),
            dataIndex: "attachments",
            key: "attachments",
            width: 250,
            fixed: false,
            sorter: false,
            filterType: "string",
            viewColumnId: 7,
            ...getColumnSearchProps({ dataIndex: "attachments" }),
            render: (value: any) => (
                <span>
                    {value.map((x: any, index: number) => {
                        return (
                            <div key={index}>
                                <DownloadFile file={{ id: x.fileId, name: x.fileName }} />
                            </div>
                        );
                    })}
                </span>
            ),
        },
        {
            title: t("review_Visibility", "Visibility"),
            dataIndex: "visibility",
            key: "visibility",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 8,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("external"), value: 2 },
                            { text: t("internal"), value: 1 },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "visibility", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "visibility" }),
            render: (val: string) => (
                <div>
                    {val !== "Personal" && val !== "ChosenByAuditor" ? t(val.toLowerCase()) : ""}
                </div>
            ),
        },
        {
            title: t("review_SurveyTitle", "Survey"),
            dataIndex: "surveyId",
            key: "surveyId",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 9,
            filterType: "array",
            ...getColumnSelectProps(useSurveyTitles.bind(null, true), {
                value: ["id"],
                text: ["title"],
            }),
            render: (id: any, record: any) => (
                <Fragment>
                    {id !== null && <div>{`${record.surveyTitle} (id:${id})`}</div>}
                </Fragment>
            ),
        },
        {
            title: t("review_VoiceOfSupplierColumn", "VOS"),
            dataIndex: "isVoiceOfSupplier",
            key: "isVoiceOfSupplier",
            width: 300,
            fixed: false,
            sorter: true,
            viewColumnId: 10,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Yes"), value: true },
                            { text: t("generic_No"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "isVoiceOfSupplier", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <Fragment>{`${value === true ? t("generic_Yes") : t("generic_No")}`}</Fragment>
            ),
        },
        {
            title: t("Review_AdditionalFields", "Additional Fields"),
            dataIndex: "hasAdditionalFields",
            key: "AdditionalFields",
            width: 300,
            fixed: false,
            sorter: true,
            viewColumnId: 11,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Yes"), value: true },
                            { text: t("generic_No"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "hasAdditionalFields", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <Fragment>{`${value === true ? t("generic_Yes") : t("generic_No")}`}</Fragment>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid
                data-test="review_table"
                additionalColumnFilters={
                    (surveyId && [{ name: "surveyId", value: surveyId }]) ||
                    (partnerId &&
                        fromDateString &&
                        toDateString && [{ name: "reviewedPartnerName", value: partnerId }])
                }
                title={() =>
                    isDefaultCompany ? (
                        <OverviewHeader
                            onCreateBtnClick={() => setCreationModalVisible(true)}
                            onDeleteBtnClick={() => setDeleteModalVisible(true)}
                            onChangeVisibilityClick={() => setVisibilityModalVisible(true)}
                            selectedRowKeys={selectedRowKeys}
                            externalReviewsSelected={externalReviewsSelected}
                        />
                    ) : (
                        <div style={{ fontWeight: "bold" }}>{t("headline_Reviews")}</div>
                    )
                }
                useData={useReviewOverview}
                columns={columns}
                fixedColumns={["id", "reviewedPartnerName"]}
                tableType={"Reviews"}
                reportType={ReportType.review}
                reloadData={reloadData}
                dateRange={{ from: fromDateString, to: toDateString }}
                rowSelection={rowSelection}
                onRangeChange={(val: any) => {
                    setFrom(val.from);
                    setTo(val.to);
                }}
                onRow={handleOnRow}></Grid>
            {creationModalVisible && (
                <CreationModal
                    visible={creationModalVisible}
                    onVisibleChange={() => setCreationModalVisible(false)}
                    reloadTable={() => setReloadData({ reload: true })}
                />
            )}
            {visibilityModalVisible && (
                <VisibilityModal
                    visible={visibilityModalVisible}
                    onVisibleChange={() => setVisibilityModalVisible(false)}
                    reloadTable={() => setReloadData({ reload: true })}
                    selectedRowKeys={selectedRowKeys}
                />
            )}
            {deleteModalVisible && (
                <DeleteModal
                    visible={deleteModalVisible}
                    onVisibleChange={() => setDeleteModalVisible(false)}
                    reloadTable={() => setReloadData({ reload: true })}
                    selectedRowKeys={selectedRowKeys}
                    clearSelectedRows={() => setSelectedRowKeys([])}
                />
            )}
            {registrationModal.visible && (
                <RegistrationModal
                    visible={registrationModal.visible}
                    id={registrationModal.id}
                    onVisibleChange={() =>
                        setRegistrationModal({
                            ...registrationModal,
                            visible: false,
                        })
                    }
                    reloadTable={() => setReloadData({ reload: true })}
                />
            )}
        </Fragment>
    );
};

export default Overview;
