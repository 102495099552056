import React, { forwardRef } from "react";
import { Select, Input } from "antd";
import { ComplianceVisibility } from "models";
import { useTranslation } from "react-i18next";
const Option = Select.Option;

type PropsType = {
    onChange?: (value: number) => void;
    value?: number;
    visibility: string;
};

const SelectVisibility = forwardRef(({ onChange, value, visibility }: PropsType, ref) => {
    const { t } = useTranslation();
    if ((ComplianceVisibility as any)[visibility] !== ComplianceVisibility.ChosenByAuditor) {
        return <Input placeholder={visibility} disabled />;
    }

    return (
        <Select
            data-test="compliance_visibility_select"
            ref={ref as any}
            onChange={(value: number) => {
                onChange && onChange(value);
            }}
            value={value === ComplianceVisibility.ChosenByAuditor ? undefined : value}
            placeholder={t("compliance_SelectVisibilityPlaceholder")}
            getPopupContainer={node => (node ? (node.parentNode as HTMLElement) : document.body)}>
            <Option value={ComplianceVisibility.Internal}>{t("internal")}</Option>
            <Option value={ComplianceVisibility.External}>{t("external")}</Option>
        </Select>
    );
});

export { SelectVisibility };
