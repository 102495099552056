import React, { useState, Fragment, forwardRef, useEffect } from "react";
import { CloseCircleFilled, StarFilled } from "@ant-design/icons";
import { Input, Rate, Tooltip } from "antd";
import classnames from "classnames";
import classes from "./styles/star.module.scss";

type PropsType = {
    onChange?: (value: number | undefined) => void;
    onHover?: (value: number | undefined) => void;
    value?: number;
    tooltips?: string[];
    allowClear?: boolean;
    [key: string]: any;
};

const NOT_SET = 0;

const Star = forwardRef(
    (
        { onChange, onHover, value = 0, tooltips = [], allowClear = false, ...rest }: PropsType,
        ref
    ) => {
        const [selectedStar, setSelectedStar] = useState<number | undefined>(value);
        const [hoveredStar, setHoveredStar] = useState<number | null>(null);

        useEffect(() => {
            setSelectedStar(value);
        }, [value]);

        const handleChange = (star: number) => {
            setSelectedStar(star);
            onChange && onChange(star);
        };

        const handleClear = () => {
            setSelectedStar(NOT_SET);
            onChange && onChange(NOT_SET);
        };

        const handleMouseEnter = (star: number) => {
            setHoveredStar(star);
            onHover && onHover(star);
        };

        const handleMouseLeave = () => {
            setHoveredStar(null);
            onHover && onHover(selectedStar);
        };

        return (
            <Fragment>
                <div className={classes.container}>
                    <div className={classes.rateContainer} onMouseLeave={handleMouseLeave}>
                        <Rate
                            className={classes.rateComponent}
                            value={selectedStar}
                            onChange={handleChange}
                            character={({ index }) => (
                                <Tooltip title={tooltips[index!] || ""} key={index}>
                                    <span onMouseEnter={() => handleMouseEnter((index || 0) + 1)}>
                                        <StarFilled
                                            className={classnames(
                                                (hoveredStar || selectedStar!) >= (index || 0) + 1
                                                    ? classes.selectedStar
                                                    : classes.whiteStar
                                            )}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                            allowClear={allowClear}
                        />
                    </div>

                    {allowClear && selectedStar !== undefined && selectedStar > 0 && (
                        <CloseCircleFilled
                            aria-label="clear star"
                            className={classnames("ant-input-clear-icon", classes.clear)}
                            onClick={handleClear}
                        />
                    )}
                </div>

                <Input data-test="star_rating_hidden_input" value={selectedStar} type="hidden" />
            </Fragment>
        );
    }
);

export { Star };
