import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Row, Spin, Tabs } from "antd";
import { SummaryReport } from "./summaryReport/summaryReport";
import { SupplierInformation } from "./supplierInformation/supplierInformation";
import { useTranslation } from "react-i18next";
import { NewsLog } from "./newsLog/newsLog";
import classes from "./summary.module.scss";
import { SupplierSummary } from "./supplierSummary/supplierSummary";
import { Sustainability } from "./sustainability/sustainability";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getEnvironmentInfo } from "utilities";
import { Environment } from "models";
import { Margin, usePDF } from "react-to-pdf";
import { FileDownload03 } from "components/utilitycomponents";
const TabPane = Tabs.TabPane;

interface IProps {
    onAssignRelationClick: Function;
    clickedTab: any;
    partnerId: number;
}

const Summary: FunctionComponent<IProps> = ({ onAssignRelationClick, clickedTab, partnerId }) => {
    const [supplierData, setSupplierData] = useState<any>(undefined);
    const [activeTab, setActiveTab] = useState(clickedTab);
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const currentCompanyId = user.profile["ll-CompanyId"];
    const { toPDF, targetRef } = usePDF({
        filename: `summary_report_${partnerId}.pdf`,
        page: { margin: Margin.MEDIUM },
    });
    const [exporting, setExporting] = useState(false);
    useEffect(() => {
        setSupplierData(undefined);
    }, [partnerId]);

    useEffect(() => {
        setActiveTab(clickedTab);
    }, [clickedTab]);

    return (
        <div>
            <Button
                size="small"
                icon={
                    <Spin size="small" spinning={exporting}>
                        <FileDownload03 />
                    </Spin>
                }
                className={classes.exportButton}
                onClick={async () => {
                    setExporting(true);
                    await toPDF();
                    setExporting(false);
                }}>
                {t("exportBtnText")}
            </Button>
            <div ref={targetRef}>
                <Row gutter={0}>
                    <Col
                        span={4}
                        style={{
                            marginTop: 4,
                            paddingRight: 0,
                        }}>
                        <SupplierInformation
                            partnerId={partnerId}
                            onAssignRelationClick={() => onAssignRelationClick()}
                            onDataLoaded={(data: any) => setSupplierData(data)}
                        />
                    </Col>
                    <Col span={20} style={{ paddingLeft: 8 }}>
                        <Tabs
                            type="card"
                            activeKey={activeTab}
                            size="small"
                            animated
                            destroyInactiveTabPane
                            renderTabBar={() => <></>}>
                            <TabPane
                                tab={
                                    <div className={classes.tab}>
                                        {t("supplierprofile_summary_SummaryTab")}
                                    </div>
                                }
                                key={"summary"}>
                                <SupplierSummary
                                    supplierData={supplierData}
                                    partnerId={partnerId}
                                />
                            </TabPane>
                            <TabPane
                                tab={
                                    <div className={classes.tab}>
                                        {t("supplierProfile_Summary_ReportTabTile")}
                                    </div>
                                }
                                key={"spendReport"}>
                                <SummaryReport supplierData={supplierData} partnerId={partnerId} />
                            </TabPane>
                            <TabPane
                                tab={
                                    <div className={classes.tab}>
                                        {t("supplierProfile_NewsLogHeadline")}
                                    </div>
                                }
                                key={"newsLog"}>
                                <NewsLog partnerId={partnerId} />
                            </TabPane>
                            {(getEnvironmentInfo().type === Environment.Local ||
                                getEnvironmentInfo().type === Environment.Staging ||
                                currentCompanyId === "4" ||
                                currentCompanyId === "4439") && (
                                <TabPane
                                    tab={
                                        <div className={classes.tab}>
                                            {t("supplierProfile_Sustainability")}
                                        </div>
                                    }
                                    key={"sustainability"}>
                                    <Sustainability partnerId={partnerId} />
                                </TabPane>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export { Summary };
