import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

export const useReviewContactsFilter = (from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            let data: any[] = await Provider.Review.getContactsForFilter(from, to);
            data = data.map((contact: IContact) => ({
                ...contact,
                name: `${contact.firstName} ${contact.lastName}`,
            }));
            let guestContacts: any[] = data.filter(
                x => x.name.toLocaleLowerCase() === "guest guest"
            );
            if (guestContacts.length) {
                let combinedGuestContact: any = {
                    id: guestContacts
                        .map(x => x.id)
                        .toString()
                        .trim()
                        //built in antd filter dropdown selection uses comma as delimiter. That is why use a semicolon here to keep the value from being split.
                        .replace(",", ";"),
                    name: "Guest Guest",
                };
                data = data.filter(x => x.name.toLocaleLowerCase() !== "guest guest");
                data.push(combinedGuestContact);
            }

            setState({ loading: false, data });
        })();
    }, [from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};
