import React, { useState, FunctionComponent, Fragment, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
    DownloadFile,
    getFilterIcon,
    MultipleTags,
} from "components/utilitycomponents";
import {
    useLinkedStatus,
    useContacts,
    useNcrOverview,
    useNcrReasonsForFilter,
    useNcrContactsFilter,
    usePartnerList,
    useCompanyType,
    usePartnerFields,
} from "controller";
import { Tag } from "antd";
import OverviewHeader from "./overviewHeader";
import RegistrationModal from "./modals/registration/registrationModal";
import CreationModal from "./modals/create/creationModal";
import DeleteModal from "./modals/delete/deleteModal";
import classes from "./styles/overview.module.scss";
import { getCustomLabel } from "./getCustomLabel";
import { ReportType } from "models";
import { useTranslation } from "react-i18next";
import VisibilityModal from "./modals/visibility/visibilityModal";
import SideFilter from "./sideFilter";
import dayjs from "utilities/daysJsConfig";
import { FiltersContext } from "components/mainContainers/ncr/ncrProvider";

interface IProps {
    [key: string]: any;
}

const Overview: FunctionComponent<IProps> = ({ ...rest }) => {
    const filtersContext = useContext(FiltersContext);
    const { t } = useTranslation();
    const location = useLocation();
    const {
        id: regsitrationId,
        partnerId,
        from: fromDateString,
        to: toDateString,
    }: any = useParams();
    const [filters, setFilters] = useState<any>({});
    const [registrationModal, setRegistrationModal] = useState({
        id: regsitrationId ? parseInt(regsitrationId) : 0,
        visible: regsitrationId ? true : false,
    });
    const [creationModalVisible, setCreationModalVisible] = useState(false);
    const [visibilityModalVisible, setVisibilityModalVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        selectedIds: [],
        visible: false,
    });
    const [ncrFields, setNcrFields] = useState<any[]>([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [from, setFrom] = useState(
        dayjs()
            .subtract(6, "months")
            .toISOString()
    );
    const [to, setTo] = useState(
        dayjs()
            .add(1, "day")
            .toISOString()
    );
    const { isDefaultCompany } = useCompanyType();
    if (!filtersContext) {
        throw new Error("SomeComponent must be used within a FiltersProvider");
    }

    const { additionalFilters, setAdditionalFilters } = filtersContext;
    const { data: partnerFields } = usePartnerFields();

    useEffect(() => {
        setRegistrationModal({
            id: regsitrationId ? parseInt(regsitrationId) : 0,
            visible: regsitrationId ? true : false,
        });
    }, [regsitrationId]);

    useEffect(() => {
        if (location.pathname.includes("filter/MyNcrs")) {
            setAdditionalFilters([...additionalFilters, { name: "MyNcrs", value: "true" }]);
        }
        if (partnerId && fromDateString && toDateString) {
            setAdditionalFilters([...additionalFilters, { name: "partnerName", value: partnerId }]);
        }
    }, []);

    const [externallyCreatedSelected, setExternallySelected] = useState(false);

    const handleOnRow = (record: any, rowIndex: number) => ({
        onClick: () => {
            setRegistrationModal({ id: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    function onSelectChange(selectedRowKeys: any[], selectedRows: any[]) {
        setSelectedRowKeys(selectedRowKeys);
        setExternallySelected(selectedRows.filter(x => x.externallyCreated).length > 0);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (row: any) => ({
            disabled: row.externallyCreated === true,
        }),
    };

    let ownerColTitle = partnerFields.headlines.find((x: any) => x.name === "Owner")?.customLabel;
    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;

    let {
        idColTile,
        ncrStatusColTitle,
        partnerLinkedColTitle,
        modifedColTitle,
        ncrHolderTitle,
        purchaseOrderColTitle,
        createdByColTitle,
        createdColTitle,
        ncrReasonColTitle,
        nextColTitle,
        valueColTitle,
        otherCostColTitle,
        visibilityColTitle,
        commentColTitle,
        salesOrderColTilte,
        deliveryNoteColTitle,
        attachmentsColTitle,
        createNcrLabel,
        approvalStatusLabel,
    } = getCustomLabel(ncrFields);

    let columns = [
        {
            title: idColTile,
            dataIndex: "id",
            key: "Id",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 1,
            ...getColumnSearchProps({ dataIndex: "id" }),
            render: (val: any, record: any) => (
                <div>{record.alternateId !== null ? record.alternateId : record.id}</div>
            ),
        },
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "partnerName",
            key: "partnerName",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 0,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partnerName", includeChildren: true }
            ),
        },
        {
            title: ncrStatusColTitle,
            dataIndex: "ncrStatus",
            key: "ncrStatus",
            width: 100,
            fixed: false,
            sorter: true,
            viewColumnId: 2,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Open"), value: 1 },
                            { text: t("generic_Closed"), value: 2 },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "ncrStatus", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "ncrStatus" }),
            render: (value: any, record: any) => (
                <div>
                    {value === "Open" ? (
                        <Tag className={classes.statusTag} color="#f5222d">
                            {t("generic_Open")}
                        </Tag>
                    ) : (
                        <Tag className={classes.statusTag} color="#52c41a">
                            {t("generic_Closed")}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: "Days Open",
            dataIndex: "daysOpen",
            key: "daysOpen",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: "daysOpen",
            filterType: "range",
            ...getColumnMinMaxProps(-9999999999999, 9999999999999, {
                dataIndex: "daysOpen",
                allowDecimals: false,
            }),
        },
        {
            title: partnerLinkedColTitle,
            dataIndex: "partnerLinkStatus",
            key: "partnerLinkStatus",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 3,
            filterType: "array",
            ...getColumnSelectProps(
                useLinkedStatus,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partnerLinkStatus" }
            ),
            render: (val: string) => <div>{val && t(val)}</div>,
        },
        {
            title: modifedColTitle,
            dataIndex: "modified",
            key: "modified",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 4,
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "modified" }),
            render: (value: any) => <span>{dayjs(value).format("MMM D, YYYY (HH:mm)")}</span>,
        },
        {
            title: ncrHolderTitle,
            dataIndex: "holder",
            key: "holder",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 5,
            filterType: "array",
            render: (holder: any) => (
                <div className={classes.TagContainer}>
                    {holder !== "" && <Tag>{`${holder}`}</Tag>}
                </div>
            ),
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "holder" }
            ),
        },
        {
            title: purchaseOrderColTitle,
            dataIndex: "purchaseOrderNumber",
            key: "purchaseOrderNumber",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 6,
            ellipsis: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "purchaseOrderNumber" }),
        },
        {
            title: createdByColTitle,
            dataIndex: "createdBy",
            key: "createdBy",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 7,
            filterType: "array",
            render: (createdBy: any) => (
                <div className={classes.columnStyle}>
                    {createdBy !== undefined && <Tag>{`${createdBy}`}</Tag>}
                </div>
            ),
            ...getColumnSelectProps(
                useNcrContactsFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "createdBy" }
            ),
        },
        {
            title: createdColTitle,
            dataIndex: "created",
            key: "created",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 8,
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "created" }),
            render: (value: any) => <span>{dayjs(value).format("MMM D, YYYY (HH:mm)")}</span>,
        },
        {
            title: approvalStatusLabel,
            dataIndex: "approvalStatus",
            key: "approvalStatus",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 9,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        loading: false,
                        data: [
                            { name: t("ncrStatusUndecided"), value: 0 },
                            { name: t("ncrStatusApproved"), value: 1 },
                            { name: t("ncrStatusNotApproved"), value: 2 },
                        ],
                    };
                },
                { value: ["value"], text: ["name"] },
                { dataIndex: "approvalStatus" }
            ),
            render: (val: string) => <div>{t(`ncrStatus${val}`)}</div>,
        },
        {
            title: ncrReasonColTitle,
            dataIndex: "ncrReason",
            key: "ncrReason",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 10,
            filterType: "array",
            ...getColumnSelectProps(
                useNcrReasonsForFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "ncrReason" }
            ),
            render: (val: string) => <div>{t(val)}</div>,
        },
        {
            title: nextColTitle,
            dataIndex: "nextResponsible",
            key: "nextResponsible",
            width: 200,
            fixed: false,
            sorter: true,
            viewColumnId: 11,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("ncrNextResponsible_Us"), value: true },
                            { text: t("ncrNextResponsible_Them"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "nextResponsible", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "nextResponsible" }),
            render: (val: string) => (
                <div>
                    {val === "Us" ? t("ncrNextResponsible_Us") : t("ncrNextResponsible_Them")}
                </div>
            ),
        },
        {
            title: valueColTitle,
            dataIndex: "value",
            key: "Value",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 12,
            filterType: "range",
            min: -9999999999999,
            max: 9999999999999,
            ...getColumnMinMaxProps(-9999999999999, 9999999999999, { dataIndex: "value" }),
            render: (value: any, record: any) => (
                <span>
                    {`${record.valueCurrency !== null ? record.valueCurrency : ""} ${
                        value !== null ? value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : ""
                    }`}
                </span>
            ),
        },
        {
            title: otherCostColTitle,
            dataIndex: "otherCosts",
            key: "otherCosts",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 13,
            filterType: "range",
            min: -9999999999999,
            max: 9999999999999,
            ...getColumnMinMaxProps(-9999999999999, 9999999999999, { dataIndex: "otherCosts" }),
            render: (value: any, record: any) => (
                <span>
                    {`${record.otherCostsCurrency !== null ? record.otherCostsCurrency : ""} ${
                        value !== null ? value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : ""
                    }`}
                </span>
            ),
        },
        {
            title: visibilityColTitle,
            dataIndex: "visibility",
            key: "visibility",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 14,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("external"), value: 2 },
                            { text: t("internal"), value: 1 },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "visibility", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "visibility" }),
            render: (val: string) => <div>{t(val.toLowerCase())}</div>,
        },
        {
            title: commentColTitle,
            dataIndex: "comment",
            key: "comment",
            ellipsis: true,
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 15,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "comment" }),
            render: (value: any) => <span>{value}</span>,
        },
        {
            title: t("searchTags"),
            dataIndex: "searchTags",
            key: "tags",
            width: 250,
            fixed: false,
            viewColumnId: 16,
            render: (value: string[]) => (
                <Fragment>
                    {value !== null &&
                        value.map((x: any, index: number) => {
                            if (x !== "") return <Tag key={index}>{x}</Tag>;
                            else {
                                return undefined;
                            }
                        })}
                </Fragment>
            ),
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "searchTags" }),
        },
        {
            title: salesOrderColTilte,
            dataIndex: "salesOrderNumber",
            key: "salesOrderNumber",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 17,
            ellipsis: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "salesOrderNumber" }),
        },
        {
            title: deliveryNoteColTitle,
            dataIndex: "deliveryNote",
            key: "deliveryNote",
            width: 250,
            fixed: false,
            sorter: true,
            viewColumnId: 18,
            ellipsis: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "deliveryNote" }),
        },
        {
            title: attachmentsColTitle,
            dataIndex: "attachments",
            key: "attachments",
            width: 250,
            fixed: false,
            sorter: false,
            filterType: "string",
            viewColumnId: 19,
            ...getColumnSearchProps({ dataIndex: "attachments" }),
            render: (value: any) => (
                <span>
                    {value.map((x: any, index: number) => {
                        return (
                            <div key={index}>
                                <DownloadFile file={{ id: x.fileId, name: x.fileName }} />
                            </div>
                        );
                    })}
                </span>
            ),
        },
        {
            title: ownerColTitle || t("owner"),
            dataIndex: "ncrPartnerOwner",
            key: "NcrPartnerOwner",
            width: 200,
            filterType: "array",
            viewColumnId: "partnerOwner",
            render: (owners: any) =>
                owners && (
                    <MultipleTags
                        values={owners.map((owner: any) => ({
                            id: owner.id,
                            name: `${owner.firstName} ${owner.lastName}`,
                        }))}
                    />
                ),
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partnerOwner" }
            ),
        },
        {
            title: t("ncrActions_CorrectiveAction"),
            dataIndex: "corrrectiveActionAnswer",
            key: "corrrectiveActionAnswer",
            ellipsis: true,
            width: 250,
            fixed: false,
            sorter: false,
            viewColumnId: 20,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("ncrActions_FilterCompleted"), value: true },
                            { text: t("ncrActions_FilterPending"), value: false },
                            { text: t("ncrActions_FilterNone"), value: "null" },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "correctiveActionAnswer", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <span>
                    {value === ""
                        ? `${
                              record.correctiveActionOwnerName !== null
                                  ? t("ncrActions_PendingResponse", {
                                        ownerName: record.correctiveActionOwnerName,
                                    })
                                  : t("ncrActions_PendingResponseNoOwner")
                          } ${
                              record.correctiveActionDeadline
                                  ? `Deadline (${dayjs(record.correctiveActionDeadline).format(
                                        "MMM D, YYYY"
                                    )})`
                                  : ""
                          }`
                        : value}
                </span>
            ),
        },
        {
            title: t("ncrActions_PreventiveAction"),
            dataIndex: "preventiveActionAnswer",
            key: "preventiveActionAnswer",
            ellipsis: true,
            width: 250,
            fixed: false,
            sorter: false,
            viewColumnId: 21,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("ncrActions_FilterCompleted"), value: true },
                            { text: t("ncrActions_FilterPending"), value: false },
                            { text: t("ncrActions_FilterNone"), value: "null" },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "preventiveActionAnswer", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <span>
                    {value === ""
                        ? `${
                              record.preventiveActionOwnerName !== null
                                  ? t("ncrActions_PendingResponse", {
                                        ownerName: record.preventiveActionOwnerName,
                                    })
                                  : t("ncrActions_PendingResponseNoOwner")
                          } ${
                              record.preventiveActionDeadline
                                  ? `Deadline (${dayjs(record.preventiveActionDeadline).format(
                                        "MMM D, YYYY"
                                    )})`
                                  : ""
                          }`
                        : value}
                </span>
            ),
        },
        {
            title: t("ncrActions_RootCauseAction"),
            dataIndex: "rootCauseActionAnswer",
            key: "rootCauseActionAnswer",
            ellipsis: true,
            width: 250,
            fixed: false,
            sorter: false,
            viewColumnId: 22,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("ncrActions_FilterCompleted"), value: true },
                            { text: t("ncrActions_FilterPending"), value: false },
                            { text: t("ncrActions_FilterNone"), value: "null" },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "rootCauseActionAnswer", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <span>
                    {value === ""
                        ? `${
                              record.rootCauseActionOwnerName !== null
                                  ? t("ncrActions_PendingResponse", {
                                        ownerName: record.rootCauseActionOwnerName,
                                    })
                                  : t("ncrActions_PendingResponseNoOwner")
                          } ${
                              record.rootCauseActionDeadline
                                  ? `Deadline (${dayjs(record.rootCauseActionDeadline).format(
                                        "MMM D, YYYY"
                                    )})`
                                  : ""
                          }`
                        : value}
                </span>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid
                additionalColumnFilters={additionalFilters}
                data-test="ncr_table"
                title={() =>
                    isDefaultCompany ? (
                        <>
                            <OverviewHeader
                                customText={createNcrLabel}
                                onCreateBtnClick={() => setCreationModalVisible(true)}
                                onDeleteBtnClick={() =>
                                    setDeleteModal({ ...deleteModal, visible: true })
                                }
                                selectedRowKeys={selectedRowKeys}
                                externallyCreatedSelected={externallyCreatedSelected}
                                onChangeVisibilityClick={() => setVisibilityModalVisible(true)}
                            />
                        </>
                    ) : (
                        <div style={{ fontWeight: "bold" }}>{t("ncr_ncrsLong")}</div>
                    )
                }
                fixedColumns={["id", "partnerName"]}
                columns={columns}
                useData={useNcrOverview}
                onDataCustomFields={(customFields: any) =>
                    customFields !== null && setNcrFields(customFields)
                }
                filters={filters}
                reportType={ReportType.ncr}
                onFiltersChange={setFilters}
                tableType={"Ncr"}
                reloadData={reloadData}
                onRow={handleOnRow}
                // sidebar={() => (
                //     <SideFilter
                //         setAdditionalFilters={setAdditionalFilters}
                //         additionalFilters={additionalFilters}
                //     />
                // )}
                sidebar={SideFilter}
                rowSelection={rowSelection}
                dateRange={{ from: fromDateString, to: toDateString }}
                onRangeChange={(val: any) => {
                    setFrom(val.from);
                    setTo(val.to);
                }}
                {...rest}></Grid>
            {registrationModal.visible && (
                <RegistrationModal
                    reloadTable={() => setReloadData({ reload: true })}
                    id={registrationModal.id}
                    visible={registrationModal.visible}
                    onVisibleChange={() =>
                        setRegistrationModal({
                            ...registrationModal,
                            visible: false,
                        })
                    }
                />
            )}
            <CreationModal
                visible={creationModalVisible}
                onVisibleChange={() => setCreationModalVisible(false)}
                reloadTable={() => setReloadData({ reload: true })}
            />
            <DeleteModal
                visible={deleteModal.visible}
                onVisibleChange={() => setDeleteModal({ ...deleteModal, visible: false })}
                selectedRowKeys={selectedRowKeys}
                reloadTable={() => setReloadData({ reload: true })}
                clearSelectedRows={() => setSelectedRowKeys([])}
            />
            <VisibilityModal
                visible={visibilityModalVisible}
                onVisibleChange={() => setVisibilityModalVisible(false)}
                reloadTable={() => setReloadData({ reload: true })}
                selectedRowKeys={selectedRowKeys}
            />
        </Fragment>
    );
};

export default Overview;
