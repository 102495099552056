import React, { FunctionComponent } from "react";
import { ResponsiveLine, Layer } from "@nivo/line";
import { format } from "d3-format";
import classes from "./styles/lineChart.module.scss";
import { getHeaderSize } from "../reportUtilities/reportUtilities";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    reportObject: any;
    parentId: any;
}

export const LineChart: FunctionComponent<IProps> = ({ reportObject, parentId }) => {
    const { ElementHeight, titleExcess, descriptionExcess } = getHeaderSize(parentId);
    const { t } = useTranslation();
    const { data } = reportObject;

    const customBottomTick = (tick: any) => {
        return (
            <g transform={`translate(${tick.x},${tick.tickIndex % 2 ? tick.y + 12 : tick.y + 22})`}>
                <line
                    stroke="rgb(190, 190, 190)"
                    strokeWidth={1.5}
                    y1={tick.tickIndex % 2 ? -12 : -22}
                    y2={-12}
                />
                <text
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fill: "#333",
                        fontSize: 10,
                    }}>
                    {tick.value}
                </text>
            </g>
        );
    };

    const PointLayer = (props: any) => {
        return props.points.map(({ id, serieColor, x, y }: any) => {
            if (id.toLowerCase().indexOf("target") !== -1) {
                return null;
            } else {
                return (
                    <circle
                        key={id}
                        cx={x}
                        cy={y}
                        r={props.pointSize / 2}
                        strokeWidth={props.pointBorderWidth}
                        fill={"#fff"}
                        stroke={serieColor}
                    />
                );
            }
        });
    };

    const styleLineById: any = {
        Target: {
            strokeDasharray: "12, 6",
            strokeWidth: 2,
        },
        default: {
            strokeWidth: 3,
        },
    };

    const LineLayer = ({ series, lineGenerator, xScale, yScale }: any) => {
        return series.map(({ id, data, color }: any) => (
            <path
                key={id}
                d={lineGenerator(
                    data.map((d: any) => {
                        if (d.data.y) {
                            return {
                                x: xScale(d.data.x),
                                y: yScale(d.data.y),
                            };
                        } else {
                            return { x: null, y: null };
                        }
                    })
                )}
                fill="none"
                stroke={color}
                style={
                    id.toLowerCase().indexOf("target") > -1
                        ? styleLineById.Target
                        : styleLineById.default
                }
            />
        ));
    };

    const colors = ["#bae637", "#bae637", "#36cfc9", "#36cfc9", "#ff4d4f"];

    return (
        <div>
            <div
                className={classes.lineChartElement}
                style={{ height: ElementHeight - titleExcess - descriptionExcess }}>
                <ResponsiveLine
                    layers={
                        [
                            "grid",
                            "axes",
                            "areas",
                            "crosshair",
                            LineLayer,
                            "markers",
                            PointLayer,
                            "slices",
                            "mesh",
                            "legends",
                        ] as Layer[]
                    }
                    data={data.chartData.filter((d: any) => d.id !== null)}
                    margin={{ top: 50, right: 180, bottom: 50, left: 80 }}
                    colors={colors}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        renderTick: customBottomTick,
                        legend: `
                        ${dayjs(reportObject.filter.from).format("MMM D, YYYY") +
                            " - " +
                            dayjs(reportObject.filter.to).format("MMM D, YYYY")}`,
                        legendOffset: 40,
                        legendPosition: "middle",
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: `${t("analytics_Value")} ${data.isPercentage ? " %" : ""}`,
                        legendOffset: -55,
                        legendPosition: "middle",
                        format: (v: any) => (!data.isPercentage && v > 1 ? format("~s")(v) : v),
                    }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    tooltip={({ point }) => {
                        return (
                            <div
                                style={{
                                    background: "white",
                                    padding: "9px 12px",
                                    border: "1px solid #ccc",
                                }}>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: point.serieColor,
                                            height: "10px",
                                            width: "10px",
                                            display: "inline-block",
                                            marginRight: "3px",
                                        }}></div>
                                    {t(point.serieId.toString())} :{" "}
                                    {point.data.y &&
                                        point.data.y
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, "$&,")}
                                </div>
                            </div>
                        );
                    }}
                    useMesh={true}
                    legends={[
                        {
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 110,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: "top-to-bottom",
                            itemWidth: 50,
                            itemHeight: 40,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",

                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        </div>
    );
};
