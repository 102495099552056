import React, { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spin } from "antd";
import { useCompanyType, useCompliance } from "controller";
import ComplianceTemplateDetail from "./complianceTemplateDetail";
import ComplianceDetail from "./complianceDetail";
import ComplianceAction from "./complianceAction";
import {
    LoadingIndicator,
    UserImage,
    FollowActivity,
    ChildActivities,
    ArrowsRight,
    FileDownload03,
} from "components/utilitycomponents";
import { ComplianceDataTypeEnum, IActivityChild } from "models";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { default as CreateActionModal } from "components/contentcomponents/action/registration/registrationCreateModal";
import { IActionType } from "models/action";
import { Margin, usePDF } from "react-to-pdf";

type PropsType = {
    visible: boolean;
    id: number | undefined;
    onClose: Function;
    onReloadData?: Function; //coming from overview page
    onAddActivity?: (parentActivityId: number, activity: IActivityChild) => void; //coming from homepage
    onRemoveActivity?: (parentActivityId: number, activityId: number) => void; //coming from homepage
    onChangeFollowActivity?: (activityId: number, isFollowed: boolean) => void; //coming from homepage
};

const RegistrationModal: FunctionComponent<PropsType> = ({
    id,
    visible,
    onClose,
    onReloadData,
    onAddActivity,
    onRemoveActivity,
    onChangeFollowActivity,
}) => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState({ hasChanged: "" });
    const [actionModal, setActionModal] = useState<any>({
        visible: false,
        registrationId: undefined,
        supplierId: undefined,
    });
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const hasProjectAccess =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Project"]) === "true";
    const [exporting, setExporting] = useState(false);
    const { isDefaultCompany } = useCompanyType();

    const { loading, data, addActivity, removeActivity } = useCompliance(
        ComplianceDataTypeEnum.ForRegistrationDialog,
        id,
        modalState
    );

    const { toPDF, targetRef } = usePDF({
        filename: `compliance_${id}.pdf`,
        page: { margin: Margin.MEDIUM },
    });

    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        addActivity(parentActivityId, activity);
        onAddActivity && onAddActivity(parentActivityId, activity);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        removeActivity(parentActivityId, activityId);
        onRemoveActivity && onRemoveActivity(parentActivityId, activityId);
    };
    if (!loading && data === null) return null;

    return (
        <Fragment>
            <Modal
                wrapProps={{ "data-test": "registration_modal" }}
                title={
                    data !== null ? (
                        <Row>
                            <Col
                                span={12}
                                style={{
                                    lineHeight: "32px",
                                }}>{`${t("compliance_RegistrationOn")} ${data.partnerName}`}</Col>
                            <Col span={11} style={{ textAlign: "right" }}>
                                <Button
                                    size="small"
                                    icon={
                                        <Spin size="small" spinning={exporting}>
                                            <FileDownload03 />
                                        </Spin>
                                    }
                                    style={{ marginRight: 4 }}
                                    onClick={async () => {
                                        setExporting(true);
                                        await toPDF();
                                        setExporting(false);
                                    }}>
                                    {t("exportBtnText")}
                                </Button>
                                {userType !== "lightuser" && hasProjectAccess && isDefaultCompany && (
                                    <Button
                                        style={{ marginRight: 4 }}
                                        onClick={() =>
                                            setActionModal({
                                                visible: true,
                                                registrationId: data!.id,
                                                supplierId: data!.partnerId,
                                            })
                                        }
                                        size="small"
                                        icon={<ArrowsRight />}>
                                        {t("action_CreateAction")}
                                    </Button>
                                )}
                                <FollowActivity
                                    isFollowed={data.isFollow}
                                    activityId={data.activityId}
                                    onChangeFollowActivity={onChangeFollowActivity}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )
                }
                open={visible}
                footer={null}
                onCancel={() => onClose()}
                width={1000}
                style={{ top: 20 }}>
                {loading && data === null && (
                    <div style={{ textAlign: "center" }}>
                        <LoadingIndicator style={{ fontSize: "18px" }} />
                    </div>
                )}
                <div ref={targetRef}>
                    {data !== null && (
                        <Row gutter={8}>
                            <Col span={2}>
                                <UserImage
                                    contactId={data.createdByContactId}
                                    data-test="creator_profile_picture"
                                    alt={data.creator}
                                    style={{ width: "100%" }}
                                    fileId={data["creatorProfilePictureId"]}
                                    customDimension={65}
                                />
                            </Col>
                            <Col span={21}>
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <ComplianceDetail
                                                data={data}
                                                approvedForLoading={
                                                    loading &&
                                                    modalState.hasChanged === "localStatus"
                                                }
                                                onRatingChanged={() => {
                                                    setModalState({ hasChanged: "rating" });
                                                    onReloadData && onReloadData();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <div>
                                        <Spin
                                            spinning={
                                                loading && modalState.hasChanged === "status"
                                            }>
                                            <ComplianceAction
                                                data={data}
                                                onStatusChanged={() => {
                                                    setModalState({ hasChanged: "status" });
                                                    onReloadData && onReloadData();
                                                }}
                                                onReloadData={(reload: boolean) => {
                                                    if (reload) {
                                                        setModalState({ hasChanged: "edited" });
                                                        onReloadData && onReloadData();
                                                    }
                                                    onReloadData && onReloadData();
                                                }}
                                            />
                                        </Spin>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <ComplianceTemplateDetail
                                            data={data.templateDetail}
                                            canSeeScores={data.canSeeScores}
                                        />
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <Spin
                                            spinning={
                                                loading && modalState.hasChanged !== "localStatus"
                                            }>
                                            <ChildActivities
                                                data={data.childrenActivity}
                                                parentActivityId={data.activityId}
                                                onRemoveActivity={handleRemoveActivity}
                                                onAddActivity={handleAddActivity}
                                            />
                                        </Spin>
                                    </div>
                                </>
                            </Col>
                        </Row>
                    )}
                </div>
            </Modal>
            <CreateActionModal
                visible={actionModal.visible}
                registrationId={actionModal.registrationId}
                supplierId={actionModal.supplierId}
                registrationType={IActionType.Compliance}
                onClose={() =>
                    setActionModal({
                        visible: false,
                        supplierId: undefined,
                        registrationId: undefined,
                    })
                }
            />
        </Fragment>
    );
};

export default RegistrationModal;
