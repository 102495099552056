import { Tooltip } from "antd";
import React, { useRef, useEffect, useState } from "react";
import { useGauge } from "use-gauge";

interface PowerProps {
    value: number | null;
    widget: boolean;
    width?: number | string;
    height?: number | string;
    strokeWidth?: number;
    offset?: number;
    circleRadius?: number;
    level?: string;
    awardLevelData?: any[];
    openModal?: boolean;
}

const SemiCircularGauge = (props: PowerProps) => {
    const {
        widget,
        value,
        width = 500,
        height = 300,
        strokeWidth = 25,
        offset = -18,
        circleRadius = 91.5,
        level,
        awardLevelData = [],
        openModal,
    } = props;

    const [textWidths, setTextWidths] = useState<number[]>([]);
    const textRefs = useRef<(SVGTextElement | null)[]>([]);
    const [pathControlWidths, setPathControlWidths] = useState<number[]>([]);

    function getTextWidth(text: string, font: string): number {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
            context.font = font;
            return context.measureText(text).width;
        }
        return 0;
    }
    function getDistance(x1: number, y1: number, x2: number, y2: number): number {
        return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    }
    const calculateWidths = () => {
        const widths = awardLevelData.map(awardLevel => getTextWidth(awardLevel.name, "6px Arial"));
        setTextWidths(widths);

        const pathControlWidths = awardLevelData.map((awardLevel, i) => {
            const startValue = awardLevel.lowerBound;
            const endValue = awardLevel.upperBound;
            const totalRange = 100;

            const startAngle = (180 * startValue) / totalRange;
            const endAngle = (180 * endValue) / totalRange;
            const startAngleRad = ((startAngle + 180) * Math.PI) / 180;
            const endAngleRad = ((endAngle + 180) * Math.PI) / 180;

            const lineX1 = radius * Math.cos(startAngleRad);
            const lineY1 = radius * Math.sin(startAngleRad);
            const lineX2 = radius * Math.cos(endAngleRad);
            const lineY2 = radius * Math.sin(endAngleRad);

            return getDistance(lineX1, lineY1, lineX2, lineY2);
        });

        setPathControlWidths(pathControlWidths);
    };
    useEffect(() => {
        calculateWidths();
    }, [openModal, awardLevelData]);

    const safeValue = (value: number | null, defaultValue: number = 0): number => {
        return value ?? defaultValue;
    };

    const gauge = useGauge({
        domain: [0, 100],
        startAngle: 90,
        endAngle: 270,
        numTicks: 15,
        diameter: 200,
    });

    const needle = gauge.getNeedleProps({
        value: safeValue(value),
        baseRadius: 0,
        tipRadius: 1,
        offset: offset,
    });

    const gradientId = "gradient";
    const numSegments = awardLevelData.length;
    const radius = 105;
    const textPaths = [];
    const pathsAndLines = [];
    const totalRange = 100;

    for (let i = 0; i < numSegments; i++) {
        const startValue = awardLevelData[i].lowerBound;
        const endValue = awardLevelData[i].upperBound;
        const startAngle = (180 * startValue) / totalRange;
        const endAngle = (180 * endValue) / totalRange;
        const startAngleRad = ((startAngle + 180) * Math.PI) / 180;
        const endAngleRad = ((endAngle + 180) * Math.PI) / 180;

        const lineX1 = radius * Math.cos(startAngleRad);
        const lineY1 = radius * Math.sin(startAngleRad);
        const lineX2 = radius * Math.cos(endAngleRad);
        const lineY2 = radius * Math.sin(endAngleRad);
        const lineX1Text = 118 * Math.cos(startAngleRad);
        const lineY1Text = 118 * Math.sin(startAngleRad);
        const lineX2Text = 118 * Math.cos(endAngleRad);
        const lineY2Text = 118 * Math.sin(endAngleRad);
        const pathData = `M${-radius},0 A${radius},${radius} 0 0,1 ${radius},0`;
        const pathControl = `M${lineX1},${lineY1} A${radius},${radius} 0 0,1 ${lineX2},${lineY2}`;
        pathsAndLines.push(
            <path
                key={`arc-${i}`}
                d={pathData}
                fill="none"
                stroke={`url(#gradient)`}
                strokeWidth={`${strokeWidth}`}
            />
        );
        const pathText = `M${lineX1Text},${lineY1Text} A${radius},${radius} 0 0,1 ${lineX2Text},${lineY2Text}`;
        textPaths.push(
            <path key={`textPath-${i}`} id={`textPath-${i}`} d={pathText} fill="none" />
        );
        const currentValue = safeValue(value);

        if (
            widget === false &&
            currentValue >= awardLevelData[i].lowerBound &&
            currentValue <= awardLevelData[i].upperBound
        ) {
            pathsAndLines.push(
                <path
                    key={`highlight-${i}`}
                    d={pathControl}
                    fill="none"
                    stroke={"#efd794"}
                    strokeWidth="45"
                />
            );
        }
    }

    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-110 -128 200 200">
            {textPaths}
            <defs>
                <linearGradient id={gradientId} gradientTransform="rotate(0)">
                    <stop offset="0%" stopColor="#faad14" />
                    <stop offset="33%" stopColor="#f1d20b" />
                    <stop offset="66%" stopColor="#f1d20b" />
                    <stop offset="100%" stopColor="#52c41a" />
                </linearGradient>
            </defs>

            {pathsAndLines}

            <g>
                <polyline points={needle.points} />
                <circle fill="white" cx={needle.base.cx} cy={needle.base.cy} r={circleRadius} />
            </g>
            <text x="0" y="-40" fontSize="24px" fill="#000" textAnchor="middle">
                <tspan fontWeight={700}>{safeValue(value) + "%"}</tspan>
            </text>
            <text x="0" y="0" fontSize="14px" fill="#000" textAnchor="middle">
                <tspan fontWeight={700}>{level?.toLocaleUpperCase().slice(0, 20)}</tspan>
            </text>
            {widget === false && awardLevelData && (
                <>
                    {awardLevelData.map((awardLevel, index) => {
                        return (
                            <text
                                ref={el => (textRefs.current[index] = el)}
                                style={{ cursor: "default" }}
                                key={`awardLabel-${index}`}
                                fontSize={`${
                                    safeValue(value) >= awardLevel.lowerBound &&
                                    safeValue(value) <= awardLevel.upperBound
                                        ? "6px"
                                        : "5px"
                                }`}
                                fill={`${
                                    safeValue(value) >= awardLevel.lowerBound &&
                                    safeValue(value) <= awardLevel.upperBound
                                        ? "black"
                                        : "gray"
                                }`}
                                textAnchor="middle"
                                fontWeight={`${
                                    safeValue(value) >= awardLevel.lowerBound &&
                                    safeValue(value) <= awardLevel.upperBound
                                        ? 600
                                        : 400
                                }`}>
                                <Tooltip
                                    placement="top"
                                    key={index}
                                    title={
                                        awardLevel.name +
                                        " " +
                                        awardLevel.lowerBound +
                                        "-" +
                                        awardLevel.upperBound +
                                        "%"
                                    }>
                                    <textPath
                                        startOffset="50%"
                                        dominantBaseline="middle"
                                        href={`#textPath-${index}`}>
                                        {textWidths[index] > pathControlWidths[index]
                                            ? `${awardLevel.name.toUpperCase().slice(0, 8 + 1) +
                                                  "..."}`
                                            : awardLevel.name.toUpperCase()}
                                    </textPath>
                                </Tooltip>
                            </text>
                        );
                    })}
                </>
            )}
        </svg>
    );
};

export default SemiCircularGauge;
