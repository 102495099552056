import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import {
    Modal,
    Row,
    Col,
    Select,
    Checkbox,
    Input,
    notification,
    DatePicker,
    Tooltip,
    Form,
    Spin,
    Skeleton,
    Button,
} from "antd";
import { useTranslation } from "react-i18next";
import {
    SelectDataMultiple,
    SelectExternalPartnerMultiple,
    RichTextEditor,
    FormRow,
    FormLabel,
    SelectTreeDataMultiple,
    GridButton,
    HelpCircle,
    PlusCircle,
} from "components/utilitycomponents";
import classes from "./styles/createForm.module.scss";
import {
    useReviewCategory,
    useCompanyContactsWithAccess,
    useAutomatedSurveyCreate,
    useContactGroups,
    useSurveyTemplate,
} from "controller";
import { ICreateAutoSurvey, Modules, SurveyExtraFieldType } from "models";
import SelectSurveyTemplateModal from "components/contentcomponents/reviews/survey/modals/create/selectSurveyTemplateModal";
import { DynamicTextFields } from "components/contentcomponents/reviews/survey/modals/create/dynamicTextFields";
import { parseValuesToAutomatedSurveyObject } from "./parseValuesToAutoSurveyObject";
import SupplierContacts from "components/contentcomponents/reviews/survey/modals/create/supplierContacts";
import dayjs from "utilities/daysJsConfig";
const Option = Select.Option;

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    reloadTable: Function;
}

const CreationModal: FunctionComponent<IProps> = ({ visible, onVisibleChange, reloadTable }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isVoiceOfSupplier, setIsVoiceOfSupplier] = useState(false);
    const [reloadTemplateData, setReloadTemplateData] = useState<any>(null);
    const [selectedPartners, setSelectedPartners] = useState<any[]>([]);
    const [customizeReceivers, setCustomizeReceivers] = useState(false);
    const [surveyTemplateModal, setSurveyTemplateModal] = useState<any>({
        templateId: undefined,
        visible: false,
    });
    const { loading: templateLoading, data: templateData } = useSurveyTemplate(
        surveyTemplateModal.templateId,
        reloadTemplateData
    );

    const { loading, createAutomatedSurvey } = useAutomatedSurveyCreate();

    useEffect(() => {
        if (visible) {
            setIsVoiceOfSupplier(false);
            setCustomizeReceivers(false);
            form.resetFields();
        }
    }, [visible]);

    useEffect(() => {
        if (templateData) {
            setIsVoiceOfSupplier(templateData.isVoiceOfSupplier);
            setCustomizeReceivers(false);
            form.resetFields();
        }
    }, [templateData]);

    const onCancel = () => {
        setSurveyTemplateModal({ visible: false, templateId: undefined });
        onVisibleChange(false);
    };

    const disabledDate = (current: any) => {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    };

    const onSubmit = async () => {
        const values = await form.validateFields();

        let autoSurvey: ICreateAutoSurvey = parseValuesToAutomatedSurveyObject({
            ...values,
            surveyTemplateId: surveyTemplateModal.templateId,
        });
        await createAutomatedSurvey(autoSurvey, { retryCount: 3, customError: "" });
        reloadTable();
        onCancel();
        notification.success({
            message: "Success",
            description: t("autoSurvey_CreateSuccess"),
        });
    };

    return (
        <>
            <Modal
                wrapProps={{ "data-test": "create_autoSurvey_modal" }}
                title={
                    <Row>
                        <Col span={12}>{t("autoSurvey_CreateTitle")}</Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                            <GridButton
                                data-test="create_survey_from_template_button"
                                icon={<PlusCircle />}
                                size="small"
                                onClick={() =>
                                    setSurveyTemplateModal({
                                        ...surveyTemplateModal,
                                        visible: true,
                                    })
                                }>
                                {t("survey_CreateSurveyFromTemplate")}
                            </GridButton>
                        </Col>
                    </Row>
                }
                open={visible}
                footer={[
                    <Button loading={loading} type="primary" onClick={async () => form.submit()}>
                        {t("generic_Create")}
                    </Button>,
                ]}
                onCancel={() => onCancel()}
                onOk={() => form.submit()}
                confirmLoading={loading}
                width={900}
                style={{ top: 20 }}
                destroyOnClose>
                <Spin spinning={templateLoading}>
                    <Form
                        form={form}
                        onFinish={onSubmit}
                        scrollToFirstError
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                        labelWrap>
                        <Form.Item
                            label={t("autoSurvey_Title")}
                            required
                            name="title"
                            initialValue={templateData ? templateData.title : undefined}
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: t("autoSurvey_TitleRequired"),
                                },
                            ]}>
                            <Input
                                data-test="title_input"
                                maxLength={200}
                                placeholder={t("survey_TitlePlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("autoSurvey_IntroductionText")}
                            name="introduction"
                            initialValue={templateData ? templateData.introduction : ""}>
                            <RichTextEditor
                                placeholder={t("survey_IntroductionPlaceholder")}
                                maxLength={1000}
                                bordered
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    {t("autoSurvey_EndDateDays")}{" "}
                                    <Tooltip title={t("autoSurvey_EndDateDaysTooltip")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="endDateDays"
                            initialValue={undefined}
                            rules={[
                                {
                                    pattern: new RegExp(/^[1-9]\d*$/g),
                                    message: t("autoSurvey_EndDateDaysErrorMessage"),
                                },
                            ]}>
                            <Input
                                placeholder={t("autoSurvey_EndDateDaysPlaceHolder")}
                                allowClear
                                maxLength={4}
                            />
                        </Form.Item>

                        <FormRow>
                            <Col span={6} className={classes.labelPadding}>
                                <Tooltip title={t("survey_InsertExtraSurveyFieldTooltip")}>
                                    <span>
                                        <HelpCircle />
                                    </span>
                                </Tooltip>{" "}
                                <FormLabel>{t("survey_InsertExtraSurveyField")}</FormLabel>
                            </Col>
                            <Col span={18}>
                                {templateLoading ? (
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                ) : (
                                    <DynamicTextFields
                                        type={"SurveyFields"}
                                        data={
                                            templateData
                                                ? templateData.extraFieldHeadlines.filter(
                                                      (x: any) =>
                                                          x.surveyExtraFieldTypeId ===
                                                          SurveyExtraFieldType.Survey
                                                  )
                                                : undefined
                                        }
                                    />
                                )}
                            </Col>
                        </FormRow>

                        <Form.Item
                            label={t("autoSurvey_SelectPartners")}
                            required
                            name="partners"
                            rules={[
                                {
                                    required: true,
                                    message: t("autoSurvey_SelectedPartnersRequired"),
                                },
                            ]}>
                            <SelectExternalPartnerMultiple
                                selectAll
                                placeholder={t("survey_SelectedPartnerPlaceholder")}
                                includeChildren
                                onChange={(_: any, items: any) => {
                                    setSelectedPartners(items);
                                }}
                            />
                        </Form.Item>

                        <FormRow>
                            <Col span={6} className={classes.labelPadding}>
                                <Tooltip title={t("survey_InsertExtraSupplierFieldTooltip")}>
                                    <span>
                                        <HelpCircle />
                                    </span>
                                </Tooltip>{" "}
                                <FormLabel>{t("survey_InsertExtraSupplierField")}</FormLabel>
                            </Col>
                            <Col span={18}>
                                {templateLoading ? (
                                    <Skeleton active paragraph={{ rows: 0 }} />
                                ) : (
                                    <DynamicTextFields
                                        type={"SupplierFields"}
                                        data={
                                            templateData
                                                ? templateData.extraFieldHeadlines.filter(
                                                      (x: any) =>
                                                          x.surveyExtraFieldTypeId ===
                                                          SurveyExtraFieldType.Supplier
                                                  )
                                                : undefined
                                        }
                                    />
                                )}
                            </Col>
                        </FormRow>

                        <Form.Item
                            label={t("autoSurvey_ReviewCriteria")}
                            required
                            name="criteria"
                            rules={[
                                {
                                    required: true,
                                    message: t("autoSurvey_CriteriaRequired"),
                                    type: "array",
                                },
                            ]}
                            initialValue={
                                templateData ? templateData.selectedReviewCriteriaIds : undefined
                            }>
                            <SelectTreeDataMultiple
                                data-test="criteria_select"
                                allowClear={true}
                                useData={useReviewCategory}
                                selectAll
                                selectOptionField="name"
                                placeholder={t("autoSurvey_CriteriaPlaceholder")}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                selectOnlyChildren
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("autoSurvey_Visibility")}
                            name="visibility"
                            initialValue={templateData ? templateData.visibilityId : 1}>
                            <Select
                                data-test="visibility_select"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }>
                                <Option key={0} value={1}>
                                    {t("internal")}
                                </Option>
                                <Option key={1} value={2}>
                                    {t("external")}
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={t("autoSurvey_ContactGroup")} name="contactGroups">
                            <SelectDataMultiple
                                data-test="contact_groups_select"
                                allowClear={true}
                                placeholder={t("autoSurvey_ContactGroupsPlaceholder")}
                                useData={useContactGroups}
                                selectAll
                                selectOptionField="name"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <Form.Item label={t("autoSurvey_Users")} name="users">
                            <SelectDataMultiple
                                data-test="users_select"
                                allowClear={true}
                                placeholder={t("autoSurvey_UsersPlaceholder")}
                                useData={useCompanyContactsWithAccess.bind(
                                    null,
                                    ["Administrator", "User", "lightuser"],
                                    Modules.Review
                                )}
                                selectAll
                                selectIdField="id"
                                selectOptionField="name"
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("autoSurvey_VoiceOfSupplier")}
                            name="isVoiceOfSupplier"
                            valuePropName="checked"
                            initialValue={templateData ? templateData.isVoiceOfSupplier : false}>
                            <Checkbox
                                data-test="is_voice_of_supplier_checkbox"
                                onChange={(e: any) => setIsVoiceOfSupplier(e.target.checked)}
                            />
                        </Form.Item>

                        {isVoiceOfSupplier && (
                            <>
                                <SupplierContacts selectedPartners={selectedPartners} />

                                <Form.Item
                                    label={t("autoSurvey_VoiceOfSupplierAllowGuests")}
                                    name="voiceOfSupplierAllowGuests"
                                    valuePropName="checked"
                                    initialValue={
                                        templateData ? templateData.allowGuestVOS : false
                                    }>
                                    <Checkbox
                                        disabled={customizeReceivers}
                                        data-test="voice_of_supplier_allow_guests_checkbox"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t("autoSurvey_VoiceOfSupplierIntroductionText")}
                                    name="introductionVOS"
                                    initialValue={
                                        templateData ? templateData.introductionVOS : false
                                    }>
                                    <RichTextEditor
                                        placeholder={t(
                                            "autoSurvey_VoiceOfSupplierIntroductionText"
                                        )}
                                        maxLength={1000}
                                        bordered
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label={t("autoSurvey_AllowGuests")}
                            name="allowGuests"
                            valuePropName="checked"
                            initialValue={templateData ? templateData.allowGuest : true}>
                            <Checkbox data-test="allow_guests_checkbox" />
                        </Form.Item>

                        <Form.Item
                            label={t("autoSurvey_StartDate")}
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: t("autoSurvey_StartDateRequired"),
                                },
                            ]}>
                            <DatePicker
                                format={"MMM D, YYYY"}
                                data-test="start_date"
                                disabledDate={disabledDate}
                                placeholder={t("generic_SelectDate")}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("autoSurvey_Frequency")}
                            name="frequency"
                            initialValue={0}>
                            <Select
                                data-test="frequency_select"
                                style={{ width: "100%" }}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }>
                                <Option key={0} value={0}>
                                    {t("autoSurvey_Daily")}
                                </Option>
                                <Option key={1} value={1}>
                                    {t("autoSurvey_Weekly")}
                                </Option>
                                <Option key={2} value={2}>
                                    {t("autoSurvey_Monthly")}
                                </Option>
                                <Option key={3} value={3}>
                                    {t("autoSurvey_Quarterly")}
                                </Option>
                                <Option key={4} value={5}>
                                    {t("autoSurvey_BiAnnually")}
                                </Option>
                                <Option key={5} value={4}>
                                    {t("autoSurvey_Annually")}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <SelectSurveyTemplateModal
                visible={surveyTemplateModal.visible}
                onClose={() => setSurveyTemplateModal({ ...surveyTemplateModal, visible: false })}
                onSelect={(templateId: number) => {
                    if (surveyTemplateModal.templateId === templateId) {
                        setReloadTemplateData({ reload: true });
                    }
                    setSurveyTemplateModal({ templateId, visible: false });
                }}
            />
        </>
    );
};

export default CreationModal;
