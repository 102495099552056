import React, { FunctionComponent, useState } from "react";
import { Row, Col, Button, Steps, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./selectRespondent.module.scss";
import SelectInternalRespondents from "./selectInternalRespondents";
import SelectSupplierRespondents from "./selectSupplierRespondents";
import { SupplierContact, SupplierContacts } from "../surveyWizard";
import { getEmailValidationPattern } from "utilities";

type PropTypes = {
    visible: boolean;
    clearData: any;
    isVos: boolean;
    supplierSet: any[];
    onNext: Function;
    onPrevious: Function;
    defaultSurvey: any | undefined;
    showInternalRespondents: boolean;
    hasAllowGuestOnVOS: boolean;
};

const SelectRespondents: FunctionComponent<PropTypes> = ({
    visible,
    clearData,
    isVos,
    supplierSet,
    onNext,
    onPrevious,
    defaultSurvey,
    showInternalRespondents,
    hasAllowGuestOnVOS,
}) => {
    const { t } = useTranslation();
    let { regex } = getEmailValidationPattern();
    const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
    const [selectedSupplierContacts, setSelectedSupplierContacts] = useState<SupplierContacts>({});
    const [current, setCurrent] = useState(0);

    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    const supplierIds = [...new Set(supplierSet.map((set: any) => set.supplier.id))];
    const suppliers = supplierIds.map(supplierId =>
        supplierSet.find(set => set.supplier.id === supplierId)
    );

    const handleNext = () => {
        for (let supplierId in selectedSupplierContacts) {
            if (
                selectedSupplierContacts[supplierId].receiverEmails.some(
                    email => !regex.test(email)
                )
            ) {
                return;
            }
        }
        onNext(selectedContacts, selectedSupplierContacts);
    };

    const renderOperation = () => {
        return (
            <div className={classes.stepButtonsRow}>
                <Row>
                    <Col span={12}>
                        {current !== 0 && isVos ? (
                            <Button
                                size="large"
                                type="primary"
                                onClick={() => {
                                    setCurrent(current > 0 ? current - 1 : 0);
                                }}
                                disabled={current === 0}>
                                {t("generic_Previous", { subject: t("supplier") })}
                            </Button>
                        ) : (
                            <Button type="primary" size="large" onClick={() => onPrevious()}>
                                {t("generic_Previous")}
                            </Button>
                        )}
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        {(current !== suppliers.length &&
                            isVos &&
                            hasAllowGuestOnVOS &&
                            showInternalRespondents) ||
                        (current !== suppliers.length - 1 &&
                            isVos &&
                            hasAllowGuestOnVOS &&
                            !showInternalRespondents) ? (
                            <Button
                                size="large"
                                type="primary"
                                onClick={() =>
                                    setCurrent(current < suppliers.length ? current + 1 : current)
                                }>
                                {t("generic_Next", { subject: t("supplier") })}
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    handleNext();
                                    setCurrent(0);
                                }}>
                                {t("generic_Next")}
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    if (isVos && defaultSurvey && showInternalRespondents) {
        return (
            <div style={{ display: visible ? "block" : "none" }}>
                <div style={{ minHeight: `${height - 300}px` }} className={classes.collapse}>
                    <Steps
                        className={classes.stepsContainer}
                        current={current}
                        progressDot
                        onChange={(val: number) => setCurrent(val)}>
                        <Steps.Step
                            title={t("survey_wizard_internalRespondents")}
                            key={0}></Steps.Step>
                        {suppliers.map((set: any, index: number) => (
                            <Steps.Step
                                title={`${set.supplier.name} ${t(
                                    "survey_Wizard_Respondents"
                                ).toLocaleLowerCase()}`}
                                key={index + 1}></Steps.Step>
                        ))}
                    </Steps>
                    <div
                        style={{
                            display: current === 0 && showInternalRespondents ? "block" : "none",
                        }}>
                        <SelectInternalRespondents
                            clearData={clearData}
                            onSelectedContactsChange={setSelectedContacts}
                            defaultValues={defaultSurvey && defaultSurvey.selectedContactIds}
                        />
                    </div>

                    {suppliers.map((set: any, index: number) => (
                        <div style={{ display: index + 1 === current ? "block" : "none" }}>
                            <SelectSupplierRespondents
                                visible={true}
                                onSelectedContactsChange={(contacts: SupplierContact) =>
                                    setSelectedSupplierContacts({
                                        ...selectedSupplierContacts,
                                        [set.supplier.id]: contacts,
                                    })
                                }
                                supplierId={set.supplier.id}
                                supplierName={set.supplier.name}
                                defaultValues={
                                    defaultSurvey &&
                                    defaultSurvey.surveyPartnerSpecifications.filter(
                                        (pSpec: any) => pSpec.partnerId === set.supplier.id
                                    )[0] &&
                                    defaultSurvey.surveyPartnerSpecifications.filter(
                                        (pSpec: any) => pSpec.partnerId === set.supplier.id
                                    )[0].contactIds
                                }
                            />
                        </div>
                    ))}

                    {renderOperation()}
                </div>
            </div>
        );
    } else if (isVos && defaultSurvey && !showInternalRespondents) {
        return (
            <div style={{ display: visible ? "block" : "none" }}>
                <div style={{ minHeight: `${height - 300}px` }} className={classes.collapse}>
                    <Steps
                        className={classes.stepsContainer}
                        current={current}
                        progressDot
                        onChange={(val: number) => setCurrent(val)}>
                        {suppliers.map((set: any, index: number) => (
                            <Steps.Step
                                title={
                                    <Tooltip title={set.supplier.name}>
                                        {set.supplier.name.slice(0, 50)}{" "}
                                        {set.supplier.name.length >= 50 && "... "}
                                        {t("survey_Wizard_Respondents").toLocaleLowerCase()}
                                    </Tooltip>
                                }
                                key={index}></Steps.Step>
                        ))}
                    </Steps>

                    {suppliers.map((set: any, index: number) => (
                        <div style={{ display: index === current ? "block" : "none" }}>
                            <SelectSupplierRespondents
                                visible={true}
                                onSelectedContactsChange={(contacts: SupplierContact) =>
                                    setSelectedSupplierContacts({
                                        ...selectedSupplierContacts,
                                        [set.supplier.id]: contacts,
                                    })
                                }
                                supplierId={set.supplier.id}
                                supplierName={set.supplier.name}
                                defaultValues={
                                    defaultSurvey &&
                                    defaultSurvey.surveyPartnerSpecifications.filter(
                                        (pSpec: any) => pSpec.partnerId === set.supplier.id
                                    )[0] &&
                                    defaultSurvey.surveyPartnerSpecifications.filter(
                                        (pSpec: any) => pSpec.partnerId === set.supplier.id
                                    )[0].contactIds
                                }
                            />
                        </div>
                    ))}

                    {renderOperation()}
                </div>
            </div>
        );
    } else if (isVos && !defaultSurvey && showInternalRespondents) {
        return (
            <div style={{ display: visible ? "block" : "none", minHeight: `${height - 300}px` }}>
                {hasAllowGuestOnVOS && (
                    <Steps
                        className={classes.stepsContainer}
                        current={current}
                        progressDot
                        onChange={(val: number) => setCurrent(val)}>
                        <Steps.Step
                            title={t("survey_wizard_internalRespondents")}
                            key={1}></Steps.Step>
                        {hasAllowGuestOnVOS &&
                            suppliers.map((set: any, index: number) => (
                                <Steps.Step
                                    title={
                                        <Tooltip title={set.supplier.name}>
                                            {set.supplier.name.slice(0, 50)}{" "}
                                            {set.supplier.name.length >= 50 && "... "}
                                            {t("survey_Wizard_Respondents").toLocaleLowerCase()}
                                        </Tooltip>
                                    }
                                    key={index + 1}></Steps.Step>
                            ))}
                    </Steps>
                )}
                <div
                    style={{
                        display: current === 0 ? "block" : "none",
                    }}>
                    <SelectInternalRespondents
                        clearData={clearData}
                        onSelectedContactsChange={setSelectedContacts}
                        defaultValues={defaultSurvey && defaultSurvey.selectedContactIds}
                    />
                </div>

                {hasAllowGuestOnVOS &&
                    suppliers.map((set: any, index: number) => (
                        <div style={{ display: index + 1 === current ? "block" : "none" }}>
                            <SelectSupplierRespondents
                                visible={index + 1 === current}
                                onSelectedContactsChange={(contacts: SupplierContact) =>
                                    setSelectedSupplierContacts({
                                        ...selectedSupplierContacts,
                                        [set.supplier.id]: contacts,
                                    })
                                }
                                supplierId={set.supplier.id}
                                supplierName={""}
                            />
                        </div>
                    ))}

                {renderOperation()}
            </div>
        );
    } else if (isVos && !defaultSurvey && !showInternalRespondents) {
        return (
            <div style={{ display: visible ? "block" : "none", minHeight: `${height - 300}px` }}>
                <Steps
                    className={classes.stepsContainer}
                    current={current}
                    progressDot
                    onChange={(val: number) => setCurrent(val)}>
                    {suppliers.map((set: any, index: number) => (
                        <Steps.Step
                            title={
                                <Tooltip title={set.supplier.name}>
                                    {set.supplier.name.slice(0, 50)}{" "}
                                    {set.supplier.name.length >= 50 && "... "}
                                    {t("survey_Wizard_Respondents").toLocaleLowerCase()}
                                </Tooltip>
                            }
                            key={index}></Steps.Step>
                    ))}
                </Steps>

                {suppliers.map((set: any, index: number) => (
                    <div style={{ display: index === current ? "block" : "none" }}>
                        <SelectSupplierRespondents
                            visible={index === current}
                            onSelectedContactsChange={(contacts: SupplierContact) =>
                                setSelectedSupplierContacts({
                                    ...selectedSupplierContacts,
                                    [set.supplier.id]: contacts,
                                })
                            }
                            supplierId={set.supplier.id}
                            supplierName={""}
                        />
                    </div>
                ))}

                {renderOperation()}
            </div>
        );
    } else {
        return (
            <div style={{ display: visible ? "block" : "none" }}>
                <div style={{ minHeight: `${height - 350}px` }}>
                    <SelectInternalRespondents
                        clearData={clearData}
                        onSelectedContactsChange={setSelectedContacts}
                        defaultValues={defaultSurvey && defaultSurvey.selectedContactIds}
                    />
                </div>
                {renderOperation()}
            </div>
        );
    }
};

export default SelectRespondents;
