import React, { useEffect, useState } from "react";
import { GridLight, Share07, Trash02 } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import classes from "./meetingCalendar.module.scss";
import { Col, Row, Progress, Tooltip, Affix, Button, Popconfirm, Tag } from "antd";
import { CreateModal } from "./createModal";
import { EditModal } from "./editModal";
import { useMeetingMinutes, useMeetingMinutesCRUD } from "controller";
import { ShareModal } from "./shareModal";
import dayjs from "utilities/daysJsConfig";
import moment from "moment";

interface IProps {
    partnerId: number;
    meetingMinuteId?: number;
}

const MeetingCalendar = ({ partnerId, meetingMinuteId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState({ reload: false });
    const [createModal, setCreateModal] = useState<any>({ visible: false });
    const [editModal, setEditModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const [shareModal, setShareModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const { data, loading } = useMeetingMinutes(reload, partnerId);
    const { removeMeetingMinute } = useMeetingMinutesCRUD();

    useEffect(() => {
        if (meetingMinuteId) {
            setEditModal({ visible: true, id: meetingMinuteId });
        }
    }, [meetingMinuteId]);

    const getStrokeColor = (val: number, maxNumber: number) => {
        let percentage = (val / maxNumber) * 100;
        if (percentage === 100) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        } else if (percentage < 100 && percentage >= 50) {
            return {
                "0%": "#fa8c16",
                "100%": "#fa8c16",
            };
        } else {
            return {
                "0%": "#f5222d",
                "100%": "#f5222d",
            };
        }
    };

    const columns: any[] = [
        {
            title: <div className={classes.colTitle}>{t("governance_meetingDate")}</div>,
            dataIndex: "date",
            key: "date",
            width: 120,
            render: (val: string) => <div>{val && moment(val).format("MMM D, YYYY (HH:mm)")}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingType")}</div>,
            dataIndex: "type",
            key: "type",
            width: 100,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingTitle")}</div>,
            dataIndex: "title",
            key: "title",
            width: 300,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingActions")}</div>,
            dataIndex: "completeActionCount",
            key: "actions",
            width: 150,
            render: (val: number, row: any) => (
                <div style={{ width: 125 }}>
                    {row.actionCount && row.actionCount > 0 ? (
                        <Progress
                            success={{ percent: 0 }}
                            size="default"
                            percent={(val / row.actionCount) * 100}
                            format={val => (
                                <span>{`${row.completeActionCount} / ${row.actionCount}`}</span>
                            )}
                            strokeColor={getStrokeColor(val, row.actionCount)}
                        />
                    ) : (
                        <Tag>-</Tag>
                    )}
                </div>
            ),
        },
        {
            title: "",
            key: "shareCol",
            width: 30,
            render: (val: any, row: any) => (
                <div>
                    {dayjs(row.date).isBefore(dayjs()) && (
                        <Tooltip mouseEnterDelay={0.5} title="Share Meeting Minute">
                            <span>
                                <Share07
                                    className={classes.shareIcon}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShareModal({ visible: true, id: row.id });
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: "",
            key: "deleteCol",
            width: 30,
            render: (val: any, row: any) => (
                <div
                    onClick={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    <Popconfirm
                        title={t("generic_DeleteConfirmation")}
                        okText={t("generic_Confirm")}
                        onConfirm={async (e: any) => {
                            await removeMeetingMinute(row.id);
                            setReload({ reload: true });
                        }}
                        placement="left">
                        <span>
                            <Trash02 />
                        </span>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const handleOnRow = (row: any) => ({
        onClick: () => {
            setEditModal({ visible: true, id: row.id });
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <div style={{ height: "70%" }}>
            <Row style={{ marginBottom: 32 }}>
                <Col span={24}>
                    <div style={{ marginBottom: 32, minHeight: 350 }}>
                        <GridLight
                            title={() => (
                                <div className={classes.tableTitle}>
                                    {t("governance_UpcomingMeetings")}
                                </div>
                            )}
                            className={classes.customTableStyle}
                            dataSource={data.upComingMeetings}
                            onRow={handleOnRow}
                            columns={columns}
                            tableSize="big"
                            pagination="none"
                            loading={loading}
                            bordered={false}
                            renderTableOnDataChange
                            scroll={{ y: 300 }}
                            emptyText={t("governance_UpcomingMeetingsEmptyText")}
                        />
                    </div>
                    <GridLight
                        title={() => (
                            <div className={classes.tableTitle}>{t("governance_PastMeetings")}</div>
                        )}
                        className={classes.customTableStyle}
                        dataSource={data.pastMeetings}
                        onRow={handleOnRow}
                        columns={columns}
                        tableSize="big"
                        pagination="none"
                        loading={loading}
                        bordered={false}
                        renderTableOnDataChange
                        showHeader={false}
                        scroll={{ y: 300 }}
                        emptyText={t("governance_PastMeetingsEmptyText")}
                    />
                </Col>
            </Row>

            <Affix style={{ position: "fixed", bottom: 10, right: 30 }} className={classes.affix}>
                <Button
                    className={classes.addBtn}
                    shape="round"
                    onClick={() => setCreateModal({ visible: true })}>
                    {t("governance_AddMeeting")}
                </Button>
            </Affix>

            <CreateModal
                visible={createModal.visible}
                onClose={(val: any) => {
                    if (val) {
                        setReload({ reload: true });
                    }
                    setCreateModal({ visible: false });
                }}
                partnerId={partnerId}
            />
            <EditModal
                partnerId={partnerId}
                visible={editModal.visible}
                meetingId={editModal.id}
                onClose={(reload: boolean) => {
                    if (reload && reload === true) {
                        setReload({ reload: true });
                    }
                    setEditModal({ visible: false, id: undefined });
                }}
                onActionInteraction={() => setReload({ reload: true })}
            />
            <ShareModal
                visible={shareModal.visible}
                onClose={() => setShareModal({ visible: false, id: undefined })}
                meetingId={shareModal.id}
                supplierId={partnerId}
            />
        </div>
    );
};

export { MeetingCalendar };
