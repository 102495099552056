import { useState } from "react";
import { Provider } from "services";

const useKpiDefinitionHasData = () => {
    const [loading, setLoading] = useState(false);

    const getKpiTotalEntries = async (kpiId: number, from: string, to: string) => {
        setLoading(true);

        const res = await Provider.Kpi.getKpiDefinitionHasData(kpiId, from, to, {
            customError: { message: "Could not get amount of kpi entries" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        getKpiTotalEntries,
    };
};

export { useKpiDefinitionHasData };
