import { AreaType } from "models/utilities";

export interface IReportSettings {
    headline: string;
    type: ReportSettingsType;
    url: string | null;
    embeddedCode: string | null;
    id?: number;
    area?: AreaType;
    isPbiReport?: boolean;
    powerBiReportGuid?: string | null;
    powerBiWorkspaceGuid?: string | null;
}

export enum ReportSettingsType {
    URL = 0,
    EmbeddedCode = 1,
}
