import React, { useState } from "react";
import { Badge, Popover } from "antd";
import NotificationList from "../notificationList/notificationList";
import { useNotificationCount } from "controller";
import classes from "./notificationDropDown.module.scss";
import { BellOutlined } from "@ant-design/icons";

const NotificationDropDown = () => {
    const { data: count } = useNotificationCount();
    const [seen, setSeen] = useState(false);

    return (
        <Popover
            overlayClassName={classes.notificationDropDown}
            placement="bottomRight"
            onOpenChange={(visible: boolean) => {
                count > 0 && setSeen(true);
            }}
            content={
                <div>
                    <NotificationList />
                </div>
            }
            trigger={"click"}>
            <Badge count={seen === false ? count : 0}>
                <BellOutlined style={{ fontSize: 24 }} className="topbarIcon" />
            </Badge>
        </Popover>
    );
};
export { NotificationDropDown };
