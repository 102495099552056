import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "components/alwaysOnComponents/errors/404";
import Suppliers from "components/mainContainers/suppliers";
import Home from "components/mainContainers/home/home";
import MasterDataOverView from "components/contentcomponents/suppliers/masterDataOverview";
import { AccountProfile } from "components/contentcomponents/user/accountProfile/profile";
import { AppState } from "store";
import ComplianceOverview from "components/contentcomponents/compliance/overview/overview";
import CreateCompliance from "components/contentcomponents/compliance/overview/create/create";
import MatrixOverview from "components/contentcomponents/compliance/matrixOverview/matrixOverview";
import ComplianceRequests from "components/contentcomponents/compliance/requests/requests";
import SelfAssessment from "components/contentcomponents/compliance/selfAssessment/selfAssessment";
import NCR from "components/mainContainers/ncr/ncr";
import ReviewOverview from "components/mainContainers/reviews/reviews";
import ReviewCreate from "components/contentcomponents/reviews/review/modals/create/createPage";
import SurveysOverview from "components/mainContainers/reviews/surveys";
import NonResponseOverview from "components/mainContainers/reviews/nonResponse";
import AutomatedSurveyeOverview from "components/mainContainers/reviews/automatedSurvey";
import Dashboard from "components/mainContainers/analytics/analyticsArea";
import ContactsOverviewPage from "components/mainContainers/contacts/contactsOverviewPage";
import ContactsGroupOverviewPage from "components/mainContainers/contacts/contactsGroupOverviewPage";
import FilesOverviewPage from "components/mainContainers/files/filesOverviewPage";
import ActionsOverviewPage from "components/mainContainers/action/actionOverviewPage";
import SurveyWizard from "components/contentcomponents/reviews/survey/surveyWizard/surveyWizard";
import Reporting from "components/contentcomponents/reporting/reporting";
import { Spin } from "antd";
import GuestRoutes from "./guestRoutes";
import developmentPlanOverviewPage from "components/mainContainers/developmentPlan/developmentPlanOverviewPage";
import { useCompanyType, useFilesFeature, useNcrFeature, useProductFeature } from "controller";
import NcrPortfolio from "components/mainContainers/ncr/ncrPortfolio";
import { ProductOverview } from "components/contentcomponents/product/overview/overview";
import { SurveyRequests } from "components/contentcomponents/reviews/survey/requests/SurveyRequests";

const AdminRoutes = lazy(() => import("./adminRoutes"));

const Routes = () => {
    const { isDefaultCompany } = useCompanyType();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const { hasProductFeature } = useProductFeature();
    const { hasNcrFeature } = useNcrFeature();
    const { hasFilesFeature } = useFilesFeature();
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;
    const hasAdminAccess = user.profile["ll-Admin"] === "true" ? true : false;
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const hasSupplierAccess = user.profile["ll-Partner"] === "true" ? true : false;
    const hasNcrAccess = user.profile["ll-Ncr"] === "true" ? true : false;
    const hasContactAccess = user.profile["ll-Contact"] === "true" ? true : false;
    const hasFileAccess = user.profile["ll-Files"] === "true" ? true : false;
    const hasKpiAccess = user.profile["ll-Kpi"] === "true" ? true : false;
    const hasReportingAccess = user.profile["ll-Reporting"] === "true";
    const hasProductAccess = user.profile["ll-Product"] === "true";
    const companyActionAccess =
        (user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyComplianceAccess =
        (user.profile["ll-enabledCompanyModule-Compliance"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyContactAccess =
        (user.profile["ll-enabledCompanyModule-Contact"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyPartnerAccess =
        (user.profile["ll-enabledCompanyModule-Supplier"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyFileAccess =
        (user.profile["ll-enabledCompanyModule-File"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyNcrAccess =
        (user.profile["ll-enabledCompanyModule-Ncr"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReviewAccess =
        (user.profile["ll-enabledCompanyModule-Review"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReportingAccess =
        (user.profile["ll-enabledCompanyModule-Reporting"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;

    if (location.hash.startsWith("#/")) {
        history.replace(location.hash.replace("#", "")); // or history.replace
    }
    return (
        <Suspense
            fallback={
                <div className="callbackSpinner">
                    <Spin size="large" />
                </div>
            }>
            <Switch>
                <Route exact path="/AcceptInvitation/:token" component={Home} />
                <Route
                    exact
                    path="/Registration/:token"
                    render={props => (
                        <Redirect to={`/AcceptInvitation/${props.match.params.token}`} />
                    )}
                />
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") && <Route exact path="/" component={Home} />}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") && <Route path="/Home" component={Home} />}

                {(userType === "user" || userType === "administrator") &&
                    hasSupplierAccess &&
                    companyPartnerAccess && (
                        <Route
                            exact
                            path="/Partners/companyNumber/:companyNumber+"
                            component={Suppliers}
                        />
                    )}

                {(userType === "user" || userType === "administrator") &&
                    hasSupplierAccess &&
                    companyPartnerAccess && <Route exact path="/Partners" component={Suppliers} />}

                {userType === "administrator" && hasSupplierAccess && companyPartnerAccess && (
                    <Route
                        exact
                        path="/Partners/MasterData/companyNumber/:companyNumber+"
                        component={MasterDataOverView}
                    />
                )}
                {(userType === "user" || userType === "administrator") &&
                    hasSupplierAccess &&
                    companyPartnerAccess && (
                        <Route exact path="/Partners/MasterData" component={MasterDataOverView} />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasSupplierAccess &&
                    companyPartnerAccess && (
                        <Route
                            path="/Partners/SupplierProfile/:tab(\d+)?/:id(\d+)?/:presetId(\d+)?/:companyId(\d+)?"
                            component={Suppliers}
                        />
                    )}

                {(userType === "user" || userType === "administrator") && hasKpiAccess && (
                    <Route
                        path="/partnerAnalytics/:id(\d+)?/:presetId(\d+)?/:companyId(\d+)?"
                        render={() => <Dashboard sharedDashboardView={true} />}
                    />
                )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance" component={ComplianceOverview} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance/Create" component={CreateCompliance} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/SupplierProfileCompliance/Create"
                            component={CreateCompliance}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/Create/:template(\d+)?"
                            component={CreateCompliance}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance/MatrixOverview" component={MatrixOverview} />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance/Requests" component={ComplianceRequests} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route exact path="/Compliance/:id(\d+)?" component={ComplianceOverview} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/partnerId/:partnerId/from/:from/to/:to"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/filter/MyGateKeeperRegistrations"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/filter/MyRegistrations"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/Compliance/companyNumber/:companyNumber"
                            component={ComplianceOverview}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasComplianceAccess &&
                    companyComplianceAccess && (
                        <Route
                            exact
                            path="/SelfAssessment/:guestToken"
                            component={SelfAssessment}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess &&
                    companyReviewAccess && (
                        <Route exact path="/Reviews" component={ReviewOverview} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route exact path="/Reviews/reviewId/:id" component={ReviewOverview} />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/partnerId/:partnerId/from/:from/to/:to"
                            component={ReviewOverview}
                        />
                    )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route exact path="/Reviews/create" component={ReviewCreate} />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route exact path="/Reviews/SurveyOverview" component={SurveysOverview} />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/SurveyOverview/:automatedSurveyId(\d+)?"
                            component={SurveysOverview}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/SurveyOverview/surveyId/:surveyId(\d+)?"
                            component={SurveysOverview}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/SurveyOverview/Create/type/:type(\d+)/:partnerId(\d+)?/:repeatSurveyId(\d+)?"
                            component={SurveyWizard}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/SurveyOverview/Create"
                            component={SurveyWizard}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/NonResponseOverview"
                            component={NonResponseOverview}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route
                            exact
                            path="/Reviews/AutomatedSurveyOverview"
                            component={AutomatedSurveyeOverview}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasReviewAccess &&
                    companyReviewAccess && (
                        <Route exact path="/SurveyRequests" component={SurveyRequests} />
                    )}

                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrFeature &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route path="/Ncrs/Portfolio" component={NcrPortfolio} />}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrFeature &&
                    hasNcrAccess &&
                    companyNcrAccess && (
                        <Route
                            path="/Ncrs/partnerId/:partnerId/from/:from/to/:to"
                            component={NCR}
                        />
                    )}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrFeature &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route exact path="/Ncrs" component={NCR} />}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrFeature &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route exact path="/Ncrs/:id(\d+)?" component={NCR} />}
                {(userType === "lightuser" ||
                    userType === "user" ||
                    userType === "administrator") &&
                    hasNcrFeature &&
                    hasNcrAccess &&
                    companyNcrAccess && <Route exact path="/Ncrs/filter/MyNcrs" component={NCR} />}
                {(userType === "user" || userType === "administrator") &&
                    hasContactAccess &&
                    companyContactAccess && (
                        <Route
                            exact
                            path="/Contacts/ContactGroup"
                            component={ContactsGroupOverviewPage}
                        />
                    )}
                {(userType === "user" || userType === "administrator") &&
                    hasContactAccess &&
                    companyContactAccess && (
                        <Route exact path="/Contacts" component={ContactsOverviewPage} />
                    )}

                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    hasFileAccess &&
                    companyFileAccess && (
                        <Route exact path="/Files" component={FilesOverviewPage} />
                    )}

                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    hasFileAccess &&
                    companyFileAccess && (
                        <Route
                            exact
                            path="/Files/folderId/:folderId(\d+)?"
                            component={FilesOverviewPage}
                        />
                    )}
                {hasFilesFeature &&
                    (userType === "user" || userType === "administrator") &&
                    hasFileAccess &&
                    companyFileAccess && (
                        <Route
                            exact
                            path="/Files/fileId/:fileId(\d+)?"
                            component={FilesOverviewPage}
                        />
                    )}
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route exact path="/Actions" component={ActionsOverviewPage} />
                    )}
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route path="/Actions/:actionId" component={ActionsOverviewPage} />
                    )}

                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route
                            path="/DevelopmentPlans/:planId(\d+)?"
                            component={developmentPlanOverviewPage}
                        />
                    )}
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    companyActionAccess && (
                        <Route
                            path="/DevelopmentPlans/myPlans"
                            component={developmentPlanOverviewPage}
                        />
                    )}

                <Route path="/analytics/Dashboard/:presetId(\d+)" component={Dashboard} />
                <Route exact path="/User" component={AccountProfile} />
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    hasReportingAccess &&
                    companyReportingAccess && (
                        <Route exact path="/Reporting" component={Reporting} />
                    )}
                {(userType === "user" ||
                    userType === "administrator" ||
                    userType === "lightuser") &&
                    hasReportingAccess &&
                    companyReportingAccess && (
                        <Route path="/Reporting/:tab" component={Reporting} />
                    )}

                {userType === "administrator" && hasAdminAccess && (
                    <Route path="/Admin" component={AdminRoutes} />
                )}

                {hasProductFeature && hasProductAccess && (
                    <Route path="/Products" component={ProductOverview} />
                )}
                <GuestRoutes />

                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
