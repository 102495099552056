import { useState } from "react";
import Provider from "services/api";

const useSupplierProfileSettingsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (id: number, enabled: boolean) => {
        setLoading(true);
        await Provider.SupplierProfileSettings.updateSupplierProfileSetting(id, enabled, {
            customError: {
                message: "Could not update setting",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        updateSetting: update,
    };
};

export { useSupplierProfileSettingsUpdate };
