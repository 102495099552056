import { useEffect, useState } from "react";
import { Provider } from "services";

type IProps = {
    data: any[];
    loading: boolean;
};

const useNewsLog = (
    supplierId: any,
    reload: any,
    searchTitle?: any,
    from?: any,
    to?: any,
    flaggedItems: boolean = false
) => {
    const [state, setState] = useState<IProps>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data = await Provider.Partner.getNewsLog(
                supplierId,
                searchTitle,
                from,
                to,
                flaggedItems
            );
            setState({
                data: data,
                loading: false,
            });
        })();
    }, [reload, supplierId, searchTitle, from, to, flaggedItems]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNewsLog };
