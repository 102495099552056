import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications, usePrevious, useSetShownOnAllNotifications } from "controller";
import { Spin, Row, Col } from "antd";
import { getNotification, UserImage } from "components/utilitycomponents";
import classes from "./notificationList.module.scss";
import { INotificationData } from "../../../../models";

type PropTypes = {
    dropDown?: boolean;
};
const NotificationList = ({ dropDown = true }: PropTypes) => {
    const pageSize = 10;
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState(1);
    const {
        data: { data = [], total = 0 },
        loading,
    } = useNotifications(pageNumber, pageSize);
    const [noMoreActivities, setNoMoreActivities] = useState<null | string>(null);
    const prevLoading = usePrevious(loading);
    const [lockLoadingData, setLockLoadingData] = useState<boolean>(false);

    if (!!prevLoading === true && loading === false && lockLoadingData === true) {
        setLockLoadingData(false);
    }
    useSetShownOnAllNotifications();
    const handleScrollReachedBottom = () => {
        if (!loading) {
            if (total > pageNumber * pageSize) {
                setPageNumber(pageNumber + 1);
            } else {
                setNoMoreActivities("No More Activities");
            }
        }
    };

    const handleScroll = () => {
        const scrollNode = document.querySelector(
            dropDown ? "#notificationListDropDown" : "#notificationList"
        );
        const offset: number = 2;
        if (scrollNode !== null) {
            const scrollPosition = scrollNode.scrollTop + scrollNode.clientHeight;
            const height = scrollNode.scrollHeight - offset;

            if (scrollPosition >= height && !lockLoadingData) {
                setLockLoadingData(true);
                handleScrollReachedBottom();
            }
        }
    };

    useEffect(() => {
        const scrollNode = document.querySelector(
            dropDown ? "#notificationListDropDown" : "#notificationList"
        );
        if (scrollNode !== null) {
            scrollNode.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollNode !== null) {
                scrollNode.removeEventListener("scroll", handleScroll);
            }
        };
    });

    return (
        <div
            id={dropDown ? "notificationListDropDown" : "notificationList"}
            style={{
                ...(dropDown && { width: "450px" }),
                ...(dropDown && { border: "1px solid #e4e4e5" }),
                maxHeight: "450px",
                overflowY: "scroll",
            }}>
            {dropDown && (
                <div style={{ padding: "8px 5px", backgroundColor: "#F5F5F5", fontWeight: "bold" }}>
                    {t("Notifications")}
                </div>
            )}
            {data.map((notification: INotificationData, index: number) => {
                let { ActivityTypeComponent, activityDetail } = getNotification(notification);
                if (!activityDetail) return null;
                return (
                    <Row className={classes.notificationItem} key={index}>
                        <Col span={4}>
                            <UserImage
                                contactId={notification.registrationCreatorContactId}
                                alt={activityDetail.creator}
                                fileId={
                                    activityDetail["creatorProfilePictureId"]
                                        ? activityDetail["creatorProfilePictureId"]
                                        : activityDetail["profilePictureId"]
                                }
                                customDimension={60}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col span={20}>
                            <ActivityTypeComponent data={activityDetail} notification={true} />
                        </Col>
                    </Row>
                );
            })}
            <div style={{ textAlign: "center", marginTop: "5px" }}>
                <Spin spinning={loading} />
            </div>
            {noMoreActivities && <div></div>}
        </div>
    );
};
export default NotificationList;
