import IColumnDefinition from "models/columns/IColumnDefinition";
import ICustomColumn from "models/suppliers/customcolumns/ICustomColumn";
import ICustomColumnValue from "models/suppliers/customcolumns/ICustomColumnValue";
import IMasterDataPartner from "models/suppliers/IMasterDataPartner";
import { useEffect, useState } from "react";
import Provider from "services/api";

const useMasterDataOverview = (
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        recordsWithChildrenFound: null,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (!externalLoading) {
                const data: any = await Provider.Partner.masterDataOverview(
                    {
                        pageSize,
                        pageNumber,
                        filters,
                        sorts,
                        to: range.to,
                        from: range.from,
                    },
                    {
                        customError: {
                            message: "Could not retrieve list of partners",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: mapMasterData(data.partnersData, data.companyCustomColumns),
                    totalRows: data.numberOfTotalPartners,
                });
            }
        })();
    }, [pageNumber, pageSize, filters, sorts, reloadData, externalLoading, range]);
    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
    };
};

const mapMasterData = (data: any[], customColumns: ICustomColumn[]) => {
    let mappedResult: IMasterDataPartner[] = [];
    data.forEach(element => {
        let children: IMasterDataPartner[] = [];
        if (element.children.length > 0) {
            children = mapChildren(element, customColumns);
        }
        let partner: IMasterDataPartner = mapMasterDataRecord({
            element,
            customColumns,
            children,
            parentId: "",
            parentName: "",
        });
        mappedResult.push(partner);
    });

    return mappedResult;
};

const mapChildren = (element: any, customColumns: ICustomColumn[]) => {
    if (!element.children || element.children.length === 0) {
        return [];
    } else {
        let mappedChildren: IMasterDataPartner[] = [];
        for (let child of element.children) {
            let mappedChild: IMasterDataPartner = mapMasterDataRecord({
                element: child,
                customColumns,
                children: mapChildren(child, customColumns),
                parentId: element.partner.id.toString(),
                parentName: element.partner.name,
            });
            mappedChildren = [...mappedChildren, mappedChild];
        }
        return mappedChildren;
    }
};

const mapMasterDataRecord = ({
    element,
    customColumns,
    children,
    parentId,
    parentName,
}: {
    element: any;
    customColumns: ICustomColumn[];
    children: IMasterDataPartner[];
    parentId: string;
    parentName: string;
}) => {
    let partner: IMasterDataPartner = {
        company: { value: element.partner.name, displayName: "Company Name" },
        companynumber: {
            value: element.partner.number,
            displayName: "Company Number",
        },
        id: { value: element.partner.id, displayName: "ID" },
        group: {
            value: element.partnerGroup
                ? { name: element.partnerGroup.name, id: element.partnerGroup.id }
                : { id: undefined, name: undefined },
            displayName: "Group",
        },
        owners: {
            value: element.owners.map(({ id, userId, firstName, lastName }: any) => ({
                id: id,
                userId: userId,
                firstName: firstName,
                lastName: lastName,
            })),
            displayName: "Owner",
        },
        status: {
            value: element.partnerStatus
                ? {
                      name: element.partnerStatus.name,
                      color: element.partnerStatus.color,
                      key: element.partnerStatus.id,
                  }
                : { name: "", key: "", color: "" },
            displayName: "Status",
        },
        yearlyValueOfCooperation: {
            value: element.partner.yearlyValueOfCooperation,
            displayName: "Value",
        },
        currency: element.partner.currency,
        category1: {
            value: element.firstCategory.map(({ id, name }: any) => ({
                id: id,
                name: name,
            })),
            displayName: "Category 1",
        },
        category2: {
            value: element.secondCategory.map(({ id, name }: any) => ({
                id: id,
                name: name,
            })),
            displayName: "Category 2",
        },
        kpcFirstName: {
            value: element.partnerDetails ? element.partnerDetails.kpC_FirstName : "",
            displayName: "Point of Contact - First Name",
        },
        kpcLastName: {
            value: element.partnerDetails ? element.partnerDetails.kpC_LastName : "",
            displayName: "Point of Contact - Last Name",
        },
        kpcEmail: {
            value: element.partnerDetails ? element.partnerDetails.kpC_Email : "",
            displayName: "Point of Contact - Email",
        },
        kpcPhoneNumber: {
            value: element.partnerDetails ? element.partnerDetails.kpC_PhoneNumber : "",
            displayName: "Point of Contact - Phone Number",
        },
        kpcCountryCode: {
            value: element.partnerDetails ? element.partnerDetails.kpC_CountryCode : "",
            displayName: "Point of Contact - Country",
        },
        linked: {
            value: {
                name:
                    element.partnerDetails && element.partnerDetails.linkStatus
                        ? element.partnerDetails.linkStatus
                        : "NoEmail",
            },
            displayName: "Linked",
        },
        children:
            children.length > 0 ? children.map((c: any) => ({ ...c, siblings: children })) : null,
        childrenCopy:
            children.length > 0 ? children.map((c: any) => ({ ...c, siblings: children })) : null,
        hasChildren: children.length > 0 ? true : false,
        parentId: parentId,
        parentName: parentName,
        created: element.partner.created,
        createdBy: element.partner.createdBy,
        ncrContacts: element.ncrContacts,
        complianceContacts: element.complianceContacts,
        domain: element.partner.domain,
    };
    if (customColumns && customColumns.length > 0) {
        let dynamicColumns: IColumnDefinition[] = [];
        let dynamicColumnsWithIndex: any = {};
        for (let cc of customColumns) {
            let ccvs: {
                partnerId: number;
                customColumnId: number;
                value: string;
            }[] = element.customColumnValues;
            let mappedCCV: ICustomColumnValue = {
                name: cc.name,
                customColumnId: cc.id,
                partnerId: element.partner.id,
                value:
                    ccvs.filter(x => x.customColumnId === cc.id).length > 0
                        ? ccvs.filter(x => x.customColumnId === cc.id)[0].value
                        : "",
            };
            dynamicColumns.push({
                displayName: cc.name,
                value: mappedCCV,
            });
            dynamicColumnsWithIndex = {
                ...dynamicColumnsWithIndex,
                [cc.id]: mappedCCV,
            };
        }
        partner.customColumnsValues = dynamicColumns;
        partner.customColumnsValuesWithIndex = dynamicColumnsWithIndex;
    }
    return partner;
};

export { useMasterDataOverview };
