import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useDashboardDelete = () => {
    const [loading, SetLoading] = useState(false);

    const deleteDashboard = async (presetId: number) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.removeDashboard(presetId).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
        return response;
    };

    return {
        loading,
        deleteDashboard,
    };
};

export { useDashboardDelete };
