import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: any;
};

const useSurveyTemplateGroups = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Review.getSurveyTemplateGroups({
                customError: {
                    message: "Could not retrieve survey template groups",
                },
            }).catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyTemplateGroups };
