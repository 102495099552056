import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Input, Form } from "antd";
import classes from "./styles/submissionMatrix.module.scss";
import { ISurveyHeadline } from "models";
import { SelectData } from "components/utilitycomponents";

const TextArea = Input.TextArea;
interface IProps {
    form: any;
    surveyHeadlines: ISurveyHeadline[];
}

const SurveyTextFields: FunctionComponent<IProps> = ({ form, surveyHeadlines }) => {
    const { t } = useTranslation();

    return (
        <>
            {surveyHeadlines.map((item: ISurveyHeadline, index: number) => (
                <Row gutter={16} className={classes.topMargin} key={index}>
                    <Col span={24}>
                        {item.fieldType === "Text" ? (
                            <Card
                                title={item.title}
                                bodyStyle={{ padding: 0, height: 80 }}
                                headStyle={{ backgroundColor: "#fafafa" }}>
                                <Form.Item
                                    name={[`surveyTextFieldAnswers`, item.id]}
                                    required
                                    rules={[
                                        { required: true, message: t("inputIsRequiredError") },
                                    ]}>
                                    <TextArea
                                        maxLength={250}
                                        className={classes.textArea}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        placeholder={item.description}
                                    />
                                </Form.Item>
                            </Card>
                        ) : (
                            <Card
                                title={item.title}
                                bodyStyle={{ padding: 4 }}
                                headStyle={{ backgroundColor: "#fafafa" }}>
                                <Form.Item
                                    name={[`surveyCustomDropdownAnswers`, item.id]}
                                    rules={[
                                        { required: true, message: t("inputIsRequiredError") },
                                    ]}>
                                    <SelectData
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        allowClear
                                        selectIdField="text"
                                        selectOptionField="text"
                                        className={classes.selectData}
                                        placeholder={`Select a value`}
                                        useData={() => ({
                                            data: item.customDropdown.customDropdownOptions,
                                            loading: false,
                                        })}
                                    />
                                </Form.Item>
                            </Card>
                        )}
                    </Col>
                </Row>
            ))}
        </>
    );
};

export { SurveyTextFields };
