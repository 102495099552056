import { message } from "antd";
import i18n from "i18n";

const copyText = (elementId: string) => {
    var copyText = document.getElementById(elementId) as HTMLInputElement;
    if (copyText) {
        copyText.select();
        document.execCommand("copy");
        message.success(i18n.t("copied"));
    }
};

export { copyText };
