import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useDashboardUpdate = () => {
    const [loading, SetLoading] = useState(false);

    const updateDashboard = async (
        presetId: number,
        supplierId: number,
        updateData: {},
        benchmarkId?: number
    ) => {
        SetLoading(true);
        await serviceProvider.Analytics.updateDashboard(
            presetId,
            updateData,
            supplierId,
            benchmarkId
        ).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
    };

    return {
        loading,
        updateDashboard,
    };
};

export { useDashboardUpdate };
