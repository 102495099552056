import { useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

const useNotificationSettingUpdate = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const updateNotificationSetting = async (
        moduleId: number,
        receiveEmailNotification: number
    ) => {
        setLoading(true);

        await Provider.User.putNotificationSetting(moduleId, receiveEmailNotification, {
            customError: {
                message: t("account_UpdateError"),
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updateNotificationSetting,
    };
};

export { useNotificationSettingUpdate };
