import { useState } from "react";
import Provider from "services/api";

const useSupplierDomainUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateDomain = async (partnerId: number, domain?: string | undefined) => {
        if (partnerId === undefined) return;
        setLoading(true);

        if (domain && (domain.includes("https://") || domain.includes("http://"))) {
            domain = domain.replace("https://", "").replace("http://", "");
        }
        await Provider.Partner.updatePartnerDomain(partnerId, domain, {
            customError: {
                message: "Could not update url",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updateDomain,
    };
};

export { useSupplierDomainUpdate };
