import { get, post, put } from "../base";
import { ITableType, ITableSize } from "models";

const UserTableSize = {
    getTableSize: async (table: ITableType) => {
        const url = `/v1/UserTableSize?Table=${table}`;
        return await get({ url });
    },
    updateTableSize: async (table: ITableType, size: ITableSize) => {
        const url = `/v1/UserTableSize?Table=${table}&TableSize=${size}`;
        return await post({ url, payload: {} });
    },
    updatePageSize: async (table: ITableType, pageSize: number) => {
        const url = `/v1/UserTableSize/AddOrUpdateItemsPerPage?listViewId=${table}&itemPerPage=${pageSize}`;
        return await put({ url, payload: {} });
    },
};

export default UserTableSize;
