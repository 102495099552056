import { useEffect, useState } from "react";
import { IPartnerGroup } from "models";
import { Provider } from "services";

type StateType = {
    data: IPartnerGroup[];
    loading: boolean;
};

const usePartnerStatusLogs = (partnerId: number, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (partnerId) {
                setState({ ...state, loading: true });
                const data = await Provider.Partner.getPartnerStatusLog(partnerId);
                setState({ loading: false, data });
            }
        })();
    }, [partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerStatusLogs };
