import React, { ReactNode, forwardRef } from "react";
import { useContactsWithGroups } from "controller";
import { SelectTreeDataMultiple } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { Modules } from "models";

type PropsType = {
    onChange?: (value: number | number[]) => void;
    value?: number[];
    onVisibleToChanged: (value: number[]) => void;
    placeHolder?: ReactNode;
};

const SelectVisibleTo = forwardRef(
    ({ onChange, value, onVisibleToChanged, placeHolder }: PropsType, ref) => {
        const { t } = useTranslation();
        const { data: companyContacts, loading: loadingCompanyContacts } = useContactsWithGroups(
            ["Administrator", "User", "LightUser"],
            Modules.Compliance,
            null,
            true
        );
        const handleChange = (value: number[]) => {
            if (!onChange) return;
            onChange(value);
            onVisibleToChanged(value);
        };

        return (
            <SelectTreeDataMultiple
                data-test="visible_to_select"
                ref={ref as any}
                style={{ width: "100%" }}
                value={value}
                allowClear={true}
                useData={() => ({
                    data: companyContacts,
                    loading: loadingCompanyContacts,
                })}
                selectIdField="id"
                onChange={handleChange}
                selectAll
                selectOptionField="name"
                placeholder={placeHolder ? placeHolder : t("compliance_SelectUser")}
                selectOnlyChildren
            />
        );
    }
);

export { SelectVisibleTo };
