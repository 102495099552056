import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Input, Button, Tooltip, Result, Typography, Form } from "antd";
import { FormRow, HelpCircle, PageContainer } from "components/utilitycomponents";
import { useCheckPassword, useIsUserNameInUse, useUserRegistration } from "controller";
import {
    getEmailValidationPattern,
    getUserNameValidationPattern,
    getBackendDomainUrl,
} from "utilities";
import sharedClasses from "./styles/shared.module.scss";
const { Title } = Typography;

interface IProps {
    companyName: string;
    email: string;
    onBack: Function;
}

const CreateProfile = ({ companyName, email = "", onBack }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { returnUrl }: any = useParams();
    const history = useHistory();
    const { token }: any = useParams();
    const { registerUser, loading: registerLoading } = useUserRegistration();
    const { isUserNameInUse, loading: checkUserNameLoading } = useIsUserNameInUse();
    const { checkPassword, loading: checkPasswordLoading } = useCheckPassword();
    const [userRegistered, setUserRegistered] = useState<any>({
        userId: undefined,
        status: false,
    });
    const password = Form.useWatch("password", form);
    const handleSubmit = async () => {
        let values = await form.validateFields();
        const userId = await registerUser(
            {
                firstName: values["firstName"],
                lastName: values["lastName"],
                emailAddress: values["email"],
                userName: values["userName"],
                password: values["password"],
                jobTitle: values["jobTitle"],
                companyName,
                invitationType: 1,
            },
            token
        );
        setUserRegistered({
            userId,
            status: true,
        });
    };

    const loginBtnClick = () => {
        if (returnUrl) {
            history.push(`/${returnUrl}`);
        } else {
            history.push(`/Admin/UserManagement/NewCompany/${userRegistered.userId}`);
        }
    };

    return (
        <PageContainer className={sharedClasses.container} settingContainer>
            <Form
                form={form}
                colon={false}
                labelAlign="left"
                labelWrap
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Row className={sharedClasses.logoContainer}>
                    <img src={`${getBackendDomainUrl()}/v1/File/GetCompanyLogo`} alt="logo" />
                </Row>
                {userRegistered.status === true ? (
                    <Result
                        status="success"
                        title={t("admin_UserRegistration_SuccessTitle")}
                        subTitle={t("admin_UserRegistration_SuccessSubtitle")}
                        extra={[
                            <Button key="loginBtn" type="primary" onClick={() => loginBtnClick()}>
                                {t("admin_UserRegistration_GoToLoginBtn")}
                            </Button>,
                        ]}
                    />
                ) : (
                    <>
                        <Title level={4} className={sharedClasses.secondaryTitle}>
                            {t("supplier_Registration_CreateProfilePageTitle")}
                        </Title>

                        <Form.Item
                            label={t("admin_UserRegistration_FistName")}
                            name="firstName"
                            required
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}>
                            <Input
                                maxLength={200}
                                placeholder={t("admin_UserRegistration_FirstNamePlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("admin_UserRegistration_LastName")}
                            name="lastName"
                            required
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}>
                            <Input
                                maxLength={200}
                                placeholder={t("admin_UserRegistration_LastNamePlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    {t("admin_UserRegistration_EmailAddress")}{" "}
                                    <Tooltip title={t("admin_UserRegistration_EmailGuideMsg")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="email"
                            initialValue={email}
                            required
                            rules={[
                                {
                                    required: true,
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value.trim() === "") {
                                            return Promise.reject(
                                                new Error(t("inputIsRequiredError"))
                                            );
                                        } else if (
                                            !new RegExp(getEmailValidationPattern().regex).test(
                                                value
                                            )
                                        ) {
                                            return Promise.reject(
                                                new Error(t(getEmailValidationPattern().messageKey))
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input
                                disabled
                                maxLength={256}
                                placeholder={t("admin_UserRegistration_EmailAddressPlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    {t("admin_UserRegistration_Username")}{" "}
                                    <Tooltip title={t("admin_UserRegistration_UserValidationMsg")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="userName"
                            initialValue={email}
                            required
                            rules={[
                                {
                                    required: true,
                                    validator: async (rule: any, value: any, callback: any) => {
                                        if (!value || value.trim() === "") {
                                            return Promise.reject(
                                                new Error(t("inputIsRequiredError"))
                                            );
                                        } else if (
                                            !new RegExp(getUserNameValidationPattern().regex).test(
                                                value
                                            )
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    t(getUserNameValidationPattern().messageKey)
                                                )
                                            );
                                        } else if (await isUserNameInUse(value, token)) {
                                            return Promise.reject(
                                                new Error(
                                                    t("admin_UserRegistration_UsernameDuplicateMsg")
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input
                                maxLength={50}
                                placeholder={t("admin_UserRegistration_UsernamePlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("admin_UserRegistration_JobTitle")}
                            name="jobTitle"
                            required
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}>
                            <Input
                                maxLength={200}
                                placeholder={t("admin_UserRegistration_JobTitlePlaceholder")}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <>
                                    {t("admin_UserRegistration_Password")}{" "}
                                    <Tooltip
                                        title={t("admin_UserRegistration_PasswordValidationMsg")}>
                                        <span>
                                            <HelpCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="password"
                            required
                            rules={[
                                {
                                    required: true,
                                    validator: async (rule: any, value: any, callback: any) => {
                                        const res = await checkPassword(
                                            form.getFieldValue("userName"),
                                            value
                                        );
                                        if (res) return Promise.reject(new Error(res));
                                        else return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input.Password
                                type="password"
                                placeholder={t("admin_UserRegistration_PasswordPlaceholder")}
                                maxLength={40}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t("admin_UserRegistration_PasswordConfirm")}
                            name="confirmPassword"
                            required
                            rules={[
                                {
                                    required: true,
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value || value !== form.getFieldValue("password")) {
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        "admin_UserRegistration_PasswordConfirmErrorMsg"
                                                    )
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input.Password
                                disabled={password === "" || password === undefined}
                                type="password"
                                placeholder={t("admin_UserRegistration_PasswordConfirmPlaceholder")}
                                maxLength={40}
                            />
                        </Form.Item>

                        <FormRow>
                            <Col span={12}>
                                <Button
                                    onClick={() => {
                                        onBack();
                                    }}>
                                    {t("generic_Back")}
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    loading={registerLoading}
                                    onClick={() =>
                                        !checkUserNameLoading &&
                                        !checkPasswordLoading &&
                                        handleSubmit()
                                    }>
                                    {t("admin_UserRegistration_RegisterBtn")}
                                </Button>
                            </Col>
                        </FormRow>
                    </>
                )}
            </Form>
        </PageContainer>
    );
};

export default CreateProfile;
