import { useState } from "react";
import { Provider } from "services";

const useSupplierCardsRiskUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateRisk = async (values: any) => {
        setLoading(true);
        await Provider.Partner.updateRisk(values, {
            customError: {
                message: "Could not update the risk",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updateRiskFile = async (partnerId: number, fileId: number) => {
        setLoading(true);
        await Provider.Partner.updateRiskFile(partnerId, fileId, {
            customError: {
                message: "Could not upload risk file",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        updateRisk,
        updateRiskFile,
    };
};

export { useSupplierCardsRiskUpdate };
