import { Reducer, Action } from "redux";

export interface KeysState {
    activeKey: string;
    selectedKey: any[];
}

const initialState: KeysState = {
    activeKey: localStorage.getItem("activeKey") || "",
    selectedKey: JSON.parse(localStorage.getItem("selectedKey") || "[]"),
};

const SET_ACTIVE_KEY = "SET_ACTIVE_KEY";
const SET_SELECTED_KEY = "SET_SELECTED_KEY";

interface SetActiveKeyAction extends Action<typeof SET_ACTIVE_KEY> {
    payload: string;
}
interface SetSelectedKeyAction extends Action<typeof SET_SELECTED_KEY> {
    payload: any[];
}

export const setActiveKeyState = (activeKey: string): SetActiveKeyAction => ({
    type: SET_ACTIVE_KEY,
    payload: activeKey,
});

export const setSelectedKeyState = (selectedKey: any[]): SetSelectedKeyAction => ({
    type: SET_SELECTED_KEY,
    payload: selectedKey,
});

type KeyActions = SetActiveKeyAction | SetSelectedKeyAction;

const mainMenuReducer: Reducer<KeysState, KeyActions> = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_KEY:
            localStorage.setItem("activeKey", action.payload);
            return { ...state, activeKey: action.payload };
        case SET_SELECTED_KEY:
            localStorage.setItem("selectedKey", JSON.stringify(action.payload));
            return { ...state, selectedKey: action.payload };
        default:
            return state;
    }
};

export default mainMenuReducer;
