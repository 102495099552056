import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
interface IProps {
    onSelect: (value: boolean) => void;
    defaultVaule: boolean;
}
const SideBarFilter = ({ onSelect, defaultVaule }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Select
                defaultValue={defaultVaule}
                style={{ width: 200 }}
                onChange={(value: boolean) => onSelect(value)}
                options={[
                    { value: true, label: t("file_CompanyFolders") },
                    { value: false, label: t("file_AllFolders") },
                ]}
            />
        </>
    );
};

export default SideBarFilter;
