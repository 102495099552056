import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Input, notification, Form, Button } from "antd";
import classes from "./styles/createModal.module.scss";
import { SelectData } from "components/utilitycomponents";
import { useDashboardCreate } from "controller";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
    visible: boolean;
    closeModal: Function;
    onDashboardCreated: Function;
    partnerId: number;
    existingDashboards: any[];
}

const DashboardCreationModal = ({
    visible,
    closeModal,
    onDashboardCreated,
    partnerId,
    existingDashboards,
}: IProps) => {
    const [form] = Form.useForm();
    const { createDashboard, loading } = useDashboardCreate();
    const { tab, companyId }: any = useParams();
    const history = useHistory();
    const [selectedVisibilty, setSelectedVisibilty] = useState(1);
    const { t } = useTranslation();

    async function submit() {
        let values = await form.validateFields();
        let response = await createDashboard(partnerId, {
            name: values.dashboardName,
            visibility: values.visibility,
        });
        closeModal();
        form.resetFields();
        notification.success({
            message: t("success"),
            description: t("analytics_CreateDashboardSuccess"),
        });
        history.replace(`/Partners/SupplierProfile/${tab}/${partnerId}/${response}/${companyId}`);
        onDashboardCreated(response);
        setSelectedVisibilty(1);
    }

    function onCancel() {
        setSelectedVisibilty(1);
        closeModal();
        form.resetFields();
    }

    function renderVisibilityText(visibility: number) {
        switch (visibility) {
            case 0:
                return <span>{t("analytics_PersonalInfo")}</span>;
            case 1:
                return <span>{t("analytics_InternalInfo")}</span>;
            case 2:
                return (
                    <span>
                        <InfoCircleOutlined className={classes.infoIcon} />{" "}
                        {t("analytics_ExternalInfo")}
                    </span>
                );
            default:
                break;
        }
    }

    return (
        <Modal
            title={t("analytics_CreateDashboard")}
            open={visible}
            onCancel={() => onCancel()}
            onOk={submit}
            width={900}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await submit()}>
                    {t("generic_Create")}
                </Button>,
            ]}>
            <Form
                form={form}
                onFinish={submit}
                labelAlign="left"
                wrapperCol={{ span: 18 }}
                labelCol={{ span: 6 }}>
                <Form.Item
                    label={t("analytics_DashboardName")}
                    required
                    className={classes.formItem}
                    name={`dashboardName`}
                    rules={[
                        {
                            required: true,
                            validator: (rule: any, value: any, callback: any) => {
                                if (!value || value.trim() === "")
                                    return Promise.reject(new Error(t("inputIsRequiredError")));
                                else if (
                                    existingDashboards.filter(
                                        (x: any) =>
                                            x.name.toLowerCase().trim() ===
                                            value.toLowerCase().trim()
                                    ).length > 0
                                ) {
                                    return Promise.reject(new Error(t("contact_GroupNameExists")));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}>
                    <Input maxLength={100} placeholder={t("analytics_DashboardPlaceholder")} />
                </Form.Item>

                <Form.Item
                    label={t("analytics_DashboardVisibility")}
                    className={classes.formItem}
                    name={`visibility`}
                    initialValue={1}
                    extra={renderVisibilityText(selectedVisibilty)}>
                    <SelectData
                        className={classes.select}
                        placeholder={t("analytics_DashboardVisibilityPlaceholder")}
                        selectOptionField="name"
                        onChange={(val: any) => setSelectedVisibilty(val)}
                        useData={() => {
                            return {
                                data: [
                                    { id: 0, name: t("analytics_Personal") },
                                    { id: 1, name: t("analytics_Internal") },
                                    { id: 2, name: t("analytics_External") },
                                ],
                                loading: false,
                            };
                        }}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default DashboardCreationModal;
