import React, { FunctionComponent } from "react";
import { Button, Modal, Spin, Tooltip } from "antd";
import classes from "./styles/surveyReviewGraph.module.scss";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { useSurveyReviewCountGraphs } from "controller";

type PropsType = {
    surveyId: number;
    created: string;
    visible: boolean;
    onClose: Function;
};

export const SurveyReviewGraphModal: FunctionComponent<PropsType> = ({
    surveyId,
    created,
    visible,
    onClose,
}) => {
    function onSubmit() {
        window.print();
    }
    const { t } = useTranslation();
    const { data, loading } = useSurveyReviewCountGraphs(surveyId);

    const legendColors = [
        "#9e0142",
        "#d53e4f",
        "#f46d43",
        "#fdae61",
        "#fee08b",
        "#ffffbf",
        "#e6f598",
        "#abdda4",
        "#66c2a5",
        "#3288bd",
        "#5e4fa2",
    ];

    const customBottomTick = (tick: any) => {
        return (
            <g transform={`translate(${tick.x},${tick.tickIndex % 2 ? tick.y + 12 : tick.y + 22})`}>
                <line
                    stroke="rgb(190, 190, 190)"
                    strokeWidth={1.5}
                    y1={tick.tickIndex % 2 ? -12 : -22}
                    y2={-12}
                />
                <Tooltip title={t(tick.value)}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fill: "#333",
                            fontSize: 10,
                        }}>
                        {tick.value.length > 30
                            ? t(tick.value)
                                  .substring(0, 30)
                                  .trim() + "..."
                            : t(tick.value)}
                    </text>
                </Tooltip>
            </g>
        );
    };

    function renderReviewCategoryGraphs() {
        return (
            <div className={classes.Chart}>
                <h3>{t("survey_ReviewBySupplierGraphTitle")}</h3>
                <div className={classes.legendContainer}>
                    {data.surveyReviewCategoriesGraphData &&
                        data.surveyReviewCategoriesGraphData.keys.map(
                            (key: string, index: number) => (
                                <>
                                    <span
                                        style={{
                                            backgroundColor:
                                                legendColors[
                                                    index >= legendColors.length
                                                        ? index - legendColors.length
                                                        : index
                                                ],
                                        }}
                                        className={classes.legendItem}>
                                        {" "}
                                    </span>
                                    <span style={{ marginRight: 4 }}>
                                        {t(key).length > 13 &&
                                        data.surveyReviewCategoriesGraphData.keys.length > 3 ? (
                                            <Tooltip title={t(key)}>
                                                {t(key).slice(0, 12) + "..."}
                                            </Tooltip>
                                        ) : (
                                            t(key)
                                        )}
                                    </span>
                                </>
                            )
                        )}
                </div>
                <ResponsiveBar
                    colors={{ scheme: "spectral" }}
                    margin={{ top: 25, right: 35, bottom: 50, left: 40 }}
                    padding={0.15}
                    innerPadding={3}
                    groupMode="grouped"
                    layout="vertical"
                    indexBy="Key"
                    keys={
                        data.surveyReviewCategoriesGraphData
                            ? data.surveyReviewCategoriesGraphData.keys
                            : []
                    }
                    data={
                        data.surveyReviewCategoriesGraphData
                            ? data.surveyReviewCategoriesGraphData.data
                            : []
                    }
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -32,
                        legend: t("survey_Score", "Score"),
                    }}
                    axisBottom={{
                        renderTick: customBottomTick,
                        legend: t("survey_ReviewCriteria", "Review Criteria"),
                        legendPosition: "middle",
                        legendOffset: 42,
                    }}
                    tooltip={({ id, value }: any) => (
                        <div className={classes.tooltip}>
                            <strong>
                                {t(id)}: {value && value.toString()}
                            </strong>
                        </div>
                    )}
                    enableGridX={true}
                    enableLabel={false}
                    labelTextColor="#fff"
                    animate={true}
                    layers={["grid", "axes", "bars", "markers", "legends"] as any}
                />
            </div>
        );
    }

    function renderSupplierReviewGraphs() {
        return (
            <div className={classes.Chart}>
                <h3>{t("survey_SupplierByReviewGraphTitle")}</h3>
                <div>
                    {data.surveySuppliersGraphData &&
                        data.surveySuppliersGraphData.keys.map((key: string, index: number) => (
                            <>
                                <span
                                    style={{
                                        backgroundColor:
                                            legendColors[
                                                index >= legendColors.length
                                                    ? index - legendColors.length
                                                    : index
                                            ],
                                    }}
                                    className={classes.legendItem}>
                                    {" "}
                                </span>
                                <span style={{ marginRight: 4 }}>
                                    {t(key).length > 13 &&
                                    data.surveySuppliersGraphData.keys.length > 3 ? (
                                        <Tooltip title={t(key)}>
                                            {t(key).slice(0, 12) + "..."}
                                        </Tooltip>
                                    ) : (
                                        t(key)
                                    )}
                                </span>
                            </>
                        ))}
                </div>
                <ResponsiveBar
                    colors={{ scheme: "spectral" }}
                    margin={{ top: 25, right: 35, bottom: 50, left: 40 }}
                    padding={0.15}
                    innerPadding={3}
                    groupMode="grouped"
                    layout="vertical"
                    indexBy="Key"
                    keys={data.surveySuppliersGraphData ? data.surveySuppliersGraphData.keys : []}
                    data={data.surveySuppliersGraphData ? data.surveySuppliersGraphData.data : []}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -32,
                        legend: t("survey_Score", "Score"),
                    }}
                    axisBottom={{
                        renderTick: customBottomTick,
                        legend: t("survey_ReviewSupplier", "Suppliers"),
                        legendPosition: "middle",
                        legendOffset: 42,
                    }}
                    tooltip={({ id, value }: any) => (
                        <div className={classes.tooltip}>
                            <strong>
                                {t(id)}: {value && value.toString()}
                            </strong>
                        </div>
                    )}
                    enableGridX={true}
                    enableLabel={false}
                    labelTextColor="#fff"
                    animate={true}
                    layers={["grid", "axes", "bars", "markers", "legends"] as any}
                />
            </div>
        );
    }

    return (
        <Modal
            width="75%"
            title={`${t("survey_SurveyCreated", "Survey Created")} ${created}`}
            open={visible}
            onOk={() => onSubmit()}
            onCancel={() => onClose()}
            footer={[
                <Button type="primary" onClick={async () => await onSubmit()}>
                    {t("print")}
                </Button>,
            ]}>
            <Spin spinning={loading}>
                <div className={classes.mainContainer}>
                    <div className={classes.chartContainer}>{renderReviewCategoryGraphs()}</div>
                    <div className={classes.chartContainer}>{renderSupplierReviewGraphs()}</div>
                </div>
            </Spin>
        </Modal>
    );
};

export default SurveyReviewGraphModal;
