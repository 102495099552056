import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useReportDelete = () => {
    const [loading, SetLoading] = useState(false);

    const deleteReport = async (presetId: number) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.removeReport(presetId).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
        return response;
    };

    return {
        loading,
        deleteReport,
    };
};

export { useReportDelete };
