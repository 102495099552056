import React from "react";
import { connect } from "react-redux";
import { AppState } from "store";
import { notification } from "antd";

type ApiErrorPropsType = {
    title?: string | null;
    message?: string;
};

class ApiErrorNotification extends React.Component<ApiErrorPropsType> {
    componentDidUpdate() {
        const { title, message } = this.props;
        notification["error"]({
            message: title,
            description: message && message.split("/time/")[0],
        });
    }
    render() {
        return null;
    }
}

function mapStateToProps(state: AppState) {
    const { title, message } = state.api.apiError;
    return {
        title,
        message,
    };
}

export default connect(mapStateToProps, null)(ApiErrorNotification);
