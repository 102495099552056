import React from "react";
import { Row, Col } from "antd";
import { GuageMetrics } from "./gaugeMetrics";
import { HeatMapMetric } from "./heatmapMetric";

interface IProps {
    partnerId: number;
}

const HighLevelMetrics = ({ partnerId }: IProps) => {
    return (
        <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} style={{ marginBottom: 12 }}>
                <HeatMapMetric partnerId={partnerId} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                <GuageMetrics partnerId={partnerId} />
            </Col>
        </Row>
    );
};

export { HighLevelMetrics };
