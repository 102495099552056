export const jobLevels = [
    {
        value: 1,
        label: "Entry",
    },
    {
        value: 2,
        label: "Mid",
    },
    {
        value: 3,
        label: "Senior",
    },
    {
        value: 4,
        label: "Lead",
    },
    {
        value: 5,
        label: "Manager",
    },
    {
        value: 6,
        label: "Head",
    },
    {
        value: 7,
        label: "Director",
    },
];
