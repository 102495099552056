import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useLayoutUpdate = () => {
    const [loading, SetLoading] = useState(false);

    const updateLayout = async (dashboardId: string, layout: any[]) => {
        SetLoading(true);
        const modifiedLayout = layout.map((x: any) => {
            return { h: x.h, i: x.i, w: x.w, x: x.x, y: x.y };
        });

        await serviceProvider.Analytics.updateLayout(parseInt(dashboardId), modifiedLayout).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
    };

    return {
        loading,
        updateLayout,
    };
};

export { useLayoutUpdate };
