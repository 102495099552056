import { Button, Result, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSupplierPortalActionsForSupplierCount } from "controller";
import classes from "./supplierLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { ArrowsRight } from "components/utilitycomponents";

const ActionCount = () => {
    const { t } = useTranslation("supplierPortal");
    const { data, loading } = useSupplierPortalActionsForSupplierCount();

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Result
                    className={classes.resultPadding}
                    icon={<ArrowsRight style={{ fontSize: 72 }} />}
                    title={
                        <span>
                            {t("generic_YouHave")} <span className={classes.count}>{data}</span>{" "}
                            {t("action_LandingPageComponentText")}
                        </span>
                    }
                    extra={
                        <Link to="/Actions">
                            <Button type="primary">{t("generic_See")}</Button>
                        </Link>
                    }
                />
            )}
        </>
    );
};
export { ActionCount };
