import { post } from "../base";

const StrategyAndGovernance = {
    updateSupplierCardsPartner: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/StrategyAndGovernance/EditStrategyAndGovernance`;
        return await post({ url, payload, customError: customError });
    },
    updateSupplierCardsMeeting: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/StrategyAndGovernance/EditGovernance`;
        return await post({ url, payload, customError: customError });
    },
    updateSupplierCardsRole: async (payload: any, { customError }: any = {}) => {
        const url = `/v1/StrategyAndGovernance/EditPartnerContacts`;
        return await post({ url, payload, customError: customError });
    },
};

export default StrategyAndGovernance;
