import { useState } from "react";
import { Provider } from "services";

const useGroupUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateGroup = async ({ groupId, newName, customHeadline }: any) => {
        setLoading(true);
        const id = await Provider.Partner.updateGroupName(
            { groupId, newName },
            {
                customError: { message: `Could not update ${customHeadline}` },
            }
        );
        setLoading(false);
        return id;
    };

    return {
        loading,
        updateGroup,
    };
};

export { useGroupUpdate };
