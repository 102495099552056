import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Modal,
    Button,
    Col,
    Row,
    Input,
    notification,
    Tooltip,
    Form,
    Flex,
    Popconfirm,
} from "antd";
import {
    SelectData,
    SelectDataMultiple,
    SelectExternalPartner,
    HelpCircle,
} from "components/utilitycomponents";
import { useContactGroups, useContactUpdate, useContactsByEmail } from "controller";
import useCountries from "controller/common/useCountries";
import { useSelector } from "react-redux";
import { AppState } from "store";
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    data: any;
    onClose: (reload: boolean) => void;
}

const CreateEditModal = ({ data = {}, visible, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { createContact, updateContact, loading } = useContactUpdate();
    const currentCompanyId = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-CompanyId"]
    );
    const { getContactsByEmail, loading: loadingContactsByEmail } = useContactsByEmail();
    const [duplicateEmailContacts, setDuplicateEmailContacts] = useState<any[]>([]);
    const editMode = Object.keys(data).length !== 0;

    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
        setDuplicateEmailContacts([]);
    }, []);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const payload = {
            firstName: fieldsValue.firstName.trim(),
            lastName: fieldsValue.lastName.trim(),
            emailAddress: fieldsValue.emailAddress,
            workPhone: fieldsValue.workPhone ? fieldsValue.workPhone : "",
            mobilePhone: fieldsValue.mobilePhone ? fieldsValue.mobilePhone : "",
            jobTitle: fieldsValue.jobTitle ? fieldsValue.jobTitle : "",
            department: fieldsValue.department ? fieldsValue.department : "",
            address: fieldsValue.address ? fieldsValue.address : "",
            countryId: fieldsValue.countryId ? fieldsValue.countryId : null,
            contactGroups: fieldsValue.contactGroups
                ? fieldsValue.contactGroups.map((id: number) => ({
                      id,
                  }))
                : [],
            partnerId: fieldsValue.partnerId ? fieldsValue.partnerId : null,
        };

        if (!editMode) {
            await createContact(payload);
            notification.success({
                message: t("success"),
                description: t("contact_CreateContactSuccess", "Contact was created successfully"),
            });
        } else {
            await updateContact({ id: data.id, ...payload });
            notification.success({
                message: t("success"),
                description: t("contact_updateContactSuccess", "Contact was updated successfully"),
            });
        }
        form.resetFields();
        onClose(true);
    };

    const emailValidateHasFeedback = () => {
        return (
            duplicateEmailContacts.length > 0 ||
            loadingContactsByEmail === true ||
            form.getFieldValue("emailAddress") === ""
        );
    };

    const emailValidateStatus = () => {
        if (duplicateEmailContacts.length > 0) {
            return "warning";
        } else if (loadingContactsByEmail === true) {
            return "validating";
        } else if (form.getFieldValue("emailAddress") === "") {
            return "error";
        }
    };

    const emailFormHelp = () => {
        if (duplicateEmailContacts.length > 0)
            return (
                <Flex vertical>
                    <div>{t("contact_DuplicateContactsExistText")}:</div>
                    {duplicateEmailContacts.map((c: any) =>
                        c.fullName.length < 31 ? (
                            <div>{`${c.fullName} ${
                                c.partnerName ? "- " + c.partnerName : ""
                            }`}</div>
                        ) : (
                            <div>
                                <Tooltip title={c.fullName}>
                                    <div>{`${c.fullName.substring(0, 31)}... ${
                                        c.partnerName ? "- " + c.partnerName : ""
                                    }`}</div>
                                </Tooltip>
                            </div>
                        )
                    )}
                </Flex>
            );
        else if (form.getFieldValue("emailAddress") === "") return t("emailInputError");
    };

    return (
        <Modal
            wrapProps={{ "data-test": "contact_create_edit_modal" }}
            title={
                editMode ? t("contact_edit", "Edit Contact") : t("contact_create", "Create Contact")
            }
            open={visible}
            footer={[
                duplicateEmailContacts.length > 0 ? (
                    <Popconfirm
                        title={t("generic_BeAdvised")}
                        description={
                            <div style={{ width: 314 }}>
                                {t("contact_DuplicateContactsExistConfirmationDescription", {
                                    count: duplicateEmailContacts.length,
                                })}
                            </div>
                        }
                        onConfirm={handleSave}
                        cancelText={t("generic_Cancel")}
                        okText={t("generic_Confirm")}>
                        <Button key="ok" type="primary" loading={loading}>
                            {editMode ? t("generic_Save") : t("generic_Create")}
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button key="ok" type="primary" onClick={handleSave} loading={loading}>
                        {editMode ? t("generic_Save") : t("generic_Create")}
                    </Button>
                ),
            ]}
            onCancel={() => onClose(false)}
            width={1000}
            style={{ top: 20 }}>
            <Form form={form} labelAlign="left" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                <Form.Item
                    label={t("contact_firstName")}
                    required
                    name="firstName"
                    initialValue={data.firstName}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}>
                    <Input placeholder={t("contact_firstName")} maxLength={200} />
                </Form.Item>

                <Form.Item
                    label={t("contact_lastName")}
                    name="lastName"
                    initialValue={data.lastName}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}>
                    <Input placeholder={t("contact_lastName")} maxLength={200} />
                </Form.Item>

                <Form.Item
                    label={t("contact_emailAddress")}
                    required
                    name="emailAddress"
                    initialValue={data.emailAddress}
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            message: t("emailInputError"),
                            type: "email",
                        },
                    ]}
                    hasFeedback={emailValidateHasFeedback()}
                    validateStatus={emailValidateStatus()}
                    help={emailFormHelp()}>
                    <Input
                        placeholder={t("contact_emailAddress")}
                        maxLength={300}
                        onBlur={async () => {
                            let duplicateContacts: any[] = await getContactsByEmail(
                                form.getFieldValue("emailAddress"),
                                editMode ? data.id : undefined
                            );
                            setDuplicateEmailContacts(duplicateContacts);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={t("contact_workPhone")}
                    name="workPhone"
                    initialValue={data.workPhone}>
                    <Input allowClear placeholder={t("contact_workPhone")} maxLength={200} />
                </Form.Item>

                <Form.Item
                    label={t("contact_mobilePhone")}
                    name="mobilePhone"
                    initialValue={data.mobilePhone}>
                    <Input allowClear placeholder={t("contact_mobilePhone")} maxLength={200} />
                </Form.Item>

                <Form.Item
                    label={t("contact_jobTitle")}
                    name="jobTitle"
                    initialValue={data.jobTitle}>
                    <Input allowClear placeholder={t("contact_jobTitle")} maxLength={200} />
                </Form.Item>

                <Form.Item
                    label={t("contact_department")}
                    name="department"
                    initialValue={data.department}>
                    <Input allowClear placeholder={t("contact_department")} maxLength={50} />
                </Form.Item>

                <Form.Item
                    label={t("contact_country")}
                    name="countryId"
                    initialValue={data.countryId ? data.countryId : undefined}>
                    <SelectData
                        useData={useCountries}
                        selectIdField="id"
                        selectOptionField="name"
                        allowClear
                        placeholder={t("contact_country")}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                    />
                </Form.Item>

                <Form.Item label={t("contact_address")} name="address" initialValue={data.address}>
                    <TextArea
                        rows={4}
                        allowClear
                        placeholder={t("contact_address")}
                        maxLength={200}
                    />
                </Form.Item>

                <Form.Item
                    label={t("contact_Group")}
                    name="contactGroups"
                    initialValue={
                        data.contactGroups ? data.contactGroups.map((c: any) => c.id) : undefined
                    }>
                    <SelectDataMultiple
                        data-test="contact_Group"
                        useData={useContactGroups}
                        selectIdField="id"
                        selectOptionField="name"
                        allowClear
                        selectAll
                        placeholder={t("contact_Group")}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                    />
                </Form.Item>

                {(!editMode || data.canEditPartner) && (
                    <Form.Item
                        label={
                            <>
                                <label>{t("contact_companyName")} </label>
                                <Tooltip title={t("contact_companyNameGuide")}>
                                    <span style={{ marginLeft: 4 }}>
                                        <HelpCircle />
                                    </span>
                                </Tooltip>
                            </>
                        }
                        name="partnerId"
                        initialValue={data.partnerId ? data.partnerId : undefined}
                        extra={t("contact_companyNameTooltip")}>
                        <SelectExternalPartner
                            placeholder={t("select")}
                            includeChildren
                            allowClear
                            showCreationButton={false}
                        />
                    </Form.Item>
                )}
                {editMode &&
                    data.creatorContact !== null &&
                    data.creatorContact.companyId.toString() !== currentCompanyId && (
                        <Row>
                            <Col span={24}>
                                <div style={{ fontSize: 10 }}>
                                    {t("contact_CreatorContactMessage", {
                                        contactName: data.creatorContact.contactName,
                                        companyName: data.creatorContact.companyName,
                                    })}
                                </div>
                            </Col>
                        </Row>
                    )}
            </Form>
        </Modal>
    );
};

export default CreateEditModal;
