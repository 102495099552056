import { IKpiDefinition } from "models";
import { useState } from "react";
import { Provider } from "services";

const useKpiDefinitionsSave = () => {
    const [loading, setLoading] = useState(false);

    const saveDefinitions = async (definitions: IKpiDefinition[]) => {
        setLoading(true);

        const res = await Provider.Kpi.saveKpiDefinitions(definitions, {
            customError: { message: "Failed to save definitions" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        saveDefinitions,
    };
};

export { useKpiDefinitionsSave };
