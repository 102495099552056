import React from "react";
import { useSupplierCustomerNames } from "controller";
import { Result, Skeleton } from "antd";
import classes from "./supplierLandingPage.module.scss";
import { useTranslation } from "react-i18next";

const LandingPageTitle = () => {
    const { t } = useTranslation("supplierPortal");
    const { data: customerNames, loading } = useSupplierCustomerNames();

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Result
                    className={classes.resultContainer}
                    icon={null}
                    title={
                        <span>
                            {t("landingPage_WelcomeMsg1")}{" "}
                            {customerNames.length === 1 ? (
                                <span>
                                    <span>{t("landingPage_WelcomeMsg2")} </span>
                                    <span className={classes.customerName}>{customerNames[0]}</span>
                                    <span> {t("generic_SupplierPortal")}</span>
                                </span>
                            ) : (
                                t("landingPage_WelcomeMsg3")
                            )}
                        </span>
                    }
                />
            )}
        </>
    );
};

export { LandingPageTitle };
