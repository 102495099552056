import React, { Fragment, useState } from "react";
import { useAutomatedSurveyOverview, useContacts } from "controller";
import {
    Grid,
    getColumnSearchProps,
    getColumnRangePickerProps,
    getColumnSelectProps,
    getFilterIcon,
    getColumnMinMaxProps,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { formatTime } from "utilities";
import CreationModal from "./modals/create/creationModal";
import EditModal from "./modals/edit/editModal";
import OverviewHeader from "./overviewHeader";
import { ReportType } from "models";
import dayjs from "utilities/daysJsConfig";

const Overview = () => {
    const { t } = useTranslation();
    const getDefaultCustomFilters = () => ({
        from: dayjs().subtract(6, "months"),
        to: dayjs().add(1, "day"),
    });
    const [customFilters] = useState<any>(getDefaultCustomFilters());
    const [reloadData, setReloadData] = useState<any>(null);
    const [creationModalVisible, setCreationModalVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editModal, setEditModal] = useState({
        id: -1,
        visible: false,
        frequency: "Daily",
        activation: false,
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleOnRow = (record: any, rowIndex: number) => ({
        onClick: () => {
            setEditModal({
                id: record.id,
                visible: true,
                frequency: record.frequency,
                activation: record.isActive,
            });
        },
        style: {
            cursor: "pointer",
        },
    });

    let columns = [
        {
            title: t("autoSurvey_Id", "Id"),
            dataIndex: "id",
            key: "id",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 0,
            ...getColumnSearchProps({ dataIndex: "id" }),
        },
        {
            title: t("autoSurvey_Title", "Title"),
            dataIndex: "title",
            key: "title",
            width: 200,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 1,
            ...getColumnSearchProps({ dataIndex: "title" }),
        },
        {
            title: t("autoSurvey_Introduction", "Introduction"),
            dataIndex: "introduction",
            key: "introduction",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "string",
            viewColumnId: 2,
            ...getColumnSearchProps({ dataIndex: "introduction" }),
        },
        {
            title: t("autoSurvey_Frequency", "Frequency"),
            dataIndex: "frequency",
            key: "frequency",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 3,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        loading: false,
                        data: [
                            { name: t("autoSurvey_Daily"), id: 0 },
                            { name: t("autoSurvey_Weekly"), id: 1 },
                            { name: t("autoSurvey_Monthly"), id: 2 },
                            { name: t("autoSurvey_Quarterly"), id: 3 },
                            { name: t("autoSurvey_BiAnnually"), id: 5 },
                            { name: t("autoSurvey_Annually"), id: 4 },
                        ],
                    };
                },
                { value: ["id"], text: ["name"] }
            ),
            render: (val: string) => <div>{t(val)}</div>,
        },
        {
            title: t("autoSurvey_IsActive", "Activation"),
            dataIndex: "isActive",
            key: "isActive",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 4,
            filterType: "array",
            ...getFilterIcon({ dataIndex: "isActive" }),
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Inactive"), value: false },
                            { text: t("generic_Active"), value: true },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "isActive", radioChoice: true }
            ),
            render: (val: boolean) => (
                <div>{val === true ? t("generic_Active") : t("generic_Inactive")}</div>
            ),
        },
        {
            title: t("autoSurvey_StartDate", "Start Date"),
            dataIndex: "startDate",
            key: "startDate",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "date",
            viewColumnId: 5,
            ...getColumnRangePickerProps(),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
        {
            title: t("autoSurvey_CreatedBy", "Created By"),
            dataIndex: "createdBy",
            key: "CreatedBy",
            width: 250,
            sorter: true,
            viewColumnId: "createdBy",
            filterType: "array",
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["userId"],
                    text: ["fullName"],
                },
                { dataIndex: "createdBy" }
            ),
        },
        {
            title: t("autoSurvey_Created", "Created (UTC)"),
            dataIndex: "created",
            key: "created",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "date",
            viewColumnId: 6,
            ...getColumnRangePickerProps(),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY (HH:mm)"),
        },
        {
            title: t("autoSurvey_Visibility", "Visibility"),
            dataIndex: "visibility",
            key: "visibility",
            ellipsis: true,
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 7,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        loading: false,
                        data: [
                            { name: t("external"), id: 2 },
                            { name: t("internal"), id: 1 },
                        ],
                    };
                },
                { value: ["id"], text: ["name"] },
                { dataIndex: "visibility", radioChoice: true }
            ),
            render: (val: string) => <div>{t(val.toLowerCase())}</div>,
        },
        {
            title: t("autoSurvey_EndDateDays"),
            dataIndex: "endDateDays",
            key: "endDateDays",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: "endDateDays",
            filterType: "range",
            min: 0,
            max: 9999,
            ...getColumnMinMaxProps(0, 9999, {
                dataIndex: "endDateDays",
                allowDecimals: false,
            }),
        },
    ];
    return (
        <Fragment>
            <Grid
                data-test="automated_survey_table"
                title={() => (
                    <OverviewHeader
                        onCreateBtnClick={() => setCreationModalVisible(true)}
                        selectedRows={selectedRows}
                        selectedRowKeys={selectedRowKeys}
                        onUpdateSelectedRows={setSelectedRows}
                        onUpdateSelectedRowKeys={setSelectedRowKeys}
                        onReloadData={() => setReloadData({ reload: true })}
                    />
                )}
                columns={columns}
                useData={useAutomatedSurveyOverview}
                tableType={"AutomatedSurveys"}
                fixedColumns={["id", "title"]}
                customFilters={customFilters}
                onRow={handleOnRow}
                rowSelection={rowSelection}
                reportType={ReportType.automatedSurvey}
                reloadData={reloadData}
            />
            <CreationModal
                visible={creationModalVisible}
                onVisibleChange={() => setCreationModalVisible(false)}
                reloadTable={() => setReloadData({ reload: true })}
            />
            <EditModal
                visible={editModal.visible}
                onVisibleChange={() => setEditModal({ ...editModal, visible: false })}
                reloadTable={() => setReloadData({ reload: true })}
                frequency={editModal.frequency}
                activation={editModal.activation}
                autoSurveyId={editModal.id}
            />
        </Fragment>
    );
};

export { Overview };
