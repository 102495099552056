import { useState } from "react";
import { Provider } from "services";

const useFolderUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [checkFolderNameLoading, setCheckFolderNameLoading] = useState(false);

    const deleteFoldersAndFiles = async (ids: { fileIds: number[]; folderIds: number[] }) => {
        setLoading(true);
        const res = await Provider.File.deleteFoldersAndFiles(ids, {
            customError: {
                message: "Could not delete the selected items",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    const updateFolder = async (folderData: any) => {
        setLoading(true);
        const res = await Provider.File.updateFolder(folderData, {
            customError: {
                message: "Could not update folder settings",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    const createFolder = async (name: string, parentId?: number) => {
        setLoading(true);
        const res = await Provider.File.createFolder(name, parentId, {
            customError: {
                message: "Could not create new folder",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    const isFolderNameInUse = async (name: string, parentId: number | null) => {
        setCheckFolderNameLoading(true);
        const res = await Provider.File.isFolderNameInUse(name, parentId, {
            customError: {
                message: "Could not check folder name",
            },
        }).catch((err: any) => {
            setCheckFolderNameLoading(false);
            return Promise.reject(err);
        });
        setCheckFolderNameLoading(false);
        return res;
    };

    const checkFileNames = async (folderId: number, fileNames: string[]) => {
        setLoading(true);
        const res = await Provider.File.checkFileNames(
            { folderId, fileNames },
            {
                customError: {
                    message: "Could not check file names",
                },
            }
        ).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };

    return {
        loading: loading,
        deleteFoldersAndFiles,
        updateFolder,
        createFolder,
        isFolderNameInUse,
        checkFileNames,
        checkFolderNameLoading,
    };
};

export { useFolderUpdate };
