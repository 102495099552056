import React, { useState, FunctionComponent, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    MultipleTags,
} from "components/utilitycomponents";
import {
    useActionOverview,
    usePartnerList,
    useActionCRUD,
    useCompanyType,
    useActionOwnerContactsFilter,
    useDevelopmentPlanFilterOptions,
    usePartnerFields,
} from "controller";
import { Button, Tag, Badge } from "antd";
import { formatTime } from "utilities";
import CreateEditModal from "./createEditModal";
import {
    IActionType,
    IOverviewAction,
    IOverviewActionTitle,
    ISystemActionType,
} from "models/action";
import classes from "./styles/action.module.scss";
import OverviewHeader from "./overviewHeader";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getPathForOverview } from "../shared/actionHelpers";
import dayjs from "utilities/daysJsConfig";
import SideBarFilter from "./sideBarFilter";

interface IProps {
    supplierProfileOverview: boolean;
    partnerId?: number | undefined;
    [key: string]: any;
}

const Overview: FunctionComponent<IProps> = ({
    supplierProfileOverview = true,
    partnerId = undefined,
    ...rest
}) => {
    const { actionId, presetId: summaryPageFilter }: any = useParams();
    const { reOpenActions, completeActions } = useActionCRUD();
    const { isDefaultCompany } = useCompanyType();
    const { t } = useTranslation();
    const currentUserContactId: string = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-ContactId"]
    );
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [createEditModal, setCreateEditModal] = useState<any>({
        visible: actionId ? true : false,
        id: actionId ? Number(actionId) : undefined,
        editMode: actionId ? true : false,
    });
    const { data: partnerFields } = usePartnerFields();
    const [actionsFilter, setActionsFilter] = useState<any>([]);
    const [from, setFrom] = useState(
        dayjs()
            .subtract(6, "months")
            .toISOString()
    );
    const [to, setTo] = useState(
        dayjs()
            .add(1, "day")
            .toISOString()
    );

    //used in summary supplier profile page
    useEffect(() => {
        if (summaryPageFilter) {
            if (summaryPageFilter === "1") {
                setActionsFilter([{ name: "TotalOpenAction", value: "1" }]);
            } else if (summaryPageFilter === "2") {
                setActionsFilter([{ name: "DueThisWeekOpenAction", value: "2" }]);
            } else if (summaryPageFilter === "3") {
                setActionsFilter([{ name: "OverdueOpenAction", value: "3" }]);
            }
        }
    }, [summaryPageFilter]);

    useEffect(() => {
        setCreateEditModal({
            visible: actionId ? true : false,
            id: actionId ? Number(actionId) : undefined,
            editMode: actionId ? true : false,
        });
    }, [actionId]);

    const handleOnRow = (record: IOverviewAction) => ({
        onClick: () => {
            if (record.editable === true) {
                setCreateEditModal({ id: record.id, visible: true, editMode: true });
            }
        },
        style: {
            cursor: record.editable === true ? "pointer" : "auto",
        },
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record: IOverviewAction) => ({
            disabled:
                (record.editable === false && !record.systemGenerated) ||
                (record.systemActionEditable === false && record.systemGenerated),
        }),
    };

    const renderTitleText = (
        actionTitle: IOverviewActionTitle,
        type: IActionType,
        systemGeneratedType: ISystemActionType
    ) => {
        switch (type) {
            case IActionType.SelfAssessment:
                return t("activity_SelfAssessmentView1");
            case IActionType.Survey:
                return t("action_SurveyRequestTitle");
            case IActionType.Segmentation:
                if (
                    systemGeneratedType &&
                    systemGeneratedType ===
                        ISystemActionType.SegmentationSettingCurrentAssignedSegment
                ) {
                    return t("action_SettingAssignedSegmentTitle");
                } else {
                    return actionTitle.text;
                }
            case IActionType.Performance:
                return actionTitle.text;
            default:
                return `${type.toString()} Id  ${actionTitle.registrationId}`;
        }
    };

    const renderNoAccessTitleText = (actionTitle: IOverviewActionTitle, type: IActionType) => {
        switch (type) {
            case IActionType.Survey:
                return t("action_SurveyRequestTitle");
            default:
                return `${type.toString()} Id  ${actionTitle.registrationId}`;
        }
    };

    const handleOnCompleteAction = async (record: IOverviewAction) => {
        await completeActions([record.id]);
        setReloadData({ reload: true });
    };

    const handleOnReOpenAction = async (record: IOverviewAction) => {
        await reOpenActions([record.id]);
        setReloadData({ reload: true });
    };

    const isNewAction = (record: IOverviewAction) => {
        let fiveMinutesAgo = dayjs().subtract(5, "minutes");
        let created = dayjs(record.created);
        if (dayjs(created).isAfter(fiveMinutesAgo)) {
            return true;
        }
        return false;
    };

    let initColumns: any[] = [
        {
            title: t("actions_Id"),
            dataIndex: "id",
            key: "Id",
            ellipsis: true,
            width: 100,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: "actionId",
            ...getColumnSearchProps({ dataIndex: "id" }),
            render: (id: number, record: IOverviewAction) => (
                <Fragment>
                    {isNewAction(record) === true ? (
                        <Badge.Ribbon style={{ top: -6 }} text={t("generic_New")}>
                            <div>{id}</div>
                        </Badge.Ribbon>
                    ) : (
                        <div>{id}</div>
                    )}
                </Fragment>
            ),
        },
        {
            title: t("actions_Title"),
            dataIndex: "title",
            fixed: true,
            key: "Title",
            ellipsis: true,
            width: 200,
            sorter: true,
            filterType: "string",
            viewColumnId: "actionTitle",
            ...getColumnSearchProps({ dataIndex: "title" }),
            render: (title: IOverviewActionTitle, record: IOverviewAction) => (
                <div>
                    {record.type === IActionType.Generic ? (
                        title.text
                    ) : record.canAccessRegistration ? (
                        <Link to={getPathForOverview(title, record.type)}>
                            {renderTitleText(title, record.type, record.systemGeneratedType!)}
                        </Link>
                    ) : (
                        renderNoAccessTitleText(title, record.type)
                    )}
                </div>
            ),
        },
        {
            title: t("actions_Owner"),
            dataIndex: "owner",
            key: "Owner",
            width: 150,
            filterType: "array",
            sorter: true,
            viewColumnId: "actionOwner",
            ...getColumnSelectProps(
                useActionOwnerContactsFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "owner" }
            ),
            render: (owners: string[]) => (
                <MultipleTags
                    values={owners.map((m: any) => ({
                        id: m,
                        name: m,
                    }))}
                />
            ),
        },
        {
            title: t("actions_DueDate"),
            dataIndex: "dueDate",
            key: "DueDate",
            width: 200,
            sorter: true,
            filterType: "date",
            viewColumnId: "actionDueDate",
            ...getColumnRangePickerProps({ dataIndex: "dueDate" }),
            render: (val: any, record: IOverviewAction) => (
                <div
                    className={
                        dayjs(val).isBefore(dayjs()) && !record.completed
                            ? classes.overDue
                            : classes.dueDate
                    }>
                    {val && formatTime(val, "MMM D, YYYY")}
                </div>
            ),
        },
        {
            title: t("developmentPlan"),
            dataIndex: "developmentPlan",
            key: "developmentPlan",
            sorter: true,
            width: 300,
            viewColumnId: "developmentPlan",
            filterType: "array",
            ...getColumnSelectProps(
                useDevelopmentPlanFilterOptions.bind(null),
                {
                    value: ["id"],
                    text: ["title"],
                },
                { dataIndex: "developmentPlan" }
            ),
            render: (val: string) => <div>{val ? val : <Tag>-</Tag>}</div>,
        },
        {
            title: t("actions_Status"),
            dataIndex: "status",
            key: "Status",
            width: 200,
            sorter: true,
            filterType: "array",
            viewColumnId: "actionStatus",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("actions_Completed_Filter"), value: 2 },
                            { text: t("actions_NotCompleted_Filter"), value: 1 },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "status", radioChoice: true }
            ),
            render: (val: any, record: any) => (
                <Tag color={record.completed === true ? "#73d13d" : "#ffc53d"}>
                    {record.completed === true
                        ? t("actions_Completed_Filter")
                        : t("actions_NotCompleted_Filter")}
                </Tag>
            ),
        },
        {
            title: "",
            operationColumn: true,
            dataIndex: "completed",
            key: "Completed",
            width: 200,
            viewColumnId: "actionCompleted",
            render: (completed: any, record: IOverviewAction) => (
                <div>
                    {completed === true ? (
                        <div onClick={e => e!.stopPropagation()}>
                            <Button
                                data-test="re_open_action"
                                disabled={
                                    (record.editable === false &&
                                        record.systemGenerated === false) ||
                                    (record.systemActionEditable === false &&
                                        record.systemGenerated)
                                }
                                size="small"
                                onClick={async (e: any) => {
                                    e.stopPropagation();
                                    await handleOnReOpenAction(record);
                                }}
                                type="link"
                                className={classes.cellBtn}>
                                {t("actions_ReOpen")}
                            </Button>
                        </div>
                    ) : (
                        <div onClick={e => e!.stopPropagation()}>
                            <Button
                                data-test="complete_action"
                                disabled={
                                    (record.editable === false &&
                                        record.systemGenerated === false) ||
                                    (record.systemActionEditable === false &&
                                        record.systemGenerated)
                                }
                                size="small"
                                onClick={async (e: any) => {
                                    e.stopPropagation();
                                    await handleOnCompleteAction(record);
                                }}
                                type="link"
                                className={classes.cellBtn}>
                                {t("actions_Complete")}
                            </Button>
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (supplierProfileOverview === false) {
            let companyNameColTitle = partnerFields.headlines.find(
                (x: any) => x.name === "CompanyName"
            )?.customLabel;

            initColumns.splice(4, 0, {
                title: isDefaultCompany
                    ? companyNameColTitle || t("company_Type_Supplier")
                    : t("company_Type_Customer"),
                dataIndex: "supplier",
                key: "Supplier",
                width: 200,
                sorter: supplierProfileOverview ? false : true,
                filterType: "array",
                viewColumnId: "actionSupplier",
                ...getColumnSelectProps(
                    usePartnerList,
                    { value: ["id"], text: ["name"] },
                    { dataIndex: "supplier" }
                ),
                render: (suppliers: string[], record: IOverviewAction) => (
                    <div>
                        <MultipleTags
                            values={suppliers.map((m: any) => ({
                                id: m,
                                name: record.originatingPartnerName
                                    ? `${m} [Origin: ${record.originatingPartnerName}]`
                                    : m,
                            }))}
                        />
                    </div>
                ),
            });
            setColumns(initColumns);
        }
    }, [supplierProfileOverview, partnerFields]);

    function handleOnClose(reloadTable: boolean) {
        setCreateEditModal({ visible: false, id: undefined, editMode: false });
        if (reloadTable) {
            setReloadData({ reload: reloadTable });
        }
    }

    return (
        <Fragment>
            <Grid
                data-test="actions_table"
                additionalColumnFilters={actionsFilter}
                title={() =>
                    isDefaultCompany ? (
                        <OverviewHeader
                            onCreateClicked={() =>
                                setCreateEditModal({
                                    id: undefined,
                                    visible: true,
                                    editMode: false,
                                })
                            }
                            selectedRowKeys={selectedRowKeys}
                            selectedRows={selectedRows}
                            onClearSelectedRows={setSelectedRowKeys}
                            onReloadData={() => setReloadData({ reload: true })}
                        />
                    ) : (
                        <div style={{ fontWeight: "bold" }}>{t("actions_Actions")}</div>
                    )
                }
                useData={useActionOverview.bind(null, partnerId)}
                columns={columns}
                onRow={handleOnRow}
                rowSelection={rowSelection}
                reloadData={reloadData}
                tableType="Action"
                sidebar={() => (
                    <SideBarFilter
                        onMyActionsClicked={() => {
                            setActionsFilter([{ name: "owner", value: currentUserContactId }]);
                        }}
                        onMyCreatedActionsClicked={() => {
                            setActionsFilter([{ name: "createdBy", value: currentUserContactId }]);
                        }}
                    />
                )}
                fixedColumns={["id", "title"]}
                onRangeChange={(val: any) => {
                    setFrom(val.from);
                    setTo(val.to);
                }}
                {...rest}
            />
            <CreateEditModal
                partnerId={partnerId}
                supplierProfileOverview={supplierProfileOverview}
                editMode={createEditModal.editMode}
                visible={createEditModal.visible}
                onCancel={(reloadTable: boolean) => handleOnClose(reloadTable)}
                recordId={createEditModal.id}
                reloadTable={() => setReloadData({ reload: true })}
            />
        </Fragment>
    );
};

export default Overview;
