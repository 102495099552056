import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { IReviewDetail } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IReviewDetail;
    isHomePage: boolean;
    onDisplayReviewModal: Function;
};

const Review: FunctionComponent<PropsType> = ({
    data,
    isHomePage = false,
    onDisplayReviewModal,
}) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IReviewDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewReviewOnPartner", {
                    id: data.id,
                    interpolation: { escapeValue: false },
                })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IReviewDetail) => {
        return (
            <Fragment>
                {t("activity_NewReviewByPartner", {
                    creator: data.creator,
                    id: data.id,
                    interpolation: { escapeValue: false },
                })}{" "}
                {data.reviewedPartnerName || data.fromOrOnPartner ? (
                    <PartnerLink
                        partnerId={data.partnerId}
                        partnerName={
                            data.reviewedPartnerName
                                ? data.reviewedPartnerName
                                : data.fromOrOnPartner
                        }
                    />
                ) : (
                    <span>{t("noPartner")}</span>
                )}
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            {isHomePage && (
                <Button
                    icon={<RightOutlined />}
                    type="link"
                    onClick={() =>
                        onDisplayReviewModal({
                            id: data.id,
                            visible: true,
                        })
                    }
                    className={classes.link}>
                    {t("activity_ReviewView")}
                </Button>
            )}
        </Fragment>
    );
};

export default Review;
