import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { Provider } from "services";
import { IExternalPartnerCreation } from "models";

const usePartnerCreate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const create = async (payload: IExternalPartnerCreation) => {
        setLoading(true);
        const id = Provider.Partner.createExternalPartner(payload, {
            customError: {
                message: "Failed to create external partner",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const createPartners = async (partnersToCreate: IExternalPartnerCreation[]) => {
        setLoading(true);
        for (let partnerToCreate of partnersToCreate) {
            await Provider.Partner.createExternalPartner(partnerToCreate).catch((err: any) => {
                notification.error({
                    message: t("systemMessage"),
                    description: t("generic_ErrorMessage"),
                    duration: 0,
                });
                setLoading(false);
                return Promise.reject(err);
            });
        }
        setLoading(false);
    };

    return {
        loading,
        create,
        createPartners,
    };
};

export default usePartnerCreate;
