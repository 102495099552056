import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState, clearExcelExport } from "store";
import { downloadExcelExport } from "utilities";
import { useExcelExport } from "controller";

const ExcelExportHandler = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const excelExportProps = useSelector((state: AppState) => state.excelExport.props);
    const { requestExcelExport } = useExcelExport();

    useEffect(() => {
        (async () => {
            if (excelExportProps.reportType !== undefined) {
                const response = await requestExcelExport({ ...excelExportProps });

                downloadExcelExport(response, location.pathname);

                dispatch(clearExcelExport());
            }
        })();
    }, [excelExportProps.reportType]);

    return <Fragment></Fragment>;
};

export default ExcelExportHandler;
