import { useEffect, useState } from "react";
import { Provider } from "services";
import { IReviewDistribution } from "models";

type StateType = {
    data: IReviewDistribution[] | null;
    loading: boolean;
};

const useRatingDistribution = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = await Provider.Partner.getRatingDistribution(partnerId, from, to, {
                customError: {
                    message: "Could not retrive data for review distribution",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });

            if (data.length === 0) {
                setState({ loading: false, data: [] });
                return;
            }

            const dataByRating: any = {};
            for (let index = 0; index < data.length; index++) {
                dataByRating[data[index].rating] = data[index];
            }

            const rearrangedData = [];
            for (let index = 1; index <= 5; index++) {
                if (!dataByRating[index]) {
                    rearrangedData.push({ rating: index, count: 0 });
                } else rearrangedData.push(dataByRating[index]);
            }
            setState({ loading: false, data: rearrangedData });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useRatingDistribution };
