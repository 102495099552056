import React, { FunctionComponent, useEffect, useState } from "react";
import { Descriptions, Row, Col, Tag, Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadFile, PageContainer, PureDiv } from "components/utilitycomponents";
import { useMeetingMinuteGuest } from "controller";
import classes from "./meetingCalendar.module.scss";
import { useParams } from "react-router-dom";
import dayjs from "utilities/daysJsConfig";

interface IProps {}

const GuestMeetingMinutePage: FunctionComponent<IProps> = () => {
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [reload] = useState({ reload: false });
    const { data, loading } = useMeetingMinuteGuest(reload, guestToken);
    const [meetingActions, setMeetingsActions] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setMeetingsActions(data.actions);
        }
    }, [data]);

    const renderActions = () => {
        if (meetingActions) {
            return meetingActions.map((x: any, index: number) => (
                <>
                    <Row key={x.id}>
                        <Col span={5}>{x.title}</Col>
                        <Col span={5}>{x.description}</Col>
                        <Col span={5}>{x.dueDate && dayjs(x.dueDate).format("MMM D, YYYY")}</Col>
                        <Col span={5}>{x.ownerNames[0]}</Col>
                        <Col span={4}>
                            {x.completed ? (
                                <Tag color={"green"}>{t("actions_Completed_Filter")}</Tag>
                            ) : (
                                <Tag color={"red"}>{t("actions_NotCompleted_Filter")}</Tag>
                            )}
                        </Col>
                    </Row>

                    {index !== meetingActions.length - 1 && <Divider></Divider>}
                </>
            ));
        }
    };

    const renderRepresentatives = (reps: any[]) => {
        if (reps) {
            return reps.map((x: any, index: number) => (
                <Tag key={index} color={"geekblue"}>
                    {x.firstName + " " + x.lastName}
                </Tag>
            ));
        }
    };

    return (
        <PageContainer title={"Meeting Minute Details"}>
            <Spin spinning={loading}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Descriptions layout="vertical" column={2} colon={false}>
                            <Descriptions.Item
                                label={
                                    <div className={classes.formLabel}> {t("generic_Type")}</div>
                                }>
                                {data && data.typeName}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <div className={classes.formLabel}> {t("generic_Date")}</div>
                                }>
                                {data && dayjs(data.date).format("YYYY-MM-DD HH:mm")}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Descriptions layout="vertical" column={2} colon={false}>
                            <Descriptions.Item
                                label={
                                    <div className={classes.formLabel}>{t("generic_Title")}</div>
                                }>
                                {data && data.title}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    <div className={classes.formLabel}>
                                        {t("generic_Description")}
                                    </div>
                                }>
                                <PureDiv textContent={data && data.description} />
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <label className={classes.formLabel}>{t("governance_Outcome")}</label>
                        <PureDiv textContent={data && data.outcome}></PureDiv>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Divider orientation="left">
                        <div className={classes.title}>{t("governance_Attendants")}</div>
                    </Divider>
                    <Col span={12}>
                        <Descriptions layout="vertical" column={1} colon={false}>
                            <Descriptions.Item
                                span={1}
                                labelStyle={{ minWidth: 400 }}
                                label={
                                    <div className={classes.formLabel}>
                                        {t("governance_Representatives", {
                                            name: data && data.companyName,
                                            interpolation: { escapeValue: false },
                                        })}
                                    </div>
                                }>
                                <div style={{ maxWidth: 700 }}>
                                    {renderRepresentatives(data && data.internalRepresentatives)}
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions layout="vertical" column={1} colon={false}>
                            <Descriptions.Item
                                labelStyle={{ minWidth: 400 }}
                                label={
                                    <div className={classes.formLabel}>
                                        {t("governance_Representatives", {
                                            name: data && data.partnerName,
                                            interpolation: { escapeValue: false },
                                        })}
                                    </div>
                                }>
                                <div style={{ maxWidth: 700 }}>
                                    {renderRepresentatives(data && data.externalRepresentatives)}
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Divider orientation="left">
                    <div className={classes.title}>{t("actions_Actions")}</div>
                </Divider>
                <Row>
                    <Col span={5} className={classes.colTitle}>
                        {t("generic_Title")}
                    </Col>
                    <Col span={5} className={classes.colTitle}>
                        {t("generic_Description")}
                    </Col>
                    <Col span={5} className={classes.colTitle}>
                        {t("actions_DueDate")}
                    </Col>
                    <Col span={5} className={classes.colTitle}>
                        {t("action_AssignedTo")}
                    </Col>
                    <Col span={4} className={classes.colTitle}>
                        {t("generic_Status")}
                    </Col>
                </Row>
                <Divider style={{ marginTop: 8 }}></Divider>
                {renderActions()}

                <Row gutter={16} style={{ marginTop: 32, marginBottom: 32 }}>
                    <Divider style={{ marginTop: 8 }} orientation="left">
                        <div className={classes.title}>{t("generic_Attachments")} </div>
                    </Divider>
                    <Col span={24}>
                        {data &&
                            data.files &&
                            data.files.map((file: any, index: number) => (
                                <div key={index}>
                                    <DownloadFile file={file} />
                                </div>
                            ))}
                    </Col>
                </Row>
            </Spin>
        </PageContainer>
    );
};

export { GuestMeetingMinutePage };
