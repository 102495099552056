import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { AppState } from "store";
import userManager from "userManager";
import classes from "./accountProfile.module.scss";
import { useSelector } from "react-redux";
import { Row, Col, Input, notification, Button, Spin, Form, Divider, Flex } from "antd";
import {
    useAccountSettings,
    useAccountSettingsUpdate,
    useProfilePictureUpdate,
    useAvailableLanguages,
    useCountries,
} from "controller";
import { IAccountSetting, INotificationSetting } from "models";
import { AttachImage, SelectData, PageContainer } from "components/utilitycomponents";
import { parseLanguageCode } from "utilities";
import { EditOutlined } from "@ant-design/icons";
import useEmit from "utilities/helpers/useEmit";

const AccountProfile = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.oidc.user);
    const InputGroup = Input.Group;
    const { data, loading } = useAccountSettings();
    const [attachedImageList, setAttachedImageList] = useState<any>();
    const [editMode, setEditMode] = useState(false);
    const {
        updateAccountSettings,
        isUsernameInUse,
        loading: updateAccountLoading,
    } = useAccountSettingsUpdate();

    const {
        updateProfilePicture,
        loading: updateProfilePictureLoading,
    } = useProfilePictureUpdate();

    useEffect(() => {
        setAttachedImageList(
            data && data.profilePictureId ? [{ id: data.profilePictureId, name: "" }] : []
        );
        form.resetFields();
    }, [data]);
    const { emit } = useEmit();
    const onSubmit = async () => {
        let values = await form.validateFields();

        let notificationSetting: INotificationSetting[] = [];
        if (data && data.notificationSetting) {
            data.notificationSetting.forEach(n => {
                notificationSetting.push({
                    id: n.id,
                    moduleId: n.moduleId,
                    receiveEmailNotification: values[`receiveEmailNotification[${n.moduleId}]`],
                });
            });
        }
        const payload: IAccountSetting = {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            preferredLanguage: parseLanguageCode(values.preferredLanguage),
            saveFilters: true,
            userName: values.userName,
            emailAddress: values.email,
            profilePictureId: values.profilePictureId.length ? values.profilePictureId[0] : null,
            notificationSetting,
            address: values.address,
            department: values.department,
            countryId: values.country,
            jobTitle: data!.jobTitle ? data!.jobTitle : undefined,
            mobilePhone: values.mobilePhone,
            workPhoneExtension: values.workPhoneExt,
            workPhone: values.workPhone,
            receiveEmailSummary: values.receiveEmailSummary,
        };

        await i18n.changeLanguage(
            values.preferredLanguage === "en-US" ? "en-GB" : values.preferredLanguage
        );
        await updateAccountSettings(payload);
        notification.success({
            message: t("success"),
            description: t("account_UpdateSuccess"),
        });
        userManager.signinSilent();
        setEditMode(false);
        emit("refresh_Avatar");
    };

    const handleProfilePictureChange = (ids: number[]) => {
        setAttachedImageList(ids);
        updateProfilePicture(ids.length ? ids[0] : null);
    };

    const disabledClass = !editMode ? classes.disabledFormItem : "";

    return (
        <>
            <PageContainer fluid style={{ padding: 24, borderRadius: 5 }}>
                <Form
                    form={form}
                    labelAlign="left"
                    layout="vertical"
                    variant={editMode ? "outlined" : "borderless"}>
                    <Spin spinning={loading}>
                        <Row gutter={64}>
                            <Col lg={10} md={24}>
                                <Form.Item
                                    required={false}
                                    label={t("account_EmailAddress")}
                                    name="email"
                                    initialValue={data ? data.emailAddress : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            type: "email",
                                        },
                                    ]}>
                                    <Input className={classes.disabledFormItem} disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label={t("account_Username")}
                                    name="userName"
                                    validateTrigger="onChange"
                                    initialValue={data ? data.userName : undefined}
                                    rules={[
                                        {
                                            validator: async (
                                                rule: any,
                                                value: any,
                                                callback: any
                                            ) => {
                                                try {
                                                    if (!value || value.trim() === "") {
                                                        return Promise.reject(
                                                            new Error(t("inputIsRequiredError"))
                                                        );
                                                    } else if (
                                                        !value.match(
                                                            /^[0-9a-zA-Z][0-9a-zA-Z-\\..@\-_+]{1,48}[0-9a-zA-Z]$/
                                                        )
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                t("admin_Username_ValidationMsg")
                                                            )
                                                        );
                                                    }
                                                    const userName = user.profile["ll-UserName"]
                                                        ? user.profile["ll-UserName"]
                                                        : data && data.userName
                                                        ? data.userName
                                                        : undefined;
                                                    if (
                                                        value !== userName &&
                                                        (await isUsernameInUse(value))
                                                    )
                                                        return Promise.reject(
                                                            new Error(t("account_UserNameExists"))
                                                        );
                                                } catch (error) {
                                                    return Promise.reject(error);
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}>
                                    <Input
                                        placeholder={t("account_Username")}
                                        min={3}
                                        max={50}
                                        className={disabledClass}
                                        disabled={!editMode}
                                    />
                                </Form.Item>

                                <Form.Item
                                    required
                                    label={t("account_FirstName")}
                                    name="firstName"
                                    initialValue={data ? data.firstName : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={"-"}
                                        max={50}
                                        className={disabledClass}
                                        disabled={!editMode}
                                    />
                                </Form.Item>

                                <Form.Item
                                    required
                                    label={t("account_LastName")}
                                    name="lastName"
                                    initialValue={data ? data.lastName : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={"-"}
                                        max={50}
                                        className={disabledClass}
                                        disabled={!editMode}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t("account_Department")}
                                    name="department"
                                    initialValue={data && data.department}
                                    rules={[
                                        {
                                            required: false,
                                            whitespace: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={"-"}
                                        maxLength={50}
                                        className={disabledClass}
                                        disabled={!editMode}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={t("account_ProfilePicture")}
                                    name="profilePictureId"
                                    className={disabledClass}
                                    initialValue={
                                        attachedImageList
                                            ? attachedImageList.map((a: any) => a.id)
                                            : undefined
                                    }>
                                    <AttachImage
                                        attachedFileList={attachedImageList}
                                        externalLoading={updateProfilePictureLoading}
                                        onChange={handleProfilePictureChange}
                                        multiple={false}
                                        enableCrop={true}
                                        styles={{
                                            attachedImageContainer: editMode
                                                ? classes.attachedImageContainer
                                                : classes.attachedImageContainerDormant,
                                            attachedImage: classes.attachedImage,
                                            removeAttachedImage: classes.removeAttachedImage,
                                        }}
                                        disabled={!editMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2} style={{ textAlign: "right" }}>
                                <Divider type="vertical" style={{ height: "100%" }} />
                            </Col>
                            <Col lg={10} md={24}>
                                <Form.Item
                                    label={t("accont_Address")}
                                    name="address"
                                    initialValue={data && data.address}>
                                    <Input
                                        placeholder={"-"}
                                        maxLength={200}
                                        disabled={!editMode}
                                        className={disabledClass}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("account_Country")}
                                    name="country"
                                    initialValue={
                                        data && data.countryId ? data.countryId : undefined
                                    }>
                                    <SelectData
                                        style={{ width: "100%" }}
                                        allowClear
                                        useData={useCountries}
                                        selectOptionField="name"
                                        placeholder={"-"}
                                        disabled={!editMode}
                                        className={disabledClass}
                                    />
                                </Form.Item>

                                <InputGroup>
                                    <div>{t("account_WorkPhone")}</div>
                                    <Row>
                                        <Col span={4}>
                                            <Form.Item
                                                name="workPhoneExt"
                                                initialValue={data && data.workPhoneExtension}>
                                                <Input
                                                    maxLength={50}
                                                    placeholder={t("account_WorkPhoneExtenstion")}
                                                    disabled={!editMode}
                                                    className={disabledClass}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                name="workPhone"
                                                initialValue={data && data.workPhone}>
                                                <Input
                                                    maxLength={200}
                                                    placeholder={"-"}
                                                    disabled={!editMode}
                                                    className={disabledClass}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </InputGroup>

                                <Form.Item
                                    label={t("account_MobilePhone")}
                                    name="mobilePhone"
                                    initialValue={data && data.mobilePhone}>
                                    <Input
                                        maxLength={200}
                                        placeholder={"-"}
                                        disabled={!editMode}
                                        className={disabledClass}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("account_Language")}
                                    name="preferredLanguage"
                                    initialValue={user.profile["ll-Locale"]}>
                                    <SelectData
                                        style={{ width: "100%" }}
                                        selectIdField="languageCode"
                                        selectOptionField="name"
                                        useData={useAvailableLanguages}
                                        getPopupContainer={(node: any) =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                        disabled={!editMode}
                                        className={disabledClass}
                                    />
                                </Form.Item>
                                <Flex justify="right">
                                    {!editMode && (
                                        <div style={{ marginTop: 170, marginBottom: 12 }}>
                                            <Button
                                                icon={<EditOutlined />}
                                                type="primary"
                                                disabled={loading}
                                                onClick={() => setEditMode(true)}>
                                                {t("generic_Edit")}
                                            </Button>
                                        </div>
                                    )}
                                    {!loading && editMode && (
                                        <div style={{ marginTop: 170, marginBottom: 12 }}>
                                            <Button
                                                style={{ marginRight: 16 }}
                                                onClick={() => {
                                                    form.resetFields();
                                                    setEditMode(false);
                                                }}>
                                                {t("generic_Cancel")}
                                            </Button>
                                            <Button
                                                type="primary"
                                                loading={updateAccountLoading}
                                                onClick={() => onSubmit()}>
                                                {t("generic_Save")}
                                            </Button>
                                        </div>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </PageContainer>
        </>
    );
};

export { AccountProfile };
