import { IPartnerFieldVisibility } from "models";

const getHeadLines = (data: any, t: any) => {
    let mappedResult: any = {
        companyHeadline: "Company Name",
        companyNumberHeadline: "Company Number",
        kpisHeadline: "KPIs",
        ncrsHeadline: "NCRs",
        ncrsOpenHeadline: "NCR Days Open",
        compliancesHeadline: "Compliance",
        reviewsHeadline: "Reviews",
        statusHeadline: "Status",
        ownerHeadline: "Owner",
        groupHeadline: "Group",
        category1Headline: "Category 1",
        category2Headline: "Category 2",
        valueHeadline: "Value",
        linkedHeadline: "Linked",
        domainHeadline: "Domain",
    };
    if (!data) return mappedResult;

    data.forEach((element: any) => {
        element.isMandatory = element.visibility === IPartnerFieldVisibility.VisibleAndMandatory;
        mappedResult[`${element.name}Headline`] = element;
    });

    return mappedResult;
};

export { getHeadLines };
