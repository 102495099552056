import { useEffect, useState } from "react";
import Provider from "services/api";
import { useTranslation } from "react-i18next";
import { useNcrFeature } from "controller/company";

const useKpisAvailable = (supplierId: number, visibility: string) => {
    const { t } = useTranslation();
    const { hasNcrFeature } = useNcrFeature();
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (supplierId !== undefined && visibility !== undefined) {
                if (supplierId !== -1) {
                    const data = await Provider.Analytics.getAvailableKpis(supplierId, visibility, {
                        retryCount: 3,
                        customError: { message: "Could not retrieve available kpis" },
                    }).catch(err => {
                        setState({ loading: false, data: {} });
                        return Promise.reject(err);
                    });
                    if (!hasNcrFeature) {
                        data.forEach((kpi: any, index: number) => {
                            if (kpi.headline.indexOf("kpi_NCR") !== -1) {
                                data.splice(index, 1);
                            }
                        });
                    }
                    let kpiData = data.map((kpi: any) => {
                        return {
                            id: kpi.id,
                            title: `${kpi.number} ${translateHeadline(kpi.headline)} (${
                                kpi.visibility === 0 ? t("internal") : t("external")
                            })`,
                            customColumns: kpi.customColumns,
                            subLevel: translateSubLevelHeadline(kpi.kpiSubLevel),
                        };
                    });
                    setState({
                        loading: false,
                        data: kpiData,
                    });
                }
            }
        })();
    }, [supplierId, visibility]);

    const translateHeadline = (headline: string) => {
        let translateHeadline = false;

        switch (headline) {
            case "kpi_ReviewCount":
            case "kpi_ReviewAverageRating":
            case "kpi_NCRDaysOpen":
            case "kpi_NCRCount":
            case "kpi_NCRCountClosed":
            case "kpi_NCRCountOpen":
                translateHeadline = true;
                break;
            default:
                translateHeadline = false;
                break;
        }

        return translateHeadline === true ? t(headline) : headline;
    };

    const translateSubLevelHeadline = (subLevel: any) => {
        let translateHeadline = false;

        switch (subLevel.subLevelHeadline) {
            case "NCR_Reason":
            case "Review_Category":
                translateHeadline = true;
                break;
            default:
                translateHeadline = false;
                break;
        }

        if (translateHeadline) {
            subLevel.subLevelHeadline = t(subLevel.subLevelHeadline);
        }
        return subLevel;
    };

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useKpisAvailable };
