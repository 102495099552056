import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DeleteIcon,
    LoadingIndicator,
    useAttach,
    FormRow,
    FormLabel,
    SelectTreeData,
} from "components/utilitycomponents";
import { Row, Col, Progress, notification, Modal, Button, Checkbox, Form, Upload } from "antd";
import { useFolders, useFolderUpdate } from "controller";
import { PlusOutlined } from "@ant-design/icons";
import { UploadFile, UploadProps } from "antd";
import classes from "./styles/overview.module.scss";

type PropTypes = {
    visible: boolean;
    onClose: (reload?: boolean) => void;
};

const UploadFilesModal = ({ visible, onClose }: PropTypes) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { checkFileNames, loading: checkFileNameLoading } = useFolderUpdate();
    const [clearFileList, setClearFileList] = useState<any>({ clear: false });
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [folder, setFolder] = useState<any>({
        hasFeedback: false,
        help: "",
        value: undefined,
        validateStatus: null,
    });
    const [duplicateFileModal, setDuplicateFileModal] = useState<any>();
    const [reloadFolderList, setReloadFolderList] = useState<any>(null);
    const [disableUploadButton, setDisableUploadFolder] = useState<boolean>(true);
    const { handleUpload, uploadInProgress, newFiles } = useAttach({
        onChange: () => {
            if (fileList && fileList.length > 0) {
                notification.success({
                    message: t("success"),
                    description: t("file_UploadFilesSuccess", { count: fileList.length }),
                });
                onClose(true);
            } else {
                onClose();
            }
            setClearFileList({ clear: true });
            setFileList([]);
        },
        notification,
        maxFileSize: 40,
        folderId: folder.value,
        clearFileList,
    });

    useEffect(() => {
        setDisableUploadFolder(true);
        setFolder({
            hasFeedback: false,
            help: "",
            value: undefined,
            validateStatus: null,
        });
        setFileList([]);
        setDuplicateFileModal({
            fileNames: [],
            visible: false,
            onClose: () => {},
        });
        setReloadFolderList({ reload: true });
        form.resetFields();
    }, [visible]);

    const handleSelectFile = async (files: any) => {
        if (folder.value === undefined) {
            setFolder({
                hasFeedback: true,
                help: t("file_SelectAFolder"),
                value: undefined,
                validateStatus: "error",
            });
        } else {
            const fileDescriptions: any = await checkFileNames(
                folder.value,
                Array.from(files).map((f: any) => f.name)
            );
            if (fileDescriptions.some((r: any) => r.duplicate === true)) {
                setDuplicateFileModal({
                    fileDescriptions: fileDescriptions.filter((f: any) => f.duplicate === true),
                    visible: true,
                    onClose: (duplicateFileNames: any) => {
                        let nonDuplicateFileNames: any = {};
                        fileDescriptions.forEach((f: any) => {
                            if (f.duplicate === false) {
                                nonDuplicateFileNames[f.name] = f.name;
                            }
                        });
                        const fileToUpload =
                            Object.keys(duplicateFileNames).length > 0 ||
                            Object.keys(nonDuplicateFileNames).length > 0;
                        if (fileToUpload) {
                            handleUpload(files, {
                                ...duplicateFileNames,
                                ...nonDuplicateFileNames,
                            });
                        } else {
                            onClose();
                        }
                        setDuplicateFileModal({
                            fileNames: [],
                            visible: false,
                            onClose: () => {},
                        });
                    },
                });
            } else {
                handleUpload(files);
            }
        }
    };
    const props: UploadProps = {
        onRemove: file => {
            const newFileList = fileList.filter(f => f.uid !== file.uid);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList(prevFileList => [...prevFileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <Fragment>
            <Modal
                closable={true}
                wrapProps={{ "data-test": "folder_upload_modal" }}
                title={t("file_UploadFiles")}
                open={visible}
                footer={null}
                onCancel={() => onClose()}>
                <FormRow>
                    <Col sm={6} xs={24}>
                        <FormLabel required>{t("files_SelectFolder")}</FormLabel>
                    </Col>

                    <Col sm={18} xs={24}>
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            help={folder.help}
                            validateStatus={folder.validateStatus}
                            required>
                            <SelectTreeData
                                data-test="folder_name_select"
                                style={{ width: "100%" }}
                                placeholder={t("file_FolderName")}
                                selectOptionField="name"
                                useData={useFolders.bind(null, reloadFolderList)}
                                value={folder.value}
                                onChange={(value?: number) => {
                                    if (value) {
                                        setFolder({
                                            hasFeedback: false,
                                            help: "",
                                            value,
                                            validateStatus: null,
                                        });
                                        setDisableUploadFolder(false);
                                    }
                                }}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>
                    </Col>
                </FormRow>

                <div className={classes.UploadContainer}>
                    <Upload {...props} multiple>
                        <Button
                            disabled={disableUploadButton}
                            className={classes.uploadButton}
                            icon={<PlusOutlined />}>
                            {t("generic_Upload")}
                        </Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSelectFile(fileList);
                        }}
                        disabled={fileList.length === 0}
                        loading={uploadInProgress || checkFileNameLoading}
                        style={{ marginTop: 16 }}>
                        {uploadInProgress || checkFileNameLoading
                            ? t("upload_File_Modal_Start_Uploading_buttonText")
                            : t("upload_File_Modal_Start_Upload_buttonText")}
                    </Button>
                </div>
                <div>
                    <div style={{ marginTop: "5px" }}>
                        {t("maximumAllowedSize", { maxFileSize: 40 })}
                    </div>

                    {newFiles.map((file: any, index: number) => (
                        <Fragment key={index}>
                            <Row style={{ lineHeight: "20px" }}>
                                <Col span={24}>{file.name}</Col>
                            </Row>
                            <Row style={{ lineHeight: "20px" }}>
                                <Col span={1} style={{ textAlign: "center" }}>
                                    <LoadingIndicator />
                                </Col>
                                <Col span={21}>
                                    <Progress
                                        percent={file.progress}
                                        size="small"
                                        status={file.progress < 100 ? "active" : "normal"}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: "center" }}>
                                    {file.progress < 100 && (
                                        <DeleteIcon
                                            fontSize={"70px"}
                                            onClick={() => file.source.cancel()}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    ))}
                </div>
            </Modal>
            <DuplicateFileModal {...duplicateFileModal} />
        </Fragment>
    );
};

const DuplicateFileModal = ({ fileDescriptions, visible, onClose }: any) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<any>({});
    const [index, setIndex] = useState(0);
    const [resolveAll, setResolveAll] = useState(false);

    useEffect(() => {
        if (visible === true) {
            setFiles({});
            setIndex(0);
            setResolveAll(false);
        }
    }, [visible]);

    const uploadAndReplace = () => {
        let newFiles = {
            ...files,
            [fileDescriptions[index].name]: fileDescriptions[index].name,
        };
        setFiles(newFiles);
        resolveAll ? resolveAllFiles("REPLACE") : resolveNextFile(newFiles);
    };
    const doNotUpload = () => {
        resolveAll ? resolveAllFiles("NONE") : resolveNextFile(files);
    };
    const uploadAndKeep = () => {
        let newFiles = {
            ...files,
            [fileDescriptions[index].name]: fileDescriptions[index].rename,
        };
        setFiles(newFiles);
        resolveAll ? resolveAllFiles("KEEP_BOTH") : resolveNextFile(newFiles);
    };

    const resolveNextFile = (newFiles: any) => {
        if (index + 1 === fileDescriptions.length) {
            onClose(newFiles);
        } else {
            setIndex(index + 1);
        }
    };

    const resolveAllFiles = (type: string = "NONE") => {
        let newFiles: any = {};
        switch (type) {
            case "REPLACE":
                fileDescriptions.forEach((f: any) => {
                    newFiles = {
                        ...newFiles,
                        [f.name]: f.name,
                    };
                });
                break;
            case "KEEP_BOTH":
                fileDescriptions.forEach((f: any) => {
                    newFiles = {
                        ...newFiles,
                        [f.name]: f.rename,
                    };
                });
                break;
        }
        onClose(newFiles);
    };

    return fileDescriptions && fileDescriptions[index] ? (
        <Modal
            wrapProps={{ "data-test": "folder_upload_modal" }}
            title={fileDescriptions[index].name}
            open={visible}
            footer={[
                <Row key={0}>
                    <Col span={16} style={{ textAlign: "left" }}>
                        {index === 0 && fileDescriptions.length > 1 && (
                            <Fragment>
                                <Checkbox
                                    onChange={(e: any) => {
                                        if (e.target.checked) {
                                            setResolveAll(true);
                                        }
                                    }}
                                />{" "}
                                {t("file_DuplicateBatchConflictResolve", {
                                    count: fileDescriptions.length - 1,
                                })}
                            </Fragment>
                        )}
                    </Col>

                    <Col span={8} style={{ textAlign: "right" }}>
                        <Button key="skip" onClick={doNotUpload} type="primary">
                            {t("generic_Skip")}
                        </Button>
                    </Col>
                </Row>,
            ]}
            onCancel={() => resolveAllFiles()}>
            <Fragment>
                <Row style={{ marginBottom: "15px", marginTop: "5px", fontWeight: "bold" }}>
                    {t("file_DuplicateMessage")}
                </Row>

                <Row>
                    <Button type="primary" onClick={uploadAndReplace}>
                        {t("file_UploadAndReplace")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_UploadAndReplaceMessage")}
                </Row>
                <Row>
                    <Button type="primary" onClick={doNotUpload}>
                        {t("file_DoNotUpload")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_DoNotUploadMessage")}
                </Row>
                <Row>
                    <Button type="primary" onClick={uploadAndKeep}>
                        {t("file_UploadAndKeep")}
                    </Button>
                </Row>
                <Row style={{ marginBottom: "15px", marginTop: "5px" }}>
                    {t("file_UploadAndKeepMessage", {
                        newName: fileDescriptions[index].rename,
                    })}
                </Row>
            </Fragment>
        </Modal>
    ) : null;
};
export default UploadFilesModal;
