import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Button, notification, Row, Col, message } from "antd";
import { ICompliance } from "models";
import { Copy01, Edit02, LoadingIndicator } from "components/utilitycomponents";
import classes from "./styles/complianceAction.module.scss";
import { useComplianceStatusUpdate, useComplianceCreate } from "controller";
import { SelectStatus } from "../sharedComponents";
import { useTranslation } from "react-i18next";
import { EditComplianceCompliance } from "../edit/editModal";

type PropsType = {
    data: ICompliance;
    onStatusChanged: Function;
    onReloadData: (reload: boolean) => void;
};

const ComplianceAction: FunctionComponent<PropsType> = ({
    data,
    onStatusChanged,
    onReloadData,
}) => {
    const { t } = useTranslation();
    const { loading: statusUpdateLoading, update: updateStatus } = useComplianceStatusUpdate();
    const { loading: copyRegistrationLoading, copyRegistration } = useComplianceCreate();
    const [openEditModal, toggleOpenEditModal] = useState<{
        id: number | undefined | any;
        open: boolean;
    }>({ id: undefined, open: false });

    const [status, setStatus] = useState<number>(data.statusId);

    useEffect(() => {
        setStatus(data.statusId);
    }, [data]);

    const handleChangeStatus = async (statusId: number) => {
        setStatus(statusId);
        try {
            const res = await updateStatus(data.id, statusId);
            if (res === false) {
                const statusName = data.complianceStatuses.filter(s => s.id === statusId)[0][
                    "name"
                ];

                notification["error"]({
                    message: t("systemMessage", "System message"),
                    description: t("compliance_StatusChangeNoPermission", {
                        statusName: t(statusName),
                    }),
                });
            }
            if (res !== false) {
                notification.success({
                    message: t("success"),
                    description: t("compliance_StatusUpdateSuccess"),
                });
            }
            onStatusChanged();
        } catch (err) {
            onStatusChanged();
        }
    };

    const handleCopyRegistration = async () => {
        message.info(t("compliance_CopyStarted"));
        let id = await copyRegistration(data.id);
        notification.success({
            message: "Success",
            description: (
                <Fragment>
                    <div>{t("compliance_CopySuccess", { id })}</div>
                    <Button
                        type="link"
                        onClick={() => toggleOpenEditModal({ id: id, open: true })}
                        style={{ padding: 0 }}>
                        {t("compliance_EditNewRegistration")}
                    </Button>
                </Fragment>
            ),
        });
        onReloadData(false);
    };

    if (!data.authorizedToEdit) {
        return (
            <div className={classes.notAuthorizedToEdit}>{t("compliance_EditNoPermission")}</div>
        );
    }

    return (
        <Fragment>
            <Row>
                <Col span={12}>
                    <div className={classes.buttons}>
                        {data.canSeeEditAndCopyButtons && (
                            <Fragment>
                                <Button
                                    data-test="registration_edit_button"
                                    icon={<Edit02 />}
                                    style={{ marginRight: "5px" }}
                                    onClick={() =>
                                        toggleOpenEditModal({ id: data.id, open: true })
                                    }>
                                    {t("edit")}
                                </Button>

                                <Button
                                    onClick={handleCopyRegistration}
                                    data-test="create_copy_button"
                                    style={{ marginRight: "5px" }}
                                    icon={<Copy01 />}
                                    loading={copyRegistrationLoading}>
                                    {t("createCopy")}
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </Col>
                <Col span={12}>
                    <div className={classes.status}>
                        {data.canChangeStatus && (
                            <>
                                <div
                                    className={
                                        data.hasStatusForRegionsOrSites
                                            ? classes.statusLabel
                                            : classes.statusLabel1
                                    }>
                                    {data.hasStatusForRegionsOrSites
                                        ? t("compliance_GlobalStatus")
                                        : t("compliance_Status")}{" "}
                                    {statusUpdateLoading && <LoadingIndicator />}
                                </div>
                                <SelectStatus
                                    data-test="compliance_status_select"
                                    onChange={handleChangeStatus}
                                    value={status}
                                    options={data.complianceStatuses}
                                />
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            {openEditModal && (
                <EditComplianceCompliance
                    complianceId={openEditModal.id}
                    open={openEditModal.open}
                    onClose={(reload: boolean) => {
                        if (reload) {
                            onReloadData(true);
                        }
                        toggleOpenEditModal({ id: undefined, open: false });
                    }}
                />
            )}
        </Fragment>
    );
};
export default ComplianceAction;
