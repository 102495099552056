import React, { FunctionComponent, useState, useEffect } from "react";
import { Tooltip, Button } from "antd";
import { useActivityFollowingUpdate } from "controller";
import { LoadingIndicator } from "./loadingIndicator";
import { useTranslation } from "react-i18next";
import { Eye, EyeOff } from "./icons";

type PropsType = {
    isFollowed: boolean;
    activityId: number;
    onChangeFollowActivity?: (activityId: number, isFollowed: boolean) => void;
};

const FollowActivity: FunctionComponent<PropsType> = ({
    isFollowed,
    activityId,
    onChangeFollowActivity,
}) => {
    const [followed, setFollowed] = useState(isFollowed);
    const { t } = useTranslation();
    useEffect(() => {
        setFollowed(isFollowed);
    }, [isFollowed]);

    const {
        loading: updateIsFollowedLoading,
        update: updateIsFollowed,
    } = useActivityFollowingUpdate();

    const handleUpdateFollowing = async () => {
        await updateIsFollowed(activityId, !followed);
        onChangeFollowActivity && onChangeFollowActivity(activityId, !followed);
        setFollowed(!followed);
    };
    return updateIsFollowedLoading ? (
        <LoadingIndicator />
    ) : (
        <Tooltip title={followed ? t("activity_StopFollow") : t("activity_StartFollow")}>
            {followed ? (
                <Button
                    onClick={handleUpdateFollowing}
                    size="small"
                    icon={<EyeOff data-test="follow_activity_icon" />}>
                    Unfollow
                </Button>
            ) : (
                <Button
                    onClick={handleUpdateFollowing}
                    size="small"
                    icon={<Eye data-test="follow_activity_icon" />}>
                    Follow
                </Button>
            )}
        </Tooltip>
    );
};

export { FollowActivity };
