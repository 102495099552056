import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/shared.module.scss";
import { IKpiUploadErrorDetail } from "models";

type PropsType = {
    data: IKpiUploadErrorDetail;
};

const KpiUploadError: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {t(
                    "activity_KpiUploadErrorNotification",
                    "Error(s) occurred during validation of your KPI data"
                )}
                {` (ID:${data.id})`}
            </div>
        </span>
    );
};

export { KpiUploadError };
