import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Input, notification, Alert, Form } from "antd";
import { SelectData } from "components/utilitycomponents";
import { usePartners, useSupplierCardsAlternateSupplierUpdate } from "controller";
const TextArea = Input.TextArea;

interface IProps {
    partnerId: number;
    partnerName: string;
    data: any;
    reloadPartners: any;
    visible: boolean;
    onClose: Function;
    onReloadTable: Function;
    alternateSuppliers: any[];
}

const AlternateSupplierModal = ({
    partnerId,
    partnerName,
    data,
    reloadPartners,
    visible,
    onClose,
    onReloadTable,
    alternateSuppliers,
}: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const {
        createAlternateSupplier,
        updateAlternateSupplier,
        loading,
    } = useSupplierCardsAlternateSupplierUpdate();
    const { data: partners, loading: partnersLoading } = usePartners(reloadPartners);

    useEffect(() => {
        form.resetFields();
    }, [visible, data]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const formValues = {
            alternativePartnerId: fieldsValue["alternativePartnerId"]
                ? fieldsValue["alternativePartnerId"]
                : null,
            name: fieldsValue["name"] ? fieldsValue["name"].trim() : null,
            comment: fieldsValue["comment"] ? fieldsValue["comment"] : "",
        };
        if (data.id) {
            await updateAlternateSupplier({ partnerId, id: data.id, ...formValues });
            notification.success({
                message: t("success"),
                description: t("supplierProfile_UpdateAlternateSupplierSuccess"),
            });
        } else {
            await createAlternateSupplier({ partnerId, ...formValues });
            notification.success({
                message: t("success"),
                description: t("supplierProfile_CreateAlternateSupplierSuccess"),
            });
        }
        handleClose();
        onReloadTable();
    };

    const handleClose = () => {
        onClose();
        form.resetFields();
    };

    const checkExistingSupplier = (supplierName: string) => {
        return (
            alternateSuppliers.filter(
                (s: any) => s.name.trim().toLowerCase() === supplierName.trim().toLowerCase()
            ).length > 0 || supplierName.trim().toLowerCase() === partnerName.trim().toLowerCase()
        );
    };

    return (
        <Modal
            width={800}
            title={data.id ? data.name : t("supplierProfile_NewAlternativeSupplier")}
            onCancel={handleClose}
            footer={[
                <Button key="ok" type="primary" onClick={handleSave} loading={loading}>
                    {t("generic_Save")}
                </Button>,
            ]}
            open={visible}>
            <Form
                form={form}
                labelAlign="left"
                labelWrap
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                {!data.name && (
                    <Alert
                        type="info"
                        message=""
                        description={t("supplierProfile_AddaNewSupplierAlert")}
                        style={{ marginBottom: "10px" }}
                    />
                )}

                <Form.Item
                    label={t("supplierProfile_SelectFromPartners")}
                    name="alternativePartnerId"
                    initialValue={data.alternativePartnerId ? data.alternativePartnerId : undefined}
                    rules={[
                        {
                            validator: async (rule: any, value: any, callback: any) => {
                                try {
                                    if (!value && !form.getFieldValue("name")) {
                                        return Promise.reject(
                                            new Error(
                                                t("supplierProfile_AddaNewSupplierRequiredMessage")
                                            )
                                        );
                                    } else if (
                                        value &&
                                        value !== data.alternativePartnerId &&
                                        checkExistingSupplier(
                                            partners.filter(p => p.id === value)[0].name
                                        )
                                    ) {
                                        return Promise.reject(
                                            new Error(t("supplierProfile_AlternateSupplierExist"))
                                        );
                                    }
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}>
                    <SelectData
                        useData={() => ({
                            data: partners.filter((p: any) => p.id !== partnerId),
                            loading: partnersLoading,
                        })}
                        selectOptionField="name"
                        placeholder={t("supplierProfile_SelectFromPartners")}
                        allowClear
                        onChange={() =>
                            form.setFieldsValue({
                                name: undefined,
                            })
                        }
                    />
                </Form.Item>

                <Form.Item
                    label={t("supplierProfile_NewSupplier")}
                    name="name"
                    initialValue={
                        data.alternativePartnerId === null && data.name ? data.name : undefined
                    }
                    rules={[
                        {
                            validator: async (rule: any, value: any, callback: any) => {
                                try {
                                    if (!form.getFieldValue("alternativePartnerId") && !value) {
                                        return Promise.reject(
                                            new Error(
                                                t("supplierProfile_AddaNewSupplierRequiredMessage")
                                            )
                                        );
                                    } else if (
                                        value &&
                                        value !== data.name &&
                                        checkExistingSupplier(value)
                                    ) {
                                        return Promise.reject(
                                            new Error(t("supplierProfile_AlternateSupplierExist"))
                                        );
                                    }
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}>
                    <Input
                        allowClear
                        placeholder={t("supplierProfile_NewSupplier")}
                        onChange={() =>
                            form.setFieldsValue({
                                alternativePartnerId: undefined,
                            })
                        }
                        maxLength={100}
                    />
                </Form.Item>

                <Form.Item
                    label={t("supplierProfile_AlternativeSupplierComment")}
                    name="comment"
                    initialValue={data.comment ? data.comment : undefined}>
                    <TextArea
                        allowClear
                        rows={5}
                        placeholder={t("supplierProfile_AlternativeSupplierComment")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AlternateSupplierModal;
