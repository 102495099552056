import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input, Checkbox, Result, Button, Typography, Form } from "antd";
import { FormRow, PageContainer } from "components/utilitycomponents";
import sharedClasses from "./styles/shared.module.scss";
import classes from "./styles/createCompany.module.scss";
import { getBackendDomainUrl } from "utilities";

const { Title } = Typography;

interface IProps {
    onNext: Function;
    invitation: any;
}

const CreateCompany = ({ invitation, onNext }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleSubmit = async () => {
        let values = await form.validateFields();
        onNext({
            companyName: values["name"],
        });
    };

    return (
        <PageContainer className={sharedClasses.container} settingContainer>
            <Form
                form={form}
                colon={false}
                labelAlign="left"
                labelWrap
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                {invitation && invitation.invitationType === "None" && (
                    <Result status="info" title={t("admin_UserRegistration_NonExistantLink")} />
                )}
                {invitation && invitation.invitationType === "Partner" && (
                    <>
                        <Row className={sharedClasses.logoContainer}>
                            <img
                                src={`${getBackendDomainUrl()}/v1/File/GetCompanyLogo`}
                                alt="logo"
                            />
                        </Row>
                        <Row>
                            <Col>
                                <Title level={2} className={classes.titleNoBotMargin}>
                                    {t("admin_UserRegistration_Hello")},
                                </Title>
                                <Title level={3} className={classes.titleSmallMargin}>
                                    {t("admin_UserRegistration_SupplierInvitationTitle", {
                                        companyName: invitation.name,
                                        interpolation: { escapeValue: false },
                                    })}
                                </Title>
                                <Title level={4} className={classes.secondaryTitle}>
                                    {t("admin_UserRegistration_FillInformation")}
                                </Title>
                            </Col>
                        </Row>

                        <Form.Item
                            label={t("admin_UserRegistration_CompanyName")}
                            name="name"
                            required
                            rules={[
                                {
                                    message: t("inputIsRequiredError"),
                                    whitespace: true,
                                    required: true,
                                },
                            ]}>
                            <Input maxLength={200} />
                        </Form.Item>

                        <Form.Item
                            name="confirmTerms"
                            valuePropName="checked"
                            required
                            rules={[
                                {
                                    required: true,
                                    validator: async (rule: any, value: any, callback: any) => {
                                        if (value === undefined || value === false)
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        "admin_UserRegistration_TermOfServiceValidatioNText"
                                                    )
                                                )
                                            );

                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Checkbox>
                                <a
                                    target="_blank"
                                    href="https://leanlinking.com/terms/"
                                    rel="noopener noreferrer">
                                    {t("admin_UserRegistration_TermsOfServiceText")}
                                </a>
                            </Checkbox>
                        </Form.Item>

                        <FormRow>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Button type="primary" onClick={handleSubmit}>
                                    {t("generic_Next")}
                                </Button>
                            </Col>
                        </FormRow>
                    </>
                )}
            </Form>
        </PageContainer>
    );
};

export default CreateCompany;
