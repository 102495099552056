import { useState } from "react";
import { Provider } from "services";

const useActivityFileUploadCreate = () => {
    const [loading, setLoading] = useState(false);

    const create = async (
        fileIds: number[],
        parentActivityId: number,
        contactActionActivity = false
    ) => {
        setLoading(true);
        const activity = await Provider.Activity.createFileUploadActivity(
            fileIds,
            parentActivityId,
            contactActionActivity
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return activity;
    };

    return {
        loading,
        create,
    };
};

export { useActivityFileUploadCreate };
