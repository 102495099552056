import { useState } from "react";
import Provider from "services/api";

const useManualSegmentHistoryCRUD = () => {
    const [loading, setLoading] = useState(false);

    const create = async (payload: any) => {
        setLoading(true);
        const id = await Provider.Segmentation.addManualSegmentHistory(payload, {
            customError: {
                message: "Could not create new history",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const update = async (historyItemid: number, segmentCategorySettingId: number) => {
        setLoading(true);
        await Provider.Segmentation.updateManualSegmentHistory(
            historyItemid,
            segmentCategorySettingId,
            {
                customError: {
                    message: "Could not update history",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (id: number) => {
        setLoading(true);
        await Provider.Segmentation.deleteManualSegmentHistory(id, {
            customError: {
                message: "Could not delete history",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        create,
        update,
        remove,
    };
};

export { useManualSegmentHistoryCRUD };
