import React, { useState, useEffect, FunctionComponent } from "react";
import {
    Form,
    Modal,
    Divider,
    Input,
    Button,
    Popconfirm,
    notification,
    Tooltip,
    Spin,
    Row,
    Col,
    Progress,
    DatePicker,
    message,
} from "antd";
import {
    AttachFile,
    InfoCircle,
    SelectData,
    SelectTreeData,
    Trash02,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import {
    usePartnerList,
    useDevelopmentPlanCRUD,
    useCompanyType,
    useActionCRUD,
    useInternalAndExternalContacts,
} from "controller";
import classes from "./developmentPlans.module.scss";
import { IDevelopmentPlanActionModel, IDevelopmentPlanModel } from "models";
import { useDevelopmentPlanById } from "controller/developmentPlan/useDevelopmentPlanByID";
import { ActionTypeEnum, ICreateEditAction } from "models/action";
import { getDomainUrl } from "utilities";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    dataId: any;
    visible: boolean;
    onClose: Function;
    onActionUpdate: Function;
    modalPartnerId?: number;
}

const EditModal: FunctionComponent<IProps> = ({
    visible,
    onClose,
    dataId,
    onActionUpdate,
    modalPartnerId,
}) => {
    const [reload, setReload] = useState<any>({ reload: false });
    const { loading: loadingData, data: record } = useDevelopmentPlanById(dataId, reload);
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [selectPartnerId, setSelectedPartnerId] = useState<any>(
        modalPartnerId ? modalPartnerId : undefined
    );
    const { loading: editingPlan, editDevelopmentPlan } = useDevelopmentPlanCRUD();
    const { isDefaultCompany } = useCompanyType();
    const {
        deleteActions,
        updateAction,
        createAction,
        reOpenActions,
        completeActions,
        creating: creatingAction,
    } = useActionCRUD();
    const { data: contacts, loading: gettingContacts } = useInternalAndExternalContacts(
        selectPartnerId,
        undefined
    );
    const { data: partners, loading: loadingPartners } = usePartnerList();
    const [actionLoading, setActionLoading] = useState<any>(undefined);
    const [autoFocusOnAction, setAutoFocusOnAction] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>();

    useEffect(() => {
        form.resetFields();
        if (!visible) {
            setAutoFocusOnAction(false);
        }
    }, [visible]);

    useEffect(() => {
        setSelectedPartnerId(record !== null ? record.supplierId : undefined);
        form.resetFields();
    }, [record]);

    useEffect(() => {
        if (record && record.files) {
            setFileList(
                record.files.map((file: any) => ({
                    id: file.id,
                    name: file.name,
                }))
            );
        }
    }, [record]);

    const getStrokeColor = (
        completedActions: number,
        overDueActionsCount: number,
        actionsCount: number
    ) => {
        let percentage = (completedActions / actionsCount) * 100;
        if (percentage === 100) {
            return {
                "0%": "#00b204",
                "100%": "#00b204",
            };
        } else if (percentage < 100 && overDueActionsCount === 0) {
            return {
                "0%": "#0477a9",
                "100%": "#0477a9",
            };
        } else if (percentage < 100 && overDueActionsCount !== 0) {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        } else {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        }
    };

    const handleActionUpdate = async (
        type: "title" | "description" | "dueDate" | "owner",
        action: any,
        payLoad: any,
        reload: boolean = false
    ) => {
        await updateAction(action.id, {
            title: type === "title" ? payLoad : form.getFieldValue(`title${action.id}`),
            description:
                type === "description" ? payLoad : form.getFieldValue(`description${action.id}`),
            ownerId:
                type === "owner"
                    ? payLoad
                        ? [payLoad]
                        : undefined
                    : action.ownerId
                    ? [action.ownerId]
                    : undefined,
            actionPartnerIds: [record.supplierId],
            dueDate: type === "dueDate" ? payLoad : form.getFieldValue(`dueDate${action.id}`),
            developmentPlanId: dataId,
        });

        if (reload) {
            onActionUpdate();
        }
    };

    const handleActionCreate = async () => {
        let action: ICreateEditAction = {
            title: "",
            description: undefined,
            ownerId: undefined,
            actionPartnerIds: [record.supplierId],
            dueDate: undefined,
            developmentPlanId: dataId,
        };
        await createAction(action);
        setReload({ reload: true });
        onActionUpdate();
        setAutoFocusOnAction(true);
    };

    const handleEdit = async () => {
        const values = await form.validateFields();
        let planActions: IDevelopmentPlanActionModel[] = record.actions.map((action: any) => ({
            title: action.title,
            description: action.description,
            dueDate: action.dueDate && dayjs(action.dueDate).toDate(),
            ownerId: action.ownerId,
        }));
        let input: IDevelopmentPlanModel = {
            id: dataId,
            title: values.title,
            description: values.description,
            supplierId: values.supplier,
            developmentPlanTemplateId: record.developmentPlanTemplateId,
            actions: planActions,
            fileIds: values.files,
        };
        await editDevelopmentPlan(input);
        notification.success({
            message: t("success"),
            description: t("generic_EditSuccess"),
        });

        form.resetFields();
        onClose(true);
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    const handleCopyUrl = async () => {
        await navigator.clipboard.writeText(`${getDomainUrl()}/DevelopmentPlans/${dataId}`);
        message.success(t("generic_CopiedToClipboard"));
    };

    const disableSupplierSelect =
        record &&
        record.actions &&
        record.actions.filter((x: any) => x.externalOwner === true).length > 0;
    if (!loadingData && (record === null || record === "")) return null;

    return (
        <>
            <div style={{ background: "#fff", position: "relative" }} id="area"></div>
            <Modal
                width={1000}
                open={visible}
                title={t("developmentPlan_Edit")}
                onOk={() => form.submit()}
                onCancel={() => handleCancel()}
                okText={t("generic_Save")}
                cancelText={t("generic_Cancel")}
                confirmLoading={editingPlan}
                footer={[
                    <Button onClick={() => handleCopyUrl()} key={2}>
                        {t("generic_CopyLink")}
                    </Button>,
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                        key={3}
                        loading={editingPlan}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Spin spinning={loadingData}>
                    <Form form={form} layout="vertical" onFinish={handleEdit} scrollToFirstError>
                        <>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Form.Item
                                    required
                                    name="title"
                                    label={t("generic_Title")}
                                    className={classes.titleInput}
                                    initialValue={record !== null ? record.title : ""}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("inputIsRequiredError"),
                                            whitespace: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={t("generic_Placeholder-Text")}
                                        maxLength={500}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name="supplier"
                                    label={
                                        <>
                                            {isDefaultCompany
                                                ? t("company_Type_Supplier")
                                                : t("company_Type_Customer")}
                                            {disableSupplierSelect && (
                                                <Tooltip
                                                    title={t(
                                                        "developmentPlan_SupplierSelectDisabled"
                                                    )}>
                                                    <span>
                                                        <InfoCircle />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </>
                                    }
                                    className={classes.supplierInput}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("inputIsRequiredError"),
                                        },
                                    ]}
                                    initialValue={record !== null ? record.supplierId : undefined}>
                                    <SelectTreeData
                                        placeholder={`${t("select")} ${
                                            isDefaultCompany
                                                ? t("company_Type_Supplier").toLocaleLowerCase()
                                                : t("company_Type_Customer").toLocaleLowerCase()
                                        }`}
                                        disabled={
                                            modalPartnerId !== undefined || disableSupplierSelect
                                        }
                                        selectOptionField="name"
                                        onChange={(val: any) => setSelectedPartnerId(val)}
                                        useData={() => ({
                                            data: partners,
                                            loading: loadingPartners,
                                        })}
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={t("generic_Description")}
                                initialValue={record !== null ? record.description : ""}>
                                <Input.TextArea
                                    placeholder={t("generic_Placeholder-Text")}
                                    maxLength={1000}></Input.TextArea>
                            </Form.Item>
                            <Row>
                                <Col span={12} className={classes.title}>
                                    Actions
                                </Col>
                                <Col span={12} className={classes.title}>
                                    <Progress
                                        success={{ percent: 0 }}
                                        size="small"
                                        percent={
                                            (record &&
                                                record.actions &&
                                                record.actions.filter((a: any) => a.status === 2)
                                                    ?.length / record.actions.length) * 100
                                        }
                                        format={val => (
                                            <span>{`${record &&
                                                record.actions &&
                                                record.actions.filter((a: any) => a.status === 2)
                                                    ?.length} / ${record &&
                                                record.actions &&
                                                record.actions.length}`}</span>
                                        )}
                                        strokeWidth={20}
                                        strokeColor={getStrokeColor(
                                            record &&
                                                record.actions &&
                                                record.actions.filter((a: any) => a.status === 2)
                                                    ?.length,
                                            record &&
                                                record.actions &&
                                                record.actions.filter(
                                                    (action: any) =>
                                                        action.dueDate !== null &&
                                                        dayjs(action.dueDate).isBefore(dayjs()) &&
                                                        action.status === 1
                                                ).length,
                                            record && record.actions && record.actions.length
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4}>{t("generic_Title")}</Col>
                                <Col span={6}>{t("generic_Description")}</Col>
                                <Col span={4}>{t("generic_Deadline")}</Col>
                                <Col span={6}>{t("action_AssignedTo")}</Col>
                                <Col span={3}></Col>
                                <Col span={1}></Col>
                                <Divider style={{ marginTop: 4, marginBottom: 12 }} />
                            </Row>
                            {record &&
                                record.actions.map((action: any, index: number) => (
                                    <Row key={index}>
                                        <Col span={4}>
                                            <Form.Item
                                                name={`title${action.id}`}
                                                initialValue={
                                                    action.title === "" ? undefined : action.title
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <Input
                                                    autoFocus={autoFocusOnAction}
                                                    placeholder={t("generic_Placeholder-Text")}
                                                    className={classes.inputClass}
                                                    maxLength={500}
                                                    bordered={false}
                                                    onBlur={async (e: any) => {
                                                        if (
                                                            e.target.value.trim() !== "" &&
                                                            e.target.value.trim() !==
                                                                action.title.trim()
                                                        )
                                                            await handleActionUpdate(
                                                                "title",
                                                                action,
                                                                e.target.value
                                                            );
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Tooltip
                                                title={action.description}
                                                mouseEnterDelay={0.5}>
                                                <Form.Item
                                                    name={`description${action.id}`}
                                                    initialValue={action.description}>
                                                    <Input
                                                        placeholder={t("generic_Placeholder-Text")}
                                                        className={classes.inputClass}
                                                        maxLength={1000}
                                                        bordered={false}
                                                        onBlur={async (e: any) =>
                                                            await handleActionUpdate(
                                                                "description",
                                                                action,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Tooltip>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item
                                                name={`dueDate${action.id}`}
                                                initialValue={
                                                    action.dueDate
                                                        ? dayjs(action.dueDate)
                                                        : undefined
                                                }>
                                                <DatePicker
                                                    format={"MMM D, YYYY "}
                                                    className={classes.inputClass}
                                                    bordered={false}
                                                    onChange={async (
                                                        date: any,
                                                        dateString: string
                                                    ) =>
                                                        await handleActionUpdate(
                                                            "dueDate",
                                                            action,
                                                            dateString.trim() === ""
                                                                ? undefined
                                                                : dateString,
                                                            true
                                                        )
                                                    }
                                                    disabledDate={disabledDate}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name={`owner${action.id}`}
                                                initialValue={action.ownerId}>
                                                <SelectData
                                                    onChange={async (_: any, item: any) =>
                                                        await handleActionUpdate(
                                                            "owner",
                                                            action,
                                                            item ? item.id : undefined,
                                                            true
                                                        )
                                                    }
                                                    useData={() => ({
                                                        data: contacts,
                                                        loading: gettingContacts,
                                                    })}
                                                    selectOptionField="name"
                                                    groupNames={[
                                                        t("action_Internal-Contacts"),
                                                        t("action_External-Contacts"),
                                                    ]}
                                                    groupFilter={(item: any, groupName: string) => {
                                                        if (
                                                            groupName ===
                                                            t("action_Internal-Contacts")
                                                        )
                                                            return item.externalUser === false;
                                                        if (
                                                            groupName ===
                                                            t("action_External-Contacts")
                                                        )
                                                            return item.externalUser === true;
                                                    }}
                                                    placeholder={t("select")}
                                                    bordered={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item name="status">
                                                <span key={`status${action.id}`}>
                                                    {action.status === ActionTypeEnum.Completed ? (
                                                        <Button
                                                            data-test="re_open_action"
                                                            size="small"
                                                            loading={actionLoading === action.id}
                                                            onClick={async (e: any) => {
                                                                setActionLoading(action.id);
                                                                await reOpenActions([action.id]);
                                                                setReload({ reload: true });
                                                                setActionLoading(undefined);
                                                                onActionUpdate();
                                                            }}
                                                            type="link"
                                                            className={classes.actionButton}>
                                                            {t("actions_ReOpen")}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            data-test="complete_action"
                                                            size="small"
                                                            loading={actionLoading === action.id}
                                                            onClick={async (e: any) => {
                                                                setActionLoading(action.id);
                                                                await completeActions([action.id]);
                                                                setReload({ reload: true });
                                                                setActionLoading(undefined);
                                                                onActionUpdate();
                                                            }}
                                                            type="link"
                                                            className={classes.actionButton}>
                                                            {t("actions_Complete")}
                                                        </Button>
                                                    )}
                                                </span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    paddingTop: 4,
                                                    cursor: "pointer",
                                                }}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}>
                                                <Popconfirm
                                                    title={t("developmentPlan_ActionRemoveConfirm")}
                                                    cancelText={t("generic_Cancel")}
                                                    okText={t("generic_Confirm")}
                                                    onConfirm={async () => {
                                                        await deleteActions([action.id]);
                                                        setReload({ reload: true });
                                                        onActionUpdate();
                                                    }}>
                                                    <span>
                                                        <Trash02 />
                                                    </span>
                                                </Popconfirm>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}

                            <Button
                                loading={creatingAction}
                                onClick={async () => await handleActionCreate()}>
                                {t("developmentPlan_AddAction")}
                            </Button>
                        </>

                        <Form.Item
                            className={classes.topMargin}
                            label={t("generic_Files")}
                            name="files"
                            initialValue={
                                record && record.files && record.files.map((x: any) => x.id)
                            }>
                            <AttachFile
                                makeTextNearButton={true}
                                attachedFileList={fileList}
                                maxFileSize={40}
                                uploadSectionLayout={{
                                    style: { display: "flex", flexDirection: "column" },
                                }}
                                uploadButtonLayout={{
                                    style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: 10,
                                    },
                                }}>
                                {t("generic_SelectFile")}
                            </AttachFile>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export { EditModal };
