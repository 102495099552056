import React, {
    FunctionComponent,
    useState,
    useLayoutEffect,
    SyntheticEvent,
    ReactNode,
    useEffect,
} from "react";
import { Resizable } from "react-resizable";
import classnames from "classnames";
import { Result, Table, Tag } from "antd";
import { getTableSize } from "./gridUtilities";
import { useTranslation } from "react-i18next";
import "./styles/grid.scss";
import classes from "./styles/grid.module.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

type PropTypes = {
    title?: () => ReactNode;
    tableSize?: any;
    pageSizeOptions?: string[];
    tableExcessHeight?: number;
    customPageSize?: number;
    minColumnWidth?: number;
    classNames?: string[];
    dataSource: any[];
    columns: any[];
    pagination?: "default" | "light" | "none";
    renderTableOnDataChange?: boolean;
    emptyText?: any;
    scroll?: any;
    [key: string]: any;
};
const { pageSize: defaultPageSize, excess } = getTableSize();

const GridLight: FunctionComponent<PropTypes> = ({
    title,
    tableSize = "small",
    pageSizeOptions = ["20", "50", "100"],
    tableExcessHeight = 0,
    customPageSize,
    minColumnWidth = 100,
    classNames = [""],
    dataSource,
    columns,
    pagination = "default",
    renderTableOnDataChange = false,
    emptyText,
    scroll,
    ...rest
}) => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(customPageSize ? customPageSize : defaultPageSize);
    const [pageNumber, setPageNumber] = useState(1);
    const [tableColumns, setTableColumns] = useState(columns);

    const resizableColumns = tableColumns.map((col, index) => {
        let column = {
            ...col,
            onHeaderCell: (column: any) => ({
                width: column.width,
                onResize: handleResize(index),
                minColumnWidth: minColumnWidth,
                style:
                    column.fixed === true
                        ? { backgroundColor: "#f4f4f3" }
                        : { backgroundColor: "#fafafa" },
            }),
        };

        const originalRender = column.render;

        column.render = (value: any, record: any, index: number) => {
            if (col.operationColumn === true) {
                return originalRender(value, record, index);
            }
            // Check for null, undefined, or empty string
            const isEmpty =
                value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && value.length === 0);

            // Use the original render if it exists, otherwise use the value or placeholder
            const content = isEmpty ? <Tag>-</Tag> : value;

            return isEmpty
                ? content
                : originalRender
                ? originalRender(content, record, index)
                : content;
        };

        return column;
    });

    useEffect(() => {
        if (renderTableOnDataChange && dataSource) {
            setTableColumns(columns);
        }
    }, [dataSource]);

    useLayoutEffect(() => {
        const header = document.querySelector(".grid .ant-table-header") as HTMLElement;
        if (header) {
            const stopScroll = (e: any) => {
                header.style.overflowY = "hidden";
            };
            const startScroll = (e: any) => {
                header.style.overflowY = "scroll";
            };
            header.addEventListener("dragstart", stopScroll, false);
            header.addEventListener("dragend", startScroll, false);
        }
    }, []);

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const components = {
        header: {
            cell: ResizableTitle,
        },
    };

    const handleResize = (index: any) => (e: any, { size }: any) => {
        let nextColumns = [...tableColumns];
        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };
        setTableColumns(nextColumns);
    };
    const paginationItemRender = (page: any, type: string) => {
        if (type === "prev") {
            return (
                <span className={classes.previousButton}>
                    <ArrowLeftOutlined className={classes.icon} />
                    {t("grid_Previous")}
                </span>
            );
        }

        if (type === "next") {
            return (
                <span className={classes.nextButton}>
                    {t("grid_Next")} <ArrowRightOutlined className={classes.icon} />
                </span>
            );
        }

        if (type === "page") {
            const isActive = pageNumber === page;

            return (
                <span className={`${isActive ? classes.activePage : classes.nonActivePage}`}>
                    {page}
                </span>
            );
        }
        if (type === "jump-prev" || type === "jump-next") {
            return <span className={classes.jumpButton}>...</span>;
        }

        return null;
    };

    return (
        <Table
            loading={{
                spinning: rest.loading ? rest.loading : false,
                tip: t("generic_Loading"),
            }}
            data-test="grid_light_table"
            title={() => {
                return (
                    <div style={{ display: "table", width: "100%" }}>
                        <div className={classes.userTitle}>
                            {title && title()}
                            {rest.rowSelection &&
                                rest.rowSelection.selectedRowKeys &&
                                rest.rowSelection.selectedRowKeys.length >= 1 && (
                                    <span onClick={() => (rest.rowSelection.selectedRowKeys = [])}>
                                        {rest.rowSelection.selectedRowKeys.length} {t("selected")}
                                    </span>
                                )}
                        </div>

                        <div className={classes.gridTitle}></div>
                    </div>
                );
            }}
            rowClassName={(record, index) =>
                classnames(index % 2 === 0 ? "table-row-light" : "table-row-dark")
            }
            className={classnames(
                "grid",
                ...classNames,
                dataSource.length === 0 ? "empty-table" : ""
            )}
            rowKey="id"
            size={tableSize}
            columns={resizableColumns}
            dataSource={dataSource}
            scroll={
                scroll && {
                    y:
                        scroll && scroll.y
                            ? scroll.y
                            : `calc(100vh - ${excess + tableExcessHeight + 32}px`,
                    x: tableColumns.reduce((total: number, column: any) => {
                        if (column.width && column.children) {
                            return (
                                total +
                                Math.max(column.children.length * minColumnWidth, column.width)
                            );
                        }
                        return total + (column.width || 0);
                    }, 0),
                }
            }
            onChange={handleTableChange}
            components={components}
            bordered={true}
            pagination={
                pagination === "default"
                    ? {
                          current: pageNumber,
                          position: ["bottomRight"],
                          pageSizeOptions: [...pageSizeOptions, defaultPageSize.toString()].sort(
                              (a: string, b: string) => parseInt(a) - parseInt(b)
                          ),
                          pageSize: pageSize,
                          showSizeChanger: true,
                          total: dataSource.length,
                          itemRender: paginationItemRender,
                          locale: {
                              items_per_page: "/ " + t("tablePage"),
                              prev_page: t("grid_PrevPage"),
                              next_page: t("grid_NextPage"),
                          },
                          showTotal: (total: number, range: any) =>
                              t("tableTotalItems", {
                                  range0: range[0],
                                  range1: range[1],
                                  total: total,
                              }),
                      }
                    : pagination === "light"
                    ? {
                          locale: {
                              items_per_page: "/ " + t("tablePage"),
                              prev_page: t("grid_PrevPage"),
                              next_page: t("grid_NextPage"),
                          },
                      }
                    : false
            }
            locale={{
                emptyText: (
                    <Result
                        style={{ marginBottom: -10, marginTop: -10 }}
                        icon={<></>}
                        title={emptyText ? emptyText : t("grid_NoData")}
                        status="info"></Result>
                ),
            }}
            {...rest}
        />
    );
};

export { GridLight };

const ResizableTitle = (props: any) => {
    const { onResize, width, onClick, minColumnWidth, ...restProps } = props;
    const [allowHeaderClick, setAllowHeaderClick] = useState(true);

    if (!width) {
        return <th {...restProps} onClick={onClick} />;
    }

    const onResizeStart = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setAllowHeaderClick(false);
    };

    return (
        <Resizable
            width={width}
            minConstraints={[
                restProps.colSpan ? restProps.colSpan * minColumnWidth : minColumnWidth,
                20,
            ]}
            height={0}
            onResize={onResize}
            onResizeStart={onResizeStart}
            draggableOpts={{ enableUserSelectHack: false }}>
            <th
                {...restProps}
                onMouseDown={() => setAllowHeaderClick(true)}
                onClick={(e: SyntheticEvent) =>
                    allowHeaderClick && onClick !== undefined && onClick(e)
                }
            />
        </Resizable>
    );
};
