import { useEffect, useState } from "react";
type State = {
    loading: boolean;
    data: any[];
};

const useMetricConditionPeriod = () => {
    const [state, setState] = useState<State>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = [
                { name: "3 months", value: 3 },
                { name: "6 months", value: 6 },
                { name: "12 months", value: 12 },
            ];
            setState({ loading: false, data });
        })();
    }, []);
    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMetricConditionPeriod };
