import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Row,
    Popover,
    Popconfirm,
    Form,
    notification,
    Input,
    List,
    Tooltip,
    Flex,
} from "antd";
import classes from "./supplierInformation.module.scss";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLinks, useLinksCrud } from "controller";
import { ILinkType } from "models";

interface IProps {
    partnerId: number;
}

const SupplierLinks = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { data: links, loading } = useLinks(ILinkType.SupplierSummary, partnerId, reload);

    useEffect(() => {
        setReload({ reload: true });
    }, [partnerId]);

    return (
        <Row>
            <Col span={24}>
                <Row className={classes.headline}>
                    <Col span={20}>{t("generic_Links")}</Col>
                </Row>

                <List
                    className={classes.linkList}
                    loading={loading}
                    style={{ minHeight: 150 }}
                    pagination={{
                        defaultCurrent: 1,
                        pageSize: 5,
                        total: links.length,
                        size: "small",
                        hideOnSinglePage: true,
                    }}
                    locale={{
                        emptyText: (
                            <div style={{ minHeight: 50 }}>
                                <div style={{ paddingTop: 10 }}>{t("generic_NoData")}</div>
                            </div>
                        ),
                    }}
                    bordered={false}
                    size="small"
                    itemLayout="horizontal"
                    dataSource={links}
                    renderItem={(item: any, index: number) => (
                        <List.Item className={classes.linkRow}>
                            <List.Item.Meta
                                title={
                                    <Flex>
                                        <Tooltip title={item.url} placement="left">
                                            <a
                                                href={
                                                    item.url.indexOf("https://") !== -1
                                                        ? item.url
                                                        : "https://" + item.url
                                                }
                                                target="_blank"
                                                rel="noreferrer">
                                                {item.title
                                                    ? item.title.length >= 30
                                                        ? `${item.title.substring(0, 30)}...`
                                                        : item.title
                                                    : `${item.url
                                                          .replace("https://www.", "")
                                                          .substring(0, 40)}`}
                                            </a>
                                        </Tooltip>
                                        <Flex style={{ display: "inline-block" }} justify="end">
                                            <UpsertItem
                                                item={item}
                                                type="edit"
                                                onUpsert={() => {
                                                    setReload({ reload: true });
                                                }}
                                                partnerId={partnerId}
                                            />
                                            <RemoveItem
                                                item={item}
                                                onRemoveItem={() => {
                                                    setReload({ reload: true });
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                }
                            />
                        </List.Item>
                    )}
                />
                <Row>
                    <Col span={4}>
                        <UpsertItem
                            item={null}
                            type="add"
                            onUpsert={() => {
                                setReload({ reload: true });
                            }}
                            partnerId={partnerId}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

interface IUpsertProps {
    item: any;
    onUpsert: Function;
    type: "add" | "edit";
    partnerId: number;
    [rest: string]: any;
}

interface IRemoveItemProps {
    item: any;
    onRemoveItem: Function;
    [rest: string]: any;
}

const RemoveItem = ({ item, onRemoveItem, rest }: IRemoveItemProps) => {
    const { t } = useTranslation();
    const { remove } = useLinksCrud();

    return (
        <Popconfirm
            placement="left"
            title={t("generic_DeleteConfirmation")}
            cancelText={t("generic_Cancel")}
            okText={t("generic_Confirm")}
            onConfirm={async () => {
                await remove([item.id]);
                onRemoveItem();
            }}>
            <DeleteOutlined className={classes.linkIcon} {...rest} />
        </Popconfirm>
    );
};

const UpsertItem = ({ item, onUpsert, type, partnerId, rest }: IUpsertProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const { upsert } = useLinksCrud();

    const handleSave = async () => {
        let values = await form.validateFields();

        if (item) {
            await upsert({
                ...item,
                title: values.title,
                url: values.url,
                partnerId: partnerId,
                typeId: ILinkType.SupplierSummary,
            });
            notification.success({
                message: t("success"),
                description: t("generic_UpdateSuccess", { name: t("generic_Link") }),
            });
        } else {
            await upsert({
                description: undefined,
                title: values.title,
                url: values.url,
                partnerId: partnerId,
                typeId: ILinkType.SupplierSummary,
            });
            notification.success({
                message: t("success"),
                description: t("generic_CreateSuccess", { name: t("generic_Link") }),
            });
        }

        onUpsert();
        setToggle(false);
    };

    const content = () => {
        return (
            <>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleSave}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 12 }}>
                    <Form.Item
                        name="url"
                        initialValue={item ? item.url : undefined}
                        label={t("generic_Url")}
                        rules={[{ required: true, whitespace: true, type: "url" }]}>
                        <Input
                            style={{ width: 300 }}
                            maxLength={4000}
                            placeholder={t("generic_UrlPlaceholder")}
                        />
                    </Form.Item>

                    <Form.Item
                        name="title"
                        initialValue={item ? item.title : undefined}
                        label={t("generic_Title")}>
                        <Input
                            style={{ width: 300 }}
                            maxLength={100}
                            placeholder={t("link_LinkTitlePlaceholder")}
                        />
                    </Form.Item>
                    <Row>
                        <Col span={18} style={{ textAlign: "right" }}>
                            <Button onClick={() => setToggle(false)}>{t("generic_Cancel")}</Button>
                        </Col>
                        <Col span={6} style={{ textAlign: "right" }}>
                            <Button
                                style={{ textAlign: "end" }}
                                type="primary"
                                onClick={() => form.submit()}>
                                {type === "add" ? t("generic_Add") : t("generic_Save")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    };

    return (
        <Popover
            trigger="click"
            placement="right"
            content={() => content()}
            open={toggle}
            onOpenChange={(visible: boolean) => {
                if (visible) {
                    form.resetFields();
                }
            }}>
            {type === "add" ? (
                <Button
                    type="primary"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => setToggle(true)}>
                    {`+ ${t("add")}`}
                </Button>
            ) : (
                <EditOutlined
                    onClick={() => setToggle(true)}
                    className={classes.linkIcon}
                    {...rest}
                />
            )}
        </Popover>
    );
};

export { SupplierLinks };
