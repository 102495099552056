import React, { FunctionComponent } from "react";
import { Modal } from "antd";
import { Grid, ReviewRate } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { usePerformanceAnswersByGroup } from "controller";

interface IProps {
    visible: boolean;
    onClose: Function;
    surveyId: number;
    criteriaGroupId: number | undefined;
    groupName?: string;
}

const ResponseDetailsByGroup: FunctionComponent<IProps> = ({
    visible,
    onClose,
    surveyId,
    criteriaGroupId,
    groupName,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t("Contact"),
            dataIndex: "contactName",
            viewColumnId: "contactName",
            key: "contactName",
            width: 250,
            ellipsis: true,
            render: (val: string) => (
                <span>{val.indexOf("ReviewCategory_") !== -1 ? t(val) : val}</span>
            ),
        },
        {
            title: t("question"),
            dataIndex: "reviewCriteria",
            viewColumnId: "reviewCriteria",
            key: "reviewCriteria",
            width: 250,
            render: (val: string) => (
                <span>{val.indexOf("ReviewCategory_") !== -1 ? t(val) : val}</span>
            ),
        },
        {
            title: t("rating"),
            dataIndex: "rating",
            viewColumnId: "rating",
            key: "rating",
            width: 250,
            render: (val: number) => (
                <div>
                    <ReviewRate allowHalf val={val} />
                </div>
            ),
        },
        {
            title: t("generic_Notes"),
            dataIndex: "notes",
            viewColumnId: "notes",
            key: "notes",
            ellipsis: true,
            width: 350,
        },
    ];

    return (
        <Modal
            width={1200}
            title={<div style={{ height: 30 }}> </div>}
            open={visible}
            onCancel={() => onClose()}
            footer={null}
            zIndex={2000}>
            <Grid
                title={() =>
                    t("survey_ResponseCriteriaDetailsTitle", {
                        groupName,
                    })
                }
                useData={usePerformanceAnswersByGroup.bind(null, criteriaGroupId, surveyId)}
                columns={columns}
                saveColumnSettings={false}
                pageContainerWidth={1200}
                customPageSize={15}
                showEmptyText
            />
        </Modal>
    );
};

export { ResponseDetailsByGroup };
