import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Input, notification, Form } from "antd";
import { useContactGroupInUse, useContactGroupUpdate } from "controller";

interface IProps {
    visible: boolean;
    data: any;
    onClose: (reload: boolean) => void;
}

const CreateEditModal = ({ data = {}, visible, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { createGroup, updateGroup, loading } = useContactGroupUpdate();
    const { isContactGroupNameInUse, loading: contactGroupInUseLoading } = useContactGroupInUse();

    useEffect(() => {
        form.resetFields();
    }, [data]);

    const editMode = Object.keys(data).length !== 0;

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const payload = {
            name: fieldsValue.name,
        };

        if (!editMode) {
            await createGroup(payload);
            notification.success({
                message: t("success"),
                description: t("contact_CreateGroupSuccess", "Group was created successfully"),
            });
        } else {
            await updateGroup({ id: data.id, ...payload });
            notification.success({
                message: t("success"),
                description: t("contact_UpdateGroupSuccess", "Group was updated successfully"),
            });
        }
        form.resetFields();
        onClose(true);
    };

    return (
        <Modal
            wrapProps={{ "data-test": "contact_group_create_edit_modal" }}
            title={editMode ? t("contact_GroupEdit") : t("contact_GroupCreate")}
            open={visible}
            footer={[
                <Button
                    key="ok"
                    type="primary"
                    onClick={() => !contactGroupInUseLoading && handleSave()}
                    loading={loading}>
                    {editMode ? t("generic_Save") : t("generic_Create")}
                </Button>,
            ]}
            onCancel={() => onClose(false)}>
            <Form
                form={form}
                labelAlign="left"
                labelCol={{ lg: 6, xs: 24 }}
                wrapperCol={{ lg: 18, xs: 24 }}>
                <Form.Item
                    required
                    label={t("contact_GroupName")}
                    name="name"
                    initialValue={data.name}
                    validateTrigger="onChange"
                    rules={[
                        {
                            validator: async (rule: any, value: any, callback: any) => {
                                try {
                                    if (!value || !value.trim()) {
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    }
                                    if (
                                        !data.name ||
                                        value.trim().toLowerCase() !==
                                            data.name.trim().toLowerCase()
                                    ) {
                                        const nameExists = await isContactGroupNameInUse(value);
                                        if (nameExists) {
                                            return Promise.reject(
                                                new Error(t("contact_GroupNameExists"))
                                            );
                                        }
                                    }
                                } catch (error) {
                                    return Promise.reject(error);
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}>
                    <Input placeholder={t("contact_GroupName")} maxLength={200} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateEditModal;
