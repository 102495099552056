import React, { useState, forwardRef } from "react";
import { SelectTreeDataMultiple } from "../select/selectTreeData/selectTreeDataMultiple";
import { Button, Divider } from "antd";
import { useExternalPartnerContacts } from "controller";
import { useTranslation } from "react-i18next";
import { TagRender } from "components/contentcomponents/suppliers/supplierProfile/enterprisePages/governance/shared/tagRender";
import { CreateModal as CreateContactModal } from "components/contentcomponents/contacts/shared/createModal";

type Props = {
    value?: any[];
    reloadOnDropdownVisibiltyChange: boolean;
    partnerId: any;
    includeChildSupplierContacts?: boolean;
    [key: string]: any;
    placeholder?: string;
};

const SelectPartnerContacts = forwardRef(
    (
        {
            partnerId,
            value,
            reloadOnDropdownVisibiltyChange = false,
            placeholder,
            includeChildSupplierContacts,
            ...rest
        }: Props,
        ref
    ) => {
        const { t } = useTranslation();
        const [contactModal, setContactModal] = useState<any>({
            visible: false,
        });
        const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });
        const {
            data: partnerContacts,
            loading: loadingPartnerContacts,
        } = useExternalPartnerContacts(partnerId, reloadContacts, includeChildSupplierContacts);
        const [expandedKeys, setExpandedKeys] = useState([]);

        return (
            <>
                <SelectTreeDataMultiple
                    ref={ref as any}
                    value={value}
                    allowClear={true}
                    useData={() => ({
                        data: partnerContacts,
                        loading: loadingPartnerContacts,
                    })}
                    selectAll
                    selectIdField="id"
                    selectOptionField="name"
                    placeholder={t(placeholder ? placeholder : "select")}
                    selectOnlyChildren
                    tagRender={(props: any) => TagRender(props, false)}
                    onDropdownVisibleChange={(open: boolean) => {
                        if (reloadOnDropdownVisibiltyChange && open) {
                            setReloadContacts({ reload: true });
                        }
                    }}
                    treeExpandedKeys={expandedKeys}
                    onTreeExpand={(keys: any) => {
                        if (keys && keys.length === 1) {
                            setExpandedKeys(keys);
                        } else if (keys && keys.length > 1) {
                            setExpandedKeys(keys.slice(1, 2));
                        } else {
                            setExpandedKeys(keys);
                        }
                    }}
                    dropdownRender={(menu: any) => (
                        <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Button
                                block
                                size="small"
                                onClick={() => {
                                    setContactModal({
                                        visible: true,
                                        partnerId: undefined,
                                    });
                                }}>
                                + {t("Contacts_Add")}
                            </Button>
                        </>
                    )}
                    {...rest}
                />
                {contactModal.visible && (
                    <CreateContactModal
                        visible={contactModal.visible}
                        onClose={(reload, contactId) => {
                            setReloadContacts({ reload: true });
                            setContactModal({
                                visible: false,
                            });
                        }}
                        partnerId={partnerId}
                        showSupplierSelect={false}
                    />
                )}
            </>
        );
    }
);

export { SelectPartnerContacts };
