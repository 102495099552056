import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { GridButton, PlusCircle, Trash02 } from "components/utilitycomponents";

type PropsType = {
    onCreateBtnClick: Function;
    onDeleteBtnClick: Function;
    selectedRowKeys: any[];
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    onCreateBtnClick,
    onDeleteBtnClick,
    selectedRowKeys,
}) => {
    const { t } = useTranslation();
    const buttonVisibility = selectedRowKeys.length < 1;
    return (
        <Fragment>
            {buttonVisibility ? (
                <GridButton data-test="create_survey_button" onClick={() => onCreateBtnClick()}>
                    <PlusCircle />
                    {t("survey_CreateSurvey")}
                </GridButton>
            ) : (
                <GridButton
                    danger
                    data-test="delete_survey_button"
                    onClick={() => onDeleteBtnClick()}>
                    <Trash02 style={{ color: selectedRowKeys.length !== 0 ? "#FF4D4F" : "" }} />
                    {t("survey_Delete", "Delete Survey")}
                </GridButton>
            )}
        </Fragment>
    );
};

export default OverviewHeader;
