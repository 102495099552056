import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, notification, Form } from "antd";
import { usePartnerOwners, usePartnerUpdate } from "controller";
import { SelectData } from "components/utilitycomponents";

interface IProps {
    visible: boolean;
    onClose: (shouldReload: boolean, newOwnerIds: number[] | undefined) => void;
    ownerHeadline: string;
    partnerId: number;
    ownerIds: number[];
}

const SwitchOwnerModal = ({ onClose, visible, ownerHeadline, partnerId, ownerIds }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const currentUserContactId = useSelector(
        (state: any) => state.oidc.user.profile["ll-ContactId"]
    );
    const { loading, updatePartnerOwners } = usePartnerUpdate();

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    const handleClose = (
        reload: boolean = false,
        newOwnerIds: number[] | undefined = undefined
    ) => {
        form.resetFields();
        onClose(reload, newOwnerIds);
    };

    const handleSubmit = async () => {
        let values = await form.validateFields();
        let newOwnerIds = ownerIds.filter(x => {
            if (x === parseInt(currentUserContactId) || x === values.owner) {
                return false;
            }
            return true;
        });
        newOwnerIds = [...newOwnerIds, values.owner];
        await updatePartnerOwners(partnerId, newOwnerIds);
        notification.success({
            message: t("success"),
            description: t("updateOwnerSuccess", { ownerHeadline: ownerHeadline }),
        });
        handleClose(true, newOwnerIds);
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
            lg: { span: 16 },
        },
    };

    return (
        <Modal
            wrapProps={{ "data-test": "switchOwner_modal" }}
            title={t("switchOwnerTitle", `Switch ${ownerHeadline} on supplier`, {
                ownerHeadline: ownerHeadline,
            })}
            open={visible}
            onOk={handleSubmit}
            onCancel={() => handleClose()}
            okText={t("generic_Save")}
            confirmLoading={loading}>
            <Form form={form}>
                <Form.Item
                    label={t("Selected") + ` ${ownerHeadline}`}
                    name="owner"
                    {...formItemLayout}
                    initialValue={parseInt(currentUserContactId)}>
                    <SelectData
                        useData={usePartnerOwners}
                        selectIdField="id"
                        selectOptionField="fullName"
                        placeholder={ownerHeadline}
                        getPopupContainer={(node: any) =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
const WithForm = SwitchOwnerModal;

export default WithForm;
