import { ITableInput } from "models";
import { IDevelopmentPlanTemplateActionModel } from "models/developmentPlan/IDevelopmentPlanTemplateActionModel";
import { IDevelopmentPlanTemplateCreationModel } from "models/developmentPlan/IDevelopmentPlanTemplateCreationModel";
import { post, remove, get, patch } from "../base";

const AdminDevelopmentPlan = {
    deleteDevelopmentPlanTemplate: async (ids: number[], { customError }: any = {}) => {
        const url = `/v1/AdminDevelopmentPlan/RemoveDevelopmentPlanTemplates`;
        await remove({ url, payload: ids, customError });
    },
    getDevelopmentPlanTemplateOverview: async (
        tableInput: ITableInput,
        { customError }: any = {
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/AdminDevelopmentPlan/GetDevelopmentPlanTemplateOverview`;
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },
    createDevelopmentPlanTemplate: async (
        developmentPlanTemplateCreationInput: IDevelopmentPlanTemplateCreationModel,
        { customError }: any = {}
    ) => {
        let url = `/v1/AdminDevelopmentPlan/CreateDevelopmentPlanTemplate`;
        return await post({ url, payload: developmentPlanTemplateCreationInput, customError });
    },
    editDevelopmentPlanTemplate: async (
        developmentPlanTemplateCreationInput: IDevelopmentPlanTemplateCreationModel,
        { customError }: any = {}
    ) => {
        let url = `/v1/AdminDevelopmentPlan/EditDevelopmentPlanTemplate`;
        return await patch({ url, payload: developmentPlanTemplateCreationInput, customError });
    },
    hasDevelopmentPlanTemplateDuplication: async (name: string, { customError }: any = {}) => {
        let url = `/v1/AdminDevelopmentPlan/HasTemplateDuplication?name=${name}`;
        return await get({ url, customError });
    },
    editDevelopmentPlanTemplateAction: async (
        input: IDevelopmentPlanTemplateActionModel,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminDevelopmentPlan/EditDevelopmentPlanTemplateAction`;
        await patch({ url, payload: input, customError });
    },
    removeTemplateAction: async (id: number, { customError }: any = {}) => {
        const url = `/v1/AdminDevelopmentPlan/RemoveDevelopmentPlanTemplateAction?id=${id}`;
        await remove({ url, payload: {}, customError });
    },
};

export default AdminDevelopmentPlan;
