import React, { FunctionComponent, Fragment } from "react";
import { Descriptions } from "antd";
import { ReviewRate, DownloadFile } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import classes from "./styles/registrationModal.module.scss";

type PropsType = { data: any };

const ReviewDetails: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Descriptions column={1} size="small" bordered>
                <Descriptions.Item label={t("review_Rating")}>
                    <ReviewRate val={data.rating} />
                </Descriptions.Item>
                <Descriptions.Item label={t("review_Criteria")}>
                    {t(data.categoryName)}
                </Descriptions.Item>
                <Descriptions.Item label={t("reviewCriteria_Description")}>
                    {t(data.categoryDescription)}
                </Descriptions.Item>
                <Descriptions.Item label={t("review_Notes")}>
                    <div className={classes.descriptionContent}>{data.notes}</div>
                </Descriptions.Item>
                {data.additionalSurveyFields &&
                    data.additionalSurveyFields.map((x: any, index: number) => (
                        <Descriptions.Item label={x.title}>
                            <div className={classes.descriptionContent}>{x.answer}</div>
                        </Descriptions.Item>
                    ))}
                {data.attachments.length !== 0 && (
                    <Descriptions.Item label={t("review_Attachments")}>
                        {data.attachments.map((file: any, index: number) => (
                            <div style={{ marginTop: 5 }} key={index}>
                                <DownloadFile file={{ id: file.fileId, name: file.fileName }} />
                            </div>
                        ))}
                    </Descriptions.Item>
                )}
            </Descriptions>
        </Fragment>
    );
};

export { ReviewDetails };
