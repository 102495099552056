import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { Provider } from "services";

const useCategoryCreate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const createCategory = async ({ name, categoryType, customHeadline }: any) => {
        setLoading(true);
        const id = await Provider.Partner.createCategory(
            { name, categoryType },
            {
                customError: { message: `Could not create ${customHeadline}` },
            }
        );
        setLoading(false);
        return id;
    };
    const createCategories = async ({ names, categoryType, customHeadline }: any) => {
        setLoading(true);
        const response = await Promise.all(
            names.map((name: string) => Provider.Partner.createCategory({ name, categoryType }))
        ).catch((err: any) => {
            notification.error({
                message: t("systemMessage"),
                description: `${t("createCategoryError")} ${customHeadline}`,
                duration: 0,
            });
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return response;
    };

    return {
        loading,
        createCategory,
        createCategories,
    };
};

export { useCategoryCreate };
