import React, { FunctionComponent, useEffect } from "react";
import { Modal, Row, Col, Input, Form, Divider, Radio, notification, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSegmentCategories } from "controller";
import { FormRow, SelectData } from "components/utilitycomponents";
import { useSegmentsAlign } from "controller";
import classes from "./alignModal.module.scss";

type PropTypes = {
    visible: boolean;
    onClose: Function;
    record: any;
    onSuccess: Function;
    onParentAlign: Function;
    partnerId: number;
};
const AlignModal: FunctionComponent<PropTypes> = ({
    visible,
    onClose,
    record,
    onSuccess,
    onParentAlign,
    partnerId,
}) => {
    const { t } = useTranslation();
    const { AlignSegments, loading } = useSegmentsAlign();
    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
    }, [visible]);

    if (record === undefined) {
        return null;
    }

    const restrictionOptions = [
        { label: t("generic_None"), value: "none" },
        { label: t("Limited"), value: "Limited" },
        { label: t("Prohibited"), value: "Prohibited" },
    ];

    const onSave = async () => {
        try {
            let values = await form.validateFields();
            let input = {
                unitOfAnalysisId: record.id,
                currentAlignedSegmentId: values.currentAlignedSegment
                    ? values.currentAlignedSegment
                    : null,
                currentSegmentRestrictions: values.currentRestriction,
                currentSegmentComment: values.currentComment,
                futureAlignedSegmentId: values.futureAlignedSegment
                    ? values.futureAlignedSegment
                    : null,
                futureSegmentRestrictions: values.futureRestriction,
                futureSegmentComment: values.futureComment,
                partnerId: partnerId,
            };

            await AlignSegments(input);
            notification.success({
                message: t("success"),
                description: t("unitOfAnalysis_AlignSegmentation_UpdateSucces"),
            });
            onClose();
            onSuccess();
            if (record.supplierId.toString() === partnerId.toString()) {
                onParentAlign();
            }
        } catch (errorInfo) {}
    };

    return (
        <Modal
            wrapProps={{ "data-test": "ncr_edit_modal" }}
            title={
                <div className={classes.modalTitle}>{t("unitOfAnalysis_AlignSegmentation")}</div>
            }
            open={visible}
            onCancel={() => onClose()}
            onOk={() => onSave()}
            width={850}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSave()}>
                    {t("generic_Save")}
                </Button>,
            ]}>
            <Form form={form}>
                <Row>
                    <Col span={11}>
                        <FormRow>
                            <Col push={9} span={24}>
                                <div className={classes.segmentationTitle}>
                                    <span>{t("Current")}</span>
                                </div>
                            </Col>
                            <Col span={9} className={classes.label}>
                                {t("unitOfAnalysis_AlignedSegment")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="currentAlignedSegment"
                                    initialValue={record.alignedSegmentCurrent.id}>
                                    <SelectData
                                        selectOptionField="segment"
                                        useData={useSegmentCategories.bind(null, "black")}
                                        style={{ width: "100%" }}
                                        placeholder={t("select")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={9} className={classes.label}>
                                {t("segmentation_SuggestSegment")}
                            </Col>
                            <Col span={15}>
                                {record.suggestedSegmentCurrent &&
                                    record.suggestedSegmentCurrent.segmentName && (
                                        <div
                                            style={{
                                                border: `1px solid ${record.suggestedSegmentCurrent.segmentColor}`,
                                                color: record.suggestedSegmentCurrent.segmentColor,
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}>
                                            {record.suggestedSegmentCurrent.segmentName}
                                        </div>
                                    )}
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={9} className={classes.label}>
                                {t("segmentation_Restrictions")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="currentRestriction"
                                    initialValue={record.alignedSegmentCurrent.restrictions}>
                                    <Radio.Group
                                        options={restrictionOptions}
                                        optionType="button"
                                        size="small"
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={9} className={classes.label}>
                                {t("segmentation_Comment")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="currentComment"
                                    initialValue={record.alignedSegmentCurrent.comment}>
                                    <Input.TextArea
                                        maxLength={1000}
                                        autoSize={{ minRows: 2, maxRows: 20 }}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </Col>
                    <Col span={2} style={{ textAlign: "center" }}>
                        <Divider dashed type="vertical" className={classes.verticalDivider} />
                    </Col>
                    <Col span={11}>
                        <FormRow>
                            <Col push={9} span={24}>
                                <div className={classes.segmentationTitle}>
                                    <span>{t("Future")}</span>
                                </div>
                            </Col>
                            <Col span={9} className={classes.label}>
                                {t("unitOfAnalysis_AlignedSegment")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="futureAlignedSegment"
                                    initialValue={record.alignedSegmentFuture.id}>
                                    <SelectData
                                        selectOptionField="segment"
                                        useData={useSegmentCategories.bind(null, "black")}
                                        style={{ width: "100%" }}
                                        placeholder={t("select")}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={9} className={classes.label}>
                                {t("segmentation_Restrictions")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="futureRestriction"
                                    initialValue={record.alignedSegmentFuture.restrictions}>
                                    <Radio.Group
                                        options={restrictionOptions}
                                        optionType="button"
                                        size="small"
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col span={9} className={classes.label}>
                                {t("segmentation_Comment")}
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    name="futureComment"
                                    initialValue={record.alignedSegmentFuture.comment}>
                                    <Input.TextArea
                                        maxLength={1000}
                                        autoSize={{ minRows: 2, maxRows: 20 }}
                                    />
                                </Form.Item>
                            </Col>
                        </FormRow>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AlignModal;
