import { IStatusEvent } from "models/admin";
import { useState } from "react";
import { Provider } from "services";

const useStatusEventSettingInUse = () => {
    const [loading, setLoading] = useState(false);

    const IsStatusEventSettingInUse = async (statusEvent: IStatusEvent) => {
        setLoading(true);

        const res = await Provider.Partner.isStatusEventSettingInUse(statusEvent, {
            customError: {
                message: "Could not check for status events that are in use",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        IsStatusEventSettingInUse,
    };
};

export { useStatusEventSettingInUse };
