import { useState } from "react";
import i18n from "i18n";
import { notification } from "antd";
import { Provider } from "services";

const useGroupsCreate = () => {
    const [loading, setLoading] = useState(false);

    const createGroups = async (names: string[], groupHeadline: string) => {
        setLoading(true);
        const response = await Promise.all(
            names.map((name: string) => Provider.Partner.createGroup({ name }))
        ).catch((err: any) => {
            notification.error({
                message: i18n.t("systemMessage"),
                description: `${i18n.t("createGroupError")} ${groupHeadline}`,
                duration: 0,
            });
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return response;
    };

    return {
        loading,
        createGroups,
    };
};

export { useGroupsCreate };
