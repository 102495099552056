export const drawProgressBar = (canvas: any, color: string, percent: number) => {
    const percentage = percent / 100;
    const ctx = canvas.getContext("2d");
    canvas.width = canvas.parentElement.offsetWidth;
    const w = canvas.width - 60;
    const h = canvas.height;

    const draw = () => {
        const p = percentage * w;
        if (p <= h) {
            ctx.beginPath();
            ctx.arc(
                h / 2,
                h / 2,
                h / 2,
                Math.PI - Math.acos((h - p) / h),
                Math.PI + Math.acos((h - p) / h)
            );
            ctx.save();
            ctx.scale(-1, 1);
            ctx.arc(
                h / 2 - p,
                h / 2,
                h / 2,
                Math.PI - Math.acos((h - p) / h),
                Math.PI + Math.acos((h - p) / h)
            );
            ctx.restore();
            ctx.closePath();
        } else {
            ctx.beginPath();
            ctx.arc(h / 2, h / 2, h / 2, Math.PI / 2, (3 / 2) * Math.PI);
            ctx.lineTo(p - h, 0);
            ctx.arc(p - h / 2, h / 2, h / 2, (3 / 2) * Math.PI, Math.PI / 2);
            ctx.lineTo(h / 2, h);
            ctx.closePath();
        }
        ctx.fillStyle = color;
        ctx.fill();
    };
    const showWholeProgressBar = () => {
        ctx.beginPath();
        ctx.arc(h / 2, h / 2, h / 2, Math.PI / 2, (3 / 2) * Math.PI);
        ctx.lineTo(w - h / 2, 0);
        ctx.arc(w - h / 2, h / 2, h / 2, (3 / 2) * Math.PI, Math.PI / 2);
        ctx.lineTo(h / 2, h);
        ctx.fillStyle = "#f5f5f5";
        ctx.fill();
        ctx.closePath();
    };
    showWholeProgressBar();
    draw();
    ctx.font = "14px sans-serif";
    ctx.fillStyle = "#111";
    ctx.fillText(`${percent}%`, w + 15, h / 2);
};
