import React, { useState, useEffect, FunctionComponent } from "react";
import {
    AlertCircle,
    BarChart07,
    Eye,
    Grid,
    MultipleTags,
    PlusCircle,
    Send01,
    Trash02,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { Tooltip, Progress, Row, Col, Popconfirm } from "antd";
import SurveyResults, {
    colorGrade,
} from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import classes from "./performanceOverview.module.scss";
import RegistrationCreateModal from "components/contentcomponents/action/registration/registrationCreateModal";
import { IActionType } from "models/action";
import { useHistory } from "react-router-dom";
import { usePerformanceUnitOfAnalysisOverview, usePerformanceAnalysisRemove } from "controller";
import { SavedFilterType, SurveyType } from "models";
import { TopFilter } from "../../../../../../utilitycomponents";
import dayjs from "utilities/daysJsConfig";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface IProps {
    onIconClick: Function;
    reloadTable: any;
    onYearFilterChange: Function;
    partnerId: number;
}

const Overview: FunctionComponent<IProps> = ({
    onIconClick,
    reloadTable,
    onYearFilterChange,
    partnerId,
}) => {
    const { t } = useTranslation();
    const [dynamicColumns, setDynamicColumns] = useState<any[]>([]);
    const [reload, setReload] = useState({ reload: false });
    const [surveyResult, setSurveyResult] = useState<any>({
        surveyId: undefined,
        visible: false,
        supplierEntity: undefined,
        descriptors: [],
        tableRecord: undefined,
    });
    const [actionModal, setActionModal] = useState<any>({
        visible: false,
        tableRecord: undefined,
    });
    const [topFilters, setTopFilters] = useState<any>({});
    const [performanceChartInput, setPerformanceChartInput] = useState<any[]>([]);
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const { deleteItem } = usePerformanceAnalysisRemove();
    useEffect(() => {
        setPerformanceChartInput([]);
        onIconClick([]);
    }, [partnerId]);

    const history = useHistory();

    const navigateToSendSurvey = (surveyId: number) => {
        const path = `/Reviews/SurveyOverview/Create/type/3/${partnerId}/${surveyId}`;
        history.push(path);
    };

    const handleInternalClick = (val: any, rowKey: string) => {
        let tempArray = performanceChartInput.slice(0);
        if (tempArray.filter((x: any) => x.rowKey === rowKey).length >= 1) {
            tempArray = tempArray.filter((x: any) => x.rowKey !== rowKey);
        } else if (performanceChartInput.length < 2) {
            tempArray.push({
                performanceAnalysisId: val,
                performanceLevel: "InternalLevel",
                rowKey: rowKey,
            });
        }
        setPerformanceChartInput(tempArray);
        onIconClick(tempArray);
    };

    const handleExternalClick = (val: any, rowKey: string) => {
        let tempArray = performanceChartInput.slice(0);
        if (tempArray.filter((x: any) => x.rowKey === rowKey).length >= 1) {
            tempArray = tempArray.filter((x: any) => x.rowKey !== rowKey);
        } else if (performanceChartInput.length < 2) {
            tempArray.push({
                performanceAnalysisId: val,
                performanceLevel: "ExternalLevel",
                rowKey: rowKey,
            });
        }
        setPerformanceChartInput(tempArray);
        onIconClick(tempArray);
    };

    const checkYearFilterChange = (filters: any[]) => {
        let yearFilterIndex = filters.findIndex((x: any) => x.type === "Year");
        if (yearFilterIndex !== -1) {
            let selectedYear = filters[yearFilterIndex].value;
            onYearFilterChange(selectedYear);
        } else {
            //filters are cleared
            onYearFilterChange(undefined);
        }
    };

    const getStrokeColor = (val: number, maxNumber: number) => {
        let percentage = (val / maxNumber) * 100;
        if (percentage === Infinity) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        }
        if (percentage === 100) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        } else if (percentage < 100 && percentage >= 75) {
            return {
                "0%": "#ABC41D",
                "100%": "#ABC41D",
            };
        } else if (percentage < 75 && percentage >= 33) {
            return {
                "0%": "#fa8c16",
                "100%": "#fa8c16",
            };
        } else {
            return {
                "0%": "#f5222d",
                "100%": "#f5222d",
            };
        }
    };

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadTable]);

    const handleChartHighlight = (isParent: boolean, id: number, rowKey: string) => {
        isParent ? handleInternalClick(id, rowKey) : handleExternalClick(id, rowKey);
    };

    const initCols = [
        {
            title: t("generic_Status"),
            dataIndex: "deadLine",
            key: "deadline",
            width: 130,
            fixed: true,
            viewColumnId: "deadLine",
            render: (deadline: string, record: any) => (
                <div>
                    <div className={classes.highlightBox}>
                        <Tooltip title={t("unitOfAnalysis_NoSegmentAssigned")}>
                            <span>
                                <AlertCircle
                                    style={{
                                        display: record.highlight === true ? "" : "none",
                                    }}
                                    className={classes.highlightIcon}
                                />
                            </span>
                        </Tooltip>
                    </div>

                    {deadline &&
                        (dayjs().isBefore(deadline, "day") || dayjs().isSame(deadline, "day")) && (
                            <div className={classes.activeDeadline}>
                                {dayjs(deadline)
                                    .format("MMM D, YYYY")
                                    .toString()}
                            </div>
                        )}
                    {deadline && dayjs().isAfter(deadline, "day") && (
                        <div className={classes.resolvedDeadline}>
                            {t("unitOfAnalysis_Completed")}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: t("unitOfAnalysis_SupplierEntity"),
            dataIndex: "supplierName",
            key: "SupplierName",
            width: 230,
            fixed: true,
            viewColumnId: "supplierName",
            render: (name: string, record: any) => (
                <div className={classes.entityCell}>
                    {record.isParent ? (
                        <div>
                            {name}{" "}
                            <>
                                {record.supplierId === partnerId && (
                                    <Tooltip title={t("unitOfAnalysis_ParentLevel")}>
                                        <span className={classes.parentIcon}>P</span>{" "}
                                    </Tooltip>
                                )}
                                <MultipleTags
                                    tagNumber={1}
                                    className={classes.surveyTitleDescriptorContainer}
                                    values={record.commodityCategories
                                        .concat(record.divisions, record.regions)
                                        .map((item: string, index: number) => ({
                                            name: item,
                                            id: index,
                                        }))}
                                    color="#108ee9"
                                    tagTooltips={true}
                                />
                            </>
                        </div>
                    ) : (
                        <>
                            <div style={{ color: "#ffa940", paddingLeft: 16 }}>
                                {t("supplier")}{" "}
                                <MultipleTags
                                    tagNumber={1}
                                    className={classes.surveyTitleDescriptorContainer}
                                    values={record.commodityCategories
                                        .concat(record.divisions, record.regions)
                                        .map((item: string, index: number) => ({
                                            name: item,
                                            id: index,
                                        }))}
                                    color="#108ee9"
                                    tagTooltips={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            ),
        },
        {
            title: t("supplierProfile_Performance_TotalScore"),
            dataIndex: ["values", "TotalAverage"],
            key: "totalScore",
            width: 130,
            viewColumnId: "totalScore",
            render: (val: number) => (
                <span style={{ color: colorGrade(val ? val : -1) }}>
                    {val ? val.toPrecision(2) : "-"}
                </span>
            ),
        },
        {
            title: "",
            dataIndex: "scores",
            key: "scores",
            viewColumnId: "scores",
            children: dynamicColumns.map((dynCol: any) => ({
                title: dynCol.criteriaName,
                dataIndex: ["values", `CategoryGroup${dynCol.criteriaId}`],
                key: `${dynCol.criteriaName}${dynCol.criteriaId}`,
                width: 200,
                className: "headerLabel2",
                ellipsis: true,
                render: (val: number) => (
                    <span style={{ color: val ? colorGrade(val) : "#808080" }}>
                        {val ? val.toPrecision(2) : "-"}
                    </span>
                ),
            })),
        },
        {
            title: t("unitOfAnalysis_ViewResponseStatistics"),
            width: 150,
            viewColumnId: "viewResult",
            align: "center",
            render: (_: any, record: any) => (
                <Row
                    onClick={(e: any) => {
                        e!.preventDefault();
                        e!.stopPropagation();
                        setSurveyResult({
                            performanceAnalysisId: record.id,
                            isParent: record.isParent,
                            surveyId: record.latestSurveyId ? record.latestSurveyId : [],
                            type: record.children ? "internalLevel" : "externalLevel",
                            visible: true,
                            supplierEntity: {
                                name: record.supplierName,
                                id: record.supplierId,
                            },
                            descriptors: record.commodityCategories
                                .concat(record.divisions, record.regions)
                                .map((item: string, index: number) => ({
                                    name: item,
                                    id: index,
                                })),
                            tableRecord: record,
                        });
                    }}
                    style={{ cursor: "pointer" }}>
                    <Col span={6}>
                        <Eye
                            style={{
                                marginTop: 2,
                            }}
                        />
                    </Col>
                    <Tooltip title={t("unitOfAnalysis_BasedRecentSurvey")}>
                        <Col span={16}>
                            <Progress
                                success={{ percent: 0 }}
                                size="small"
                                percent={
                                    (record.currentResponseCount / record.maxResponseCount) * 100
                                }
                                format={() => (
                                    <span>{`${record.currentResponseCount} / ${record.maxResponseCount}`}</span>
                                )}
                                strokeWidth={20}
                                strokeColor={getStrokeColor(
                                    record.currentResponseCount,
                                    record.maxResponseCount
                                )}
                            />
                        </Col>
                    </Tooltip>
                </Row>
            ),
        },
        {
            title: t("generic_Actions"),
            className: classes.centerTitle,
            width: 300,
            key: `actionsCol`,
            viewColumnId: "actionButtons",
            operationColumn: true,
            render: (_: any, record: any) => (
                <div
                    style={{ textAlign: "center", display: "flex" }}
                    key={`showInchart${record.tableKey}`}>
                    <Tooltip title={t("unitOfAnalysis_ShowInChart")}>
                        <span>
                            <BarChart07
                                onClick={(e: any) => {
                                    handleChartHighlight(
                                        record.isParent,
                                        record.id,
                                        record.tableKey
                                    );
                                    e!.preventDefault();
                                    e!.stopPropagation();
                                }}
                                className={
                                    performanceChartInput.filter(
                                        (x: any) => x.rowKey === record.tableKey
                                    ).length > 0 &&
                                    performanceChartInput.findIndex(
                                        (x: any) => x.rowKey === record.tableKey
                                    ) === 0
                                        ? classes.showInChartActiveInternal
                                        : performanceChartInput.filter(
                                              (x: any) => x.rowKey === record.tableKey
                                          ).length > 0 &&
                                          performanceChartInput.findIndex(
                                              (x: any) => x.rowKey === record.tableKey
                                          ) === 1
                                        ? classes.showInChartActiveExternal
                                        : classes.showInChartInactive
                                }
                            />
                        </span>
                    </Tooltip>
                    {record.children && (
                        <Tooltip title={t("unitOfAnalysis_CreateAction")}>
                            <span>
                                <PlusCircle
                                    className={
                                        record.values.TotalAverage !== null
                                            ? classes.rowIconEnabled
                                            : classes.rowIconDisabled
                                    }
                                    onClick={(e: any) => {
                                        e!.preventDefault();
                                        e!.stopPropagation();
                                        if (record.values.TotalAverage !== null) {
                                            setActionModal({
                                                visible: true,
                                                tableRecord: { ...record, columns: dynamicColumns },
                                            });
                                        }
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )}
                    {record.children ? (
                        <Tooltip title={"Repeat Survey"}>
                            <span>
                                <Send01
                                    className={
                                        record.latestSurveyId !== null
                                            ? classes.rowIconEnabled
                                            : classes.rowIconDisabled
                                    }
                                    onClick={(e: any) => {
                                        e!.preventDefault();
                                        e!.stopPropagation();
                                        if (record.latestSurveyId !== null) {
                                            navigateToSendSurvey(record.latestSurveyId);
                                        }
                                    }}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <span style={{ marginRight: 70 }}></span>
                    )}
                    {record.children && userType === "administrator" && (
                        <Tooltip title={t("segmentation_DeleteUnitOfAnalysis")} placement="left">
                            <Popconfirm
                                placement="left"
                                title={t("performance_RemoveConfirm")}
                                okText={t("generic_Yes")}
                                cancelText={t("generic_No")}
                                onPopupClick={(e: any) => e.stopPropagation()}
                                onConfirm={async () => {
                                    await deleteItem(record.id);
                                    setReload({ reload: true });
                                }}>
                                <span>
                                    <Trash02
                                        className={classes.removeSegment}
                                        style={{ marginLeft: 0 }}
                                        onClick={(e: any) => e.stopPropagation()}
                                    />
                                </span>
                            </Popconfirm>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div style={{ marginLeft: -10, marginRight: -10, marginBottom: 4 }}>
            <Grid
                data-test="unit_of_analysis_table"
                columns={initCols}
                useData={usePerformanceUnitOfAnalysisOverview.bind(null, topFilters)}
                onDataCustomFields={(customFields: any) => {
                    customFields &&
                        customFields.dynamicColumns &&
                        setDynamicColumns(customFields.dynamicColumns);
                }}
                rowKey="tableKey"
                expandable={{
                    expandRowByClick: true,
                    showExpandColumn: false,
                }}
                onRow={(record: any) => {
                    return {
                        style: { cursor: record.children ? "pointer" : "default" },
                    };
                }}
                saveColumnSettings={false}
                fixedColumns={["id", "supplierName"]}
                reloadData={reload}
                onHeaderCell={() => ({
                    style: {
                        color: "#808080",
                        fontWeight: "bold",
                    },
                })}
                title={() => (
                    <Row>
                        <Col span={24}>
                            <TopFilter
                                partnerId={partnerId}
                                onFiltersChange={(val: any) => {
                                    setTopFilters(val);
                                    checkYearFilterChange(val);
                                }}
                                filterType={SavedFilterType.SegmentationPerformance}
                                onSavedFiltersChange={() => setReload({ reload: true })}
                                updateSavedFilters={(val: any) => setTopFilters(val)}
                                type={SurveyType.Performance}
                            />
                        </Col>
                    </Row>
                )}
                customPageSize={15}
                draggableRows={false}
            />

            <SurveyResults
                gridTitle={<SurveyResultsTitle surveyResults={surveyResult} />}
                visible={surveyResult.visible}
                type={"performance"}
                performanceUnit={{
                    id: surveyResult.performanceAnalysisId,
                    type: surveyResult.type,
                }}
                surveyId={surveyResult.surveyId}
                onClose={() =>
                    setSurveyResult({
                        surveyId: undefined,
                        performanceAnalysisId: undefined,
                        type: undefined,
                        supplierEntity: undefined,
                        descriptors: [],
                        tableRecord: undefined,
                        visible: false,
                    })
                }
            />
            {actionModal.tableRecord && (
                <RegistrationCreateModal
                    registrationType={IActionType.Performance}
                    visible={actionModal.visible}
                    onClose={() => setActionModal({ visible: false, tableRecord: undefined })}
                    registrationId={actionModal.tableRecord.id}
                    supplierId={actionModal.tableRecord.supplierId}
                    data={actionModal.tableRecord}
                />
            )}
        </div>
    );
};

interface ITitleProps {
    surveyResults: any;
}

const SurveyResultsTitle = ({ surveyResults }: ITitleProps) => {
    const { t } = useTranslation();
    return (
        <Row style={{ height: "100%" }}>
            <Col style={{ backgroundColor: "#FFF" }} span={24}>
                <span className={classes.surveyTitle}>
                    {surveyResults.isParent
                        ? t("segmentation_SurveyResults_Title", {
                              supplierName: surveyResults.supplierEntity.name,
                              supplierId: surveyResults.supplierEntity.id,
                              interpolation: { escapeValue: false },
                          })
                        : t("segmentation_VOSSurveyResults_Title", {
                              supplierName: surveyResults.supplierEntity.name,
                              supplierId: surveyResults.supplierEntity.id,
                              interpolation: { escapeValue: false },
                          })}
                </span>
                {surveyResults.descriptors && (
                    <MultipleTags
                        tagNumber={1}
                        className={classes.surveyTitleDescriptorContainer}
                        values={surveyResults.descriptors}
                        color="#108ee9"
                        tagTooltips={false}
                    />
                )}
            </Col>
        </Row>
    );
};

export default Overview;
