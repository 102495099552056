import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: any[];
};

const useSupplierAccessSettings = (reload: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Company.getSupplierAccessSettings().catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSupplierAccessSettings };
