import React, { useState } from "react";
import { Modal, Row, Col, Select, Alert, notification, Spin, Button } from "antd";
import { categoryType } from "models";
import { useTranslation } from "react-i18next";
import { useCategoryCreate, usePartnerCategories } from "controller";

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    setCreatedCategory(createdCategories: { key: string | number; label: string }[]): void;
    categoryHeadline: string;
    catType: categoryType;
}

interface ModalState {
    loading: boolean;
    categoryNames: string[];
    hasError: boolean;
    errorText: string;
}

const CreateCategoryModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const [state, setState] = useState<ModalState>({
        categoryNames: [],
        loading: false,
        hasError: false,
        errorText: "",
    });

    const { data: existingCategories, loading } = usePartnerCategories(props.catType, "", reload);
    const { createCategories, loading: createLoading } = useCategoryCreate();

    const openNotification = (
        type: "success" | "info" | "error" | "warning",
        title: string,
        description: string,
        duration: number
    ) => {
        notification.open({
            type: type,
            message: title,
            description: description,
            duration: duration,
        });
    };

    const categoryHeadline = props.categoryHeadline;

    const onSelectChange = async (values: string[]) => {
        values = values.map(x => x.trim()).filter(x => x.length > 0);
        let hasError: boolean = false;
        let errorText: string = "";
        if (values.filter(x => x.trim().length > 50).length > 0) {
            hasError = true;
            errorText = `${categoryHeadline} ${t("createCategoryLengthError")}`;
        }
        for (let i = 0; i < values.length; i++) {
            if (
                existingCategories
                    .slice(0)
                    .filter(x => x.name.toLowerCase() === values[i].toLowerCase()).length > 0
            ) {
                hasError = true;
                errorText = `${categoryHeadline} ${t("createCategoryNameExists")}`;
            }
        }
        setState({
            ...state,
            categoryNames: values,
            hasError: hasError,
            errorText: errorText,
        });
    };

    const onSubmit = async () => {
        if (state.categoryNames.length > 0) {
            const response = await createCategories({
                names: state.categoryNames,
                categoryType: props.catType,
                customHeadline: categoryHeadline,
            });
            openNotification(
                "success",
                `${t("success")}`,
                `${categoryHeadline} ${t("createCategorySuccess")}`,
                4.5
            );
            let createdCategories: any[] = [];
            for (let i = 0; i < response.length; i++) {
                createdCategories.push({
                    key: response[i],
                    label: state.categoryNames[i],
                });
            }
            setReload({ reload: true });
            setState({ ...state, categoryNames: [], hasError: false, errorText: "" });
            props.setCreatedCategory(createdCategories);
            props.setModalVisibility(false);
        }
    };
    return (
        <Modal
            title={t("createCategoryTitle", { categoryHeadline: categoryHeadline })}
            open={props.visible}
            onCancel={() => {
                props.setModalVisibility(false);
                setState({ ...state, categoryNames: [], hasError: false, errorText: "" });
            }}
            onOk={() => onSubmit()}
            zIndex={1001}
            footer={[
                <Button
                    disabled={state.hasError}
                    loading={createLoading}
                    type="primary"
                    onClick={async () => await onSubmit()}>
                    {t("generic_Create")}
                </Button>,
            ]}>
            <Spin spinning={loading}>
                <Row>
                    <Alert
                        type="info"
                        style={{ marginBottom: 8 }}
                        message=""
                        description={
                            <div className="infoTooltip">
                                {t("createCategoryInfo", { categoryHeadline: categoryHeadline })}
                            </div>
                        }
                    />
                    <Col span={24}>
                        <Select
                            mode="tags"
                            placeholder={t("createCategoryPlaceholder", {
                                categoryHeadline: categoryHeadline,
                            })}
                            style={{ width: "100%" }}
                            tokenSeparators={[",", "."]}
                            onChange={(values: any) => onSelectChange(values)}
                            dropdownStyle={{ display: "none" }}
                            value={state.categoryNames}></Select>
                    </Col>
                    {state.hasError && <span style={{ color: "red" }}>{state.errorText}</span>}
                </Row>
            </Spin>
        </Modal>
    );
};
export default CreateCategoryModal;
