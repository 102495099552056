import React, { forwardRef } from "react";
import { LinkOutlined, MailOutlined } from "@ant-design/icons";
import { SelectDataMultiple } from "../selectDataMultiple";
import { usePartnerList } from "controller";
import { SelectTreeDataMultiple } from "../selectTreeData/selectTreeDataMultiple";

type PropsType = {
    onChange?: (value?: any[]) => void;
    value?: any[];
    selectAll?: boolean;
    includeChildren: boolean;
    [key: string]: any;
};

const SelectExternalPartnerMultiple = forwardRef(
    ({ onChange, value, selectAll = false, includeChildren, ...rest }: PropsType, ref) => {
        return includeChildren ? (
            <SelectTreeDataMultiple
                data-test="external_partner_selectSingle"
                ref={ref as any}
                value={value}
                onChange={onChange}
                allowClear
                selectAll
                selectOptionField={(item: any) => ({
                    text: `${item.name} ${item.number}`,
                    node: (
                        <>
                            {item.hasEmail && (
                                <>
                                    <MailOutlined />{" "}
                                </>
                            )}
                            {item.isLinked && (
                                <>
                                    <LinkOutlined />{" "}
                                </>
                            )}
                            {`${item.name} (${item.number && item.number})`}
                        </>
                    ),
                })}
                useData={usePartnerList}
                getPopupContainer={(node: any) =>
                    node ? (node.parentNode as HTMLElement) : document.body
                }
                autoClearSearchValue={false}
                {...rest}
            />
        ) : (
            <SelectDataMultiple
                data-test="external_partner_selectSingle"
                ref={ref as any}
                value={value}
                onChange={onChange}
                allowClear
                selectAll
                selectOptionField={(item: any) => ({
                    text: `${item.name} ${item.number}`,
                    node: (
                        <>
                            {item.hasEmail && (
                                <>
                                    <MailOutlined />{" "}
                                </>
                            )}
                            {item.isLinked && (
                                <>
                                    <LinkOutlined />{" "}
                                </>
                            )}
                            {`${item.name} (${item.number && item.number})`}
                        </>
                    ),
                })}
                useData={usePartnerList}
                getPopupContainer={(node: any) =>
                    node ? (node.parentNode as HTMLElement) : document.body
                }
                autoClearSearchValue={false}
                {...rest}
            />
        );
    }
);

export { SelectExternalPartnerMultiple };
