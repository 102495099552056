import React, { FunctionComponent, Fragment, useLayoutEffect, useState, useEffect } from "react";
import {
    Button,
    Spin,
    Empty,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Dropdown,
    Flex,
} from "antd";
import {
    DotsHorizontal,
    HelpCircle,
    Lock01,
    LockUnlocked01,
    SelectData,
} from "components/utilitycomponents";
import { useSelector } from "react-redux";
import { AppState } from "store";
import GridLayout from "react-grid-layout";
import classes from "./styles/dashboard.module.scss";
import { getViewPort } from "../../../utilitycomponents/grid/gridUtilities";
import EditChartContainerModal from "./reports/reportContainer/edit/editReportContrainerModal";
import FilterModal from "./filtration/filterModal";
import {
    useDashboard,
    useAvailableDashboards,
    useLayoutUpdate,
    useDashboardDelete,
    usePartners,
    useDashboardUpdate,
    useDefaultDashboard,
    useDashboardToggleLock,
} from "controller";
import { DashboardTreeSelect, SharedDashboardTreeSelect } from "./dashboardUtils/";
import ReportCreateModal from "./reports/create/creationModal";
import DashboardCreationModal from "./dashboardUtils/modals/create/createModal";
import { ChartContainer } from "./reports/reportContainer/reportContainer";
import { useParams } from "react-router-dom";
import SaveDashboardModal from "./dashboardUtils/modals/save/saveModal";
import { useTranslation } from "react-i18next";
import "./styles/dashboard.css";
import ShareDashboardModal from "./dashboardUtils/modals/shareDashboardModal";
import { SharedDashboardsModal } from "./dashboardUtils/modals/sharedDashboardsModal";
import { usePDF, Margin } from "react-to-pdf";

interface Props {
    sharedDashboardView: boolean;
    closeDashboardModals?: any;
    partnerId: number;
}

const Dashboard: FunctionComponent<Props> = ({
    sharedDashboardView,
    closeDashboardModals = {},
    partnerId,
}) => {
    const [controlledPartnerId, setControlledPartnerId] = useState<number>(partnerId);
    const { setDefaultCompanyDashboard, setDefaultDashboard } = useDefaultDashboard();
    const [loadDefault, setLoadDefault] = useState(true);
    const { toPDF, targetRef } = usePDF({
        filename: `report.pdf`,
        page: { margin: Margin.MEDIUM },
    });
    const [exporting, setExporting] = useState(false);
    const isAdmin =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]) ===
        "administrator";
    const { presetId: paramDashboardId, companyId }: any = useParams();
    const [selectedDashboardId, setSelectedDashboardId] = useState(
        paramDashboardId ? paramDashboardId : undefined
    );
    const [dashboardCompanyId, setDashboardCompanyId] = useState(companyId ? companyId : -1);
    const [viewPort, setViewPort] = useState(getViewPort());
    const [wizardVisible, setWizardVisible] = useState(false);
    const [sendReportModal, setSendReportModal] = useState<any>({
        visible: false,
        reportData: null,
    });
    const [reloadDashboard, setReloadDashboard] = useState({ reload: false });
    const { data, loading, defaultDashboardId: defaultId } = useDashboard(
        selectedDashboardId!,
        controlledPartnerId,
        dashboardCompanyId,
        reloadDashboard,
        sharedDashboardView,
        !sharedDashboardView ? loadDefault : false
    );
    const [defaultDashboardId, setDefaultDashboardId] = useState<any>(undefined);
    const [dashboardData, setDashboardData] = useState<any>({});
    const { data: availableDashboads, loading: dashboardsLoading } = useAvailableDashboards(
        reloadDashboard
    );
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editChartModal, setEditChartModal] = useState({
        visible: false,
        reportObject: undefined,
    });
    const [filterModal, setFilterModal] = useState({
        visible: false,
        reportObject: undefined,
    });
    const [saveModal, setSaveModal] = useState({
        visible: false,
    });
    const [sharedDashboardsModal, setSharedDashboardsModal] = useState<any>({
        visible: false,
        partnerId: undefined,
        companyId: undefined,
    });

    const [reloadReport, setReloadReport] = useState({ reload: true, id: -1 });
    const { updateLayout } = useLayoutUpdate();
    const { deleteDashboard } = useDashboardDelete();
    const { updateDashboard } = useDashboardUpdate();
    const { ToggleLock, loading: togglingLock } = useDashboardToggleLock();
    const [selectedBenchmarkId, setSelectedBenchmarkId] = useState(undefined);
    const contentSectionElement = document.getElementById("contentSection");
    const centerDivHeight = contentSectionElement ? contentSectionElement!.offsetHeight - 250 : 0;
    const { t } = useTranslation();

    useLayoutEffect(() => {
        const updateSize = () => {
            setViewPort(getViewPort());
        };
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    });

    useEffect(() => {
        setControlledPartnerId(partnerId);
    }, [partnerId]);

    useEffect(() => {
        setReloadDashboard({ reload: true });
        setReloadReport({ ...reloadReport, id: -1 });
    }, [controlledPartnerId]);

    useEffect(() => {
        if (!sharedDashboardView) {
            setFilterModal({ reportObject: undefined, visible: false });
            setCreateModalVisible(false);
            setSaveModal({ visible: false });
            setSharedDashboardsModal({
                visible: false,
                partnerId: undefined,
                companyId: undefined,
            });
            setEditChartModal({ visible: false, reportObject: undefined });
        }
    }, [closeDashboardModals]);

    useLayoutEffect(() => {
        if (loading === false && data.data) {
            setDashboardData(data);
            data.data.benchmarkId
                ? setSelectedBenchmarkId(data.data.benchmarkId)
                : setSelectedBenchmarkId(undefined);
        }
        if (defaultId !== undefined && defaultId !== null && loadDefault) {
            handleGetDefaultDashboardId();
        }
    }, [loading, selectedDashboardId]);

    async function handleGetDefaultDashboardId() {
        if (!sharedDashboardView) {
            setSelectedDashboardId(defaultId);
            setDefaultDashboardId(defaultId);
            setLoadDefault(false);
        }
    }

    async function onLayoutChange(layout: any[]) {
        await updateLayout(selectedDashboardId!, layout);
    }

    function onDashboardChange(selectedDashboardId: any, selectedDashboard: any) {
        setSelectedDashboardId(selectedDashboardId);
        setDashboardCompanyId(selectedDashboard.companyId);

        if (!sharedDashboardView) {
        } else {
            setReloadReport({ ...reloadReport, id: -1 });
            setControlledPartnerId(selectedDashboard.supplierId);
        }
    }

    async function onDashboardDelete() {
        await deleteDashboard(selectedDashboardId);
        setSelectedDashboardId(undefined);
        setReloadDashboard({ reload: true });
        setReloadReport({ reload: false, id: -1 });
    }

    async function onBenchmarkChange(benchmarkId: any) {
        setSelectedBenchmarkId(benchmarkId);
        await updateDashboard(selectedDashboardId, controlledPartnerId, {}, benchmarkId);
        setReloadDashboard({ reload: true });
        setReloadReport({ reload: true, id: -1 });
    }

    async function onToggleLock(presetId: number, toggleLock: boolean) {
        await ToggleLock(presetId, toggleLock);
        setReloadDashboard({ reload: true });
    }

    function parseVisibility(visibility: string) {
        switch (visibility) {
            case "Personal":
                return 0;
            case "Internal":
                return 0;
            case "External":
                return 1;
            default:
                return 0;
        }
    }

    function handleSetDefaultDashboard() {
        setDefaultDashboard(selectedDashboardId);
        notification.success({
            message: t("success"),
            description: t("analytics_DashboardSetSuccess", "Default dashboard set"),
        });
    }

    function handleSetDefaultCompanyDashboard() {
        setDefaultCompanyDashboard(selectedDashboardId);
        notification.success({
            message: t("success"),
            description: t("analytics_DashboardSetSuccess", "Default dashboard set"),
        });
    }

    const handleExport = async () => {
        setExporting(true);
        await toPDF();
        setExporting(false);
    };

    return (
        <Fragment>
            <Flex justify="flex-start" gap={"small"} wrap="wrap">
                {!sharedDashboardView ? (
                    <DashboardTreeSelect
                        selectedKey={defaultId}
                        useData={() => {
                            return { data: availableDashboads, loading: dashboardsLoading };
                        }}
                        onChange={(selectedKeys: any, item: any) =>
                            onDashboardChange(selectedKeys[0], item)
                        }
                    />
                ) : (
                    <SharedDashboardTreeSelect
                        onChange={(selectedKeys: any, item: any) => {
                            onDashboardChange(selectedKeys[0], item);
                        }}
                    />
                )}
                {!sharedDashboardView && (
                    <Button
                        className={classes.printerButton}
                        onClick={() => setWizardVisible(true)}>
                        {t("analytics_CreateDashboard")}
                    </Button>
                )}
                {!sharedDashboardView &&
                    dashboardData &&
                    dashboardData.data &&
                    !dashboardData.data.isLocked && (
                        <Tooltip title={t("analytics_AddReportTooltip")} mouseEnterDelay={1}>
                            <Button
                                disabled={selectedDashboardId === undefined}
                                className={classes.button}
                                onClick={() => setCreateModalVisible(true)}>
                                {t("analytics_AddReport")}
                            </Button>
                        </Tooltip>
                    )}
                {!sharedDashboardView &&
                    dashboardData &&
                    dashboardData.data &&
                    !dashboardData.data.isLocked && (
                        <div>
                            <SelectData
                                allowClear
                                value={selectedBenchmarkId}
                                disabled={selectedDashboardId === undefined}
                                onChange={(val: any) => onBenchmarkChange(val)}
                                placeholder={`Benchmark`}
                                useData={usePartners}
                                selectOptionField="name"
                                style={{ width: "200px" }}
                            />
                            <Tooltip title={t("analytics_BenchmarkTooltip")}>
                                <span>
                                    <HelpCircle />
                                </span>
                            </Tooltip>
                        </div>
                    )}
                {dashboardData &&
                    dashboardData.data &&
                    dashboardData.data.userCanLock &&
                    dashboardData.data.visibility !== "Personal" &&
                    !sharedDashboardView && (
                        <div>
                            <Tooltip
                                title={`${
                                    dashboardData &&
                                    dashboardData.data &&
                                    dashboardData.data.isLocked
                                        ? t("analytics_DashboardLockedTooltip")
                                        : t("analylics_DashboardUnlockedTooltip")
                                }`}>
                                <Switch
                                    loading={togglingLock}
                                    className={classes.toggleLock}
                                    size="default"
                                    checkedChildren={
                                        <LockUnlocked01
                                            style={{ color: "#fff" }}
                                            className={classes.toggleLockIcon}
                                        />
                                    }
                                    unCheckedChildren={
                                        <Lock01
                                            style={{ color: "#fff" }}
                                            className={classes.toggleLockIcon}
                                        />
                                    }
                                    checked={
                                        dashboardData &&
                                        dashboardData.data &&
                                        !dashboardData.data.isLocked
                                    }
                                    onClick={(checked: boolean) => {
                                        onToggleLock(selectedDashboardId, !checked);
                                        setReloadDashboard({ reload: true });
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                <Dropdown
                    menu={{
                        items: [
                            !sharedDashboardView
                                ? {
                                      key: "defaultDashboard",
                                      label: (
                                          <Tooltip
                                              title={t(
                                                  defaultDashboardId &&
                                                      defaultDashboardId.toString() ===
                                                          paramDashboardId
                                                      ? "analytics_IsCurrentDefault"
                                                      : "analytics_DashboardDefaultTooltip"
                                              )}>
                                              {t("analytics_DashboardDefault")}
                                          </Tooltip>
                                      ),
                                      disabled:
                                          selectedDashboardId === undefined ||
                                          (defaultDashboardId &&
                                              defaultDashboardId.toString() === paramDashboardId),
                                      onClick: () => handleSetDefaultDashboard(),
                                  }
                                : null,
                            !sharedDashboardView && isAdmin
                                ? {
                                      key: "companyDefault",
                                      label: (
                                          <Tooltip
                                              mouseEnterDelay={1}
                                              title={t("analytics_DashboardCompanyDefaultTooltip")}>
                                              {t("analytics_DashboardCompanyDefault")}
                                          </Tooltip>
                                      ),
                                      disabled:
                                          selectedDashboardId === undefined ||
                                          (dashboardData &&
                                              dashboardData.data &&
                                              dashboardData.data.visibility === "Personal"),
                                      onClick: () => handleSetDefaultCompanyDashboard(),
                                  }
                                : null,
                            {
                                key: "export",
                                label: (
                                    <Spin size="small" spinning={exporting}>
                                        {t("exportBtnText")}
                                    </Spin>
                                ),
                                disabled: selectedDashboardId === undefined,
                                onClick: handleExport,
                            },
                            !sharedDashboardView &&
                            dashboardData &&
                            dashboardData.data &&
                            !dashboardData.data.isLocked
                                ? {
                                      key: "save",
                                      label: t("analytics_SaveDashboard"),
                                      disabled: selectedDashboardId === undefined,
                                      onClick: () => setSaveModal({ visible: true }),
                                  }
                                : null,
                            !sharedDashboardView &&
                            dashboardData &&
                            dashboardData.data &&
                            !dashboardData.data.isLocked
                                ? {
                                      key: "share",
                                      label: t("analytics_ShareDashboard"),
                                      disabled: selectedDashboardId === undefined,
                                      onClick: () =>
                                          setSendReportModal({
                                              visible: true,
                                              reportData: {
                                                  presetId: selectedDashboardId,
                                                  controlledPartnerId,
                                                  companyId,
                                              },
                                          }),
                                  }
                                : null,
                            !sharedDashboardView &&
                            dashboardData &&
                            dashboardData.data &&
                            !dashboardData.data.isLocked
                                ? {
                                      key: "sharedDashboards",
                                      label: t("analytics_Shared_SharedDashboard"),
                                      onClick: () =>
                                          setSharedDashboardsModal({
                                              visible: true,
                                              companyId: companyId,
                                              partnerId: controlledPartnerId,
                                          }),
                                  }
                                : null,
                            !sharedDashboardView &&
                            dashboardData &&
                            dashboardData.data &&
                            !dashboardData.data.isLocked
                                ? {
                                      key: "delete",
                                      label: (
                                          <Popconfirm
                                              arrow={false}
                                              okType="danger"
                                              okText={t("generic_Confirm")}
                                              cancelText={t("generic_Cancel")}
                                              title={t("analytics_DeleteDashboardConfirmation")}
                                              onConfirm={() => onDashboardDelete()}>
                                              <span style={{ color: "#FF4D4F" }}>
                                                  {t("analytics_DeleteDashboard")}
                                              </span>
                                          </Popconfirm>
                                      ),
                                      disabled: selectedDashboardId === undefined,
                                  }
                                : null,
                        ],
                    }}>
                    <a
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                        <DotsHorizontal style={{ fontSize: 22 }} />
                    </a>
                </Dropdown>
            </Flex>

            <div ref={targetRef}>
                {selectedDashboardId !== undefined || defaultId !== undefined ? (
                    <Spin spinning={loading}>
                        {dashboardData &&
                        dashboardData.data &&
                        dashboardData.data.boxes.length > 0 ? (
                            <GridLayout
                                className={classes.gridLayout}
                                onLayoutChange={(layout: any[]) => onLayoutChange(layout)}
                                layout={dashboardData && dashboardData.layout}
                                cols={24}
                                rowHeight={30}
                                width={viewPort - 209}>
                                {dashboardData &&
                                    dashboardData.data.boxes.map((box: any, index: number) => {
                                        return (
                                            <div
                                                className={classes.gridItem}
                                                id={box.i}
                                                key={box.i}>
                                                <ChartContainer
                                                    partnerId={controlledPartnerId}
                                                    benchmarkId={selectedBenchmarkId}
                                                    parentId={box.i}
                                                    onEditChartModalClick={(reportObject: any) =>
                                                        setEditChartModal({
                                                            reportObject: reportObject,
                                                            visible: true,
                                                        })
                                                    }
                                                    onFilterModalClick={(reportObject: any) =>
                                                        setFilterModal({
                                                            reportObject: reportObject,
                                                            visible: true,
                                                        })
                                                    }
                                                    reportId={box.report.id}
                                                    reportObject={box.report}
                                                    reloadReport={reloadReport}
                                                    reloadDashboard={() =>
                                                        setReloadDashboard({ reload: true })
                                                    }
                                                    editable={
                                                        !sharedDashboardView &&
                                                        dashboardData &&
                                                        dashboardData.data &&
                                                        !dashboardData.data.isLocked
                                                    }
                                                    columnNames={{
                                                        subLevelName:
                                                            box.report &&
                                                            box.report.subLeveLHeading,
                                                        targetName:
                                                            dashboardData.data &&
                                                            dashboardData.data.targetName,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                            </GridLayout>
                        ) : !sharedDashboardView &&
                          dashboardData &&
                          dashboardData.data &&
                          !dashboardData.data.isLocked ? (
                            <div
                                style={{
                                    height: centerDivHeight,
                                }}>
                                <Empty
                                    className={classes.centerAddContainer}
                                    description={t("analytics_NoReports")}>
                                    <Button
                                        disabled={selectedDashboardId === undefined}
                                        className={classes.button}
                                        onClick={() => setCreateModalVisible(true)}>
                                        {t("analytics_AddReport")}
                                    </Button>
                                </Empty>
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: centerDivHeight,
                                }}>
                                <Empty
                                    className={classes.centerAddContainer}
                                    description={t(
                                        "Looks like this dashboard contains no reports"
                                    )}></Empty>
                            </div>
                        )}
                    </Spin>
                ) : (
                    <div
                        style={{
                            height: centerDivHeight,
                        }}>
                        <Empty
                            className={classes.centerAddContainer}
                            description={t(
                                sharedDashboardView
                                    ? "analytics_Shared_NoDashboards"
                                    : "analytics_NoDashboard"
                            )}
                        />
                    </div>
                )}
                {!sharedDashboardView && (
                    <Fragment>
                        <DashboardCreationModal
                            partnerId={controlledPartnerId}
                            visible={wizardVisible}
                            closeModal={() => setWizardVisible(false)}
                            onDashboardCreated={(id: any) => {
                                setSelectedDashboardId(id);
                                setReloadDashboard({ reload: true });
                            }}
                            existingDashboards={availableDashboads}
                        />
                        <ReportCreateModal
                            dashboardVisibility={
                                dashboardData &&
                                dashboardData.data &&
                                parseVisibility(dashboardData.data.visibility)
                            }
                            visible={createModalVisible}
                            closeModal={() => setCreateModalVisible(false)}
                            reload={() => {
                                setReloadDashboard({ reload: true });
                                setReloadReport({ reload: true, id: -1 });
                            }}
                            benchmarkId={selectedBenchmarkId}
                            partnerId={controlledPartnerId}
                            presetId={selectedDashboardId}
                        />
                        <EditChartContainerModal
                            visible={editChartModal.visible}
                            closeModal={() =>
                                setEditChartModal({ reportObject: undefined, visible: false })
                            }
                            reportObject={editChartModal.reportObject}
                            onSubmitFinished={(id: number) =>
                                setReloadReport({ reload: true, id: id })
                            }
                        />
                        <FilterModal
                            partnerId={controlledPartnerId}
                            dashboardVisibility={
                                dashboardData &&
                                dashboardData.data &&
                                parseVisibility(dashboardData && dashboardData.data.visibility)
                            }
                            visible={filterModal.visible}
                            closeModal={() =>
                                setFilterModal({ reportObject: undefined, visible: false })
                            }
                            reportObject={filterModal.reportObject}
                            benchmarkId={selectedBenchmarkId}
                            reload={(id: number) => {
                                setReloadReport({ reload: true, id: id });
                            }}
                        />
                        <SaveDashboardModal
                            dashboardData={dashboardData !== undefined && dashboardData}
                            visible={saveModal.visible}
                            closeModal={() => setSaveModal({ visible: false })}
                            reloadDashboard={() => setReloadDashboard({ reload: true })}
                            benchmarkId={selectedBenchmarkId}
                            existingDashboards={availableDashboads}
                        />
                        {sendReportModal.visible && (
                            <ShareDashboardModal
                                visible={sendReportModal.visible}
                                reportData={sendReportModal.reportData}
                                onClose={() =>
                                    setSendReportModal({ visible: false, reportData: null })
                                }
                                dashboardVisibilty={dashboardData && dashboardData.data.visibility}
                            />
                        )}
                        {sharedDashboardsModal.visible && (
                            <SharedDashboardsModal
                                visible={sharedDashboardsModal.visible}
                                partnerId={controlledPartnerId}
                                companyId={sharedDashboardsModal.companyId}
                                onClose={() =>
                                    setSharedDashboardsModal({
                                        visible: false,
                                        companyId: undefined,
                                        partnerId: undefined,
                                    })
                                }
                            />
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default Dashboard;
