import { useEffect, useState } from "react";
import Provider from "services/api";

const useNcrCustomLabel = (reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            const data = await Provider.Ncr.getNcrFields({
                customError: { message: "Could not retrieve NCR fields" },
            }).catch(err => {
                setState({ loading: false, data: [] });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data: data.ncrFields,
            });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNcrCustomLabel };
