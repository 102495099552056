import React from "react";
import { Dropdown, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useCompanySupportEmail } from "controller";
import { useCompanyFaqLink } from "controller/admin/company/useCompanyFaqLink";
import { HelpCircle } from "components/utilitycomponents";
const SupportDropDown = () => {
    const { t } = useTranslation();
    const { getSupportEmail } = useCompanySupportEmail();
    const { data: faqLink } = useCompanyFaqLink();

    function companyLinkClick() {
        let faqUrl = faqLink;
        if (faqLink?.indexOf("https") === -1 && faqLink.indexOf("http") === -1) {
            faqUrl = "https://" + faqLink;
        }
        window.open(faqUrl, "_blank", "noopener,noreferrer");
    }
    async function handleSupportClick() {
        let mailAddress = await getSupportEmail();
        window.location.href = `mailto:${mailAddress}`;
    }
    const onClick: MenuProps["onClick"] = ({ key }) => {
        if (key === "1") {
            companyLinkClick();
        } else if (key === "2") {
            handleSupportClick();
        }
    };
    const items: MenuProps["items"] = [
        {
            label: t("generic_KnowledgeBase"),
            key: "1",
        },
        {
            label: t("emailBtnText"),
            key: "2",
        },
    ];
    return (
        <Dropdown menu={{ items, onClick }} placement="bottom">
            <a onClick={e => e.preventDefault()}>
                <Space>
                    <HelpCircle style={{ fontSize: 24, color: "#000000" }} />
                </Space>
            </a>
        </Dropdown>
    );
};

export default SupportDropDown;
