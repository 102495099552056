import React, { FunctionComponent } from "react";
import { Button } from "antd";

type PropsType = {
    icon?: any;
    iconColor?: string;
    loading?: boolean;
    [key: string]: any;
};
const MinimalButton: FunctionComponent<PropsType> = ({
    icon,
    iconColor,
    loading,
    children,
    ...rest
}) => {
    const buttonStyle = rest.style;
    delete rest.style;

    return (
        <Button
            loading={loading}
            style={{
                border: 0,
                boxShadow: "none",
                background: "none",
                height: "auto",
                ...buttonStyle,
            }}
            {...rest}>
            {icon && (
                <span
                    style={{
                        ...(!rest.disabled && iconColor && { color: iconColor }),
                    }}>
                    {icon}
                </span>
            )}
            {children}
        </Button>
    );
};

export { MinimalButton };
