import React from "react";
import { FilterFilled } from "@ant-design/icons";

const getFilterIcon = (params: any = { dataIndex: Math.floor(Math.random() * 100) }) => ({
    filterIcon: (filtered: boolean) => (
        <div data-test={`filter_${params.dataIndex}`} className="filterIconContainer">
            <FilterFilled />
        </div>
    ),
});
export default getFilterIcon;
