import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import classNames from "classnames";
import classes from "./styles/customIcon.module.css";
type CustomIconComponentProps = GetProps<typeof Icon>;

function Expand01(props: Partial<GetProps<typeof Icon>>) {
    const svg = () => (
        <svg
            className={classNames(classes.customIconDefaultStyle, props.className)}
            style={{ height: props.style?.fontSize, width: props.style?.fontSize }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15"
                style={{ stroke: props.style?.color }}
            />
        </svg>
    );

    const Expand01Icon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props} />
    );
    return <Expand01Icon {...props} />;
}

export default Expand01;
