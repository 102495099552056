import React, { FunctionComponent, useState } from "react";
import { Card, Row, Col, Input, Button } from "antd";
import { Star, X } from "components/utilitycomponents";
import classes from "./styles/ncrActions.module.scss";
import { useNcrCreateReview } from "controller";
import { useTranslation } from "react-i18next";
const TextArea = Input.TextArea;

type PropsType = {
    data: any;
    onClose: Function;
    reloadTable: Function;
};

export const CloseNcr: FunctionComponent<PropsType> = ({ data, onClose, reloadTable }) => {
    const [starValue, setStarValue] = useState(undefined);
    const [comment, setComment] = useState(undefined);
    const { loading, createReview } = useNcrCreateReview();
    const { t } = useTranslation();
    async function onSubmit() {
        await createReview({
            ncrId: data.id,
            review: {
                partnerId: data.partnerId,
                reviewedPartnerName: data.partnerName,
                notes: comment!,
                rating: starValue!,
                reviewCategoryId: 2,
                visibility: data.visibility,
            },
        });
        onClose();
        reloadTable();
    }

    function onStarChange(val: any) {
        if (val !== 0) {
            setStarValue(val);
        }
    }
    return (
        <Card
            data-test="create_review"
            className={classes.reviewCard}
            title={t("createReview")}
            extra={
                <span>
                    <X onClick={() => onClose()} />
                </span>
            }>
            <Row className={classes.reviewRow}>
                <Col span={4}>
                    <span className={classes.reviewLabel}>{`${t("supplier")}: `}</span>
                </Col>
                <Col span={20}>{data.partnerName}</Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={4}>
                    <span className={classes.reviewLabel}>{`${t("reviewCriteria")}: `}</span>
                </Col>
                <Col span={20}>{t("ncrHandling")}</Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={24}>
                    <span className={classes.reviewLabel}>{`${t("rateExperience")}: `}</span>
                </Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={24}>
                    <Star
                        data-test="ncr_close_rating"
                        value={starValue}
                        onChange={(val: any) => onStarChange(val)}
                    />
                </Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={24}>
                    <span className={classes.reviewLabel}>{`${t("comment")}: `}</span>
                </Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={24}>
                    <TextArea
                        data-test="review_comment"
                        maxLength={1000}
                        value={comment}
                        onChange={(e: any) => setComment(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={4}>
                    <span className={classes.reviewLabel}>{`Visibility: `}</span>
                </Col>
                <Col span={20}>{data.visibility}</Col>
            </Row>
            <Row className={classes.reviewRow}>
                <Col span={4}>
                    <Button
                        data-test="close_ncr_save_review_button"
                        loading={loading}
                        type="primary"
                        disabled={
                            starValue === undefined && (comment === undefined || comment === "")
                        }
                        className={classes.greenActionButton}
                        onClick={() => onSubmit()}>
                        {t("saveReviewNow")}
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default CloseNcr;
