import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userManager from "userManager";
import { AppState, store } from "store";
import { Spin } from "antd";
import { getGuestTokenFromPathName, isGuestAuthorizedPath } from "utilities";

const Login = () => {
    const { location } = useHistory();
    const isLoadingUser = useSelector((state: AppState) => state.oidc.isLoadingUser);
    //when refreshing the page don't go through login process
    if (isLoadingUser) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    // @ts-ignore
    if (window.Cypress) {
        //@ts-ignore
        window.store = store;
        return null;
    }

    let guestToken = undefined;
    let pathName = location.pathname;
    if (isGuestAuthorizedPath(pathName)) {
        guestToken = getGuestTokenFromPathName(pathName);
    }

    if (window.location.href.indexOf("#") !== -1) {
        pathName = window.location.href.substr(window.location.href.indexOf("#") + 1);
    }

    userManager.signinRedirect({
        data: { path: pathName },
        ...(guestToken !== undefined && {
            extraQueryParams: {
                guest_token: guestToken,
            },
        }),
    });

    return null;
};

export default Login;
