import { Col, Progress, Row, Tag } from "antd";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatTime } from "utilities";
import classes from "./surveyResults.module.scss";

export interface ISurveyCustomFields {
    endDate: string;
    isExpired: boolean;
    currentResponseCount: number;
    maxResponseCount: number;
    completionAverage: number;
}

const SurveyStats = ({ stats }: { stats: ISurveyCustomFields | undefined }) => {
    const { t } = useTranslation();

    const getStrokeColor = (val: number, maxNumber: number) => {
        let percentage = (val / maxNumber) * 100;
        if (percentage >= 100) {
            return {
                "0%": "#52c41a",
                "100%": "#52c41a",
            };
        } else if (percentage < 100 && percentage >= 75) {
            return {
                "0%": "#ABC41D",
                "100%": "#ABC41D",
            };
        } else if (percentage < 75 && percentage >= 33) {
            return {
                "0%": "#fa8c16",
                "100%": "#fa8c16",
            };
        } else {
            return {
                "0%": "#f5222d",
                "100%": "#f5222d",
            };
        }
    };

    return (
        <div className={classes.titleBackground}>
            <Row style={{ paddingTop: 30 }}></Row>
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <span className={classes.surveyEndDate}>
                                <span className={classes.title}>{t("generic_Status")}:</span>{" "}
                                {stats && (!stats.isExpired || !stats.endDate) ? (
                                    <span className={classes.active}>{t("generic_Active")}</span>
                                ) : (
                                    <span className={classes.ended}>{t("survey_Ended")}</span>
                                )}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <span>
                                <span className={classes.title}>End Date:</span>{" "}
                                {stats && stats.endDate ? (
                                    <Tag
                                        className={classnames(
                                            stats.isExpired ? classes.endedTag : classes.activeTag,
                                            classes.tag
                                        )}>
                                        {formatTime(stats.endDate, "MMM D, YYYY")}
                                    </Tag>
                                ) : (
                                    <b>N/A</b>
                                )}
                            </span>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <div className={classes.titleGap}></div>
                            <div className={classes.surveyEndDate}>
                                <span className={classes.title}>{t("survey_NoOfResponse")}:</span>{" "}
                                <span>
                                    {stats && stats.currentResponseCount && (
                                        <Progress
                                            style={{ width: 97 }}
                                            success={{ percent: 0 }}
                                            size="small"
                                            width={100}
                                            percent={
                                                (stats!.currentResponseCount /
                                                    stats!.maxResponseCount) *
                                                100
                                            }
                                            format={val => (
                                                <span>{`${stats!.currentResponseCount} / ${
                                                    stats!.maxResponseCount
                                                }`}</span>
                                            )}
                                            strokeWidth={20}
                                            strokeColor={getStrokeColor(
                                                stats!.currentResponseCount,
                                                stats!.maxResponseCount
                                            )}
                                        />
                                    )}
                                </span>
                                <div className={classes.title}>
                                    {t("survey_ResponseScore")}:{" "}
                                    <span>
                                        {stats &&
                                            stats.completionAverage &&
                                            `${
                                                stats.completionAverage > 100
                                                    ? 100
                                                    : stats.completionAverage
                                            }`}
                                        %
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export { SurveyStats };
