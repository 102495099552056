import { useProductFeature } from "controller/company";
import { IProduct } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";
import { groupBy } from "lodash";

type StateType = {
    loading: boolean;
    data: IProduct[];
};

const useProductsByPartner = (
    partnerId: any,
    guestMode: boolean = false,
    groupByCategory: boolean = false
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    const { hasProductFeature } = useProductFeature();

    useEffect(() => {
        (async () => {
            if ((partnerId && hasProductFeature) || (partnerId && guestMode)) {
                setState({ ...state, loading: true });
                let data: any = await Provider.Product.getProductsByPartnerId(partnerId, {
                    customError: {
                        message: "Could not retrieve products",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                if (groupByCategory) {
                    let groupedData = groupBy(data, "commodityCategory");
                    let groupedArray: any[] = [];
                    Object.keys(groupedData).forEach((key: string) =>
                        groupedArray.push({
                            name: key,
                            id: `group-${key}`,
                            children: groupedData[key],
                        })
                    );
                    data = groupedArray;
                }

                setState({ loading: false, data });
            }
        })();
    }, [partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useProductsByPartner;
