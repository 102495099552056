import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal, Form, Row, Col, DatePicker, Input, Divider, notification, Button } from "antd";
import { useTranslation } from "react-i18next";
import {
    AttachFile,
    MinusCircle,
    RichTextEditor,
    SelectData,
    SelectExternalPartner,
    SelectPartnerContacts,
    SelectTreeDataMultiple,
} from "components/utilitycomponents";
import {
    useMeetingMinutesCRUD,
    useMeetingMinuteTypes,
    useCompanyType,
    useContactsWithGroups,
    useInternalAndExternalContacts,
} from "controller";
import { Modules } from "models";
import classes from "./meetingCalendar.module.scss";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { CreateModal as CreateContactModal } from "../../../../../contacts/shared/createModal";
import { TagRender } from "../shared/tagRender";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { Moment } from "moment";
import dayjs from "utilities/daysJsConfig";
const MomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

interface IProps {
    visible: boolean;
    onClose: Function;
    partnerId: any;
    [key: string]: any;
}

const CreateModal: FunctionComponent<IProps> = ({ visible, onClose, partnerId, ...rest }) => {
    const [reloadContacts, setReloadContacts] = useState<any>({ reload: false });
    const { data: companyContacts, loading: loadingCompanyContacts } = useContactsWithGroups(
        ["Administrator", "User", "LightUser"],
        Modules.Partner,
        reloadContacts
    );
    const {
        data: internalAndExternalContacts,
        loading: gettingContacts,
    } = useInternalAndExternalContacts(partnerId, reloadContacts);
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { createMeetingMinute, loading } = useMeetingMinutesCRUD();
    const { data: meetingTypes, loading: loadingTypes } = useMeetingMinuteTypes();
    const { isDefaultCompany } = useCompanyType();
    const [contactModal, setContactModal] = useState<any>({
        visible: false,
    });
    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    const onSaveClick = async () => {
        form.validateFields()
            .then(async values => {
                let input = {
                    partnerId: values.partner ? values.partner : partnerId,
                    title: values.title,
                    description: values.description,
                    date: values.datetime,
                    typeId: values.type,
                    outcome: values.outcome,
                    internalRepresentatives: values.internalReps,
                    externalRepresentatives: values.externalReps,
                    fileIds: values.files,
                    actions: values.actions,
                };
                await createMeetingMinute(input);
                onClose(true);
                notification.success({
                    message: "Success",
                    description: `${t("governance_MeetingMinute")} ${t("Created")}`,
                });
            })
            .catch(err => {
                return;
            });
    };

    return (
        <Modal
            width={900}
            title={t("governance_AddMeeting")}
            open={visible}
            onOk={async () => await onSaveClick()}
            cancelText={t("generic_Cancel")}
            onCancel={() => onClose()}
            footer={[
                <Button loading={loading} type="primary" onClick={async () => await onSaveClick()}>
                    {t("generic_Create")}
                </Button>,
            ]}
            {...rest}>
            <div
                style={{ background: "#fff", position: "relative" }}
                id="meetingCalendarCreate"></div>
            <Form form={form} layout="vertical">
                {partnerId === undefined && (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={
                                    isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer")
                                }
                                name="partner"
                                rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                                <SelectExternalPartner
                                    placeholder={t("compliance_SelectPartnerPlaceholder")}
                                    showCreationButton={false}
                                    withEmail={false}
                                    withIsLinked={false}
                                    withContacts={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={t("governance_Type")}
                            name="type"
                            required
                            rules={[{ required: true }]}
                            initialValue={meetingTypes[0] && meetingTypes[0].id}>
                            <SelectData
                                useData={() => {
                                    return { data: meetingTypes, loading: loadingTypes };
                                }}
                                selectOptionField="name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("generic_Date")}
                            name="datetime"
                            required
                            rules={[{ required: true }]}>
                            <MomentDatePicker
                                style={{ width: "100%" }}
                                showTime={{ format: "HH:mm" }}
                                minuteStep={15 as any}
                                format="MMM D, YYYY (HH:mm)"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label={t("generic_Title")}
                            name="title"
                            required
                            rules={[{ required: true }]}>
                            <Input placeholder={t("generic_Placeholder-Text")} maxLength={255} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={t("generic_Description")} name="description">
                            <RichTextEditor
                                placeholder={t("generic_Placeholder-Text")}
                                maxLength={10000}
                                bordered
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={
                                <Row>
                                    <Col span={24}>{t("governance_Outcome")}</Col>
                                </Row>
                            }
                            name="outcome"
                            initialValue={undefined}>
                            <RichTextEditor placeholder={t("Outcome")} maxLength={10000} bordered />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left" orientationMargin={0}>
                    {t("governance_Attendants")}
                </Divider>
                <Row gutter={8}>
                    <Col span={12}>
                        <span className={classes.representativeLabel}>
                            {t("meetingForum_CompanyMember", {
                                name: companyName,
                                interpolation: { escapeValue: false },
                            })}
                        </span>
                        <div className={classes.box}>
                            <Form.Item
                                wrapperCol={{ span: 22 }}
                                colon={false}
                                name="internalReps"
                                initialValue={[]}>
                                <SelectTreeDataMultiple
                                    style={{ width: "100%" }}
                                    bordered={false}
                                    allowClear={true}
                                    useData={() => ({
                                        data: companyContacts,
                                        loading: loadingCompanyContacts,
                                    })}
                                    selectAll
                                    selectOptionField="name"
                                    placeholder={t("select")}
                                    getPopupContainer={(node: any) =>
                                        document.getElementById("meetingCalendarCreate")
                                    }
                                    selectOnlyChildren
                                    tagRender={(props: any) => TagRender(props, true)}
                                    dropdownRender={(menu: any) => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: "8px 0" }} />
                                            <Button
                                                block
                                                size="small"
                                                onClick={() =>
                                                    setContactModal({
                                                        visible: true,
                                                        partnerId: undefined,
                                                        type: "companyContacts",
                                                    })
                                                }>
                                                + {t("Contacts_Add")}
                                            </Button>
                                        </>
                                    )}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <span className={classes.representativeLabel}>
                            {t("meetingForum_SupplierMember")}
                        </span>
                        <div className={classes.box}>
                            <Form.Item
                                wrapperCol={{ span: 22 }}
                                name="externalReps"
                                initialValue={[]}>
                                <SelectPartnerContacts
                                    partnerId={partnerId}
                                    bordered={false}
                                    includeChildSupplierContacts={true}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Divider children={t("actions_Actions")} orientationMargin={0} orientation="left" />
                <Row>
                    <Col span={6}>{t("generic_Title")}</Col>
                    <Col span={6}>{t("generic_Description")}</Col>
                    <Col span={5}>{t("generic_Deadline")}</Col>
                    <Col span={6}>{t("action_AssignedTo")}</Col>
                    <Col span={1}></Col>
                    <Divider style={{ marginTop: 4, marginBottom: 12 }} />
                </Row>
                <Form.List name="actions">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row gutter={8}>
                                    <Col span={6}>
                                        <Form.Item
                                            name={[name, "title"]}
                                            initialValue={undefined}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <Input
                                                autoFocus
                                                placeholder={t("generic_Placeholder-Text")}
                                                className={classes.inputClass}
                                                maxLength={500}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name={[name, "description"]}
                                            initialValue={undefined}>
                                            <Input
                                                placeholder={t("generic_Placeholder-Text")}
                                                className={classes.inputClass}
                                                maxLength={1000}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name={[name, "dueDate"]}
                                            initialValue={undefined}>
                                            <DatePicker
                                                format={"MMM D, YYYY "}
                                                className={classes.inputClass}
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name={[name, "ownerId"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("inputIsRequiredError"),
                                                },
                                            ]}>
                                            <SelectData
                                                useData={() => ({
                                                    data: internalAndExternalContacts,
                                                    loading: gettingContacts,
                                                })}
                                                selectOptionField="name"
                                                groupNames={[
                                                    t("action_Internal-Contacts"),
                                                    t("action_External-Contacts"),
                                                ]}
                                                groupFilter={(item: any, groupName: string) => {
                                                    if (groupName === t("action_Internal-Contacts"))
                                                        return item.externalUser === false;
                                                    if (groupName === t("action_External-Contacts"))
                                                        return item.externalUser === true;
                                                }}
                                                placeholder={t("select")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <MinusCircle
                                        style={{ marginBottom: 26 }}
                                        onClick={() => remove(name)}
                                    />
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    disabled={fields.length >= 10}
                                    className={classes.addActionButton}
                                    onClick={() => add()}>
                                    {t("developmentPlan_AddAction")}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider></Divider>
                <Row>
                    <Col span={24}>
                        <Form.Item label={t("attachFile")} name="files">
                            <AttachFile
                                attachedFileList={[]}
                                maxFileSize={40}
                                showMaxFileSize={true}
                                multiple={true}
                                uploadSectionLayout={{ style: { display: "table" } }}
                                uploadButtonLayout={{
                                    style: { display: "table-cell" },
                                }}
                                filesSectionLayout={{
                                    style: {
                                        display: "table-cell",
                                        width: "100%",
                                        padding: "0 10px",
                                    },
                                }}>
                                {t("generic_SelectFile")}
                            </AttachFile>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {contactModal.visible && (
                <CreateContactModal
                    visible={contactModal.visible}
                    onClose={(reload, contactId) => {
                        setReloadContacts({ reload: true });

                        if (contactModal.type === "companyContacts") {
                            form.setFieldsValue({
                                internalReps: [...form.getFieldValue("internalReps"), contactId],
                            });
                        }
                        if (contactModal.type === "supplierContacts") {
                            form.setFieldsValue({
                                externalReps: [...form.getFieldValue("externalReps"), contactId],
                            });
                        }
                        setContactModal({ visible: false, partnerId: undefined, type: undefined });
                    }}
                    partnerId={contactModal.partnerId}
                    showSupplierSelect={false}
                />
            )}
        </Modal>
    );
};

export { CreateModal };
