import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PartnerLink } from "../partnerLink";
import { RightCircleFilled } from "@ant-design/icons";
import { ISelfAssessmentNotificationDetail } from "models";
import { getDomainUrl } from "utilities";
import classes from "./styles/shared.module.scss";

type PropsType = {
    data: any;
};

const SelfAssessmentSent: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: ISelfAssessmentNotificationDetail) => {
        let from = (
            <PartnerLink partnerId={data.fromOrOnPartnerId} partnerName={data.fromOrOnPartner} />
        );

        return (
            <Fragment>
                {data.creator} {t("from")} {from} {t("notification_SelfAssessmentRequest")}
            </Fragment>
        );
    };
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>{renderDoneOnPartner(data)}</div>
            <a
                className={classes.notificationLink}
                href={`${getDomainUrl()}/SelfAssessment/${data.token}`}>
                <RightCircleFilled />
                View SelfAssessment
            </a>
        </span>
    );
};

export { SelfAssessmentSent };
