import React, { FunctionComponent } from "react";
import {
    SelectDataMultiple,
    SelectData,
    SelectTreeDataMultiple,
} from "components/utilitycomponents";
import {
    useContacts,
    usePartnerCategories,
    usePartnerGroups,
    useAuthorizedTemplates,
    useComplianceStatus,
    usePartnerFields,
} from "controller";
import classes from "./styles/sideFilter.module.scss";
import { useTranslation } from "react-i18next";
import { IColumnFilter } from "components/utilitycomponents/grid/models";
import { handleCustomFilterChange } from "components/utilitycomponents/grid/customFilterUtilities";
import { categoryType } from "../../../../models";
import { Switch } from "antd";

type PropsType = {
    onCustomFiltersChange?: Function;
    customFilters?: IColumnFilter[];
};

const SideFilter: FunctionComponent<PropsType> = ({
    onCustomFiltersChange = null,
    customFilters = [],
}) => {
    const { t } = useTranslation();
    const { data: headLineTitle } = usePartnerFields();
    const handleChange = (name: string, value: any, type: string) => {
        handleCustomFilterChange({
            name,
            value,
            type,
            customFilters,
            onCustomFiltersChange,
        });
    };
    function getCustomLabel(customHeadlines: string) {
        if (headLineTitle) {
            var headLine = headLineTitle.headlines.filter(
                (f: any) => f.name === customHeadlines
            )[0];
            return headLine === undefined ? "" : headLine.customLabel;
        }
    }

    let Status: number | undefined = undefined,
        Owner: number[] = [],
        PartnerCategory1: number[] = [],
        PartnerCategory2: number[] = [],
        PartnerGroup: number[] = [],
        Template: number[] = [];
    for (let customFilter of customFilters) {
        let value = customFilter.value.split(",").map(v => Number(v));
        switch (customFilter.name) {
            case "Status":
                Status = Number(customFilter.value);
                break;
            case "Owner":
                Owner = value;
                break;
            case "PartnerCategory1":
                PartnerCategory1 = value;
                break;
            case "PartnerCategory2":
                PartnerCategory2 = value;
                break;
            case "PartnerGroup":
                PartnerGroup = value;
                break;
            case "Template":
                Template = value;
                break;
        }
    }
    let IncludeChildren = customFilters.find(f => f.name === "IncludeChildren")?.value === "true";
    return (
        <>
            <div className={classes.label}>{t("compliance_SelectStatus")}</div>
            <SelectData
                style={{ width: "100%", marginBottom: "10px" }}
                value={Status}
                onChange={(value: any) => {
                    handleChange("Status", value, "string");
                }}
                placeholder={t("compliance_NotSelected")}
                selectOptionField="name"
                useData={useComplianceStatus}
                allowClear
            />
            <div className={classes.label}>
                {getCustomLabel("Owner") ?? t("compliance_SelectOwners")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("Owner", value, "array");
                }}
                value={Owner}
                useData={useContacts}
                selectIdField="id"
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                maxTagCount={3}
                allowClear
                selectAll
            />
            <div className={classes.label}>
                {getCustomLabel("Category1") ?? t("compliance_SelectCategory2")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("PartnerCategory1", value, "array");
                }}
                value={PartnerCategory1}
                useData={usePartnerCategories.bind(null, categoryType.one)}
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                maxTagCount={3}
                allowClear
                selectAll
            />
            <div className={classes.label}>
                {getCustomLabel("Category2") ?? t("compliance_SelectCategory2")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("PartnerCategory2", value, "array");
                }}
                value={PartnerCategory2}
                useData={usePartnerCategories.bind(null, categoryType.two)}
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                maxTagCount={3}
                allowClear
                selectAll
            />
            <div className={classes.label}>
                {getCustomLabel("Group") ?? t("compliance_SelectGroup")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("PartnerGroup", value, "array");
                }}
                value={PartnerGroup}
                useData={usePartnerGroups}
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                maxTagCount={3}
                allowClear
                selectAll
            />
            <div className={classes.label}>{t("compliance_SelectTemplate")}</div>
            <SelectTreeDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("Template", value, "array");
                }}
                value={Template}
                useData={useAuthorizedTemplates}
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                maxTagCount={3}
                selectOnlyChildren
                allowClear
            />

            <div className={classes.label}>{t("compliance_IncludeSupplierChildren")}</div>
            <Switch
                checked={IncludeChildren}
                onChange={(value: any) => handleChange("IncludeChildren", value, "bool")}></Switch>
        </>
    );
};

export default SideFilter;
