import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data:
        | {
              companyName: any;
              companyNumber: any;
              owner: any;
              group: any;
              category1: any;
              category2: any;
              value: any;
              status: any;
              pocFirstName: any;
              pocLastName: any;
              pocPhone: any;
              pocCountry: any;
              emailAddress: any;
              domain: any;
          }
        | undefined;
};

const useMandatoryPartnerFields = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let response = await Provider.Company.getCompanyHeadlines().catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            response.typeValue.map((x: any) => (x.isMandatory = x.visibility === 2));

            let data = {
                companyName: response.typeValue.find((x: any) => x.name === "CompanyName"),
                companyNumber: response.typeValue.find((x: any) => x.name === "CompanyNumber"),
                owner: response.typeValue.find((x: any) => x.name === "Owner"),
                group: response.typeValue.find((x: any) => x.name === "Group"),
                category1: response.typeValue.find((x: any) => x.name === "Category1"),
                category2: response.typeValue.find((x: any) => x.name === "Category2"),
                value: response.typeValue.find((x: any) => x.name === "Value"),
                status: response.typeValue.find((x: any) => x.name === "Status"),
                pocFirstName: response.typeValue.find((x: any) => x.name === "POCFirstName"),
                pocLastName: response.typeValue.find((x: any) => x.name === "POCLastName"),
                pocPhone: response.typeValue.find((x: any) => x.name === "POCPhone"),
                pocCountry: response.typeValue.find((x: any) => x.name === "POCCountry"),
                emailAddress: response.typeValue.find((x: any) => x.name === "EmailAddress"),
                domain: response.typeValue.find((x: any) => x.name === "Domain"),
            };

            setState({
                loading: false,
                data: data,
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMandatoryPartnerFields };
