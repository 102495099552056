import { useState } from "react";
import { Provider } from "services";

const useMatrixSATypeUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (token: string, flag: boolean) => {
        setLoading(true);

        await Provider.Compliance.updateSAType(token, flag, {
            customError: { message: "Could not update Self Assessment type" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);
    };

    return {
        loading,
        update,
    };
};

export { useMatrixSATypeUpdate };
