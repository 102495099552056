import React, { FunctionComponent, useState, useEffect, Fragment } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useCompanyType, useNcrRegistration } from "controller";
import {
    LoadingIndicator,
    UserImage,
    FollowActivity,
    ChildActivities,
    ArrowsRight,
} from "components/utilitycomponents";
import NCRHeader from "./ncrHeader";
import NCRDetails from "./ncrDetail";
import NCRInteractables from "./NCRInteractables";
import EditModal from "../edit/editModal";
import { getCustomLabel } from "../../getCustomLabel";
import { getCustomLabelVisiblity } from "../../getCustomLabelVisibility";
import { useTranslation, Trans } from "react-i18next";
import { IActivityChild } from "models";
import NCRActions from "./ncrActions";
import { default as CreateActionModal } from "components/contentcomponents/action/registration/registrationCreateModal";
import { IActionType } from "models/action";

type PropsType = {
    visible: boolean;
    id: any;
    onVisibleChange: Function;
    reloadTable?: Function;
    onAddActivity?: (parentActivityId: number, activity: IActivityChild) => void; //coming from homepage
    onRemoveActivity?: (parentActivityId: number, activityId: number) => void; //coming from homepage
    onChangeFollowActivity?: (activityId: number, isFollowed: boolean) => void; //coming from homepage
};

const RegistrationModal: FunctionComponent<PropsType> = ({
    visible,
    id,
    onVisibleChange,
    reloadTable,
    onAddActivity,
    onRemoveActivity,
    onChangeFollowActivity,
}) => {
    const [reload, setReload] = useState({ reload: false });
    const { loading, data, addActivity, removeActivity } = useNcrRegistration(id, reload);
    const [ncrFields, setNcrFields] = useState<any[]>([]);
    const [editModal, setEditModal] = useState({
        id: -1,
        editModalVisible: false,
    });
    const [actionModal, setActionModal] = useState<any>({
        visible: false,
        registrationId: undefined,
        supplierId: undefined,
    });
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        if (data !== null) {
            if (data.customFields) {
                setNcrFields(data.customFields.ncrFields);
            }
        }
    }, [data]);

    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        addActivity(parentActivityId, activity);
        onAddActivity && onAddActivity(parentActivityId, activity);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        removeActivity(parentActivityId, activityId);
        onRemoveActivity && onRemoveActivity(parentActivityId, activityId);
    };

    if (!loading && (data === null || data === "")) return null;

    let {
        holderLabel,
        purchaseOrderNumberLabel,
        valueLabel,
        otherCostsLabel,
        commentLabel,
        salesOrderNumberLabel,
        deliveryNoteLabel,
        approvalStatusLabel,
        otherCostsReasonLabel,
        deviationsLabel,
        deviationsProductNumberLabel,
        deviationsDescriptLabel,
        deviationsOrderedLabel,
        deviationsDeliveredLabel,
        deviationsFaultyLabel,
        nextResponsibleLabel,
    } = getCustomLabel(ncrFields);

    let {
        purchaseOrderNumberVisiblity,
        valueVisiblity,
        otherCostsVisiblity,
        commentVisiblity,
        salesOrderNumberVisiblity,
        deliveryNoteVisiblity,
        otherCostsReasonVisiblity,
        deviationsVisiblity,
        deviationsProductNumberVisiblity,
        deviationsDescriptVisiblity,
        deviationsOrderedVisiblity,
        deviationsDeliveredVisiblity,
        deviationsFaultyVisiblity,
    } = getCustomLabelVisiblity(ncrFields);

    return (
        <Fragment>
            {data && (
                <Modal
                    wrapProps={{ "data-test": "ncr_registration_modal" }}
                    title={
                        data !== null ? (
                            <Row>
                                <Col
                                    span={16}
                                    style={{
                                        lineHeight: "32px",
                                    }}>
                                    <Trans
                                        i18nKey="ncrModalTitle"
                                        values={{
                                            id: data.alternateId
                                                ? `${data.alternateId} (${data.id})`
                                                : data.id,
                                            ncrReason: data.ncrReason,
                                        }}>
                                        {t("ncrModalTitle")}
                                    </Trans>
                                </Col>
                                <Col span={7} style={{ textAlign: "right" }}>
                                    {isDefaultCompany && (
                                        <Button
                                            style={{ marginRight: 4 }}
                                            size="small"
                                            onClick={() =>
                                                setActionModal({
                                                    visible: true,
                                                    registrationId: data.id,
                                                    supplierId: data.partnerId,
                                                })
                                            }>
                                            <ArrowsRight />
                                            {t("action_CreateAction")}
                                        </Button>
                                    )}
                                    <FollowActivity
                                        isFollowed={data.isFollow}
                                        activityId={data.activityId}
                                        onChangeFollowActivity={onChangeFollowActivity}
                                    />
                                </Col>
                            </Row>
                        ) : null
                    }
                    open={visible}
                    footer={null}
                    onCancel={() => onVisibleChange()}
                    width={900}
                    style={{ top: 20 }}>
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <LoadingIndicator style={{ fontSize: "18px" }} />
                        </div>
                    )}
                    {data === null && null}
                    {data !== null && (
                        <Row gutter={8}>
                            <Col span={2}>
                                <UserImage
                                    contactId={data.createdByContactId}
                                    alt={data.creator}
                                    style={{ width: "100%" }}
                                    fileId={
                                        "creatorProfilePictureId" in data &&
                                        data.creatorProfilePictureId !== null
                                            ? data.creatorProfilePictureId
                                            : undefined
                                    }
                                    customDimension={65}
                                />
                            </Col>
                            <Col span={21}>
                                <NCRHeader data={data} />
                                <div style={{ marginTop: "50px" }}>
                                    <NCRDetails
                                        data={data}
                                        ncrValueLabel={valueLabel!}
                                        otherCostsLabel={otherCostsLabel!}
                                        OtherCostsReasonLabel={otherCostsReasonLabel!}
                                        purchaseOrderNumberLabel={purchaseOrderNumberLabel!}
                                        salesOrderNumberLabel={salesOrderNumberLabel!}
                                        deliveryNoteLabel={deliveryNoteLabel!}
                                        commentLabel={commentLabel!}
                                        ncrValueVisibility={valueVisiblity}
                                        otherCostsVisibility={otherCostsVisiblity}
                                        otherCostsReasonVisibilty={otherCostsReasonVisiblity}
                                        purchaseOrderNumberVisibility={purchaseOrderNumberVisiblity}
                                        salesOrderNumberVisibility={salesOrderNumberVisiblity}
                                        deliveryNoteVisibility={deliveryNoteVisiblity}
                                        commentVisibility={commentVisiblity}
                                        deviationsVisibility={deviationsVisiblity}
                                        deviationsLabel={deviationsLabel}
                                        deviationsProductNumberLabel={deviationsProductNumberLabel}
                                        deviationsProductNumberVisiblity={
                                            deviationsProductNumberVisiblity
                                        }
                                        deviationsDescriptLabel={deviationsDescriptLabel}
                                        deviationsDescriptVisiblity={deviationsDescriptVisiblity}
                                        deviationsOrderedLabel={deviationsOrderedLabel}
                                        deviationsOrderedVisiblity={deviationsOrderedVisiblity}
                                        deviationsDeliveredLabel={deviationsDeliveredLabel}
                                        deviationsDeliveredVisiblity={deviationsDeliveredVisiblity}
                                        deviationsFaultyLabel={deviationsFaultyLabel}
                                        deviationsFaultyVisiblity={deviationsFaultyVisiblity}
                                    />
                                </div>
                                {data.ncrActions.length > 0 && (
                                    <NCRActions
                                        ncr={data}
                                        reloadTable={() => {
                                            if (reloadTable) reloadTable();
                                            setReload({ reload: true });
                                        }}
                                    />
                                )}
                                <div style={{ marginTop: "16px" }}>
                                    <NCRInteractables
                                        data={data}
                                        ncrHolderLabel={holderLabel!}
                                        approvalStatusLabel={approvalStatusLabel!}
                                        nextResponsibleLabel={nextResponsibleLabel!}
                                        reloadTable={() => {
                                            if (reloadTable) reloadTable();
                                            setReload({ reload: true });
                                        }}
                                        openEditModal={() =>
                                            setEditModal({
                                                id,
                                                editModalVisible: true,
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <ChildActivities
                                        data={data.childrenActivity}
                                        parentActivityId={data.activityId}
                                        onRemoveActivity={handleRemoveActivity}
                                        onAddActivity={handleAddActivity}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                </Modal>
            )}
            <EditModal
                id={id}
                visible={editModal.editModalVisible}
                onVisibleChange={() =>
                    setEditModal({
                        ...editModal,
                        editModalVisible: false,
                    })
                }
                reloadTable={() => reloadTable && reloadTable()}
                reloadRegistrationModal={() => setReload({ reload: true })}
            />
            <CreateActionModal
                visible={actionModal.visible}
                registrationId={actionModal.registrationId}
                supplierId={actionModal.supplierId}
                registrationType={IActionType.NCR}
                onClose={() =>
                    setActionModal({
                        visible: false,
                        supplierId: undefined,
                        registrationId: undefined,
                    })
                }
            />
        </Fragment>
    );
};

export default RegistrationModal;
