import React, { Fragment, FunctionComponent } from "react";
import { Row, Col, Popconfirm, Menu, Dropdown } from "antd";
import { DotsHorizontal, MinimalButton } from "components/utilitycomponents";
import classes from "./styles/chartHeader.module.scss";
import { useReportDelete } from "controller";
import { useTranslation } from "react-i18next";

interface IProps {
    reportObject: any;
    onEditChartModalClick: Function;
    onFilterModalClick: Function;
    reloadDashboard: Function;
    parentId: any;
    editable: boolean;
}

export const ReportHeader: FunctionComponent<IProps> = ({
    reportObject,
    onEditChartModalClick,
    onFilterModalClick,
    reloadDashboard,
    parentId,
    editable,
}) => {
    const { deleteReport } = useReportDelete();
    const { t } = useTranslation();

    async function onDeleteClick() {
        await deleteReport(parentId);
        reloadDashboard();
    }

    const actionsMenu = (
        <Menu>
            <Menu.Item
                key="2"
                onClick={(e: any) => {
                    onFilterModalClick(reportObject);
                }}>
                {t("analytics_FiltrationButton")}
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={(e: any) => {
                    onEditChartModalClick(reportObject);
                }}>
                {t("analytics_TitleButton")}
            </Menu.Item>
            <Menu.Item key="3">
                <Popconfirm
                    okType="danger"
                    title={t("analytics_DeleteReportButtonTooltip")}
                    okText={t("generic_Confirm")}
                    cancelText={t("generic_Cancel")}
                    onConfirm={() => onDeleteClick()}>
                    <span style={{ color: "#FF4D4F" }}>{t("analytics_DeleteReportButton")}</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Fragment>
            <Row id={`reportTitle${parentId}`}>
                <Col span={21}>
                    <h3 className={classes.text}>
                        {reportObject && reportObject.title && reportObject.title}
                    </h3>
                </Col>
                <Col className={classes.iconContainer} span={3}>
                    {editable && (
                        <Dropdown overlay={actionsMenu}>
                            <MinimalButton>
                                <DotsHorizontal />
                            </MinimalButton>
                        </Dropdown>
                    )}
                </Col>
            </Row>
            <Row id={`reportDescription${parentId}`}>
                <Col className={classes.text} span={24}>
                    {reportObject && reportObject.description && reportObject.description}
                </Col>
            </Row>
        </Fragment>
    );
};
