import { useState, useEffect } from "react";
import Provider from "services/api";

type StateType = {
    loading: boolean;
    data: any[];
};

const useAdminExportOptions = () => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let exportOptions = await Provider.Company.getAllExportPrivilegeOptions({
                customError: {
                    message: "Could not fetch export options",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ data: exportOptions, loading: false });
            return exportOptions;
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAdminExportOptions };
