export const LinkStatusType = {
    None: "None",
    NotLinked: "NotLinked",
    Linked: "Linked",
    Invited: "Invited",
    NoEmail: "NoEmail",
};

export enum LinkStatusEnum {
    Invited = 3,
    Linked = 2,
    NoEmail = 4,
    NotLinked = 1,
}
