import { useState } from "react";
import { default as serviceProvider } from "../../services/api";

const useCompanyAutoGenerateCompanyNumbers = () => {
    const [loading, setLoading] = useState(false);

    const getAutoGenerateCompanyNumberSetting = async () => {
        setLoading(true);
        const autoGenerateCompanyNumber = await serviceProvider.Partner.getAutoGenerateCompanyNumbers().catch(
            () => {
                setLoading(false);
                return Promise.reject();
            }
        );
        setLoading(false);
        return autoGenerateCompanyNumber;
    };

    return {
        loading,
        getAutoGenerateCompanyNumberSetting,
    };
};

export default useCompanyAutoGenerateCompanyNumbers;
