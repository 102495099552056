import React, { useState, useEffect, FunctionComponent } from "react";
import classes from "./segmentationChart.module.scss";
import { useSegmentCategories, useSegmentationReviewGroupNames } from "controller";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Tooltip, Spin } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
const GridLayout = WidthProvider(Responsive);

interface IProps {
    chartData?: any[];
    globalSegment: any | undefined;
}

const SegmentationChart: FunctionComponent<IProps> = ({ chartData, globalSegment }) => {
    const { t } = useTranslation();
    const { data: segments, loading } = useSegmentCategories();
    const { data: axisNames, loading: loadingNames } = useSegmentationReviewGroupNames();
    const [points, setPoints] = useState<any[]>([]);
    const [activeGlobalSegment, setActiveGlobalSegment] = useState<any>(undefined);

    useEffect(() => {
        if (chartData) {
            chartData = chartData.map((point: any) => ({
                ...point,
                y: setYEdgeValues(point.y), //manipulating the y value so that is displays nicely in the chart
            }));
            setPoints(chartData);
        }
    }, [chartData]);

    useEffect(() => {
        if (globalSegment) {
            if (globalSegment.currentAlignedSegmentId !== null) {
                let activeGlobalIndex = segments.findIndex(
                    (x: any) => x.id === globalSegment.currentAlignedSegmentId
                );
                switch (activeGlobalIndex) {
                    case 0:
                        setActiveGlobalSegment("a");
                        break;
                    case 1:
                        setActiveGlobalSegment("b");
                        break;
                    case 2:
                        setActiveGlobalSegment("c");
                        break;
                    case 3:
                        setActiveGlobalSegment("d");
                        break;
                    default:
                        break;
                }
            } else {
                setActiveGlobalSegment(undefined);
            }
        }
    }, [globalSegment, segments]);

    const setYEdgeValues = (y: number) => {
        if (y === 100) {
            return 96;
        } else if (y <= 20) {
            return 25;
        } else {
            return y;
        }
    };

    if (loading) {
        return null;
    }

    const layout = [
        {
            i: "a",
            x: segments[0].xAreaDefinitionStart * 20,
            w: segments[0].xAreaDefinitionEnd * 20 - segments[0].xAreaDefinitionStart * 20,
            y: segments[0].yAreaDefinitionStart - 1,
            h: segments[0].yAreaDefinitionEnd,
            static: true,
        },
        {
            i: "b",
            x: segments[1].xAreaDefinitionStart * 20,
            w: segments[1].xAreaDefinitionEnd * 20 - segments[1].xAreaDefinitionStart * 20,
            y: segments[1].yAreaDefinitionStart - 1,
            h: segments[1].yAreaDefinitionEnd,
            static: true,
        },
        {
            i: "c",
            x: segments[2].xAreaDefinitionStart * 20,
            w: segments[2].xAreaDefinitionEnd * 20 - segments[2].xAreaDefinitionStart * 20,
            y: segments[2].yAreaDefinitionStart - 1,
            h: segments[2].yAreaDefinitionEnd,
            static: true,
        },
        {
            i: "d",
            x: segments[3].xAreaDefinitionStart * 20,
            w: segments[3].xAreaDefinitionEnd * 20 - segments[3].xAreaDefinitionStart * 20,
            y: segments[3].yAreaDefinitionStart - 1,
            h: segments[3].yAreaDefinitionEnd,
            static: true,
        },
    ];
    const layouts = { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout };

    return (
        <>
            <div className={classes.chartHeader}>{t("segmentation_Segmentation")}</div>
            <div className={classes.yAxisLabel}>
                <Spin spinning={loadingNames}>
                    {axisNames && axisNames.activeYName
                        ? axisNames.activeYName
                        : t("unitOfAnalysis_ImpactAverage")}
                </Spin>
            </div>
            <GridLayout
                cols={{ lg: 100, md: 100, sm: 100, xs: 100, xxs: 100 }}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                rowHeight={-2}
                autoSize={true}
                containerPadding={[42, 12]}
                className={classes.segmentChart}
                allowOverlap
                layouts={layouts}>
                <div
                    className={classNames(
                        classes.center,
                        activeGlobalSegment === "a" ? classes.activeGlobalSegment : ""
                    )}
                    style={{
                        backgroundColor: segments[0].color,
                    }}
                    key={"a"}>
                    <p className={classes.segmentText}>{segments[0].segment}</p>
                </div>
                <div
                    className={classNames(
                        classes.center,
                        activeGlobalSegment === "b" ? classes.activeGlobalSegment : ""
                    )}
                    style={{
                        backgroundColor: segments[1].color,
                    }}
                    key={"b"}>
                    <p className={classes.segmentText}>{segments[1].segment}</p>
                </div>
                <div
                    className={classNames(
                        classes.center,
                        activeGlobalSegment === "c" ? classes.activeGlobalSegment : ""
                    )}
                    style={{
                        backgroundColor: segments[2].color,
                    }}
                    key={"c"}>
                    <p className={classes.segmentText}>{segments[2].segment}</p>
                </div>
                <div
                    className={classNames(
                        classes.center,
                        activeGlobalSegment === "d" ? classes.activeGlobalSegment : ""
                    )}
                    style={{
                        backgroundColor: segments[3].color,
                    }}
                    key={"d"}>
                    <p className={classes.segmentText}>{segments[3].segment}</p>
                </div>
                {points &&
                    points.map((point: any, index: number) => (
                        <div
                            className={classes.point}
                            style={{
                                minWidth: point.parent ? 25 : 15,
                                minHeight: point.parent ? 25 : 15,
                                marginTop: point.parent ? 45 : 40,
                            }}
                            key={`point${point.unitId}`}
                            data-grid={{
                                x: point.x * 0.827, //we subtract a few points to make it look better and account for margin and padding of element.
                                w: 2,
                                y: 30 - 38 * (point.y / 100),
                                h: 2,
                                isDraggable: false,
                                isResizable: false,
                            }}>
                            <Tooltip
                                overlayClassName={classes.pointTooltip}
                                overlayInnerStyle={{ color: "#808080" }}
                                title={point.name}
                                key={index}>
                                <div style={{ height: 25, width: 25 }}></div>
                            </Tooltip>
                        </div>
                    ))}
            </GridLayout>
            <div className={classes.topLeftBottomContainer}>
                <div className={classes.xAxisLabel}>
                    <Spin spinning={loadingNames}>
                        {axisNames && axisNames.activeXName
                            ? axisNames.activeXName
                            : t("unitOfAnalysis_AlignmentAverage")}
                    </Spin>
                </div>
            </div>
        </>
    );
};

export { SegmentationChart };
