import { useEffect, useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";
import { useNcrFeature } from "controller";

const useUserAccessSettingModules = (reload = null) => {
    const { t } = useTranslation();
    const { hasNcrFeature } = useNcrFeature();
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    const getTranslationKey = (name: string) => {
        switch (name) {
            case "External Partners":
                return t("admin_UserAccessSetting_Partners");
            case "Contacts":
                return t("admin_UserAccessSetting_Contacts");
            case "Files":
                return t("admin_UserAccessSetting_Files");
            case "Reviews":
                return t("admin_UserAccessSetting_Reviews");
            case "NCR":
                return t("admin_UserAccessSetting_NCR");
            case "Compliance":
                return t("admin_UserAccessSetting_Compliance");
            case "Projects":
                return t("admin_UserAccessSetting_Projects");
            case "KPI":
                return t("admin_UserAccessSetting_KPI");
            default:
                return name;
        }
    };

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.User.getUserAccessSettingModules({
                customError: {
                    message: "Could not retrieve list of access groups",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            if (!hasNcrFeature) {
                data = [...data.filter((item: any) => item.name !== "NCR")];
            }
            data = [
                ...data
                    .filter((x: any) => x.name !== "Projects")
                    .map((item: any) => ({ ...item, name: getTranslationKey(item.name) })),
            ];
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useUserAccessSettingModules };
