export function reconfigureColumnWidth({
    columns,
    viewColumnSettings,
    minColumnWidth,
    pageContainerWidth,
}: {
    columns: any;
    viewColumnSettings: any;
    minColumnWidth: number;
    pageContainerWidth?: number;
}) {
    let newColumns: any[] = [];
    let columnWidths: number[] = [];
    if (viewColumnSettings) {
        for (let view of viewColumnSettings) {
            columnWidths[view.viewColumnId] = view.width;
        }
    }
    const allColumnsWidth = columns.reduce((sum: number, column: any) => {
        if (columnWidths[column.viewColumnId]) {
            if (column.children) {
                return (
                    sum +
                    Math.max(
                        columnWidths[column.viewColumnId],
                        column.children.length * minColumnWidth
                    )
                );
            } else {
                //TODO apply minColumnWidth
                return sum + columnWidths[column.viewColumnId];
            }
        } else return sum + column.width || 0;
    }, 0);

    newColumns = columns.map((column: any) => {
        if (columnWidths[column.viewColumnId]) {
            if (column.children) {
                column.width = Math.max(
                    columnWidths[column.viewColumnId],
                    column.children.length * 120
                );
            } else {
                //TODO apply minColumnWidth
                column.width = columnWidths[column.viewColumnId];
            }
        }
        return column;
    });

    const viewport = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const pageMargin = 20;
    const tableVerticalBar = 24;
    const pageWidth = pageContainerWidth ? pageContainerWidth : viewport;

    if (allColumnsWidth <= pageWidth - pageMargin - tableVerticalBar) {
        if (newColumns.length > 1) {
            delete newColumns[columns.length - 1].width;
        }
    }

    newColumns = newColumns.map((column: any) => {
        if (viewport < 700) delete column.fixed;
        return column;
    });

    newColumns = newColumns.map((column: any) => {
        if (column.children) {
            column.children = column.children.map((child: any) => {
                if (column.width) {
                    child.width = column.width / column.children.length;
                } else {
                    delete child.width;
                }
                return child;
            });
        }
        return column;
    });
    return newColumns;
}
