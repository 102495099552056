import { useEffect, useState } from "react";
import Provider from "services/api";
import { IComplianceTemplate } from "models";

type UseTemplatesType = {
    data: IComplianceTemplate[];
    loading: boolean;
};

const useProductTemplates = (reload: any = null) => {
    const [state, setState] = useState<UseTemplatesType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Compliance.getProductTemplates({
                customError: {
                    message: "Could not retrieve list of templates",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useProductTemplates };
