import { useEffect, useState } from "react";
import { Provider } from "services";
import { Modules } from "../../../models";

type StateType = {
    data: any[];
    loading: boolean;
};

const useInternalContactsWithUser = (accessTo: Modules) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data = await Provider.Contact.getInternalContactsWithUser(accessTo);

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useInternalContactsWithUser };
