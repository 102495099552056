import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Col, Input, notification, Form } from "antd";
import { FormRow } from "components/utilitycomponents";
import { ISupplierCardsRole } from "models";
import { useSupplierCardsPartnerUpdate } from "controller";
interface IProps {
    partnerId: number;
    data: ISupplierCardsRole;
    visible: boolean;
    onSave: Function;
    onClose: () => void;
}

const EditModal = ({ partnerId, data, visible, onSave, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, updateRole } = useSupplierCardsPartnerUpdate();

    useEffect(() => {
        form.resetFields();
    }, [data, partnerId]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        for (const property in fieldsValue) {
            fieldsValue[property] = fieldsValue[property] === null ? "" : fieldsValue[property];
        }
        await updateRole({
            partnerId,
            partnerContactRoleId: data.partnerContactRoleId,
            ...fieldsValue,
        });
        notification.success({
            message: "Success",
            description: t("supplierProfile_UpdateRelationSuccess", {
                title: data.partnerContactRole,
            }),
        });
        onSave({
            partnerContactRoleId: data.partnerContactRoleId,
            ...fieldsValue,
        });
        onClose();
    };

    return (
        <Modal
            wrapProps={{ "data-test": "supplier_profile_role_edit_modal" }}
            style={{ top: 20 }}
            width={600}
            title={data.partnerContactRole}
            onCancel={onClose}
            footer={[
                <Button
                    data-test="meeting_modal_save_button"
                    key="ok"
                    type="primary"
                    onClick={handleSave}
                    loading={loading}>
                    {t("Save")}
                </Button>,
            ]}
            open={visible}>
            <Form form={form}>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RoleOurRepresentative")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="ourRepresentative" initialValue={data.ourRepresentative}>
                            <Input
                                data-test="role_our_representatives_input"
                                placeholder={t("supplierProfile_RoleOurRepresentative")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RoleTheirRepresentative")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="theirRepresentative"
                            initialValue={data.theirRepresentative}>
                            <Input
                                data-test="role_their_representative_input"
                                placeholder={t("supplierProfile_RoleTheirRepresentative")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RolePosition")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="position" initialValue={data.position}>
                            <Input
                                data-test="role_position_input"
                                placeholder={t("supplierProfile_RolePosition")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RoleLocation")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="location" initialValue={data.location}>
                            <Input
                                data-test="role_location_input"
                                placeholder={t("supplierProfile_RoleLocation")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RolePhone")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item name="phone" initialValue={data.phone}>
                            <Input
                                data-test="role_phone_input"
                                placeholder={t("supplierProfile_RolePhone")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col lg={12} xs={24}>
                        <label>{t("supplierProfile_RoleEmail")}</label>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="email"
                            initialValue={data.email}
                            rules={[
                                {
                                    required: false,
                                    message: t("emailInputError"),
                                    type: "email",
                                },
                            ]}>
                            <Input
                                data-test="role_email_input"
                                placeholder={t("supplierProfile_RoleEmail")}
                            />
                        </Form.Item>
                    </Col>
                </FormRow>
            </Form>
        </Modal>
    );
};

export default EditModal;
