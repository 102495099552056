import { Col, Row } from "antd";
import React from "react";
import { HighLevelMetrics } from "./HighLevelMetrics";
import { DrilldownMetrics } from "./drilldownMetrics/drilldownMetrics";

interface IProps {
    partnerId: number;
}

const Sustainability = ({ partnerId }: IProps) => {
    return (
        <div style={{ marginBottom: 10 }}>
            <Row>
                <Col span={24}>
                    <HighLevelMetrics partnerId={partnerId} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DrilldownMetrics partnerId={partnerId} />
                </Col>
            </Row>
        </div>
    );
};

export { Sustainability };
