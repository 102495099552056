import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, notification, Tag, Form, Button } from "antd";
import { useUpdateVisibility } from "controller";
import { SelectData } from "components/utilitycomponents";

interface IProps {
    selectedRows: any[];
    visible: boolean;
    onClose: Function;
    onConfirm: Function;
}

const VisibilityModal = ({ selectedRows, visible, onClose, onConfirm }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, changeVisibility } = useUpdateVisibility();
    function renderTags() {
        return selectedRows.map((row: any) => {
            return (
                <Tag color="blue" key={row.id}>
                    {row.firstName} {row.lastName}
                </Tag>
            );
        });
    }

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    async function onSubmit() {
        let values = await form.validateFields();
        await changeVisibility(
            values.visibilitySelect,
            selectedRows.map(x => x.id)
        );
        notification.success({
            message: t("success"),
            description: t("contact_visibiltyUpdateSuccess"),
        });
        onConfirm();
    }

    return (
        <Fragment>
            {visible && (
                <Modal
                    wrapProps={{ "data-test": "change_visibility_modal" }}
                    title={t("contact_ChangeVisibilityTitle")}
                    open={visible}
                    onOk={onSubmit}
                    onCancel={() => onClose(false)}
                    footer={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={async () => await onSubmit()}>
                            {t("generic_Save")}
                        </Button>,
                    ]}>
                    <Form
                        form={form}
                        labelAlign="left"
                        labelCol={{ lg: 6, xs: 24 }}
                        wrapperCol={{ lg: 18, xs: 24 }}>
                        <Fragment>
                            <div className="gridTagLabel">
                                {t("contact_SelectedContacts") + " :"}
                            </div>
                            <div className="gridTagArea">{renderTags()}</div>

                            <Form.Item
                                label={t("contact_Visibility")}
                                name="visibilitySelect"
                                initialValue={1}>
                                <SelectData
                                    style={{ width: "100%" }}
                                    selectOptionField="name"
                                    useData={() => {
                                        return {
                                            data: [
                                                { name: t("internal"), id: 1 },
                                                { name: t("external"), id: 2 },
                                            ],
                                            loading: false,
                                        };
                                    }}
                                />
                            </Form.Item>
                        </Fragment>
                    </Form>
                </Modal>
            )}
        </Fragment>
    );
};

export default VisibilityModal;
