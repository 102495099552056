import { useState } from "react";
import { Provider } from "services";

const useCompanyNumberInUse = () => {
    const [loading, setLoading] = useState(false);

    const isCompanyNumberInUse = async (companyNumber: string) => {
        setLoading(true);
        const id = await Provider.Partner.IsCompanyNumberInUse(companyNumber, {
            customError: { message: `Could not validate company number` },
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        isCompanyNumberInUse,
    };
};

export { useCompanyNumberInUse };
