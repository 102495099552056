import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { PureDiv, RichTextEditor } from "components/utilitycomponents";
import classes from "./styles/adjustSurvey.module.scss";

type PropTypes = {
    text: string;
    onChange: Function;
    showEditButton?: boolean;
};

const EditIntroduction: FunctionComponent<PropTypes> = ({
    text,
    onChange,
    showEditButton = true,
}) => {
    const { t } = useTranslation();

    const [introduction, setIntroduction] = useState<any>(text);
    const [editIntroduction, setEditIntroduction] = useState(false);

    useEffect(() => {
        setIntroduction(text);
    }, [text]);

    const handleChange = (val: string) => {
        onChange(val);
        setIntroduction(val);
    };

    return !editIntroduction ? (
        <>
            <PureDiv className={classes.introduction} textContent={introduction} />
            {showEditButton && (
                <Button
                    className={classes.formEditButton}
                    type="primary"
                    size="small"
                    onClick={() => setEditIntroduction(true)}>
                    {t("generic_Edit")}
                </Button>
            )}
        </>
    ) : (
        <>
            <RichTextEditor
                value={introduction}
                placeholder={t("survey_IntroductionPlaceholder")}
                maxLength={1000}
                bordered
                onChange={handleChange}
            />
            {showEditButton && (
                <Button
                    className={classes.formEditButton}
                    type="primary"
                    size="small"
                    onClick={() => setEditIntroduction(false)}>
                    {t("generic_Ok")}
                </Button>
            )}
        </>
    );
};

export default EditIntroduction;
