import React, { FunctionComponent } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import classnames from "classnames";
import { useReviewCategoriesRadarChart } from "controller";
import { Divider } from "antd";
import { LoadingIndicator } from "components/utilitycomponents";
import classes from "./styles/radarDiagramChart.module.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

export const RadarDiagram: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();
    const { loading, data } = useReviewCategoriesRadarChart(partnerId, range.from, range.to);

    if (loading) return <LoadingIndicator />;

    const renderChart = () => {
        return (
            <ResponsiveRadar
                data={data.map((item: any) => {
                    return {
                        ...item,
                        reviewCriteria: t(item.reviewCriteria),
                        [`${t("performance_internalResponseAvg")}`]: item.internalResponseAvg,
                        [`${t(
                            "performance_voiceOfSupplierResponseAvg"
                        )}`]: item.voiceOfSupplierResponseAvg,
                    };
                })}
                keys={[
                    t("performance_internalResponseAvg").toString(),
                    t("performance_voiceOfSupplierResponseAvg").toString(),
                ]}
                indexBy="reviewCriteria"
                margin={{ top: 70, right: 120, bottom: 40, left: 120 }}
                borderColor={{ from: "color" }}
                gridLabelOffset={36}
                dotSize={10}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                colors={["#6baed6", "#ffc069"]}
                blendMode="multiply"
            />
        );
    };
    return (
        <div>
            <Divider />

            <div className={classnames(classes.radarChart)}>
                <div className={classes.legend}>
                    <div>
                        <span
                            style={{ backgroundColor: "#6baed6" }}
                            className={classes.legendColor}></span>
                        <span className={classes.legendText}>
                            {t("performance_internalResponseAvg")}
                        </span>
                    </div>
                    <div>
                        <span
                            style={{ backgroundColor: "#ffc069" }}
                            className={classes.legendColor}></span>
                        <span className={classes.legendText}>
                            {t("performance_voiceOfSupplierResponseAvg")}
                        </span>
                    </div>
                </div>
                {renderChart()}
            </div>
            {/**print section */}
            <div style={{ width: "595px", position: "absolute", left: -9999 }}>
                <div className={classnames(classes.radarChart, "ncrChart")}>{renderChart()}</div>
            </div>
            <div className={classnames(classes.radarChartPrint, "radarChartPrint")}></div>
            {/**end print section */}
        </div>
    );
};

export default RadarDiagram;
