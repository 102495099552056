import React, { useEffect } from "react";
import { Form, Row, Col } from "antd";
import { SelectData } from "components/utilitycomponents";
import classes from "./surveyResults.module.scss";
import { useTranslation } from "react-i18next";
import { useSurveyRelatedToSegmentationUnit, useSurveysRelatedToPerformanceUnit } from "controller";
interface IProps {
    segmentationUnitId?: number;
    performanceUnit?: any;
    onSurveySelect: Function;
}

const SurveySelector = ({ segmentationUnitId = -1, performanceUnit, onSurveySelect }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const {
        data: segmentationUnitSurveys,
        loading: loadingSegmentationSurveys,
    } = useSurveyRelatedToSegmentationUnit(segmentationUnitId);
    const {
        data: performanceUnitSurveys,
        loading: loadingPerformanceSurveys,
    } = useSurveysRelatedToPerformanceUnit(
        performanceUnit && performanceUnit.id,
        performanceUnit && performanceUnit.type
    );

    useEffect(() => {
        form.resetFields();
    }, [segmentationUnitSurveys, performanceUnitSurveys]);

    return (
        <div className={classes.surveySelectContainer}>
            <Row style={{ paddingTop: 18 }}></Row>
            <Row className={classes.title}>{t("survey_Survey")}</Row>
            <Row className={classes.surveySelector}>
                <Col span={24}>
                    <Form form={form}>
                        {segmentationUnitId !== -1 && (
                            <Form.Item
                                name="selectedSurvey"
                                initialValue={
                                    segmentationUnitSurveys[0] &&
                                    segmentationUnitSurveys[0].surveyId
                                }>
                                <SelectData
                                    className={classes.surveySelector}
                                    style={{ width: 300 }}
                                    useData={() => ({
                                        data: segmentationUnitSurveys,
                                        loading: loadingSegmentationSurveys,
                                    })}
                                    selectOptionField="name"
                                    selectIdField="surveyId"
                                    onChange={(val: any) => {
                                        onSurveySelect(val);
                                    }}
                                />
                            </Form.Item>
                        )}
                        {performanceUnit &&
                            performanceUnit.id !== -1 &&
                            !loadingPerformanceSurveys && (
                                <Form.Item
                                    name="selectedPerformanceSurvey"
                                    initialValue={
                                        performanceUnitSurveys[0] &&
                                        performanceUnitSurveys[0].surveyId
                                    }>
                                    <SelectData
                                        className={classes.surveySelector}
                                        style={{ width: 300 }}
                                        useData={() => ({
                                            data: performanceUnitSurveys,
                                            loading: loadingPerformanceSurveys,
                                        })}
                                        selectOptionField="name"
                                        selectIdField="surveyId"
                                        onChange={(val: any) => {
                                            onSurveySelect(val);
                                        }}
                                    />
                                </Form.Item>
                            )}
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export { SurveySelector };
