import React, { FunctionComponent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridButton, GridDeleteModal, PlusCircle, Trash02 } from "components/utilitycomponents";
import { notification } from "antd";
import { useContactGroupUpdate } from "controller";

type PropsType = {
    selectedRowKeys: any[];
    selectedRows: any[];
    onClearSelectedRows: Function;
    onReloadData: Function;
    onCreateClicked: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    selectedRowKeys,
    selectedRows,
    onClearSelectedRows,
    onReloadData,
    onCreateClicked,
}) => {
    const { t } = useTranslation();
    const { deleteGroup, loading: deleteLoading } = useContactGroupUpdate();
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });

    const handleDelete = async (ids: number[]) => {
        await deleteGroup(ids);
        notification.success({
            message: t("success"),
            description: t(`contact_GroupDeleteSuccess`),
        });
        setDeleteModal({
            visible: false,
        });
        onClearSelectedRows([]);
        onReloadData();
    };

    const buttonVisibility = selectedRowKeys.length > 0;

    return (
        <Fragment>
            {!buttonVisibility && (
                <GridButton
                    data-test="create_contact_group_button"
                    icon={<PlusCircle />}
                    type="default"
                    onClick={() => onCreateClicked()}>
                    {t("contact_CreateGroup")}
                </GridButton>
            )}

            {buttonVisibility && (
                <GridButton
                    danger
                    data-test="delete_button"
                    icon={
                        <Trash02 style={{ color: selectedRowKeys.length !== 0 ? "#FF4D4F" : "" }} />
                    }
                    type="default"
                    onClick={() => setDeleteModal({ visible: true })}>
                    {t("contact_DeleteGroup")}
                </GridButton>
            )}

            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                    });
                    onClearSelectedRows([]);
                }}
                data={selectedRows.map((r: any) => ({
                    id: r.id,
                    name: r.name,
                }))}
                name={t("contact_Groups")}
                confirmLoading={deleteLoading}
                onConfirm={handleDelete}
            />
        </Fragment>
    );
};

export default OverviewHeader;
