import React, { useEffect, useState } from "react";
import { Cascader } from "antd";
import classes from "./cascade.module.scss";

type PropsType = {
    value?: any;
    onChange?: (value?: any, item?: any) => void;
    useData: Function;
    selectIdField?: string;
    selectOptionField?: string;
    selectLabelSuffix?: string;
    placeholderText?: string;
    [key: string]: any;
};

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
}

const CascadeSelect = ({
    value,
    onChange,
    useData,
    selectIdField = "id",
    selectOptionField = "name",
    selectLabelSuffix,
    placeholderText,
    ...rest
}: PropsType) => {
    const { data, loading } = useData();
    const [options, setOptions] = useState<Option[]>([]);
    const [controlledValue, setControlledValue] = useState<any>(value);

    useEffect(() => {
        findSelectedItem(value);
    }, [value, data]);

    useEffect(() => {
        if (data) {
            let optionData: Option[] = data.map((item: any) => {
                return {
                    value: item[selectIdField],
                    label: selectLabelSuffix
                        ? `${item[selectOptionField]} (${item[selectLabelSuffix]})`
                        : item[selectOptionField],
                    children: item.children
                        ? item.children.map((child: any) => {
                              return {
                                  value: child[selectIdField],
                                  label: selectLabelSuffix
                                      ? `${child[selectOptionField]} (${child[selectLabelSuffix]})`
                                      : child[selectOptionField],
                                  children: child.children
                                      ? child.children.map((child: any) => {
                                            return {
                                                value: child[selectIdField],
                                                label: child[selectOptionField],
                                                children: child.children ? child.children : [],
                                            };
                                        })
                                      : [],
                              };
                          })
                        : [],
                };
            });
            setOptions(optionData);
        }
    }, [data]);

    const findSelectedItem = (val: number[]) => {
        if (data) {
            if (val.length > 1) {
                setControlledValue(val);
            } else if (val.length === 1) {
                for (let i = 0; i < data.length; i++) {
                    let selectedItem = findSelected(data[i], val[0]);
                    if (selectedItem) {
                        setControlledValue(selectedItem);
                        i = data.length + 1;
                    }
                }
            } else {
                setControlledValue(undefined);
            }
        }
    };

    const findSelected = (item: any, val: any, parentElement?: any) => {
        if (item[selectIdField] === val) {
            return [item[selectIdField]];
        } else if (item.children) {
            for (let j = 0; j < item.children.length; j++) {
                const child: any = findSelected(item.children[j], val, item);
                if (child) {
                    return [item[selectIdField], child[0]];
                }
            }
        } else {
            return undefined;
        }
    };

    return (
        <Cascader
            onChange={(value?: any, item?: any) => {
                onChange && onChange(value, item);
            }}
            value={controlledValue}
            options={options}
            loading={loading}
            placeholder={placeholderText}
            showSearch
            popupClassName={classes.cascadePopUp}
            {...rest}
        />
    );
};

export { CascadeSelect };
