import { post, put, get, remove } from "../base";
import { ICreateAutoSurvey, ITableInput } from "models";

const Survey = {
    getOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/AutomatedSurvey/Overview`;
        let response = await post({
            url,
            payload: tableInput,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    createAutomatedSurvey: async (
        survey: ICreateAutoSurvey,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/AutomatedSurvey/Create`;
        let response = await post({
            url,
            payload: survey,
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        });
        return response;
    },
    updateAutomatedSurvey: async (autoSurveyId: number, frequency: number, isActive: boolean) => {
        const url = `/v1/AutomatedSurvey/Update?surveyId=${autoSurveyId}&frequency=${frequency}&isActive=${isActive}`;
        await put({
            url,
            payload: {},
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        });
    },
    getAutomatedSurveyTitles: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/AutomatedSurvey/GetAllAutomatedSurveysTitle`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
    deleteAutomatedSurveys: async (
        autoSurveyIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/AutomatedSurvey/DeleteAutomatedSurveys`;
        let response = await remove({
            url,
            payload: autoSurveyIds,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return response;
    },
};

export default Survey;
