import { get } from "../base";
import Config from "../../../config";

const Locize = {
    getAvailableLanguages: async ({ customError }: any = {}) => {
        const url = `https://api.locize.app/languages/${Config.locizeProjectId}`;
        return await get({ url, customError });
    },
};

export default Locize;
