import i18n from "i18n";
import dayjs from "utilities/daysJsConfig";
const elapsedTime = (utcDate: string, format: string = "MMM D, YYYY") => {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;

    var elapsed = dayjs().valueOf() - dayjs(utcDate).valueOf();
    if (elapsed < 3 * msPerDay) {
        if (elapsed < msPerMinute) return i18n.t("activity_CreatedFewSecondsAgo");
        if (elapsed >= msPerMinute && elapsed < msPerHour)
            return Math.floor(elapsed / msPerMinute) + i18n.t("activity_CreatedMinuteAgo");
        if (elapsed >= msPerHour && elapsed < msPerDay)
            return Math.floor(elapsed / msPerHour) + i18n.t("activity_CreatedHourAgo");
        else return Math.floor(elapsed / msPerDay) + i18n.t("activtiy_CreatedDayAgo");
    }

    return dayjs(utcDate).format(format);
};

export { elapsedTime };
