import { IAwardSetting, IScorecardMetric, IScorecardArea, ITableInput } from "models";
import { get, post, put, remove } from "../base";

const controller = `/v1/AdminScorecardsSettings`;
const AdminScorecard = {
    getScorecardSettingsOverview: async (
        tableInput: ITableInput,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/AdminScorecardsSettings/Overview`;
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },
    getAwardSettings: async () => {
        return await get({
            url: `${controller}/AwardSettings`,
        });
    },
    upsertAwardSettings: async (payload: IAwardSetting[]) => {
        let url = `/v1/AdminScorecardsSettings/AwardSettings`;
        await put({ url, payload });
    },
    createScorecardArea: async (payload: IScorecardArea) => {
        let url = `/v1/AdminScorecardsSettings/CreateScoreArea`;
        return await post({ url, payload });
    },
    updateScorecardArea: async (payload: IScorecardArea) => {
        let url = `/v1/AdminScorecardsSettings/ScoreArea`;
        return await put({ url, payload });
    },
    deleteScorecardArea: async (scorecardAreaId: number) => {
        let url = `/v1/AdminScorecardsSettings/ScoreArea/${scorecardAreaId}`;
        return await remove({ url });
    },
    createMetric: async (payload: IScorecardMetric) => {
        let url = `/v1/AdminScorecardsSettings/Metric`;
        return await post({ url, payload });
    },
    deleteMetric: async (metricId: number) => {
        let url = `/v1/AdminScorecardsSettings/Metric/${metricId}`;
        await remove({ url });
    },
    getScorecardAreas: async () => {
        let url = `/v1/AdminScorecardsSettings/Metric/GetScoreAreas`;
        return await get({ url });
    },
    getScorecardMetricTypes: async () => {
        let url = `/v1/AdminScorecardsSettings/Metric/GetTypes`;
        return await get({ url });
    },
    orderScorecardArea: async (payload: any) => {
        let url = `/v1/AdminScorecardsSettings/ScoreArea/Order`;
        await put({ url, payload });
    },
};

export { AdminScorecard };
