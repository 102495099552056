import { useEffect, useState } from "react";
import { Provider } from "services";
import { IExternalPartner } from "models";

export type StateType = {
    data: IExternalPartner[];
    loading: boolean;
};

const useExternalPartners = (
    newPartner: number = 0,
    reload: any = null,
    withEmail: boolean | undefined = undefined,
    withIsLinked: boolean | undefined = undefined,
    withContacts: boolean | undefined = undefined
) => {
    const [state, setState] = useState<StateType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setState({
                ...state,
                loading: true,
            });
            const data = await Provider.Partner.getExternalPartners(
                withEmail,
                withIsLinked,
                withContacts
            );
            setState({
                data,
                loading: false,
            });
        })();
    }, [newPartner, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useExternalPartners };
