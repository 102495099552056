import React, { forwardRef, useState, useEffect } from "react";
import { Checkbox, DatePicker, Input } from "antd";
import { IComplianceAnswer, IComplianceQuestion } from "models";
import { CustomDropDown } from "./customDropDown";
import { ThreeStatusDropDown } from "./threeStatusDropDown";
import { AttachFile, Star } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import "./styles/typedQuestion.scss";
import { CustomDropDownMultipleSelect } from "./customDropDownMultipleChoice";

const TextArea = Input.TextArea;

type PropsType = {
    onChange?: (value: any) => void;
    value?: any;
    question: IComplianceQuestion;
    onUploadingFile?: any;
    loadFiles?: any;
};

const TypedQuestion = forwardRef(
    ({ onChange, value, question, onUploadingFile, loadFiles }: PropsType, ref) => {
        const { t } = useTranslation();
        const [attachedFileList, setAttachedFileList] = useState<any>([]);
        const [fileUploadInProgress, setFileUploadInprogress] = useState(false);

        useEffect(() => {
            onUploadingFile && onUploadingFile(fileUploadInProgress);
        }, [fileUploadInProgress]);

        useEffect(() => {
            if (
                question.questionType === "AttachDocuments" &&
                question.complianceAnswers &&
                question.complianceAnswers.length !== 0 &&
                loadFiles &&
                loadFiles.load === true
            ) {
                question.complianceAnswers.forEach((answer: IComplianceAnswer) => {
                    if (answer.files && answer.files.length !== 0) {
                        let files = answer.files.map((file: any) => ({
                            id: file.id,
                            name: file.name,
                        }));
                        setAttachedFileList(files);
                    }
                });
            }
        }, [question.complianceAnswers]);

        switch (question.questionType) {
            case "Check":
                return (
                    <Checkbox
                        checked={value}
                        data-test="compliance_question_checkbox"
                        onChange={(e: any) => onChange && onChange(e.target.checked)}
                        className="complianceCheckboxQuestion"
                    />
                );
            case "Text":
                return (
                    <TextArea
                        data-test="compliance_question_textarea"
                        maxLength={4000}
                        rows={4}
                        placeholder={t("compliance_InsertText")}
                        value={value}
                        onChange={onChange}
                    />
                );
            case "CustomDropdowns":
                return (
                    <CustomDropDown
                        data-test="compliance_question_custom_dropdown"
                        onChange={onChange}
                        value={value}
                        options={
                            question.customDropdownOptions ? question.customDropdownOptions : []
                        }
                    />
                );
            case "MultipleChoiceCustomDropdown":
                return (
                    <CustomDropDownMultipleSelect
                        data-test="compliance_question_custom_dropdown_multiple"
                        onChange={onChange}
                        value={value}
                        options={
                            question.customDropdownOptions ? question.customDropdownOptions : []
                        }
                    />
                );
            case "Date":
                return (
                    <DatePicker
                        format={"MMM D, YYYY"}
                        data-test="compliance_question_date_picker"
                        placeholder={t("generic_SelectDate")}
                        getPopupContainer={node =>
                            node ? (node.parentNode as HTMLElement) : document.body
                        }
                        value={value}
                        onChange={onChange}
                        allowClear
                    />
                );
            case "Number":
                return (
                    <Input
                        data-test="compliance_question_input"
                        placeholder={t("compliance_InsertNumber")}
                        style={{ width: "100%" }}
                        value={value}
                        onChange={(e: any) => {
                            if (e.target.value === "" || /^-?\d*\.?\d*?$/.test(e.target.value)) {
                                onChange && onChange(e);
                            }
                        }}
                        onBlur={(e: any) => {
                            let value = e.target.value;
                            if (value && value[value.length - 1] === ".") {
                                onChange && onChange(value.slice(0, -1));
                            }
                            if (value && value[0] === ".") {
                                onChange && onChange("0" + value);
                            }
                        }}
                        maxLength={4000}
                    />
                );
            case "Stars":
                return (
                    <Star
                        data-test="compliance_question_star"
                        allowClear
                        onChange={onChange}
                        value={value}
                    />
                );
            case "ThreeStatusDropdown":
                return (
                    <ThreeStatusDropDown
                        data-test="compliance_question_three_status_dropdown"
                        onChange={onChange}
                        value={value}
                    />
                );
            case "AttachDocuments":
                return (
                    <AttachFile
                        attachedFileList={attachedFileList}
                        showMaxFileSize={false}
                        maxFileSize={40}
                        onUploadingFile={(uplading: boolean) => setFileUploadInprogress(uplading)}
                        uploadSectionLayout={{ style: { display: "table" } }}
                        onChange={onChange}>
                        {t("generic_SelectFile")}
                    </AttachFile>
                );

            default:
                return <div />;
        }
    }
);

export { TypedQuestion };
