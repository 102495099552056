import { useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";
import { IUpdateReview } from "models";

const useReviewUpdate = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const updateReviewsVisibility = async (reviewIds: number[], visibility: 1 | 2) => {
        setLoading(true);
        await Provider.Review.updateReviewsVisibility(reviewIds, visibility, {
            customError: {
                message: t("review_UpdateVisibilityError"),
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const updateReview = async (reviewId: number, reviewValues: IUpdateReview) => {
        setLoading(true);
        switch (reviewValues.visibility) {
            case "1":
                reviewValues.visibility = "Internal";
                break;
            case "2":
                reviewValues.visibility = "External";
                break;
            default:
                break;
        }
        await Provider.Review.updateReview(reviewId, reviewValues).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        updateReviewsVisibility,
        updateReview,
    };
};

export { useReviewUpdate };
