import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Provider } from "services";
import { IActivityData, IActivityChild } from "models";

type ActivitiesStateType = {
    loading: boolean;
    data: IActivityData[];
};

type TotalStateType = {
    loading: boolean;
    count: number;
};

const useActivities = (
    pageNumber: number,
    pageSize: number,
    reloadActivities: any = { reload: false }
) => {
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);

    const [state, setState] = useState<ActivitiesStateType>({
        loading: true,
        data: [],
    });

    const [total, setTotal] = useState<TotalStateType>({
        loading: true,
        count: 0,
    });

    useEffect(() => {
        (async () => {
            setTotal({ ...total, loading: true });
            const totalCount: number = await Provider.Activity.getActivitiesTotalCount({
                customError: {
                    message: "Could not retrieve activities total count",
                },
            }).catch(err => {
                setTotal({
                    ...total,
                    loading: false,
                });
                return Promise.reject();
            });
            setTotal({
                loading: false,
                count: totalCount,
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (userType === "lightuser") {
                setState({ ...state, loading: false });
                return;
            }
            setState({ ...state, loading: true });
            let data = await Provider.Activity.activityList(pageNumber, pageSize, {
                customError: {
                    message: "Could not retrieve activities",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject();
            });

            setState({ loading: false, data: data.activitiesData });

            setState({
                loading: false,
                data: [...state.data, ...data.activitiesData],
            });
        })();
    }, [pageNumber]);

    useEffect(() => {
        (async () => {
            if (userType === "lightuser") {
                setState({ ...state, loading: false });
                return;
            }
            setState({ ...state, loading: true });
            let data = await Provider.Activity.activityList(1, pageSize, {
                customError: {
                    message: "Could not retrieve activities",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject();
            });

            setState({ loading: false, data: data.activitiesData });
        })();
    }, [reloadActivities]);

    const addActivity = (newActivity: any) => {
        setState({
            ...state,
            data: [newActivity, ...state.data],
        });
    };

    const addChildActivity = (parentActivityId: number, newActivity: IActivityChild) => {
        const activitiesData = state.data.map(activityData => {
            if (activityData.activity.id === parentActivityId) {
                activityData.children =
                    activityData.children !== null
                        ? [newActivity, ...activityData.children]
                        : [newActivity];
                return activityData;
            }
            return activityData;
        });
        setState({
            ...state,
            data: activitiesData,
        });
    };

    const removeActivity = (activityId: number) => {
        setState({
            ...state,
            data: state.data.filter(
                (activityData: IActivityData) => activityData.activity.id !== activityId
            ),
        });
    };

    const removeChildActivity = (parentActivityId: number, activityId: number) => {
        setState({
            ...state,
            data: state.data.map((activityData: IActivityData) => {
                if (activityData.activity.id !== parentActivityId) {
                    return activityData;
                }
                activityData.children = activityData.children.map((activity: IActivityChild) => {
                    if (activity.id !== activityId) {
                        return activity;
                    }
                    activity.isDeleted = true;
                    activity.canDelete = false;
                    return activity;
                });
                return activityData;
            }),
        });
    };
    const changeFollowActivity = (activityId: number, isFollowed: boolean) => {
        setState({
            ...state,
            data: state.data.map((activityData: IActivityData) => {
                const newObject = { ...activityData };
                if (activityData.activity.id !== activityId) return newObject;
                newObject.isFollow = isFollowed;
                return newObject;
            }),
        });
    };

    return {
        loading: state.loading,
        data: { numberOfTotalActivities: total.count, activitiesData: state.data },
        addActivity,
        addChildActivity,
        removeActivity,
        removeChildActivity,
        changeFollowActivity,
    };
};

export { useActivities };
