import { get } from "../base";

const CompanySettings = {
    getCompanyHeadlines: async () => {
        const url = `/v1/CompanySettings/PartnerOverviewHeadine`;
        return await get({ url });
    },
    getCompanyLogo: async (companyId: number) => {
        const url = `/v1/File/GetCompanyLogo?companyId=${companyId}`;
        return await get({ url });
    },
    getCompanySupportEmail: async () => {
        const url = `/v1/Company/SupportEmail`;
        return await get({ url, retryCount: 3, customError: "Failed to get company email" });
    },
    getCompanyBranding: async ({ customError }: any = {}) => {
        const url = `/v1/CompanySettings/GetCompanyBranding`;
        return await get({ url, customError });
    },
};

export default CompanySettings;
