import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { Link, useHistory } from "react-router-dom";
import { AppState } from "store";
import { Card, Col, Row, Skeleton } from "antd";
import NcrCreationModal from "components/contentcomponents/ncr/overview/modals/create/creationModal";
import { useTranslation } from "react-i18next";
import classes from "./shortcuts.module.scss";
import RequestInfoModal from "components/contentcomponents/compliance/requests/requestInfo/requestInfoModal";
import ReviewCreationModal from "components/contentcomponents/reviews/review/modals/create/creationModal";
import { default as ActionCreationModal } from "components/contentcomponents/action/overview/createEditModal";
import {
    useCompanyType,
    useCompanySettings,
    useEnabledSupplierProfilePages,
    useSections,
    useProductFeature,
    useNcrFeature,
    useFilesFeature,
} from "controller";
import SelectSupplierModal from "./selectSupplierModal";
import { ISupplierProfilePage } from "models";
import { CreateModal as DevelopmentPlanCreationModal } from "components/contentcomponents/developmentPlans/createModal";
import CreateExternalPartnerModal from "components/utilitycomponents/select/selectExternalPartner/createExternalPartnerModal";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import { RequestProductInfoModal } from "components/contentcomponents/compliance/requests/productRequest/requestProductInfoModal";
import { CreateModal as CreateMeetingMinuteModal } from "components/contentcomponents/suppliers/supplierProfile/enterprisePages/governance/meetingCalendar/createModal";
import {
    ArrowNarrowUpVertical,
    ArrowsRight,
    BarChart07,
    CalendarCheck01,
    ClipboardCheck,
    FilePlus02,
    Globe01,
    InfoOctagon,
    LayoutGrid01,
    Link01,
    PlusCircle,
    Send01,
    Star01,
    UserPlus01,
} from "components/utilitycomponents";

interface shortcutsProps {
    user: User;
}

const Shortcuts = (props: shortcutsProps) => {
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showNcrModal, setShowNcrModal] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showCreateReviewModal, setShowCreateReviewModal] = useState(false);
    const [showCreateActionModal, setShowCreateActionModal] = useState(false);
    const [selectSupplierModal, setSelectSupplierModal] = useState(false);
    const [showProductRequestModal, setShowProductRequestModal] = useState(false);
    const [showMeetingMinuteModal, setShowMeetingMinuteModal] = useState(false);
    const userType: string = props.user.profile["ll-Usertype"];
    const hasReviewAccess = props.user.profile["ll-Review"] === "true" ? true : false;
    const hasComplianceAccess = props.user.profile["ll-Compliance"] === "true" ? true : false;
    const hasSupplierAccess = props.user.profile["ll-Partner"] === "true" ? true : false;
    const hasNcrAccess = props.user.profile["ll-Ncr"] === "true" ? true : false;
    const hasFilesAccess = props.user.profile["ll-Files"] === "true" ? true : false;
    const hasProductAccess = props.user.profile["ll-Product"] === "true";
    const { hasNcrFeature } = useNcrFeature();
    const { hasFilesFeature } = useFilesFeature();
    const companyActionAccess =
        (props.user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyComplianceAccess =
        (props.user.profile["ll-enabledCompanyModule-Compliance"] === "true" &&
            !isDefaultCompany) ||
        isDefaultCompany;
    const companyPartnersAccess =
        (props.user.profile["ll-enabledCompanyModule-Supplier"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyFilesAccess =
        (props.user.profile["ll-enabledCompanyModule-file"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyNcrAccess =
        (props.user.profile["ll-enabledCompanyModule-Ncr"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReviewAccess =
        (props.user.profile["ll-enabledCompanyModule-Review"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const { data: companySettings } = useCompanySettings();
    const canCreateSupplier = companySettings && companySettings.canUsersCreateSupplier;
    const history = useHistory();
    const { data: enabledPages } = useEnabledSupplierProfilePages();
    const [toggleDevPlanModal, setToggleDevPlanModal] = useState(false);
    const viewport = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const { data: shortcutSections, loading: loadingSections } = useSections();
    const { height, width } = useWindowDimensions();
    const { hasProductFeature } = useProductFeature();

    const setCardWidth = () => {
        let _width = width ? width : viewport;
        if (shortcutSections.length >= 4 && _width > 1800) {
            return "46%";
        } else if (shortcutSections.length <= 4 && _width > 1180 && _width < 1800) {
            return "46%";
        } else if (shortcutSections.length <= 4 && _width > 900 && _width < 1180) {
            return "43%";
        } else if (shortcutSections.length <= 4 && _width < 1180) {
            return "99%";
        } else if (shortcutSections.length <= 3 && _width < 1190) {
            return "46%";
        } else if (shortcutSections.length <= 4 && _width > 1800) {
            return "46%";
        } else {
            return "23%";
        }
    };

    const [gridStyle, setGridStyle] = useState<React.CSSProperties>({
        width: setCardWidth(),
        textAlign: "left",
        cursor: "pointer",
        minHeight: 80,
        wordBreak: "break-all",
        wordWrap: "break-word",
        position: "relative",
        borderRadius: 5,
        margin: 8,
        paddingLeft: viewport < 1700 ? 8 : 16,
    });

    useEffect(() => {
        if (height && width) {
            setGridStyle({
                width: setCardWidth(),
                textAlign: "left",
                cursor: "pointer",
                minHeight: 80,
                wordBreak: "break-all",
                wordWrap: "break-word",
                position: "relative",
                borderRadius: 5,
                margin: 8,
                paddingLeft: width < 1700 ? 8 : 16,
            });
        }
    }, [height, width]);

    const calculateColumnSpan = () => {
        if (shortcutSections.length > 0) {
            switch (shortcutSections.length) {
                case 1:
                case 2:
                case 3:
                    return 24;
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    return 12;

                default:
                    return 24;
            }
        }
    };

    const renderSections = () => {
        return (
            <>
                {shortcutSections.map((section: any, index: number) => (
                    <Col
                        key={`section${index}`}
                        span={calculateColumnSpan()}
                        style={{ marginBottom: 32 }}>
                        <Card
                            headStyle={{ border: "none", marginBottom: -16 }}
                            style={{ borderRadius: 5 }}
                            bodyStyle={{
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                                padding: 4,
                                paddingLeft: 20,
                                paddingBottom: 20,
                            }}
                            className={classes.cardGridContainer}
                            title={
                                <span className={classes.title}>
                                    {section.name === null ? t("generic_Shortcuts") : section.name}
                                </span>
                            }>
                            {renderShortcuts(section.shortcuts)}
                        </Card>
                    </Col>
                ))}
            </>
        );
    };

    const renderShortcuts = (shortcuts: any[]) => {
        return shortcuts.map((shortcut: any) => {
            switch (shortcut.shortcutTypeId) {
                case 1:
                    return (
                        <Card.Grid
                            key={`${shortcut.shortcutTypeId}-${shortcut.title}`}
                            className={classes.card}
                            style={gridStyle}
                            onClick={() => window.open(shortcut.url, "_blank")}>
                            <span>
                                <Link01 className={classes.icon} />
                            </span>
                            <span className={classes.cardBodyVerticalAlign}>
                                <span className={classes.cardText}>{shortcut.title}</span>
                            </span>
                        </Card.Grid>
                    );
                case 2:
                    return (
                        (userType === "user" || userType === "administrator") &&
                        hasSupplierAccess &&
                        companyPartnersAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                data-test="supplier_profile_shortcut_menu_item"
                                style={gridStyle}
                                onClick={() => setSelectSupplierModal(true)}>
                                <span>
                                    <ArrowNarrowUpVertical className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : isDefaultCompany
                                            ? t("shortcuts_goToSupplier")
                                            : t("shortcuts_GoToCustomer")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 3:
                    return (
                        (userType === "user" || userType === "administrator") &&
                        hasSupplierAccess &&
                        hasReviewAccess &&
                        companyPartnersAccess &&
                        companyReviewAccess &&
                        enabledPages.findIndex(
                            (page: ISupplierProfilePage) =>
                                page.pageName === "SegmentationManagement"
                        ) !== -1 && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                data-test="create_segmentation_shortcut_menu_item"
                                style={gridStyle}
                                onClick={() =>
                                    history.push("/Reviews/SurveyOverview/Create/Type/2")
                                }>
                                <span>
                                    <LayoutGrid01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <Link
                                        to="/Reviews/SurveyOverview/Create/Type/2"
                                        className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : t("survey_CreateNewSegmentation")}
                                    </Link>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 4:
                    return (
                        (userType === "user" || userType === "administrator") &&
                        hasSupplierAccess &&
                        hasReviewAccess &&
                        companyPartnersAccess &&
                        companyReviewAccess &&
                        enabledPages.findIndex(
                            (page: ISupplierProfilePage) =>
                                page.pageName === "PerformanceManagement"
                        ) !== -1 && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                data-test="create_performance_shortcut_menu_item"
                                style={gridStyle}
                                onClick={() =>
                                    history.push("/Reviews/SurveyOverview/Create/Type/3")
                                }>
                                <span>
                                    <BarChart07 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <Link
                                        to="/Reviews/SurveyOverview/Create/Type/3"
                                        className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : t("performance_CreateNewPerformance")}
                                    </Link>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 5:
                    return (
                        hasFilesFeature &&
                        (userType === "user" || userType === "administrator") &&
                        hasFilesAccess &&
                        companyFilesAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                data-test="upload_files_shortcut_menu_item"
                                style={gridStyle}
                                className={classes.card}
                                onClick={() => history.push("/Files")}>
                                <span>
                                    <FilePlus02 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <Link to="/Files" className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("uploadFiles")}
                                    </Link>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 6:
                    return (
                        ((userType === "user" && hasSupplierAccess && canCreateSupplier) ||
                            userType === "administrator") &&
                        companyPartnersAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="add_supplier_shortcut_menu_item"
                                onClick={() => setShowCreateModal(true)}>
                                <span>
                                    <Globe01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : isDefaultCompany
                                            ? t("addSuppliers")
                                            : t("addCustomers")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 7:
                    return (
                        userType === "administrator" && (
                            <Card.Grid
                                className={classes.card}
                                style={gridStyle}
                                key={shortcut.shortcutTypeId}
                                data-test="invite_user_shortcut_menu_item"
                                onClick={() => history.push("/Admin/UserManagement")}>
                                <span>
                                    <UserPlus01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <Link to="/Admin/UserManagement" className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : t("admin_User_InviteUsers")}
                                    </Link>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 8:
                    return (
                        hasReviewAccess &&
                        companyReviewAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="create_review_shortcut_menu_item"
                                onClick={() => setShowCreateReviewModal(true)}>
                                <span>
                                    <Star01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("createReview")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 9:
                    return (
                        hasComplianceAccess &&
                        companyComplianceAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="create_compliance_shortcut_menu_item"
                                onClick={() => history.push("/Compliance/Create")}>
                                <span>
                                    <ClipboardCheck className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <Link to="/Compliance/Create" className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("goToTemplates")}
                                    </Link>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 10:
                    return (
                        (userType === "user" || userType === "administrator") &&
                        hasComplianceAccess &&
                        companyComplianceAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="request_info_shortcut_menu_item"
                                onClick={() => setShowRequestModal(true)}>
                                <span>
                                    <Send01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("requestInfo")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 11:
                    return (
                        hasNcrFeature &&
                        hasNcrAccess &&
                        companyNcrAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="create_ncr_shortcut_menu_item"
                                onClick={() => setShowNcrModal(true)}>
                                <span>
                                    <InfoOctagon className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("createNcr")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 12:
                    return (
                        companyActionAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="create_action_shortcut_menu_item"
                                onClick={() => setShowCreateActionModal(true)}>
                                <span>
                                    <span>
                                        <ArrowsRight className={classes.icon} />
                                    </span>
                                    <span className={classes.cardBodyVerticalAlign}>
                                        <span className={classes.cardText}>
                                            {shortcut.title
                                                ? shortcut.title
                                                : t("action_CreateAction")}
                                        </span>
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 13:
                    return (
                        companyActionAccess && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                data-test="create_devPlan_shortcute_menu_item"
                                onClick={() => setToggleDevPlanModal(true)}>
                                <span>
                                    <span>
                                        <CalendarCheck01 className={classes.icon} />
                                    </span>
                                    <span className={classes.cardBodyVerticalAlign}>
                                        <span className={classes.cardText}>
                                            {shortcut.title
                                                ? shortcut.title
                                                : t("developmentPlan_AddPlan")}
                                        </span>
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 14:
                    return (
                        (userType === "user" || userType === "administrator") &&
                        hasComplianceAccess &&
                        companyComplianceAccess &&
                        hasProductAccess &&
                        hasProductFeature && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                onClick={() => setShowProductRequestModal(true)}>
                                <span>
                                    <Send01 className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title
                                            ? shortcut.title
                                            : t("product_RequestProductInfo")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                case 15:
                    return (
                        (userType === "user" || userType === "administrator") && (
                            <Card.Grid
                                key={shortcut.shortcutTypeId}
                                className={classes.card}
                                style={gridStyle}
                                onClick={() => setShowMeetingMinuteModal(true)}>
                                <span>
                                    <PlusCircle className={classes.icon} />
                                </span>
                                <span className={classes.cardBodyVerticalAlign}>
                                    <span className={classes.cardText}>
                                        {shortcut.title ? shortcut.title : t("createMeetingMinute")}
                                    </span>
                                </span>
                            </Card.Grid>
                        )
                    );
                default:
                    return <Card.Grid style={gridStyle}></Card.Grid>;
            }
        });
    };

    return (
        <div>
            <Row gutter={24}>
                {loadingSections ? <Skeleton paragraph={{ rows: 8 }} /> : renderSections()}
            </Row>
            {showCreateModal && (
                <CreateExternalPartnerModal
                    visible={showCreateModal}
                    onCancel={() => setShowCreateModal(false)}
                    onCreateExternalPartner={() => {}}
                    footer={"big"}
                />
            )}
            {showNcrModal && (
                <NcrCreationModal
                    visible={showNcrModal}
                    onVisibleChange={() => setShowNcrModal(false)}
                />
            )}
            {showRequestModal && (
                <RequestInfoModal
                    visible={showRequestModal}
                    onClose={() => setShowRequestModal(false)}
                />
            )}
            {showCreateReviewModal && (
                <ReviewCreationModal
                    visible={showCreateReviewModal}
                    onVisibleChange={() => setShowCreateReviewModal(false)}
                />
            )}
            {showCreateActionModal && (
                <ActionCreationModal
                    visible={showCreateActionModal}
                    onCancel={() => setShowCreateActionModal(false)}
                    editMode={false}
                    supplierProfileOverview={false}
                />
            )}
            {selectSupplierModal && (
                <SelectSupplierModal
                    visible={selectSupplierModal}
                    onClose={() => setSelectSupplierModal(false)}
                />
            )}
            {toggleDevPlanModal && (
                <DevelopmentPlanCreationModal
                    visible={toggleDevPlanModal}
                    onClose={() => setToggleDevPlanModal(false)}
                />
            )}
            {showProductRequestModal && (
                <RequestProductInfoModal
                    visible={showProductRequestModal}
                    onClose={() => setShowProductRequestModal(false)}
                />
            )}
            {showMeetingMinuteModal && (
                <CreateMeetingMinuteModal
                    partnerId={undefined}
                    visible={showMeetingMinuteModal}
                    onClose={() => setShowMeetingMinuteModal(false)}
                />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Shortcuts);
