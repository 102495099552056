import { SurveyType } from "models";
import { useEffect, useState } from "react";
import Provider from "services/api";

const useCommodityCategoriesByPartner = (
    partnerIds: number[] | undefined,
    level: string | undefined,
    type: SurveyType = SurveyType.Segmentation
) => {
    const [state, setState] = useState<any>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            if (partnerIds && level) {
                let data: any = await Provider.CommodityCategory.getCommodityCategoriesByPartner(
                    partnerIds,
                    level,
                    type,
                    {
                        customError: {
                            message: "Could not retrieve commodity categories",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data: data });
            } else {
                setState({ loading: false, data: [] });
            }
        })();
    }, [partnerIds]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCommodityCategoriesByPartner };
