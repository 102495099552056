import { useEffect, useState } from "react";
import { Provider } from "services";
import IDomain from "models/singleSignOn/IDomain";

export type UseSSODomainType = {
    data: { domains: IDomain[]; isInternalLogingAllowed: boolean; ssoEnabled: boolean };
    loading: boolean;
};

const useSSODomain = (reloadData: any) => {
    const [state, setState] = useState<UseSSODomainType>({
        loading: true,
        data: { domains: [], isInternalLogingAllowed: true, ssoEnabled: false },
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let domains: IDomain[] = [];
            const response = await Provider.Company.getSSOSettings();
            response.domainNames.forEach((element: any) => {
                domains.push({
                    id: element.id,
                    domain: element.domainName,
                });
            });
            setState({
                loading: false,
                data: {
                    domains: domains,
                    isInternalLogingAllowed: response.isInternalLogingAllowed,
                    ssoEnabled: response.ssoEnabled,
                },
            });
        })();
    }, [reloadData]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useSSODomain;
