import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import classes from "./selectFlatTreeData.module.scss";

type PropsType = {
    onSearch: Function;
    removeSearch: any;
};

const SelectFlatTreeDataSearch = ({ onSearch, removeSearch }: PropsType) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (removeSearch) {
            setSearch("");
            onSearch("");
        }
    }, [removeSearch]);

    return (
        <Input
            prefix={<SearchOutlined className={classes.searchIcon} />}
            className={classes.searchInput}
            placeholder={t("search")}
            onChange={(e: any) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
            }}
            value={search}
            allowClear
        />
    );
};

export { SelectFlatTreeDataSearch };
