import { useEffect, useState } from "react";
import { Provider } from "services";
import { IActivityChild, ICompliance, ComplianceDataTypeEnum } from "models";

type StateType = {
    loading: boolean;
    data: ICompliance | null;
};

const useCompliance = (
    type: ComplianceDataTypeEnum,
    complianceId: number | undefined,
    reload: any = null
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (complianceId === undefined) return;
            setState({ ...state, loading: true });

            let data;
            if (type === ComplianceDataTypeEnum.ForRegistrationDialog) {
                data = await Provider.Compliance.getComplianceForRegistrationDialog(complianceId, {
                    customError: {
                        message: "Could not retrieve registration data",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
            }
            if (type === ComplianceDataTypeEnum.ForEditPage) {
                data = await Provider.Compliance.getComplianceForEditPage(complianceId, {
                    customError: {
                        message: "Could not retrieve registration data",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
            }

            setState({ ...state, loading: false, data });
        })();
    }, [complianceId, reload]);

    const addActivity = (parentActivityId: number, newActivity: IActivityChild) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: [newActivity, ...state.data.childrenActivity],
                },
            });
        }
    };

    const removeActivity = (parentActivityId: number, activityId: number) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: state.data.childrenActivity.map(
                        (activity: IActivityChild) => {
                            if (activity.id !== activityId) {
                                return activity;
                            }
                            activity.isDeleted = true;
                            activity.canDelete = false;
                            return activity;
                        }
                    ),
                },
            });
        }
    };

    return {
        loading: state.loading,
        data: state.data,
        addActivity,
        removeActivity,
    };
};

export { useCompliance };
