import { useSelector } from "react-redux";
import { CompanyType } from "models";
import { AppState } from "store";

const useCompanyType = () => {
    const user = useSelector((state: AppState) => state.oidc.user);

    const isDefaultCompany =
        user && user.profile && user.profile["ll-companyType"] === CompanyType.default;

    return {
        isDefaultCompany,
    };
};

export { useCompanyType };
