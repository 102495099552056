import { useEffect, useState } from "react";
import Provider from "services/api";

const useDashboard = (
    dashboardId: string,
    supplierId: number,
    companyId: number,
    reloadDashboard: { reload: boolean },
    isSharedDashboard: boolean,
    loadDefault: boolean = false
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: {},
        defaultDashboardId: undefined,
        defaultCompanyId: undefined,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (supplierId !== undefined && supplierId !== -1) {
                if (loadDefault) {
                    let defaultDashboardId = await Provider.Analytics.getDefaultDashboardId();
                    if (defaultDashboardId !== "") {
                        dashboardId = defaultDashboardId;
                    }
                }
                if (dashboardId !== null && dashboardId !== "" && dashboardId !== undefined) {
                    let id = parseInt(dashboardId);
                    if (id !== -1) {
                        const data = await Provider.Analytics.getPreset(id, supplierId, companyId, {
                            retryCount: 3,
                            customError: { message: "Could not retrieve dashboard" },
                        }).catch(err => {
                            setState({ loading: false, data: {} });
                            return Promise.reject(err);
                        });
                        let layoutData = data.boxes.map((box: any) => {
                            return {
                                i: box.i,
                                x: box.x,
                                y: box.y,
                                w: box.w,
                                h: box.h,
                                isDraggable: !isSharedDashboard ? !data.isLocked : false,
                                isResizable: !isSharedDashboard ? !data.isLocked : false,
                            };
                        });
                        setState({
                            loading: false,
                            data: { data: data, layout: layoutData },
                            defaultDashboardId: loadDefault ? dashboardId : undefined,
                            defaultCompanyId: loadDefault ? data.companyId : undefined,
                        });
                    }
                }
            }
        })();
    }, [dashboardId, reloadDashboard]);

    return {
        loading: state.loading,
        data: state.data,
        defaultDashboardId: state.defaultDashboardId,
        defaultCompanyId: state.defaultCompanyId,
    };
};

export { useDashboard };
