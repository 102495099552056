import { SavedFilterType, ISavedReportFilter } from "models";
import { useState } from "react";
import { Provider } from "services";

const useSegmentationFilters = (filterType: SavedFilterType, reloadSavedFilters: any = null) => {
    const [filterLoading, setFilterLoading] = useState(false);
    const [saveFiltersLoading, setSaveFiltersLoading] = useState(false);
    const [filterInUseLoading, setFilterInUseLoading] = useState(false);
    const [deleteFilterLoading, setDeleteFilterLoading] = useState<string | undefined>(undefined);

    const getFilter = async (id: string) => {
        setFilterLoading(true);
        const data: any = await Provider.Segmentation.getSegmentationFilter(id, filterType);
        setFilterLoading(false);
        return data;
    };

    const saveFilters = async (filterName: string, filters: ISavedReportFilter[]) => {
        setSaveFiltersLoading(true);
        const filterId = await Provider.Segmentation.saveSegmentationFilter(
            filterName,
            filterType,
            filters,
            {
                customError: {
                    message: "Could not save the filter",
                },
            }
        ).catch(e => {
            setSaveFiltersLoading(false);
            return Promise.reject();
        });
        setSaveFiltersLoading(false);
        return filterId;
    };

    const filterInUse = async (filterName: string, filters: ISavedReportFilter[]) => {
        setFilterInUseLoading(true);
        const result = await Provider.Segmentation.segmentationFilterInUse(
            filterName,
            filterType,
            filters,
            {
                customError: {
                    message: "Could not check filter validation",
                },
            }
        ).catch(e => {
            setFilterInUseLoading(false);
            return Promise.reject();
        });
        setFilterInUseLoading(false);
        return result;
    };

    const deleteFilter = async (id: string) => {
        setDeleteFilterLoading(id);
        const result = await Provider.Segmentation.deleteSegmentationFilter(id, filterType, {
            customError: {
                message: "Could not delete filter",
            },
        }).catch(e => {
            setDeleteFilterLoading(undefined);
            return Promise.reject();
        });
        setDeleteFilterLoading(undefined);
        return result;
    };

    return {
        getFilter,
        filterLoading,
        saveFilters,
        saveFiltersLoading,
        filterInUse,
        filterInUseLoading,
        deleteFilter,
        deleteFilterLoading,
    };
};

export { useSegmentationFilters };
