import React, { FunctionComponent, Fragment } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from "./styles/shared.module.scss";
import { INcrDetail } from "models";
import { useTranslation } from "react-i18next";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: INcrDetail;
    isHomePage: boolean;
    onDisplayNcrModal: Function;
};

const Ncr: FunctionComponent<PropsType> = ({ data, isHomePage = false, onDisplayNcrModal }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: INcrDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewNcr", { id: data.id })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: INcrDetail) => {
        return (
            <Fragment>
                {t("activity_NewNcrByPartner", {
                    creator: data.creator,
                    id: data.id,
                    interpolation: { escapeValue: false },
                })}{" "}
                {data.fromOrOnPartner ? (
                    <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />
                ) : (
                    <Fragment>
                        {data.fromOrOnPartner ? (
                            <span>{data.fromOrOnPartner}</span>
                        ) : (
                            t("noPartner")
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    };
    const renderReason = (data: INcrDetail) => {
        return (
            data.ncrReason && (
                <Fragment>
                    {" "}
                    {t("activity_NcrWithReason")} <u>{data.ncrReason}</u>
                </Fragment>
            )
        );
    };

    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
                {renderReason(data)}
            </div>
            {isHomePage && (
                <Button
                    icon={<RightOutlined />}
                    type="link"
                    onClick={() =>
                        onDisplayNcrModal({
                            id: data.id,
                            visible: true,
                        })
                    }
                    className={classes.link}>
                    {t("activity_NcrView")}
                </Button>
            )}
        </Fragment>
    );
};

export default Ncr;
