import React, { Fragment, FunctionComponent } from "react";
import { Row, Input, Form, Col } from "antd";
import classes from "./styles/submissionMatrix.module.scss";
import { SelectData } from "components/utilitycomponents";
const TextArea = Input.TextArea;

interface IProps {
    fieldKey: any;
    type: "dropdown" | "textField";
    headline: any;
    required: boolean;
}

const SubmissionCard: FunctionComponent<IProps> = ({ type, fieldKey, headline, required }) => {
    return (
        <Fragment>
            {type === "textField" ? (
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name={[`supplierTextFieldAnswers`, fieldKey]}
                            rules={[{ required: required }]}>
                            <TextArea
                                placeholder={headline.description}
                                maxLength={250}
                                autoSize={{ minRows: 2, maxRows: 10 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <Row style={{ marginTop: "6px" }}>
                    <Col span={24}>
                        <Form.Item name={[`supplierCustomDropdownAnswers`, fieldKey]}>
                            <SelectData
                                getPopupContainer={(node: any) => document.body}
                                allowClear
                                className={classes.selectData}
                                placeholder={`Select a value`}
                                selectIdField="text"
                                selectOptionField="text"
                                useData={() => {
                                    return {
                                        data: headline.customDropdownOptions,
                                        loading: false,
                                    };
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default SubmissionCard;
