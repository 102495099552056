import { useEffect, useState } from "react";
import Provider from "services/api";

const useNotificationCount = (reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Notification.getUnreadNotificationCount().catch(
                (err: any) => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                }
            );
            setState({
                loading: false,
                data,
            });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNotificationCount };
