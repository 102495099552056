import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Input, notification, Form, Button } from "antd";
import classes from "./styles/saveModal.module.scss";
import { useParams } from "react-router-dom";
import { SelectData } from "components/utilitycomponents";
import { useDashboardUpdate } from "controller";
import { useTranslation } from "react-i18next";

interface IProps {
    visible: boolean;
    closeModal: Function;
    dashboardData: any;
    reloadDashboard: Function;
    benchmarkId: any;
    existingDashboards: any[];
}

const DashboardSaveModal = ({
    visible,
    closeModal,
    dashboardData,
    reloadDashboard,
    benchmarkId,
    existingDashboards,
}: IProps) => {
    const [form] = Form.useForm();
    const { id: supplierId, presetId }: any = useParams();
    const { updateDashboard } = useDashboardUpdate();
    const { t } = useTranslation();
    const selectedVisibility = Form.useWatch("dashboardVisibility", form);
    async function onSubmit() {
        let values = await form.validateFields();
        await updateDashboard(
            presetId,
            supplierId,
            {
                name: values.dashboardName,
                visibility: values.dashboardVisibility,
                dashboardType: "Default",
            },
            benchmarkId
        );
        notification.success({
            message: t("success"),
            description: t("analytics_SaveDashboardSuccess"),
        });
        closeModal();
        reloadDashboard();
        form.resetFields();
    }

    function onCancel() {
        form.resetFields();
        closeModal();
    }

    function renderVisibilityText(visibility: string) {
        switch (visibility) {
            case "Personal":
                return <span>{t("analytics_PersonalInfo")}</span>;
            case "Internal":
                return <span>{t("analytics_InternalInfo")}</span>;
            case "External":
                return (
                    <span>
                        <InfoCircleOutlined className={classes.infoIcon} />
                        {t("analytics_ExternalInfo")}
                    </span>
                );
            default:
                break;
        }
    }

    return dashboardData.data ? (
        <div>
            <Modal
                title={t("analytics_SaveDashboard")}
                closable={true}
                onOk={onSubmit}
                width={900}
                open={visible}
                mask={false}
                onCancel={(e: any) => {
                    onCancel();
                }}
                footer={[
                    <Button type="primary" onClick={async () => await onSubmit()}>
                        {t("generic_Save")}
                    </Button>,
                ]}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}>
                    <Form.Item
                        label={t("analytics_DashboardName")}
                        required
                        className={classes.formItem}
                        name="dashboardName"
                        initialValue={dashboardData.data.name}
                        rules={[
                            {
                                required: true,
                                validator: (rule: any, value: any, callback: any) => {
                                    if (!value || value.trim() === "")
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    if (
                                        existingDashboards.filter(
                                            (x: any) =>
                                                x.name.toLowerCase().trim() ===
                                                value.toLowerCase().trim()
                                        ).length > 0 &&
                                        value.toLowerCase().trim() !==
                                            dashboardData.data.name.toLowerCase().trim()
                                    ) {
                                        return Promise.reject(
                                            new Error(t("contact_GroupNameExists"))
                                        );
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}>
                        <Input maxLength={100} placeholder={t("analytics_DashboardPlaceholder")} />
                    </Form.Item>

                    <Form.Item
                        label={t("analytics_DashboardVisibility")}
                        className={classes.formItem}
                        name="dashboardVisibility"
                        valuePropName="value"
                        initialValue={dashboardData.data.visibility}
                        extra={renderVisibilityText(selectedVisibility)}>
                        <SelectData
                            selectOptionField="name"
                            useData={() => {
                                return {
                                    data: [
                                        { id: "Personal", name: t("analytics_Personal") },
                                        { id: "Internal", name: t("analytics_Internal") },
                                        { id: "External", name: t("analytics_External") },
                                    ],
                                    loading: false,
                                };
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    ) : (
        <div></div>
    );
};

export default DashboardSaveModal;
