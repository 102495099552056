import { useNcrFeature, useProductFeature } from "controller/company";
import { IShortcut, ShortcutType } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IShortcut[];
};

const useShortcutTypes = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    const { hasProductFeature } = useProductFeature();
    const { hasNcrFeature } = useNcrFeature();
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Company.getShortcutTypes({
                customError: {
                    message: "Could not retrieve sections",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            if (!hasProductFeature) {
                data = data.filter((item: any) => item.id !== ShortcutType.requestProductInfo);
            }
            if (!hasNcrFeature) {
                data = data.filter((item: any) => item.id !== ShortcutType.createNcr);
            }
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useShortcutTypes };
