import React, { FunctionComponent, Fragment } from "react";

type PropsType = {
    title: string;
    icon: any;
};

const CardTitle: FunctionComponent<PropsType> = ({ title, icon }) => {
    return (
        <Fragment>
            {icon}
            <span style={{ margin: "0 5px", fontWeight: "bold" }}>{title}</span>
        </Fragment>
    );
};

export default CardTitle;
