import React, { useEffect, useState } from "react";
import { useEmbedInfo, useEmbedExport } from "controller";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import classes from "./pbiEmbedReport.module.scss";
import { Button, Row, Col, Tooltip, message } from "antd";
import { IPbiExportType } from "models";
import { useTranslation } from "react-i18next";
import { FileDownload03 } from "../icons";

interface IProps {
    workspaceId: string | null | undefined;
    reportId: string | null | undefined;
    reportClassName?: string;
}

const PbiEmbedReport = ({ workspaceId, reportId, reportClassName }: IProps) => {
    const { t } = useTranslation();
    const [reload] = useState<any>({ reload: false });
    const { data, loading } = useEmbedInfo(workspaceId!, reportId!, reload);
    const [report, setReport] = useState<any>(null);
    const { exportRequest, loading: exporting } = useEmbedExport();

    const [embedInfo, setEmbedInfo] = useState<any>(data);
    useEffect(() => {
        setEmbedInfo(data);
    }, [data]);

    const makeExportRequest = async () => {
        let bookmark = await report.bookmarksManager.capture({
            allPages: true,
            personalizeVisuals: true,
        });
        await report.bookmarksManager.applyState(bookmark.state);
        message.info(t("pbi_Exporting"), 4);
        await exportRequest(reportId!, workspaceId!, IPbiExportType.PDF, bookmark);
    };

    return (
        <div style={{ minHeight: 725 }}>
            {!loading &&
            embedInfo !== undefined &&
            embedInfo.EmbedReport &&
            embedInfo.EmbedReport.length > 0 ? (
                <>
                    <PowerBIEmbed
                        embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
                            id: embedInfo.EmbedReport[0].ReportId,
                            embedUrl: embedInfo.EmbedReport[0].EmbedUrl,
                            accessToken: embedInfo.EmbedToken.Token, // Keep as empty string, null or undefined
                            tokenType: models.TokenType.Embed,
                            permissions: models.Permissions.Read,
                            settings: {
                                filterPaneEnabled: false,
                                navContentPaneEnabled: true,
                            },
                        }}
                        eventHandlers={
                            new Map([
                                ["loaded", function(e: any) {}],
                                ["rendered", async function(e: any) {}],
                                ["error", function(event: any) {}],
                                ["visualClicked", async (e: any) => {}],
                                ["pageChanged", event => {}],
                            ])
                        }
                        getEmbeddedComponent={embeddedReport => {
                            setReport(embeddedReport);
                        }}
                        cssClassName={reportClassName ? reportClassName : classes.pbiExample}
                    />
                    <Row className={classes.reportFooter}>
                        <Col span={20}>
                            <div style={{ fontSize: 8 }}>
                                Report Id: {embedInfo.EmbedReport[0].ReportId}
                            </div>
                        </Col>
                        <Col span={4} style={{ textAlign: "right" }}>
                            <Tooltip
                                title={t("pbi_ExportHint")}
                                placement="left"
                                mouseEnterDelay={1.5}>
                                <Button
                                    icon={<FileDownload03 />}
                                    className={classes.exportBtn}
                                    size="small"
                                    loading={exporting}
                                    type="dashed"
                                    onClick={async () => await makeExportRequest()}>
                                    {t("exportBtnText")}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </>
            ) : (
                <div style={{ marginLeft: 800, paddingTop: 400, fontSize: 20 }}>Loading...</div>
            )}
        </div>
    );
};

export { PbiEmbedReport };
