import { get, post } from "../base";
const SelfAssessment = {
    getTemplateForSelfAssessment: async (token: string, { customError }: any = {}) => {
        const url = `/v1/SelfAssessment/GetSelfAssessmentTemplate?token=${token}`;
        return await get({ url, customError });
    },
    createUpdateSelfAssessment: async (selfAssessment: any, { customError }: any = {}) => {
        const url = "/v1/SelfAssessment/CreateOrUpdateSelfAssessment";
        return await post({ url, payload: selfAssessment, customError });
    },
    getSelfAssessmentRequestContacts: async (
        partnerId: number,
        complianceTemplateId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Compliance/GetSelfAssessmentRequestContacts?partnerId=${partnerId}&templateId=${complianceTemplateId}`;
        return await get({ url, customError });
    },
};

export default SelfAssessment;
