import React, { useState } from "react";
import { Button, Dropdown, Tag } from "antd";
import {
    DotsHorizontal,
    Grid,
    PlusCircle,
    SwitchHorizontal01,
    Trash02,
    getColumnSearchProps,
    getColumnSelectProps,
} from "components/utilitycomponents";
import { usePartnerList, useProductOverview, useProductStatus } from "controller";
import { ProductDetails } from "./productDetails";
import { CreateModal } from "./createModal";
import { IProduct } from "models";
import { DeletionModal } from "./deletionModal";
import { MoveDocumentsModal } from "./moveDocumentsModal";
import { useTranslation } from "react-i18next";

const ProductOverview = () => {
    const { t } = useTranslation();
    const [productModal, toggleProductModal] = useState<any>({ visible: false, id: undefined });
    const [deletionModal, toggleDeletionModal] = useState<any>({ visible: false, id: undefined });
    const [createModal, toggleCreateModal] = useState<boolean>(false);
    const [moveDocumentsModal, toggleMoveDocumentsModal] = useState<any>({
        visible: false,
        product: undefined,
    });
    const [reload, setReload] = useState<any>({ reload: false });

    const handleMenuClick = (key: string, record: IProduct) => {
        if (key === "delete") {
            toggleDeletionModal({ id: record.id, visible: true });
        }
        if (key === "moveDocuments") {
            toggleMoveDocumentsModal({
                product: record,
                visible: true,
            });
        }
    };

    const columns = [
        {
            title: t("product_ProductId"),
            dataIndex: "productId",
            viewColumnId: "productId",
            key: "productId",
            ellipsis: true,
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "productId" }),
        },
        {
            title: t("generic_Name"),
            dataIndex: "name",
            viewColumnId: "name",
            key: "productName",
            ellipsis: true,
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "productName" }),
        },
        {
            title: t("product_Category"),
            dataIndex: "commodityCategory",
            viewColumnId: "commodityCategory",
            ellipsis: true,
            key: "commodityCategory",
            sorter: true,
            filterType: "string",
            ...getColumnSearchProps({ dataIndex: "commodityCategory" }),
        },
        {
            title: t("product_ProductStatus"),
            dataIndex: "productStatus",
            viewColumnId: "productStatus",
            key: "productStatus",
            ellipsis: true,
            width: 150,
            sorter: true,
            filterType: "array",
            ...getColumnSelectProps(
                useProductStatus,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "productStatus" }
            ),
            render: (text: any) => {
                switch (text) {
                    case "Active":
                        return (
                            <Tag bordered={false} color="#2db7f5">
                                {text}
                            </Tag>
                        );
                    case "Inactive":
                        return <Tag bordered={false}>{text}</Tag>;
                    case "Pending":
                        return (
                            <Tag bordered={false} color="#f50">
                                {text}
                            </Tag>
                        );

                    default:
                        return <></>;
                }
            },
        },
        {
            title: t("supplier"),
            dataIndex: "partners",
            viewColumnId: "partners",
            key: "suppliers",
            render: (_: any, record: any) =>
                record.partners &&
                record.partners.map((partner: any) => (
                    <Tag style={{ marginBottom: 2 }} bordered={false}>
                        {partner.partnerName}
                    </Tag>
                )),
            filterType: "array",
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "Suppliers", includeChildren: true }
            ),
        },
        {
            title: "",
            dataIndex: "grid_operation",
            key: "operations",
            viewColumnId: "operations",
            operationColumn: true,
            render: (_: any, record: IProduct) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: "delete",
                                label: t("product_DeleteProductLabel"),
                                disabled: !record.isUserCreated,
                                icon: <Trash02 />,
                            },
                            {
                                key: "moveDocuments",
                                label: t("product_MoveDocumentsLabel"),
                                icon: <SwitchHorizontal01 />,
                            },
                        ],
                        onClick: ({ key, keyPath, domEvent }) => {
                            domEvent.preventDefault();
                            domEvent.stopPropagation();
                            handleMenuClick(key, record);
                        },
                    }}>
                    <a
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                        <DotsHorizontal style={{ fontSize: 22, color: "#336DFF" }} />
                    </a>
                </Dropdown>
            ),
        },
    ];

    const handleOnRow = (record: any) => ({
        onClick: async () => {
            toggleProductModal({ id: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <div>
            <Grid
                title={() => (
                    <div>
                        <Button onClick={() => toggleCreateModal(true)}>
                            <PlusCircle />
                            {t("product_AddProduct")}
                        </Button>
                    </div>
                )}
                useData={useProductOverview.bind(null, undefined)}
                columns={columns}
                tableType="DevelopmentPlan"
                onRow={handleOnRow}
                reloadData={reload}
            />
            <CreateModal
                onClose={() => {
                    toggleCreateModal(false);
                    setReload({ reload: true });
                }}
                visible={createModal}
            />
            <ProductDetails
                id={productModal.id}
                visible={productModal.visible}
                onClose={(reload: any) => {
                    toggleProductModal(false);
                    if (reload === true) {
                        setReload({ reload: true });
                    }
                }}
            />
            <MoveDocumentsModal
                product={moveDocumentsModal.product}
                visible={moveDocumentsModal.visible}
                onClose={(reload: boolean) => {
                    toggleMoveDocumentsModal({
                        product: undefined,
                        visible: undefined,
                    });
                    if (reload) {
                        setReload({ reload: true });
                    }
                }}
            />
            <DeletionModal
                id={deletionModal.id}
                visible={deletionModal.visible}
                onClose={() => {
                    toggleDeletionModal({ id: undefined, visible: undefined });
                    setReload({ reload: true });
                }}
            />
        </div>
    );
};

export { ProductOverview };
