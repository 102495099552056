import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Input, Row, Button, Card, Col } from "antd";
import { UserImage } from "components/utilitycomponents";
import { useActivityMessageCreate } from "controller";
import { IActivityData } from "models";
import { AppState } from "store";
import classes from "./styles/shared.module.scss";
const TextArea = Input.TextArea;

type PropsType = {
    onAddMessage: (activity: IActivityData) => void;
    loading: boolean;
};

const AddMessage: FunctionComponent<PropsType> = ({ onAddMessage, loading }) => {
    const maximomLength = 4000;

    const { t } = useTranslation();
    const profile: any = useSelector((state: AppState) => state.oidc.user.profile);
    const [newMessage, setNewMessage] = useState("");
    const { loading: createLoading, create } = useActivityMessageCreate();

    const handleInputChange = (e: any) => {
        setNewMessage(e.target.value.substring(0, maximomLength));
    };

    const handleAddMessage = async () => {
        if (newMessage === "") return;
        const activity = await create({ message: newMessage });
        onAddMessage(activity);
        setNewMessage("");
    };

    return (
        <Card
            className={classes.activityCard}
            style={{ marginBottom: "12px", textAlign: "left", cursor: "auto" }}
            size="small">
            <Row align="middle" gutter={10}>
                <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={2}>
                    <UserImage
                        customDimension={65}
                        contactId={profile["ll-ContactId"]}
                        alt={profile["ll-Fullname"]}
                        style={{ width: "100%" }}
                        fileId={profile["ll-pictureId"] ? profile["ll-pictureId"] : undefined}
                    />
                </Col>
                <Col xs={24} sm={24} md={20} lg={20} xl={21} xxl={22}>
                    <TextArea
                        data-test="add_message_text_area"
                        className={classes.messageBoxMightUpdateWithVersion}
                        placeholder={t("activity_SharePlaceholder")}
                        autoSize={true}
                        allowClear={true}
                        onChange={handleInputChange}
                        value={newMessage}
                    />
                    <Row style={{ marginTop: "10px" }}>
                        <Col span={12}>
                            <Button
                                data-test="share_button"
                                type="primary"
                                onClick={() => !loading && handleAddMessage()}
                                loading={createLoading}>
                                {t("activity_Share")}
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            {maximomLength - newMessage.length}{" "}
                            {t("activity_CommentRemainingChars")}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default AddMessage;
