import { useState } from "react";
import { Provider } from "services";

const useCustomDropdownDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteCustomDropdowns = async (customDropdownIds: number[]) => {
        setLoading(true);
        const result = await Provider.Company.deleteCustomDropdowns(customDropdownIds, {
            customError: {
                message: "Could not delete custom dropdowns",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading,
        deleteCustomDropdowns,
    };
};

export { useCustomDropdownDelete };
