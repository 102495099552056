import React, { FunctionComponent, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ISupplierCardsRole } from "models";
import EditModal from "./editModal";
import EditButton from "../../editButton";
import HandShakeIcon from "./handShakeIcon";
import classes from "./styles/role.module.scss";

type PropsType = {
    partnerId: number;
    data: ISupplierCardsRole[];
};

const Role: FunctionComponent<PropsType> = ({ partnerId, data }) => {
    const { t } = useTranslation();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [roles, setRoles] = useState(data);
    const [role, setRole] = useState<ISupplierCardsRole | null>();

    const handleSave = ({ partnerContactRoleId, ...rest }: any) => {
        setRoles(
            roles.map((r: ISupplierCardsRole) => {
                if (r.partnerContactRoleId === partnerContactRoleId) {
                    return {
                        ...r,
                        ...rest,
                    };
                }
                return r;
            })
        );
    };

    const handleOnRow = (record: any) => ({
        onClick: () => {
            setRole(record);
            setEditModalVisible(true);
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <Fragment>
            <Table
                data-test="supplier_profile_roles_table"
                pagination={false}
                bordered
                scroll={{ x: 1600 }}
                onRow={handleOnRow}
                rowKey="partnerContactRoleId"
                columns={[
                    {
                        title: t("supplierProfile_Role"),
                        dataIndex: "partnerContactRole",
                        fixed: true,
                        width: 200,
                        render: (text: string) => (
                            <div style={{ display: "table", width: "100%" }}>
                                <div style={{ display: "table-cell" }}>{text}</div>
                                <div style={{ display: "table-cell", textAlign: "right" }}>
                                    <EditButton onClick={() => setEditModalVisible(true)} />
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: t("supplierProfile_RoleOurRepresentative"),
                        dataIndex: "ourRepresentative",
                        width: 200,
                        align: "center",
                        render: (text: string) => (
                            <div style={{ position: "relative" }}>
                                <div>{text}</div>
                                <div className={classes.handShake}>
                                    <HandShakeIcon />
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: t("supplierProfile_RoleTheirRepresentative"),
                        dataIndex: "theirRepresentative",
                        width: 200,
                        align: "center",
                    },
                    {
                        title: t("supplierProfile_RolePosition"),
                        dataIndex: "position",
                        width: 200,
                    },
                    {
                        title: t("supplierProfile_RoleLocation"),
                        dataIndex: "location",
                        width: 200,
                    },
                    {
                        title: t("supplierProfile_RolePhone"),
                        dataIndex: "phone",
                        width: 200,
                    },
                    {
                        title: t("supplierProfile_RoleEmail"),
                        dataIndex: "email",
                    },
                ]}
                dataSource={roles}
            />
            {role && (
                <EditModal
                    visible={editModalVisible}
                    partnerId={partnerId}
                    data={role}
                    onSave={handleSave}
                    onClose={() => {
                        setEditModalVisible(false);
                        setRole(null);
                    }}
                />
            )}
        </Fragment>
    );
};

export default Role;
