import { useEffect, useState } from "react";
import { Provider } from "services";

type UseContactsType = {
    data: any;
    loading: boolean;
};

const useComplianceNotifyUsers = (visibilityUsers: number[]) => {
    const [state, setState] = useState<UseContactsType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.User.getComplianceNotifyUsers(visibilityUsers, {
                customError: {
                    message: "Could not retrieve list of users to notify",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [visibilityUsers]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useComplianceNotifyUsers };
