import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import classes from "./style/riskGuid.module.scss";
import { ArrowDown, ArrowRight } from "components/utilitycomponents";

const RiskGuid: FunctionComponent = () => {
    const { t } = useTranslation();
    const greenColor = "#00b204";
    const yellowColor = "#fa9308";
    const redColor = "#b20000";
    return (
        <Fragment>
            <table className={classes.guidTable}>
                <tbody>
                    <tr>
                        <td colSpan={4} className={classes.noBorder} style={{ height: "40px" }}>
                            <b>{t("supplierProfile_CalculationOfScore")}</b>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.noBorder} style={{ height: "auto" }}></td>
                        <td
                            className={classes.noBorder}
                            colSpan={3}
                            style={{ textAlign: "left", verticalAlign: "bottom", height: "auto" }}>
                            {t("supplierProfile_RiskSeverity")}
                            <ArrowRight />
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.noBorder}>
                            <ArrowDown />
                            {t("supplierProfile_RiskLikelihood")}
                        </td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td style={{ backgroundColor: greenColor }}>
                            <div>{t("supplierProfile_RiskLow")}</div>
                            <div>-1-</div>
                        </td>
                        <td style={{ backgroundColor: greenColor }}>
                            <div>{t("supplierProfile_RiskLow")}</div>
                            <div>-2-</div>
                        </td>
                        <td style={{ backgroundColor: yellowColor }}>
                            <div>{t("supplierProfile_RiskMedium")}</div>
                            <div>-3-</div>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td style={{ backgroundColor: greenColor }}>
                            <div>{t("supplierProfile_RiskLow")}</div>
                            <div>-2-</div>
                        </td>
                        <td style={{ backgroundColor: yellowColor }}>
                            <div>{t("supplierProfile_RiskMedium")}</div>
                            <div>-4-</div>
                        </td>
                        <td style={{ backgroundColor: redColor }}>
                            <div>{t("supplierProfile_RiskHigh")}</div>
                            <div>-6-</div>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td style={{ backgroundColor: yellowColor }}>
                            <div>{t("supplierProfile_RiskMedium")}</div>
                            <div>-3-</div>
                        </td>
                        <td style={{ backgroundColor: redColor }}>
                            <div>{t("supplierProfile_RiskHigh")}</div>
                            <div>-6-</div>
                        </td>
                        <td style={{ backgroundColor: redColor }}>
                            <div>{t("supplierProfile_RiskHigh")}</div>
                            <div>-9-</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default RiskGuid;
