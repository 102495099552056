import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { Risks } from "./risks";
import { useTranslation } from "react-i18next";
import { RiskReport } from "./riskReport/riskReport";
import { useSupplierProfile } from "controller";
const { TabPane } = Tabs;

interface IProps {
    clickedTab: any;
    riskReports: any[];
    partnerId: number;
}

const RiskComponent = ({ clickedTab, riskReports, partnerId }: IProps) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("riskSummary");
    const [reload] = useState<any>({ reload: false });
    const { data: supplierData } = useSupplierProfile(partnerId, reload);

    useEffect(() => {
        setActiveTab(clickedTab);
    }, [clickedTab]);

    return (
        <>
            <Tabs
                tabPosition="left"
                size="large"
                tabBarGutter={24}
                activeKey={activeTab}
                onTabClick={(key: string) => setActiveTab(key)}
                renderTabBar={() => <></>}>
                <TabPane key="0" tab={t("supplierProfile_Enterprise_RiskSummary")}>
                    <Risks partnerId={partnerId} />
                </TabPane>
                {supplierData &&
                    riskReports.map((report: any, index: number) => {
                        return (
                            <TabPane
                                key={index + 1}
                                tab={t("supplierProfile_Enterprise_RiskReport")}>
                                <RiskReport
                                    reportObject={report}
                                    supplierNumber={supplierData.supplierNumber}
                                />
                            </TabPane>
                        );
                    })}
            </Tabs>
        </>
    );
};

export { RiskComponent };
