import React, { FunctionComponent } from "react";
import { DatePicker } from "antd";
import dayjs from "utilities/daysJsConfig";
import { useTranslation } from "react-i18next";
const RangePicker = DatePicker.RangePicker;

type PropsType = {
    onRangeChange: Function;
    from: string | undefined;
    to: string | undefined;
    defaultRange?: boolean;
    [key: string]: any;
};

const GridRangePicker: FunctionComponent<PropsType> = ({
    onRangeChange,
    from,
    to,
    defaultRange = true,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <RangePicker
            data-test="date_range_picker"
            onChange={(dates: any) =>
                onRangeChange({
                    from: dates && dates[0] ? dates[0].startOf("day").toISOString() : undefined,
                    to: dates && dates[1] ? dates[1].endOf("day").toISOString() : undefined,
                })
            }
            placeholder={[t("Start date"), t("End date")]}
            allowEmpty={[true, true]}
            value={[from ? dayjs(from).startOf("day") : null, to ? dayjs(to).endOf("day") : null]}
            presets={[
                {
                    label: t("dateRange_lastMonth"),
                    value: [
                        dayjs()
                            .subtract(1, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last3Months"),
                    value: [
                        dayjs()
                            .subtract(2, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last6Months"),
                    value: [
                        dayjs()
                            .subtract(5, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_last9Months"),
                    value: [
                        dayjs()
                            .subtract(9, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
                {
                    label: t("dateRange_lastYear"),
                    value: [
                        dayjs()
                            .subtract(11, "months")
                            .startOf("day"),
                        dayjs().endOf("day"),
                    ],
                },
            ]}
            format={"MMM D, YYYY"}
            allowClear={!defaultRange}
            style={{
                textAlign: "left",
                maxWidth: "300px",
            }}
            {...rest}
        />
    );
};

export default GridRangePicker;
