import React, { FunctionComponent, useContext } from "react";
import { SelectDataMultiple, User01 } from "components/utilitycomponents";
import { usePartnerFields, usePartnerCategories, usePartnerGroups } from "controller";
import classes from "../../compliance/matrixOverview/styles/sideFilter.module.scss";
import { useTranslation } from "react-i18next";
import { IColumnFilter } from "components/utilitycomponents/grid/models";
import { handleCustomFilterChange } from "components/utilitycomponents/grid/customFilterUtilities";
import { categoryType } from "../../../../models";
import { Button } from "antd";
import { FiltersContext } from "components/mainContainers/ncr/ncrProvider";

type PropsType = {
    onCustomFiltersChange?: Function;
    customFilters?: IColumnFilter[];
};

const SideFilter: FunctionComponent<PropsType> = ({
    onCustomFiltersChange = null,
    customFilters = [],
}) => {
    const filtersContext = useContext(FiltersContext);
    if (!filtersContext) {
        throw new Error("SomeComponent must be used within a FiltersProvider");
    }
    const { additionalFilters, setAdditionalFilters } = filtersContext;
    const { t } = useTranslation();
    const { data: headLineTitle } = usePartnerFields();
    const handleChange = (name: string, value: any, type: string) => {
        handleCustomFilterChange({
            name,
            value,
            type,
            customFilters,
            onCustomFiltersChange,
        });
    };
    function getCustomLabel(customHeadlines: string) {
        if (headLineTitle) {
            var headLine = headLineTitle.headlines.filter(
                (f: any) => f.name === customHeadlines
            )[0];
            return headLine === undefined ? "" : headLine.customLabel;
        }
    }

    let Group: number[] = [],
        Category1: number[] = [],
        Category2: number[] = [];

    for (let customFilter of customFilters) {
        let value = customFilter.value.split(",").map(v => Number(v));
        switch (customFilter.name) {
            case "Group":
                Group = value;
                break;
            case "Category1":
                Category1 = value;
                break;
            case "Category2":
                Category2 = value;
                break;
        }
    }
    return (
        <>
            <div className={classes.label}>
                {getCustomLabel("Group") ?? t("compliance_SelectGroup") + ":"}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("Group", value, "array");
                }}
                value={Group}
                useData={usePartnerGroups}
                selectIdField="id"
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                allowClear
                selectAll
            />
            <div className={classes.label}>
                {getCustomLabel("Category1") ?? t("NCR_SelectCategory1")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("Category1", value, "array");
                }}
                value={Category1}
                useData={usePartnerCategories.bind(null, categoryType.one)}
                selectIdField="id"
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                allowClear
                maxTagCount={3}
                selectAll
            />
            <div className={classes.label}>
                {getCustomLabel("Category2") ?? t("NCR_SelectCategory2")}
            </div>
            <SelectDataMultiple
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) => {
                    handleChange("Category2", value, "array");
                }}
                value={Category2}
                useData={usePartnerCategories.bind(null, categoryType.two)}
                selectIdField="id"
                selectOptionField="name"
                placeholder={t("compliance_NotSelected")}
                allowClear
                maxTagCount={3}
                selectAll
            />
            <div className={classes.label}>{t("filter_By_Label")}</div>

            <Button
                icon={<User01 />}
                onClick={() => {
                    setAdditionalFilters([...additionalFilters, { name: "MyNcrs", value: "true" }]);
                }}>
                {t("ncr_MyNcrs")}
            </Button>
        </>
    );
};

export default SideFilter;
