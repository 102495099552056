import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    Modal,
    Button,
    Col,
    Row,
    Input,
    notification,
    Tooltip,
    DatePicker,
    Spin,
    Tag,
    Form,
} from "antd";
import { FormRow, FormLabel, DownloadFile, CountDownChars } from "components/utilitycomponents";
import { useFile, useFileUpdate } from "controller";
import { FileExternalVisibilityEnum, FileInternalVisibilityEnum } from "models";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;

interface IProps {
    visible: boolean;
    data: any;
    onClose: (reload: boolean) => void;
}

const EditFileModal = ({ data: { name, id }, visible, onClose }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { data, loading } = useFile(id);
    const { isFileNameInUse, updateFile, loading: updateLoading } = useFileUpdate();
    const [alertDateEnabled, setAlertDateEnabled] = useState(false);
    const [endDateEnabled, setEndDateEnabled] = useState(false);
    const [internalVisibility, setInternalVisibility] = useState();
    const [externalVisibility, setExternalVisibility] = useState();

    useEffect(() => {
        if (data) {
            setAlertDateEnabled(data.alertDate !== null);
            setEndDateEnabled(data.endDate !== null);
            setInternalVisibility(data.internalVisibilityId);
            setExternalVisibility(data.externalVisibilityId);
        }
        form.resetFields();
    }, [data]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const payload = {
            id,
            name: fieldsValue.name + `${getFileExtension(data.name)}`,
            endDate: fieldsValue.endDate
                ? fieldsValue.endDate
                : data.canEditReminders
                ? null
                : data.endDate,
            alertDate: fieldsValue.alertDate
                ? fieldsValue.alertDate
                : data.canEditReminders
                ? null
                : data.alertDate,
            alertMessage: fieldsValue.alertMessage
                ? fieldsValue.alertMessage
                : data.canEditReminders
                ? null
                : data.alertMessage,
        };

        await updateFile(payload);
        notification.success({
            message: t("success"),
            description: t("file_UpdateFileSuccess"),
        });

        form.resetFields();
        onClose(true);
    };
    if (!loading && data === null) return null;
    return (
        <Modal
            wrapProps={{ "data-test": "folder_edit_modal" }}
            title={`${t("file_FileSettings")}: ${data && data.name ? data.name : name}`}
            open={visible}
            footer={[
                <Button key="ok" type="primary" onClick={handleSave} loading={updateLoading}>
                    {t("generic_Save")}
                </Button>,
            ]}
            onCancel={() => {
                form.resetFields();
                onClose(false);
            }}
            width={900}
            style={{ top: 20 }}>
            <Spin spinning={loading}>
                <Form form={form}>
                    <Row>
                        <Col span={16}>
                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <FormLabel>{t("file_FileName")}</FormLabel>
                                </Col>

                                <Col lg={14} xs={24}>
                                    {data ? (
                                        <DownloadFile
                                            file={{
                                                id,
                                                name: data && data.name ? data.name : name,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <FormLabel>{t("file_FolderName")}</FormLabel>
                                </Col>

                                <Col lg={14} xs={24}>
                                    {data ? data.folderName : ""}
                                </Col>
                            </FormRow>

                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <FormLabel>{t("file_InternalVisibility")}</FormLabel>
                                </Col>

                                <Col lg={14} xs={24}>
                                    {data ? data.internalVisibility : ""}
                                </Col>
                            </FormRow>
                            {internalVisibility === FileInternalVisibilityEnum.Internal && (
                                <Fragment>
                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <FormLabel>{t("file_ExternalVisibility")}</FormLabel>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            {data ? data.externalVisibility : ""}
                                        </Col>
                                    </FormRow>

                                    {externalVisibility === FileExternalVisibilityEnum.Selected && (
                                        <FormRow>
                                            <Col lg={10} xs={24}>
                                                <FormLabel>{t("file_SelectedPartners")}</FormLabel>
                                            </Col>

                                            <Col lg={14} xs={24}>
                                                {data &&
                                                    data.selectedPartners &&
                                                    data.selectedPartners.map((s: any) => (
                                                        <Tag color="blue">{s.companyName}</Tag>
                                                    ))}
                                            </Col>
                                        </FormRow>
                                    )}
                                </Fragment>
                            )}
                            <FormRow>
                                <Col lg={10} xs={24}>
                                    <FormLabel required>{t("file_RenameFile")}</FormLabel>
                                </Col>

                                <Col lg={14} xs={24}>
                                    <Form.Item
                                        name="name"
                                        initialValue={data ? getFileName(data.name) : undefined}
                                        validateTrigger="onBlur"
                                        rules={[
                                            { required: true, message: t("inputIsRequiredError") },
                                            {
                                                validator: async (
                                                    rule: any,
                                                    value: any,
                                                    callback: any
                                                ) => {
                                                    try {
                                                        if (
                                                            value.toLowerCase() !==
                                                            getFileName(data.name).toLowerCase()
                                                        ) {
                                                            const nameExist = await isFileNameInUse(
                                                                data.folderId,
                                                                `${value}${getFileExtension(
                                                                    data.name
                                                                )}`
                                                            );
                                                            if (nameExist) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        t("file_FileNameInUse")
                                                                    )
                                                                );
                                                            }
                                                        }
                                                    } catch (error) {
                                                        return Promise.reject(error);
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}>
                                        <Input
                                            addonAfter={
                                                data ? getFileExtension(data.name) : undefined
                                            }
                                            maxLength={
                                                256 -
                                                (data ? getFileExtension(data.name).length : 0)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </FormRow>
                            {data && data.canEditReminders && (
                                <Fragment>
                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <FormLabel>{t("file_EndDate")}</FormLabel>{" "}
                                            <Tooltip title={t("file_FileEndDateGuide")}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            <Form.Item
                                                name="endDate"
                                                initialValue={
                                                    data && data.endDate
                                                        ? dayjs(data.endDate)
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        required: endDateEnabled,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <DatePicker
                                                    format={"MMM D, YYYY"}
                                                    style={{ width: "100%" }}
                                                    placeholder={t("file_EndDate")}
                                                    allowClear
                                                    onChange={value => {
                                                        setEndDateEnabled(value !== null);
                                                    }}
                                                    disabledDate={(current: any) =>
                                                        current < dayjs().startOf("day")
                                                    }
                                                    getPopupContainer={node =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </FormRow>

                                    <FormRow>
                                        <Col lg={10} xs={24}>
                                            <FormLabel>{t("file_AlertDate")}</FormLabel>{" "}
                                            <Tooltip title={t("file_FileAlertDateGuide")}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </Col>

                                        <Col lg={14} xs={24}>
                                            <Form.Item
                                                name="alertDate"
                                                initialValue={
                                                    data && data.alertDate
                                                        ? dayjs(data.alertDate)
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        required: alertDateEnabled,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}>
                                                <DatePicker
                                                    format={"MMM D, YYYY"}
                                                    style={{ width: "100%" }}
                                                    placeholder={t("file_AlertDate")}
                                                    allowClear
                                                    onChange={value => {
                                                        setAlertDateEnabled(value !== null);
                                                    }}
                                                    disabledDate={(current: any) =>
                                                        current < dayjs().startOf("day")
                                                    }
                                                    getPopupContainer={node =>
                                                        node
                                                            ? (node.parentNode as HTMLElement)
                                                            : document.body
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </FormRow>
                                    {alertDateEnabled && (
                                        <FormRow>
                                            <Col lg={10} xs={24}>
                                                <label>{t("file_AlertMessage")}</label>{" "}
                                                <Tooltip title={t("file_AlertMessageGuide")}>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </Col>

                                            <Col lg={14} xs={24}>
                                                <Form.Item
                                                    name="alertMessage"
                                                    initialValue={
                                                        data ? data.alertMessage : undefined
                                                    }>
                                                    <CountDownChars maxChars={500}>
                                                        <TextArea
                                                            rows={4}
                                                            allowClear
                                                            placeholder={t("file_AlertMessage")}
                                                            maxLength={500}
                                                        />
                                                    </CountDownChars>
                                                </Form.Item>
                                            </Col>
                                        </FormRow>
                                    )}
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

const getFileExtension = (name: string) => {
    var index = name.lastIndexOf(".");
    return index !== -1 ? "." + name.slice(index + 1) : "";
};
const getFileName = (name: string) => {
    var index = name.lastIndexOf(".");
    return index !== -1 ? name.slice(0, index) : name;
};

export default EditFileModal;
