import { IOwlerCompanyInfo } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IOwlerCompanyInfo | undefined;
};

const useOwlerCompanyInfo = (domain: string | undefined, reload?: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            if (domain === undefined || domain === null) {
                setState({ loading: false, data: undefined });
                return;
            }
            setState({ ...state, loading: true });
            let data = await Provider.Owler.GetOwlerCompanyData(domain).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [domain, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useOwlerCompanyInfo;
