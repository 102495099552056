import { INewUser } from "models/user";
import { useState } from "react";
import { Provider } from "services";

const useUserRegistration = () => {
    const [loading, setLoading] = useState(false);

    const registerUser = async (userData: INewUser, invitationToken: string) => {
        setLoading(true);
        const result = await Provider.User.registerUser(userData, invitationToken, {
            customError: {
                message: "Failed to register user",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading,
        registerUser,
    };
};

export { useUserRegistration };
