import { get, post } from "../base";

const SupplierAccessSettings = {
    getSupplierAccessSettings: async ({ customError }: any = {}) => {
        const url = `/v1/AdminCompany/GetEnabledCompanyModules`;
        return await get({ url, customError });
    },
    updateSupplierAccessSetting: async (settingId: number, { customError }: any = {}) => {
        const url = `/v1/AdminCompany/UpdateEnabledCompanyModule?moduleId=${settingId}`;
        await post({ url, payload: {}, customError });
    },
};

export default SupplierAccessSettings;
