import { useEffect, useState } from "react";
import { categoryType, ICategory } from "models";
import { Provider } from "services";

type StateType = {
    data: ICategory[];
    loading: boolean;
};

const usePartnerCategories = (
    type: categoryType | "all" = "all",
    categoryName = "",
    reload: any = null
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Partner.getPartnerCategories(type.toString());
            setState({ loading: false, data });
        })();
    }, [type, categoryName, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default usePartnerCategories;
