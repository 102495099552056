import { get } from "../base";

const PartnerMetrics = {
    getReputationScore: async (
        partnerId: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Metrics/GetReputationScore?partnerId=${partnerId}`;

        let response = await get({
            url,
            retryCount: 3,
            customError: customError,
        });
        return response;
    },
    getCarbonScore: async (
        partnerId: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Metrics/GetLatestCarbonContributionScore/${partnerId}`;
        let response = await get({
            url,
            retryCount: 3,
            customError: customError,
        });
        return response;
    },
};

export default PartnerMetrics;
