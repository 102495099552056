import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useSurveyTemplate = (templateId: number | undefined, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: null,
    });

    useEffect(() => {
        let data;
        (async () => {
            if (!templateId) {
                setState({ data: null, loading: false });
            } else {
                setState({ data: null, loading: true });
                data = await Provider.Review.getSurveyTemplate(templateId, {
                    customError: {
                        message: "Could not get survey template",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ data, loading: false });
            }
        })();
    }, [templateId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyTemplate };
