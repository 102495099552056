import { useNcrFeature } from "controller";
import { ICompanyHeadline } from "models/admin/companySettings/ICompanyHeadline";
import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: { id: number; type: "PartnerOverviewHeadineSettings"; headlines: ICompanyHeadline[] };
};

const usePartnerFields = () => {
    const { hasNcrFeature } = useNcrFeature();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: { id: -1, type: "PartnerOverviewHeadineSettings", headlines: [] },
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Company.getCompanyHeadlines().catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            if (!hasNcrFeature) {
                data.typeValue = data.typeValue.filter(
                    (item: ICompanyHeadline) => item.name !== "NCRs" && item.name !== "NCRDaysOpen"
                );
            }
            setState({
                loading: false,
                data: { id: data.id, type: data.type, headlines: data.typeValue },
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerFields };
