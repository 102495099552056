import { useSelector } from "react-redux";
import { AppState } from "store";

const useCompletedRelationsOnboarding = () => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const showCompletedOnboarding =
        user &&
        user.profile &&
        user.profile["ll-CompletedRelationOnboarding"] === "false" &&
        user.profile["ll-companyType"] !== "Supplier" &&
        user.profile["ll-UserType"] !== "Guest";
    return {
        showCompletedOnboarding,
    };
};

export { useCompletedRelationsOnboarding };
