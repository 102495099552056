function aggragateNumberRanges(
    ranges: number[],
    min: number | string = "",
    max: number | string = ""
) {
    return `${ranges[0] === undefined ? min : ranges[0]},${
        ranges[1] === undefined ? max : ranges[1]
    }`;
}

export { aggragateNumberRanges };
