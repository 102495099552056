import React, { FunctionComponent } from "react";
import { DeleteOutlined } from "@ant-design/icons";

type PropsType = {
    style?: any;
    [key: string]: any;
};
const DeleteIcon: FunctionComponent<PropsType> = ({ style, ...rest }) => {
    return <DeleteOutlined type="delete" {...rest} style={{ color: "#ff7875", ...style }} />;
};

export { DeleteIcon };
