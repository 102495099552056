import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import classNames from "classnames";
import classes from "./styles/customIcon.module.css";

type CustomIconComponentProps = GetProps<typeof Icon>;

function FileCheck02(props: Partial<GetProps<typeof Icon>>) {
    const svg = () => (
        <svg
            className={classNames(classes.customIconDefaultStyle, props.className)}
            style={{ height: props.style?.fontSize, width: props.style?.fontSize }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H12M14 11H8M10 15H8M16 7H8M14.5 19L16.5 21L21 16.5"
                style={{ stroke: props.style?.color }}
            />
        </svg>
    );

    const FileCheck02Icon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props} />
    );
    return <FileCheck02Icon {...props} />;
}

export default FileCheck02;
