import React, { useState } from "react";
import { Alert, Modal, Checkbox, Flex, Skeleton, Button } from "antd";
import { useProductHasDocumentation, useProductDelete } from "controller";
import { useTranslation } from "react-i18next";

type Props = {
    id: number | undefined;
    visible: boolean;
    onClose: Function;
};

const DeletionModal = ({ id, visible, onClose }: Props) => {
    const { t } = useTranslation();
    const { data: hasDocumentation, loading: checkingForDocuments } = useProductHasDocumentation(
        id
    );
    const { deleteProduct, loading: deleting } = useProductDelete();
    const [confirmed, toggleConfirmed] = useState(false);

    const onSubmit = async () => {
        await deleteProduct(id);
        onClose();
    };

    return (
        <Modal
            title={<div style={{ height: 20 }}></div>}
            onOk={onSubmit}
            open={visible}
            onCancel={() => onClose()}
            footer={[
                <Button
                    loading={deleting}
                    type="primary"
                    danger
                    disabled={hasDocumentation === true || confirmed === false}
                    onClick={async () => await onSubmit()}>
                    {t("generic_Confirm")}
                </Button>,
            ]}>
            <Skeleton loading={checkingForDocuments}>
                {hasDocumentation === false && (
                    <>
                        <Alert
                            message={t("generic_Warning")}
                            description={t("product_DeleteInfo")}
                            type="warning"
                            showIcon
                        />
                        <Flex justify="center">
                            <Checkbox
                                style={{ marginTop: 32, marginBottom: 32 }}
                                onChange={() => toggleConfirmed(!confirmed)}>
                                {t("product_DeleteConfirmCheckBox")}
                            </Checkbox>
                        </Flex>
                    </>
                )}
                {hasDocumentation === true && (
                    <Alert
                        message={t("generic_BeAdvised")}
                        description={t("product_DeleteDisabledInfo")}
                        type="info"
                        showIcon
                    />
                )}
            </Skeleton>
        </Modal>
    );
};

export { DeletionModal };
