import { useState } from "react";
import { Provider } from "services";

const useTemplateCopy = () => {
    const [loading, setLoading] = useState(false);
    const copyTemplate = async (templateId: number, templateName: string) => {
        setLoading(true);
        let data = await Provider.Compliance.copyTemplate(templateId, templateName, {
            customError: {
                message: "Could not copy template",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const copyRegistrations = async (oldTemplateId: number, newTemplateId: number) => {
        let data = await Provider.Compliance.copyTemplateRegistrations(
            oldTemplateId,
            newTemplateId
        ).catch(() => {
            return;
        });

        return data;
    };

    return {
        loading,
        copyTemplate,
        copyRegistrations,
    };
};

export { useTemplateCopy };
