import { post, get } from "../base";

const AdminContact = {
    getContactUploadTemplate: async ({ customError, onDownloadProgress }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/AdminContact/GetContactUploadTemplate?rows=11`;
        return await get({
            url,
            responseType: "blob",
            customError,
            customConfig,
            onDownloadProgress,
        });
    },
    uploadContacts: async (fileId: number, { customError }: any = {}) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/AdminContact/UploadContacts?fileId=${fileId}`;
        await post({ url, payload: {}, customError, customConfig });
    },
};

export { AdminContact };
