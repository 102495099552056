import { useEffect, useState } from "react";
import { Provider } from "services";
import { IReviewRank } from "models";

type StateType = {
    data: IReviewRank | null;
    loading: boolean;
};

const useReviewRank = (partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            const data = await Provider.Partner.getReviewRank(partnerId, from, to, {
                customError: {
                    message: "Could not retrive data for review rank",
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data: { ...data.reviewRankDto, ...data.reviewTotalsTableDto },
            });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewRank };
