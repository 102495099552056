import React, { FunctionComponent, Fragment } from "react";
import { Overview } from "components/contentcomponents/reviews/automatedSurvey/overview";

export const AutomatedSurveyeOverview: FunctionComponent = () => {
    return (
        <Fragment>
            <Overview />
        </Fragment>
    );
};
export default AutomatedSurveyeOverview;
