import React, { FunctionComponent } from "react";
import { Rate } from "antd";
import classes from "./reviewRate.module.scss";
import { StarFilled } from "@ant-design/icons";

type PropsType = {
    val: number;
    [key: string]: any;
    allowHalf?: boolean;
};

const ReviewRate: FunctionComponent<PropsType> = ({ val, allowHalf = false, ...rest }) => {
    return (
        <div className={classes.rateContainer}>
            <Rate
                allowHalf={allowHalf}
                className={classes.rateComponent}
                disabled
                value={val}
                character={({ index }) => {
                    const starColor = val >= (index || 0) + 1 ? "#FAAD14" : "#ffffff";
                    return (
                        <span>
                            <StarFilled style={{ color: starColor }} />
                        </span>
                    );
                }}
                {...rest}
            />
        </div>
    );
};

export { ReviewRate };
