import { IMeetingForum } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IMeetingForum[];
};

const useMeetingForumsForSupplier = (
    supplierId: number,
    loadOnMount = false,
    reload: any = null
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (loadOnMount) {
                setState({ ...state, loading: true });
                let data = await Provider.Governance.getMeetingForumsForSupplier(supplierId, {
                    customError: {
                        message: "Could not retrieve meeting forums",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [loadOnMount, reload, supplierId]);

    const create = async (meeting: any) => {
        setCreateLoading(true);
        await Provider.Governance.updateSupplierMeetingForum(meeting, {
            customError: {
                message: "Could create meeting forum",
            },
        }).catch(err => {
            setCreateLoading(false);
            return Promise.reject(err);
        });
        setCreateLoading(false);
    };

    const update = async (meeting: any) => {
        setUpdateLoading(true);
        await Provider.Governance.updateSupplierMeetingForum(meeting, {
            customError: {
                message: "Could update meeting forum",
            },
        }).catch(err => {
            setUpdateLoading(false);
            return Promise.reject(err);
        });
        setUpdateLoading(false);
    };

    const remove = async (supplierId: number, templateIds: number[]) => {
        setDeleteLoading(true);
        await Provider.Governance.deleteSupplierMeetingForum(supplierId, templateIds, {
            customError: {
                message: "Could delete meeting forum",
            },
        }).catch(err => {
            setDeleteLoading(false);
            return Promise.reject(err);
        });
        setDeleteLoading(false);
    };

    return {
        loading: state.loading,
        data: state.data,
        create,
        update,
        remove,
        createLoading,
        updateLoading,
        deleteLoading,
    };
};

export { useMeetingForumsForSupplier };
