import React, { FunctionComponent, useState } from "react";
import {
    Grid,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
} from "components/utilitycomponents";
import {
    useRequestOverview,
    useTemplatesForFilter,
    useContacts,
    useCompanyType,
    usePartnerList,
    usePartnerFields,
} from "controller";
import { formatTime } from "utilities";
import RequestsHeader from "./requestsHeader";
import { useTranslation } from "react-i18next";

const Requests: FunctionComponent = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const { t } = useTranslation();
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.recalledBy !== null || record.canRecall !== true,
        }),
    };
    const { data: partnerFields } = usePartnerFields();

    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;
    const { isDefaultCompany } = useCompanyType();

    const columns = [
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "partnerName",
            key: "Partner",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 1,
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partnerName", includeChildren: true }
            ),
        },
        {
            title: t("compliance_Template"),
            dataIndex: "auditDefinitionName",
            key: "AuditDefinition",
            width: 250,
            sorter: true,
            filterType: "array",
            viewColumnId: 2,
            ...getColumnSelectProps(
                useTemplatesForFilter.bind(null),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "auditDefinitionName", includeKeyInText: true, includeChildren: true }
            ),
        },
        {
            title: t("compliance_MultipleAnswersEnabled"),
            dataIndex: "multipleAssesmentEnabled",
            key: "MultipleAssesmentEnabled",
            width: 250,
            sorter: true,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Yes"), value: true },
                            { text: t("generic_No"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "multipleAssesmentEnabled", radioChoice: true }
            ),
            viewColumnId: 3,
            render: (val: string) => <div>{val === "No" ? t("generic_No") : t("generic_Yes")}</div>,
        },
        {
            title: t("compliance_NumberOfAnswers"),
            dataIndex: "numberOfAnswers",
            key: "NumberOfAnswers",
            width: 200,
            sorter: true,
            filterType: "range",
            viewColumnId: 4,
            min: 0,
            max: 2147483647,
            ...getColumnMinMaxProps(0, 2147483647, { dataIndex: "numberOfAnswers" }),
        },
        {
            title: t("compliance_SentBy"),
            dataIndex: "sentBy",
            key: "SentBy",
            width: 200,
            sorter: true,
            filterType: "array",
            viewColumnId: 5,
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["userId"],
                    text: ["name"],
                },
                { dataIndex: "sentBy" }
            ),
        },
        {
            title: t("compliance_SentDate"),
            dataIndex: "sentDate",
            key: "SentDate",
            filterType: "date",
            width: 250,
            sorter: true,
            viewColumnId: 6,
            ...getColumnRangePickerProps({ dataIndex: "sentDate" }),
            render: (val: any) => val && formatTime(val),
        },
        {
            title: t("compliance_RecalledBy"),
            dataIndex: "recalledBy",
            key: "RecalledBy",
            width: 200,
            sorter: true,
            filterType: "array",
            viewColumnId: 7,
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["userId"],
                    text: ["name"],
                },
                { dataIndex: "recalledBy" }
            ),
        },
        {
            title: t("compliance_RecalledDate"),
            dataIndex: "recalledDate",
            key: "RecalledDate",
            filterType: "date",
            width: 350,
            sorter: true,
            viewColumnId: 8,
            ...getColumnRangePickerProps({ dataIndex: "recalledDate" }),
            render: (val: any) => val && formatTime(val),
        },
    ];

    return (
        <>
            <Grid
                data-test="requests_table"
                title={() => (
                    <RequestsHeader
                        selectedTokens={selectedRowKeys}
                        onReloadData={() => setReloadData({ reload: true })}
                        onClearSelectedRows={setSelectedRowKeys}
                    />
                )}
                columns={columns}
                fixedColumns={["partnerName"]}
                useData={useRequestOverview}
                reloadData={reloadData}
                tableType="ComplianceRequest"
                rowKey="token"
                rowSelection={rowSelection}
            />
        </>
    );
};

export default Requests;
