import { ITableInput } from "models";
import { post, get, remove } from "../base";

const Folder = {
    folderOverview: async (tableInput: ITableInput, { customError }: any = {}) => {
        const url = "/v1/Folder/Overview";
        return await post({ url, payload: tableInput, customError });
    },
    deleteFoldersAndFiles: async (ids: any, { customError }: any = {}) => {
        const url = "/v1/Folder/DeleteFoldersAndFiles";
        return await remove({ url, payload: ids, customError });
    },
    getFolder: async (id: any, { customError }: any = {}) => {
        const url = `/v1/Folder/GetFolderDetailById?id=${id}`;
        return await get({ url, customError });
    },
    getInternalVisibilities: async (folderId: number, { customError }: any = {}) => {
        if (folderId !== null && folderId !== undefined) {
            const url = `/v1/Folder/GetInternalVisibilites?folderId=${folderId}`;
            return await get({ url, customError });
        } else {
            const url = `/v1/Folder/GetInternalVisibilites`;
            return await get({ url, customError });
        }
    },
    getExternalVisibilities: async (folderId: number, { customError }: any = {}) => {
        if (folderId !== null && folderId !== undefined) {
            const url = `/v1/Folder/GetExtenalVisibilites?folderId=${folderId}`;
            return await get({ url, customError });
        } else {
            const url = `/v1/Folder/GetExtenalVisibilites`;
            return await get({ url, customError });
        }
    },
    updateFolder: async (folderData: any, { customError }: any = {}) => {
        const url = `/v1/Folder/Edit`;
        return await post({ url, payload: folderData, customError });
    },
    createFolder: async (name: string, parentId?: number, { customError }: any = {}) => {
        let url = `/v1/Folder/CreateFolder?folderName=${name}`;
        if (parentId) {
            url = url + `&parentId=${parentId}`;
        }
        return await post({ url, customError });
    },
    getFolderOrFileCreatorList: async ({ customError }: any = {}) => {
        const url = `/v1/Folder/GetContactsCreatedFileOrFolder`;
        return await get({ url, customError });
    },
    isFolderNameInUse: async (name: string, parentId: number | null, { customError }: any = {}) => {
        const url =
            parentId === null
                ? `/v1/Folder/isFolderNameInUse?folderName=${name}`
                : `/v1/Folder/isFolderNameInUse?folderName=${name}&&parentId=${parentId}`;
        return await get({ url, customError });
    },
    getFolders: async ({ customError }: any = {}) => {
        const url = `/v1/Folder/GetUploadFolders`;
        return await get({ url, customError });
    },
    checkFileNames: async (filesData: any, { customError }: any = {}) => {
        const url = `/v1/Folder/CheckFileNames`;
        return await post({ url, payload: filesData, customError });
    },
};

export default Folder;
