import React, { useEffect, useState } from "react";
import { Button, Col, message, Progress, Row, Tooltip } from "antd";
import {
    AlertTriangle,
    getColumnRangePickerProps,
    getColumnSearchProps,
    getColumnSelectProps,
    Grid,
    Link01,
} from "components/utilitycomponents";
import {
    useDevelopmentPlanOverview,
    useDevelopmentPlanTemplates,
    useReviewContactsFilter,
} from "controller";
import { useTranslation } from "react-i18next";
import classes from "../../summary.module.scss";
import { formatTime, getDomainUrl } from "utilities";
import { EditModal } from "components/contentcomponents/developmentPlans/editModal";
import { CreateModal } from "components/contentcomponents/developmentPlans/createModal";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    partnerId: number;
}

const DevelopmentPlanSummary = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState({ reload: false });
    const [customDevPlanFields, setCustomDevPlanFields] = useState<any>({});
    const [toggleEditModal, setToggleEditModal] = useState({ dataId: undefined, visible: false });
    const [from, setFrom] = useState(
        dayjs()
            .subtract(6, "months")
            .toISOString()
    );
    const [to, setTo] = useState(
        dayjs()
            .add(1, "day")
            .toISOString()
    );
    const [toggleShareButton, setToggleShareButton] = useState<any>(undefined);
    const [createModal, setCreateModal] = useState<boolean>(false);

    useEffect(() => {
        setReload({ reload: true });
    }, [partnerId]);

    const getStrokeColor = (
        completedActions: number,
        overDueActionsCount: number,
        actionsCount: number
    ) => {
        let percentage = (completedActions / actionsCount) * 100;
        if (percentage === 100) {
            return {
                "0%": "#00b204",
                "100%": "#00b204",
            };
        } else if (percentage < 100 && overDueActionsCount === 0) {
            return {
                "0%": "#0477a9",
                "100%": "#0477a9",
            };
        } else if (percentage < 100 && overDueActionsCount !== 0) {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        } else {
            return {
                "0%": "#b20000",
                "100%": "#b20000",
            };
        }
    };
    const handleOnRow = (record: any) => ({
        onClick: async () => {
            setToggleEditModal({
                dataId: record.id,
                visible: true,
            });
        },
        onMouseEnter: () => {
            setToggleShareButton(record.id);
        },
        onMouseLeave: () => {
            setToggleShareButton(undefined);
        },
        style: {
            cursor: "pointer",
        },
    });

    const handleCopyUrl = async (planId: number) => {
        await navigator.clipboard.writeText(`${getDomainUrl()}/DevelopmentPlans/${planId}`);
        message.success(t("generic_CopiedToClipboard"));
    };

    const columns = [
        {
            title: "Template",
            viewColumnId: "template",
            dataIndex: "templateName",
            key: "Template",
            filterType: "array",
            ellipsis: true,
            sorter: true,
            ...getColumnSelectProps(
                useDevelopmentPlanTemplates.bind(null),
                {
                    value: ["id"],
                    text: ["title"],
                },
                { dataIndex: "Template" }
            ),
        },
        {
            title: "Title",
            viewColumnId: "title",
            dataIndex: "title",
            key: "title",
            sorter: true,
            filterType: "string",
            ellipsis: true,
            ...getColumnSearchProps({ dataIndex: "title" }),
        },
        {
            title: "Progress",
            viewColumnId: "progress",
            dataIndex: "actionsCount",
            key: "Action",
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("overdue_actions"), value: "OverdueAction" },
                            { text: t("no_overdue_actions"), value: "NoOverdueAction" },
                            { text: t("action_AllCompletedActions"), value: "CompletedAction" },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "Action", radioChoice: true }
            ),
            render: (_: any, record: any) => (
                <div className={classes.progress}>
                    <div className={classes.overDueAction}>
                        <Tooltip
                            title={t("developmentPlan_OverDueActionTooltip", {
                                count: record.overDueActionsCount,
                            })}>
                            <span>
                                <AlertTriangle
                                    style={{
                                        display: record.overDueActionsCount !== 0 ? "" : "none",
                                    }}
                                    className={classes.overDueActionIcon}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <Progress
                        success={{ percent: 0 }}
                        size="small"
                        width={100}
                        percent={(record.completedActions / record.actionsCount) * 100}
                        format={val => (
                            <span>{`${record.completedActions} / ${record.actionsCount}`}</span>
                        )}
                        strokeWidth={20}
                        strokeColor={getStrokeColor(
                            record.completedActions,
                            record.overDueActionsCount,
                            record.actionsCount
                        )}
                    />
                </div>
            ),
        },
        {
            title: "Next Deadline",
            viewColumnId: "nextDeadline",
            dataIndex: "dueDate",
            filterType: "date",
            sorter: true,
            key: "Deadline",
            ...getColumnRangePickerProps({ dataIndex: "Deadline" }),
            render: (val: any) => val && formatTime(val, "DD MMM YYYY"),
        },
        {
            title: "Next Action",
            viewColumnId: "nextAction",
            dataIndex: "assignedTo",
            key: "Owner",
            filterType: "array",
            width: 50,
            sorter: true,
            ...getColumnSelectProps(
                useReviewContactsFilter.bind(null, from, to),
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "owner" }
            ),
            render: (val: string, record: any) => (
                <Row>
                    <Col span={toggleShareButton !== record.id ? 24 : 16}>{val}</Col>
                    {toggleShareButton === record.id && (
                        <Col span={4}>
                            <Tooltip title={t("generic_CopyLink")}>
                                <span>
                                    <Link01
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            handleCopyUrl(record.id);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            ),
        },
    ];
    return (
        <>
            <Grid
                showHeader={false}
                className={classes.customTableStyle}
                hideGridSetting={true}
                containerMargin={false}
                tableType="DevelopmentPlan"
                title={() => (
                    <div className={classes.tableTitle}>
                        {t("developmentPlans_Overview").toLocaleUpperCase()}
                        {customDevPlanFields.dataLength !== 0 && (
                            <span>
                                <Button
                                    style={{
                                        float: "right",
                                        height: 20,
                                        marginTop: -5,
                                        marginRight: -6,
                                    }}
                                    type="link"
                                    onClick={() => setCreateModal(true)}>
                                    {t("generic_Add")}
                                </Button>
                            </span>
                        )}
                        <span className={classes.devPlanCounts}>
                            <span>
                                <strong>{customDevPlanFields.totalActive}</strong>
                                {t("developmentPlans_Active")}
                            </span>
                            <span>|</span>
                            <span>
                                <strong>{customDevPlanFields.totalCompleted}</strong>
                                {t("developmentPlans_Completed")}
                            </span>
                        </span>
                    </div>
                )}
                saveColumnSettings={false}
                pagination={false}
                columns={columns}
                useData={useDevelopmentPlanOverview.bind(null, partnerId)}
                onDataCustomFields={(customFields: any) =>
                    customFields !== null && setCustomDevPlanFields(customFields)
                }
                onRow={handleOnRow}
                reloadData={reload}
                onRangeChange={(val: any) => {
                    setFrom(val.from);
                    setTo(val.to);
                }}
                showEmptyText={true}
                customEmptyText={() => (
                    <div className={classes.devPlanEmtptyText}>
                        <Button type="primary" onClick={() => setCreateModal(true)}>
                            Add Plan
                        </Button>
                    </div>
                )}
                scroll={{ y: 200 }}
            />
            {createModal && (
                <CreateModal
                    visible={createModal}
                    onClose={(reload: boolean) => {
                        if (reload) {
                            setReload({ reload: true });
                        }
                        setCreateModal(false);
                    }}
                    manuallySetPartnerId={partnerId}
                />
            )}
            {toggleEditModal.visible && (
                <EditModal
                    dataId={toggleEditModal.dataId}
                    visible={toggleEditModal.visible}
                    onClose={(reload: boolean) => {
                        setToggleEditModal({ dataId: undefined, visible: false });
                        if (reload) {
                            setReload({ reload: true });
                        }
                    }}
                    onActionUpdate={() => setReload({ reload: true })}
                />
            )}
        </>
    );
};

export { DevelopmentPlanSummary };
