import { useEffect, useState } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";
import { useCompanyType } from "controller/company";

const useUserManagementSelectOptions = (reload = null) => {
    const { isDefaultCompany } = useCompanyType();
    const { t } = useTranslation();
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.User.getUserManagementSelectOptions({
                customError: {
                    message: "Could not retrieve options",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            data.userTypes = data.userTypes.map((x: any) => {
                switch (x.name) {
                    case "lightuser":
                        return { name: t("generic_UserType_Light"), id: x.id };
                    case "user":
                        return { name: t("generic_UserType_Normal"), id: x.id };
                    case "administrator":
                        return { name: t("generic_UserType_Admin"), id: x.id };
                    default:
                        return "";
                }
            });
            if (!isDefaultCompany) {
                data.userTypes = data.userTypes.filter(
                    (x: any) => x.name !== t("generic_UserType_Light")
                );
            }
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useUserManagementSelectOptions };
