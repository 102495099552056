import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Card, Spin, Result } from "antd";
import { useNcrRegistration } from "controller";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChildActivities, PageContainer, UserImage } from "components/utilitycomponents";
import NCRHeader from "./ncrHeader";
import NCRDetails from "./ncrDetail";
import { getCustomLabel } from "../../getCustomLabel";
import { getCustomLabelVisiblity } from "../../getCustomLabelVisibility";
import NCRActions from "./ncrActions";

const GuestRegistrationModal: FunctionComponent = () => {
    const { t } = useTranslation();
    const { guestToken }: any = useParams();
    const [reload, setReload] = useState({ reload: false });
    const { loading, data } = useNcrRegistration(guestToken, reload);
    const [ncrFields, setNcrFields] = useState<any[]>([]);

    useEffect(() => {
        if (data !== null) {
            if (data.customFields) {
                setNcrFields(data.customFields.ncrFields);
            }
        }
    }, [data]);

    let {
        purchaseOrderNumberLabel,
        valueLabel,
        otherCostsLabel,
        commentLabel,
        salesOrderNumberLabel,
        deliveryNoteLabel,
        otherCostsReasonLabel,
        deviationsLabel,
        deviationsProductNumberLabel,
        deviationsDescriptLabel,
        deviationsOrderedLabel,
        deviationsDeliveredLabel,
        deviationsFaultyLabel,
    } = getCustomLabel(ncrFields);

    let {
        purchaseOrderNumberVisiblity,
        valueVisiblity,
        otherCostsVisiblity,
        commentVisiblity,
        salesOrderNumberVisiblity,
        deliveryNoteVisiblity,
        otherCostsReasonVisiblity,
        deviationsVisiblity,
        deviationsProductNumberVisiblity,
        deviationsDescriptVisiblity,
        deviationsOrderedVisiblity,
        deviationsDeliveredVisiblity,
        deviationsFaultyVisiblity,
    } = getCustomLabelVisiblity(ncrFields);

    if (loading && data === null) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    if (!loading && (data === null || data === ""))
        return (
            <PageContainer settingContainer>
                <Result status="info" title={t("ncr_InvalidNcrToken")} />
            </PageContainer>
        );

    return (
        <PageContainer>
            <Card>
                <Row gutter={8}>
                    <Col span={2}>
                        <UserImage
                            alt={data.creator}
                            style={{ width: "100%" }}
                            fileId={
                                "creatorProfilePictureId" in data &&
                                data.creatorProfilePictureId !== null
                                    ? data.creatorProfilePictureId
                                    : undefined
                            }
                            customDimension={65}
                        />
                    </Col>
                    <Col span={22}>
                        <NCRHeader data={data} />
                        <Row style={{ marginTop: "50px" }}>
                            <Col span={24}>
                                <NCRDetails
                                    data={data}
                                    ncrValueLabel={valueLabel!}
                                    otherCostsLabel={otherCostsLabel!}
                                    OtherCostsReasonLabel={otherCostsReasonLabel!}
                                    purchaseOrderNumberLabel={purchaseOrderNumberLabel!}
                                    salesOrderNumberLabel={salesOrderNumberLabel!}
                                    deliveryNoteLabel={deliveryNoteLabel!}
                                    commentLabel={commentLabel!}
                                    ncrValueVisibility={valueVisiblity}
                                    otherCostsVisibility={otherCostsVisiblity}
                                    otherCostsReasonVisibilty={otherCostsReasonVisiblity}
                                    purchaseOrderNumberVisibility={purchaseOrderNumberVisiblity}
                                    salesOrderNumberVisibility={salesOrderNumberVisiblity}
                                    deliveryNoteVisibility={deliveryNoteVisiblity}
                                    commentVisibility={commentVisiblity}
                                    deviationsVisibility={deviationsVisiblity}
                                    deviationsLabel={deviationsLabel}
                                    deviationsProductNumberLabel={deviationsProductNumberLabel}
                                    deviationsProductNumberVisiblity={
                                        deviationsProductNumberVisiblity
                                    }
                                    deviationsDescriptLabel={deviationsDescriptLabel}
                                    deviationsDescriptVisiblity={deviationsDescriptVisiblity}
                                    deviationsOrderedLabel={deviationsOrderedLabel}
                                    deviationsOrderedVisiblity={deviationsOrderedVisiblity}
                                    deviationsDeliveredLabel={deviationsDeliveredLabel}
                                    deviationsDeliveredVisiblity={deviationsDeliveredVisiblity}
                                    deviationsFaultyLabel={deviationsFaultyLabel}
                                    deviationsFaultyVisiblity={deviationsFaultyVisiblity}
                                />
                            </Col>
                        </Row>
                        {data.ncrActions.length > 0 && (
                            <NCRActions
                                ncr={data}
                                reloadTable={() => setReload({ reload: true })}
                            />
                        )}
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={24}>
                                <ChildActivities
                                    data={data.childrenActivity}
                                    parentActivityId={data.activityId}
                                    onRemoveActivity={() => setReload({ reload: true })}
                                    onAddActivity={() => setReload({ reload: true })}
                                    guestCommentary={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageContainer>
    );
};

export default GuestRegistrationModal;
