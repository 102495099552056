import React, { Fragment, useState, useEffect } from "react";
import { useActivities, usePrevious } from "controller";
import Activity from "./activity";
import { LoadingIndicator } from "components/utilitycomponents";
import classes from "./styles/activities.module.scss";
import AddMessage from "./addMessage";
import { IActivityData } from "models";
import RegistrationModal from "components/contentcomponents/compliance/overview/registrationModal/registrationModal";
import NcrModal from "components/contentcomponents/ncr/overview/modals/registration/registrationModal";
import ReviewModal from "components/contentcomponents/reviews/review/modals/registration/registrationModal";
interface IProps {
    reloadActivities: { reload: boolean };
}

const Activities = ({ reloadActivities }: IProps) => {
    const pageSize = 10;
    const [pageNumber, setPageNumber] = useState(1);
    const [noMoreActivities, setNoMoreActivities] = useState<null | string>(null);
    const [lockLoadingData, setlockLoadingData] = useState<boolean>(false);
    const [registrationModal, setRegistrationModal] = useState({
        id: 0,
        visible: false,
    });
    const [ncrModal, setNcrModal] = useState({
        id: 0,
        visible: false,
    });
    const [reviewModal, setReviewModal] = useState({
        id: 0,
        visible: false,
    });
    const {
        loading,
        data,
        addActivity,
        addChildActivity,
        removeActivity,
        removeChildActivity,
        changeFollowActivity,
    } = useActivities(pageNumber, pageSize, reloadActivities);

    const prevLoading = usePrevious(loading);
    if (!!prevLoading === true && loading === false && lockLoadingData === true) {
        setlockLoadingData(false);
    }

    const handleScrollReachedBottom = () => {
        if (!loading) {
            if (data.numberOfTotalActivities > pageNumber * pageSize) {
                setPageNumber(pageNumber + 1);
            } else {
                setNoMoreActivities("No More Activities");
            }
        }
    };

    const handleScroll = () => {
        const scrollNode = document.querySelector("#contentSection");
        const offset: number = 2;
        if (scrollNode !== null) {
            const scrollPosition = scrollNode.scrollTop + scrollNode.clientHeight;
            const height = scrollNode.scrollHeight - offset;

            if (scrollPosition >= height && !lockLoadingData) {
                setlockLoadingData(true);
                handleScrollReachedBottom();
            }
        }
    };

    useEffect(() => {
        const scrollNode = document.querySelector("#contentSection");
        if (scrollNode !== null) {
            scrollNode.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollNode !== null) {
                scrollNode.removeEventListener("scroll", handleScroll);
            }
        };
    });

    return (
        <Fragment>
            <AddMessage onAddMessage={addActivity} loading={loading} />
            {loading && pageNumber === 1 ? (
                <LoadingIndicator style={{ fontSize: "30px" }} />
            ) : (
                <Fragment>
                    {data.activitiesData.map((activityData: IActivityData, index: number) => (
                        <Activity
                            key={index}
                            activityData={activityData}
                            onAddActivity={addChildActivity}
                            onRemoveActivity={removeActivity}
                            onRemoveChildActivity={removeChildActivity}
                            onDisplayRegistrationModal={setRegistrationModal}
                            onDisplayNcrModal={setNcrModal}
                            onDisplayReviewModal={setReviewModal}
                        />
                    ))}
                    <div className={classes.loadMoreContainer}>
                        {loading && pageNumber !== 1 && (
                            <LoadingIndicator style={{ fontSize: "30px" }} />
                        )}
                        {noMoreActivities}
                    </div>
                </Fragment>
            )}

            {registrationModal.visible && (
                <RegistrationModal
                    id={registrationModal.id}
                    visible={registrationModal.visible}
                    onClose={() => {
                        setRegistrationModal({ ...registrationModal, visible: false });
                    }}
                    onChangeFollowActivity={changeFollowActivity}
                    onAddActivity={addChildActivity}
                    onRemoveActivity={removeChildActivity}
                />
            )}
            {ncrModal.visible && (
                <NcrModal
                    id={ncrModal.id}
                    visible={ncrModal.visible}
                    onVisibleChange={() =>
                        setNcrModal({
                            ...ncrModal,
                            visible: false,
                        })
                    }
                    onChangeFollowActivity={changeFollowActivity}
                    onAddActivity={addChildActivity}
                    onRemoveActivity={removeChildActivity}
                />
            )}
            {reviewModal.visible && (
                <ReviewModal
                    id={reviewModal.id}
                    visible={reviewModal.visible}
                    onVisibleChange={() => setReviewModal({ ...reviewModal, visible: false })}
                    onChangeFollowActivity={changeFollowActivity}
                    onAddActivity={addChildActivity}
                    onRemoveActivity={removeChildActivity}
                />
            )}
        </Fragment>
    );
};

export default Activities;
