import React, { SyntheticEvent, Fragment, ReactNode } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../store";
import { Tooltip, Tag, Popconfirm, notification, Button } from "antd";
import ICustomColumn from "../../../models/suppliers/customcolumns/ICustomColumn";
import IHeadlineSetting from "../../../models/suppliers/IHeadlineSetting";
import "./supplierTable.css";
import ICompanySettings from "../../../models/suppliers/ICompanySettings";
import { NCRModalProps } from "../../../store/ncrModal/types";
import { showModal } from "../../../store/ncrModal/actions";
import Provider from "services/api";
import { setExcelExport } from "store/excelExport/actions";
import { ExcelExportProps } from "store/excelExport/types";
import { CompanyType, ISupplierProfilePage, LinkStatusType, ReportType } from "models";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import {
    Grid,
    MultipleTags,
    ColoredPercentTag,
    getColumnMinMaxProps,
    getColumnSelectProps,
    getFilterIcon,
    getColumnSearchProps,
    getColumnRangePickerProps,
    ReviewRate,
    BarChart07,
    InfoCircle,
    XCircle,
} from "components/utilitycomponents";
import {
    useAwardsSettings,
    useMasterDataOverview,
    usePartnerCategories,
    usePartnerGroups,
    usePartnerOverview,
    usePartnerOwners,
    usePartnerStatuses,
    useSegmentCategories,
} from "controller";
import { PartnerModelMapper } from "services";
import IMasterDataPartner from "models/suppliers/IMergeSetChildren";
import { categoryType } from "models";
import { Spin } from "antd";
import dayjs from "utilities/daysJsConfig";
import { SupplierProfileModal } from "./supplierProfile/supplierProfileModal";
import { scoreColor } from "./supplierProfile/scorecard/scorecardGradient";
import classes from "./styles/styles.module.scss";
interface IPartnerTableState {
    selectedRows: any[];
    selectedRowKeys: string[];
    masterDataContextMenu: {
        visible: boolean;
        x: number;
        y: number;
        rowId: string;
        record?: IMasterDataPartner;
    };
    overviewContextMenu: {
        visible: boolean;
        x: number;
        y: number;
        rowId: string;
    };
    loading: boolean;
    searchText: string;
    customColumns: ICustomColumn[];
    customHeadlines: IHeadlineSetting;
    allowHeaderClick: boolean;
    companySettings: ICompanySettings;
    fromDateString: string;
    toDateString: string;
    reload: any;
    columns: any[];
}

interface ISupplierProfileState {
    showSupplierProfileModal: boolean;
    supplierProfilePartnerId?: number;
}

interface IPartnerTableProps extends WithTranslation {
    masterDataMenu?: () => ReactNode;
    onSelectedRowsKeysChange?(selectedRowsKeys: any[]): void;
    onSelectedRowsChange?(selectedRows: any[]): void;
    selectedRows?: any[];
    selectedRowKeys?: string[];
    selectableRows: boolean;
    onRowClick?(
        show: boolean,
        selectedRow: IMasterDataPartner | undefined,
        canEditSupplier: boolean
    ): void;
    tableType: "partnerOverview" | "masterDataOverview";
    onContextMenuInteraction?(
        selectedRow: any | undefined,
        selectedRowKeys: string | undefined
    ): void;
    showGroupModal?(value: boolean): void;
    showOwnerModal?(value: boolean): void;
    showCat1Modal?(value: boolean): void;
    showCat2Modal?(value: boolean): void;
    showDeleteModal?(value: boolean): void;
    showSwitchOwnerModal?(value: boolean): void;
    showStatusLogModal?(value: boolean, row: any): void;
    setCustomColumns?(customColumns: ICustomColumn[]): void;
    setCustomHeadlines?(customHeadlines: IHeadlineSetting): void;
    setCompanySettings?(companySettings: ICompanySettings): void;
    user: User;
    showNCRModal(modalProps: NCRModalProps): void;
    setExcelExport(propos: ExcelExportProps): void;
    excelExportProps: ExcelExportProps;
    onFiltersChange?: Function;
    onPaginationChange?: Function;
    reloadData?: any;
}
interface MatchParams {
    companyNumber: string;
    tab: string;
    id: string;
    presetId: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

class PartnerTable extends React.Component<
    IPartnerTableProps & MatchProps,
    IPartnerTableState & ISupplierProfileState
> {
    constructor(props: IPartnerTableProps & MatchProps) {
        super(props);

        this.state = {
            selectedRowKeys:
                this.props.selectedRowKeys !== undefined ? this.props.selectedRowKeys : [],
            selectedRows: this.props.selectedRows ? this.props.selectedRows : [],
            masterDataContextMenu: {
                visible: false,
                x: 0,
                y: 0,
                rowId: "",
            },
            overviewContextMenu: {
                visible: false,
                x: 0,
                y: 0,
                rowId: "",
            },
            loading: true,
            searchText: "",
            customColumns: [],
            customHeadlines: {},
            allowHeaderClick: true,
            companySettings: {
                companyId: -1,
                allowComplianceRequesterToReceiveEmailWhenStatusChanged: false,
                allowOnlyOwnersToEditPartnerData: false,
                allowUsersMakeThemSelvesAsOwner: false,
                allowUsersOnCreateSupplier: false,
                autoGenerateCompanyNumbers: false,
                showComplianceUrlIconOnMatrixOverview: false,
                showOwnUploadedPartnerName: false,
                allowNormalUsersToSwitchOwner: false,
                canUsersCreateSupplier: false,
            },
            toDateString: dayjs().toISOString(),
            fromDateString: dayjs()
                .subtract(6, "months")
                .toISOString(),
            reload: { changed: false },
            columns: [],
            showSupplierProfileModal: false,
        };
    }

    linkedFiltersArray = [
        { text: "Invited", value: "3" },
        { text: "Linked", value: "2" },
        { text: "NoEmail", value: "4" },
        { text: "NotLinked", value: "1" },
    ];

    masterDatalinkedFiltersArray = [
        { text: "Invited", value: "3" },
        { text: "Linked", value: "2" },
        { text: "NoEmail/NotLinked", value: "-1" },
    ];

    async componentDidMount() {
        let companySettings = await this.fetchCompanySettings();
        let customHeadlines = await this.fetchHeadlineSettings();
        let customColumns: any = await this.fetchCustomColumns();
        let supplierPages = await this.fetchSupplierProfilePages();
        this.setState({
            loading: false,
            companySettings,
            customHeadlines,
            customColumns,
            columns: this.getColumns(
                companySettings,
                customColumns,
                customHeadlines,
                supplierPages
            ),
        });
        this.props.setCompanySettings && this.props.setCompanySettings(companySettings);
        this.props.setCustomHeadlines && this.props.setCustomHeadlines(customHeadlines);
        this.props.setCustomColumns && this.props.setCustomColumns(customColumns);

        //open supplierProfileModal if the url contains the tab and id
        const supplierProfileTab = this.props.match.params.tab;
        const supplierProfilePartnerId = this.props.match.params.id;
        if (supplierProfileTab && supplierProfilePartnerId) {
            this.setState({
                showSupplierProfileModal: true,
                supplierProfilePartnerId: parseInt(supplierProfilePartnerId),
            });
        }
    }

    async componentDidUpdate(prevProps: IPartnerTableProps, prevState: IPartnerTableState) {
        if (prevProps.selectedRowKeys && this.props.selectedRowKeys) {
            if (prevProps.selectedRowKeys!.length !== this.props.selectedRowKeys!.length) {
                if (
                    this.props.selectedRowKeys !== undefined &&
                    this.props.selectedRows !== undefined
                ) {
                    this.setState({
                        selectedRowKeys: this.props.selectedRowKeys,
                        selectedRows: this.props.selectedRows,
                    });
                }
            }
        }

        if (prevProps.reloadData !== this.props.reloadData) {
            this.setState({ reload: { changed: true } });
        }
    }

    async fetchSupplierProfilePages() {
        let profilePages: ISupplierProfilePage[] = [];
        if (this.props.tableType === "partnerOverview") {
            profilePages = await Provider.SupplierProfileSettings.getEnabledSupplierProfilePages();
        }
        return profilePages;
    }

    async fetchCompanySettings() {
        let companySettings: ICompanySettings = this.state.companySettings;
        if (this.props.tableType === "masterDataOverview") {
            companySettings = await Provider.Company.getGlobalSettings();
        }
        return companySettings;
    }

    async fetchHeadlineSettings() {
        const partnerModelMapper = new PartnerModelMapper();
        let response = await Provider.Company.getCompanyHeadlines();
        return response.typeValue ? partnerModelMapper.mapCustomHeadlines(response.typeValue) : {};
    }

    async fetchCustomColumns() {
        return await Provider.Partner.getCustomColumns();
    }

    onSelectChange(selectedRowKeys: any[], selectedRows: IMasterDataPartner[]) {
        // make this check to check if we are selectin all or not
        this.props.onSelectedRowsChange && this.props.onSelectedRowsChange(selectedRows);
        this.props.onSelectedRowsKeysChange && this.props.onSelectedRowsKeysChange(selectedRowKeys);
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        });
    }

    onSelectAll(selected: boolean, selectedRows: any[], changeRows: any[]) {
        let allRowsWithoutChildren: any[] = [];
        let selectedRowKeys: string[] = [];
        if (changeRows.length === selectedRows.length) {
            //if changerow and selectedrows are equal it means that we are selecting all rows. if not then we are deselecting all rows
            for (let row of selectedRows) {
                if (row.parentId === "") {
                    allRowsWithoutChildren.push(row);
                    selectedRowKeys.push(row.id.value);
                }
            }
            this.props.onSelectedRowsChange &&
                this.props.onSelectedRowsChange(allRowsWithoutChildren);
            this.props.onSelectedRowsKeysChange &&
                this.props.onSelectedRowsKeysChange(selectedRowKeys);
            this.setState({
                selectedRows: allRowsWithoutChildren,
                selectedRowKeys: selectedRowKeys,
            });
        } else {
            this.props.onSelectedRowsChange && this.props.onSelectedRowsChange([]);
            this.props.onSelectedRowsKeysChange && this.props.onSelectedRowsKeysChange([]);
            this.setState({
                selectedRows: [],
                selectedRowKeys: [],
            });
        }
    }

    openSwitchOwnerModal(
        showModal: boolean,
        currentRow: IMasterDataPartner,
        rowId: string,
        e: SyntheticEvent
    ) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ selectedRows: [currentRow], selectedRowKeys: [rowId] });
        this.props.onSelectedRowsChange!([currentRow]);
        this.props.onSelectedRowsKeysChange!([rowId]);

        this.props.showSwitchOwnerModal!(showModal);
    }

    async setSelfAsOwner(
        ownerIds: number[],
        contactId: number,
        partnerId: string,
        ownerHeadline: string,
        e: SyntheticEvent
    ) {
        e.stopPropagation();
        e.preventDefault();
        ownerIds.push(contactId);
        await Provider.Partner.updatePartnerOwners([Number(partnerId)], ownerIds);
        notification.success({
            message: this.props.t("success"),
            description: this.props.t("supplier_UpdateOwnerSuccess", {
                ownerHeadline,
                companyType:
                    this.props.user.profile["ll-companyType"] === CompanyType.default
                        ? this.props.t("company_Type_Supplier").toLowerCase()
                        : this.props.t("company_Type_Customer").toLowerCase(),
            }),
        });
        this.setState({ reload: { changed: true } });
    }

    async removeSelfAsOwner(
        ownerIds: number[],
        contactId: number,
        partnerId: string,
        ownerHeadline: string,
        e: SyntheticEvent
    ) {
        e.stopPropagation();
        e.preventDefault();
        ownerIds = ownerIds.filter(x => x !== contactId);
        await Provider.Partner.updatePartnerOwners([Number(partnerId)], ownerIds);
        notification.success({
            message: this.props.t("success"),
            description: this.props.t("supplier_UpdateOwnerSuccess", {
                ownerHeadline,
                companyType:
                    this.props.user.profile["ll-companyType"] === CompanyType.default
                        ? this.props.t("company_Type_Supplier").toLowerCase()
                        : this.props.t("company_Type_Customer").toLowerCase(),
            }),
        });
        this.setState({ reload: { changed: true } });
    }

    async unLinkPartner(partnerId: string, e: any) {
        e.stopPropagation();
        e.preventDefault();
        await Provider.Partner.unlinkPartner(partnerId);
        notification.success({
            message: this.props.t("success"),
            description: this.props.t("supplier_UnlinkSuccess", {
                companyType:
                    this.props.user.profile["ll-companyType"] === CompanyType.default
                        ? this.props.t("company_Type_Supplier")
                        : this.props.t("company_Type_Customer"),
            }),
        });
        this.setState({ reload: { changed: true } });
    }

    onStatusLogIconClick(row: IMasterDataPartner, e: SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.props.showStatusLogModal!(true, row);
    }
    //#region COLUMNS
    private searchInput: any = undefined;

    handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({
            searchText: "",
        });
    };

    setReviewColor(review: number) {
        let color = "";

        if (review > 0 && review <= 1) color = "#b20000";
        else if (review > 1 && review <= 2) color = "#bf4b00";
        else if (review > 2 && review <= 3) color = "#f89406";
        else if (review > 3 && review <= 4) color = "#b1bf0a";
        else if (review > 4 && review <= 5) color = "#00b203";

        return color;
    }

    setColor(score: number) {
        let color = "";
        if (score < 25) color = "#b40000";
        else if (score >= 25 && score < 50) color = "#c14b00";
        else if (score >= 50 && score < 75) color = "#fa9500";
        else if (score >= 75 && score < 100) color = "#b1c100";
        else if (score === 100) color = "#00b400";

        return color;
    }

    getLinkedTranslationKey(valueText: string) {
        switch (valueText) {
            case "Linked":
                return "Linked";
            case "NotLinked":
                return "NotLinked";
            case "Invited":
                return "Invited";
            case "NoEmail":
                return "NoEmail";
            default:
                return "";
        }
    }

    getCustomLabel(customHeadlines: any) {
        let companyNameHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.companyHeadline &&
            customHeadlines.companyHeadline.customLabel !== null
                ? customHeadlines.companyHeadline.customLabel
                : this.props.t("supplier_CompanyName");
        let companyNumberHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.companyNumberHeadline &&
            customHeadlines.companyNumberHeadline.customLabel !== null
                ? customHeadlines.companyNumberHeadline.customLabel
                : this.props.t("supplier_CompanyNumber");
        let groupHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.groupHeadline &&
            customHeadlines.groupHeadline.customLabel !== null
                ? customHeadlines.groupHeadline.customLabel
                : this.props.t("supplier_Group");
        let ownerHeadline: any =
            customHeadlines !== undefined &&
            customHeadlines.ownerHeadline &&
            customHeadlines.ownerHeadline.customLabel !== null
                ? customHeadlines.ownerHeadline.customLabel
                : this.props.t("supplier_Owner");
        let statusHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.statusHeadline &&
            customHeadlines.statusHeadline.customLabel !== null
                ? customHeadlines.statusHeadline.customLabel
                : this.props.t("supplier_Status");
        let yearlyValHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.valueHeadline &&
            customHeadlines.valueHeadline.customLabel !== null
                ? customHeadlines.valueHeadline.customLabel
                : this.props.t("supplier_Value");
        let category1Headline: any =
            customHeadlines !== undefined &&
            customHeadlines.category1Headline &&
            customHeadlines.category1Headline.customLabel !== null
                ? customHeadlines.category1Headline.customLabel
                : this.props.t("supplier_Category1");
        let category2Headline: any =
            customHeadlines !== undefined &&
            customHeadlines.category2Headline &&
            customHeadlines.category2Headline.customLabel !== null
                ? customHeadlines.category2Headline.customLabel
                : this.props.t("supplier_Category2");
        let kpisHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.kpisHeadline &&
            customHeadlines.kpisHeadline.customLabel !== null
                ? customHeadlines.kpisHeadline.customLabel
                : this.props.t("supplier_KPIs");
        let ncrsHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.ncrsHeadline &&
            customHeadlines.ncrsHeadline.customLabel !== null
                ? customHeadlines.ncrsHeadline.customLabel
                : this.props.t("supplier_NCRs");
        let ncrsDaysOpenHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.ncrsOpenHeadline &&
            customHeadlines.ncrsOpenHeadline.customLabel !== null
                ? customHeadlines.ncrsOpenHeadline.customLabel
                : this.props.t("supplier_NCRDaysOpen");
        let complianceHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.compliancesHeadline &&
            customHeadlines.compliancesHeadline.customLabel !== null
                ? customHeadlines.compliancesHeadline.customLabel
                : this.props.t("supplier_ComplianceFillRate");
        let reviewHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.reviewsHeadline &&
            customHeadlines.reviewsHeadline.customLabel !== null
                ? customHeadlines.reviewsHeadline.customLabel
                : this.props.t("supplier_Reviews");
        let linkedHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.linkedHeadline &&
            customHeadlines.linkedHeadline.customLabel !== null
                ? customHeadlines.linkedHeadline.customLabel
                : this.props.t("supplier_Linked");
        let complianceScoreHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.complianceScoreHeadline &&
            customHeadlines.complianceScoreHeadline.customLabel !== null
                ? customHeadlines.complianceScoreHeadline.customLabel
                : this.props.t("supplier_ComplianceScore");
        let pocFirstNameHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.POCFirstName &&
            customHeadlines.POCFirstName.customLabel !== null
                ? customHeadlines.POCFirstName.customLabel
                : this.props.t("pocFirstName");
        let pocLastNameHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.POCLastName &&
            customHeadlines.POCLastName.customLabel !== null
                ? customHeadlines.POCLastName.customLabel
                : this.props.t("pocLastName");
        let pocPhoneHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.POCPhone &&
            customHeadlines.POCPhone.customLabel !== null
                ? customHeadlines.POCPhone.customLabel
                : this.props.t("pocPhone");
        let pocCountryHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.POCCountry &&
            customHeadlines.POCCountry.customLabel !== null
                ? customHeadlines.POCCountry.customLabel
                : this.props.t("pocCountry");
        let pocEmailAddressHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.EmailAddress &&
            customHeadlines.EmailAddress.customLabel !== null
                ? customHeadlines.EmailAddress.customLabel
                : this.props.t("email");
        let domainHeadline: string =
            customHeadlines !== undefined &&
            customHeadlines.Domain &&
            customHeadlines.Domain.customLabel !== null
                ? customHeadlines.Domain.customLabel
                : this.props.t("partner_Website");

        return {
            companyNameHeadline,
            companyNumberHeadline,
            groupHeadline,
            ownerHeadline,
            statusHeadline,
            category1Headline,
            category2Headline,
            kpisHeadline,
            ncrsHeadline,
            reviewHeadline,
            linkedHeadline,
            ncrsDaysOpenHeadline,
            complianceHeadline,
            yearlyValHeadline,
            complianceScoreHeadline,
            pocFirstNameHeadline,
            pocCountryHeadline,
            pocLastNameHeadline,
            pocPhoneHeadline,
            pocEmailAddressHeadline,
            domainHeadline,
        };
    }

    getColumns(
        companySettings: any,
        customColumns: any,
        customHeadLines: any,
        enabledSupplierPages: ISupplierProfilePage[]
    ) {
        let columns: any[] = [];

        let {
            companyNameHeadline,
            companyNumberHeadline,
            groupHeadline,
            ownerHeadline,
            statusHeadline,
            category1Headline,
            category2Headline,
            kpisHeadline,
            ncrsHeadline,
            reviewHeadline,
            linkedHeadline,
            ncrsDaysOpenHeadline,
            complianceHeadline,
            yearlyValHeadline,
            complianceScoreHeadline,
            pocFirstNameHeadline,
            pocLastNameHeadline,
            pocCountryHeadline,
            pocPhoneHeadline,
            pocEmailAddressHeadline,
            domainHeadline,
        } = this.getCustomLabel(customHeadLines);

        //define customHeadlines
        const hasKpiAccess = this.props.user.profile["ll-Kpi"] === "true" ? true : false;
        const hasComplianceAccess =
            this.props.user.profile["ll-Compliance"] === "true" ? true : false;
        const hasReviewAccess = this.props.user.profile["ll-Review"] === "true" ? true : false;
        const hasNcrAccess = this.props.user.profile["ll-Ncr"] === "true" ? true : false;
        const hasNcrFeature = this.props.user.profile["ll-enabledFeature-Ncr"] === "true";
        const hasScorecardFeature =
            this.props.user.profile["ll-enabledFeature-Scorecard"] === "true";

        if (this.props.tableType === "masterDataOverview") {
            columns = [
                {
                    title: companyNameHeadline,
                    viewColumnId: 0,
                    dataIndex: ["company", "value"],
                    key: "company",
                    fixed: true,
                    width: 350,
                    sorter: true,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "company" }),
                    render: (value: any, record: any) => (
                        <>
                            <Tooltip title={<Trans i18nKey="supplierCardsTooltip"></Trans>}>
                                <Button
                                    type="link"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        this.setState({
                                            showSupplierProfileModal: true,
                                            supplierProfilePartnerId: record.id.value,
                                        });
                                    }}>
                                    {value}
                                </Button>
                            </Tooltip>
                        </>
                    ),
                },
                {
                    title: companyNumberHeadline,
                    viewColumnId: 1,
                    dataIndex: ["companynumber", "value"],
                    key: "companynumber",
                    fixed: true,
                    width: 200,
                    sorter: true,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "companynumber" }),
                },
                {
                    title: groupHeadline,
                    viewColumnId: 2,
                    dataIndex: ["group", "value", "name"],
                    key: "group",
                    width: 200,
                    filters: [{ text: "", value: "" }],
                    sorter: true,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerGroups,
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "group" }
                    ),
                    render: (value: any, record: any) => <div>{record.group.value.name}</div>,
                },
                {
                    title: ownerHeadline,
                    viewColumnId: 3,
                    dataIndex: ["owners", "value"],
                    key: "owner",
                    className: "ownerColumn",
                    width: 300,
                    filters: [{ text: "", value: "" }],
                    filterType: "array",
                    sorter: false,
                    ...getColumnSelectProps(
                        usePartnerOwners,
                        {
                            value: ["id"],
                            text: ["fullName"],
                        },
                        { dataIndex: "owner" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tags: string[], record: any) => (
                        <>
                            {record.owners.value ? (
                                <MultipleTags
                                    color="blue"
                                    values={record.owners.value.map((val: any) => ({
                                        id: val.id,
                                        name: `${val.firstName} ${val.lastName}`,
                                    }))}
                                />
                            ) : null}
                        </>
                    ),
                },
                {
                    title: statusHeadline,
                    viewColumnId: 4,
                    dataIndex: ["status", "value", "name"],
                    key: "status",
                    width: 200,
                    sorter: true,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerStatuses,
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "status" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (val: any, record: any) => (
                        <>
                            <InfoCircle
                                style={{ fontSize: 22 }}
                                className="statusLogIcon"
                                onClick={(e: SyntheticEvent) =>
                                    this.onStatusLogIconClick(record, e)
                                }
                            />

                            {record.status.value.name !== "" ? (
                                <Tooltip title={record.status.value.name}>
                                    <Tag color={record.status.value.color}>
                                        {record.status.value.name}
                                    </Tag>
                                </Tooltip>
                            ) : (
                                undefined
                            )}
                        </>
                    ),
                },
                {
                    title: yearlyValHeadline,
                    viewColumnId: 5,
                    dataIndex: ["yearlyValueOfCooperation", "value"],
                    key: "yearlyvalue",
                    width: 200,
                    sorter: true,
                    filterType: "range",
                    render: (val: any, record: any) => (
                        <div>{`${record.currency !== null ? record.currency : ""} ${
                            record.yearlyValueOfCooperation.value !== null
                                ? record.yearlyValueOfCooperation.value
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : ""
                        }`}</div>
                    ),
                    ...getColumnMinMaxProps(-2147483648, 2147483647, {
                        dataIndex: "yearlyValueOfCooperation",
                    }),
                },
                {
                    title: category1Headline,
                    viewColumnId: 6,
                    dataIndex: ["category1", "value"],
                    key: "category1",
                    width: 350,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerCategories.bind(null, categoryType.one),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "category1" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tag: any, record: any) =>
                        record.category1.value ? (
                            <MultipleTags
                                color="blue"
                                values={record.category1.value.map((val: any) => ({
                                    id: val.id,
                                    name: val.name,
                                }))}
                            />
                        ) : null,
                },
                {
                    title: category2Headline,
                    viewColumnId: 7,
                    dataIndex: ["category2", "value"],
                    key: "category2",
                    width: 350,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerCategories.bind(null, categoryType.two),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "category2" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tag: any, record: any) =>
                        record.category2.value ? (
                            <MultipleTags
                                color="blue"
                                values={record.category2.value.map((val: any) => ({
                                    id: val.id,
                                    name: val.name,
                                }))}
                            />
                        ) : null,
                },
                {
                    title: pocFirstNameHeadline,
                    viewColumnId: 8,
                    dataIndex: ["kpcFirstName", "value"],
                    key: "fname",
                    width: 300,
                    sorter: true,
                    render: (val: any, record: any) => <div>{record.kpcFirstName.value}</div>,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "kpcFirstName" }),
                },
                {
                    title: pocLastNameHeadline,
                    viewColumnId: 9,
                    dataIndex: ["kpcLastName", "value"],
                    key: "lname",
                    width: 300,
                    sorter: true,
                    render: (val: any, record: any) => <div>{record.kpcLastName.value}</div>,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "kpcLastName" }),
                },
                {
                    title: pocEmailAddressHeadline,
                    viewColumnId: 10,
                    dataIndex: ["kpcEmail", "value"],
                    key: "email",
                    width: 200,
                    sorter: true,
                    render: (val: any, record: any) => <div>{record.kpcEmail.value}</div>,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "kpcEmail" }),
                },
                {
                    title: pocPhoneHeadline,
                    viewColumnId: 11,
                    dataIndex: ["kpcPhoneNumber", "value"],
                    key: "phone",
                    width: 290,
                    sorter: true,
                    render: (val: any, record: any) => <div>{record.kpcPhoneNumber.value}</div>,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "kpcPhoneNumber" }),
                },
                {
                    title: pocCountryHeadline,
                    viewColumnId: 12,
                    dataIndex: ["kpcCountryCode", "value"],
                    key: "country",
                    width: 290,
                    sorter: true,
                    render: (val: any, record: any) => <div>{record.kpcCountryCode.value}</div>,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "kpcCountryCode" }),
                },
                {
                    title: linkedHeadline,
                    viewColumnId: 13,
                    dataIndex: ["linked", "value", "name"],
                    key: "linked",
                    width: customColumns.length > 0 ? 200 : 200,
                    ...getColumnSelectProps(
                        () => ({
                            data: this.masterDatalinkedFiltersArray.map(x => ({
                                id: x.value,
                                name: this.props.t(x.text),
                            })),
                            loading: false,
                        }),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "linked" }
                    ),
                    sorter: true,
                    filterType: "array",
                    ...getFilterIcon({ dataIndex: "linked" }),
                    render: (val: any, record: any) => {
                        return (
                            <div>
                                {record.linked.value.name === LinkStatusType.NotLinked && (
                                    <span>{this.props.t("NotLinked")}</span>
                                )}
                                {(record.linked.value.name === LinkStatusType.None ||
                                    record.linked.value.name === LinkStatusType.NoEmail) && (
                                    <div>
                                        {record.owners!.value.some(
                                            (x: any) =>
                                                x.userId === this.props.user.profile["ll-UserId"]
                                        ) ||
                                        (this.props.user.profile["ll-Usertype"] ===
                                            "administrator" &&
                                            companySettings.allowOnlyOwnersToEditPartnerData) ? (
                                            <Tooltip
                                                placement="left"
                                                title={this.props.t("enterSupplierEmailTooltip")}>
                                                <span style={{ color: "blue" }}>
                                                    {this.props.t("NoEmail")}
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <span>{this.props.t("NoEmail")}</span>
                                        )}
                                    </div>
                                )}

                                {(record.linked.value.name === LinkStatusType.Linked ||
                                    record.linked.value.name === LinkStatusType.Invited) && (
                                    <span>
                                        {this.props.t(
                                            this.getLinkedTranslationKey(record.linked.value.name)
                                        )}
                                        {this.props.user.profile["ll-Usertype"] ===
                                            "administrator" && (
                                            <div
                                                style={{ display: "inline" }}
                                                onClick={(e: SyntheticEvent) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}>
                                                <Popconfirm
                                                    okText={this.props.t("generic_Confirm")}
                                                    cancelText={this.props.t("generic_Cancel")}
                                                    title={this.props.t("linkStatusRemoveConfirm")}
                                                    onConfirm={(e: any) =>
                                                        this.unLinkPartner(
                                                            record.id.value.toString(),
                                                            e
                                                        )
                                                    }>
                                                    <Tooltip
                                                        title={this.props.t(
                                                            "linkStatusRemoveTitle"
                                                        )}
                                                        placement="right">
                                                        <span>
                                                            <XCircle
                                                                onClick={(e: SyntheticEvent) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }}
                                                                className="unlinkIcon"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Popconfirm>
                                            </div>
                                        )}
                                    </span>
                                )}
                            </div>
                        );
                    },
                },
                {
                    title: this.props.t("Partner_Created"),
                    viewColumnId: 15,
                    dataIndex: "created",
                    key: "created",
                    sorter: true,
                    render: (val: any, record: any) => (
                        <div>{dayjs(val).format("MMM D, YYYY (HH:mm)")}</div>
                    ),
                    width: 200,
                    filterType: "date",
                    ...getColumnRangePickerProps(),
                },
                {
                    title: this.props.t("Partner_CreatedBy"),
                    viewColumnId: 16,
                    dataIndex: "createdBy",
                    key: "createdBy",
                    width: 200,
                    sorter: true,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "createdBy" }),
                    render: (val: any, record: any) =>
                        val && (
                            <MultipleTags
                                tagTooltips={false}
                                tagNumber={1}
                                color="blue"
                                values={[{ id: 0, name: val }]}
                            />
                        ),
                },
                {
                    title: domainHeadline,
                    viewColumnId: "supplierDomain",
                    dataIndex: "domain",
                    key: "domain",
                    width: 200,
                    ellipsis: true,
                    sorter: true,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "domain" }),
                },
            ];

            if (customColumns.length > 0) {
                customColumns.forEach((cc: ICustomColumn, index: number) => {
                    columns.push({
                        title: cc.name,
                        viewColumnId: `c${cc.id}`,
                        dataIndex: ["customColumnsValuesWithIndex", cc.id, "value"],
                        customColumn: true,
                        key: `${cc.id}`,
                        sorter: true,
                        width: 200,
                        ellipsis: true,
                        filterType: "string",
                        render: (val: any, record: any) => {
                            let value = "";
                            if (cc.id in record.customColumnsValuesWithIndex) {
                                value = record.customColumnsValuesWithIndex[cc.id].value;
                            }
                            return (
                                <div
                                    className={
                                        value && value.length > 85 ? "customColumnText" : ""
                                    }>
                                    {value}
                                </div>
                            );
                        },
                        ...getColumnSearchProps({ dataIndex: `customColumn_${index.toString()}` }),
                    });
                });
            }
        } else if (this.props.tableType === "partnerOverview") {
            columns = [
                {
                    title: companyNameHeadline,
                    viewColumnId: 0,
                    dataIndex: ["company", "value"],
                    key: "company",
                    fixed: true,
                    width: 350,
                    sorter: true,
                    ...getColumnSearchProps({ dataIndex: "company" }),
                    filterType: "string",
                    render: (value: any, record: any) => (
                        <>
                            <Tooltip title={<Trans i18nKey="supplierCardsTooltip"></Trans>}>
                                <Button
                                    type="link"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        this.setState({
                                            showSupplierProfileModal: true,
                                            supplierProfilePartnerId: record.id.value,
                                        });
                                    }}>
                                    {value}
                                </Button>
                            </Tooltip>
                        </>
                    ),
                },
                {
                    title: companyNumberHeadline,
                    viewColumnId: 1,
                    dataIndex: ["companynumber", "value"],
                    key: "companynumber",
                    fixed: true,
                    width: 200,
                    sorter: true,
                    filterType: "string",
                    ...getColumnSearchProps({ dataIndex: "companynumber" }),
                },
                ...(hasScorecardFeature
                    ? [
                          {
                              title: this.props.t("scorecard_ScorecardStatus"),
                              viewColumnId: "scorecardStatus",
                              dataIndex: "scorecardScore",
                              key: "AwardStatus",
                              width: 200,
                              sorter: true,
                              filterType: "array",
                              render: (value: any, record: any) => (
                                  <div>
                                      {record.scorecardScore !== undefined &&
                                          record.scorecardScore !== null && (
                                              <Tooltip
                                                  title={`${record.scorecardScore}% - ${record.scorecardAwardStatus}`}>
                                                  <Tag
                                                      color={scoreColor(record.scorecardScore)}
                                                      bordered={false}>
                                                      {record.scorecardAwardStatus}
                                                  </Tag>
                                              </Tooltip>
                                          )}
                                  </div>
                              ),
                              ...getColumnSelectProps(
                                  useAwardsSettings,
                                  {
                                      value: ["id"],
                                      text: ["name"],
                                  },
                                  { dataIndex: "owner" }
                              ),
                          },
                      ]
                    : []),
                ...(hasKpiAccess
                    ? [
                          {
                              title: kpisHeadline,
                              viewColumnId: 2,
                              dataIndex: ["kpis", "value", "score"],
                              key: "kpi",
                              width: 200,
                              sorter: true,
                              filterType: "range",
                              render: (value: any, record: any) => (
                                  <div>
                                      {record.kpis.value.score !== false &&
                                          record.kpis.value.Applicable !== true && (
                                              <Tooltip
                                                  placement="left"
                                                  title={this.props.t(
                                                      "supplierOverviewKpiTooltip",
                                                      {
                                                          month: this.props.t(
                                                              record.kpis.value.month
                                                          ),
                                                      }
                                                  )}>
                                                  <span>
                                                      {record.kpis.value.hasScore &&
                                                          record.kpis.value.applicable && (
                                                              <ColoredPercentTag
                                                                  value={record.kpis.value.score}
                                                              />
                                                          )}
                                                  </span>
                                              </Tooltip>
                                          )}
                                      {!record.kpis.value.applicable && record.kpis.value.hasScore && (
                                          <Tooltip
                                              placement="left"
                                              title={`No benchmark is defined for the KPIs on this external partner`}>
                                              <ColoredPercentTag value={undefined} />
                                          </Tooltip>
                                      )}
                                  </div>
                              ),
                              ...getColumnMinMaxProps(0, 100, { dataIndex: "kpis" }),
                          },
                      ]
                    : []),
                ...(hasNcrAccess && hasNcrFeature
                    ? [
                          {
                              title: ncrsHeadline,
                              viewColumnId: 3,
                              dataIndex: ["ncrs", "value", "totalCount"],
                              key: "ncr",
                              width: 100,
                              sorter: true,
                              filterType: "range",
                              render: (value: any, record: any, index: any) => (
                                  <div className="ncrContainer" key={index}>
                                      {record.ncrs.value.totalCount !== undefined &&
                                          record.ncrs.value.totalCount !== 0 && (
                                              <span className="ncrIconContainer">
                                                  <Tooltip
                                                      placement="bottomLeft"
                                                      title={this.props.t("ncrStatisticsTooltip")}
                                                      trigger="hover">
                                                      <span>
                                                          <BarChart07
                                                              key={record.ncrs.value.totalCount}
                                                              onClick={(e: any) => {
                                                                  e.stopPropagation();
                                                                  e.preventDefault();
                                                                  this.props.showNCRModal({
                                                                      visible: true,
                                                                      partnerId: record.id.value,
                                                                      currentRow: record,
                                                                      fromDate: this.state
                                                                          .fromDateString,
                                                                      toDate: this.state
                                                                          .toDateString,
                                                                  });
                                                              }}
                                                              style={{
                                                                  fontSize: 18,
                                                                  fontWeight: "bold",
                                                                  cursor: "pointer",
                                                              }}
                                                          />
                                                      </span>
                                                  </Tooltip>
                                              </span>
                                          )}
                                      <Tooltip
                                          placement="left"
                                          title={
                                              (record.ncrs.value.totalCount !== undefined
                                                  ? record.ncrs.value.totalCount
                                                  : this.props.t("no")) + ` ${this.props.t("ncrs")}`
                                          }>
                                          <Link
                                              className="ncrNumberContainer"
                                              to={`/Ncrs/partnerId/${record.id.value.toString()}/from/${
                                                  this.state.fromDateString
                                              }/to/${this.state.toDateString}`}>
                                              {record.ncrs.value.totalCount !== undefined
                                                  ? record.ncrs.value.totalCount
                                                  : undefined}
                                          </Link>
                                      </Tooltip>
                                  </div>
                              ),
                              ...getColumnMinMaxProps(-2147483648, 2147483647, {
                                  dataIndex: "ncrs",
                                  allowDecimals: false,
                              }),
                          },
                      ]
                    : []),
                ...(hasNcrAccess && hasNcrFeature
                    ? [
                          {
                              title: ncrsDaysOpenHeadline,
                              viewColumnId: 4,
                              dataIndex: ["ncrs", "value", "openDaysAverage"],
                              key: "opendays",
                              width: 200,
                              sorter: true,
                              render: (value: any, record: any) => (
                                  <Fragment>
                                      {record.ncrs.value.openDaysAverage !== undefined &&
                                          record.ncrs.value.totalCount >= 1 && (
                                              <div> {record.ncrs.value.openDaysAverage}</div>
                                          )}
                                  </Fragment>
                              ),
                              filterType: "range",
                              ...getColumnMinMaxProps(-2147483648, 2147483647, {
                                  dataIndex: "ncrDaysOpen",
                                  allowDecimals: false,
                              }),
                          },
                      ]
                    : []),
                ...(hasComplianceAccess
                    ? [
                          {
                              title: complianceHeadline,
                              viewColumnId: 5,
                              dataIndex: ["compliance", "value", "answersAverage"],
                              key: "compliance",
                              width: 200,
                              sorter: true,
                              filterType: "range",
                              render: (val: any, record: any) => (
                                  <div>
                                      {record.compliance.value.answersAverage !== null && (
                                          <Tooltip
                                              placement="left"
                                              title={
                                                  <Trans
                                                      i18nKey="complianceStatisticsTooltip"
                                                      values={{
                                                          compliancePercentage:
                                                              record.compliance.value
                                                                  .answersAverage,
                                                          complianceCount:
                                                              record.compliance.value.totalCount,
                                                      }}>
                                                      {this.props.t("complianceStatisticsTooltip")}
                                                  </Trans>
                                              }>
                                              <ColoredPercentTag
                                                  value={record.compliance.value.answersAverage}
                                              />
                                          </Tooltip>
                                      )}
                                  </div>
                              ),
                              ...getColumnMinMaxProps(0, 100, {
                                  dataIndex: "compliance",
                              }),
                          },
                      ]
                    : []),
                ...(hasComplianceAccess
                    ? [
                          {
                              title: complianceScoreHeadline,
                              viewColumnId: 14,
                              dataIndex: ["complianceScore", "value"],
                              key: "AverageScore",
                              width: 200,
                              sorter: true,
                              filterType: "range",
                              min: 0,
                              max: 100,
                              render: (val: any, record: any) =>
                                  record.complianceScore.value !== null && (
                                      <div>
                                          <ColoredPercentTag value={record.complianceScore.value} />
                                      </div>
                                  ),
                              ...getColumnMinMaxProps(0, 100, { dataIndex: "AverageScore" }),
                          },
                      ]
                    : []),
                ...(hasReviewAccess
                    ? [
                          {
                              title: reviewHeadline,
                              viewColumnId: 6,
                              dataIndex: ["review", "value", "totalCount"],
                              key: "review",
                              width: 220,
                              sorter: true,
                              filterType: "range",
                              render: (val: any, record: any) => (
                                  <div
                                      className={classes.reviewContainer}
                                      onClick={(e: any) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                      }}>
                                      <Tooltip
                                          placement="left"
                                          title={
                                              <Trans
                                                  i18nKey="reviewStatisticsTooltip"
                                                  values={{
                                                      reviewNumber:
                                                          record.review.value.reviewsAverage,
                                                      reviewTotal: record.review.value.totalCount,
                                                  }}>
                                                  {this.props.t("reviewStatisticsTooltip")}
                                              </Trans>
                                          }>
                                          <Link
                                              className="reviewContainer"
                                              to={`/Reviews/partnerId/${record.id.value.toString()}/from/${
                                                  this.state.fromDateString
                                              }/to/${this.state.toDateString}`}>
                                              <ReviewRate
                                                  allowHalf
                                                  val={
                                                      Math.round(
                                                          record.review.value.reviewsAverage * 2
                                                      ) / 2
                                                  }
                                              />
                                          </Link>
                                      </Tooltip>
                                      <div>
                                          (
                                          {record.review.value.totalCount !== undefined
                                              ? record.review.value.totalCount
                                              : 0}
                                          )
                                      </div>
                                  </div>
                              ),
                              ...getColumnMinMaxProps(0, 5, {
                                  dataIndex: "review",
                                  allowDecimals: false,
                              }),
                          },
                      ]
                    : []),
                {
                    title: linkedHeadline,
                    viewColumnId: 7,
                    dataIndex: ["linked", "value", "name"],
                    key: "linked",
                    width: 200,
                    sorter: true,
                    filterType: "array",
                    ...getColumnSelectProps(
                        () => ({
                            data: this.linkedFiltersArray.map(x => ({
                                id: x.value.toString(),
                                name: this.props.t(x.text),
                            })),
                            loading: false,
                        }),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "linked" }
                    ),
                    render: (val: any, record: any) => {
                        return (
                            <div>
                                {record.linked.value.name === "NotLinked" && (
                                    <span>
                                        {record.linked.value.name === "NotLinked"
                                            ? this.props.t("NotLinked")
                                            : this.props.t(
                                                  this.getLinkedTranslationKey(
                                                      record.linked.value.name
                                                  )
                                              )}
                                    </span>
                                )}
                                {(record.linked.value.name === "None" ||
                                    record.linked.value.name === "NoEmail") && (
                                    <span>{this.props.t("NoEmail")}</span>
                                )}
                                {record.linked.value.name === "Invited" && (
                                    <span>{this.props.t("Invited")}</span>
                                )}
                                {record.linked.value.name === "Linked" && (
                                    <span>
                                        {this.props.t(
                                            this.getLinkedTranslationKey(record.linked.value.name)
                                        )}
                                    </span>
                                )}
                            </div>
                        );
                    },
                    ...getFilterIcon({ dataIndex: "linked" }),
                },
                {
                    title: ownerHeadline,
                    viewColumnId: 8,
                    dataIndex: ["owners", "value"],
                    key: "owner",
                    width: 300,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerOwners,
                        {
                            value: ["id"],
                            text: ["fullName"],
                        },
                        { dataIndex: "owner" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tags: string[], record: any) =>
                        record.owners.value ? (
                            <MultipleTags
                                disableTooltip={true}
                                color="blue"
                                values={record.owners.value.map((val: any) => ({
                                    id: val.id,
                                    name: `${val.firstName} ${val.lastName}`,
                                }))}
                            />
                        ) : null,
                },
                {
                    title: groupHeadline,
                    viewColumnId: 9,
                    dataIndex: ["group", "value", "name"],
                    key: "group",
                    width: 200,
                    sorter: true,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerGroups,
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "group" }
                    ),
                    render: (value: any, record: any) => <div>{record.group.value.name}</div>,
                },
                {
                    title: category1Headline,
                    viewColumnId: 10,
                    dataIndex: ["category1", "value"],
                    key: "category1",
                    width: 350,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerCategories.bind(null, categoryType.one),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "category1" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tag: any, record: any) =>
                        record.category1.value ? (
                            <MultipleTags
                                color="blue"
                                values={record.category1.value.map((val: any) => ({
                                    id: val.id,
                                    name: val.name,
                                }))}
                            />
                        ) : null,
                },
                {
                    title: category2Headline,
                    viewColumnId: 11,
                    dataIndex: ["category2", "value"],
                    key: "category2",
                    width: 300,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerCategories.bind(null, categoryType.two),
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "category2" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (tag: any, record: any) =>
                        record.category2.value ? (
                            <MultipleTags
                                color="blue"
                                values={record.category2.value.map((val: any) => ({
                                    id: val.id,
                                    name: val.name,
                                }))}
                            />
                        ) : null,
                },
                {
                    title: yearlyValHeadline,
                    viewColumnId: 12,
                    dataIndex: ["yearlyValueOfCooperation", "value"],
                    key: "yearlyvalue",
                    sorter: true,
                    width: 200,
                    render: (val: any, record: any) => (
                        <div>{`${record.currency !== null ? record.currency : ""} ${
                            record.yearlyValueOfCooperation.value !== null
                                ? record.yearlyValueOfCooperation.value
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                : ""
                        }`}</div>
                    ),
                    filterType: "range",
                    ...getColumnMinMaxProps(-2147483648, 2147483647, {
                        dataIndex: "yearlyValueOfCooperation",
                    }),
                },
                {
                    title: statusHeadline,
                    viewColumnId: 13,
                    dataIndex: ["status", "value", "name"],
                    key: "status",
                    width: 200,
                    sorter: true,
                    filterType: "array",
                    ...getColumnSelectProps(
                        usePartnerStatuses,
                        {
                            value: ["id"],
                            text: ["name"],
                        },
                        { dataIndex: "status" }
                    ),
                    onCell: () => {
                        return {
                            style: {
                                overflow: "hidden",
                            },
                        };
                    },
                    render: (val: any, record: any) => (
                        <div>
                            <InfoCircle
                                style={{ fontSize: 22 }}
                                className="statusLogIcon"
                                onClick={(e: SyntheticEvent) =>
                                    this.onStatusLogIconClick(record, e)
                                }
                            />
                            {record.status.value.name !== "" ? (
                                <Tooltip title={record.status.value.name}>
                                    <Tag color={record.status.value.color}>
                                        {record.status.value.name}
                                    </Tag>
                                </Tooltip>
                            ) : (
                                undefined
                            )}
                        </div>
                    ),
                },
            ];
            if (
                enabledSupplierPages.findIndex(
                    (page: ISupplierProfilePage) => page.pageName === "SegmentationManagement"
                ) !== -1
            ) {
                columns.push({
                    title: this.props.t("segmentation_Segment"),
                    viewColumnId: "segmentColumn",
                    dataIndex: "segment",
                    key: "segmentId",
                    width: 200,
                    sorter: false,
                    filterType: "array",
                    ...getColumnSelectProps(
                        useSegmentCategories.bind(null, undefined, true),
                        {
                            value: ["id"],
                            text: ["segment"],
                        },
                        { dataIndex: "segmentId" }
                    ),
                });
            }
        }
        return columns;
    }

    render() {
        let rowSelection: any;
        if (this.props.selectableRows) {
            rowSelection = {
                selectedRowKeys: this.state.selectedRowKeys,
                selectedRows: this.state.selectedRows,
                onChange: this.onSelectChange.bind(this),
                onSelectAll: this.onSelectAll.bind(this),
            };
        } else {
            rowSelection = null;
        }

        let onRow: any;

        if (this.props.tableType === "masterDataOverview") {
            onRow = (record: IMasterDataPartner) => {
                let isOwnerOnRecord = record.owners!.value.some(
                    (x: any) => x.userId.toString() === this.props.user.profile["ll-UserId"]
                );
                let canEditSupplier = this.state.companySettings.allowOnlyOwnersToEditPartnerData;

                return {
                    style: { cursor: "pointer" },
                    onClick: () => {
                        this.props.onRowClick!(
                            true,
                            record,
                            (isOwnerOnRecord && canEditSupplier) ||
                                this.props.user.profile["ll-Usertype"] === "administrator" ||
                                (this.props.user.profile["ll-isGateKeeperUser"] === "true" &&
                                    this.props.user.profile[
                                        "ll-isGatekeeperAllowedToEditMasterData"
                                    ] === "true")
                        );
                    },
                };
            };
        } else if (this.props.tableType === "partnerOverview") {
            onRow = (record: any) => {
                return {
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => {
                        this.setState({
                            showSupplierProfileModal: true,
                            supplierProfilePartnerId: record.id.value,
                        });
                    },
                };
            };
        }
        if (this.state.columns.length === 0) {
            return (
                <div className="callbackSpinner">
                    <Spin size="large" />
                </div>
            );
        }
        return (
            <Fragment>
                <Grid
                    title={this.props.masterDataMenu}
                    data-test="supplier_table"
                    columns={this.state.columns}
                    fixedColumns={["company.value", "companynumber.value"]}
                    rowSelection={
                        this.props.tableType === "partnerOverview" ? undefined : rowSelection
                    }
                    rowKey={(record: any) => record.id.value}
                    useData={
                        this.props.tableType === "partnerOverview"
                            ? usePartnerOverview
                            : useMasterDataOverview
                    }
                    reloadData={this.state.reload}
                    tableType={
                        this.props.tableType === "partnerOverview" ? "Partner" : "MasterData"
                    }
                    tableExcessHeight={this.props.tableType === "partnerOverview" ? 30 : 65}
                    externalLoading={this.state.loading}
                    {...(this.props.tableType === "partnerOverview" && { rangePicker: true })}
                    reportType={ReportType.partner}
                    onRow={onRow}
                    onRangeChange={({ to, from }: any) => {
                        this.setState({
                            fromDateString: from,
                            toDateString: to,
                        });
                    }}
                    additionalColumnFilters={
                        this.props.match.params.companyNumber
                            ? [
                                  {
                                      name: "companynumber.value",
                                      value: decodeURIComponent(
                                          this.props.match.params.companyNumber
                                      ),
                                  },
                              ]
                            : undefined
                    }
                />
                {this.state.showSupplierProfileModal && this.state.supplierProfilePartnerId && (
                    <SupplierProfileModal
                        open={this.state.showSupplierProfileModal}
                        supplierId={this.state.supplierProfilePartnerId}
                        onClose={() =>
                            this.setState({
                                showSupplierProfileModal: false,
                                supplierProfilePartnerId: undefined,
                            })
                        }
                    />
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
        ncrModalProps: state.ncrModal.props,
        excelExportProps: state.excelExport.props,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        showNCRModal: bindActionCreators(showModal, dispatch),
        setExcelExport: bindActionCreators(setExcelExport, dispatch),
    };
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(PartnerTable))
);
