import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Checkbox, notification, Form, Tooltip, Button } from "antd";
import { useAllActiveTemplates, useDefaultContacts, useSendSelfAssessment } from "controller";
import {
    SelectExternalPartner,
    RichTextEditor,
    SelectTreeDataMultiple,
    InfoCircle,
} from "components/utilitycomponents";
import classes from "./styles/requestInfoModal.module.scss";
import { useTranslation } from "react-i18next";
import { SelectPartnerContacts } from "components/utilitycomponents/contact";

interface PropsType {
    visible: boolean;
    onClose: Function;
}

const RequestInfoModal = ({ visible, onClose }: PropsType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const { sendSelfAssessments, loading: sendSALoading } = useSendSelfAssessment();
    const [addCustomMsg, setAddCustomMsg] = useState<boolean>(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState<number | undefined>(undefined);
    const { data: defaultContactReceivers } = useDefaultContacts(
        selectedPartnerId,
        "ComplianceContact"
    );
    const selectedContacts = Form.useWatch("compliancePartnerContactReceiver", form);

    useEffect(() => {
        form.setFieldValue(
            "compliancePartnerContactReceiver",
            defaultContactReceivers.map((contact: any) => contact.id)
        );
    }, [defaultContactReceivers]);

    useEffect(() => {
        if (visible) {
            setReload({ reload: true });
        }
        form.resetFields();
    }, [visible]);

    const onCancel = () => {
        form.resetFields();
        setAddCustomMsg(false);
        setSelectedPartnerId(undefined);
        onClose();
    };

    const onSubmit = async () => {
        let values = await form.validateFields();
        await sendSelfAssessments({
            templateIds: values.templates,
            partnerId: values.partner,
            multipleAssesmentsEnabled: values.multipleSAEnabled,
            hasAdditionalText: values.addCustomMsg,
            additionalText: values.customMsg,
            compliancePartnerContactReceiverIds: values.compliancePartnerContactReceiver
                ? [...new Set(values.compliancePartnerContactReceiver)]
                : [],
        });
        notification.success({
            message: "Success",
            description: t("compliance_sendSA_Success"),
        });
        onCancel();
    };

    return (
        <Modal
            wrapProps={{ "data-test": "admin_compliance_request_info_modal" }}
            title={t("admin_Compliance_RequestInfo")}
            open={visible}
            onCancel={() => onCancel()}
            style={{ top: 20 }}
            onOk={onSubmit}
            footer={[
                <Button
                    disabled={selectedContacts === undefined || selectedContacts.length === 0}
                    loading={sendSALoading}
                    type="primary"
                    onClick={async () => await onSubmit()}>
                    {t("compliance_CreateAndSendSA")}
                </Button>,
            ]}>
            <Form form={form} onFinish={onSubmit} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={t("compliance_ExternalPartner")}
                            className={classes.formItem}
                            name="partner"
                            valuePropName="value"
                            rules={[
                                {
                                    required: true,
                                    message: t("compliance_PartnerRequired"),
                                },
                            ]}>
                            <SelectExternalPartner
                                placeholder={t("compliance_SelectPartnerPlaceholder")}
                                onChange={(value: number | undefined) =>
                                    setSelectedPartnerId(value)
                                }
                                showCreationButton={false}
                                withEmail={false}
                                withIsLinked={true}
                                withContacts={false}
                                includeChildren={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={t("compliance_Template")}
                            className={classes.formItem}
                            name="templates"
                            valuePropName="value"
                            rules={[
                                {
                                    required: true,
                                    message: t("compliance_TemplateRequired"),
                                },
                            ]}>
                            <SelectTreeDataMultiple
                                placeholder={t("compliance_SelectTemplatePlaceholder")}
                                className={classes.select}
                                selectOptionField="name"
                                useData={useAllActiveTemplates.bind(null, reload)}
                                getPopupContainer={(node: any) =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                                selectOnlyChildren
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            className={classes.formItem}
                            name="multipleSAEnabled"
                            valuePropName="checked"
                            initialValue={true}>
                            <Checkbox>{t("compliance_MultipleSAEnabled")}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="compliancePartnerContactReceiver"
                            label={
                                <span>
                                    {t("compliance_RequestReceivers")}{" "}
                                    <Tooltip title={t("compliance_RequestReceiversTip")}>
                                        <span>
                                            <InfoCircle />
                                        </span>
                                    </Tooltip>
                                </span>
                            }
                            initialValue={defaultContactReceivers}
                            className={classes.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}>
                            <SelectPartnerContacts
                                partnerId={selectedPartnerId}
                                disabled={
                                    selectedPartnerId === undefined || selectedPartnerId === null
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            className={classes.formItem}
                            name="addCustomMsg"
                            valuePropName="checked"
                            initialValue={false}>
                            <Checkbox onChange={(e: any) => setAddCustomMsg(e.target.checked)}>
                                {t("compliance_AddCustomMsg")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                {addCustomMsg === true && (
                    <Row>
                        <Col span={24}>
                            <Form.Item name="customMsg" initialValue={""}>
                                <RichTextEditor
                                    placeholder={t("compliance_InsertText")}
                                    maxLength={1000}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};

export default RequestInfoModal;
