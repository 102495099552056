import { useEffect, useState } from "react";
import Provider from "services/api";

const useNotifications = (pageNumber: number, pageSize: number) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: { data: [] },
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Notification.notificationOverview(pageNumber, pageSize, {
                customError: {
                    message: "Could not retrieve list of notifications",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data: { ...data, data: [...state.data.data, ...data.data] },
            });
        })();
    }, [pageNumber, pageSize]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNotifications };
