import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: string | undefined;
    hasData: boolean;
};

const useCompanyFaqLink = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
        hasData: false,
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Company.getCompanyFaqLink().catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({
                loading: false,
                data: data,
                hasData: data !== undefined && data !== null && data !== "",
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
        hasData: state.hasData,
    };
};

export { useCompanyFaqLink };
