import React, { Fragment, useState, useEffect } from "react";
import { Modal, Tag, Row, Col, notification, Button } from "antd";
import { useTranslation } from "react-i18next";
import { SelectStatus } from "./sharedComponents";
import {
    useComplianceStatus,
    useComplianceStatusBulkUpdate,
    useComplianceStatusBulkUpdateSendEmail,
} from "controller";

interface IProps {
    visible: boolean;
    onClose: Function;
    selectedRowKeys: any[];
    onSuccess: Function;
}

const ChangeStatusModal = ({ visible, selectedRowKeys, onClose, onSuccess }: IProps) => {
    const { t } = useTranslation();
    const [selectedStatus, setSelectedStatus] = useState(-1);
    const { data } = useComplianceStatus();
    const { bulkUpdate, loading: updating } = useComplianceStatusBulkUpdate();
    const { sendBulkStatusChangeEmails } = useComplianceStatusBulkUpdateSendEmail();

    useEffect(() => {
        setSelectedStatus(-1);
    }, [visible]);

    const handleChangeStatus = async () => {
        let response: any = await bulkUpdate(selectedRowKeys, selectedStatus);
        if (response.notAccessComplianceIds.length > 0) {
            notification.success({
                message: t("success"),
                description: `${t(
                    "compliance_StatusUpdateSuccess"
                )}, however the following registrations were not updated because you do not have permission to change status ${response.notAccessComplianceIds.toString()}`,
                duration: 0,
            });
        } else {
            notification.success({
                message: t("success"),
                description: t("compliance_StatusUpdateSuccess"),
            });
        }
        sendBulkStatusChangeEmails(response);
        onSuccess();
    };

    return (
        <Modal
            wrapProps={{ "data-test": "change_visibility_modal" }}
            title={t("compliance_ChangeStatus")}
            open={visible}
            onOk={() => handleChangeStatus()}
            onCancel={() => {
                onClose();
            }}
            footer={[
                <Button
                    loading={updating}
                    type="primary"
                    onClick={async () => await handleChangeStatus()}>
                    {t("generic_Save")}
                </Button>,
            ]}>
            <Fragment>
                <div className="gridTagLabel">
                    {t("grid_TagSelected", {
                        name: t("compliance_Registrations"),
                    })}
                    :
                </div>
                <div className="gridTagArea">
                    {selectedRowKeys.map((id: any) => {
                        return (
                            <Tag color="blue" key={id}>
                                {id}
                            </Tag>
                        );
                    })}
                </div>
            </Fragment>
            <Row>
                <Col span={24}>{t("compliance_ChangeStatusInfo")}</Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
                <Col span={8}>{t("compliance_SelectStatus")}</Col>
                <Col span={16}>
                    <SelectStatus
                        data-test="compliance_status_select"
                        onChange={(val: number) => setSelectedStatus(val)}
                        value={undefined}
                        options={data}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export { ChangeStatusModal };
