import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { Link, useLocation } from "react-router-dom";
import Config from "../../../config";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getBackendDomainUrl, getGuestTokenFromPathName } from "utilities";
import classes from "./headerBar.module.scss";
import { NotificationDropDown } from "components/contentcomponents/notification";
import { ActionsDropdown } from "components/contentcomponents/action/actionDropdown/actionDropdown";
import { useCompanyType } from "controller";
import DownloadProgress from "components/utilitycomponents/excelExport/downloadProgress";
import ExcelExportHandler from "components/utilitycomponents/excelExport/ExcelExport";
import SupportDropDown from "components/contentcomponents/support/supportDropDown";
import AccountDropDown from "components/contentcomponents/account/accountDropDown";
import { PageTitle } from "components/utilitycomponents";

const HeaderBar = () => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const isGuest: boolean = user.profile["ll-Usertype"] === "Guest";
    const location = useLocation();
    const guestToken = getGuestTokenFromPathName(location.pathname);
    const userType: string = user.profile["ll-Usertype"];
    const [companyLogoUrl, setCompanyLogoUrl] = useState("");
    const { isDefaultCompany } = useCompanyType();
    const companyActionAccess =
        (user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;

    useEffect(() => {
        if (userType !== Config.guestUser) {
            setCompanyLogoUrl(
                `${getBackendDomainUrl()}/v1/File/GetCompanyLogo?companyId=${
                    user.profile["ll-CompanyId"]
                }`
            );
        } else {
            setCompanyLogoUrl(
                `${getBackendDomainUrl()}/v1/File/GetCompanyLogoByToken?guestToken=${guestToken}`
            );
        }
    }, []);

    return (
        <div className={classes.mainHeaderContainer}>
            <div>
                {location.pathname === "/" ? (
                    <div style={{ paddingTop: 6 }}>
                        {userType !== Config.guestUser && (
                            <Link to={"/"}>
                                <img
                                    width={300}
                                    height={45}
                                    className={classes.logoImg}
                                    alt=""
                                    src={companyLogoUrl}
                                />
                            </Link>
                        )}
                        {userType === Config.guestUser && (
                            <img
                                width={300}
                                height={45}
                                className={classes.logoImg}
                                alt=""
                                src={companyLogoUrl}
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <PageTitle />
                    </div>
                )}
            </div>
            <div className={classes.columnContainer}>
                <div className={classes.downloadContainer}>
                    <DownloadProgress className={classes.downloadProgressElement} />
                </div>
                <div className={classes.rightSide}>
                    {" "}
                    <div className={classes.devider}>
                        <Divider type="vertical" className={classes.icon} />
                    </div>
                    <div className={classes.notificationContainer}>
                        <SupportDropDown />
                    </div>
                    {!isGuest && companyActionAccess && (
                        <div className={classes.notificationContainer}>
                            <ActionsDropdown />
                        </div>
                    )}
                    {!isGuest && (
                        <div className={classes.notificationContainer}>
                            <NotificationDropDown />
                        </div>
                    )}
                    <div className={classes.notificationContainer}>
                        <AccountDropDown />
                    </div>
                </div>
                <ExcelExportHandler />
            </div>
        </div>
    );
};

export { HeaderBar };
