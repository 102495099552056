import { useEffect, useState } from "react";
import { Provider } from "services";

const useGuestDashBoard = (guestToken: string) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: {},
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            const data = await Provider.Analytics.getAnalyticsGuestDashboard(guestToken, {
                customError: { message: "Could not retrieve dashboard" },
            }).catch((err: any) => {
                setState({ loading: false, data: {} });
                return Promise.reject(err);
            });

            let layoutData = data.preset.boxes.map((box: any) => {
                return {
                    i: box.i,
                    x: box.x,
                    y: box.y,
                    w: box.w,
                    h: box.h,
                    isDraggable: false,
                    isResizable: false,
                };
            });
            setState({
                loading: false,
                data: { data: data, layout: layoutData },
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useGuestDashBoard };
