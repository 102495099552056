import { post } from "../base";
import { getRequestExportUrl } from "utilities";

const AzureFunction = {
    requestExcelExport: async (
        payload: any,
        { customError, onDownloadProgress, responseType }: any = {}
    ) => {
        const customConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Pragma: "no-cache",
                "Access-Control-Expose-Headers": "content-disposition",
            },
            timeout: 30 * 60 * 1000,
        };
        return await post({
            url: getRequestExportUrl(),
            payload,
            customError,
            customConfig,
            responseType,
            onDownloadProgress,
        });
    },
};

export default AzureFunction;
