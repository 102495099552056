import { IPbiBookmark, IPbiExportType } from "models";
import { useState } from "react";
import Provider from "services/api";

const useEmbedExport = () => {
    const [loading, setLoading] = useState(false);

    const exportRequest = async (
        reportId: string,
        workspaceId: string,
        format: IPbiExportType,
        bookmark?: IPbiBookmark
    ) => {
        setLoading(true);
        const response: any = await Provider.PowerBi.postExportRequest(
            workspaceId,
            reportId,
            format,
            bookmark,
            {
                customError: {
                    message: "Could not export report",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        const downloadUrl = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "reportExport.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    };

    return {
        loading: loading,
        exportRequest,
    };
};

export { useEmbedExport };
