import { get } from "../base";

const UserLandingPage = {
    getUserSurveyRequests: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetSurveyRequestsForUser`;
        return await get({ url, customError });
    },
    getUserSurveyRequestsCount: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetSurveyRequestsForUserCount`;
        return await get({ url, customError });
    },
    getNcrCount: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetOpenNcrForUserCount`;
        return await get({ url, customError });
    },
    getMyComplianceCount: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetComplianceForUserCount`;
        return await get({ url, customError });
    },
    getMyGateKeeperComplinaceCount: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetGateKeeperComplianceForUserCount`;
        return await get({ url, customError });
    },
    isGateKeeperUser: async ({ customError }: any = {}) => {
        const url = "/v1/UserLandingPage/IsGateKeeperUser";
        return await get({ url, customError });
    },
    getDevelopmentPlanCount: async ({ customError }: any = {}) => {
        const url = `/v1/UserLandingPage/GetActiveDevelopmentPlansCount`;
        return await get({ url, customError });
    },
};

export { UserLandingPage };
