import React, { FunctionComponent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Eye,
    GridButton,
    GridDeleteModal,
    Link01,
    PlusCircle,
    Trash02,
} from "components/utilitycomponents";
import { notification } from "antd";
import { useContactUpdate } from "controller";
import VisibilityModal from "./visibilityModal";
import { useSelector } from "react-redux";
import { AppState } from "store";

type PropsType = {
    selectedRowKeys: any[];
    selectedRows: any[];
    onClearSelectedRows: Function;
    onReloadData: Function;
    onCreateClicked: Function;
    onGroupClicked: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    selectedRowKeys,
    selectedRows,
    onClearSelectedRows,
    onReloadData,
    onCreateClicked,
    onGroupClicked,
}) => {
    const isAdmin: boolean = useSelector(
        (state: AppState) => state.oidc.user.profile["ll-Usertype"] === "administrator"
    );
    const { t } = useTranslation();
    const { deleteContact, loading: deleteLoading } = useContactUpdate();
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });
    const [visibilityModal, setVisibilityModal] = useState({ visible: false });

    const handleDelete = async (ids: number[]) => {
        const res: any = await deleteContact(ids);

        if (res.length > 0) {
            notification.error({
                message: t("systemMessage"),
                description: (
                    <>
                        {t(`contact_DeleteError`)}
                        {res.map((r: string) => (
                            <div>{r}</div>
                        ))}
                    </>
                ),
                duration: 0,
            });
            if (res.length < ids.length) {
                onClearSelectedRows([]);
                onReloadData();
            }
        } else {
            notification.success({
                message: t("success"),
                description: t(`contact_DeleteSuccess`),
            });
            setDeleteModal({
                visible: false,
            });
            onClearSelectedRows([]);
            onReloadData();
        }
    };

    const handleVisibilityChange = () => {
        setVisibilityModal({ visible: false });
        onClearSelectedRows([]);
        onReloadData();
    };

    const handleOpenDeleteModal = () => {
        if (selectedRows.length >= 125) {
            notification.error({
                message: t("systemMessage"),
                description: <>{t(`contact_LimitedDeleteError`)}</>,
            });
            return;
        } else {
            setDeleteModal({ visible: true });
        }
    };

    const buttonVisibility = selectedRowKeys.length > 0;
    const deleteButtonVisibility =
        selectedRowKeys.length > 0 || selectedRows.some(s => s.isEditable === true);
    const changeButtonVisibility =
        selectedRowKeys.length > 0 || selectedRows.some(x => x.visibilityEditable);

    return (
        <Fragment>
            {!buttonVisibility && (
                <GridButton
                    data-test="create_contact_button"
                    icon={<PlusCircle />}
                    type="default"
                    onClick={() => onCreateClicked()}>
                    {t("contact_CreateContact")}
                </GridButton>
            )}

            {isAdmin && changeButtonVisibility && (
                <GridButton
                    data-test="change_visibility_button"
                    icon={<Eye />}
                    iconColor="#1890ff"
                    type="default"
                    onClick={() => setVisibilityModal({ visible: true })}>
                    {t("contact_ChangeVisibility")}
                </GridButton>
            )}

            {buttonVisibility && (
                <GridButton
                    data-test="contact_group_button"
                    icon={<Link01 />}
                    iconColor="#1890ff"
                    type="default"
                    onClick={() => onGroupClicked()}>
                    {t("contact_AddGroup")}
                </GridButton>
            )}

            {deleteButtonVisibility && (
                <GridButton
                    danger
                    data-test="delete_contact_button"
                    icon={
                        <Trash02 style={{ color: selectedRowKeys.length !== 0 ? "#FF4D4F" : "" }} />
                    }
                    type="default"
                    onClick={() => handleOpenDeleteModal()}>
                    {t("contact_DeleteContact")}
                </GridButton>
            )}

            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                    });
                    onClearSelectedRows([]);
                }}
                data={selectedRows.map((r: any) => ({
                    id: r.id,
                    name: `${r.firstName} ${r.lastName}`,
                }))}
                name={t("contact_Contacts", "Contacts")}
                confirmLoading={deleteLoading}
                onConfirm={handleDelete}
            />

            {isAdmin && (
                <VisibilityModal
                    selectedRows={selectedRows}
                    onClose={() => setVisibilityModal({ visible: false })}
                    visible={visibilityModal.visible}
                    onConfirm={() => handleVisibilityChange()}
                />
            )}
        </Fragment>
    );
};

export default OverviewHeader;
