import { Provider } from "services";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setExcelExport, clearExcelExport, ExcelExportProps, AppState } from "store";

const useExcelExport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToken = useSelector((state: AppState) => state.oidc.user.access_token);

    const requestExcelExport = async ({
        reportType,
        loading,
        downloadProgress,
        ...rest
    }: ExcelExportProps) => {
        return await Provider.AzureFunction.requestExcelExport(
            {
                reportType,
                accessToken,
                ...rest,
            },
            {
                customError: {
                    message: t(
                        "ExcelExport_Error",
                        "Something went wrong while processing the export request. Please wait a moment and try again."
                    ),
                },
                responseType: "blob",
                onDownloadProgress: (progressEvent: any) => {
                    let downloadProgress: number = Math.floor(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    dispatch(
                        setExcelExport({
                            downloadProgress,
                            loading: true,
                        })
                    );
                },
            }
        ).catch(err => {
            dispatch(clearExcelExport());
            return Promise.reject(err);
        });
    };

    return {
        requestExcelExport,
    };
};

export { useExcelExport };
