import { IAction, IActionType, IOverviewActionTitle } from "models/action";

export const getPath = (action: IAction) => {
    switch (action.type) {
        case IActionType.NCR:
            return `/Ncrs/${action.registrationId}`;
        case IActionType.Compliance:
            return `/Compliance/${action.registrationId}`;
        case IActionType.Review:
            return `/Reviews/ReviewId/${action.registrationId}`;
        case IActionType.Survey:
            return `/Reviews/Survey/${action.registrationToken}`;
        case IActionType.SelfAssessment:
            if (action.registrationId !== null) {
                return `/Compliance/Edit/${action.registrationId}`;
            } else {
                return `/SelfAssessment/${action.registrationToken}`;
            }
        case IActionType.Segmentation:
            return `/Partners/SupplierProfile/2/${action.actionPartnerIds![0]}`;
        case IActionType.Performance:
            return `/Partners/SupplierProfile/3/${action.actionPartnerIds![0]}`;
        default:
            return "";
    }
};

export const getPathForOverview = (actionTitle: IOverviewActionTitle, type: IActionType) => {
    switch (type) {
        case IActionType.NCR:
            return `/Ncrs/${actionTitle.registrationId}`;
        case IActionType.Compliance:
            return `/Compliance/${actionTitle.registrationId}`;
        case IActionType.Review:
            return `/Reviews/ReviewId/${actionTitle.registrationId}`;
        case IActionType.SelfAssessment:
            if (actionTitle.registrationId !== 0) {
                return `/Compliance/Edit/${actionTitle.registrationId}`;
            } else {
                return `/SelfAssessment/${actionTitle.registrationToken}`;
            }
        case IActionType.Survey:
            return `/Reviews/Survey/${actionTitle.registrationToken}`;
        case IActionType.Segmentation:
            return `/Partners/SupplierProfile/2/${actionTitle.supplierId}`;
        case IActionType.Performance:
            return `/Partners/SupplierProfile/3/${actionTitle.supplierId}`;
        default:
            return "";
    }
};
