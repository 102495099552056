import React, { FunctionComponent, Fragment, useState } from "react";
import { DownOutlined, UpOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import ChildActivity from "./childActivity";
import { IActivityChild, ActivityType } from "models";
import { AddChildMessage } from "./addChildMessage";
import { useTranslation } from "react-i18next";
import { AppState } from "store";
import { useSelector } from "react-redux";
import Config from "../../../config";

type PropsType = {
    data: IActivityChild[] | null;
    guestCommentary?: boolean;
    parentActivityId: number;
    onRemoveActivity: (parentActivityId: number, activityId: number) => void;
    onAddActivity: (parentActivityId: number, activity: IActivityChild) => void;
    isContactActivty?: boolean;
    showChangeHistoryButton?: boolean;
    showTopDivider?: boolean;
    showMoreCount?: number;
};

const ChildActivities: FunctionComponent<PropsType> = ({
    data,
    parentActivityId,
    onRemoveActivity,
    onAddActivity,
    guestCommentary = false,
    isContactActivty = false,
    showChangeHistoryButton = true,
    showTopDivider = true,
    showMoreCount = 2,
}) => {
    const { t } = useTranslation();
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    const [displayChangeHistory, setDisplayChangeHistory] = useState(false);
    const isGuest =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]) ===
        Config.guestUser;

    const handletoggleChangeLog = () => {
        setDisplayChangeHistory(!displayChangeHistory);
        setShowMoreClicked(false);
    };

    if (!data) return null;

    let displayedData: IActivityChild[] = [];
    if (!displayChangeHistory) {
        displayedData = data.filter(
            (child: IActivityChild) =>
                child.typeName === ActivityType.Comment ||
                child.typeName === ActivityType.FilesUploaded
        );
    } else {
        displayedData = data.filter(
            (child: IActivityChild) =>
                child.typeName !== ActivityType.Comment &&
                child.typeName !== ActivityType.FilesUploaded
        );
    }

    return (
        <Fragment>
            {showTopDivider && <Divider />}

            {(!isGuest || guestCommentary) && (
                <AddChildMessage
                    onAddActivity={onAddActivity}
                    parentActivityId={parentActivityId}
                    contactActionActivity={isContactActivty}
                />
            )}

            {(!isGuest || guestCommentary) && showChangeHistoryButton && (
                <div style={{ textAlign: "right" }}>
                    <Button
                        data-test="show_change_history_button"
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={handletoggleChangeLog}
                        icon={<HistoryOutlined />}>
                        {displayChangeHistory
                            ? t("activity_HideChangeHistory")
                            : t("activity_ShowChangeHistory")}
                    </Button>
                </div>
            )}

            {(!isGuest || guestCommentary) &&
                displayedData.length === 0 &&
                displayChangeHistory && (
                    <span data-test="no_change_history">{t("activity_NoChangeHistory")}</span>
                )}

            {displayedData.length === 0 && !showChangeHistoryButton && (
                <span data-test="no_comments">{t("activity_NoComments")}</span>
            )}

            {displayedData.map(
                (child: IActivityChild, index: number) =>
                    (index < showMoreCount || (index >= showMoreCount && showMoreClicked)) && (
                        <ChildActivity
                            key={index}
                            data={child}
                            parentActivityId={parentActivityId}
                            onRemoveActivity={onRemoveActivity}
                        />
                    )
            )}
            {displayedData.length > 2 && (
                <div style={{ textAlign: "center" }}>
                    <Button type="link" onClick={() => setShowMoreClicked(!showMoreClicked)}>
                        {showMoreClicked ? (
                            <Fragment>
                                {t("showLess")} <UpOutlined />
                            </Fragment>
                        ) : (
                            <Fragment>
                                {t("showMore")} <DownOutlined />
                            </Fragment>
                        )}
                    </Button>
                </div>
            )}
        </Fragment>
    );
};

export { ChildActivities };
