import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: any[];
    loading: boolean;
};

const useRiskReport = (reload: any, riskReportId?: number) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data = await Provider.Company.getRiskReport(riskReportId);
            setState({ loading: false, data: data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useRiskReport };
