import React from "react";
import { Tooltip, Tag } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./multipleTags.module.scss";

type PropsType = {
    values: any[];
    ellipsisThreshold?: number;
    tagTooltips?: boolean;
    tagNumber?: number;
    color?: string;
    isSpecialStyle?: boolean;
    numberPerLine?: number;
    bordered?: boolean;
    tooltipValueField?: string;
    [key: string]: any;
    disableTooltip?: boolean;
};

const MultipleTags = ({
    values,
    disableTooltip = false,
    tagNumber = 3,
    color,
    tagTooltips = true,
    isSpecialStyle = false,
    numberPerLine = 3,
    ellipsisThreshold = 25,
    bordered = true,
    tooltipValueField = "name",
    ...rest
}: PropsType) => {
    const { t } = useTranslation();
    const MULTI_TAG_ELLIPSIS_THRESHOLD = ellipsisThreshold;

    return (
        <div {...rest} className={isSpecialStyle ? classes.flexTags : ""}>
            {values.slice(0, tagNumber).map((value: any, index: number) => {
                return tagTooltips ? (
                    <Tooltip title={!disableTooltip ? value[tooltipValueField] : ""} key={index}>
                        <Tag
                            bordered={bordered}
                            className={classes.specialTagStyle}
                            style={
                                isSpecialStyle
                                    ? {
                                          width:
                                              "calc(100% * (1/" + numberPerLine + ") - 3px - 1px)",
                                      }
                                    : {}
                            }
                            color={value.color ? value.color : color ? color : undefined}
                            key={value.id}>
                            {value.name.length > MULTI_TAG_ELLIPSIS_THRESHOLD
                                ? value.name.substr(0, MULTI_TAG_ELLIPSIS_THRESHOLD) + "..."
                                : value.name}
                        </Tag>
                    </Tooltip>
                ) : (
                    <Tag
                        bordered={bordered}
                        style={
                            isSpecialStyle
                                ? {
                                      width: "calc(100% * (1/" + numberPerLine + ") - 3px - 1px)",
                                  }
                                : {}
                        }
                        className={classes.specialTagStyle}
                        color={value.color ? value.color : color ? color : undefined}
                        key={value.id}>
                        {value.name.length > MULTI_TAG_ELLIPSIS_THRESHOLD
                            ? value.name.substr(0, MULTI_TAG_ELLIPSIS_THRESHOLD) + "..."
                            : value.name}
                    </Tag>
                );
            })}
            {values.length > tagNumber && (
                <Tooltip
                    title={values.slice(tagNumber).map((value: any, index: number) => {
                        return (
                            <Tooltip title={value[tooltipValueField]}>
                                <Tag
                                    bordered={bordered}
                                    color={value.color ? value.color : color ? color : undefined}
                                    className={classes.tagStyle}
                                    key={index}>
                                    {value.name.length > MULTI_TAG_ELLIPSIS_THRESHOLD
                                        ? value.name.substr(0, MULTI_TAG_ELLIPSIS_THRESHOLD) + "..."
                                        : value.name}
                                </Tag>
                            </Tooltip>
                        );
                    })}>
                    <span className={classes.categoryIndicator}>
                        {t("additionalCategories", { value: values.length - tagNumber })}
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export { MultipleTags };
