import { useState } from "react";
import { Provider } from "services";
import { ICreateSurvey } from "models";

const useSurveyCreate = () => {
    const [loading, setLoading] = useState(false);

    const createSurvey = async (payload: ICreateSurvey, { retryCount, customError }: any = {}) => {
        setLoading(true);
        const token = await Provider.Survey.createSurvey(payload, {
            customError: { message: "Could not create survey" },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return token;
    };

    return { loading, createSurvey };
};

export { useSurveyCreate };
