import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveyResults = (
    surveyId: number,
    segmentationAnalysisId: number,
    performanceAnalysisId: number,
    partnerId: any,
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
        customFields: {},
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (!externalLoading) {
                let data: any = [];
                if (surveyId && surveyId !== -1 && performanceAnalysisId === -1) {
                    data = await Provider.Survey.getSurveyResultsBySurveyId(
                        surveyId,
                        {
                            pageNumber,
                            pageSize,
                            filters,
                            sorts,
                            from: range.from,
                            to: range.to,
                        },
                        partnerId,
                        {
                            customError: { message: "Could not retrieve survey results" },
                        }
                    ).catch(err => {
                        setState({ loading: false, data: [], customFields: {}, totalRows: 0 });
                        return Promise.reject(err);
                    });
                }
                // else if (segmentationAnalysisId !== -1) {
                //     data = await Provider.Survey.getSurveyResultsBySegmentationAnalysisId(
                //         segmentationAnalysisId,
                //         {
                //             pageNumber,
                //             pageSize,
                //             filters,
                //             sorts,
                //             from: range.from,
                //             to: range.to,
                //         },
                //         partnerId,
                //         {
                //             customError: { message: "Could not retrieve surveys results" },
                //         }
                //     ).catch(err => {
                //         setState({ loading: false, data: [], customFields: {}, totalRows: 0 });
                //         return Promise.reject(err);
                //     });
                // }
                else if (performanceAnalysisId !== -1) {
                    data = await Provider.Performance.getPerformanceSurveyReviews(
                        surveyId,
                        {
                            pageNumber,
                            pageSize,
                            filters,
                            sorts,
                            from: range.from,
                            to: range.to,
                        },
                        partnerId,
                        {
                            customError: { message: "Could not retrieve surveys results" },
                        }
                    ).catch(err => {
                        setState({ loading: false, data: [], customFields: {}, totalRows: 0 });
                        return Promise.reject(err);
                    });
                }

                setState({
                    loading: false,
                    data: data.data,
                    totalRows: data.totalNumberOfRecords,
                    customFields: data.customFields,
                });
            }
        })();
    }, [
        pageSize,
        pageNumber,
        filters,
        sorts,
        externalLoading,
        reloadData,
        range,
        surveyId,
        segmentationAnalysisId,
    ]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        customFields: state.customFields,
    };
};

export { useSurveyResults };
