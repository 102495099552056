import React, { Fragment, FunctionComponent, useState } from "react";
import PartnerTable from "./partnerTable";
import ModalNcr from "./partnerOverviewModals/modal-ncr";
import ModalStatusLog from "./statuslogmodal/modal-statusLog";
import "./supplierTable.css";

interface IPartnerOverviewProps {}

const PartnerOverview: FunctionComponent<IPartnerOverviewProps> = () => {
    const [showStatusLogModal, setShowStatusLogModal] = useState<any>(false);
    const [currentRow, setCurrentRow] = useState<any>();

    return (
        <Fragment>
            <PartnerTable
                tableType="partnerOverview"
                selectableRows={false}
                setCustomHeadlines={() => {}}
                showStatusLogModal={(value: boolean, row: any) => {
                    setShowStatusLogModal(value);
                    setCurrentRow(row);
                }}
            />

            <ModalNcr />
            <ModalStatusLog
                visible={showStatusLogModal}
                setModalVisible={(value: boolean) => setShowStatusLogModal(value)}
                selectedRow={currentRow}
            />
        </Fragment>
    );
};

export default PartnerOverview;
