import { useState } from "react";
import { Provider } from "services";

const useComplianceStatusBulkUpdate = () => {
    const [loading, setLoading] = useState(false);

    const bulkUpdate = async (ids: number[], statusId: number) => {
        setLoading(true);

        const res = await Provider.Compliance.bulkUpdateStatus(ids, statusId, {
            customError: { message: "Could not update the status" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };

    return {
        loading,
        bulkUpdate,
    };
};

export { useComplianceStatusBulkUpdate };
