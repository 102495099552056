import React, { useState, useEffect } from "react";
import {
    Button,
    Flex,
    Modal,
    Form,
    Checkbox,
    Divider,
    Skeleton,
    Input,
    Tag,
    Row,
    Col,
    notification,
} from "antd";
import {
    Edit02,
    RichTextEditor,
    SelectData,
    SelectPartnerContacts,
} from "components/utilitycomponents";
import useProduct from "controller/product/useProduct";
import dayjs from "dayjs";
import classes from "./overview.module.scss";
import { IDocumentation } from "models";
import { useTranslation } from "react-i18next";
import { useSendSelfAssessment, useProductStatus } from "controller";
import RegistrationModal from "components/contentcomponents/compliance/overview/registrationModal/registrationModal";
import CreateModal from "components/contentcomponents/compliance/overview/create/createModal";

type IProps = {
    id: number | undefined;
    visible: boolean;
    onClose: Function;
    reloadTable: Function;
    partnerId: number;
};

const ProductDocumentationDetails = ({ id, visible, onClose, reloadTable, partnerId }: IProps) => {
    const [reload, setReload] = useState<{ reload: boolean }>({ reload: false });
    const [form] = Form.useForm();
    const selectedDocuments = Form.useWatch("selectedDocuments", form);
    const [documentNames, setDocumentNames] = useState<string[]>([]);
    const [showContactSelect, setShowContactSelect] = useState<boolean>(false);
    const { data, loading } = useProduct(id, partnerId, reload);
    const [showDetails, toggleDetails] = useState<boolean>(false);
    const { sendSelfAssessments, loading: requestLoading } = useSendSelfAssessment();
    const { t } = useTranslation();
    const selectedContacts = Form.useWatch("selectedContacts", form);
    const [addCustomMsg, setAddCustomMsg] = useState<boolean>(false);
    const [complianceModal, toggleComplianceModal] = useState<{
        visible: boolean;
        id: number | undefined;
    }>({ visible: false, id: undefined });
    const [createModal, toggleCreateModal] = useState<{
        open: boolean;
        templateId: number | undefined;
    }>({
        open: false,
        templateId: undefined,
    });
    useEffect(() => {
        form.resetFields();
        setShowContactSelect(false);
    }, [visible]);

    const setEndDateTagColor = (endDate: string) => {
        if (dayjs(endDate).isBefore(dayjs().add(1, "month"))) return "#f50";
        if (dayjs().isAfter(endDate)) return "#f5222d";
    };

    const onSubmit = async () => {
        let values = await form.validateFields();
        let templateIds = Object.keys(values.selectedDocuments)
            .map((key: string) => values.selectedDocuments[key] && parseInt(key))
            .filter((key: any) => key);

        await sendSelfAssessments({
            templateIds: templateIds,
            partnerId: partnerId,
            multipleAssesmentsEnabled: true,
            hasAdditionalText: values.addCustomMsg,
            additionalText: values.customMsg,
            compliancePartnerContactReceiverIds: selectedContacts,
            productIds: [id!],
        });
        notification.success({
            message: "Success",
            description: t("compliance_sendSA_Success"),
        });
        closeModal();
    };

    const closeModal = () => {
        form.resetFields();
        setDocumentNames([]);
        setAddCustomMsg(false);
        onClose();
    };

    const disabled = true;
    const disabledClass = classes.disabledFormItem;
    return (
        <>
            <Modal
                zIndex={1000}
                destroyOnClose
                title={
                    <Divider orientation="left" orientationMargin={0}>
                        {t("generic_Details")}
                    </Divider>
                }
                width={1200}
                open={visible}
                onCancel={() => closeModal()}
                footer={[
                    !showContactSelect ? (
                        <Button
                            key="requestBtn"
                            loading={requestLoading}
                            disabled={
                                selectedDocuments === undefined ||
                                Object.values(selectedDocuments).filter(
                                    (selected: any) => selected === true
                                ).length === 0
                            }
                            onClick={async () => {
                                if (!showContactSelect) {
                                    setShowContactSelect(true);
                                } else {
                                    onSubmit();
                                }
                            }}>
                            {t("product_RequestDocs")}
                        </Button>
                    ) : (
                        undefined
                    ),
                    showContactSelect ? (
                        <Button key="backBtn" onClick={() => setShowContactSelect(false)}>
                            {t("generic_Back")}
                        </Button>
                    ) : (
                        undefined
                    ),
                    showContactSelect ? (
                        <Button
                            type="primary"
                            key="requestBtn"
                            loading={requestLoading}
                            disabled={
                                selectedContacts === undefined || selectedContacts.length === 0
                            }
                            onClick={onSubmit}>
                            {t("compliance_CreateAndSendSA")}
                        </Button>
                    ) : (
                        undefined
                    ),
                ]}>
                <Skeleton loading={loading} active>
                    <Form
                        form={form}
                        variant="filled"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        colon={false}>
                        <Form.Item
                            name="productId"
                            label={t("product_ProductId")}
                            initialValue={data && data.productId}
                            className={classes.smallMargin}>
                            <Input
                                disabled={disabled}
                                className={disabledClass}
                                placeholder={
                                    data && data.isUserCreated
                                        ? t("product_ProductIdPlaceholder")
                                        : t("product_NotEditable")
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label={t("generic_Name")}
                            initialValue={data && data.name}
                            className={classes.smallMargin}>
                            <Input
                                disabled={disabled}
                                className={disabledClass}
                                placeholder={
                                    data && data.isUserCreated
                                        ? t("product_ProductNamePlaceholder")
                                        : t("product_NotEditable")
                                }
                            />
                        </Form.Item>
                        {showDetails && (
                            <>
                                <Form.Item
                                    name="description"
                                    label={t("generic_Description")}
                                    initialValue={data && data.description}
                                    className={classes.smallMargin}>
                                    <Input.TextArea
                                        disabled={disabled}
                                        className={disabledClass}
                                        placeholder={
                                            data && data.isUserCreated
                                                ? t("product_ProductDescriptionPlaceholder")
                                                : t("product_NotEditable")
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="commodityCategory"
                                    label={t("product_Category")}
                                    initialValue={data && data.commodityCategory}
                                    className={classes.smallMargin}>
                                    <Input disabled={disabled} className={disabledClass} />
                                </Form.Item>
                                <Form.Item
                                    name="productStatus"
                                    label={t("product_ProductStatus")}
                                    initialValue={data && data.productStatusId}
                                    className={classes.smallMargin}>
                                    <SelectData
                                        useData={useProductStatus}
                                        selectIdField="id"
                                        selectOptionField="name"
                                        disabled={disabled}
                                        className={disabledClass}
                                        showArrow={false}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="created"
                                    label={t("Created")}
                                    initialValue={data && dayjs(data.created).format("MMM D, YYYY")}
                                    className={classes.smallMargin}>
                                    <Input disabled={disabled} className={disabledClass} />
                                </Form.Item>
                                <Form.Item
                                    name="modified"
                                    label={t("generic_Updated")}
                                    initialValue={
                                        data && dayjs(data.modified).format("MMM D, YYYY")
                                    }
                                    className={classes.smallMargin}>
                                    <Input disabled={disabled} className={disabledClass} />
                                </Form.Item>
                            </>
                        )}
                        <Flex justify="center">
                            <div onClick={() => toggleDetails(!showDetails)}>
                                {showDetails ? (
                                    <a>{t("showLess").toLowerCase()}</a>
                                ) : (
                                    <a>{t("showMore").toLowerCase()}</a>
                                )}
                            </div>
                        </Flex>

                        <Divider
                            orientation="left"
                            orientationMargin={0}
                            children={t("product_Documentation")}
                        />
                        <div className={showContactSelect ? classes.noDisplay : ""}>
                            <Row style={{ marginBottom: 8 }}>
                                <Col span={10}>
                                    <span style={{ fontWeight: "bold" }}>
                                        {t("compliance_Template")}
                                    </span>
                                </Col>
                                <Col span={2}>
                                    <span style={{ fontWeight: "bold" }}>
                                        {t("product_EndDate")}
                                    </span>
                                </Col>
                                <Col span={8}>
                                    <span style={{ fontWeight: "bold" }}>
                                        {t("product_ProductStatus")}
                                    </span>
                                </Col>
                                <Col span={2}>
                                    <span style={{ fontWeight: "bold" }}>
                                        {t("generic_Requested")}
                                    </span>
                                </Col>
                                <Col span={2}>
                                    {" "}
                                    <span style={{ fontWeight: "bold" }}>{"Action"}</span>
                                </Col>
                            </Row>
                            {data &&
                                data.documentation &&
                                data.documentation.map((doc: IDocumentation, index: number) => (
                                    <Row key={index}>
                                        <Col span={1}>
                                            <Form.Item
                                                key={doc.templateName}
                                                style={{ marginTop: 4, marginBottom: 4 }}
                                                name={[
                                                    "selectedDocuments",
                                                    doc.templateId.toString(),
                                                ]}
                                                valuePropName="checked">
                                                <Checkbox
                                                    onChange={(val: any) =>
                                                        documentNames.filter(
                                                            (name: string) =>
                                                                name === doc.templateName
                                                        ).length === 0
                                                            ? setDocumentNames([
                                                                  ...documentNames,
                                                                  doc.templateName,
                                                              ])
                                                            : setDocumentNames(
                                                                  documentNames.filter(
                                                                      (name: string) =>
                                                                          doc.templateName !== name
                                                                  )
                                                              )
                                                    }></Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <div
                                                key={doc.complianceId}
                                                className={classes.informationTag}>
                                                {doc.complianceId ? (
                                                    <a
                                                        onClick={() =>
                                                            toggleComplianceModal({
                                                                visible: true,
                                                                id: doc.complianceId,
                                                            })
                                                        }>
                                                        {doc.templateName}
                                                    </a>
                                                ) : (
                                                    doc.templateName
                                                )}
                                            </div>
                                        </Col>
                                        <Col span={2}>
                                            {doc.endDate ? (
                                                <Tag
                                                    bordered={false}
                                                    className={classes.informationTag}
                                                    color={setEndDateTagColor(doc.endDate)}>
                                                    {dayjs(doc.endDate).format("MMM D, YYYY")}
                                                </Tag>
                                            ) : (
                                                <Tag
                                                    bordered={false}
                                                    className={classes.informationTag}>
                                                    {t("product_NoEndDateSet")}
                                                </Tag>
                                            )}
                                        </Col>
                                        <Col span={8}>
                                            {doc.status ? (
                                                <Tag
                                                    bordered={false}
                                                    className={classes.informationTag}>
                                                    {t(doc.status)}
                                                </Tag>
                                            ) : (
                                                <Tag
                                                    bordered={false}
                                                    className={classes.informationTag}>
                                                    {t(
                                                        "product_NoComplianceRegisted"
                                                    ).toLocaleLowerCase()}
                                                </Tag>
                                            )}
                                        </Col>
                                        <Col span={2}>
                                            {doc.requested && (
                                                <Tag
                                                    bordered={false}
                                                    className={classes.informationTag}>
                                                    {dayjs(doc.requested).format("MMM D, YYYY")}
                                                </Tag>
                                            )}
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                icon={<Edit02 />}
                                                shape="default"
                                                onClick={() =>
                                                    doc.complianceId
                                                        ? toggleComplianceModal({
                                                              visible: true,
                                                              id: doc.complianceId,
                                                          })
                                                        : toggleCreateModal({
                                                              open: true,
                                                              templateId: doc.templateId,
                                                          })
                                                }></Button>
                                        </Col>
                                    </Row>
                                ))}
                        </div>
                        <div
                            className={showContactSelect ? "" : classes.noDisplay}
                            style={{ minHeight: 190 }}>
                            <Row>
                                <Col push={4} span={16}>
                                    <div style={{ fontWeight: "bold" }}>
                                        {t("product_RequestInfo_SelectReceiverTip1")}
                                    </div>
                                    {documentNames.map((name: string, index: number) => (
                                        <div key={index}>{name}</div>
                                    ))}
                                </Col>
                            </Row>

                            <Form.Item
                                className={classes.formItemNoColor}
                                label={<></>}
                                name="selectedContacts"
                                style={{ marginTop: 32 }}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 16 }}>
                                <SelectPartnerContacts partnerId={partnerId} />
                            </Form.Item>

                            <Form.Item
                                label={<></>}
                                name="addCustomMsg"
                                valuePropName="checked"
                                initialValue={false}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 16 }}>
                                <Checkbox onChange={(e: any) => setAddCustomMsg(e.target.checked)}>
                                    {t("compliance_AddCustomMsg")}
                                </Checkbox>
                            </Form.Item>
                            {addCustomMsg === true && (
                                <Form.Item
                                    name="customMsg"
                                    label={<></>}
                                    initialValue={""}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 16 }}>
                                    <RichTextEditor
                                        placeholder={t("compliance_InsertText")}
                                        maxLength={1000}
                                        bordered={true}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    </Form>
                </Skeleton>
            </Modal>
            <RegistrationModal
                visible={complianceModal.visible}
                id={complianceModal.id}
                onClose={() => toggleComplianceModal({ visible: false, id: undefined })}
            />
            <CreateModal
                templateId={createModal.templateId}
                partnerId={partnerId}
                productId={id}
                open={createModal.open}
                onClose={(reload: boolean) => {
                    toggleCreateModal({ open: false, templateId: undefined });
                    if (reload) {
                        setReload({ reload: true });
                        reloadTable();
                    }
                }}
            />
        </>
    );
};

export { ProductDocumentationDetails };
