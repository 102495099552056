import { useState } from "react";
import { Provider } from "services";

const useTemplateGroupMatrixUpdate = () => {
    const [loading, setLoading] = useState(false);
    const update = async (templateId: number, groupId: number, isCheck: boolean) => {
        setLoading(true);
        let data = await Provider.Compliance.updateTemplateGroupMatrix(
            templateId,
            groupId,
            isCheck,
            {
                customError: {
                    message: "Could not update template-group data",
                },
            }
        ).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    return {
        loading,
        update,
    };
};

export { useTemplateGroupMatrixUpdate };
