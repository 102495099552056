import React, { useState, FunctionComponent, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
    getColumnRadioProps,
    MinimalButton,
} from "components/utilitycomponents";

import {
    DashOutlined,
    DownloadOutlined,
    EditOutlined,
    FolderFilled,
    FolderOpenFilled,
    PlusOutlined,
} from "@ant-design/icons";

import { Popover, Progress, Dropdown, Popconfirm } from "antd";
import OverviewHeader from "./overviewHeader";
import {
    useFolderExternalVisibilities,
    useFolderFileCreators,
    useFolderInternalVisibilities,
    useFolderOverview,
    useFileDownload,
    useCompanyType,
} from "controller";
import { formatTime, showDownloadDialog } from "utilities";
import EditFolderModal from "./editFolderModal";
import EditFileModal from "./editFileModal";
import classes from "./styles/overview.module.scss";
import CreateFolderModal from "./createFolderModal";
import SideBarFilter from "./sideBarFilter";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { folderId, fileId }: any = useParams();
    const { downloadFileLoading, downloadFileProgress, downloadFile } = useFileDownload();

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [editFolderModal, setEditFolderModal] = useState<any>({
        data: {},
        visible: false,
    });
    const [editFileModal, setEditFileModal] = useState<any>({
        data: {},
        visible: false,
    });
    const [createFolderModal, setCreateFolderModal] = useState<any>({
        visible: false,
    });
    const [filesFilter, setFilesFilter] = useState<any>([]);
    const { isDefaultCompany } = useCompanyType();
    useEffect(() => {
        setFilesFilter([{ name: "owner", value: `${!isDefaultCompany}` }]);
        if (fileId) {
            setEditFileModal({
                data: { id: Number(fileId), name: "" },
                visible: true,
            });
        }
    }, [fileId]);

    const [downloadInInProgressId, setDownloadInProgressId] = useState(0);
    const handleOpenEditModal = (e: any, record: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (record.canEdit === true) {
            if ("extension" in record) {
                setEditFileModal({
                    data: record,
                    visible: true,
                });
            } else {
                setEditFolderModal({
                    data: record,
                    visible: true,
                });
            }
        }
    };

    const handleDownload = (record: any) => ({
        onClick: async (e: any) => {
            if ("extension" in record) {
                if (!downloadFileLoading) {
                    e.stopPropagation();
                    e.preventDefault();
                    setDownloadInProgressId(record.id);
                    const data = await downloadFile(record.id);
                    showDownloadDialog(data, record.name);
                }
            }
        },
        style: {
            cursor: "pointer",
        },
    });

    const handleOnSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        selectedRows,
        onChange: handleOnSelectChange,
        getCheckboxProps: (record: any) => ({
            disabled: record && record.canEdit === false,
        }),
    };

    const columns = [
        {
            title: t("file_Name"),
            dataIndex: "name",
            key: "Name",
            width: 350,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: "name",
            ...getColumnSearchProps({ dataIndex: "name" }),
            render: (val: any, record: any) => {
                if ("extension" in record) {
                    return (
                        <div>
                            <span className={classes.fileName}>
                                <Popover
                                    content={
                                        <div style={{ width: "100px" }}>
                                            <Progress percent={downloadFileProgress} size="small" />
                                        </div>
                                    }
                                    open={
                                        downloadFileLoading && downloadInInProgressId === record.id
                                    }>
                                    {record.name}
                                </Popover>
                            </span>

                            {record.canEdit === true && (
                                <span className={classes.fileEditButton}>
                                    <MinimalButton
                                        data-test="edit_properties_button"
                                        icon={<EditOutlined />}
                                        onClick={(e: any) => handleOpenEditModal(e, record)}
                                        iconColor="#0477a9"
                                    />
                                </span>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.folderNameContainer}>
                            <span className={classes.name}>{val}</span>
                            {record.canEdit === true && record.parentId === null && (
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                key: "1",
                                                label: t("generic_Edit"),
                                                icon: <EditOutlined />,
                                                onClick: (e: any) => {
                                                    handleOpenEditModal(e.domEvent, record);
                                                },
                                            },
                                            {
                                                key: "2",
                                                label: "Add Sub-Folder",
                                                onClick: (e: any) => {
                                                    e.domEvent.preventDefault();
                                                    e.domEvent.stopPropagation();
                                                    setCreateFolderModal({
                                                        visible: true,
                                                        parentId: record.id,
                                                    });
                                                },
                                                icon: <PlusOutlined style={{ color: "#0477a9" }} />,
                                            },
                                        ],
                                    }}>
                                    <DashOutlined className={classes.burgerMenu} rotate={90} />
                                </Dropdown>
                            )}
                            {record.canEdit && record.parentId !== null && (
                                <span className={classes.editButton}>
                                    <MinimalButton
                                        data-test="edit_properties_button"
                                        icon={<EditOutlined />}
                                        onClick={(e: any) => handleOpenEditModal(e, record)}
                                        iconColor="#0477a9"
                                    />
                                </span>
                            )}
                        </div>
                    );
                }
            },
        },
        {
            title: t("file_CreatedBy"),
            dataIndex: "createdBy",
            key: "CreatedBy",
            width: 250,
            sorter: true,
            filterType: "array",
            viewColumnId: "createdBy",
            ...getColumnSelectProps(
                useFolderFileCreators,
                {
                    value: ["userId"],
                    text: ["fullName"],
                },
                { dataIndex: "createdBy" }
            ),
        },
        {
            title: t("file_Created"),
            dataIndex: "created",
            key: "Created",
            width: 150,
            sorter: true,
            viewColumnId: "created",
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "created" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY (HH:mm)"),
        },
        {
            title: t("file_CompanyName"),
            dataIndex: "companyName",
            key: "CompanyName",
            width: 250,
            sorter: true,
            filterType: "string",
            viewColumnId: "companyName",
            ...getColumnSearchProps({ dataIndex: "companyName" }),
        },
        {
            title: t("file_Type"),
            dataIndex: "extension",
            key: "Type",
            width: 150,
            filterType: "string",
            viewColumnId: "extension",
            ...getColumnSearchProps({ dataIndex: "extension" }),
        },
        {
            title: t("file_InternalVisibility"),
            dataIndex: "internalVisibility",
            key: "InternalVisibility",
            width: 200,
            sorter: true,
            filterType: "array",
            viewColumnId: "internalVisibility",
            ...getColumnRadioProps(
                useFolderInternalVisibilities,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "internalVisibility" }
            ),
        },
        {
            title: t("file_ExternalVisibility"),
            dataIndex: "externalVisibility",
            key: "ExternalVisibility",
            filterType: "array",
            width: 200,
            sorter: true,
            viewColumnId: "externalVisibility",
            ...getColumnSelectProps(
                useFolderExternalVisibilities,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "externalVisibility" }
            ),
        },
        {
            title: t("file_AlertDate"),
            dataIndex: "alertDate",
            key: "AlertDate",
            width: 250,
            sorter: true,
            filterType: "date",
            viewColumnId: "alertDate",
            ...getColumnRangePickerProps({ dataIndex: "alertDate" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
        {
            title: t("file_EndDate"),
            dataIndex: "endDate",
            key: "EndDate",
            width: 250,
            sorter: true,
            filterType: "date",
            viewColumnId: "endDate",
            ...getColumnRangePickerProps({ dataIndex: "endDate" }),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY"),
        },
        {
            title: t("file_Size") + " (MB)",
            dataIndex: "size",
            key: "Size",
            width: 150,
            sorter: true,
            filterType: "range",
            viewColumnId: "size",
            onCell: (record: any) =>
                "extension" in record
                    ? {
                          style: { textAlign: "left" },
                      }
                    : {},
            ...getColumnMinMaxProps(0, 40, { dataIndex: "size" }),
        },
    ];

    return (
        <Fragment>
            <Grid
                data-test="file_table"
                additionalColumnFilters={filesFilter}
                classNames={["file_table"]}
                title={() => (
                    <OverviewHeader
                        selectedRowKeys={selectedRowKeys}
                        selectedRows={selectedRows}
                        onClearSelectedRows={setSelectedRowKeys}
                        onReloadData={() => setReloadData({ reload: true })}
                    />
                )}
                sidebar={() => (
                    <SideBarFilter
                        defaultVaule={filesFilter[0]?.value === "true"}
                        onSelect={(val: boolean) =>
                            setFilesFilter([{ name: "owner", value: `${val}` }])
                        }
                    />
                )}
                columns={columns}
                fixedColumns={["name"]}
                useData={useFolderOverview.bind(null, folderId)}
                tableType="File"
                rowSelection={rowSelection}
                reloadData={reloadData}
                expandable={{
                    expandIcon: (props: any) => (
                        <CustomExpandIcon {...props} handleDownload={handleDownload} />
                    ),
                    columnWidth: 50,
                    expandRowByClick: true,
                }}
            />

            <EditFolderModal
                data={editFolderModal.data}
                visible={editFolderModal.visible}
                onClose={(reload: boolean) => {
                    setEditFolderModal({ data: {}, visible: false });
                    if (reload) {
                        setReloadData({ reload: true });
                        setSelectedRowKeys([]);
                    }
                }}
            />

            <EditFileModal
                data={editFileModal.data}
                visible={editFileModal.visible}
                onClose={(reload: boolean) => {
                    setEditFileModal({ data: {}, visible: false });
                    if (reload) {
                        setReloadData({ reload: true });
                        setSelectedRowKeys([]);
                    }
                    if (fileId) {
                        history.push("/Files");
                    }
                }}
            />
            <CreateFolderModal
                visible={createFolderModal.visible}
                parentId={createFolderModal.parentId}
                onClose={(reload: boolean = false) => {
                    setCreateFolderModal({ visible: false });
                    if (reload) {
                        setReloadData({ reload: true });
                    }
                }}
            />
        </Fragment>
    );
};

function CustomExpandIcon(props: any) {
    const { t } = useTranslation();
    let icon: any;
    if (props.expanded && props.record.children) {
        icon = (
            <div className={classes.folderIconContainer}>
                <FolderOpenFilled className={classes.folder} />
            </div>
        );
    } else {
        if (props.record.children) {
            icon = (
                <div className={classes.folderIconContainer}>
                    <FolderFilled className={classes.folder} />
                </div>
            );
        } else {
            icon = (
                <div className={classes.iconContainer} title={t("files_Download")}>
                    <Popconfirm
                        title={t("generic_Download_FIle")}
                        description={t("confirm_Download_File")}
                        placement="rightTop"
                        onConfirm={props.handleDownload(props.record).onClick}
                        okText={t("generic_Download")}
                        cancelText={t("generic_Cancel")}>
                        <DownloadOutlined style={{ fontSize: "18px" }} />
                    </Popconfirm>
                </div>
            );
        }
    }
    return (
        <div
            className={classes.expandButton}
            onClick={e => {
                props.onExpand(e, props.record);
            }}>
            {icon}
        </div>
    );
}

export default Overview;
