import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveyTitles = (isReviewOverview = false, isNonResponseOverview = false) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            let data: any[] = [];
            if (isReviewOverview) {
                data = await Provider.Survey.getSurveyTitlesForReviewOverview().catch(
                    (err: any) => {
                        setState({ loading: false, data: [] });
                        return Promise.reject(err);
                    }
                );
            } else if (isNonResponseOverview) {
                data = await Provider.Survey.getSurveyTitlesForNonResponseOverview().catch(
                    (err: any) => {
                        setState({ loading: false, data: [] });
                        return Promise.reject(err);
                    }
                );
            } else {
                data = await Provider.Survey.getSurveyTitles().catch((err: any) => {
                    setState({ loading: false, data: [] });
                    return Promise.reject(err);
                });
            }
            let surveyTiles: any[] = data.map((x: any) => ({
                id: x.id,
                title: `${x.title} (id:${x.id})`,
            }));
            setState({
                loading: false,
                data: surveyTiles,
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyTitles };
