export enum IModule {
    "File" = 1,
    "Compliance" = 2,
    "Review" = 3,
    "NCR" = 4,
    "Kpi" = 5,
    "Action" = 6,
    "Home" = 7,
    "Supplier" = 8,
    "Reporting" = 9,
}
