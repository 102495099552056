import React, { useEffect } from "react";
import {
    Alert,
    Button,
    Flex,
    Form,
    Input,
    Modal,
    Row,
    Skeleton,
    Tooltip,
    notification,
} from "antd";
import { ArrowRight, InfoCircle, SelectData, SelectTreeData } from "components/utilitycomponents";
import {
    useProductHasDocumentation,
    useProductStatus,
    useProductMoveDocumentation,
} from "controller";
import useProducts from "controller/product/useProducts";
import { useTranslation } from "react-i18next";
import { IProduct } from "models";
import classes from "./overview.module.scss";

type Props = {
    product: IProduct | undefined;
    visible: boolean;
    onClose: Function;
};

const MoveDocumentsModal = ({ product, visible, onClose }: Props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { data: hasDocuments, loading: checkingForDocuments } = useProductHasDocumentation(
        product?.id
    );
    const { data: products, loading: loadingProducts } = useProducts();
    const { moveDocumentation, loading: movingDocumentation } = useProductMoveDocumentation();

    const onSubmit = async () => {
        let values = await form.validateFields();
        let reloadOverview = values.productStatus !== product?.productStatusId;
        await moveDocumentation(product!.id, values.toProduct, values.productStatus);
        onClose(reloadOverview);
        notification.success({ message: t("success"), type: "success" });
    };

    useEffect(() => {
        form.resetFields();
    }, [product, visible]);

    return (
        <Modal
            destroyOnClose
            title={<div>{t("product_MoveProductTitle")}</div>}
            onOk={onSubmit}
            open={visible}
            onCancel={() => onClose()}
            width={800}
            footer={[
                <Button
                    loading={movingDocumentation}
                    type="primary"
                    danger
                    disabled={!hasDocuments}
                    onClick={async () => await onSubmit()}>
                    {t("generic_Confirm")}
                </Button>,
            ]}>
            <Skeleton loading={checkingForDocuments || loadingProducts}>
                <Form form={form} colon={false} layout="vertical">
                    <Alert
                        message={
                            hasDocuments ? t("generic_BeAdvised") : t("product_NoDocumentation")
                        }
                        description={hasDocuments ? t("product_MoveDocumentationInfo") : ""}
                        type="warning"
                        showIcon
                    />
                    <Flex justify="center" style={{ marginTop: 30 }}>
                        <div>
                            <Row>{t("generic_From")}</Row>
                            <Row>
                                <Form.Item
                                    name="fromProduct"
                                    required={false}
                                    initialValue={product && product.name}
                                    rules={[{ required: true, message: t("inputIsRequiredError") }]}
                                    noStyle>
                                    <Input
                                        className={classes.disabledFormItem}
                                        disabled
                                        style={{ marginTop: 2 }}
                                    />
                                </Form.Item>
                            </Row>
                        </div>
                        <div
                            style={{
                                marginTop: 18,
                                display: "inline-block",
                                fontSize: 24,
                                marginLeft: 16,
                                marginRight: 16,
                            }}>
                            <ArrowRight />
                        </div>
                        <div>
                            <Row>{t("generic_To")}</Row>
                            <Row>
                                <Form.Item
                                    name="toProduct"
                                    required={false}
                                    rules={[{ required: true, message: t("inputIsRequiredError") }]}
                                    noStyle>
                                    <SelectTreeData
                                        style={{ width: 300 }}
                                        placeholder="Select a product"
                                        useData={() => ({
                                            loading: loadingProducts,
                                            data: products,
                                        })}
                                        selectOptionField="name"
                                    />
                                </Form.Item>
                            </Row>
                        </div>
                    </Flex>
                    <Flex justify="start" style={{ marginTop: 32 }}>
                        <Form.Item
                            style={{ marginLeft: 110 }}
                            label={
                                <>
                                    {t("product_ProductStatus")}
                                    <Tooltip
                                        title={
                                            product && !product.isUserCreated
                                                ? t("product_ProductStatusDisabledInfo")
                                                : t("product_ProductStatusInfo")
                                        }>
                                        <span style={{ marginLeft: 4 }}>
                                            <InfoCircle />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            name="productStatus"
                            initialValue={product && product.productStatusId}>
                            <SelectData
                                disabled={product && !product.isUserCreated}
                                useData={useProductStatus}
                                selectOptionField="name"
                                style={{ width: 180 }}
                            />
                        </Form.Item>
                    </Flex>
                </Form>
            </Skeleton>
        </Modal>
    );
};

export { MoveDocumentsModal };
