import { Overview } from "components/contentcomponents/developmentPlans/overview";
import React, { FunctionComponent } from "react";

export const developmentPlanOverviewPage: FunctionComponent = () => {
    return (
        <div style={{ paddingRight: 12, paddingLeft: 12 }}>
            <Overview />
        </div>
    );
};
export default developmentPlanOverviewPage;
