import React, { FunctionComponent, useState } from "react";
import { Button, Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { SelectTreeData } from "components/utilitycomponents";
import { useCompanyType, usePartnerList } from "controller";
import { SupplierProfileModal } from "components/contentcomponents/suppliers/supplierProfile/supplierProfileModal";

type PropTypes = {
    visible: boolean;
    onClose: Function;
};

const SelectSupplierModal: FunctionComponent<PropTypes> = ({ visible, onClose }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { isDefaultCompany } = useCompanyType();
    const [showSupplierProfileModal, setShowSupplierProfileModal] = useState(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState<number>(0);

    const handleSubmit = async () => {
        setShowSupplierProfileModal(true);
    };

    return (
        <>
            <Modal
                wrapProps={{ "data-test": "select_supplier_modal" }}
                title={`${t("select")} ${isDefaultCompany ? t("supplier") : t("customer")}`}
                open={visible}
                onCancel={() => onClose()}
                footer={[
                    <Button
                        data-test="ok_button"
                        key="ok"
                        type="primary"
                        onClick={() => form.submit()}>
                        {t("go")}
                    </Button>,
                ]}>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="supplier"
                        rules={[{ required: true, message: t("inputIsRequiredError") }]}>
                        <SelectTreeData
                            placeholder={t("select")}
                            selectOptionField={(item: any) => ({
                                text: `${item.name} ${item.number}`,
                                node: (
                                    <span>
                                        {item.name} {item.number}
                                    </span>
                                ),
                            })}
                            useData={usePartnerList}
                            onChange={(value: number) => setSelectedPartnerId(value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {showSupplierProfileModal && (
                <SupplierProfileModal
                    open={showSupplierProfileModal}
                    onClose={() => setShowSupplierProfileModal(false)}
                    supplierId={selectedPartnerId}
                />
            )}
        </>
    );
};

export default SelectSupplierModal;
