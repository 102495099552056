import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any | null;
};

const useDevelopmentPlanById = (id: any, reloadData: any = true) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            if (id !== undefined) {
                setState({ ...state, loading: true });
                const data = await Provider.DevelopmentPlan.getDevelopmentPlanById(id, {
                    customError: {
                        message: "Could not retrieve Development Plan",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [id, reloadData]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDevelopmentPlanById };
