import React from "react";
import summaryClasses from "../../summary.module.scss";
import { ResponsiveRadar } from "@nivo/radar";
import { Col, Divider, Row, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import classes from "../sustainability.module.scss";
import { InfoCircle, PureDiv } from "components/utilitycomponents";
import _ from "lodash";

interface IProps {
    data: any;
    loading: boolean;
}

const SustainabilityRadarChart = ({ data, loading }: IProps) => {
    const { t } = useTranslation();

    return (
        <Spin spinning={loading}>
            <div style={{ height: 500 }} className={summaryClasses.container}>
                <Row>
                    <Col span={24}>
                        <div
                            className={summaryClasses.tableTitle}
                            style={{ marginTop: 4, marginLeft: 8 }}>
                            {t("supplierProfile_SustainabilityHeadlines").toUpperCase()}
                        </div>
                        <Divider style={{ marginTop: 8 }} />
                    </Col>
                </Row>
                <Tooltip
                    title={
                        <PureDiv textContent={t("supplierProfile_SustainabilityRadarScoresInfo")} />
                    }
                    overlayClassName={classes.radarChartInfoOverlay}>
                    <span>
                        <InfoCircle className={classes.radarChartInfoIcon} />
                    </span>
                </Tooltip>
                <ResponsiveRadar
                    margin={{ top: 30, bottom: 90, right: -50 }}
                    data={data}
                    keys={["Average", "Current Year", "Previous Year"]}
                    indexBy="question"
                    gridLevels={10}
                    maxValue={100}
                    colors={["#FF35C7", "#17FAFC", "#FCDC30"]}
                    blendMode="multiply"
                    dotLabel={(v: any) => _.truncate(v.value, { length: 50 })}
                    gridLabel={function noRefCheck(v: any) {
                        return (
                            <text textAnchor={v.anchor} {...v}>
                                {_.truncate(v.id, { length: 25 })}
                            </text>
                        );
                    }}
                    legends={[
                        {
                            anchor: "top-left",
                            direction: "column",
                            translateX: 40,
                            translateY: 0,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: "#999",
                            symbolSize: 12,
                            symbolShape: "circle",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemTextColor: "#000",
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        </Spin>
    );
};

export { SustainabilityRadarChart };
