import React, { useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Popover, Row } from "antd";
import {
    useRelationshipCategoryCRUD,
    useRelationshipCompanyContactCRUD,
    useRelationshipDimensionCRUD,
    useRelationshipSupplierContactCRUD,
} from "controller";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ChevronSelectorVertical, DotsHorizontal } from "components/utilitycomponents";

interface IProps {
    type: "dimension" | "category" | "member";
    item: any;
    onCallback: Function;
    form: any;
    dimensionId?: number;
}

const ActionIcons = ({ type, item, onCallback, form, dimensionId }: IProps) => {
    const { t } = useTranslation();
    const { id: partnerId }: any = useParams();
    const { updateDimension, deleteDimension } = useRelationshipDimensionCRUD();
    const { updateCategory, deleteCategory } = useRelationshipCategoryCRUD();
    const { deleteContact: deleteCompanyContact } = useRelationshipCompanyContactCRUD();
    const { deleteContact: deleteSupplierContact } = useRelationshipSupplierContactCRUD();
    const [togglePopOver, setTogglePopover] = useState<boolean>(false);
    const [toggleParentPopOver, setToggleParentPopover] = useState<boolean>(false);

    const handleDimensionUpdate = async (dimension: any) => {
        await updateDimension(dimension.id, {
            title: dimension.title,
            order: dimension.order,
            partnerId: partnerId,
        });
        onCallback();
    };

    const handleDeleteDimension = async (dimensionId: any) => {
        await deleteDimension(dimensionId);
        onCallback();
    };

    const handleCategoryUpdate = async (category: any, title: string) => {
        await updateCategory({
            id: category.id,
            title: title,
            order: category.order,
            relationshipDimensionId: dimensionId && dimensionId,
        });
        onCallback();
    };

    const handleDeleteCategory = async (categoryId: any) => {
        await deleteCategory(categoryId);
        onCallback();
    };

    const handleDeleteMember = async (member: any) => {
        if (member.type === "company") {
            await deleteCompanyContact(member.relationshipCategoryContactId);
        } else {
            await deleteSupplierContact(member.relationshipCategoryContactId);
        }
        onCallback();
    };

    return (
        <>
            <Col span={1} style={{ textAlign: "center", fontSize: 20 }}>
                <Popover
                    trigger="click"
                    onOpenChange={(visible: boolean) => setToggleParentPopover(visible)}
                    open={toggleParentPopOver}
                    placement="bottomRight"
                    content={
                        <div>
                            <Popover
                                placement="left"
                                trigger="click"
                                open={togglePopOver}
                                onOpenChange={(visible: boolean) => {
                                    form.setFieldsValue({ [`${type}Title`]: item.title });
                                    setTogglePopover(visible);
                                }}
                                content={
                                    <Row
                                        style={{ width: 400 }}
                                        gutter={8}
                                        onClick={(e: any) => e.stopPropagation()}>
                                        <Col span={14}>
                                            <Form.Item
                                                name={`${type}Title`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: t("inputIsRequiredError"),
                                                    },
                                                ]}
                                                initialValue={item.title}>
                                                <Input autoFocus maxLength={256} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Button
                                                onClick={async (e: any) => {
                                                    e.stopPropagation();
                                                    setTogglePopover(false);
                                                }}>
                                                {t("generic_Cancel")}
                                            </Button>
                                        </Col>
                                        <Col span={5}>
                                            <Button
                                                type="primary"
                                                placeholder={t("generic_Placeholder-Text")}
                                                onClick={async () => {
                                                    if (type === "dimension") {
                                                        await handleDimensionUpdate({
                                                            id: item.id,
                                                            title: form.getFieldValue(
                                                                `${type}Title`
                                                            ),
                                                            order: item.order,
                                                        });
                                                    } else if (type === "category") {
                                                        await handleCategoryUpdate(
                                                            item,
                                                            form.getFieldValue(`${type}Title`)
                                                        );
                                                    }
                                                    setTogglePopover(false);
                                                    setToggleParentPopover(false);
                                                }}>
                                                {t("generic_Save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                }>
                                {type !== "member" && (
                                    <Button
                                        block
                                        type="primary"
                                        style={{ padding: 2, marginBottom: 4 }}
                                        onClick={(e: any) => e.stopPropagation()}>
                                        {t("generic_Rename")}
                                    </Button>
                                )}
                            </Popover>
                            <Popconfirm
                                title={t("grid_DeleteWarning", {
                                    name:
                                        type === "dimension"
                                            ? t("relationsMatrix_Dimension").toLocaleLowerCase()
                                            : type === "category"
                                            ? t("generic_Category").toLocaleLowerCase()
                                            : t("generic_Member").toLocaleLowerCase(),
                                })}
                                okText={t("generic_Confirm")}
                                cancelText={t("generic_Cancel")}
                                onCancel={(e: any) => {
                                    e.stopPropagation();
                                }}
                                onConfirm={async (e: any) => {
                                    e.stopPropagation();
                                    if (type === "dimension") {
                                        await handleDeleteDimension(item.id);
                                    } else if (type === "category") {
                                        await handleDeleteCategory(item.id);
                                    } else if (type === "member") {
                                        await handleDeleteMember(item);
                                    }
                                }}>
                                <Button
                                    block
                                    danger
                                    style={{ padding: 2 }}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}>
                                    {t("generic_Delete")}
                                </Button>
                            </Popconfirm>
                        </div>
                    }>
                    <span onClick={(e: any) => e.stopPropagation()}>
                        <DotsHorizontal style={{ color: "#fff" }} />
                    </span>
                </Popover>
            </Col>
            <Col span={1} style={{ textAlign: "center", fontSize: 20 }}>
                <ChevronSelectorVertical
                    style={{ cursor: "grab", color: "#fff" }}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                />
            </Col>
        </>
    );
};

export { ActionIcons };
