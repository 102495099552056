import React, { FunctionComponent, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "antd";
import { NCRModalProps } from "../../../../store/ncrModal/types";
import { AppState } from "../../../../store";
import { Dispatch, bindActionCreators } from "redux";
import { hideModal } from "../../../../store/ncrModal/actions";
import { ResponsiveBar } from "@nivo/bar";
import Bowser from "bowser";
import "./modal-ncr.css";
import { useTranslation } from "react-i18next";
const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

interface NcrModalProps {}

type Props = NcrModalProps & LinkStateProps & LinkDispatchProps;

export const ModalNcr: FunctionComponent<Props> = (props: Props) => {
    function onSubmit() {
        window.print();
    }
    const { t } = useTranslation();
    const [chartContainerHeight, setChartContainerHeight] = useState("500px");
    const [tickValues, setTickValues] = useState<number[]>([0]);

    useEffect(() => {
        if (props.modalProps.visible === true) {
            setTickValues(getTickValues());

            //Due to an issue in Safari & IE we have to forcefully set the height of the chart container to make sure the chart renders correctly every time
            if (browser === "Safari" || browser === "Internet Explorer") {
                setTimeout(() => {
                    setChartContainerHeight("550px");
                }, 500);
            }
        } else if (props.modalProps.visible === false) {
            if (browser === "Safari") {
                setChartContainerHeight("0px");
            }
        }
    }, [props.modalProps.visible]);

    function getTickValues() {
        let tickValues: number[] = [];
        for (let i = 0; i < 1500; i++) {
            tickValues.push(i);
        }
        return tickValues;
    }

    const customBottomTick = (tick: any) => {
        return (
            <g transform={`translate(${tick.x},${tick.tickIndex % 2 ? tick.y + 12 : tick.y + 22})`}>
                <line
                    stroke="rgb(190, 190, 190)"
                    strokeWidth={1.5}
                    y1={tick.tickIndex % 2 ? -12 : -22}
                    y2={-12}
                />
                <text
                    textAnchor="start"
                    dominantBaseline="middle"
                    style={{
                        fill: "#333",
                        fontSize: 10,
                        transform: "rotate(30deg)",
                    }}>
                    <title>{t(tick.value)}</title>
                    {t(tick.value).length > 15
                        ? t(tick.value)
                              .substring(0, 15)
                              .trim() + "..."
                        : t(tick.value)}
                </text>
            </g>
        );
    };

    return (
        <Modal
            width={800}
            title={
                <div className="modalTitle">{`${t("ncrStatisticsTitle")} ${
                    props.modalProps.currentRow ? props.modalProps.currentRow!.company.value : ""
                }`}</div>
            }
            open={props.modalProps.visible}
            onOk={() => onSubmit()}
            onCancel={() =>
                props.hideModal({
                    partnerId: "",
                    visible: false,
                    currentRow: undefined,
                    fromDate: "",
                    toDate: "",
                })
            }
            footer={[
                <Button type="primary" onClick={() => onSubmit()}>
                    {t("print")}
                </Button>,
            ]}>
            {`${props.modalProps.fromDate.split("T")[0]} ~ ${
                props.modalProps.toDate.split("T")[0]
            }`}
            <div style={{ height: chartContainerHeight }}>
                <ResponsiveBar
                    colors={{ scheme: "paired" }}
                    margin={{ bottom: 180, left: 30, top: 10, right: 50 }}
                    layout="vertical"
                    indexBy="type"
                    keys={["value"]}
                    data={
                        props.modalProps.currentRow
                            ? props.modalProps.currentRow.ncrReasonStatistic!.map(x => ({
                                  type: x.reasonText,
                                  value: x.totalCount,
                              }))
                            : []
                    }
                    tooltip={({ value, indexValue, theme }: any) => {
                        return (
                            <div className="tooltip">
                                {t(indexValue)}: {value}
                            </div>
                        );
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 35,
                        renderTick: customBottomTick,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "value",
                        legendPosition: "middle",
                        legendOffset: -40,
                        tickValues:
                            browser === "Safari" || browser === "Internet Explorer"
                                ? tickValues
                                : 10,
                    }}
                />
            </div>
        </Modal>
    );
};

interface LinkStateProps {
    modalProps: NCRModalProps;
}

interface LinkDispatchProps {
    hideModal: (modalProps: NCRModalProps) => void;
}

const mapStateToProps = (state: AppState, ownProps: NcrModalProps): LinkStateProps => ({
    modalProps: state.ncrModal.props,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: NcrModalProps): LinkDispatchProps => ({
    hideModal: bindActionCreators(hideModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNcr);
