import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSegmentationReportEmbedInfo } from "controller";
import classes from "../embeddedReports.module.scss";

const RocheSegmentationReport = () => {
    const { data, loading } = useSegmentationReportEmbedInfo();

    const [embedInfo, setEmbedInfo] = useState<any>(data);
    useEffect(() => {
        setEmbedInfo(data);
    }, [data]);

    return (
        <div style={{ minHeight: 800 }}>
            {!loading &&
            embedInfo !== undefined &&
            embedInfo.EmbedReport &&
            embedInfo.EmbedReport.length > 0 ? (
                <>
                    <PowerBIEmbed
                        embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
                            id: embedInfo.EmbedReport[0].ReportId,
                            embedUrl: embedInfo.EmbedReport[0].EmbedUrl,
                            accessToken: embedInfo.EmbedToken.Token, // Keep as empty string, null or undefined
                            tokenType: models.TokenType.Embed,
                            permissions: models.Permissions.Read,
                            settings: { filterPaneEnabled: false },
                            // filters: filters,
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    "loaded",
                                    function() {
                                        console.log("Report loaded");
                                    },
                                ],
                                [
                                    "rendered",
                                    function() {
                                        console.log("Report rendered");
                                    },
                                ],
                                [
                                    "error",
                                    function(event: any) {
                                        console.log(event.detail);
                                    },
                                ],
                                ["visualClicked", () => console.log("visual clicked")],
                                ["pageChanged", event => console.log(event)],
                            ])
                        }
                        cssClassName={classes.pbiExample}
                    />
                    <div style={{ fontSize: 8 }}>
                        Report Id: {embedInfo.EmbedReport[0].ReportId}
                    </div>
                </>
            ) : (
                <div style={{ marginLeft: 800, paddingTop: 400, fontSize: 20 }}>Loading...</div>
            )}
        </div>
    );
};

export { RocheSegmentationReport };
