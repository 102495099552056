const getExtraFieldValue = (
    values: any = {},
    surveyFieldKeys: number[] = [],
    supplierFieldKeys: number[] = []
) => {
    let customDropdownHeadlines: any[] = [];
    let textFieldHeadlines: any[] = [];
    let position = 0;
    for (let i = 0; i < surveyFieldKeys.length; i++) {
        const key = surveyFieldKeys[i];
        if (
            values.textFieldTitleSurveyFields[key] &&
            values.textFieldTitleSurveyFields[key].trim() !== ""
        ) {
            if (values.dropdownSurveyFields && values.dropdownSurveyFields[key]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSurveyFields[key],
                    title: values.textFieldTitleSurveyFields[key],
                    headlineType: "Survey",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSurveyFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSurveyFields[key] !== undefined
                            ? values.textFieldDescriptionSurveyFields[key]
                            : "",
                    title: values.textFieldTitleSurveyFields[key],
                    headlineType: "Survey",
                    position,
                });
                position++;
            }
        }
    }
    position = 0;
    for (let i = 0; i < supplierFieldKeys.length; i++) {
        const key = supplierFieldKeys[i];
        if (
            values.textFieldTitleSupplierFields[key] &&
            values.textFieldTitleSupplierFields[key].trim() !== ""
        ) {
            if (values.dropdownSupplierFields && values.dropdownSupplierFields[key]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSupplierFields[key],
                    title: values.textFieldTitleSupplierFields[key],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSupplierFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSupplierFields[key] !== undefined
                            ? values.textFieldDescriptionSupplierFields[key]
                            : "",
                    title: values.textFieldTitleSupplierFields[key],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            }
        }
    }
    return { customDropdownHeadlines, textFieldHeadlines };
};
export default getExtraFieldValue;
