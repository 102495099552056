import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import i18n from "i18n";
import "./styles/filterDropDown.scss";
import getFilterIcon from "./getFilterIcon";

let searchInput: any;
const getColumnSearchProps = (
    params: any = { dataIndex: Math.floor(Math.random() * 100), placeholder: "" }
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div className="filterDropDownContainer">
            <Input
                data-test={`search_${params.dataIndex}_input`}
                ref={node => {
                    searchInput = node;
                }}
                {...(params.placeholder && { placeholder: `Search ${params.placeholder}` })}
                value={selectedKeys}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
                data-test={`search_${params.dataIndex}_button`}
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}>
                {i18n.t("grid_Filter")}
            </Button>

            <Button
                onClick={() => {
                    clearFilters();
                    confirm();
                }}
                size="small"
                style={{ width: 90 }}>
                {i18n.t("grid_Reset")}
            </Button>
        </div>
    ),
    onFilterDropdownVisibleChange: (visible: any) => {
        if (visible) {
            setTimeout(() => searchInput.select());
        }
    },
    ...getFilterIcon(params),
});

export default getColumnSearchProps;
