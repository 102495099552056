import { useState } from "react";
import { Provider } from "services";

const useReviewCriteriaNameInUse = () => {
    const [loading, setLoading] = useState(false);

    const checkForExistingName = async (name: string) => {
        if (name !== "") {
            setLoading(true);
            const inUse = await Provider.Review.isReviewCriteriaNameInUse(name, {
                customError: {
                    message: "Could not validate review criteria name",
                },
            }).catch((err: any) => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
            return inUse;
        }
    };

    return {
        loading,
        checkForExistingName,
    };
};

export { useReviewCriteriaNameInUse };
