import React, { Fragment, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { notification, Progress, Row, Col, Popconfirm } from "antd";
import {
    UploadButton,
    DownloadImage,
    useAttach,
    Plus,
    Trash02,
} from "components/utilitycomponents";
import { CropImageModal } from "./cropImageModal";
import { resizeImage } from "./imageUtility";
import classes from "./styles/attachImage.module.scss";

type InputFileType = {
    name: string;
    id: number;
};

type PropsType = {
    onChange?: Function;
    externalLoading?: boolean;
    attachedFileList?: InputFileType[];
    maxFileSize?: number;
    multiple?: boolean;
    enableCrop?: boolean;
    cropHeight?: number;
    enableResize?: boolean;
    resizeHeight?: number;
    styles?: any;
    disabled?: boolean;
    [key: string]: any;
};

const AttachImage = forwardRef(
    (
        {
            onChange,
            externalLoading = false,
            attachedFileList = undefined,
            maxFileSize = 40,
            multiple = true,
            enableCrop = false,
            cropHeight = 200,
            enableResize = false,
            resizeHeight = 200,
            styles = null,
            disabled = false,
            rest,
        }: PropsType,
        ref
    ) => {
        const { t } = useTranslation();
        const { handleDeleteFile, handleUpload, uploadInProgress, allFiles, newFiles } = useAttach({
            attachedFileList,
            onChange,
            notification,
            maxFileSize,
        });
        const [cropImageModal, setCropImageModal] = useState<any>({
            visible: false,
            image: {
                imageFile: null,
                imageName: null,
                dimensions: null,
                imageType: null,
            },
        });

        const handleSelectFile = (files: any) => {
            if (enableCrop) {
                resizeImage({
                    file: files[0],
                    height: cropHeight,
                    output: "dataUrl",
                    callback: function(file: any, width: any, height: any) {
                        setCropImageModal({
                            visible: true,
                            image: {
                                imageFile: file,
                                imageName: files[0].name,
                                dimensions: { width, height },
                                imageType: files[0].type,
                            },
                        });
                    },
                });
            } else if (enableResize) {
                resizeImage({
                    file: files[0],
                    height: resizeHeight,
                    output: "blob",
                    callback: function(file: any) {
                        handleUpload([file]);
                    },
                });
            } else {
                handleUpload(files);
            }
        };

        return (
            <Fragment>
                {allFiles.length === 0 && (
                    <UploadButton
                        className={classes.uploadImageButton}
                        multiple={multiple}
                        onSelect={handleSelectFile}
                        icon={<Plus />}
                        accept="image/x-png,image/gif,image/jpeg"
                        loading={uploadInProgress || externalLoading}
                        disabled={disabled}>
                        {t("upload", "Upload")}
                    </UploadButton>
                )}
                {newFiles.map((file: any, index: number) => (
                    <Fragment key={index}>
                        <Row style={{ lineHeight: "20px", marginTop: "4px" }}>{file.name}</Row>
                        <Row style={{ lineHeight: "20px" }}>
                            <Col span={21}>
                                <Progress
                                    percent={file.progress}
                                    size="small"
                                    status={file.progress < 100 ? "active" : "normal"}
                                />
                            </Col>
                            <Col span={2} style={{ textAlign: "center" }}>
                                {file.progress < 100 && (
                                    <Trash02 onClick={() => file.source.cancel()} />
                                )}
                            </Col>
                        </Row>
                    </Fragment>
                ))}

                {allFiles.map((file: any, index: number) => (
                    <div
                        className={
                            styles && styles.attachedImageContainer
                                ? styles.attachedImageContainer
                                : classes.attachedImageContainer
                        }
                        id="attachedImageContainer"
                        key={index}>
                        <div
                            className={
                                styles && styles.attachedImage
                                    ? styles.attachedImage
                                    : classes.attachedImage
                            }>
                            <DownloadImage
                                key={index}
                                alt=""
                                fileId={file.id}
                                style={{ maxWidth: "250px", width: "100%" }}
                            />
                        </div>
                        <div
                            className={
                                styles && styles.removeAttachedImage
                                    ? styles.removeAttachedImage
                                    : classes.removeAttachedImage
                            }>
                            {!disabled && (
                                <Popconfirm
                                    getPopupContainer={() =>
                                        document.getElementById("contentSection") || document.body
                                    }
                                    title={t("file_RemoveImageConfirm")}
                                    onConfirm={() => handleDeleteFile(file.id)}
                                    okText={t("generic_Yes")}
                                    cancelText={t("generic_No")}>
                                    <span>
                                        <Trash02
                                            onClick={() => {}}
                                            data-test="remove_image_button"
                                            title={t("file_RemoveImage")}
                                            style={{ fontSize: "18px", color: "#FF4D4F" }}
                                        />
                                    </span>
                                </Popconfirm>
                            )}
                        </div>
                    </div>
                ))}

                <CropImageModal
                    onClose={() => {
                        setCropImageModal({
                            ...cropImageModal,
                            visible: false,
                        });
                    }}
                    onConfirm={(file: any) => {
                        handleUpload([file]);
                        setCropImageModal({
                            ...cropImageModal,
                            visible: false,
                        });
                    }}
                    image={cropImageModal.image}
                    visible={cropImageModal.visible}
                />
            </Fragment>
        );
    }
);

export { AttachImage };
