import { get } from "../base";

const Sustainability = {
    getSustainabilityScores: async (
        partnerId: any,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/GetSustainabilityInformation?partnerId=${partnerId}`;

        let response = await get({
            url,
            retryCount: 3,
            customError: customError,
        });
        return response;
    },
};

export default Sustainability;
