import { useState } from "react";
import { Provider } from "services";

interface requiredIds {
    presetId: any;
    supplierId: any;
    companyId: any;
}

const useDashboardGuestToken = () => {
    const [loading, setLoading] = useState(false);

    const getGuestToken = async (ids?: requiredIds) => {
        setLoading(true);

        if (ids !== undefined) {
            setLoading(true);

            const data = await Provider.Analytics.getAnalyticsGuestToken(
                ids.presetId,
                ids.supplierId,
                ids.companyId,
                {
                    customError: { message: "Could not retrieve share preset data" },
                }
            ).catch((err: any) => {
                setLoading(false);
                return Promise.reject(err);
            });
            setLoading(false);
            return data;
        }
    };

    return {
        loading,
        getGuestToken,
    };
};

export { useDashboardGuestToken };
