import React, { forwardRef } from "react";
import { useComplianceNotifyUsers } from "controller";
import { SelectDataMultiple } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";

type PropsType = {
    onChange?: (value: number | number[]) => void;
    value?: number[];
    visibleTo: number[];
};

const SelectNotifyUsers = forwardRef(({ onChange, value, visibleTo }: PropsType, ref) => {
    const { t } = useTranslation();
    return (
        <SelectDataMultiple
            data-test="notify_users_select"
            ref={ref as any}
            onChange={onChange}
            value={value}
            useData={useComplianceNotifyUsers.bind(null, visibleTo)}
            selectIdField="id"
            selectOptionField="fullName"
            placeholder={t("compliance_SelectUser")}
            maxTagCount={3}
            allowClear
            selectAll
            getPopupContainer={(node: any) =>
                node ? (node.parentNode as HTMLElement) : document.body
            }
        />
    );
});

export { SelectNotifyUsers };
