import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Checkbox, DatePicker, Tooltip, notification, Form, Tag } from "antd";
import { IComplianceTemplate, ComplianceVisibility, IComplianceCreateInput } from "models";
import { usePrevious, useComplianceCreate, useCompanyType, useProductFeature } from "controller";
import {
    AttachFile,
    SelectExternalPartner,
    DownloadFile,
    PureDiv,
    SelectDataMultiple,
    HelpCircle,
} from "components/utilitycomponents";
import { newlineToBr } from "utilities";
import {
    SelectNotifyUsers,
    SelectVisibleTo,
    SelectVisibility,
    Entity,
    FormRow,
    ColLeft,
    ColRight,
} from "../../sharedComponents";
import TemplateHeadLines from "./headLines";
import classes from "./styles/createForm.module.scss";
import dayjs from "dayjs";
import useProductsByPartner from "controller/product/useProductsByPartner";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface IProps {
    data: IComplianceTemplate;
    parentComponentType?: "default" | "modal";
    lightUserSupplierCreation?: boolean;
    enableSupplierCreation?: boolean;
    defaultSelectedPartner?: number;
    defaultSelectedProducts?: number[];
    onSubmitFinished?: Function;
}

const CreateForm = ({
    data,
    parentComponentType = "default",
    lightUserSupplierCreation = false,
    enableSupplierCreation = true,
    defaultSelectedPartner,
    defaultSelectedProducts,
    onSubmitFinished,
}: IProps) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { loading, create } = useComplianceCreate();
    const [isDraft, setIsDraft] = useState(false);
    const { isDefaultCompany } = useCompanyType();
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
    const [fileUploadInProgress, setFileUploadInprogress] = useState(false);
    const [fileQuestionsLoading, setFileQuestionsLoading] = useState<any[]>([]);
    const [visibleTo, setVisibleTo] = useState<number[]>([]);
    const [partnerId, setPartnerId] = useState<number | undefined>(undefined);
    const { data: products, loading: loadingProducts } = useProductsByPartner(partnerId);
    const { hasProductFeature } = useProductFeature();
    const [disableNotifySupplier, setDisableNotifySupplier] = useState<boolean>(false);
    const user = useSelector((state: AppState) => state.oidc.user);
    const contactId = user.profile["ll-ContactId"];
    const notifyAlertDateUsers = Form.useWatch("alertDateNotifyUserIds", form);
    const notifyEndDateUsers = Form.useWatch("endDateNotifyUserIds", form);

    useEffect(() => {
        form.resetFields();
    }, [data]);

    useEffect(() => {
        if (defaultSelectedPartner) {
            setPartnerId(defaultSelectedPartner);
        }
    }, [defaultSelectedPartner]);

    useEffect(() => {
        if (showValidationMessage === true) {
            setTimeout(() => {
                setShowValidationMessage(false);
            }, 7500);
        }
    }, [showValidationMessage]);

    const handleSubmit = async ({ isDraft }: any) => {
        setIsDraft(isDraft);
        let values = await form.validateFields();

        const valueKeys: any[] = Object.keys(values);
        const questionKeys = valueKeys
            .filter((key: string) => key.startsWith("question["))
            .map((key: string) => key.replace("question[", ""))
            .map((key: string) => key.replace("]", ""));

        const createForm: IComplianceCreateInput = {
            complianceTemplateId: data.id,
            partnerId: form.getFieldValue("externalPartner"),
            entity: form.getFieldValue("entity") ? form.getFieldValue("entity") : "",
            visibility: form.getFieldValue("visibility"),
            isDraft,
            notifyUserAlertDate:
                notifyAlertDateUsers && notifyAlertDateUsers.length > 0 ? true : false,
            notifyUserEndDate: notifyEndDateUsers && notifyEndDateUsers.length > 0 ? true : false,
            notifyPartnerAlertDate: form.getFieldValue("alertDateNotifyExternalPartner")
                ? true
                : false,
            notifyPartnerEndDate: form.getFieldValue("endDateNotifyExternalPartner") ? true : false,
            endDate: form.getFieldValue("endDate")
                ? dayjs(form.getFieldValue("endDate")).toISOString()
                : null,
            alertDate: form.getFieldValue("alertDate")
                ? dayjs(form.getFieldValue("alertDate")).toISOString()
                : null,
            productIds: hasProductFeature ? form.getFieldValue("products") : [],
            complianceAnswers:
                questionKeys.length > 0
                    ? questionKeys.map((questionId: any) => {
                          let value = "";
                          let multiChoiceValue;
                          let fileIds;
                          let fieldValue: any = form.getFieldValue(`question[${questionId}]`);

                          if (fieldValue) {
                              let isArray = Array.isArray(fieldValue);
                              if (isArray) {
                                  if (
                                      fieldValue.filter((x: any) => typeof x === "number").length >
                                      0
                                  ) {
                                      fileIds = fieldValue;
                                      value = fieldValue.length > 0 ? "true" : "";
                                  } else {
                                      multiChoiceValue = fieldValue.join("|");
                                  }
                              } else {
                                  value = dayjs.isDayjs(fieldValue)
                                      ? dayjs(fieldValue).toISOString()
                                      : fieldValue.toString();
                              }
                          }

                          return {
                              id: 0,
                              value,
                              MultipleChoiceQuestionValue: multiChoiceValue,
                              fileIds: fileIds,
                              comment: form.getFieldValue(`comment[${questionId}]`)
                                  ? form.getFieldValue(`comment[${questionId}]`)
                                  : null,
                              complianceQuestionId: parseInt(questionId),
                              complianceId: 0,
                          };
                      })
                    : [],
            fileIds: form.getFieldValue("attachedFiles") ? form.getFieldValue("attachedFiles") : [],
            complianceAlertDateNotifyUsers: notifyAlertDateUsers
                ? notifyAlertDateUsers.map((id: number) => ({
                      contactId: id,
                  }))
                : [],
            complianceEndDateNotifyUsers: notifyEndDateUsers
                ? notifyEndDateUsers.map((id: number) => ({
                      contactId: id,
                  }))
                : [],
            complianceAllowedUsers: form.getFieldValue("visibleTo")
                ? form.getFieldValue("visibleTo").map((id: number) => ({
                      contactId: id,
                  }))
                : [],
        };

        await create(createForm);
        if (parentComponentType === "modal") {
            onSubmitFinished && onSubmitFinished(true);
        } else {
            if (location.pathname === "/SupplierProfileCompliance/Create") {
                history.goBack();
            } else {
                history.push("/Compliance");
            }
        }

        notification.success({
            message: t("success"),
            description: t("generic_RegistrationCreationSuccess"),
        });
    };

    const preVisibility = usePrevious(data.visibility);
    if (preVisibility && preVisibility !== data.visibility) {
        form.setFieldsValue({ visibility: (ComplianceVisibility as any)[data.visibility] });
    }
    const showNotifyExternalPartner =
        (ComplianceVisibility as any)[data.visibility] === ComplianceVisibility.External ||
        form.getFieldValue("visibility") === ComplianceVisibility.External;

    return (
        <Fragment>
            <Form
                form={form}
                labelWrap
                labelAlign="left"
                labelCol={{ lg: 5, md: 12, sm: 12, xs: 24 }}
                wrapperCol={{ lg: 8, md: 12, sm: 12, xs: 24 }}>
                <FormRow>
                    <ColLeft>
                        <label>{t("compliance_Introduction")}</label>
                    </ColLeft>
                    <ColRight>
                        <label>
                            <PureDiv
                                data-test="compliance_introduction"
                                textContent={newlineToBr(
                                    data.introduction ? data.introduction : ""
                                )}
                            />
                        </label>
                        {data.files.map((file, index) => (
                            <div data-test={`template_detail_file_${index}`} key={index}>
                                <DownloadFile file={file} />
                            </div>
                        ))}
                    </ColRight>
                </FormRow>

                <Form.Item
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }
                    name="externalPartner"
                    rules={[{ required: true, message: t("compliance_SupplierRequired") }]}
                    initialValue={defaultSelectedPartner}>
                    <SelectExternalPartner
                        data-test="external_partner_select"
                        placeholder={`${t("select")} ${
                            isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer")
                        }`}
                        onChange={(val: number | undefined, item: any) => {
                            setPartnerId(val);
                            if (item && item.hasComplianceContacts === false) {
                                setDisableNotifySupplier(true);
                            } else {
                                setDisableNotifySupplier(false);
                            }
                        }}
                        showCreationButton={enableSupplierCreation}
                        lightUserSupplierCreationPermissions={lightUserSupplierCreation}
                        smallSupplierSelection={lightUserSupplierCreation}
                        creationButtonType={
                            lightUserSupplierCreation === true ? "large" : "default"
                        }
                        includeChildren={true}
                    />
                </Form.Item>

                <Form.Item
                    label={t("compliance_Visibility")}
                    data-test="compliance_visibility_select"
                    name="visibility"
                    initialValue={(ComplianceVisibility as any)[data.visibility]}
                    rules={[
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (
                                    value === ComplianceVisibility.Internal ||
                                    value === ComplianceVisibility.External
                                ) {
                                    return Promise.resolve();
                                }
                                Promise.reject(new Error(t("compliance_VisibilityValidation")));
                            },
                        },
                    ]}>
                    <SelectVisibility visibility={data.visibility} />
                </Form.Item>

                <Form.Item
                    label={
                        <>
                            {t("compliance_VisibleTo")}
                            <Tooltip title={t("compliance_VisibilityChangeTooltip")}>
                                <span style={{ marginLeft: 4 }}>
                                    <HelpCircle />
                                </span>
                            </Tooltip>
                        </>
                    }
                    className={classes.formItem1}
                    name="visibleTo"
                    initialValue={[]}>
                    <SelectVisibleTo
                        placeHolder={
                            <Tag style={{ fontSize: 14 }} bordered={false}>
                                {t("compliance_VisibleToAll")}
                            </Tag>
                        }
                        onChange={(value: any) => setVisibleTo(value)}
                        onVisibleToChanged={() => {
                            form.setFieldsValue({
                                endDateNotifyUserIds: [],
                                alertDateNotifyUserIds: [],
                            });
                        }}
                    />
                </Form.Item>

                {data.includeEndDate && (
                    <>
                        <Form.Item label={t("compliance_EndDate")} name="endDate">
                            <DatePicker
                                format={"MMM D, YYYY"}
                                data-test="end_date_picker"
                                placeholder={t("generic_SelectDate")}
                                getPopupContainer={node =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>
                        {showNotifyExternalPartner && (
                            <Form.Item
                                colon={false}
                                label={<div></div>}
                                name="endDateNotifyExternalPartner"
                                valuePropName="checked">
                                <Checkbox
                                    disabled={disableNotifySupplier}
                                    data-test="end_date_notify_external_partner_checkbox">
                                    {t("compliance_NotifyCompany", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    })}{" "}
                                    {disableNotifySupplier &&
                                        " - " + t("compliance_NoSupplierContacts")}
                                </Checkbox>
                            </Form.Item>
                        )}
                        <Form.Item
                            label={t("compliance_NotifyUser")}
                            name="endDateNotifyUserIds"
                            initialValue={[parseInt(contactId)]}>
                            <SelectNotifyUsers visibleTo={visibleTo} />
                        </Form.Item>
                    </>
                )}
                {data.includeAlertDate && (
                    <>
                        <Form.Item label={t("compliance_AlertDate")} name="alertDate">
                            <DatePicker
                                format={"MMM D, YYYY"}
                                data-test="alert_date_picker"
                                placeholder={t("generic_SelectDate")}
                                getPopupContainer={node =>
                                    node ? (node.parentNode as HTMLElement) : document.body
                                }
                            />
                        </Form.Item>
                        {showNotifyExternalPartner && (
                            <Form.Item
                                colon={false}
                                label={<div></div>}
                                name="alertDateNotifyExternalPartner"
                                valuePropName="checked">
                                <Checkbox
                                    disabled={disableNotifySupplier}
                                    data-test="alert_date_notify_external_partner_checkbox">
                                    {t("compliance_NotifyCompany", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    })}{" "}
                                    {disableNotifySupplier &&
                                        " - " + t("compliance_NoSupplierContacts")}
                                </Checkbox>
                            </Form.Item>
                        )}
                        <Form.Item
                            label={t("compliance_NotifyUser")}
                            name="alertDateNotifyUserIds"
                            initialValue={[parseInt(contactId)]}>
                            <SelectNotifyUsers visibleTo={visibleTo} />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    label={data.entity}
                    name="entity"
                    required={data.isEntityRequired === true}
                    rules={[
                        {
                            required: data.isEntityRequired === true,
                            whitespace: data.isEntityRequired === true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}>
                    <Entity entityType={data.entityType} options={data.customDropdownOptions} />
                </Form.Item>

                {hasProductFeature && data.isProductTemplate && (
                    <Form.Item
                        label={t("Products")}
                        name="products"
                        initialValue={defaultSelectedProducts}>
                        <SelectDataMultiple
                            disabled={partnerId === undefined}
                            selectOptionField="name"
                            selectIdField="id"
                            useData={() => ({
                                data: products,
                                loading: loadingProducts,
                            })}
                        />
                    </Form.Item>
                )}

                <Form.Item label={t("compliance_Attachments")} name="attachedFiles">
                    <AttachFile
                        maxFileSize={40}
                        onUploadingFile={(uplading: boolean) => setFileUploadInprogress(uplading)}
                    />
                </Form.Item>

                <TemplateHeadLines
                    form={form}
                    templateHeadLines={data.headLines}
                    onAttachDocumentLoading={(fileQuestionsLoading: any[]) =>
                        setFileQuestionsLoading(fileQuestionsLoading)
                    }
                />
                <FormRow gutter={16}>
                    <Col lg={{ span: 7, offset: 17 }}>
                        <Button
                            data-test="save_as_draft_button"
                            onClick={() => handleSubmit({ isDraft: true })}
                            disabled={
                                fileUploadInProgress ||
                                fileQuestionsLoading.filter(
                                    (x: any) => Object.values(x)[0] === true
                                ).length > 0
                            }
                            loading={isDraft && loading}>
                            {t("compliance_SaveAsDraft")}
                        </Button>
                    </Col>
                </FormRow>
                <FormRow gutter={16}>
                    <Col lg={{ span: 6, push: 10 }}>
                        {showValidationMessage && (
                            <div className={classes.validationErrorText}>
                                {t("compliance_ValidationErrorMsg")}
                            </div>
                        )}
                    </Col>
                    <Col lg={{ span: 7, offset: 11 }}>
                        <Button
                            data-test="submit_your_answers_button"
                            type="primary"
                            block
                            disabled={
                                fileUploadInProgress ||
                                fileQuestionsLoading.filter(
                                    (x: any) => Object.values(x)[0] === true
                                ).length > 0
                            }
                            onClick={() => handleSubmit({ isDraft: false })}
                            loading={!isDraft && loading}>
                            {t("compliance_SubmitAnswer")}
                        </Button>
                    </Col>
                </FormRow>
            </Form>
        </Fragment>
    );
};

export default CreateForm;
