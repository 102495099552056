import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "services";

const useUpdateMergeSet = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const updateMergeSet = async (parentId: string, childrenIds: number[]) => {
        setLoading(true);
        const id = await Provider.Partner.updateMergeSet(parentId, childrenIds, {
            customError: {
                message: t("supplier_AddToMergeError"),
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        updateMergeSet,
    };
};

export { useUpdateMergeSet };
