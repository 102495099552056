import IPartnerTableData from "../../models/suppliers/IPartnerTableData";

export interface NCRModalProps {
    visible: boolean;
    partnerId: string;
    currentRow: IPartnerTableData | undefined;
    fromDate: string;
    toDate: string;
}

export interface NCRModalState {
    props: NCRModalProps;
}

export const SHOW_NCRMODAL = "SHOW_NCRMODAL";
export const HIDE_NCRMODAL = "HIDE_NCRMODAL";

export interface ShowModal {
    type: typeof SHOW_NCRMODAL;
    payload: NCRModalProps;
}

export interface HideModal {
    type: typeof HIDE_NCRMODAL;
    payload: NCRModalProps;
}

export type NCRModalActionTypes = ShowModal | HideModal;
