import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveyCriteriaResponse = (
    surveyId: number,
    segmentationAnalysisId: number,
    criteriaId: number | undefined,
    pageSize: number,
    pageNumber: number,
    filters: any,
    sorts: any,
    externalLoading: boolean,
    reloadData: any,
    range?: any
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (!externalLoading && criteriaId) {
                let data: any;
                if (surveyId !== -1) {
                    data = await Provider.Survey.getSurveyCriteriaResponse(
                        surveyId,
                        criteriaId,
                        {
                            pageNumber,
                            pageSize,
                            filters,
                            sorts,
                            from: range.from,
                            to: range.to,
                        },
                        {
                            customError: { message: "Could not retrieve survey details" },
                        }
                    ).catch(err => {
                        setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                        return Promise.reject(err);
                    });
                }
                if (segmentationAnalysisId !== -1) {
                    data = await Provider.Survey.getSegmentationCriteriaResponse(
                        segmentationAnalysisId,
                        criteriaId,
                        {
                            pageNumber,
                            pageSize,
                            filters,
                            sorts,
                            from: range.from,
                            to: range.to,
                        },
                        {
                            customError: { message: "Could not retrieve question details" },
                        }
                    ).catch(err => {
                        setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                        return Promise.reject(err);
                    });
                }

                setState({
                    loading: false,
                    data: data.data,
                    totalRows: data.totalNumberOfRecords,
                });
            }
        })();
    }, [
        pageSize,
        pageNumber,
        filters,
        sorts,
        externalLoading,
        reloadData,
        range,
        surveyId,
        criteriaId,
    ]);

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
    };
};

export { useSurveyCriteriaResponse };
