import React, { useState } from "react";
import { PastMeetings } from "./pastMeetings";
import { UpcomingMeetings } from "./upcomingMeetings";

interface IProps {
    partnerId: number;
}

const MeetingSummary = ({ partnerId }: IProps) => {
    const [reload, setReload] = useState<any>({ reload: false });
    const [showUpcomingMeetings, setShowUpcomingMeetings] = useState<boolean>(true);
    return (
        <>
            {showUpcomingMeetings ? (
                <UpcomingMeetings
                    partnerId={partnerId}
                    onMeetingCreated={() => setReload({ reload: true })}
                    reloadTable={reload}
                    onSwitchClick={() => setShowUpcomingMeetings(!showUpcomingMeetings)}
                />
            ) : (
                <PastMeetings
                    partnerId={partnerId}
                    onMeetingCreated={() => setReload({ reload: true })}
                    reloadTable={reload}
                    onSwitchClick={() => setShowUpcomingMeetings(!showUpcomingMeetings)}
                />
            )}
        </>
    );
};
export { MeetingSummary };
