import { useState } from "react";
import { Provider } from "services";

const useNcrFieldsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (items: any) => {
        setLoading(true);

        const res = await Provider.Ncr.updateNcrFields(items, {
            customError: { message: "Could not update ncr fields" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        update,
    };
};

export { useNcrFieldsUpdate };
