import React, { useEffect, useState } from "react";
import { Modal, Input, notification, Form, Button, Select, InputNumber } from "antd";
import { SelectDataMultiple, SelectData } from "components/utilitycomponents";
import {
    useContacts,
    usePartnerCategories,
    usePartnerGroups,
    usePartnerCreate,
    useIsCompanyNumberInUse,
    useCompanyAutoGenerateCompanyNumbers,
    useCompanyType,
    useMandatoryPartnerFields,
    usePartnerStatuses,
} from "controller";
import { categoryType } from "models/suppliers/ICategory";
import { useTranslation } from "react-i18next";
import countryCodes from "../../../../utilities/misc/countryCodes.json";
import currencyCodes from "../../../../utilities/misc/currencies.json";
const { Option } = Select;

interface IProps {
    visible: boolean;
    onCancel: () => void;
    onCreateExternalPartner: Function;
    footer: "default" | "big";
    reload?: Function;
}

const CreateExternalPartnerModal = ({
    visible,
    onCancel,
    onCreateExternalPartner,
    footer = "default",
    reload,
}: IProps) => {
    const [form] = Form.useForm();
    const { checkForExistingNumber } = useIsCompanyNumberInUse();
    const { create: createPartner } = usePartnerCreate();
    const { getAutoGenerateCompanyNumberSetting } = useCompanyAutoGenerateCompanyNumbers();
    const [autoGenerateCompanyNumbers, setAutoGenerateCompanyNumbers] = useState(false);
    const { isDefaultCompany } = useCompanyType();
    const { data: partnerFields, loading: loadingPartnerFields } = useMandatoryPartnerFields();
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined);
    const [disableButtons, toggleDisableButtons] = useState<boolean>(false);

    useEffect(() => {
        async function getAutoGenerateCompanyNumbersSetting() {
            setAutoGenerateCompanyNumbers(await getAutoGenerateCompanyNumberSetting());
        }

        if (visible === true) {
            getAutoGenerateCompanyNumbersSetting();
        }
        form.resetFields();
    }, [visible]);

    function onSelectCurrencyChange(currencyCode: string) {
        if (currencyCode === undefined) {
            form.setFieldsValue({ yearlyVal: undefined });
        }
        setSelectedCurrency(currencyCode);
    }

    function renderCountryOptions() {
        let countryArray = countryCodes.sort((e1, e2) => {
            if (e1.TwoLetterIsoCode > e2.TwoLetterIsoCode) {
                return 1;
            }
            if (e1.TwoLetterIsoCode < e2.TwoLetterIsoCode) {
                return -1;
            }

            return 0;
        });
        return countryArray.map((element: { TwoLetterIsoCode: string; EnglishName: string }) => {
            return (
                <Option key={element.TwoLetterIsoCode} value={element.TwoLetterIsoCode}>
                    {element.TwoLetterIsoCode}
                </Option>
            );
        });
    }

    function renderCurrencyOptions() {
        let currencyArray = currencyCodes.sort((e1, e2) => {
            if (e1.CurrencyCode > e2.CurrencyCode) {
                return 1;
            }
            if (e1.CurrencyCode < e2.CurrencyCode) {
                return -1;
            }

            return 0;
        });

        return currencyArray.map((element: { CurrencyCode: string; EnglishName: string }) => {
            return (
                <Option key={element.CurrencyCode} value={element.CurrencyCode}>
                    {element.CurrencyCode}
                </Option>
            );
        });
    }

    const submit = async (addMore: boolean) => {
        let fieldsValue = await form.validateFields();
        toggleDisableButtons(true);
        const {
            companyName,
            companyNumber,
            pocFirstName,
            pocLastName,
            pocPhone,
            pocCountry,
            yearlyVal,
            status,
            email,
            group,
            categoryFirst,
            categorySecond,
            owner,
            domain,
        } = fieldsValue;
        const id = await createPartner({
            companyName: companyName.trim(),
            companyNumber: !autoGenerateCompanyNumbers ? companyNumber.trim() : undefined,
            pocFirstName: pocFirstName?.trim(),
            pocLastName: pocLastName?.trim(),
            pocPhoneNumber: pocPhone?.trim(),
            pocCountry: pocCountry,
            currency: selectedCurrency,
            value: yearlyVal,
            statusId: status,
            email: email,
            groupId: group,
            categoryOne: categoryFirst,
            categoryTwo: categorySecond,
            ownersId: owner,
            domain: domain,
        });
        onCreateExternalPartner(id, {
            id: id,
            name: companyName.trim(),
            number: !autoGenerateCompanyNumbers ? companyNumber.trim() : undefined,
        });
        form.resetFields();
        toggleDisableButtons(false);
        notification.success({
            message: t("success"),
            description: t("generic_CreateCompanySuccess", {
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier")
                    : t("company_Type_Customer"),
            }),
        });
        reload && reload();
        if (!addMore) {
            onCancel();
        }
    };
    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 16 },
        },
    };
    if (!visible) {
        return null;
    }

    const defaultFooter = [
        <Button type="primary" onClick={() => submit(false)} loading={disableButtons}>
            {t("generic_Create")}
        </Button>,
    ];
    const bigFooter = [
        <Button type="primary" onClick={() => submit(true)} loading={disableButtons}>
            {t("generic_CreateMore")}
        </Button>,
        <Button type="primary" onClick={() => submit(false)} loading={disableButtons}>
            {t("generic_Create")}
        </Button>,
    ];

    return (
        <Modal
            wrapProps={{ "data-test": "create_external_partner_modal" }}
            title={t("generic_CreateCompanyTitle", {
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier")
                    : t("company_Type_Customer"),
            })}
            style={{ top: 20, backdropFilter: "blur(5px)", backgroundColor: "transparent" }}
            open={visible}
            onOk={() => submit(false)}
            cancelText={t("generic_Cancel")}
            onCancel={onCancel}
            footer={footer === "default" ? defaultFooter : bigFooter}
            confirmLoading={disableButtons}
            width={800}>
            {!loadingPartnerFields && partnerFields !== undefined && (
                <Form form={form} labelWrap>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.companyName?.customLabel ?? t("companyName")}
                        labelAlign="left"
                        name="companyName"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input
                            maxLength={200}
                            placeholder={partnerFields.companyName?.customLabel ?? t("companyName")}
                        />
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.companyNumber?.customLabel ?? t("companyNumber")}
                        labelAlign="left"
                        name="companyNumber"
                        validateTrigger="onChange"
                        rules={[
                            {
                                required: autoGenerateCompanyNumbers ? false : true,
                                validator: async (rule: any, value: any, callback: any) => {
                                    if (autoGenerateCompanyNumbers) {
                                        return Promise.resolve();
                                    }
                                    if (!value || value.trim() === "") {
                                        return Promise.reject(new Error(t("inputIsRequiredError")));
                                    }
                                    let numberInUse = await checkForExistingNumber(value.trim());
                                    if (numberInUse === true) {
                                        return Promise.reject(new Error(t("companyNumberInUse")));
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}>
                        <Input
                            maxLength={20}
                            disabled={autoGenerateCompanyNumbers}
                            placeholder={
                                partnerFields.companyNumber?.customLabel ?? t("companyNumber")
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        labelAlign="left"
                        name="domain"
                        label={partnerFields.domain?.customLabel ?? t("partner_Website")}
                        rules={[
                            {
                                required: partnerFields.domain?.isMandatory,
                                whitespace: true,
                                type: "url",
                            },
                        ]}>
                        <Input maxLength={253} placeholder={t("partner_WebsitePlaceholder")} />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.pocFirstName?.customLabel ?? t("pocFirstName")}
                        labelAlign="left"
                        name={"pocFirstName"}
                        rules={[
                            {
                                required: partnerFields.pocFirstName?.isMandatory,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input
                            placeholder={
                                partnerFields.pocFirstName?.customLabel ?? t("pocFirstName")
                            }
                            maxLength={200}
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.pocLastName?.customLabel ?? t("pocLastName")}
                        labelAlign="left"
                        name={"pocLastName"}
                        rules={[
                            {
                                required: partnerFields.pocLastName?.isMandatory,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input
                            placeholder={partnerFields.pocLastName?.customLabel ?? t("pocLastName")}
                            maxLength={200}
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.emailAddress?.customLabel ?? t("emailPlaceHolder")}
                        labelAlign="left"
                        name="email"
                        rules={[
                            {
                                type: "email",
                                message: t("pocEmailAddressValidation"),
                                required: partnerFields.emailAddress?.isMandatory,
                            },
                        ]}>
                        <Input
                            placeholder={
                                partnerFields.emailAddress?.customLabel ?? t("emailPlaceHolder")
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.pocPhone?.customLabel ?? t("pocPhone")}
                        labelAlign="left"
                        name={"pocPhone"}
                        valuePropName="value"
                        rules={[
                            {
                                required: partnerFields.pocPhone?.isMandatory,
                                whitespace: true,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Input
                            placeholder={partnerFields.pocPhone?.customLabel ?? t("pocPhone")}
                            maxLength={50}
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.pocCountry?.customLabel ?? t("pocCountry")}
                        labelAlign="left"
                        name={"pocCountry"}
                        valuePropName="value"
                        rules={[
                            {
                                required: partnerFields.pocCountry?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <Select
                            allowClear
                            placeholder={partnerFields.pocCountry?.customLabel ?? t("pocCountry")}
                            showSearch>
                            {renderCountryOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        initialValue={undefined}
                        label={partnerFields.value?.customLabel ?? t("supplier_Value")}
                        labelAlign="left"
                        name={"yearlyVal"}
                        rules={[
                            {
                                required:
                                    selectedCurrency !== undefined && selectedCurrency.length > 0
                                        ? true
                                        : partnerFields.value?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <InputNumber
                            placeholder={partnerFields.value?.customLabel ?? t("supplier_Value")}
                            disabled={selectedCurrency === undefined}
                            style={{ width: "67%", marginRight: "5px" }}
                            maxLength={15}
                            decimalSeparator={","}
                            min={0}
                        />
                    </Form.Item>

                    <Select
                        id="currency"
                        placeholder={t("currencyPlaceholder")}
                        style={{
                            width: 150,
                            position: "absolute",
                            bottom: 356,
                            left: 625,
                        }}
                        showSearch
                        allowClear
                        value={selectedCurrency}
                        onChange={(value: string, option: any) =>
                            onSelectCurrencyChange(option ? option.key : undefined)
                        }>
                        {renderCurrencyOptions()}
                    </Select>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.status?.customLabel ?? t("generic_Status")}
                        labelAlign="left"
                        name={"status"}
                        valuePropName="value"
                        rules={[
                            {
                                required: partnerFields.status?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <SelectData
                            data-test="select_status_select"
                            placeholder={partnerFields.status?.customLabel ?? t("generic_Status")}
                            allowClear
                            useData={usePartnerStatuses}
                            selectOptionField={(item: any) => ({
                                text: item.name,
                                node: <span style={{ color: item.color }}>{item.name}</span>,
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.group?.customLabel ?? t("group")}
                        labelAlign="left"
                        name="group"
                        rules={[{ required: partnerFields.group?.isMandatory }]}>
                        <SelectData
                            selectOptionField="name"
                            placeholder={t("notSelected")}
                            useData={usePartnerGroups}
                        />
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.owner?.customLabel ?? t("owners")}
                        labelAlign="left"
                        name="owner"
                        rules={[
                            {
                                required: partnerFields.owner?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <SelectDataMultiple
                            useData={useContacts}
                            selectOptionField="name"
                            selectIdField="id"
                            placeholder={t("notSelected")}
                        />
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.category1?.customLabel ?? t("category1")}
                        labelAlign="left"
                        name="categoryFirst"
                        rules={[
                            {
                                required: partnerFields.category1?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <SelectDataMultiple
                            selectOptionField="name"
                            placeholder={t("notSelected")}
                            useData={usePartnerCategories.bind(null, categoryType.one)}
                        />
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={partnerFields.category2?.customLabel ?? t("category2")}
                        labelAlign="left"
                        name="categorySecond"
                        rules={[
                            {
                                required: partnerFields.category2?.isMandatory,
                                message: t("inputIsRequiredError"),
                            },
                        ]}>
                        <SelectDataMultiple
                            selectOptionField="name"
                            placeholder={t("notSelected")}
                            useData={usePartnerCategories.bind(null, categoryType.two)}
                        />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default CreateExternalPartnerModal;
