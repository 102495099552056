import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useMeetingMinute = (reload: any, id: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            if (id !== undefined) {
                setState({ ...state, loading: true });
                let data = await Provider.Governance.getMeetingMinuteById(id, {
                    customError: {
                        message: "Could not retrieve meeting minute",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data });
            }
        })();
    }, [reload, id]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMeetingMinute };
