import React from "react";
import { Spin } from "antd";
import { ChildActivities } from "components/utilitycomponents";
import { usePartnerComment } from "controller";
import classes from "../../summary.module.scss";
import { IActivityChild } from "models";

interface IProps {
    partnerId: number;
}

const PartnerCommentFeed = ({ partnerId }: IProps) => {
    const { data, loading: loadingComment, addActivity, removeActivity } = usePartnerComment(
        partnerId
    );

    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        addActivity(parentActivityId, activity);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        removeActivity(parentActivityId, activityId);
    };

    return (
        <>
            <Spin spinning={loadingComment}>
                {data && (
                    <div
                        className={classes.container}
                        style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 8 }}>
                        <ChildActivities
                            data={data.messages}
                            parentActivityId={data.parentActivityId}
                            onRemoveActivity={handleRemoveActivity}
                            onAddActivity={handleAddActivity}
                            showChangeHistoryButton={false}
                            showTopDivider={false}
                            showMoreCount={5}
                        />
                    </div>
                )}
            </Spin>
        </>
    );
};

export { PartnerCommentFeed };
