import React, { useEffect, useState } from "react";
import classes from "./relationships.module.scss";
import { Button, Card, Col, Collapse, Row, Popover, Form, Input, Spin } from "antd";
import { AddMemberModal } from "./CreateEditMemberModal";
//@ts-ignore
import ReactDragListView from "react-drag-listview";
import { generate } from "@ant-design/colors";
import {
    useRelationDimensions,
    useRelationshipCategoryCRUD,
    useRelationshipCompanyContactCRUD,
    useRelationshipDimensionCRUD,
    useRelationshipSupplierContactCRUD,
} from "controller";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ActionIcons } from "./actionIcons";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { ChevronDown, ChevronRight } from "components/utilitycomponents";
const { Panel } = Collapse;

interface IProps {
    partnerId: number;
}

const Relationships = ({ partnerId }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [memberModal, setMemberModal] = useState<any>({ visible: false });
    const [reload, setReload] = useState<any>({ reload: false });
    const { data: dimensions } = useRelationDimensions(partnerId, reload);
    const [orderedDimensions, setOrderedDimensions] = useState<any[]>(dimensions);
    const {
        createDimension,
        reorder: reorderDimensions,
        loading: loadingDimensions,
    } = useRelationshipDimensionCRUD();
    const {
        createCategory,
        reorder: reorderCategories,
        loading: loadingCategories,
    } = useRelationshipCategoryCRUD();
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);
    const { reorder: reorderCompanyContacts } = useRelationshipCompanyContactCRUD();
    const { reorder: reorderSupplierContacts } = useRelationshipSupplierContactCRUD();
    const [popOverOpen, setPopOverOpen] = useState<any[]>([]);
    const dimensionColors = generate("#91caff").reverse();

    useEffect(() => {
        setOrderedDimensions(dimensions);
    }, [dimensions]);

    useEffect(() => {
        form.resetFields();
    }, [orderedDimensions]);

    const dimensionDragProps = {
        async onDragEnd(fromIndex: any, toIndex: any) {
            let currentDimensions = orderedDimensions.slice(0);
            const item = currentDimensions.splice(fromIndex, 1)[0];
            currentDimensions.splice(toIndex, 0, item);
            currentDimensions.map((item: any, index: any) => (item.order = index));
            setOrderedDimensions(currentDimensions);
            await reorderDimensions(
                currentDimensions.map((x: any) => ({ id: x.id, order: x.order }))
            );
        },
        nodeSelector: ".drag",
        handleSelector: "div",
    };

    const categoryDragProps = {
        nodeSelector: ".dragMeister",
        handleSelector: "div",
    };

    const handleCategoryDrag = async (fromIndex: number, toIndex: number, dimensionId: number) => {
        let currentDimensions = orderedDimensions.slice(0);
        let currentDimension = orderedDimensions.find((x: any) => x.id === dimensionId);
        let currentCategories = currentDimension.categories.slice(0);
        const item = currentCategories.splice(fromIndex, 1)[0];
        currentCategories.splice(toIndex, 0, item);
        currentCategories.map((item: any, index: any) => (item.order = index));
        currentDimension.categories = currentCategories;
        await reorderCategories(
            currentCategories.map((cat: any) => ({
                id: cat.id,
                relationshipDimensionId: dimensionId,
                title: cat.title,
                order: cat.order,
            }))
        );
        setOrderedDimensions(currentDimensions);
    };

    const memberDragProps = {
        nodeSelector: ".dragMeistro",
        handleSelector: "div",
    };

    const handleMemberDrag = async (
        fromIndex: number,
        toIndex: number,
        dimensionId: number,
        categoryId: number,
        type: "company" | "supplier"
    ) => {
        let currentDimensions = orderedDimensions.slice(0);
        let currentDimension = orderedDimensions.find((x: any) => x.id === dimensionId);

        let currentCategories: any[] = currentDimension.categories.slice(0);
        let currentMembers = currentCategories.find((cat: any) => cat.id === categoryId)[
            type === "company" ? "companyContacts" : "partnerContacts"
        ];
        const item = currentMembers.splice(fromIndex, 1)[0];
        currentMembers.splice(toIndex, 0, item);
        currentMembers.map((item: any, index: any) => (item.order = index));
        currentDimension.categories.find((cat: any) => cat.id === categoryId)[
            type === "company" ? "companyContacts" : "partnerContacts"
        ] = currentMembers;
        if (type === "company") {
            await reorderCompanyContacts(
                currentMembers.map((member: any) => ({
                    id: member.relationshipCategoryContactId,
                    order: member.order,
                }))
            );
        } else {
            await reorderSupplierContacts(
                currentMembers.map((member: any) => ({
                    id: member.relationshipCategoryContactId,
                    order: member.order,
                }))
            );
        }
        setOrderedDimensions(currentDimensions);
    };

    const handleDimensionCreate = async () => {
        let values = await form.validateFields();
        await createDimension({
            title: values.createDimensionTitle,
            order: orderedDimensions.length + 1,
            partnerId: partnerId,
        });
        setReload({ reload: true });
        setPopOverOpen([]);
    };

    const handleCategoryCreate = async (dimensionId: number, order: number) => {
        await form.validateFields();
        let title = await form.getFieldValue(`createCategoryTitle${dimensionId}`);

        await createCategory({
            title: title,
            order: order,
            relationshipDimensionId: dimensionId,
        });
        setReload({ reload: true });
        setPopOverOpen([]);
    };

    const renderDimensions = () => {
        return orderedDimensions.map((dimension: any, index: number) => {
            let backgoundColor = dimensionColors[index];
            let color = generate("#fff", {
                backgroundColor: dimensionColors[index],
            })[index];

            return (
                <div className={classNames("drag", classes.dimentationCollapseContainer)}>
                    <Collapse
                        defaultActiveKey={[0]}
                        style={{ backgroundColor: backgoundColor, borderRadius: 6 }}
                        expandIcon={({ isActive }) => (
                            <div className={classes.collapseIcon}>
                                {!isActive ? (
                                    <ChevronRight
                                        style={{
                                            color: color,
                                        }}
                                    />
                                ) : (
                                    <ChevronDown
                                        style={{
                                            color: color,
                                        }}
                                    />
                                )}
                            </div>
                        )}>
                        <Panel
                            key={index}
                            header={
                                <Row
                                    className={classes.dimensionTitle}
                                    style={{
                                        color: color,
                                    }}>
                                    <Col span={22}>{dimension.title}</Col>
                                    <ActionIcons
                                        type="dimension"
                                        item={dimension}
                                        onCallback={() => {
                                            setReload({ reload: true });
                                            form.resetFields();
                                        }}
                                        form={form}
                                    />
                                </Row>
                            }>
                            <ReactDragListView
                                key={index}
                                {...categoryDragProps}
                                onDragEnd={(fromIndex: number, toIndex: number) =>
                                    handleCategoryDrag(fromIndex, toIndex, dimension.id)
                                }>
                                {renderCategories(dimension.categories, dimension.id)}
                                <Popover
                                    key={index}
                                    trigger="click"
                                    onOpenChange={(open: boolean) => {
                                        setPopOverOpen(open ? [dimension.id] : []);
                                        form.resetFields();
                                    }}
                                    open={
                                        popOverOpen.filter((x: number) => x === dimension.id)
                                            .length > 0
                                    }
                                    content={
                                        <Row style={{ width: 400 }} gutter={8}>
                                            <Col span={18}>
                                                <Form.Item
                                                    key={index}
                                                    name={`createCategoryTitle${dimension.id}`}
                                                    label={t("generic_Title")}
                                                    initialValue={`${t(
                                                        "generic_Category"
                                                    )} ${dimension.categories.length + 1}`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: t("inputIsRequiredError"),
                                                        },
                                                    ]}>
                                                    <Input maxLength={256} autoFocus key={index} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Button
                                                    type="primary"
                                                    placeholder={t("generic_Placeholder-Text")}
                                                    onClick={() =>
                                                        handleCategoryCreate(
                                                            dimension.id,
                                                            dimension.categories.length
                                                        )
                                                    }>
                                                    {t("generic_Create")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    }>
                                    <Button
                                        block
                                        type="dashed"
                                        onClick={(e: any) => e.stopPropagation()}>
                                        {t("generic_AddObject", { object: t("generic_Category") })}
                                    </Button>
                                </Popover>
                            </ReactDragListView>
                        </Panel>
                    </Collapse>
                </div>
            );
        });
    };

    const renderCategories = (categories: any[], dimensionId: number) => {
        return categories.map((category: any, index: number) => {
            let color = "#fff";

            return (
                <div className={classNames("dragMeister", classes.categoryCollapseContainer)}>
                    <Collapse
                        style={{ backgroundColor: "#8490b3", borderRadius: 6 }}
                        expandIcon={({ isActive }) => (
                            <div className={classes.collapseIcon}>
                                {!isActive ? (
                                    <ChevronRight
                                        style={{
                                            color: color,
                                        }}
                                    />
                                ) : (
                                    <ChevronDown
                                        style={{
                                            color: color,
                                        }}
                                    />
                                )}
                            </div>
                        )}>
                        <Panel
                            key={index}
                            header={
                                <Row
                                    className={classes.categoryTitle}
                                    style={{
                                        color: color,
                                    }}>
                                    <Col span={22}>{category.title}</Col>
                                    <ActionIcons
                                        type="category"
                                        item={category}
                                        onCallback={() => {
                                            setReload({ reload: true });
                                            form.resetFields();
                                        }}
                                        form={form}
                                        dimensionId={dimensionId}
                                    />
                                </Row>
                            }>
                            <div className={classes.contactContainer}>
                                <div className={classes.companyContactsContainer}>
                                    <Card
                                        title={
                                            <h4>
                                                {t("relationsMatrix_MemberTitle", {
                                                    type: companyName,
                                                    interpolation: { escapeValue: false },
                                                })}
                                            </h4>
                                        }
                                        bodyStyle={{ paddingBottom: 8 }}>
                                        <ReactDragListView
                                            {...memberDragProps}
                                            onDragEnd={(fromIndex: number, toIndex: number) =>
                                                handleMemberDrag(
                                                    fromIndex,
                                                    toIndex,
                                                    dimensionId,
                                                    category.id,
                                                    "company"
                                                )
                                            }>
                                            {renderMembers(
                                                category.companyContacts,
                                                category.id,
                                                "company"
                                            )}
                                        </ReactDragListView>
                                        <Button
                                            className={classes.addMemberButton}
                                            type="link"
                                            onClick={() =>
                                                setMemberModal({
                                                    visible: true,
                                                    type: "company",
                                                    contactsLength: category.companyContacts.length,
                                                    categoryId: category.id,
                                                })
                                            }>
                                            {t("relationsMatrix_AddMember")}
                                        </Button>
                                    </Card>
                                </div>
                                <div className={classes.supplierContactsContainer}>
                                    <Card
                                        title={
                                            <h4>
                                                {t("relationsMatrix_MemberTitle", {
                                                    type: t("supplier"),
                                                })}
                                            </h4>
                                        }
                                        bodyStyle={{ paddingBottom: 8 }}>
                                        <ReactDragListView
                                            {...memberDragProps}
                                            onDragEnd={(fromIndex: number, toIndex: number) =>
                                                handleMemberDrag(
                                                    fromIndex,
                                                    toIndex,
                                                    dimensionId,
                                                    category.id,
                                                    "supplier"
                                                )
                                            }>
                                            {renderMembers(
                                                category.partnerContacts,
                                                category.id,
                                                "partner"
                                            )}
                                        </ReactDragListView>
                                        <Button
                                            className={classes.addMemberButton}
                                            type="link"
                                            onClick={() =>
                                                setMemberModal({
                                                    visible: true,
                                                    type: "partner",
                                                    contactsLength: category.partnerContacts.length,
                                                    categoryId: category.id,
                                                })
                                            }>
                                            {t("relationsMatrix_AddMember")}
                                        </Button>
                                    </Card>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            );
        });
    };

    const renderMembers = (members: any[], categoryId: number, type: "company" | "partner") => {
        return members.map((member: any) => (
            <div
                className={classNames("dragMeistro", classes.memberElement)}
                onClick={() =>
                    setMemberModal({
                        visible: true,
                        type: type,
                        categoryId: categoryId,
                        contact: member,
                    })
                }>
                <div className={classes.memberInformation}>
                    <div className={classes.name} style={{ paddingTop: member.role ? 0 : 2 }}>
                        {member.name}
                    </div>
                    {member.role && <div className={classes.role}>{member.role}</div>}
                </div>
                <div className={classes.memberActions}>
                    <Row
                        gutter={24}
                        className={classes.memberActionRowElement}
                        style={{ paddingTop: member.role ? 12 : 4 }}>
                        <Col span={14}></Col>
                        <ActionIcons
                            type={"member"}
                            item={{ ...member, type: type }}
                            onCallback={() => {
                                setReload({ reload: true });
                                form.resetFields();
                            }}
                            form={form}
                        />
                    </Row>
                </div>
            </div>
        ));
    };

    return (
        <Form form={form}>
            <div className={classes.contentContainer}>
                <Spin spinning={loadingCategories === true || loadingDimensions === true}>
                    <ReactDragListView {...dimensionDragProps}>
                        {renderDimensions()}
                    </ReactDragListView>
                    {orderedDimensions.length === 0 && (
                        <Row>
                            <Col span={8} push={8} className={classes.emptyTextContainer}>
                                <h2 className={classes.emptyText}>
                                    {t("relationsMatrix_EmptyText")}
                                </h2>
                            </Col>
                        </Row>
                    )}
                    <Popover
                        trigger="click"
                        onOpenChange={(open: boolean) => setPopOverOpen(open ? [-1] : [])}
                        open={popOverOpen.filter((x: number) => x === -1).length > 0}
                        content={
                            <Row style={{ width: 400 }} gutter={8}>
                                <Col span={18}>
                                    <Form.Item
                                        name="createDimensionTitle"
                                        label={t("generic_Title")}
                                        initialValue={`${t(
                                            "relationsMatrix_Dimension"
                                        )} ${orderedDimensions.length + 1}`}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: t("inputIsRequiredError"),
                                            },
                                        ]}>
                                        <Input autoFocus maxLength={256} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button
                                        type="primary"
                                        placeholder={t("generic_Placeholder-Text")}
                                        onClick={() => handleDimensionCreate()}>
                                        {t("generic_Create")}
                                    </Button>
                                </Col>
                            </Row>
                        }>
                        <Button block type="dashed">
                            {t("generic_AddObject", { object: t("relationsMatrix_Dimension") })}
                        </Button>
                    </Popover>
                </Spin>
            </div>
            <AddMemberModal
                partnerId={partnerId}
                visible={memberModal.visible}
                onClose={() =>
                    setMemberModal({
                        visible: false,
                        contactsLength: undefined,
                        categoryId: undefined,
                        contact: undefined,
                    })
                }
                onSubmit={() => {
                    setMemberModal({
                        visible: false,
                        contactsLength: undefined,
                        categoryId: undefined,
                        contact: undefined,
                    });
                    setReload({ reload: true });
                }}
                type={memberModal.type}
                categoryId={memberModal.categoryId}
                contactsLength={memberModal.contactsLength}
                contact={memberModal.contact}
            />
        </Form>
    );
};

export { Relationships };
