import React, { useState, FunctionComponent, Fragment } from "react";
import {
    Grid,
    getColumnSearchProps,
    getColumnSelectProps,
    getColumnRangePickerProps,
    getColumnMinMaxProps,
    ReviewRate,
    MultipleTags,
    BarChart07,
} from "components/utilitycomponents";
import { Button, Popconfirm, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./styles/overview.module.scss";
import {
    useSurveyOverview,
    useAutomatedSurveyTitles,
    useContacts,
    useCompanyType,
    usePartnerList,
    usePartnerFields,
} from "controller";
import UrlLinkModal from "./modals/urlModal/surveyUrlModal";
import OverviewHeader from "./overviewHeader";
import DeleteModal from "./modals/delete/deleteModal";
import CreationModal from "./modals/create/creationModal";
import SurveyReviewGraphModal from "./modals/surveyReviewCountGraph/surveyReviewGraphModal";
import { useParams } from "react-router-dom";
import { ReportType } from "models";
import SurveyResults from "./modals/surveyResults/surveyResults";
import { useResendSurvey } from "controller";
import dayjs from "utilities/daysJsConfig";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();
    const { ResendSurvey } = useResendSurvey();
    const [urlLinkModal, setUrlLinkModal] = useState({
        urlLinks: undefined,
        visible: false,
        clickedRow: undefined,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reloadData, setReloadData] = useState<any>(null);
    const [creationModalVisible, setCreationModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [surveyGraphModal, setSurveyGraphModal] = useState({
        surveyId: -1,
        visible: false,
        created: "",
    });
    const [resultsModal, setResultsModal] = useState({ surveyId: -1, visible: false });
    const { automatedSurveyId, surveyId }: any = useParams();
    const getDefaultCustomFilters = () => ({
        from: dayjs().subtract(6, "months"),
        to: dayjs().add(1, "day"),
    });
    const { data: partnerFields } = usePartnerFields();
    const [customFilters] = useState<any>(getDefaultCustomFilters());
    const { isDefaultCompany } = useCompanyType();

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.isSelectable === false,
        }),
    };

    const handleOnRow = (record: any, rowIndex: number) => ({
        onClick: () => {
            setResultsModal({ surveyId: record.id, visible: true });
        },
        style: {
            cursor: "pointer",
        },
    });

    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;

    let columns = [
        {
            title: t("survey_Id", "Id"),
            dataIndex: "id",
            key: "id",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 0,
            ...getColumnSearchProps({ dataIndex: "id" }),
        },
        {
            title: t("survey_Title", "Title"),
            dataIndex: "title",
            key: "title",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 1,
            ...getColumnSearchProps({ dataIndex: "title" }),
        },
        {
            title: t("survey_Introduction", "Introduction"),
            dataIndex: "introduction",
            key: "introduction",
            width: 250,
            fixed: false,
            ellipsis: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 2,
            ...getColumnSearchProps({ dataIndex: "introduction" }),
        },
        {
            title: isDefaultCompany
                ? companyNameColTitle || t("company_Type_Supplier")
                : t("company_Type_Customer"),
            dataIndex: "partners",
            key: "partnerId",
            width: 150,
            fixed: false,
            sorter: false,
            filterType: "array",
            viewColumnId: "supplierColumn",
            ...getColumnSelectProps(
                usePartnerList,
                {
                    value: ["id"],
                    text: ["name"],
                },
                { dataIndex: "partners", includeChildren: true }
            ),
            render: (val: any, record: any) => (
                <div>
                    {record.partners ? (
                        <MultipleTags values={record.partners} color="blue" tagNumber={3} />
                    ) : (
                        <></>
                    )}
                </div>
            ),
        },
        {
            title: t("survey_NumberOfSuppliers", "Number of Suppliers"),
            dataIndex: "numberOfSuppliers",
            key: "numberOfSuppliers",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "range",
            viewColumnId: 3,
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "numberOfSuppliers",
                allowDecimals: false,
            }),
        },
        {
            title: t("survey_NumberOfContacts", "Number of Contacts"),
            dataIndex: "numberOfContacts",
            key: "numberOfContacts",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "range",
            viewColumnId: 4,
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "numberOfContacts",
                allowDecimals: false,
            }),
        },
        {
            title: t("survey_NumberOfQuestions", "Number of Questions"),
            dataIndex: "numberOfQuestions",
            key: "numberOfQuestions",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "range",
            viewColumnId: 5,
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "numberOfQuestions",
                allowDecimals: false,
            }),
        },
        {
            title: t("survey_PotentialReviews", "Potential Reviews"),
            dataIndex: "potentialReviews",
            key: "potentialReviews",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 6,
            filterType: "range",
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "potentialReviews",
                allowDecimals: false,
            }),
            render: (val: any) => <div>{val === 0 ? "N/A" : val}</div>,
        },
        {
            title: t("survey_RatingAverage", "Rating Avg."),
            dataIndex: "ratingAvg",
            key: "ratingAvg",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 7,
            filterType: "range",
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, { dataIndex: "ratingAvg" }),
            render: (val: any) => (
                <Tooltip title={`${t("survey_RatingAveverageValue")} ${val.toFixed(2)}`}>
                    <div>
                        <ReviewRate allowHalf val={Math.round(val * 2) / 2} />
                    </div>
                </Tooltip>
            ),
        },
        {
            title: t("survey_ReviewsCount", "Reviews Count"),
            dataIndex: "reviewsCount",
            key: "reviewsCount",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 8,
            filterType: "range",
            min: -2147483648,
            max: 2147483647,
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "reviewsCount",
                allowDecimals: false,
            }),
            render: (val: any, record: any) => (
                <div className={classes.reviewCountContainer}>
                    <div className={classes.reviewCount}>{val}</div>
                    {val > 0 && (
                        <div
                            className={classes.reviewCountIcon}
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSurveyGraphModal({
                                    surveyId: record.id,
                                    visible: true,
                                    created: dayjs(record.created).format("MMM D, YYYY"),
                                });
                            }}>
                            <BarChart07 />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: t("survey_ResponseRate", "Response Rate"),
            dataIndex: "responseRate",
            key: "responseRate",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 9,
            min: -2147483648,
            max: 2147483647,
            filterType: "range",
            ...getColumnMinMaxProps(-2147483648, 2147483647, {
                dataIndex: "responseRate",
                allowDecimals: false,
            }),
            render: (val: any) => (
                <div>{val === 0 ? "N/A" : `${(val.toFixed(2) * 100).toFixed(0)} %`}</div>
            ),
        },
        {
            title: t("survey_CreatedBy", "Created By"),
            dataIndex: "createdBy",
            key: "CreatedBy",
            width: 250,
            sorter: true,
            viewColumnId: "createdBy",
            filterType: "array",
            ...getColumnSelectProps(
                useContacts,
                {
                    value: ["id"],
                    text: ["fullName"],
                },
                { dataIndex: "owners" }
            ),
        },
        {
            title: t("survey_Created", "Created (UTC)"),
            dataIndex: "created",
            key: "created",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 10,
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "created" }),
            render: (val: any) => <div>{dayjs(val).format("MMM D, YYYY (HH:mm)")}</div>,
        },
        {
            title: t("survey_EndDate", "End Date"),
            dataIndex: "endDate",
            key: "endDate",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: "endDate",
            filterType: "date",
            ...getColumnRangePickerProps({ dataIndex: "endDate" }),
            render: (val: any) => (val ? <div>{dayjs(val).format("MMM D, YYYY")}</div> : null),
        },
        {
            title: t("survey_Visibility", "Visibility"),
            dataIndex: "visibility",
            key: "visibility",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 11,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        loading: false,
                        data: [
                            { name: t("External"), id: 2 },
                            { name: t("Internal"), id: 1 },
                        ],
                    };
                },
                { value: ["id"], text: ["name"] },
                { dataIndex: "visibility", radioChoice: true }
            ),
            render: (val: string) => <div>{t(val.toLowerCase())}</div>,
        },
        {
            title: t("survey_UrlButton"),
            dataIndex: "urlLinks",
            key: "urlLinks",
            width: 150,
            fixed: false,
            sorter: false,
            viewColumnId: 12,
            render: (value: any, record: any) => (
                <div
                    onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    <Button
                        size="small"
                        type="primary"
                        onClick={() =>
                            setUrlLinkModal({ urlLinks: value, visible: true, clickedRow: record })
                        }>
                        {t("survey_UrlButton")}
                    </Button>
                    <Popconfirm
                        title={t("survey_ReminderBtnInfo")}
                        okText={t("generic_Confirm")}
                        cancelText={t("generic_Cancel")}
                        onCancel={e => e!.stopPropagation()}
                        disabled={
                            record.endDate !== null && dayjs(record.endDate).isBefore(dayjs())
                        }
                        onConfirm={async e => {
                            e!.stopPropagation();
                            await ResendSurvey(record.id);
                        }}>
                        <Button
                            size="small"
                            type="primary"
                            style={{ marginLeft: 5 }}
                            disabled={
                                record.endDate !== null && dayjs(record.endDate).isBefore(dayjs())
                            }>
                            {t("survey_ReminderBtn")}
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
        {
            title: t("survey_AutomatedSurveyTitle"),
            dataIndex: "automatedSurveyId",
            key: "automatedSurveyId",
            width: 200,
            fixed: false,
            sorter: true,
            filterType: "array",
            viewColumnId: 13,
            ...getColumnSelectProps(useAutomatedSurveyTitles, {
                value: ["id"],
                text: ["title"],
            }),
            render: (id: any, record: any) => (
                <Fragment>
                    {id !== null && <div>{`${record.automatedSurveyTitle} (id:${id})`}</div>}
                </Fragment>
            ),
        },
        {
            title: t("review_VoiceOfSupplierColumn"),
            dataIndex: "isVoiceOfSupplier",
            key: "isVoiceOfSupplier",
            width: 100,
            fixed: false,
            sorter: true,
            viewColumnId: 14,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Yes"), value: true },
                            { text: t("generic_No"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "isVoiceOfSupplier", radioChoice: true }
            ),
            render: (value: any, record: any) => (
                <Fragment>{`${value === true ? t("generic_Yes") : t("generic_No")}`}</Fragment>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid
                data-test="survey_table"
                additionalColumnFilters={
                    automatedSurveyId || surveyId
                        ? [
                              ...(automatedSurveyId
                                  ? [
                                        {
                                            name: "automatedSurveyId",
                                            value: automatedSurveyId,
                                        },
                                    ]
                                  : []),
                              ...(surveyId
                                  ? [
                                        {
                                            name: "id",
                                            value: surveyId,
                                        },
                                    ]
                                  : []),
                          ]
                        : undefined
                }
                title={() => (
                    <OverviewHeader
                        selectedRowKeys={selectedRowKeys}
                        onCreateBtnClick={() => setCreationModalVisible(true)}
                        onDeleteBtnClick={() => setDeleteModalVisible(true)}
                    />
                )}
                reloadData={reloadData}
                columns={columns}
                reportType={ReportType.survey}
                useData={useSurveyOverview}
                tableType={"Surveys"}
                fixedColumns={["id", "title"]}
                rowSelection={rowSelection}
                customFilters={customFilters}
                onRow={handleOnRow}
            />
            <CreationModal
                visible={creationModalVisible}
                onVisibleChange={() => setCreationModalVisible(false)}
                reloadTable={() => setReloadData({ reload: true })}
            />
            <DeleteModal
                visible={deleteModalVisible}
                onVisibleChange={() => setDeleteModalVisible(false)}
                reloadTable={() => setReloadData({ reload: true })}
                selectedRowKeys={selectedRowKeys}
                clearSelectedRows={() => setSelectedRowKeys([])}
            />
            <UrlLinkModal
                visible={urlLinkModal.visible}
                onClose={() => setUrlLinkModal({ ...urlLinkModal, visible: false })}
                urlData={urlLinkModal.urlLinks && urlLinkModal.urlLinks}
                clickedRow={urlLinkModal.clickedRow && urlLinkModal.clickedRow}
            />
            <SurveyReviewGraphModal
                created={surveyGraphModal.created}
                surveyId={surveyGraphModal.surveyId}
                visible={surveyGraphModal.visible}
                onClose={() => setSurveyGraphModal({ ...surveyGraphModal, visible: false })}
            />
            <SurveyResults
                surveyId={resultsModal.surveyId}
                visible={resultsModal.visible}
                onClose={() => setResultsModal({ surveyId: -1, visible: false })}
                type={"generic"}
            />
        </Fragment>
    );
};

export { Overview };
