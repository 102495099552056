import { patch, get } from "../base";

const AdminSupplierProfileSettings = {
    getSupplierProfileSettings: async ({ customError }: any = {}) => {
        const url = "/v1/AdminDashboard/GetSupplierProfileSettings";
        return await get({ url, customError });
    },
    updateSupplierProfileSetting: async (
        id: number,
        enabled: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/AdminDashboard/UpdateSupplierProfileSetting?id=${id}&enabled=${enabled}`;
        await patch({ url, customError });
    },
    getEnabledSupplierProfilePages: async ({ customError }: any = {}) => {
        const url = `/v1/AdminDashboard/GetEnabledSupplierProfilePages`;
        return await get({ url, customError });
    },
};

export { AdminSupplierProfileSettings };
