import React, { FunctionComponent } from "react";

const ChartToolTipContainer: FunctionComponent = ({ children }) => {
    return (
        <div
            style={{
                background: "#fff",
                padding: "9px 5px",
                borderRadius: "2px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            }}>
            {children}
        </div>
    );
};

export { ChartToolTipContainer };
