import { useState } from "react";
import Provider from "services/api";

const useProductMoveDocumentation = () => {
    const [loading, setLoading] = useState(false);

    const moveDocumentation = async (
        currentProductId: number,
        newProductId: number,
        newProductStatusId: number
    ) => {
        setLoading(true);
        const inUse = await Provider.Product.moveDocumentation(
            currentProductId,
            newProductId,
            newProductStatusId,
            {
                customError: {
                    message: "Could not move documentation",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return inUse;
    };

    return {
        loading,
        moveDocumentation,
    };
};

export { useProductMoveDocumentation };
