import React, { FunctionComponent, Fragment, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from "./styles/shared.module.scss";
import { IKpiUploadedDetail } from "models";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: IKpiUploadedDetail;
};

const KpiUploaded: FunctionComponent<PropsType> = ({ data }) => {
    const kpis = data.periods.split(";");
    const [showAllClicked, setShowAllClicked] = useState(false);
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className={classes.activityTitle}>{t("activity_KpiUploaded")}</div>
            <div>
                {kpis.map(
                    (p: string, index: number) =>
                        (index < 5 || (index > 2 && showAllClicked)) && (
                            <div key={index}>{[p.slice(0, 4), "-", p.slice(4)].join("")}</div>
                        )
                )}
                {kpis.length > 5 && (
                    <div>
                        <Button
                            type="link"
                            shape="circle"
                            onClick={() => setShowAllClicked(!showAllClicked)}>
                            {showAllClicked ? (
                                <Fragment>
                                    {t("showLess")} <UpOutlined />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {t("showAll")} {kpis.length} {t("activity_KpiPeriods")}{" "}
                                    <DownOutlined />
                                </Fragment>
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default KpiUploaded;
