import { useState } from "react";
import { Provider } from "services";

const useGroupDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteGroups = async (groupIds: number[]) => {
        setLoading(true);
        const id = await Provider.Partner.deleteGroups(groupIds, {
            customError: {
                message: "Could remove selected group(s)",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        deleteGroups,
    };
};

export { useGroupDelete };
