import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Input, Radio, Form } from "antd";
import { List, SelectData } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import classes from "./styles/dynamicTextFields.module.scss";
const TextArea = Input.TextArea;

interface IProps {
    type: "SupplierFields" | "SurveyFields";
    fieldKey: any;
    customDropdowns: { data: any[]; loading: boolean };
    data?: any;
}

const DescriptionComponent: FunctionComponent<IProps> = ({
    type,
    fieldKey,
    customDropdowns,
    data = undefined,
}) => {
    const { t } = useTranslation();
    const [toggleDropdown, setToggleDropdown] = useState(false);

    useEffect(() => {
        if (data !== undefined && data.customDropdownId) {
            setToggleDropdown(true);
        }
    }, []);

    return (
        <Row gutter={8}>
            <Col span={20}>
                {toggleDropdown || (data !== undefined && data.customDropdownId) ? (
                    <Form.Item
                        name={[`dropdown${type}`, fieldKey]}
                        initialValue={data && data.customDropdownId}>
                        <SelectData
                            placeholder={t("survey_CustomDropdownPlaceholder")}
                            selectOptionField="headLine"
                            useData={() => ({
                                data: customDropdowns.data,
                                loading: customDropdowns.loading,
                            })}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }
                        />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name={[`textFieldDescription${type}`, fieldKey]}
                        initialValue={data && data.description}>
                        <TextArea
                            autoSize
                            className={classes.textInput}
                            maxLength={200}
                            placeholder={t("survey_TextFieldDescriptionPlaceholder")}
                        />
                    </Form.Item>
                )}
            </Col>
            <Col span={4} className={classes.radioGroup}>
                <Radio.Group value={toggleDropdown} size="small">
                    <Radio.Button value={false} onClick={() => setToggleDropdown(false)}>
                        A
                    </Radio.Button>
                    <Radio.Button value={true} onClick={() => setToggleDropdown(true)}>
                        <List />
                    </Radio.Button>
                </Radio.Group>
            </Col>
        </Row>
    );
};

export { DescriptionComponent };
