import { useState } from "react";
import { Provider } from "services";

const useComplianceStatusUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (id: number, statusId: number) => {
        setLoading(true);

        const res = await Provider.Compliance.updateStatus(id, statusId, {
            customError: { message: "Could not update the status" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    const canChangeToStatus = async (id: number, statusId: number) => {
        setLoading(true);

        const res = await Provider.Compliance.canChangeToStatus(id, statusId, {
            customError: { message: "Could not check the status change validation" },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };

    return {
        loading,
        update,
        canChangeToStatus,
    };
};

export { useComplianceStatusUpdate };
