import React, { Fragment, forwardRef } from "react";
import { notification, Progress, Row, Col } from "antd";
import { UploadButton, useAttach } from "components/utilitycomponents";
import { DeleteIcon } from "../deleteIcon";
import { LoadingIndicator } from "../loadingIndicator";

type PropsType = {
    onChange: Function;
    externalLoading?: boolean;
    children?: any;
};

const AttachActivityFile = forwardRef(
    ({ onChange, externalLoading = false, children = "Attach File(s)" }: PropsType, ref) => {
        const { handleUpload, uploadInProgress, newFiles } = useAttach({
            onChange,
            notification,
        });
        return (
            <Fragment>
                <UploadButton onSelect={handleUpload} loading={uploadInProgress || externalLoading}>
                    {children}
                </UploadButton>
                <div style={{ marginTop: "5px" }}>
                    {newFiles.map((file: any, index: number) => (
                        <Fragment key={index}>
                            <Row style={{ lineHeight: "20px" }}>{file.name}</Row>
                            <Row style={{ lineHeight: "20px" }}>
                                <Col span={1} style={{ textAlign: "center" }}>
                                    <LoadingIndicator />
                                </Col>
                                <Col span={21}>
                                    <Progress
                                        percent={file.progress}
                                        size="small"
                                        status={file.progress < 100 ? "active" : "normal"}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: "center" }}>
                                    {file.progress < 100 && (
                                        <DeleteIcon
                                            fontSize={"70px"}
                                            onClick={() => file.source.cancel()}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    ))}
                </div>
            </Fragment>
        );
    }
);

export { AttachActivityFile };
