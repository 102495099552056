import React from "react";
import { GridLight } from "components/utilitycomponents";
import { useSupplierPortalSurveyRequests } from "controller";
import { getDomainUrl } from "utilities";
import dayjs from "utilities/daysJsConfig";
import { Flex, Tag } from "antd";
import classes from "./supplierPortal.module.scss";

const SurveyRequests = () => {
    const { data, loading } = useSupplierPortalSurveyRequests();

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (val: string) => <div>{val}</div>,
        },
        {
            title: "Deadline",
            dataIndex: "endDate",
            key: "deadline",
            render: (val: string) => (
                <Flex justify="flex-start">
                    <div style={{ marginRight: 4 }}>
                        {val ? dayjs(val).format("MMM D, YYYY") : "-"}
                    </div>
                    {val && dayjs(val).isBefore(dayjs()) && <Tag color="#f5222d">Overdue!</Tag>}
                    {val &&
                        dayjs(val).isAfter(dayjs()) &&
                        dayjs(val).isBefore(dayjs().add(7, "days")) && (
                            <Tag color="#fa8c16">Close to deadline</Tag>
                        )}
                </Flex>
            ),
        },
        {
            title: "Url",
            dataIndex: "surveyUrl",
            key: "url",
            render: (val: string) => <a href={`${getDomainUrl()}${val}`}>Answer Survey</a>,
        },
    ];

    return (
        <div className={classes.tableContainer}>
            <GridLight
                title={() => <div className={classes.tableTitle}> Survey Requests</div>}
                rowKey={"surveyUrl"}
                columns={columns}
                dataSource={data}
                pagination="none"
                scroll={{ y: 500 }}
                loading={loading}
            />
        </div>
    );
};

export { SurveyRequests };
