import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Divider, Spin, Tabs, Empty } from "antd";
import classes from "./changelog.module.scss";
import { useSegmentationAlignmentChangelog } from "controller";
import classNames from "classnames";
import dayjs from "utilities/daysJsConfig";
const { TabPane } = Tabs;

interface IProps {
    visible: boolean;
    onClose: Function;
    record: any;
}

const ChangeLogModal: FunctionComponent<IProps> = ({ visible, onClose, record }) => {
    const { t } = useTranslation();

    const { data, loading } = useSegmentationAlignmentChangelog(record?.alignmentId);

    return (
        <>
            <Modal
                width={1100}
                title={
                    <Row>
                        {record && (
                            <Col span={24} className={classes.modalTitle}>
                                {t("segmentation_Changelog_Title", {
                                    supplierName: record.supplierName,
                                    supplierId: record.supplierId,
                                    interpolation: { escapeValue: false },
                                })}
                            </Col>
                        )}
                    </Row>
                }
                open={visible}
                onCancel={() => onClose()}
                footer={null}
                closeIcon={<></>}>
                <Spin spinning={loading}>
                    <div className={classNames(classes.rogueScroll, classes.changelogContainer)}>
                        <Tabs defaultActiveKey="1" size="small">
                            <TabPane key="1" tab={t("segmentation_current")} destroyInactiveTabPane>
                                <Row style={{ marginBottom: 16 }}>
                                    <Col span={1}></Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_ChangeDate")}
                                    </Col>
                                    <Col span={5} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_Responsible")}
                                    </Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_StartValue")}
                                    </Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_AgreedValue")}
                                    </Col>
                                    <Col span={5} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_Explanation")}
                                    </Col>
                                    <Divider />
                                </Row>
                                <div className={classes.changelogText}>
                                    {!loading && (
                                        <div>
                                            {data.length > 0 &&
                                                data.map((x: any, index: number) => (
                                                    <Row className={classes.itemRow}>
                                                        <Col span={1}></Col>
                                                        <Col span={4}>
                                                            {dayjs(x.modifiedDate).format(
                                                                "DD.MM.YYYY"
                                                            )}
                                                        </Col>
                                                        <Col span={5}>{x.modifiedByUserName}</Col>
                                                        <Col span={4}>
                                                            {x.previousAlignedSegmentCurrent}
                                                        </Col>
                                                        <Col span={4}>
                                                            {x.alignedSegmentCurrent}
                                                        </Col>
                                                        <Col span={5}>
                                                            {x.alignedSegmentCurrentComment}
                                                        </Col>
                                                        <Divider />
                                                    </Row>
                                                ))}
                                        </div>
                                    )}
                                    {data.length === 0 && !loading && (
                                        <Empty description={t("segmentation_Changelog_NoData")} />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane key="2" tab={t("segmentation_future")}>
                                <Row style={{ marginBottom: 16 }}>
                                    <Col span={1}></Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_ChangeDate")}
                                    </Col>
                                    <Col span={5} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_Responsible")}
                                    </Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_StartValue")}
                                    </Col>
                                    <Col span={4} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_AgreedValue")}
                                    </Col>
                                    <Col span={5} className={classes.headerLabel}>
                                        {t("segmentation_Changelog_Explanation")}
                                    </Col>
                                    <Divider />
                                </Row>
                                <div className={classes.changelogText}>
                                    {!loading && (
                                        <div>
                                            {data.length > 0 &&
                                                data.map((x: any, index: number) => (
                                                    <Row className={classes.itemRow}>
                                                        <Col span={1}></Col>
                                                        <Col span={4}>
                                                            {dayjs(x.modifiedDate).format(
                                                                "DD.MM.YYYY"
                                                            )}
                                                        </Col>
                                                        <Col span={5}>{x.modifiedByUserName}</Col>
                                                        <Col span={4}>
                                                            {x.previousAlignedSegmentFuture}
                                                        </Col>
                                                        <Col span={4}>{x.alignedSegmentFuture}</Col>
                                                        <Col span={5}>
                                                            {x.alignedSegmentFutureComment}
                                                        </Col>
                                                        <Divider />
                                                    </Row>
                                                ))}
                                        </div>
                                    )}
                                    {data.length === 0 && !loading && (
                                        <Empty description={t("segmentation_Changelog_NoData")} />
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export { ChangeLogModal };
