const Config = {
    lightUser: "lightuser",
    lightUserModuleAccess: ["Compliance", "Review", "NCR", "Action"],
    normalUser: "user",
    adminUser: "administrator",
    guestUser: "Guest",
    guestRoutes: [
        "SelfAssessment",
        "Reviews/Survey",
        "Guest/Compliance",
        "Guest/Ncr",
        "Guest/Analytics",
        "Reviews/Survey",
        "Guest/Review",
        "Guest/MeetingMinutes",
        "Guest/Actions",
    ],
    locizeProjectId: "5abccb9d-b39b-4e2b-9116-2dfcc8b89546",
    unAuthenticatedRoutes: ["Registration/User", "Registration"],
    //should change in scss files too
    pageContainerFixedWidth: 1200,
    apiConfig: {
        headers: {
            "Content-Type": "application/json-patch+json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
        },
        timeout: 30000,
    },
};
export default Config;
