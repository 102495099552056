import {
    ComplianceUpdated,
    ReviewUpdated,
    UnknownActivity,
    ProjectApproved,
    ProjectUpdated,
    FilesUploaded,
    ClosedNcr,
    OpenNcr,
    UpdatedNcr,
    ChangedNcrHolder,
    ApprovalStatusChangeNcr,
    NextResponsibleNcr,
    MessageChild,
    ActionCreated,
    ActionCompleted,
    ActionReopened,
} from "../activityTypes";
import { IActivityChild, ActivityType } from "models";

const getChildActivity = (data: IActivityChild) => {
    let ActivityTypeComponent: any;

    switch (data.typeName) {
        case ActivityType.ComplianceUpdated:
            ActivityTypeComponent = ComplianceUpdated;
            break;
        case ActivityType.ReviewUpdated:
            ActivityTypeComponent = ReviewUpdated;
            break;
        case ActivityType.ProjectApproved:
            ActivityTypeComponent = ProjectApproved;
            break;
        case ActivityType.ProjectUpdated:
            ActivityTypeComponent = ProjectUpdated;
            break;
        case ActivityType.FilesUploaded:
            ActivityTypeComponent = FilesUploaded;
            break;
        case ActivityType.ClosedNcr:
            ActivityTypeComponent = ClosedNcr;
            break;
        case ActivityType.OpenNcr:
            ActivityTypeComponent = OpenNcr;
            break;
        case ActivityType.UpdatedNcr:
            ActivityTypeComponent = UpdatedNcr;
            break;
        case ActivityType.ChangedNcrHolder:
            ActivityTypeComponent = ChangedNcrHolder;
            break;
        case ActivityType.ApprovalStatusChangeNcr:
            ActivityTypeComponent = ApprovalStatusChangeNcr;
            break;
        case ActivityType.NextResponsibleNcr:
            ActivityTypeComponent = NextResponsibleNcr;
            break;
        case ActivityType.Comment:
            ActivityTypeComponent = MessageChild;
            break;
        case ActivityType.ActionCreated:
            ActivityTypeComponent = ActionCreated;
            break;
        case ActivityType.ActionCompleted:
            ActivityTypeComponent = ActionCompleted;
            break;
        case ActivityType.ActionReopened:
            ActivityTypeComponent = ActionReopened;
            break;
        default:
            ActivityTypeComponent = UnknownActivity;
    }
    return {
        ActivityTypeComponent,
        activityDetail: data,
    };
};

export { getChildActivity };
