import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useExternalPartnersWithComplianceContacts = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Partner.getAdminExternalPartners(true, {
                customError: {
                    message: "Could not retrieve external partners",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            data = data.filter((x: any) => x.hasComplianceContacts === true);

            setState({ ...state, loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useExternalPartnersWithComplianceContacts };
