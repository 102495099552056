import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, Row, Col, Button, Spin, notification } from "antd";
import { useUserRegistrationType } from "controller";
import { useLinkPartnerByToken } from "controller";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Config from "../../../../config";

type PropTypes = {
    visible: boolean;
    onClose: Function;
};
const AcceptInvitationModal: FunctionComponent<PropTypes> = ({ visible, onClose }) => {
    const { t } = useTranslation();
    const { token }: any = useParams();
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const { loading, data: invitation } = useUserRegistrationType(token);
    const { loading: linkPartnerLoading, linkPartner } = useLinkPartnerByToken();

    const handleConfirm = async () => {
        await linkPartner(token);
        notification.success({
            message: t("success"),
            description: t("supplier_AcceptInvitation_Success", {
                companyName: invitation.name,
                interpolation: { escapeValue: false },
            }),
        });
        onClose();
    };

    if (!token) return null;

    return (
        <Modal
            title={t("supplier_AcceptInvitation_ModalTitle")}
            open={visible}
            footer={[
                userType === Config.adminUser &&
                !loading &&
                invitation &&
                invitation.invitationType === "CompanyExists" ? (
                    <Button
                        key="ok"
                        type="primary"
                        onClick={handleConfirm}
                        loading={linkPartnerLoading}>
                        {t("generic_Confirm")}
                    </Button>
                ) : null,
            ]}
            onCancel={() => onClose()}
            zIndex={1001}>
            {loading && (
                <Row style={{ margin: "10px 0" }}>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Spin spinning={loading} />
                    </Col>
                </Row>
            )}
            {userType !== Config.adminUser && !loading && (
                <Row style={{ margin: "10px 0" }}>
                    <Col span={24}>{t("supplier_AcceptInvitation_NotAKeyUser")}</Col>
                </Row>
            )}
            {!loading && (!invitation || invitation.invitationType !== "CompanyExists") && (
                <Row style={{ margin: "10px 0" }}>
                    <Col span={24}>{t("admin_UserRegistration_NonExistantLink")}</Col>
                </Row>
            )}
            {!loading &&
                userType === Config.adminUser &&
                invitation &&
                invitation.invitationType === "CompanyExists" && (
                    <>
                        <Row key={1} style={{ margin: "0 0 10px 0" }}>
                            <Col span={24}>
                                {t("supplier_AcceptInvitation_InviteMessage", {
                                    userName: invitation.userFullName,
                                    companyName: invitation.name,
                                })}
                            </Col>
                        </Row>
                        <Row key={2} style={{ margin: "10px 0" }}>
                            <Col span={24}>
                                {t("supplier_AcceptInvitation_ConfirmMessage", {
                                    companyName: invitation.name,
                                })}
                            </Col>
                        </Row>
                    </>
                )}
        </Modal>
    );
};

export default AcceptInvitationModal;
