import React, { useState, Fragment } from "react";
import { Row, Col, Button, Card, Input, notification, Form } from "antd";
import { ChevronLeft, Edit02, LockUnlocked01, SelectData } from "components/utilitycomponents";
import { useNcrActionOwners, useNcrActionAnswerUpdate, useNcrActionOwnerUpdate } from "controller";
import classes from "./styles/ncrActions.module.scss";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;

interface Props {
    ncr: any;
    reloadTable: Function;
}

const NCRActions = ({ ncr, reloadTable }: Props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { UpdateAnswer, loading: loadingAnswer } = useNcrActionAnswerUpdate();
    const { UpdateOwner, loading: loadingOwner } = useNcrActionOwnerUpdate();
    const correctiveAction =
        ncr && ncr.ncrActions.filter((x: any) => x.actionType === "Corrective")[0];
    const preventiveAction =
        ncr && ncr.ncrActions.filter((x: any) => x.actionType === "Preventive")[0];
    const rootCauseAction =
        ncr && ncr.ncrActions.filter((x: any) => x.actionType === "RootCause")[0];
    const [editCorrective, setEditCorrective] = useState(false);
    const [editPreventive, setEditPreventive] = useState(false);
    const [editRootCause, setEditRootCause] = useState(false);
    const [editCorrectiveAnswer, setEditCorrectiveAnswer] = useState(false);
    const [editPreventiveAnswer, setEditPreventiveAnswer] = useState(false);
    const [editRootCauseAnswer, setEditRootCauseAnswer] = useState(false);
    const [submitClicked, setSubmitClicked] = useState<
        "Corrective" | "Preventive" | "RootCause" | undefined
    >(undefined);
    const [rootAnswer, setRootAnswer] = useState<any>(undefined);
    const [correctiveAnswer, setCorrectiveAnswer] = useState<any>(undefined);
    const [preventiveAnswer, setPreventiveAnswer] = useState<any>(undefined);

    async function updateActionAnswer(actionId: number, answerText: string) {
        await UpdateAnswer(actionId, answerText);
        reloadTable();
        notification.success({
            message: t("success"),
            description: t("ncrActions_AnswerSubmitted"),
        });
    }

    async function updateActionOwner(actionId: number, ownerId: string) {
        let ownerType: string = ownerId.indexOf("companyId:") > 0 ? "Company" : "Contact";
        await UpdateOwner(actionId, ownerId.replace("companyId:", ""), ownerType);
        reloadTable();
        notification.success({
            message: t("success"),
            description: t("ncrActions_OwnerChange"),
        });
    }

    return (
        <Form form={form}>
            <div className={classes.actionsContainer}>
                {ncr.externallyCreated === true && (
                    <Row className={classes.row}>
                        <div className={classes.ncrActionsHeaderTile}>
                            {t("ncrActions_RequestTitle", { partnerName: ncr.partnerName })}
                        </div>
                    </Row>
                )}
                {rootCauseAction && (
                    <Card className={classes.card} size="small">
                        <Row gutter={8}>
                            <Col className={classes.center} span={6}>
                                <div className={classes.title}>
                                    {t("ncrActions_RootCauseAction")}
                                </div>
                                {rootCauseAction.deadline && (
                                    <div className={classes.deadline}>
                                        {t("ncrActions_Deadline")}:{" "}
                                        {dayjs(rootCauseAction.deadline).format("MMM D, YYYY")}
                                    </div>
                                )}
                            </Col>
                            {ncr.externallyCreated === true ? (
                                <Col className={classes.center} span={2}>
                                    {editRootCause === false ? (
                                        <Edit02 onClick={() => setEditRootCause(!editRootCause)} />
                                    ) : (
                                        <ChevronLeft
                                            onClick={() => setEditRootCause(!editRootCause)}
                                        />
                                    )}
                                </Col>
                            ) : (
                                <Col span={2}></Col>
                            )}
                            {editRootCause === false ? (
                                <Col span={16}>
                                    {rootCauseAction.answers.length === 0 ? (
                                        <div className={classes.textAreaContainer}>
                                            <Form.Item
                                                className={classes.textArea}
                                                name="rootCauseAnswer"
                                                rules={[
                                                    {
                                                        message: t("inputIsRequiredError"),
                                                        whitespace: true,
                                                    },
                                                ]}>
                                                <TextArea
                                                    data-test="root_cause_answer_textarea"
                                                    maxLength={1000}
                                                    autoSize={{ minRows: 2 }}
                                                    placeholder={t("ncrActions_PendingResponse", {
                                                        ownerName: rootCauseAction.owners[0]
                                                            ? rootCauseAction.owners[0].ownerName
                                                            : "",
                                                        interpolation: { escapeValue: false },
                                                    })}
                                                    onChange={(e: any) =>
                                                        setRootAnswer(e.target.value)
                                                    }
                                                />
                                            </Form.Item>
                                            <Button
                                                data-test="root_cause_answer_submit_button"
                                                disabled={
                                                    rootAnswer === undefined ||
                                                    rootAnswer === null ||
                                                    rootAnswer === ""
                                                }
                                                size="small"
                                                onClick={async () => {
                                                    setSubmitClicked("RootCause");
                                                    await updateActionAnswer(
                                                        rootCauseAction.id,
                                                        rootAnswer
                                                    );
                                                    setSubmitClicked(undefined);
                                                }}
                                                loading={
                                                    submitClicked === "RootCause" && loadingAnswer
                                                }>
                                                {t("generic_Submit")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={classes.textAreaContainer}>
                                            <Form.Item
                                                className={classes.textArea}
                                                name="rootCauseAnswer"
                                                initialValue={rootCauseAction.answers[0].answer}
                                                rules={[
                                                    {
                                                        message: t("inputIsRequiredError"),
                                                        whitespace: true,
                                                    },
                                                ]}>
                                                <TextArea
                                                    data-test="root_cause_answer_textarea"
                                                    maxLength={1000}
                                                    className={classes.textArea}
                                                    autoSize={{ minRows: 2 }}
                                                    onChange={(e: any) =>
                                                        setRootAnswer(e.target.value)
                                                    }
                                                    disabled={!editRootCauseAnswer}
                                                />
                                            </Form.Item>
                                            {editRootCauseAnswer === false && (
                                                <Button
                                                    data-test="root_cause_answer_unlock_button"
                                                    size="small"
                                                    onClick={() => setEditRootCauseAnswer(true)}
                                                    icon={<LockUnlocked01 />}></Button>
                                            )}
                                            {editRootCauseAnswer === true && (
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        data-test="root_cause_answer_edit_button"
                                                        disabled={
                                                            rootAnswer === undefined ||
                                                            rootAnswer === null ||
                                                            rootAnswer === ""
                                                        }
                                                        size="small"
                                                        onClick={async () => {
                                                            setSubmitClicked("RootCause");
                                                            await updateActionAnswer(
                                                                rootCauseAction.id,
                                                                rootAnswer
                                                            );
                                                            setEditRootCauseAnswer(
                                                                !editRootCauseAnswer
                                                            );
                                                            setSubmitClicked(undefined);
                                                        }}
                                                        loading={
                                                            submitClicked === "RootCause" &&
                                                            loadingAnswer
                                                        }>
                                                        {t("generic_Submit")}
                                                    </Button>
                                                    <Button
                                                        data-test="root_cause_answer_cancel_button"
                                                        className={classes.cancelButton}
                                                        size="small"
                                                        onClick={() => {
                                                            setEditRootCauseAnswer(
                                                                !editRootCauseAnswer
                                                            );
                                                            form.setFieldsValue({
                                                                rootCauseAnswer:
                                                                    rootCauseAction.answers
                                                                        .length === 0
                                                                        ? ""
                                                                        : rootCauseAction.answers[0]
                                                                              .answer,
                                                            });
                                                        }}>
                                                        {t("generic_Cancel")}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            ) : (
                                <Fragment>
                                    <Col span={12}>
                                        <Form.Item
                                            className={classes.formItem}
                                            name="rootCauseActionOwner"
                                            initialValue={
                                                rootCauseAction && rootCauseAction.owners[0].ownerId
                                            }>
                                            <SelectData
                                                size="small"
                                                selectIdField="id"
                                                selectOptionField="name"
                                                useData={useNcrActionOwners.bind(
                                                    null,
                                                    rootCauseAction.owners[0].ownerId,
                                                    ncr.partnerName
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item>
                                            <Button
                                                size="small"
                                                onClick={async () => {
                                                    setSubmitClicked("RootCause");
                                                    await updateActionOwner(
                                                        rootCauseAction.id,
                                                        form.getFieldValue("rootCauseActionOwner")
                                                    );
                                                    setSubmitClicked(undefined);
                                                }}
                                                loading={
                                                    submitClicked === "RootCause" && loadingOwner
                                                }>
                                                {t("ncrActions_ChangeOwner")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Fragment>
                            )}
                        </Row>
                    </Card>
                )}
                {correctiveAction && (
                    <Card className={classes.card} size="small">
                        <Row gutter={8}>
                            <Col className={classes.center} span={6}>
                                <div className={classes.title}>
                                    {t("ncrActions_CorrectiveAction")}
                                </div>
                                {correctiveAction.deadline && (
                                    <div className={classes.deadline}>
                                        {t("ncrActions_Deadline")}:{" "}
                                        {dayjs(correctiveAction.deadline).format("MMM D, YYYY")}
                                    </div>
                                )}
                            </Col>
                            {ncr.externallyCreated === true ? (
                                <Col className={classes.center} span={2}>
                                    {editCorrective === false ? (
                                        <Edit02
                                            onClick={() => setEditCorrective(!editCorrective)}
                                        />
                                    ) : (
                                        <ChevronLeft
                                            onClick={() => setEditCorrective(!editCorrective)}
                                        />
                                    )}
                                </Col>
                            ) : (
                                <Col span={2}></Col>
                            )}
                            {editCorrective === false ? (
                                <Col span={16}>
                                    {correctiveAction.answers.length === 0 ? (
                                        <div className={classes.textAreaContainer}>
                                            <Form.Item
                                                className={classes.textArea}
                                                name="correctiveAnswer"
                                                rules={[
                                                    {
                                                        message: t("inputIsRequiredError"),
                                                        whitespace: true,
                                                    },
                                                ]}>
                                                <TextArea
                                                    data-test="corrective_answer_textarea"
                                                    autoSize={{ minRows: 2 }}
                                                    maxLength={1000}
                                                    onChange={(e: any) =>
                                                        setCorrectiveAnswer(e.target.value)
                                                    }
                                                    placeholder={t("ncrActions_PendingResponse", {
                                                        ownerName: correctiveAction.owners[0]
                                                            ? correctiveAction.owners[0].ownerName
                                                            : "",
                                                        interpolation: { escapeValue: false },
                                                    })}
                                                />
                                            </Form.Item>
                                            <Button
                                                data-test="corrective_answer_submit_button"
                                                disabled={
                                                    correctiveAnswer === undefined ||
                                                    correctiveAnswer === null ||
                                                    correctiveAnswer === ""
                                                }
                                                size="small"
                                                onClick={async () => {
                                                    setSubmitClicked("Corrective");
                                                    await updateActionAnswer(
                                                        correctiveAction.id,
                                                        correctiveAnswer
                                                    );
                                                    setSubmitClicked(undefined);
                                                }}
                                                loading={
                                                    submitClicked === "Corrective" && loadingAnswer
                                                }>
                                                {t("generic_Submit")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={classes.textAreaContainer}>
                                            <Form.Item
                                                className={classes.textArea}
                                                name="correctiveAnswer"
                                                initialValue={correctiveAction.answers[0].answer}
                                                rules={[
                                                    {
                                                        message: t("inputIsRequiredError"),
                                                        whitespace: true,
                                                    },
                                                ]}>
                                                <TextArea
                                                    data-test="corrective_answer_textarea"
                                                    maxLength={1000}
                                                    className={classes.textArea}
                                                    autoSize={{ minRows: 2 }}
                                                    onChange={(e: any) =>
                                                        setCorrectiveAnswer(e.target.value)
                                                    }
                                                    disabled={!editCorrectiveAnswer}
                                                />
                                            </Form.Item>
                                            {editCorrectiveAnswer === false && (
                                                <Button
                                                    data-test="corrective_answer_unlock_button"
                                                    size="small"
                                                    onClick={() => setEditCorrectiveAnswer(true)}
                                                    icon={<LockUnlocked01 />}></Button>
                                            )}
                                            {editCorrectiveAnswer === true && (
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        data-test="corrective_answer_edit_button"
                                                        disabled={
                                                            correctiveAnswer === undefined ||
                                                            correctiveAnswer === null ||
                                                            correctiveAnswer === ""
                                                        }
                                                        size="small"
                                                        onClick={async () => {
                                                            setSubmitClicked("Corrective");
                                                            await updateActionAnswer(
                                                                correctiveAction.id,
                                                                correctiveAnswer
                                                            );
                                                            setEditCorrectiveAnswer(
                                                                !editCorrectiveAnswer
                                                            );
                                                            setSubmitClicked(undefined);
                                                        }}
                                                        loading={
                                                            submitClicked === "Corrective" &&
                                                            loadingAnswer
                                                        }>
                                                        {t("generic_Submit")}
                                                    </Button>
                                                    <Button
                                                        data-test="corrective_answer_cancel_button"
                                                        className={classes.cancelButton}
                                                        size="small"
                                                        onClick={() => {
                                                            setEditCorrectiveAnswer(
                                                                !editCorrectiveAnswer
                                                            );
                                                            form.setFieldsValue({
                                                                correctiveAnswer:
                                                                    correctiveAction.answers
                                                                        .length === 0
                                                                        ? ""
                                                                        : correctiveAction
                                                                              .answers[0].answer,
                                                            });
                                                        }}>
                                                        {t("generic_Cancel")}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            ) : (
                                <Fragment>
                                    <Col span={12}>
                                        <Form.Item
                                            className={classes.formItem}
                                            name="correctiveActionOwner"
                                            initialValue={
                                                correctiveAction &&
                                                correctiveAction.owners[0].ownerId
                                            }>
                                            <SelectData
                                                size="small"
                                                selectIdField="id"
                                                selectOptionField="name"
                                                useData={useNcrActionOwners.bind(
                                                    null,
                                                    correctiveAction.owners[0].ownerId,
                                                    ncr.partnerName
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item>
                                            <Button
                                                size="small"
                                                onClick={async () => {
                                                    setSubmitClicked("Corrective");
                                                    await updateActionOwner(
                                                        correctiveAction.id,
                                                        form.getFieldValue("correctiveActionOwner")
                                                    );
                                                    setSubmitClicked(undefined);
                                                }}
                                                loading={
                                                    submitClicked === "Corrective" && loadingOwner
                                                }>
                                                {t("ncrActions_ChangeOwner")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Fragment>
                            )}
                        </Row>
                    </Card>
                )}
                {preventiveAction && (
                    <Card className={classes.card} size="small">
                        <Row gutter={8}>
                            <Col className={classes.center} span={6}>
                                <div className={classes.title}>
                                    {t("ncrActions_PreventiveAction")}
                                </div>
                                {preventiveAction.deadline && (
                                    <div className={classes.deadline}>
                                        {t("ncrActions_Deadline")}:{" "}
                                        {dayjs(preventiveAction.deadline).format("MMM D, YYYY")}
                                    </div>
                                )}
                            </Col>
                            {ncr.externallyCreated === true ? (
                                <Col className={classes.center} span={2}>
                                    {editPreventive === false ? (
                                        <Edit02
                                            onClick={() => setEditPreventive(!editPreventive)}
                                        />
                                    ) : (
                                        <ChevronLeft
                                            onClick={() => setEditPreventive(!editPreventive)}
                                        />
                                    )}
                                </Col>
                            ) : (
                                <Col span={2}></Col>
                            )}
                            {editPreventive === false ? (
                                <Fragment>
                                    <Col span={16}>
                                        {preventiveAction.answers.length === 0 ? (
                                            <div className={classes.textAreaContainer}>
                                                <Form.Item
                                                    className={classes.textArea}
                                                    name="preventiveAnswer"
                                                    rules={[
                                                        {
                                                            message: t("inputIsRequiredError"),
                                                            whitespace: true,
                                                        },
                                                    ]}>
                                                    <TextArea
                                                        data-test="preventive_answer_textarea"
                                                        maxLength={1000}
                                                        autoSize={{ minRows: 2 }}
                                                        onChange={(e: any) =>
                                                            setPreventiveAnswer(e.target.value)
                                                        }
                                                        placeholder={t(
                                                            "ncrActions_PendingResponse",

                                                            {
                                                                ownerName: preventiveAction
                                                                    .owners[0]
                                                                    ? preventiveAction.owners[0]
                                                                          .ownerName
                                                                    : "",
                                                                interpolation: {
                                                                    escapeValue: false,
                                                                },
                                                            }
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Button
                                                    data-test="preventive_answer_submit_button"
                                                    disabled={
                                                        preventiveAnswer === undefined ||
                                                        preventiveAnswer === null ||
                                                        preventiveAnswer === ""
                                                    }
                                                    size="small"
                                                    onClick={async () => {
                                                        setSubmitClicked("Preventive");
                                                        await updateActionAnswer(
                                                            preventiveAction.id,
                                                            preventiveAnswer
                                                        );
                                                        setSubmitClicked(undefined);
                                                    }}
                                                    loading={
                                                        submitClicked === "Preventive" &&
                                                        loadingAnswer
                                                    }>
                                                    {t("generic_Submit")}
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className={classes.textAreaContainer}>
                                                <Form.Item
                                                    className={classes.textArea}
                                                    name="preventiveAnswer"
                                                    initialValue={
                                                        preventiveAction.answers[0].answer
                                                    }
                                                    rules={[
                                                        {
                                                            message: t("inputIsRequiredError"),
                                                            whitespace: true,
                                                        },
                                                    ]}>
                                                    <TextArea
                                                        data-test="preventive_answer_textarea"
                                                        maxLength={1000}
                                                        className={classes.textArea}
                                                        autoSize={{ minRows: 2 }}
                                                        disabled={!editPreventiveAnswer}
                                                        onChange={(e: any) =>
                                                            setPreventiveAnswer(e.target.value)
                                                        }
                                                    />
                                                </Form.Item>
                                                {editPreventiveAnswer === false && (
                                                    <Button
                                                        data-test="preventive_answer_unlock_button"
                                                        size="small"
                                                        onClick={() =>
                                                            setEditPreventiveAnswer(true)
                                                        }
                                                        icon={<LockUnlocked01 />}></Button>
                                                )}
                                                {editPreventiveAnswer === true && (
                                                    <div className={classes.buttonContainer}>
                                                        <Button
                                                            data-test="preventive_answer_edit_button"
                                                            disabled={
                                                                preventiveAnswer === undefined ||
                                                                preventiveAnswer === null ||
                                                                preventiveAnswer === ""
                                                            }
                                                            size="small"
                                                            onClick={async () => {
                                                                setSubmitClicked("Preventive");
                                                                await updateActionAnswer(
                                                                    preventiveAction.id,
                                                                    preventiveAnswer
                                                                );
                                                                setEditPreventiveAnswer(
                                                                    !editPreventiveAnswer
                                                                );
                                                                setSubmitClicked(undefined);
                                                            }}
                                                            loading={
                                                                submitClicked === "Preventive" &&
                                                                loadingAnswer
                                                            }>
                                                            {t("generic_Submit")}
                                                        </Button>
                                                        <Button
                                                            data-test="preventive_answer_cancel_button"
                                                            className={classes.cancelButton}
                                                            size="small"
                                                            onClick={() => {
                                                                setEditPreventiveAnswer(
                                                                    !editPreventiveAnswer
                                                                );
                                                                form.setFieldsValue({
                                                                    preventiveAnswer:
                                                                        preventiveAction.answers
                                                                            .length === 0
                                                                            ? ""
                                                                            : preventiveAction
                                                                                  .answers[0]
                                                                                  .answer,
                                                                });
                                                            }}>
                                                            {t("generic_Cancel")}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Col>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Col span={12}>
                                        <Form.Item
                                            className={classes.formItem}
                                            name="preventiveActionOwner"
                                            initialValue={
                                                preventiveAction &&
                                                preventiveAction.owners[0].ownerId
                                            }>
                                            <SelectData
                                                size="small"
                                                selectIdField="id"
                                                selectOptionField="name"
                                                useData={useNcrActionOwners.bind(
                                                    null,
                                                    preventiveAction.owners[0].ownerId,
                                                    ncr.partnerName
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item>
                                            <Button
                                                size="small"
                                                onClick={async () => {
                                                    setSubmitClicked("Preventive");
                                                    await updateActionOwner(
                                                        preventiveAction.id,
                                                        form.getFieldValue("preventiveActionOwner")
                                                    );
                                                    setSubmitClicked(undefined);
                                                }}
                                                loading={
                                                    submitClicked === "Preventive" && loadingOwner
                                                }>
                                                {t("ncrActions_ChangeOwner")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Fragment>
                            )}
                        </Row>
                    </Card>
                )}
            </div>
        </Form>
    );
};

export default NCRActions;
