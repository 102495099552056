import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Popconfirm, message, Checkbox, notification, Form, Tooltip } from "antd";
import {
    useMatrixSATypeUpdate,
    useSendSelfAssessment,
    useMatrixSADetail,
    useCompanyType,
    useDefaultContacts,
    useSelfAssessmentRequestContacts,
} from "controller";
import {
    LoadingIndicator,
    ButtonedInput,
    RichTextEditor,
    InfoCircle,
} from "components/utilitycomponents";
import { getDomainUrl } from "utilities";
import dayjs from "utilities/daysJsConfig";
import { SelectPartnerContacts } from "components/utilitycomponents/contact";

type PropsType = {
    visible: boolean;
    onClose: Function;
    onCloseAndReload: Function;
    SAData: {
        partnerId: number;
        templateId: number;
        isObligated: boolean;
    };
};

const SendSAModal: FunctionComponent<PropsType> = ({
    SAData,
    visible,
    onClose,
    onCloseAndReload,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { data, loading } = useMatrixSADetail(SAData.partnerId, SAData.templateId);
    const { update: updateSAType, loading: SATypeUpdateLoading } = useMatrixSATypeUpdate();
    const { sendSelfAssessments, loading: sendSALoading } = useSendSelfAssessment();
    const [multipleSAEnabled, setMultipleSAEnabled] = useState<boolean>();
    const { isDefaultCompany } = useCompanyType();
    const [addCustomMsg, setAddCustomMsg] = useState(false);
    const [customMsg, setCustomMsg] = useState<any>(undefined);
    const { data: defaultContactReceivers } = useDefaultContacts(
        SAData.partnerId,
        "ComplianceContact"
    );
    const { data: saRequestees } = useSelfAssessmentRequestContacts(
        SAData.partnerId,
        SAData.templateId
    );
    const selectedContacts = Form.useWatch("compliancePartnerContactReceiver", form);

    useEffect(() => {
        if (saRequestees && saRequestees.length > 0) {
            form.setFieldValue(
                "compliancePartnerContactReceiver",
                saRequestees.map((contact: any) => contact.id)
            );
        } else {
            form.setFieldValue(
                "compliancePartnerContactReceiver",
                defaultContactReceivers.map((contact: any) => contact.id)
            );
        }
    }, [defaultContactReceivers, saRequestees]);

    useEffect(() => {
        setMultipleSAEnabled(data.multipleAssesmentEnabled);
    }, [data]);

    const handleCopyUrl = () => {
        var copyText = document.getElementById(
            "compliance_assessment_info_modal_url"
        ) as HTMLInputElement;
        if (copyText) {
            copyText.select();
            document.execCommand("copy");
            message.success(t("copied"));
        }
    };

    const handleSendSA = async () => {
        let values = await form.validateFields();
        await sendSelfAssessments({
            templateIds: [SAData.templateId],
            partnerId: SAData.partnerId,
            hasAdditionalText: addCustomMsg,
            additionalText: customMsg,
            compliancePartnerContactReceiverIds: values.compliancePartnerContactReceiver
                ? [...new Set(values.compliancePartnerContactReceiver)]
                : [],
        });
        notification.success({
            message: t("success"),
            description: t("compliance_sendSA_Success"),
        });
        onCloseAndReload();
    };

    const handleChangeSAType = async () => {
        setMultipleSAEnabled(!multipleSAEnabled);
        await updateSAType(data.token, !multipleSAEnabled);
        notification.success({
            message: t("success"),
            description: t("compliance_changeSAtype_Success"),
        });
    };

    if (loading) return <LoadingIndicator />;

    return (
        <Modal
            data-test="compliance_send_self_assessment_modal"
            title={t("compliance_SendSA")}
            open={visible}
            footer={[
                <Popconfirm
                    data-test="compliance_send_sa_warning"
                    title={t("compliance_SendSAToCompanyWarning", {
                        companyType: isDefaultCompany
                            ? t("company_Type_Supplier").toLocaleLowerCase()
                            : t("company_Type_Customer").toLocaleLowerCase(),
                    })}
                    onConfirm={handleSendSA}
                    okText={t("generic_Confirm")}
                    cancelText={t("generic_Cancel")}
                    key={0}>
                    <Button
                        data-test="send_button"
                        type="primary"
                        loading={sendSALoading}
                        disabled={selectedContacts === undefined || selectedContacts.length === 0}>
                        {t("compliance_SendSA")}
                    </Button>
                </Popconfirm>,
                <Button data-test="cancel_button" type="default" key={2} onClick={() => onClose()}>
                    {t("generic_Cancel")}
                </Button>,
            ]}
            onCancel={() => onClose()}>
            <Form form={form} layout="vertical">
                <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                    <div style={{ float: "left" }}>{t("compliance_LastSADate")}&nbsp;</div>
                    <div style={{ float: "left" }}>
                        {data.sentDate
                            ? dayjs(data.sentDate)
                                  .format("MMM D, YYYY")
                                  .toString()
                            : "-"}
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>

                <ButtonedInput
                    value={`${getDomainUrl()}/SelfAssessment/${data.token}`}
                    buttonText={t("copyUrl")}
                    inputId="compliance_assessment_info_modal_url"
                    onClick={handleCopyUrl}
                />

                <Form.Item
                    style={{ marginTop: "10px" }}
                    name="compliancePartnerContactReceiver"
                    label={
                        <span>
                            {t("compliance_RequestReceivers")}{" "}
                            <Tooltip title={t("compliance_RequestReceiversTip")}>
                                <span>
                                    <InfoCircle />
                                </span>
                            </Tooltip>
                        </span>
                    }
                    initialValue={defaultContactReceivers}
                    rules={[
                        {
                            required: true,
                            message: t("inputIsRequiredError"),
                        },
                    ]}>
                    <SelectPartnerContacts
                        partnerId={SAData.partnerId}
                        disabled={SAData.partnerId === undefined || SAData.partnerId === null}
                    />
                </Form.Item>

                <div style={{ marginTop: "10px" }}>
                    <Popconfirm
                        data-test="compliance_change_sa_type_warning"
                        title={t("compliance_ChangeSATypeWarning")}
                        onConfirm={handleChangeSAType}
                        okText={t("generic_Confirm")}
                        cancelText={t("generic_Cancel")}>
                        <Checkbox
                            data-test="compliance_multiple_SA_enabled_checkbox"
                            checked={multipleSAEnabled}>
                            {t("compliance_MultipleSAEnabled")}
                            {SATypeUpdateLoading && <LoadingIndicator />}
                        </Checkbox>
                    </Popconfirm>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Checkbox
                        value={addCustomMsg}
                        onChange={(e: any) => setAddCustomMsg(e.target.checked)}>
                        {t("compliance_AddCustomMsg")}
                    </Checkbox>
                    {addCustomMsg === true && (
                        <div style={{ marginTop: "10px" }}>
                            <RichTextEditor
                                bordered
                                onChange={(val: string) => setCustomMsg(val)}
                                defaultValue=""
                                placeholder={t("compliance_InsertText")}
                                maxLength={1000}
                            />
                        </div>
                    )}
                </div>
            </Form>
        </Modal>
    );
};

export default SendSAModal;
