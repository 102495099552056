import React, { FunctionComponent, useState } from "react";
import { Button, Popconfirm, Result, Table } from "antd";
import { Link } from "react-router-dom";
import classes from "./actionDropdown.module.scss";
import { useTranslation } from "react-i18next";
import { formatTime } from "utilities";
import { useOverdueActions, useActionCRUD } from "controller";
import { IAction, IActionType } from "models/action";
import { MultipleTags } from "components/utilitycomponents";
import { ActionDescriptionRenderer } from "../shared/actionDescriptionRenderer";
import { getPath } from "../shared/actionHelpers";

interface IProps {
    reloadCount?: Function;
    closeDropdown?: Function;
}

const OverdueActionsTable: FunctionComponent<IProps> = ({ reloadCount, closeDropdown }) => {
    const { t } = useTranslation();
    const [reloadTable, setReloadTable] = useState({ reload: false });
    const { data, loading } = useOverdueActions(reloadTable);
    const { completeActions, loading: completingAction } = useActionCRUD();

    const handleOnCompleteAction = async (actionId: number) => {
        await completeActions([actionId]);
        setReloadTable({ reload: true });
        reloadCount && reloadCount();
    };

    const renderTitle = (action: IAction) => {
        switch (action.type) {
            case IActionType.SelfAssessment:
                return t("activity_SelfAssessmentView1");
            case IActionType.Survey:
                return t("action_SurveyRequestTitle");
            default:
                return `${action.type.toString()} Id  ${action.registrationId}`;
        }
    };

    const columns = [
        {
            title: t("actions_Id"),
            ellipsis: true,
            dataIndex: "id",
            key: "Id",
            width: 100,
        },
        {
            title: t("actions_Title"),
            dataIndex: "title",
            key: "Title",
            ellipsis: true,
            width: 150,
            render: (title: string, record: IAction) => (
                <div>
                    {record.type === IActionType.Generic ? (
                        title
                    ) : record.canAccessRegistration ? (
                        <Link to={getPath(record)}>{renderTitle(record)}</Link>
                    ) : (
                        `${record.type.toString()} Id  ${record.registrationId}`
                    )}
                </div>
            ),
        },
        {
            title: t("actions_Description"),
            dataIndex: "description",
            key: "Description",
            width: 300,
            ellipsis: true,
            render: (description: string, record: IAction) => (
                <ActionDescriptionRenderer
                    description={description}
                    systemGeneratedType={record.systemGeneratedType}
                />
            ),
        },
        {
            title: t("actions_Supplier"),
            dataIndex: "supplier",
            key: "Supplier",
            ellipsis: true,
            width: 200,
            render: (supplier: any, record: IAction) => (
                <div>
                    {supplier !== null && (
                        <div>
                            {supplier}{" "}
                            {record.originatingPartnerName &&
                                `[Origin: ${record.originatingPartnerName}]`}
                        </div>
                    )}
                    {record.actionPartnerNames && record.actionPartnerNames.length > 1 && (
                        <MultipleTags
                            values={record.actionPartnerNames.map((m: any) => ({
                                id: m,
                                name: m,
                            }))}
                        />
                    )}
                </div>
            ),
        },
        {
            title: t("actions_DueDate"),
            dataIndex: "dueDate",
            key: "DueDate",
            ellipsis: true,
            render: (val: any) => <div>{val && `Due on ${formatTime(val, "MMM D, YYYY")}`}</div>,
        },
        {
            title: "",
            dataIndex: "id",
            key: "actionCol",
            width: 100,
            render: (val: number, record: IAction) => (
                <div className={classes.completeButtonContainer}>
                    <Popconfirm
                        disabled={
                            (record.editable === false && record.systemGenerated === false) ||
                            (record.systemActionEditable === false && record.systemGenerated)
                        }
                        title={t("actions_CompleteConfirmation")}
                        onCancel={e => e!.stopPropagation()}
                        onConfirm={e => {
                            e!.stopPropagation();
                            handleOnCompleteAction(val);
                        }}
                        okText={t("actions_Complete")}>
                        <Button
                            disabled={
                                (record.editable === false && record.systemGenerated === false) ||
                                (record.systemActionEditable === false && record.systemGenerated)
                            }
                            loading={completingAction}
                            size="small"
                            type="primary"
                            onClick={(e: any) => {
                                e.stopPropagation();
                            }}>
                            {t("actions_Complete")}
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];
    return (
        <div
            className={classes.overdueTableContainer}
            style={{
                width: closeDropdown ? "1050px" : "",
                minHeight: closeDropdown ? "" : "400px",
            }}>
            <div className={classes.overdueTableHeader}>{t("action_OverdueActions")}</div>
            <div className={classes.overdueTableWrapper}>
                {data.length > 0 && (
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading === true || completingAction === true}
                        pagination={false}
                        size="small"
                        scroll={{ y: 500 }}
                    />
                )}
                {!loading && data.length === 0 && (
                    <Result status="success" title={t("action_NoOverdue")} />
                )}
            </div>
        </div>
    );
};

export default OverdueActionsTable;
