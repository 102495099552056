import { useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    downloadProgress: number;
};

const useFileDownload = () => {
    const [downloadFileState, setDownloadFileState] = useState<StateType>({
        loading: false,
        downloadProgress: 0,
    });
    const [profileImageLoading, setProfileImageLoading] = useState(false);

    const downloadFile = async (fileId: number) => {
        setDownloadFileState({
            loading: true,
            downloadProgress: 0,
        });
        const data = await Provider.File.getFile(fileId, {
            onDownloadProgress: (progressEvent: any) => {
                let downloadProgress: number = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setDownloadFileState({
                    loading: true,
                    downloadProgress,
                });
            },
        }).catch(err => {
            setDownloadFileState({
                loading: false,
                downloadProgress: 0,
            });
            return Promise.reject(err);
        });
        setDownloadFileState({
            loading: false,
            downloadProgress: 100,
        });
        return data;
    };

    const downloadContactUploadSampleFile = async () => {
        setDownloadFileState({
            loading: true,
            downloadProgress: 0,
        });
        const data = await Provider.Contact.getContactUploadTemplate({
            onDownloadProgress: (progressEvent: any) => {
                let downloadProgress: number = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setDownloadFileState({
                    loading: true,
                    downloadProgress,
                });
            },
        }).catch((err: any) => {
            setDownloadFileState({
                loading: false,
                downloadProgress: 0,
            });
            return Promise.reject(err);
        });
        setDownloadFileState({
            loading: false,
            downloadProgress: 100,
        });
        return data;
    };

    const downloadKPIDataSpecificationSampleFile = async () => {
        setDownloadFileState({
            loading: true,
            downloadProgress: 0,
        });
        const data = await Provider.Analytics.getDataSpecificationExample({
            onDownloadProgress: (progressEvent: any) => {
                let downloadProgress: number = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setDownloadFileState({
                    loading: true,
                    downloadProgress,
                });
            },
        }).catch(err => {
            setDownloadFileState({
                loading: false,
                downloadProgress: 0,
            });
            return Promise.reject(err);
        });
        setDownloadFileState({
            loading: false,
            downloadProgress: 100,
        });
        return data;
    };

    const downloadPartnerFileTemplate = async () => {
        setDownloadFileState({
            loading: true,
            downloadProgress: 0,
        });
        const data = await Provider.Partner.getPartnerFileTemplate({
            onDownloadProgress: (progressEvent: any) => {
                let downloadProgress: number = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setDownloadFileState({
                    loading: true,
                    downloadProgress,
                });
            },
        }).catch(err => {
            setDownloadFileState({
                loading: false,
                downloadProgress: 0,
            });
            return Promise.reject(err);
        });
        setDownloadFileState({
            loading: false,
            downloadProgress: 100,
        });
        return data;
    };

    const downloadProfileImage = async (fileId: number) => {
        setProfileImageLoading(true);
        const data = await Provider.File.getProfileImage(fileId).catch(err => {
            setProfileImageLoading(false);
            return Promise.reject(err);
        });
        setProfileImageLoading(false);
        return data;
    };

    return {
        profileImageLoading,
        downloadFileLoading: downloadFileState.loading,
        downloadFileProgress: downloadFileState.downloadProgress,
        downloadFile,
        downloadKPIDataSpecificationSampleFile,
        downloadProfileImage,
        downloadContactUploadSampleFile,
        downloadPartnerFileTemplate,
    };
};

export { useFileDownload };
