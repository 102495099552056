import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useForumFrequency = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any[] = await Provider.Governance.getForumFrequency({
                customError: {
                    message: "Could not retrieve forum types",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useForumFrequency };
