import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    DatePicker,
    Checkbox,
    Tooltip,
    notification,
    Form,
    Tag,
    Modal,
    Skeleton,
} from "antd";
import { useCompanyType, useCompliance, useProductFeature } from "controller";
import { ComplianceVisibility, IComplianceUpdateInput, ComplianceDataTypeEnum } from "models";
import { dayjs, newlineToBr } from "utilities";
import { useComplianceUpdate } from "controller";
import HeadLines from "./headLines";
import {
    AttachFile,
    DownloadFile,
    PureDiv,
    SelectTreeDataMultiple,
    HelpCircle,
} from "components/utilitycomponents";
import {
    SelectVisibility,
    SelectVisibleTo,
    SelectNotifyUsers,
    Entity,
    FormRow,
    ColLeft,
    ColRight,
} from "../sharedComponents";
import classes from "./styles/edit.module.scss";
import useProductsByPartner from "controller/product/useProductsByPartner";

interface IProps {
    open: boolean;
    onClose: (reload: boolean) => void;
    complianceId: number | undefined;
}

const EditComplianceCompliance = ({ open, onClose, complianceId }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, data } = useCompliance(ComplianceDataTypeEnum.ForEditPage, complianceId);
    const { loading: updateLoading, update } = useComplianceUpdate();
    const [isDraft, setIsDraft] = useState(false);
    const [visibleTo, setVisibleTo] = useState<number[]>(data ? data.selectedAllowedUsers : []);
    const [attachedFileList, setAttachedFileList] = useState<any>([]);
    const { isDefaultCompany } = useCompanyType();
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
    const [fileUploadInProgress, setFileUploadInprogress] = useState(false);
    const [fileQuestionsLoading, setFileQuestionsLoading] = useState<any[]>([]);
    const { data: products, loading: loadingProducts } = useProductsByPartner(
        data && data.partnerId,
        false,
        true
    );
    const { hasProductFeature } = useProductFeature();
    const notifyAlertDateUsers = Form.useWatch("alertDateNotifyUserIds", form);
    const notifyEndDateUsers = Form.useWatch("endDateNotifyUserIds", form);

    useEffect(() => {
        form.resetFields();
    }, [data]);
    useEffect(() => {
        if (data !== null && data !== undefined) {
            setVisibleTo(data.selectedAllowedUsers);
            setAttachedFileList(
                data.files.map(file => ({
                    id: file.id,
                    name: file.name,
                }))
            );
        }
    }, [data]);

    useEffect(() => {
        if (showValidationMessage === true) {
            setTimeout(() => {
                setShowValidationMessage(false);
            }, 7500);
        }
    }, [showValidationMessage]);

    const showNotifyExternalPartner =
        (data &&
            (ComplianceVisibility as any)[data.visibility] === ComplianceVisibility.External) ||
        form.getFieldValue("visibility") === ComplianceVisibility.External;

    const handleSubmit = async ({ isDraft }: any) => {
        if (!data) return;
        setIsDraft(isDraft);
        await form
            .validateFields()
            .then(async (values: any) => {
                const valueKeys: any[] = Object.keys(values);
                const questionKeys = valueKeys.filter((key: string) => key.startsWith("question["));
                const questionsAndAnswersIds = questionKeys.map((questionKey: string) => {
                    let splitString = questionKey.split("[");
                    return {
                        questionId: splitString[1].replace("]answer", ""),
                        answerId: splitString[2].replace("]", ""),
                    };
                });

                const editForm: IComplianceUpdateInput = {
                    id: data.id,
                    status: form.getFieldValue("status"),
                    entity: form.getFieldValue("entity") ? form.getFieldValue("entity") : "",
                    visibility: form.getFieldValue("visibility"),
                    isDraft,
                    notifyUserAlertDate:
                        notifyAlertDateUsers && notifyAlertDateUsers.length > 0 ? true : false,
                    notifyUserEndDate:
                        notifyEndDateUsers && notifyEndDateUsers.length > 0 ? true : false,
                    notifyPartnerAlertDate: form.getFieldValue("alertDateNotifyExternalPartner")
                        ? true
                        : false,
                    notifyPartnerEndDate: form.getFieldValue("endDateNotifyExternalPartner")
                        ? true
                        : false,
                    endDate: form.getFieldValue("endDate")
                        ? dayjs(form.getFieldValue("endDate"))
                              .endOf("day")
                              .toISOString()
                        : null,
                    alertDate: form.getFieldValue("alertDate")
                        ? dayjs(form.getFieldValue("alertDate"))
                              .endOf("day")
                              .toISOString()
                        : null,
                    productIds:
                        hasProductFeature && form.getFieldValue("products")
                            ? form.getFieldValue("products")
                            : [],
                    complianceAnswers:
                        questionsAndAnswersIds.length > 0
                            ? questionsAndAnswersIds.map((idItem: any) => {
                                  let questionId = idItem.questionId;
                                  let answerId = idItem.answerId;

                                  let value = "";
                                  let multiChoiceValue;
                                  let fileIds;
                                  let fieldValue: any = form.getFieldValue(
                                      `question[${questionId}]answer[${answerId}]`
                                  );
                                  if (fieldValue) {
                                      let isArray = Array.isArray(fieldValue);
                                      if (isArray) {
                                          if (
                                              fieldValue.filter((x: any) => typeof x === "number")
                                                  .length > 0 ||
                                              fieldValue.filter(
                                                  (x: any) => x.id && typeof x.id === "number"
                                              ).length > 0
                                          ) {
                                              if (fieldValue.length > 0) {
                                                  if (fieldValue[0].id) {
                                                      fileIds = fieldValue.map(
                                                          (file: any) => file.id
                                                      );
                                                  } else {
                                                      fileIds = fieldValue;
                                                  }
                                                  value = "true";
                                              } else {
                                                  fileIds = [];
                                                  value = "";
                                              }
                                          } else {
                                              multiChoiceValue = fieldValue.join("|");
                                          }
                                      } else {
                                          value = dayjs.isDayjs(fieldValue)
                                              ? dayjs(fieldValue)
                                                    .endOf("day")
                                                    .toISOString()
                                              : fieldValue.toString();
                                      }
                                  }

                                  return {
                                      id: parseInt(answerId),
                                      value,
                                      MultipleChoiceQuestionValue: multiChoiceValue,
                                      fileIds: fileIds,
                                      comment: values[`comment[${questionId}]`]
                                          ? values[`comment[${questionId}]`]
                                          : null,
                                      complianceQuestionId: parseInt(questionId),
                                      complianceId: data.id,
                                  };
                              })
                            : [],
                    fileIds: form.getFieldValue("attachedFiles")
                        ? form.getFieldValue("attachedFiles")
                        : data.files.map(file => file.id),
                    complianceAlertDateNotifyUsers: notifyAlertDateUsers
                        ? notifyAlertDateUsers.map((id: number) => ({
                              contactId: id,
                          }))
                        : [],
                    complianceEndDateNotifyUsers: notifyEndDateUsers
                        ? notifyEndDateUsers.map((id: number) => ({
                              contactId: id,
                          }))
                        : [],
                    complianceAllowedUsers: form.getFieldValue("visibleTo")
                        ? form.getFieldValue("visibleTo").map((id: number) => ({
                              contactId: id,
                          }))
                        : [],
                };

                await update(editForm);
                notification.success({
                    message: t("success"),
                    description: t("generic_RegistrationUpdateSuccess"),
                });
                onClose(true);
            })
            .catch((err: any) => {
                setShowValidationMessage(true);
            });
    };

    return (
        <Modal
            centered={false}
            width={1200}
            style={{ top: 20 }}
            open={open}
            onCancel={() => onClose(false)}
            title={t("editRegistration")}
            footer={[
                data && data.isDraft ? (
                    <Button
                        onClick={() => handleSubmit({ isDraft: true })}
                        disabled={
                            fileUploadInProgress ||
                            fileQuestionsLoading.filter((x: any) => Object.values(x)[0] === true)
                                .length > 0 ||
                            loading
                        }
                        loading={isDraft && updateLoading}>
                        {t("compliance_SaveAsDraft")}
                    </Button>
                ) : (
                    undefined
                ),
                <Button
                    data-test="submit_your_answers_button"
                    type="primary"
                    onClick={() => handleSubmit({ isDraft: false })}
                    disabled={
                        fileUploadInProgress ||
                        fileQuestionsLoading.filter((x: any) => Object.values(x)[0] === true)
                            .length > 0 ||
                        loading
                    }
                    loading={!isDraft && updateLoading}>
                    {t("compliance_SubmitAnswer")}
                </Button>,
            ]}>
            {loading && <Skeleton paragraph={{ rows: 8 }} active />}
            {!loading && data !== null && (
                <div>
                    <Form
                        form={form}
                        labelWrap
                        labelAlign="left"
                        labelCol={{ lg: 5, md: 12, sm: 12, xs: 24 }}
                        wrapperCol={{ lg: 8, md: 12, sm: 12, xs: 24 }}>
                        <FormRow>
                            <ColLeft>
                                <label>{t("compliance_Introduction")}</label>
                            </ColLeft>
                            <ColRight>
                                <label>
                                    <PureDiv
                                        data-test="compliance_introduction"
                                        textContent={newlineToBr(
                                            data.templateDetail.introduction
                                                ? data.templateDetail.introduction
                                                : ""
                                        )}
                                    />
                                </label>
                                {data.templateDetail.files.map((file, index) => (
                                    <div data-test={`template_detail_file_${index}`} key={index}>
                                        <DownloadFile file={file} />
                                    </div>
                                ))}
                            </ColRight>
                        </FormRow>

                        <FormRow>
                            <ColLeft>
                                <label>{t("compliance_Template")}</label>
                            </ColLeft>
                            <ColRight>
                                <label data-test="compliance_template_name">
                                    {data.templateDetail.name}
                                </label>
                            </ColRight>
                        </FormRow>

                        <FormRow>
                            <ColLeft>
                                <label data-test="compliance_customer">
                                    {isDefaultCompany
                                        ? t("company_Type_Supplier")
                                        : t("company_Type_Customer")}
                                </label>
                            </ColLeft>
                            <ColRight>
                                <label data-test="compliance_fromOrOnPartner">
                                    {data.partnerName}
                                </label>
                            </ColRight>
                        </FormRow>
                        {!data.isSelfAssessment && (
                            <Form.Item
                                label={t("compliance_Visibility")}
                                name="visibility"
                                initialValue={(ComplianceVisibility as any)[data.visibility]}
                                rules={[
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            if (
                                                value === ComplianceVisibility.Internal ||
                                                value === ComplianceVisibility.External
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(t("compliance_VisibilityValidation"))
                                            );
                                        },
                                    },
                                ]}>
                                <SelectVisibility visibility={data.visibility} />
                            </Form.Item>
                        )}
                        {!data.isSelfAssessment && (
                            <Fragment>
                                {data.canChangeCustomizeVisibility && (
                                    <Form.Item
                                        label={
                                            <>
                                                <div>{t("compliance_VisibleTo")}</div>{" "}
                                                <Tooltip
                                                    title={t("compliance_VisibilityChangeTooltip")}>
                                                    <span style={{ marginLeft: 4 }}>
                                                        <HelpCircle />
                                                    </span>
                                                </Tooltip>
                                            </>
                                        }
                                        className={classes.formItem1}
                                        name="visibleTo"
                                        initialValue={data.selectedAllowedUsers}>
                                        <SelectVisibleTo
                                            placeHolder={
                                                <Tag style={{ fontSize: 14 }} bordered={false}>
                                                    {t("compliance_VisibleToAll")}
                                                </Tag>
                                            }
                                            onVisibleToChanged={() => {
                                                form.setFieldsValue({
                                                    endDateNotifyUserIds: [],
                                                    alertDateNotifyUserIds: [],
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                )}
                            </Fragment>
                        )}

                        {data.templateDetail.includeEndDate && (
                            <>
                                <Form.Item
                                    label={t("compliance_EndDate")}
                                    name="endDate"
                                    initialValue={data.endDate ? dayjs(data.endDate) : undefined}>
                                    <DatePicker
                                        data-test="end_date_picker"
                                        format={"MMM D, YYYY"}
                                        placeholder={t("generic_SelectDate")}
                                        getPopupContainer={node =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>
                                {!data.isSelfAssessment && (
                                    <Fragment>
                                        {showNotifyExternalPartner && (
                                            <Form.Item
                                                colon={false}
                                                label={<div></div>}
                                                name="endDateNotifyExternalPartner"
                                                initialValue={data.notifyPartnerEndDate}
                                                valuePropName="checked">
                                                <Checkbox
                                                    disabled={!data.partnerHasComplianceContacts}>
                                                    {t("compliance_NotifySupplier")}{" "}
                                                    {!data.partnerHasComplianceContacts &&
                                                        " - " + t("compliance_NoSupplierContacts")}
                                                </Checkbox>
                                            </Form.Item>
                                        )}

                                        <Form.Item
                                            label={t("compliance_NotifyUser")}
                                            name="endDateNotifyUserIds"
                                            initialValue={data.notifyEndDateSelectedUsers}>
                                            <SelectNotifyUsers visibleTo={visibleTo} />
                                        </Form.Item>
                                    </Fragment>
                                )}
                            </>
                        )}
                        {data.templateDetail.includeAlertDate && (
                            <>
                                <Form.Item
                                    label={t("compliance_AlertDate")}
                                    name="alertDate"
                                    initialValue={
                                        data.alertDate ? dayjs(data.alertDate) : undefined
                                    }>
                                    <DatePicker
                                        format={"MMM D, YYYY"}
                                        data-test="alert_date_picker"
                                        placeholder={t("generic_SelectDate")}
                                        getPopupContainer={node =>
                                            node ? (node.parentNode as HTMLElement) : document.body
                                        }
                                    />
                                </Form.Item>
                                {!data.isSelfAssessment && (
                                    <Fragment>
                                        {showNotifyExternalPartner && (
                                            <Form.Item
                                                colon={false}
                                                label={<div></div>}
                                                name="alertDateNotifyExternalPartner"
                                                valuePropName="checked"
                                                initialValue={data.notifyPartnerAlertDate}>
                                                <Checkbox
                                                    disabled={!data.partnerHasComplianceContacts}
                                                    data-test="alert_date_notify_external_partner_checkbox">
                                                    {t("compliance_NotifySupplier")}{" "}
                                                    {!data.partnerHasComplianceContacts &&
                                                        " - " + t("compliance_NoSupplierContacts")}
                                                </Checkbox>
                                            </Form.Item>
                                        )}

                                        <Form.Item
                                            label={t("compliance_NotifyUser")}
                                            name="alertDateNotifyUserIds"
                                            initialValue={data.notifyAlertDateSelectedUsers}>
                                            <SelectNotifyUsers visibleTo={visibleTo} />
                                        </Form.Item>
                                    </Fragment>
                                )}
                            </>
                        )}

                        <Form.Item
                            label={data.templateDetail.entity}
                            name="entity"
                            rules={[
                                {
                                    required: data.templateDetail.isEntityRequired === true,
                                    whitespace: data.templateDetail.isEntityRequired === true,
                                    message: t("inputIsRequiredError"),
                                },
                            ]}
                            initialValue={data.entity}>
                            <Entity
                                entityType={data.templateDetail.entityType}
                                options={data.templateDetail.customDropdownOptions}
                            />
                        </Form.Item>

                        {hasProductFeature && data.templateDetail.isProductTemplate && (
                            <Form.Item
                                label={t("Products")}
                                name="products"
                                initialValue={data && data.productIds}>
                                <SelectTreeDataMultiple
                                    selectOnlyChildren
                                    disabled={complianceId === undefined}
                                    selectOptionField="name"
                                    selectIdField="id"
                                    useData={() => ({
                                        data: products,
                                        loading: loadingProducts,
                                    })}
                                />
                            </Form.Item>
                        )}
                        {data.templateDetail.canUserAccessAttachments && (
                            <Form.Item
                                label={t("compliance_Attachments")}
                                name="attachedFiles"
                                initialValue={data.files.map(file => file.id)}>
                                <AttachFile
                                    maxFileSize={40}
                                    attachedFileList={attachedFileList}
                                    onUploadingFile={(uplading: boolean) =>
                                        setFileUploadInprogress(uplading)
                                    }
                                />
                            </Form.Item>
                        )}

                        <HeadLines
                            form={form}
                            data={data.templateDetail.headLines}
                            onAttachDocumentLoading={(fileQuestionsLoading: any[]) =>
                                setFileQuestionsLoading(fileQuestionsLoading)
                            }
                        />

                        <FormRow gutter={16}>
                            <Col lg={{ span: 10, push: 10 }}>
                                {showValidationMessage && (
                                    <div className={classes.validationErrorText}>
                                        {t("compliance_ValidationErrorMsg")}
                                    </div>
                                )}
                            </Col>
                        </FormRow>
                    </Form>
                </div>
            )}
        </Modal>
    );
};

export { EditComplianceCompliance };
