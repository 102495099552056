import { GraphType } from "models";
import { get, post } from "../base";

const customConfig = {
    timeout: 30 * 60 * 1000,
};

const PerformanceOverview = {
    getReviewGraph: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetReviewGraph?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getRatingDistribution: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetRatingDistribution?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getReviewRank: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetReviewTotalsTableAndRank?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getReviewCategory: async (
        partnerId: number,
        from: string,
        to: string,
        graphType: GraphType = GraphType.BarChart,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetReviewCategoriesGraph?partnerId=${partnerId}&from=${from}&to=${to}&graphType=${graphType}`;
        return await get({ url, customError: customError, customConfig });
    },
    getKpiGraph: async (partnerId: number, from: string, to: string, { customError }: any = {}) => {
        const url = `/v1/PerformanceOverview/GetKpiGraph`;
        return await post({
            url,
            payload: { partnerId, from, to },
            customError: customError,
            customConfig,
        });
    },
    getComplianceElements: async (
        partnerId: number,
        from?: string,
        to?: string,
        { customError }: any = {}
    ) => {
        let url = `/v1/PerformanceOverview/GetComplianceScoreTable?partnerId=${partnerId}`;
        if (from !== undefined || to !== undefined) {
            url = url + `&from=${from}&to=${to}`;
        }
        return await get({ url, customError: customError, customConfig });
    },
    getSelfAssessmentFillRate: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetSelfAssessmentScore?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getComplianceFillRate: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetComplianceFillScore?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getNcrGraph: async (partnerId: number, from: string, to: string, { customError }: any = {}) => {
        const url = `/v1/PerformanceOverview/GetNcrGraph?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
    getSupplierCardsPartnerInfo: async (partnerId: number, { customError }: any = {}) => {
        let url = `/v1/SupplierCards/GetPartnerInfo?partnerId=${partnerId}`;
        return await get({ url, customError: customError, customConfig });
    },
    getComplianceScore: async (
        partnerId: number,
        from: string,
        to: string,
        { customError }: any = {}
    ) => {
        const url = `/v1/PerformanceOverview/GetComplianceAverageScore?partnerId=${partnerId}&from=${from}&to=${to}`;
        return await get({ url, customError: customError, customConfig });
    },
};

export default PerformanceOverview;
