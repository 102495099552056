import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveyReviewCriteria = (surveyId: number, segmentationAnalysisId: number) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (surveyId && surveyId !== -1 && !Array.isArray(surveyId)) {
                setState({ ...state, loading: true });
                const data = await Provider.Survey.getReviewCriteriaBySurveyId(surveyId, {
                    customError: {
                        message: "Could not retrieve list of review criteria for survey",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            } else if (segmentationAnalysisId !== -1) {
                setState({ ...state, loading: true });
                const data = await Provider.Survey.getReviewCriteriaBySegmentationAnalysisId(
                    segmentationAnalysisId,
                    {
                        customError: {
                            message: "Could not retrieve list of review criteria",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data });
            }
        })();
    }, [surveyId, segmentationAnalysisId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyReviewCriteria };
