import { Input, Form, Skeleton } from "antd";
import { SelectData, SelectDataMultiple, SelectTreeData } from "components/utilitycomponents";
import { useCompanyType, usePartnerList } from "controller";
import { IAction, ISystemActionType } from "models/action";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    data: IAction | null;
    editMode: boolean;
    supplierProfileOverview: boolean;
    onChange: Function;
    partnerId: number | undefined;
}

const ActionSupplierSelect: FunctionComponent<IProps> = ({
    data,
    editMode,
    supplierProfileOverview,
    onChange,
    partnerId,
}) => {
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();

    const initialSupplierValue = () => {
        if (editMode && !supplierProfileOverview) {
            if (data) {
                if (data.actionPartnerIds === null) {
                    return undefined;
                } else if (!data.createdExternally) {
                    return data.actionPartnerIds![0];
                }
            } else {
                return undefined;
            }
        } else if (
            (editMode && supplierProfileOverview) ||
            (!editMode && supplierProfileOverview)
        ) {
            return partnerId;
        } else {
            return undefined;
        }
    };

    return (
        <Fragment>
            {((data &&
                editMode &&
                !data.createdExternally &&
                data.systemGeneratedType !== ISystemActionType.NcrOpenOnSupplier &&
                data.systemGeneratedType !== ISystemActionType.NcrCorrectiveAction &&
                data.systemGeneratedType !== ISystemActionType.NcrPreventiveAction &&
                data.systemGeneratedType !== ISystemActionType.NcrRootCauseAction &&
                data.systemGeneratedType !== ISystemActionType.SurveyRequest &&
                data.systemGeneratedType !== ISystemActionType.ComplianceEndDate &&
                data.systemGeneratedType !== ISystemActionType.SelfAssessmentRequest &&
                data.systemGeneratedType !== ISystemActionType.GateKeeper) ||
                !editMode) && (
                <Form.Item
                    name="supplier"
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }
                    initialValue={initialSupplierValue()}>
                    <SelectTreeData
                        data-test="action_supplier_select"
                        allowClear
                        placeholder={`${t("select")} ${
                            isDefaultCompany
                                ? t("company_Type_Supplier").toLocaleLowerCase()
                                : t("company_Type_Customer").toLocaleLowerCase()
                        }`}
                        disabled={
                            (data &&
                                editMode &&
                                (data.systemGenerated ||
                                    data.completed ||
                                    data.editable === false ||
                                    data.registrationId !== null ||
                                    data.developmentPlanId !== null)) ||
                            supplierProfileOverview
                                ? true
                                : false
                        }
                        selectOptionField="name"
                        onChange={(val: any, item: any) => onChange(val, item)}
                        useData={usePartnerList.bind(null)}
                    />
                </Form.Item>
            )}
            {data &&
                editMode &&
                (data.systemGeneratedType === ISystemActionType.NcrOpenOnSupplier ||
                    data.systemGeneratedType === ISystemActionType.NcrCorrectiveAction ||
                    data.systemGeneratedType === ISystemActionType.NcrPreventiveAction ||
                    data.systemGeneratedType === ISystemActionType.NcrRootCauseAction ||
                    data.systemGeneratedType === ISystemActionType.ComplianceEndDate ||
                    data.systemGeneratedType === ISystemActionType.SelfAssessmentRequest ||
                    data.systemGeneratedType === ISystemActionType.GateKeeper) && (
                    <Form.Item
                        name="supplierRepresentsCompany"
                        label={
                            isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer")
                        }
                        initialValue={data.actionPartnerIds![0]}>
                        <SelectData
                            data-test="action_supplier_select"
                            allowClear
                            placeholder={`${t("select")} ${
                                isDefaultCompany
                                    ? t("company_Type_Supplier").toLocaleLowerCase()
                                    : t("company_Type_Customer").toLocaleLowerCase()
                            }`}
                            disabled
                            selectOptionField="name"
                            useData={() => ({
                                loading: false,
                                data: data.actionPartnerIds!.map((x: any, index: number) => ({
                                    id: x,
                                    name: data.actionPartnerNames![index],
                                })),
                            })}
                        />
                    </Form.Item>
                )}
            {data &&
                data.systemGeneratedType === ISystemActionType.SurveyRequest &&
                data.createdByCompanyId === data.originatingCompanyId &&
                editMode && (
                    <Form.Item
                        name="supplierMultiple"
                        label={
                            isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer")
                        }
                        initialValue={data.actionPartnerIds}>
                        <SelectDataMultiple
                            data-test="action_supplier_select"
                            disabled
                            selectOptionField="name"
                            useData={() => ({
                                loading: false,
                                data: data.actionPartnerIds!.map((x: any, index: number) => ({
                                    id: x,
                                    name: data.actionPartnerNames![index],
                                })),
                            })}
                        />
                    </Form.Item>
                )}
            {((data &&
                data.systemGeneratedType === ISystemActionType.SurveyRequest &&
                data.createdByCompanyId !== data.originatingCompanyId &&
                data.actionPartnerNames &&
                editMode) ||
                (data && data.createdExternally && editMode)) && (
                <Form.Item
                    name="supplierMultiple"
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }
                    initialValue={data.actionPartnerNames![0]}>
                    <Input allowClear disabled />
                </Form.Item>
            )}
            {editMode && data === null && (
                <Form.Item
                    name="supplierSkeleton"
                    label={
                        isDefaultCompany ? t("company_Type_Supplier") : t("company_Type_Customer")
                    }>
                    <Skeleton.Input style={{ width: 350 }} size="default" active={true} />
                </Form.Item>
            )}
        </Fragment>
    );
};

export { ActionSupplierSelect };
