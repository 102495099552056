import { SavedFilterType, ISavedReportFilter, ITableInput } from "models";
import { get, post, put, remove } from "../base";
const Segmentation = {
    getSegmentationReviewCriteriaGroups: async ({ customError }: any = {}) => {
        const url = `/api/SegmentationGroup/GetSegmentationReviewCriteriaGroups`;
        return await get({ url, customError });
    },
    getSegmentationReviewCriteriaSettings: async (groupAxis: any, { customError }: any = {}) => {
        const url = `/api/SegmentationGroup/GetSegmentationReviewCriteria`;
        return await post({ url, payload: groupAxis, customError });
    },
    updateSegmentationReviewCriteriaSettings: async (
        criteriaSetting: any[],
        { customError }: any = {}
    ) => {
        const url = `/api/SegmentationGroup/RemoveAndCreateReviewCriteria`;
        return await post({ url, payload: criteriaSetting, customError });
    },
    getAlignmentChangelog: async (alignmentId: number, { customError }: any = {}) => {
        const url = `/api/SegmentationCategorySetting/GetSegmentationChangeLog?segmentationAlignmentId=${alignmentId}`;
        return await get({ url, customError });
    },
    getActiveSegmentationReviewGroupNames: async ({ customError }: any = {}) => {
        const url = `/api/SegmentationGroup/GetSegmentationActiveAxisCategoryNames`;
        return await get({ url, customError });
    },
    getSegmentationStatus: async (from: any, to: any, { customError }: any = {}) => {
        const url = `/v1/Segmentation/GetSegmentationStatusData?from=${from.toISOString()}&to=${to.toISOString()}`;
        return await get({ url, customError });
    },
    getSegmentationResult: async (from: any, to: any, { customError }: any = {}) => {
        const url = `/v1/Segmentation/GetSegmentationResultData?from=${from.toISOString()}&to=${to.toISOString()}`;
        return await get({ url, customError });
    },
    getFilteredSegmentationStatus: async (
        filters: any,
        from: any,
        to: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/GetFilteredSegmentationStatusData?from=${from}&to=${to}`;
        return await post({ url, payload: filters, customError });
    },
    getFilteredSegmentationResult: async (
        filters: any,
        from: any,
        to: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/GetSegmentationResultData?from=${from}&to=${to}`;
        return await post({ url, payload: filters, customError });
    },
    getFilteredSegmentationReportSupplierList: async (
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/GetSegmentationReportSupplierList`;
        let response = await post({
            url,
            payload: tableInput,
            customError,
        });
        return response;
    },
    getSegmentationFilters: async (filterType: SavedFilterType, { customError }: any = {}) => {
        const url = `/v1/Segmentation/GetSavedFiltrationsOnSegmentationByType?filtrationType=${filterType}`;
        return await get({ url, customError });
    },
    getSegmentationFilter: async (
        id: string,
        filterType: SavedFilterType,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/GetSavedFiltrationOnSegmentationById?entityGuidId=${id}&filtrationType=${filterType}`;
        return await get({ url, customError });
    },
    saveSegmentationFilter: async (
        filterName: string,
        filterType: SavedFilterType,
        filters: ISavedReportFilter[],
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/SaveFiltrationOnSegmentation?filterName=${encodeURIComponent(
            filterName
        )}&filtrationType=${filterType}`;
        return await post({ url, payload: filters, customError });
    },
    segmentationFilterInUse: async (
        filterName: string,
        filterType: number,
        filters: ISavedReportFilter[],
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/CheckDuplicateFiltration?filterName=${encodeURIComponent(
            filterName
        )}&filtrationType=${filterType}`;
        return await post({ url, payload: filters, customError });
    },
    deleteSegmentationFilter: async (id: string, filterType: number, { customError }: any = {}) => {
        const url = `/v1/Segmentation/DeleteSavedFiltrationOnSegmentationById?entityGuidId=${id}&filtrationType=${filterType}`;
        return await remove({ url, customError });
    },
    getSegmentationReportSupplierList: async (
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/GetSegmentationStatusSupplierList`;
        let response = await post({
            url,
            payload: tableInput,
            customError,
        });
        return response;
    },
    sendSegmentationSurveyReminder: async (analysisUnitId: any, { customError }: any = {}) => {
        const url = `/v1/Survey/SendRemindersBySegmentationAnalysisId?segmentationAnalysisId=${analysisUnitId}`;
        await post({ url });
    },
    deleteSegmentation: async (segmentationAnalysisId: number, { customError }: any = {}) => {
        const url = `/v1/Survey/DeleteAnalysisUnit?segmentationAnalysisId=${segmentationAnalysisId}`;
        return await remove({ url, customError });
    },
    addManualSegmentHistory: async (payload: any, { customError }: any = {}) => {
        console.log(payload);
        const url = `/v1/Segmentation/AddSegmentHistory`;
        return await post({ url, payload, customError });
    },
    updateManualSegmentHistory: async (
        manualSegmentHistoryId: number,
        segmentationCategorySettingId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/UpdateSegmentHistory?manuelSegmentHistoryId=${manualSegmentHistoryId}&segmentationCategorySettingId=${segmentationCategorySettingId}`;
        await put({ url, payload: {}, customError });
    },
    deleteManualSegmentHistory: async (
        manualSegmentHistoryId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Segmentation/DeleteSegmentHistory?id=${manualSegmentHistoryId}`;
        return await remove({ url, customError });
    },
};

export default Segmentation;
