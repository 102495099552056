import { useState } from "react";
import Provider from "services/api";

const useContactUpdate = () => {
    const [loading, setLoading] = useState(false);

    const create = async (contactCreateInput: any) => {
        setLoading(true);
        const id = await Provider.Contact.createContact(contactCreateInput, {
            customError: {
                message: "Could not create new contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const update = async (contactUpdateInput: any) => {
        setLoading(true);
        const id = await Provider.Contact.updateContact(contactUpdateInput, {
            customError: {
                message: "Could not update the contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const remove = async (ids: number[]) => {
        setLoading(true);
        const id = await Provider.Contact.deleteContact(ids, {
            customError: {
                message: "Could not delete the contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const assignGroups = async (contactIds: number[], groupIds: number[]) => {
        setLoading(true);
        const id = await Provider.Contact.assignContactsToGroups(contactIds, groupIds, {
            customError: {
                message: "Could not assign group(s) to contact(s)",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const removeGroupFromContact = async (contactIds: number[], groupIds: number[]) => {
        setLoading(true);
        const id = await Provider.Contact.deleteContactsFromGroups(contactIds, groupIds, {
            customError: {
                message: "Could not remove group from contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        createContact: create,
        updateContact: update,
        deleteContact: remove,
        assignGroups,
        removeGroupFromContact,
    };
};

export { useContactUpdate };
