import React, { FunctionComponent, Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFillRate } from "controller";
import { LoadingIndicator, getPercentTagColor } from "components/utilitycomponents";
import { drawProgressBar } from "./drawProgressBar";

type PropsType = {
    type: string;
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

const FillRateProgressBar: FunctionComponent<PropsType> = ({ type, partnerId, range }) => {
    const { t } = useTranslation();
    const canvasRef = useRef(null);
    const { loading, data } = useRegistrationFillRate(type, partnerId, range.from, range.to);

    useEffect(() => {
        if (data && data !== 0) {
            const canvas = canvasRef.current;
            drawProgressBar(canvas, getPercentTagColor(data), data);
        }
    }, [data]);

    if (loading) return <LoadingIndicator />;
    if (data === null) return null;
    return (
        <Fragment>
            {data === 0 && <span>{t("supplierProfile_NoRegistrations")}</span>}
            {data !== 0 && (
                <canvas style={{ width: "100%" }} height="35px" ref={canvasRef}></canvas>
            )}
        </Fragment>
    );
};

export default FillRateProgressBar;
