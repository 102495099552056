import React, { FunctionComponent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin, Card, Result } from "antd";
import { useComplianceByToken } from "controller";
import ComplianceTemplateDetail from "./complianceTemplateDetail";
import { UserImage, PageContainer, ChildActivities } from "components/utilitycomponents";
import { AppState } from "store";
import Config from "../../../../../config";
import ComplianceDetail from "./complianceDetail";

const ComplianceGuestRegistration: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const { guestToken }: any = useParams();

    const { data, loading } = useComplianceByToken(guestToken);

    if (loading && data === null) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    if (!loading && (data === null || data === ""))
        return (
            <PageContainer settingContainer>
                <Result status="info" title={t("compliance_InvalidComplianceToken")} />
            </PageContainer>
        );

    if (data && userType !== Config.guestUser) {
        history.replace(`/Compliance/${data.id}`);
    }

    return (
        <PageContainer>
            <Card>
                {data !== null && (
                    <>
                        <Row gutter={8}>
                            <Col span={2}>
                                <UserImage
                                    alt={data.creator}
                                    style={{ width: "100%" }}
                                    fileId={data["creatorProfilePictureId"]}
                                    customDimension={65}
                                />
                            </Col>
                            <Col span={22}>
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <ComplianceDetail
                                                data={data}
                                                approvedForLoading={loading}
                                                onRatingChanged={() => {}}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={24}>
                                            <ComplianceTemplateDetail
                                                data={data.templateDetail}
                                                canSeeScores={data.canSeeScores}
                                            />
                                        </Col>
                                    </Row>
                                    <ChildActivities
                                        data={data.childrenActivity}
                                        parentActivityId={data.activityId}
                                        onRemoveActivity={() => {}}
                                        onAddActivity={() => {}}
                                    />
                                </>
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
        </PageContainer>
    );
};

export default ComplianceGuestRegistration;
