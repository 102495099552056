export enum ActivityType {
    KickStarter = "KickStarter",
    Message = "Message",
    InvitationAccepted = "InvitationAccepted",
    NewUser = "NewUser",
    FolderShared = "FolderShared",
    FolderSharedByPartner = "FolderSharedByPartner",
    PartnerUploadedFilesToSharedFolder = "PartnerUploadedFilesToSharedFolder",
    KpiUpload = "KpiUpload",
    KpiUploadOnMyCompany = "KpiUploadOnMyCompany",
    Review = "Review",
    Compliance = "Compliance",
    Project = "Project",
    Ncr = "Ncr",
    ComplianceUpdated = "ComplianceUpdated",
    ReviewUpdated = "ReviewUpdated",
    ProjectApproved = "ProjectApproved",
    ProjectUpdated = "ProjectUpdated",
    FilesUploaded = "FilesUploaded",
    ClosedNcr = "ClosedNcr",
    OpenNcr = "OpenNcr",
    UpdatedNcr = "UpdatedNcr",
    ChangedNcrHolder = "ChangedNcrHolder",
    ApprovalStatusChangeNcr = "ApprovalStatusChangeNcr",
    NextResponsibleNcr = "NextResponsibleNcr",
    Comment = "Message",
    ActionCreated = "ActionCreated",
    ActionCompleted = "ActionCompleted",
    ActionReopened = "ActionReopened",
    MeetingMinute = "MeetingMinute",
    PartnerFeed = "PartnerFeed",
}
