import { useEffect, useState } from "react";
import Provider from "services/api";

const useCommodityCategories = ({ level, singleLevel }: any) => {
    const [state, setState] = useState<any>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });

            let data: any = await Provider.CommodityCategory.getCommodityCategories(
                level,
                singleLevel,
                {
                    customError: {
                        message: "Could not retrieve commodity categories",
                    },
                }
            ).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data: data });
        })();
    }, [level, singleLevel]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCommodityCategories };
