function findNcrFieldVisibility(internalName: string, ncrFields: any[]) {
    let customDropdownId: number = 0;
    if (ncrFields) {
        let ncrField = ncrFields.filter((x: any) => x.internalName === internalName)[0];
        if (ncrField !== undefined) {
            customDropdownId = ncrField.customDropdownId;
        }
    }

    return customDropdownId;
}

const getNcrFieldCustomdropdownId = (ncrFields: any[]) => {
    let purchaseOrderNumberCustomDropdownId = findNcrFieldVisibility(
        "PurchaseOrderNumber",
        ncrFields
    );
    let salesOrderNumberCustomDropdownId = findNcrFieldVisibility("SalesOrderNumber", ncrFields);
    let deliveryNoteCustomDropdownId = findNcrFieldVisibility("DeliveryNote", ncrFields);

    return {
        purchaseOrderNumberCustomDropdownId,
        salesOrderNumberCustomDropdownId,
        deliveryNoteCustomDropdownId,
    };
};

export { getNcrFieldCustomdropdownId };
