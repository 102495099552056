import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useNcrExternalActionOwners = (supplierId: number, selectedPartner: any = undefined) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            if (supplierId === undefined || supplierId === null) {
                supplierId = -1;
            }
            if (supplierId !== undefined) {
                let data: any[] = await Provider.Ncr.getExternalNcrOwnerContacts(supplierId);
                data = data.map((contact: IContact) => ({
                    ...contact,
                    id: contact.id.toString(),
                    name: `${contact.firstName} ${contact.lastName}`,
                }));

                if (selectedPartner && selectedPartner.hasEmail === true && selectedPartner.name) {
                    data.unshift({
                        name: selectedPartner.name,
                        id: selectedPartner.representsCompanyId
                            ? `companyId:${selectedPartner.representsCompanyId}`
                            : `partnerId:${selectedPartner.id}`,
                    });
                }
                setState({ loading: false, data });
            }
        })();
    }, [supplierId, selectedPartner]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNcrExternalActionOwners };
