import { useState } from "react";
import { Provider } from "services";

const useSelfAssessmentCreate = () => {
    const [loading, setLoading] = useState(false);

    const createUpdate = async (selfAssessment: any) => {
        setLoading(true);
        const id = await Provider.SelfAssessment.createUpdateSelfAssessment(selfAssessment, {
            customError: {
                message: "Could not create self assessment",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        createUpdate,
    };
};

export { useSelfAssessmentCreate };
