import { Form, FormInstance, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "../../styles/onBoarding.module.scss";
import { useCheckPassword } from "controller";
interface IProps {
    formRef: React.RefObject<FormInstance>;
    username: string | undefined;
}
const ThirdStep = ({ formRef, username }: IProps) => {
    const { t } = useTranslation();
    const { checkPassword } = useCheckPassword();

    return (
        <>
            <Form.Item
                required
                name="password"
                className={classes.formItem}
                label={t("admin_UserRegistration_Password")}
                rules={[
                    {
                        required: true,
                        validator: async (rule: any, value: any, callback: any) => {
                            const res = await checkPassword(username!, value);
                            if (res) {
                                return Promise.reject(new Error(res));
                            } else return Promise.resolve();
                        },
                    },
                ]}>
                <Input.Password
                    placeholder={t("admin_UserRegistration_PasswordPlaceholder")}
                    maxLength={40}
                />
            </Form.Item>
            <Form.Item
                required
                name="confirmPassword"
                className={classes.formItem}
                label={t("onboarding_Repeat_Password_Label")}
                rules={[
                    {
                        required: true,
                        validator: (rule: any, value: any, callback: any) => {
                            if (!value || value !== formRef.current?.getFieldValue("password")) {
                                return Promise.reject(
                                    new Error(t("admin_UserRegistration_PasswordConfirmErrorMsg"))
                                );
                            }
                            return Promise.resolve();
                        },
                    },
                ]}>
                <Input.Password
                    placeholder={t("admin_UserRegistration_PasswordPlaceholder")}
                    maxLength={40}
                />
            </Form.Item>
        </>
    );
};

export default ThirdStep;
