import React, { FunctionComponent, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { AttachFile } from "components/utilitycomponents";
import { useParams } from "react-router-dom";
import { useNewsLogCreate } from "controller";

interface IProps {
    visible: boolean;
    onCreate: Function;
    onClose: Function;
}

const CreateModal: FunctionComponent<IProps> = ({ visible, onClose, onCreate }) => {
    const { id: supplierId }: any = useParams();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { createItem, loading } = useNewsLogCreate();

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    const handleSave = async () => {
        let values = await form.validateFields();
        let payload = {
            title: values.event,
            url: values.url && prefixUrl(values.url),
            fileIds: values.files,
            supplierId: supplierId,
        };
        await createItem(payload);
        onCreate();
        onClose();
    };

    const prefixUrl = (url: string) => {
        if (url.indexOf("https://") < 0) {
            url = "https://" + url;
        }
        return url;
    };

    return (
        <>
            <Modal
                title={
                    <>
                        {t("generic_Add")} {t("supplierProfile_NewsLogEvent")}
                    </>
                }
                open={visible}
                onCancel={() => onClose()}
                onOk={() => form.submit()}
                cancelText={t("generic_Cancel")}
                footer={[
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={async () => await form.submit()}>
                        {t("generic_Create")}
                    </Button>,
                ]}>
                <Form form={form} layout="vertical" onFinish={handleSave}>
                    <Form.Item
                        name="event"
                        label={t("Event")}
                        required
                        rules={[{ required: true }]}>
                        <Input.TextArea
                            autoFocus
                            maxLength={4000}
                            placeholder={t(
                                "supplierProfile_NewsLogEventPlaceholder"
                            )}></Input.TextArea>
                    </Form.Item>
                    <Form.Item name="url" label={t("generic_Url")}>
                        <Input maxLength={4000} placeholder={t("governanceTask_URLPlaceholder")} />
                    </Form.Item>
                    <Form.Item name="files" label={t("attachFile")}>
                        <AttachFile
                            attachedFileList={[]}
                            maxFileSize={40}
                            showMaxFileSize={false}
                            multiple={true}
                            filesSectionLayout={{
                                style: {
                                    display: "table-cell",
                                    width: "100%",
                                    padding: "0 10px",
                                },
                            }}>
                            {t("generic_SelectFile")}
                        </AttachFile>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export { CreateModal };
