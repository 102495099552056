import React, { FunctionComponent, Fragment } from "react";
import Dashboard from "../../contentcomponents/analytics/dashboard/dashboard";
import { useParams } from "react-router-dom";

interface Props {
    sharedDashboardView: boolean;
}

export const KPIArea: FunctionComponent<Props> = ({ sharedDashboardView }) => {
    const { id: partnerId }: any = useParams();
    return (
        <Fragment>
            <Dashboard partnerId={parseInt(partnerId)} sharedDashboardView={sharedDashboardView} />
        </Fragment>
    );
};
export default KPIArea;
