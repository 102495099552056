import React, { FunctionComponent, Fragment, useEffect, useState } from "react";
import {
    Modal,
    Row,
    Col,
    Input,
    DatePicker,
    Button,
    Tooltip,
    Form,
    Collapse,
    notification,
    Result,
    Flex,
} from "antd";
import { ChildActivities, Plus, SelectData } from "components/utilitycomponents";
import { useAction } from "controller";
import { useTranslation } from "react-i18next";
import { useActionCRUD, useDevelopmentPlanByPartnerId } from "controller";
import { IAction, IActionType, ICreateEditAction, ISystemActionType } from "models/action";
import { IActivityChild } from "models";
import { ActionSupplierSelect } from "../shared/actionSupplierSelect";
import { ActionOwnerSelect } from "../shared/actionOwnerSelect";
import { CreateModal as CreateContactModal } from "../../contacts/shared";
import { PerformanceData } from "../registration/performanceData";
import { SegmentationData } from "../registration/segmentationData";
import { useActivityActionByActionId } from "../../../../controller/action/useActivityActionByActionId";
import dayjs from "utilities/daysJsConfig";
const TextArea = Input.TextArea;
const Panel = Collapse.Panel;

interface IProps {
    visible: boolean;
    editMode: boolean;
    onCancel: Function;
    supplierProfileOverview: boolean;
    recordId?: number;
    reloadTable?: Function;
    partnerId?: number;
}

const CreateEditModal: FunctionComponent<IProps> = ({
    editMode,
    visible,
    onCancel,
    supplierProfileOverview,
    recordId,
    reloadTable,
    partnerId,
}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [reloadData, setReloadData] = useState<any>(null);
    const [reloadActivity, setReloadActivity] = useState<any>(null);
    const { data, loading: loadingAction } = useAction(recordId, reloadData);
    const { addActivity, removeActivity } = useAction(recordId, reloadActivity);
    const { data: activityData } = useActivityActionByActionId(recordId, reloadActivity);
    const {
        createAction,
        updateAction,
        creating,
        loading,
        completionLoading,
        completeActions,
        reOpenActions,
    } = useActionCRUD();
    const [selectedSupplierId, setSelectedSupplierId] = useState<any>();
    const [createModal, setCreateModal] = useState<any>({
        visible: false,
        newContacts: [],
    });
    const handleAddActivity = (parentActivityId: number, activity: IActivityChild) => {
        setReloadActivity(false);
        addActivity(parentActivityId, activity);
        setReloadActivity(true);
    };

    const handleRemoveActivity = (parentActivityId: number, activityId: number) => {
        setReloadActivity(false);
        removeActivity(parentActivityId, activityId);
        setReloadActivity(true);
    };

    const GetActionDescriptionText = (description: string, systemType?: ISystemActionType) => {
        switch (systemType) {
            case undefined:
                return description;
            case ISystemActionType.NcrHolder:
                return `${t("action_NcrOpen")} - ${t(description)}`;
            case ISystemActionType.NcrOpenOnSupplier:
                return `${t(description)} ${t("action_NcrOpenOnYourCompany")}`;
            case ISystemActionType.NcrCorrectiveAction:
            case ISystemActionType.NcrPreventiveAction:
            case ISystemActionType.NcrRootCauseAction:
                return `${t(description)}`;
            case ISystemActionType.SurveyRequest:
                return t(description);
            case ISystemActionType.SegmentationSettingCurrentAssignedSegment:
                return `${t("action_SettingAssignedSegment(Current)")}`;
            default:
                return description;
        }
    };

    const GetActionTitle = (data: IAction) => {
        if (
            data.title === null ||
            data.systemGeneratedType === ISystemActionType.SegmentationSettingCurrentAssignedSegment
        ) {
            switch (data.type) {
                case IActionType.SelfAssessment:
                    return t("action_SelfAssessmentTitle");
                case IActionType.Segmentation:
                    if (
                        data.systemGeneratedType &&
                        data.systemGeneratedType ===
                            ISystemActionType.SegmentationSettingCurrentAssignedSegment
                    ) {
                        return t("action_SettingAssignedSegmentTitle");
                    } else {
                        return data.title;
                    }
                default:
                    return `${data.type} Id ${data.registrationId}`;
            }
        } else {
            return data.title;
        }
    };

    function disabledDate(current: any) {
        return (
            current &&
            current <
                dayjs()
                    .subtract(1, "day")
                    .endOf("day")
        );
    }

    const handleSave = () => {
        form.validateFields()
            .then(async values => {
                let action: ICreateEditAction = {
                    title: values.title,
                    description: values.description && values.description,
                    ownerId: values.owner,
                    actionPartnerIds: values.supplier && [values.supplier],
                    dueDate: values.dueDate && values.dueDate.toISOString(),
                    developmentPlanId: values.developmentPlan && values.developmentPlan,
                };
                if (!editMode) {
                    await createAction(action);
                    handleOnClose(true);
                    notification.success({
                        message: t("success"),
                        description: t("action_CreatedSuccess"),
                    });
                } else {
                    if (
                        data?.systemGeneratedType === ISystemActionType.ComplianceEndDate ||
                        data?.systemGeneratedType === ISystemActionType.GateKeeper
                    ) {
                        action.ownerId = values.owners;
                    }
                    await updateAction(data!.id, action);
                    handleOnClose(true);
                    notification.success({
                        message: t("success"),
                        description: t("action_updateSuccess"),
                    });
                }
            })
            .catch(err => {
                return;
            });
    };

    const handleOnCompleteAction = async () => {
        await completeActions([data!.id]);
        setReloadData({ reload: true });
        reloadTable && reloadTable(true);
    };

    const handleOnReOpenAction = async () => {
        await reOpenActions([data!.id]);
        setReloadData({ reload: true });
        reloadTable && reloadTable(true);
    };

    async function handleOnClose(reloadTable: boolean) {
        await form.resetFields();
        onCancel(reloadTable);
    }

    useEffect(() => {
        if (!loading && data) {
            setSelectedSupplierId(data.actionPartnerIds![0]);
        }
    }, [data]);

    useEffect(() => {
        if (!visible) {
            setSelectedSupplierId(undefined);
        }

        form.resetFields();
    }, [data, visible]);

    useEffect(() => {
        if (editMode === false) {
            form.resetFields();
        }
    }, [editMode]);

    const disableField = () => {
        return data &&
            editMode &&
            (data.type !== IActionType.Segmentation ||
                data.systemGeneratedType ===
                    ISystemActionType.SegmentationSettingCurrentAssignedSegment) &&
            (data.systemGenerated ||
                data.completed ||
                data.editable === false ||
                data.registrationId !== null ||
                data.createdExternally)
            ? true
            : false;
    };

    return (
        <Fragment>
            {((data && !loadingAction) || !editMode) && (
                <Modal
                    afterClose={async () => {
                        await form.resetFields();
                    }}
                    destroyOnClose={true}
                    wrapProps={{ "data-test": "actions_create_edit_modal" }}
                    footer={null}
                    onCancel={async () => await handleOnClose(false)}
                    open={visible}
                    title={
                        editMode
                            ? `${t("action_EditAction")} id ${data && data.id} ${
                                  data && data.title !== null ? "- " + data.title : ""
                              }`
                            : t("action_CreateAction")
                    }>
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="title"
                            label={t("actions_Title")}
                            initialValue={editMode && data ? GetActionTitle(data) : undefined}
                            valuePropName="value"
                            rules={[
                                {
                                    required: true,
                                    message: t("inputIsRequiredError"),
                                    whitespace: true,
                                },
                            ]}>
                            <TextArea
                                data-test="action_title_textarea"
                                autoSize={{ minRows: 1, maxRows: 10 }}
                                maxLength={500}
                                placeholder={t("actions_TitlePlaceholder")}
                                autoFocus={!editMode}
                                disabled={disableField()}
                            />
                        </Form.Item>

                        <Row>
                            <Col span={22}>
                                <ActionOwnerSelect
                                    initialOwner={editMode ? data && data.ownerId : undefined}
                                    data={data}
                                    editMode={editMode}
                                    supplierId={selectedSupplierId}
                                    reload={reloadData}
                                />
                            </Col>
                            <Col span={1}>
                                <Tooltip title={t("Contacts_Add")}>
                                    <Button
                                        disabled={
                                            data &&
                                            editMode &&
                                            (data.systemGenerated ||
                                                data.completed ||
                                                data.editable === false ||
                                                data.createdExternally)
                                                ? true
                                                : false
                                        }
                                        style={{ marginTop: "30px", marginLeft: 10 }}
                                        icon={<Plus />}
                                        onClick={() =>
                                            setCreateModal({ newContacts: [], visible: true })
                                        }></Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <ActionSupplierSelect
                            partnerId={partnerId}
                            data={data}
                            editMode={editMode}
                            supplierProfileOverview={supplierProfileOverview}
                            onChange={(val: any, item: any) => {
                                setSelectedSupplierId(val);
                                form.setFieldsValue({ owner: [] });
                            }}
                        />
                        <Form.Item
                            name="description"
                            label={t("actions_Description")}
                            initialValue={
                                editMode
                                    ? data &&
                                      GetActionDescriptionText(
                                          data.description,
                                          data.systemGeneratedType
                                      )
                                    : undefined
                            }
                            valuePropName="value"
                            rules={[{ message: t("inputIsRequiredError"), whitespace: true }]}>
                            <TextArea
                                data-test="action_description_textarea"
                                autoSize={{ minRows: 3, maxRows: 10 }}
                                maxLength={1000}
                                placeholder={t("actions_DescriptionPlaceholder")}
                                disabled={disableField()}
                            />
                        </Form.Item>

                        <Form.Item
                            name="dueDate"
                            label={t("actions_DueDate")}
                            initialValue={
                                editMode
                                    ? data && data.dueDate !== null
                                        ? dayjs(data.dueDate)
                                        : undefined
                                    : undefined
                            }>
                            <DatePicker
                                format={"MMM D, YYYY"}
                                data-test="action_due_date_picker"
                                allowClear={true}
                                style={{ width: "100%" }}
                                disabled={
                                    data &&
                                    editMode &&
                                    (data.completed ||
                                        data.editable === false ||
                                        data.createdExternally)
                                        ? true
                                        : false
                                }
                                disabledDate={disabledDate}
                            />
                        </Form.Item>

                        {(!editMode || (data && data.developmentPlanId !== null)) && (
                            <Form.Item
                                label={
                                    !editMode
                                        ? t("developmentPlan_AddActionToPlan")
                                        : t("developmentPlan_PartOfPlan ")
                                }
                                name="developmentPlan"
                                initialValue={
                                    data && editMode ? data.developmentPlanId : undefined
                                }>
                                <SelectData
                                    allowClear
                                    selectIdField="id"
                                    useData={useDevelopmentPlanByPartnerId.bind(
                                        null,
                                        selectedSupplierId
                                    )}
                                    getPopupContainer={(node: any) =>
                                        node ? (node.parentNode as HTMLElement) : document.body
                                    }
                                    selectOptionField="name"
                                    placeholder={t("developmentPlan_SelectPlanPlaceHolder")}
                                />
                            </Form.Item>
                        )}

                        {editMode && data && data.actionSegmentationValue !== null && (
                            <SegmentationData
                                data={data.actionSegmentationValue}
                                editMode={editMode}
                            />
                        )}
                        {data?.type === IActionType.Performance && <PerformanceData data={data} />}
                        <Row>
                            <Col span={24}>
                                <Flex justify="flex-end">
                                    {editMode && data && (
                                        <div>
                                            {data && !loadingAction && data.completed ? (
                                                <Button
                                                    data-test="reopen_action_button"
                                                    loading={completionLoading}
                                                    type="primary"
                                                    onClick={() => handleOnReOpenAction()}>
                                                    {t("action_ReOpenAction")}
                                                </Button>
                                            ) : (
                                                !loadingAction && (
                                                    <Button
                                                        data-test="complete_action_button"
                                                        loading={completionLoading}
                                                        type="primary"
                                                        onClick={() => handleOnCompleteAction()}>
                                                        {t("action_CompleteAction")}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {((data && !data.completed) || data === null) && (
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            data-test="create_save_button"
                                            onClick={() => handleSave()}
                                            type="primary"
                                            loading={loading === true || creating === true}
                                            disabled={
                                                (data &&
                                                    editMode &&
                                                    data.editable === false &&
                                                    data.systemGenerated === false) ||
                                                (data && data.completed)
                                                    ? true
                                                    : false
                                            }>
                                            {editMode ? t("generic_Save") : t("generic_Create")}
                                        </Button>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                        {editMode && data && (
                            <Collapse ghost>
                                <Panel header={t("action_ChangeHistory")} key="1">
                                    <ChildActivities
                                        data={activityData}
                                        parentActivityId={data.activityId}
                                        onRemoveActivity={handleRemoveActivity}
                                        onAddActivity={handleAddActivity}
                                    />
                                </Panel>
                            </Collapse>
                        )}
                    </Form>
                </Modal>
            )}
            {!data && !loadingAction && (
                <Modal
                    footer={[
                        <Button onClick={() => handleOnClose(false)}>{t("generic_Close")}</Button>,
                    ]}
                    width={1200}
                    onCancel={() => handleOnClose(false)}
                    open={visible}>
                    <Result title={t("actions_Not_Available")}></Result>
                </Modal>
            )}
            {createModal.visible && (
                <CreateContactModal
                    visible={createModal.visible}
                    onClose={(reload, contactId) => {
                        if (reload) {
                            setCreateModal({ newContacts: [contactId], visible: false });
                            setReloadData({ reload: true });
                            let selectedContacts = form.getFieldValue("owner");
                            form.setFieldsValue({ owner: selectedContacts.concat([contactId]) });
                        } else {
                            setCreateModal({ newContacts: [], visible: false });
                        }
                    }}
                    partnerId={selectedSupplierId}
                    showSupplierSelect
                />
            )}
        </Fragment>
    );
};

export default CreateEditModal;
