import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Col, Row, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import { DescriptionComponent } from "./descriptionComponent";
import classes from "./styles/dynamicTextFields.module.scss";
import { useCustomDropDown } from "controller";
import { MinusCircle, PlusCircle } from "components/utilitycomponents";
const TextArea = Input.TextArea;

interface IProps {
    type: "SupplierFields" | "SurveyFields";
    data?: any[];
}

const DynamicTextFields: FunctionComponent<IProps> = ({ type, data = undefined }) => {
    const { t } = useTranslation();
    const { data: customDropdowns, loading } = useCustomDropDown();
    const [currentKey, setCurrentKey] = useState(0);
    const [keys, setKeys] = useState([0]);

    useEffect(() => {
        if (data && data.length !== 0) {
            setCurrentKey(data.length - 1);
            const newKeys = [...Array(data.length).keys()];
            setKeys(newKeys);
        } else {
            setCurrentKey(0);
            setKeys([0]);
        }
    }, []);

    function addRow() {
        if (keys.length < 10) {
            const newKeys = [...keys, currentKey + 1];
            setKeys(newKeys);
            setCurrentKey(currentKey + 1);
        }
    }

    function removeRow(k: any) {
        if (keys.length === 1) {
            return;
        }
        const newKeys = keys.filter(key => key !== k);
        setKeys(newKeys);
    }

    function renderTextFields() {
        return keys.map((key: any, index: number) => {
            return (
                <Row gutter={8} key={key}>
                    <Col className={classes.addRemoveIcon} span={1}>
                        <Row>
                            {index === 0 && keys.length < 10 && (
                                <Col span={1}>
                                    <PlusCircle onClick={addRow} />
                                </Col>
                            )}
                            {index !== 0 && (
                                <Col span={1}>
                                    <MinusCircle onClick={() => removeRow(key)} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col push={1} span={7}>
                        <Form.Item
                            name={[`textFieldTitle${type}`, key]}
                            initialValue={
                                data && data[key] && data[key].title ? data[key].title : undefined
                            }>
                            <TextArea
                                style={{ marginLeft: 8 }}
                                autoSize
                                className={classes.textInput}
                                maxLength={100}
                                placeholder={t("survey_TextFieldTitlePlaceholder")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <DescriptionComponent
                            type={type}
                            fieldKey={key}
                            customDropdowns={{
                                data: customDropdowns,
                                loading: loading,
                            }}
                            data={data && data[key]}
                        />
                    </Col>
                </Row>
            );
        });
    }

    return <Fragment>{renderTextFields()}</Fragment>;
};

export { DynamicTextFields };
