import { useSelector } from "react-redux";
import { AppState } from "store";

const useProductFeature = () => {
    const user = useSelector((state: AppState) => state.oidc.user);

    const hasProductFeature =
        user && user.profile && user.profile["ll-enabledFeature-Product"] === "true";

    return {
        hasProductFeature,
    };
};

export { useProductFeature };
