import { useEffect, useState } from "react";
import Provider from "services/api";

const useFolderInternalVisibilities = (id: any) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.File.getInternalVisibilities(id, {
                customError: {
                    message: "Could not retrieve internal visibilities",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, [id]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useFolderInternalVisibilities };
