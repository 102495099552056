import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IFolderSharedByPartnerDetail } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";
import { AppState } from "store";

type PropsType = {
    data: IFolderSharedByPartnerDetail;
};

const FolderSharedByPartner: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const hasFileAccess =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Files"]) === "true"
            ? true
            : false;

    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_SharedFolder")}{" "}
                {userType !== "lightuser" && hasFileAccess ? (
                    <Link to={`/Files/folderId/${data.id}`}>{data.name}</Link>
                ) : (
                    <span>{data.name}</span>
                )}{" "}
                {t("activity_WithYourCompany")}
            </div>
        </span>
    );
};

export { FolderSharedByPartner };
