import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useDevelopmentPlanTemplates = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any = await Provider.DevelopmentPlan.getDevelopmentPlanTemplates({
                customError: {
                    message: "Could not retrieve templates",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data: data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useDevelopmentPlanTemplates };
