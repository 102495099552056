import { get } from "../base";

const Common = {
    getCountries: async ({ customError }: any = {}) => {
        const url = "/v1/Common/GetCountries";
        return await get({ url, customError });
    },
};

export default Common;
