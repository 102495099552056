import { ITableInput } from "models";
import { ICreateEditAction } from "models/action";
import { post, get, remove, put } from "../base";

const Action = {
    getOverview: async (
        tableInput: ITableInput,
        partnerId: number | undefined = undefined,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/Action/Overview`;
        if (partnerId !== undefined) {
            url = `/v1/Action/Overview?supplierId=${partnerId}`;
        }
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },
    getActionById: async (actionId: number, { customError }: any) => {
        const url = `/v1/Action?actionId=${actionId}`;
        return await get({ url, customError });
    },
    getActionChildrenActivityById: async (actionId: number, { customError }: any) => {
        const url = `/v1/Action/GetActionChildrenActivity?actionId=${actionId}`;
        return await get({ url, customError });
    },
    deleteActions: async (
        actionIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action";
        await remove({
            url,
            payload: actionIds,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
    },
    createAction: async (
        createInput: ICreateEditAction,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Action`;
        await post({
            url,
            payload: createInput,
            customError: customError ? customError : null,
        });
    },
    updateAction: async (
        actionId: number,
        updateInput: ICreateEditAction,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Action?actionId=${actionId}`;
        await put({
            url,
            payload: updateInput,
            customError: customError ? customError : null,
        });
    },
    completeActions: async (
        actionIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/Complete";
        await put({ url, payload: actionIds });
    },
    openActions: async (
        actionIds: number[],
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/Open";
        await put({ url, payload: actionIds });
    },
    getOverDueActionsCount: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/OverdueActionsCount";
        const response = await get({ url });
        return response;
    },
    getOverdueActions: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/OverdueActions";
        const response = await get({
            url,
        });
        return response;
    },
    getOwnedActionsInInterval: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Action/OwnedFromInterval?from=${from}&to=${to}`;
        const response = await get({
            url,
            customError: customError ? customError : null,
        });
        return response;
    },
    getTotalOwnedActions: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/Owned";
        const response = await get({
            url,
            customError: customError,
        });
        return response;
    },
    getOwnedActionsCounts: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Action/OwnedActionsCounts?from=${from}&to=${to}`;
        const response = await get({ url, retryCount: 3, customError });
        return response;
    },
    getOwnedActionsSinceLastLogin: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = "/v1/Action/SinceLastLogin";
        const response = await get({
            url,
            customError: customError,
        });
        return response;
    },
    getOwnersForFilter: async (
        from: string,
        to: string,
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/Action/OwnerFilter`;
        let response = await post({
            url,
            payload: { from: from, to: to },
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getActionByToken: async (token: string, { customError }: any) => {
        const url = `/v1/Action/GetActionByToken?token=${token}`;
        return await get({ url, customError });
    },
};

export default Action;
