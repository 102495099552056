import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Provider from "services/api";

const useContactUserTypes = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Contact.getContactUserTypes({
                customError: {
                    message: "Could not retrieve list of user types",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            const translatedData = data.map((d: any) => ({ ...d, typeName: t(d.typeName) }));
            setState({ loading: false, data: translatedData });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useContactUserTypes };
