import React, { FunctionComponent } from "react";
import classes from "./styles/shared.module.scss";
import { IUnknownDetail } from "models";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: IUnknownDetail;
};

const UnknownNotification: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return <div className={classes.notificationTitle}>{t("notification_Unknown")}</div>;
};

export { UnknownNotification };
