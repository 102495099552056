import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Spin, Menu, Row, Col } from "antd";
import { CascadeSelect } from "components/utilitycomponents";
import PerformanceOverview from "./performanceOverview/performanceOverview";
import MasterData from "./masterData/masterData";
import StrategyAndGovernance from "./strategyAndGovernanve/strategyAndGovernance";
import Risk from "./risk/risk";
import {
    useSupplierCardsPartner,
    usePartnerList,
    useEnabledSupplierProfilePages,
    useRiskReport,
    useProductFeature,
    useScorecardFeature,
} from "controller";
import classes from "./styles/supplierProfile.module.scss";
import Dashboard from "components/contentcomponents/analytics/dashboard/dashboard";
import { Governance, Segmentation, Summary } from "./enterprisePages";
import ComplianceOverview from "components/contentcomponents/compliance/overview/supplierProfileOverview";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Performance } from "./enterprisePages/performance/performance";
import { getPageKeyByPageName } from "utilities/helpers/getPageKeyByPageName";
import { ActionOverviewContainer } from "components/contentcomponents/action/overview/actionOverviewContainer";
import { Environment, ISupplierProfilePage } from "models";
import SubMenu from "antd/lib/menu/SubMenu";
import { getEnvironmentInfo } from "utilities";
import { RiskComponent } from "./enterprisePages/risk/RiskComponent";
import { SupplierProfileProductOverview } from "components/contentcomponents/product/overview/supplierProfileOverview";
import { CaretRightOutlined } from "@ant-design/icons";
import { ScorecardPage } from "./scorecard";
const TabPane = Tabs.TabPane;

interface IProps {
    propsPartnerId: number;
}

const SupplierProfile = ({ propsPartnerId }: IProps) => {
    const { t } = useTranslation();
    const [partnerId, setPartnerId] = useState<number>(propsPartnerId);
    const { tab }: any = useParams();
    const user = useSelector((state: AppState) => state.oidc.user);
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const [reload, setReload] = useState({ reload: false });
    const { loading, data } = useSupplierCardsPartner(partnerId, reload);
    const [closeDashboardModals, setCloseDashboardModals] = useState<any>({ close: false });
    const [reloadRelationships, setReloadRelationships] = useState<any>({ reload: false });
    const { data: enabledPages, loading: loadingPages } = useEnabledSupplierProfilePages();
    const [activeTab, setActiveTab] = useState(
        tab ? tab : enabledPages.length > 0 && enabledPages[0].pageName
    );
    const [openKeys, setOpenKeys] = useState([activeTab]);
    const [summaryActiveTab, setSummaryActiveTab] = useState("summary");
    const [governaceActiveTab, setGovernanceActiveTab] = useState("governancePlaybook");
    const [actionsActiveTab, setActionsActiveTab] = useState("0");
    const [riskActiveTab, setRiskActiveTab] = useState("0");
    const { data: riskReports } = useRiskReport(reload);
    const currentCompanyId = user.profile["ll-CompanyId"];
    const { hasProductFeature } = useProductFeature();
    const hasProductAccess = user.profile["ll-Product"] === "true";
    const { hasScorecardFeature } = useScorecardFeature();
    const [selectedPartnerIds, setSelectedPartnerIds] = useState<number[]>([partnerId]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        setPartnerId(propsPartnerId);
    }, [propsPartnerId]);
    useEffect(() => {
        if (enabledPages.length > 0 && tab === undefined) {
            let firstTab = enabledPages[0].pageName;
            switch (firstTab) {
                case "SummaryPage":
                    setOpenKeys(["1"]);
                    setSelectedKeys(["summaryPage1"]);
                    break;
                case "Governance":
                    setOpenKeys(["4"]);
                    setSelectedKeys(["governance1"]);
                    break;
                case "Risk":
                    setOpenKeys(["6"]);
                    setSelectedKeys(["risk1"]);
                    break;
                case "Actions":
                    setOpenKeys(["13"]);
                    setSelectedKeys(["actions1"]);
                    break;
                default:
                    setSelectedKeys([enabledPages[0].id.toString()!]);
            }
        }
    }, [enabledPages]);

    useEffect(() => {
        if (tab === "0" && enabledPages.length > 0) {
            let firstTab = getPageKeyByPageName(enabledPages[0].pageName);
            setActiveTab(firstTab);
            setSelectedKeys([tab]);
        } else if (tab === "4") {
            setSelectedKeys(["governance4"]);
            setGovernanceActiveTab("meetingMinutes");
            setOpenKeys(["4"]);
        } else {
            setActiveTab(tab);
            setSelectedKeys([tab]);
        }
    }, [tab, enabledPages]);

    function onTabChange(tab: any) {
        if (tab !== "11") {
            setCloseDashboardModals({ close: true });
        }
        setActiveTab(tab);
    }

    const handleOnMenuTitleClick = (key: any) => {
        let newOpenKeys = openKeys.slice(0);
        if (newOpenKeys.length > 0 && newOpenKeys.filter((x: any) => x !== key).length > 0) {
            setOpenKeys([key]);
        }
        if (newOpenKeys.filter((x: string) => x === key).length > 0) {
            setOpenKeys([]);
        } else {
            setOpenKeys([key]);
        }
    };

    if (loadingPages) {
        return (
            <div className="callbackSpinner">
                <Spin size="large" />
            </div>
        );
    }

    const renderProfileTabs = () => {
        let tabs = enabledPages.map((page: ISupplierProfilePage) => {
            switch (page.pageName) {
                case "SummaryPage":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            destroyInactiveTabPane
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_Summary")}
                                </div>
                            }
                            key={getPageKeyByPageName("SummaryPage")}>
                            <div className={classes.tabContainer}>
                                <Summary
                                    partnerId={partnerId}
                                    clickedTab={summaryActiveTab}
                                    onAssignRelationClick={() => {
                                        setReloadRelationships({
                                            activeTab: "relationships",
                                        });
                                        setGovernanceActiveTab("relationships");
                                        onTabChange("4");
                                    }}
                                />
                            </div>
                        </TabPane>
                    );
                case "SegmentationManagement":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_Segmentation")}
                                </div>
                            }
                            key={getPageKeyByPageName("SegmentationManagement")}>
                            <div className={classes.tabContainer}>
                                <Segmentation partnerId={partnerId} />
                            </div>
                        </TabPane>
                    );
                case "PerformanceManagement":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_Perfomance")}
                                </div>
                            }
                            key={getPageKeyByPageName("PerformanceManagement")}>
                            <div className={classes.tabContainer}>
                                <Performance partnerId={partnerId} />
                            </div>
                        </TabPane>
                    );
                case "Governance":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_governance")}
                                </div>
                            }
                            key={getPageKeyByPageName("Governance")}>
                            <div className={classes.tabContainer}>
                                <Governance
                                    partnerId={partnerId}
                                    partnerSelectChange={reloadRelationships}
                                    clickedTab={governaceActiveTab}
                                />
                            </div>
                        </TabPane>
                    );
                case "Risk":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_Risk")}
                                </div>
                            }
                            key={getPageKeyByPageName("Risk")}>
                            <div className={classes.tabContainer}>
                                <RiskComponent
                                    partnerId={partnerId}
                                    clickedTab={riskActiveTab}
                                    riskReports={riskReports}
                                />
                            </div>
                        </TabPane>
                    );
                case "StrategyAndGovernance":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_StrategyTab")}
                                </div>
                            }
                            key={getPageKeyByPageName("StrategyAndGovernance")}>
                            {data && (
                                <div className={classes.tabContainer}>
                                    <StrategyAndGovernance data={data} />{" "}
                                </div>
                            )}
                        </TabPane>
                    );
                case "PerformanceOverview":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_PerformanceTab")}
                                </div>
                            }
                            key={getPageKeyByPageName("PerformanceOverview")}>
                            {data && (
                                <div className={classes.tabContainer}>
                                    <PerformanceOverview partner={data} reloadKpi={reload} />
                                </div>
                            )}
                        </TabPane>
                    );
                case "RiskOverview":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={<div className={classes.tab}>{t("supplierProfile_RiskTab")}</div>}
                            key={getPageKeyByPageName("RiskOverview")}>
                            {data && (
                                <div className={classes.tabContainer}>
                                    <Risk
                                        partnerId={partnerId}
                                        partnerName={data.partnerName}
                                        reloadPartners={reload}
                                    />
                                </div>
                            )}
                        </TabPane>
                    );
                case "MasterData":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_MasterDataTab")}
                                </div>
                            }
                            key={getPageKeyByPageName("MasterData")}>
                            <div style={{ marginBottom: "10px" }}>
                                {data && (
                                    <div className={classes.tabContainer}>
                                        <MasterData
                                            partnerId={partnerId}
                                            partnerName={data.partnerName}
                                            onReloadPartnerData={() => setReload({ reload: true })}
                                        />
                                    </div>
                                )}
                            </div>
                        </TabPane>
                    );
                case "Analytics":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>{t("supplierProfile_Analytics")}</div>
                            }
                            key={getPageKeyByPageName("Analytics")}>
                            <div style={{ marginBottom: "10px" }} className={classes.tabContainer}>
                                <Dashboard
                                    partnerId={partnerId}
                                    sharedDashboardView={false}
                                    closeDashboardModals={closeDashboardModals}
                                />
                            </div>
                        </TabPane>
                    );
                case "Compliance":
                    if (hasComplianceAccess)
                        return (
                            <TabPane
                                className={classes.containerPadding}
                                tab={
                                    <div className={classes.tab}>
                                        {t("supplierProfile_Compliance")}
                                    </div>
                                }
                                key={getPageKeyByPageName("Compliance")}>
                                <div
                                    style={{ minHeight: "400px" }}
                                    className={classes.tabContainer}>
                                    <ComplianceOverview partnerId={partnerId} />
                                </div>
                            </TabPane>
                        );
                    break;
                case "Actions":
                    return (
                        <TabPane
                            className={classes.containerPadding}
                            tab={
                                <div className={classes.tab}>
                                    {t("supplierProfile_Enterprise_Actions")}
                                </div>
                            }
                            key={getPageKeyByPageName("Actions")}>
                            <div style={{ minHeight: "400px" }} className={classes.tabContainer}>
                                <ActionOverviewContainer
                                    partnerId={partnerId}
                                    supplierProfileOverview={true}
                                    clickedTab={actionsActiveTab}
                                />
                            </div>
                        </TabPane>
                    );
                default:
                    return undefined;
            }
            return undefined;
        });

        hasScorecardFeature &&
            tabs.splice(
                1,
                0,
                <TabPane
                    className={classes.containerPadding}
                    tab={<div className={classes.tab}>{t("scorecard_Scorecard")}</div>}
                    key={getPageKeyByPageName("Scorecard")}>
                    <div style={{ minHeight: "400px" }} className={classes.tabContainer}>
                        <ScorecardPage partnerId={partnerId} partnerName={data?.partnerName} />
                    </div>
                </TabPane>
            );

        hasProductFeature &&
            hasProductAccess &&
            tabs.push(
                <TabPane
                    className={classes.containerPadding}
                    tab={<div className={classes.tab}>{t("product_Products")}</div>}
                    key={getPageKeyByPageName("Products")}>
                    <div style={{ minHeight: "400px" }} className={classes.tabContainer}>
                        <SupplierProfileProductOverview partnerId={partnerId} />
                    </div>
                </TabPane>
            );

        return tabs;
    };

    const renderSupplierProfileMenuItems = () => {
        let menuItems = enabledPages.map((page: ISupplierProfilePage) => {
            switch (page.pageName) {
                case "SummaryPage":
                    return (
                        <SubMenu
                            onTitleClick={(event: any) => {
                                handleOnMenuTitleClick(event.key);
                            }}
                            title={t("supplierProfile_Enterprise_Summary")}
                            className={classes.subMenu}
                            key={getPageKeyByPageName("SummaryPage")}>
                            <Menu.Item
                                key={"summaryPage1"}
                                className={classes.menuItem}
                                title={t("supplierprofile_summary_SummaryTab")}
                                onClick={() => setSummaryActiveTab("summary")}>
                                {t("supplierprofile_summary_SummaryTab")}
                            </Menu.Item>

                            {enabledPages.map((childPage: ISupplierProfilePage) => {
                                switch (childPage.pageName) {
                                    case "SpendAnalytics":
                                        return (
                                            <Menu.Item
                                                key={"summaryPage2"}
                                                className={classes.menuItem}
                                                title={t("supplierProfile_Summary_ReportTabTile")}
                                                onClick={() => setSummaryActiveTab("spendReport")}>
                                                {t("supplierProfile_Summary_ReportTabTile")}
                                            </Menu.Item>
                                        );
                                    case "NewsFeed":
                                        return (
                                            <Menu.Item
                                                key={"summaryPage4"}
                                                className={classes.menuItem}
                                                title={t("supplierProfile_NewsLogHeadline")}
                                                onClick={() => setSummaryActiveTab("newsLog")}>
                                                {t("supplierProfile_NewsLogHeadline")}
                                            </Menu.Item>
                                        );
                                    case "Sustainability":
                                        return (
                                            (getEnvironmentInfo().type === Environment.Local ||
                                                getEnvironmentInfo().type === Environment.Staging ||
                                                currentCompanyId === "4" ||
                                                currentCompanyId === "4439") && (
                                                <Menu.Item
                                                    key={"summaryPage5"}
                                                    className={classes.menuItem}
                                                    title={t("supplierProfile_Sustainability")}
                                                    onClick={() =>
                                                        setSummaryActiveTab("sustainability")
                                                    }>
                                                    {t("supplierProfile_Sustainability")}
                                                </Menu.Item>
                                            )
                                        );
                                    default:
                                        return undefined;
                                }
                            })}
                        </SubMenu>
                    );
                case "SegmentationManagement":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("SegmentationManagement")}
                            title={t("supplierProfile_Enterprise_Segmentation")}
                            className={classes.menuItem}>
                            {t("supplierProfile_Enterprise_Segmentation")}
                        </Menu.Item>
                    );
                case "PerformanceManagement":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("PerformanceManagement")}
                            title={t("supplierProfile_Enterprise_Perfomance")}
                            className={classes.menuItem}>
                            {t("supplierProfile_Enterprise_Perfomance")}
                        </Menu.Item>
                    );
                case "Governance":
                    return (
                        <SubMenu
                            onTitleClick={(event: any) => {
                                handleOnMenuTitleClick(event.key);
                            }}
                            title={t("supplierProfile_Enterprise_governance")}
                            className={classes.subMenu}
                            key={getPageKeyByPageName("Governance")}>
                            {enabledPages.map((childPage: ISupplierProfilePage) => {
                                switch (childPage.pageName) {
                                    case "Playbook":
                                        return (
                                            <Menu.Item
                                                key="governance1"
                                                title={t("governance_GovernancePlaybook")}
                                                className={classes.menuItem}
                                                onClick={() =>
                                                    setGovernanceActiveTab("governancePlaybook")
                                                }>
                                                {t("governance_GovernancePlaybook")}
                                            </Menu.Item>
                                        );
                                    case "Relationships":
                                        return (
                                            <Menu.Item
                                                key="governance2"
                                                title={t("governance_Relationships")}
                                                className={classes.menuItem}
                                                onClick={() =>
                                                    setGovernanceActiveTab("relationships")
                                                }>
                                                {t("governance_Relationships")}
                                            </Menu.Item>
                                        );
                                    case "MeetingForums":
                                        return (
                                            <Menu.Item
                                                key="governance3"
                                                title={t("governance_MeetingForums")}
                                                className={classes.menuItem}
                                                onClick={() =>
                                                    setGovernanceActiveTab("meetingForums")
                                                }>
                                                {t("governance_MeetingForums")}
                                            </Menu.Item>
                                        );
                                    case "MeetingMinutes":
                                        return (
                                            <Menu.Item
                                                key="governance4"
                                                title={t("governance_MeetingMinutes")}
                                                className={classes.menuItem}
                                                onClick={() =>
                                                    setGovernanceActiveTab("meetingMinutes")
                                                }>
                                                {t("governance_MeetingMinutes")}
                                            </Menu.Item>
                                        );
                                    default:
                                        return undefined;
                                }
                            })}
                        </SubMenu>
                    );
                case "Risk":
                    return (
                        <SubMenu
                            onTitleClick={(event: any) => {
                                handleOnMenuTitleClick(event.key);
                            }}
                            className={classes.subMenu}
                            title={t("supplierProfile_Enterprise_Risk")}
                            key={getPageKeyByPageName("Risk")}>
                            <Menu.Item
                                key={"risk1"}
                                title={t("supplierProfile_Enterprise_RiskSummary")}
                                className={classes.menuItem}
                                onClick={() => setRiskActiveTab("0")}>
                                {t("supplierProfile_Enterprise_RiskSummary")}
                            </Menu.Item>
                            {riskReports.map((report: any, index: number) => {
                                return (
                                    <Menu.Item
                                        key={`risk${index + 2}`}
                                        title={report.name}
                                        className={classes.menuItem}
                                        onClick={() => setRiskActiveTab((index + 1).toString())}>
                                        {report.name
                                            ? report.name
                                            : `${t(
                                                  "supplierProfile_Enterprise_RiskReport"
                                              )} ${index + 1}`}
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                case "StrategyAndGovernance":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("StrategyAndGovernance")}
                            title={t("supplierProfile_StrategyTab")}
                            className={classes.menuItem}>
                            {t("supplierProfile_StrategyTab")}
                        </Menu.Item>
                    );
                case "PerformanceOverview":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("PerformanceOverview")}
                            title={t("supplierProfile_PerformanceTab")}
                            className={classes.menuItem}>
                            {t("supplierProfile_PerformanceTab")}
                        </Menu.Item>
                    );
                case "RiskOverview":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("RiskOverview")}
                            title={t("supplierProfile_RiskTab")}
                            className={classes.menuItem}>
                            {t("supplierProfile_RiskTab")}
                        </Menu.Item>
                    );
                case "MasterData":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("MasterData")}
                            title={t("supplierProfile_MasterDataTab")}
                            className={classes.menuItem}>
                            {t("supplierProfile_MasterDataTab")}
                        </Menu.Item>
                    );
                case "Analytics":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("Analytics")}
                            title={t("supplierProfile_Analytics")}
                            className={classes.menuItem}>
                            {t("supplierProfile_Analytics")}
                        </Menu.Item>
                    );
                case "Compliance":
                    return (
                        <Menu.Item
                            key={getPageKeyByPageName("Compliance")}
                            title={t("supplierProfile_Compliance")}
                            className={classes.menuItem}>
                            {t("supplierProfile_Compliance")}
                        </Menu.Item>
                    );
                case "Actions":
                    return (
                        <SubMenu
                            onTitleClick={(event: any) => {
                                handleOnMenuTitleClick(event.key);
                            }}
                            title={t("supplierProfile_Enterprise_Actions")}
                            className={classes.subMenu}
                            key={getPageKeyByPageName("Actions")}>
                            <Menu.Item
                                key="actions1"
                                className={classes.menuItem}
                                title={t("actions_Actions") + " " + t("actions_Overview")}
                                onClick={() => setActionsActiveTab("0")}>
                                {t("actions_Actions")} {t("actions_Overview")}
                            </Menu.Item>
                            <Menu.Item
                                key="actions2"
                                className={classes.menuItem}
                                title={t("developmentPlans_Overview")}
                                onClick={() => setActionsActiveTab("1")}>
                                {t("developmentPlans_Overview")}
                            </Menu.Item>
                        </SubMenu>
                    );
                default:
                    return undefined;
            }
        });

        hasScorecardFeature &&
            menuItems.splice(
                1,
                0,
                <Menu.Item
                    key={getPageKeyByPageName("Scorecard")}
                    title={t("scorecard_Scorecard")}
                    className={classes.menuItem}>
                    {t("scorecard_Scorecard")}
                </Menu.Item>
            );

        hasProductFeature &&
            hasProductAccess &&
            menuItems.push(
                <Menu.Item
                    key={getPageKeyByPageName("Products")}
                    title={t("product_Products")}
                    className={classes.menuItem}>
                    {t("product_Products")}
                </Menu.Item>
            );
        return menuItems;
    };

    return (
        <div id="supplier-profile-pendo-tag">
            <Row>
                <Col span={12}>
                    <CascadeSelect
                        changeOnSelect
                        expandTrigger="hover"
                        className={classes.menuSupplierSelect}
                        onChange={(ids: number[]) => {
                            // history.push(`/Partners/SupplierProfile/${tab}/${ids[ids.length - 1]}`);
                            //due to complications with rerendering the relationsmatrix table when switching supplier we will reload the page.
                            if (tab === "4") {
                                setReloadRelationships({ reload: true });
                            }
                            setSelectedPartnerIds(ids);
                            setPartnerId(ids[ids.length - 1]);
                        }}
                        value={selectedPartnerIds}
                        useData={usePartnerList}
                        selectOptionField="name"
                        selectIdField="id"
                        selectLabelSuffix="number"
                        allowClear={false}
                        size="large"
                        placement="bottomLeft"
                        expandIcon={<CaretRightOutlined />}
                    />
                </Col>
            </Row>
            <Spin spinning={loading}>
                <Tabs
                    destroyInactiveTabPane
                    className={classes.verticalTabs}
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    size="small"
                    type="card"
                    tabPosition="left"
                    renderTabBar={() => (
                        <div className={classes.tabBar}>
                            <Row>
                                <Col span={24}>
                                    <Menu
                                        activeKey={activeTab}
                                        selectedKeys={selectedKeys}
                                        mode="inline"
                                        openKeys={openKeys}
                                        className={classes.menu}
                                        onClick={({ item, key, keyPath, domEvent }: any) => {
                                            let parsedKey = key;
                                            switch (key) {
                                                case "summaryPage1":
                                                case "summaryPage2":
                                                case "summaryPage4":
                                                case "summaryPage5":
                                                    parsedKey = getPageKeyByPageName("SummaryPage");
                                                    break;
                                                case "governance1":
                                                case "governance2":
                                                case "governance3":
                                                case "governance4":
                                                    parsedKey = getPageKeyByPageName("Governance");
                                                    break;
                                                case "actions1":
                                                case "actions2":
                                                    parsedKey = getPageKeyByPageName("Actions");
                                                    break;
                                                case "risk1":
                                                case "risk2":
                                                case "risk3":
                                                case "risk4":
                                                case "risk5":
                                                    parsedKey = getPageKeyByPageName("Risk");
                                                    break;
                                                default:
                                                    break;
                                            }
                                            onTabChange(parsedKey);
                                            setSelectedKeys([key]);
                                        }}
                                        inlineIndent={22}>
                                        {renderSupplierProfileMenuItems()}
                                    </Menu>
                                </Col>
                            </Row>
                        </div>
                    )}>
                    {!loadingPages && renderProfileTabs()}
                </Tabs>
            </Spin>
        </div>
    );
};

export default SupplierProfile;
