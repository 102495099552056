import { useEffect, useState } from "react";
import Provider from "services/api";

type StateType = {
    data: any[];
    loading: boolean;
};

const useSelfAssessmentRequestContacts = (
    partnerId: number,
    complianceTemplateId: number,
    reload?: any
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.SelfAssessment.getSelfAssessmentRequestContacts(
                partnerId,
                complianceTemplateId
            ).catch(err => {
                setState({ data: [], loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, complianceTemplateId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSelfAssessmentRequestContacts };
