function groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | { (obj: T): string }
): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
        const value = keyFn(obj);
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {} as Record<string, T[]>);
}
export { groupBy };
