import React from "react";
import { useTranslation } from "react-i18next";
import { IOwlerCompanyInfo, IOwlerCompetitor, IOwlerNewsFeed } from "models";

interface IProps {
    onDomainChange: Function;
    domain: string | undefined;
    data: null | undefined | IOwlerCompanyInfo | IOwlerNewsFeed[] | IOwlerCompetitor[];
}

const OwlerDomainInput = ({ onDomainChange, domain, data }: IProps) => {
    const { t } = useTranslation();

    return (
        <div>
            {domain && !data ? <p>{t("owler_NoData")}</p> : <p>{t("owler_DomainInputText")}</p>}

            {/* <SupplierDomain
                propsDomain={domain}
                onDomainChange={(domain: string | undefined) => onDomainChange(domain)}
            /> */}
        </div>
    );
};

export { OwlerDomainInput };
