import React, { useEffect, useState } from "react";
import { GridLight } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import classes from "./../../summary.module.scss";
import { EditModal } from "../../../governance/meetingCalendar/editModal";
import { ShareModal } from "../../../governance/meetingCalendar/shareModal";
import { useMeetingMinutes } from "controller";
import { CreateModal } from "components/contentcomponents/suppliers/supplierProfile/enterprisePages/governance/meetingCalendar/createModal";
import { Button, Radio } from "antd";
import dayjs from "utilities/daysJsConfig";

interface IProps {
    onMeetingCreated: Function;
    reloadTable: any;
    onSwitchClick: Function;
    partnerId: number;
}

const UpcomingMeetings = ({ onMeetingCreated, reloadTable, onSwitchClick, partnerId }: IProps) => {
    const { t } = useTranslation();
    const [shareModal, setShareModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const [editModal, setEditModal] = useState<any>({
        visible: false,
        id: undefined,
    });
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading } = useMeetingMinutes(reload, partnerId);
    const [createModal, setCreateModal] = useState<boolean>(false);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadTable]);

    const columns: any[] = [
        {
            title: <div className={classes.colTitle}>{t("governance_meetingDate")}</div>,
            dataIndex: "date",
            key: "date",
            width: 100,
            render: (val: string) => <div>{val && dayjs(val).format("MMM D, YYYY")}</div>,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingType")}</div>,
            dataIndex: "type",
            key: "type",
            ellipsis: true,
            width: 100,
        },
        {
            title: <div className={classes.colTitle}>{t("governance_meetingTitle")}</div>,
            dataIndex: "title",
            ellipsis: true,
            key: "title",
            width: 50,
        },
    ];

    const handleOnRow = (row: any) => ({
        onClick: () => {
            setEditModal({ visible: true, id: row.id });
        },
        style: {
            cursor: "pointer",
        },
    });

    return (
        <>
            <GridLight
                title={() => (
                    <div>
                        <div className={classes.tableTitle}>
                            {t("governance_Meetings").toLocaleUpperCase()}
                        </div>
                        <div className={classes.tableTitleSwitch}>
                            <Radio.Group
                                options={[
                                    { label: "Upcoming", value: "upcoming" },
                                    { label: "Past", value: "past" },
                                ]}
                                onChange={() => onSwitchClick()}
                                value={"upcoming"}
                                optionType="button"
                                size="small"
                            />
                        </div>
                        {data.upComingMeetings.length !== 0 && (
                            <div className={classes.tableTitleAddBtn}>
                                <Button
                                    style={{ marginRight: -10 }}
                                    type="link"
                                    onClick={() => setCreateModal(true)}>
                                    {t("generic_Add")}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                className={classes.customTableStyle}
                dataSource={data.upComingMeetings.slice(0, 5)}
                onRow={handleOnRow}
                columns={columns}
                tableSize="small"
                pagination="none"
                loading={loading}
                bordered={false}
                showHeader={false}
                renderTableOnDataChange
                emptyText={
                    <Button type="primary" onClick={() => setCreateModal(true)}>
                        Add Meeting
                    </Button>
                }
            />
            <CreateModal
                visible={createModal}
                onClose={(reload: boolean) => {
                    if (reload) {
                        setReload({ reload: true });
                        onMeetingCreated();
                    }
                    setCreateModal(false);
                }}
                partnerId={partnerId}
                centered
            />
            <EditModal
                partnerId={partnerId}
                visible={editModal.visible}
                meetingId={editModal.id}
                onClose={() => setEditModal({ visible: false, id: undefined })}
                onActionInteraction={() => setReload({ reload: true })}
                centered
            />
            <ShareModal
                visible={shareModal.visible}
                onClose={() => setShareModal({ visible: false, id: undefined })}
                meetingId={shareModal.id}
                supplierId={partnerId}
            />
        </>
    );
};

export { UpcomingMeetings };
