import React, { useState, FunctionComponent } from "react";
import { Modal, Row, Col, Select, Tag, Alert, notification, Button } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./styles/visibilityModal.module.scss";
import { useNcrChangeVisibility } from "controller";
const Option = Select.Option;

interface IProps {
    visible: boolean;
    onVisibleChange: Function;
    reloadTable: Function;
    selectedRowKeys: any[];
}

const VisibilityModal: FunctionComponent<IProps> = ({
    visible,
    onVisibleChange,
    reloadTable,
    selectedRowKeys,
}) => {
    const { t } = useTranslation();
    const [selectedVisibility, setSelectedVisibility] = useState(undefined);
    const { loading, ChangeVisibility } = useNcrChangeVisibility();
    function onClose() {
        onVisibleChange(false);
    }

    function renderTags() {
        return selectedRowKeys.map((row: any) => {
            return (
                <Tag color="blue" key={row}>
                    {row}
                </Tag>
            );
        });
    }

    async function onSubmit() {
        await ChangeVisibility(selectedRowKeys, selectedVisibility);
        reloadTable();
        onVisibleChange(false);
        setSelectedVisibility(undefined);
        notification.success({
            message: t("success"),
            description: t("ncr_UpdateVisibilitySuccess"),
        });
    }

    return (
        <Modal
            wrapProps={{ "data-test": "change_visibility_modal" }}
            title={t("ncr_ChangeVisibility")}
            open={visible}
            onCancel={() => onClose()}
            footer={[
                <Button
                    disabled={selectedVisibility === undefined}
                    loading={loading}
                    type="primary"
                    onClick={async () => await onSubmit()}>
                    {t("generic_Save")}
                </Button>,
            ]}
            onOk={() => onSubmit()}>
            <div>
                <div className={classes.tagAreaContainer}>
                    <div className={classes.tagLabel}>{t("selectedNcrs") + " :"}</div>
                    <div className={classes.tagArea}>{renderTags()}</div>
                </div>
                <Row>
                    <Col span={24}>{t("ncr_ChangeVisibilityInfo")}</Col>
                </Row>
                <Row>
                    <Col className={classes.labelCol} span={4}>
                        {t("review_Visibility")}
                    </Col>
                    <Col span={20}>
                        <Select
                            className={classes.select}
                            value={selectedVisibility}
                            style={{ width: "100%" }}
                            placeholder={t("ncr_SelectVisibility")}
                            onChange={(value: any) => setSelectedVisibility(value)}
                            getPopupContainer={(node: any) =>
                                node ? (node.parentNode as HTMLElement) : document.body
                            }>
                            <Option key={0} value={1}>
                                {t("internal")}
                            </Option>
                            <Option key={1} value={2}>
                                {t("external")}
                            </Option>
                        </Select>
                    </Col>
                </Row>
                <Alert
                    message=""
                    type={"info"}
                    description={<div>{t("review_ChangeVisibilityEmailInfo")}</div>}
                />
            </div>
        </Modal>
    );
};

export default VisibilityModal;
