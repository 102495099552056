import { useState } from "react";
import Provider from "services/api";

const useShortcutsCrud = () => {
    const [creatingSection, setCreatingSection] = useState(false);

    const createSection = async (sectionInput: any) => {
        setCreatingSection(true);
        const id = await Provider.Company.createSection(sectionInput, {
            customError: {
                message: "Could not create new section",
            },
        }).catch(err => {
            setCreatingSection(false);
            return Promise.reject(err);
        });
        setCreatingSection(false);
        return id;
    };

    const editSection = async (sectionInput: any) => {
        await Provider.Company.editSection(sectionInput, {
            customError: {
                message: "Could not edit section",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    const updateSectionOrders = async (sections: any[]) => {
        await Provider.Company.editSectionOrders(sections, {
            customError: {
                message: "Could not edit sections",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    const removeSection = async (id: any) => {
        await Provider.Company.deleteSection(id, {
            customError: {
                message: "Could not delete section",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    const createShortcut = async (shortcutInput: any) => {
        setCreatingSection(true);
        const id = await Provider.Company.createShortcut(shortcutInput, {
            customError: {
                message: "Could not create new section",
            },
        }).catch(err => {
            setCreatingSection(false);
            return Promise.reject(err);
        });
        setCreatingSection(false);
        return id;
    };

    const editShortcut = async (shortcutInput: any) => {
        await Provider.Company.editShortcut(shortcutInput, {
            customError: {
                message: "Could not edit shortcut",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    const updateShortcutOrders = async (shortcuts: any[]) => {
        await Provider.Company.editShortcutOrders(shortcuts, {
            customError: {
                message: "Could not edit shortcuts",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    const removeShortcut = async (id: any) => {
        await Provider.Company.deleteShortcut(id, {
            customError: {
                message: "Could not delete shortcut",
            },
        }).catch(err => {
            return Promise.reject(err);
        });
    };

    return {
        creating: creatingSection,
        editSection: editSection,
        updateSectionOrders: updateSectionOrders,
        createSection: createSection,
        deleteSection: removeSection,
        createShortcut: createShortcut,
        editShortcut,
        updateShortcutOrders,
        removeShortcut,
    };
};

export { useShortcutsCrud };
