import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useForumType = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    const setForumName = (name: string) => {
        switch (name) {
            case "Strategic":
                return `${name} - ${t("meetingForum_StrategicDescription")}`;
            case "Tactical":
                return `${name} - ${t("meetingForum_TacticalDescription")}`;
            case "Operational":
                return `${name} - ${t("meetingForum_OperationalDescription")}`;
            default:
                return name;
        }
    };

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any[] = await Provider.Governance.getForumTypes({
                customError: {
                    message: "Could not retrieve forum types",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            data = data.map((forum: any) => ({ ...forum, name: setForumName(forum.name) }));

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useForumType };
