import { Dayjs } from "dayjs";

function aggregateDateRanges(dates: Dayjs[]) {
    return dates.reduce(
        (acc: string, date: Dayjs) => `${acc}${acc !== "" ? "," : ""}${date.toISOString()}`,
        ""
    );
}

export { aggregateDateRanges };
