import { useState } from "react";
import { Provider } from "services";

const useSupplierCardsPartnerUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updatePartnerInfo = async (values: any) => {
        setLoading(true);
        await Provider.Partner.updateSupplierCardsPartner(values, {
            customError: {
                message: "Could not update supplier information",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updateMeeting = async ({ partnerId, meetingTypeId, frequency, attendees }: any) => {
        setLoading(true);
        await Provider.Partner.updateSupplierCardsMeeting(
            { partnerId, meetingTypeId, frequency, attendees },
            {
                customError: {
                    message: "Could not update the meeting",
                },
            }
        ).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updateRole = async (values: any) => {
        setLoading(true);
        await Provider.Partner.updateSupplierCardsRole(values, {
            customError: {
                message: "Could not update relation",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        updatePartnerInfo,
        updateMeeting,
        updateRole,
    };
};

export { useSupplierCardsPartnerUpdate };
