import React, { FunctionComponent, useState, useEffect } from "react";
import defaultImage from "./defaultImage.png";
import { useFileDownload } from "controller";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type PropsType = {
    fileId?: number | undefined;
    alt: string;
    [key: string]: any;
};

const DownloadImage: FunctionComponent<PropsType> = ({ alt, fileId, ...rest }) => {
    const { profileImageLoading, downloadProfileImage } = useFileDownload();
    const [downloadUrl, setDownloadUrl] = useState<any>();

    useEffect(() => {
        (async () => {
            if (fileId) {
                const data = await downloadProfileImage(fileId).catch(err => {
                    setDownloadUrl(defaultImage);
                    return Promise.reject(err);
                });
                setDownloadUrl(window.URL.createObjectURL(new Blob([data])));
            } else {
                setDownloadUrl(defaultImage);
            }
        })();
    }, [fileId]);

    return (
        <Spin
            spinning={profileImageLoading}
            indicator={<LoadingOutlined spin style={{ fontSize: 18 }} />}>
            <img {...rest} src={downloadUrl} alt={alt} />
        </Spin>
    );
};

export { DownloadImage };
