import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const usePartnerReviewAverage = (
    trigger: any,
    partnerId: any,
    from: any,
    to: any,
    reviewCriteriaId: any,
    includeReviews: boolean = false
) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            if (trigger && trigger.trigger === true) {
                setState({ ...state, loading: true });
                let data: any = await Provider.Review.getPartnerReviewAverage(
                    partnerId,
                    from,
                    to,
                    reviewCriteriaId,
                    includeReviews
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data: data });
            }
        })();
    }, [partnerId, trigger]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerReviewAverage };
