import React, { forwardRef, useState, useEffect } from "react";
import { Tree, Spin, Popover, Button } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAvailableSharedDashboards } from "controller";

type PropsType = {
    value?: string;
    onChange?: (value: string | string[], item?: any) => void;
    [key: string]: any;
};

const SharedDashboardTreeSelect = forwardRef(({ value, onChange, ...rest }: PropsType, ref) => {
    const { presetId: paramDashboardId }: any = useParams();
    const { TreeNode } = Tree;

    const { data, loading } = useAvailableSharedDashboards();

    const [expandedKeys, setExpandedKeys] = useState<string[]>(getDefaultExpandedKeys());
    const [selectedKeys, setSelectedKeys] = useState(
        paramDashboardId ? [paramDashboardId] : undefined
    );

    const { t } = useTranslation();

    useEffect(() => {
        setExpandedKeys(getDefaultExpandedKeys());
    }, [paramDashboardId, loading]);

    function getDefaultExpandedKeys() {
        if (!loading && paramDashboardId !== undefined) {
            let presetElement = data.filter(
                (x: any) =>
                    x.presets.filter((y: any) => y.presetId.toString() === paramDashboardId)[0]
            )[0];
            return [`0-${presetElement.companyName}`];
        }
        return [];
    }

    function onExpand(expandedKeys: any) {
        setExpandedKeys(expandedKeys);
    }

    function onSelect(selectedKeys: any, { node }: any) {
        if (node.props.isLeaf) {
            setSelectedKeys(selectedKeys);
            onChange &&
                onChange(
                    selectedKeys,
                    data.find(
                        (x: any) =>
                            x.presets.filter(
                                (y: any) => y.presetId.toString() === selectedKeys[0].toString()
                            )[0]
                    ).presets[0]
                );
        } else {
            setExpandedKeys(
                node.props.expanded
                    ? expandedKeys!.filter((k: any) => k !== node.props.eventKey)
                    : expandedKeys!.concat(node.props.eventKey)
            );
        }
    }

    function sortDashboard(a: any, b: any) {
        if (a && b) {
            if (a.title > b.title) {
                return 1;
            } else {
                return -1;
            }
        } else {
            return 1;
        }
    }

    function renderTreeNodes() {
        let treeNodes: any[] = data.map((x: any) => {
            return (
                <TreeNode
                    key={`0-${x.companyName}`}
                    isLeaf={false}
                    title={t("analytics_Shared_SharedBy", {
                        companyName: x.companyName,
                        interpolation: { escapeValue: false },
                    })}>
                    {x.presets
                        .sort((a: any, b: any) => sortDashboard(a, b))
                        .map((preset: any, index: number) => {
                            return (
                                <TreeNode
                                    key={`${preset.presetId}`}
                                    selectable={
                                        paramDashboardId &&
                                        preset.presetId.toString() !== paramDashboardId.toString()
                                    }
                                    title={preset.title}
                                    isLeaf></TreeNode>
                            );
                        })}
                </TreeNode>
            );
        });

        return treeNodes;
    }

    const content = (
        <Spin spinning={loading}>
            <Tree
                expandedKeys={expandedKeys}
                selectedKeys={selectedKeys}
                {...rest}
                onSelect={(selectedKeys: any, info: any) => {
                    onSelect(selectedKeys, info);
                }}
                onExpand={(expandedKeys: any) => onExpand(expandedKeys)}>
                {renderTreeNodes()}
            </Tree>
        </Spin>
    );

    return (
        <Popover
            placement="bottom"
            title={t("analytics_Shared_SharedDashboard")}
            trigger="hover"
            content={content}>
            <Button>{t("analytics_Shared_SharedDashboard")}</Button>
        </Popover>
    );
});

export { SharedDashboardTreeSelect };
