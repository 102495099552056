import { useState } from "react";
import { Provider } from "services";

const useActivityMessageCreate = () => {
    const [loading, setLoading] = useState(false);

    const create = async (message: any) => {
        setLoading(true);
        const activity = await Provider.Activity.createMessage(message);
        setLoading(false);
        return activity;
    };

    return {
        loading,
        create,
    };
};

export { useActivityMessageCreate };
