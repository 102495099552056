import React, { Fragment } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { User } from "oidc-client";
import { AppState } from "../../../store";
import PartnerTable from "./partnerTable";
import { Button, Tooltip } from "antd";
import ModalGroup from "./masterdataOverviewModals/modal-group";
import ModalMerge from "./masterdataOverviewModals/modal-merge";
import ModalAddToMergeSet from "./masterdataOverviewModals/modal-addToMergeSet";
import ModalUnMerge from "./masterdataOverviewModals/modal-unmerge";
import ModalOwner from "./masterdataOverviewModals/modal-owner";
import CategoryOneModal from "./masterdataOverviewModals/modal-category1";
import CategoryTwoModal from "./masterdataOverviewModals/modal-category2";
import IPartnerGroup from "../../../models/suppliers/IPartnerGroup";
import EditPartnerModal from "./masterdataOverviewModals/modal-editPartner";
import ModalDelete from "./masterdataOverviewModals/modal-delete";
import ModalSwitchOwner from "./masterdataOverviewModals/modal-switchOwner";
import IMasterDataPartner from "../../../models/suppliers/IMasterDataPartner";
import ICustomColumn from "../../../models/suppliers/customcolumns/ICustomColumn";
import CreateGroupModal from "./masterdataOverviewModals/createGroupModal";
import IHeadlineSetting from "../../../models/suppliers/IHeadlineSetting";
import "./supplierTable.css";
import ICompanySettings from "../../../models/suppliers/ICompanySettings";
import ModalStatusLog from "./statuslogmodal/modal-statusLog";
import { WithTranslation, withTranslation } from "react-i18next";
import CreateCategoryModal from "./masterdataOverviewModals/createCategoryModal";
import { categoryType, CompanyType } from "models";
import CreateExternalPartnerModal from "components/utilitycomponents/select/selectExternalPartner/createExternalPartnerModal";
import {
    Link01,
    Pin02,
    PlusCircle,
    Reflect02,
    Trash02,
    User01,
} from "components/utilitycomponents";
interface IMasterDataOverviewState {
    selectedRows: IMasterDataPartner[];
    selectedRowKeys: string[];
    showModals: {
        showGroupModal: boolean;
        showMergeModal: boolean;
        showOwnerModal: boolean;
        showCategoryOneModal: boolean;
        showCategoryTwoModal: boolean;
        showEditPartnerModal: boolean;
        showCreateModal: boolean;
        showDeleteModal: boolean;
        showUnmergeModal: boolean;
        showCreateGroupModal: boolean;
        showCreateCategoryOneModal: boolean;
        showCreateCategoryTwoModal: boolean;
        showSwitchOwnerModal: boolean;
        showStatusLogModal: boolean;
        showAddToMergeSetModal: boolean;
    };
    groupsInUse: IPartnerGroup[];
    ownersInUse: { key: string | number; label: string }[];
    selectedPartnerGroups: { key: string | number; label: string }[];
    currentRow: IMasterDataPartner | undefined;
    customColumns: ICustomColumn[];
    customHeadlines: IHeadlineSetting;
    companySettings: ICompanySettings;
    newCreatedCategory1: { key: string | number; label: string }[];
    newCreatedCategory2: { key: string | number; label: string }[];
    newCreatedGroup: { key: string | number; label: string }[];
    MergetSetParent: IMasterDataPartner | undefined;
    reloadTable: any;
    filters: any;
    currentPage: any;
}

interface IMasterDateOverviewProps extends WithTranslation {
    user: User;
}

class MasterDataOverview extends React.Component<
    IMasterDateOverviewProps,
    IMasterDataOverviewState
> {
    constructor(props: IMasterDateOverviewProps) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRows: [],
            showModals: {
                showCategoryOneModal: false,
                showCategoryTwoModal: false,
                showGroupModal: false,
                showMergeModal: false,
                showOwnerModal: false,
                showEditPartnerModal: false,
                showCreateModal: false,
                showDeleteModal: false,
                showUnmergeModal: false,
                showCreateGroupModal: false,
                showCreateCategoryOneModal: false,
                showCreateCategoryTwoModal: false,
                showSwitchOwnerModal: false,
                showStatusLogModal: false,
                showAddToMergeSetModal: false,
            },
            groupsInUse: [],
            ownersInUse: [],
            selectedPartnerGroups: [],
            currentRow: undefined,
            customColumns: [],
            customHeadlines: {},
            companySettings: {
                companyId: -1,
                allowComplianceRequesterToReceiveEmailWhenStatusChanged: false,
                allowOnlyOwnersToEditPartnerData: false,
                allowUsersMakeThemSelvesAsOwner: false,
                allowUsersOnCreateSupplier: false,
                autoGenerateCompanyNumbers: false,
                showComplianceUrlIconOnMatrixOverview: false,
                showOwnUploadedPartnerName: false,
                allowNormalUsersToSwitchOwner: false,
                canUsersCreateSupplier: false,
            },
            newCreatedCategory1: [],
            newCreatedCategory2: [],
            newCreatedGroup: [],
            MergetSetParent: undefined,
            reloadTable: {},
            filters: [],
            currentPage: 1,
        };
    }

    async fetchData() {
        this.setState({
            reloadTable: { changed: true },
        });
    }

    updateSelectedRows(tagName: any, tagKey: any) {
        const newSelectedRows = [
            ...this.state.selectedRows.filter((row: IMasterDataPartner) => {
                return row.id.value.toString() !== tagKey.value.toString();
            }),
        ];
        const newSelectedRowsKeys = [
            ...this.state.selectedRowKeys.filter(
                (key: any) => key.toString() !== tagKey.value.toString()
            ),
        ];
        this.setState({
            selectedRowKeys: newSelectedRowsKeys,
            selectedRows: newSelectedRows,
        });
    }

    selectedRowsChange(selectedRows: IMasterDataPartner[]) {
        this.setState({
            selectedRows: selectedRows,
        });
    }

    selectedRowsKeyChange(selectedRowKeys: string[]) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
        });
    }

    clearSelections() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
        });
    }

    //#endregion
    //#region Category One methods
    addCategoriesOne(categories: string[]) {}

    addCategoriesTwo(categories: string[]) {}

    //#endregion

    resetCreatedCategories() {
        this.setState({
            newCreatedCategory1: [],
            newCreatedCategory2: [],
        });
    }

    resetCreatedGroup() {
        this.setState({
            newCreatedGroup: [],
        });
    }

    clearSelectedRows() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
        });
    }

    setGroupModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showGroupModal: value,
            },
        }));
    }
    setMergeModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showMergeModal: value,
            },
        }));
    }

    setAddToMergeSetVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showAddToMergeSetModal: value,
            },
        }));
    }

    setUnMergeModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showUnmergeModal: value,
            },
        }));
    }
    setOwnerModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showOwnerModal: value,
            },
        }));
    }
    setCatOneModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCategoryOneModal: value,
            },
        }));
    }
    setCatTwoModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCategoryTwoModal: value,
            },
        }));
    }
    setCreateodalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCreateModal: value,
            },
        }));
    }
    setDeleteModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showDeleteModal: value,
            },
        }));
    }

    setEditPartnerModalVisibility(value: boolean, row: IMasterDataPartner | undefined) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showEditPartnerModal: value,
            },
            currentRow: row,
        }));
    }

    setCreateGroupModalVisible(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCreateGroupModal: value,
            },
        }));
    }

    setCreateCategoryOneModalVisible(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCreateCategoryOneModal: value,
            },
        }));
    }

    setCreateCategoryTwoModalVisible(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showCreateCategoryTwoModal: value,
            },
        }));
    }

    setSwitchOwnerModalVisibility(value: boolean) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showSwitchOwnerModal: value,
            },
        }));
    }

    setStatusLogModalVisibility(value: boolean, row: any | undefined) {
        this.setState(prevState => ({
            showModals: {
                ...prevState.showModals,
                showStatusLogModal: value,
            },
            currentRow: row,
        }));
    }

    openAddToMergeModal() {
        let selectedRowParent: IMasterDataPartner = this.state.selectedRows.filter(
            x => x.hasChildren === true
        )[0];

        //Antd selectedRows does not contain children so we made a copy of that in partnerModelMapper
        this.setState({
            MergetSetParent: { ...selectedRowParent, children: selectedRowParent.childrenCopy },
        });

        this.setAddToMergeSetVisibility(true);
    }

    render() {
        const selectedRowsHasNoOwner: boolean =
            this.state.selectedRows
                .map(x =>
                    x.owners!.value.some(
                        x => x.id.toString() === this.props.user.profile["ll-ContactId"]
                    )
                )
                .filter(x => x === false).length > 0;
        const usertype: string = this.props.user.profile["ll-Usertype"];
        const isAdmin = usertype === "administrator";

        //#region Action Menu bar definitions

        const MenuBar = () => {
            const allowEdit = this.state.companySettings.allowOnlyOwnersToEditPartnerData;
            const buttonVisibility = this.state.selectedRowKeys.length > 0;
            const grouptButtonVisibility =
                this.state.selectedRowKeys.length > 0 ||
                (selectedRowsHasNoOwner && allowEdit && !isAdmin);
            const mergeButtonVisibility =
                this.state.selectedRows.filter(x => x.hasChildren).length > 1 ||
                this.state.selectedRowKeys.length >= 2 ||
                this.state.selectedRows.filter(x => x.parentId !== "").length > 1;
            const deleteButtonVisibility =
                this.state.selectedRowKeys.length > 0 ||
                this.state.selectedRows.some(x => x.parentId !== "") ||
                this.state.selectedRowKeys.length > 100;
            if (allowEdit || usertype === "administrator") {
                return (
                    <div className="actionMenuContainer" id="masterDataOverviewActionBar">
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                width: `${!mergeButtonVisibility ? 650 : 750}`,
                            }}>
                            {grouptButtonVisibility && (
                                <Button
                                    data-test="group_modal_button"
                                    onClick={() => this.setGroupModalVisibility(true)}>
                                    <Link01 />
                                    {this.state.customHeadlines?.groupHeadline?.customLabel ??
                                        this.props.t("supplier_Group")}
                                </Button>
                            )}

                            {this.state.selectedRows.some(x => x.parentId) && isAdmin ? (
                                <Button
                                    data-test="unmerge_button"
                                    disabled={this.state.selectedRows.some(x => x.parentId === "")}
                                    onClick={() => this.setUnMergeModalVisibility(true)}>
                                    <Reflect02 />
                                    {this.props.t("unmergeBtnText", "Unmerge")}
                                </Button>
                            ) : (
                                usertype === "administrator" &&
                                mergeButtonVisibility && (
                                    <Button
                                        data-test="merge_button"
                                        onClick={() => {
                                            if (this.state.selectedRows.some(x => x.hasChildren)) {
                                                this.openAddToMergeModal();
                                            } else {
                                                this.setMergeModalVisibility(true);
                                            }
                                        }}>
                                        <Reflect02 />
                                        {this.props.t("mergeBtnText", "Merge")}
                                    </Button>
                                )
                            )}

                            {isAdmin && buttonVisibility && (
                                <Button
                                    data-test="owner_modal_button"
                                    onClick={() => this.setOwnerModalVisibility(true)}>
                                    <User01 />
                                    {this.state.customHeadlines?.ownerHeadline?.customLabel ??
                                        this.props.t("supplier_Owner")}
                                </Button>
                            )}

                            {grouptButtonVisibility && (
                                <>
                                    <Button
                                        data-test="category1_modal_button"
                                        onClick={() => this.setCatOneModalVisibility(true)}>
                                        <Pin02 />
                                        {this.state.customHeadlines?.category1Headline
                                            ?.customLabel ?? this.props.t("supplier_Category1")}
                                    </Button>

                                    <Button
                                        data-test="category2_modal_button"
                                        onClick={() => this.setCatTwoModalVisibility(true)}>
                                        <Pin02 />
                                        {this.state.customHeadlines?.category2Headline
                                            ?.customLabel ?? this.props.t("supplier_Category2")}
                                    </Button>
                                </>
                            )}

                            {((usertype !== "lightuser" &&
                                !buttonVisibility &&
                                this.state.companySettings.canUsersCreateSupplier) ||
                                usertype === "administrator") &&
                                !buttonVisibility && (
                                    <Button
                                        data-test="create_supplier"
                                        onClick={() => this.setCreateodalVisibility(true)}>
                                        <PlusCircle />
                                        {this.props.user.profile["ll-companyType"] ===
                                        CompanyType.default
                                            ? this.props.t("supplier_CreateSupplierBtnText", {
                                                  companyType: this.props.t(
                                                      "company_Type_Supplier"
                                                  ),
                                              })
                                            : this.props.t("supplier_CreateSupplierBtnText", {
                                                  companyType: this.props.t(
                                                      "company_Type_Customer"
                                                  ),
                                              })}
                                    </Button>
                                )}

                            {usertype === "administrator" && deleteButtonVisibility && (
                                <Tooltip
                                    title={
                                        this.state.selectedRowKeys.length > 100
                                            ? this.props.t("supplier_DeleteThresholdInfo")
                                            : ""
                                    }>
                                    <Button
                                        danger
                                        data-test="delete_button"
                                        onClick={() => this.setDeleteModalVisibility(true)}>
                                        <Trash02
                                            style={{
                                                color:
                                                    this.state.selectedRowKeys.length !== 0
                                                        ? "#FF4D4F"
                                                        : "",
                                            }}
                                        />
                                        {this.props.user.profile["ll-companyType"] ===
                                        CompanyType.default
                                            ? this.props.t("supplier_DeleteSupplierBtnText", {
                                                  companyType: this.props.t(
                                                      "company_Type_Supplier"
                                                  ),
                                              })
                                            : this.props.t("supplier_DeleteSupplierBtnText", {
                                                  companyType: this.props.t(
                                                      "company_Type_Customer"
                                                  ),
                                              })}
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                );
            } else {
                return <Fragment></Fragment>;
            }
        };

        //#endregion
        return (
            <div>
                <div>
                    <PartnerTable
                        masterDataMenu={() => <MenuBar />}
                        tableType="masterDataOverview"
                        onSelectedRowsChange={(selectedRows: IMasterDataPartner[]) =>
                            this.selectedRowsChange(selectedRows)
                        }
                        onSelectedRowsKeysChange={(selectedRowKeys: string[]) =>
                            this.selectedRowsKeyChange(selectedRowKeys)
                        }
                        selectedRowKeys={this.state.selectedRowKeys}
                        selectedRows={this.state.selectedRows}
                        selectableRows={true}
                        onRowClick={(showModal: boolean, row: IMasterDataPartner) =>
                            this.setEditPartnerModalVisibility(showModal, row)
                        }
                        showGroupModal={(value: boolean) => this.setGroupModalVisibility(value)}
                        showOwnerModal={(value: boolean) => this.setOwnerModalVisibility(value)}
                        showCat1Modal={(value: boolean) => this.setCatOneModalVisibility(value)}
                        showCat2Modal={(value: boolean) => this.setCatTwoModalVisibility(value)}
                        showDeleteModal={(value: boolean) => this.setDeleteModalVisibility(value)}
                        showSwitchOwnerModal={(value: boolean) =>
                            this.setSwitchOwnerModalVisibility(value)
                        }
                        showStatusLogModal={(value: boolean, row: any) =>
                            this.setStatusLogModalVisibility(value, row)
                        }
                        setCustomColumns={(customColumns: ICustomColumn[]) =>
                            this.setState({ customColumns: customColumns })
                        }
                        setCustomHeadlines={(customHeadlines: IHeadlineSetting) =>
                            this.setState({ customHeadlines: customHeadlines })
                        }
                        setCompanySettings={(companySettings: ICompanySettings) => {
                            this.setState({ companySettings });
                        }}
                        onFiltersChange={(filters: any) => this.setState({ filters: filters })}
                        onPaginationChange={(currentPage: any) =>
                            this.setState({ currentPage: currentPage })
                        }
                        reloadData={this.state.reloadTable}
                    />
                </div>
                <ModalGroup
                    visible={this.state.showModals.showGroupModal}
                    setGroupModalVisibility={() => this.setGroupModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    partnerGroups={this.state.groupsInUse}
                    updateTable={() => this.fetchData()}
                    setCreateGroupModalVisible={(value: boolean) =>
                        this.setCreateGroupModalVisible(value)
                    }
                    groupHeadline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.groupHeadline
                            ? this.state.customHeadlines.groupHeadline
                            : {
                                  name: "group",
                                  customLabel: "Group",
                                  standardLabel: "Group",
                                  isMandatory: false,
                              }
                    }
                    createdGroup={this.state.newCreatedGroup}
                    resetCreatedGroup={() => this.resetCreatedGroup()}
                    onSelectedRowsChange={(selectedRows: IMasterDataPartner[]) =>
                        this.selectedRowsChange(selectedRows)
                    }
                />
                <ModalMerge
                    visible={this.state.showModals.showMergeModal}
                    setMergeModalVisibility={() => this.setMergeModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    clearSelectedRows={() => this.clearSelectedRows()}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    updateTable={() => this.fetchData()}
                    selectedRowKeys={this.state.selectedRowKeys}
                />
                <ModalAddToMergeSet
                    visible={this.state.showModals.showAddToMergeSetModal}
                    setModalVisibility={() => this.setAddToMergeSetVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    clearSelectedRows={() => this.clearSelectedRows()}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    updateTable={() => this.fetchData()}
                    selectedRowKeys={this.state.selectedRowKeys}
                    mergeSetParent={this.state.MergetSetParent!}
                />
                <ModalUnMerge
                    visible={this.state.showModals.showUnmergeModal}
                    setModalVisibility={() => this.setUnMergeModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    clearSelectedRows={() => this.clearSelectedRows()}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    updateTable={() => this.fetchData()}
                    selectedRowKeys={this.state.selectedRowKeys}
                />
                <ModalOwner
                    visible={this.state.showModals.showOwnerModal}
                    setModalVisibility={() => this.setOwnerModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    updateTable={() => this.fetchData()}
                    ownerHeadlinne={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.ownerHeadline
                            ? this.state.customHeadlines.ownerHeadline
                            : {
                                  name: "owner",
                                  customLabel: "Owner",
                                  standardLabel: "Owner",
                                  isMandatory: false,
                              }
                    }
                    onSelectedRowsChange={(selectedRows: IMasterDataPartner[]) =>
                        this.selectedRowsChange(selectedRows)
                    }
                />
                <CategoryOneModal
                    visible={this.state.showModals.showCategoryOneModal}
                    setModalVisibility={() => this.setCatOneModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    addCategories={(categories: string[]) => this.addCategoriesOne(categories)}
                    updateTable={() => this.fetchData()}
                    setCreateCategory1ModalVisible={(value: boolean) =>
                        this.setCreateCategoryOneModalVisible(value)
                    }
                    category1Headline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.category1Headline
                            ? this.state.customHeadlines.category1Headline
                            : {
                                  name: "category1",
                                  customLabel: "Category 1",
                                  standardLabel: "Category 1",
                                  isMandatory: false,
                              }
                    }
                    createdCategories={this.state.newCreatedCategory1}
                    resetCreatedCategories={() => this.resetCreatedCategories()}
                    onSelectedRowsChange={(selectedRows: IMasterDataPartner[]) =>
                        this.selectedRowsChange(selectedRows)
                    }
                />
                <CategoryTwoModal
                    visible={this.state.showModals.showCategoryTwoModal}
                    setModalVisibility={() => this.setCatTwoModalVisibility(false)}
                    selectedRows={this.state.selectedRows}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    addCategories={(categories: string[]) => this.addCategoriesTwo(categories)}
                    updateTable={() => this.fetchData()}
                    setCreateCategory2ModalVisible={(value: boolean) =>
                        this.setCreateCategoryTwoModalVisible(value)
                    }
                    category2Headline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.category2Headline
                            ? this.state.customHeadlines.category2Headline
                            : {
                                  name: "category2",
                                  customLabel: "Category 2",
                                  standardLabel: "Category 2",
                                  isMandatory: false,
                              }
                    }
                    createdCategories={this.state.newCreatedCategory2}
                    resetCreatedCategories={() => this.resetCreatedCategories()}
                    onSelectedRowsChange={(selectedRows: IMasterDataPartner[]) =>
                        this.selectedRowsChange(selectedRows)
                    }
                />
                <ModalDelete
                    visible={this.state.showModals.showDeleteModal}
                    setDeleteModalVisibility={() => this.setDeleteModalVisibility(false)}
                    clearSelectedRows={() => this.clearSelectedRows()}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                    selectedRows={this.state.selectedRows}
                    updateTable={() => this.fetchData()}
                />
                <EditPartnerModal
                    visible={this.state.showModals.showEditPartnerModal}
                    setModalEditPartnerVisibility={() =>
                        this.setEditPartnerModalVisibility(false, undefined)
                    }
                    selectedRow={this.state.currentRow!}
                    updateTable={() => {
                        this.fetchData();
                        this.setState({
                            selectedRows: [],
                            selectedRowKeys: [],
                        });
                    }}
                    customColumns={this.state.customColumns}
                    setCreateGroupModalVisible={(value: boolean) =>
                        this.setCreateGroupModalVisible(value)
                    }
                    setCreateCategoryOneModalVisible={(value: boolean) =>
                        this.setCreateCategoryOneModalVisible(value)
                    }
                    setCreateCategoryTwoModalVisible={(value: boolean) =>
                        this.setCreateCategoryTwoModalVisible(value)
                    }
                    customHeadlines={this.state.customHeadlines}
                    createdCategories1={this.state.newCreatedCategory1}
                    createdCategories2={this.state.newCreatedCategory2}
                    resetCreatedCategories={() => this.resetCreatedCategories()}
                    createdGroup={this.state.newCreatedGroup}
                    resetCreatedGroup={() => this.resetCreatedGroup()}
                />

                <CreateExternalPartnerModal
                    visible={this.state.showModals.showCreateModal}
                    onCancel={() => this.setCreateodalVisibility(false)}
                    onCreateExternalPartner={() => {}}
                    reload={() => this.fetchData()}
                    footer={"big"}
                />
                <CreateGroupModal
                    visible={this.state.showModals.showCreateGroupModal}
                    setModalVisibility={(value: boolean) => this.setCreateGroupModalVisible(value)}
                    setCreatedGroup={(createdGroups: { key: string | number; label: string }[]) =>
                        this.setState({ newCreatedGroup: createdGroups })
                    }
                    groupHeadline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.groupHeadline &&
                        this.state.customHeadlines.groupHeadline.customLabel
                            ? this.state.customHeadlines.groupHeadline.customLabel
                            : this.props.t("group")
                    }
                />
                <CreateCategoryModal
                    visible={this.state.showModals.showCreateCategoryOneModal}
                    setModalVisibility={(value: boolean) =>
                        this.setCreateCategoryOneModalVisible(value)
                    }
                    setCreatedCategory={(
                        createdCategories: { key: string | number; label: string }[]
                    ) => this.setState({ newCreatedCategory1: createdCategories })}
                    categoryHeadline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.category1Headline &&
                        this.state.customHeadlines.category1Headline.customLabel
                            ? this.state.customHeadlines.category1Headline.customLabel
                            : this.props.t("category1")
                    }
                    catType={categoryType.one}
                />
                <CreateCategoryModal
                    visible={this.state.showModals.showCreateCategoryTwoModal}
                    setModalVisibility={(value: boolean) =>
                        this.setCreateCategoryTwoModalVisible(value)
                    }
                    setCreatedCategory={(
                        createdCategories: { key: string | number; label: string }[]
                    ) => this.setState({ newCreatedCategory2: createdCategories })}
                    categoryHeadline={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.category2Headline &&
                        this.state.customHeadlines.category2Headline.customLabel
                            ? this.state.customHeadlines.category2Headline.customLabel
                            : this.props.t("category2")
                    }
                    catType={categoryType.two}
                />
                <ModalSwitchOwner
                    visible={this.state.showModals.showSwitchOwnerModal}
                    setModalVisibility={(value: boolean) =>
                        this.setSwitchOwnerModalVisibility(value)
                    }
                    updateTable={() => this.fetchData()}
                    clearSelectedRows={() => this.clearSelectedRows()}
                    contactId={this.props.user.profile["ll-ContactId"]}
                    ownerHeadlinne={
                        this.state.customHeadlines !== undefined &&
                        this.state.customHeadlines.ownerHeadline
                            ? this.state.customHeadlines.ownerHeadline
                            : {
                                  name: "owner",
                                  customLabel: "Owner",
                                  standardLabel: "Owner",
                                  isMandatory: false,
                              }
                    }
                    selectedRows={this.state.selectedRows}
                    updateSelectedRows={(tag: any, key: any) => this.updateSelectedRows(tag, key)}
                />
                <ModalStatusLog
                    visible={this.state.showModals.showStatusLogModal}
                    setModalVisible={(value: boolean) =>
                        this.setStatusLogModalVisibility(value, undefined)
                    }
                    selectedRow={this.state.currentRow}
                />
            </div>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        user: state.oidc.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        dispatch,
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MasterDataOverview));
