export const scoreGradient = (score: number) => {
    if (score < 0) {
        return "linear-gradient(90deg, #FF0000 0%, #FF0000 100%)";
    } else if (score < 50) {
        return `linear-gradient(90deg, #FF0000 ${score}%, #FFFFFF ${score}%)`;
    } else if (score < 75) {
        return `linear-gradient(90deg, #FFA500 ${score}%, #FFFFFF ${score}%)`;
    } else {
        return `linear-gradient(90deg, #00FF00 ${score}%, #FFFFFF ${score}%)`;
    }
};

export const scoreColor = (score: number) => {
    if (score <= 0) {
        return "#faad14";
    } else if (score <= 25) {
        return "#faad14";
    } else if (score <= 50) {
        return "#f2bf05";
    } else if (score < 70) {
        return "#b0c41a";
    } else if (score < 85) {
        return "#7dc41a";
    } else {
        return "#52c41a";
    }
};
