import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useNcrActionOwnerUpdate = () => {
    const [loading, setLoading] = useState(false);

    const UpdateOwner = async (actionId: number, ownerId: string, ownerType: number | string) => {
        setLoading(true);

        const ncrActionid = await serviceProvider.Ncr.updateNcrActionOwner(
            actionId,
            ownerId,
            ownerType
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return ncrActionid;
    };

    return {
        loading,
        UpdateOwner,
    };
};

export { useNcrActionOwnerUpdate };
