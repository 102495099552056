import React, { FunctionComponent, Fragment, useState } from "react";
import {
    Grid,
    getColumnSearchProps,
    getColumnRangePickerProps,
    getColumnSelectProps,
    getFilterIcon,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { formatTime } from "utilities";
import { useNonResponseOverview, usePartnerFields, useSurveyTitles } from "controller";
import { ReportType } from "models";
import dayjs from "utilities/daysJsConfig";

const Overview: FunctionComponent = () => {
    const { t } = useTranslation();
    const getDefaultCustomFilters = () => ({
        from: dayjs().subtract(6, "months"),
        to: dayjs().add(1, "day"),
    });
    const [customFilters] = useState<any>(getDefaultCustomFilters());
    const { data: partnerFields } = usePartnerFields();

    let companyNameColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyName")
        ?.customLabel;
    let companyNumberColTitle = partnerFields.headlines.find((x: any) => x.name === "CompanyNumber")
        ?.customLabel;

    let columns = [
        {
            title: t("survey_Id"),
            dataIndex: "surveyId",
            key: "surveyId",
            width: 150,
            fixed: true,
            sorter: true,
            filterType: "string",
            viewColumnId: 0,
            ...getColumnSearchProps({ dataIndex: "surveyId" }),
        },
        {
            title: t("survey_Title"),
            dataIndex: "surveyTitle",
            key: "surveyTitle",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "array",
            viewColumnId: 1,
            ...getColumnSelectProps(useSurveyTitles.bind(null, false, true), {
                value: ["id"],
                text: ["title"],
            }),
        },
        {
            title: t("survey_SurveyCreated"),
            dataIndex: "surveyCreated",
            key: "surveyCreated",
            width: 250,
            fixed: true,
            sorter: true,
            filterType: "date",
            viewColumnId: 2,
            ...getColumnRangePickerProps(),
            render: (val: any) => val && formatTime(val, "MMM D, YYYY (HH:mm)"),
        },
        {
            title: companyNameColTitle || t("survey_CompanyName"),
            dataIndex: "partnerName",
            key: "partnerName",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "string",
            viewColumnId: 3,
            ...getColumnSearchProps({ dataIndex: "partnerName" }),
        },
        {
            title: companyNumberColTitle || t("survey_CompanyNumber"),
            dataIndex: "partnerNumber",
            key: "partnerNumber",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "string",
            viewColumnId: 4,
            ...getColumnSearchProps({ dataIndex: "partnerNumber" }),
        },
        {
            title: t("survey_ContactName"),
            dataIndex: "contactName",
            key: "contactName",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "array",
            viewColumnId: 5,
            ...getColumnSearchProps({ dataIndex: "contactName" }),
        },
        {
            title: t("survey_ContactEmail"),
            dataIndex: "contactEmail",
            key: "contactEmail",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "string",
            viewColumnId: 6,
            ...getColumnSearchProps({ dataIndex: "contactEmail" }),
        },
        {
            title: t("survey_ContactGroup"),
            dataIndex: "contactGroupName",
            key: "contactGroupName",
            width: 150,
            fixed: false,
            sorter: true,
            filterType: "string",
            viewColumnId: 7,
            ...getColumnSearchProps({ dataIndex: "contactGroupName" }),
        },
        {
            title: t("review_VoiceOfSupplierColumn"),
            dataIndex: "isVoiceOfSupplier",
            key: "isVoiceOfSupplier",
            width: 150,
            fixed: false,
            sorter: true,
            viewColumnId: 8,
            filterType: "array",
            ...getColumnSelectProps(
                () => {
                    return {
                        data: [
                            { text: t("generic_Yes"), value: true },
                            { text: t("generic_No"), value: false },
                        ],
                        loading: false,
                    };
                },
                {
                    value: ["value"],
                    text: ["text"],
                },
                { dataIndex: "isVoiceOfSupplier", radioChoice: true }
            ),
            ...getFilterIcon({ dataIndex: "isVoiceOfSupplier" }),
            render: (val: boolean) => (
                <div>{val === true ? t("generic_Yes") : t("generic_No")}</div>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid
                columns={columns}
                useData={useNonResponseOverview}
                tableType={"NonResponse"}
                reportType={ReportType.nonResponseSurvey}
                fixedColumns={["surveyId", "surveyTitle", "surveyCreated"]}
                tableExcessHeight={25}
                rowKey="rowId"
                customFilters={customFilters}
            />
        </Fragment>
    );
};

export { Overview };
