import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Overview from "./overview";
import { Overview as DevelopmentPlanOverview } from "../../developmentPlans/overview";
import classes from "./styles/action.module.scss";
import { useTranslation } from "react-i18next";
const TabPane = Tabs.TabPane;

interface IProps {
    supplierProfileOverview: boolean;
    partnerId: number;
    clickedTab: any;
}

const ActionOverviewContainer = ({ supplierProfileOverview, clickedTab, partnerId }: IProps) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(clickedTab);

    useEffect(() => {
        setActiveTab(clickedTab);
    }, [clickedTab]);

    return (
        <>
            <Tabs
                activeKey={activeTab}
                size="small"
                type="card"
                onChange={(tab: any) => setActiveTab(tab)}
                renderTabBar={() => <></>}>
                <TabPane key={"0"} tab={<div className={classes.tab}>{t("actions_Actions")}</div>}>
                    <Overview
                        partnerId={partnerId}
                        supplierProfileOverview={supplierProfileOverview}
                    />
                </TabPane>
                <TabPane
                    key={"1"}
                    tab={<div className={classes.tab}>{t("developmentPlans_Overview")}</div>}>
                    <DevelopmentPlanOverview partnerId={partnerId} />
                </TabPane>
            </Tabs>
        </>
    );
};

export { ActionOverviewContainer };
