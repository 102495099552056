import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useReportUpdateFilter = () => {
    const [loading, SetLoading] = useState(false);

    const updateFilters = async (reportId: number, filterData: any) => {
        SetLoading(true);

        let reportFilters = {
            samePeriodPreviousYear: filterData.previousPeriod,
            includeBenchmark: filterData.benchmark,
            chartType: filterData.chartType,
            from: filterData.dateRange[0].toISOString(),
            to: filterData.dateRange[1].toISOString(),
            dataRelation: {
                dataId: filterData.kpi,
                subLevels: (filterData.subLevels
                    ? filterData.subLevels
                    : filterData.level
                    ? [filterData.level]
                    : []
                ).map((subLevel: any) => ({ id: subLevel })),
            },

            xAxisProduct: filterData.xAxisProduct,
            enabledStandardColumns:
                filterData.enabledStandardColumns && filterData.enabledStandardColumns.toString(),
            enabledCustomColumns: filterData.enabledCustomColumns
                ? filterData.enabledCustomColumns.map((x: any) => ({
                      customColumnId: x,
                  }))
                : [],
        };

        let response = await serviceProvider.Analytics.updateReportFilter(
            reportId,
            reportFilters
        ).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
        return response;
    };

    return {
        loading,
        updateFilters,
    };
};

export { useReportUpdateFilter };
