import { useState } from "react";
import Provider from "services/api";

const useProductIdInUse = () => {
    const [loading, setLoading] = useState(false);

    const isProductIdInUse = async (productId: string) => {
        setLoading(true);
        const inUse = await Provider.Product.isProductInUse(productId, {
            customError: {
                message: "error checking product id",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return inUse;
    };

    return {
        loading,
        isProductIdInUse,
    };
};

export { useProductIdInUse };
