import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Descriptions, notification, Tag, Row } from "antd";
import {
    IActivityData,
    ICompliance,
    IComplianceAnswer,
    IComplianceHeadLine,
    IComplianceQuestion,
    IProduct,
} from "models";
import {
    getActivity,
    DownloadFile,
    ShowMore,
    ColoredPercentTag,
    Star,
    ReviewRate,
    VisibilityTag,
} from "components/utilitycomponents";
import { useComplianceRatingUpdate, useProductFeature } from "controller";
import classes from "./styles/complianceDetail.module.scss";
import { dayjs, elapsedTime, formatTime } from "utilities";

type PropsType = {
    data: ICompliance;
    approvedForLoading: boolean;
    onRatingChanged: Function;
    readOnly?: boolean;
};

const ComplianceDetail: FunctionComponent<PropsType> = ({
    data,
    approvedForLoading,
    onRatingChanged,
    readOnly = false,
}) => {
    const { t } = useTranslation();
    const [rating, setRating] = useState<number | null>(data.rating);
    const { loading: ratingUpdateLoading, update: updateRating } = useComplianceRatingUpdate();
    const [answerFiles, setAnswerFiles] = useState<any[]>([]);
    const { hasProductFeature } = useProductFeature();

    useEffect(() => {
        setRating(data.rating);
    }, [data]);

    const activityData: IActivityData = {
        isFollow: data.isFollow,
        canDelete: false,
        typeName: "Compliance",
        activity: {
            id: data.activityId,
            created: "",
        },
        children: data.childrenActivity,
        selfAssessmentDetail: data.isSelfAssessment
            ? {
                  id: data.id,
                  fromOrOnPartner: data.partnerName,
                  isOnPartner: data.isOnPartner,
                  partnerId: data.partnerId,
                  creatorProfilePictureId: data.creatorProfilePictureId,
                  visibility: data.visibility,
                  fromOrOnPartnerId: data.fromOrOnPartnerId,
                  myCompanyName: data.myCompanyName,
                  creator: data.creator,
              }
            : null,
        complianceDetail: data.isSelfAssessment
            ? null
            : {
                  id: data.id,
                  fromOrOnPartner: data.partnerName,
                  isOnPartner: data.isOnPartner,
                  partnerId: data.partnerId,
                  creatorProfilePictureId: data.creatorProfilePictureId,
                  visibility: data.visibility,
                  myCompanyName: data.myCompanyName,
                  companyNumber: data.companyNumber,
                  creator: data.creator,
              },
    };
    const { ActivityTypeComponent, activityDetail } = getActivity(activityData);

    const handleChangeRating = async (value: number) => {
        if (value === undefined) value = 0;
        setRating(value);

        try {
            await updateRating(data.id, value, data.activityId);
            notification.success({
                message: t("success"),
                description: t("compliance_RatingUpdateSuccess"),
            });
            onRatingChanged();
        } catch (err) {
            onRatingChanged();
        }
    };

    useEffect(() => {
        if (data) {
            let attachedDocuments: any[] = [];
            data.templateDetail.headLines.forEach(
                (headline: IComplianceHeadLine, index: number) => {
                    if (
                        headline.complianceQuestions !== null &&
                        headline.complianceQuestions.length > 0
                    ) {
                        headline.complianceQuestions.forEach(
                            (question: IComplianceQuestion, index: number) => {
                                if (question && question.complianceAnswers.length > 0) {
                                    if (question.questionType === "AttachDocuments")
                                        question.complianceAnswers.forEach(
                                            (answer: IComplianceAnswer) => {
                                                if (answer.files && answer.files.length > 0) {
                                                    answer.files.forEach((file: any) => {
                                                        attachedDocuments.push(file);
                                                    });
                                                }
                                            }
                                        );
                                }
                            }
                        );
                    }
                }
            );
            setAnswerFiles(attachedDocuments);
        }
    }, [data]);

    return (
        <Fragment>
            <ActivityTypeComponent data={activityDetail} />

            <Descriptions style={{ marginTop: "12px" }} column={1} bordered size="small">
                {data.created && (
                    <Descriptions.Item label={t("createdDate")}>
                        {elapsedTime(data.created)}
                    </Descriptions.Item>
                )}
                {data.canSeeScores && (
                    <Descriptions.Item label={t("compliance_TotalScore")}>
                        {data.averageScore !== null && (
                            <ColoredPercentTag value={data.averageScore} />
                        )}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={t("compliance_Type")}>
                    {data.templateDetail.name}
                </Descriptions.Item>
                <Descriptions.Item
                    data-test="entity_text"
                    label={data.templateDetail.entity}
                    className={classes.entity}>
                    {data.entity}
                </Descriptions.Item>
                {data.endDate && (
                    <Descriptions.Item label={t("compliance_EndDate")}>
                        {dayjs().isAfter(data.endDate) ? (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_Expired")} {formatTime(data.endDate)}
                            </Tag>
                        ) : (
                            data.endDate && formatTime(data.endDate, "MMM D, YYYY")
                        )}
                    </Descriptions.Item>
                )}
                {data.alertDate && (
                    <Descriptions.Item label={t("compliance_AlertDate")}>
                        {dayjs(data.alertDate).format("MMM D, YYYY")}
                    </Descriptions.Item>
                )}
                {hasProductFeature && data.products && data.products.length > 0 && (
                    <Descriptions.Item label={t("product_Products")}>
                        {data.products.map((prod: IProduct) => (
                            <Tag bordered={false}>{prod.name.substring(0, 100)}</Tag>
                        ))}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={t("compliance_Attachments")}>
                    {data.templateDetail.canUserAccessAttachments &&
                        data.files.length !== 0 &&
                        data.files.map((file: any, index: number) => (
                            <Row key={index} style={{ marginTop: 4 }}>
                                <DownloadFile file={file} />
                            </Row>
                        ))}
                    {answerFiles.length !== 0 &&
                        answerFiles.map((file: any, index: number) => (
                            <Fragment key={index}>
                                {index !== 0 && <span>,</span>}
                                <DownloadFile file={file} />
                            </Fragment>
                        ))}
                </Descriptions.Item>

                {data.hasStatusForRegionsOrSites && (
                    <Descriptions.Item label={t("compliance_ApprovedFor")}>
                        {data.approvedForSiteAndRegions && data.approvedForSiteAndRegions.length ? (
                            <Spin spinning={approvedForLoading} size="small">
                                <ShowMore
                                    text={data.approvedForSiteAndRegions.join(", ")}
                                    characterLimit={240}
                                    lineLimit={2}
                                    showGuidAlign="left"
                                />
                            </Spin>
                        ) : (
                            ""
                        )}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={"Rating"}>
                    {data.canChangeRating && !readOnly ? (
                        <div className={classes.ratingContainer}>
                            <Spin spinning={ratingUpdateLoading}>
                                <Star
                                    value={rating !== null ? rating : 0}
                                    data-test="registration_rating"
                                    onChange={(value: number) => {
                                        handleChangeRating(value);
                                    }}
                                    allowClear
                                />
                            </Spin>
                        </div>
                    ) : (
                        <ReviewRate val={rating !== null ? rating : 0} />
                    )}
                </Descriptions.Item>
                {data.visibility && (
                    <Descriptions.Item label={t("generic_Visibility")}>
                        <VisibilityTag visibility={data.visibility} />
                    </Descriptions.Item>
                )}
            </Descriptions>
        </Fragment>
    );
};

export default ComplianceDetail;
