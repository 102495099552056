import { useState } from "react";
import { Provider } from "services";

const useIsValidMerge = () => {
    const [loading, setLoading] = useState(false);

    const isValidMerge = async (parentId: string, childrenIds: number[]) => {
        setLoading(true);
        const id = await Provider.Partner.isValidMerge(parentId, childrenIds, {
            customError: {
                message: "Could not check merge validation",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        isValidMerge,
    };
};

export { useIsValidMerge };
