import React, { useState } from "react";
import classes from "./metrics.module.scss";
import { usePerformanceDataByDateRange } from "controller";
import { Link } from "react-router-dom";
import { Spin, Row, Col, Statistic, DatePicker, Popover, Divider, Button } from "antd";
import { colorGrade } from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";
const { RangePicker } = DatePicker;

interface IProps {
    partnerId: number;
}

const PerformanceMetrics = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [from, setFrom] = useState<any>(
        dayjs()
            .subtract(12, "month")
            .startOf("month")
    );
    const [to, setTo] = useState<any>(dayjs().endOf("month"));
    const { data, loading } = usePerformanceDataByDateRange(partnerId, from, to);
    const [rangeText, setRangeText] = useState<string>(t("dateRange_last12Months"));

    const renderMetrics = () => {
        return (
            data &&
            data.length > 0 &&
            data
                .filter((x: any) => x.criteriaGroupId !== null)
                .map((x: any) => (
                    <Row>
                        <Col span={16}>{x.criteriaGroup}</Col>
                        <Col span={4}>
                            {x.parentLevelAvg ? x.parentLevelAvg.toPrecision(2) : "-"}
                        </Col>
                    </Row>
                ))
        );
    };

    const renderTotalAverage = () => {
        let totalItem =
            data && data.length > 0 && data.find((x: any) => x.criteriaGroupId === null);

        return (
            <Row>
                <Col span={8}></Col>
                <Col span={16}>
                    <Statistic
                        title={"Total"}
                        value={
                            totalItem.parentLevelAvg ? totalItem.parentLevelAvg.toPrecision(2) : 0
                        }
                        formatter={(val: any) => (
                            <div style={{ color: colorGrade(val), fontSize: 38 }}>
                                {val ? val : "-"}
                            </div>
                        )}
                        suffix={"/ 5"}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <Spin spinning={loading}>
            <div className={classes.performanceMetricsContainer}>
                <div className={classes.performanceTitle}>
                    <div>{t("supplierprofile_summary_StakeholderFeedbackAvg").toUpperCase()}</div>
                    <div></div>
                    <Divider className={classes.divider} />
                </div>

                <div className={classes.performanceRangeSelect}>
                    <div style={{ display: "inline-block" }}>
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            content={
                                <RangePicker
                                    allowClear={false}
                                    size="small"
                                    picker="month"
                                    bordered={false}
                                    defaultValue={[from, to]}
                                    onChange={(values: any) => {
                                        setFrom(values[0]);
                                        setTo(values[1]);
                                        setRangeText(
                                            `${values[0].format("MM-YYYY")} / ${values[1].format(
                                                "MM-YYYY"
                                            )}`
                                        );
                                    }}
                                />
                            }>
                            <div className={classes.linkStyle}>{rangeText}</div>
                        </Popover>
                    </div>
                    {data.length !== 0 && (
                        <div style={{ display: "inline-block" }}>
                            <Button
                                style={{ marginTop: -6 }}
                                className={classes.tableTitleAddBtn}
                                type="link">
                                <Link to="/Reviews/SurveyOverview/Create/Type/3">
                                    {t("generic_Add")}
                                </Link>
                            </Button>
                        </div>
                    )}

                    <Divider className={classes.divider} />
                </div>
                <div className={classes.performanceLeft}>{renderTotalAverage()}</div>
                <div className={classes.performanceRight}>
                    {!loading && data.length > 0 ? (
                        renderMetrics()
                    ) : (
                        <div className={classes.emptyText}>
                            <Button type="primary">
                                <Link to="/Reviews/SurveyOverview/Create/Type/3">
                                    {t("performance_CreateNewPerformance2")}
                                </Link>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Spin>
    );
};

export { PerformanceMetrics };
