import React, { useEffect, useState } from "react";
import classes from "./owlerStyle.module.scss";
import { Card, Flex, Tooltip, Modal, Image, Skeleton, List } from "antd";
import { OwlerDomainInput } from "./owlerDomainInput";
import { useTranslation } from "react-i18next";
import { IOwlerCompetitor } from "models";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import useOwlerCompetitorList from "controller/owler/useOwlerCompetitorList";
import { OwlerCardFooter } from "./owlerCardFooter";
import { useParams } from "react-router-dom";
import { Expand01, InfoCircle } from "components/utilitycomponents";

interface IProps {
    domain: string | undefined;
    loadingDomain: boolean;
    onDomainSet?: Function;
    reloadDatasource?: any;
}

const OwlerCompetitorContainer = ({
    domain,
    loadingDomain,
    onDomainSet,
    reloadDatasource,
}: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const [controlledDomain, setControlledDomain] = useState<string | undefined>(domain);
    const { loading, data: competitors } = useOwlerCompetitorList(controlledDomain, reload);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<IOwlerCompetitor[]>(competitors);
    const { width } = useWindowDimensions();
    const [renderCount, setRenderCount] = useState<number>(3);
    const { id: partnerId }: any = useParams();

    useEffect(() => {
        setControlledDomain(domain);
    }, [domain]);

    useEffect(() => {
        setData(competitors);
    }, [competitors]);

    useEffect(() => {
        setReload({ reload: true });
    }, [reloadDatasource, partnerId]);

    useEffect(() => {
        if (width) {
            if (width > 1200 && width < 1300) {
                setRenderCount(2);
            } else if (width > 1300 && width < 1450) {
                setRenderCount(3);
            } else if (width > 992 && width < 1168) {
                setRenderCount(3);
            } else {
                setRenderCount(4);
            }
        }
    }, [width]);

    const renderFeed = (itemRenderCount: number) => {
        return (
            <List
                style={{ marginBottom: -8 }}
                pagination={
                    data.length > 10
                        ? {
                              pageSize: 10,
                              position: "bottom",
                              hideOnSinglePage: true,
                              align: "center",
                          }
                        : false
                }
                size="small"
                itemLayout="horizontal"
                dataSource={data.slice(0, itemRenderCount)}
                renderItem={(item, index) =>
                    item && (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <Flex justify="start">
                                        <Flex vertical justify="center">
                                            <Tooltip title={item.shortName}>
                                                <Image width={50} src={item.logoUrl} />
                                            </Tooltip>
                                        </Flex>

                                        <a
                                            style={{ marginLeft: 16, marginRight: 0, width: 300 }}
                                            href={
                                                item && item.website.indexOf("https") === -1
                                                    ? `https://${item.website}`
                                                    : item && item.website
                                            }
                                            target="_blank"
                                            rel="noreferrer">
                                            {item.name}
                                        </a>
                                        <div
                                            style={{ marginLeft: 16, marginRight: 0, width: 200 }}
                                            className={classes.scoreText}>
                                            {((item.score / 100000) * 100).toPrecision(3)}%
                                        </div>
                                    </Flex>
                                }
                                description={null}
                            />
                        </List.Item>
                    )
                }
            />
        );
    };

    return (
        <>
            <Card
                className={classes.owlerCard}
                bodyStyle={{ overflow: "hidden", height: 254 }}
                size="small"
                bordered={false}
                title={
                    <Flex justify="space-between">
                        <div className={classes.cardTitle}>
                            {t("owler_Competitors").toLocaleUpperCase()}
                        </div>
                        <Tooltip title={t("owler_FirmographicInfoAdditionalInfo")}>
                            <span>
                                <Expand01
                                    id="owlerCompetitorExpandIcon"
                                    onClick={() => domain && data && setShowModal(true)}
                                    className={classes.expandIcon}
                                />
                            </span>
                        </Tooltip>
                    </Flex>
                }>
                <div>
                    {!loading && domain && data[0] && (
                        <div>
                            <div>{renderFeed(renderCount)}</div>
                            <OwlerCardFooter
                                modifiedValue={data[0] && data[0].competitorModified}
                            />
                        </div>
                    )}
                    <Skeleton
                        active={loadingDomain === true || loading === true}
                        loading={loadingDomain === true || loading === true}
                    />
                    {!loadingDomain &&
                        (domain === undefined ||
                            domain === null ||
                            (domain !== undefined && domain !== null && !data)) && (
                            <Flex justify="center" className={classes.domainInputContainer}>
                                <Flex vertical justify="center">
                                    <OwlerDomainInput
                                        domain={domain}
                                        data={data}
                                        onDomainChange={(setDomain: string | undefined) => {
                                            if (setDomain) {
                                                setReload({ reload: true });
                                                onDomainSet && onDomainSet(setDomain);
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        )}
                </div>
            </Card>
            <Modal
                destroyOnClose
                width={800}
                open={showModal}
                title={
                    <Flex justify="start" style={{ marginTop: 22 }}>
                        <div style={{ width: 380, color: "#1378a7" }}>
                            {t("owler_Competitors").toLocaleUpperCase()}
                        </div>

                        <div
                            style={{
                                marginLeft: 16,
                                marginRight: 0,
                                width: 200,
                                color: "#1378a7",
                            }}>
                            {t("owler_Score").toLocaleUpperCase()}{" "}
                            <Tooltip title={t("owler_ScoreTooltip")}>
                                <span>
                                    <InfoCircle />
                                </span>
                            </Tooltip>
                        </div>
                    </Flex>
                }
                footer={null}
                onCancel={() => {
                    setShowModal(false);
                }}>
                {domain && data && <>{renderFeed(data.length)}</>}
            </Modal>
        </>
    );
};

export { OwlerCompetitorContainer };
