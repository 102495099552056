export function getTableSize() {
    let windowInnerHeight: number = window.innerHeight;
    let headerOuterHeight: number = document.getElementById("headerSection")
        ? document.getElementById("headerSection")!.clientHeight
        : 0;
    let pageTitleHeight: number = document.getElementById("pageTitle")
        ? document.getElementById("pageTitle")!.clientHeight
        : 0;
    let paginationHeight = 25;
    let defaultRowHeight: number = 39;
    let tableHeight: number = 0;
    let pageSize: number = 0;
    let excess: number = 0;

    tableHeight = windowInnerHeight - headerOuterHeight - paginationHeight - pageTitleHeight - 250;
    pageSize = Math.round(tableHeight / defaultRowHeight);
    excess = headerOuterHeight + paginationHeight + 250;

    return { pageSize: pageSize, excess: excess };
}

export function getViewPort() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

export function isOnSelectionColumn(event: any) {
    return event.target.className.indexOf("selection-column") !== -1;
}
