import { useState, useEffect } from "react";
import { Provider } from "services";
import { useTranslation } from "react-i18next";

type StateType = {
    loading: boolean;
    data: any[];
};

const useReviewCategory = (categoryId = -1) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any = await Provider.Review.getReviewCategories(categoryId, {
                customError: {
                    message: t("review_GetCategoriesError"),
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            data = data.map((d: any) => ({
                ...d,
                id: d.children === null ? d.id : `group-${d.id}`,
                name: t(d.name),
                ...(d.children && {
                    children: d.children.map((x: any) => ({ ...x, name: t(x.name) })),
                }),
            }));
            setState({ loading: false, data: data });
        })();
    }, [categoryId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useReviewCategory };
