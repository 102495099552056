import React from "react";
import { ChromePicker } from "react-color";
import classes from "./colorInput.module.scss";
import { Row, Col } from "antd";
import i18n from "i18n";

interface IProps {
    value?: string;
    onChange?(colorHex: string): void;
    showColorSuggestions?: boolean;
    suggestedColors?: string[];
    width?: string;
}

interface IState {
    displayColorPicker: boolean;
    FixedColors: string[];
    color?: string;
    colorTextBoxStyle: {
        height: string;
        borderRadius: string;
        borderBottom: string;
        paddingLeft: string;
        width: string;
    };
}

class ColorInput extends React.Component<IProps, IState> {
    public static defaultProps = {
        showColorSuggestions: true,
        suggestedColors: [],
    };
    constructor(props: IProps) {
        super(props);
        this.state = {
            displayColorPicker: false,
            FixedColors: [
                "#f54b42",
                "#f5dd42",
                "#00b203",
                "#663399",
                "#F416FA",
                "#FA6416",
                "#FCA17E",
            ],
            color: props.value,
            colorTextBoxStyle: {
                height: "35px",
                borderRadius: "3px",
                borderBottom: "1px solid lightgray",
                paddingLeft: "10px",
                width: "80%",
            },
        };
    }

    //componentWillReceiveProps
    componentDidUpdate(prevProps: IProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                color: this.props.value,
            });
        }
    }

    onChangeColorPicker = (color: any) => {
        let alpha = "";
        if (color.rgb.a !== 1) {
            alpha = Math.round(color.rgb.a * 255).toString(16);
            if (alpha.length === 1) alpha = "0" + alpha;
        }

        this.props.onChange!(color.hex + alpha);
        this.setState({
            color: color.hex + alpha,
        });
    };

    onHandleShowColorPicker = () => {
        this.setState({
            displayColorPicker: true,
        });
    };

    onHandleCloseColorPicker = () => {
        this.setState({
            displayColorPicker: false,
        });
    };

    getColorLi = (id: number) => {
        this.props.onChange!(this.state.FixedColors[id].toString());
        this.setState({
            color: this.state.FixedColors[id],
        });
    };

    render() {
        return (
            <Row style={{ width: this.props.width ? this.props.width : "" }}>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <div>
                                <input
                                    data-test="color-picker-text_input"
                                    style={this.state.colorTextBoxStyle}
                                    type="text"
                                    name="color-picker-text"
                                    value={this.state.color}
                                    onClick={() => this.onHandleShowColorPicker()}
                                    onChange={() => {}}
                                    className="ant-input"
                                />
                                <span
                                    data-test="color_picker_button"
                                    className={classes.colorBox}
                                    onClick={() => this.onHandleShowColorPicker()}>
                                    <i
                                        style={{
                                            background: this.state.color,
                                        }}
                                        className={classes.iColor}
                                    />
                                </span>
                            </div>
                            {this.state.displayColorPicker && (
                                <div className={classes.colorPickerPalette}>
                                    <div
                                        className={classes.colorPickerCover}
                                        onClick={() => this.onHandleCloseColorPicker()}
                                    />
                                    <ChromePicker
                                        color={this.state.color}
                                        onChange={this.onChangeColorPicker}
                                        disableAlpha
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                    {this.props.showColorSuggestions && (
                        <Row>
                            <Col span={this.props.suggestedColors?.length === 0 ? 24 : 0}>
                                {this.props.showColorSuggestions &&
                                    this.props.suggestedColors?.length === 0 && (
                                        <div className={classes.defaultColorContainer}>
                                            <ul>
                                                <li
                                                    data-test="red_color_button"
                                                    style={{
                                                        backgroundColor: "rgb(245, 75, 66)",
                                                    }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[0]}
                                                    onClick={this.getColorLi.bind(this, 0)}
                                                />
                                                <li
                                                    data-test="yellow_color_button"
                                                    style={{
                                                        backgroundColor: "rgb(245, 221, 66)",
                                                    }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[1]}
                                                    onClick={this.getColorLi.bind(this, 1)}
                                                />
                                                <li
                                                    data-test="green_color_button"
                                                    style={{
                                                        backgroundColor: "rgb(0, 178, 3)",
                                                    }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[2]}
                                                    onClick={this.getColorLi.bind(this, 2)}
                                                />
                                                <li
                                                    data-test="purple_color_button"
                                                    style={{
                                                        backgroundColor: "rgb(102, 51, 153)",
                                                    }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[3]}
                                                    onClick={this.getColorLi.bind(this, 3)}
                                                />
                                                <li
                                                    data-test="pink_color_button"
                                                    style={{ backgroundColor: "#F416FA" }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[4]}
                                                    onClick={this.getColorLi.bind(this, 4)}
                                                />
                                                <li
                                                    data-test="orange_color_button"
                                                    style={{ backgroundColor: "#FA6416" }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[5]}
                                                    onClick={this.getColorLi.bind(this, 5)}
                                                />
                                                <li
                                                    data-test="cream_color_button"
                                                    style={{ backgroundColor: "#FCA17E" }}
                                                    className={classes.li}
                                                    value={this.state.FixedColors[6]}
                                                    onClick={this.getColorLi.bind(this, 6)}
                                                />
                                            </ul>
                                        </div>
                                    )}
                            </Col>
                            <Col span={this.props.suggestedColors?.length === 0 ? 0 : 24}>
                                {this.props.showColorSuggestions && this.props.suggestedColors && (
                                    <Row>
                                        <Col span={5} className={classes.defaultLabel}>
                                            {i18n.t("generic_Default")}:
                                        </Col>
                                        <Col span={19}>
                                            <div className={classes.suggestedColors}>
                                                <ul>
                                                    {this.props.suggestedColors.map(color => (
                                                        <li
                                                            style={{ backgroundColor: color }}
                                                            className={classes.li}
                                                            value={color}
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    color: color,
                                                                });
                                                                this.props.onChange &&
                                                                    this.props.onChange(color);
                                                            }}></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        );
    }
}

export { ColorInput };
