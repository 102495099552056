import { ISurveyAggregate } from "models/survey/surveySubmissionMatrix/ISurveyAggregate";
import { useEffect, useState } from "react";
import Provider from "services/api";

const useSurveySubmissionAggregate = (surveyToken: string, contactId: string | undefined) => {
    const [state, setState] = useState<{
        loading: boolean;
        data?: { surveyAggregate: ISurveyAggregate; isAnswered: boolean };
    }>({
        loading: true,
        data: undefined,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            let data: {
                item1: ISurveyAggregate;
                item2: boolean;
            } = await Provider.Survey.getSurveySubmissionAggregate(surveyToken, contactId, {
                retryCount: 3,
                customError: { message: "Could not retrieve survey" },
            }).catch((err: any) => {
                setState({ loading: false, data: undefined });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data: data ? { surveyAggregate: data.item1, isAnswered: data.item2 } : undefined,
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data?.surveyAggregate,
        isAnswered: state.data?.isAnswered,
    };
};

export { useSurveySubmissionAggregate };
