import { useState } from "react";
import { Provider } from "services";
import { ICreateNcrReview } from "models";

const useNcrCreateReview = () => {
    const [loading, setLoading] = useState(false);

    const createReview = async (review: ICreateNcrReview) => {
        setLoading(true);
        const success = await Provider.Ncr.createReview(review, {
            customError: { message: `Could not create review` },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return success;
    };

    return {
        loading,
        createReview,
    };
};

export { useNcrCreateReview };
