import { useState, useEffect } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useSurveyTemplates = (reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any = await Provider.Survey.getSurveyTemplates({
                customError: {
                    message: "Could not get survey templates",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            data = data.map((d: any) => ({
                ...d,
                id: d.children === null ? d.id : `group-${d.id}`,
            }));
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSurveyTemplates };
