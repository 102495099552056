import React, { useState, useEffect } from "react";
import { MultipleTags } from "components/utilitycomponents";
import classes from "./categories.module.scss";
import { usePartnerCategoriesByPartnerId, useIsOwner, useMandatoryPartnerFields } from "controller";
import { Button, Col, Divider, Row, Skeleton, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { CategoryModal } from "./categoryModal";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface IProps {
    partnerId: number;
}

const Categories = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading } = usePartnerCategoriesByPartnerId(partnerId, reload, "SecondCategory");
    const [modal, setModal] = useState<boolean>(false);
    const user = useSelector((state: AppState) => state.oidc.user);
    const onlOwnersCanEditPartner = user.profile["ll-AllowOnlyOwnersToEditPartnerData"] === "true";
    const isAdmin = user.profile["ll-Admin"] === "true" ? true : false;
    const { data: currentUserIsOwner } = useIsOwner(partnerId, reload);
    const { data: partnerFields, loading: loadingPartnerFields } = useMandatoryPartnerFields();

    useEffect(() => {
        setReload({ reload: true });
    }, [partnerId]);

    return (
        <>
            <div className={classes.categoriesContainer}>
                <div className={classes.titleContainer}>
                    <Row gutter={8}>
                        <Col span={20}>
                            <span>
                                {loadingPartnerFields ? (
                                    <Skeleton />
                                ) : partnerFields?.category2 ? (
                                    partnerFields.category2.customLabel ? (
                                        partnerFields.category2.customLabel.toUpperCase()
                                    ) : (
                                        partnerFields.category2.standardLabel.toUpperCase()
                                    )
                                ) : (
                                    t("category2").toUpperCase()
                                )}
                            </span>
                        </Col>
                        <Col span={4} style={{ textAlign: "right" }}>
                            {((onlOwnersCanEditPartner && currentUserIsOwner) || isAdmin) &&
                                data.length !== 0 && (
                                    <Button
                                        type="link"
                                        onClick={() => setModal(true)}
                                        style={{ marginTop: -8 }}>
                                        {t("generic_Add")}
                                    </Button>
                                )}
                        </Col>
                    </Row>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.contentContainer}>
                    <Spin spinning={loading}>
                        {data.length > 0 ? (
                            <MultipleTags values={data} tagNumber={3} color="#40a9ff" />
                        ) : (
                            ((onlOwnersCanEditPartner && currentUserIsOwner) || isAdmin) && (
                                <Row>
                                    <Col push={9} span={4}>
                                        <Button type="primary" onClick={() => setModal(true)}>
                                            {`${t("generic_Add")} ${t("generic_Category")}`}
                                        </Button>
                                    </Col>
                                </Row>
                            )
                        )}
                    </Spin>
                </div>
            </div>
            <CategoryModal
                visible={modal}
                onClose={(reload: boolean) => {
                    setModal(false);
                    if (reload === true) {
                        setReload({ reload: true });
                    }
                }}
                categories={data}
                partnerId={partnerId}
            />
        </>
    );
};

export { Categories };
