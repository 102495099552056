import React, { useEffect, useState, forwardRef } from "react";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InfoCircle, Plus, SelectFlatTreeData, TreeTransfer } from "components/utilitycomponents";
import { useContactsWithGroups } from "controller";
import { Modules } from "models";
import { CreateModal } from "../../../../contacts/shared/createModal";
import classes from "./selectRespondent.module.scss";

type PropTypes = {
    clearData: any;
    onSelectedContactsChange: Function;
    defaultValues: number[];
};
const SelectInternalRespondents = forwardRef(
    ({ clearData, onSelectedContactsChange, defaultValues }: PropTypes, ref) => {
        const { t } = useTranslation();
        const [reload, setReload] = useState<any>(null);
        const { data, loading } = useContactsWithGroups(
            ["Administrator", "User", "LightUser"],
            Modules.Review,
            reload
        );
        const [createModal, setCreateModal] = useState<any>({
            visible: false,
            newContacts: [],
        });
        const [addedContacts, setAddedContacts] = useState<number[]>([]);

        useEffect(() => {
            if (defaultValues && defaultValues.length > 0) {
                setAddedContacts(defaultValues);
                onSelectedContactsChange(defaultValues);
            }
        }, defaultValues);

        useEffect(() => {
            setAddedContacts(createModal.newContacts);
        }, [data]);

        useEffect(() => {
            onSelectedContactsChange([]);
        }, [clearData]);

        return (
            <>
                <TreeTransfer
                    data={data}
                    selectOptionField="name"
                    selectOnlyChildren
                    onTransferChange={(ids: number[]) => onSelectedContactsChange(ids)}
                    clearData={clearData}
                    injectedTargetKeys={defaultValues ? defaultValues : addedContacts}>
                    {({
                        direction,
                        selectedSourceKeys,
                        selectedTargetKeys,
                        targetKeys,
                        targetData,
                        removeSourceCheckedKeys,
                        removeTargetCheckedKeys,
                        removeTargetSelectAll,
                        removeSourceSelectAll,
                        setSelectedSourceKeys,
                        setSelectedTargetKeys,
                        removeTargetSearch,
                    }: any) => {
                        if (direction === "left") {
                            return (
                                <SelectFlatTreeData
                                    title={t("Contact", { count: 2 })}
                                    placeholder="Search Name or Email"
                                    selectAll
                                    selectOnlyChildren
                                    useData={() => ({
                                        loading,
                                        data,
                                    })}
                                    selectOptionField={(item: any) => {
                                        return {
                                            node: (
                                                <span
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "270px",
                                                        display: "block",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                    }}
                                                    title={
                                                        item.emailAddress
                                                            ? item.emailAddress
                                                            : item.name
                                                    }>
                                                    {item.name +
                                                        (item.emailAddress
                                                            ? ` (${item.emailAddress})`
                                                            : "")}
                                                </span>
                                            ),
                                            text:
                                                item.name +
                                                (item.emailAddress ? item.emailAddress : ""),
                                            disabled:
                                                (item.children &&
                                                    !item.children.some(
                                                        (child: any) =>
                                                            !targetKeys.includes(child.id)
                                                    )) ||
                                                (!item.children && targetKeys.includes(item.id)),
                                        };
                                    }}
                                    height={350}
                                    onCheck={(checked: any, info: any) => {
                                        info.node.id = Array.isArray(info.node.id)
                                            ? info.node.id
                                            : [info.node.id];
                                        if (info.checked === true || info.selected === true) {
                                            setSelectedSourceKeys([
                                                ...selectedSourceKeys,
                                                ...info.node.id,
                                            ]);
                                        } else {
                                            setSelectedSourceKeys(
                                                selectedSourceKeys.filter(
                                                    (s: any) => !info.node.id.includes(s)
                                                )
                                            );
                                        }
                                    }}
                                    onMultipleCheck={(ids: any[]) => {
                                        setSelectedSourceKeys(ids);
                                    }}
                                    blockNode
                                    checkable
                                    checkStrictly
                                    removeCheckedKeys={removeSourceCheckedKeys}
                                    removeSelectAll={removeSourceSelectAll}
                                    removeSearch={removeTargetSearch}
                                />
                            );
                        }
                        if (direction === "right") {
                            return (
                                <SelectFlatTreeData
                                    selectAll
                                    selectOnlyChildren
                                    title={t("survey_Wizard_SelectedRespondents")}
                                    useData={() => ({
                                        loading: false,
                                        data: targetData,
                                    })}
                                    selectOptionField={(item: any) => {
                                        return {
                                            node: (
                                                <span
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "270px",
                                                        display: "block",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                    }}
                                                    title={
                                                        item.emailAddress
                                                            ? item.emailAddress
                                                            : item.name
                                                    }>
                                                    {item.name +
                                                        (item.emailAddress
                                                            ? ` (${item.emailAddress})`
                                                            : "")}
                                                </span>
                                            ),
                                            text:
                                                item.name +
                                                (item.emailAddress ? item.emailAddress : ""),
                                        };
                                    }}
                                    height={350}
                                    onCheck={(checked: any, info: any) => {
                                        if (info.checked === true || info.selected === true) {
                                            setSelectedTargetKeys([
                                                ...selectedTargetKeys,
                                                info.node.id,
                                            ]);
                                        } else {
                                            setSelectedTargetKeys(
                                                selectedTargetKeys.filter(
                                                    (s: any) => s !== info.node.id
                                                )
                                            );
                                        }
                                    }}
                                    onMultipleCheck={(ids: any[]) => {
                                        setSelectedTargetKeys(ids);
                                    }}
                                    blockNode
                                    checkable
                                    checkStrictly
                                    removeCheckedKeys={removeTargetCheckedKeys}
                                    removeSelectAll={removeTargetSelectAll}
                                    removeSearch={removeTargetSearch}
                                />
                            );
                        }
                    }}
                </TreeTransfer>
                <div style={{ paddingTop: "10px", paddingBottom: "30px", marginBottom: "15px" }}>
                    <Button
                        icon={<Plus />}
                        onClick={() => setCreateModal({ newContacts: [], visible: true })}>
                        {t("Contacts_Add")}
                    </Button>

                    <Tooltip title={t("survey_wizard_AddContactTooltip")}>
                        <span>
                            <InfoCircle className={classes.tooltipIcom} />
                        </span>
                    </Tooltip>
                </div>
                {createModal.visible && (
                    <CreateModal
                        visible={createModal.visible}
                        onClose={(reload, contactId) => {
                            if (reload) {
                                setCreateModal({ newContacts: [contactId], visible: false });
                                setReload({ reload: true });
                            } else {
                                setCreateModal({ newContacts: [], visible: false });
                            }
                        }}
                    />
                )}
            </>
        );
    }
);

export default SelectInternalRespondents;
