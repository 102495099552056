import React, { FunctionComponent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tag } from "antd";
import { useComplianceElements } from "controller";
import { ReviewRate, LoadingIndicator, ColoredPercentTag } from "components/utilitycomponents";
import RegistrationsModal from "./registrationsModal";
import dayjs from "utilities/daysJsConfig";
import { formatTime } from "utilities";

type PropsType = {
    partnerId: number;
    range: {
        from: string;
        to: string;
    };
};

const ComplianceElements: FunctionComponent<PropsType> = ({ partnerId, range }) => {
    const { t } = useTranslation();
    const [registrationsModal, setRegistrationsModal] = useState<any>({
        data: {},
        visible: false,
    });

    const { data, loading } = useComplianceElements(partnerId, undefined, undefined);

    if (loading) return <LoadingIndicator />;
    if (!data) return null;

    return (
        <Fragment>
            {data.length === 0 && <span>{t("supplierProfile_ComplianceNoRegs")}</span>}
            {data.length !== 0 &&
                data.map((templateGroup, index) => {
                    const column = [
                        {
                            title: templateGroup.templateGroup,
                            dataIndex: "name",
                            width: 100,
                        },
                        {
                            title: t("supplierProfile_ComplianceRating"),
                            dataIndex: "rating",
                            width: 100,
                            render: (review: number) => <ReviewRate val={review} />,
                        },
                        {
                            title: t("compliance_FillRate"),
                            dataIndex: "score",
                            width: 100,
                            render: (score: number) =>
                                score && (
                                    <div className="alignCenter">
                                        <ColoredPercentTag value={score} />
                                    </div>
                                ),
                        },
                        {
                            title: t("compliance_ComplianceScore"),
                            dataIndex: "complianceAverageScore",
                            width: 100,
                            render: (score: number) =>
                                score && (
                                    <div className="alignCenter">
                                        <ColoredPercentTag value={score} />
                                    </div>
                                ),
                        },
                        {
                            title: t("compliance_EndDate"),
                            dataIndex: "complianceEndDate",
                            width: 150,
                            render: (_: any, record: any) => {
                                if (record.compliances && record.compliances.length > 0) {
                                    let latestEndDate = record.compliances
                                        .filter((x: any) => x)
                                        .slice(-1)[0]?.endDate;
                                    return (
                                        <div>
                                            {latestEndDate ? (
                                                dayjs().isAfter(latestEndDate) ? (
                                                    <Tag color="#F19306" bordered={false}>
                                                        {t("scorecard_State_Expired")}{" "}
                                                        {formatTime(latestEndDate)}
                                                    </Tag>
                                                ) : (
                                                    latestEndDate &&
                                                    formatTime(latestEndDate, "MMM D, YYYY")
                                                )
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    );
                                } else {
                                    return <div>-</div>;
                                }
                            },
                        },
                    ];
                    return (
                        <Table
                            key={index}
                            style={{
                                paddingTop: 10,
                            }}
                            rowKey="name"
                            onRow={(record: any) => {
                                return {
                                    onClick: () => {
                                        setRegistrationsModal({
                                            visible: true,
                                            data: record,
                                        });
                                    },
                                    style: { cursor: "pointer" },
                                };
                            }}
                            columns={column}
                            dataSource={templateGroup.templates}
                            size="middle"
                            pagination={false}
                            bordered
                        />
                    );
                })}
            <RegistrationsModal
                visible={registrationsModal.visible}
                data={registrationsModal.data}
                onCancel={() => setRegistrationsModal({ data: {}, visible: false })}
            />
        </Fragment>
    );
};

export default ComplianceElements;
