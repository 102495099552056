import dayjs from "utilities/daysJsConfig";

export function downloadExcelExport(response: any, pathName: string) {
    let fileName = parseFileName(pathName);
    let blob = new Blob([response.data ? response.data : response], { type: ".xlsx" });
    if (window.navigator.msSaveOrOpenBlob !== undefined) {
        //If we are on IE
        window.navigator.msSaveBlob(blob, `${fileName}.xlsx`);
    } else {
        var elem = window.document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        elem.href = url;
        elem.download = `${fileName}.xlsx`;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(url);
    }
}

function parseFileName(pathName: string) {
    let newFileName: string = `Export_${dayjs().format("DD-MM-YYYY_HHmmss")}`;
    if (pathName.includes("Partners")) {
        newFileName = `Partners_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("Compliance")) {
        newFileName = `Compliance_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("Ncr")) {
        newFileName = `NCR_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("/Reviews/SurveyOverview")) {
        newFileName = `SurveyOverview_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("NonResponseOverview")) {
        newFileName = `NonResponseOverview_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("/Reviews/AutomatedSurveyOverview")) {
        newFileName = `AutomatedSurveyOverview_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("Reviews")) {
        newFileName = `Reviews_${newFileName}`;
        return newFileName;
    } else if (pathName.includes("Contact")) {
        newFileName = `Contact_${newFileName}`;
        return newFileName;
    } else {
        return newFileName;
    }
}

export function showDownloadDialog(data: any, fileName: string) {
    let blob = new Blob([data]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    }
}
