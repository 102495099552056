import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./treeTransfer.module.scss";
import { ChevronLeft, ChevronRight } from "components/utilitycomponents/icons";

type PropTypes = {
    selectOptionField: string;
    selectIdField?: string;
    selectOnlyChildren?: boolean;
    data: any;
    clearData?: any;
    injectedTargetKeys: number[];
    operations?: string[];
    onTransferChange: Function;
    children: any;
};
const TreeTransfer: FunctionComponent<PropTypes> = ({
    selectIdField = "id",
    selectOptionField,
    selectOnlyChildren = false,
    data,
    clearData,
    injectedTargetKeys,
    operations,
    onTransferChange,
    children,
}) => {
    const { t } = useTranslation();
    const [selectedSourceKeys, setSelectedSourceKeys] = useState<any>([]);
    const [selectedTargetKeys, setSelectedTargetKeys] = useState<any>([]);
    const [targetKeys, setTargetKeys] = useState<any>([]);
    const [targetData, setTargetData] = useState<any>([]);
    const [removeSourceCheckedKeys, setRemoveSourceCheckedKeys] = useState<any>(null);
    const [removeTargetCheckedKeys, setRemoveTargetCheckedKeys] = useState<any>(null);
    const [removeTargetSelectAll, setRemoveTargetSelectAll] = useState<any>(null);
    const [removeSourceSelectAll, setRemoveSourceSelectAll] = useState<any>(null);
    const [removeTargetSearch, setRemoveTargetSearch] = useState<any>(null);

    useEffect(() => {
        setTargetData(
            memoizedFlattenData.filter((item: any) => {
                return targetKeys.includes(item[selectIdField]);
            })
        );
        onTransferChange(targetKeys);
    }, [targetKeys, data]);

    useEffect(() => {
        setSelectedSourceKeys([]);
        setSelectedTargetKeys([]);
        setTargetKeys([]);
        setTargetData([]);
        setRemoveSourceCheckedKeys({ remove: true });
        setRemoveTargetCheckedKeys({ remove: true });
        setRemoveTargetSelectAll({ remove: true });
        setRemoveSourceSelectAll({ remove: true });
        setRemoveTargetSearch({ remove: true });
    }, [clearData]);

    useEffect(() => {
        if (injectedTargetKeys.length) {
            setTargetKeys([...targetKeys, ...injectedTargetKeys]);
        }
    }, [injectedTargetKeys]);

    const flatten = (list: any[]) => {
        let flatList: any = [];

        if (list) {
            list.forEach((item: any) => {
                if (!selectOnlyChildren || !item.children) {
                    flatList = [
                        ...flatList,
                        {
                            ...item,
                            id: item[selectIdField],
                            name: item[selectOptionField],
                            children: null,
                        },
                    ];
                }
                flatList = [...flatList, ...flatten(item.children)];
            });
        }
        return flatList;
    };
    const removeDuplicateChildren = (flattenData: any) => {
        let ids: any = [];
        return flattenData.filter((item: any) => {
            const toRemove = ids.includes(item[selectIdField]);
            ids = [...ids, item[selectIdField]];

            return !toRemove;
        });
    };

    const memoizedFlattenData = useMemo(() => removeDuplicateChildren(flatten(data)), [data]);

    return (
        <>
            <div className={classes.treeTransfer}>
                <div className={classes.treeTransferList}>
                    {children({
                        direction: "left",
                        selectedSourceKeys,
                        selectedTargetKeys,
                        targetKeys,
                        targetData,
                        removeSourceCheckedKeys,
                        removeTargetCheckedKeys,
                        removeTargetSelectAll,
                        removeSourceSelectAll,
                        removeTargetSearch,
                        setSelectedSourceKeys,
                        setSelectedTargetKeys,
                        setTargetKeys,
                        setTargetData,
                        setRemoveSourceCheckedKeys,
                        setRemoveTargetCheckedKeys,
                        setRemoveTargetSelectAll,
                        setRemoveSourceSelectAll,
                    })}
                </div>
                <div className={classes.treeTransferOperation}>
                    <Button
                        className={classes.treeTransferButton}
                        disabled={selectedSourceKeys.length === 0}
                        onClick={() => {
                            const uniqueTargetKeys = [
                                ...new Set([...targetKeys, ...selectedSourceKeys]),
                            ];
                            setTargetKeys(uniqueTargetKeys);
                            setSelectedSourceKeys([]);
                            setRemoveSourceCheckedKeys({ remove: true });
                            setRemoveSourceSelectAll({ remove: true });
                        }}>
                        {operations && operations.length >= 1
                            ? operations[0]
                            : t("treeTransfer_AddToSelected")}
                        <ChevronRight
                            className={classes.chevron}
                            style={{
                                color: selectedSourceKeys.length === 0 ? "#00000040" : undefined,
                            }}
                        />
                    </Button>
                    <Button
                        className={classes.treeTransferButton}
                        disabled={selectedTargetKeys.length === 0}
                        onClick={() => {
                            setTargetKeys(
                                targetKeys.filter((k: any) => !selectedTargetKeys.includes(k))
                            );
                            setSelectedTargetKeys([]);
                            setRemoveTargetCheckedKeys({ remove: true });
                            setRemoveTargetSelectAll({ remove: true });
                            setRemoveTargetSearch({ remove: true });
                        }}>
                        <ChevronLeft
                            className={classes.chevron}
                            style={{
                                color: selectedSourceKeys.length === 0 ? "#00000040" : undefined,
                            }}
                        />
                        {operations && operations.length >= 2
                            ? operations[0]
                            : t("treeTransfer_RemoveSelected")}
                    </Button>
                </div>
                <div className={classes.treeTransferList}>
                    {children({
                        direction: "right",
                        selectedSourceKeys,
                        selectedTargetKeys,
                        targetKeys,
                        targetData,
                        removeSourceCheckedKeys,
                        removeTargetCheckedKeys,
                        removeTargetSelectAll,
                        removeSourceSelectAll,
                        removeTargetSearch,
                        setSelectedSourceKeys,
                        setSelectedTargetKeys,
                        setTargetKeys,
                        setTargetData,
                        setRemoveSourceCheckedKeys,
                        setRemoveTargetCheckedKeys,
                        setRemoveTargetSelectAll,
                        setRemoveSourceSelectAll,
                    })}
                </div>
            </div>
        </>
    );
};

export { TreeTransfer };
