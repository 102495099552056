import React, { FunctionComponent } from "react";
import { Button } from "antd";

type PropsType = {
    icon?: any;
    iconColor?: string;
    loading?: boolean;
    [key: string]: any;
};
const GridButton: FunctionComponent<PropsType> = ({
    icon,
    iconColor,
    loading,
    children,
    ...rest
}) => {
    const buttonStyle = rest.style;
    delete rest.style;

    return (
        <Button
            style={{
                ...buttonStyle,
                marginRight: "5px",
            }}
            loading={loading}
            {...rest}>
            {icon && (
                <span
                    style={{
                        ...(!rest.disabled && iconColor && { color: iconColor }),
                    }}>
                    <span style={{ marginRight: 4 }}>{icon}</span>
                </span>
            )}
            {children}
        </Button>
    );
};

export { GridButton };
