import { useState } from "react";
import Provider from "services/api";

const useGovernanceTaskTitleInUse = () => {
    const [loading, setLoading] = useState(false);

    const isTitleUsed = async (title: string, segmentId: number) => {
        setLoading(true);
        const inUse = await Provider.Governance.isTitleInUse(title, segmentId, {
            customError: {
                message: "Could not check if title exists",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return inUse;
    };

    return {
        loading: loading,
        isTitleUsed,
    };
};

export { useGovernanceTaskTitleInUse };
