import { useState } from "react";
import { Provider } from "services";

const useTemplateGroupCategoryUpdate = () => {
    const [loading, setLoading] = useState(false);

    const updateCategory = async (templateGroupIds: number[], typeId?: string) => {
        setLoading(true);
        const id = await Provider.Review.UpdateSurveyTemplateGroupType(templateGroupIds, typeId, {
            customError: {
                message: "Could not update category",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        updateCategory,
    };
};

export { useTemplateGroupCategoryUpdate };
