import { Form, FormInstance, Tooltip } from "antd";
import { SelectData, SelectTreeDataMultiple } from "components/utilitycomponents";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { departments } from "../../localData/departments";
import { jobLevels } from "../../localData/jobLevels";
import { useCaseTree } from "../../localData/useCaseTree";
import classes from "../../styles/onBoarding.module.scss";
interface IProps {
    formRef?: React.RefObject<FormInstance>;
    setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
    step?: number;
    maxWidth?: number;
}
const SecondStep = ({ formRef, setDisable, step, maxWidth = 282 }: IProps) => {
    const { t } = useTranslation();
    const [useCaseValues, setUseCaseValues] = useState<any>([]);
    const [jobLevelValue, setJobLevelValue] = useState<number | undefined>();
    const [departmentValue, setDepartmentValue] = useState<number | undefined>();
    const checkFields = () => {
        const department = formRef?.current?.getFieldValue("department");
        const jobLevel = formRef?.current?.getFieldValue("jobLevel");
        const useCases = formRef?.current?.getFieldValue("useCases");

        if (department !== undefined && jobLevel !== undefined && useCases?.length > 0) {
            setDisable && setDisable(false);
        } else {
            setDisable && setDisable(true);
        }
    };
    useEffect(() => {
        checkFields();
    }, [step, formRef, useCaseValues, jobLevelValue, departmentValue]);
    const renderMaxTagPlaceholder = (omittedValues: any[]) => {
        const omittedItems = omittedValues
            .map(item => item.label)
            .filter(Boolean)
            .join(", ");

        return (
            <Tooltip title={omittedItems}>
                <span>+{omittedValues.length} more</span>
            </Tooltip>
        );
    };
    return (
        <>
            <Form.Item
                label={t("admin_UserRegistration_Department")}
                name="department"
                rules={[
                    {
                        required: true,
                        message: t("inputIsRequiredError"),
                    },
                ]}>
                <SelectData
                    useData={() => ({ loading: false, data: departments })}
                    selectIdField="value"
                    selectOptionField="label"
                    placeholder={t("user_Onboarding_DepartmentPlaceholder")}
                    onChange={(val: number) => {
                        setDepartmentValue(val);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t("admin_UserRegistration_JobLevel")}
                name="jobLevel"
                rules={[
                    {
                        required: true,
                        message: t("inputIsRequiredError"),
                    },
                ]}>
                <SelectData
                    useData={() => ({ loading: false, data: jobLevels })}
                    selectIdField="value"
                    selectOptionField="label"
                    placeholder={t("admin_UserRegistration_JobLevelPlaceholder")}
                    onChange={(val: number) => {
                        setJobLevelValue(val);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t("admin_UserRegistration_UseCase")}
                style={{ maxWidth: maxWidth }}
                className={classes.treeSelectInput}
                name="useCases"
                rules={[
                    {
                        required: true,
                        message: t("inputIsRequiredError"),
                    },
                ]}>
                <SelectTreeDataMultiple
                    maxTagPlaceholder={renderMaxTagPlaceholder}
                    maxTagCount={2}
                    placeholder={t("admin_UserRegistration_UseCasePlaceholder")}
                    treeDefaultExpandedKeys={[1, 8, 11, 14, 18, 21]}
                    selectOnlyChildren
                    selectIdField="value"
                    selectOptionField="title"
                    onChange={(val: any) => setUseCaseValues([...val])}
                    useData={() => {
                        return { data: useCaseTree, loading: false };
                    }}
                />
            </Form.Item>
        </>
    );
};

export default SecondStep;
