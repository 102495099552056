import { IAwardSetting } from "models";
import { useEffect, useState } from "react";
import Provider from "services/api";

type State = {
    loading: boolean;
    data: IAwardSetting[];
};

const useAwardsSettings = () => {
    const [state, setState] = useState<State>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Scorecard.getAwardSettings().catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);
    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useAwardsSettings };
