import ComplianceTemplate from "./complianceArea/complianceTemplate";
import Compliance from "./complianceArea/compliance";
import Partners from "./partnerArea/partners";
import UserTableSize from "./userArea/userTableSize";
import ColumnFilters from "./userArea/columnFilters";
import UserSettings from "./userArea/userSetting";
import CompanySettings from "./companyArea/companySettings";
import Activity from "./activityArea/activity";
import ComplianceStatus from "./complianceArea/complianceStatus";
import Ncr from "./ncrArea/ncrs";
import UIViewSetting from "./userArea/uiViewSetting";
import ListView from "./userArea/listView";
import Contacts from "./userArea/contacts";
import Review from "./reviewArea/reviews";
import Survey from "./reviewArea/surveys";
import File from "./fileArea/file";
import AzureFunction from "./azureFunction";
import PerformanceOverview from "./partnerArea/performanceOverview";
import PartnerStatus from "./partnerArea/partnerStatus";
import AutomatedSurveys from "./reviewArea/automatedSurveys";
import StrategyAndGovernance from "./partnerArea/strategyAndGovernance";
import PartnerRisk from "./partnerArea/partnerRisk";
import Company from "./companyArea/company";
import Dashboard from "./analytics/dashboard";
import Kpi from "./analytics/kpi";
import Matrix from "./complianceArea/matrix";
import Request from "./complianceArea/request";
import Contact from "./contactArea/contact";
import Folder from "./fileArea/folder";
import CompanyAuthenticationSettings from "./companyArea/companyAuthenticationSettings";
import Notification from "./notificationArea/notification";
import {
    AdminUser,
    AdminCompliance,
    AdminCompany,
    AdminPartner,
    AdminKpi,
    AdminContact,
    AdminSegmentation,
    AdminGovernance,
    AdminPerformanceSettings,
    AdminSupplierProfileSettings,
    AdminShortcutSettings,
} from "./adminArea";
import { AdminNcr } from "./adminArea/adminNcr";
import { AdminReview } from "./adminArea/adminReview";
import Action from "./actionArea/action";
import Locize from "./locize/locize";
import Common from "./commonArea/common";
import SelfAssessment from "./selfAssessmentArea/selfAssessment";
import UserAccount from "./userArea/userAccount";
import Segmentation from "./segmentationArea/segmentation";
import CommodityCategory from "./commodityCategoryArea/commodityCategory";
import { AdminExport } from "./adminArea/adminExport";
import { GovernanceTasks, MeetingForums } from "./Governance";
import MeetingMinutes from "./Governance/meetingMinutes";
import { Risks } from "./riskArea/risks";
import BusinessPartner from "./partnerArea/businessPartner";
import NewsLog from "./partnerArea/newslog";
import Performance from "./performanceArea/performance";
import DevelopmentPlan from "./developmentPlan/developmentPlan";
import AdminDevelopmentPlan from "./adminArea/adminDevelopmentPlan";
import SupplierInsights from "./partnerArea/supplierInsights";
import PartnerMetrics from "./partnerArea/partnerMetrics";
import Relationships from "./partnerArea/relationships";
import Sustainability from "./partnerArea/sustainability";
import PowerBi from "./powerBi/powerBi";
import SupplierAccessSettings from "./companyArea/supplierAccessSettings";
import { Links } from "./links/links";
import { SupplierPortal } from "./supplierPortal/supplierPortal";
import { UserLandingPage } from "./userLandingPageArea/userLandingPage";
import { Product } from "./product/product";
import Owler from "./owlerArea/owler";
import { AdminScorecard } from "./adminArea/adminScorecard";
import { Scorecard } from "./scorecard/scorecard";

const Provider = {
    Compliance: {
        ...ComplianceTemplate,
        ...Compliance,
        ...ComplianceStatus,
        ...Matrix,
        ...Request,
        ...AdminCompliance,
    },
    Partner: {
        ...Partners,
        ...PartnerStatus,
        ...PerformanceOverview,
        ...StrategyAndGovernance,
        ...PartnerRisk,
        ...AdminPartner,
        ...AdminSegmentation,
        ...BusinessPartner,
        ...NewsLog,
        ...SupplierInsights,
        ...PartnerMetrics,
        ...Sustainability,
    },
    User: {
        ...UserTableSize,
        ...ColumnFilters,
        ...UserSettings,
        ...UIViewSetting,
        ...ListView,
        ...Contacts,
        ...AdminUser,
        ...UserAccount,
    },
    Activity: {
        ...Activity,
    },
    Company: {
        ...CompanySettings,
        ...CompanyAuthenticationSettings,
        ...Company,
        ...AdminCompany,
        ...AdminExport,
        ...AdminShortcutSettings,
        ...SupplierAccessSettings,
    },
    Ncr: {
        ...Ncr,
        ...AdminNcr,
    },
    Review: {
        ...Review,
        ...AdminReview,
    },
    Survey: {
        ...Survey,
    },
    AutomatedSurvey: {
        ...AutomatedSurveys,
    },
    File: {
        ...File,
        ...Folder,
    },
    Contact: {
        ...Contact,
        ...AdminContact,
    },
    Notification: {
        ...Notification,
    },
    AzureFunction: {
        ...AzureFunction,
    },
    Analytics: {
        ...Dashboard,
        ...Kpi,
        ...AdminKpi,
    },
    Kpi: {
        ...AdminKpi,
    },
    Action: {
        ...Action,
    },
    Locize: {
        ...Locize,
    },
    Common: {
        ...Common,
    },
    SelfAssessment: {
        ...SelfAssessment,
    },
    Segmentation: {
        ...Segmentation,
    },
    Performance: {
        ...AdminPerformanceSettings,
        ...Performance,
    },
    CommodityCategory: {
        ...CommodityCategory,
    },
    Governance: {
        ...GovernanceTasks,
        ...MeetingMinutes,
        ...MeetingForums,
        ...AdminGovernance,
    },
    Risk: {
        ...Risks,
    },
    SupplierProfileSettings: {
        ...AdminSupplierProfileSettings,
    },
    DevelopmentPlan: {
        ...DevelopmentPlan,
        ...AdminDevelopmentPlan,
    },
    Relationships: {
        ...Relationships,
    },
    PowerBi: {
        ...PowerBi,
    },
    Links: {
        ...Links,
    },
    SupplierPortal: {
        ...SupplierPortal,
    },
    UserLandingPage: {
        ...UserLandingPage,
    },
    Product: {
        ...Product,
    },
    Owler: {
        ...Owler,
    },
    Scorecard: {
        ...AdminScorecard,
        ...Scorecard,
    },
};

export default Provider;
