export interface IRisk {
    id?: number;
    title: string;
    state?: RiskState;
    type: RiskType;
    partnerId?: number;
}

export enum RiskType {
    Highlight = 0,
    Issue = 1,
    Risk = 2,
}

export enum RiskState {
    Mitigated = 0,
    MitigationOnGoing = 1,
    NotMitigated = 2,
}
