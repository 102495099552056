import { get } from "../base";

const SupplierInsights = {
    getPartnerTotalRevenue: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/PartnerInsights/TotalRevenue?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    getPartnerRevenueBreakdown: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/PartnerInsights/GetRevenuePerBusinessLine?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    getPartnerFullTimeEmployees: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/PartnerInsights/GetFTECount?partnerId=${partnerId}`;
        return await get({ url, customError });
    },
    getPartnerEbit: async (
        partnerId: number,
        useEbitMargin: boolean,
        { customError }: any = {}
    ) => {
        const url = `/v1/PartnerInsights/PartnerEbit?supplierId=${partnerId}&useEbitMargin=${useEbitMargin}`;
        return await get({ url, customError });
    },
};

export default SupplierInsights;
