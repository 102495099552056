import { useState } from "react";
import { Provider } from "services";
import { ISegmentCategory as SegmentCategory } from "models";

const useSegmentCategoriesUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (values: any) => {
        setLoading(true);
        let segmentCategories: SegmentCategory[] = [];
        for (let i = 0; i < Object.values(values.id).length; i++) {
            segmentCategories.push({
                id: values.id[`segment${i + 1}`],
                segment: values.segment[`segment${i + 1}`],
                color: values.color[`segment${i + 1}`],
                xAreaDefinitionStart: values.xAreaDefinitionStart[`segment${i + 1}`],
                xAreaDefinitionEnd: values.xAreaDefinitionEnd[`segment${i + 1}`],
                yAreaDefinitionStart: values.yAreaDefinitionStart[`segment${i + 1}`],
                yAreaDefinitionEnd: values.yAreaDefinitionEnd[`segment${i + 1}`],
                xResultDefinition: values.xResultDefinition[`segment${i + 1}`],
                xResultDefinitionOperator: values.xResultDefinitionOperator[`segment${i + 1}`],
                yResultDefinition: values.yResultDefinition[`segment${i + 1}`],
                yResultDefinitionOperator: values.yResultDefinitionOperator[`segment${i + 1}`],
            });
        }

        const res = Provider.Partner.updateSegmentCategories(segmentCategories, {
            customError: {
                message: "Could not update segment categories",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        update,
    };
};

export { useSegmentCategoriesUpdate };
