/**
 *  https://github.com/react-component/table/blob/v6.10.1/src/utils.ts
 */

// let scrollbarVerticalSize: number;
// let scrollbarHorizontalSize: number;

// Measure scrollbar width for padding body during modal show/hide
const scrollbarMeasure: any = {
    position: "absolute",
    top: "-9999px",
    width: "50px",
    height: "50px",
};

function measureScrollbar({
    direction = "vertical",
    prefixCls = "ant-table",
}: {
    direction: "horizontal" | "vertical";
    prefixCls?: string;
}) {
    if (typeof document === "undefined" || typeof window === "undefined") {
        return 0;
    }
    const isVertical = direction === "vertical";
    //   if (isVertical && scrollbarVerticalSize) {
    //     return scrollbarVerticalSize;
    //   }

    //   if (!isVertical && scrollbarHorizontalSize) {
    //     return scrollbarHorizontalSize;
    //   }
    const scrollDiv = document.createElement("div");
    Object.keys(scrollbarMeasure).forEach((scrollProp: any) => {
        scrollDiv.style[scrollProp] = scrollbarMeasure[scrollProp];
    });
    // apply hide scrollbar className ahead
    scrollDiv.className = `${prefixCls}-hide-scrollbar scroll-div-append-to-body`;
    // Append related overflow style
    if (isVertical) {
        scrollDiv.style.overflowY = "scroll";
    } else {
        scrollDiv.style.overflowX = "scroll";
    }
    document.body.appendChild(scrollDiv);
    let size = 0;
    if (isVertical) {
        size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        //scrollbarVerticalSize = size;
    } else {
        size = scrollDiv.offsetHeight - scrollDiv.clientHeight;
        //scrollbarHorizontalSize = size;
    }

    document.body.removeChild(scrollDiv);
    return size;
}

export default measureScrollbar;
