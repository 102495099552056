import { useEffect, useState } from "react";
import Provider from "services/api";

const useSustainabilityScores = (partnerId: any) => {
    const [state, setState] = useState<any>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });

            let data: any = await Provider.Partner.getSustainabilityScores(partnerId, {
                customError: {
                    message: "Could not retrieve scores",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            data = data.map((item: any) => ({
                ...item,
                "Previous Year": item.previousYear,
                "Current Year": item.currentYear,
                Average: item.average,
            }));
            setState({ loading: false, data: data });
        })();
    }, [partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSustainabilityScores };
