import { ICreateEditAction } from "models/action";
import { useState } from "react";
import Provider from "services/api";

const useActionCRUD = () => {
    const [loading, setLoading] = useState(false);
    const [completeLoading, setCompleteLoading] = useState(false);
    const [creatingAction, setCreatingAction] = useState(false);

    const create = async (actionCreateInput: ICreateEditAction) => {
        setCreatingAction(true);
        const id = await Provider.Action.createAction(actionCreateInput, {
            customError: {
                message: "Could not create new action",
            },
        }).catch(err => {
            setCreatingAction(false);
            return Promise.reject(err);
        });
        setCreatingAction(false);
        return id;
    };

    const update = async (actionId: number, actionUpdateInput: ICreateEditAction) => {
        setLoading(true);
        await Provider.Action.updateAction(actionId, actionUpdateInput, {
            customError: {
                message: "Could not update the action",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const remove = async (ids: number[]) => {
        setLoading(true);
        const id = await Provider.Action.deleteActions(ids, {
            customError: {
                message: "Could not delete actions",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const complete = async (ids: number[]) => {
        setCompleteLoading(true);
        await Provider.Action.completeActions(ids, {
            customError: {
                message: "Could not complete action",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setCompleteLoading(false);
    };

    const open = async (ids: number[]) => {
        setCompleteLoading(true);
        await Provider.Action.openActions(ids, {
            customError: {
                message: "Could not re-open action",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setCompleteLoading(false);
    };

    return {
        completionLoading: completeLoading,
        creating: creatingAction,
        loading: loading,
        createAction: create,
        updateAction: update,
        deleteActions: remove,
        reOpenActions: open,
        completeActions: complete,
    };
};

export { useActionCRUD };
