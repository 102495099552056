import { ICompanyInformation } from "models/admin/company/ICompanyInformation";
import { useEffect, useState } from "react";
import { Provider } from "services";
type StateType = {
    loading: boolean;
    data: ICompanyInformation;
};

const useCompanyInformation = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: { owners: [], subsidiaries: [] },
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Company.getCompanyInformation().catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCompanyInformation };
