import {
    KpiUploadError,
    SelfAssessmentSent,
    VoiceOfSupplierSurvey,
    Review,
    SelfAssessment,
    Compliance,
    FolderSharedByPartner,
    InvitationAccepted,
    KpiUploaded,
    KpiUploadedOnMyCompanyId,
    Message,
    NewUser,
    Project,
    Ncr,
    KickStarter,
    PartnerUploadedFilesToSharedFolder,
    ClosedNcr,
    OpenNcr,
    ApprovalStatusChangeNcr,
    NextResponsibleNcr,
    MessageChild,
    FolderShared,
    MeetingMinute,
    UnknownNotification,
} from "./notificationTypes";
import { ActivityType, NotificationType, INotificationData } from "models";

const getNotification = (data: INotificationData) => {
    let ActivityTypeComponent: any;
    let activityDetail: any;

    switch (data.typeName) {
        case ActivityType.KickStarter:
            ActivityTypeComponent = KickStarter;
            activityDetail = data["kickStarterDetail"];
            break;
        case ActivityType.Message:
            if (data.messageDetail !== null) {
                ActivityTypeComponent = Message;
                activityDetail = data["messageDetail"];
            } else {
                ActivityTypeComponent = MessageChild;
                activityDetail = data["childActivityDetail"];
            }
            break;
        case ActivityType.InvitationAccepted:
            ActivityTypeComponent = InvitationAccepted;
            activityDetail = data["invitationAcceptedDetail"];
            break;
        case ActivityType.NewUser:
            ActivityTypeComponent = NewUser;
            activityDetail = data["newUserDetail"];
            break;
        case ActivityType.FolderShared:
            ActivityTypeComponent = FolderShared;
            activityDetail = data["folderSharedDetail"];
            break;
        case ActivityType.FolderSharedByPartner:
            ActivityTypeComponent = FolderSharedByPartner;
            activityDetail = data["folderSharedByPartnerDetail"];
            break;
        case ActivityType.PartnerUploadedFilesToSharedFolder:
            ActivityTypeComponent = PartnerUploadedFilesToSharedFolder;
            activityDetail = data["partnerUploadedFilesToSharedFolderActivity"];
            break;
        case ActivityType.KpiUpload:
            ActivityTypeComponent = KpiUploaded;
            activityDetail = data["kpiUploadedDetail"];
            break;
        case ActivityType.KpiUploadOnMyCompany:
            ActivityTypeComponent = KpiUploadedOnMyCompanyId;
            activityDetail = data["kpiUploadedOnMyCompanyIdDetail"];
            break;

        case ActivityType.Review:
            ActivityTypeComponent = Review;
            activityDetail = data["reviewDetail"];
            break;
        case ActivityType.Compliance:
            if (data.filledSelfAssessmentDetail !== null) {
                ActivityTypeComponent = SelfAssessment;
                activityDetail = data["filledSelfAssessmentDetail"];
            } else {
                ActivityTypeComponent = Compliance;
                activityDetail = data["complianceDetail"];
            }
            break;
        case ActivityType.Project:
            ActivityTypeComponent = Project;
            activityDetail = data["projectDetail"];
            break;
        case ActivityType.Ncr:
            ActivityTypeComponent = Ncr;
            activityDetail = data["ncrDetail"];
            break;
        case NotificationType.KpiUploadError:
            ActivityTypeComponent = KpiUploadError;
            activityDetail = data["activity"];
            break;
        case NotificationType.SelfAssessment:
            ActivityTypeComponent = SelfAssessmentSent;
            activityDetail = data["sendedSelfAssessmentDetail"];
            break;
        case NotificationType.VoiceOfSupplierSurvey:
            ActivityTypeComponent = VoiceOfSupplierSurvey;
            activityDetail = data["voiceOfSupplierSurveyDetail"];
            break;
        case ActivityType.ClosedNcr:
            ActivityTypeComponent = ClosedNcr;
            activityDetail = data["childActivityDetail"];
            break;
        case ActivityType.OpenNcr:
            ActivityTypeComponent = OpenNcr;
            activityDetail = data["childActivityDetail"];
            break;
        case ActivityType.ApprovalStatusChangeNcr:
            ActivityTypeComponent = ApprovalStatusChangeNcr;
            activityDetail = data["childActivityDetail"];
            break;
        case ActivityType.NextResponsibleNcr:
            ActivityTypeComponent = NextResponsibleNcr;
            activityDetail = data["childActivityDetail"];
            break;
        case ActivityType.MeetingMinute:
            ActivityTypeComponent = MeetingMinute;
            activityDetail = data["meetingMinuteActivityDetail"];
            break;
        default:
            ActivityTypeComponent = UnknownNotification;
            activityDetail = data["activity"];
    }
    return {
        ActivityTypeComponent,
        activityDetail,
    };
};

export { getNotification };
