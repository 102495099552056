import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

const useContacts = (userTypes: string[] = ["Administrator", "User"], sortByName = true) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        (async () => {
            let data = await Provider.User.getContacts(userTypes, sortByName);
            data = data.map((contact: IContact) => ({
                ...contact,
                name: `${contact.firstName} ${contact.lastName}`,
            }));
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export default useContacts;
