import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useDashboardCreate = () => {
    const [loading, SetLoading] = useState(false);

    const createDashboard = async (supplierId: number, creationData: any) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.addDashboard(supplierId, creationData).catch(
            err => {
                SetLoading(false);
                return Promise.reject(err);
            }
        );
        SetLoading(false);
        return response;
    };

    return {
        loading,
        createDashboard,
    };
};

export { useDashboardCreate };
