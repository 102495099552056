import { reconfigureColumnWidth } from "./reconfigureColumnWidth";

const getShownUserOrderedColumns = (
    viewColumnSettings: any[],
    columns: any[],
    minColumnWidth = 100,
    pageContainerWidth: number | undefined
) => {
    let shownUserOrderedColumns: any[] = [];
    if (viewColumnSettings && viewColumnSettings.length > 0) {
        for (let view of viewColumnSettings) {
            if (view.isHidden === false) {
                for (let i = 0; i < columns.length; i++) {
                    if (view.viewColumnId === columns[i]["viewColumnId"]) {
                        shownUserOrderedColumns.push(columns[i]);
                        break;
                    }
                }
            }
        }
        /*there might be a situation in which a column has been added in code but the list of
            columns are not updated yet*/
        for (let i = 0; i < columns.length; i++) {
            let columnIsInViewSettings = false;
            for (let view of viewColumnSettings) {
                if (view.viewColumnId === columns[i]["viewColumnId"]) {
                    columnIsInViewSettings = true;
                    break;
                }
            }
            if (!columnIsInViewSettings) {
                shownUserOrderedColumns.push(columns[i]);
            }
        }
    } else shownUserOrderedColumns = columns;

    shownUserOrderedColumns = reconfigureColumnWidth({
        columns: shownUserOrderedColumns,
        viewColumnSettings,
        minColumnWidth,
        pageContainerWidth,
    });
    return shownUserOrderedColumns;
};

export { getShownUserOrderedColumns };
