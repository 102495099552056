import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Tag, Row, Col, Divider, notification, Form, Button } from "antd";
import IPartnerGroup from "models/suppliers/IPartnerGroup";
import IMasterDataPartner from "models/suppliers/IMasterDataPartner";
import ICustomHeadline from "models/suppliers/ICustomHeadline";
import { useTranslation, Trans } from "react-i18next";
import { SelectData } from "components/utilitycomponents";
import { useCompanyType, usePartnerGroups, usePartnerGroupUpdate } from "controller";

interface ModalProps {
    visible: boolean;
    selectedRows: IMasterDataPartner[];
    partnerGroups: IPartnerGroup[];
    setGroupModalVisibility(value: boolean): void;
    setCreateGroupModalVisible(value: boolean): void;
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    updateTable(): void;
    groupHeadline: ICustomHeadline;
    createdGroup: { key: string | number; label: string }[];
    resetCreatedGroup(): void;
    onSelectedRowsChange: Function;
}

export const ContentModal = ({
    visible,
    selectedRows,
    setGroupModalVisibility,
    setCreateGroupModalVisible,
    updateSelectedRows,
    updateTable,
    groupHeadline,
    createdGroup,
    resetCreatedGroup,
    onSelectedRowsChange,
}: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState<any>(undefined);
    const { update, loading } = usePartnerGroupUpdate();
    const { isDefaultCompany } = useCompanyType();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };
    const customGroupHeadline =
        groupHeadline && groupHeadline.customLabel !== null
            ? groupHeadline.customLabel
            : t("group");

    useEffect(() => {
        if (createdGroup.length > 0) {
            form.setFieldsValue({ selectGroup: createdGroup[0].key });
            setSelectedGroup({ id: createdGroup[0].key, name: createdGroup[0].label });
        }
    }, [createdGroup]);

    useEffect(() => {
        form.setFieldsValue({
            selectGroup:
                selectedRows.length === 1 &&
                selectedRows[0].group !== undefined &&
                selectedRows[0].group.value &&
                selectedRows[0].group.value.name !== undefined
                    ? selectedRows[0].group!.value.id
                    : undefined,
        });
        setSelectedGroup(
            selectedRows.length === 1
                ? {
                      id: selectedRows[0].group!.value.id,
                      name: selectedRows[0].group!.value.name,
                  }
                : undefined
        );
    }, [visible]);

    function onCancel() {
        resetCreatedGroup();
        form.resetFields();
        setGroupModalVisibility(false);
    }

    async function onSubmit() {
        let values = await form.validateFields();
        await update(
            selectedRows.map(x => x.id.value),
            values.selectGroup ? values.selectGroup : "",
            customGroupHeadline
        );
        notification.success({
            message: t("success"),
            description: t("supplier_GroupUpdatePartnerSuccess", {
                groupHeadline: customGroupHeadline,
                companyType: isDefaultCompany
                    ? t("company_Type_Supplier").toLocaleLowerCase()
                    : t("company_Type_Customer").toLocaleLowerCase(),
            }),
        });
        setGroupModalVisibility(false);
        updateTable();
        onSelectedRowsChange(
            selectedRows.map(r => ({
                ...r,
                group: {
                    ...r.group,
                    value: selectedGroup
                        ? { id: selectedGroup.id, name: selectedGroup.name }
                        : null,
                },
            }))
        );
        form.resetFields();
    }

    function onTagClose(tag: any, key: any) {
        updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return selectedRows.map((row: any) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onNewGroupClick() {
        setCreateGroupModalVisible(true);
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Modal
                        wrapProps={{ "data-test": "group_modal" }}
                        title={t("supplier_GroupModalTitle", {
                            groupHeadline: customGroupHeadline,
                            companyType: isDefaultCompany
                                ? t("company_Type_Supplier")
                                : t("company_Type_Customer"),
                        })}
                        open={visible}
                        onOk={onSubmit}
                        onCancel={onCancel}
                        confirmLoading={loading}
                        width={"75vmin"}
                        footer={[
                            <Button
                                loading={loading}
                                type="primary"
                                onClick={async () => await onSubmit()}>
                                {t("generic_Save")}
                            </Button>,
                        ]}>
                        <Form form={form} {...formItemLayout} colon={false}>
                            <Form.Item
                                label={
                                    t("supplier_SelectedSuppliers", {
                                        companyType: isDefaultCompany
                                            ? t("company_Type_Supplier")
                                            : t("company_Type_Customer"),
                                    }) + " : "
                                }>
                                {selectedRows.length === 1 && (
                                    <div className="tagArea">{renderTags(false)}</div>
                                )}
                                {selectedRows.length > 1 && (
                                    <div className="tagArea">{renderTags(true)}</div>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <Trans
                                        i18nKey="groupSelectLabel"
                                        values={{ groupHeadline: customGroupHeadline }}>
                                        {t("groupSelectLabel")}
                                    </Trans>
                                }
                                name={"selectGroup"}
                                valuePropName="value">
                                <SelectData
                                    id="groupSelect"
                                    style={{ width: "100%" }}
                                    placeholder={`${t(
                                        "groupSelectPlaceholder"
                                    )} ${customGroupHeadline}`}
                                    allowClear
                                    dropdownRender={(menu: any) => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div
                                                style={{
                                                    padding: "8px",
                                                    cursor: "pointer",
                                                }}
                                                onMouseDown={() => onNewGroupClick()}>
                                                <PlusOutlined /> {t("add")} {customGroupHeadline}
                                            </div>
                                        </div>
                                    )}
                                    useData={usePartnerGroups.bind(
                                        null,
                                        createdGroup.length > 0 ? { reload: true } : null
                                    )}
                                    onChange={(value: number, element: any) => {
                                        setSelectedGroup(element);
                                    }}
                                    selectOptionField="name"
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
};
const ModalGroup = ContentModal;
export default ModalGroup;
