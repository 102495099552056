import { useState } from "react";
import { Provider } from "services";

const useSurveysDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteSurveys = async (surveyIds: string[], { retryCount, customError }: any) => {
        setLoading(true);
        await Provider.Survey.deleteSurveys(surveyIds, {
            retryCount,
            customError,
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
    };

    return {
        loading,
        deleteSurveys,
    };
};

export { useSurveysDelete };
