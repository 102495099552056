import { useEffect, useState } from "react";
import { default as serviceProvider } from "../../services/api";
import { ICustomHeadline } from "../../models/suppliers/ICustomHeadline";

export type UseCustomHeadlinesType = {
    data: ICustomHeadline[];
    loading: boolean;
};

const useCustomHeadlines = () => {
    const [headlines, setHeadlines] = useState<UseCustomHeadlinesType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setHeadlines({
                ...headlines,
                loading: true,
            });
            const response = await serviceProvider.Company.getCompanyHeadlines();
            if (response && response.typeValue) {
                let data: ICustomHeadline[] = [];
                response.typeValue.forEach((element: any) => {
                    data.push({
                        customLabel: element.customLabel,
                        name: element.name,
                        standardLabel: element.standardLabel,
                        isMandatory: element.isMandatory,
                    });
                });
                setHeadlines({
                    data,
                    loading: false,
                });
            }
        })();
    }, []);

    return {
        loading: headlines.loading,
        data: headlines.data,
    };
};

export { useCustomHeadlines };
