import { useState } from "react";
import { default as serviceProvider } from "../../services/api";

const useNcrDelete = () => {
    const [loading, setLoading] = useState(false);

    const deleteNcrs = async (ncrIds: number[]) => {
        setLoading(true);
        const success = await serviceProvider.Ncr.deleteNcrs(ncrIds, {
            customError: {
                message: "Could not delete the registration(s)",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });
        setLoading(false);
        return success;
    };

    return {
        loading,
        deleteNcrs,
    };
};

export default useNcrDelete;
