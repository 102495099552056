import { NCRModalProps, SHOW_NCRMODAL, HIDE_NCRMODAL, ShowModal, HideModal } from "./types";

export function showModal(props: NCRModalProps): ShowModal {
    return {
        type: SHOW_NCRMODAL,
        payload: {
            visible: true,
            partnerId: props.partnerId,
            currentRow: props.currentRow,
            fromDate: props.fromDate,
            toDate: props.toDate,
        },
    };
}

export function hideModal(): HideModal {
    return {
        type: HIDE_NCRMODAL,
        payload: { visible: false, partnerId: "", currentRow: undefined, fromDate: "", toDate: "" },
    };
}
