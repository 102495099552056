import { useEffect, useState } from "react";
import { Provider } from "services";

export interface IAvatar {
    profilePictureData: string | null;
    contentType: string | null;
    hasFile: boolean;
    initials: string;
}

type StateType = {
    loading: boolean;
    data: IAvatar | null;
    error: string | null;
};

const useAvatar = (contactId: number | null) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: null,
        error: null,
    });

    const fetchData = async () => {
        setState(prevState => ({ ...prevState, loading: true, error: null }));
        try {
            const data = (await Provider.Contact.getAvatarData(contactId)) as IAvatar;
            setState(prevState => ({ ...prevState, loading: false, data, error: null }));
        } catch (err) {
            let errorMessage = "An unknown error occurred";
            if (err instanceof Error) {
                errorMessage = err.message;
            }
            setState(prevState => ({
                ...prevState,
                loading: false,
                error: errorMessage,
                data: null,
            }));
        }
    };

    useEffect(() => {
        contactId !== null && fetchData();
    }, [contactId]);

    return {
        loading: state.loading,
        data: state.data,
        error: state.error,
        refresh: fetchData,
    };
};

export { useAvatar };
