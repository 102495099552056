export enum ComplianceMetricState {
    Approved = "Approved",
    NotApproved = "NotApproved",
    Expired = "Expired",
    NotApprovedExpired = "NotApprovedExpired",
    ExpiringSoon = "ExpiringSoon",
    NotApprovedExpiringSoon = "NotApprovedExpiringSoon",
    NoCompliance = "NoCompliance",
    NotRegistered = "NotRegistered",
}
