import { ITableInput } from "models";
import { post, get, remove, put } from "../base";
import dayjs from "utilities/daysJsConfig";

const Performance = {
    getPerformanceUnitOfAnalysisOverview: async (
        tableInput: ITableInput,
        { customError }: any = {}
    ) => {
        const url = `/v1/Performance/GetPerformanceOverview`;
        let response = await post({
            url,
            payload: tableInput,
            customError,
        });
        return response;
    },
    getPerformanceFilesAndLinks: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Performance/GetPerformanceFilesAndLinks?partnerId=${partnerId}`;
        return await get({ url });
    },
    getPerformanceChartData: async (
        partnerId: number,
        year: any,
        input: any[],
        { customError }: any = {}
    ) => {
        let selectedYear = year;
        if (selectedYear === undefined) {
            selectedYear = dayjs().year();
            input = [];
        }

        let url = `/v1/Performance/GetPerformanceChartData?supplierId=${partnerId}&date=${`01-01-${parseInt(
            selectedYear
        )}`}`;
        return await post({ url, payload: input, customError });
    },
    getPerformanceSurveyReviews: async (
        surveyId: number,
        input: any,
        partnerId: number,
        { customError }: any = {}
    ) => {
        let url = `/v1/Performance/GetPerformanceSurveyReviewsBySurveyId?surveyId=${surveyId}`;
        if (partnerId !== undefined) {
            url = url + `&partnerId=${partnerId}`;
        }
        return await post({ url, payload: input, customError });
    },
    getAnalysisUnitRelatedSurveys: async (
        analysisUnitId: number,
        type: "internalLevel" | "externalLevel",
        { customError }: any = {}
    ) => {
        const url = `/v1/Performance/GetSurveysByPerformanceUnitAnalysisId?unitAnalysisId=${analysisUnitId}&type=${type}`;
        return await get({ url, customError });
    },
    getReviewDetails: async (
        surveyId: number,
        contactId: number,
        partnerId: number,
        { customError }: any = {}
    ) => {
        const url = `/v1/Performance/GetReviewDetailByContactAndSurveyId/${surveyId}/${contactId}/${partnerId}`;
        return await post({ url, payload: {}, customError });
    },
    getPerformanceSurveyAnswersByGroup: async (
        criteriaGroupId: number,
        surveyId: number,
        input: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Performance/GetPerformanceSurveyAnswersByReviewCriteriaId?reviewCriteriaGroupId=${criteriaGroupId}&surveyId=${surveyId}`;
        return await post({ url, payload: input, customError });
    },
    getPerformanceDataByDateRange: async (input: any, { customError }: any = {}) => {
        const url = `/v1/Performance/GetPerformanceDataByDateRange`;
        return await post({ url, payload: input, customError });
    },
    upsertPerformanceLinks: async (input: any, { customError }: any = {}) => {
        const url = `/v1/Performance/UpdatePerformanceLink`;
        return await put({ url, payload: input, customError });
    },
    deletePerformanceLink: async (id: any, { customError }: any = {}) => {
        const url = `/v1/Performance/RemovePerformanceLink?performanceLinkId=${id}`;
        return await remove({ url, payload: id, customError });
    },
    deleteUnitOfAnalysis: async (unitOfAnalysisId: number, { customError }: any = {}) => {
        const url = `/v1/Performance/DeleteUnitOfAnalysis?unitOfAnalysisId=${unitOfAnalysisId}`;
        return await remove({ url, customError });
    },
};

export default Performance;
