import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./styles/shared.module.scss";
import { IPartnerUploadedFilesToSharedFolder } from "models";
import { AppState } from "store";
import { useSelector } from "react-redux";

type PropsType = {
    data: IPartnerUploadedFilesToSharedFolder;
};

const PartnerUploadedFilesToSharedFolder: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const userType = useSelector((state: AppState) => state.oidc.user.profile["ll-Usertype"]);
    const hasFileAccess =
        useSelector((state: AppState) => state.oidc.user.profile["ll-Files"]) === "true"
            ? true
            : false;
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.creator} {t("activity_HasUploadedFiles")}{" "}
                {userType !== "lightuser" && hasFileAccess ? (
                    <Link to={`/Files/folderId/${data.folderId}`}>{data.name}</Link>
                ) : (
                    <span>{data.name}</span>
                )}
            </div>
        </Fragment>
    );
};

export default PartnerUploadedFilesToSharedFolder;
