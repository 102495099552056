import { Button, Col, Row, Tag, Tooltip } from "antd";
import {
    AlertCircle,
    CheckCircle,
    ChevronDown,
    ChevronRight,
    Expand01,
    Grid,
    PageContainer,
} from "components/utilitycomponents";
import { useAwardStatus, useAwardsSettings, useScorecardOverview } from "controller";
import { ITableType } from "models";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./scorecard.module.scss";
import { scoreColor } from "./scorecardGradient";
import classNames from "classnames";
import useAreaStatus from "controller/admin/scorecard/useAreaStatus";
import SemiCircularGauge from "./semiCircularChart";
import AreaStatusComponent from "./areaStatusComponent";
import ScorecardModal from "./modals/scorecardModal";
import { ComplianceMetricState, IScorecardTableMetric, ReviewMetricStates } from "models/scorecard";
import { MetricValidation } from "models/scorecard/MetricValidation";
import { formatTime } from "utilities";
import ActionModal from "./modals/actionModal";
interface IProps {
    partnerId: number;
    partnerName?: string;
}

const ScorecardPage = ({ partnerId, partnerName }: IProps) => {
    const { t } = useTranslation();
    const { data } = useAwardStatus(partnerId);
    const { data: dataAreaStates } = useAreaStatus(partnerId);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data: awardSettings } = useAwardsSettings();
    const [openModal, setOpenModal] = useState({ isVisible: false, metricId: 0, partnerId: 0 });

    const columns = [
        {
            title: t("scorecard_Title"),
            dataIndex: "title",
            key: "title",
            viewColumnId: "title",
            width: 350,
            render: (text: string, record: any) => (
                <div style={{ display: "inline-block", marginLeft: record.children ? 0 : 30 }}>
                    <div>
                        {record.title.indexOf("ReviewCategory_") !== -1
                            ? t(record.title)
                            : record.title}
                    </div>
                </div>
            ),
        },
        {
            title: t("scorecard_Score"),
            key: "score",
            viewColumnId: "score",
            width: 100,
            render: (text: string, record: any) => (
                <div>
                    {record.children && record.score !== null && (
                        <Tag color={scoreColor(record.score)} bordered={false}>
                            {`${record.score}%`}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            title: t("scorecard_State"),
            key: "state",
            viewColumnId: "state",
            width: 350,
            render: (text: string, record: any) => (
                <>
                    {record.children && record.score !== null && record.score >= 100 && (
                        <Tooltip
                            title={
                                <div style={{ width: 300 }}>{t("scorecard_Score_AllOnTarget")}</div>
                            }>
                            <span>
                                <CheckCircle className={classes.stateIcon} />
                            </span>
                        </Tooltip>
                    )}
                    {record.children && record.score !== null && record.score < 100 && (
                        <Tooltip
                            title={
                                <div style={{ width: 300 }}>{t("scorecard_Score_OffTarget")}</div>
                            }>
                            <span>
                                <AlertCircle className={classes.stateIcon} />
                            </span>
                        </Tooltip>
                    )}
                    {record.children === undefined && renderStateCell(record)}
                </>
            ),
        },
        {
            title: t("scorecard_Action"),
            dataIndex: "action",
            key: "action",
            viewColumnId: "action",
            operationColumn: true,
            render: (text: string, record: any) => {
                if (record.type === "Compliance") {
                    return (
                        <Button
                            onClick={() => {
                                const metricIdString = record.id.split(":")[1];
                                const metricId = Number(metricIdString);
                                setOpenModal({
                                    isVisible: true,
                                    metricId: metricId,
                                    partnerId: partnerId,
                                });
                            }}
                            style={{ fontSize: 12 }}>
                            Open
                        </Button>
                    );
                }
                return null;
            },
        },
    ];

    const renderStateCell = (record: IScorecardTableMetric) => {
        if (record.onTarget === MetricValidation.OnTarget) {
            if (record.type === "Compliance") {
                switch (record.complianceStatus) {
                    case ComplianceMetricState.ExpiringSoon:
                        return (
                            <>
                                <CheckCircle className={classes.stateIcon} />{" "}
                                <Tag color="#F19306" bordered={false}>
                                    {t("scorecard_State_Expiring")} {formatTime(record.endDate!)}
                                </Tag>
                            </>
                        );
                    default:
                        return <CheckCircle className={classes.stateIcon} />;
                }
            } else {
                return <CheckCircle className={classes.stateIcon} />;
            }
        } else if (
            record.onTarget === MetricValidation.NotOnTarget ||
            record.onTarget === MetricValidation.None
        ) {
            if (record.type === "Compliance") {
                switch (record.complianceStatus) {
                    case ComplianceMetricState.NotApproved:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_NotApproved")}
                            </Tag>
                        );
                    case ComplianceMetricState.Expired:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_Expired")} {formatTime(record.endDate!)}
                            </Tag>
                        );
                    case ComplianceMetricState.NotApprovedExpired:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_NotApproved&Expired")}{" "}
                                {formatTime(record.endDate!)}
                            </Tag>
                        );
                    case ComplianceMetricState.ExpiringSoon:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_Expiring")} {formatTime(record.endDate!)}
                            </Tag>
                        );
                    case ComplianceMetricState.NotApprovedExpiringSoon:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_NotApproved&Expiring")}{" "}
                                {formatTime(record.endDate!)}
                            </Tag>
                        );
                    case ComplianceMetricState.NoCompliance:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_None")}
                            </Tag>
                        );
                    case ComplianceMetricState.NotRegistered:
                        return (
                            <Tag color="#F19306" bordered={false}>
                                {t("scorecard_State_NotRegistered")}
                            </Tag>
                        );
                    default:
                        return <></>;
                }
            } else if (record.type === "ReviewCategory") {
                switch (record.reviewCriteriaStatus) {
                    case ReviewMetricStates.BelowTarget:
                        return (
                            <Tooltip
                                title={t("scorecard_State_RatingTooltip", {
                                    currentAvg: record.averageScore?.toPrecision(2),
                                    target: record.targetScore,
                                })}>
                                <Tag color="#F19306" bordered={false}>
                                    {t("scorecard_State_BelowTarget")}
                                </Tag>
                            </Tooltip>
                        );
                    case ReviewMetricStates.BelowTargetOverLastDate:
                        return (
                            <Tooltip
                                title={t("scorecard_State_RatingOverTimeTooltip", {
                                    currentAvg: record.averageScore?.toPrecision(2),
                                    target: record.targetScore,
                                    period: record.conditionPeriod,
                                })}>
                                <Tag color="#F19306" bordered={false}>
                                    {t("scorecard_State_BelowTarget")}
                                </Tag>
                            </Tooltip>
                        );
                    case ReviewMetricStates.NoReviews:
                        return <Tag bordered={false}>{t("scorecard_State_None")}</Tag>;
                    default:
                        return <></>;
                }
            } else if (record.type === "ReviewCategoryGroup") {
                switch (record.reviewCriteriaGroupStatus) {
                    case ReviewMetricStates.BelowTarget:
                        return (
                            <Tooltip
                                title={t("scorecard_State_RatingTooltip", {
                                    currentAvg: record.averageScore?.toPrecision(2),
                                    target: record.targetScore,
                                })}>
                                <Tag color="#F19306" bordered={false}>
                                    {t("scorecard_State_BelowTarget")}
                                </Tag>
                            </Tooltip>
                        );
                    case ReviewMetricStates.BelowTargetOverLastDate:
                        return (
                            <Tooltip
                                title={t("scorecard_State_RatingOverTimeTooltip", {
                                    currentAvg: record.averageScore?.toPrecision(2),
                                    target: record.targetScore,
                                    period: record.conditionPeriod,
                                })}>
                                <Tag color="#F19306" bordered={false}>
                                    {t("scorecard_State_BelowTarget")}
                                </Tag>
                            </Tooltip>
                        );
                    case ReviewMetricStates.NoReviews:
                        return <Tag bordered={false}>{t("scorecard_State_None")}</Tag>;
                    default:
                        return <></>;
                }
            }
        }
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageContainer
                        gridContainer
                        fluid
                        className={classNames(classes.widgetContainer)}>
                        <div className={classes.pageContainer}>
                            <div className={classes.awardStatusContainer}>
                                <div className={classes.titleAward}>
                                    {t("scorecard_award_statusTitle")}{" "}
                                </div>
                                <div style={{ marginRight: 20 }}>
                                    <SemiCircularGauge
                                        value={data && data.score}
                                        width={342}
                                        height={181}
                                        widget={true}
                                        level={data?.level}
                                        awardLevelData={awardSettings}
                                    />
                                </div>
                            </div>
                            <div className={classes.devider} />
                            <div className={classes.areaStatesContainer}>
                                <div className={classes.areaStatesHeader}>
                                    <div className={classes.titleArea}>
                                        {t("scorecard_area_statesTitle").toUpperCase()}{" "}
                                    </div>

                                    <Expand01
                                        className={classes.icon}
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    />
                                </div>

                                <AreaStatusComponent data={dataAreaStates} />
                            </div>
                        </div>
                    </PageContainer>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Grid
                        containerMargin={false}
                        title={() => (
                            <div className={classes.tableTitle}>
                                {`${t("supplier").toLocaleUpperCase()} 
                                ${t("scorecard_Scorecard").toLocaleUpperCase()}`}
                            </div>
                        )}
                        useData={useScorecardOverview.bind(null, partnerId)}
                        columns={columns}
                        tableType={ITableType.ScorecardOverview}
                        expandable={{
                            expandIcon: ({ expanded, onExpand, record }: any) =>
                                record.children &&
                                (expanded ? (
                                    <ChevronDown
                                        style={{ display: "inline-block", width: 20 }}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onExpand(record, e);
                                        }}
                                    />
                                ) : (
                                    <ChevronRight
                                        style={{ display: "inline-block", width: 20 }}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onExpand(record, e);
                                        }}
                                    />
                                )),
                        }}
                        pagination={false}
                    />
                </Col>
            </Row>
            <ScorecardModal
                open={showModal}
                onClose={() => setShowModal(false)}
                awardData={data}
                awardLevelsData={awardSettings}
                areaData={dataAreaStates}
                supplierName={partnerName}
            />
            <ActionModal
                open={openModal.isVisible}
                metricId={openModal.metricId}
                partnerId={partnerId}
                onCancel={() => setOpenModal({ isVisible: false, partnerId: 0, metricId: 0 })}
            />
        </>
    );
};

export { ScorecardPage };
