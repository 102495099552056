import React, { FunctionComponent, Fragment, useState } from "react";
import { Input, Row, Button, Col } from "antd";
import { useActivityChildMessageCreate, useActivityFileUploadCreate } from "controller";
import { AttachActivityFile } from "./attachActivityFile";
import { IActivityChild } from "models";
import classes from "./styles/addChildMessage.module.scss";
import { useTranslation } from "react-i18next";
const TextArea = Input.TextArea;

type PropsType = {
    onAddActivity: (parentActivityId: number, activity: IActivityChild) => void;
    parentActivityId: number;
    contactActionActivity?: boolean;
};

const AddChildMessage: FunctionComponent<PropsType> = ({
    onAddActivity,
    parentActivityId,
    contactActionActivity = false,
}) => {
    const { t } = useTranslation();
    const maximumLength = 4000;
    const [newMessage, setNewMessage] = useState("");
    const [displayActionButtons, setDisplayActionButtons] = useState(false);
    const { loading: createLoading, create } = useActivityChildMessageCreate();
    const { loading: uploadLoading, create: createFileUpload } = useActivityFileUploadCreate();

    const handleInputChange = (e: any) => {
        setNewMessage(e.target.value.substring(0, maximumLength));
    };

    const handleInputFocus = (e: any) => {
        setDisplayActionButtons(true);
    };

    const handleSave = async () => {
        if (newMessage.trim() === "") return;
        const activity = await create(newMessage, parentActivityId, contactActionActivity);
        onAddActivity(parentActivityId, activity);
        setNewMessage("");
        setDisplayActionButtons(false);
    };

    const handleFileUpload = async (fileIds: number[]) => {
        const activity = await createFileUpload(fileIds, parentActivityId, contactActionActivity);
        onAddActivity(parentActivityId, activity);
        setDisplayActionButtons(false);
    };

    return (
        <Fragment>
            <div>
                <TextArea
                    data-test="post_comment_textarea"
                    className={classes.messageBoxMightUpdateWithVersion}
                    placeholder={t("activity_CommentPlaceholder")}
                    autoSize={true}
                    allowClear
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    value={newMessage}
                />
            </div>
            {displayActionButtons && (
                <Row style={{ margin: "10px 0" }}>
                    <Col lg={16}>
                        <div style={{ float: "left", position: "relative", zIndex: 1 }}>
                            <Button
                                data-test="post_comment_button"
                                id="test"
                                type="primary"
                                onClick={handleSave}
                                loading={createLoading}
                                style={{ marginRight: "15px" }}>
                                {t("activity_PostComment")}
                            </Button>
                        </div>
                        <div data-test="view_attached_files">
                            <AttachActivityFile
                                onChange={handleFileUpload}
                                externalLoading={uploadLoading}>
                                {t("file_UploadFiles")}
                            </AttachActivityFile>
                        </div>
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                        {maximumLength - newMessage.length} {t("activity_CommentRemainingChars")}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export { AddChildMessage };
