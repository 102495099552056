import { IRelationsUserDetail } from "models/user";
import { useState } from "react";
import { Provider } from "services";

const useAddRelationsUserDetails = () => {
    const [loading, setLoading] = useState(false);
    const addUserDetails = async (userDetails: IRelationsUserDetail) => {
        setLoading(true);
        const res = await Provider.User.addRelationsUserDetail(userDetails, {
            customError: {
                message: "Failed to submit user details",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return res;
    };
    return { loading, addUserDetails };
};

export { useAddRelationsUserDetails };
