/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState, useEffect, forwardRef } from "react";
import Editor from "@draft-js-plugins/editor";
//@ts-ignore
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createLinkPlugin from "@draft-js-plugins/anchor";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import createLinkDetectionPlugin from "draft-js-link-detection-plugin";
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
} from "@draft-js-plugins/buttons";
import { EditorState } from "draft-js";
import classnames from "classnames";
import "./richTextEditor.scss";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";

const RichTextEditor = forwardRef(
    (
        {
            value,
            onChange,
            defaultValue = "<p></p>",
            maxLength = undefined,
            placeholder = "",
            bordered = false,
            showSaveButton = false,
            onSaveButtonClick,
            ...rest
        }: any,
        ref
    ) => {
        const [element, setElement] = useState<any>();
        const { t } = useTranslation();

        const [editorState, setEditorState] = useState<any>(() => {
            return EditorState.createWithContent(
                stateFromHTML(value ? value : defaultValue ? defaultValue : "")
            );
        });
        const [remainingCharacters, setRemainingCharacters] = useState<any>(undefined);

        useEffect(() => {
            if (
                defaultValue === undefined &&
                value !== stateToHTML(editorState.getCurrentContent())
            ) {
                setEditorState(EditorState.createWithContent(stateFromHTML(value ? value : "")));
                value && setRemainingCharacters(maxLength - value.length);
            }
        }, [value]);

        const [{ plugins, Toolbar, linkPlugin }] = useState(() => {
            const toolbarPlugin = createToolbarPlugin();
            const linkDetectionPlugin = createLinkDetectionPlugin();
            const linkPlugin = createLinkPlugin({
                theme: { input: "input", inputInvalid: "", link: "link" },
                placeholder: "http://...",
                linkTarget: "_blank",
            });
            const { Toolbar } = toolbarPlugin;
            const plugins = [toolbarPlugin, linkPlugin, linkDetectionPlugin];
            return {
                plugins,
                Toolbar,
                linkPlugin,
            };
        });

        const focus = () => {
            element.focus();
        };

        const handleChange = (state: any) => {
            if (maxLength !== undefined) {
                let remainingCharacters =
                    maxLength - state.getCurrentContent().getPlainText("").length;
                if (remainingCharacters >= 0) setRemainingCharacters(remainingCharacters);
            }

            setEditorState(state);
            onChange(stateToHTML(state.getCurrentContent()));
        };

        const getLengthOfSelectedText = () => {
            const currentSelection = editorState.getSelection();
            const isCollapsed = currentSelection.isCollapsed();

            let length = 0;

            if (!isCollapsed) {
                const currentContent = editorState.getCurrentContent();
                const startKey = currentSelection.getStartKey();
                const endKey = currentSelection.getEndKey();
                const startBlock = currentContent.getBlockForKey(startKey);
                const isStartAndEndBlockAreTheSame = startKey === endKey;
                const startBlockTextLength = startBlock.getLength();
                const startSelectedTextLength =
                    startBlockTextLength - currentSelection.getStartOffset();
                const endSelectedTextLength = currentSelection.getEndOffset();
                const keyAfterEnd = currentContent.getKeyAfter(endKey);
                if (isStartAndEndBlockAreTheSame) {
                    length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
                } else {
                    let currentKey = startKey;

                    while (currentKey && currentKey !== keyAfterEnd) {
                        if (currentKey === startKey) {
                            length += startSelectedTextLength + 1;
                        } else if (currentKey === endKey) {
                            length += endSelectedTextLength;
                        } else {
                            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                        }

                        currentKey = currentContent.getKeyAfter(currentKey);
                    }
                }
            }

            return length;
        };

        const handleBeforeInput = () => {
            if (maxLength !== undefined) {
                const currentContent = editorState.getCurrentContent();
                const currentContentLength = currentContent.getPlainText("").length;
                const selectedTextLength = getLengthOfSelectedText();

                if (currentContentLength - selectedTextLength > maxLength - 1) {
                    return "handled";
                }
            }
            return "not-handled";
        };

        const handlePastedText = (pastedText: string) => {
            if (maxLength !== undefined) {
                const currentContent = editorState.getCurrentContent();
                const currentContentLength = currentContent.getPlainText("").length;
                const selectedTextLength = getLengthOfSelectedText();

                if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
                    return "handled";
                }
            }
            return "not-handled";
        };
        return (
            <div>
                <div
                    className={classnames(
                        "richTextEditor",
                        rest["data-__meta"] || bordered ? "formInput" : ""
                    )}
                    style={{ ...rest.style }}
                    onClick={focus}>
                    {element && (
                        <Toolbar>
                            {// may be use React.Fragment instead of div to improve performance after React 16
                            (externalProps: any) => (
                                <Fragment>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <UnorderedListButton {...externalProps} />
                                    <OrderedListButton {...externalProps} />
                                    <linkPlugin.LinkButton {...externalProps} />
                                </Fragment>
                            )}
                        </Toolbar>
                    )}
                    <Editor
                        editorState={editorState}
                        onChange={handleChange}
                        plugins={plugins}
                        ref={editor => {
                            setElement(editor);
                        }}
                        handleBeforeInput={handleBeforeInput}
                        handlePastedText={handlePastedText}
                        placeholder={placeholder}
                    />
                </div>
                <Row>
                    <Col span={showSaveButton ? 18 : 24}>
                        {maxLength !== undefined && (
                            <div style={{ marginTop: "5px", lineHeight: "normal" }}>
                                {remainingCharacters}
                                {" " + t("activity_CommentRemainingChars")}
                            </div>
                        )}
                    </Col>
                    {showSaveButton && (
                        <Col span={6} style={{ textAlign: "right" }}>
                            <Button
                                style={{ marginTop: "5px" }}
                                type="primary"
                                size="small"
                                onClick={() => onSaveButtonClick()}>
                                {t("generic_Ok")}
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
);
export { RichTextEditor };
