import { useEffect, useState } from "react";
import Provider from "services/api";

const usePartnerDivisions = () => {
    const [state, setState] = useState<any>({
        loading: false,
        data: [],
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data: any = await Provider.Survey.getDivisions({
                customError: {
                    message: "Could not retrieve list of divisions",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePartnerDivisions };
