import { useEffect, useState } from "react";
import Provider from "services/api";
import { useTranslation } from "react-i18next";

const useKpisSubLevels = (kpiId: any, supplierId: any) => {
    const { t } = useTranslation();
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
        });

        (async () => {
            if (kpiId !== undefined && supplierId !== "-1") {
                let data: any = await Provider.Analytics.getAvailableSubLevels(kpiId, supplierId, {
                    retryCount: 3,
                    customError: { message: "Could not retrieve available kpis" },
                }).catch(err => {
                    setState({ loading: false, data: {} });
                    return Promise.reject(err);
                });
                data = data.map((d: any) => ({
                    ...d,
                    id: !d.children ? d.id : `group-${d.id}`,
                    subLevelHeadline: t(d.subLevelHeadline),
                    ...(d.children && {
                        children: d.children.map((x: any) => ({
                            ...x,
                            subLevelHeadline: t(x.subLevelHeadline),
                        })),
                    }),
                }));
                setState({
                    loading: false,
                    data: data,
                });
            }
        })();
    }, [kpiId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useKpisSubLevels };
