import { useState } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { Provider } from "services";

const usePartnerUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const updatePartnerDetail = async (partnerId: number, partnerDetail: any) => {
        setLoading(true);
        await Provider.Partner.updatePartnerMasterData(partnerId, partnerDetail, {
            customError: {
                message: "Could not update partner master data",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updatePartnerOwners = async (partnerId: number, ownerIds: number[]) => {
        setLoading(true);
        await Provider.Partner.updatePartnerOwners([partnerId], ownerIds, {
            customError: {
                message: "Could not update partner owner(s)",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    const updateOwnersOnPartners = async (partnerIds: number[], ownerIds: number[]) => {
        setLoading(true);
        await Provider.Partner.updatePartnerOwners(partnerIds, ownerIds).catch((err: any) => {
            notification.error({
                message: t("systemMessage"),
                description: t("generic_ErrorMessage"),
                duration: 0,
            });
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };
    return {
        loading,
        updatePartnerDetail,
        updatePartnerOwners,
        updateOwnersOnPartners,
    };
};

export { usePartnerUpdate };
