import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any[];
};

const useSegmentationReviewCriteriaSettings = (groupId: number | undefined, axisId: number) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            if (groupId) {
                setState({ ...state, loading: true });
                let data: any = await Provider.Segmentation.getSegmentationReviewCriteriaSettings(
                    { groupCriteriaId: groupId, axisId },
                    {
                        customError: {
                            message: "Could not retrieve list of review criteria",
                        },
                    }
                ).catch((err: any) => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                data = data.map((d: any) => {
                    return {
                        ...d,
                        reviewCriteriaName: t(d.reviewCriteriaName),
                    };
                });
                setState({ loading: false, data: data });
            } else {
                setState({ loading: false, data: [] });
            }
        })();
    }, [groupId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSegmentationReviewCriteriaSettings };
