import { aggragateNumberRanges } from "controller/grid/helpers";
import { IColumnFilter } from "./models";

const convertCustomFilterToColumnFilter = (name: string, value: any, type: string) => {
    switch (type) {
        case "array":
            if (Array.isArray(value)) {
                value = value.join(",");
            } else {
                value = [value];
            }
            break;
        case "range":
            value = aggragateNumberRanges(value[0], value[1]);
            break;
        default:
            value = value.toString();
    }
    return { name, value, type: "customFilter", customColumnId: null };
};

export const handleCustomFilterChange = ({
    customFilters,
    onCustomFiltersChange,
    name,
    value,
    type,
}: any) => {
    let newCustomFilters: IColumnFilter[] = [...customFilters];
    let existingFilterIndex = newCustomFilters.findIndex((f: IColumnFilter) => f.name === name);

    if (
        (!Array.isArray(value) && value !== undefined) ||
        (Array.isArray(value) && value.length !== 0)
    ) {
        if (existingFilterIndex !== -1) {
            newCustomFilters[existingFilterIndex] = convertCustomFilterToColumnFilter(
                name,
                value,
                type
            );
        } else {
            newCustomFilters.push(convertCustomFilterToColumnFilter(name, value, type));
        }
    } else {
        newCustomFilters = newCustomFilters.filter(f => f.name !== name);
    }

    onCustomFiltersChange(newCustomFilters);
};
