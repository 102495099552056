import { useEffect, useState } from "react";
import Provider from "services/api";

const useUserResponseDetails = (
    surveyId: number,
    segmentationAnalysisId: number,
    performanceAnalysisId: number,
    contactId: number | undefined,
    partnerId: number | undefined
) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    useEffect(() => {
        setState({
            loading: true,
            data: state.data,
            totalRows: state.totalRows,
        });

        (async () => {
            if (surveyId !== -1 && contactId && partnerId) {
                const data = await Provider.Survey.getUserReviewDetailsBySurveyId(
                    surveyId,
                    contactId,
                    partnerId,
                    {
                        customError: { message: "Could not retrieve review details" },
                    }
                ).catch(err => {
                    setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: data,
                });
            } else if (segmentationAnalysisId !== -1 && contactId && partnerId) {
                const data = await Provider.Survey.getUserReviewDetailsBySegmentationAnalysisId(
                    segmentationAnalysisId,
                    contactId,
                    partnerId,
                    {
                        customError: { message: "Could not retrieve review details" },
                    }
                ).catch(err => {
                    setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: data,
                });
            } else if (performanceAnalysisId !== -1 && contactId && partnerId) {
                const data = await Provider.Performance.getReviewDetails(
                    surveyId,
                    contactId,
                    partnerId
                ).catch(err => {
                    setState({ loading: false, data: [], customFields: [], totalRows: 0 });
                    return Promise.reject(err);
                });
                setState({
                    loading: false,
                    data: data,
                });
            } else if (
                (segmentationAnalysisId !== -1 && contactId === undefined) ||
                partnerId === undefined
            ) {
                setState({
                    loading: false,
                    data: [],
                });
            }
        })();
    }, [surveyId, segmentationAnalysisId, performanceAnalysisId, contactId, partnerId]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useUserResponseDetails };
