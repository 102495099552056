import { ReportType } from "models";

export interface ExcelExportProps {
    reportType?: ReportType | undefined;
    loading: boolean;
    downloadProgress: number;
    [key: string]: any;
}

export interface ExcelExportState {
    props: ExcelExportProps;
}

export const SET_EXCELEXPORT = "SET_EXCELEXPORT";
export const CLEAR_EXCELEXPORT = "CLEAR_EXCELEXPORT";

export interface SetExcelExport {
    type: typeof SET_EXCELEXPORT;
    payload: ExcelExportProps;
}

export interface ClearExcelExport {
    type: typeof CLEAR_EXCELEXPORT;
    payload: ExcelExportProps;
}

export type ExcelExportActionTypes = SetExcelExport | ClearExcelExport;
