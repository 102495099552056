import React, { useEffect, useState } from "react";
import { Modal, Spin, Select, Alert, notification, Button } from "antd";
import { IPartnerGroup } from "models";
import { useTranslation } from "react-i18next";
import { useGroupsCreate, usePartnerGroups } from "controller";

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    setCreatedGroup(createdGroup: { key: string | number; label: string }[]): void;
    groupHeadline: string;
}

interface ModalState {
    groupNames: string[];
    hasError: boolean;
    errorMsg: string;
}

const CreateGroupModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const [reload, setReload] = useState<any>(null);
    const { createGroups, loading: createLoading } = useGroupsCreate();
    const { data: groups, loading } = usePartnerGroups(reload);
    const [state, setState] = useState<ModalState>({
        groupNames: [],
        hasError: false,
        errorMsg: "",
    });

    useEffect(() => {
        setReload({ reload: true });
    }, [props.visible]);

    const groupHeadline = props.groupHeadline;

    const onSelectChange = async (values: string[]) => {
        let existingGroups: IPartnerGroup[] = groups;
        let hasError = false;
        let errorText = "";
        if (values.filter(x => x.length > 50).length > 0) {
            hasError = true;
            errorText = `${groupHeadline} ${t("createGroupLengthError")}`;
        }
        for (let i = 0; i < values.length; i++) {
            if (
                existingGroups.filter(x => x.name.toLowerCase() === values[i].toLowerCase())
                    .length > 0
            ) {
                hasError = true;
                errorText = `${groupHeadline} ${t("createGroupNameExists")}`;
            }
        }
        setState({
            ...state,
            groupNames: values,
            hasError: hasError,
            errorMsg: errorText,
        });
    };

    const onSubmit = async () => {
        if (state.groupNames.length > 0) {
            let createdGroups: { key: string | number; label: string }[] = [];
            let response = await createGroups(state.groupNames, groupHeadline);
            for (let i = 0; i < response.length; i++) {
                createdGroups.push({
                    key: response[i],
                    label: state.groupNames[i],
                });
            }
            notification.success({
                message: t("success"),
                description: `${groupHeadline} ${t("createGroupSuccess")}`,
            });
            props.setModalVisibility(false);
            props.setCreatedGroup(createdGroups);
            setState({ ...state, groupNames: [], hasError: false });
        }
    };

    return (
        <Modal
            title={t("createGroupTitle", { groupHeadline: groupHeadline })}
            open={props.visible}
            onCancel={() => {
                props.setModalVisibility(false);
                setState({ ...state, groupNames: [], hasError: false });
            }}
            onOk={() => onSubmit()}
            zIndex={1001}
            footer={[
                <Button
                    disabled={state.hasError}
                    loading={createLoading}
                    type="primary"
                    onClick={async () => await onSubmit()}>
                    {t("generic_Create")}
                </Button>,
            ]}>
            <Spin spinning={loading}>
                <Alert
                    type="info"
                    style={{ marginBottom: 8 }}
                    message=""
                    description={
                        <div className="infoTooltip">
                            {t("createGroupInfo", { groupHeadline: groupHeadline })}
                        </div>
                    }
                />

                <Select
                    mode="tags"
                    placeholder={t("createGroupPlaceholder", { groupHeadline: groupHeadline })}
                    style={{ width: "100%" }}
                    tokenSeparators={[",", "."]}
                    dropdownRender={() => <div></div>}
                    onChange={(value: any) => onSelectChange(value)}
                    value={state.groupNames}></Select>

                {state.hasError && <span style={{ color: "red" }}>{state.errorMsg}</span>}
            </Spin>
        </Modal>
    );
};

export default CreateGroupModal;
