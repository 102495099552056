import { get, post, patch, remove } from "../base";

const Relationships = {
    getRelationshipDimensions: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Relationship/FetchRelationshipDimension/${partnerId}`;
        return await get({ url, customError });
    },
    createRelationshipDimension: async (dimension: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/PostRelationshipDimension`;
        return await post({ url, payload: dimension, customError });
    },
    updateRelationshipDimension: async (
        dimensionId: number,
        dimension: any,
        { customError }: any = {}
    ) => {
        const url = `/v1/Relationship/UpdateRelationshipDimension/${dimensionId}`;
        await patch({ url, payload: dimension, customError });
    },
    updateRelationDimensionOrders: async (orders: any[], { customError }: any = {}) => {
        const url = `/v1/Relationship/ReorderRelationshipDimensions`;
        await post({ url, payload: orders, customError });
    },
    getFirstDimension: async (partnerId: number, { customError }: any = {}) => {
        const url = `/v1/Relationship/FetchTopRelationshipDimension/${partnerId}`;
        return await get({ url, customError });
    },
    deleteRelationshipDimension: async (dimensionId: number, { customError }: any = {}) => {
        const url = `/v1/Relationship/DeleteRelationshipDimension/${dimensionId}`;
        await remove({ url, customError });
    },
    createRelationshipCategory: async (category: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/CreateRelationshipCategory`;
        return await post({ url, payload: category, customError });
    },
    updateRelationshipCategory: async (category: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/UpdateRelationshipCategory`;
        await patch({ url, payload: category, customError });
    },
    updateRelationCategoriesOrders: async (categories: any[], { customError }: any = {}) => {
        const url = `/v1/Relationship/ReorderRelationshipCategories`;
        await patch({ url, payload: categories, customError });
    },
    deleteRelationshipCategory: async (categoryId: number, { customError }: any = {}) => {
        const url = `/v1/Relationship/DeleteRelationshipCategory?id=${categoryId}`;
        await remove({ url, customError });
    },
    createRelationshipCompanyContact: async (contact: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/CreateRelationshipCategoryCompanyContact`;
        return await post({ url, payload: contact, customError });
    },
    updateRelationshipCompanyContact: async (contact: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/UpdateRelationshipCategoryCompanyContact`;
        await patch({ url, payload: contact, customError });
    },
    reorderCompanyContacts: async (contacts: any[], { customError }: any = {}) => {
        const url = `/v1/Relationship/ReorderRelationshipCategoryCompanyContacts`;
        await post({ url, payload: contacts, customError });
    },
    deleteCompanyContact: async (contactId: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/DeleteRelationshipCategoryCompanyContact?id=${contactId}`;
        await remove({ url, customError });
    },
    createRelationshipSupplierContact: async (contact: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/CreateRelationshipCategorySupplierContact`;
        return await post({ url, payload: contact, customError });
    },
    updateRelationshipSupplierContact: async (contact: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/UpdateRelationshipCategorySupplierContact`;
        await patch({ url, payload: contact, customError });
    },
    reorderSupplierContacts: async (contacts: any[], { customError }: any = {}) => {
        const url = `/v1/Relationship/ReorderRelationshipCategorySupplierContacts`;
        await post({ url, payload: contacts, customError });
    },
    deleteSupplierContact: async (contactId: any, { customError }: any = {}) => {
        const url = `/v1/Relationship/DeleteRelationshipCategorySupplierContact?id=${contactId}`;
        await remove({ url, customError });
    },
};

export default Relationships;
