import React, { FunctionComponent, Fragment } from "react";
import { getActivity } from "components/utilitycomponents";
import { Row } from "antd";
import { IActivityData } from "models";
import { elapsedTime } from "utilities";
import classes from "../registration/styles/registrationModal.module.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: any;
};

const ReviewHeader: FunctionComponent<PropsType> = ({ data }) => {
    const activityData: IActivityData = {
        isFollow: data.isFollow,
        canDelete: false,
        typeName: "Review",
        activity: {
            id: data.activityId,
            created: data.created,
        },
        children: data.childrenActivity,
        reviewDetail: {
            id: data.id,
            isOnPartner: data.isOnPartner,
            partnerId: data.reviewedPartnerId,
            reviewedPartnerName: data.reviewedPartnerName,
            categoryName: data.categoryName,
            creator: data.createdBy,
            creatorProfilePictureId: data.creatorProfilePictureId,
            rating: data.rating,
            visibility: data.visibility,
            fromOrOnPartner: data.fromOrOnPartner,
        },
    };
    const { ActivityTypeComponent, activityDetail } = getActivity(activityData);
    const { t } = useTranslation();
    return (
        <Fragment>
            <Row data-test="activity_detail_row">
                <ActivityTypeComponent data={activityDetail} />
            </Row>

            <Row className={classes.creationTime}>
                <span>
                    {t("review_Created")}: {elapsedTime(activityData.activity.created)}
                </span>
            </Row>
        </Fragment>
    );
};

export default ReviewHeader;
