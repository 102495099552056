import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Input, Select, notification, Form } from "antd";
import { useSupplierCardsRiskUpdate } from "controller";
const Option = Select.Option;
const TextArea = Input.TextArea;

interface IProps {
    partnerId: number;
    data: any;
    riskTypes: any[];
    visible: boolean;
    onClose: Function;
    onReloadTable: Function;
}

const RiskEditModal = ({ partnerId, data, riskTypes, visible, onClose, onReloadTable }: IProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { loading, updateRisk } = useSupplierCardsRiskUpdate();

    useEffect(() => {
        form.resetFields();
    }, [partnerId, data]);

    const handleSave = async () => {
        let fieldsValue = await form.validateFields();
        const formValues = {
            severity: fieldsValue["severity"] ? fieldsValue["severity"] : null,
            likelyhood: fieldsValue["likelihood"] ? fieldsValue["likelihood"] : null,
            comment: fieldsValue["comment"] ? fieldsValue["comment"] : "",
        };
        await updateRisk({ partnerId, riskTypeId: data.riskTypeId, ...formValues });
        notification.success({
            message: "Success",
            description: t("supplierProfile_UpdateSuccess", {
                title: t(`riskType_${data.name}`),
                interpolation: { escapeValue: false },
            }),
        });
        handleClose();
        onReloadTable();
    };
    const handleClose = () => {
        onClose();
        form.resetFields();
    };

    const getTranslationKey = (name: string) => {
        switch (name) {
            case "Low":
                return "supplierProfile_RiskLow";
            case "Medium":
                return "supplierProfile_RiskMedium";
            case "High":
                return "supplierProfile_RiskHigh";
            default:
                return name;
        }
    };

    return (
        <Modal
            title={t(`riskType_${data.name}`)}
            onCancel={handleClose}
            footer={[
                <Button key="ok" type="primary" loading={loading} onClick={handleSave}>
                    {t("generic_Save")}
                </Button>,
            ]}
            open={visible}>
            <Form
                form={form}
                labelAlign="left"
                labelWrap
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                    label={t("supplierProfile_RiskLikelihood")}
                    name="likelihood"
                    initialValue={data.likelihoodId ? data.likelihoodId : undefined}>
                    <Select placeholder={t("select") + "..."} allowClear>
                        <Option key={-1} value="" style={{ color: "#BFBFBF" }}>
                            {t("select") + "..."}
                        </Option>
                        {riskTypes.map(r => (
                            <Option key={r.id} value={r.id}>
                                {t(getTranslationKey(r.riskName))}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("supplierProfile_RiskSeverity")}
                    name="severity"
                    initialValue={data.severityId ? data.severityId : undefined}>
                    <Select placeholder={t("select") + "..."} allowClear>
                        <Option key={-1} value="" style={{ color: "#BFBFBF" }}>
                            {t("select") + "..."}
                        </Option>
                        {riskTypes.map(r => (
                            <Option key={r.id} value={r.id}>
                                {t(getTranslationKey(r.riskName))}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("supplierProfile_RiskComment")}
                    name="comment"
                    initialValue={data.comment}>
                    <TextArea placeholder={t("supplierProfile_RiskComment")} rows={5} allowClear />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RiskEditModal;
