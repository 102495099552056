import { useState } from "react";
import { Provider } from "services";

const usePartnerStatusUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (statusId: number, statusData: any) => {
        setLoading(true);

        const res = Provider.Partner.updatePartnerStatus(statusId, statusData, {
            customError: {
                message: "Could not update status",
            },
        }).catch(() => {
            setLoading(false);
            return Promise.reject();
        });

        setLoading(false);

        return res;
    };
    return {
        loading,
        update,
    };
};

export { usePartnerStatusUpdate };
