import { useEffect, useState } from "react";
import { Provider } from "services";
import { IColumnFilter } from "components/utilitycomponents/grid/models";
import { ITableType } from "models";

type StateType = {
    loading: boolean;
    data: IColumnFilter[];
};

const useCustomFilters = (tableType: ITableType) => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.User.getColumnFilters(tableType).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({
                loading: false,
                data: data.filter((f: IColumnFilter) => f.type === "customFilter"),
            });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCustomFilters };
