import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Divider, Spin, Tooltip } from "antd";
import classnames from "classnames";
//@ts-ignore
import ReactDragListView from "react-drag-listview";
import { useReviewCategory } from "controller";
import classes from "./styles/adjustSurvey.module.scss";
import { SurveyType } from "models";
import { ChevronSelectorVertical, Trash02 } from "components/utilitycomponents";

type PropTypes = {
    selectedReviewCriteriaIds: number[];
    surveyType: SurveyType;
    onChange: Function;
};

const SurveyQuestions: FunctionComponent<PropTypes> = ({
    selectedReviewCriteriaIds,
    surveyType,
    onChange,
}) => {
    const { t } = useTranslation();
    const { data: reviewCriteria, loading: reviewCriteriaLoading } = useReviewCategory();
    const [flattenedReviewCriteria, setFlattenedReviewCriteria] = useState<any>([]);
    useEffect(() => {
        setFlattenedReviewCriteria(createFlattenedReviewCriteria(selectedReviewCriteriaIds));
    }, [selectedReviewCriteriaIds]);

    const createFlattenedReviewCriteria = (selectedIds: number[]) => {
        let filteredReviewCriteria: any = [];
        reviewCriteria.forEach((criteria: any) => {
            const hasSelectedChild =
                criteria.children &&
                selectedIds.some((id: number) =>
                    criteria.children.map((c: any) => c.id).includes(id)
                );
            const isSelectedItem = !criteria.children && selectedIds.includes(criteria.id);
            if (hasSelectedChild) {
                filteredReviewCriteria = [
                    ...filteredReviewCriteria,
                    {
                        id: criteria.id,
                        name: criteria.name,
                        children: criteria.children.filter((childCriteria: any) =>
                            selectedIds.includes(childCriteria.id)
                        ),
                    },
                ];
            }
            if (isSelectedItem) {
                filteredReviewCriteria = [
                    ...filteredReviewCriteria,
                    {
                        id: criteria.id,
                        name: criteria.name,
                        children: null,
                    },
                ];
            }
        });
        return flatten(filteredReviewCriteria);
    };

    const handleDeleteCriteria = (criteriaId: number) => {
        const newFlattenedReviewCriteria = flattenedReviewCriteria.filter(
            (criteria: any) => criteria.isGroup || criteria.id !== criteriaId
        );
        let removeChildlessGroups: any = [];
        newFlattenedReviewCriteria.forEach((criteria: any, index: number) => {
            if (
                !criteria.isGroup ||
                (criteria.isGroup &&
                    newFlattenedReviewCriteria[index + 1] &&
                    newFlattenedReviewCriteria[index + 1].parent !== null)
            ) {
                removeChildlessGroups = [...removeChildlessGroups, criteria];
            }
        });
        setFlattenedReviewCriteria(removeChildlessGroups);
        handleChange(removeChildlessGroups);
    };

    const isRemovable = () => {
        const oneCriteriaLeft =
            flattenedReviewCriteria.filter((criteria: any) => !criteria.isGroup).length === 1;
        return (
            surveyType !== SurveyType.Segmentation &&
            surveyType !== SurveyType.Performance &&
            !oneCriteriaLeft
        );
    };

    const handleChange = (data: any) => {
        onChange(
            data
                .filter((criteria: any) => !criteria.isGroup)
                .map((criteria: any, index: number) => ({
                    reviewCriteriaId: criteria.id,
                    reviewCategoryOrder: index,
                }))
        );
    };

    const flatten = (list: any[]) => {
        let flatList: any = [];

        list.forEach(item => {
            if (item.children) {
                flatList = [
                    ...flatList,
                    {
                        id: item.id,
                        name: item.name,
                        isGroup: true,
                        parent: null,
                        description: item.description,
                    },
                ];
                item.children.forEach((child: any) => {
                    flatList = [
                        ...flatList,
                        {
                            id: child.id,
                            name: child.name,
                            description: child.description,
                            isGroup: false,
                            parent: item.id,
                        },
                    ];
                });
            } else {
                flatList = [
                    ...flatList,
                    { id: item.id, name: item.name, isGroup: false, parent: null },
                ];
            }
        });
        return flatList;
    };

    let questionCount = 1;

    const dragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            const data = [...flattenedReviewCriteria];
            if (
                data[fromIndex] &&
                data[toIndex] &&
                data[fromIndex].parent === data[toIndex].parent
            ) {
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                setFlattenedReviewCriteria(data);
                handleChange(data);
            }
        },
        nodeSelector: ".criteria",
        handleSelector: ".drag",
    };

    return (
        <Spin spinning={reviewCriteriaLoading}>
            <Row>
                <Col lg={24}>
                    <div className={classnames(classes.section, classes.first)}>
                        {t("survey_Wizard_SurveyQuestions")}
                    </div>
                </Col>
            </Row>
            <Divider className={classes.sectionDivider} />
            <ReactDragListView {...dragProps}>
                {flattenedReviewCriteria.map((criteria: any) => {
                    if (criteria.isGroup) {
                        return (
                            <div key={criteria.id} className={classes.groupName}>
                                {criteria.name}
                            </div>
                        );
                    }
                    if (criteria.parent !== null) {
                        return (
                            <Row
                                key={criteria.name}
                                className={classnames("criteria", classes.criteria)}>
                                <Col lg={1}></Col>
                                <Col lg={1}>
                                    <ChevronSelectorVertical
                                        className={classnames("drag", classes.dragIcon)}
                                    />
                                </Col>
                                <Tooltip title={criteria.description} placement="topLeft">
                                    <Col lg={21} style={{ cursor: "help" }}>
                                        {`${questionCount++}. `}
                                        {criteria.name}
                                    </Col>
                                </Tooltip>
                                <Col lg={1} style={{ textAlign: "right" }}>
                                    {isRemovable() && (
                                        <Trash02
                                            title={t("generic_Delete")}
                                            onClick={() => handleDeleteCriteria(criteria.id)}
                                        />
                                    )}
                                </Col>
                            </Row>
                        );
                    } else {
                        return (
                            <Row
                                className={classnames("criteria", classes.criteria)}
                                key={criteria.name}>
                                <Col lg={1}>
                                    <ChevronSelectorVertical
                                        className={classnames("drag", classes.dragIcon)}
                                    />
                                </Col>
                                <Tooltip title={criteria.description} placement="topLeft">
                                    <Col lg={22} style={{ cursor: "help" }}>
                                        {`${questionCount++}. `}
                                        {criteria.name}
                                    </Col>
                                </Tooltip>
                                <Col lg={1} style={{ textAlign: "right" }}>
                                    {isRemovable() && (
                                        <Trash02
                                            title={t("generic_Delete")}
                                            onClick={() => handleDeleteCriteria(criteria.id)}
                                        />
                                    )}
                                </Col>
                            </Row>
                        );
                    }
                })}
            </ReactDragListView>
        </Spin>
    );
};

export default SurveyQuestions;
