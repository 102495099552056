import React, { FunctionComponent } from "react";
import classes from "./styles/shared.module.scss";
import { INewUserDetail } from "models";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: INewUserDetail;
};

const NewUser: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {t("activity_WelcomeUser", { fullname: data.fullName })}
            </div>
        </span>
    );
};

export { NewUser };
