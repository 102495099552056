import { useEffect, useState } from "react";
import { Provider } from "services";

const usePerformanceDataByDateRange = (partnerId: any, from?: any, to?: any) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: [],
    });
    useEffect(() => {
        setState({
            ...state,
            loading: true,
        });
        (async () => {
            //defaults to last 12 months
            const data = await Provider.Performance.getPerformanceDataByDateRange(
                { supplierId: partnerId, from, to },
                {
                    customError: {
                        message: "Could not get performance data",
                    },
                }
            ).catch(err => {
                setState({
                    ...state,
                    loading: false,
                });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data,
            });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { usePerformanceDataByDateRange };
