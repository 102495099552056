import React, { FunctionComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IComplianceDetail } from "models";
import { RightCircleFilled } from "@ant-design/icons";
import classes from "./styles/shared.module.scss";

type PropsType = {
    data: IComplianceDetail;
    isHomePage: boolean;
    onDisplayRegistrationModal: Function;
};

const Compliance: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IComplianceDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")} {data.fromOrOnPartner}{" "}
                {t("activity_NewComplianceRegistrationDoneOnPartner", {
                    id: data.id,
                    myCompany: data.myCompanyName,
                })}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IComplianceDetail) => {
        return (
            <Fragment>
                {t("activity_NewComplianceRegistrationByPartner", {
                    creator: data.creator,
                    id: data.id,
                })}{" "}
                {data.fromOrOnPartner}
            </Fragment>
        );
    };

    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
            <Link to={`/Compliance/${data.id}`} className={classes.notificationLink}>
                <RightCircleFilled />
                View Compliance
            </Link>
        </span>
    );
};

export { Compliance };
