import { useState } from "react";
import { Provider } from "services";

const useSurveyTemplateGroupCrud = () => {
    const [loading, setLoading] = useState(false);
    const [checkNameLoading, setCheckNameLoading] = useState(false);

    const create = async (name: string) => {
        setLoading(true);
        let data = await Provider.Review.createSurveyTemplateGroup(name, {
            customError: {
                message: "Could not create survey template group",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const update = async (id: number, name: string) => {
        setLoading(true);
        let data = await Provider.Review.updateSurveyTemplateGroup(id, name, {
            customError: {
                message: "Could not update survey template group",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const remove = async (ids: number[]) => {
        setLoading(true);
        let data = await Provider.Review.deleteSurveyTemplateGroups(ids, {
            customError: {
                message: "Could not create review criteria group",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    const checkName = async (name: string) => {
        if (name !== "") {
            setCheckNameLoading(true);
            const inUse = await Provider.Review.isSurveyTemplateGroupNameInUse(name, {
                customError: {
                    message: "Could not validate survey template group name",
                },
            }).catch((err: any) => {
                setCheckNameLoading(false);
                return Promise.reject(err);
            });
            setCheckNameLoading(false);
            return inUse;
        }
    };

    return {
        loading,
        checkNameLoading,
        create,
        update,
        remove,
        checkName,
    };
};

export { useSurveyTemplateGroupCrud };
