import { useState } from "react";
import { Provider } from "services";

const useUserInvitationSend = () => {
    const [loading, setLoading] = useState(false);

    const SendUserInvitations = async (payload: any[]) => {
        setLoading(true);
        const id = await Provider.User.sendUserInvitation(payload, {
            customError: {
                message: "Could not send invitations to users",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading,
        SendUserInvitations,
    };
};

export { useUserInvitationSend };
