import { ICreateSurvey, SurveyPartnerSpecification } from "models";
import { ICustomDropdownHeadline } from "models/survey/ICustomDropdownHeadline";
import { ITextFieldHeadline } from "models/survey/ITextFieldHeadline";

export function parseValuesToSurveyObject(values: any) {
    let customDropdownHeadlines: ICustomDropdownHeadline[] = [];
    let textFieldHeadlines: ITextFieldHeadline[] = [];
    let position = 0;
    for (let i = 0; i < values.textFieldTitleSurveyFields.length; i++) {
        if (
            values.textFieldTitleSurveyFields[i] &&
            values.textFieldTitleSurveyFields[i].trim() !== ""
        ) {
            if (values.dropdownSurveyFields && values.dropdownSurveyFields[i]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSurveyFields[i],
                    title: values.textFieldTitleSurveyFields[i],
                    headlineType: "Survey",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSurveyFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSurveyFields[i] !== undefined
                            ? values.textFieldDescriptionSurveyFields[i]
                            : "",
                    title: values.textFieldTitleSurveyFields[i],
                    headlineType: "Survey",
                    position,
                });
                position++;
            }
        }
    }
    position = 0;
    for (let i = 0; i < values.textFieldTitleSupplierFields.length; i++) {
        if (
            values.textFieldTitleSupplierFields[i] &&
            values.textFieldTitleSupplierFields[i].trim() !== ""
        ) {
            if (values.dropdownSupplierFields && values.dropdownSupplierFields[i]) {
                customDropdownHeadlines.push({
                    customDropdownId: values.dropdownSupplierFields[i],
                    title: values.textFieldTitleSupplierFields[i],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            } else if (values.textFieldDescriptionSupplierFields) {
                textFieldHeadlines.push({
                    description:
                        values.textFieldDescriptionSupplierFields[i] !== undefined
                            ? values.textFieldDescriptionSupplierFields[i]
                            : "",
                    title: values.textFieldTitleSupplierFields[i],
                    headlineType: "Supplier",
                    position,
                });
                position++;
            }
        }
    }
    let surveyPartnerSpecifications = [];
    for (let partnerId of values.partners) {
        let surveyPartnerSpecification: SurveyPartnerSpecification = {
            partnerId: 0,
            contactIds: [],
        };
        surveyPartnerSpecification.partnerId = partnerId;
        if (values.receiverContacts) {
            surveyPartnerSpecification.contactIds = values.receiverContacts[partnerId]
                ? values.receiverContacts[partnerId]
                : [];
        }

        surveyPartnerSpecifications.push(surveyPartnerSpecification);
    }

    let survey: ICreateSurvey = {
        title: values.title,
        introduction: values.introduction,
        endDate: values.endDate ? values.endDate : undefined,
        selectedPartnerIds: values.partners && values.partners,
        selectedReviewCriterias: values.selectedReviewCriteriaIds.map((id: number) => ({
            reviewCriteriaId: id,
            reviewCriteriaOrder: 0,
        })),
        visibility: values.visibility,
        selectedContactGroupIds: values.contactGroups ? values.contactGroups : [],
        selectedContactIds: values.users ? values.users : [],
        isVoiceOfSupplier: values.isVoiceOfSupplier && values.isVoiceOfSupplier,
        allowGuestOnVOS: values.allowGuestVOS && values.allowGuestVOS,
        introductionVOS: values.introductionVOS,
        allowGuest: values.allowGuest && values.allowGuest,
        customDropdownHeadlines: customDropdownHeadlines,
        textFieldHeadlines: textFieldHeadlines,
        surveyTemplateId: values.surveyTemplateId,
        surveyPartnerSpecifications,
    };

    return survey;
}
