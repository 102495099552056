import { useState } from "react";
import Provider from "services/api";

const useRelationshipSupplierContactCRUD = () => {
    const [loading, setLoading] = useState(false);

    const createContact = async (contact: any) => {
        setLoading(true);
        const id = await Provider.Relationships.createRelationshipSupplierContact(contact, {
            customError: {
                message: "Could not create contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const updateContact = async (contact: any) => {
        setLoading(true);
        await Provider.Relationships.updateRelationshipSupplierContact(contact, {
            customError: {
                message: "Could not update contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const deleteContact = async (id: number) => {
        setLoading(true);
        await Provider.Relationships.deleteSupplierContact(id, {
            customError: {
                message: "Could not delete contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const reorder = async (contacts: any[]) => {
        setLoading(true);
        await Provider.Relationships.reorderSupplierContacts(contacts, {
            customError: {
                message: "Could not update contact",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        createContact,
        updateContact,
        deleteContact,
        reorder,
    };
};

export { useRelationshipSupplierContactCRUD };
