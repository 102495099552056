import React, { FunctionComponent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridButton, GridDeleteModal, PlusCircle, Trash02 } from "components/utilitycomponents";
import { notification } from "antd";
import { useActionCRUD } from "controller";
import { IOverviewAction } from "models/action";

type PropsType = {
    selectedRowKeys: any[];
    selectedRows: IOverviewAction[];
    onClearSelectedRows: Function;
    onReloadData: Function;
    onCreateClicked: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    selectedRowKeys,
    selectedRows,
    onClearSelectedRows,
    onReloadData,
    onCreateClicked,
}) => {
    const { t } = useTranslation();
    const { deleteActions, loading: deleteLoading } = useActionCRUD();
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });

    const buttonVisibility =
        selectedRowKeys.length > 0 ||
        selectedRows.some(s => s.editable === true && s.systemActionEditable === true);

    const handleDelete = async (ids: number[]) => {
        await deleteActions(ids);
        notification.success({
            message: t("success"),
            description: t(`action_DeleteSuccess`),
        });
        setDeleteModal({
            visible: false,
        });
        onClearSelectedRows([]);
        onReloadData();
    };

    return (
        <Fragment>
            {buttonVisibility ? (
                <GridButton
                    danger
                    data-test="delete_button"
                    icon={<Trash02 style={{ color: "#FF4D4F" }} />}
                    type="default"
                    onClick={() => setDeleteModal({ visible: true })}>
                    {t("generic_Delete")}
                </GridButton>
            ) : (
                <>
                    <GridButton
                        data-test="create_action_button"
                        icon={<PlusCircle />}
                        type="default"
                        onClick={() => onCreateClicked()}>
                        {t("action_CreateAction")}
                    </GridButton>

                    <GridDeleteModal
                        visible={deleteModal.visible}
                        onClose={() => {
                            setDeleteModal({
                                visible: false,
                            });
                            onClearSelectedRows([]);
                        }}
                        data={selectedRowKeys.map((id: number) => ({
                            id: id,
                            name: `${id}`,
                        }))}
                        name={t("action_ActionsPlural")}
                        confirmLoading={deleteLoading}
                        onConfirm={handleDelete}
                    />
                </>
            )}
        </Fragment>
    );
};

export default OverviewHeader;
