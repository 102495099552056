import React, { FunctionComponent, Fragment } from "react";
import { Eye, GridButton, PlusCircle, Trash02 } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";

type PropsType = {
    customText: string;
    onCreateBtnClick: Function;
    onDeleteBtnClick: Function;
    selectedRowKeys: any[];
    externallyCreatedSelected: boolean;
    onChangeVisibilityClick: Function;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    customText,
    onCreateBtnClick,
    onDeleteBtnClick,
    selectedRowKeys,
    externallyCreatedSelected,
    onChangeVisibilityClick,
}) => {
    const { t } = useTranslation();
    const buttonVisibility = selectedRowKeys.length < 1 || externallyCreatedSelected;
    return (
        <Fragment>
            {buttonVisibility ? (
                <GridButton data-test="create_ncr_button" onClick={() => onCreateBtnClick()}>
                    <PlusCircle />
                    {customText}
                </GridButton>
            ) : (
                <>
                    <GridButton
                        data-test="change_visibility_button"
                        onClick={() => onChangeVisibilityClick()}>
                        <Eye />
                        {t("ncr_ChangeVisibility")}
                    </GridButton>
                    <GridButton
                        danger
                        data-test="delete_ncr_button"
                        onClick={() => onDeleteBtnClick()}>
                        <Trash02
                            style={{
                                color:
                                    selectedRowKeys.length !== 0 && !externallyCreatedSelected
                                        ? "#FF4D4F"
                                        : "",
                            }}
                        />
                        {t("ncr_Delete")}
                    </GridButton>
                </>
            )}
        </Fragment>
    );
};

export default OverviewHeader;
