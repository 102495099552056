import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const useMeetingMinuteGuest = (reload: any, token: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: undefined,
    });

    useEffect(() => {
        (async () => {
            if (token !== undefined) {
                setState({ ...state, loading: true });
                let data = await Provider.Governance.getMeetingMinuteByToken(token, {
                    customError: {
                        message: "Could not retrieve meeting minute",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });

                setState({ loading: false, data });
            }
        })();
    }, [reload, token]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMeetingMinuteGuest };
