import React, { useState, useEffect, FunctionComponent } from "react";
import { Result, Skeleton } from "antd";
import { useSummaryReport } from "controller";
import useWindowDimensions from "utilities/helpers/useWindowDimensions";
import classes from "./summaryReport.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
    supplierData: any;
    partnerId: number;
}

const SummaryReport: FunctionComponent<IProps> = ({ supplierData, partnerId }) => {
    const [reload, setReload] = useState<any>({ reload: false });
    const { data, loading } = useSummaryReport(reload);
    const { height } = useWindowDimensions();
    const { t } = useTranslation();

    useEffect(() => {
        setReload({ reload: true });
    }, [partnerId]);

    const setContainerClass = () => {
        if (height) {
            if (height <= 1100 && height >= 900) {
                return classes.infoContainerSmall;
            } else if (height <= 890) {
                return classes.infoContainerExtraSmall;
            } else {
                return "";
            }
        }
    };

    return (
        <>
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {data && data.embedLink ? (
                        <>
                            <iframe
                                className={setContainerClass()}
                                width="100%"
                                height={300}
                                style={{
                                    borderWidth: 0,
                                    padding: 0,
                                    height: height! - 200,
                                }}
                                src={data.embedLink.replace(
                                    data.queryParameterName,
                                    supplierData.supplierNumber
                                )}
                                title={"Spend Analytics"}></iframe>
                        </>
                    ) : (
                        <Result
                            status="info"
                            title={t("generic_ReportNotConfigured")}
                            subTitle={t("generic_ReportNotConfiguredSubTitle")}
                        />
                    )}
                </>
            )}
        </>
    );
};

export { SummaryReport };
