import React, { FunctionComponent } from "react";
import { Tag } from "antd";
import i18n from "i18n";

type PropsType = {
    visibility: string;
};

const VisibilityTag: FunctionComponent<PropsType> = ({ visibility }) => {
    return (
        <Tag data-test={visibility} color={visibility === "Internal" ? "#00b203" : "#f89406"}>
            {i18n.t(visibility.toLowerCase())}
        </Tag>
    );
};

export { VisibilityTag };
