function findNcrFieldVisibility(internalName: string, ncrFields: any[]) {
    let visibility: number = 0;
    if (ncrFields) {
        let ncrField = ncrFields.filter((x: any) => x.internalName === internalName)[0];
        if (ncrField !== undefined) {
            visibility = ncrField.visibility;
        }
    }

    return visibility;
}

const getCustomLabelVisiblity = (ncrFields: any[]) => {
    let purchaseOrderNumberVisiblity = findNcrFieldVisibility("PurchaseOrderNumber", ncrFields);
    let salesOrderNumberVisiblity = findNcrFieldVisibility("SalesOrderNumber", ncrFields);
    let valueVisiblity = findNcrFieldVisibility("ValueOfNCR", ncrFields);
    let otherCostsVisiblity = findNcrFieldVisibility("OtherCostsToNCR", ncrFields);
    let otherCostsReasonVisiblity = findNcrFieldVisibility("ReasonForOtherCosts", ncrFields);
    let commentVisiblity = findNcrFieldVisibility("Comment", ncrFields);
    let deliveryNoteVisiblity = findNcrFieldVisibility("DeliveryNote", ncrFields);
    let visibilityVisiblity = findNcrFieldVisibility("Visibility", ncrFields);
    let deviationsVisiblity = findNcrFieldVisibility("Deviations", ncrFields);
    let deviationsProductNumberVisiblity = findNcrFieldVisibility("ProductNumber", ncrFields);
    let deviationsDescriptVisiblity = findNcrFieldVisibility("Description", ncrFields);
    let deviationsOrderedVisiblity = findNcrFieldVisibility("Ordered", ncrFields);
    let deviationsDeliveredVisiblity = findNcrFieldVisibility("Delivered", ncrFields);
    let deviationsFaultyVisiblity = findNcrFieldVisibility("Faulty", ncrFields);

    return {
        purchaseOrderNumberVisiblity,
        salesOrderNumberVisiblity,
        valueVisiblity,
        otherCostsVisiblity,
        otherCostsReasonVisiblity,
        commentVisiblity,
        deliveryNoteVisiblity,
        visibilityVisiblity,
        deviationsVisiblity,
        deviationsProductNumberVisiblity,
        deviationsDescriptVisiblity,
        deviationsOrderedVisiblity,
        deviationsDeliveredVisiblity,
        deviationsFaultyVisiblity,
    };
};

export { getCustomLabelVisiblity };
