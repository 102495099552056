import React, { useEffect, useState } from "react";
import { Row, Col, MenuProps, Menu, Avatar, Dropdown, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getBackendDomainUrl } from "utilities";
import classes from "./headerBar.module.scss";
import userManager from "userManager";
import {
    useAvailableSharedDashboards,
    useFilesFeature,
    useSupplierCustomerEmails,
} from "controller";
import {
    AlertOctagon,
    ArrowsRight,
    BarChart07,
    ClipboardCheck,
    File02,
    Home02,
    Mail,
    Star01,
    User01,
} from "components/utilitycomponents";
type MenuItem = Required<MenuProps>["items"][number];

const SupplierHeaderBar = () => {
    const { t } = useTranslation("supplierPortal");
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const isGuest: boolean = userType === "Guest";
    const [companyLogoUrl, setCompanyLogoUrl] = useState("");
    const customerCompanyIds = user.profile["ll-SupplierCustomerCompanyId"]
        ? user.profile["ll-SupplierCustomerCompanyId"].split(",")
        : [];
    const companyComplianceAccess = user.profile["ll-enabledCompanyModule-Compliance"] === "true";
    const companyNcrAccess = user.profile["ll-enabledCompanyModule-Ncr"] === "true";
    const companyActionAccess = user.profile["ll-enabledCompanyModule-Action"] === "true";
    const companyReviewAccess = user.profile["ll-enabledCompanyModule-Review"] === "true";
    const companyFileAccess = user.profile["ll-enabledCompanyModule-File"] === "true";
    const { data: sharedDashboards } = useAvailableSharedDashboards();
    const { data: customerEmails } = useSupplierCustomerEmails();
    const { hasFilesFeature } = useFilesFeature();

    useEffect(() => {
        if (customerCompanyIds.length === 1) {
            setCompanyLogoUrl(
                `${getBackendDomainUrl()}/v1/File/GetCompanyLogo?companyId=${customerCompanyIds[0]}`
            );
        }
    }, []);

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[]
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem(<Link to="/">{t("navigation_Home")}</Link>, "home:1", <Home02 />),
        // getItem("Contacts", "contact:1", <ContactsFilled />),
        companyComplianceAccess
            ? getItem(
                  <Link to="/Compliance">{t("navigation_Compliance")}</Link>,
                  "compliance:1",
                  <ClipboardCheck />
              )
            : null,
        companyComplianceAccess
            ? getItem(
                  <Link to="/SelfAssessmentRequests">
                      {t("navigation_SelfAssessmentRequests")}
                  </Link>,
                  "selfAss:1",
                  <>
                      <ClipboardCheck />
                      <span
                          style={{
                              marginLeft: 0,
                              marginRight: 10,
                              fontSize: 16,
                              fontWeight: "bold",
                          }}>
                          !
                      </span>
                  </>
              )
            : null,
        companyNcrAccess
            ? getItem(<Link to="/Ncrs">{t("navigation_Ncr")}</Link>, "ncr:1", <AlertOctagon />)
            : null,
        companyReviewAccess
            ? getItem(
                  <Link to="/SurveyRequests">{t("navigation_SurveyRequests")}</Link>,
                  "review:1",
                  <>
                      <Star01 />
                      <span
                          style={{
                              marginLeft: 0,
                              marginRight: 10,
                              fontSize: 16,
                              fontWeight: "bold",
                          }}>
                          !
                      </span>
                  </>
              )
            : null,
        companyReviewAccess
            ? getItem(<Link to="/Reviews">{t("navigation_Reviews")}</Link>, "review:2", <Star01 />)
            : null,
        companyActionAccess
            ? getItem(
                  <Link to="/Actions">{t("navigation_Actions")}</Link>,
                  "action:1",
                  <ArrowsRight />
              )
            : null,
        sharedDashboards.length > 0
            ? getItem(
                  <Link to="/partnerAnalytics">{t("analytics_SharedReports")}</Link>,
                  "partnerAnalytics:1",
                  <BarChart07 />
              )
            : null,
        companyFileAccess && hasFilesFeature
            ? getItem(<Link to="/Files">{t("navigation_Files")}</Link>, "file:1", <File02 />)
            : null,
        userType === "administrator"
            ? getItem(
                  <Link to="/Admin/UserManagement">{t("navigation_UserManagement")}</Link>,
                  "user:1",
                  <User01 />
              )
            : null,
    ];

    return (
        <>
            <Row>
                <Col xs={18} sm={18} md={18} lg={18} xl={19} xxl={20}>
                    <Row>
                        <Col xs={7} sm={7} md={7} lg={6} xl={5} xxl={4}>
                            <div style={{ paddingTop: 6 }}>
                                {customerCompanyIds.length === 1 && (
                                    <Link to={"/"}>
                                        <img
                                            alt=""
                                            src={companyLogoUrl}
                                            className={classes.logoImg}
                                        />
                                    </Link>
                                )}
                            </div>
                        </Col>
                        <Col xs={17} sm={17} md={17} lg={18} xl={19} xxl={20}>
                            {!isGuest && <Menu mode="horizontal" items={items} />}
                        </Col>
                    </Row>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={4} xxl={3} style={{ textAlign: "right" }}>
                    {customerEmails.length === 1 && (
                        <Button
                            icon={<Mail />}
                            onClick={() => (window.location.href = `mailto:${customerEmails[0]}`)}>
                            {t("generic_ContactCustomer")}
                        </Button>
                    )}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                    {!isGuest && (
                        <div style={{ textAlign: "right" }}>
                            <Dropdown
                                className={classes.dropdownMenu}
                                menu={{
                                    items: [
                                        {
                                            key: 1,
                                            label: (
                                                <Link to="/User">
                                                    <div>{t("navigation_AccountSettings")}</div>
                                                </Link>
                                            ),
                                        },
                                        {
                                            key: 2,
                                            label: t("navigation_Logout"),
                                            onClick: () => {
                                                userManager.signoutRedirect({
                                                    id_token_hint: user.id_token,
                                                });
                                                userManager.removeUser();
                                            },
                                        },
                                    ],
                                }}>
                                <Avatar size="large" icon={<User01 style={{ fontSize: 24 }} />} />
                            </Dropdown>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export { SupplierHeaderBar };
