import { useSelector } from "react-redux";
import { AppState } from "store";

const useNcrFeature = () => {
    const user = useSelector((state: AppState) => state.oidc.user);

    const hasNcrFeature = user && user.profile && user.profile["ll-enabledFeature-Ncr"] === "true";

    return {
        hasNcrFeature,
    };
};

export { useNcrFeature };
