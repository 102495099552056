import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "blueimp-canvas-to-blob/js/canvas-to-blob";
import Cropper from "react-easy-crop";
import { Modal, Slider, notification } from "antd";
import { canvasToBlob } from "./imageUtility";
import "./styles/cropImageModal.scss";

type PropsType = {
    image: { imageFile: any; imageName: string; dimensions: any; imageType: string };
    visible: boolean;
    onClose: () => void;
    onConfirm: Function;
};

const CropImageModal: FunctionComponent<PropsType> = ({ image, visible, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<any>(1);
    const [area, setArea] = useState<any>();

    useEffect(() => {
        if (visible) {
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setArea({});
        }
    }, [visible]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setArea({
            croppedArea,
            croppedAreaPixels,
        });
    }, []);

    const handleConfirm = async () => {
        try {
            const canvas = document.createElement("canvas");
            canvas.width = area.croppedAreaPixels.width;
            canvas.height = area.croppedAreaPixels.height;
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                return;
            }
            const img = new Image();
            img.src = image.imageFile;

            ctx.drawImage(
                img,
                area.croppedAreaPixels.x,
                area.croppedAreaPixels.y,
                area.croppedAreaPixels.width,
                area.croppedAreaPixels.height,
                0,
                0,
                area.croppedAreaPixels.width,
                area.croppedAreaPixels.height
            );
            const file = await canvasToBlob(canvas, image.imageName, image.imageType);
            onConfirm(file);
        } catch (e) {
            notification.error({
                message: t("systemMessage"),
                description: t(
                    "attachImageError",
                    "Something went wrong with uploading this image please upload another image or try again later"
                ),
            });
        }
    };
    if (image.imageFile === null) return null;
    return (
        <Modal
            title={t("account_CropAndAdjust", "Crop & Adjust")}
            open={visible}
            onOk={handleConfirm}
            onCancel={onClose}
            okText={t("generic_Confirm")}
            bodyStyle={{ overflow: "hidden", padding: "24px 0" }}
            cancelText={t("generic_Cancel")}>
            <div
                style={{
                    position: "relative",
                    height: image.dimensions.height,
                    width: image.dimensions.width,
                    margin: "auto",
                }}>
                <Cropper
                    image={image.imageFile}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    style={{
                        containerStyle: {
                            left:
                                image.dimensions.width < 512
                                    ? 0
                                    : `${-image.dimensions.width + 254 + 256}px`,
                        },
                    }}
                />
            </div>

            <div style={{ padding: "0 24px" }}>
                <Slider
                    className="cropImageSlider"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.2}
                    onChange={(value: any) => setZoom(value)}
                />

                <div style={{ marginTop: "10px" }}>
                    {t("account_ScrollToZoom", "Scroll on the image or use the slider to zoom in")}
                </div>
            </div>
        </Modal>
    );
};
export { CropImageModal };
