import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/regAction.module.scss";
import { Descriptions } from "antd";
import { colorGrade } from "components/contentcomponents/reviews/survey/modals/surveyResults/surveyResults";

interface IProps {
    data: any;
}

const PerformanceData = ({ data }: IProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        renderValues();
    }, []);

    const renderValues = () => {
        let values = [];
        if (data.actionPerformanceValues === undefined) {
            values = Object.keys(data.values).map((key: string) => {
                return {
                    name:
                        key === "TotalAverage"
                            ? t("supplierProfile_Performance_TotalScore")
                            : data.columns.filter(
                                  (col: any) => "CategoryGroup" + col.criteriaId.toString() === key
                              )[0].criteriaName,
                    value: data.values[key],
                };
            });

            return values.map((value: any) => (
                <Descriptions.Item
                    label={
                        <div>
                            <span className={classes.descriptorLabel}>{value.name}</span>
                        </div>
                    }>
                    <span
                        className={classes.dataContent}
                        style={{ color: colorGrade(value.value) }}>
                        {value.value && value.value.toPrecision(2)}
                    </span>
                </Descriptions.Item>
            ));
        } else if (
            data.actionPerformanceValues !== undefined &&
            data.actionPerformanceValues.length > 0
        ) {
            return data.actionPerformanceValues.map((value: any) => (
                <Descriptions.Item
                    label={
                        <div>
                            <span className={classes.descriptorLabel}>{value.name}</span>
                        </div>
                    }>
                    <span
                        className={classes.dataContent}
                        style={{ color: colorGrade(value.value) }}>
                        {value.value && value.value.toPrecision(2)}
                    </span>
                </Descriptions.Item>
            ));
        }
    };

    return (
        <div className={classes.dataInfoContainer}>
            <Descriptions layout="vertical" column={7} colon={false} size="small">
                {renderValues()}
            </Descriptions>
        </div>
    );
};

export { PerformanceData };
