import React from "react";
import { htmlSanitizer } from "utilities";

type PropsType = {
    textContent: string;
    [key: string]: any;
};

const PureDiv = ({ textContent, ...rest }: PropsType) => {
    return <div {...rest} dangerouslySetInnerHTML={{ __html: htmlSanitizer(textContent) }}></div>;
};

export { PureDiv };
