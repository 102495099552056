import React from "react";
import classes from "./styles/supplierProfile.module.scss";
import { Modal } from "antd";
import SupplierProfile from "./supplierProfile";

interface IProps {
    supplierId: number;
    open: boolean;
    onClose: () => void;
}

const SupplierProfileModal = ({ open, onClose, supplierId }: IProps) => {
    return (
        <>
            {open && (
                <Modal
                    style={{ marginLeft: "-1%" }}
                    mask={true}
                    width={"100%"}
                    centered
                    open={open}
                    onCancel={() => onClose()}
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    wrapClassName={classes.fullScreenModal}>
                    <SupplierProfile propsPartnerId={supplierId} />
                </Modal>
            )}
        </>
    );
};

export { SupplierProfileModal };
