import React, { useState } from "react";
import { Col, Row } from "antd";
import { Gauge } from "components/utilitycomponents";
import classes from "../summary.module.scss";
import { useSupplierProfileComplianceScore, useCarbonScore } from "controller";
import RegistrationModal from "components/contentcomponents/compliance/overview/registrationModal/registrationModal";
import { useTranslation } from "react-i18next";

interface IProps {
    partnerId: number;
}

const GuageMetrics = ({ partnerId }: IProps) => {
    const { t } = useTranslation();
    const [registrationModal, setRegistrationModal] = useState({
        id: 0,
        visible: false,
    });
    const {
        data: complianceScoreData,
        loading: loadingComplianceScore,
    } = useSupplierProfileComplianceScore(partnerId);

    const { data: carbonScoreData, loading: loadingCarbonScore } = useCarbonScore(partnerId);

    const carbonScoreScaleValues = [
        {
            percentage: 20,
            color: "#00E004",
            description: t("generic_VeryLow"),
        },
        {
            percentage: 40,
            color: "#00b204",
            description: t("generic_Low"),
        },
        {
            percentage: 60,
            color: "#ffd965",
            description: t("generic_Medium"),
        },
        {
            percentage: 80,
            color: "#ff7a45",
            description: t("generic_High"),
        },
        {
            percentage: 100,
            color: "#b20000",
            description: t("generic_VeryHigh"),
        },
    ];

    const susScaleValues = [
        {
            percentage: 20,
            color: "#b20000",
            description: t("generic_VeryLow"),
        },
        {
            percentage: 40,
            color: "#ff7a45",
            description: t("generic_Low"),
        },
        {
            percentage: 60,
            color: "#ffd965",
            description: t("generic_Medium"),
        },
        {
            percentage: 80,
            color: "#00b204",
            description: t("generic_High"),
        },
        {
            percentage: 100,
            color: "#00E004",
            description: t("generic_VeryHigh"),
        },
    ];

    return (
        <>
            <Row gutter={32} style={{ paddingRight: 1 }}>
                <Col span={24} className={classes.marginBottom}>
                    <Gauge
                        style={{ height: 200, paddingTop: "25%" }}
                        title={t("supplierProfile_summary_MaturityScore").toUpperCase()}
                        type="dashboard"
                        modifiedFieldName="lastUpdated"
                        useData={() => ({
                            data: complianceScoreData,
                            loading: loadingComplianceScore,
                        })}
                        descriptionPosition="right"
                        descriptionStyle={{ paddingTop: "25%", fontSize: 30 }}
                        format={"percentage"}
                        maxNumber={5}
                        strokeWidth={10}
                        tooltipPosition="right"
                        onClick={() => {
                            if (complianceScoreData && complianceScoreData.hasAccess === true) {
                                setRegistrationModal({
                                    id: complianceScoreData.complianceId,
                                    visible: true,
                                });
                            }
                        }}
                        strokeLinecap="round"
                        scaleValues={susScaleValues}
                    />
                </Col>

                <Col span={24}>
                    <Gauge
                        hideNumber={true}
                        style={{ height: 200, paddingTop: "25%" }}
                        title={t("supplierProfile_summary_CarbonContribution").toUpperCase()}
                        type="dashboard"
                        modifiedFieldName="created"
                        useData={() => ({
                            data: carbonScoreData,
                            loading: loadingCarbonScore,
                        })}
                        descriptionPosition="right"
                        descriptionStyle={{ paddingTop: "25%", fontSize: 30 }}
                        format={"percentage"}
                        maxNumber={5}
                        strokeWidth={10}
                        tooltipPosition="right"
                        strokeLinecap="round"
                        scaleValues={carbonScoreScaleValues}
                    />
                </Col>
            </Row>
            {registrationModal.visible && (
                <RegistrationModal
                    id={registrationModal.id}
                    visible={registrationModal.visible}
                    onClose={() => {
                        setRegistrationModal({ id: 0, visible: false });
                    }}
                />
            )}
        </>
    );
};

export { GuageMetrics };
