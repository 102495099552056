export enum ProfilePage {
    "SummaryPage" = 1,
    "SegmentationManagement" = 2,
    "PerformanceManagement" = 3,
    "Governance" = 4,
    "Risk" = 6,
    "StrategyAndGovernance" = 7,
    "PerformanceOverview" = 8,
    "RiskOverview" = 9,
    "Masterdata" = 10,
    "Analytics" = 11,
    "Compliance" = 12,
    "Actions" = 13,
}
