import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useNcrChangeVisibility = () => {
    const [loading, setLoading] = useState(false);

    const ChangeVisibility = async (actionIds: number[], visibility: any) => {
        setLoading(true);
        if (visibility !== undefined) {
            await serviceProvider.Ncr.changeNcrVisibility(actionIds, visibility).catch(err => {
                setLoading(false);
                return Promise.reject(err);
            });
        }
        setLoading(false);
    };

    return {
        loading,
        ChangeVisibility,
    };
};

export { useNcrChangeVisibility };
