import { useEffect, useState } from "react";
import { Provider } from "services";
import { ISegmentCategory as SegmentCategory } from "models";

type StateType = {
    loading: boolean;
    data: SegmentCategory[];
};

const useSegmentCategories = (color?: string, emptyOption?: boolean) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Partner.getSegmentCategories({
                customError: {
                    message: "Could not retrieve segment categories",
                },
            }).catch((err: any) => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            if (color !== undefined) {
                data = data.map((x: any) => ({ ...x, color: color }));
            }
            if (emptyOption === true) {
                data.push({ id: -1, segment: "None" });
            }
            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSegmentCategories };
