import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tooltip, Popconfirm } from "antd";
import {
    UserImage,
    VisibilityTag,
    LoadingIndicator,
    DeleteIcon,
} from "components/utilitycomponents";
import classes from "./styles/shared.module.scss";
import { elapsedTime } from "utilities";
import { getActivity, ChildActivitiesForHome, AddChildMessage } from "components/utilitycomponents";
import { IActivityData, IActivityChild } from "models";
import { useActivityFollowingUpdate, useActivityRemove } from "controller";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

type PropsType = {
    activityData: IActivityData;
    onAddActivity: (parentActivityId: number, activity: IActivityChild) => void;
    onRemoveActivity: (activityId: number) => void;
    onRemoveChildActivity: (parentActivityId: number, activityId: number) => void;
    onDisplayRegistrationModal: Function;
    onDisplayNcrModal: Function;
    onDisplayReviewModal: Function;
};

const Activity = ({
    activityData,
    onAddActivity,
    onRemoveActivity,
    onRemoveChildActivity,
    onDisplayRegistrationModal,
    onDisplayNcrModal,
    onDisplayReviewModal,
}: PropsType) => {
    const { t } = useTranslation();
    const [isFollowed, setIsFollowed] = useState(activityData.isFollow);
    const {
        loading: updateIsFollowedLoading,
        update: updateIsFollowed,
    } = useActivityFollowingUpdate();

    const { loading: removeLoading, remove: removeActivity } = useActivityRemove();

    useEffect(() => {
        setIsFollowed(activityData.isFollow);
    }, [activityData]);

    const handleUpdateFollowing = async () => {
        await updateIsFollowed(activityData.activity.id, !isFollowed);
        setIsFollowed(!isFollowed);
    };

    const handleRemoveActivity = async () => {
        await removeActivity(activityData.activity.id);
        onRemoveActivity(activityData.activity.id);
    };

    const handleRemoveChildActivity = (parentActivityId: number, activityId: number) => {
        onRemoveChildActivity(parentActivityId, activityId);
    };

    const { ActivityTypeComponent, activityDetail } = getActivity(activityData);

    return (
        activityDetail && (
            <Card
                data-test="activityDetail"
                style={{ marginBottom: "12px", textAlign: "left", cursor: "auto" }}
                className={classes.activityCard}
                size="small">
                <Row>
                    <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={2}>
                        <UserImage
                            customDimension={65}
                            contactId={activityData.activity.createdByContactId}
                            alt={activityDetail.creator}
                            style={{ width: "100%" }}
                            fileId={activityDetail["creatorProfilePictureId"]}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={20} lg={20} xl={21} xxl={22}>
                        <Row>
                            <Col span={22}>
                                <ActivityTypeComponent
                                    data={activityDetail}
                                    isHomePage={true}
                                    onDisplayRegistrationModal={onDisplayRegistrationModal}
                                    onDisplayNcrModal={onDisplayNcrModal}
                                    onDisplayReviewModal={onDisplayReviewModal}
                                />
                            </Col>
                            <Col span={2} style={{ textAlign: "right" }}>
                                <Tooltip
                                    placement="top"
                                    mouseLeaveDelay={0}
                                    title={
                                        isFollowed
                                            ? t("activity_StopFollow")
                                            : t("activity_StartFollow")
                                    }>
                                    {updateIsFollowedLoading ? (
                                        <LoadingIndicator />
                                    ) : isFollowed ? (
                                        <EyeOutlined onClick={handleUpdateFollowing} />
                                    ) : (
                                        <EyeInvisibleOutlined onClick={handleUpdateFollowing} />
                                    )}
                                </Tooltip>
                                {activityData.canDelete && (
                                    <Tooltip
                                        placement="top"
                                        title={t("generic_Delete")}
                                        mouseLeaveDelay={0}>
                                        {removeLoading ? (
                                            <LoadingIndicator />
                                        ) : (
                                            <Popconfirm
                                                placement="topRight"
                                                title={t("activity_DeleteConfirm")}
                                                onConfirm={handleRemoveActivity}
                                                okText={t("generic_Yes")}
                                                cancelText={t("generic_No")}>
                                                <DeleteIcon
                                                    data-test="delete_activity_data_icon"
                                                    style={{ marginLeft: 6 }}
                                                />
                                            </Popconfirm>
                                        )}
                                    </Tooltip>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ fontSize: "12px" }} span={12}>
                                {elapsedTime(activityData.activity.created)}
                            </Col>

                            {"visibility" in activityDetail && (
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <VisibilityTag visibility={activityDetail.visibility} />
                                </Col>
                            )}
                        </Row>
                        <div data-test="add_child_message_row" style={{ marginTop: "15px" }}>
                            <AddChildMessage
                                onAddActivity={onAddActivity}
                                parentActivityId={activityData.activity.id}
                            />
                            <ChildActivitiesForHome
                                data={activityData.children}
                                parentActivityId={activityData.activity.id}
                                onRemoveActivity={handleRemoveChildActivity}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        )
    );
};

export default Activity;
