import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    data: number | null;
    loading: boolean;
};

const useRegistrationFillRate = (type: string, partnerId: number, from: string, to: string) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            let data = null;
            if (type === "SA") {
                data = await Provider.Partner.getSelfAssessmentFillRate(partnerId, from, to, {
                    customError: {
                        message: "Could not retrieve data for self assessment fill rate",
                    },
                }).catch(err => {
                    setState({ loading: false, data: null });
                    return Promise.reject(err);
                });
            }
            if (type === "Compliance") {
                data = await Provider.Partner.getComplianceFillRate(partnerId, from, to, {
                    customError: {
                        message: "Could not retrieve data for compliance fill rate",
                    },
                }).catch(err => {
                    setState({ loading: false, data: null });
                    return Promise.reject(err);
                });
            }

            setState({ loading: false, data: data.score });
        })();
    }, [type, partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useRegistrationFillRate };
