import { IRequestMultipleInfoObject } from "models";
import { useState } from "react";
import { Provider } from "services";

const useAdminSendSelfAssessment = () => {
    const [loading, setLoading] = useState(false);
    const send = async (payload: IRequestMultipleInfoObject) => {
        if (payload.hasAdditionalText) {
            payload.additionalText =
                "<b>Message from sender:</b> " +
                payload.additionalText +
                " <em>End of message</em>";
        }
        setLoading(true);
        let data = await Provider.Compliance.sendAdminSelfAssessment(payload).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };

    return {
        loading,
        send,
    };
};

export { useAdminSendSelfAssessment };
