import React, { createRef, forwardRef, useEffect, useState } from "react";
import { Spin, Tree, Checkbox } from "antd";
import classnames from "classnames";
import classes from "./selectFlatTreeData.module.scss";
import { SelectFlatTreeDataSearch } from "./selectFlatTreeDataSearch";

type PropsType = {
    selectIdField?: string;
    selectOptionField: string | Function;
    selectOnlyChildren?: boolean;
    removeCheckedKeys?: any;
    removeSelectedKeys?: any;
    removeSelectAll?: any;
    removeSearch?: any;
    bordered?: boolean;
    title?: React.ReactNode | string;
    selectAll?: boolean;
    multiple?: boolean;
    useData: Function;
    onCheck?: Function;
    onSelect?: Function;
    onMultipleCheck?: Function;
    [key: string]: any;
};
//forwardRef is used for the component to be able to be used as a FormItem child direclty
const SelectFlatTreeData = forwardRef(
    (
        {
            selectIdField = "id",
            selectOptionField,
            selectOnlyChildren = false,
            removeCheckedKeys,
            removeSelectedKeys,
            removeSelectAll,
            removeSearch,
            bordered = true,
            selectAll = false,
            multiple = false,
            title,
            useData,
            onCheck,
            onSelect,
            onMultipleCheck,
            ...rest
        }: PropsType,
        ref
    ) => {
        const { data, loading } = useData();
        const [filteredData, setFilteredData] = useState<any>([]);
        const [checkedKeys, setCheckedKeys] = useState<any>([]);
        const [selectedKeys, setSelectedKeys] = useState<any>([]);
        const [expandedKeys, setExpandedKeys] = useState<any>([]);
        const [selectAllChecked, setSelectAllChecked] = useState<any>(false);

        const refsArray = data.map((x: any) => ({ id: x[selectIdField], ref: createRef() }));

        useEffect(() => {
            setFilteredData(data);
        }, [data]);

        useEffect(() => {
            if (removeCheckedKeys) {
                setCheckedKeys([]);
            }
        }, [removeCheckedKeys]);

        useEffect(() => {
            if (removeSelectedKeys) {
                setSelectedKeys([]);
            }
        }, [removeSelectedKeys]);

        useEffect(() => {
            if (removeSelectAll) {
                setSelectAllChecked(false);
            }
        }, [removeSelectAll]);

        const expandParent = (elementId: string) => {
            const elementRef = refsArray.some((x: any) => x.id === elementId)
                ? refsArray.filter((x: any) => x.id === elementId)[0].ref
                : null;
            if (elementRef) {
                const p: HTMLElement | null = elementRef.current.closest(".parentTreeNode");
                if (p) {
                    const switcherElement: NodeListOf<HTMLElement> = p.querySelectorAll(
                        ".ant-tree-switcher"
                    );
                    if (switcherElement) {
                        switcherElement[0].click();
                    }
                }
            }
        };

        const itemKey = (item: any) => {
            return selectOnlyChildren && item.children
                ? `group-${item[selectIdField]}`
                : item[selectIdField];
        };

        const childKey = (item: any, child: any) => {
            return selectOnlyChildren
                ? `group-${item[selectIdField]}-child-${child[selectIdField]}`
                : child[selectIdField];
        };

        const treeCount = (data: any) => {
            let treeLength = 0;
            data.forEach((x: any) => {
                if (x.children) {
                    treeLength = treeLength + x.children.length;
                } else {
                    treeLength++;
                }
            });
            return treeLength;
        };

        const handleCheck = (_: any, info: any) => {
            if (selectOnlyChildren && info.node.children) {
                const childKeys = info.node.children.map((c: any) => c.key);
                const childIds = info.node.children.map((c: any) => c[selectIdField]);
                if (info.checked === true || info.selected === true) {
                    setCheckedKeys([...checkedKeys, info.node.key, ...childKeys]);
                } else {
                    setCheckedKeys(
                        checkedKeys.filter(
                            (x: any) => !childKeys.includes(x) && x !== info.node.key
                        )
                    );
                }
                info.node.id = childIds;
            } else {
                if (info.checked === true || info.selected === true) {
                    setCheckedKeys([...checkedKeys, info.node.key]);
                } else {
                    setCheckedKeys(checkedKeys.filter((x: any) => x !== info.node.key));
                }
            }
        };
        const handleSelect = (_: any, info: any) => {
            if (multiple) {
                if (selectOnlyChildren && info.node.children) {
                    const childKeys = info.node.children.map((c: any) => c.key);
                    const childIds = info.node.children.map((c: any) => c[selectIdField]);
                    if (info.selected === true) {
                        setSelectedKeys([...selectedKeys, info.node.key, ...childKeys]);
                    } else {
                        setSelectedKeys(
                            selectedKeys.filter(
                                (x: any) => !childKeys.includes(x) && x !== info.node.key
                            )
                        );
                    }
                    info.node.id = childIds;
                } else {
                    if (info.selected === true) {
                        setSelectedKeys([...selectedKeys, info.node.key]);
                    } else {
                        setSelectedKeys(selectedKeys.filter((x: any) => x !== info.node.key));
                    }
                }
            } else {
                if (info.selected === true) {
                    setSelectedKeys([info.node.key]);
                }
            }
        };

        const handleSearch = (value: string) => {
            let newExpandedKeys: any[] = [];
            let newFilteredData: any[] = [];

            data.forEach((item: any) => {
                const parentName =
                    typeof selectOptionField === "function"
                        ? selectOptionField(item).text
                        : item[selectOptionField];
                if (!item.children && parentName.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    newFilteredData = [...newFilteredData, item];
                    newExpandedKeys = [...newExpandedKeys, itemKey(item)];
                } else if (item.children) {
                    let itemFilteredChildren: any[] = [];
                    item.children.forEach((child: any) => {
                        const childName =
                            typeof selectOptionField === "function"
                                ? selectOptionField(child).text
                                : child[selectOptionField];
                        if (childName.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                            newExpandedKeys = [...newExpandedKeys, itemKey(item)];
                            itemFilteredChildren = [...itemFilteredChildren, child];
                        }
                    });
                    if (
                        itemFilteredChildren.length > 0 ||
                        parentName.toLowerCase().indexOf(value.toLowerCase()) > -1
                    ) {
                        newFilteredData = [
                            ...newFilteredData,
                            { ...item, children: itemFilteredChildren },
                        ];
                    }
                }
            });
            setFilteredData(newFilteredData);
            setExpandedKeys(value.trim() === "" ? [] : newExpandedKeys);
        };

        const treeData = filteredData.map((item: any) => ({
            title:
                selectOnlyChildren && item.children ? (
                    <span
                        ref={
                            refsArray.some((x: any) => x.id === item[selectIdField])
                                ? refsArray.filter((x: any) => x.id === item[selectIdField])[0].ref
                                : null
                        }
                        onClick={() => {
                            expandParent(item[selectIdField]);
                        }}>
                        {typeof selectOptionField === "function"
                            ? selectOptionField(item).node
                            : item[selectOptionField]}
                    </span>
                ) : typeof selectOptionField === "function" ? (
                    selectOptionField(item).node
                ) : (
                    item[selectOptionField]
                ),
            disableCheckbox:
                typeof selectOptionField === "function" &&
                selectOptionField(item).disableCheckbox !== undefined
                    ? selectOptionField(item).disableCheckbox
                    : false,
            selectable:
                selectOnlyChildren && item.children
                    ? false
                    : typeof selectOptionField === "function" &&
                      selectOptionField(item).selectable !== undefined
                    ? selectOptionField(item).selectable
                    : true,
            disabled:
                typeof selectOptionField === "function" &&
                selectOptionField(item).disabled !== undefined
                    ? selectOptionField(item).disabled
                    : false,
            key: itemKey(item),
            id: item[selectIdField],
            className: classnames(
                typeof selectOptionField === "function"
                    ? selectOptionField(item).text.replace(/\s/g, "")
                    : item[selectOptionField].replace(/\s/g, ""),
                !item.children ? "nonParentTreeNode" : "parentTreeNode"
            ),
            ...rest,
            children: item.children
                ? item.children.map((child: any) => ({
                      title:
                          typeof selectOptionField === "function"
                              ? selectOptionField(child).node
                              : child[selectOptionField],

                      selectable:
                          typeof selectOptionField === "function" &&
                          selectOptionField(child).selectable !== undefined
                              ? selectOptionField(child).selectable
                              : true,
                      disableCheckbox:
                          typeof selectOptionField === "function" &&
                          selectOptionField(child).disableCheckbox !== undefined
                              ? selectOptionField(child).disableCheckbox
                              : false,
                      disabled:
                          typeof selectOptionField === "function" &&
                          selectOptionField(child).disabled !== undefined
                              ? selectOptionField(child).disabled
                              : false,
                      className:
                          typeof selectOptionField === "function"
                              ? selectOptionField(child).text.replace(/\s/g, "")
                              : child[selectOptionField].replace(/\s/g, ""),
                      key: childKey(item, child),
                      id: child[selectIdField],
                  }))
                : null,
        }));

        return (
            <div className={classnames(classes.treeContainer, bordered ? classes.bordered : "")}>
                {(title || selectAll) && (
                    <div className={classes.titleWrapper}>
                        {selectAll && (
                            <div className={classes.selectAll}>
                                <Checkbox
                                    onChange={(e: any) => {
                                        if (e.target.checked) {
                                            setSelectAllChecked(true);
                                            let allKeys: any[] = [];
                                            let allIds: any[] = [];
                                            filteredData.forEach((item: any) => {
                                                const isDisabled =
                                                    typeof selectOptionField === "function" &&
                                                    selectOptionField(item).disabled !== undefined
                                                        ? selectOptionField(item).disabled
                                                        : false;
                                                if (!isDisabled) {
                                                    allKeys = [...allKeys, itemKey(item)];
                                                    //do not include the id of potentially selected contactgroups as this will not be handled well in the backend
                                                    if (
                                                        item.children === null ||
                                                        item.children === undefined
                                                    ) {
                                                        allIds = [...allIds, item[selectIdField]];
                                                    }
                                                }
                                                if (item.children) {
                                                    item.children.forEach((child: any) => {
                                                        const isDisabled =
                                                            typeof selectOptionField ===
                                                                "function" &&
                                                            selectOptionField(
                                                                child,
                                                                childKey(item, child)
                                                            ).disabled !== undefined
                                                                ? selectOptionField(
                                                                      child,
                                                                      childKey(item, child)
                                                                  ).disabled
                                                                : false;
                                                        const disableCheckbox =
                                                            typeof selectOptionField ===
                                                                "function" &&
                                                            selectOptionField(
                                                                child,
                                                                childKey(item, child)
                                                            ).disableCheckbox !== undefined
                                                                ? selectOptionField(
                                                                      child,
                                                                      childKey(item, child)
                                                                  ).disableCheckbox
                                                                : false;
                                                        if (!isDisabled && !disableCheckbox) {
                                                            allKeys = [
                                                                ...allKeys,
                                                                childKey(item, child),
                                                            ];
                                                            allIds = [
                                                                ...allIds,
                                                                child[selectIdField],
                                                            ];
                                                        }
                                                    });
                                                }
                                            });

                                            setCheckedKeys(allKeys);
                                            onMultipleCheck && onMultipleCheck(allIds);
                                        } else {
                                            setSelectAllChecked(false);
                                            setCheckedKeys([]);
                                            onMultipleCheck && onMultipleCheck([]);
                                        }
                                    }}
                                    checked={selectAllChecked}>
                                    {treeCount(filteredData)}
                                </Checkbox>
                            </div>
                        )}
                        {title && <div className={classes.title}>{title}</div>}
                    </div>
                )}
                <SelectFlatTreeDataSearch onSearch={handleSearch} removeSearch={removeSearch} />
                {loading ? (
                    <div className={classes.loadingContainer}>
                        <Spin spinning={loading}></Spin>
                    </div>
                ) : (
                    <Tree
                        treeData={treeData}
                        checkedKeys={checkedKeys}
                        selectedKeys={selectedKeys}
                        expandedKeys={expandedKeys}
                        onCheck={(checked: any, info: any) => {
                            handleCheck(checked, info);
                            onCheck && onCheck(checked, info);
                        }}
                        onSelect={(selected: any, info: any) => {
                            if (rest.checkable) {
                                handleCheck(selected, info);
                                onCheck && onCheck(selected, info);
                            } else {
                                handleSelect(selected, info);
                            }
                            onSelect && onSelect(selected, info);
                        }}
                        onExpand={(expandedKeys: any, info: any) => {
                            if (info.expanded === true) {
                                setExpandedKeys([...expandedKeys, info.node.key]);
                            } else {
                                setExpandedKeys(
                                    expandedKeys.filter((x: any) => x !== info.node.key)
                                );
                            }
                        }}
                        {...rest}
                    />
                )}
            </div>
        );
    }
);

export { SelectFlatTreeData };
