import { useEffect, useState } from "react";
import { default as serviceProvider } from "../../services/api";
import { IPartnerGroup } from "../../models/suppliers/IPartnerGroup";

export type UseGroupsType = {
    data: IPartnerGroup[];
    loading: boolean;
};

const useGroups = (newgroup = "", reload: any = null) => {
    const [groups, setGroups] = useState<UseGroupsType>({
        data: [],
        loading: false,
    });
    useEffect(() => {
        (async () => {
            setGroups({
                ...groups,
                loading: true,
            });
            let response = await serviceProvider.Partner.getGroups(true);
            let data: IPartnerGroup[] = [];
            response.forEach((element: any) => {
                data.push({ id: element.id, name: element.name });
            });
            setGroups({
                data,
                loading: false,
            });
        })();
    }, [newgroup, reload]);

    return {
        loading: groups.loading,
        data: groups.data,
    };
};

export { useGroups };
