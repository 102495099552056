import React, { createContext, useState, ReactNode } from "react";

export const FiltersContext = createContext<{
    additionalFilters: any[];
    setAdditionalFilters: React.Dispatch<React.SetStateAction<any[]>>;
} | null>(null);

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
    const [additionalFilters, setAdditionalFilters] = useState<any[]>([]);

    return (
        <FiltersContext.Provider value={{ additionalFilters, setAdditionalFilters }}>
            {children}
        </FiltersContext.Provider>
    );
};
