import { useEffect, useState } from "react";
import { Provider } from "services";
import { IRisk } from "models";
import isObjEqual from "utilities/helpers/isObjEqual";

type StateType = {
    loading: boolean;
    data: IRisk[];
    totalRows: number;
};

const useRisks = (
    supplierId: number,
    loadOnMount: boolean,
    pageSize: number = 1,
    pageNumber: number = 15,
    filters: any = null,
    sorts: any = null,
    externalLoading: boolean = false,
    reloadData: any = null,
    range: any = null
) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
        totalRows: 0,
    });
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateStateLoading, setUpdateStateLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!externalLoading && loadOnMount) {
                setState({ ...state, loading: true });
                let data = await Provider.Risk.getRisks(
                    supplierId,
                    {
                        pageSize,
                        pageNumber,
                        filters: [],
                        sorts: [],
                    },
                    {
                        customError: {
                            message: "Could not retrieve risks",
                        },
                    }
                ).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ loading: false, data: data.data, totalRows: data.totalNumberOfRecords });
            }
        })();
    }, [
        loadOnMount,
        supplierId,
        pageNumber,
        pageSize,
        filters,
        sorts,
        reloadData,
        externalLoading,
        range,
    ]);

    const create = async (risk: any) => {
        setCreateLoading(true);
        await Provider.Risk.createRisk(risk, {
            customError: {
                message: "Could not create risk",
            },
        }).catch(err => {
            setCreateLoading(false);
            return Promise.reject(err);
        });
        setCreateLoading(false);
    };

    const update = async ({ newItem, item }: any) => {
        if (isObjEqual(newItem, item, ["title", "type"])) return false;
        setUpdateLoading(true);
        await Provider.Risk.updateRisk(newItem, {
            customError: {
                message: "Could not update risk",
            },
        }).catch(err => {
            setUpdateLoading(false);
            return Promise.reject(err);
        });
        setUpdateLoading(false);
    };

    const remove = async (ids: number[]) => {
        setDeleteLoading(true);
        await Provider.Risk.deleteRisk(ids, {
            customError: {
                message: "Could not delete risks",
            },
        }).catch(err => {
            setDeleteLoading(false);
            return Promise.reject(err);
        });
        setDeleteLoading(false);
    };

    const updateState = async ({ newItem, item }: any) => {
        if (isObjEqual(newItem, item, ["state"])) return false;
        setUpdateStateLoading(true);
        await Provider.Risk.updateRiskState(newItem.id, newItem.state, {
            customError: {
                message: "Could not update risk state",
            },
        }).catch(err => {
            setUpdateStateLoading(false);
            return Promise.reject(err);
        });
        setUpdateStateLoading(false);
    };

    return {
        loading: state.loading,
        data: state.data,
        totalRows: state.totalRows,
        create,
        update,
        remove,
        updateState,
        createLoading,
        updateLoading,
        deleteLoading,
        updateStateLoading,
    };
};

export { useRisks };
