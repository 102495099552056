import { IAwardSetting } from "models";
import { useState } from "react";
import Provider from "services/api";

const useAwardSettingsUpsert = () => {
    const [loading, setLoading] = useState(false);

    const upsert = async (payload: IAwardSetting[]) => {
        setLoading(true);
        await Provider.Scorecard.upsertAwardSettings(payload).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading,
        upsert,
    };
};

export { useAwardSettingsUpsert };
