import { useEffect, useState } from "react";
import { Provider } from "services";

const useGlobalSettings = (reload: any) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: {},
    });
    useEffect(() => {
        setState({
            ...state,
            loading: true,
        });
        (async () => {
            const data = await Provider.Company.getGlobalSettings({
                customError: {
                    message: "Could not retrieve global settings on the company",
                },
            }).catch(err => {
                setState({
                    ...state,
                    loading: false,
                });
                return Promise.reject(err);
            });
            setState({
                loading: false,
                data,
            });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useGlobalSettings };
