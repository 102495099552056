import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
    AreaChartOutlined,
    BankOutlined,
    CheckOutlined,
    ContactsOutlined,
    FileDoneOutlined,
    IssuesCloseOutlined,
    PushpinOutlined,
    ScheduleOutlined,
    StarOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useCompanyType, useNcrFeature, useScorecardFeature } from "controller";
const { SubMenu } = Menu;
interface IProps {
    onClick?: (event: any) => void;
    selectedKeys: string[];
    activeKeys?: string;
}
const AdminMenu = ({ onClick, selectedKeys, activeKeys }: IProps) => {
    const { isDefaultCompany } = useCompanyType();
    const { hasScorecardFeature } = useScorecardFeature();
    const { hasNcrFeature } = useNcrFeature();
    const { t } = useTranslation();
    return (
        <Menu
            mode="vertical"
            id="adminSettingsMenu"
            onSelect={onClick}
            selectedKeys={selectedKeys}
            activeKey={activeKeys}>
            <SubMenu
                key="general"
                title={
                    <span>
                        <BankOutlined />
                        <span>{t("admin_General")}</span>
                    </span>
                }>
                <Menu.Item key="CompanyInformation">
                    <Link to="/Admin/CompanyInformation">{t("admin_General_CompanyInfo")}</Link>
                </Menu.Item>
                <Menu.Item key="CompanySettings">
                    <Link to="/Admin/CompanySettings">{t("admin_General_CompanySettings")}</Link>
                </Menu.Item>
                <Menu.Item key="CustomDropdowns">
                    <Link to="/Admin/CustomDropdowns">{t("admin_General_CustomDropdown")}</Link>
                </Menu.Item>
                <Menu.Item key="SingleSignOn">
                    <Link to="/Admin/SingleSignOn">{t("admin_General_Sso")}</Link>
                </Menu.Item>
                <Menu.Item key="reportSettings">
                    <Link to="/Admin/ReportSettings">{t("admin_General_ReportSettings")}</Link>
                </Menu.Item>
                <Menu.Item key="shortcutSettings">
                    <Link to="/Admin/ShortcutSettings">{t("admin_General_ShortcutSettings")}</Link>
                </Menu.Item>
                {isDefaultCompany && (
                    <Menu.Item key="supplierAccessSettings">
                        <Link to="/Admin/SupplierAccessSettings">
                            {t("admin_SupplierAccessSettings_SupplierAccessSettings")}
                        </Link>
                    </Menu.Item>
                )}
            </SubMenu>
            <SubMenu
                key="Users"
                title={
                    <span>
                        <UserOutlined />
                        <span>{t("admin_Users")}</span>
                    </span>
                }>
                <Menu.Item key="UserManagement">
                    <Link to="/Admin/UserManagement">{t("admin_Users_UserManagement")}</Link>
                </Menu.Item>
                <Menu.Item key="UserAccessSettings">
                    <Link to="/Admin/UserAccessSettings">
                        {t("admin_Users_UserAccessSettings")}
                    </Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="contacts"
                title={
                    <span>
                        <ContactsOutlined />
                        <span>{t("admin_Contacts")}</span>
                    </span>
                }>
                <Menu.Item key="UploadContacts">
                    <Link to="/Admin/UploadContacts">{t("admin_Contacts_UploadContacts")}</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="External Partners"
                title={
                    <span>
                        <UserOutlined />
                        <span>{t("admin_Supplier")}</span>
                    </span>
                }>
                <Menu.Item key="UploadPartnerFile">
                    <Link to="/Admin/UploadPartnerFile">{t("admin_SupplierPartnerFileLabel")}</Link>
                </Menu.Item>
                {isDefaultCompany && (
                    <Menu.Item key="Invite">
                        <Link to="/Admin/Invite">{t("admin_Supplier_Invite")}</Link>
                    </Menu.Item>
                )}
                {isDefaultCompany && (
                    <Menu.Item key="InvitationStatus">
                        <Link to="/Admin/InvitationStatus">
                            {t("admin_Supplier_InvitationStatus")}
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key="StandingSettings">
                    <Link to="/Admin/StandingSettings">{t("admin_Supplier_StatusCategories")}</Link>
                </Menu.Item>
                <Menu.Item key="StatusEventSettings">
                    <Link to="/Admin/StatusEventSettings">
                        {t("admin_Supplier_StatusEventSettings")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="SupplierTags">
                    <Link to="/Admin/SupplierTags">{t("admin_Supplier_ManageTags")}</Link>
                </Menu.Item>
                <Menu.Item key="FieldSettings">
                    <Link to="/Admin/FieldSettings">{t("admin_General_FieldSettings")}</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="supplierProfile"
                title={
                    <span>
                        <AreaChartOutlined />
                        <span>{t("admin_SupplierProfile")}</span>
                    </span>
                }>
                <Menu.Item key="supplierProfileSettings">
                    <Link to="/Admin/supplierProfileSettings">
                        {t("admin_SupplierProfile_Settings")}
                    </Link>
                </Menu.Item>
                {hasScorecardFeature && (
                    <Menu.Item key="scorecardSettings">
                        <Link to="/Admin/ScorecardSettings">
                            {t("admin_SupplierProfile_ScorecardSetting")}
                        </Link>
                    </Menu.Item>
                )}
                <SubMenu
                    key="governanceSettings"
                    title={<span>{t("admin_SupplierProfile_GovernanceSettings")}</span>}>
                    <Menu.Item key="governanceTaskSettings">
                        <Link to="/Admin/GovernanceTasks">
                            {t("admin_SupplierProfile_GovernanceTaskSettings")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="meetingForumsSettings">
                        <Link to="/Admin/MeetingForums">
                            {t("meetingForum_MeetingForum", { count: 2 })}
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="segmentationSettings"
                    title={
                        <span>
                            <span>{t("admin_SupplierProfile_SegmentationSettings")}</span>
                        </span>
                    }>
                    <Menu.Item key="SegmentationChartSettings">
                        <Link to="/Admin/SegmentationChartSettings">
                            {t("admin_SupplierProfile_SegmentationChartSettings")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="SegmentationScoreSettings">
                        <Link to="/Admin/SegmentationScoreSettings">
                            {t("admin_SupplierProfile_SegmentationScoreSettings")}
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <Menu.Item key="PerformanceOrderSettings">
                    <Link to="/Admin/PerformanceSettings">
                        {t("admin_SupplierProfile_PerformanceSettings")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="riskSettings">
                    <Link to="/Admin/RiskSettings">{t("admin_SupplierProfile_RiskSettings")}</Link>
                </Menu.Item>
                <Menu.Item key="summarySettings">
                    <Link to="/Admin/SummarySettings">
                        {t("admin_SupplierProfile_SummarySettings")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="sustainabilitySettings">
                    <Link to="/Admin/SustainabilitySettings">
                        {t("admin_SupplierProfile_SustainabilitySettings")}
                    </Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="KeyPerformanceIndicators"
                title={
                    <span>
                        <FileDoneOutlined />
                        <span>{t("admin_Kpi")}</span>
                    </span>
                }>
                <Menu.Item key="KPIDefinition">
                    <Link to="/Admin/KpiDefinition">{t("admin_Kpi_Definition")}</Link>
                </Menu.Item>
                <Menu.Item key="KPICustomColumns">
                    <Link to="/Admin/KPICustomColumns">{t("admin_Kpi_CustomColumns")}</Link>
                </Menu.Item>

                <Menu.Item key="KPIUpload">
                    <Link to="/Admin/KpiUpload">{t("admin_Kpi_Upload")}</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="reviews"
                title={
                    <span>
                        <StarOutlined />
                        <span>{t("admin_Review")}</span>
                    </span>
                }>
                <Menu.Item key="ReviewCriteriaGroup">
                    <Link to="/Admin/ReviewsCriteriaGroup">{t("admin_ReviewCriteria_Group")}</Link>
                </Menu.Item>
                <Menu.Item key="ReviewsCriteria">
                    <Link to="/Admin/ReviewsCriteria">{t("admin_Review_Criteria")}</Link>
                </Menu.Item>
                <Menu.Item key="ReviewDefaults">
                    <Link to="/Admin/ReviewDefaults">{t("admin_Review_Defaults")}</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="surveys"
                title={
                    <span>
                        <ScheduleOutlined />
                        <span>{t("admin_Surveys")}</span>
                    </span>
                }>
                <Menu.Item key="SurveyTemplateGroup">
                    <Link to="/Admin/SurveyTemplateGroup">{t("admin_SurveyTemplate_Group")}</Link>
                </Menu.Item>
                <Menu.Item key="SurveyTemplateOverview">
                    <Link to="/Admin/SurveyTemplateOverview">
                        {t("admin_SurveyTemplate_Overview")}
                    </Link>
                </Menu.Item>
            </SubMenu>
            {hasNcrFeature && (
                <SubMenu
                    key="nonConformanceReports"
                    title={
                        <span>
                            <IssuesCloseOutlined />
                            <span>{t("admin_Ncr")}</span>
                        </span>
                    }>
                    <Menu.Item key="NCRFields">
                        <Link to="/Admin/NCRFields">{t("admin_Ncr_Fields")}</Link>
                    </Menu.Item>
                    <Menu.Item key="NcrReasons">
                        <Link to="/Admin/NcrReasons">{t("admin_Ncr_Reasons")}</Link>
                    </Menu.Item>
                </SubMenu>
            )}
            <SubMenu
                key="compliance"
                title={
                    <span>
                        <CheckOutlined />
                        <span>{t("admin_Compliance")}</span>
                    </span>
                }>
                <Menu.Item key="TemplateGroup">
                    <Link to="/Admin/TemplateGroup">{t("admin_Compliance_TemplateGroup")}</Link>
                </Menu.Item>
                <Menu.Item key="TemplateGroupMatrix">
                    <Link to="/Admin/TemplateGroupMatrix">
                        {t("admin_Compliance_TemplateGroupMatrix")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="TemplateOverview">
                    <Link to="/Admin/TemplateOverview">
                        {t("admin_Compliance_TemplateOverview")}
                    </Link>
                </Menu.Item>
                <Menu.Item key="RequestInformation">
                    <Link to="/Admin/RequestInformation">{t("admin_Compliance_RequestInfo")}</Link>
                </Menu.Item>
                <Menu.Item key="Gatekeepers">
                    <Link to="/Admin/Gatekeepers">{t("admin_Compliance_Gatekeepers")}</Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
                key="Actions"
                title={
                    <span>
                        <PushpinOutlined />
                        <span>{t("admin_Actions")}</span>
                    </span>
                }>
                <Menu.Item key="DevelopmentPlanTemplates">
                    <Link to="/Admin/developmentPlanTemplates">
                        {t("admin_DevelopmentPlanTemplates")}
                    </Link>
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
};

export { AdminMenu };
