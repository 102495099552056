import { IActivityChild } from "models";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: any;
};

const usePartnerComment = (partnerId: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            const data = await Provider.Partner.getPartnerComment(partnerId, {
                customError: {
                    message: "Could not retrieve comment ",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ ...state, loading: false, data });
        })();
    }, [partnerId]);

    const addActivity = (parentActivityId: number, newActivity: IActivityChild) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    messages: [newActivity, ...state.data.messages],
                },
            });
        }
    };

    const removeActivity = (parentActivityId: number, activityId: number) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    messages: state.data.messages.map((activity: IActivityChild) => {
                        if (activity.id !== activityId) {
                            return activity;
                        }
                        activity.isDeleted = true;
                        activity.canDelete = false;
                        return activity;
                    }),
                },
            });
        }
    };

    return {
        loading: state.loading,
        data: state.data,
        addActivity,
        removeActivity,
    };
};

export { usePartnerComment };
