import React, { useEffect, useState } from "react";
import { Modal, Tag, Alert, notification, Form, Button } from "antd";
import IMasterDataPartner from "../../../../models/suppliers/IMasterDataPartner";
import { useTranslation } from "react-i18next";
import { Provider } from "services";
import { useCompanyType } from "controller";

interface ModalProps {
    visible: boolean;
    setModalVisibility(value: boolean): void;
    selectedRows: IMasterDataPartner[];
    selectedRowKeys: string[];
    updateSelectedRows(selectedTag: any, selectedTagKey: any): void;
    clearSelectedRows(): void;
    updateTable(): void;
}

export const ContentModal = (props: ModalProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
            lg: { span: 16 },
        },
    };
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    function onTagClose(tag: any, key: any) {
        props.updateSelectedRows(tag, key);
    }

    function renderTags(closable: boolean) {
        return props.selectedRows.map((row: any) => {
            return (
                <Tag
                    color="blue"
                    key={row.id.value}
                    closable={closable}
                    onClose={() => onTagClose(row.company.value, row.id)}>
                    {row.company.value}
                </Tag>
            );
        });
    }

    function onCancel() {
        form.resetFields();
        props.setModalVisibility(false);
    }

    async function onSubmit() {
        await form.validateFields();
        let parentIds: any[] = [];
        for (let element of props.selectedRows) {
            let parentId = element.parentId ? element.parentId : "";
            if (parentId !== "" && !parentIds.includes(parentId)) {
                parentIds.push(parentId);

                const siblings = element.siblings ? element.siblings : [];
                let oldChildrenIds: string[] = siblings
                    .map(x => x.id.value.toString())
                    .concat()
                    .sort();
                let selectedRowIds: string[] = props.selectedRows
                    .map(x => x.id.value.toString())
                    .concat()
                    .sort();
                let newChildrenIds: string[] = oldChildrenIds.filter(
                    x => !selectedRowIds.includes(x)
                );
                setConfirmLoading(true);
                try {
                    await Provider.Partner.unMerge(
                        parentId,
                        newChildrenIds.map(x => parseInt(x))
                    );
                    notification.success({
                        message: t("success"),
                        description: t("supplier_UnMergeMessage", {
                            parent: element.parentName,
                            children: props.selectedRows
                                .filter(x => oldChildrenIds.includes(x.id.value.toString()))
                                .map(x => x.company.value)
                                .join(", "),
                        }),
                    });
                } catch {
                    notification.error({
                        message: t("systemMessage"),
                        description: t("generic_ErrorMessage"),
                        duration: 0,
                    });
                }
            }
        }
        form.resetFields();
        props.setModalVisibility(false);
        props.clearSelectedRows();
        setConfirmLoading(false);
        await props.updateTable();
    }

    return (
        <div>
            <Modal
                wrapProps={{ "data-test": "unmerge_supplier_modal" }}
                title={t("supplier_UnmergeTitle", {
                    companyType: isDefaultCompany
                        ? t("company_Type_Supplier")
                        : t("company_Type_Customer"),
                })}
                open={props.visible}
                onOk={() => onSubmit()}
                onCancel={() => onCancel()}
                width={800}
                footer={[
                    <Button
                        loading={confirmLoading}
                        type="primary"
                        onClick={async () => await onSubmit()}>
                        {t("generic_Confirm")}
                    </Button>,
                ]}>
                <Form form={form} {...formItemLayout} layout={"horizontal"} colon={false}>
                    <Form.Item
                        label={
                            t("supplier_SelectedSuppliers", {
                                companyType: isDefaultCompany
                                    ? t("company_Type_Supplier")
                                    : t("company_Type_Customer"),
                            }) + " : "
                        }>
                        {props.selectedRows.length === 1 && (
                            <div className={"tagArea"}>{renderTags(false)}</div>
                        )}
                        {props.selectedRows.length > 1 && (
                            <div className={"tagArea"}>{renderTags(true)}</div>
                        )}
                    </Form.Item>
                    <Alert
                        message={t("supplier_UnmergeWarningText", {
                            companyType: isDefaultCompany
                                ? t("company_Type_Supplier").toLowerCase()
                                : t("company_Type_Customer").toLowerCase(),
                        })}
                        type="warning"
                    />
                </Form>
            </Modal>
        </div>
    );
};
const ModalUnMerge = ContentModal;

export default ModalUnMerge;
