import React, { FunctionComponent, Fragment } from "react";
import { Overview } from "components/contentcomponents/reviews/survey/overview";

export const SurveysOverview: FunctionComponent = () => {
    return (
        <Fragment>
            <Overview />
        </Fragment>
    );
};
export default SurveysOverview;
