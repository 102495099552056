import React, { FunctionComponent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IProjectDetail } from "models";
import classes from "./styles/shared.module.scss";
import { PartnerLink } from "../partnerLink";

type PropsType = {
    data: IProjectDetail;
    isHomePage: boolean;
};

const Project: FunctionComponent<PropsType> = ({ data, isHomePage = false }) => {
    const { t } = useTranslation();
    const renderDoneOnPartner = (data: IProjectDetail) => {
        return (
            <Fragment>
                {data.creator} {t("from")}{" "}
                <PartnerLink partnerId={data.partnerId} partnerName={data.fromOrOnPartner} />{" "}
                {t("activity_NewProject", { id: data.id })}
                {data.withPartnerId !== 0 && data.withPartner !== t("yourCompany") ? (
                    <PartnerLink partnerId={data.withPartnerId} partnerName={data.withPartner} />
                ) : (
                    data.withPartner
                )}
            </Fragment>
        );
    };

    const renderDoneByPartner = (data: IProjectDetail) => {
        return (
            <Fragment>
                {data.creator} {t("activity_NewProject", { id: data.id })}{" "}
                {data.withPartnerId !== 0 ? (
                    <PartnerLink partnerId={data.withPartnerId} partnerName={data.withPartner} />
                ) : (
                    data.withPartner
                )}
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div className={classes.activityTitle}>
                {data.isOnPartner ? renderDoneByPartner(data) : renderDoneOnPartner(data)}
            </div>
        </Fragment>
    );
};

export default Project;
