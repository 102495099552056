import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: { upComingMeetings: any[]; pastMeetings: any[] };
};

const useMeetingMinutes = (reload: any, partnerId: any) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: { upComingMeetings: [], pastMeetings: [] },
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.Governance.getMeetingMinutes(partnerId, {
                customError: {
                    message: "Could not retrieve meeting minutes",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, [partnerId, reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useMeetingMinutes };
