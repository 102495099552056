import React, { FunctionComponent, useRef } from "react";
import { Button } from "antd";
import { Upload01 } from "../icons";

type PropTypes = {
    onSelect: Function;
    loading: boolean;
    icon?: any;
    multiple?: boolean;
    disabled?: boolean;
    accept?: string;
    [key: string]: any;
};

const UploadButton: FunctionComponent<PropTypes> = ({
    onSelect,
    loading = false,
    icon = <Upload01 />,
    multiple = true,
    disabled = false,
    accept = null,
    children,
    ...rest
}) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const onChange = (ev: any) => {
        onSelect(Array.from(ev.target.files));
    };
    return (
        <div style={{ position: "relative" }}>
            <input
                data-test="upload_input"
                style={{
                    opacity: "0",
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                }}
                type="file"
                name="files"
                {...(multiple ? { multiple: true } : {})}
                {...(accept ? { accept: accept } : {})}
                onChange={onChange}
                ref={inputEl}
                key={Date.now()}
            />
            <Button
                loading={loading}
                data-test="select_files_button"
                {...rest}
                icon={icon && icon}
                disabled={loading || disabled}
                onClick={() => inputEl.current !== null && inputEl.current.click()}>
                {children}
            </Button>
        </div>
    );
};

export { UploadButton };
