import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Spin, Button, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import "./styles/filterDropDown.scss";

type PropsType = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: any;
    clearFilters: any;
    useData: Function;
    dataFields: { value: string[]; text: string[] };
    dataIndex: string;
    radioChoice: boolean;
    includeKeyInText: boolean;
    includeChildren: boolean;
};

const FilterDropDown: FunctionComponent<PropsType> = ({
    useData,
    dataFields,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex,
    radioChoice = false,
    includeKeyInText = false,
    includeChildren = false,
}) => {
    const { t } = useTranslation();
    const { loading, data } = useData();
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("");
    const [activeParentKeys, setActiveParentKeys] = useState<any>([]);

    useEffect(() => {
        setFilteredData(data);
    }, [loading]);

    const handleSearch = (e: any) => {
        const value = e.target.value;
        setSearch(value);
        if (!includeChildren) {
            setFilteredData(
                data.filter((d: any) => {
                    const key = dataFields.value
                        .reduce((acc, column) => `${acc}${d[column]} `, "")
                        .trim();
                    let text = dataFields.text
                        .reduce((acc, column) => `${acc}${d[column]} `, "")
                        .trim();
                    if (includeKeyInText) {
                        text = `${text} [${key}]`;
                    }
                    return text.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1;
                })
            );
        } else {
            setFilteredData(
                data.filter((d: any) => {
                    const key = dataFields.value
                        .reduce((acc, column) => `${acc}${d[column]} `, "")
                        .trim();
                    let text = dataFields.text
                        .reduce((acc, column) => `${acc}${d[column]} `, "")
                        .trim();
                    if (includeKeyInText) {
                        text = `${text} [${key}]`;
                    }
                    return (
                        text.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1 ||
                        (d.children &&
                            d.children.some((child: any) => {
                                const childKey = dataFields.value
                                    .reduce((acc, column) => `${acc}${child[column]} `, "")
                                    .trim();
                                let childText = dataFields.text
                                    .reduce((acc, column) => `${acc}${child[column]} `, "")
                                    .trim();
                                if (includeKeyInText) {
                                    childText = `${childText} [${childKey}]`;
                                }
                                return (
                                    childText
                                        .toLocaleLowerCase()
                                        .indexOf(value.toLocaleLowerCase()) !== -1
                                );
                            }))
                    );
                })
            );
        }
    };

    const renderCheckboxFilterItem = (
        row: any,
        index: number,
        isParent: boolean = false,
        parentKey = ""
    ) => {
        const key = dataFields.value.reduce((acc, column) => `${acc}${row[column]} `, "").trim();
        let text = dataFields.text.reduce((acc, column) => `${acc}${row[column]} `, "").trim();
        if (includeKeyInText) {
            text = `${text} [${key}]`;
        }
        return (
            <Checkbox
                data-test={`filter_dropdown_value_${dataFields.text
                    .reduce((acc, column) => `${acc}${row[column]} `, "")
                    .split(" ")
                    .join("")
                    .trim()
                    .toLowerCase()
                    .replace(/\/|\(|\)/g, "")}`}
                key={index}
                value={key}
                onChange={e =>
                    setSelectedKeys(
                        e.target.checked
                            ? [...selectedKeys, e.target.value]
                            : selectedKeys.filter((x: any) => x !== e.target.value)
                    )
                }>
                <span className="checkboxLabel">
                    {includeChildren && isParent && activeParentKeys.includes(parentKey) && (
                        <CaretDownOutlined
                            className="parentFilterIcon"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const cloneKeys = activeParentKeys.slice();
                                cloneKeys.splice(cloneKeys.indexOf(parentKey), 1);
                                setActiveParentKeys(cloneKeys);
                            }}
                        />
                    )}

                    {includeChildren && isParent && !activeParentKeys.includes(parentKey) && (
                        <CaretRightOutlined
                            className="parentFilterIcon"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setActiveParentKeys([...activeParentKeys, parentKey]);
                            }}
                        />
                    )}
                    {text}
                </span>
                {/* {row.children &&
                    row.children.map((childRow: any, index: number) =>
                        renderCheckboxFilterItem(childRow, index, true)
                    )} */}
            </Checkbox>
        );
    };

    return (
        <Fragment>
            <div className="filterDropDownContainer">
                <div className="filterDropDownList">
                    <Spin spinning={loading}>
                        {data.length > 20 && (
                            <div className="filterSearchInput">
                                <Input
                                    data-test={`search_${dataIndex}_input`}
                                    placeholder={t("search") + "..."}
                                    value={search}
                                    onChange={handleSearch}
                                    allowClear={true}
                                />
                            </div>
                        )}
                        {radioChoice === false && (
                            <Checkbox.Group className="checkBoxGroup" value={selectedKeys}>
                                {filteredData.map((row: any, index: any) => {
                                    const key = dataFields.value
                                        .reduce((acc, column) => `${acc}${row[column]} `, "")
                                        .trim();
                                    if (row.children) {
                                        return (
                                            <div key={index} className="filterMenu">
                                                {renderCheckboxFilterItem(row, index, true, key)}
                                                {includeChildren && (
                                                    <div
                                                        className={`filterSubMenu ${
                                                            activeParentKeys.includes(key)
                                                                ? "active"
                                                                : ""
                                                        }`}>
                                                        {row.children.map(
                                                            (childRow: any, index: number) => {
                                                                return renderCheckboxFilterItem(
                                                                    childRow,
                                                                    index
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    } else return renderCheckboxFilterItem(row, index);
                                })}
                            </Checkbox.Group>
                        )}
                        {radioChoice === true && (
                            <Radio.Group
                                className="checkBoxGroup"
                                value={
                                    Array.isArray(selectedKeys) ? selectedKeys[0] : selectedKeys
                                }>
                                {filteredData.map((row: any, index: any) => {
                                    const key = dataFields.value
                                        .reduce((acc, column) => `${acc}${row[column]} `, "")
                                        .trim();
                                    let text = dataFields.text
                                        .reduce((acc, column) => `${acc}${row[column]} `, "")
                                        .trim();
                                    if (includeKeyInText) {
                                        text = `${text} [${key}]`;
                                    }
                                    return (
                                        <Radio
                                            data-test={`filter_dropdown_value_${dataFields.text
                                                .reduce(
                                                    (acc, column) => `${acc}${row[column]} `,
                                                    ""
                                                )
                                                .split(" ")
                                                .join("")
                                                .trim()
                                                .toLowerCase()
                                                .replace(/\/|\(|\)/g, "")}`}
                                            key={index}
                                            value={key}
                                            onChange={e => {
                                                setSelectedKeys(e.target.value);
                                            }}>
                                            <span className="checkboxLabel">{text}</span>
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        )}
                    </Spin>
                </div>
                <div className="filterDropDownAction">
                    <Button
                        data-test={`search_${dataIndex}_button`}
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}>
                        {t("grid_Filter")}
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{ width: 90 }}>
                        {t("grid_Reset")}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default FilterDropDown;
