import { useEffect, useState } from "react";
import { IContact } from "models";
import { Provider } from "services";

type StateType = {
    data: IContact[];
    loading: boolean;
};

export const useDevelopmentPlanContactsFilter = () => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: [],
    });

    useEffect(() => {
        (async () => {
            let data: any[] = await Provider.DevelopmentPlan.getContactsForFilter();
            data = data.map((contact: IContact) => ({
                ...contact,
                name: `${contact.firstName} ${contact.lastName}`,
            }));

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};
