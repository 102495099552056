import { IDevelopmentPlanModel } from "models";
import { useState } from "react";
import Provider from "services/api";

const useDevelopmentPlanCRUD = () => {
    const [loading, setLoading] = useState(false);

    const create = async (input: IDevelopmentPlanModel) => {
        setLoading(true);
        const id = await Provider.DevelopmentPlan.createDevelopmentPlan(input, {
            customError: {
                message: "Could not create new development plan",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    const remove = async (ids: any[]) => {
        setLoading(true);
        const result = await Provider.DevelopmentPlan.deleteDevelopmentPlanByIds(ids, {
            customError: {
                message: "Could not create new development plan",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    const edit = async (input: IDevelopmentPlanModel) => {
        setLoading(true);
        const result = await Provider.DevelopmentPlan.editDevelopmentPlan(input).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return result;
    };

    return {
        loading: loading,
        createDevelopmentPlan: create,
        deleteDevelopmentPlan: remove,
        editDevelopmentPlan: edit,
    };
};

export { useDevelopmentPlanCRUD };
