import { useEffect, useState } from "react";
import { Provider } from "services";
import { IComplianceElement } from "models";
import { useTranslation } from "react-i18next";

type StateType = {
    data: IComplianceElement[] | null;
    loading: boolean;
};

const useComplianceElements = (partnerId: number, from?: string, to?: string) => {
    const { t } = useTranslation();
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            const data = await Provider.Partner.getComplianceElements(partnerId, from, to, {
                customError: {
                    message: t("supplierProfile_PerformanceComplianceElementsError"),
                },
            }).catch(err => {
                setState({ loading: false, data: null });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [partnerId, from, to]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useComplianceElements };
