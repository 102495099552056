import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Modal, Select, Tag, notification, Alert, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useComplianceVisibilityUpdate, useComplianceRemove } from "controller";
import { ComplianceVisibility } from "models";
import {
    GridDeleteModal,
    GridButton,
    PlusCircle,
    Eye,
    Trash02,
} from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import { ChangeStatusModal } from "./changeStatusModal";
const Option = Select.Option;

type PropsType = {
    selectedRowKeys: number[];
    selectedRows: any[];
    onUpdateSelectedRows: Function;
    onUpdateSelectedRowKeys: Function;
    onReloadData: Function;
    supplierProfile?: boolean;
};

const OverviewHeader: FunctionComponent<PropsType> = ({
    selectedRowKeys,
    selectedRows,
    onUpdateSelectedRows,
    onUpdateSelectedRowKeys,
    onReloadData,
    supplierProfile = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [visibilityModal, setVisibilityModal] = useState(false);
    const [selectedVisibility, setSelectedVisibility] = useState<ComplianceVisibility | undefined>(
        undefined
    );
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
    });
    const [statusModalVisible, setStatusModalVisible] = useState(false);

    useEffect(() => {
        let visibility = undefined;
        for (let i = 0; i < selectedRows.length; i++) {
            if (i !== 0 && visibility !== selectedRows[i].visibility) {
                visibility = undefined;
                break;
            }
            visibility = selectedRows[i].visibility;
        }
        setSelectedVisibility(ComplianceVisibility[visibility] as any);
    }, [visibilityModal]);

    const { loading: visibilityUpdateLoading, update } = useComplianceVisibilityUpdate();

    const { loading: removeLoading, remove } = useComplianceRemove();

    const handleChangeVisibility = async () => {
        if (selectedVisibility) {
            await update(selectedRowKeys, selectedVisibility);
            notification.success({
                message: t("success"),
                description: t("compliance_ChangeVisibility_Success"),
            });
            setSelectedVisibility(undefined);
            setVisibilityModal(false);
            onUpdateSelectedRows(selectedRows.map(r => ({ ...r, visibility: selectedVisibility })));
            onReloadData();
        }
    };

    const handleDelete = async () => {
        await remove(selectedRowKeys);
        notification.success({
            message: t("success"),
            description: t(`compliance_DeleteSuccess`),
        });
        setDeleteModal({
            visible: false,
        });
        onUpdateSelectedRowKeys([]);
        onUpdateSelectedRows([]);
        onReloadData();
    };

    const buttonVisibility = selectedRowKeys.length < 1;
    const changeButtonVisibility =
        selectedRowKeys.length > 0 || selectedRows.some(s => s.canChangeVisibility === true);
    const statusButtonVisibility =
        selectedRowKeys.length > 0 || selectedRows.some(s => s.canChangeStatus === true);

    return (
        <Fragment>
            {buttonVisibility && (
                <GridButton
                    data-test="create_registration_button"
                    icon={<PlusCircle />}
                    type="default"
                    onClick={() =>
                        !supplierProfile
                            ? history.push("/Compliance/Create")
                            : history.push("/SupplierProfileCompliance/Create")
                    }>
                    {t("createRegistration")}
                </GridButton>
            )}

            {!buttonVisibility && changeButtonVisibility && statusButtonVisibility && (
                <>
                    <GridButton
                        data-test="change_visibility_button"
                        icon={<Eye />}
                        type="default"
                        onClick={() => setVisibilityModal(true)}
                        loading={visibilityUpdateLoading}>
                        {t("compliance_ChangeVisibility")}
                    </GridButton>

                    <GridButton
                        icon={<Eye />}
                        type="default"
                        onClick={() => setStatusModalVisible(true)}
                        loading={false}>
                        {t("compliance_ChangeStatus")}
                    </GridButton>

                    <GridButton
                        danger
                        data-test="delete_button"
                        icon={<Trash02 style={{ color: "#FF4D4F" }} />}
                        type="default"
                        loading={removeLoading}
                        onClick={() => setDeleteModal({ visible: true })}>
                        {t("deleteRegistration")}
                    </GridButton>
                </>
            )}

            <Modal
                wrapProps={{ "data-test": "change_visibility_modal" }}
                title={t("compliance_ChangeVisibility")}
                open={visibilityModal}
                onOk={() => handleChangeVisibility()}
                footer={[
                    <Button
                        loading={visibilityUpdateLoading}
                        type="primary"
                        onClick={async () => await handleChangeVisibility()}>
                        {t("generic_Save")}
                    </Button>,
                ]}
                onCancel={() => {
                    setSelectedVisibility(undefined);
                    setVisibilityModal(false);
                }}>
                <Fragment>
                    <div className="gridTagLabel">
                        {t("grid_TagSelected", {
                            name: t("compliance_Registrations"),
                        })}
                        :
                    </div>
                    <div className="gridTagArea">
                        {selectedRowKeys.map((id: any) => {
                            return (
                                <Tag color="blue" key={id}>
                                    {id}
                                </Tag>
                            );
                        })}
                    </div>
                </Fragment>
                <Row>
                    <Col span={24}>{t("compliance_ChangeVisibilityInfo")}</Col>
                </Row>
                <Row style={{ marginTop: 16 }}>
                    <Col span={4}>{t("compliance_SelectVisibility")}:</Col>
                    <Col span={20}>
                        <Select
                            value={selectedVisibility}
                            style={{ width: "100%", marginBottom: "12px" }}
                            placeholder={t("compliance_SelectVisibilityPlaceholder")}
                            onChange={(value: ComplianceVisibility) =>
                                setSelectedVisibility(value)
                            }>
                            <Option value={ComplianceVisibility.Internal}>{t("internal")}</Option>
                            <Option value={ComplianceVisibility.External}>{t("external")}</Option>
                        </Select>
                    </Col>
                </Row>
                <Alert
                    message=""
                    type={"info"}
                    description={<div>{t("review_ChangeVisibilityEmailInfo")}</div>}
                />
            </Modal>

            <ChangeStatusModal
                onClose={() => setStatusModalVisible(false)}
                selectedRowKeys={selectedRowKeys}
                visible={statusModalVisible}
                onSuccess={() => {
                    setStatusModalVisible(false);
                    onUpdateSelectedRowKeys([]);
                    onUpdateSelectedRows([]);
                    onReloadData();
                }}
            />

            <GridDeleteModal
                visible={deleteModal.visible}
                onClose={() => {
                    setDeleteModal({
                        visible: false,
                    });
                }}
                data={selectedRowKeys.map((id: any) => ({
                    id: id,
                    name: id,
                }))}
                name={t("compliance_Registrations", "Registrations")}
                confirmLoading={removeLoading}
                onConfirm={handleDelete}
            />
        </Fragment>
    );
};

export default OverviewHeader;
