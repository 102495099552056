import { useEffect, useState } from "react";
import { Provider } from "services";

const useSupplierPortalSurveyRequestCount = (reload: any = null) => {
    const [state, setState] = useState<any>({
        loading: true,
        data: 0,
    });
    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });

            let data = await Provider.SupplierPortal.getSupplierSurveyRequestCount({
                customError: {
                    message: "Could not retrieve data",
                },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });
            setState({ loading: false, data });
        })();
    }, [reload]);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useSupplierPortalSurveyRequestCount };
