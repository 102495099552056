import React, { FunctionComponent } from "react";
import { Card, Row, Col, Tooltip, Popconfirm } from "antd";
import { UserImage, LoadingIndicator, DeleteIcon } from "components/utilitycomponents";
import { elapsedTime } from "utilities";
import { getChildActivity } from "./helpers/getChildActivity";
import { IActivityChild } from "models";
import { useActivityRemove } from "controller";
import { useTranslation } from "react-i18next";

type PropsType = {
    data: IActivityChild;
    parentActivityId: number;
    onRemoveActivity: (parentActivityId: number, activityId: number) => void;
};

const ChildActvity: FunctionComponent<PropsType> = ({
    data,
    onRemoveActivity,
    parentActivityId,
}) => {
    const { t } = useTranslation();
    const { ActivityTypeComponent, activityDetail } = getChildActivity(data);

    const { loading: removeLoading, remove: removeActivity } = useActivityRemove();

    const handleRemoveActivity = async () => {
        await removeActivity(data.id);
        onRemoveActivity(parentActivityId, data.id);
    };
    return (
        <Card
            hoverable
            style={{ marginBottom: "12px", marginTop: "12px", cursor: "auto" }}
            size="small">
            <Row>
                <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                    <UserImage
                        contactId={data?.createdByContactId}
                        alt={activityDetail.creator_FullName}
                        style={{ width: "100%" }}
                        fileId={
                            "profilePictureId" in activityDetail &&
                            activityDetail.profilePictureId !== null
                                ? activityDetail.profilePictureId
                                : undefined
                        }
                        customDimension={65}
                    />
                </Col>
                <Col xs={24} sm={24} md={20} lg={20} xl={21} xxl={21} style={{ textAlign: "left" }}>
                    <Row>
                        <Col span={23}>
                            <ActivityTypeComponent
                                data={activityDetail}
                                isDeleted={data.isDeleted}
                            />
                        </Col>
                        <Col span={1} style={{ textAlign: "right" }}>
                            {data.canDelete && !data.isDeleted && (
                                <Tooltip
                                    placement="top"
                                    title={t("generic_Delete")}
                                    mouseLeaveDelay={0}>
                                    {removeLoading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <Popconfirm
                                            placement="topRight"
                                            title={t("activity_ChildDeleteConfirm")}
                                            onConfirm={handleRemoveActivity}
                                            okText={t("generic_Yes")}
                                            cancelText={t("generic_No")}>
                                            <DeleteIcon style={{ marginLeft: 6 }} />
                                        </Popconfirm>
                                    )}
                                </Tooltip>
                            )}
                        </Col>
                    </Row>
                    <Row>{elapsedTime(data.created)}</Row>
                </Col>
            </Row>
        </Card>
    );
};

export default ChildActvity;
