import React, { FunctionComponent } from "react";
import { Col } from "antd";

export const ColLeft: FunctionComponent = ({ children }) => (
    <Col lg={5} md={12} sm={12} xs={24}>
        {children}
    </Col>
);
export const ColRight: FunctionComponent = ({ children }) => (
    <Col lg={8} md={12} sm={12} xs={24}>
        {children}
    </Col>
);
