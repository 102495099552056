import { get } from "../base";

const ComplianceStatus = {
    complianceStatusList: async (sortByName: boolean = true) => {
        const url = `/v1/ComplianceStatus?sortByName=${sortByName}`;
        return await get({ url });
    },
};

export default ComplianceStatus;
