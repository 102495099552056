import React, { FunctionComponent } from "react";
import { Modal, Table, Button } from "antd";
import { ColoredPercentTag, ReviewRate } from "components/utilitycomponents";
import { useTranslation } from "react-i18next";
import dayjs from "utilities/daysJsConfig";

type PropsType = {
    visible: boolean;
    data: any;
    onCancel: () => void;
};

export const RegistrationsModal: FunctionComponent<PropsType> = ({ visible, data, onCancel }) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t("supplierProfile_ComplianceDate"),
            dataIndex: "date",
            render: (val: any, record: any) => (
                <div>{val ? dayjs(val).format("DD MMM YYYY") : "-"}</div>
            ),
        },
        {
            title: t("supplierProfile_ComplianceRating"),
            dataIndex: "rating",
            render: (review: number) => <ReviewRate val={review} />,
        },
        {
            title: t("compliance_FillRate"),
            dataIndex: "fillScore",
            render: (score: number) =>
                score && (
                    <div className="alignCenter">
                        <ColoredPercentTag value={score} />
                    </div>
                ),
        },
        {
            title: t("compliance_ComplianceScore"),
            dataIndex: "complianceAverageScore",
            render: (score: number) =>
                score && (
                    <div className="alignCenter">
                        <ColoredPercentTag value={score} />
                    </div>
                ),
        },
        {
            title: t("compliance_EndDate"),
            dataIndex: "endDate",
            width: 150,
            render: (val: any, record: any) => (
                <div>{val ? dayjs(val).format("DD MMM YYYY") : "-"}</div>
            ),
        },
    ];
    return (
        <Modal
            title={data.name}
            open={visible}
            style={{ top: 5 }}
            centered={false}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button type={"primary"} onClick={onCancel}>
                    {t("generic_Close")}
                </Button>,
            ]}>
            <Table
                columns={columns}
                dataSource={data.compliances}
                size="middle"
                pagination={{ showSizeChanger: true }}
                locale={{ emptyText: () => <div>{t("supplierProfile_ComplianceNoRegs")}</div> }}
            />
        </Modal>
    );
};

export default RegistrationsModal;
