import React, { useEffect } from "react";
import { Modal, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { SelectDataMultiple } from "components/utilitycomponents";
import { usePartnerCategories, usePartnerCategoriesUpdate } from "controller";
import { categoryType } from "models";

interface IProps {
    visible: boolean;
    onClose: Function;
    categories: any[];
    partnerId: number;
}

const CategoryModal = ({ visible, onClose, categories, partnerId }: IProps) => {
    const { update, loading } = usePartnerCategoriesUpdate();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleOnSubmit = async () => {
        let values = form.getFieldsValue();
        await update([partnerId], values.categories, "SecondCategory", "");
        onClose(true);
    };

    useEffect(() => {
        form.resetFields();
    }, [partnerId]);

    return (
        <Modal
            title={`${t("select")} ${t("supplierProfile_summary_Category")}`}
            open={visible}
            onCancel={() => onClose()}
            destroyOnClose
            footer={[
                <Button
                    loading={loading}
                    type="primary"
                    onClick={async () => await handleOnSubmit()}>
                    {t("generic_Save")}
                </Button>,
            ]}>
            <Form form={form} onFinish={handleOnSubmit}>
                <Form.Item name="categories" initialValue={categories.map((c: any) => c.id)}>
                    <SelectDataMultiple
                        style={{ width: "100%" }}
                        placeholder={`${t("categorySelectPlaceholder")} ${t(
                            "supplierProfile_summary_Category"
                        )}`}
                        dropdownRender={(menu: any) => <div>{menu}</div>}
                        selectOptionField="name"
                        useData={usePartnerCategories.bind(null, categoryType.two, "")}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export { CategoryModal };
