import { IActivityChild } from "models";
import { IAction } from "models/action";
import { useEffect, useState } from "react";
import { Provider } from "services";

type StateType = {
    loading: boolean;
    data: IAction | null;
};

const useAction = (actionId: number | undefined, reload: any = null) => {
    const [state, setState] = useState<StateType>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        if (actionId !== undefined) {
            (async () => {
                setState({ ...state, loading: true });

                let data;

                data = await Provider.Action.getActionById(actionId, {
                    customError: {
                        message: "Could not retrieve action data",
                    },
                }).catch(err => {
                    setState({ ...state, loading: false });
                    return Promise.reject(err);
                });
                setState({ ...state, loading: false, data });
            })();
        }
    }, [actionId, reload]);

    const addActivity = (parentActivityId: number, newActivity: IActivityChild) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: [newActivity, ...state.data.childrenActivity],
                },
            });
        }
    };

    const removeActivity = (parentActivityId: number, activityId: number) => {
        if (state.data) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    childrenActivity: state.data.childrenActivity.map(
                        (activity: IActivityChild) => {
                            if (activity.id !== activityId) {
                                return activity;
                            }
                            activity.isDeleted = true;
                            activity.canDelete = false;
                            return activity;
                        }
                    ),
                },
            });
        }
    };

    return {
        loading: state.loading,
        data: state.data,
        addActivity,
        removeActivity,
    };
};

export { useAction };
