import { useState } from "react";
import { Provider } from "services";

const useReviewDefaultsUpdate = () => {
    const [loading, setLoading] = useState(false);

    const update = async (reviewDefaults: any) => {
        setLoading(true);
        const id = await Provider.Review.updateReviewDefaults(reviewDefaults, {
            customError: {
                message: "Could not update review defaults",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return id;
    };

    return {
        loading: loading,
        update,
    };
};

export { useReviewDefaultsUpdate };
