import { useState } from "react";
import { Provider } from "services";

const useRecallSelfAssessment = () => {
    const [loading, setLoading] = useState(false);

    const recall = async (tokens: string[]) => {
        setLoading(true);
        await Provider.Compliance.recallSelfAssessment(tokens);
        setLoading(false);
    };

    return {
        loading,
        recall,
    };
};

export { useRecallSelfAssessment };
