import { useState } from "react";
import { Provider } from "services";

const useSSOActions = () => {
    const [loading, setLoading] = useState(false);
    const [checkForExistingDomainLoading, setCheckForExistingDomainLoading] = useState(false);

    const addDomainName = async (domainName: string) => {
        setLoading(true);
        await Provider.Company.addSSODomain(domainName);
        setLoading(false);
    };

    const deleteDomainName = async (domainId: number[]) => {
        setLoading(true);
        for (let id of domainId) {
            await Provider.Company.deleteSSODomain(id);
        }
        setLoading(false);
    };

    const updateDomainName = async (domainId: number, newName: string) => {
        setLoading(true);
        await Provider.Company.updateSSODomain(domainId, newName);
        setLoading(false);
    };

    const setIsInternalLoginAllowed = async (isAllowed: boolean) => {
        setLoading(true);
        await Provider.Company.setIsInternalLoginAllowed(isAllowed);
        setLoading(false);
    };

    const setSsoEnabled = async (isEnabled: boolean) => {
        setLoading(true);
        await Provider.Company.setSsoEnabled(isEnabled);
        setLoading(false);
    };

    const checkForExistingDomain = async (domainName: string) => {
        setCheckForExistingDomainLoading(true);
        const exists = await Provider.Company.checkExistingDomain(domainName);
        setCheckForExistingDomainLoading(false);
        return exists;
    };

    return {
        loading,
        checkForExistingDomainLoading,
        addDomainName,
        deleteDomainName,
        updateDomainName,
        setIsInternalLoginAllowed,
        setSsoEnabled,
        checkForExistingDomain,
    };
};

export { useSSOActions };
