import { useEffect, useState } from "react";
import { Provider } from "services";
import { INotificationSetting } from "models";

type StateType = {
    loading: boolean;
    data: INotificationSetting[];
};

const useNotificationSettings = () => {
    const [state, setState] = useState<StateType>({
        loading: false,
        data: [],
    });

    useEffect(() => {
        (async () => {
            setState({ ...state, loading: true });
            let data = await Provider.User.getNotificationSettings({
                retryCount: 3,
                customError: { message: "Could not retrieve notification settings" },
            }).catch(err => {
                setState({ ...state, loading: false });
                return Promise.reject(err);
            });

            setState({ loading: false, data });
        })();
    }, []);

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useNotificationSettings };
