import { IDevelopmentPlanTemplateActionModel } from "models/developmentPlan/IDevelopmentPlanTemplateActionModel";
import { IDevelopmentPlanTemplateCreationModel } from "models/developmentPlan/IDevelopmentPlanTemplateCreationModel";
import { useState } from "react";
import Provider from "services/api";

const useDevelopmentPlanTemplateCRUD = () => {
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);

    const remove = async (ids: number[]) => {
        setLoading(true);
        await Provider.DevelopmentPlan.deleteDevelopmentPlanTemplate(ids, {
            customError: {
                message: "Could not delete templates",
            },
        }).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const create = async (
        developmentPlanTemplateCreationInput: IDevelopmentPlanTemplateCreationModel
    ) => {
        setCreating(true);
        await Provider.DevelopmentPlan.createDevelopmentPlanTemplate(
            developmentPlanTemplateCreationInput,
            {
                customError: {
                    message: "Could not create new development plan tempate",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setCreating(false);
    };
    const edit = async (
        developmentPlanTemplateCreationInput: IDevelopmentPlanTemplateCreationModel
    ) => {
        setCreating(true);
        await Provider.DevelopmentPlan.editDevelopmentPlanTemplate(
            developmentPlanTemplateCreationInput,
            {
                customError: {
                    message: "Could not edit development plan tempate",
                },
            }
        ).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setCreating(false);
    };

    const editTemplateAction = async (input: IDevelopmentPlanTemplateActionModel) => {
        setLoading(true);
        await Provider.DevelopmentPlan.editDevelopmentPlanTemplateAction(input).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    const removeTemplateAction = async (id: number) => {
        setLoading(true);
        await Provider.DevelopmentPlan.removeTemplateAction(id).catch(err => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
    };

    return {
        loading: loading,
        deleteDevelopmentPlanTemplate: remove,
        creating,
        createDevelopmentPlanTemplate: create,
        editDevelopmentPlanTemplate: edit,
        editTemplateAction,
        removeTemplateAction,
    };
};

export { useDevelopmentPlanTemplateCRUD };
