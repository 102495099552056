export function getTableSize(parentElementId: any) {
    let parentElementHeight: any = document.getElementById(parentElementId)!.style.height;
    let yScrollHeight = parentElementHeight.replace("px", "");

    let titleHeight: any = document.getElementById(`reportTitle${parentElementId}`)!.offsetHeight;
    let titleExcess = titleHeight + 20;

    let descriptionHeight: any = document.getElementById(`reportDescription${parentElementId}`)!
        .offsetHeight;
    let descriptionExcess = descriptionHeight + 125;

    let pageSize: number = 0;
    let defaultRowHeight: number = 25;
    pageSize = Math.round(yScrollHeight / defaultRowHeight);
    return { pageSize, yScrollHeight, titleExcess, descriptionExcess };
}
