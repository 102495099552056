import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles/shared.module.scss";
import { IKickStarterDetail } from "models";

type PropsType = {
    data: IKickStarterDetail;
};

const KickStarter: FunctionComponent<PropsType> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <span className={classes.notificationContainer}>
            <div className={classes.notificationTitle}>{t("activity_Welcome")}</div>
            <div>{t("activity_Kickstarter")}</div>
        </span>
    );
};

export { KickStarter };
