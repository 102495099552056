import { useState } from "react";
import { Provider } from "services";

const useSurveyTemplateCrud = () => {
    const [loading, setLoading] = useState(false);
    const [checkTitleLoading, setCheckTitleLoading] = useState(false);

    const create = async (payload: any) => {
        setLoading(true);
        let data = await Provider.Review.createSurveyTemplate(payload, {
            customError: {
                message: "Could not create survey template",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const update = async (payload: any) => {
        setLoading(true);
        let data = await Provider.Review.updateSurveyTemplate(payload, {
            customError: {
                message: "Could not update survey template",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const remove = async (ids: number[]) => {
        setLoading(true);
        let data = await Provider.Review.deleteSurveyTemplates(ids, {
            customError: {
                message: "Could not delete survey template(s)",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });
        setLoading(false);
        return data;
    };
    const checkTitle = async (title: string) => {
        if (title !== "") {
            setCheckTitleLoading(true);
            const inUse = await Provider.Review.isSurveyTemplateTitleInUse(title, {
                customError: {
                    message: "Could not validate survey template title",
                },
            }).catch((err: any) => {
                setCheckTitleLoading(false);
                return Promise.reject(err);
            });
            setCheckTitleLoading(false);
            return inUse;
        }
    };

    return {
        loading,
        checkTitleLoading,
        create,
        update,
        remove,
        checkTitle,
    };
};

export { useSurveyTemplateCrud };
