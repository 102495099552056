import { IDevelopmentPlanModel, ITableInput } from "models";
import { post, get, remove, patch } from "../base";

const DevelopmentPlan = {
    createDevelopmentPlan: async (input: IDevelopmentPlanModel, { customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/CreateDevelopmentPlan`;
        let id = await post({ url, payload: input, customError });
        return id;
    },
    deleteDevelopmentPlanByIds: async (ids: any[], { customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/DeleteDevelopmentPlanByIds`;
        return await remove({ url, payload: ids, customError });
    },
    getDevelopmentPlanById: async (id: number, { customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/GetDevelopmentPlanById?developmentPlanId=${id}`;
        return await get({ url, customError });
    },
    getDevelopmentPlanTemplates: async ({ customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/GetDevelopmentPlanTemplates`;
        return await get({ url, customError });
    },
    getDevelopmentPlanTemplateById: async (templateId: number, { customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/GetDevelopmentPlanTemplateDetailById?templateId=${templateId}`;
        return await get({ url, customError });
    },
    getDevelopmentPlanOverview: async (
        tableInput: ITableInput,
        partnerId: number | undefined,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        let url = `/v1/DevelopmentPlan/DevelopmentPlanOverview`;
        if (partnerId !== undefined) {
            url = `/v1/DevelopmentPlan/DevelopmentPlanOverview?supplierId=${partnerId}`;
        }
        let response = await post({
            url,
            payload: tableInput,
            customError: customError ? customError : null,
        });
        return response;
    },
    editDevelopmentPlan: async (input: IDevelopmentPlanModel, { customError }: any = {}) => {
        const url = `/v1/DevelopmentPlan/EditDevelopmentPlan`;
        await patch({ url, payload: input, customError });
    },
    getDevelopmentPlanByPartnerId: async (suplierId: Number, { customError }: any) => {
        const url = `/v1/Action/GetDevelopmentPlansBySupplierId?suplierId=${suplierId}`;
        return await get({ url, customError });
    },
    getContactsForFilter: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/DevelopmentPlan/ContactsFilter`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
    getDevelopmentPlanFilterOptions: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/DevelopmentPlan/DevelopmentPlansFilter`;
        let response = await get({
            url,
            retryCount: retryCount ? retryCount : 0,
            customError: customError ? customError : null,
        });
        return response;
    },
};

export default DevelopmentPlan;
