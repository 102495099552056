import React, { FunctionComponent, Fragment } from "react";
import Overview from "components/contentcomponents/action/overview/overview";

export const ActionOverviewPage: FunctionComponent = () => {
    return (
        <Fragment>
            <Overview supplierProfileOverview={false} />
        </Fragment>
    );
};
export default ActionOverviewPage;
