import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Button, notification } from "antd";
import EditButton from "../editButton";
import { useSupplierCardsPartnerUpdate } from "controller";
import { AttachFile, RichTextEditor, ShowMore } from "components/utilitycomponents";
import classes from "./styles/editableCard.module.scss";

type PropsType = {
    icon?: any;
    title: string;
    data: {
        text: string;
        fileId: number;
        fileName: string;
    };
    fileParameterName: string;
    textParameterName: string;
    partnerId: number;
};

const EditableCard: FunctionComponent<PropsType> = ({
    icon,
    title,
    data,
    partnerId,
    textParameterName,
    fileParameterName,
}) => {
    const { t } = useTranslation();

    const { loading, updatePartnerInfo } = useSupplierCardsPartnerUpdate();
    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState<string>("");
    const [attachedFileList, setAttachedFileList] = useState<any>();

    useEffect(() => {
        setAttachedFileList(data.fileId ? [{ id: data.fileId, name: data.fileName }] : undefined);
        setText(data ? data.text : "");
    }, [data]);

    const handleAttachFile = async (fileIds: number[]) => {
        await updatePartnerInfo({
            partnerId,
            [fileParameterName]: fileIds.length ? fileIds[0] : 0,
        });
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateSupplierProfileSuccess", { title }),
        });
    };

    const handleTextChange = async () => {
        await updatePartnerInfo({ partnerId, [textParameterName]: text });
        notification.success({
            message: t("success"),
            description: t("supplierProfile_UpdateSupplierProfileSuccess", { title }),
        });
        setEditMode(false);
    };

    return (
        <Fragment>
            <Card
                data-test={`editable_card_${title.toLowerCase().replace(/\s+/g, "_")}`}
                headStyle={{ background: "#fafafa" }}
                bodyStyle={{ padding: "15px" }}
                className={classes.editableCard}
                title={
                    <Fragment>
                        <div style={{ display: "inline-block" }}>
                            {icon && (
                                <div style={{ marginRight: "5px", color: "#0477a9" }}>{icon}</div>
                            )}
                        </div>
                        <div style={{ display: "inline-block" }}>{title}</div>
                    </Fragment>
                }
                extra={
                    editMode ? (
                        <Fragment>
                            <Button
                                data-test="save_button"
                                type="primary"
                                onClick={() => {
                                    handleTextChange();
                                }}
                                loading={loading}
                                style={{ marginRight: "5px" }}>
                                {t("generic_Save")}
                            </Button>
                            <Button
                                data-test="cancel_button"
                                onClick={() => {
                                    setEditMode(false);
                                }}>
                                {t("generic_Cancel")}
                            </Button>
                        </Fragment>
                    ) : (
                        <EditButton onClick={() => setEditMode(true)}>{t("edit")}</EditButton>
                    )
                }>
                {editMode ? (
                    <RichTextEditor defaultValue={text} onChange={(t: string) => setText(t)} />
                ) : (
                    <ShowMore
                        text={text}
                        lineLimit={20}
                        characterLimit={600}
                        convertNlToBr={false}
                    />
                )}
            </Card>
            <div
                style={{
                    borderWidth: "0 1px 1px 1px",
                    borderStyle: "solid",
                    borderColor: "#e8e8e8",
                    padding: "10px",
                }}>
                <AttachFile
                    showMaxFileSize={false}
                    multiple={false}
                    onChange={handleAttachFile}
                    uploadSectionLayout={{ style: { display: "table" } }}
                    uploadButtonLayout={{ style: { display: "table-cell" } }}
                    filesSectionLayout={{
                        style: { display: "table-cell", width: "100%", padding: "0 10px" },
                    }}
                    attachedFileList={attachedFileList}>
                    {t("supplierProfile_AddAFile")}
                </AttachFile>
            </div>
        </Fragment>
    );
};

export default EditableCard;
