import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col } from "antd";
import classes from "./supplierInformation.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useFirstRelationshipDimension } from "controller";

interface IProps {
    data: any;
    loading: boolean;
    onAssignRelationClick: Function;
    partnerId: number;
}

const Relationships: FunctionComponent<IProps> = ({
    data: partnerData,
    loading,
    onAssignRelationClick,
    partnerId,
}) => {
    const { data: dimension } = useFirstRelationshipDimension(partnerId);
    const { t } = useTranslation();
    const {
        profile: { "ll-CompanyName": companyName },
    } = useSelector((state: AppState) => state.oidc.user);
    const [companyMembers, setCompanyMembers] = useState<any[]>([]);
    const [customerMembers, setCustomerMembers] = useState<any[]>([]);
    const [showAllCompanyMembers, setShowAllCompanyMembers] = useState<boolean>(false);
    const [showAllCustomerMembers, setShowAllCustomerMembers] = useState<boolean>(false);

    useEffect(() => {
        if (dimension) {
            if (dimension.categories && dimension.categories.length > 0) {
                let compMemmbers: any[] = [];
                let custMembers: any[] = [];
                dimension.categories.forEach((category: any) => {
                    category.companyContacts.forEach((contact: any) => compMemmbers.push(contact));
                });
                dimension.categories.forEach((category: any) => {
                    category.partnerContacts.forEach((contact: any) => custMembers.push(contact));
                });
                setCompanyMembers(compMemmbers);
                setCustomerMembers(custMembers);
            }
        }
    }, [dimension]);

    const renderRelationships = (type: "company" | "customer") => {
        let members = type === "company" ? companyMembers.slice(0) : customerMembers.slice(0);

        return (
            <Row style={{ marginBottom: 8 }}>
                <Col span={24}>
                    <div style={{ fontWeight: "bold" }}>
                        {type === "company" ? companyName : partnerData.supplierName}
                    </div>
                </Col>
                <Row style={{ width: "100%" }}>
                    <Col span={24}>
                        {members.slice(0, 2).map((member: any) => (
                            <div className={classes.contactContainer}>
                                <div className={classes.companyContact}>{member.name}</div>
                                <div className={classes.supplierContact}>{member.role}</div>
                            </div>
                        ))}
                        {((type === "company" && showAllCompanyMembers === true) ||
                            (type === "customer" && showAllCustomerMembers === true)) &&
                            members.slice(2).map((member: any) => (
                                <div className={classes.contactContainer}>
                                    <div className={classes.companyContact}>{member.name}</div>
                                    <div className={classes.supplierContact}>{member.role}</div>
                                </div>
                            ))}
                        {members.length > 2 && (
                            <div
                                className={classes.showMore}
                                onClick={() => {
                                    type === "company"
                                        ? setShowAllCompanyMembers(!showAllCompanyMembers)
                                        : setShowAllCustomerMembers(!showAllCustomerMembers);
                                }}>
                                {(type === "company" && showAllCompanyMembers === true) ||
                                (type === "customer" && showAllCustomerMembers === true)
                                    ? "Show less"
                                    : "Show more"}
                            </div>
                        )}
                    </Col>
                </Row>
            </Row>
        );
    };

    return (
        <Row style={{ overflowX: "auto" }}>
            <Col span={24}>
                <Row>
                    <Col span={24} className={classes.headline}>
                        {t("governance_Relationships").toLocaleUpperCase()}
                    </Col>
                </Row>
                {renderRelationships("company")}
                {renderRelationships("customer")}

                {/* <Row style={{ marginTop: 4 }}>
                    <Col span={24}>
                        <Button type="primary" size="small" onClick={() => onAssignRelationClick()}>
                            <div style={{ fontSize: 10 }}>{t("governance_AssignRelation")}</div>
                        </Button>
                    </Col>
                </Row> */}
            </Col>
        </Row>
    );
};

export { Relationships };
