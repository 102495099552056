import { useState } from "react";
import { default as serviceProvider } from "services/api";

const useRemoveSharedDashboardToken = () => {
    const [loading, SetLoading] = useState(false);

    const removeGuestToken = async (guestTokenIds: number[]) => {
        SetLoading(true);

        let response = await serviceProvider.Analytics.removeSharedDashboardGuestToken(
            guestTokenIds
        ).catch(err => {
            SetLoading(false);
            return Promise.reject(err);
        });
        SetLoading(false);
        return response;
    };

    return {
        loading,
        removeGuestToken,
    };
};

export { useRemoveSharedDashboardToken };
