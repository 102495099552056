import { ICompanyBranding } from "models";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
type StateType = {
    loading: boolean;
    data: ICompanyBranding;
};

const useCompanyBranding = (isGuest: boolean) => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const [state] = useState<StateType>({
        loading: false,
        data: {
            menuColor: user.profile["ll-BrandingMenuColor"]
                ? user.profile["ll-BrandingMenuColor"]
                : "#1890ff",
            menuTextColor: user.profile["ll-BrandingMenuTextColor"]
                ? user.profile["ll-BrandingMenuTextColor"]
                : "#ffffff",
        },
    });

    return {
        loading: state.loading,
        data: state.data,
    };
};

export { useCompanyBranding };
