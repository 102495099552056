import { get, put } from "../base";
import { IAccountSetting } from "models";

const UserSettings = {
    getRememberFilters: async () => {
        const url = `/v1/UserSetting`;
        let userSettings: any = await get({ url });
        return userSettings.rememberFilters;
    },
    getAccountSettings: async (
        { retryCount, customError }: any = {
            retryCount: 3,
            customError: { title: "Error", message: "An error occured" },
        }
    ) => {
        const url = `/v1/UserAccount/AccountSettings`;
        let userSettings: any = await get({
            url,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
        return userSettings;
    },
    updateAccountSettings: async (
        accountSettings: IAccountSetting,
        { retryCount, customError }: any = {
            retryCount: 0,
            customError: { title: "Error", message: "An error occurred" },
        }
    ) => {
        const url = `/v1/UserAccount/AccountSettings`;
        await put({
            url,
            payload: accountSettings,
            retryCount: retryCount ? retryCount : 3,
            customError: customError ? customError : null,
        });
    },
    isUsernameInUse: async (userName: string, { customError }: any = {}) => {
        const url = `/v1/UserAccount/isUsernameInUse?userName=${userName}`;
        return await get({ url, customError });
    },
};

export default UserSettings;
