export enum categoryType {
    one = 1,
    two = 2,
}

export interface ICategory {
    id: string;
    name: string;
    categoryType: categoryType;
}

export default ICategory;
