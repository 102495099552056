import { IPbiBookmark, IPbiExportType } from "models";
import { get, post } from "../base";

const PowerBi = {
    getSegmentationReportEmbedInfo: async ({ customError }: any = {}) => {
        const url = `/v1/SegmentationReportEmbedInfo`;
        return await get({ url, customError });
    },
    getEmbedInfo: async (workspaceId: string, reportId: string, { customError }: any = {}) => {
        const url = `/v1/RetrieveEmbedInfo?workspaceId=${workspaceId}&reportId=${reportId}`;
        return await get({ url, customError });
    },
    postExportRequest: async (
        workspaceId: string,
        reportId: string,
        format: IPbiExportType,
        bookmark?: IPbiBookmark,
        { customError }: any = {}
    ) => {
        const customConfig = {
            timeout: 30 * 60 * 1000,
        };
        const url = `/v1/ExportReport?reportId=${reportId}&groupId=${workspaceId}&format=${format}`;
        return await post({
            url,
            payload: bookmark,
            customError,
            customConfig,
            responseType: "blob",
        });
    },
    getEnabledPowerBiReportsByArea: async (areaId: number, { customError }: any = {}) => {
        const url = `/v1/EnabledPowerBiEmbedInfoByArea?areaId=${areaId}`;
        return await get({ url, customError });
    },
};

export default PowerBi;
