import { useState } from "react";
import { Provider } from "services";

const useIsUserNameInUse = () => {
    const [loading, setLoading] = useState(false);

    const isUserNameInUse = async (username: string, invitationToken: string) => {
        const result = await Provider.User.isUsernameDuplicate(username, invitationToken, {
            customError: {
                message: "Could not check if username exists",
            },
        }).catch((err: any) => {
            setLoading(false);
            return Promise.reject(err);
        });

        return result;
    };
    return {
        loading,

        isUserNameInUse,
    };
};

export { useIsUserNameInUse };
